import { useState } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

type AllergenProps = {
  uniqueId: string;
  date: string;
  vendor: string;
  totalCost: string;
};

const allergensData: AllergenProps[] = [
  {
    uniqueId: "1",
    date: "09 / 14 / 2022",
    vendor: "For all items in contact with peanuts",
    totalCost: "$0.00",
  },
  {
    uniqueId: "2",
    date: "09 / 14 / 2022",
    vendor: "For all items in contact with peanuts",
    totalCost: "$0.00",
  },
  {
    uniqueId: "3",
    date: "09 / 14 / 2022",
    vendor: "For all items in contact with peanuts",
    totalCost: "$0.00",
  },
];

const columns: ColumnInstruction<AllergenProps>[] = [
  { type: "data", header: "Date", attribute: "date" },
  { type: "data", header: "Vendor", attribute: "vendor" },
  { type: "data", header: "Total Costs", attribute: "totalCost" },
];

const instructions: { [x: string]: RenderInstruction<AllergenProps> } = {};

const useInvoices = () => {
  const [isEditing, setEditing] = useState(false);

  const noOp = () => Promise.resolve(true);
  const logIt = (args: string[]) => console.log(args);

  return {
    allergensData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default useInvoices;
