import { InputGroup } from "@blueprintjs/core";
import {
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { find, findIndex } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { staffSelector } from "model/selectors/staffing";

import { RootState } from "../../../../model/store";
import RoleCard from "../RoleCard";
import SelectedTreeNode from "../TreeView/SelectedTreeNode";
import "../index.css";

const Departments = () => {
  const dummyURL =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI0AAACNCAMAAAC9gAmXAAAAMFBMVEXk5ueutLeyuLvp6+yrsbTh4+TS1dfKztCnrrHY29zb3t/P0tS1u76/w8bBxsjHy81ElUJxAAADL0lEQVR4nO2a227DIAxAy7VAgPz/347Srk2XLmAnJn3wkSatb0fGXGL7cmEYhmEYhmEYhmEYpok6W+CJCXaaJuuNOVlEGZujcLriRJytOi9KPhUJsaD8yuEUFeXju8pDSGQzPD7KRLdWufu4eXQGTeJDXJ4+cexy5Q2X6mPHrZaJDZmik4fptGWKznWQTo+MEC6PcFGpS6ZEZxogc+2UKTr0Oyt0ywghyc+d2C9DvrHUDAhNyWTitQLJCJFIZVpn8F+0J5QxEiYjRKTLHDUBQ1OgyxwFDo3QM5lNAMuUpSKz6T+GX9Bt8t4bagndXQ53KSQiG/PfS3gTqsSxKBuiqxPwlniD5jhW0GvhjrY0NgkVGrbZsKF5Hn9ZbPJX2cBeoU+IHlyI101BEl2b/pvO4i+7p0DfUr/QfVNhrgZHs6UKFvPaopK5GLgM4fddd7FkERrCsomHLxWdDHxX6ZmySgHOY9IKDvBrk/BLswLMHOK6KGhbET0mlvSvlR5Qo+0vDdAVBF70F3HGtD6mvpdFGFPa7/roJK34vdOMjpaDIlPxcjM8Oo3t3pm0ER53HepyqU3Wzz66BOaMNrSV66avFnFc+v71ycIthLST88jsXROuKUZZiDFNZ88ulARSF3Oj/HNqWFQVCd5bW/68DyGYs5SCfazRbRvVeYq6Xmm2Q1vzSgU7p9tkyT873OmYJz8kSMbnWzS2TuKqVL6kLG1SK+WTdN2PPyfTRDe15GcNrVJolyyJkI/N9fnoo+Pxx5CVqOLNXUhcD7257PYDou0jDxsQUgHTl1r5HDORYzJ+jd584v4L9TYrdohMTZ+9NvMxgXn47AuP2Zm9a/DfwsofrCJ2NF0xkwEdOrhCII0MslaA7WJ26CB60kSRqTrQ6ChMoRqgA4sOZFYMowPbWfDRFqAO5O18wDXZANC1Ik2aO4CNRe4iANUm3IQClM4aJabvg6BvIBrR9kHSExrMqBiKrtYV2f20oidzqA++Fx3jbuHIp+c27VYadlgCRXupMB14NK2lgk/J7qDZv0J0dXfYtBJnwIW5oHV1jrmjfpHbNirLoTS3+FAaMgzDMAzzTfwA+XMmLtWzWB4AAAAASUVORK5CYII=";
  const [searchText, setSearchText] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [isDisplayTreeNode, setIsDisplayTreeNode] = useState(false);
  const [staffMemberLoaded, setStaffMembersLoaded] = useState(false);

  const staffMembers: StaffMember[] = useSelector(staffSelector);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const departments = ["FOH", "BOH", "Other"];

  useEffect(() => {
    if (staffMembers.length) {
      setStaffMembersLoaded(true);
    }
  }, [staffMembers]);

  const jobFunctions = useMemo(() => {
    return businessSettings
      ? Object.keys(businessSettings.jobFunctions).map(
          (key) => businessSettings.jobFunctions[key]
        )
      : [];
  }, [businessSettings]);

  const departmentEmployeeData = useMemo(() => {
    if (departments && jobFunctions && staffMemberLoaded) {
      const finalData: any = [];
      departments.forEach((department) => {
        const employees: any = [...staffMembers]
          .filter((staffMember) => {
            if (!staffMember.departmentId) {
              return false;
            }
            const fullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
            if (!fullName.toLowerCase().includes(searchText.toLowerCase())) {
              return false;
            }
            const isAvailable = findIndex(staffMembers, {
              departmentId: department.toLowerCase(),
              id: staffMember.id,
            });
            return isAvailable !== -1;
          })
          .map((staffMember) => {
            const roleName = find(jobFunctions, {
              id: staffMember.primaryRole,
            }) as any;
            return {
              id: staffMember.id,
              firstName: staffMember.contact.firstName,
              lastName: staffMember.contact.lastName,
              url: staffMember.imageUrl,
              role: roleName?.title,
            };
          });
        finalData.push({
          id: department,
          name: department,
          employees,
        });
      });
      console.log({ finalData });
      return finalData;
    }
    return [];
  }, [departments, jobFunctions, searchText, staffMemberLoaded]);

  useEffect(() => {
    requestAnimationFrame(() => {
      const element = document.querySelector("[data-type='level-selected']");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    });
  }, [activeStep]);

  const LevelsArray = [
    {
      id: 1,
      name: "CEO",
      employees: [
        {
          id: 1,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
      ],
    },
    {
      id: 2,
      name: "C-Suite",
      employees: [
        {
          id: 1,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 2,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 3,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 4,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 5,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 6,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
        {
          id: 7,
          firstName: "Terrence",
          lastName: "Field",
          url: dummyURL,
          role: "CEO",
        },
      ],
    },
    {
      id: 3,
      name: "Level-3",
      employees: [
        {
          id: 1,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 2,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 3,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 4,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 5,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 6,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 7,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 8,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 9,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 10,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 11,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
      ],
    },
    {
      id: 4,
      name: "Locations",
      employees: [
        {
          id: 1,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 2,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 3,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 4,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 5,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 6,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 7,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 8,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
        {
          id: 9,
          firstName: "Terrence",
          lastName: "Field",
          url: "",
          role: "CEO",
        },
      ],
    },
  ];

  type Employee = {
    id: string;
    firstName: string;
    lastName: string;
    url?: string;
    role: string;
  };

  const roleCardDetails = (employee: Employee) => {
    setIsDisplayTreeNode(false);
  };

  const StepIcon = ({
    id,
    label,
    selected,
  }: {
    id: number;
    label: string;
    selected: boolean;
  }) => (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        ...(selected ? { color: "#4385EF" } : {}),
      }}
      onClick={() => setActiveStep(id)}
    >
      {label}
    </div>
  );
  return (
    <>
      {!isDisplayTreeNode ? (
        <>
          <Grid spacing={6} container>
            <Grid item md={9}>
              <Box className="level-search-section">
                <InputGroup
                  placeholder="Search Employee"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  leftIcon="search"
                  intent="none"
                />
              </Box>
              {departmentEmployeeData.map((level: any) => (
                <Box className="levels">
                  <div
                    className="level-title"
                    data-type={level.id === activeStep ? "level-selected" : ""}
                  >
                    {level.name}
                  </div>
                  <Grid container spacing={3} display="flex" marginBottom={5}>
                    {level.employees.map((ele: any, index: any) => (
                      <Grid item key={index} md={3} display={"flex"}>
                        <RoleCard
                          employee={ele}
                          onClickRoleCard={roleCardDetails}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Divider />
                </Box>
              ))}
            </Grid>
            <Grid item md={3}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                className="level-step"
              >
                {departments.map((department, index) => (
                  <Step key={department}>
                    <StepLabel
                      icon={
                        <StepIcon
                          id={index}
                          label={department}
                          selected={index === activeStep}
                        />
                      }
                    />
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <SelectedTreeNode
            isDisplayTreeNode={isDisplayTreeNode}
            setIsDisplayTreeNode={setIsDisplayTreeNode}
          />
        </>
      )}
    </>
  );
};

export default Departments;
