import { FixesAvailabilityAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FixedAvailability[] = [];

export default function fixedAvailabilities(
  state = initialState,
  action: FixesAvailabilityAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_FIXED_AVAILABILITIES:
      return action.fixedAvailabilities;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
