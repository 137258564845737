import { Classes, Icon } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import AvailabilityReportDocument from "views/BusinessAdmin/Reporting/PdfReports/AvailabilityReportDocument";

import { generateStaffAvailabilityReportReport } from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import "../style.css";

export const AvailabilityMoreAction = () => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const generateDepartmentOrientedReport = async () => {
    if (!business || !fixedAvailabilities || !fellowStaffMembers) return null;
    const reportData = generateStaffAvailabilityReportReport(
      fellowStaffMembers,
      fixedAvailabilities
    );

    if (reportData) {
      const blob = await pdf(
        <AvailabilityReportDocument
          report={reportData}
          businessName={business.businessName}
        />
      ).toBlob();
      // setReportIdLoading(null)
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } else {
      alert("No report data available");
    }
  };

  return (
    <Box className="more-action">
      <Box className="action-header">
        <Icon icon="delete" className={Classes.POPOVER_DISMISS} />
        Availabilities actions
      </Box>
      <Box className="action-item" onClick={generateDepartmentOrientedReport}>
        Generate Availabilities PDF report
      </Box>
    </Box>
  );
};
