import * as React from "react";

export const jobDescription = ({
  height = "24px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M9.5 3.5V2H6.5V3.5H9.5ZM2 5V13.25H14V5H2ZM14 3.5C14.8325 3.5 15.5 4.1675 15.5 5V13.25C15.5 14.0825 14.8325 14.75 14 14.75H2C1.1675 14.75 0.5 14.0825 0.5 13.25L0.5075 5C0.5075 4.1675 1.1675 3.5 2 3.5H5V2C5 1.1675 5.6675 0.5 6.5 0.5H9.5C10.3325 0.5 11 1.1675 11 2V3.5H14Z"
      fill={color}
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
