import * as React from "react";

export const All = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      d="M8.45833 4.29167H14.7083V6.375H8.45833V4.29167ZM8.45833 8.45833H14.7083V10.5417H8.45833V8.45833ZM8.45833 12.625H14.7083V14.7083H8.45833V12.625ZM4.29167 4.29167H6.375V6.375H4.29167V4.29167ZM4.29167 8.45833H6.375V10.5417H4.29167V8.45833ZM4.29167 12.625H6.375V14.7083H4.29167V12.625ZM17.9375 0.125H1.0625C0.541667 0.125 0.125 0.541667 0.125 1.0625V17.9375C0.125 18.3542 0.541667 18.875 1.0625 18.875H17.9375C18.3542 18.875 18.875 18.3542 18.875 17.9375V1.0625C18.875 0.541667 18.3542 0.125 17.9375 0.125ZM16.7917 16.7917H2.20833V2.20833H16.7917V16.7917Z"
      fill={color}
    />
  </svg>
);
