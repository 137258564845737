import { Button, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  ICloseOutReport,
  getCloseOutReportForADateRange,
} from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import CloseOutReportDocument from "../PdfReports/CloseOutReportDocument";
import "../style.css";

interface ModalOption {
  action: string;
  title: string;
  icon: string;
}

interface Props {
  open: boolean;
  closeModal: () => void;
  subTitle?: string;
  title: string;
  onSelect: (option: ModalOption) => void;
  businessId: string;
}

interface EventSummary {
  amount: number;
  timestamp: string;
  description?: string;
}

interface CashDrawer {
  cashDrawerId: string;
  openDrawer: EventSummary | null;
  closeDrawer: EventSummary | null;
  payout: EventSummary[] | null;
  order: EventSummary[] | null;
  refund: EventSummary[] | null;
}
[];

const CloseOutReportModal: FunctionComponent = () => {
  const [dailyReports, setDailyReports] = useState<ICloseOutReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);
  const [downloadInitialData, setDownloadInitialData] = useState(false);

  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const allPricingModels = [].concat.apply(
    [],
    business?.tangoProducts.map((model: DocumentData) => {
      const { name } = model;
      return model.orderTypes.map((data: DocumentData) => {
        return {
          orderChannel: name,
          orderType: data.name,
          taxRate: data.taxRate,
          tangoFeeCents: data.tangoFeeCents,
          tangoFeePercent: data.tangoFeePercent,
          waitTime: data.waitTime || 0,
        };
      });
    })
  );

  const fetchMoreData = useCallback(async () => {
    if (
      business &&
      earliestFetchedDate &&
      allPricingModels &&
      businessSettings
    ) {
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract(30, "days")
        .toDate();
      const reports = await getCloseOutReportForADateRange(
        business.id,
        moment(newEarliestDate).startOf("day").add(4, "hours").toDate(),
        moment(oldEarliestDate).startOf("day").add(4, "hours").toDate(),
        allPricingModels,
        businessSettings,
        business
      );
      setEarliestFetchedDate(newEarliestDate);
      const realData = reports.filter(
        (report) => report.data.length > 0 && report.data[0].netTotal > 0
      );
      setLatestResponseLength(reports.length);
      setDailyReports(
        [...dailyReports, ...realData].sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        )
      );
    }
  }, [dailyReports, earliestFetchedDate, business?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (downloadInitialData) {
        const earliestDate = moment().subtract(30, "days").toDate();
        const reports = await getCloseOutReportForADateRange(
          business.id,
          moment(earliestDate).startOf("day").add(4, "hours").toDate(),
          moment().startOf("day").add(4, "hours").toDate(),
          allPricingModels,
          businessSettings,
          business
        );
        setEarliestFetchedDate(earliestDate);
        const realData = reports.filter(
          (report) => report.data.length > 0 && report.data[0].netTotal > 0
        );
        setLatestResponseLength(reports.length);
        setDailyReports(
          realData.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
        );
      }
    };
    fetchInitialData();
  }, [downloadInitialData]);

  useEffect(() => {
    if (
      business &&
      allPricingModels &&
      businessSettings &&
      !downloadInitialData
    ) {
      setDownloadInitialData(true);
    }
  }, [business?.id, allPricingModels, businessSettings]);

  const createBlob = async (report: ICloseOutReport, snapshot = false) => {
    try {
      const blob = await pdf(
        <CloseOutReportDocument
          report={report}
          businessName={business.businessName || ""}
          snapshot={snapshot}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      setReportIdLoading(null);
    } catch (e) {
      setReportIdLoading(null);
      alert("Opps, something went wrong generating your report");
      console.log("error generating report", e);
    }
  };

  const hasMoreDocumentsToLoad = _.isNull(latestResponseLength)
    ? true
    : Boolean(latestResponseLength);

  return (
    <div className="w-full">
      <div className="page-header">
        <div className="page-title">Summary Reports</div>
      </div>
      <Box display="flex" flexDirection="column">
        {dailyReports.length === 0 ? (
          <div style={{ marginTop: 20 }}>
            <Spinner size={40} />
            <div
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              This may take a couple minutes to load.
            </div>
          </div>
        ) : (
          <>
            {_.uniqBy(dailyReports, "id").map((report) => {
              const isLoadingBlob = reportIdLoading === report.id;
              const renderButton = () => {
                const lastReportStartDay = moment(report.date);
                const lastReportEndDay = lastReportStartDay
                  .clone()
                  .add(1, "day");
                if (
                  moment().isAfter(lastReportStartDay) &&
                  moment().isBefore(lastReportEndDay)
                ) {
                  return (
                    <div
                      className="report-table-text"
                      onClick={() => {
                        setReportIdLoading(report.id);

                        createBlob(report, true);
                        // fetchAndOpenReport()
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View Snapshot
                    </div>
                  );
                }
                if (isLoadingBlob) {
                  return <Spinner size={20} />;
                }
                return (
                  <div
                    className="report-table-text"
                    onClick={() => {
                      setReportIdLoading(report.id);

                      createBlob(report);
                      // fetchAndOpenReport()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View Report
                  </div>
                );
              };
              return (
                <Box
                  key={report.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: 5, marginTop: 10 }}
                >
                  <div className="report-table-text">
                    {moment(report.date).format("MMMM D, YYYY")}
                  </div>
                  {renderButton()}
                </Box>
              );
            })}
            {hasMoreDocumentsToLoad && (
              <div className="center-button">
                <Button
                  text="Load more"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await fetchMoreData();
                    setLoading(false);
                  }}
                  className="load-more-button" // create-new-teammate
                  fill
                />
              </div>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default CloseOutReportModal;
