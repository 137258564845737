import _, { isEqual } from "lodash";

import { FirebasePrintersDoc, PrintersDoc } from "types/printers";

import firebase from "../config/firebase";

const db = firebase.firestore();
const PrintersCollection = db.collection("Printers");

export const performSoftDelete = async (printers: PrintersDoc[]) => {
  for await (const printer of printers) {
    try {
      if (printer.id && printer.id.length > 0) {
        await PrintersCollection.doc(printer.id).update({ deleted: true });
      }
    } catch (err) {
      console.log("Failed to update: ", printer.id);
    }
  }
};

export const saveData = async (
  newData: PrintersDoc[],
  existingData: PrintersDoc[],
  businessId: string
) => {
  const firebasePrinters: FirebasePrintersDoc[] = newData
    .map((printer, index) => ({ ...printer, index: index }))
    .filter((printer) => {
      const printerToCheck = _.omit(printer, "index");
      const foundItem = existingData.some((existingPrinter) =>
        isEqual(existingPrinter, printerToCheck)
      );

      // Remove printer documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((printer) => {
      return {
        id: printer.id,
        businessId: businessId,
        name: printer.name,
        macAddress: printer.macAddress,
        printerType: printer.printerType,
        routedSubTypes: printer.routedSubTypes.includes("all")
          ? []
          : printer.routedSubTypes,
        routedOrderTypes: printer.routedOrderTypes.includes("all")
          ? []
          : printer.routedOrderTypes,
        ticketType: printer.ticketType,
        serviceAreas: printer.serviceAreas,
        allowAllRoutedSubTypes: printer.routedSubTypes.includes("all"),
        allowAllRoutedOrderTypes: printer.routedOrderTypes.includes("all"),
        autoPrint: printer.autoPrint === "Yes",
        enabled: printer.enabled === "Yes",
        deleted: false,
        qrIds: [],
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    });

  for await (const printer of firebasePrinters) {
    // For existing printers - just find the differences to update
    if (printer.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === printer.id
      );
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[index]
        )
          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingData[index][key], newData[index][key]) &&
              Object.keys(printer).includes(key)
          )
          // @ts-ignore
          .reduce((acc, key) => {
            if (key === "routedSubTypes") {
              return {
                ...acc,
                [key]: printer[key],
                allowAllRoutedSubTypes: printer.routedSubTypes.includes("all"),
              };
            }

            if (key === "routedOrderTypes") {
              return {
                ...acc,
                [key]: printer[key],
                allowAllRoutedOrderTypes:
                  printer.routedOrderTypes.includes("all"),
              };
            }

            // @ts-ignore
            return { ...acc, [key]: printer[key] };
          }, {});

        try {
          await PrintersCollection.doc(printer.id).update(dataToUpdate);
        } catch (err) {
          console.log("Failed to update: ", printer.id);
        }
      }
    } else {
      const docRef = PrintersCollection.doc();
      printer["id"] = docRef.id;
      const finalDoc = _.omit(printer, "index");

      console.log("FINAL PRINTER: ", printer);
      try {
        await docRef.set(finalDoc, { merge: true });
      } catch (err) {
        console.log(
          "Failed to update: ",
          printer.id,
          " ",
          (err as Error).message
        );
      }
    }
  }
};
