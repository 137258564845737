import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import camelcase from "camelcase";
import { camelCase, sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";

import { RootState } from "../../../../model/store";
import { updateBusinessSetting } from "../../../../models/businesses";
import "./LevelDialog.css";

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogHeaderTitle: string;
}

const CustomGroupDialog = ({
  isOpen,
  setIsOpen,
  dialogHeaderTitle,
}: DialogProps) => {
  const [customGroups, setCustomGroups] = useState<SubCollectionKey[]>([]);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  useEffect(() => {
    if (businessSettings && businessSettings.customGroups) {
      setCustomGroups(
        sortBy(
          Object.keys(businessSettings.customGroups).map(
            (key) => businessSettings.customGroups![key]
          ),
          "index"
        )
      );
    } else {
      setCustomGroups([]);
    }
  }, [businessSettings]);
  console.log({ customGroupsNewwww: customGroups });

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteOld = (level: SubCollectionKey, index: number) => {
    if (level.id) {
      const customGroupsTemp = [...customGroups].filter(
        (item) => item.id !== level.id
      );
      setCustomGroups(customGroupsTemp);
    } else {
      const customGroupsTemp = [...customGroups];
      customGroupsTemp.splice(index, 1);
      setCustomGroups(customGroupsTemp);
    }
  };

  const handleDeleteNew = (index: number) => {};

  const addNewLevel = () => {
    const newcustomGroup: SubCollectionKey = {
      id: "",
      title: "",
      deleted: false,
      description: "",
    };
    setCustomGroups([...customGroups, newcustomGroup]);
  };

  const handleDone = async () => {
    const businessSettingsLevels: any = {};
    customGroups.forEach((customGroup, index) => {
      if (customGroup.id) {
        businessSettingsLevels[customGroup.id] = {
          ...customGroup,
          index,
        };
      } else {
        const newLevelTemp = {
          ...customGroup,
          id: camelCase(customGroup.title),
        };
        businessSettingsLevels[newLevelTemp.id] = {
          ...newLevelTemp,
          index,
        };
      }
    });
    console.log({ businessSettingsLevels });
    await updateBusinessSetting(
      businessSettings.businessId,
      "customGroups",
      businessSettingsLevels
    );
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="level_dialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="level_dialog_title">{dialogHeaderTitle}</div>
            <div className="level_dialog_body">
              <ReactSortable list={customGroups} setList={setCustomGroups}>
                {customGroups.map((customGroup, customGroupIndex) => (
                  <div className="level_dialog_content" key={customGroup.id}>
                    <div className="level_dialog_index">
                      {customGroupIndex + 1}.
                    </div>
                    <div className="level_dialog_details">
                      <input
                        className="level_dialog_name"
                        value={customGroup.title}
                        onChange={(e) => {
                          const value = e.target.value;
                          const newCustomGroupsTemp = [...(customGroups || [])];
                          newCustomGroupsTemp[customGroupIndex].title = value;
                          setCustomGroups(newCustomGroupsTemp);
                        }}
                      />
                      <div>
                        <img
                          src={require("assets/drag-reordering.svg").default}
                          className="level_dialog_icon level_dialog_reordering_icon"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        handleDeleteOld(customGroup, customGroupIndex)
                      }
                    >
                      <img
                        src={require("assets/level-close.svg").default}
                        className="level_dialog_icon level_dialog_cross_icon"
                      />
                    </div>
                  </div>
                ))}
              </ReactSortable>

              <div className="level_dialog_footer">
                <button
                  type="button"
                  className="level_dialog_new_level_btn"
                  onClick={addNewLevel}
                >
                  New Custom Group
                </button>
                <button
                  type="button"
                  className="level_dialog_done_btn"
                  onClick={handleDone}
                >
                  Done
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomGroupDialog;
