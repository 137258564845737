import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import { ITipsByMenuReport } from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface TipsByMenuProps {
  report: ITipsByMenuReport;
  businessName: string;
  snapshot?: boolean;
}

interface ProductMixReportProps {}

const TipsByMenuDocument = (props: TipsByMenuProps) => {
  const data = props.report.data;
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} - ${moment(
        props.report.date
      )
        .add(1, "day")
        .format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Tips By Menu {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
        </View>
        <Table>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Menu Name
            </TableCell>
            {/*
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Type
            </TableCell>
          */}
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Tips
            </TableCell>
          </TableHeader>
        </Table>

        <Table
          data={data}
          style={{
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <TableBody>
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(p) => p.menuName}
            />
            {/*
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => `${p.type.toLocaleString()}`}
                />
                */}
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(p) => `$${(Math.round(p.tip) / 100).toFixed(2)}`}
            />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default TipsByMenuDocument;
