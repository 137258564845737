import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import _ from "lodash";
import React from "react";

import HorizontalTable from "components/Table/HorizontalTable";

import { useTeamTable } from "../../hooks/useTeamTable";
import CreateEmployeeModalFlow from "../CreateEmployeeModalFlow";

const { Button, TextField } = tangoComponents;

interface TeamTableHeaderProps {
  title: string;
  onArchiveClick: () => void;
  onAddNewEmployeeClick: () => void;
  onAddNewGroupClick: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onRefreshClick: () => void;
  loading: boolean;
}

const TeamTableHeader = (props: TeamTableHeaderProps) => {
  return (
    <>
      <div className={"flex items-center"}>

        <div
          onClick={props.onRefreshClick}
          className={"cursor-pointer text-xs font-lato-bold text-black mr-7"}
        >
          Refresh
        </div>

        {props.loading ? (
          <Spinner size={SpinnerSize.SMALL} className="mr-7" />
        ) : null}

        <div className={"h-9 w-px bg-grey-1 mr-7"} />
        {/* <Button
          disabled
          type="btn-style-2"
          size="btn-large"
          label="Add to Group"
          className="mr-4"
          onClick={props.onAddNewGroupClick}
        /> */}
        <Button
          type="btn-style-2"
          size="btn-large"
          label="Add New Employee"
          onClick={props.onAddNewEmployeeClick}
        />
        <TextField
          className="hori-table-search-input"
          value={props.searchValue}
          // @ts-ignore
          onChange={(e) => props.onSearchChange(e.target.value)}
          sizeType="medium"
          placeholder="Search"
        />
      </div>
    </>
  );
};

const TeamTable = () => {
  const [isCreateEmployeeFlowOpen, setIsCreateEmployeeFlowOpen] =
    React.useState(false);

  const {
    columns,
    teamTableData,
    onSearchChange,
    searchFilter,
    navigate,
    noOp,
    archiveEmployeesHandler,
    archiveEmployeeMutation
  } = useTeamTable();

  return (
    <div style={{ height: "93vh", overflowY: "scroll" }}>
      <CreateEmployeeModalFlow
        isOpen={isCreateEmployeeFlowOpen}
        onClose={() => setIsCreateEmployeeFlowOpen(false)}
      />
      <HorizontalTable
        title="Team"
        columns={columns}
        data={teamTableData.data}
        instructions={{}}
        isEditing={false}
        errorTag="error"
        subTitle="test"
        onRowClick={navigate}
        hideEdit
        displayDeleteToTheLeftOfCustomContent
        deleteText="Archive"
        deleteIds={archiveEmployeesHandler}
        customHeaderLeftContent={
          <TeamTableHeader
            title="Team"
            onArchiveClick={noOp}
            onAddNewEmployeeClick={() => setIsCreateEmployeeFlowOpen(true)}
            onAddNewGroupClick={noOp}
            searchValue={searchFilter}
            onSearchChange={onSearchChange}
            loading={teamTableData.isFetching || archiveEmployeeMutation.isLoading}
            onRefreshClick={() => teamTableData.refetch()}
          />
        }
      />
    </div>
  );
};

export default TeamTable;
