import ActionType from "./actionTypes";

export function receiveFixedAvailabilities(
  fixedAvailabilities: FixedAvailability[]
) {
  return {
    type: ActionType.RECEIVE_FIXED_AVAILABILITIES,
    fixedAvailabilities,
  };
}
