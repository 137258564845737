import {
  Button,
  Icon,
  InputGroup,
  Intent,
  Position,
  Toaster,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import firebase from "config/firebase";
import { default as _, default as lodash } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { getAllJobFunctions } from "model/selectors/teamsTable";

import ArchivedFolderIcon from "assets/archived-folder.svg";

import Box from "../../../../components/Box";
import { RootState } from "../../../../model/store";
import { getAllBusinessesByAccountId } from "../teamFirebaseCalls";
import "./index.css";
import AddStatusModal from "./modal/AddStatus";
import EditStatusModal from "./modal/StatusEditModal";
import ViewApplicationModal from "./modal/ViewApplicationModal";
import RenderJobs from "./renderJobs";

interface StatusHeaders {
  colorCode: string;
  name: string;
  headerId: string;
}

interface FormatedData {
  title: string;
  items: any;
  colorCode?: string;
  headerId?: string;
  index?: number;
}

const ApplicationTracker = () => {
  const db = firebase.firestore();
  const navigate = useNavigate();
  let toaster: Toaster;
  const refHandlers = {
    toaster: (ref: Toaster) => (toaster = ref),
  };

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const jobFunc = useSelector(getAllJobFunctions);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const tangoSettingsJobFunctions: { [T: string]: string } = jobFunc
    ? Object.keys(jobFunc).reduce(
        (acc, key) => ({
          ...acc,
          [jobFunc[key].title]: jobFunc[key].id,
        }),
        {}
      )
    : {};
  const businessSettingsJobFunctions: { [T: string]: string } =
    businessSettings && businessSettings.jobFunctions
      ? Object.keys(businessSettings.jobFunctions).reduce(
          (acc, key) => ({
            ...acc,
            [businessSettings.jobFunctions[key].title]:
              businessSettings.jobFunctions[key].id,
          }),
          {}
        )
      : {};
  const jobFunctions: string[] = _.union(
    Object.keys(tangoSettingsJobFunctions),
    Object.keys(businessSettingsJobFunctions)
  );
  const usersLocations = useSelector((state: RootState) => state.locations);

  const [employeeApplicationsData, setEmployeeApplicationsData]: any =
    useState(); //data from firebase
  const [
    normalemployeeApplicationsData,
    setnormalEmployeeApplicationsData,
  ]: any = useState(); // ungrouped data
  const [employeeApplicationsStatus, setEmployeeApplicationsStatus] = useState<
    TangoEmployeeStatus[]
  >([]); //filltered deleted data from firebase
  const [employeeRawStatuses, setEmployeeRawStatus]: any = useState([]);
  const [statusHeader, setStatusHeader] = useState<StatusHeaders[]>([]);
  const [ShowAddStatusModal, setShowAddStatusModal] = useState<boolean>(false);
  const [ShowEditStatusModal, setShowEditStatusModal] =
    useState<boolean>(false);
  const [EditStatusModalType, setEditStatusModalType] = useState<
    "color" | "name"
  >("color");
  const [showApplicationModal, setShowApplicationModal] =
    useState<boolean>(false);
  const [background, setBackground] = useState<string>("#FFFFFF");
  const [selectedOption, setSelectedOption] = useState<"applications" | "jobs">(
    "jobs"
  );
  const [formatedData, setFormatedData] = useState<FormatedData[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [formatedFilteredData, setFormatedFilteredData] =
    useState<FormatedData[]>();
  const [dragging, setDragging] = useState<boolean>(false);
  const [draggingBoard, setDraggingBoard] = useState<boolean>(false);
  const [dataDraggable, SetDataDraggable] = useState<boolean>(false);
  const [draggedItem, SetDraggedItem]: any = useState();
  const [draggedItemDivName, setDraggedItemDivName] = useState<string>("");
  const [draggedItemDivIndex, setDraggedItemDivIndex] = useState<
    string | number
  >("");
  const [applicationModalData, setApplicationModalData] = useState();
  const [locations, setLocations] = useState<TangoBusiness[]>();
  const [selectedStatushData, setSelectedStatushData]: any = useState();
  const [selectedDepartment, setSelectedDepartment] = useState<
    "foh" | "boh" | string
  >("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [selectedLocations, setSelectedLocations] = useState<string>("");
  const [rolesForSidebar, setRolesForSidebar] = useState<string[]>([]);
  const handleColorChange = (color: string) => {
    setBackground(color);
  };

  const getEmployeeApplications = () => {
    const unsubscribe = db
      .collection("EmployeeApplications")
      .where("businessId", "==", business.id)
      .onSnapshot((snapshot) => {
        let EmployeeApplications: any = [];
        snapshot.forEach((doc) => {
          EmployeeApplications = [...EmployeeApplications, doc.data()];
        });

        if (EmployeeApplications.length > 0 && jobFunc) {
          EmployeeApplications.map((row: any, key: any) => {
            EmployeeApplications[key].department =
              row.primaryRole.length > 0 &&
              jobFunc[row.primaryRole.toLowerCase()]
                ? jobFunc[row.primaryRole.toLowerCase()]
                : "";
          });
        }
        console.log("EmployeeApplications", EmployeeApplications);
        const filterDeletedData = EmployeeApplications.filter(
          (item: any) => item.deleted == false
        );
        setnormalEmployeeApplicationsData(filterDeletedData);
        const groupedByRolesApplications = lodash.groupBy(
          filterDeletedData,
          (item) => item.jobTitle
        );
        const rolesFromApplication = Object.keys(
          groupedByRolesApplications
        ).filter((item) => {
          if (item !== "undefined") {
            return item;
          }
        });
        setRolesForSidebar(rolesFromApplication);
        const groupedData = lodash.groupBy(filterDeletedData, "status");
        setEmployeeApplicationsData(groupedData);
      });

    return () => unsubscribe();
  };

  const addNewStatus = (name: string, color: string) => {
    const payload = {
      id: null, // uniq id of each status
      businessId: business.id, // to make sure that we don't mix status of all the business
      createAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      name: name, // name of the status
      colorCode: color, // hex color code of the status
      index: employeeApplicationsStatus?.length + 1, // to sort the whole board
    };

    if (business) {
      return db
        .collection("EmployeeApplicationStatuses")
        .add(payload)
        .then((docRef: any) => {
          db.collection("EmployeeApplicationStatuses")
            .doc(docRef?.id)
            .update({ id: docRef?.id });
          setShowAddStatusModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const deleteStatus = (id: string | undefined, title: string) => {
    const index = formatedData.findIndex((item: any) => item.title === title);
    if (formatedData[index].items.length > 0) {
      return toaster.show({
        message: "Status is not deletable because It contains Applications",
        intent: Intent.DANGER,
      });
    } else {
      return db
        .collection("EmployeeApplicationStatuses")
        .doc(id)
        .update({ deleted: true });
    }
  };

  const deleteApplication = (id: string) => {
    return db
      .collection("EmployeeApplications")
      .doc(id)
      .update({ deleted: true });
  };

  const UpdateCards = async (id: any, name: string, index: number | string) => {
    if (name.length > 0 && index != null) {
      return await db.collection("EmployeeApplications").doc(id).update({
        index: index?.toString(),
        status: name?.toString(),
      });
    }
  };

  const UpdateBoards = () => {
    const statuses = employeeRawStatuses.sort(function (a: any, b: any) {
      return a.index - b.index;
    });
    console.log(statuses);
  };

  const getNewStatus = () => {
    const unsubscribe = db
      .collection("EmployeeApplicationStatuses")
      .where("businessId", "==", business.id)
      .onSnapshot((snapshot) => {
        let EmployeeApplicationsStatus: any = [];
        snapshot.forEach((doc) => {
          EmployeeApplicationsStatus = [
            ...EmployeeApplicationsStatus,
            doc.data(),
          ];
        });
        console.log(
          "EmployeeApplicationsStatus",
          EmployeeApplicationsStatus.filter(
            (item: any) => item.deleted === false
          )
        );
        setEmployeeApplicationsStatus(EmployeeApplicationsStatus);
        setEmployeeRawStatus(EmployeeApplicationsStatus);
        const headerData: any = [];

        const filteredStatuses = EmployeeApplicationsStatus.filter(
          (i: any) => i.deleted === false
        ).map((i: any) => {
          headerData.push({
            name: i.name,
            colorCode: i.colorCode,
            headerId: i.id,
            index: i.index,
          });
        });
        console.log("headerData", headerData);
        setStatusHeader(headerData);
      });
    return () => unsubscribe();
  };

  const getLocations = async () => {
    const locations = await getAllBusinessesByAccountId(business.accountId);
    setLocations(locations);
  };

  const formatDraggableData = (
    employeeApplicationsData: any,
    statusHeader: any
  ) => {
    const data: any = [];

    data.push({
      title: "Applied",
      items: employeeApplicationsData["Applied"]
        ? employeeApplicationsData["Applied"]?.map((i: any) => i)
        : [],
      button: {
        name: "View Application",
        color: "#4385EF",
      },
      action: "ViewApplication",
    });

    statusHeader.map((item: any) => {
      data.push({
        title: item.name,
        items: employeeApplicationsData[item.name]
          ? employeeApplicationsData[item.name]?.map((i: any) => i)
          : [],
        colorCode: item.colorCode,
        headerId: item.headerId,
        button: {
          name: "View Application",
          color: "#4385EF",
        },
        action: "ViewApplication",
        index: item.index,
      });
    });

    data.push(
      {
        title: "Hire",
        items: employeeApplicationsData["Hire"]
          ? employeeApplicationsData["Hire"]?.map((i: any) => i)
          : [],
        colorCode: "#43EFB1",
        button: {
          name: "Start Hiring Process",
          color: "#4385EF",
        },
        action: "StartHiring",
      },
      {
        title: "No Hire",
        items: employeeApplicationsData["No Hire"]
          ? employeeApplicationsData["No Hire"]?.map((i: any) => i)
          : [],
        colorCode: "#EF4377",
        button: {
          name: "Archive Application",
          color: "#DE8839",
        },
        action: "ArchiveApplication",
      }
    );

    setFormatedData(data);
    //console.log("formated data",data)
    SetDataDraggable(true);
  };

  const handleDragEnd = async (e: any) => {
    setDragging(false);
    const name = await localStorage.getItem("draggedItemDivName");
    const index = await localStorage.getItem("draggedItemDivIndex");
    const id = await localStorage.getItem("draggedItemid");

    if (index && name && id) {
      UpdateCards(JSON.parse(id), JSON.parse(name), JSON.parse(index));
    }
    dragItem.current = null;
    dragItemNode.current.removeEventListener("dragend", handleDragEnd);
    dragItemNode.current = null;
  };

  const dragItem: any = useRef();
  const dragItemNode: any = useRef();
  const dragItemBoard: any = useRef();
  const dragItemBoardOver: any = useRef();
  let dragItemBoardIndex: any = null;
  let fromStatusIndex: any = null;
  let toStatusIndex: any = null;

  const handletDragStart = (e: any, item: any) => {
    if (isFiltered || isSearching) {
      if (formatedFilteredData) {
        SetDraggedItem(formatedFilteredData[item.grpI].items[item.itemI]);
      }
    }
    SetDraggedItem(formatedData[item.grpI].items[item.itemI]);

    dragItem.current = item;
    dragItemNode.current = e.target;
    dragItemNode.current.addEventListener("dragend", handleDragEnd);
    setTimeout(() => {
      setDragging(true);
    });
  };

  const handleDragEnter = (e: any, targetItem: any) => {
    if (dragging) {
      if (dragItemNode.current !== e.target) {
        if (isFiltered || isSearching) {
          if (formatedFilteredData) {
            setFormatedFilteredData((oldList: any) => {
              const newList = JSON.parse(JSON.stringify(oldList));
              newList[targetItem.grpI]?.items?.splice(
                targetItem.itemI,
                0,
                newList[dragItem.current.grpI].items.splice(
                  dragItem.current.itemI,
                  1
                )[0]
              );
              dragItem.current = targetItem;

              if (draggedItem) {
                const index = newList[targetItem.grpI].items.findIndex(
                  (item: any) => item?.id == draggedItem?.id
                );
                const name = newList[targetItem.grpI].title;

                if (index !== -1) {
                  setDraggedItemDivName(name);
                  setDraggedItemDivIndex(
                    formatedData[targetItem.grpI].items.length - 1
                  );
                  localStorage.setItem(
                    "draggedItemDivName",
                    JSON.stringify(draggedItemDivName)
                  );
                  localStorage.setItem(
                    "draggedItemDivIndex",
                    JSON.stringify(draggedItemDivIndex)
                  );
                  localStorage.setItem(
                    "draggedItemid",
                    JSON.stringify(draggedItem.id)
                  );
                }
              }

              localStorage.setItem("List", JSON.stringify(newList));
              return newList;
            });
          }
        } else {
          setFormatedData((oldList: any) => {
            const newList = JSON.parse(JSON.stringify(oldList));
            newList[targetItem.grpI]?.items?.splice(
              targetItem.itemI,
              0,
              newList[dragItem.current.grpI].items.splice(
                dragItem.current.itemI,
                1
              )[0]
            );
            dragItem.current = targetItem;

            if (draggedItem) {
              const index = newList[targetItem.grpI].items.findIndex(
                (item: any) => item?.id == draggedItem?.id
              );
              const name = newList[targetItem.grpI].title;

              if (index !== -1) {
                setDraggedItemDivName(name);
                setDraggedItemDivIndex(
                  newList[targetItem.grpI].items.length - 1
                );
                localStorage.setItem(
                  "draggedItemDivName",
                  JSON.stringify(draggedItemDivName)
                );
                localStorage.setItem(
                  "draggedItemDivIndex",
                  JSON.stringify(draggedItemDivIndex)
                );
                localStorage.setItem(
                  "draggedItemid",
                  JSON.stringify(draggedItem.id)
                );
              }
            }

            localStorage.setItem("List", JSON.stringify(newList));
            return newList;
          });
        }
      }
    }
  };

  const onDragStartBoard = (e: any, item: any) => {
    //e.preventDefault();
    dragItemBoard.current = e.target;
    console.log("item in drag start", item.index);
    fromStatusIndex = item.index;
    console.log("dragItemBoard", dragItemBoard);
    console.log("dragged.dataset", dragItemBoard.current.dataset);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", dragItemBoard.current);
    setDraggingBoard(true);
  };

  const onDragEnterBoard = (e: any, to: any) => {
    if (dragItemBoardIndex != to) {
      dragItemBoardIndex = to;
      toStatusIndex = formatedData[to];
      console.log("formatedData[to]", formatedData[to]);
    }
  };

  const onDragEndBoard = (e: any) => {
    console.log("dragged.dataset");
    const data = formatedData;
    const from = Number(dragItemBoard.current.dataset.id);
    let to = dragItemBoardIndex;
    console.log("From: ", {
      dragItemBoard,
      from,
    });

    console.log("To: ", {
      dragItemBoardIndex,
    });
    //setFormatedData(data);

    if (from && to) {
      if (checkboardIndex(from) == false && checkboardIndex(to) == false) {
        setFormatedData((oldList: any) => {
          const newList = JSON.parse(JSON.stringify(oldList));
          newList.splice(to, 0, newList.splice(from, 1)[0]);
          return newList;
        });
      }
    }

    dragItemBoard.current = null;
    dragItemBoardOver.current = null;
    to = null;
    setDraggingBoard(false);
  };

  const onDragOverBoard = (e: any) => {
    // e.preventDefault();
    if (dragItemBoardOver.current != e.target) {
      dragItemBoardOver.current = e.target;
    }
    //console.log("dragItemBoardOver.current.dataset.id",dragItemBoardOver.current.dataset.id)
    e.preventDefault();
  };

  const renderButtonAction = (action: string, data: any) => {
    switch (action) {
      case "ViewApplication":
        setApplicationModalData(data);
        setShowApplicationModal(true);
        break;
      case "StartHiring":
        navigate("/add-employee");
        break;
      case "ArchiveApplication":
        deleteApplication(data.id);
        break;
    }
  };

  const checkboardIndex = (index: number) => {
    const value = [0, formatedData.length - 2, formatedData.length - 1];
    return value.includes(index);
  };

  useEffect(() => {
    if (business) {
      console.log("businessId", business.id);
      // setSelectedLocations(business.id);
      getEmployeeApplications();
      getNewStatus();
      getLocations();
    }
  }, [business, jobFunc]),
    useEffect(() => {
      if (employeeApplicationsData && statusHeader) {
        formatDraggableData(employeeApplicationsData, statusHeader);
      }
    }, [employeeApplicationsData, statusHeader]);

  useEffect(() => {
    if (selectedRole.length > 0) {
      const data: any = formatedData.map((item) => ({ ...item }));
      for (let i = 0; i < data.length; i++) {
        data[i].items = formatedData[i].items.filter((item: any) =>
          item.jobTitle
            ? item.jobTitle.toLowerCase()
            : null === selectedRole.toLowerCase()
        );
      }
      console.log("in selectedrole useEffect");
      setFormatedFilteredData(data);
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [selectedRole]);

  useEffect(() => {
    console.log("locations", locations);
  }, [locations]);

  useEffect(() => {
    if (searchText.length > 0) {
      const data: any = formatedData.map((item) => ({ ...item }));
      console.log("data", data);
      for (let i = 0; i < data.length; i++) {
        data[i].items = formatedData[i].items.filter(
          (item: any) =>
            item.name.includes(searchText) ||
            item.email.includes(searchText) ||
            item.phone.includes(searchText)
        );
      }
      setIsSearching(true);
      // console.log("data",data)
      // console.log("in searchText useEffect")
      setFormatedFilteredData(data);
    } else if (searchText.length == 0) {
      setIsSearching(false);
      setFormatedFilteredData([]);
    }
    console.log(
      "searchText.length",
      searchText.length,
      "searching=>",
      formatedData
    );
  }, [searchText]);

  useEffect(() => {
    if (selectedLocations.length > 0) {
      const data: any = formatedData.map((item) => ({ ...item }));
      console.log("data", data);
      for (let i = 0; i < data.length; i++) {
        data[i].items = formatedData[i].items.filter(
          (item: any) => item.businessId == selectedLocations
        );
      }
      setIsSearching(true);
      setFormatedFilteredData(data);
    } else if (selectedLocations.length == 0) {
      setIsSearching(false);
      setFormatedFilteredData([]);
    }
  }, [selectedLocations]);

  useEffect(() => {
    console.log("jobFunc", jobFunc);
  }, [jobFunc]);

  useEffect(() => {
    if (selectedDepartment.length > 0) {
      const data: any = formatedData.map((item) => ({ ...item }));
      //console.log("data",data)
      for (let i = 0; i < data.length; i++) {
        data[i].items = formatedData[i].items.filter(
          (item: any) => item.department.departmentId == selectedDepartment
        );
      }
      setIsSearching(true);
      setFormatedFilteredData(data);
    } else if (searchText.length == 0) {
      setIsSearching(false);
      setFormatedFilteredData([]);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    console.log("employeeRawStatuses", employeeRawStatuses);
    UpdateBoards();
  }, [employeeRawStatuses]);

  const renderJobs = () => (
    <RenderJobs
      businessId={business.id}
      employeeApplications={normalemployeeApplicationsData}
      setSelectedOption={setSelectedOption}
      setSelectedRole={setSelectedRole}
      selectedRole={selectedRole}
      selectedLocation={selectedLocations}
    />
  );

  const renderBoard = () => {
    return (
      <div className="cards-board" onDragOver={onDragOverBoard}>
        {!isFiltered === false || !isSearching === false
          ? formatedFilteredData &&
            formatedFilteredData.map((grp: any, grpI: any) => (
              <div
                key={grp.title}
                className="cards-column"
                onDragOver={onDragOverBoard}
                onDragEnter={(e) => {
                  if (dragging && !grp?.items?.length) {
                    handleDragEnter(e, {
                      grpI,
                      itemI: 0,
                    });
                  }
                }}
                onDrop={(e) => e.preventDefault()}
              >
                <Box
                  className="column-header"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className="column-header-border"
                    style={{
                      backgroundColor: grp.colorCode
                        ? grp.colorCode
                        : "#43EF4A",
                    }}
                  ></div>
                  <div>{grp.title}</div>
                  {grp.headerId ? (
                    <Popover2
                      content={
                        <div className="action-popover">
                          <div className="popover-title">Actions</div>
                          <div
                            className="popover-content"
                            onClick={() => {
                              setEditStatusModalType("color");
                              setShowEditStatusModal(true);
                            }}
                          >
                            <img
                              src={require("assets/themeIcon.svg").default}
                            />
                            Change Color
                          </div>
                          <div
                            className="popover-content"
                            onClick={() => {
                              setEditStatusModalType("name");
                              setSelectedStatushData({
                                id: grp.headerId,
                                title: grp.title,
                              });
                              setShowEditStatusModal(true);
                            }}
                          >
                            <img
                              src={require("assets/renameIcon.svg").default}
                            />
                            Rename Status
                          </div>
                          <div
                            className="popover-content deleteicon"
                            onClick={() => {
                              deleteStatus(grp.headerId, grp.title);
                            }}
                          >
                            <img
                              src={require("assets/deleteIcon.svg").default}
                            />
                            Delete Status
                          </div>
                        </div>
                      }
                      position={Position.BOTTOM}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon icon="dot" color={grp?.colorCode} iconSize={19} />
                        <img
                          src={require("assets/more-icon.svg").default}
                          className="more-icon"
                        />
                      </Box>
                    </Popover2>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                    >
                      <Icon
                        icon="dot"
                        color={grp.colorCode ? grp.colorCode : "#43EF4A"}
                        iconSize={19}
                      />
                    </Box>
                  )}
                </Box>
                {
                  //@ts-ignore
                  grp &&
                    grp.items.length > 0 &&
                    grp.items
                      // @ts-ignore
                      .sort((a, b) => a.index - b.index)
                      .map((i: any, itemI: any) => {
                        return (
                          <div
                            className="application-card"
                            key={i}
                            onDragStart={(e) =>
                              handletDragStart(e, {
                                grpI,
                                itemI,
                              })
                            }
                            onDragEnter={(e: any) => {
                              handleDragEnter(e, {
                                grpI,
                                itemI,
                              });
                            }}
                            onDrop={() => console.log("item dropped")}
                          >
                            <Box
                              className="application-header"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <div>{i.name}</div>
                              <Popover2
                                content={
                                  <div className="action-popover">
                                    <div className="popover-title">Actions</div>
                                    <div
                                      className="popover-content deleteicon"
                                      onClick={() => {
                                        deleteApplication(i.id);
                                      }}
                                    >
                                      <img
                                        src={
                                          require("assets/deleteIcon.svg")
                                            .default
                                        }
                                      />
                                      Delete Application
                                    </div>
                                  </div>
                                }
                                position={Position.BOTTOM}
                              >
                                <img
                                  src={require("assets/more-icon.svg").default}
                                  className="more-icon"
                                />
                              </Popover2>
                            </Box>
                            <div className="application-detail">
                              <div>Job Title</div>
                              <div>
                                {i?.jobTitle?.length > 0
                                  ? i?.jobTitle
                                  : "Not Available"}
                              </div>
                            </div>

                            <div className="application-detail">
                              <div>Location</div>
                              <div>
                                {i.location?.name
                                  ? i.location.name
                                  : "Not Available"}
                              </div>
                            </div>
                            <div
                              className="application-footer"
                              style={{ color: grp.button.color }}
                              onClick={() => {
                                setApplicationModalData(i);
                                setShowApplicationModal(true);
                              }}
                            >
                              {grp.button.name}
                            </div>
                          </div>
                        );
                      })
                }
              </div>
            ))
          : formatedData &&
            formatedData.map((grp: any, grpI: any) => (
              <div
                key={grpI}
                data-id={grpI}
                className="cards-column"
                draggable={checkboardIndex(grpI) == true ? false : true}
                onDragEnter={(e) => {
                  if (dragging && !grp?.items?.length) {
                    handleDragEnter(e, {
                      grpI,
                      itemI: 0,
                    });
                  }
                  if (draggingBoard) {
                    onDragEnterBoard(e, grpI);
                  }
                }}
                onDragEnd={onDragEndBoard}
                onDragStart={(e: any) => onDragStartBoard(e, grp)}
                onDragOver={onDragOverBoard}
                onDrop={() => console.log("item dropped")}
              >
                <Box
                  className="column-header"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className="column-header-border"
                    style={{
                      backgroundColor: grp.colorCode
                        ? grp.colorCode
                        : "#43EF4A",
                    }}
                  ></div>
                  <div>{grp.title}</div>
                  {grp.headerId ? (
                    <Popover2
                      content={
                        <div className="action-popover">
                          <div className="popover-title">Actions</div>
                          <div
                            className="popover-content"
                            onClick={() => {
                              setEditStatusModalType("color");
                              setShowEditStatusModal(true);
                            }}
                          >
                            <img
                              src={require("assets/themeIcon.svg").default}
                            />
                            Change Color
                          </div>
                          <div
                            className="popover-content"
                            onClick={() => {
                              setEditStatusModalType("name");
                              setSelectedStatushData({
                                id: grp.headerId,
                                title: grp.title,
                              });
                              setShowEditStatusModal(true);
                            }}
                          >
                            <img
                              src={require("assets/renameIcon.svg").default}
                            />
                            Rename Status
                          </div>
                          <div
                            className="popover-content deleteicon"
                            onClick={() => {
                              deleteStatus(grp.headerId, grp.title);
                            }}
                          >
                            <img
                              src={require("assets/deleteIcon.svg").default}
                            />
                            Delete Status
                          </div>
                        </div>
                      }
                      position={Position.BOTTOM}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon icon="dot" color={grp?.colorCode} iconSize={19} />
                        <img
                          src={require("assets/more-icon.svg").default}
                          className="more-icon"
                        />
                      </Box>
                    </Popover2>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                    >
                      <Icon
                        icon="dot"
                        color={grp.colorCode ? grp.colorCode : "#43EF4A"}
                        iconSize={19}
                      />
                    </Box>
                  )}
                </Box>
                {
                  //@ts-ignore
                  grp &&
                    grp.items.length > 0 &&
                    grp.items
                      // @ts-ignore
                      .sort((a, b) => a.index - b.index)
                      .map((i: any, itemI: any) => {
                        return (
                          <div
                            draggable
                            className="application-card"
                            key={i}
                            onDragOver={(e) => e.preventDefault()}
                            onDragStart={(e) =>
                              handletDragStart(e, {
                                grpI,
                                itemI,
                              })
                            }
                            onDragEnter={(e: any) => {
                              handleDragEnter(e, {
                                grpI,
                                itemI,
                              });
                            }}
                            onDrop={() => console.log("item dropped")}
                          >
                            <Box
                              className="application-header"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <div>{i.name}</div>
                              <Popover2
                                content={
                                  <div className="action-popover">
                                    <div className="popover-title">Actions</div>
                                    <div
                                      className="popover-content deleteicon"
                                      onClick={() => {
                                        deleteApplication(i.id);
                                      }}
                                    >
                                      <img
                                        src={
                                          require("assets/deleteIcon.svg")
                                            .default
                                        }
                                      />
                                      Delete Application
                                    </div>
                                  </div>
                                }
                                position={Position.BOTTOM}
                              >
                                <img
                                  src={require("assets/more-icon.svg").default}
                                  className="more-icon"
                                />
                              </Popover2>
                            </Box>

                            {/* { i && i?.responses?.map((section:any)=>{
                         if(typeof(section.answer) == "object") return;
                         return(
                         <div className="application-detail">
                         <div>{section?.question}</div>
                         <div>{section?.answer}</div>
                         </div>
                         )
                         })} */}

                            <div className="application-detail">
                              <div>Job Title</div>
                              <div>
                                {i?.jobTitle?.length > 0
                                  ? i?.jobTitle
                                  : "Not Available"}
                              </div>
                            </div>

                            <div className="application-detail">
                              <div>Location</div>
                              <div>
                                {i.location?.name
                                  ? i.location.name
                                  : "Not Available"}
                              </div>
                            </div>

                            <div
                              className="application-footer"
                              style={{ color: grp.button.color }}
                              onClick={() => {
                                renderButtonAction(grp.action, i);
                              }}
                            >
                              {grp.button.name}
                            </div>
                          </div>
                        );
                      })
                }
              </div>
            ))}

        <div className="cards-column">
          <Box
            className="column-header-Add-status"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div className="column-header-border add-status-card-border"></div>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={() => setShowAddStatusModal(true)}
              >
                <div>Add New Status</div>
                <Icon
                  icon="plus"
                  color="#5143EF"
                  iconSize={10}
                  style={{ marginLeft: "5px" }}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    );
  };

  return (
    <div id="application-tracker">
      <div className="small-title">Team</div>
      <div className="big-title">Hiring</div>
      <Box className="back-button">
        <Icon icon="arrow-left" color="#7C7F93" />
        Back to dashboard
      </Box>
      <Box
        className={`application-tracker-page ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        flex={1}
        style={{
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {/*sidebar*/}
        <div className="sidebar-container">
          <div className="sidebar-toggle-button">
            <div
              className={`toggle-button ${
                selectedOption === "jobs" ? "active" : ""
              }`}
              onClick={() => setSelectedOption("jobs")}
            >
              Jobs
            </div>
          </div>

          <div className="sidebar">
            <div className="sidebar-title">Action</div>
            {selectedOption === "jobs" ? (
              <div className="sidebar-section">
                <Button
                  text="Post a Job"
                  rightIcon={<Icon icon="plus" color="#FFFFFF" />}
                  onClick={() => navigate("/create-job-post")}
                  className="create-new-teammate"
                  fill
                />
              </div>
            ) : null}

            <div className="sidebar-section">
              <InputGroup
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search"
                leftIcon="search"
                intent="none"
              />
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Type:</div>
              <ul>
                <li
                  className={selectedDepartment === "boh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "boh") {
                      setSelectedDepartment("boh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  BOH
                </li>
                <li
                  className={selectedDepartment === "foh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "foh") {
                      setSelectedDepartment("foh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  FOH
                </li>
                <li onClick={() => setSelectedDepartment("")}>Other</li>
              </ul>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Role:</div>
              <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select a role</option>
                {rolesForSidebar.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Location:</div>
              <select onChange={(e) => setSelectedLocations(e.target.value)}>
                {(usersLocations || []).length > 0 ? (
                  usersLocations.map((item, index) => (
                    <option
                      key={index}
                      value={item.id}
                      selected={(item.id || business.id) === selectedLocations}
                    >
                      {item.businessName}
                    </option>
                  ))
                ) : business ? (
                  <option key={0} value={business.id}>
                    {business.businessName}
                  </option>
                ) : null}
              </select>
            </div>
          </div>
          {selectedOption === "applications" ? (
            <Link to="/manager/view-archives">
              <Box className="view-archive">
                <img src={ArchivedFolderIcon} />
                View Archived Candidates
              </Box>
            </Link>
          ) : (
            <Link
              to="/manager/view-archived-jobs"
              state={{
                applications: normalemployeeApplicationsData,
                businessId: business.id,
              }}
            >
              <Box className="view-archive">
                <img src={ArchivedFolderIcon} />
                View Archived Jobs
              </Box>
            </Link>
          )}
        </div>
        {/*sidebar*/}
        {selectedOption === "applications" ? renderBoard() : renderJobs()}

        <AddStatusModal
          isOpen={ShowAddStatusModal}
          onClose={() => setShowAddStatusModal(false)}
          selectedColor={background}
          changeSelectedColor={handleColorChange}
          addNewStatus={addNewStatus}
        />

        <EditStatusModal
          isOpen={ShowEditStatusModal}
          onClose={() => setShowEditStatusModal(false)}
          editType={EditStatusModalType}
          selectedStatushData={selectedStatushData}
          businessId={business?.id}
        />

        <ViewApplicationModal
          isOpen={showApplicationModal}
          closeModal={() => setShowApplicationModal(false)}
          data={applicationModalData}
          statusHeaders={statusHeader}
          formatedData={formatedData}
        />

        <Toaster position={Position.BOTTOM_RIGHT} ref={refHandlers.toaster} />
      </Box>
    </div>
  );
};
export default ApplicationTracker;
