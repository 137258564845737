import { CustomerAmount } from 'controllers/payments';
import moment from 'moment';

export const getTimestampCharged = (timestampCharged: any, tabUpdatedAt: any, stripeTimestamp: any) => {
  //default to tab updated at
  let time = moment(new Date(tabUpdatedAt._seconds * 1000)).format('MM/DD/YYYY  h:mm A');

  //if no tango timestamp use the stripe timestamp
  if (!timestampCharged && stripeTimestamp) {
    //if unix
    if (parseInt(stripeTimestamp)) {
      time = moment.unix(stripeTimestamp).format('MM/DD/YYYY h:mm A');
    } else {
      time = moment(stripeTimestamp).format('MM/DD/YYYY h:mm A');
    }
  }

  //format the actual timstamp charged (usually firebase format but could be other format - we should make sure its consistent in db)
  if (timestampCharged && timestampCharged._seconds) {
    const converted = new Date(timestampCharged._seconds * 1000);
    time = moment(converted).format('MM/DD/YYYY h:mm A');
  } else {
    time = moment(timestampCharged).format('MM/DD/YYYY h:mm A');
  }
  return time;
};

export const getPaymentStatus = (customerAmount: CustomerAmount, paymentAmount: number) => {
  if (customerAmount.refundedAmount > 0 && customerAmount.refundedAmount < paymentAmount) {
    return 'Partially Refunded';
  }
  if (customerAmount.refundedAmount > 0 && customerAmount.refundedAmount >= paymentAmount) {
    return 'Fully Refunded';
  }
  //TO DO - fetch disputes from firestore and see if charge id is disputed
  //Could also update the query that fetches completed tabs to include dispute data - for now disputes are in disputes table
  return 'Succeeded';
};
