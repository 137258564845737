import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DiscountsTable from "views/admin/menus&Products/discounts/DiscountsTable";
import { FirebaseDiscountDoc } from "views/admin/menus&Products/discounts/discountTypes";

import { FirebaseMenuDoc } from "types/menus";

import { RootState } from "model/store";

import Layout from "./Layout";

const Discounts = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const discounts: FirebaseDiscountDoc[] = useSelector(
    (state: RootState) => state.discounts
  );
  const products: FirebaseProductDoc[] = useSelector(
    (state: RootState) => state.products
  );
  const menus: FirebaseMenuDoc[] = useSelector(
    (state: RootState) => state.menus
  );
  const menuCategories: FirebaseMenuCategoryDoc[] = useSelector(
    (state: RootState) => state.menuCategories
  );

  // Hack to update discounts
  const [allDiscounts, setAllDiscounts] = useState<FirebaseDiscountDoc[]>([]);
  const [updateDiscounts, setUpdateDiscounts] = useState(false);

  useEffect(() => {
    if (discounts.length > 0) {
      setAllDiscounts(discounts);
      setUpdateDiscounts(true);
    }
  }, [discounts]);

  useEffect(() => {
    if (updateDiscounts) {
      setUpdateDiscounts(false);
    }
  }, [updateDiscounts]);

  if (business && !updateDiscounts) {
    return (
      <Layout>
        <DiscountsTable
          business={business}
          discounts={allDiscounts}
          products={products}
          menus={menus}
          menuCategories={menuCategories}
        />
      </Layout>
    );
  }
  return null;
};

export default Discounts;
