import UIComponents from "@tangopay/tango-ui-library";
import { fetchBusinessSettingsByBusinessIds } from "controllers/accounts";
import moment from "moment";
import React, { useState } from "react";
import { RatingFilterType } from "./useReviews";

interface Props {
  updateRatingFilter: (newValue: RatingFilterType) => void;
  updateReviewTagsFilter: (newValue: string) => void;
  updateReviewSiteFilter: (newValue: string) => void;
  updateReviewDateFilter: (newValue: string) => void;
  updateReviewLocationFilter: (newValue: string, businessSettingsForSelectedLocation: any) => void;
  selectedRatingFilter: RatingFilterType;
  selectedReviewSiteFilter: string;
  selectedReviewTagsFilter: string;
  selectedDateFilter: string;
  selectedReviewLocationFilter: string;
  reviewSites: string[];
  reviewTags: string[];
  businesses: TangoBusiness[];
}

const Filters = ({
  selectedDateFilter,
  selectedReviewLocationFilter,
  updateRatingFilter,
  selectedRatingFilter,
  reviewSites,
  selectedReviewTagsFilter,
  selectedReviewSiteFilter,
  updateReviewLocationFilter,
  updateReviewSiteFilter,
  updateReviewDateFilter,
  updateReviewTagsFilter,
  businesses
}: Props) => {

  return (
    <div className={"bg-white shadow-card mt-8 rounded-2xl w-full"}>
      <div
        className={
          "font-lato-bold text-xl text-black text-center py-6 border-b border-grey-1"
        }
      >
        Filters
      </div>
      <div className={"px-6 py-8"}>
        <div className={"mb-8"}>
          <UIComponents.SearchBar
            options={[
              {
                label: "test",
                value: "test",
              },
            ]}
            size={"small"}
            filters={[]}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Locations
          </div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"All Locations"}
            onChange={async (newValue: any) => {
              let businessSettingsForSelectedLocation = null
              if (newValue && newValue.value != "All Locations") {
                businessSettingsForSelectedLocation = await fetchBusinessSettingsByBusinessIds([newValue.value]);
                return updateReviewLocationFilter(newValue.value, businessSettingsForSelectedLocation)
              } else {
                return updateReviewLocationFilter(newValue.value, businessSettingsForSelectedLocation)
              }
            }}
            options={
              [
                {
                  label: "All Locations",
                  value: "All Locations",
                }
              ].concat(
                businesses.map((business: TangoBusiness) => {
                  return (
                    {
                      label: business.businessName,
                      value: business.id,
                    }
                  )
                })
              )

            }
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>Rating</div>
          <UIComponents.Dropdown
            size={"small"}
            placeholder={"All Rating"}
            defaultValue={{
              label: selectedRatingFilter,
              value: selectedRatingFilter,
            }}
            onChange={(newValue: any) => {
              if (newValue) updateRatingFilter(newValue.value);
            }}
            options={[
              {
                label: "All",
                value: "All",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "1",
                value: "1",
              },
            ]}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>Time</div>
          <UIComponents.DatePickerInput
            size={"small"}
            value={new Date(selectedDateFilter)}
            onChange={(date: any) => {
              updateReviewDateFilter(date);
            }}
          />
        </div>
        <div className={"mb-8"}>
          <div className={"text-sm font-lato-bold text-black mb-4"}>
            Review Sites
          </div>
          <UIComponents.Dropdown
            size={"small"}
            onChange={(newValue: any) => {
              if (newValue) updateReviewSiteFilter(newValue.value);
            }}
            defaultValue={{
              label: selectedReviewSiteFilter,
              value: selectedReviewSiteFilter,
            }}
            placeholder={"All Rating"}
            options={[...reviewSites, "All"].map((site) => ({
              label: site,
              value: site,
            }))}
          />
        </div>
        <div>
          <div className={"text-sm font-lato-bold text-black mb-4"}>Tags</div>
          <ul className={"p-0 m-0"}>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
                checked={selectedReviewTagsFilter === "unresponded"}
                onClick={() => {
                  updateReviewTagsFilter("unresponded");
                }}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Unresponded
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
                checked={selectedReviewTagsFilter === "responded"}
                onClick={() => {
                  updateReviewTagsFilter("responded");
                }}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Responded
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
                checked={selectedReviewTagsFilter === "positive"}
                onClick={() => {
                  updateReviewTagsFilter("positive");
                }}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Positive
              </div>
            </li>
            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
                checked={selectedReviewTagsFilter === "negative"}
                onClick={() => {
                  updateReviewTagsFilter("negative");
                }}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Negative
              </div>
            </li>

            <li className={"flex items-center mb-4"}>
              <UIComponents.RadioButton
                style={{ cursor: "pointer" }}
                type={"small"}
                className={"mr-4"}
                checked={selectedReviewTagsFilter === "pictures"}
                onClick={() => {
                  updateReviewTagsFilter("pictures");
                }}
              />
              <div className={"text-tiny font-lato-regular text-black"}>
                Attached Pictures
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          "font-lato-bold text-sm text-black text-center py-6 border-t border-grey-1"
        }
        style={{ cursor: "pointer" }}
        onClick={() => {
          updateReviewTagsFilter("");
          updateRatingFilter("All");
          updateReviewSiteFilter("All");
          updateReviewDateFilter(
            moment().subtract(6, "months").format("yyyy-MM-DD")
          ); //default back to one month//RT only returns 250 max anyway?
        }}
      >
        Reset Filters
      </div>
    </div>
  );
};
export default Filters;
