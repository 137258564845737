import * as React from "react";

export const ImportContacts = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      d="M3.125 5.20833V19.7917C3.125 20.9375 4.05208 21.875 5.20833 21.875H11.4583V3.125H5.20833C4.05208 3.125 3.125 4.0625 3.125 5.20833ZM9.375 19.7917H5.20833V5.20833H9.375V19.7917Z"
      fill={color}
    />
    <path
      d="M19.792 3.125H13.542V11.4583H21.8753V5.20833C21.8753 4.0625 20.9378 3.125 19.792 3.125ZM19.792 9.375H15.6253V5.20833H19.792V9.375Z"
      fill={color}
    />
    <path
      d="M13.542 21.875H19.792C20.9378 21.875 21.8753 20.9375 21.8753 19.7916V13.5416H13.542V21.875ZM15.6253 15.625H19.792V19.7916H15.6253V15.625Z"
      fill={color}
    />
  </svg>
);
