import { useState } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

type AllergenProps = {
  uniqueId: string;
  item: string;
  vendorName: string;
  purchasePrice: string;
  splitUnit: string;
  pakUnit: string;
  cost: string;
};

const allergensData: AllergenProps[] = [
  {
    uniqueId: "1",
    item: "Avocado",
    vendorName: "Arterra",
    purchasePrice: "$20.00 / Case",
    splitUnit: "12 Unit / Case",
    pakUnit: "2 lbs / Unit",
    cost: "$0.83 / lb",
  },
  {
    uniqueId: "2",
    item: "Avocado",
    vendorName: "Arterra",
    purchasePrice: "$20.00 / Case",
    splitUnit: "12 Unit / Case",
    pakUnit: "2 lbs / Unit",
    cost: "$0.83 / lb",
  },
  {
    uniqueId: "3",
    item: "Avocado",
    vendorName: "Arterra",
    purchasePrice: "$20.00 / Case",
    splitUnit: "12 Unit / Case",
    pakUnit: "2 lbs / Unit",
    cost: "$0.83 / lb",
  },
];

const columns: ColumnInstruction<AllergenProps>[] = [
  { type: "data", header: "Item", attribute: "item" },
  { type: "data", header: "Vendor", attribute: "vendorName" },
  { type: "data", header: "Purchase Price", attribute: "purchasePrice" },
  { type: "data", header: "Split Unit", attribute: "splitUnit" },
  { type: "data", header: "Pak Unit", attribute: "pakUnit" },
  { type: "data", header: "Cost", attribute: "cost" },
];

const instructions: { [x: string]: RenderInstruction<AllergenProps> } = {};

const useAllergens = () => {
  const [isEditing, setEditing] = useState(false);

  const noOp = () => Promise.resolve(true);
  const logIt = (args: string[]) => console.log(args);

  return {
    allergensData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default useAllergens;
