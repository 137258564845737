import { ComponentType, ThemeProp } from "./types";

export interface ComponentTypeProp {
  slug: ComponentType;
  label: string;
  type: "contact-info" | "text" | "choices" | "form-structure";
}
export const componentTypes: ComponentTypeProp[] = [
  {
    slug: ComponentType.shortText,
    label: "Short Text",
    type: "text",
  },
  {
    slug: ComponentType.longText,
    label: "Long Text",
    type: "text",
  },
  {
    slug: ComponentType.statement,
    label: "Statement",
    type: "text",
  },
  {
    slug: ComponentType.email,
    label: "Email",
    type: "contact-info",
  },
  {
    slug: ComponentType.phone,
    label: "Phone",
    type: "contact-info",
  },
  {
    slug: ComponentType.url,
    label: "URL",
    type: "contact-info",
  },
  {
    slug: ComponentType.multipleChoice,
    label: "Multiple Choice",
    type: "choices",
  },
  {
    slug: ComponentType.pictureChoice,
    label: "Picture Choice",
    type: "choices",
  },
  {
    slug: ComponentType.yesNo,
    label: "Yes/No",
    type: "choices",
  },
  {
    slug: ComponentType.dropdown,
    label: "Dropdown",
    type: "choices",
  },
  {
    slug: ComponentType.ratings,
    label: "Ratings",
    type: "choices",
  },
  {
    slug: ComponentType.uploadFile,
    label: "Upload File",
    type: "choices",
  },
  {
    slug: ComponentType.legal,
    label: "Legal Accept/Decline",
    type: "choices",
  },
  {
    slug: ComponentType.correctOrder,
    label: "Correct Order",
    type: "choices",
  },
  {
    slug: ComponentType.digitalSignature,
    label: "Digital Signature",
    type: "choices",
  },
  {
    slug: ComponentType.labelPicture,
    label: "Label a Picture",
    type: "choices",
  },
  {
    slug: ComponentType.fillInBlank,
    label: "Fill In the Blank",
    type: "choices",
  },
  {
    slug: ComponentType.matching,
    label: "Matching",
    type: "choices",
  },
  {
    slug: ComponentType.welcomeScreen,
    label: "Welcome Screen",
    type: "form-structure",
  },
];
export const surveyArray = [
  {
    id: 1,
    type: "short-text",
    title: "Your text can be edited here",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 2,
    type: "long-text",
    title: "Your long text component here",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 3,
    type: "phone",
    title: "Enter Your phone number here",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 4,
    type: "email",
    title: "Enter Your email here",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 5,
    type: "url",
    title: "Your text can be edited here",
    subtitle: "Description on this is optional",
    values: [],
    required: false,
  },
  {
    id: 6,
    type: "multiple-choice",
    title: "multiple choice",
    subtitle: "Description on this is optional",
    values: ["Choice 1", "choice 2"],
    required: true,
  },
  {
    id: 7,
    type: "picture-choice",
    title: "Your text can be edited here",
    subtitle: "Description on this is optional",
    values: ["choice1", "choice 2"],
    required: false,
  },
  {
    id: 8,
    type: "yes-no",
    title: "Please select one",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 9,
    type: "dropdown",
    title: "Please select one",
    subtitle: "Description on this is optional",
    values: ["test", "adsfd", "ssss"],
    required: true,
  },
  {
    id: 10,
    type: "ratings",
    title: "Please give ratings",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 11,
    type: "upload-file",
    title: "Please upload file",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 12,
    type: "legal",
    title: "Please accept or decline",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 13,
    type: "digital-signature",
    title: "Please sign the doc",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 14,
    type: "label-picture",
    title: "Please label picture",
    subtitle: "Description on this is optional",
    values: [],
    required: true,
  },
  {
    id: 15,
    type: "fill-in-blank",
    title: "Your text can be edited here",
    subtitle: "Description on this is optional",
    values: [
      ["This is really long question", "", "another long text"],
      ["This is really long question", "", "another long text"],
    ],
    required: false,
  },
  {
    id: 16,
    type: "matching",
    title: "Your text can be edited here",
    subtitle: "Description on this is optional",
    values: {
      leftArray: ["test", "test2", "test3"],
      rightArray: ["best", "best1", "best2"],
    },
    required: false,
  },
];

export const themesData: ThemeProp[] = [
  {
    questionColor: "#231f20",
    answerColor: "#231f20",
    buttonColor: "#231f20",
    buttonTextColor: "#ffffff",
    backgroundColor: "#fff",
    systemFont: "Lato",
    themeName: "Default",
    backgroundImage: "",
    backgroundImageBrightness: 0,
    type: "system",
    status: "saved",
    id: "1",
    businessId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    enabled: true,
    deleted: false,
  },
  {
    questionColor: "red",
    answerColor: "#231f20",
    buttonColor: "#231f20",
    buttonTextColor: "tan",
    backgroundColor: "#fff",
    systemFont: "Lato",
    themeName: "Red",
    backgroundImage: "",
    backgroundImageBrightness: 0,
    type: "system",
    status: "saved",
    id: "2",
    businessId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    enabled: true,
    deleted: false,
  },
];
