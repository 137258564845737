import { MenuItem, useMenuItem, useUpdateIngredients, useUpdateRecipe } from "controllers/menuItems";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../model/store";
import { SimpleRenderInstruction } from "../../../components/Table/GenericCell/TableCell";
import { ColumnInstruction, UpdateState } from "components/Table/HorizontalTable";
import { useAllItemsInfo } from "controllers/inventoryItemInfo";
import { Ingredient, instructionsFactory } from "./ingredients";


type MenuItemDetail = {
  uniqueId: string;
  name: string;
  type: string;
  plu: string;
}

const detailsColumns: ColumnInstruction<MenuItemDetail>[] = [
  { type: "data", header: "Item Name", attribute: "name" },
  { type: "data", header: "Menu Category", attribute: "type" },
  { type: "data", header: "PLU", attribute: "plu" },
];
const detailsInstructions: { [x: string]: SimpleRenderInstruction } = {};
const ingredientsColumns: ColumnInstruction<Ingredient>[] = [
  { type: "data", header: "Ingredient", attribute: "itemId" },
  { type: "data", header: "Quantity", attribute: "amount" },
  { type: "projection", header: "Production Unit", attribute: "unit" },
  { type: "projection", header: "Cost", attribute: "cost" },
  { type: "projection", header: "Item Type", attribute: "itemType" },
];

const prefix = "new__ingredient";

const useItemDetail = (isModifier: boolean, itemId: string) => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isEditingIngredients, setIsEditingIngredients] = useState(false);
  const [isEditingRecipe, setIsEditingRecipe] = useState(false);

  // call update with false to push, true to clear
  const [addedIds, updateAddedIngredients] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [prefix + curr.length, ...curr];
    },
    []
  );
  useEffect(() => {
    if (!isEditingIngredients) updateAddedIngredients(true);
  }, [isEditingIngredients]);
  const addIngredient = useCallback(() => {
    setIsEditingIngredients(true);
    updateAddedIngredients(false);
  }, []);
  const query = useMenuItem(businessId, isModifier, itemId);
  const data = query.data as MenuItem;
  const allItemsQuery = useAllItemsInfo(businessId, true);
  const updateIngredients = useUpdateIngredients(businessId, isModifier, itemId);
  const updateRecipe = useUpdateRecipe(businessId, isModifier, itemId);
  const item = useMemo(() =>
    data ? {
      uniqueId: data.id,
      name: data.nameInternal,
      type: data.type,
      plu: data.plu,
    } : null,
    [data]
  );
  const ingredientsFromServer = useMemo(() =>
    data?.ingredients
      ? data.ingredients.map(i => ({
        uniqueId: i.id,
        itemId: i.id,
        amount: i.amount,
      })) as Ingredient[]
      : [],
    [data]
  );
  const ingredients = useMemo(() => [
    ...addedIds.map(uniqueId => ({
      uniqueId,
      amount: 1,
      itemId: null,
    } as Ingredient)),
    ...ingredientsFromServer,
  ], [addedIds, ingredientsFromServer])
  const saveIngredientsChanges = useCallback((instructions: UpdateState) => {
    const items = allItemsQuery.data ?? [];
    const newVals = ingredients.map(ingredient => {
      const update = instructions[ingredient.uniqueId];
      const itemId = (update?.itemId?.newValue ?? ingredient.itemId) as string;
      const item = items.find(i => i.id == itemId);
      if (!item) throw "Item not found";
      return ({
        id: itemId,
        isRaw: item.isRaw,
        amount: (update?.amount?.newValue ?? ingredient.amount) as number,
      });
    });
    return updateIngredients.mutateAsync(newVals);
  }, [updateIngredients, ingredients, allItemsQuery.data]);
  const deleteIngredients = useCallback((ids: string[]) => {
    if (!data?.ingredients) return;

    const newVals = data?.ingredients
      .filter(i => !ids.includes(i.id))
      .map(ingredient => ({
        isRaw: ingredient.isRaw,
        id: ingredient.id,
        amount: ingredient.amount,
      }));
    updateIngredients.mutate(newVals);
  }, [updateIngredients, data]);
  const ingredientsInstructions = instructionsFactory(allItemsQuery.data ?? []);

  const recipe = data?.recipe;
  const saveRecipeChanges = useCallback(
    (newRecipe) => updateRecipe.mutateAsync(newRecipe).then(
      () => setIsEditingRecipe(false)
    ),
    [updateRecipe],
  );
  return {
    item,
    isEditingDetails,
    setIsEditingDetails,
    detailsColumns,
    detailsInstructions,
    ingredients,
    isEditingIngredients,
    setIsEditingIngredients,
    addIngredient,
    ingredientsColumns,
    ingredientsInstructions,
    saveIngredientsChanges,
    deleteIngredients,
    recipe,
    isEditingRecipe,
    setIsEditingRecipe,
    saveRecipeChanges,
  };
};
export default useItemDetail;
