import { Button, Dialog } from "@blueprintjs/core";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const ShowPreview = ({ isOpen, onClose, data }: Props) => {
  const [currentItem, setCurrentItem] = useState(0);
  const maxLength = data.length;
  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  const [inputText, setInputText] = useState("");

  return (
    <Dialog className="preview" isOpen={isOpen} onClose={onClose}>
      <div
        className="header"
        style={{ display: "grid", gridTemplateColumns: "auto auto" }}
      >
        <div className="search" style={{ marginTop: 20, marginLeft: 20 }}>
          Jump to{" "}
          <input
            type="text"
            style={{ width: 40 }}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />{" "}
          <Button
            text="Enter"
            onClick={() => {
              const number = parseInt(inputText);
              if (number && number < maxLength - 1 && number > 0) {
                setCurrentItem(number - 1);
                setInputText("");
              }
            }}
          />
        </div>
        <div
          className="controls"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 20,
            marginTop: 20,
          }}
        >
          <div
            className="numbering"
            style={{ fontSize: 12, marginTop: 7.5, marginRight: 10 }}
          >
            Showing {currentItem + 1} of {maxLength}
          </div>
          <Button
            minimal
            icon="less-than"
            onClick={() => {
              if (currentItem > 0) {
                setCurrentItem(currentItem - 1);
              }
            }}
          />
          <Button
            minimal
            icon="greater-than"
            onClick={() => {
              if (currentItem < maxLength - 1) {
                setCurrentItem(currentItem + 1);
              }
            }}
          />
        </div>
      </div>
      <br />

      <div
        className="content"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          display: "grid",
          gridTemplateColumns: "200px 1fr",
          rowGap: "1ch",
        }}
      >
        {columns.map((column) => {
          if (typeof data[currentItem][column] === "object") {
            // Array or object or null
            if (Array.isArray(data[currentItem][column])) {
              return (
                <>
                  <div className="key">
                    <b>{column}</b>
                  </div>
                  <div className="value">
                    {data[currentItem][column].map(
                      (item: any, index: number) => {
                        return (
                          <div
                            className={column + "-" + index + ""}
                            style={{
                              marginRight: 5,
                              backgroundColor: "#d3d3d3",
                            }}
                          >
                            {item}
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              );
            }
          } else {
            return (
              <>
                <div className="key">
                  <b>{column}</b>
                </div>
                <div className="value">{data[currentItem][column]}</div>
              </>
            );
          }
          return <></>;
        })}
      </div>
    </Dialog>
  );
};

export default ShowPreview;
