import { AccountAction } from "model/types";

import ActionType from "../actions/actionTypes";

// const initialState: TangoAccount | null = null;
const initialState: any = {
  boards: [],
  tickets: [],
  boardIcons: [
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup%203853.svg?alt=media&token=2e51a31f-01b4-4252-a315-2a0e94f04c04",
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup-1.svg?alt=media&token=a4455a8d-5a20-43d3-8118-9926c6b64961",
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup-2.svg?alt=media&token=f1fc2ee6-81e6-4d77-bc2d-7ab1232f9ab8",
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup-3.svg?alt=media&token=da892ff8-cbf4-4b19-803c-730899d150c3",
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup-4.svg?alt=media&token=fe2fcd12-b369-4926-8d8a-b70f45aae29f",
    "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/BoardIcons%2FGroup.svg?alt=media&token=babd9bc0-0a32-4128-a105-24ec52efd7ff",
  ],
};

export default function boards(state = initialState, action: any) {
  switch (action.type) {
    case ActionType.RECEIVE_BOARDS:
      return {
        ...state,
        boards: action.boards,
      };
    case ActionType.RECEIVE_BOARD_TICKETS:
      return {
        ...state,
        tickets: action.tickets,
      };
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
