import UIComponents from "@tangopay/tango-ui-library";
import React, { useEffect } from "react";
import { ReviewResponseTemplate } from "types/reputationManagement";

import Header from "../../../components/Header";
import { getAllReviewResponseTemplates } from "../../../models/reputationManagement";
import NewTemplateModal from "./NewTemplateModal";
import Sidebar from "./Sidebar";
import ViewTemplate from "./ViewTemplate";
import useTemplates from "./useTemplates";

const ReviewsTemplates = () => {

  const {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    showNewTemplateModal,
    setShowNewTemplateModal,
    setTemplates,
    business
  } = useTemplates();

  const fetchTemplates = async () => {
    const allTemplates = await getAllReviewResponseTemplates(
      business.accountId
    );
    setTemplates(allTemplates);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={[
          {
            label: "Reviews",
            link: "/reviews",
          },
          {
            label: "Templates",
            link: "/reviews-templates",
          },
        ]}
        selectedRoute={"/reviews-templates"}
      />
      <div className="flex h-full">
        <div
          className={"border-r border-grey-1"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar />
        </div>
        <div className={"flex"} style={{ width: "90%" }}>
          {selectedTemplate ? (
            <ViewTemplate
              setSelectedTemplate={(template: ReviewResponseTemplate | null) =>
                setSelectedTemplate(template)
              }
              selectedTemplate={selectedTemplate}
              updateSuccess={() => {
                return fetchTemplates(); //fetch templates again after update success
              }}
            />
          ) : (
            <div
              className={
                "m-10 border border-grey-1 w-full rounded-2xl border-solid"
              }
              style={{ height: "fit-content" }}
            >
              <div className={"py-6 px-4 flex items-center justify-between"}>
                <div className={"text-black font-lato-black text-xl"}>
                  Review Reply Templates
                </div>
                <UIComponents.Button
                  label={"Add Template"}
                  type={"btn-style-outline"}
                  size={"btn-medium"}
                  onClick={() => setShowNewTemplateModal(true)}
                />
              </div>
              <div
                className={
                  "py-4 px-6 bg-blue-grey-2 text-black font-lato-black text-xs"
                }
              >
                Subject
              </div>
              <ul className={"m-0 p-0 list-none"}>
                {templates.map((template: ReviewResponseTemplate, index) => (
                  <li
                    key={index}
                    className={
                      "flex items-center justify-between py-6 px-4 border-b last:border-0 border-grey-1 cursor-pointer"
                    }
                    style={{ borderBottomStyle: "solid" }}
                    onClick={() => setSelectedTemplate(template)}
                  >
                    <div className={"text-sm font-lato-regular text-black"}>
                      {template.name}
                    </div>
                    <UIComponents.Icon name={"chevron-right"} size={"20"} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <NewTemplateModal
          isOpen={showNewTemplateModal}
          closeModal={() => setShowNewTemplateModal(false)}
          createSuccess={() => {
            return fetchTemplates(); //fetch templates again after create success // or could append new template to existing state?
          }}
          business={business}
        />
      </div>
    </div>
  );
};

export default ReviewsTemplates;
