import { useState } from "react";

import Layout from "../Layout";
import ServiceAreas from "./ServiceAreas";
import Tables from "./Tables";
import "./style.css";

const DeviceSetup = () => {
  const TABLES = "Tables";
  const SERVICE_AREAS = "Service Areas";
  const [selectedNavigation, setSelectedNavigation] = useState(SERVICE_AREAS);

  return (
    <Layout>
      <div id="device-setup">
        <div className="navigation">
          <div
            className={
              selectedNavigation === TABLES ? `button active` : `button`
            }
            onClick={() => setSelectedNavigation(TABLES)}
          >
            {TABLES}
          </div>
          <div
            className={
              selectedNavigation === SERVICE_AREAS ? `button active` : `button`
            }
            onClick={() => setSelectedNavigation(SERVICE_AREAS)}
          >
            {SERVICE_AREAS}
          </div>
        </div>
        <div className="content">
          <div className="section-name">{selectedNavigation}</div>
          <div className="section-body">
            {selectedNavigation === TABLES && (
              <div className="sample">
                <Tables />
              </div>
            )}
            {selectedNavigation === SERVICE_AREAS && (
              <div className="sample">
                <ServiceAreas />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeviceSetup;
