import UIComponents from "@tangopay/tango-ui-library";
import React, { ReactElement } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  workspaces: any[];
  email: string;
  showMergeCalendarModal: () => void;
}

const MergeCalendarPopover = ({
  isOpen,
  onClose,
  children,
  email,
  workspaces,
  showMergeCalendarModal,
}: Props) => {
  return (
    <UIComponents.Popover
      containerClassName={"z-10"}
      positions={["right"]}
      reposition={true}
      isOpen={isOpen}
      onClickOutside={onClose}
      content={
        <div className={"bg-white py-4 px-6 shadow-general-shadow rounded-2xl"}>
          <div className={"font-lato-extrabold text-tiny text-grey-2 mb-2.5"}>
            {" "}
            {email}
          </div>
          {workspaces.map((workspace) => (
            <div
              className={"flex items-center mb-2 cursor-pointer"}
              onClick={showMergeCalendarModal}
            >
              <UIComponents.Checkbox
                type={"small"}
                colorType={"green"}
                checked
                className={"mr-2"}
              />
              <div className={"font-lato-bold text-tiny text-black"}>
                {workspace}
              </div>
            </div>
          ))}
        </div>
      }
    >
      {children}
    </UIComponents.Popover>
  );
};
export default MergeCalendarPopover;
