import Header from "components/Header";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const launchpad = useLaunchpad();
  return (
    <Box className="stores-and-ops-page">
      <Header
        routes={[
          {
            label: "Table Management",
            link: "/table-management",
          },
          {
            label: "Device Setup",
            link: "/device-setup",
          },

        ]}
        selectedRoute={location.pathname}
        selectedPage={{
          label: "Store Setup",
          value: "/device-setup"
        }}
      />

      <div className="children pt-16" style={{ marginTop: 20 }}>
        {children}
      </div>
    </Box>
  );
};
export default Layout;
