import {
  Icon,
  InputGroup,
  Intent,
  MenuItem,
  Position,
  Toaster,
} from "@blueprintjs/core";
import { Omnibar, Suggest } from "@blueprintjs/select";
import axios from "axios";
import firebase from "config/firebase";
import DOMPurify from "dompurify";
import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import _, { find, findIndex } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import { getAllJobFunctions } from "model/selectors/teamsTable";
import { RootState } from "model/store";

import LocationIcon from "assets/location-icon.svg";
import logo from "assets/normallogo.svg";
import QualificationIcon from "assets/qualifications-icon.svg";
import SalaryBenefitsIcon from "assets/salary-benefits.svg";

import { fetchFormsByAccountId } from "../../../../models/surveyBuilder";
import { getAllBusinessesByAccountId } from "../teamFirebaseCalls";
import { BasicInfo } from "./Icons/BasicInfo";
import { jobDescription } from "./Icons/JobDescription";
import { JobDetail } from "./Icons/JobDetail";
import { PreviewJobs } from "./Icons/PreviewJobs";
import { Qualifications } from "./Icons/Qualifications";
import { SalaryBenifit } from "./Icons/SalaryBenifit";
import "./index.css";
import FindLocationModal from "./modals/FindLocationModal";

const CreateJobPosting = () => {
  // const [businessSettingsForbene, setBusinessSettings] = useState([]);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const jobFunc = useSelector(getAllJobFunctions);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const tangoSettingsJobFunctions: { [T: string]: string } = jobFunc
    ? Object.keys(jobFunc).reduce(
        (acc, key) => ({ ...acc, [jobFunc[key].title]: jobFunc[key].id }),
        {}
      )
    : {};
  const businessSettingsJobFunctions: { [T: string]: string } =
    businessSettings && businessSettings.jobFunctions
      ? Object.keys(businessSettings.jobFunctions).reduce(
          (acc, key) => ({
            ...acc,
            [businessSettings.jobFunctions[key].title]:
              businessSettings.jobFunctions[key].id,
          }),
          {}
        )
      : {};
  const jobFunctions: string[] = _.union(
    Object.keys(tangoSettingsJobFunctions),
    Object.keys(businessSettingsJobFunctions)
  );
  const db = firebase.firestore();

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [step, setStep] = useState<number>(1);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [editorStateQualification, setEditorStateQualification] =
    useState<EditorState>(EditorState.createEmpty());
  const [ConvertedContent, setConvertedContent]: any = useState("");
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [allBusinesses, setAllBusinesses] = useState<TangoBusiness[]>([]);
  const [selectedLocation, setSelectedLocation]: any = useState([]);
  const [existingApplications, setExistingApplications] = useState<any[]>([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formData, setFormData]: any = useState({
    jobTitle: "",
    jobType: "",
    jobAddress: "",
    city: "",
    state: "",
    zip: "",
    availableShifts: [],
    openPositions: 0,
    payRate: 0,
    compensationType: "",
    benifits: [],
    jobDescription: "",
    qualifications: "",
  });
  const [benifitQuery, setBenifitQuery] = useState<string>("");
  const [showOmnibar, setShowOmnibar] = useState<boolean>(false);
  const navigate = useNavigate();
  let toaster: Toaster;
  const refHandlers = {
    toaster: (ref: Toaster) => (toaster = ref),
  };

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const availableShiftsData = [
    { shift: "Day Shift", id: 0 },
    { shift: "Evening Shift", id: 1 },
    { shift: "Holidays", id: 2 },
    { shift: "Weekend Availability", id: 3 },
  ];

  const availableBenifitsData = [
    { benifit: "Career Advancement", id: 0 },
    { benifit: "Health Insurance", id: 1 },
  ];
  const sidebarList = [
    {
      name: "Basic Information",
      icon: BasicInfo,
      index: 1,
    },
    {
      name: "Job Details",
      icon: JobDetail,
      index: 2,
    },
    {
      name: "Salary & Benefits",
      icon: SalaryBenifit,
      index: 3,
    },
    {
      name: "Job Description",
      icon: jobDescription,
      index: 4,
    },
    {
      name: "Qualifications",
      icon: Qualifications,
      index: 5,
    },
    {
      name: "Preview Job",
      icon: PreviewJobs,
      index: 6,
    },
    {
      name: "Select Application Form",
      icon: PreviewJobs,
      index: 7,
    },
  ];

  const updateLocation = (
    location: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      if (index !== -1) {
        let oldLocationsArray = [...selectedLocation];
        if (oldLocationsArray.length === index) {
          oldLocationsArray = [];
        } else {
          oldLocationsArray.splice(index, 1);
        }
        setSelectedLocation(oldLocationsArray);
      }
    } else {
      setSelectedLocation([...selectedLocation, location]);
    }
  };

  const isShiftSelected = (shift: string) => {
    const availableShift = find(formData.availableShifts, (item) => {
      if (item === shift) {
        return true;
      }
      return false;
    });
    return availableShift;
  };

  const updateShift = (shift: string, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(
        formData.availableShifts,
        (item) => item === shift
      );
      if (indexToRemove !== -1) {
        const oldJobRolesArray = [...formData.availableShifts];
        oldJobRolesArray.splice(indexToRemove, 1);
        setFormData({
          ...formData,
          availableShifts: oldJobRolesArray,
        });
      }
    } else {
      setFormData({
        ...formData,
        availableShifts: [...formData.availableShifts, shift],
      });
    }
  };

  const isBenifitSelected = (benifit: string) => {
    const availableBenifit = find(formData.benifits, (item) => {
      if (item === benifit) {
        return true;
      }
      return false;
    });
    return availableBenifit;
  };

  const updateBenifit = (benifit: string, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(
        formData.benifits,
        (item) => item === benifit
      );
      if (indexToRemove !== -1) {
        const oldBenifitsArray = [...formData.benifits];
        oldBenifitsArray.splice(indexToRemove, 1);
        setFormData({
          ...formData,
          benifits: oldBenifitsArray,
        });
      }
    } else {
      setFormData({
        ...formData,
        benifits: [...formData.benifits, benifit],
      });
    }
  };

  let locationlength = selectedLocation.length;

  const submitForm = async (payload: any) => {
    return db
      .collection("JobPosting")
      .add(payload)
      .then((docRef: any) => {
        db.collection("JobPosting")
          .doc(docRef.id)
          .update({ id: docRef.id })
          .then(() => {
            toaster.show({
              message: "Job Post created successfully",
              intent: Intent.SUCCESS,
            });
            locationlength--;
            if (locationlength == 0) {
              setStep(1);
              setFormData({
                jobTitle: "",
                jobType: "",
                jobAddress: "",
                city: "",
                state: "",
                zip: "",
                availableShifts: [],
                openPositions: 0,
                payRate: 0,
                compensationType: "",
                benifits: [],
                jobDescription: "",
              });
              setSelectedForm(null);
              setSelectedLocation([]);
              setEditorState(EditorState.createEmpty());
              setEditorStateQualification(EditorState.createEmpty());
            }
          });
      })
      .catch((error) => console.log(error));
  };

  const createJobPost = async () => {
    const createForm =
      selectedLocation &&
      selectedLocation.map(async (item: any) => {
        const payload = {
          id: null,
          businessId: item.id,
          jobTitle: formData.jobTitle,
          jobType: formData.jobType,
          location: {
            jobAddress: item.location.address,
            city: "",
            state: "",
            zip: "",
            name: item.businessName,
          },
          accountId: item.accountId,
          availableShifts: formData.availableShifts,
          openPositions: formData.openPositions,
          payRate: formData.payRate,
          compensationType: formData.compensationType,
          benefits: formData.benifits,
          jobDescription: formData.jobDescription,
          qualifications: formData.qualifications,
          createdAt: new Date(),
          updatedAt: new Date(),
          deleted: false,
          department: "",
          applicationUrl: "",
          //@ts-ignore
          formId: selectedForm ? selectedForm.id : "",
        };
        await submitForm(payload);
      });

    await Promise.all(createForm);
  };

  useEffect(() => {
    // axios
    //   .post(
    //     "https://us-central1-tango-2.cloudfunctions.net/typeformReceiveForm",
    //     {
    //       accountId: business.accountId,
    //     }
    //   )
    //   .then(async (res) => {
    //     console.log("list of exisitng typeforms", res.data);
    //     let backgroundImageUrl = "";
    //     if (res.data.items.length) {
    //       try {
    //         const response = await axios.get(res.data.items[0].theme.href);
    //         backgroundImageUrl = response.data.background.href;
    //       } catch (error) {}
    //     }
    //     const typeFormData = res.data.items.map((item: any) => ({
    //       ...item,
    //       backgroundImageUrl: backgroundImageUrl,
    //     }));
    //     setExistingApplications(typeFormData);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    const fetchData = async () => {
      const formsResponse = await fetchFormsByAccountId(business.accountId);
      if (formsResponse.data) {
        setExistingApplications(formsResponse.data);
      }
    };
    if (business) {
      fetchData();
    }
  }, [business]);

  useEffect(() => {
    const getAllLocationsOfAccount = async () => {
      if (business && business.accountId) {
        const businesses = await getAllBusinessesByAccountId(
          business.accountId
        );
        setAllBusinesses(businesses);
      }
    };
    if (business) getAllLocationsOfAccount();
  }, [business]);

  useEffect(() => {
    setFormData({
      ...formData,
      jobDescription: convertToHTML(editorState.getCurrentContent()),
    });
  }, [editorState]);

  useEffect(() => {
    console.log("formData", formData);
    setFormData({
      ...formData,
      qualifications: convertToHTML(
        editorStateQualification.getCurrentContent()
      ),
    });
  }, [editorStateQualification]);

  useEffect(() => {
    console.log("selectedLocation", selectedLocation, existingApplications);
  }, [selectedLocation]);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const addBenefitsToDB = async (data: string) => {
    const clonedBenefits = businessSettings.benefits
      ? [...businessSettings.benefits]
      : [];
    clonedBenefits.push(data);
    try {
      await db
        .collection("BusinessSettings")
        .doc(businessSettings.id)
        .update({ benefits: clonedBenefits });
      return true;
    } catch (error) {
      console.log({ newError: error });
      //@ts-ignore
      return { error: error.message };
    }
  };
  const renderJobBasics = () => {
    const isFirstStepContinueButtonEnable = () => {
      if (formData.jobTitle.length > 2 && selectedLocation != "") {
        return true;
      }
      return false;
    };

    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Job Basics</div>
          <div className="section-subtitle">Let’s create your job posting</div>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <div className="input-container">
              <div className="input-label">Job Title</div>
              <Suggest
                className="jobtitleinput"
                fill={true}
                popoverProps={{ minimal: true }}
                openOnKeyDown={true}
                items={jobFunctions && jobFunctions.map((item) => item)}
                itemRenderer={(items, meta) => {
                  if (meta.modifiers.matchesPredicate) {
                    return (
                      <MenuItem
                        active={formData.jobTitle == items ? true : false}
                        key={items}
                        text={items}
                        onClick={meta.handleClick}
                        textClassName="menu-item-text"
                      />
                    );
                  } else {
                    return null;
                  }
                }}
                itemPredicate={(query, item) => {
                  let result =
                    `${item.toLowerCase()} ${item.toLowerCase()}`.indexOf(
                      query.toLowerCase()
                    ) >= 0;
                  return result;
                }}
                inputValueRenderer={(item) => item}
                noResults={
                  <MenuItem disabled={true} text="No Job types found." />
                }
                onItemSelect={(item) =>
                  setFormData({ ...formData, jobTitle: item })
                }
              />
              {/* <InputGroup
              onChange={(e) =>setFormData({...formData,jobTitle:e.target.value})}
              value ={formData.jobTitle}

            /> */}
              {formData.jobTitle.length > 2 && (
                <img
                  src={require("assets/input-check.svg").default}
                  className="input-check"
                />
              )}
            </div>
            {/* <div className='input-container'>
            <div className='input-label'>Job Address</div>
            <InputGroup
              value ={formData.jobAddress}
              onChange={(e) => setFormData({...formData,jobAddress:e.target.value})}
              className='employeeinputs'
            />
            {
              formData.jobAddress.length > 2 && <img src={require('assets/input-check.svg').default} className='input-check'/>
            }
            <div className="remove-image" onClick={()=> setFile(null)}>
              <Icon icon="cross" color="#FFFFFF"/>
            </div>
          </div> */}
          </Box>
        </Box>
        {/*locations*/}
        <Box className="section option-section">
          <div className="section-title">Job Location</div>
          <div className="section-subtitle">
            Please select which locations this job is for
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            {selectedLocation.length > 0 &&
              selectedLocation.map((item: any) => (
                <button className="select-button active">
                  <Icon icon="tick" size={12} color={"#4385EF"} />{" "}
                  {item?.businessName}
                </button>
              ))}
            <button
              className="select-button"
              onClick={() => setShowLocationModal(true)}
            >
              <Icon icon="plus" size={12} color={"#4385EF"} /> Please add a
              location for this job
            </button>
          </Box>
        </Box>
        <button
          className={
            isFirstStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          disabled={!isFirstStepContinueButtonEnable()}
          onClick={() => {
            window.scrollTo(0, 0);
            setStep(2);
          }}
        >
          Continue
        </button>
      </Box>
    );
  };

  const renderJobDetails = () => {
    const isSecondStepContinueButtonEnable = () => {
      if (
        formData.jobType &&
        formData.availableShifts.length > 0 &&
        formData.openPositions > 0
      ) {
        return true;
      }
      return false;
    };

    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Job Type</div>
          <div className="section-subtitle">
            Select what type of hire this employee will be
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <button
              className={`select-button simple ${
                formData.jobType == "Full-Time" ? "active" : ""
              }`}
              onClick={() => setFormData({ ...formData, jobType: "Full-Time" })}
            >
              <Icon
                icon={formData.jobType == "Full-Time" ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />
              Full Time Employee
            </button>
            <button
              className={`select-button simple ${
                formData.jobType == "Part-Time" ? "active" : ""
              } `}
              onClick={() => setFormData({ ...formData, jobType: "Part-Time" })}
            >
              <Icon
                icon={formData.jobType == "Part-Time" ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />
              Part Time Employee
            </button>
            <button
              className={`select-button simple ${
                formData.jobType == "Intern" ? "active" : ""
              } `}
              onClick={() => setFormData({ ...formData, jobType: "Intern" })}
            >
              <Icon
                icon={formData.jobType == "Inten" ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />
              Intern
            </button>
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Available Shifts</div>
          <div className="section-subtitle">
            Select what shifts are available for this role
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            {availableShiftsData.map((item, key) => {
              return (
                <button
                  className={`select-button ${
                    isShiftSelected(item.shift) ? "active" : ""
                  }`}
                  onClick={() =>
                    updateShift(item.shift, isShiftSelected(item.shift))
                  }
                >
                  <Icon
                    icon={isShiftSelected(item.shift) ? "tick" : "plus"}
                    size={12}
                    color={"#4385EF"}
                  />
                  {item.shift}
                </button>
              );
            })}
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Open Positions</div>
          <div className="section-subtitle">
            How many people are you looking to hire for this role?
          </div>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <div className="input-container">
              <div className="input-label">Positions</div>
              <InputGroup
                onChange={(e) =>
                  setFormData({ ...formData, openPositions: e.target.value })
                }
                value={formData.openPositions}
                className="employeeinputs"
              />
              {formData.openPositions > 0 && (
                <img
                  src={require("assets/input-check.svg").default}
                  className="input-check"
                />
              )}
            </div>
          </Box>
        </Box>
        <button
          className={
            isSecondStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          disabled={!isSecondStepContinueButtonEnable()}
          onClick={() => {
            window.scrollTo(0, 0);
            setStep(3);
          }}
        >
          Continue
        </button>
      </Box>
    );
  };
  const renderSalaryBenefits = () => {
    const isThirdStepContinueButtonEnable = () => {
      if (parseFloat(formData.payRate) > 4 && formData.compensationType) {
        return true;
      }
      return false;
    };

    const isNumberKey = (evt: any) => {
      let charCode = evt.which ? evt.which : evt.keyCode;
      console.log(charCode);
      if (
        charCode != 46 &&
        charCode != 45 &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
      ) {
        return false;
      }

      return true;
    };

    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Pay Rate / Pay Range</div>
          <div className="section-subtitle">
            Enter the desired pay rate for this employee
          </div>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <div className="input-container">
              <div className="input-label">Pay Rate</div>
              <span className="input-currencyprefix">$</span>
              <input
                className="employeeinputspayrate"
                type="text"
                pattern="[0-9]+([-\,][0-9]+)?"
                value={formData.payRate}
                onChange={(e) =>
                  setFormData({ ...formData, payRate: e.target.value })
                }
              />
              {formData &&
              formData.payRate &&
              parseFloat(formData.payRate) > 4 ? (
                <img
                  src={require("assets/input-check.svg").default}
                  className="input-check"
                />
              ) : null}
            </div>
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Compensation Type</div>
          <div className="section-subtitle">How will this employee be paid</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <button
              className={`select-button ${
                formData.compensationType == "Per-Hour" ? "active" : ""
              }`}
              onClick={() =>
                setFormData({ ...formData, compensationType: "Per-Hour" })
              }
            >
              <Icon
                icon={formData.compensationType == "Per-Hour" ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />
              Per Hour
            </button>
            <button
              className={`select-button ${
                formData.compensationType == "Per-Year" ? "active" : ""
              }`}
              onClick={() =>
                setFormData({ ...formData, compensationType: "Per-Year" })
              }
            >
              <Icon
                icon={formData.compensationType == "Per-Year" ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />
              Per Year
            </button>
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Benefits</div>
          <div className="section-subtitle">
            Does this position come with any benefits?
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            {formData.benifits
              ? formData.benifits.map((item: string, key: number) => {
                  return (
                    <button
                      className={`select-button simple ${
                        isBenifitSelected(item) ? "active" : ""
                      } `}
                      onClick={() =>
                        updateBenifit(item, isBenifitSelected(item))
                      }
                    >
                      <Icon
                        icon={isBenifitSelected(item) ? "tick" : "plus"}
                        size={12}
                        color={"#4385EF"}
                      />
                      {item}
                    </button>
                  );
                })
              : null}
            <button
              className={`select-button simple`}
              onClick={() => setShowOmnibar(true)}
            >
              <Icon icon={"plus"} size={12} color={"#4385EF"} />
              Select Benefits
            </button>
            <Omnibar
              resetOnSelect
              overlayProps={{
                portalClassName: "benefits-omnibar",
              }}
              query={benifitQuery}
              isOpen={showOmnibar}
              onClose={() => {
                setBenifitQuery("");
                setShowOmnibar(false);
              }}
              onItemSelect={async (item: string, event) => {
                // updateBenifit(item, isBenifitSelected(item));
                const response = await addBenefitsToDB(item);
                if (response) {
                  updateBenifit(item, isBenifitSelected(item));
                }
              }}
              onQueryChange={(query) => {
                setBenifitQuery(query);
              }}
              createNewItemFromQuery={(query) => query}
              createNewItemRenderer={(
                query: string,
                active: boolean,
                handleClick: React.MouseEventHandler<HTMLElement>
              ) => (
                <MenuItem
                  icon="add"
                  text={`Create "${query}"`}
                  active={active}
                  onClick={async (e) => {
                    const response = await addBenefitsToDB(query);
                    if (response) {
                      handleClick(e);
                    }
                  }}
                  shouldDismissPopover={false}
                />
              )}
              itemPredicate={(query, film, _index, exactMatch) => {
                const normalizedTitle = film.toLowerCase();
                const normalizedQuery = query.toLowerCase();

                if (exactMatch) {
                  return normalizedTitle === normalizedQuery;
                } else {
                  return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
                }
              }}
              itemRenderer={(item, { handleClick }) => {
                if (isBenifitSelected(item)) {
                  return null;
                } else {
                  return <MenuItem text={item} onClick={handleClick} />;
                }
              }}
              itemsEqual={(filmA: string, filmB: string) => {
                // Compare only the titles (ignoring case) just for simplicity.
                return filmA.toLowerCase() === filmB.toLowerCase();
              }}
              items={businessSettings.benefits ? businessSettings.benefits : []}
            />
          </Box>
        </Box>
        <button
          className={
            isThirdStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          disabled={!isThirdStepContinueButtonEnable()}
          onClick={() => {
            window.scrollTo(0, 0);
            setStep(4);
          }}
        >
          Continue
        </button>
      </Box>
    );
  };

  const renderJobDescription = () => {
    const isFourthStepContinueButtonEnable = () => {
      if (formData.jobDescription.length > 7) {
        return true;
      }
      return false;
    };
    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Job Description</div>
          <div className="section-subtitle">What is this job all about?</div>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <Editor
              placeholder="Type memo here.."
              editorState={editorState}
              toolbar={{
                options: ["inline", "textAlign", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
              wrapperClassName="job-description-editor"
              onEditorStateChange={(editorStateData) =>
                setEditorState(editorStateData)
              }
            />
          </Box>
        </Box>
        <button
          className={
            isFourthStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          disabled={!isFourthStepContinueButtonEnable()}
          onClick={() => {
            window.scrollTo(0, 0);
            setStep(5);
          }}
        >
          Continue
        </button>
      </Box>
    );
  };

  const renderQualifications = () => {
    const isFifthStepContinueButtonEnable = () => {
      if (formData.qualifications.length > 7) {
        return true;
      }
      return false;
    };
    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Qualifications</div>
          <div className="section-subtitle">
            We’re looking for someone who has:
          </div>
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginTop: "15px" }}
          >
            <Editor
              placeholder="Type memo here.."
              editorState={editorStateQualification}
              toolbar={{
                options: ["inline", "textAlign", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
              wrapperClassName="job-description-editor"
              onEditorStateChange={(editorStateData) =>
                setEditorStateQualification(editorStateData)
              }
            />
          </Box>
        </Box>
        <button
          className={
            isFifthStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          disabled={!isFifthStepContinueButtonEnable()}
          onClick={() => setStep(6)}
        >
          Continue
        </button>
      </Box>
    );
  };

  const renderPreviewJobPosting = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">Preview Job Posting</div>
        <div className="section-subtitle">
          Review the details before posting
        </div>
        <div className="job-posting-preview">
          <div className="job-posting-title">Work for The Coop</div>
          <div className="job-posting-role">
            {formData.jobTitle || "Server"} - The Coop
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <button
              className={`select-button simple`}
              onClick={() => console.log("new-hire")}
            >
              <Icon
                icon={<img src={LocationIcon} />}
                size={12}
                color={"#4385EF"}
              />
              {selectedLocation.length > 0 &&
                selectedLocation.map((i: any) => i.businessName).join(", ")}
            </button>
            <button
              className={`select-button simple`}
              onClick={() => console.log("new-hire")}
            >
              <Icon
                icon={<img src={QualificationIcon} />}
                size={12}
                color={"#4385EF"}
              />
              {formData.jobType} Employee
            </button>
            <button
              className={`select-button simple`}
              onClick={() => console.log("new-hire")}
            >
              <Icon
                icon={<img src={SalaryBenefitsIcon} />}
                size={12}
                color={"#4385EF"}
              />
              ${formData.payRate} {formData.compensationType}
            </button>
          </Box>
          <div className="job-description-title">Job Description</div>
          <div className="job-description-text">
            <div
              className="preview"
              dangerouslySetInnerHTML={createMarkup(formData.jobDescription)}
            ></div>
          </div>
          <div className="job-description-title">Qualifications</div>
          <div className="job-description-text">
            <div
              className="preview"
              dangerouslySetInnerHTML={createMarkup(formData.qualifications)}
            ></div>
          </div>

          {/* <div className="job-description-text">
          We’re looking for a Developer Advocate to work with Slack’s developer community. Slack is where people can work with not only their colleagues, but also the other tools that are a part of their daily work. Slack’s apps and extensibility are a key part of what makes it so essential. By working with developers to bring even more touch points from our users’ everyday tools into Slack, you’ll be making Slack more useful and delightful for our customers.
Developer Advocates at Slack work directly with our community of developers as they build on our platform, speaking at events, producing video content, and creating other content to help our community learn how to build products on our platform. You’ll collaborate closely with the other product and engineering teams to advocate for the community internally, set roadmaps and define and launch new features.

Developer Advocacy requires people with technical backgrounds who can also communicate and empathize with developers who are building on the Slack Platform. This role would appeal to you if you love coding and thinking through advanced scenarios, just as much as communicating with a variety of partners, both technical and non-technical. You enjoy helping people solve problems, writing code samples, and communicating to a broad audience.
Slack has a positive, diverse, and supportive culture—we look for people who are curious, inventive, and work to be a little better every single day. In our work together we aim to be smart, humble, hardworking
          </div> */}
        </div>
      </Box>
      <button className="continue-button" onClick={() => setStep(7)}>
        Continue
      </button>
    </Box>
  );

  const renderSelectTypeForm = () => {
    return (
      <Box
        display="flex"
        flex-direction="column"
        className="select-typeform-container"
      >
        <Box className="section option-section">
          <div className="section-title">
            Select an application form to link to this job
          </div>
          <div className="section-subtitle">Select a form below</div>
          <div className="typeform-container">
            {existingApplications
              ? existingApplications.map((item: any, index: number) => (
                  <div
                    key={index}
                    className={`single-typeform ${
                      //@ts-ignore
                      selectedForm && item.id === selectedForm.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => setSelectedForm(item)}
                  >
                    {/*{item.backgroundImageUrl.length > 0 ? (*/}
                    {/*  <img src={item.backgroundImageUrl} />*/}
                    {/*) : (*/}
                    <div className="typeform-image" />
                    {/*)}*/}
                    <div className="typeform-info">
                      <div className="typeform-title">{item.name}</div>
                      <div className="typeform-date">
                        Created: {moment(item.created_at).format("DD MM YYYY")}
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </Box>
        <button
          className={`${
            !selectedForm ? "continue-button-disabled" : "continue-button"
          }`}
          disabled={!selectedForm}
          onClick={() => createJobPost()}
        >
          Continue
        </button>
      </Box>
    );
  };
  return (
    <div id="add-employee-page" className="create-job-page">
      <Box className="container">
        <Box className="header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <Icon icon="arrow-left" size={11} color={"#7C7F93"} />
            <span className="btn-text">Back to Team</span>
          </button>
        </Box>
        <Box className="bodycontent">
          <Box className="sidetab">
            <span className="checklist-header">Hiring Checklist</span>
            <span className="checklist-action">Let’s get started</span>
            <div className="checklistdiv">
              <ul className="checklist">
                {sidebarList.map((item) => {
                  const IconComponent = item.icon;
                  return (
                    <li
                      onClick={() => {
                        if (item.index < step) {
                          setStep(item.index);
                        }
                      }}
                      className={`${
                        item.index < step ? "checkedlistitem" : ""
                      } ${item.index === step ? "activelistitem" : ""}`}
                    >
                      {item.index < step && (
                        <Icon
                          icon={"tick"}
                          size={15}
                          color={"#4385EF"}
                          style={{ marginRight: "22px" }}
                        />
                      )}

                      <span className="navtext">{item.name}</span>
                    </li>
                  );
                })}
                {/* <li><img src={BasicInformationIcon} height="15" width="15" /><span className="navtext">Basic Information</span></li>
                <li> <img src={JobDetailsIcon} height="15" width="15" /> <span className="navtext">Job Details</span></li>
                <li> <img src={SalaryBenefitsIcon} height="15" width="15" /> <span className="navtext">Salary & Benefits</span></li>
                <li> <img src={JobDescriptionIcon} height="15" width="15" /> <span className="navtext">Job Description</span></li>
                <li> <img src={QualificationIcon} height="15" width="15" /> <span className="navtext">Qualifications</span></li>
                <li> <img src={PreviewJobsIcon} height="15" width="15" /> <span className="navtext">Preview Job</span></li>
               */}
              </ul>
            </div>
          </Box>
          <Box className="tabcontent">
            <img src={logo} className="logo" />
            <Box>
              <div className="form-title">Job Posting</div>
              <div className="form-subtitle">Job Posting Creation Form</div>
            </Box>
            {step === 1 && renderJobBasics()}
            {step === 2 && renderJobDetails()}
            {step === 3 && renderSalaryBenefits()}
            {step === 4 && renderJobDescription()}
            {step === 5 && renderQualifications()}
            {step === 6 && renderPreviewJobPosting()}
            {step === 7 && renderSelectTypeForm()}
          </Box>
        </Box>
        <Toaster position={Position.BOTTOM_RIGHT} ref={refHandlers.toaster} />
        <FindLocationModal
          isOpen={showLocationModal}
          updateLocation={updateLocation}
          selectedLocations={selectedLocation}
          businesses={allBusinesses}
          onClose={() => setShowLocationModal(false)}
        />
      </Box>
    </div>
  );
};
export default CreateJobPosting;

{
  /*
  updateLocation={updateLocation} selectedLocations={selectedLocation}
*/
}
