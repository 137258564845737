// @ts-nocheck
import _ from "lodash";
import moment from "moment";
import { useState } from "react";

import { apiCall } from "controllers/core";

import { TangoOrder, TangoProductOrdered } from "types/reporting";

import firebase from "../../config/firebase";
import "./index.css";

const db = firebase.firestore();

interface Amount {
  subTotal: number;
  tax: number;
  deliveryFee: number;
  serviceChargeTotal: number;
  discountTotal: number;
  tip: number;
  grossTotal: number;
  netTotal: number;
  currency: string;
}

const Flex = () => {
  const yesterday = moment().startOf("day").add(4, "hours").toDate();
  const [allPricingModels, setAllPricingModels] = useState<TangoTax[]>([]);

  const [businessId, setBusinessId] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [tabId, setTabId] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");
  const [fee, setFee] = useState(0);
  const [stripeConnectId, setStripeConnectId] = useState("");
  const [country, setCountry] = useState("");
  const [isTest, setIsTest] = useState(false);

  const [customerId, setCustomerId] = useState("");
  const [finalAmount, setFinalAmount] = useState("");

  const [output, setOutput] = useState<{
    tab: Tab;
    orders: TangoOrder[];
    unpaidProducts: TangoProductOrdered[];
    paidProducts: TangoProductOrdered[];
    unpaidAmount: Amount;
  } | null>(null);

  const getTaxRateAndTangoFees = (
    allPricingModels: TangoTax[],
    orderChannel = "tangoPOS",
    orderType = "dineIn"
  ) => {
    const pricing = allPricingModels.filter(
      (price) =>
        orderChannel === price.orderChannel && orderType === price.orderType
    );

    if (pricing.length > 0) {
      return {
        taxRate: pricing[0].taxRate,
        tangoFeeCents: pricing[0].tangoFeeCents,
        tangoFeePercent: pricing[0].tangoFeePercent,
      };
    }

    return {
      taxRate: 0,
      tangoFeeCents: 0,
      tangoFeePercent: 0,
    };
  };

  const createAmount = (
    itemsInCart: TangoProductOrdered[],
    allPricingModels: TangoTax[],
    currency: string,
    businessSettings: TangoBusinessSettings | null = null,
    isSpeed: boolean = false,
    isCash: boolean = false,
    tip: number = 0
  ): Amount => {
    const taxesAndFees = getTaxRateAndTangoFees(allPricingModels);
    console.log(allPricingModels);
    console.log("Taxes and fees: ", taxesAndFees);

    let subTotal = 0;
    let discountTotal = 0;
    let tax = 0;

    const allUseDefaultTaxRate = !itemsInCart.some((item) => item.taxRate);

    itemsInCart.forEach((item) => {
      const taxRate = item.taxRate ? item.taxRate : taxesAndFees.taxRate;
      const totalRegularPrice =
        item.price +
        (item.selectedModifiers || []).reduce(
          (acc, val) => acc + (val.additionalCost || 0),
          0
        );
      const totalProductPrice =
        (item.discountedPrice || item.discountedPrice === 0
          ? item.discountedPrice
          : totalRegularPrice) * item.quantity;
      const totalProductDiscount =
        item.discountedPrice || item.discountedPrice === 0
          ? (totalRegularPrice - item.discountedPrice) * item.quantity
          : 0;

      subTotal += totalProductPrice;
      discountTotal += totalProductDiscount;
      tax += (taxRate / 100) * totalProductPrice;
    });

    if (isCash) {
      if (allUseDefaultTaxRate) {
        const finalTaxRate = taxesAndFees.taxRate;
        const oldPrice = subTotal + tax;
        const roundingFactor = isSpeed
          ? businessSettings?.speedCashRoundAmount || 0
          : businessSettings?.flexCashRoundAmount || 0;
        const roundingType = isSpeed
          ? businessSettings?.speedCashRoundType
          : businessSettings?.flexCashRoundType;

        if (roundingFactor !== 0) {
          switch (roundingType) {
            case "to":
              const factor = parseFloat(String(roundingFactor)) * 100;
              const upper = (Math.trunc(oldPrice / factor) + 1) * factor;
              const newToPrice =
                (upper - oldPrice) / factor < 0.5
                  ? upper
                  : oldPrice - (oldPrice % factor);
              subTotal = newToPrice / (1 + finalTaxRate / 100);
              tax = subTotal * (finalTaxRate / 100);
              break;
            case "down":
              const newDownPrice =
                oldPrice -
                (oldPrice % (parseFloat(String(roundingFactor)) * 100));
              subTotal = newDownPrice / (1 + finalTaxRate / 100);
              tax = subTotal * (finalTaxRate / 100);
              break;
            default:
              break;
          }
        }
      }
    }

    return {
      subTotal: subTotal,
      tax: tax,
      deliveryFee: 0,
      serviceChargeTotal: 0,
      discountTotal: discountTotal,
      tip: tip,
      grossTotal: subTotal + tax + discountTotal,
      netTotal: subTotal + tax,
      currency: currency,
    };
  };

  const getOrdersByTabId = async (tabId: string) => {
    const ordersSnapshot = await db
      .collection("Orders")
      .where("tabId", "==", tabId)
      .get();
    const orders: TangoOrder[] = [];
    ordersSnapshot.forEach((doc) => {
      if (doc.exists) {
        const order = doc.data() as TangoOrder;
        // @ts-ignore
        if (
          !!order.renderOrderInQueue &&
          order.orderApp !== "printer" &&
          !order.deleted &&
          !order.cancelled
        ) {
          orders.push(order);
        }
      }
    });

    return orders;
  };

  const convertToProduct = (product: any): any => {
    const finalProduct = {
      businessId: product.businessId,
      course: product.course,
      discountedPrice: product.discountedPrice,
      discountsAppliedToProduct: product.discountsAppliedToProduct,
      menuCategory: product.menuCategory,
      menuName: product.menuName,
      name: product.name,
      plu: product.plu,
      price: product.price,
      productId: product.productId,
      productOrderId: product.productOrderId,
      quantity: product.quantity,
      seat: product.seat,
      selectedModifiers: product.selectedModifiers,
      taxRate: product.taxRate,
      type: product.type,
    };
    return finalProduct;
  };

  const getTabByTabId = async (
    allPricingModels: TangoTax[],
    currency: string
  ): Promise<{
    tab: Tab;
    orders: TangoOrder[];
    unpaidProducts: TangoProductOrdered[];
    paidProducts: TangoProductOrdered[];
    unpaidAmount: Amount;
  } | null> => {
    if (tabId) {
      const tabDoc = await db.collection("Tabs").doc(tabId).get();
      if (tabDoc.exists) {
        const tab = tabDoc.data() as Tab;
        if (!tab.deleted && tab.status.open && !tab.paymentComplete) {
          const orders = await getOrdersByTabId(tabId);

          const allProducts = orders.reduce((acc, val) => {
            val.products.forEach((product) => {
              acc.push(convertToProduct(product));
            });
            return acc;
          }, [] as TangoProductOrdered[]);

          const paidProducts = tab.customer.reduce((acc, val) => {
            val;
            val.productsPaid.forEach((product: TangoProductOrdered) => {
              acc.push(convertToProduct(product));
            });
            return acc;
          }, [] as TangoProductOrdered[]);

          const unpaidProducts: TangoProductOrdered[] = _.differenceWith(
            allProducts,
            paidProducts,
            _.isEqual
          );

          return {
            tab: tab,
            orders: orders,
            unpaidProducts,
            paidProducts,
            unpaidAmount: createAmount(
              unpaidProducts,
              allPricingModels,
              currency
            ),
          };
        }
      }
    }

    return null;
  };

  const getPaymentAndGenerateCustomer = async (
    amount: Amount,
    paymentIntentId: string,
    country: string,
    isTest: boolean,
    tab: Tab,
    orders: { id: string }[]
  ) => {
    const url = apiCall("globalRetrievePaymentIntent");
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        paymentIntentId,
        country,
        isTest,
      }),
    });
    const result = await response.json();
    console.log("Response: ", result.response);

    const tabsCollection = db.collection("Tabs");
    const ordersCollection = db.collection("Orders");

    const composeUsableResponse = (response: any) => {
      if (response) {
        const charge =
          response.charges.data && response.charges.data.length
            ? response.charges.data[0]
            : null;

        if (charge) {
          const isInteracPayment =
            charge && charge.payment_method_details
              ? charge.payment_method_details.read_method === "interac_present"
              : false;
          const paymentDetails =
            charge && charge.payment_method_details
              ? charge.payment_method_details[
                  charge.payment_method_details.type
                ]
              : null;

          return {
            amount: response.amount,
            authorizationCode: charge.authorization_code,
            brand: paymentDetails.brand,
            cardholderName: paymentDetails.cardholder_name,
            chargeId: charge.id,
            clientSecret: "",
            country: paymentDetails.country,
            created: new Date(charge.created * 1000).toISOString(),
            currency: charge.currency,
            customerId: charge.customer,
            fingerprint: paymentDetails.fingerprint,
            funding: paymentDetails.funding,
            generatedCardId: paymentDetails.generated_card,
            isInteracPayment: isInteracPayment,
            lastFour: paymentDetails.last4,
            readMethod: paymentDetails.read_method,
            stripeId: response.id,
          };
        }
      }
    };

    const orderPayment = composeUsableResponse(result);

    if (orderPayment) {
      // Generate the customer model with the new discount
      const successfulCharge = {
        id: orderPayment.chargeId,
        amount: orderPayment.amount,
        timestampCharged: new Date(),
        paymentVendor: "stripe",
        applicationFeeAmount: response.application_fee_amount,
      };

      const customerModel = {
        firstName: "",
        lastName: "",
        email: "",
        phone: 0,
        productsPaid: remainingProducts,
        userId: "",
        payment: orderPayment,
        splitType: "customSplit",
        orderType: "dineIn",
        orderChannel: "tangoPOS",
        isDineInOrder: true,
        amount: {
          netTotal: orderPayment.amount,
          refundPayload: null,
          refundedAmount: 0,
          deliveryFee: 0,
          tip: response.amount_details.tip,
          tax: amount.tax,
        },
      };
      const customers = [...(tab.customer || []), customerModel];

      console.log("Customers: ", customers);

      // await tabsCollection.doc(tab.id).update({
      //   "status.open": false,
      //   "status.data": "Tab is closed",
      //   paymentComplete: true,
      //   updatedAt: FieldValue.serverTimestamp(),
      //   customer: customers,
      // });

      // for await (const order of orders) {
      //   await ordersCollection.doc(order.id).update({ completed: true });
      // }
    }
  };

  const getTabByTableId = async (
    allPricingModels: TangoTax[],
    currency: string
  ): Promise<{
    tab: Tab;
    orders: TangoOrder[];
    unpaidProducts: TangoProductOrdered[];
    paidProducts: TangoProductOrdered[];
    unpaidAmount: Amount;
  } | null> => {
    if (tableNumber) {
      const tabsSnapshot = await db
        .collection("Tabs")
        .where("updatedAt", ">=", yesterday)
        .where("businessId", "==", businessId)
        .where("paymentComplete", "==", false)
        .get();

      const tabs: Tab[] = [];
      tabsSnapshot.forEach((doc) => {
        if (doc.exists) {
          const tab = doc.data() as Tab;
          if (
            !tab.deleted &&
            tab.status.open &&
            !tab.paymentComplete &&
            tab.tableNumber === parseInt(tableNumber)
          ) {
            tabs.push(tab);
          }
        }
      });

      if (tabs.length > 0) {
        const orders = await getOrdersByTabId(tabs[0].id);

        const allProducts = orders.reduce((acc, val) => {
          val.products.forEach((product) => {
            acc.push(convertToProduct(product));
          });
          return acc;
        }, [] as TangoProductOrdered[]);

        const paidProducts = tabs[0].customer.reduce((acc, val) => {
          val;
          val.productsPaid.forEach((product: TangoProductOrdered) => {
            acc.push(convertToProduct(product));
          });
          return acc;
        }, [] as TangoProductOrdered[]);

        console.log(allProducts);
        console.log(paidProducts);
        const unpaidProducts: TangoProductOrdered[] = _.differenceWith(
          allProducts,
          paidProducts,
          _.isEqual
        );

        return {
          tab: tabs[0],
          orders: orders,
          unpaidProducts,
          paidProducts,
          unpaidAmount: createAmount(
            unpaidProducts,
            allPricingModels,
            currency
          ),
        };
      }
    }
    return null;
  };

  const getBusinessInfo = async () => {
    if (businessId) {
      const bsSnapshot = await db
        .collection("Businesses")
        .doc(businessId)
        .get();
      if (bsSnapshot.exists) {
        const businessInfo = bsSnapshot.data() as TangoBusiness;

        return businessInfo;
      }
      return null;
    } else {
      alert("Please type the business info.");
    }
  };

  const executePayment = async () => {
    const response = await fetch(
      "https://us-central1-tango-2.cloudfunctions.net/globalCaptureStripePayment",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          paymentIntentId,
          customerId,
          generatedCardId: null,
          preAuthAmount: finalAmount,
          finalAmount: Math.round(parseFloat(finalAmount) * 100),
          currency,
          description,
          applicationFeeAmount: fee,
          stripeConnectId,
          country,
          isTest,
        }),
      }
    );

    // Check for response status of 200
    if (response.status === 200) {
      const text = await response.json();
      return text;
    } else {
      const err = await response.json();
      return null;
    }
  };

  const queryTabOrTableId = async () => {
    // Get the business information
    const businessInfo = await getBusinessInfo();
    if (businessInfo) {
      const country = businessInfo.location.country;
      const isTest = businessInfo.businessId === "ftLhD4UUP7lHkfLu7iF5";
      const currency = businessInfo.currency.toUpperCase();
      const pricingModels: TangoTax[] = [].concat.apply(
        [],
        businessInfo.tangoProducts.map((model: DocumentData) => {
          const { name } = model;
          return model.orderTypes.map((data: DocumentData) => {
            return {
              orderChannel: name,
              orderType: data.name,
              taxRate: data.taxRate,
              tangoFeeCents: data.tangoFeeCents,
              tangoFeePercent: data.tangoFeePercent,
            };
          });
        })
      );

      // Get the tab information
      const tableResult = await getTabByTableId(pricingModels, currency);
      const tabResult = await getTabByTabId(pricingModels, currency);

      setCurrency(currency);
      setDescription("Terminal payment at " + businessInfo.businessName);
      // const [fee, setFee] = useState('')
      setStripeConnectId("");
      setCountry(country);
      setIsTest(isTest);

      if (tableResult) {
        setOutput(tableResult);
        const amount = createAmount(
          tableResult.unpaidProducts,
          allPricingModels,
          currency
        );

        setFee(amount.serviceChargeTotal);
      } else if (tabResult) {
        setOutput(tabResult);
        const amount = createAmount(
          tabResult.unpaidProducts,
          allPricingModels,
          currency
        );
        setFee(amount.serviceChargeTotal);
      } else {
        setOutput(null);
      }
    }
  };

  return (
    <div className="report flex-page">
      <div className="payments">
        <div className="flex left">
          <div className="input-field">
            <div className="label">Business ID:</div>
            <input
              className="id"
              value={businessId}
              onChange={(e) => setBusinessId(e.target.value)}
            />
          </div>
          <br />
          <div className="input-field">
            <div className="label">Table number:</div>
            <input
              className="id"
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
              disabled={!!tabId.length}
            />
          </div>
          <br />
          <div className="input-field">
            <div className="label">Tab ID:</div>
            <input
              className="id"
              value={tabId}
              onChange={(e) => setTabId(e.target.value)}
              disabled={!!tableNumber.length}
            />
          </div>
          <br />
          {output ? (
            <>
              <div className="input-field">
                <div className="label">Payment ID:</div>
                <input
                  className="id"
                  value={paymentIntentId}
                  onChange={(e) => setPaymentIntentId(e.target.value)}
                />
              </div>
              <br />
              <div className="input-field">
                <div className="label">Customer ID:</div>
                <input
                  className="id"
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                />
              </div>
              <br />
              <div className="input-field">
                <div className="label">Final amount (after tip in $):</div>
                <input
                  className="id"
                  value={finalAmount}
                  onChange={(e) => setFinalAmount(e.target.value)}
                />
              </div>
              <br />
            </>
          ) : null}
          {!output ? (
            <button onClick={queryTabOrTableId}>Enter</button>
          ) : (
            <button
              onClick={async () =>
                await getPaymentAndGenerateCustomer(
                  output.unpaidAmount,
                  paymentIntentId,
                  country,
                  isTest
                )
              }
            >
              Charge
            </button>
          )}
        </div>
        <div className="flex right">
          Common businesses:
          <ul>
            <li>
              <b>vkVT6JpeOPrg3hvaNgnv</b>: The Coop Niagara
            </li>
            <li>
              <b>SXKDiMEA7EtUUrD5UAUl</b>: The Coop Port Carling
            </li>
            <li>
              <b>ftLhD4UUP7lHkfLu7iF5</b>: Grub on Grenville
            </li>
            <li>
              <b>5fc13f6dc70feebea3ed0bbb</b>: The Coop Brant Street
            </li>
            <li>
              <b>5fcfea765ab62ce00616aeac</b>: The Coop Hamilton
            </li>
          </ul>
          {output ? (
            <div className="output">
              <h2>Paid Products: </h2>
              <h3>TabID: {output?.tab.id}</h3>
              <ul>
                {output.paidProducts.map((product, i) => {
                  return (
                    <li className={"order-" + i} key={i}>
                      {/* @ts-ignore */}
                      {`SEAT ${(product.seat || "-") + " - "}  ${
                        product.quantity
                      }x ${product.name}`}
                    </li>
                  );
                })}
              </ul>
              <br />
              <h2>Unpaid Products: </h2>
              <h3>TabID: {output?.tab.id}</h3>
              <ul>
                {output.unpaidProducts.map((product, i) => {
                  return (
                    <li className={"order-" + i} key={i}>
                      {/* @ts-ignore */}
                      {`SEAT ${(product.seat || "-") + " - "}  ${
                        product.quantity
                      }x ${product.name}`}
                    </li>
                  );
                })}
              </ul>
              <div>
                Subtotal: ${Math.round(output.unpaidAmount.subTotal) / 100}
              </div>
              <div>Tax: ${Math.round(output.unpaidAmount.tax) / 100}</div>
              <div>
                Total: ${Math.round(output.unpaidAmount.netTotal) / 100}
              </div>
              <br />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Flex;
