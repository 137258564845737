const RowRenderer = (props) => {
  const {
    as: Tag,
    cellAs: Cell,
    className,
    row,
    selected,
    onSelectChanged,
    showModalRow,
    setIndexOfModalToOpen,
    isModalViewOnly,
    rowSelected,
  } = props;
  return (
    <Tag
      className={className}
      style={{ backgroundColor: selected ? "#ddedfa" : "#fff" }}
    >
      <Cell className="action-cell cell" style={{ backgroundColor: "#fff" }}>
        <input
          type="checkbox"
          checked={rowSelected}
          onChange={(e) => onSelectChanged(row, e.target.checked)}
          style={{ width: "auto" }}
        />
      </Cell>
      {props.children}
      {showModalRow && (
        <Cell className="modal-cell cell" style={{ width: "16.6667%" }}>
          <span className="value-viewer">
            <button
              className="edit"
              style={{ margin: 10 }}
              onClick={() => setIndexOfModalToOpen(props.cells[0].index)}
            >
              <img
                src={require("../../assets/open-modal.svg").default}
                style={{ width: "10px", marginRight: "8px" }}
              />{" "}
              {isModalViewOnly ? "View" : "Edit"}
            </button>
          </span>
        </Cell>
      )}
    </Tag>
  );
};

export default RowRenderer;
