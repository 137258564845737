import "@blueprintjs/core/lib/css/blueprint.css";
// required for blueprint js to work
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
// required for blueprint icons js to work
import "@blueprintjs/select/lib/css/blueprint-select.css";
// required for blueprint selects to be styled
import "@blueprintjs/table/lib/css/table.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// required for blueprint table to be styled
import "normalize.css";
// required for blueprint js to work with css
import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { isElectron } from "utils";

import { apiErrorHandler } from "controllers/core";

import store from "model/store";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://de7db556849847e8927506d140d9908c@o509640.ingest.sentry.io/6112004",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !(!process.env.NODE_ENV || process.env.NODE_ENV === "development"),
});

const rootElement = document.getElementById("root");

const PlatformRouter = isElectron() ? HashRouter : BrowserRouter;
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: apiErrorHandler,
  }),
});

console.log("ReactQueryDevtools", ReactQueryDevtools);

render(
  <React.Fragment>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={true} />
        <PlatformRouter>
          <App />
        </PlatformRouter>
      </QueryClientProvider>
    </Provider>
  </React.Fragment>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
