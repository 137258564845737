import axios from "axios";

import { apiErrorHandler, baseUrl } from "./core";
import { generateBearerToken } from "./init";

const tangoTeamApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/team`,
});

tangoTeamApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

// TODO: Extract to tango-shared for reusability
export interface TeamMember {
  uniqueId: string;
  name: string;
  locationName: string;
  employmentType: string;
  formattedStartDate: string;
  primaryRoleTitle: string;
}
// TODO: Extract to tango-shared for reusability
export interface ArchivedTeamMember {
  uniqueId: string;
  name: string;
  locationName: string;
  employmentType: string;
  formattedStartDate: string;
  primaryRoleTitle: string;
  formattedArchivedDate: string;
}
// TODO: Extract to tango-shared for reusability
export interface EmployeeDetailsBasicInfo {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  formattedDOB: string | null;
  profileUrl: string | null;
  abbreviatedName: string;
}
// TODO: Extract to tango-shared for reusability
export interface EmployeeDetailsAddressInfo {
  streetAddress: string | null;
  unit: string | null;
  city: string | null;
  zipCode: string | null;
  state: string | null;
  country: string | null;
}
// TODO: Extract to tango-shared for reusability
export interface EmployeeDetailsEmergencyContactInfo {
  name: string | null;
  phone: string | null;
  relation: string | null;
}
// TODO: Extract to tango-shared for reusability
export type RoleInfo = {
  roleId: string;
  roleTitle: string;
};

// TODO: Extract to tango-shared for reusability
export interface EmployeeDetailsEmploymentInfo {
  employmentType: string;
  formattedEmploymentStartDate: string;
  managerName: string | null;
  locations: string[];
  permission: string;
  department: string | null;
  level: string | null;
  primaryRole: RoleInfo;
  secondaryRoles: RoleInfo[];
  compensationType: string;
  externalId: string | null;
  pinCode: number | null;
  isAdmin: boolean;
}

// TODO: Extract to tango-shared for reusability
export type EmployeeCompensationItem = {
  roleId: string;
  roleTitle: string;
  rate: number;
  isPrimary: boolean;
};

// TODO: Extract to tango-shared for reusability
export type AvailabilityEntry = {
  day: string;
  startTime: string;
  endTime: string;
  available: boolean;
};

// TODO: Extract to tango-shared for reusability
export interface EmployeeDetails {
  uid: string;
  basicInfo: EmployeeDetailsBasicInfo;
  addressInfo: EmployeeDetailsAddressInfo;
  emergencyContactInfo: EmployeeDetailsEmergencyContactInfo;
  employmentInfo: EmployeeDetailsEmploymentInfo;
  compensationInfo: EmployeeCompensationItem[];
  availabilityInfo: AvailabilityEntry[];
}

// TODO: Extract to tango-shared for reusability
export interface JobRolesData {
  uniqueId: string;
  title: string;
  departmentId: string | null;
  departmentTitle: string | null;
  numberOfEmployees: number;
}

export type AddRolesData = {
  title: string;
  departmentId: "boh" | "foh";
};

export type UpdateRolesData = {
  roleId: string;
  title: string;
  departmentId: "boh" | "foh";
};

export const fetchTeamTableData = async (businessId: string) => {
  const res = await tangoTeamApiClient.get<TeamMember[]>(
    `/team-table-data/${businessId}`
  );
  return res.data;
};

export const archiveEmployee = async (businessId: string, uid: string) => {
  await tangoTeamApiClient.put(`/archive-employee/${businessId}/${uid}`);
};

export const unArchiveEmployee = async (businessId: string, uid: string) => {
  await tangoTeamApiClient.put(`/unarchive-employee/${businessId}/${uid}`);
};

export const fetchJobRolesTableData = async (businessId: string) => {
  const res = await tangoTeamApiClient.get<JobRolesData[]>(
    `/roles/${businessId}`
  );
  return res.data;
};

export const deleteRoles = async (businessId: string, rolesIds: string[]) => {
  await tangoTeamApiClient.post(`/delete-roles`, {
    businessId,
    rolesIds,
  });
};

export const addRoles = async (businessId: string, roles: AddRolesData[]) => {
  await tangoTeamApiClient.post(`/add-roles`, {
    businessId,
    roles,
  });
};

export const updateRoles = async (
  businessId: string,
  roles: UpdateRolesData[]
) => {
  await tangoTeamApiClient.post(`/update-roles`, {
    businessId,
    roles,
  });
};

export const fetchArchivedTeamTableData = async (businessId: string) => {
  const res = await tangoTeamApiClient.get<ArchivedTeamMember[]>(
    `/team-table-data/${businessId}/archived`
  );
  return res.data;
};

export const updateEmployeeEmergencyContactInfo = async (
  businessId: string,
  uid: string,
  name: string | null,
  phone: string | null,
  relationship: string | null
) => {
  const res = await tangoTeamApiClient.put(
    `/employee-details/emergency-contact`,
    {
      businessId,
      uid,
      name,
      phone,
      relationship,
    }
  );
  return res.data;
};

export const updateEmployementInfo = async (
  businessId: string,
  uid: string,
  primaryRole: string,
  secondaryRoles: string[],
  isAdmin: boolean,
  externalId?: string | null
) => {
  const res = await tangoTeamApiClient.put(
    `/employee-details/employment-info`,
    {
      businessId,
      uid,
      primaryRole,
      secondaryRoles,
      isAdmin,
      externalId: externalId ?? null,
    }
  );
  return res.data;
};

export const updateCompensationInfo = async (
  businessId: string,
  uid: string,
  payRates: PayRate[]
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/compensation`, {
    businessId,
    uid,
    payRates,
  });
  return res.data;
};

export const updateAvailabilityInfo = async (
  businessId: string,
  uid: string,
  availabilityEntries: {
    day: string;
    startTime: string;
    endTime: string;
    available: boolean;
  }[]
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/availability`, {
    businessId,
    uid,
    availabilityEntries,
  });
  return res.data;
};

export const updateEmployeeBasicInfo = async (
  businessId: string,
  uid: string,
  firstName: string,
  lastName: string,
  dob: string | null
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/basicInfo`, {
    businessId,
    uid,
    firstName,
    lastName,
    dateOfBirth: dob,
  });
  return res.data;
};

export const updateEmployeePhoneNumber = async (
  businessId: string,
  uid: string,
  newPhoneNumber: string | null
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/phone`, {
    businessId,
    uid,
    phoneNumber: newPhoneNumber,
  });
  return res.data;
};

export const updateEmployeeEmail = async (
  businessId: string,
  uid: string,
  email: string | null
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/email`, {
    businessId,
    uid,
    email,
  });
  return res.data;
};

export const updateEmployeeAddress = async (
  businessId: string,
  uid: string,
  address: string | null,
  unit: string | null,
  city: string | null,
  state: string | null,
  zip: string | null,
  country: string | null
) => {
  const res = await tangoTeamApiClient.put(`/employee-details/address`, {
    businessId,
    uid,
    address,
    unit,
    city,
    state,
    zip,
    country,
  });
  return res.data;
};

export const fetchEmployeeDetails = async (
  businessId: string,
  staffUID: string
) => {
  const res = await tangoTeamApiClient.get<EmployeeDetails>(
    `/employee-details/${businessId}/${staffUID}`
  );
  return res.data;
};
