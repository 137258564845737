import * as React from "react";

export const AddSubsciber = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      d="M9.5 9.5C11.2188 9.5 12.625 8.09375 12.625 6.375C12.625 4.65625 11.2188 3.25 9.5 3.25C7.78125 3.25 6.375 4.65625 6.375 6.375C6.375 8.09375 7.78125 9.5 9.5 9.5ZM9.5 5.33333C10.0729 5.33333 10.5417 5.80208 10.5417 6.375C10.5417 6.94792 10.0729 7.41667 9.5 7.41667C8.92708 7.41667 8.45833 6.94792 8.45833 6.375C8.45833 5.80208 8.92708 5.33333 9.5 5.33333ZM15.75 14.2708C15.75 11.6667 11.6146 10.5417 9.5 10.5417C7.38542 10.5417 3.25 11.6667 3.25 14.2708V15.75H15.75V14.2708ZM5.83333 13.6667C6.60417 13.1354 8.15625 12.625 9.5 12.625C10.8438 12.625 12.3958 13.1354 13.1667 13.6667H5.83333ZM16.7917 0.125H2.20833C1.05208 0.125 0.125 1.0625 0.125 2.20833V16.7917C0.125 17.9375 1.05208 18.875 2.20833 18.875H16.7917C17.9375 18.875 18.875 17.9375 18.875 16.7917V2.20833C18.875 1.0625 17.9375 0.125 16.7917 0.125ZM16.7917 16.7917H2.20833V2.20833H16.7917V16.7917Z"
      fill={color}
    />
  </svg>
);
