import { FirebaseOrderDoc } from "types/order";

import { ordersAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseOrderDoc[] = [];

export default function orders(state = initialState, action: ordersAction) {
  switch (action.type) {
    case ActionType.RECEIVE_ORDERS:
      return action.orders;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
