import { FirebaseMenuDoc } from "types/menus";

import { MenusAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseMenuDoc[] = [];

export default function menus(state = initialState, action: MenusAction) {
  switch (action.type) {
    case ActionType.RECEIVE_MENUS:
      return action.menus;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
