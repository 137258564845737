import comps from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo } from "react";

import { getInitials, selectColor } from "utils/strings";

import "./style.css";

const { Dropdown } = comps;

type Props = {
  options: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  selected: string | null;
  onSelect?: (id: string) => unknown;
  disabled?: boolean;
};

const AuthorSelector = (props: Props) => {
  const selected = props.options.find(({ id }) => id == props.selected);
  const name = selected?.firstName + " " + selected?.lastName;
  const initials = getInitials(name);
  const style = useMemo(
    () => ({
      backgroundColor: selectColor(name),
    }),
    [name]
  );
  const labeledPeople = useMemo(
    () =>
      props.options.map((option) => ({
        value: option.id,
        label: option.firstName + " " + option.lastName,
      })),
    [props.options]
  );
  const selection = labeledPeople.find(({ value }) => value === props.selected);
  const handleSelect = useCallback(
    (x) => props.onSelect?.(x.value),
    [props.onSelect]
  );
  return (
    <div className="daily-note-author">
      <div style={style} className="daily-note-user-image">
        {initials}
      </div>
      <Dropdown
        size="medium"
        className="author-select-dropdown"
        options={labeledPeople}
        value={selection}
        onChange={handleSelect}
        isDisabled={props.disabled}
      />
    </div>
  );
};
export default AuthorSelector;
