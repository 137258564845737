const CellRenderer = (props) => {
  const {
    as: Tag,
    cell,
    row,
    col,
    columns,
    attributesRenderer,
    selected,
    editing,
    updated,
    style,
    grid,
    errorManager,
    ...rest
  } = props;

  // Find the cells with an error
  let backgroundColor = "#fff";
  const errorColor = "rgba(255, 119, 119, 0.2)";
  const errors = errorManager.errors.filter(
    (error) => error.rowIndex === cell.index && !error.isEdited
  );
  if (errors.length > 0) {
    errors.forEach((error) => {
      // Select the entire row if a duplicateRows error
      if (error.errorType === "duplicateRows") {
        backgroundColor = errorColor;
      } else {
        // Find the cells with issues using error.columnNames
        const errorColumns = error.columnNames.map(
          (errorColumn) => errorColumn.columnName
        );
        const colIndex = columns.findIndex((column) =>
          errorColumns.includes(column.label)
        );
        if (colIndex !== -1) {
          if (col === colIndex) {
            backgroundColor = errorColor;
          }
        }
      }
    });
  }

  const attributes = cell.attributes || {};
  attributes.style = { width: columns[col].width, backgroundColor };
  if (col === 0) {
    attributes.title = cell.label;
  }

  // Images
  if (props.children.props.cell.isImage) {
    return (
      <Tag {...rest} {...attributes}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            alt="itemImage"
            loading="lazy"
            src={props.children.props.cell.value}
            width="100"
            height="100"
            style={{
              display:
                props.children.props.cell.value === null ||
                props.children.props.cell.value === "null" ||
                props.children.props.cell.value.length === 0
                  ? "none"
                  : "block",
              objectFit: "contain",
            }}
          />
        </div>
      </Tag>
    );
  }

  const rowData = grid.find(
    (cell) => cell[0].index === props.children.props.cell.index
  );
  const prefix = props.children.props.cell.isCustomText
    ? props.children.props.cell.customText.prefix
    : null;
  const suffix = props.children.props.cell.isCustomText
    ? props.children.props.cell.customText.suffix
    : null;
  const customTransform =
    props.children.props.cell.isCustomText &&
    props.children.props.cell.customTransform
      ? (v) => props.children.props.cell.customTransform(v)
      : null;
  const transform = props.children.props.cell.isCustomText
    ? new Function(
        `return ${props.children.props.cell.customText.customFunction}`
      )()
    : null;

  const findDependantData = (independentColumn, dependentColumn, data) => {
    const index = data.findIndex((cell) => cell.key === independentColumn);
    if (index !== -1) {
      const independentValue = data[index].value;
      const dataIndex =
        props.children.props.cell.dependantColumn.data.findIndex(
          (doc) => doc[independentColumn] === independentValue
        );
      if (dataIndex !== -1) {
        return props.children.props.cell.dependantColumn.data[dataIndex][
          dependentColumn
        ];
      }
    }
    return null;
  };

  const extractValueBasedOnProvidedTransform = () => {
    // if (customTransform) {
    //   return (prefix || '') + (customTransform ? customTransform(props.children.props.cell.value) : props.children.props.cell.value) + (suffix || '');
    // }
    if (transform) {
      return (
        (prefix || "") +
        (transform
          ? transform(props.children.props.cell.value)
          : props.children.props.cell.value) +
        (suffix || "")
      );
    }
  };

  switch (props.children.props.cell.type) {
    case "value":
      const value = extractValueBasedOnProvidedTransform();
      let dependentValue = null;
      if (props.children.props.cell.isDependantColumn && rowData) {
        dependentValue = findDependantData(
          props.children.props.cell.dependantColumn.independentColumn,
          props.children.props.cell.dependantColumn.dependentColumn,
          rowData
        );
        // console.log("CELL INFO: ", props.children.props.cell)
        // console.log("FOUND DEPENDENT VALUE: ", dependentValue)
      }

      /*
       * Avoid showing background color for single tag or text with empty values
       */
      if (!dependentValue) {
        if (typeof value === "string") {
          if (value.length === 0) {
            return <Tag {...rest} {...attributes} />;
          }
        } else if (isNaN(value)) {
          return <Tag {...rest} {...attributes} />;
        }
      }

      // Single value dropdown
      if (props.children.props.cell.isDropdown) {
        let colorIndex = null;
        let color = "#FFF";

        if (!props.children.props.cell.dropdown.isIndependent) {
          const allColors = props.children.props.cell.dropdown.options.reduce(
            (acc, val) => [...acc, ...val.options],
            []
          );
          colorIndex = props.children.props.cell.isDropdown
            ? allColors.findIndex((option) => option.optionName === value)
            : -1;
          color =
            colorIndex !== -1
              ? `rgba(${allColors[colorIndex].color[0]},${allColors[colorIndex].color[1]},${allColors[colorIndex].color[2]}, 0.2)`
              : "#FFF";
        } else {
          colorIndex = props.children.props.cell.dropdown.options.findIndex(
            (option) => option.optionName === props.children.props.cell.value
          );
          color =
            colorIndex !== -1
              ? `rgba(${props.children.props.cell.dropdown.options[colorIndex].color[0]},${props.children.props.cell.dropdown.options[colorIndex].color[1]},${props.children.props.cell.dropdown.options[colorIndex].color[2]}, 0.2)`
              : "#FFF";
        }

        return (
          <Tag {...rest} {...attributes}>
            <div
              style={{
                paddingTop: 7,
                paddingLeft: 3,
                paddingBottom: 7,
                paddingRight: 3,
              }}
            >
              <div
                style={{
                  backgroundColor: color,
                  color: colorIndex !== -1 ? "#000" : "#fff",
                  padding: "3px 8px",
                  borderRadius: 3,
                  fontSize: 12,
                  display: "inline-block",
                }}
              >
                {dependentValue || value}
              </div>
            </div>
          </Tag>
        );
      }

      // Regular text/number
      return (
        <Tag {...rest} {...attributes}>
          <div
            style={{
              paddingTop: 7,
              paddingLeft: 3,
              paddingBottom: 7,
              paddingRight: 3,
            }}
          >
            {dependentValue || value}
          </div>
        </Tag>
      );
    case "arrayOfValue":
      let arrayValue = props.children.props.cell.value;
      // Handle dependant columns
      if (props.children.props.cell.isDependantColumn) {
        const dependentValue = findDependantData(
          props.children.props.cell.dependantColumn.independentColumn,
          props.children.props.cell.dependantColumn.dependentColumn,
          rowData
        );

        arrayValue = dependentValue;
      }

      /*
       * Avoid showing background color for elements with no tags
       */
      if (arrayValue ? arrayValue.length === 0 : true) {
        return <Tag {...rest} {...attributes} />;
      }

      // Multiselect dropdown
      return (
        <Tag {...rest} {...attributes}>
          <div
            style={{
              display: "flex",
              height: "fit-content",
              flexDirection: "row",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {(props.children.props.cell.isDependantColumn && rowData
              ? findDependantData(
                  props.children.props.cell.dependantColumn.independentColumn,
                  props.children.props.cell.dependantColumn.dependentColumn,
                  rowData
                )
              : props.children.props.cell.value.split(",")
            ).map((value, index) => {
              const finalValue =
                (prefix || "") +
                (transform ? transform(value.trim()) : value.trim()) +
                (suffix || "");

              let colorIndex = null;
              let color = "#D3D3D3";

              if (
                props.children.props.cell.dropdown
                  ? !props.children.props.cell.dropdown.isIndependent
                  : false
              ) {
                const allColors =
                  props.children.props.cell.dropdown.options.reduce(
                    (acc, val) => [...acc, ...val.options],
                    []
                  );
                colorIndex = props.children.props.cell.isDropdown
                  ? allColors.findIndex((option) => option.optionName === value)
                  : -1;
                color =
                  colorIndex !== -1
                    ? `rgba(${allColors[colorIndex].color[0]},${allColors[colorIndex].color[1]},${allColors[colorIndex].color[2]}, 0.2)`
                    : "#D3D3D3";
              } else {
                colorIndex = props.children.props.cell.isDropdown
                  ? props.children.props.cell.dropdown.options.findIndex(
                      (option) => option.optionName === value
                    )
                  : -1;
                color =
                  colorIndex !== -1
                    ? `rgba(${props.children.props.cell.dropdown.options[colorIndex].color[0]},${props.children.props.cell.dropdown.options[colorIndex].color[1]},${props.children.props.cell.dropdown.options[colorIndex].color[2]}, 0.2)`
                    : "#D3D3D3";
              }

              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: color,
                    margin: 5,
                    color: "#000",
                    padding: "3px 8px",
                    borderRadius: 3,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}
                >
                  {finalValue}
                </div>
              );
            })}
          </div>
        </Tag>
      );
    default:
      return (
        <Tag {...rest} {...attributes}>
          <div
            style={{
              width: "100%",
              height: "100%",
              paddingTop: 7,
              paddingLeft: 3,
              paddingBottom: 7,
              paddingRight: 3,
            }}
          >
            {props.children}
          </div>
        </Tag>
      );
  }
};

export default CellRenderer;
