import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const { Icon } = UIComponents;
export type SidebarRoute = {
  label: string;
  link: string;
  subRoutes: { label: string; link: string }[];
  isExpanded: boolean;
};

type Props = {
  routes: SidebarRoute[];
};
const Sidebar = ({ routes }: Props) => {
  const [menuItems, setMenuItems] = useState([...routes]);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  return (
    <ul className={"p-0 m-0 list-none mt-6"}>
      {menuItems.map((item, index) => {
        const hasSubRoutes = item.subRoutes.length > 0;
        return (
          <>
            <li
              key={index}
              className={`py-2 pl-5 pr-1 ${pathname + search === item.link
                ? "bg-blue-grey-2 font-lato-bold text-black"
                : "font-lato-medium text-grey-3"
                } text-xs hover:bg-blue-grey-2 cursor-pointer`}
              onClick={() => {
                if (hasSubRoutes) {
                  if (item.isExpanded) {
                    navigate(item.link);
                  }
                  const updatedRoutes = [...menuItems];
                  updatedRoutes[index]["isExpanded"] =
                    !updatedRoutes[index]["isExpanded"];
                  setMenuItems(updatedRoutes);
                } else {
                  navigate(item.link);
                }
              }}
            >
              <div className={"flex items-center justify-between"}>
                {item.label}
                {hasSubRoutes ? (
                  <Icon
                    name={`${item.isExpanded ? "chevron-down" : "chevron-right"
                      }`}
                    size={"12"}
                  />
                ) : null}
              </div>
            </li>
            {hasSubRoutes && item.isExpanded
              ? item.subRoutes.map((subRoute) => {
                return (
                  <li
                    key={subRoute.link}
                    className={`py-2 pl-9 ${pathname + search === subRoute.link
                      ? "font-lato-bold text-black"
                      : "font-lato-medium text-grey-3"
                      } text-tiny hover:bg-blue-grey-2 cursor-pointer`}
                    onClick={() => navigate(subRoute.link)}
                  >
                    {subRoute.label}
                  </li>
                );
              })
              : null}
          </>
        );
      })}
    </ul>
  );
};
export default Sidebar;
