import { Icon } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import firebase from "config/firebase";
import { find, findIndex } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Box from "components/Box";
import Spinner from "components/Spinner";

import { RootState } from "model/store";

import { HashIcon } from "assets/Icons/HashIcon";

import {
  sendCampaignEmails,
  sendTestCampaignEmail,
} from "../../../controllers/auth";
import Layout from "../../MarketingFeedback/Layout";
import hashIcon from "../icons/hashIcon.svg";
import AddSubscriberAlert from "./Alert/addSubscriberAlert";
import TemplateModal from "./model/templateChoose";
import TemplateNameModal from "./model/templateName";
import "./styles.css";

const CreateCampaign = () => {
  const navigate = useNavigate();
  const db = firebase.firestore();

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [recipients, setRecipients]: any = useState([]);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
  const [showTemplateNameModal, setShowTemplateNameModal] =
    useState<boolean>(false);
  const [listOptions, setListOptions]: any = useState([]);
  const [selectedTemplate, setSelectedTemplate]: any = useState({});
  const [audienceList, setAudienceList]: any = useState([]);
  const [tags, setTags]: any = useState([]);
  const [activeTags, setActiveTags]: any = useState([]);
  const [isAlertOpen, setAlertOpen] = useState<boolean>(false);

  const [emailInfo, setEmailInfo] = useState({
    To: null,
    senderName: "",
    senderEmail: "",
    subjectLine: "",
  });

  const updateEmailInfo = (key: string, value: string | number) => {
    setEmailInfo({
      ...emailInfo,
      [key]: value,
    });
  };

  const sendTestEmail = async () => {
    setLoading(true);
    try {
      await sendTestCampaignEmail(
        emailInfo.senderEmail,
        emailInfo.senderName,
        emailInfo.senderEmail,
        emailInfo.subjectLine,
        selectedTemplate && selectedTemplate.id
      );
      alert(`Test email sent successfully to ${emailInfo.senderEmail}`);
    } catch (e) {
      console.log(e);
      alert(`Error while sending test email to ${emailInfo.senderEmail}`);
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = () => {
    console.log("in sendEmail function");
    console.log("emailInfo", emailInfo);
    console.log("To", recipients);

    let payload = {
      id: null,
      campaignName: emailInfo.subjectLine,
      businessId: business.id,
      groups: recipients
        .filter((item: any) => item.type === "group")
        .map((item: any) => item.value),
      individual: recipients
        .filter((item: any) => item.type === "individual")
        .map((item: any) => {
          return {
            name: item.label,
            email: item.email,
            id: item.collectionId,
          };
        }),
      senderName: emailInfo.senderName,
      senderEmail: emailInfo.senderEmail,
      subjectLine: emailInfo.subjectLine,
      deleted: false,
      updatedAt: new Date(),
      deletedAt: null,
      createdAt: new Date(),
      audience: null,
      tags: activeTags.length > 0 ? activeTags : null,
      status: "Draft",
      template: {
        name: selectedTemplate && selectedTemplate.templateName,
        body: selectedTemplate && selectedTemplate.templateBody,
        templateId: selectedTemplate && selectedTemplate.id,
      },
      report: {
        opened: 0,
        clicked: 0,
        bounced: 0,
        unsubscribed: 0,
        successfullDeliveries: "0",
        clicksPerUniqueOpens: "0",
        totalOpens: 0,
        totalClicks: 0,
        lastOpened: new Date(),
        lastclicks: new Date(),
        forwaded: 0,
        abuseReports: null,
      },
      users: null,
    };

    try {
      setLoading(true);
      db.collection("Campaigns")
        .add(payload)
        .then((docRef) => {
          db.collection("Campaigns")
            .doc(docRef.id)
            .update({ id: docRef.id })
            .then(async () => {
              try {
                await sendCampaignEmails(docRef.id);
              } catch (e) {
                console.log(e);
              } finally {
                setLoading(false);
                navigate("/campaign-dashboard");
              }
            });
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const listData = [
    {
      value: "max@gmail.com",
      label: "Max",
    },
    {
      value: "skote@gmail.com",
      label: "Skote",
    },
    {
      value: "christian@gmail.com",
      label: "Christian",
    },
  ];

  const getAudienceList = async (businessId: string) => {
    try {
      const data = await db
        .collection("Audiences")
        .where("businessId", "==", businessId)
        .get();
      let value: any = [];
      data.docs.forEach((item: any) => {
        value = [...value, item.data()];
      });
      const audienceName = value.map((item: any) => ({
        value: item.name,
        label: item.name,
        type: "individual",
        email: item.emailAddress,
        collectionId: item.id,
      }));
      console.log("audienceName", audienceName);
      setAudienceList([...audienceName]);
      if (audienceName.length <= 0) {
        setAlertOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreate = useCallback(
    (inputValue) => {
      const newValue = {
        value: inputValue,
        label: inputValue,
      };
      setRecipients([...recipients, newValue]);
    },
    [recipients]
  );

  const valiadateFields = () => {
    const name = emailInfo.senderName.length > 2 ? true : false;
    const recipient = recipients.length > 0 ? true : false;
    const campaignName = emailInfo.subjectLine.length > 5 ? true : false;
    const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      emailInfo.senderEmail
    );
    const template = selectedTemplate.id ? true : false;

    if (name && recipient && campaignName && email && template) {
      return true;
    }
    return false;
  };

  const isActiveTagSelected = (tag: any) => {
    const availableTags = find(recipients, (item: any) => {
      if (item.value === tag.value) {
        return true;
      }
      return false;
    });
    return availableTags;
  };

  const updateActiveTags = (tagItem: any, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(
        recipients,
        (item: any) => item.value === tagItem.value
      );
      if (indexToRemove !== -1) {
        const oldTagsArray = [...recipients];
        oldTagsArray.splice(indexToRemove, 1);
        setRecipients(oldTagsArray);
      }
    } else {
      setRecipients([...recipients, tagItem]);
    }
  };

  useEffect(() => {
    if (businessSettings) {
      const tags: any =
        businessSettings.audienceTags &&
        businessSettings?.audienceTags.map((item: any) => {
          return {
            label: item,
            value: item,
            type: "group",
          };
        });
      console.log("tags", tags);
      const value = listOptions.concat(tags);
      setListOptions([...value]);
      setTags([...new Set(tags)]);
    }
  }, [businessSettings]);

  useEffect(() => {
    if (business) {
      business.id && getAudienceList(business.id);
    }
  }, [business]);

  useEffect(() => {
    console.log("recipients", recipients);
  }, [recipients]);

  const renderForm = () => {
    return (
      <Box className="email-layout">
        <div className="layout-title">Email layout</div>
        <Box className="email-layout-body">
          <Box display="flex" flexDirection="column">
            <div className="input-container">
              <div className="input-label">To</div>
              <Box display="flex" alignItems="center">
                <Select
                  classNamePrefix="email-groups"
                  isMulti
                  value={recipients}
                  isClearable={false}
                  options={listOptions.concat(audienceList && audienceList)}
                  placeholder={"Select group or recipients"}
                  onChange={(e: any) => setRecipients(e)}
                />

                {recipients.length > 0 && (
                  <Icon icon="tick" size={20} color="#424452" />
                )}
              </Box>
            </div>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box style={{ width: "47%" }}>
                <div className="input-container">
                  <div className="input-label">Sender Name</div>
                  <InputGroup
                    onChange={(e) =>
                      updateEmailInfo("senderName", e.target.value)
                    }
                    placeholder="Type here.."
                    value={emailInfo.senderName}
                    className="emailinputs"
                  />
                  {emailInfo.senderName.length > 2 && (
                    <Icon
                      icon="tick"
                      size={20}
                      color="#424452"
                      className="input-check"
                    />
                  )}
                </div>
              </Box>

              <Box style={{ width: "47%" }}>
                <div className="input-container">
                  <div className="input-label">Sender Email</div>
                  <InputGroup
                    onChange={(e) =>
                      updateEmailInfo("senderEmail", e.target.value)
                    }
                    placeholder="Type here.."
                    value={emailInfo.senderEmail}
                    className="emailinputs"
                  />
                  {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    emailInfo.senderEmail
                  ) && (
                    <Icon
                      icon="tick"
                      size={20}
                      color="#424452"
                      className="input-check"
                    />
                  )}
                </div>
              </Box>
            </Box>

            <div className="input-container">
              <div className="input-label">Subject Line</div>
              <InputGroup
                onChange={(e) => updateEmailInfo("subjectLine", e.target.value)}
                placeholder="Type here.."
                value={emailInfo.subjectLine}
                className="emailinputs"
              />
              {emailInfo.subjectLine.length > 5 && (
                <Icon
                  icon="tick"
                  size={20}
                  color="#424452"
                  className="input-check"
                />
              )}
            </div>
          </Box>

          <Box className="design-body" display="flex" flex-direction="column">
            <div className="input-label">Design</div>
            <Box className="email-template-body">
              <Box>
                <div className="input-label">Creative Studio</div>
                <div>
                  Open Tango’s design studio to create your own email template
                </div>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ marginRight: "46px" }}
              >
                <div
                  className="email-template"
                  onClick={() => setShowTemplateModal(true)}
                ></div>
                <Icon
                  icon="chevron-right"
                  className="email-template-icon"
                  color="#7C7F93"
                  size={12}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex-direction="row"
              alignItems="center"
              style={{
                marginTop: "37px",
                marginLeft: "9px",
              }}
            >
              <div
                className={`${
                  valiadateFields()
                    ? "send-email-button"
                    : "send-email-button-disabled"
                }`}
                onClick={() => {
                  if (valiadateFields()) {
                    sendEmail();
                  }
                }}
              >
                Send Email
              </div>
              <div
                className="send-test-email-button"
                onClick={() => {
                  if (valiadateFields()) {
                    sendTestEmail();
                  }
                }}
              >
                send a test email
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderTags = () => {
    return (
      <Box className="tags-container">
        <div className="layout-title">Tags</div>
        <Box
          className="tag-container-body"
          display="flex"
          flex-direction="column"
        >
          {tags.length > 0 &&
            tags.map((item: any) => {
              return (
                <Box
                  className={`${
                    isActiveTagSelected(item) ? "active-tag" : "tag"
                  }`}
                  onClick={() =>
                    updateActiveTags(item, isActiveTagSelected(item))
                  }
                >
                  <HashIcon
                    height="14.67px"
                    width="14.67px"
                    color={isActiveTagSelected(item) ? "#FFFFFF" : "#424452"}
                  />
                  <div>{item.value}</div>
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  };

  return (
    <Layout>
      <div id="edit-campaign-page">
        <Box className="page-head">
          <Box
            display="flex"
            flex-direction="row"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/campaigns")}
          >
            <Icon icon="chevron-left" color="#424452" size={12} />
            <span className="backword-text">Back to Campaigns</span>
          </Box>
          <div className="email-title">Create Email</div>
          {/* <div className="email-time">
           Last Edited via <span className="bold-subheader">Brandon Herron</span>{" "}
           on <span className="bold-subheader">Mon, March 7th 3:33 PM</span>
           </div> */}
        </Box>

        {/*body*/}

        <Box display="flex" style={{ marginBottom: "20px" }}>
          {renderForm()}
          {renderTags()}
        </Box>
        {loading && <Spinner />}
        <TemplateModal
          isOpen={showTemplateModal}
          closeModal={() => setShowTemplateModal(false)}
          openTemplateNameModal={() => setShowTemplateNameModal(true)}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
        <TemplateNameModal
          isOpen={showTemplateNameModal}
          closeModal={() => setShowTemplateNameModal(false)}
          businessId={business && business.id}
        />
        <AddSubscriberAlert
          isOpen={isAlertOpen}
          closeAlert={() => setAlertOpen(false)}
        />
      </div>
    </Layout>
  );
};

export default CreateCampaign;
