import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { VENDOR_ITEM_KEY, VENDORS_KEY } from "../constants";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";
import { RawItemDTO } from "./inventoryItemInfo";

export type Vendor = {
  id: string;
  name: string;
  accountNumber: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactAddress: string;
};

export type Sale = {
  id: string;
  isDefaultVendor: boolean;
  saleUnit: string;
  splitUnitsPerSaleUnit: number;
  pricePerSaleUnit: number;
  splitUnit: string;
  productionUnitsPerSplitUnit: number;
  productionUnit: string;
  rawItem: RawItemDTO;
};

export type VendorWithSale = {
  vendor: Vendor;
  itemsSold: null | Sale[];
};

export type UpdateSaleDTO = {
  isDefaultVendor?: boolean;
  pricePerSaleUnit?: number;
  saleUnit?: string;
  splitUnit?: string;
  splitUnitsPerSaleUnit?: number;
  productionUnitsPerSplitUnit?: number;
  itemId: string; // only for create, not update
};
export type PurchaseItemDTO = {
  itemId: string;
  saleId: string;
  saleUnitCount: number;
  price?: number;
  
  // optional - expiration date
};
export type ExpenseDTO = {
  price: number;
  count: number;
  name: string;
  type: "tax" | "expense";
};

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const fetchVendors = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<VendorWithSale[]>(`${businessId}/vendors`);
  return r.data;
};

const fetchVendorDetails = async (businessId: string, vendorId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<VendorWithSale>(`${businessId}/vendors/${vendorId}`);
  return r.data;
};

const createVendor = async (businessId: string, toCreate: Partial<Vendor>) => {
  if (!businessId) return null;
  const r = await apiClient.post<Vendor>(`${businessId}/vendors`, toCreate);
  return r.data;
};

const updateVendor = async (
  businessId: string,
  vendorId: string,
  updateValues: Partial<Vendor>
) => {
  if (!businessId) return null;
  const r = await apiClient.put<Vendor>(
    `${businessId}/vendors/${vendorId}`,
    updateValues
  );
  return r.data;
};
const createSale = async (
  businessId: string,
  vendorId: string,
  newSale: UpdateSaleDTO
) => {
  if (!businessId) return null;
  const r = await apiClient.post<Vendor>(
    `${businessId}/vendors/${vendorId}/sales`,
    newSale
  );
  return r.data;
};
const updateSale = async (
  businessId: string,
  vendorId: string,
  saleId: string,
  updateValues: Partial<UpdateSaleDTO>
) => {
  if (!businessId) return null;
  const r = await apiClient.put<Vendor>(
    `${businessId}/vendors/${vendorId}/sales/${saleId}`,
    updateValues
  );
  return r.data;
};
const deleteSale = async (
  businessId: string,
  vendorId: string,
  saleId: string
) => {
  if (!businessId) return null;
  if (!vendorId) return null;
  if (!saleId) return null;
  await apiClient.delete(`${businessId}/vendors/${vendorId}/sales/${saleId}`);
};
const deleteVendor = async (businessId: string, vendorId: string) => {
  if (!businessId) return null;
  if (!vendorId) return null;
  await apiClient.delete(`${businessId}/vendors/${vendorId}`);
};

const makePurchase = async (
  businessId: string,
  vendorId: string,
  items: PurchaseItemDTO[],
  expenses: ExpenseDTO[],
  purchaseId: string,
) => {
  if (!businessId) return null;
  if (!vendorId) return null;
  const r = await apiClient.post(
    `${businessId}/vendors/${vendorId}/purchase`,
    {items, expenses, purchaseId}
  );
  return r.data;
};

// HOOKS
export const useVendors = (businessId: string) => {
  return useQuery([VENDORS_KEY, businessId], () => fetchVendors(businessId));
};
export const useVendor = (businessId: string, vendorId: string) => {
  return useQuery([VENDORS_KEY, businessId, vendorId], () =>
    fetchVendorDetails(businessId, vendorId)
  );
};
export const useUpdateVendor = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { vendorId: string; newValue: Partial<Vendor> }) =>
      updateVendor(businessId, args.vendorId, args.newValue),
    onSuccess: (data) => {
      const curr = client.getQueryData([
        VENDORS_KEY,
        businessId,
        data?.id,
      ]) as VendorWithSale;
      client.setQueryData([VENDORS_KEY, businessId, data?.id], {
        vendor: data,
        itemsSold: curr?.itemsSold ?? null,
      });
      client.invalidateQueries([VENDORS_KEY, businessId]);
    },
  });
};
export const useUpdateSale = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      itemId?: string; // used for invalidation
      vendorId: string;
      saleId: string;
      newValue: Partial<UpdateSaleDTO>;
    }) => updateSale(businessId, args.vendorId, args.saleId, args.newValue),
    onSuccess: (_data, ctx) => {
      client.invalidateQueries([VENDORS_KEY, businessId, ctx.vendorId]);
      if (ctx.itemId) {
        client.invalidateQueries([VENDOR_ITEM_KEY, businessId, ctx.itemId]);
      }
    },
  });
};
export const useCreateSale = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      vendorId: string;
      newValue: UpdateSaleDTO;
      itemId?: string;
    }) =>
      createSale(businessId, args.vendorId, args.newValue),
    onSuccess: (_data, ctx) => {
      client.invalidateQueries([VENDORS_KEY, businessId, ctx.vendorId]);
      if (ctx.itemId) {
        client.invalidateQueries([VENDOR_ITEM_KEY, businessId, ctx.itemId]);
      }
    },
  });
};
export const useCreateVendor = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: Partial<Vendor>) => createVendor(businessId, args),
    onSuccess: (data) => {
      if (data?.id) {
        client.setQueryData([VENDORS_KEY, businessId, data.id], {
          vendor: data,
          itemsSold: [],
        });
      }
      client.invalidateQueries([VENDORS_KEY, businessId]);
    },
  });
};

export const useDeleteSale = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      vendorId: string;
      saleId: string;
      itemId?: string;
    }) =>
      deleteSale(businessId, args.vendorId, args.saleId),
    onSuccess: (_data, ctx) => {
      client.invalidateQueries([VENDORS_KEY, businessId, ctx.vendorId]);
      if (ctx.itemId) {
        client.invalidateQueries([VENDOR_ITEM_KEY, businessId, ctx.itemId]);
      }
    },
  });
};

export const useDeleteVendor = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { vendorId: string }) =>
      deleteVendor(businessId, args.vendorId),
    onSuccess: (_data, ctx) => {
      client.invalidateQueries([VENDORS_KEY, businessId]);
      client.invalidateQueries([VENDORS_KEY, businessId, ctx.vendorId]);
    },
  });
};


export const useMakePurchase = (businessId: string) => {
  return useMutation({
    mutationFn: (args: {
      vendorId: string;
      items: PurchaseItemDTO[];
      expenses: ExpenseDTO[];
      purchaseId: string;
    }) =>
      makePurchase(businessId, args.vendorId, args.items, args.expenses, args.purchaseId),
  });
};