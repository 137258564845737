import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../model/store";
import {
  addTheme,
  fetchThemes,
  updateTheme,
} from "../../../models/surveyBuilder";
import { themesData } from "../data";
import { ThemeProp } from "../types";

const useThemes = (bgImageInputRef: React.RefObject<HTMLInputElement>) => {
  const [themes, setThemes] = useState<ThemeProp[]>(themesData);
  const [selectedTheme, setSelectedTheme] = useState<ThemeProp>(themesData[0]);
  const [showThemeSettings, setShowThemeSettings] = useState<boolean>(false);
  const [selectedBrightness, setSelectedBrightness] = useState<number>(0);
  const [previousThemeId, setPreviousThemeId] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<string | null>("");

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const uploadThemeImage = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target) {
        if (bgImageInputRef.current) {
          bgImageInputRef.current.value = "";
        }
        const text = event.target.result;
        //@ts-ignore
        setSelectedFile(text);
        setSelectedTheme({
          ...selectedTheme,
          backgroundImage: text,
          status: "draft",
        });
        //@ts-ignore
        onChange(text);
      }
    };
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const revertThemeSettings = () => {
    const existingTheme = themes.find((item) => item.id === previousThemeId);
    if (existingTheme) {
      setSelectedTheme(existingTheme);
      setShowThemeSettings(false);
    }
  };
  const saveNewTheme = async () => {
    const newTheme: ThemeProp = {
      ...selectedTheme,
      type: "custom",
      status: "saved",
      businessId: business.id,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      enabled: true,
    };
    const response = await addTheme(newTheme, selectedFile);
    if (response.data) {
      if (selectedFile) {
        // @ts-ignore
        setSelectedFile(response.data.backgroundImage);
      }
      setThemes([response.data, ...themes]);
      setSelectedTheme(response.data);
      setShowThemeSettings(false);
    }
  };

  const saveChangesOfTheme = async () => {
    const clonedThemes = [...themes];
    const updatedThemeData: ThemeProp = {
      ...selectedTheme,
      status: "saved",
      updatedAt: new Date(),
    };
    const response = await updateTheme(updatedThemeData, selectedFile);
    if (response.data) {
      const themeIndex = clonedThemes.findIndex(
        (item) => item.id === selectedTheme.id
      );
      if (themeIndex >= 0) {
        clonedThemes[themeIndex] = { ...selectedTheme, status: "saved" };
      }
      setThemes(clonedThemes);
      setShowThemeSettings(false);
    }
  };
  const addNewThemeData = () => {
    const newTheme: ThemeProp = {
      ...themesData[0],
      themeName: "My Theme",
      id: Math.floor(Math.random() * 10 + 1).toString(),
      status: "draft",
      type: "custom",
    };
    setPreviousThemeId(selectedTheme.id);
    setSelectedTheme(newTheme);
    setSelectedFile("");
    setShowThemeSettings(true);
  };
  const handleEditThemeButton = (theme: ThemeProp) => {
    setSelectedFile(
      // @ts-ignore
      theme.backgroundImage ? theme.backgroundImage : ""
    );
    setSelectedTheme(theme);
    setShowThemeSettings(true);
    setPreviousThemeId(theme.id);
  };

  useEffect(() => {
    const fetchData = async () => {
      const themeResponse = await fetchThemes(business.id);
      if (themeResponse && themeResponse.data) {
        setThemes(themeResponse.data);
      }
    };
    if (business) {
      fetchData();
    }
  }, [business]);

  return {
    themes,
    selectedTheme,
    showThemeSettings,
    uploadThemeImage,
    previousThemeId,
    revertThemeSettings,
    saveNewTheme,
    selectedFile,
    saveChangesOfTheme,
    addNewThemeData,
    handleEditThemeButton,
    setShowThemeSettings,
    setSelectedTheme,
    selectedBrightness,
    setSelectedBrightness,
    setSelectedFile,
  };
};
export default useThemes;
