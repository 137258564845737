import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import moment from "moment";
import Firebase from "../../config/firebase";


export type ResponseItem = {
    date: Firebase.firestore.Timestamp;
    score: number | string;
    [key: string]: unknown;
    uniqueId: string;
}

type Instruction = RenderInstruction<ResponseItem>;
export const instructions: { [x: string]: Instruction } = {};

instructions.date = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return (
            <div>{moment(fullObject.date.toDate()).format("YYYY-MM-DD")}</div>
        )
    },
    editComponent: null,
}
