import React, { useCallback, useMemo, useState } from "react";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import Payments from "../Payments";
import useDisputeDetail from "./useDisputeDetail";
import Timeline from "./Timeline";
import OrderReciept from "../OrderReciept";
import { RootState } from "model/store";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { closeDispute, TangoDispute, useTab } from "controllers/disputes";
import moment from "moment";
import RefundPaymentModal from "../ClosedCheckDetail/RefundPaymentModal";
import { useStaffMember } from "controllers/payments";

const { TextField, Button, Icon, CallToActionModal } = UIComponents;
const DisputeDetail = () => {
    const navigate = useNavigate();

    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business as TangoBusiness
    );
    const loc = useLocation();
    const disputeId = useMemo(() => {
        const split = loc.pathname.split("/");
        return split[split.length - 1];
    }, [loc]);
    const country = business.currency.toLowerCase() === 'ca' || business.currency.toLowerCase() === 'cad' ? 'CA' : 'USA'
    const [loading, setLoading] = useState(false)
    const dispute: TangoDispute = loc.state


    const useTabForDispute = () => {
        const businessId: string = useSelector(
            (state: RootState) => state.business as TangoBusiness
        )?.id;
        const tabInfo = useTab(businessId, dispute.tabId);
        return tabInfo?.data ?? null;
    }

    const tab = useTabForDispute();

    const fetchStaffMemberData = (staffMemberId: string) => {
        const businessId: string = useSelector(
            (state: RootState) => state.business as TangoBusiness
        )?.id;
        const info = useStaffMember(businessId, staffMemberId);
        return info?.data ?? null;
    }

    const staffMember: any = tab && tab.staffId ? fetchStaffMemberData(tab.staffId) : null;

    const {
        setShowRefundPaymentModal,
        showRefundPaymentModal,
        selectedPaymentToRefund,
        setSelectedPaymentToRefund,
        showAcceptDispute,
        setShowAcceptDispute,
        setRefundSuccess,
        setUpdateCheckWithRefund,
        updatedCheckWithRefund,
        refundSuccess
    } = useDisputeDetail();

    const handleAcceptDispute = async () => {
        setLoading(true)
        console.log('clicked accept dispute', country, dispute)
        const acceptDisputeSuccess = await closeDispute(business.id, country, dispute.id)
        if (acceptDisputeSuccess) {
            setShowAcceptDispute(true)
        }
        setLoading(false)
    }


    const navigateToDispute = useCallback(() => {
        navigate(`/dispute/evidence`, { state: { dispute, tab } });
    }, [navigate, tab]);



    return (
        <div className="absolute top-[63px] bottom-0 w-full">
            <Header routes={headerRoutes} selectedRoute={"/payments/closedchecks/"} selectedPage={{ label: "Customers", value: "/payments" }} />
            <div className="flex h-full">
                <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div>

                <div style={{ width: "90%" }}>
                    {/* Header: starts */}
                    <div
                        className={
                            "flex p-8 justify-between items-center bg-white border-b border-grey-1"
                        }
                        style={{ borderBottomStyle: "solid" }}
                    >
                        <div className={"flex items-center"}>
                            <Icon
                                name={"arrow-left"}
                                size={"20"}
                                className={"cursor-pointer"}
                                onClick={() => navigate(-1)}
                            />

                            <div className={"text-big text-black font-lato-black ml-10"}>
                                ${(dispute.amount * 0.01).toFixed(2)}
                            </div>
                            <div className="text-grey-2 font-lato-regular text-2xl ml-2">{business && business.currency.toUpperCase()}</div>
                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className="text-black text-base font-lato-bold">Disputed on {moment.unix(dispute.created).format('MMM DD, YYYY')}</div>
                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className='py-1 px-4 rounded-2xl bg-green-2 border-green-3 text-xs font-lato-bold text-black border-solid border'>
                                {dispute.status.replace('_', ' ')}
                            </div>

                            {dispute.status !== 'closed' &&
                                <div className="flex items-center">


                                    {/* <Button label="Save" size="btn-medium" type="btn-style-2" />
                             <Button label="Submit Evidence" size="btn-medium" type="btn-style-1" className="ml-5" /> */}


                                    <Button
                                        disabled={dispute.status === ' closed' || dispute.status === 'lost'}
                                        label='Accept'
                                        size="btn-medium"
                                        type="btn-style-2"
                                        onClick={() => {
                                            setShowAcceptDispute(!showAcceptDispute)
                                        }}
                                    />


                                    <Button
                                        disabled={dispute.status === ' closed' || dispute.status === 'lost'}
                                        label='Submit Evidence'
                                        size="btn-medium"
                                        type="btn-style-1"
                                        className="ml-5"
                                        onClick={() => {
                                            // console.log('clicked submit evidence')
                                            navigateToDispute()
                                        }}
                                    />
                                </div>
                            }
                            {dispute.status === 'closed' &&
                                <div>
                                    <div className='text-xs font-lato-bold text-black mb-1'>
                                        You accepted the dispute.
                                    </div>
                                    <div className='text-xs font-lato-regular text-grey-3'>
                                        The refund to the cardholder stands and no further action is required.
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                    {/* Header: ends */}
                    {/* container starts */}
                    <div className="pt-10 pl-10 pr-16 flex justify-start">
                        <div className="w-4/12">

                            {tab &&
                                <OrderReciept
                                    closedCheck={tab}
                                    staffMember={staffMember}
                                />
                            }

                        </div>
                        <div className="w-8/12 flex">
                            <div className="w-8/12">
                                {tab &&
                                    <Payments
                                        closedCheck={tab}
                                        setShowRefundPaymentModal={setShowRefundPaymentModal}
                                        setSelectedPaymentToRefund={setSelectedPaymentToRefund}
                                        setRefundSuccess={setRefundSuccess}
                                        refundSuccess={refundSuccess}
                                        allowRefund={false}
                                    />
                                }
                            </div>
                            <div className="w-4/12 ml-8">
                                <div className='font-lato-black text-2xl text-black mb-5'>Dispute</div>

                                <ul className='p-0 m-0 mb-10 list-none border border-grey-1 border-solid rounded-2xl overflow-hidden'>
                                    <li className='flex border-t-0 border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                        <div className='text-xs w-5/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Status</div>
                                        <div className='text-xs w-7/12 text-black font-lato-regular py-1 px-6'>{dispute.status.replaceAll('_', ' ')}</div>
                                    </li>
                                    <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                        <div className='text-xs w-5/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Reason</div>
                                        <div className='text-xs w-7/12 text-black font-lato-regular py-1 px-6'>{dispute.reason.replaceAll('_', ' ')}</div>
                                    </li>
                                    <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                        <div className='text-xs w-5/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Amount</div>
                                        <div className='text-xs w-7/12 text-black font-lato-regular py-1 px-6'>
                                            ${(dispute.amount * 0.01).toFixed(2)}
                                        </div>
                                    </li>
                                    <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                        <div className='text-xs w-5/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Submissions</div>
                                        <div className='text-xs w-7/12 text-black font-lato-regular py-1 px-6'>{dispute.evidenceDetails.submissionCount}</div>
                                    </li>
                                </ul>


                                {tab &&
                                    <Timeline
                                        dispute={dispute}
                                        closedCheck={tab}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {selectedPaymentToRefund &&
                <RefundPaymentModal
                    isOpen={showRefundPaymentModal}
                    selectedPaymentToRefund={selectedPaymentToRefund}
                    setRefundSuccess={setRefundSuccess}
                    setUpdateCheckWithRefund={setUpdateCheckWithRefund}
                    closedCheckWithStaff={null}
                    closeModal={() => setShowRefundPaymentModal(false)}
                />
            }

            <CallToActionModal
                isOpen={showAcceptDispute}
                modalTitle="Accept Dispute?"
                modalSubtitle={"You will automatically lose this dispute and will no longer be able to submit evidence that the payment is legitimate."}
                buttonLabel={loading ? 'Processing' : "Accept Dispute"}
                onOptionClick={() => {
                    if (loading) {
                        return
                    }
                    handleAcceptDispute()
                }}
                closeModal={() => setShowAcceptDispute(false)}
            />
        </div>
    );
};

export default DisputeDetail;
