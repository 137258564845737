import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const RequestReviewModal = ({ isOpen, closeModal }: Props) => {
  return (
    <UIComponents.Modal isOpen={isOpen} closeModal={closeModal} width={"720"}>
      <div className={"text-big text-black font-lato-black mb-10"}>
        Request Review
      </div>
      <div className={"mb-10"}>
        <div className={"text-xl text-black font-lato-black mb-4"}>
          Request From
        </div>
        <UIComponents.Dropdown
          size={"large"}
          options={[
            {
              label: "test",
              value: "test2",
            },
          ]}
        />
      </div>
      <div className={"mb-10"}>
        <div className={"text-xl text-black font-lato-black mb-4"}>
          Location
        </div>
        <UIComponents.Dropdown
          size={"large"}
          options={[
            {
              label: "test",
              value: "test2",
            },
          ]}
        />
      </div>
      <div className={"mb-10"}>
        <div className={"text-xl text-black font-lato-black mb-4"}>
          Template
        </div>
        <UIComponents.Dropdown
          size={"large"}
          options={[
            {
              label: "test",
              value: "test2",
            },
          ]}
        />
      </div>
      <UIComponents.Button
        label={"Request"}
        type={"btn-style-2"}
        size={"btn-medium"}
        className={"w-full justify-center"}
      />
    </UIComponents.Modal>
  );
};
export default RequestReviewModal;
