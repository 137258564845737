import { AccountsAction } from "model/types";

import ActionType from "./actionTypes";

export function receiveAccounts(accounts: TangoAccount[]): AccountsAction {
  return {
    type: ActionType.RECEIVE_ACCOUNTS,
    accounts,
  };
}
