import { SidebarRoute } from "components/Sidebar";

export const sidebarRoutes: SidebarRoute[] = [
    {
        label: "Closed Checks",
        link: "/payments/closedchecks/",
        subRoutes: [],
        isExpanded: false,
    },
    {
        label: "Payments",
        link: "/payments",
        subRoutes: [],
        isExpanded: false,
    },
    {
        label: "Disputes",
        link: "/payments/disputes",
        subRoutes: [],
        isExpanded: false,
    },
    {
        label: "Discounts",
        link: "/payments/discounts",
        subRoutes: [],
        isExpanded: false,
    },
    // {
    //     label: "Payouts",
    //     link: "/payouts",
    //     subRoutes: [],
    //     isExpanded: false,
    // },
];

export const headerRoutes = [
    {
        label: "Payments",
        link: "/payments/closedchecks/",
    }
];
