import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import CrossIcon from "../../../assets/calendar/status_cross.svg";
import QuestionMarkIcon from "../../../assets/calendar/status_question_mark.svg";
import TickIcon from "../../../assets/calendar/status_tick.svg";

interface Props {
  invites: any[];
  expandInvite: (index: number) => void;
}
const Invites = ({ invites, expandInvite }: Props) => {
  return (
    <div className={"p-6"}>
      {invites.map((invite, inviteIndex) => (
        <div
          className="border border-grey-1 rounded-2xl mb-6"
          onClick={() => expandInvite(inviteIndex)}
        >
          {invite.isExpanded ? (
            <div className={"py-3 px-4 flex justify-between items-center"}>
              <div className={"flex"}>
                <UIComponents.Checkbox
                  type={"small"}
                  checked={true}
                  colorType={"blue"}
                />
                <div className={"font-lato-bold text-xs text-black ml-2"}>
                  Ops team
                </div>
              </div>
              <UIComponents.Icon name={"chevron-right"} size={"12"} />
            </div>
          ) : null}
          <div className={"pt-2.5 pb-3.5 px-3.5 bg-green-1"}>
            <div className={"font-lato-black text-sm text-black"}>
              {invite.title}
            </div>
            <div className={"font-lato-medium text-veryTiny text-black"}>
              {invite.description}
            </div>
          </div>
          {invite.isExpanded ? (
            <div
              className={
                "px-3.5 py-2.5 flex justify-between items-center border-b border-grey-1"
              }
            >
              <div className={"font-lato-medium text-veryTiny text-black"}>
                April 19, 2022
              </div>
              <div className={"font-lato-black text-veryTiny text-black"}>
                8:00 AM — 9:00 AM
              </div>
            </div>
          ) : null}
          <ul className={"px-3.5 py-2.5 border-b border-grey-1"}>
            {[1, 2, 3, 4].map((item, index) => {
              return (
                <li
                  key={item}
                  className={`font-lato-medium text-veryTiny text-black items-center ${
                    invite.isExpanded ? "flex" : index === 0 ? `flex` : "hidden"
                  }`}
                >
                  <img src={TickIcon} className={"mr-2.5"} /> Billy Joel
                  (organizer)
                </li>
              );
            })}
          </ul>
          {invite.isExpanded ? (
            <div
              className={
                "flex justify-between items-center px-3.5 py-3 font-lato-medium text-veryTiny text-grey-2 border-b border-grey-1 cursor-pointer"
              }
            >
              Propose a new time...
            </div>
          ) : null}
          <div className={"px-3.5 pt-2 pb-3 flex justify-between items-center"}>
            <div className={"flex items-center"}>
              <UIComponents.Button
                label={"Accept"}
                type={"btn-style-1"}
                className={"mr-1 !bg-black !text-white"}
                size={"btn-tiny"}
              />
              <UIComponents.Button
                label={"Maybe"}
                type={"btn-style-1"}
                className={"mr-1"}
                size={"btn-tiny"}
              />
              <UIComponents.Button
                label={"Delete"}
                type={"btn-style-1"}
                size={"btn-tiny"}
              />
            </div>
            <UIComponents.Icon
              name={invite.isExpanded ? "chevron-up" : "chevron-down"}
              size={"12"}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default Invites;
