import { RootState } from "model/store";
import React from "react";
import Select from 'react-select';
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";
import { useSelector } from 'react-redux';
import LauncherIcon from "../../assets/OrganizationalLauncher.svg";
import TangoLogo from "../../assets/OrganizationalLogo.svg";
import UIComponents from "@tangopay/tango-ui-library";
const { Icon } = UIComponents;
interface Route {
  label: string;
  link: string;
}
interface Props {
  routes: Route[];
  selectedRoute: string;
  selectedPage?: {
    label: string,
    value: string
  }
}
const Header = ({ routes, selectedRoute, selectedPage }: Props) => {
  const launchpad = useLaunchpad();
  const navigate = useNavigate();
  const user: StaffMember = useSelector((state: RootState) => state.user);

  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;
  return (
    <div
      className={
        "flex border-b border-grey-1 justify-between items-center fixed top-0 w-full bg-white z-10"
      }
      style={{ borderBottomStyle: "solid" }}
    >
      <div className={"flex items-center"}>
        <img
          src={TangoLogo}
          className="p-5 border-r border-grey-1 cursor-pointer"
          style={{ borderRightStyle: "solid" }}
          onClick={() => navigate('/dashboard')}
        />
        {
          selectedPage ?
            <div className=" w-48">
              <Select
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: null,
                  SingleValue: (props) => {
                    return (
                      <div className="flex items-center">
                        <div>{props.data.label}</div>
                        {
                          props.selectProps.menuIsOpen ?
                            <Icon name="chevron-up" className="ml-3" color="#424452" />
                            :
                            <Icon name="chevron-down" className="ml-3" color="#424452" />

                        }
                      </div>
                    )
                  }
                }}
                isSearchable={false}
                value={{
                  label: selectedPage.label,
                  value: selectedPage.value
                }}
                styles={{
                  valueContainer: (baseStyle, state) => ({
                    ...baseStyle,
                    fontSize: "14",
                    fontFamily: "Lato-Black",
                    color: "#424452",

                  }),
                  option: (baseStyle, state) => ({
                    ...baseStyle,
                    fontSize: "14",
                    fontFamily: "Lato-Bold",
                    color: "#424452",
                    padding: '16px'
                  }),
                  menuList: (baseStyle, state) => ({
                    ...baseStyle,
                    borderRadius: '15px',
                    padding: "0px",
                  }),
                  dropdownIndicator: (base, props) => ({
                    ...base,
                    // marginRight: '50px',
                    transform: props.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
                  }),
                }}
                menuIs
                hideSelectedOptions
                onChange={(newValue) => {
                  if (newValue)
                    navigate(newValue?.value)
                }}
                classNamePrefix={"navigation-dropdown"}
                className="navigation-dropdown"
                options={[{
                  label: "People",
                  value: "/manager/team"
                },
                {
                  label: "Food & Bev",
                  value: "/products"
                },
                {
                  label: "Business Admin",
                  value: "/core-reports"
                },
                {
                  label: "Customers",
                  value: "/payments"
                },
                {
                  label: "Productivity",
                  value: "/documents"
                },
                {
                  label: "Store Setup",
                  value: "/device-setup"
                },
                ]}
              />
            </div>

            :
            null
        }

        {/* <img
          src={LauncherIcon}
          className="p-5 border-r border-grey-1"
          style={{ borderRightStyle: "solid" }}
          onClick={() => {
            if (isEnterpriseLevel) {/manager/team
              navigate("/enterprise");
            } else {
              launchpad.setVisibility(true);
            }
          }}
        /> */}
      </div>
      <ul className={"pr-6 flex justify-end list-none m-0"}>
        {routes.map((route, routeIndex) => (
          <Link to={route.link} key={route.label}>
            <li
              className={`text-base font-lato-bold ml-10 cursor-pointer leading-[60px] border-b-[3px] ${selectedRoute === route.link
                ? "border-black  text-black"
                : "text-grey-2 border-white hover:border-black   hover:text-black"
                }  `}
              style={{ borderBottomStyle: "solid" }}
            >
              {route.label}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};
export default Header;
