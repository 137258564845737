import React from "react";
import { useNavigate } from "react-router-dom";

import { RenderInstruction } from "../../../components/Table/GenericCell/TableCell";
import HorizontalTable, {
  ColumnInstruction,
} from "../../../components/Table/HorizontalTable";

type VendorItemDetail = {
  uniqueId: string;
  item: string;
  primaryGroup: string;
  secondaryGroup: string;
};

const vendorsData: VendorItemDetail[] = [
  {
    uniqueId: "1",
    item: "Avocado",
    primaryGroup: "Produce",
    secondaryGroup: "Fruit",
  },
  {
    uniqueId: "2",
    item: "Guacamole",
    primaryGroup: "Ingredient",
    secondaryGroup: "Dips",
  },
  {
    uniqueId: "3",
    item: "Marinara Sauce",
    primaryGroup: "Sauce",
    secondaryGroup: "Pasta Sauce",
  },
];

const columns: ColumnInstruction<VendorItemDetail>[] = [
  { type: "data", header: "Item", attribute: "item" },
  { type: "data", header: "Primary Group", attribute: "primaryGroup" },
  { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
];

const instructions: { [x: string]: RenderInstruction<VendorItemDetail> } = {};

const RecipeItemsTable = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        "bg-white border border-grey border-b-0 border-solid rounded-2xl"
      }
    >
      <HorizontalTable
        title="Recipe Items"
        columns={columns}
        data={vendorsData}
        instructions={instructions}
        saveResults={() => Promise.resolve(true)}
        setEditing={() => console.log("yye")}
        isEditing={false}
        deleteIds={() => console.log("yye")}
        onRowClick={() => navigate("/inventory/recipes/item/1")}
      />
    </div>
  );
};
export default RecipeItemsTable;
