import { BusinessAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function business(state = initialState, action: BusinessAction) {
  switch (action.type) {
    case ActionType.RECEIVE_BUSINESS:
      return action.business;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
