import React, { ChangeEvent } from "react";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string | any[];
  color: string;
}
const ShortText = ({ onChange, value, color }: Props) => {
  return (
    <input
      style={{ color, borderColor: color, borderWidth: "0.5px" }}
      placeholder="Type answer here"
      className="short-text-component apply-font"
      onChange={onChange}
      value={value}
    />
  );
};

export default ShortText;
