import { Grid } from "@mui/material";
import React from "react";

import "./style.css";

interface Props {
  labels: string[];
  values: string[];
}
const StatsGrid = (props: Props) => {
  const { labels, values } = props;
  if (labels.length !== values.length) throw new Error("must have same length");

  return (
    <Grid container>
      {labels.map((label, idx) => {
        const value = values[idx];
        return (
          <Grid className="stat-item" item xs={6} lg={4} key={label}>
            <span className="stat-label">{label}</span>
            <br />
            <span className="stat-value">{value}</span>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default StatsGrid;
