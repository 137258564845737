import { Dialog, Icon, Position, Switch, TextArea } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import Box from "components/Box";

import { createBoard } from "models/boards";

import AssignToTicketIcon from "assets/ToDo/AssignToTicketIcon.svg";
import HirirngIcon from "assets/ToDo/HiringIcon.svg";
import PriorityIcon from "assets/ToDo/PriorityIcon.svg";
import ShortFieldIcon from "assets/ToDo/ShortFieldIcon.svg";

import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  fixedProperties: any;
  setFixedProperties: any;
  customProperties: any;
  setCustomProperties: any;
  boardProperties: any;
  formId: string | null;
  formData: any | null;
}
export interface FormField {
  name: string;
  enabled: boolean;
  type: "textInput";
  isTitle: boolean;
}
export function TicketPropertyModal({
  isOpen,
  closeModal,
  fixedProperties,
  setFixedProperties,
  customProperties,
  setCustomProperties,
  boardProperties,
  formId,
  formData,
}: Props) {
  const db = firebase.firestore();

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [switches, setSwitches] = useState({
    emailSwitch: false,
    Safetytraing: false,
    createEmployee: false,
  });

  const [properties, setProperties]: any = useState([]);

  const handleSwithChange = (key: any, e: any) => {
    setSwitches({ ...switches, [key]: e.target.checked });
  };
  const [formFields, setFormFields] = useState<FormField[]>([]);
  const handlefixPropertyChange = (key: any, e: any) => {
    setFixedProperties({ ...fixedProperties, [key]: e.target.checked });
  };

  const handleCustomPropertyChange = (
    key: string,
    name: string,
    value: any
  ) => {
    setCustomProperties({
      ...customProperties,
      [key]: { ...customProperties[key], [name]: value },
    });
  };

  const handleCreateProperty = (type: string) => {
    const length = Object.keys(customProperties).length;

    setCustomProperties({
      ...customProperties,
      [uuid()]: {
        name: `Property ${length + 1}`,
        value: "Property Content",
        automationId: null,
        type: type,
        enable: true,
      },
    });
  };
  useEffect(() => {
    if (formData) {
      const formFieldsArray = formData.questions.map(
        (item: any, index: number) => {
          return {
            name: item.title,
            enabled: index === 0 ? true : false,
            type: "textInput",
            isTitle: index === 0 ? true : false,
          };
        }
      );
      setFormFields(formFieldsArray);
    }
  }, [formData]);

  const updateFormField = (
    key: keyof FormField,
    value: boolean,
    index: number
  ) => {
    const clonedFormFields = [...formFields];
    clonedFormFields[index] = { ...clonedFormFields[index], [key]: value };
    setFormFields(clonedFormFields);
  };
  const setTitleOfTicket = (fieldIndex: number) => {
    const clonedFormFields = [...formFields].map((item, itemIndex) => {
      if (itemIndex === fieldIndex) {
        return { ...item, isTitle: true };
      } else {
        return { ...item, isTitle: false };
      }
    });
    setFormFields(clonedFormFields);
  };
  const getTicketName = () => {
    const formField = formFields.find((item) => item.isTitle);
    if (formField) {
      return formField.name;
    } else {
      return "Ticket Title";
    }
  };
  const renderCustomProperties = () => {
    if (formData && formFields.length > 0) {
      return formFields.map((item) => {
        if (item.enabled && !item.isTitle) {
          return (
            <div className="ticket-box-section">
              <div className="ticket-box-label">{item.name}</div>
              {item.enabled === true && (
                <div className="ticket-box-content">Form Response</div>
              )}
            </div>
          );
        }
        return null;
      });
    } else {
      return (
        Object.keys(customProperties).length > 0 &&
        Object.keys(customProperties).map((item: any) => {
          return (
            <div className="ticket-box-section">
              <div className="ticket-box-label">
                {customProperties[item]?.enable === false && (
                  <Icon icon={"arrow-right"} size={16} />
                )}
                {customProperties[item]?.name}
              </div>
              {customProperties[item]?.enable === true && (
                <div className="ticket-box-content">Property Content</div>
              )}
            </div>
          );
        })
      );
    }
  };
  const renderFormFields = () => {
    return formFields.map((item, index) => {
      return (
        <div className="property-body-item">
          {" "}
          <Switch
            checked={item.enabled}
            onChange={(e) => updateFormField("enabled", !item.enabled, index)}
            className={`${item.enabled ? "active" : "in-active"}`}
            innerLabel={"Hide"}
            innerLabelChecked={"Show"}
          />
          <div className="property-input-div">
            <img src={ShortFieldIcon} height="23px" width="23px" />
            <input
              className="property-input"
              placeholder="Property 1"
              value={item.name}
            />
            <Icon
              icon={`${item.isTitle ? "star" : "star-empty"}`}
              size={20}
              onClick={() => setTitleOfTicket(index)}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeModal}
        className="ticketproperty-modal"
      >
        <div className="modal-header">
          <div className="modal-title">Ticket Properties</div>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ gap: "13px" }}
            className="modal-header-right"
          >
            <img src={HirirngIcon} height="48px" width="48px" />
            <span>Hiring</span>
          </Box>
          {/* <span
            className="closebtn"
            // onClick={() => {
            //   clearState();
            //   closeModal();
            // }}
          >
            <img src={Closeicon} style={{ height: 12.83 }} />
          </span> */}
        </div>

        {/* <div className="modal-groups">
          <span className="modal-groupstxt">
            <Select
              classNamePrefix="new-note-modal"
              isMulti
              placeholder={"Groups or individuals"}
              options={listData}
              onChange={(e: any) => setSelectedItems(e)}
            />
            select
          </span>
        </div> */}

        <div className="modal-body">
          <div className="properties-div">
            <div className="property-label">Properties</div>
            <div className="property-body">
              <div className="property-body-section">
                <div className="property-body-item">
                  {" "}
                  <Switch
                    checked={fixedProperties.Assignpeopletoticket}
                    onChange={(e) =>
                      handlefixPropertyChange("Assignpeopletoticket", e)
                    }
                    className={`${
                      fixedProperties.Assignpeopletoticket
                        ? "active"
                        : "in-active"
                    }`}
                    innerLabel={"No"}
                    innerLabelChecked={"Yes"}
                  />
                  Assign people to ticket
                </div>
                <div className="property-body-item">
                  {" "}
                  <Switch
                    checked={fixedProperties.Ticketduedate}
                    onChange={(e) =>
                      handlefixPropertyChange("Ticketduedate", e)
                    }
                    className={`${
                      fixedProperties.Ticketduedate ? "active" : "in-active"
                    }`}
                    innerLabel={"Off"}
                    innerLabelChecked={"On"}
                  />
                  Ticket due date
                </div>
                <div className="property-body-item">
                  {" "}
                  <Switch
                    className={`${
                      fixedProperties.Ticketpriority ? "active" : "in-active"
                    }`}
                    checked={fixedProperties.Ticketpriority}
                    onChange={(e) =>
                      handlefixPropertyChange("Ticketpriority", e)
                    }
                    innerLabel={"Off"}
                    innerLabelChecked={"On"}
                  />
                  Ticket priority
                </div>
                <div className="property-body-item">
                  {" "}
                  <Switch
                    className={`${
                      fixedProperties.Attachfilestoticket
                        ? "active"
                        : "in-active"
                    }`}
                    checked={fixedProperties.Attachfilestoticket}
                    onChange={(e) =>
                      handlefixPropertyChange("Attachfilestoticket", e)
                    }
                    innerLabel={"Off"}
                    innerLabelChecked={"On"}
                  />
                  Attach files to ticket
                </div>
              </div>
              {!formData ? (
                <div className="add-property-section">
                  {Object.keys(customProperties).map((item) => {
                    if (customProperties[item]?.type == "textInput") {
                      return (
                        <div className="property-body-item">
                          {" "}
                          <Switch
                            className={`${
                              customProperties[item]?.enable
                                ? "active"
                                : "in-active"
                            }`}
                            checked={customProperties[item]?.enable}
                            onChange={(e: any) =>
                              handleCustomPropertyChange(
                                item,
                                "enable",
                                e.target.checked
                              )
                            }
                            innerLabel={"Hide "}
                            innerLabelChecked={"Show"}
                          />
                          <div className="property-input-div">
                            <img
                              src={ShortFieldIcon}
                              height="23px"
                              width="23px"
                            />
                            <input
                              className="property-input"
                              placeholder="Property 1"
                              value={customProperties[item]?.name}
                              onChange={(e: any) =>
                                handleCustomPropertyChange(
                                  item,
                                  "name",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    } else if (customProperties[item]?.type == "textArea") {
                      return (
                        <div className="property-body-item">
                          {" "}
                          <Switch
                            checked={customProperties[item]?.enable}
                            onChange={(e: any) =>
                              handleCustomPropertyChange(
                                item,
                                "enable",
                                e.target.checked
                              )
                            }
                            innerLabel={"Hide"}
                            innerLabelChecked={"Show"}
                          />
                          <div className="property-input-div">
                            <img
                              src={ShortFieldIcon}
                              height="23px"
                              width="23px"
                            />
                            <TextArea
                              dir="auto"
                              className="property-input-textarea"
                              value={customProperties[item]?.name}
                              onChange={(e: any) =>
                                handleCustomPropertyChange(
                                  item,
                                  "name",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                  })}

                  <Popover2
                    content={
                      <div className="action-popover">
                        <div className="popover-title">Property Type</div>
                        <div
                          className="popover-content"
                          onClick={() => handleCreateProperty("textInput")}
                        >
                          <img
                            src={ShortFieldIcon}
                            height="15px"
                            width="15px"
                          />
                          short field
                        </div>
                        <div
                          className="popover-content"
                          onClick={() => handleCreateProperty("textArea")}
                        >
                          <img
                            src={ShortFieldIcon}
                            height="15px"
                            width="15px"
                          />
                          long field
                        </div>
                      </div>
                    }
                    position={Position.BOTTOM}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      style={{
                        gap: "11.33px",
                        marginLeft: "110px",
                        marginTop: "19.33px",
                      }}
                    >
                      <Icon icon="plus" size={9} />
                      New Property
                    </Box>
                  </Popover2>
                </div>
              ) : (
                <div className="add-property-section form-fields">
                  {renderFormFields()}
                </div>
              )}
            </div>
          </div>
          <div className="ticket-div">
            <div className="ticket-box">
              <div className="ticket-box-header">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    padding: "8px 8px 17px 16px",
                  }}
                >
                  {!formData ? "Ticket Name" : getTicketName()}
                  <img src={AssignToTicketIcon} height="24px" width="24px" />
                </Box>
                <img src={PriorityIcon} height="15px" width="15px" />
              </div>
              {renderCustomProperties()}

              {/* <div className="ticket-box-section">
                <div className="ticket-box-label">Applied for</div>
                <div className="ticket-box-content">Property Content</div>
              </div>
              <div className="ticket-box-section">
                <div className="ticket-box-label">Location</div>
                <div className="ticket-box-content">Property Content</div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <span className="color-blue pointer" onClick={() => closeModal()}>
            Skip and Create
          </span>
          <button
            className="trigger-btn"
            onClick={async () => {
              if (business) {
                await createBoard(
                  boardProperties.name,
                  boardProperties.description,
                  boardProperties.color,
                  business.id,
                  business.accountId,
                  business?.parentId ? business?.parentId : null,
                  fixedProperties,
                  customProperties,
                  boardProperties.Icon,
                  formFields,
                  formId
                );
                closeModal();
              }
            }}
          >
            Trigger1
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default TicketPropertyModal;
