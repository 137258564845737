import React from "react";
import { useSelector } from "react-redux";
import { firstBy } from "thenby";
import ModifierOptionsTable from "views/admin/menus&Products/modifiers/ModifierOptionsTable";

import { RootState } from "model/store";

import Layout from "./Layout";

const ModifierOptions = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const modifiers: FirebaseModifierDoc[] = useSelector((state: RootState) =>
    state.modifiers.sort(firstBy("nameExternal", { ignoreCase: true }))
  );
  const modifierOptions: FirebaseModifierOptionsDoc[] = useSelector(
    (state: RootState) => state.modifierOptions
  );
  const products: FirebaseProductDoc[] = useSelector(
    (state: RootState) => state.products
  );

  if (business) {
    return (
      <Layout>
        {/*
        <ModifiersTable
          businessId={business?.id}
          modifierOptions={modifierOptions}
          modifiers={modifiers}
          products={products}
        />
        */}
        <ModifierOptionsTable
          // modifier={data}
          // modifierOptions={data.options}
          businessId={business.id}
          // allModifierOptions={additionalInfo.allModifierOptions}
          // setAllModifierOptions={additionalInfo.setAllModifierOptions}
          // modifiers={additionalInfo.modifiers}
          // setModifierOptions={(newOptions: ModifierOptions[]) => {
          //   setData({
          //     ...data,
          //     options: newOptions,
          //     nameExternal: record.nameExternal,
          //     min: parseInt(record.min),
          //     max: parseInt(record.max),
          //     description: record.description,
          //   });
          // }}
        />
      </Layout>
    );
  }
  return null;
};

export default ModifierOptions;
