
// react-query keys
export const INV_INFO_KEY = "inventoryInfo";
export const RAW_ITEM_KEY = "rawItemsInfo ";
export const PREP_ITEM_KEY = "prepItemsInfo";
export const VENDOR_ITEM_KEY = "vendorInfoForItem";
export const MENU_ITEMS_KEY = "menuItems";
export const MODIFIERS_KEY = "modifiers";
export const VENDORS_KEY = "vendors";
export const WASTE_KEY = "wasteGroups";
export const RAW_MENU_USAGE_KEY = "rawItemMenuUsage";
export const RAW_PREP_USAGE_KEY = "rawItemPrepUsage";
export const PREP_MENU_USAGE_KEY = "prepItemMenuUsage";
