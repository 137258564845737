export const productTypes = [
  "food",
  "beverage (non-alcoholic)",
  "wine",
  "liquor & shots",
  "beer & cider",
  "seltzer & malt beverages",
  "merchandise",
  "coffee & tea",
  "other",
];

export const productSubTypes = [
  "appetizers",
  "mains",
  "sides",
  "desserts",
  "NA beverages",
  "bottled wine",
  "glass wine",
  "red wine",
  "white wine",
  "rose",
  "sparkling",
  "vodka",
  "gin",
  "tequila",
  "whiskey",
  "rum",
  "liqueur",
  "cocktail",
  "shot",
  "canned beer",
  "bottled beer",
  "draft beer",
  "cider",
  "seltzer",
  "malt bev",
  "coffee",
  "tea",
];
