import { Icon } from "@blueprintjs/core";
import React from "react";

interface Props {
  color: string;
}
const Ratings = ({ color }: Props) => {
  return (
    <div className="ratings-component">
      {[1, 2, 3, 4, 5].map((item, index) => (
        <Icon key={index} icon="star-empty" iconSize={40} color={color} />
      ))}
    </div>
  );
};

export default Ratings;
