import { Button, Dialog, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import Axios from "axios";
import firebase from "config/firebase";
import JSZip from "jszip";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "../../../../../../model/store";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  data: any;
  statusHeaders: any;
  formatedData: any;
}
const ViewApplicationModal = ({
  isOpen,
  closeModal,
  data,
  statusHeaders,
  formatedData,
}: Props) => {
  const db = firebase.firestore();
  const zip = new JSZip();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  console.log("data", data);
  //    console.log("business",business)

  const [menuItems, setMenuItems]: any[] = useState([]);
  const [noteTxt, setNoteTxt] = useState<string>("");
  const [employeeApplicationData, setEmployeeApplicationData]: any = useState();
  const [applicationStatus, setApplicationStatus]: any = useState();

  const renderMenuItems = (header: any) => {
    const menuItems = [
      {
        name: "Applied",
        colorCode: "#43EF4A",
      },
    ];

    header &&
      header.map((item: any, key: number) => {
        menuItems.push({
          name: item.name,
          colorCode: item.colorCode,
        });
      });

    menuItems.push(
      {
        name: "Hire",
        colorCode: "#43EFB1",
      },
      {
        name: "No Hire",
        colorCode: "#EF4377",
      }
    );

    setMenuItems(menuItems);
  };

  const addNotes = () => {
    const payload = {
      author: {
        userId: business.id,
        firstName: business.owner.firstName,
        lastName: business.owner.lastName,
        createdAt: new Date(),
        updatedAt: new Date(),
      },
      comment: noteTxt,
    };
    return db
      .collection("EmployeeApplications")
      .doc(data.id)
      .update({ comments: firebase.firestore.FieldValue.arrayUnion(payload) })
      .then(() => {
        setNoteTxt("");
      });
  };

  const getModalData = (id: string) => {
    const unsubscribe = db
      .collection("EmployeeApplications")
      .where("id", "==", id)
      .onSnapshot((snapshot) => {
        let EmployeeApplication: any = [];
        snapshot.forEach((doc) => {
          EmployeeApplication = [...EmployeeApplication, doc.data()];
        });
        if (EmployeeApplication.length > 0) {
          setEmployeeApplicationData(EmployeeApplication[0]);
        }
      });

    return () => unsubscribe();
  };

  const findIndexBasedOnName = (name: string) => {
    const index = formatedData.findIndex((item: any) => item.title == name);
    if (index != -1) {
      return formatedData[index].items.length + 1;
    } else return 0;
  };

  const updateApplicationStatus = async (id: string, name: string) => {
    const index = findIndexBasedOnName(name);

    if (name.length > 0 && index != null) {
      return await db
        .collection("EmployeeApplications")
        .doc(id)
        .update({ status: name?.toString(), index: index?.toString() });
    }
  };

  useEffect(() => {
    renderMenuItems(statusHeaders);
  }, [statusHeaders]);

  useEffect(() => {
    if (data) {
      getModalData(data.id);
    }
  }, [data]);

  useEffect(() => {
    console.log("employeeApplicationData", employeeApplicationData);

    if (employeeApplicationData && menuItems) {
      const index = menuItems.findIndex(
        (item: any) => item.name == employeeApplicationData.status
      );
      if (index !== -1) {
        setApplicationStatus({
          title: employeeApplicationData?.status,
          colorCode: menuItems[index].colorCode,
        });
      }
    }
  }, [employeeApplicationData, menuItems]);

  const firstName = employeeApplicationData?.name
    .split(" ")
    .slice(0, -1)
    .join(" ");
  const lastName = employeeApplicationData?.name.split(" ").slice(-1).join(" ");

  const downloadAttachment = async (data: any) => {
    // console.log('data', data);
    const applicationId = data.id;
    const fileName = data.name + "_attachments";

    //pass in the application id as param
    Axios.get(
      "https://us-central1-tango-2.cloudfunctions.net/nestApi/hiring/employee-applications/" +
        applicationId +
        "/attachments/zip",
      {
        responseType: "arraybuffer",
      }
    )
      .then((response) => {
        // console.log('data ', response.data);
        const blob = new Blob([response.data], { type: "application/zip" });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(function () {
        console.log("complete");
      });

    //const reader = new FileReader();
    // console.log('dataUrls', data);

    // function download(source: any) {
    //   const fileName = source.split('/').pop();
    //   var el = document.createElement('a');
    //   el.setAttribute('href', source);
    //   el.setAttribute('download', fileName);
    //   document.body.appendChild(el);
    //   el.click();

    //   setTimeout(() => el.remove());
    // }
    // window.open(data[0], '_blank');

    //       data.forEach((item:any)=>download(item));
    {
      /*
           data.forEach(async(url:any,key:any)=>{
              const response = await fetch(url,{
                  mode:'no-cors',
                  method: 'GET',
                  headers:{
                     'Access-Control-Allow-Origin': '*',
                     'Accept': "application/json"
                  }
              }).then(response=>{
                  console.log("response",response)
                  response.blob().then(blob=>{
                      console.log("blob",blob);
                      let imgUrl = URL.createObjectURL(blob);
                      console.log("imgUrl",imgUrl);
                      let link = document.createElement('a');
                      link.setAttribute('download',url.split('/').pop())
                      link.href = imgUrl;
                      document.body.appendChild(link);
        //              // a.download = 'image'+key+ `${url.includes('jpg') ? '.jpg':
        //              // imgUrl.includes('jpeg') ? '.jpeg' : 
        //              // imgUrl.includes('png') ? '.png': '.jpg'}`;
        //              //
                       link.click();
                       document.body.removeChild(link);
                  })
           
              })

           });
*/
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={closeModal}
      className="view-application-modal"
      hasBackdrop
    >
      <div className="modal-header">
        <Icon
          icon="cross"
          color="#7C7F93"
          size={18}
          onClick={() => closeModal()}
        />
      </div>
      <div className="modal-body">
        <div className="body-left">
          <div className="user-info section">
            <Box display="flex">
              <div className="user-avatar">
                {firstName?.charAt(0)?.toUpperCase()}
                {lastName?.charAt(0)?.toUpperCase()}
              </div>
              <div>
                <div className="user-name">{employeeApplicationData?.name}</div>
                <div className="other-info">
                  Applied for{" "}
                  {employeeApplicationData?.jobTitle
                    ? employeeApplicationData?.jobTitle
                    : ""}
                </div>
                <div className="other-info">
                  at {employeeApplicationData?.location?.name}
                </div>
                {employeeApplicationData?.attachments.length >= 1 && (
                  <div
                    className="view-resume-link"
                    onClick={() => downloadAttachment(employeeApplicationData)}
                  >
                    <a>Download Attachments</a>
                  </div>
                )}
              </div>
            </Box>

            <div className="status-dropdown">
              <Popover2
                position="bottom"
                content={
                  <Menu className="status-popover-menu">
                    <MenuItem text="Statues" />
                    {menuItems &&
                      menuItems.map((item: any) => {
                        return (
                          <MenuItem
                            icon={
                              <Icon
                                icon="dot"
                                color={item.colorCode}
                                size={22}
                              />
                            }
                            text={item.name}
                            onClick={() => {
                              updateApplicationStatus(
                                employeeApplicationData.id,
                                item.name
                              );
                            }}
                          />
                        );
                      })}
                    <MenuItem
                      icon={<Icon icon="trash" color="#EF436C" size={12} />}
                      text="No Hire"
                    />
                  </Menu>
                }
                fill
                className="status-popover"
              >
                <Box display="flex" justifyContent="space-between">
                  <div
                    className="top-border"
                    style={{ background: applicationStatus?.colorCode }}
                  ></div>
                  <Box display="flex" alignItems="center">
                    <div>{applicationStatus?.title}</div>
                    <Icon
                      icon="dot"
                      size={14}
                      color={applicationStatus?.colorCode}
                    />
                  </Box>
                  <Icon icon="chevron-down" />
                </Box>
              </Popover2>
            </div>
          </div>
          <div className="user-question">
            {employeeApplicationData?.responses?.map(
              (item: any, key: number) => {
                if (
                  typeof item.answer == "object" ||
                  typeof item.question == "object"
                ) {
                  return;
                }
                return (
                  <div className="section">
                    <div className="question-number">Question {key + 1}</div>
                    <div className="question-title">{item?.question}</div>
                    <div className="question-answer">{item?.answer}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="body-right">
          <ul className="comments-container">
            {/* <li>
                        <div className='comment-header'>
                            <div className="comment-user">Brandon Herron</div>
                            <div className='comment-date'>02/14/2022 3:41 PM</div>
                        </div>
                        <div className="comment-description">
                            <span className='tagged'>@Kevin Manning</span> - this person looks pretty solid
                        </div>
                    </li> */}
            {employeeApplicationData?.comments?.map((item: any, key: any) => (
              <li>
                <div className="comment-header">
                  <div className="comment-user">
                    {item?.author?.firstName} {item?.author?.lastName}
                  </div>
                  <div className="comment-date">
                    {moment(item?.author?.createdAt?.toDate())
                      .format("MM/DD/YY h:mm A")
                      .toString()}
                  </div>
                </div>
                <div className="comment-description">{item.comment}</div>
              </li>
            ))}
          </ul>
          <div className="reply-container">
            <textarea
              placeholder="Type to send a comment.."
              value={noteTxt}
              onChange={(e) => setNoteTxt(e.target.value)}
            />
            <Button
              text="Enter Note"
              disabled={noteTxt?.length > 0 ? false : true}
              className="enter-note"
              onClick={() => addNotes()}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ViewApplicationModal;
