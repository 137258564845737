
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { locationInstruction, primaryGroupInstruction, secondaryGroupInstruction } from "../tableCells";


export type ItemProps = {
    uniqueId: string;
    itemName: string;
    primaryGroup: string;
    secondaryGroup: string;
    productionUnitName: string;
    ingredientUnitName: string;
    conversionRatio: number;
    location: string;
    inStock: boolean;
};
const instructions: { [x: string]: RenderInstruction<ItemProps> } = {};
instructions.inStock = {
    type: "boolean",
    useCheckbox: false,
    trueText: "Yes",
    falseText: "No",
    readOnly: true,
};
instructions.conversionRatio = {
    type: "number",
};
instructions.ingredientUnitName = {
    type: "default",
    readOnly: true,
    editablePrefix: "new__",
};

instructions.primaryGroup = primaryGroupInstruction;

instructions.secondaryGroup = secondaryGroupInstruction;

instructions.location = locationInstruction;

export default instructions;
