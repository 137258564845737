/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Dialog, Icon } from "@blueprintjs/core";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ScheduleEventType } from "controllers/schedule";
import {
  approveDropRequest,
  approveRequest,
  approveTradeRequest,
  denyRequest,
  undoApproveRequest,
} from "controllers/scheduleEvents";

import Box from "components/Box";

import { getPositionNameById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

import "./style.css";

interface Props {
  requestId: string | null;
  request: ScheduleEvent | undefined;
  closeModal: () => void;
  selectedRequest?: ScheduleEvent | null;
  showAssignStaffModal: (shiftId: string, scheduleId: string) => void;
}
const RequestModal = ({
  requestId,
  closeModal,
  request,
  showAssignStaffModal,
  selectedRequest,
}: Props) => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const [approveLoading, setApproveLoading] = useState(false);
  const [undoLoading, setUndoLoading] = useState(false);

  const [denyLoading, setDenyLoading] = useState(false);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const schedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  let shift = null;

  const requestToUse = selectedRequest || request;

  if (request?.eventType !== "time_off") {
    shift = schedules
      .find((schedule) => schedule.id === request?.scheduleId)
      ?.shifts.find((s) => s.id === request?.shiftId);
  }

  const positionName = shift
    ? getPositionNameById(businessSettings, shift.position)
    : null;

  const sendingSchedule = schedules.find(
    (schedule) => schedule.id === requestToUse?.sendingScheduleId
  );
  const sendingShift = sendingSchedule?.shifts.find(
    (s) => s.id === requestToUse?.sendingShiftId
  );
  const senderStaff = fellowStaffMembers.find(
    (sm) => sm?.id === requestToUse?.senderStaff?.id
  );
  const receiverStaff = fellowStaffMembers.find(
    (sm) => sm?.id === requestToUse?.receiverStaff?.id
  );
  const sendingPositionName = sendingShift
    ? getPositionNameById(businessSettings, sendingShift?.position)
    : null;

  const approveRequestHandler = async () => {
    if (requestId) {
      setApproveLoading(true);
      if (
        request?.eventType === "drop" &&
        request?.scheduleId &&
        request?.shiftId
      ) {
        await approveDropRequest(
          requestId,
          request.scheduleId,
          request.shiftId
        );
      } else if (request?.eventType === "trade" && request) {
        await approveTradeRequest(request);
      } else {
        await approveRequest(requestId);
      }
      setApproveLoading(false);
      closeModal();
    }
  };

  const undoApprovalHandler = async () => {
    if (requestId) {
      setUndoLoading(true);
      await undoApproveRequest(requestId);
      setUndoLoading(false);
      closeModal();
    }
  };

  const denyRequestHandler = async () => {
    if (requestId) {
      setDenyLoading(true);
      await denyRequest(requestId);
      setDenyLoading(false);
      closeModal();
    }
  };

  if (!fellowStaffMembers) {
    return null;
  }

  const staffMember = fellowStaffMembers.find(
    (sm) => sm?.id === request?.senderStaff?.id
  );

  const renderTimeOffDetails = () => {
    const sortedTimeOffDates = request?.timeOffRequestDates?.sort(
      (a, b) => a?.toMillis() - b?.toMillis()
    );
    if (!sortedTimeOffDates && (!request?.timeOffRequestStartDate && !request?.timeOffRequestEndDate)) return null;
    if (request?.timeOffRequestStartDate && request?.timeOffRequestEndDate) {
      return (
        <Box className="shift-time" display="flex" flex={0.6}>
          <Box
            className="shift-start-time  body-section border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <div className="section-title">From:</div>
            <div className="section-body" style={{ fontSize: 12 }}>
              {moment(request?.timeOffRequestStartDate?.toDate()).format(
                "ddd MMM DD, YYYY hh:mm a"
              )}
            </div>
          </Box>
          <Box
            className="shift-end-time  body-section  border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <div className="section-title">To:</div>
            <div className="section-body" style={{ fontSize: 12 }}>
              {moment(
                request?.timeOffRequestEndDate?.toDate()
              ).format("ddd MMM DD, YYYY hh:mm a")}
            </div>
          </Box>
        </Box>
      );
    }
    if (sortedTimeOffDates && sortedTimeOffDates.length > 1) {
      return (
        <Box className="shift-time" display="flex" flex={0.6}>
          <Box
            className="shift-start-time  body-section border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <div className="section-title">From:</div>
            <div className="section-body" style={{ fontSize: 12 }}>
              {moment(sortedTimeOffDates[0]?.toDate()).format(
                "ddd MMM DD, YYYY"
              )}
            </div>
          </Box>
          <Box
            className="shift-end-time  body-section  border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <div className="section-title">To:</div>
            <div className="section-body" style={{ fontSize: 12 }}>
              {moment(
                sortedTimeOffDates[sortedTimeOffDates.length - 1]?.toDate()
              ).format("ddd MMM DD, YYYY")}
            </div>
          </Box>
        </Box>
      );
    }
    if (sortedTimeOffDates?.length === 1) {
      return (
        <Box className="shift-time" display="flex" flex={0.6}>
          <Box
            className="shift-start-time  body-section border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <div className="section-title">Time Off day:</div>
            <div className="section-body" style={{ fontSize: 12 }}>
              {moment(sortedTimeOffDates[0]?.toDate()).format("ddd MMM DD, YYYY")}
            </div>
          </Box>
          <Box
            className="shift-end-time  body-section  border-left"
            display="flex"
            flex={1}
            flexDirection="column"
          />
        </Box>
      );
    }
  };

  return (
    <Dialog
      isOpen={Boolean(requestId)}
      canOutsideClickClose
      onClose={closeModal}
      className="request-modal"
    >
      {request ? (
        <>
          <Box className="modal-header">
            <div className="modal-back-button" onClick={closeModal}>
              <Icon icon="chevron-left" iconSize={8} />
            </div>
            {request.eventType === "time_off"
              ? "Time Off request"
              : moment(request.eventDate.toMillis()).format("dddd, MMM D")}
          </Box>
          <Box className="modal-body">
            <Box display="flex" flexDirection="row" className="border-bottom">
              <Box
                className="staff-name body-section"
                display="flex"
                flex={0.4}
                flexDirection="column"
              >
                <div className="section-title">{positionName || "-"}</div>
                <div className="section-body">
                  {staffMember?.contact?.firstName}{" "}
                  {staffMember?.contact?.lastName}
                </div>
              </Box>
              {request.eventType === "time_off" ? (
                renderTimeOffDetails()
              ) : (
                <Box className="shift-time" display="flex" flex={0.6}>
                  <Box
                    className="shift-start-time  body-section border-left"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                  >
                    <div className="section-title">start</div>
                    <div className="section-body">
                      {shift
                        ? moment(shift.startDate.toMillis()).format("HH:mm")
                        : "-"}
                    </div>
                  </Box>
                  <Box
                    className="shift-end-time  body-section  border-left"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                  >
                    <div className="section-title">end</div>
                    <div className="section-body">
                      {shift
                        ? moment(shift.endDate.toMillis()).format("HH:mm")
                        : "-"}
                    </div>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className="body-section border-bottom">
              <div className="section-title">Reason</div>
              <div className="section-body">
                {request?.description || "Reason not provided"}
              </div>
            </Box>
            {request.eventType === "time_off" && (
              <Box className="body-section border-bottom">
                <div className="section-title">Time Submitted</div>
                <div className="section-body">
                  {moment(request?.createdAt?.toDate()).format(
                    "YYYY MMMM D hh:mm a"
                  )}
                </div>
              </Box>
            )}
            {request.eventType !== ScheduleEventType.time_off && (
              <Box className="body-section border-bottom">
                <div className="section-title">Picked Up By</div>
                <div className="section-body">Unassigned</div>
                {request?.shiftId ? (
                  <Box
                    onClick={() =>
                      showAssignStaffModal(
                        request.shiftId as string,
                        request.scheduleId as string
                      )
                    }
                    className="assign-container"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <div>Assign</div>
                    <Icon icon="add" iconSize={16} />
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
          {request.eventType === "time_off" && request.status === "approved" ? (
            <Box
              className="modal-footer"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                disabled
                icon="small-tick"
                text="Approved"
                className="footer-button"
                id="approve-button"
              />
              <Button
                onClick={undoApprovalHandler}
                loading={undoLoading}
                disabled={undoLoading}
                icon="cross"
                text="Undo Approval"
                className="footer-button"
                id="deny-button"
              />
            </Box>
          ) : null}
          {request.status === "pending" && (
            <Box
              className="modal-footer"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                onClick={denyRequestHandler}
                loading={denyLoading}
                disabled={approveLoading || denyLoading}
                icon="cross"
                text="Deny"
                className="footer-button"
                id="deny-button"
              />
              <Button
                onClick={approveRequestHandler}
                loading={approveLoading}
                disabled={approveLoading || denyLoading}
                icon="small-tick"
                text="Approve"
                className="footer-button"
                id="approve-button"
              />
            </Box>
          )}
        </>
      ) : null}
    </Dialog>
  );
};
export default RequestModal;
