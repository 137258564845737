import { Button, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ITipsByMenuReport, getTipsByMenu } from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import TipsByMenuDocument from "../PdfReports/TipsByMenuPdfDocument";
// import LaborReportModal from "../LaborReportModal";
// import SalesMixReportDocument from "../PdfReports/SalesMixReportDocument";
import "../style.css";

const TipsByMenu = () => {
  const [dailyReports, setDailyReports] = useState<ITipsByMenuReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const deliverectProductTypes: FirebaseDeliverectProductType[] = useSelector(
    (state: RootState) => state.productTypes
  );

  // Sales	Meals	Average Table	Turn

  const fetchMoreData = useCallback(async () => {
    if (business && earliestFetchedDate) {
      const productTypes = deliverectProductTypes.reduce(
        (acc, val) => ({ ...acc, [val.plu]: val.productType }),
        {}
      );
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract(30, "days")
        .toDate();
      const laborReports = await getTipsByMenu(
        business.id,
        newEarliestDate,
        oldEarliestDate
      );

      setEarliestFetchedDate(newEarliestDate);
      setLatestResponseLength(laborReports.length);
      setDailyReports(
        [...dailyReports, ...laborReports].sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        )
      );
    }
  }, [dailyReports, earliestFetchedDate, business?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (business) {
        const productTypes = deliverectProductTypes.reduce(
          (acc, val) => ({ ...acc, [val.plu]: val.productType }),
          {}
        );
        const earliestDate = moment().subtract("days", 30).toDate();
        const reports = await getTipsByMenu(
          business.id,
          earliestDate,
          moment().add(1, "day").toDate()
        );
        setEarliestFetchedDate(earliestDate);
        setLatestResponseLength(reports.length);
        setDailyReports(
          reports.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
        );
        // console.log("Report: ", reports);
      }
    };

    fetchInitialData();
  }, [business?.id, deliverectProductTypes]);

  const createBlob = async (report: ITipsByMenuReport, snapshot = false) => {
    try {
      const blob = await pdf(
        <TipsByMenuDocument
          report={report}
          businessName={business.businessName || ""}
          snapshot={snapshot}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      setReportIdLoading(null);
      window.open(url, "_blank");
    } catch (e) {
      setReportIdLoading(null);
      alert("Opps, something went wrong generating your report");
      console.log("error generating report", e);
    }
  };

  const hasMoreDocumentsToLoad = _.isNull(latestResponseLength)
    ? true
    : Boolean(latestResponseLength);
  return (
    <div className="w-full">
      <div className="page-header">
        <div className="page-title">Tips by Menu Report</div>
      </div>
      <Box display="flex" flexDirection="column">
        {dailyReports.length === 0 ? (
          <div style={{ marginTop: 20 }}>
            <Spinner size={40} />
            <div
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              This may take a couple minutes to load.
            </div>
          </div>
        ) : (
          <>
            {_.uniqBy(dailyReports, "id").map((report) => {
              const isLoadingBlob = reportIdLoading === report.id;
              const renderButton = () => {
                const lastReportStartDay = moment(report.date);
                const lastReportEndDay = lastReportStartDay
                  .clone()
                  .add(1, "day");
                if (
                  moment().isAfter(lastReportStartDay) &&
                  moment().isBefore(lastReportEndDay)
                ) {
                  return (
                    <div
                      className="report-table-text"
                      onClick={() => {
                        setReportIdLoading(report.id);

                        createBlob(report, true);
                        // fetchAndOpenReport()
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View Snapshot
                    </div>
                  );
                }
                if (isLoadingBlob) {
                  return <Spinner size={20} />;
                }
                return (
                  <div
                    className="report-table-text"
                    onClick={() => {
                      setReportIdLoading(report.id);

                      createBlob(report);
                      // fetchAndOpenReport()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View Report
                  </div>
                );
              };
              return (
                <Box
                  key={report.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: 5, marginTop: 10 }}
                >
                  <div className="report-table-text">
                    {moment(report.date).format("MMMM D, YYYY")}
                  </div>
                  {renderButton()}
                </Box>
              );
            })}
            {hasMoreDocumentsToLoad && (
              <div className="center-button">
                <Button
                  text="Load more"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await fetchMoreData();
                    setLoading(false);
                  }}
                  className="load-more-button" // create-new-teammate
                  fill
                />
              </div>
            )}
          </>
        )}
      </Box>
    </div>
  );
};
export default TipsByMenu;
