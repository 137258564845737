import { Icon, InputGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import firebase from "config/firebase";
import { default as _, default as lodash } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getAllJobFunctions } from "model/selectors/teamsTable";

import Box from "../../../../components/Box";
import { RootState } from "../../../../model/store";
import "./index.css";

const db = firebase.firestore();

const ViewArchivedJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usersLocations = useSelector((state: RootState) => state.locations);

  const [jobs, setJobs]: any = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState<
    "foh" | "boh" | string
  >("");
  const jobFunc = useSelector(getAllJobFunctions);
  const [searchText, setSearchText] = useState("");
  const [rolesForSidebar, setRolesForSidebar] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const getDepartment = (jobTitle: string) => {
    const jobIndex = Object.keys(jobFunc).find(
      (jobFuncKey) => jobFunc[jobFuncKey]["title"] === jobTitle
    );
    if (jobIndex) {
      //@ts-ignore
      return jobFunc[jobIndex]["departmentId"];
    } else {
      return "";
    }
  };
  const transformDataforGrid = (data: any[]) =>
    data.map((row: any) => {
      let applicantcount = 0;
      //@ts-ignore
      if (location.state.applications) {
        //@ts-ignore
        location.state.applications.map((item: any) => {
          if (row.jobTitle.toLowerCase() == item.jobTitle?.toLowerCase()) {
            applicantcount++;
          }
        });
      }

      return {
        id: row.id,
        businessId: row.businessId,
        jobTitle: row.jobTitle,
        jobAddress: row.location.jobAddress,
        jobType: row.jobType,
        date: moment(row.createdAt.toDate()).format("MM/DD/YYYY").toString(),
        location: row.location,
        department: getDepartment(row.jobTitle),
        status: row.status || null,
        Applicants: applicantcount,
      };
    });

  const getJobsData = () => {
    const unsubscribe = db
      .collection("JobPosting")
      //@ts-ignore
      .where("businessId", "==", location.state.businessId)
      .onSnapshot((snapshot) => {
        let jobsData: any[] = [];
        snapshot.forEach((doc) => {
          jobsData = [...jobsData, doc.data()];
        });
        const sortedData = jobsData.sort(
          (b, a) => a.createdAt.toDate() - b.createdAt.toDate()
        );
        setJobs(transformDataforGrid(sortedData));
      });

    return () => unsubscribe();
  };
  useEffect(() => {
    //@ts-ignore
    const groupedByRoles = _.groupBy(
      jobs.filter((item: any) => item.status === "archive"),
      (item: any) => item.jobTitle
    );
    //@ts-ignore
    setRolesForSidebar(Object.keys(groupedByRoles));
  }, [jobs]);

  useEffect(() => {
    //@ts-ignore
    const { businessId, applications } = location.state;
    if (businessId && applications) {
      getJobsData();
    }
  }, []);

  const getDotColor = (status: string) => {
    switch (status) {
      case "active":
        return "#5BEF43";
      case "paused":
        return "yellow";
      case "archive":
        return "orange";
      case "delete":
        return "red";
    }
  };
  const getStatusLabel = (status: string) => {
    switch (status) {
      case "active":
        return "Active";
      case "paused":
        return "Paused";
      case "archive":
        return "Archive";
      case "delete":
        return "Delete";
    }
  };
  const updateStauts = async (jobData: any, status: any) => {
    try {
      const documentSnapShot = await firebase
        .firestore()
        .collection("JobPosting")
        .doc(jobData.id)
        .update({ status: status.slug, updatedAt: new Date() });
      const jobIndex = jobs.findIndex((item: any) => item.id === jobData.id);
      const clonedJobsData = [...jobs];
      clonedJobsData[jobIndex]["status"] = status.slug;
      setJobs(clonedJobsData);
      if (filteredData.length > 0) {
        const clonedFilterData = [...filteredData];
        const jobSearchIndex = clonedFilterData.findIndex(
          (item: any) => item.id === jobData.id
        );
        //@ts-ignore
        clonedFilterData[jobSearchIndex]["status"] = status.slug;
        setFilteredData(clonedFilterData);
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    if (selectedDepartment.length > 0) {
      const filteredData = jobs.filter((item: any) => {
        if (selectedDepartment === "other") {
          return item.department === "";
        } else {
          return item.department === selectedDepartment;
        }
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (searchText.length > 0) {
      const filteredData = jobs.filter((item: any) => {
        const position = item.jobTitle
          .toLowerCase()
          .search(searchText.toLowerCase());
        if (position >= 0) {
          return item;
        }
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }, [searchText]);

  useEffect(() => {
    if (selectedRole.length > 0) {
      const filteredStaffData = jobs.filter(
        (item: any) => item.jobTitle === selectedRole
      );
      setFilteredData(filteredStaffData);
    } else {
      setFilteredData([]);
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedLocation.length > 0) {
      const filteredData = jobs.filter(
        (item: any) => item.businessId === selectedLocation
      );
      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }, [selectedLocation]);

  const renderJobRow = (item: any) => {
    if (item.status === "archive") {
      return (
        <tr>
          <td>
            <div>{item.jobTitle}</div>
            <ul>
              <li>
                {item.location && item.location.name ? item.location.name : ""}
              </li>
              <li>{item.jobType}</li>
              <li>{item.department ? item.department.toUpperCase() : ""}</li>
            </ul>
          </td>
          <td className="status-column">
            {item.status ? (
              <Icon icon="dot" color={getDotColor(item.status)} size={20} />
            ) : null}{" "}
            <Select
              items={[
                { name: "Active", slug: "active" },
                { name: "Paused", slug: "paused" },
                { name: "Archive", slug: "archive" },
                { name: "Delete", slug: "delete" },
              ]}
              filterable={false}
              itemRenderer={(statusItem, { handleClick }) => (
                <MenuItem text={statusItem.name} onClick={handleClick} />
              )}
              onItemSelect={(statusItem) => {
                updateStauts(item, statusItem);
              }}
            >
              <MenuItem
                text={item.status ? getStatusLabel(item.status) : "No Status"}
              />
            </Select>
          </td>
          <td className="applicants-column">{item.Applicants} applicants</td>
          <td className="date-column">
            <div>{item.date}</div>
            {/* <Link to="/view-applications">
         <div className='view-application'
         onClick={()=>setSelectedOption('applications')}
         >View Applications</div>
         </Link> */}
            <div
              className="view-application"
              onClick={() => {
                // if (item.Applicants > 0) {
                // setSelectedOption("applications");
                // setSelectedRole(lodash.capitalize(item.jobTitle));
                navigate("/view-applications", {
                  state: {
                    filter: lodash.capitalize(item.jobTitle),
                    jobdetail: {
                      noOfApplicant: item.Applicants,
                      jobType: item.jobType,
                      address: item.address,
                    },
                  },
                });

                // }
              }}
            >
              View Applications
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div>
      <div
        className={`application-tracker-header ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        id="view-archives"
      >
        <div className="small-title">Archives</div>
        <div className="big-title">Previous Jobs</div>
        <Box className="back-button" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" color="#7C7F93" />
          Back to Jobs
        </Box>
      </div>
      <Box
        className={`application-tracker-page  ${
          emulatorMode ? "emulatorMode" : ""
        } `}
        flex={1}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {/*sidebar*/}
        <div className="sidebar-container">
          <div className="sidebar">
            <div className="sidebar-title">Action</div>
            <div className="sidebar-section">
              <InputGroup
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                leftIcon="search"
                intent="none"
              />
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Type:</div>
              <ul>
                <li
                  className={selectedDepartment === "boh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "boh") {
                      setSelectedDepartment("boh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  BOH
                </li>
                <li
                  className={selectedDepartment === "foh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "foh") {
                      setSelectedDepartment("foh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  FOH
                </li>
                <li onClick={() => setSelectedDepartment("")}>Other</li>
              </ul>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Role:</div>
              <select onChange={(e) => setSelectedRole(e.target.value)}>
                <option value="">Select a role</option>
                {rolesForSidebar.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Location:</div>
              <select onChange={(e) => setSelectedLocation(e.target.value)}>
                <option value="">Select a Location</option>
                {usersLocations
                  ? usersLocations.map((item, index) => {
                      return (
                        <option value={item.id}>{item.businessName}</option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
        </div>
        <div className="jobs-container archive-table">
          <table>
            <tr>
              <th>Job Title</th>
              <th>Status</th>
              <th>Applicants</th>
              <th>Posted Date</th>
            </tr>
            {filteredData.length > 0
              ? filteredData.map((item) => renderJobRow(item))
              : jobs.map((item: any) => renderJobRow(item))}
          </table>
        </div>
      </Box>
    </div>
  );
};
export default ViewArchivedJobs;
