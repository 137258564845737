export enum ComponentType {
  shortText = "short-text",
  longText = "long-text",
  email = "email",
  phone = "phone",
  url = "url",
  multipleChoice = "multiple-choice",
  pictureChoice = "picture-choice",
  yesNo = "yes-no",
  dropdown = "dropdown",
  ratings = "ratings",
  uploadFile = "upload-file",
  legal = "legal",
  correctOrder = "correct-order",
  digitalSignature = "digital-signature",
  labelPicture = "label-picture",
  fillInBlank = "fill-in-blank",
  matching = "matching",
  endingSection = "endingSection",
  welcomeScreen = "welcomeScreen",
  statement = "statement",
}
export interface ThemeProp {
  questionColor: string;
  answerColor: string;
  buttonColor: string;
  buttonTextColor: string;
  backgroundColor: string;
  systemFont: string;
  themeName: string;
  backgroundImage: string | ArrayBuffer | null;
  backgroundImageBrightness: number;
  type: "system" | "custom";
  status: "saved" | "draft";
  id: string;
  businessId: string;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  enabled: boolean;
}
export type ImageLayoutType =
  | "top"
  | "left"
  | "right"
  | "right-full"
  | "left-full"
  | "background";
export interface EndingSectionProps {
  title: string;
  subtitle: string;
  showButton: boolean;
  buttonLink: string;
  buttonLabel: string;
  image: string;
  showButtonLink: boolean;
  index: number;
  imageBrightness: number;
  imageLayout: ImageLayoutType;
  id: number;
  type: EndingSectionType;
}
export type EndingSectionType = "endingSection" | "welcomeScreen";
export interface SurveyData {
  id: number;
  title: string;
  subtitle: string;
  type: ComponentType;
  required: boolean;
  value: string | null;
  choices: string[];
  fillInBlankChoices: Array<{ data: string[] }> | null;
  selectedChoices: any[];
  label?: string;
  matchingObj?: { leftArray: string[]; rightArray: string[] } | null;
  images: string[];
  rules: Rule[];
  score: number;
  isScorePending: boolean;
}

export interface Question {
  id: number;
  type: ComponentType;
  title: string;
  subtitle: string;
  values: any;
  required: boolean;
  hasAnswer: boolean;
  images: Array<string>;
  answer: null | any;
  points: number;
}
export type FormType =
  | "General Survey"
  | "Quiz/Training"
  | "Ticket"
  | "Job Application";
// types related to logic
export type OptionType<T> = { label: string; value: T };
export type Operators =
  | "is-equal-to"
  | "is-not-equal-to"
  | "begins-with"
  | "ends-with"
  | "contains"
  | "does-not-contain";
export type OperatorOptions = OptionType<Operators>;
export type OperationLogic = "jump-to";
export type MainCondition = "if" | "always" | "";
export type OperationLogicOptions = OptionType<OperationLogic>;
export type ChainBy = "or" | "and" | null;
export interface Condition {
  chainBy: ChainBy;
  operator: Operators;
  operationValue: string;
}
export interface Rule {
  mainCondition: MainCondition;
  origin: number;
  operationLogic: OperationLogic;
  destination: number | null;
  default: boolean;
  conditions: Condition[];
}
