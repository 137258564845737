import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import CrossIcon from "../../../assets/calendar/status_cross.svg";
import QuestionMarkIcon from "../../../assets/calendar/status_question_mark.svg";
import TickIcon from "../../../assets/calendar/status_tick.svg";
import { CustomEventType } from "./useCalendar";

interface Props {
  onClickOutside: () => void;
  isOpen: boolean;
  event: CustomEventType;
  onProposeTimeClick: () => void;
  onCloseProposeTime: () => void;
  onEventClick: () => void;
  showTimePicker: boolean;
  showAccountsPicker: boolean;
  toggleAccountsPicker: () => void;
  accounts: any[];
}
const EventPopover = ({
  onClickOutside,
  isOpen,
  event,
  onProposeTimeClick,
  onCloseProposeTime,
  onEventClick,
  showTimePicker,
  toggleAccountsPicker,
  showAccountsPicker,
  accounts,
}: Props) => {
  const { title, description, allDay, videoCallUrl } = event;
  return (
    <UIComponents.Popover
      onClickOutside={onClickOutside}
      containerClassName={"z-10"}
      isOpen={isOpen}
      content={
        <div className={"flex"}>
          <div className={"w-48 shadow-card rounded-2xl bg-white"}>
            {!allDay ? (
              <div
                className={"py-3 px-4 flex justify-between items-center"}
                onClick={toggleAccountsPicker}
              >
                <div className={"flex"}>
                  <UIComponents.Checkbox
                    type={"small"}
                    checked={true}
                    colorType={"blue"}
                  />
                  <div
                    className={
                      "font-lato-bold text-xs text-black ml-2 cursor-pointer"
                    }
                  >
                    Ops team
                  </div>
                </div>
                <UIComponents.Icon name={"chevron-right"} size={"12"} />
              </div>
            ) : null}

            <div
              className={`px-3.5 pt-2.5 pb-3.5 bg-blue-1 border-t border-b border-blue-4 ${
                allDay ? "rounded-t-2xl" : ""
              }`}
            >
              <div className={"font-lato-black text-sm text-black"}>
                {" "}
                {title}
              </div>
              <div className={"mt-2 flex justify-between items-center"}>
                <div className={"font-lato-medium text-veryTiny text-black"}>
                  {description}
                </div>
                {videoCallUrl ? (
                  <div
                    className={
                      "h-3.5 w-3.5 bg-blue-grey-3 border border-blue-grey-4 rounded-full flex justify-center items-center"
                    }
                  >
                    <UIComponents.Icon name={"call"} size={"8"} />
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={
                "px-3.5 py-2.5 flex justify-between items-center border-b border-grey-1"
              }
            >
              <div className={"font-lato-medium text-veryTiny text-black"}>
                April 19, 2022
              </div>
              <div className={"font-lato-black text-veryTiny text-black"}>
                8:00 AM — 9:00 AM
              </div>
            </div>
            {!allDay ? (
              <ul className={"px-3.5 py-2.5 border-b border-grey-1"}>
                {[1, 2, 3, 4].map((item, index) => (
                  <li
                    key={item}
                    className={
                      "font-lato-medium text-veryTiny text-black flex items-center"
                    }
                  >
                    <img src={TickIcon} className={"mr-2.5"} /> Billy Joel
                    (organizer)
                  </li>
                ))}
                <li
                  className={
                    "font-lato-medium text-veryTiny text-black flex items-center"
                  }
                >
                  <img src={CrossIcon} className={"mr-2.5"} />{" "}
                  first.last@gmail.com
                </li>
                <li
                  className={
                    "font-lato-medium text-veryTiny text-black flex items-center"
                  }
                >
                  <img src={QuestionMarkIcon} className={"mr-2.5"} />{" "}
                  first.last@gmail.com
                </li>
              </ul>
            ) : null}
            {!allDay ? (
              <div
                className={
                  "flex justify-between items-center px-3.5 py-3 border-b border-grey-1"
                }
              >
                <div className={"flex items-center"}>
                  <img src={TickIcon} className={"mr-2.5"} />
                  <div className={"font-lato-medium text-veryTiny text-black "}>
                    Accepted
                  </div>
                </div>
                <UIComponents.Icon
                  name={"chevron-down"}
                  color={"#AAADB6"}
                  size={"12"}
                />
              </div>
            ) : null}
            {!allDay ? (
              <div
                className={
                  "flex justify-between items-center px-3.5 py-3 font-lato-medium text-veryTiny text-grey-2 border-b border-grey-1 cursor-pointer"
                }
                onClick={onProposeTimeClick}
              >
                Propose a new time...
              </div>
            ) : null}

            <input
              type={"text"}
              className={
                "flex rounded-2xl justify-between items-center px-3.5 py-3 font-lato-medium text-veryTiny text-black w-full"
              }
              placeholder={"Notes"}
            />
          </div>
          {showTimePicker ? (
            <div
              className={"px-3.5 py-4 shadow-card rounded-2xl bg-white"}
              style={{ height: "fit-content" }}
            >
              <div className={"font-lato-bold text-tiny text-black"}>
                Propose a New Time
              </div>
              <div className={"flex items-center mt-2"}>
                <UIComponents.DatePickerInput
                  size={"large"}
                  className={"mr-4"}
                />
                <UIComponents.TimePickerInput size={"large"} />
              </div>
              <div className={"flex justify-end mt-7"}>
                <UIComponents.Button
                  label={"Cancel"}
                  type={"btn-style-1"}
                  onClick={onCloseProposeTime}
                  size={"btn-small"}
                  className={"mr-4"}
                />
                <UIComponents.Button
                  label={"Submit"}
                  type={"btn-style-1"}
                  size={"btn-small"}
                  className={""}
                />
              </div>
            </div>
          ) : null}
          {showAccountsPicker ? (
            <div
              className={"px-2.5 py-3.5 shadow-card rounded-2xl bg-white"}
              style={{ height: "fit-content" }}
            >
              {accounts.map((account, index) => (
                <div className={"mb-3"}>
                  <div
                    className={
                      "font-lato-extrabold text-veryTiny text-grey-2 mb-1.5"
                    }
                  >
                    {account.email}
                  </div>
                  {account.workspaces.map(
                    (workspace: string, workspaceIndex: number) => (
                      <div className={"flex items-center p-px"}>
                        <UIComponents.Checkbox
                          type={"small"}
                          colorType={"orange"}
                          checked
                          className={"mr-3"}
                        />
                        <div
                          className={"text-veryTiny text-black font-lato-bold"}
                        >
                          {workspace}
                        </div>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      }
    >
      <div
        onClick={onEventClick}
        className={`font-lato-black text-tiny text-black flex items-center pl-2 mb-2.5 event-stripe border border-white hover:bg-blue-1 hover:border-blue-4 px-1 cursor-pointer rounded-[5px] ${
          event.allDay ? "bg-blue-1 mt-1" : ""
        }`}
      >
        {!event.allDay ? (
          <div className={"h-2.5 w-2.5 rounded-full bg-blue-4 mr-1.5"} />
        ) : null}

        {title}
      </div>
    </UIComponents.Popover>
  );
};
export default EventPopover;
