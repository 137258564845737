import { Icon } from "@blueprintjs/core";
import React from "react";

interface Props {
  addChoice: () => void;
  options: string[];
  updateChoice: (value: string, index: number) => void;
  color: string;
  deleteOption: (index: number) => void;
}
const MultipleChoice = ({
  addChoice,
  options,
  updateChoice,
  color,
  deleteOption,
}: Props) => {
  return (
    <div className="multiple-choice-container">
      <div className="choices-container">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`single-choice-container active`}
              style={{ borderColor: color, backgroundColor: color + "0C" }}
            >
              <div
                className="key-bind apply-font"
                style={{ borderColor: color, color }}
              >
                {String.fromCharCode(index + 65)}
              </div>
              <input
                style={{ color }}
                className="choice-name apply-font"
                value={item}
                onChange={(e) => updateChoice(e.target.value, index)}
              />
              <Icon
                onClick={() => deleteOption(index)}
                icon="cross"
                className="delete-option-icon"
                color={color}
              />
            </div>
          );
        })}
      </div>
      <div
        className="add-choice apply-font"
        onClick={addChoice}
        style={{ color }}
      >
        Add Another choice
      </div>
    </div>
  );
};
export default MultipleChoice;
