import React, { useCallback } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { EditPriceRatio, EditUnitQuantity, EditUnitRatio, ViewPriceRatio, ViewUnitRatio } from "../VendorDetail/supplies";
import { locationInstruction, primaryGroupInstruction, secondaryGroupInstruction, minParInstruction, maxParInstruction, stockInstruction, shelfLifeInstruction } from "../tableCells";
import { UnitQuantityDTO } from "controllers/inventoryItemInfo";

export type ItemDetails = {
  uniqueId: string,
  itemName: string,
  primaryGroup: string | null,
  secondaryGroup: string | null,
  unitType: string, // enum?
  ingredientUnitName: string,
  productionUnitName: string,
  conversionRatio: number,
  isRaw: boolean,
  location: string | null,
  isKey: boolean,
  inStock: boolean,
  actualized: boolean,
  allergens: string[],
  maxPar: number,
  minPar: number,
  theoreticalStock: UnitQuantityDTO[],
  shelfLifeHours: number;
  shelfLifeUnits: "hours" | "days" | "weeks";
};
export type ItemInRecipeDetail = {
  uniqueId: string;
  isMenuItem: boolean;
  isModifier: boolean;
  itemName: string;
  quantity: number;
  unitName: string;
};
export type VendorItemDetail = {
  uniqueId: string;
  vendor: string | null;
  purchasePrice: number;
  saleUnit: string;
  splitUnit: string;
  splitUnitsPerSaleUnit: number;
  pakUnit: string;
  pakUnitsPerSplitUnit: number;
  plu: string;
  isDefault: boolean;
}

export const detailColumns: ColumnInstruction<ItemDetails>[] = [
  { type: "data", header: "Item Name", attribute: "itemName" },
  { type: "data", header: "Primary Group", attribute: "primaryGroup" },
  { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
  { type: "data", header: "Unit Type", attribute: "unitType" },
  { type: "data", header: "Ingredient Unit", attribute: "ingredientUnitName" },
  { type: "data", header: "Reporting Unit", attribute: "productionUnitName" },
  { type: "data", header: "Type", attribute: "isRaw" },
  { type: "data", header: "Location", attribute: "location" },
  { type: "data", header: "Key Item?", attribute: "isKey" },
  { type: "data", header: "Actualize Usage Values?", attribute: "actualized" },
  { type: "projection", header: "Shelf Life", attribute: "shelfLife" },
  { type: "data", header: "Minimum Par", attribute: "minPar" },
  { type: "projection", header: "Theoretical Stock", attribute: "stock" },
  { type: "data", header: "Maximum Par", attribute: "maxPar" },
];

export const recipeColumns: ColumnInstruction<ItemInRecipeDetail>[] = [
  { type: "data", header: "Item", attribute: "itemName" },
  { type: "data", header: "Quantity", attribute: "quantity" },
  { type: "data", header: "Unit", attribute: "unitName" },
];

export const vendorColumns: ColumnInstruction<VendorItemDetail>[] = [
  { type: "data", header: "Vendor", attribute: "vendor" },
  { type: "data", header: "Purchase Price", attribute: "purchasePrice" },
  { type: "data", header: "Split Unit", attribute: "splitUnit" },
  { type: "data", header: "Pak Unit", attribute: "pakUnit" },
  { type: "projection", header: "Cost", attribute: "cost" },
  // { type: "data", header: "PLU", attribute: "plu" },
  { type: "data", header: "Set Default?", attribute: "isDefault" },
];

const baseVendorInstructions: { [x: string]: RenderInstruction<VendorItemDetail> } = {};
baseVendorInstructions.isDefault = {
  type: "boolean",
  useCheckbox: true,
};

baseVendorInstructions.purchasePrice = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    return (
      <ViewPriceRatio
        price={fullObject.purchasePrice}
        unit={fullObject.saleUnit}
      />
    );
  },
  editComponent: ({ fullObject, onChange }) => {
    const onChangePrice = useCallback(
      (purchasePrice) =>
        onChange?.({
          ...fullObject,
          purchasePrice,
        }),
      [fullObject]
    );
    const onChangeUnit = useCallback(
      (saleUnit) =>
        onChange?.({
          ...fullObject,
          saleUnit,
        }),
      [fullObject]
    );
    return (
      <EditPriceRatio
        price={fullObject.purchasePrice}
        unit={fullObject.saleUnit}
        onChangePrice={onChangePrice}
        onChangeUnit={onChangeUnit}
      />
    );
  },
}

baseVendorInstructions.splitUnit = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    return (
      <ViewUnitRatio
        unitOne={fullObject.splitUnit}
        count={fullObject.splitUnitsPerSaleUnit}
        unitTwo={fullObject.saleUnit}
      />
    );
  },
  editComponent: ({ fullObject, onChange }) => {
    const onChangeCount = useCallback(
      (splitUnitsPerSaleUnit) =>
        onChange?.({
          ...fullObject,
          splitUnitsPerSaleUnit,
        }),
      [fullObject]
    );
    const onChangeUnit = useCallback(
      (splitUnit) =>
        onChange?.({
          ...fullObject,
          splitUnit,
        }),
      [fullObject]
    );
    return (
      <EditUnitRatio
        unitOne={fullObject.splitUnit}
        count={fullObject.splitUnitsPerSaleUnit}
        unitTwo={fullObject.saleUnit}
        onChangeCount={onChangeCount}
        onChangeUnit={onChangeUnit}
      />
    );
  },
}

baseVendorInstructions.pakUnit = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    return (
      <ViewUnitRatio
        unitOne={fullObject.pakUnit}
        count={fullObject.pakUnitsPerSplitUnit}
        unitTwo={fullObject.splitUnit}
      />
    );
  },
  editComponent: ({ fullObject, onChange }) => {
    const onChangeCount = useCallback(
      (pakUnitsPerSplitUnit) =>
        onChange?.({
          ...fullObject,
          pakUnitsPerSplitUnit,
        }),
      [fullObject]
    );
    return (
      <EditUnitQuantity
        unitOne={fullObject.pakUnit}
        count={fullObject.pakUnitsPerSplitUnit}
        unitTwo={fullObject.splitUnit}
        onChangeCount={onChangeCount}
      />
    );
  },
}

baseVendorInstructions.cost = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    const {
      pakUnit,
      purchasePrice,
      pakUnitsPerSplitUnit,
      splitUnitsPerSaleUnit,
    } = fullObject;

    const pricePerPak = purchasePrice / (100 * pakUnitsPerSplitUnit * splitUnitsPerSaleUnit);

    const text = `${pricePerPak.toLocaleString("en-US", { style: "currency", currency: "USD" })
      } / ${pakUnit
      }`;

    return (
      <div>{text}</div>
    );
  },
  editComponent: null,
  readOnly: true,
}

export const recipeInstructions: { [x: string]: RenderInstruction<ItemInRecipeDetail> } = {
  quantity: {
    type: "number"
  }
};

export const detailInstructions: { [x: string]: RenderInstruction<ItemDetails> } = {};
detailInstructions.stock = stockInstruction;
detailInstructions.isRaw = {
  type: "boolean",
  useCheckbox: false,
  trueText: "Basic",
  falseText: "Prep",
  readOnly: true,
};
detailInstructions.actualized = {
  type: "boolean",
  useCheckbox: false,
  trueText: "Yes",
  falseText: "No",
};
detailInstructions.isKey = {
  type: "boolean",
  useCheckbox: false,
  trueText: "Yes",
  falseText: "No",
};
detailInstructions.conversionRatio = {
  type: "number",
};
detailInstructions.ingredientUnitName = {
  type: "default",
  readOnly: true,
};
detailInstructions.minPar = minParInstruction
detailInstructions.maxPar = maxParInstruction;

detailInstructions.secondaryGroup = secondaryGroupInstruction;
detailInstructions.primaryGroup = primaryGroupInstruction;
detailInstructions.location = locationInstruction;
detailInstructions.shelfLife = shelfLifeInstruction;


export const vendorInstructionsFactory = (vendors: { id: string; name: string }[]) => {
  const instructions = { ...baseVendorInstructions };
  instructions.vendor = {
    type: "select",
    options: vendors.map((v) => ({ label: v.name, value: v.id })),
    placeholder: "Select a vendor...",
    readOnly: true,
    editablePrefix: "new__",
  }
  return instructions;
};

