import firebase from "config/firebase";
import React from "react";
import { Navigate } from "react-router-dom";

import Spinner from "components/Spinner";

type PrivateRouteWrapper = {
  children: any;
  business: TangoBusiness | null;
  path: string;
};

const PrivateRouteWrapper = ({
  children,
  business,
  path,
}: PrivateRouteWrapper) => {
  if (!firebase.auth().currentUser) {
    return <Navigate to="/signIn" />;
  }
  if (path === "/onboarding" && !business?.pendingOnBoarding) {
    return <Navigate to="/employees" />;
  }
  if (business?.pendingOnBoarding && path !== "/onboarding") {
    return <Navigate to="/onboarding" />;
  }
  return <React.Suspense fallback={<Spinner />}>{children}</React.Suspense>;
};

export default PrivateRouteWrapper;
