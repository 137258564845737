import { EmulatorModeAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = false;

export default function emulatorMode(
  state = initialState,
  action: EmulatorModeAction
) {
  switch (action.type) {
    case ActionType.EMULATOR_MODE:
      return action.emulatorMode;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
