import { AvailabilityAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function business(
  state = initialState,
  action: AvailabilityAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_AVAILABILITIES:
      return action.availabilities;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
