import { Collapse, Icon } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  composeUsableTabAndOrder,
  fetchOlderTabAndOrders,
} from "models/reporting";

import { TabAndOrderForRust } from "types/order";

import { getOrdersAndTabsForReporting } from "model/selectors/reporting";
import { RootState } from "model/store";

import Layout from "../../Layout";
import "../style.css";

interface ProductMixModifiers {
  modifierName: string;
  numbersSold: number;
  grossSales: number;
  netSales: number;
}

interface ProductMixResult {
  [T: string]: {
    productName: string;
    numbersSold: number;
    grossSales: number;
    discounts: number;
    netSales: number;
    modifiers: ProductMixModifiers[];
  }[];
}

interface DiffSummary {
  [T: string]: {
    currentSales: number;
    prevSales: number;
    diff: number;
  };
}

const ProductAnalysis = () => {
  let reporting: any | null = null;

  const { orders, tabs } = useSelector(getOrdersAndTabsForReporting);

  const products: FirebaseProductDoc[] = useSelector(
    (state: RootState) => state.products
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [allOrders, setAllOrders] = useState<TabAndOrderForRust[]>([]);

  const firstPickerRef = useRef<DayPickerInput>(null);
  const secondPickerRef = useRef<DayPickerInput>(null);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [range, setRange] = useState<DateRange>([
    moment().hour() <= 4
      ? moment().subtract(8, "day").startOf("day").toDate()
      : moment().subtract(7, "day").startOf("day").toDate(),
    moment().hour() <= 4
      ? moment().subtract(1, "day").startOf("day").toDate()
      : moment().startOf("day").toDate(),
  ]);
  const [secondRange, setSecondRange] = useState<DateRange>([
    moment().hour() <= 4
      ? moment().subtract(22, "day").startOf("day").toDate()
      : moment().subtract(21, "day").startOf("day").toDate(),
    moment().hour() <= 4
      ? moment().subtract(15, "day").startOf("day").toDate()
      : moment().subtract(14, "day").startOf("day").toDate(),
  ]);
  const [productMixData, setProductMixData] = useState<ProductMixResult | null>(
    null
  );
  const [pastProductMixData, setPastProductMixData] =
    useState<ProductMixResult | null>(null);
  const [productTypeSummary, setProductTypeSummary] = useState<DiffSummary>({});
  const [productSummary, setProductSummary] = useState<DiffSummary>({});
  const [modifierSummary, setModifierSummary] = useState<DiffSummary>({});

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: business ? business.currency || "USD" : "USD",
  });

  const addOrdersForReporting = async (
    ordersForReporting: TabAndOrderForRust[]
  ) => {
    const { Reporting: RustReporting } = await import("../../../../rustUtils");
    reporting = RustReporting.new(ordersForReporting);
  };

  const transformDataForProductMix = (productMixData: any[]) => {
    const result: ProductMixResult = {};
    for (let i = 0; i < productMixData.length; i += 2) {
      const productType = productMixData[i];
      const productData = productMixData[i + 1];

      const products = [];

      for (let j = 0; j < productData.length; j += 6) {
        const productName = productData[j];
        const productSold = productData[j + 1];
        const productGrossSales = productData[j + 2];
        const productDiscounts = productData[j + 3];
        const productNetSales = productData[j + 4];
        const modifierData = productData[j + 5];

        const modifiers = [];

        for (let k = 0; k < modifierData.length; k++) {
          const modifierName = modifierData[k][0];

          if (modifierName) {
            const modifierSold = modifierData[k][1];
            const modifierGrossSales = modifierData[k][2];
            const modifierNetSales = modifierData[k][3];

            modifiers.push({
              modifierName: modifierName,
              numbersSold: modifierSold,
              grossSales: modifierGrossSales,
              netSales: modifierNetSales,
            });
          }
        }

        products.push({
          productName: productName,
          numbersSold: productSold,
          grossSales: productGrossSales,
          // TODO: This isn't right!
          // DiscountTotal is calculated without the
          // offset for modifier total
          discounts: productDiscounts,
          netSales: productNetSales,
          modifiers: modifiers,
        });
      }

      result[productType] = products;
    }

    return result;
  };

  const getProductMix = () => {
    const pastProductMix = reporting.summarize_product_analysis(
      secondRange[0],
      secondRange[1],
      new Date().getTimezoneOffset() / 100
    );
    const pastProductMixTransformed =
      transformDataForProductMix(pastProductMix);

    const productMix = reporting.summarize_product_analysis(
      range[0],
      range[1],
      new Date().getTimezoneOffset() / 100
    );
    const productMixTransformed = transformDataForProductMix(productMix);

    // Diff by product type
    setProductTypeSummary(
      Object.keys(productMixTransformed).reduce((acc, productType) => {
        const current = productMixTransformed[productType].reduce(
          (total, val) =>
            total + val.netSales + +findModifierNetSales(val.modifiers),
          0
        );
        const past = pastProductMixTransformed[productType]
          ? pastProductMixTransformed[productType].reduce(
              (total, val) =>
                total + val.netSales + +findModifierNetSales(val.modifiers),
              0
            )
          : 0;

        const diff = past !== 0 ? (current - past) / past : 1;

        return {
          ...acc,
          [productType]: { currentSales: current, prevSales: past, diff: diff },
        };
      }, {})
    );

    const currentProductsFlattened = _.flatten(
      Object.values(productMixTransformed)
    );
    const pastProductsFlattened = _.flatten(
      Object.values(pastProductMixTransformed)
    );

    // {pastProductMixData ? pastProductMixData[productType] ? (modifier.netSales - (pastProductMixData[productType].reduce((_, product) => product.modifiers.reduce((_, modifier) => modifier.modifierName === modifier.modifierName ? modifier.netSales : 0, 0), 0))) : null : null}

    setModifierSummary(
      currentProductsFlattened.reduce((acc, product) => {
        // Find the index to match based on the product name and it's modifiers
        const index = pastProductsFlattened.findIndex(
          (pastProduct) =>
            pastProduct.productName === product.productName &&
            _.isEqual(
              _.flatten(
                pastProduct.modifiers.map((modifier) =>
                  modifier.modifierName.split("/").filter((i) => !!i)
                )
              ).sort(),
              _.flatten(
                product.modifiers.map((modifier) =>
                  modifier.modifierName.split("/").filter((i) => !!i)
                )
              ).sort()
            )
        );

        if (index !== -1) {
          const modifierSummary = product.modifiers.reduce((agg, modifier) => {
            const current = modifier.netSales;
            const currentModifiers = modifier.modifierName
              .split("/")
              .filter((i) => !!i)
              .sort();
            const modifierIndex = pastProductsFlattened[
              index
            ].modifiers.findIndex((pastModifier) =>
              _.isEqual(
                pastModifier.modifierName
                  .split("/")
                  .filter((i) => !!i)
                  .sort(),
                currentModifiers
              )
            );

            if (modifierIndex !== -1) {
              const past =
                pastProductsFlattened[index].modifiers[modifierIndex].netSales;
              const diff = past !== 0 ? (current - past) / past : 1;

              return {
                ...agg,
                [product.productName + "-" + modifier.modifierName]: {
                  current: current,
                  past: past,
                  diff: diff,
                },
              };
            } else {
              return {
                ...agg,
                [product.productName + "-" + modifier.modifierName]: {
                  current: current,
                  past: 0,
                  diff: 1,
                },
              };
            }
          }, {});

          return { ...acc, ...modifierSummary };
        } else {
          const modifierSummary = product.modifiers.reduce((agg, modifier) => {
            const current = modifier.netSales;
            return {
              ...agg,
              [product.productName + "-" + modifier.modifierName]: {
                current: current,
                past: 0,
                diff: 1,
              },
            };
          }, {});

          return { ...acc, ...modifierSummary };
        }
      }, {})
    );

    setProductSummary(
      currentProductsFlattened.reduce((acc, product) => {
        // Find the index to match based on the product name and it's modifiers
        const index = pastProductsFlattened.findIndex(
          (pastProduct) =>
            pastProduct.productName === product.productName &&
            _.isEqual(
              _.flatten(
                pastProduct.modifiers.map((modifier) =>
                  modifier.modifierName.split("/").filter((i) => !!i)
                )
              ).sort(),
              _.flatten(
                product.modifiers.map((modifier) =>
                  modifier.modifierName.split("/").filter((i) => !!i)
                )
              ).sort()
            )
        );
        const current =
          product.netSales + findModifierNetSales(product.modifiers);

        if (index !== -1) {
          const past =
            pastProductsFlattened[index].netSales +
            findModifierNetSales(pastProductsFlattened[index].modifiers);
          const diff = past !== 0 ? (current - past) / past : 1;

          return {
            ...acc,
            [product.productName]: { current: current, past: past, diff: diff },
          };
        } else {
          return {
            ...acc,
            [product.productName]: { current: current, past: 0, diff: 1 },
          };
        }
      }, {})
    );

    setPastProductMixData(pastProductMixTransformed);
    setProductMixData(productMixTransformed);
  };

  const updateProductMix = async (ordersForReporting: TabAndOrderForRust[]) => {
    await addOrdersForReporting(ordersForReporting);
    getProductMix();
  };

  const findModifierNetSales = (modifiers: ProductMixModifiers[]) => {
    return modifiers.reduce((acc, val) => acc + val.netSales, 0);
  };

  // Updating the product mix
  useEffect(() => {
    const updateData = async () => {
      const productsSubTypeRef = products.reduce(
        (acc, val) => ({ ...acc, [val.id]: val.subType }),
        {}
      );
      const lowestCurrentDate = moment
        .min((allOrders || orders).map((order) => moment(order.createdAt)))
        .toDate();

      // Requires older data to be fetched
      if (range[0] && secondRange[0]) {
        if (
          range[0] < lowestCurrentDate ||
          secondRange[0] < lowestCurrentDate
        ) {
          const lowestDate = moment
            .min([moment(range[0]), moment(secondRange[0])])
            .toDate();

          if (business) {
            const updatedTabAndOrders = await fetchOlderTabAndOrders(
              lowestDate,
              lowestCurrentDate,
              products,
              allOrders,
              business.id
            );

            setAllOrders(updatedTabAndOrders || []);
            updateProductMix(updatedTabAndOrders || []);
          }
        }

        // Existing data could be updated
      } else {
        const ordersForReporting = composeUsableTabAndOrder(
          orders,
          tabs,
          productsSubTypeRef
        );
        setAllOrders(ordersForReporting);
        updateProductMix(ordersForReporting);
      }
    };

    if (orders && tabs && products) {
      updateData();
    }
  }, [orders, tabs, products, range, secondRange]);

  return (
    <Layout>
      <div id="product-analytics">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          <Link to="/reporting">
            <div className="button">Sales Summary</div>
          </Link>
          <div className="button active">Product Analytics</div>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>
          {/*
          <Link to="/cash-summary">
            <div className="button">Cash Summary</div>
          </Link>
          <Link to="/labor-payroll">
            <div  className="button">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section">
          <div className="body">
            {/* header : starts */}
            <div className="page-header">
              <div className="page-title">Product Analytics</div>
              <div className="date-pickers">
                <div style={{ position: "relative" }}>
                  <DateRangeInput
                    allowSingleDayRange
                    formatDate={(date) => moment(date).format("MMM Do")}
                    parseDate={(str) => moment(str, "MMM Do").toDate()}
                    placeholder={`${"MMM DO"} (moment)`}
                    singleMonthOnly
                    shortcuts={false}
                    value={range}
                    onChange={(dateRange: DateRange) => setRange(dateRange)}
                    className="date-range"
                  />
                  <Icon icon="chevron-down" color="#fff" />
                </div>
                <div className="compared-to">compared to</div>
                <div style={{ position: "relative" }}>
                  <DateRangeInput
                    allowSingleDayRange
                    formatDate={(date) => moment(date).format("MMM Do")}
                    parseDate={(str) => moment(str, "MMM Do").toDate()}
                    placeholder={`${"MMM DO"} (moment)`}
                    singleMonthOnly
                    shortcuts={false}
                    value={secondRange}
                    onChange={(dateRange: DateRange) =>
                      setSecondRange(dateRange)
                    }
                    className="date-range"
                  />
                  <Icon icon="chevron-down" color="#fff" />
                </div>
              </div>
            </div>
            {/* header ends */}
            {/* table starts */}
            <div className="product-analytics-table">
              <div className="product-row header-row">
                <div className="product-category column">PRODUCT</div>
                <div className="number-sold column">NUMBER SOLD</div>
                <div className="gross-sales column">GROSS SALES</div>
                <div className="discounts column">DISCOUNTS</div>

                <div className="net-sales column">NET SALES</div>
                <div className="avg-price column">AVG PRICE</div>
              </div>
              {productMixData &&
                Object.keys(productMixData).map((productType) => (
                  <div className="row-container">
                    <div
                      className={`${
                        selectedRow === productType ? `active ` : ""
                      }product-row level-0`}
                      onClick={() =>
                        setSelectedRow(
                          selectedRow === productType ? null : productType
                        )
                      }
                    >
                      <div className="product-category column bold-font">
                        <Icon
                          icon={
                            selectedRow === productType
                              ? "chevron-down"
                              : "chevron-right"
                          }
                        />
                        {_.startCase(_.toLower(productType))}
                      </div>
                      <div className="column">
                        {productMixData[productType].reduce(
                          (acc, val) => acc + val.numbersSold,
                          0
                        )}
                      </div>
                      <div className="column">
                        {formatter.format(
                          productMixData[productType].reduce(
                            (acc, val) =>
                              acc +
                              val.grossSales +
                              +findModifierNetSales(val.modifiers),
                            0
                          )
                        )}
                      </div>
                      <div className="column">
                        {formatter.format(
                          productMixData[productType].reduce(
                            (acc, val) => acc + val.discounts,
                            0
                          )
                        )}
                      </div>
                      <div className="column bold-font">
                        <span className="net-sales-value ">
                          {formatter.format(
                            productMixData[productType].reduce(
                              (acc, val) =>
                                acc +
                                val.netSales +
                                +findModifierNetSales(val.modifiers),
                              0
                            )
                          )}
                        </span>
                        {productTypeSummary[productType] ? (
                          productTypeSummary[productType].diff > 0 ? (
                            <span className="percentage-gain green">
                              +
                              {(
                                productTypeSummary[productType].diff * 100
                              ).toFixed(2)}
                              %
                            </span>
                          ) : (
                            <span className="percentage-gain red">
                              {(
                                productTypeSummary[productType].diff * 100
                              ).toFixed(2)}
                              %
                            </span>
                          )
                        ) : (
                          <span className="percentage-gain green">
                            +100.00%
                          </span>
                        )}
                      </div>
                      <div className="column">
                        {formatter.format(
                          productMixData[productType].reduce(
                            (acc, val) =>
                              acc +
                              val.netSales +
                              +findModifierNetSales(val.modifiers),
                            0
                          ) /
                            productMixData[productType].reduce(
                              (acc, val) => acc + val.numbersSold,
                              0
                            )
                        )}
                      </div>
                    </div>
                    <Collapse isOpen={selectedRow === productType}>
                      {productMixData[productType].map((product) => {
                        return (
                          <>
                            <div className="sub-category-row level-1">
                              <div className="product-row">
                                <div className="product-category column">
                                  {product.productName}
                                </div>
                                <div className="column">
                                  {product.numbersSold}
                                </div>
                                <div className="column">
                                  {formatter.format(
                                    product.grossSales +
                                      findModifierNetSales(product.modifiers)
                                  )}
                                </div>
                                <div className="column">
                                  {formatter.format(product.discounts)}
                                </div>
                                <div className="column">
                                  <span className="net-sales-value ">
                                    {formatter.format(
                                      product.netSales +
                                        findModifierNetSales(product.modifiers)
                                    )}
                                  </span>
                                  {productSummary[product.productName] ? (
                                    productSummary[product.productName].diff >
                                    0 ? (
                                      <span className="percentage-gain green">
                                        +
                                        {(
                                          productSummary[product.productName]
                                            .diff * 100
                                        ).toFixed(2)}
                                        %
                                      </span>
                                    ) : (
                                      <span className="percentage-gain red">
                                        {(
                                          productSummary[product.productName]
                                            .diff * 100
                                        ).toFixed(2)}
                                        %
                                      </span>
                                    )
                                  ) : (
                                    <span className="percentage-gain green">
                                      +100.00%
                                    </span>
                                  )}
                                </div>
                                <div className="column">
                                  {formatter.format(
                                    (product.netSales +
                                      findModifierNetSales(product.modifiers)) /
                                      product.numbersSold
                                  )}
                                </div>
                              </div>
                            </div>
                            {product.modifiers.length > 0
                              ? product.modifiers.map((modifier) => {
                                  return (
                                    <div className="sub-category-row level-2">
                                      <div className="product-row">
                                        <div className="product-category column">
                                          {modifier.modifierName
                                            .split("/")
                                            .filter((i) => !!i)
                                            .map((modifierName) => {
                                              return <div>{modifierName}</div>;
                                            })}
                                        </div>
                                        <div className="column">
                                          {modifier.numbersSold}
                                        </div>
                                        <div className="column">
                                          {formatter.format(
                                            modifier.grossSales
                                          )}
                                        </div>
                                        <div className="column">$0.00</div>
                                        <div className="column">
                                          <span className="net-sales-value ">
                                            {formatter.format(
                                              modifier.netSales
                                            )}
                                          </span>
                                          {modifierSummary[
                                            product.productName +
                                              "-" +
                                              modifier.modifierName
                                          ] ? (
                                            modifierSummary[
                                              product.productName +
                                                "-" +
                                                modifier.modifierName
                                            ].diff > 0 ? (
                                              <span className="percentage-gain green">
                                                +
                                                {(
                                                  modifierSummary[
                                                    product.productName +
                                                      "-" +
                                                      modifier.modifierName
                                                  ].diff * 100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            ) : (
                                              <span className="percentage-gain red">
                                                {(
                                                  modifierSummary[
                                                    product.productName +
                                                      "-" +
                                                      modifier.modifierName
                                                  ].diff * 100
                                                ).toFixed(2)}
                                                %
                                              </span>
                                            )
                                          ) : null}
                                        </div>
                                        <div className="column">
                                          {formatter.format(
                                            modifier.netSales /
                                              modifier.numbersSold
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </>
                        );
                      })}
                    </Collapse>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProductAnalysis;
