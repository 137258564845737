import { Icon, Popover } from "@blueprintjs/core";
import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useNavigate } from "react-router-dom";

import {
  DraftIdWithDepartmentId,
  ScheduleEventType,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { AvailabilityMoreAction } from "../Actions/AvailabilityMoreAction";
import { DayPickerDay } from "./DayPickerDay";
import { ScheduleStatus } from "./ShiftViewTypeSidebarContent";
import { SideBarContent } from "./SidebarContent";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";

const birthdayStyle = `.DayPicker-Day--highlighted {
  background-color: orange;
  border-radius: 50px;
  color: white;
}`;

const NavBar = ({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: any) => {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  return (
    <div className={className}>
      <div onClick={() => onPreviousClick()} className="calendar-nav-button">
        <Icon icon="chevron-left" iconSize={8} />
      </div>
      <div onClick={() => onNextClick()} className="calendar-nav-button">
        <Icon icon="chevron-right" iconSize={8} />
      </div>
    </div>
  );
};

const titleByRequestType = (requestType: ScheduleEventType) => {
  if (requestType === ScheduleEventType.drop) {
    return "Dropped Shifts";
  }
  if (requestType === ScheduleEventType.time_off) {
    return "Time Off Requests";
  }
  if (requestType === ScheduleEventType.trade) {
    return "Trades & Covers";
  }
};

interface SideBarNavProps {
  selectedRequestType?: ScheduleEventType;
  isAvailabilityView: boolean;
}

const SideBarNav = ({
  selectedRequestType,
  isAvailabilityView = false,
}: SideBarNavProps) => {
  const navigate = useNavigate();

  if (selectedRequestType) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item current-menu-item"
      >
        <Icon
          onClick={() => {
            navigate(-1);
          }}
          icon="chevron-left"
          iconSize={14}
        />
        <div>{titleByRequestType(selectedRequestType)}</div>
        <Icon icon="more" iconSize={18} />
      </Box>
    );
  }

  if (isAvailabilityView) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item current-menu-item"
      >
        <Icon
          onClick={() => {
            navigate(-1);
          }}
          icon="chevron-left"
          iconSize={14}
        />
        <div>Availabilities</div>
        <Popover
          className="more-action-popover"
          modifiers={{ arrow: { enabled: false } }}
          position="bottom-right"
          content={<AvailabilityMoreAction />}
          target={<Icon icon="more" iconSize={18} />}
        />
        <Box />
      </Box>
    );
  }

  return null;
};

const getDayPickerClassName = (selectedRequestType?: ScheduleEventType) => {
  if (selectedRequestType) {
    return "Selectable";
  }
  return "Selectable";
};

interface WeeklySidebarProps {
  weekRange: Date[];
  selectedRequestType?: ScheduleEventType;
  isSelectRequestType: boolean | undefined;
  isAvailabilityView: boolean;
  onCalendarDateClick: (d: Date) => void;
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];

  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;

  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;

  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}

export const WeeklySidebar = ({
  weekRange,
  selectedRequestType,
  isSelectRequestType = false,
  isAvailabilityView = false,
  onCalendarDateClick,
  business,
  schedulesWithLocalSchedule,
  fixedSchedules,
  schedules,
  scheduleEvents,
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,
  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  scheduleStatus,
  publishScheduleLoading,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,
  draftsWithDepartmentForAWeek,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
}: WeeklySidebarProps) => {
  return (
    <>
      <SideBarNav
        selectedRequestType={selectedRequestType}
        isAvailabilityView={isAvailabilityView}
      />
      <style>{birthdayStyle}</style>
      {isAvailabilityView ? null : (
        <DayPicker
          month={weekRange.length ? weekRange[0] : new Date()}
          className={getDayPickerClassName(selectedRequestType)}
          selectedDays={weekRange}
          renderDay={(day) => (
            <DayPickerDay
              business={business}
              day={day}
              requestType={selectedRequestType ?? null}
              schedulesWithLocalSchedule={schedulesWithLocalSchedule}
              fixedSchedules={fixedSchedules}
              schedules={schedules}
              scheduleEvents={scheduleEvents}
            />
          )}
          onDayClick={onCalendarDateClick}
          modifiers={{
            start: weekRange[0],
            end: weekRange[weekRange.length - 1],
          }}
          navbarElement={<NavBar />}
        />
      )}

      <SideBarContent
        selectedRequestType={selectedRequestType}
        isSelectRequestType={isSelectRequestType}
        isAvailabilityView={isAvailabilityView}
        scheduleEvents={scheduleEvents}
        setAvailabilityViewFilterAnchorEl={setAvailabilityViewFilterAnchorEl}
        availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
        availabilityRoleFilterId={availabilityRoleFilterId}
        businessSettings={businessSettings}
        jobFunctions={jobFunctions}
        onAvailabilityFullNameInputChangeDebounced={
          onAvailabilityFullNameInputChangeDebounced
        }
        setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
        weeklyScheduleViewType={weeklyScheduleViewType}
        setWeeklyScheduleViewType={setWeeklyScheduleViewType}
        departmentScheduleViewType={departmentScheduleViewType}
        setDepartmentScheduleViewType={setDepartmentScheduleViewType}
        scheduleStatus={scheduleStatus}
        publishScheduleLoading={publishScheduleLoading}
        scheduleForWeekRange={scheduleForWeekRange}
        duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
        weekRange={weekRange}
        schedulesWithLocalSchedule={schedulesWithLocalSchedule}
        setWeeklyStaffMemberViewFilterAnchorEl={
          setWeeklyStaffMemberViewFilterAnchorEl
        }
        weeklyStaffMemberViewFilterAnchorEl={
          weeklyStaffMemberViewFilterAnchorEl
        }
        onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
        roleFilterId={roleFilterId}
        setRoleFilterId={setRoleFilterId}
        draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
        bohDraftStatus={bohDraftStatus}
        fohDraftStatus={fohDraftStatus}
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        fohScheduleForAWeekRange={fohScheduleForAWeekRange}
        bohScheduleForAWeekRange={bohScheduleForAWeekRange}
      />
    </>
  );
};
