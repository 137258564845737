import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const groupedOptions = [
  {
    label: "People",
    options: [
      { value: "kishan", label: "Kishan" },
      { value: "spiderman", label: "spiderman" },
    ],
  },
  {
    label: "Business",
    options: [
      { value: "Tango", label: "Tango" },
      { value: "Coop", label: "Coop" },
    ],
  },
  {
    label: "Group",
    options: [
      { value: "group1", label: "Group1" },
      { value: "group2", label: "Group2" },
    ],
  },
];
const InvitePeopleModal = ({ isOpen, closeModal }: Props) => (
  <UIComponents.Modal isOpen={isOpen} closeModal={closeModal}>
    <div className={"font-lato-bold text-xl text-black"}>
      Who would you like to invite to this calendar?
    </div>
    <div className={"h-60 mt-10"}>
      <div className={"flex"}>
        <UIComponents.SearchBar
          //@ts-ignore
          options={groupedOptions}
          size={"medium"}
          filters={["People", "Groups"]}
          placeholder={"Search"}
          className={"w-full mr-4"}
        />
        <UIComponents.Button
          label={"Invite"}
          type={"btn-style-1"}
          size={"btn-small"}
        />
      </div>
    </div>
  </UIComponents.Modal>
);
export default InvitePeopleModal;
