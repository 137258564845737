import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Firebase from "../../config/firebase";
import { RootState } from "../../model/store";
import { fetchTypeForms } from "../../models/surveyBuilder";
import {
  EndingSectionProps,
  FormType,
  ThemeProp,
} from "../SurveyBuilder/types";

export interface Form {
  businessId: string;
  createdAt: Firebase.firestore.Timestamp;
  updatedAt: Firebase.firestore.Timestamp;
  deleted: boolean;
  enabled: boolean;
  endingSections: EndingSectionProps[];
  id: string;
  name: string;
  questions: any[];
  selectedEndingSection: EndingSectionProps | null;
  selectedTheme: ThemeProp;
  type: FormType;
  typeformDescription: string;
  typeformDisplayName: string;
  responseCount: number;
  status?: "draft" | "published";
}

export type FormItem = Pick<Form, 'name' | 'createdAt' | 'responseCount' | 'enabled' | 'type'> & {
  uniqueId: string
}
const useForms = () => {
  const [forms, setForms] = useState<FormItem[]>([]);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchTypeForms(business.id);
      if (response.data) {
        const formsWithId = response.data.map((item) => {
          return { name: item.name, uniqueId: item.id, createdAt: item.createdAt, responseCount: item.responseCount, enabled: item.enabled, type: item.type }
        })
        setForms(formsWithId);
      } else {
        console.log(response.error);
      }
    };
    if (business) {
      fetchData();
    }
  }, [business]);

  return {
    forms,
  };
};
export default useForms;
