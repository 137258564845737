/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-use-before-define */
import {
  Classes,
  Icon,
  Popover,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { Grid, Box as MaterialBox } from "@mui/material";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { importTimeOffRequests } from "controllers/scheduleEvents";

import Box from "components/Box";

import { selectMonthsForTimeOffRequestsPage } from "model/selectors/scheduleEvents";
import { RootState } from "model/store";

import RequestCard from "./RequestCard";
import "./style.css";

type TimeOffMonthYear = {
  month: string;
  year: number;
  firstDayReference: Date;
  timeOffNumber: number;
};

type Props = {
  onRequestPress: (requestId: string) => void;
};

const MoreAction = ({
  parseCSVLoading,
  parseFile,
}: {
  parseCSVLoading: boolean;
  parseFile: (f: File) => void;
}) => {
  const hiddenFileInput = useRef(null);

  return (
    <Box className="more-action">
      <Box className="action-header">
        <Icon icon="delete" className={Classes.POPOVER_DISMISS} />
        Actions
      </Box>
      <Box
        className="action-item"
        flexDirection="row"
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        //@ts-ignore
        onClick={() => hiddenFileInput?.current?.click()}
      >
        Import from CSV{" "}
        <Box style={{ marginRight: 30 }}>
          {parseCSVLoading ? <Spinner size={SpinnerSize.SMALL} /> : null}
        </Box>
      </Box>
      <input
        accept=".csv"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files?.length) {
            const file = files[0];
            parseFile(file);
          }
        }}
      />
    </Box>
  );
};

const TimeOffRequests = ({ onRequestPress }: Props) => {
  const months = moment.months();

  const navigate = useNavigate();
  const [activeMonth, setActiveMonth] = useState<string | null>(null);
  const { allMonths, gridMonths, pendingMonths, archivedMonths } = useSelector(
    selectMonthsForTimeOffRequestsPage
  );

  const monthRefs = useRef<{ [date: string]: any }>({});

  const sectionRefs = useRef<{ [date: string]: any }>({});
  const [parseCSVLoading, setParseCSVLoading] = useState(false);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const [page, setPage] = useState(1);
  const loader = useRef(null);

  const pastLoader = useRef(null);

  const parseFile = useCallback(
    async (file: File) => {
      try {
        setParseCSVLoading(true);
        await importTimeOffRequests(file, business, fellowStaffMembers);
        setParseCSVLoading(false);
      } catch (e) {
        console.log("e", e);
        setParseCSVLoading(false);
        alert("opps something went wrong");
      }
    },
    [business, fellowStaffMembers]
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current as unknown as Element);
    }
    const currentMonthYear = allMonths.find(
      (monthYear) =>
        monthYear.month === months[moment().month()] &&
        monthYear.year === moment().year()
    );
    setActiveMonth(moment(currentMonthYear?.firstDayReference).format());
    monthRefs.current?.[
      moment(currentMonthYear?.firstDayReference).format()
    ]?.scrollIntoView();
  }, []);

  // useEffect(() => {
  //   if (activeMonth) {
  //     sectionRefs.current?.[activeMonth]?.scrollIntoView({behavior: 'smooth'});
  //   }
  // }, [activeMonth]);

  // here we handle what happens when user scrolls to Load More div
  // in this case we just update page variable
  const handleObserver = (entities: any[]) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };

  const gridMonthArchiveExtractor = (gridMonth: {
    monthDetails: TimeOffMonthYear;
    timeOffRequests: ScheduleEvent[];
  }) => {
    const filteredTimeOffRequests: ScheduleEvent[] =
      gridMonth.timeOffRequests.filter((tor) => tor?.status !== "pending");
    return {
      ...gridMonth,
      timeOffRequests: filteredTimeOffRequests,
    };
  };

  const gridMonthPendingExtractor = (gridMonth: {
    monthDetails: TimeOffMonthYear;
    timeOffRequests: ScheduleEvent[];
  }) => {
    const filteredTimeOffRequests: ScheduleEvent[] =
      gridMonth.timeOffRequests.filter((tor) => tor?.status === "pending");
    return {
      ...gridMonth,
      timeOffRequests: filteredTimeOffRequests,
    };
  };

  const renderGrid = (isArchive = false) => {
    const filteredGridMonths = isArchive
      ? gridMonths
          .map(gridMonthArchiveExtractor)
          .filter((gm) => gm?.timeOffRequests?.length)
      : gridMonths
          .map(gridMonthPendingExtractor)
          .filter((gm) => gm?.timeOffRequests?.length);
    return filteredGridMonths.map((month) => {
      const name = moment(month.monthDetails.firstDayReference).format();

      return (
        <>
          <div
            ref={(element) => (sectionRefs.current[name] = element)}
            className="time-off-section-title"
          >
            {moment(month.monthDetails.firstDayReference).format("MMMM YYYY")}
          </div>
          <Grid container spacing={2} columns={16}>
            {month.timeOffRequests.map((r) => (
              <Grid key={r.id} item xs={4}>
                <RequestCard
                  request={r}
                  key={r?.id}
                  title={r?.senderStaff?.name || ""}
                  subtitle="subtitle"
                  type={r.eventType}
                  onClick={() => {
                    console.log("r", r);
                    onRequestPress(r.id);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      );
    });
  };

  const renderSidebar = (isArchive = false) => {
    const monthsToDisplay = isArchive ? archivedMonths : pendingMonths;
    return (
      <div className="time-off-sidebar">
        <div className="time-off-sidebar" id="cont">
          {/* <div className="loading" ref={pastLoader}>
                    <h2>Previous years</h2>
            </div> */}
          {isArchive ? null : (
            <Link
              to="archive"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <div className={`time-off-sidebar-item`}>
                <div
                  className="time-off-sidebar-body"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("assets/time_off_archive.svg").default}
                    style={{ marginRight: 10, marginBottom: 3 }}
                  />
                  Archive
                </div>
              </div>
            </Link>
          )}

          {monthsToDisplay.map((monthYear, index) => {
            const name = moment(monthYear.firstDayReference).format();
            return (
              <div
                onClick={() => {
                  sectionRefs.current?.[name]?.scrollIntoView({
                    behavior: "smooth",
                  });
                  setActiveMonth(name);
                }}
                className={`time-off-sidebar-item${
                  activeMonth === name ? "-active" : ""
                }`}
                ref={(element) => (monthRefs.current[name] = element)}
                key={name}
              >
                <div className="time-off-sidebar-header">
                  {moment(monthYear.firstDayReference).format("MMMM, YYYY")}
                </div>
                <div className="time-off-sidebar-body">
                  {isArchive
                    ? monthYear.archiveTimeOffNumber
                    : monthYear.pendingTimeOffNumber}{" "}
                  requests
                </div>
              </div>
            );
          })}
          {/* <div className="loading" ref={loader}>
                  <h2>Future years</h2>
              </div> */}
        </div>
      </div>
    );
  };

  const renderPendingTimeOffRequests = () => {
    return (
      <>
        <Box
          display="flex"
          flex={0.2}
          flexDirection="column"
          style={{ overflow: "scroll", height: "90vh" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            className="side-nav-item current-menu-item"
          >
            <Icon
              onClick={() => {
                navigate(-1);
              }}
              icon="chevron-left"
              iconSize={14}
            />
            <div className="time-off-sidebar-header-text">
              Time off requests
            </div>
            <Popover
              className="more-action-popover"
              modifiers={{ arrow: { enabled: false } }}
              position="bottom-right"
              content={
                <MoreAction
                  parseFile={parseFile}
                  parseCSVLoading={parseCSVLoading}
                />
              }
              target={<Icon icon="more" iconSize={18} />}
            />
          </Box>
          {renderSidebar()}
        </Box>
        <Box
          display="flex"
          flex={0.8}
          style={{ overflow: "scroll", height: "90vh" }}
        >
          <MaterialBox
            onScroll={(e) => console.log("scroll event", e)}
            sx={{ flexGrow: 1 }}
          >
            {renderGrid()}
          </MaterialBox>
        </Box>
      </>
    );
  };

  const renderArchivedTimeOffRequests = () => {
    return (
      <>
        <Box
          display="flex"
          flex={0.2}
          flexDirection="column"
          style={{ overflow: "scroll", height: "90vh" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-evenly"
            className="side-nav-item current-menu-item"
          >
            <Icon
              onClick={() => {
                navigate(-1);
              }}
              icon="chevron-left"
              iconSize={14}
            />
            <div className="time-off-sidebar-header-text">Archive View</div>
            <div />
          </Box>
          {renderSidebar(true)}
        </Box>
        <Box
          display="flex"
          flex={0.8}
          style={{ overflow: "scroll", height: "90vh" }}
        >
          <MaterialBox
            onScroll={(e) => console.log("scroll event", e)}
            sx={{ flexGrow: 1 }}
          >
            {renderGrid(true)}
          </MaterialBox>
        </Box>
      </>
    );
  };

  return (
    <>
      <Routes>
        <Route index element={renderPendingTimeOffRequests()} />
        <Route path="archive" element={renderArchivedTimeOffRequests()} />
        <Route
          path="*"
          element={<Navigate to="/manager/scheduling/time-off-requests" />}
        />
      </Routes>
    </>
  );
};

export default TimeOffRequests;
