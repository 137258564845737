import { EnterpriseFilesAndFoldersTypesAction } from "model/types";

import ActionType from "./actionTypes";

export function receiveEnterpriseDocs(
  enterpriseDocs: FilesAndFolders[]
): EnterpriseFilesAndFoldersTypesAction {
  return {
    type: ActionType.RECEIVE_ENTERPRISE_DOCS,
    enterpriseDocs,
  };
}
