import { ModifiersAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseModifierDoc[] = [];

export default function modifiers(
  state = initialState,
  action: ModifiersAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_MODIFIERS:
      return action.modifiers;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
