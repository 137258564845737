import _ from "lodash";

import { isEqual } from "utils/data";

import { FirebaseServiceAreaDoc } from "types/serviceArea";
import { FirebaseTableDoc, TableDoc } from "types/tables";

import firebase from "../config/firebase";

const db = firebase.firestore();
const tablesCollection = db.collection("Tables");

export const performSoftDelete = async (tables: TableDoc[]) => {
  for await (const table of tables) {
    try {
      if (table.id && table.id.length > 0) {
        await tablesCollection.doc(table.id).update({ deleted: true });
      }
    } catch (err) {
      console.log("Failed to update: ", table.id);
    }
  }
};

export const saveData = async (
  newData: TableDoc[],
  existingData: TableDoc[],
  serviceAreas: FirebaseServiceAreaDoc[],
  businessId: string
) => {
  const firebaseTables: FirebaseTableDoc[] = newData
    .map((table, index) => ({ ...table, index: index }))
    .filter((table) => {
      const tableToCheck = _.omit(table, "index");
      const foundItem = existingData.some((existingTable) =>
        isEqual(existingTable, tableToCheck)
      );

      // Remove service area documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((table) => {
      const serviceAreaIndex = serviceAreas.findIndex(
        (area) => area.serviceAreaName === table.serviceArea
      );
      return {
        businessId: businessId,
        minCapacity: parseInt(table.minCapacity),
        maxCapacity: parseInt(table.maxCapacity),
        createdAt: new Date(),
        deleted: false,
        description: null,
        enabled: table.enabled === "Yes",
        id: table.id,
        notes: null,
        qrCodeImageUrl: null,
        tableNumber: parseInt(table.tableNumber),
        updatedAt: new Date(),
        serviceArea:
          serviceAreaIndex !== -1 ? serviceAreas[serviceAreaIndex].id : null,
      };
    });

  for await (const table of firebaseTables) {
    // For existing tables - just find the differences to update
    if (table.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === table.id
      );
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[index]
        )
          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingData[index][key], newData[index][key]) &&
              Object.keys(table).includes(key)
          )
          // @ts-ignore
          .reduce((acc, key) => ({ ...acc, [key]: table[key] }), {});

        try {
          await tablesCollection.doc(table.id).update(dataToUpdate);
        } catch (err) {
          console.log("Failed to update: ", table.id);
        }
      }
    } else {
      const docRef = tablesCollection.doc();
      table["id"] = docRef.id;
      const finalDoc = _.omit(table, "index");

      try {
        await docRef.set(finalDoc, { merge: true });
      } catch (err) {
        console.log("Failed to update: ", table.id);
      }
    }
  }
};
