import { Button, Classes, Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import moment from "moment";
import React, { useCallback, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";

import {
  duplicateScheduleIndefinitely,
  duplicateScheduleUntilACertainDate,
} from "controllers/schedule";

import Box from "components/Box";

import { getWeekRangeBySelectedDateAndPayrollStartDate } from "utils/dateUtils";
import { getArrayOfDatesStartingFromADate, getWeekDays } from "utils/manager";

import { RootState } from "model/store";

type Props = {
  schedule: TangoSchedule;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
};

const NavBar = ({
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: any) => {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  return (
    <div className={className}>
      <div onClick={() => onPreviousClick()} className="calendar-nav-button">
        <Icon icon="chevron-left" iconSize={8} />
      </div>
      <div onClick={() => onNextClick()} className="calendar-nav-button">
        <Icon icon="chevron-right" iconSize={8} />
      </div>
    </div>
  );
};

const DuplicateScheduleAction = (props: Props) => {
  const { schedule } = props;
  const [weekRange, setWeekRange] = useState<Date[]>([]);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const [shiftsOnly, setShiftsOnly] = useState(false);
  const [withAssignments, setWithAssignments] = useState(false);
  const [indefinitely, setIndefinitely] = useState(false);
  const [hasEndingDate, setHasEndingDate] = useState(false);
  const [endingDate, setEndingDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  const fixedSchedules: TangoFixedSchedule[] = useSelector(
    (state: RootState) => state.fixedSchedules
  );
  const draftSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.draftSchedules
  );
  const publishedSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  console.log("weekRange", weekRange);

  // useEffect(() => {
  //   const selectedDate = moment(selectedDay);
  //   if (business) {
  //     const startOfWeekNumber = moment().day(business?.payrollStartOfTheWeek).get('day');
  //     selectedDate.set("day", startOfWeekNumber);
  //     const selectedDays = getWeekDays(selectedDate.toDate());
  //     setWeekRange(selectedDays);

  //     // if (duplicatedScheduleForAWeekRange && !scheduleForWeekRange) {
  //     //   setLocalSchedule({...duplicatedScheduleForAWeekRange});
  //     // }

  //   }
  // }, [selectedDay]);

  const clearState = () => {
    setShiftsOnly(false);
    setWithAssignments(false);
    setLoading(false);
    setHasEndingDate(false);
    setIndefinitely(false);
  };

  const duplicateScheduleIndefinitelyHandler = async () => {
    setLoading(true);
    await duplicateScheduleIndefinitely(
      schedule,
      withAssignments,
      fixedSchedules,
      business,
      draftSchedules
    );
    setLoading(false);
    clearState();
  };

  const duplicateScheduleUntilACertainDateHandler = useCallback(async () => {
    if (
      business &&
      props.fohScheduleForAWeekRange &&
      props.bohScheduleForAWeekRange
    ) {
      setLoading(true);
      await duplicateScheduleUntilACertainDate(
        business.id,
        props.fohScheduleForAWeekRange.startDate.toDate(),
        props.fohScheduleForAWeekRange.id,
        props.bohScheduleForAWeekRange.id,
        weekRange[weekRange.length - 1],
        withAssignments
      );
      setLoading(false);
      clearState();
    }
  }, [
    business,
    schedule,
    weekRange,
    withAssignments,
    props.fohScheduleForAWeekRange,
    props.bohScheduleForAWeekRange,
  ]);

  const onDayPress = (day: Date) => {
    if (business) {
      setSelectedDay(day);
      console.log("schedule.endDate.toDate()", schedule.endDate.toDate());
      const selectedScheduleEndDate = schedule.endDate.toDate();
      const newDateRange = getArrayOfDatesStartingFromADate(
        selectedScheduleEndDate,
        day
      );
      // const selectedRange = getWeekRangeBySelectedDateAndPayrollStartDate(day, business.payrollStartOfTheWeek);
      // console.log("selectedRange", selectedRange)
      const lastDate = moment(newDateRange[newDateRange.length - 1]);
      const startOfWeekNumber = moment()
        .day(business?.payrollStartOfTheWeek)
        .get("day");

      lastDate.set("day", startOfWeekNumber);
      const lastWeek = getWeekDays(lastDate.toDate());
      const newRange = [...newDateRange, ...lastWeek];

      setWeekRange(newRange);
    }
  };

  const renderStepBasedOnState = () => {
    if (!props.bohScheduleForAWeekRange || !props.fohScheduleForAWeekRange) {
      return null;
    }
    if (!shiftsOnly && !withAssignments) {
      return (
        <Box className="more-action">
          <Box className="action-header">
            <Icon
              icon="delete"
              className={Classes.POPOVER_DISMISS}
              onClick={clearState}
            />
            Duplicate Schedule
          </Box>
          <Box
            onClick={() => setShiftsOnly(true)}
            className="action-item"
            style={{ color: "#61C554" }}
          >
            Shifts Only
          </Box>
          <Box
            onClick={() => setWithAssignments(true)}
            className="action-item"
            style={{ color: "#61C554" }}
          >
            Shifts & Employee Assignments{" "}
          </Box>
        </Box>
      );
    }
    if (hasEndingDate) {
      return (
        <Box className="more-action" style={{ width: 400 }}>
          <Box
            className="action-header"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Icon
              icon="chevron-left"
              onClick={() => {
                setHasEndingDate(false);
              }}
            />
            Duplicate Schedule Until When?
            {loading ? <Spinner size={SpinnerSize.SMALL} /> : <div />}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <DayPicker
              month={schedule?.startDate?.toDate() || undefined}
              className="Selectable-Green"
              selectedDays={weekRange}
              onDayClick={onDayPress}
              navbarElement={<NavBar />}
            />
          </Box>
          <Box style={{ paddingLeft: "27px", paddingRight: "27px" }}>
            <Button
              disabled={!selectedDay}
              loading={loading}
              text="Duplicate Schedule"
              id="duplicate-schedule-button"
              onClick={duplicateScheduleUntilACertainDateHandler}
            />
          </Box>
        </Box>
      );
    }
    if (shiftsOnly || withAssignments) {
      return (
        <Box className="more-action" style={{ width: 400 }}>
          <Box
            className="action-header"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Icon
              icon="chevron-left"
              onClick={() => {
                setShiftsOnly(false);
                setWithAssignments(false);
              }}
            />
            Duplicate Schedule Until When?
            {loading ? <Spinner size={SpinnerSize.SMALL} /> : <div />}
          </Box>
          <Box
            onClick={duplicateScheduleIndefinitelyHandler}
            className="action-item"
            style={{ color: "#61C554" }}
          >
            Indefinitely
          </Box>
          <Box
            onClick={() => setHasEndingDate(true)}
            className="action-item"
            style={{ color: "#61C554" }}
          >
            Choose a specific date
          </Box>
        </Box>
      );
    }

    return null;
  };

  return renderStepBasedOnState();
};

export default DuplicateScheduleAction;
