import { JobFunctionsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function jobFunctions(
  state = initialState,
  action: JobFunctionsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_JOB_FUNCTIONS:
      return action.jobFunctions;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
