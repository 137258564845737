import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteFormResponses,
  fetchForm,
  updateFormField,
} from "../../models/surveyBuilder";
import { Form } from "../ViewForms/useForms";

const useFormPreview = () => {
  const [formData, setFormData] = useState<Form>();
  const [selectedPreview, setSelectedPreview] = useState<"desktop" | "mobile">(
    "desktop"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const [showDeleteFormConfirmationModal, setShowDeleteFormConfirmationModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await fetchForm(id);
        if (response.data) {
          setFormData(response.data as Form);
        }
      }
    };
    fetchData();
  }, []);

  const updateFormSettings = async (
    key: keyof Pick<Form, "enabled" | "deleted">,
    value: boolean
  ) => {
    setLoading(true);
    if (id && formData) {
      const response = await updateFormField(id, key, value);
      if (response.data && key === "enabled") {
        setFormData({ ...formData, enabled: value });
      }
      if (key === "deleted" && response.data) {
        const formDeleteResponse = await deleteFormResponses(id);
        if (formDeleteResponse.data) {
          navigate("/manager/forms", { replace: true });
        }
      }
    }
    setLoading(false);
  };
  return {
    formData,
    selectedPreview,
    setSelectedPreview,
    id,
    updateFormSettings,
    showDeleteFormConfirmationModal,
    setShowDeleteFormConfirmationModal,
    loading,
  };
};
export default useFormPreview;
