import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import usePaymentScreen from "./usePaymentScreen";
import { Route, Routes, useNavigate } from "react-router-dom";
import ClosedCheckDetail from "../ClosedCheckDetail";

const { TextField, Button, Icon } = UIComponents;

const PaymentsScreen = () => {
    const {
        data,
        noOp,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastTabId
    } = usePaymentScreen();

    const navigate = useNavigate();
    const [previousPageTabIds, setPreviousPageTabIds] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [chunkedPayments, setChunkedPayments] = useState<any>([])
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - ' + 20)

    useEffect(() => {
        if (chunkedPayments.length > 0 && pageNumber > 0) {
            const fullList = chunkedPayments.flat(1)
            const lastStartId = fullList[fullList.length - 1].uniqueId
            const startNumber = fullList.findIndex((payment: any) => payment.uniqueId === lastStartId);
            setDisplayCounterMessage(startNumber + 1 + ' - ' + (fullList.length + data.length))
        } else {
            setDisplayCounterMessage('1 - ' + data.length)
        }
    }, [chunkedPayments, data])

    const nav = useCallback((id: string) => {
        const item = data.filter((completedPayment: any) => {
            return completedPayment.uniqueId === id
        })
        if (item[0] && item[0].completedCheck) {
            navigate(`${item[0].completedCheck.id}`, { state: item[0] });
        }
    }, [navigate, data]);

    const returnTable = () => {
        return (
            <div className="absolute top-[63px] bottom-0 w-full overflow-hidden">
                <Header routes={headerRoutes} selectedRoute={"/payments"} selectedPage={{ label: "Customers", value: "/payments" }} />
                <div className="flex h-full">
                    <div
                        className={"border-r border-grey-1 border-t-0 border-b-0"}
                        style={{ width: "10%", borderRightStyle: "solid" }}
                    >
                        <Sidebar routes={sidebarRoutes} />
                    </div>

                    <div className={"flex px-10 py-6 overflow-y-auto flex-col"} style={{ width: "90%" }}>
                        <HorizontalTable
                            title="Payments"
                            columns={columns}
                            data={data}
                            instructions={instructions}
                            saveResults={noOp}
                            setEditing={setEditing}
                            isEditing={isEditing}
                            hideEdit
                            onRowClick={nav}
                            customHeaderRightContent={<div className="flex items-center">
                                <Button
                                    label="Export"
                                    size="btn-medium"
                                    type="btn-style-1"
                                    onClick={() => console.log('click payment')}
                                />
                                <TextField
                                    className="hori-table-search-input bg-blue-grey-2 border-0"
                                    value={''}
                                    onChange={() => console.log('yey')}
                                    sizeType="medium"
                                    placeholder="Search"
                                />
                            </div>}
                        />
                        {data.length > 0 &&
                            <div className="flex justify-between items-center">
                                <div className="mt-7 flex items-center justify-start">
                                    Viewing {displayCounterMessage}
                                </div>
                                <div className="mt-7 flex items-center justify-end">
                                    <Icon name="chevron-left" className=" cursor-pointer" size="20" onClick={() => {
                                        if (pageNumber > 0 && previousPageTabIds.length > 0 && chunkedPayments.length > 0) {
                                            setLastTabId(previousPageTabIds[pageNumber])
                                            setPreviousPageTabIds([
                                                ...previousPageTabIds.slice(0, previousPageTabIds.length - 1),
                                                ...previousPageTabIds.slice(previousPageTabIds.length)
                                            ]);
                                            setChunkedPayments([
                                                ...chunkedPayments.slice(0, chunkedPayments.length - 1),
                                                ...chunkedPayments.slice(chunkedPayments.length)
                                            ]);
                                            setPageNumber(pageNumber - 1)
                                        }
                                    }} />
                                    <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                        if (!data[data.length - 1].completedCheck) {
                                            return
                                        }

                                        setLastTabId(data[data.length - 1].completedCheck.id)
                                        setChunkedPayments([...chunkedPayments, data])
                                        setPageNumber(pageNumber + 1)
                                        setPreviousPageTabIds([...previousPageTabIds, data[0].completedCheck.id])
                                    }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        );
    }

    const returnClosedCheck = () => {
        return (
            <ClosedCheckDetail />
        )
    }

    return (
        <div className="bottom-0 w-full overflow-hidden">
            <div className="flex h-full">
                <Routes>
                    <Route index element={returnTable()} />
                    <Route
                        path=":id"
                        element={returnClosedCheck()}
                    />
                </Routes>
            </div>
        </div>
    )

};

export default PaymentsScreen;
