import { Icon } from "@blueprintjs/core";
//@ts-ignore
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getSummaryForDate } from "controllers/dailyLogs";

import Box from "components/Box";

import {
  selectCostScheduleData,
  selectScheduleDates,
} from "model/selectors/staffing";
import { RootState } from "model/store";

import ChevronDown from "assets/chevron-down.png";
import CostScheduleUpIcon from "assets/cost-schedule-up.svg";

import { CostScheduleCard } from "./costSchedule";
import "./style.css";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

interface CostScheduleChatModuleProps {
  onlyChat?: boolean;
}

export const CostScheduleChatModule = (props: CostScheduleChatModuleProps) => {
  const costScheduleData = useSelector(selectCostScheduleData);
  const selectedDepartment = costScheduleData?.selectedDepartment;
  const dates = useSelector(selectScheduleDates);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const dailyActuals = costScheduleData?.dailyActuals;
  const [targets, setTargets] = useState<number[]>(dates.map(() => 0));
  // const [actuals, setActuals] = useState<number[]>(dates.map(() => 0));
  const actuals = useMemo(() => {
    return (
      dailyActuals?.map((b) => {
        if (selectedDepartment == "boh") return b.boh;
        if (selectedDepartment == "foh") return b.foh;
        return b.amount;
      }) ?? []
    );
  }, [dailyActuals, selectedDepartment]);
  const [projectedSales, setProjectedSales] = useState<number[]>(
    dates.map(() => 0)
  );
  const [projectionsLoading, setProjectionsLoading] = useState(false);
  useEffect(() => {
    if (!business?.id) return;
    setProjectionsLoading(true);
    setTargets(dates.map(() => 0));
    // setActuals(dates.map(() => 0))
    setProjectedSales(dates.map(() => 0));
    Promise.all(
      dates.map((d) => getSummaryForDate(business.id, d.day, true))
    ).then((results) => {
      setProjectionsLoading(false);
      setTargets(
        results.map((r) => {
          if (selectedDepartment == "boh") {
            return r.targetBoh;
          } else if (selectedDepartment == "foh") {
            return r.targetFoh;
          } else {
            return r.targetAll;
          }
        })
      );
      // setActuals(results.map(r => {
      //   if (selectedDepartment == 'boh') {
      //     return r.bohCost
      //   } else if (selectedDepartment == 'foh') {
      //     return r.fohCost
      //   } else {
      //     return r.totalCost
      //   }
      // }));
      setProjectedSales(results.map((r) => r.projectedSales));
    });
  }, [dates, business?.id]);
  const targetTotal = targets.reduce((a, b) => a + (b ?? 0), 0);
  const actualTotal = actuals.reduce((a, b) => a + (b ?? 0), 0);
  const projectedTotal = projectedSales.reduce((a, b) => a + (b ?? 0), 0);

  const [costScheduleMode, setCostScheduleMode] = useState<
    "hidden" | "minified" | "expanded"
  >("hidden");

  const location = useLocation();

  const showCostSchedule = location.pathname.includes("scheduling");

  const projectedLaborCost = useMemo(() => {
    const expenseProjectedSalesRatio = targetTotal / projectedTotal;
    if (!_.isFinite(expenseProjectedSalesRatio)) {
      return "-";
    }
    if ((targetTotal / projectedTotal) * 100 > 10000) {
      return "-";
    }
    return `${((targetTotal / projectedTotal) * 100).toFixed(2)}%`;
  }, [targetTotal, projectedSales]);

  const actualScheduleCost = ((actualTotal ?? 0) / 100).toLocaleString(
    "en-US",
    {
      style: "currency",
      currency: "USD",
    }
  );
  const overBudgetAmount = actualTotal - targetTotal;
  const isOverBudget = overBudgetAmount > 0;
  const overBudgetString = (overBudgetAmount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  const overBudgetText = isOverBudget
    ? `You are currently ${overBudgetString} over budget.`
    : "You are currently not over budget.";

  const minifiedCostSchedule = () => {
    return (
      <motion.div
        className="minified-cs-container"
        animate={{
          visibility: costScheduleMode === "minified" ? "visible" : "hidden",
          opacity: costScheduleMode === "minified" ? 1 : 0,
          right: 90,
        }}
        transition={spring}
      >
        <Box
          onClick={() => {
            setCostScheduleMode("expanded");
          }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className="cost-schedule-title">Costed Schedule</div>
            <div
              className={
                isOverBudget
                  ? "cost-schedule-title-warning"
                  : "cost-schedule-subtitle"
              }
            >
              {overBudgetText}
            </div>
          </Box>
          <motion.img source={ChevronDown} />
          <motion.div animate={{ rotate: 180 }}>
            <img style={{ height: 10 }} src={ChevronDown} />
          </motion.div>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <CostScheduleCard
            disabled={projectionsLoading || !selectedDepartment}
            title={`Target ${selectedDepartment ?? ""} Cost`}
            value={targetTotal}
          />
          <CostScheduleCard
            showWarning={isOverBudget}
            title={`Actual ${selectedDepartment ?? ""} Cost`}
            value={actualTotal}
          />
        </Box>
      </motion.div>
    );
  };

  const expandedCostSchedule = () => {
    return (
      <motion.div
        className="expanded-cs-container"
        animate={{
          visibility: costScheduleMode === "expanded" ? "visible" : "hidden",
          opacity: costScheduleMode === "expanded" ? 1 : 0,
          right: 90,
        }}
        transition={spring}
      >
        <Box
          onClick={() => setCostScheduleMode("minified")}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className="cost-schedule-title">Costed Schedule</div>
            <div
              className={
                isOverBudget
                  ? "cost-schedule-title-warning"
                  : "cost-schedule-subtitle"
              }
            >
              {overBudgetText}
            </div>
          </Box>
          <motion.img source={ChevronDown} />
          <motion.div>
            <img style={{ height: 10 }} src={ChevronDown} />
          </motion.div>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <CostScheduleCard
            disabled={projectionsLoading || !selectedDepartment}
            title={`Target ${selectedDepartment ?? ""} Cost`}
            value={targetTotal}
          />
          <CostScheduleCard
            showWarning={isOverBudget}
            title={`Actual ${selectedDepartment ?? ""} Cost`}
            value={actualTotal}
            removeExtraMargin
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <div className="cost-schedule-target-label">Target</div>
          </Box>
          <Box
            display="flex"
            flex={1}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <div className="cost-schedule-actual-label">Actual</div>
          </Box>
        </Box>
        {dates.map((day, idx) => {
          const target = targets[idx];
          const actual = actuals[idx];
          const dayOverBudget = target < actual;
          return (
            <Box
              key={day.day}
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <CostScheduleCard
                disabled={projectionsLoading}
                title={day.dayName}
                value={target}
              />
              <CostScheduleCard
                disabled={projectionsLoading}
                title={day.dayName}
                value={actual}
                showWarning={dayOverBudget}
                removeExtraMargin
              />
            </Box>
          );
        })}
      </motion.div>
    );
  };

  return (
    <AnimatePresence>
      <motion.div className="module-container">
        {costScheduleData && showCostSchedule ? (
          <>
            {minifiedCostSchedule()}
            {expandedCostSchedule()}

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                if (costScheduleMode === "hidden") {
                  setCostScheduleMode("minified");
                } else {
                  setCostScheduleMode("hidden");
                }
              }}
              animate={{
                width: costScheduleMode === "hidden" ? 130 : 60,
                borderRadius: costScheduleMode === "hidden" ? 15 : 30,
                marginRight: 0,
              }}
              style={{
                backgroundColor: !isOverBudget ? "#08C88E" : "#ff7777",
              }}
              className="cost-widget-container"
            >
              {costScheduleMode === "hidden" ? (
                <>
                  <div className="cost-widget-text-container">
                    <div className="cost-widget-text-title">
                      {isOverBudget ? "Over Budget" : "Under Budget"}
                    </div>
                    <div className="cost-widget-text-value">
                      {actualScheduleCost}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        height: 25,
                        transform: !isOverBudget ? "rotate(180deg)" : undefined,
                      }}
                      src={CostScheduleUpIcon}
                    />
                  </div>
                </>
              ) : (
                <motion.div
                  className="noselect"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon size={35} icon="chevron-down" color="#fff" />
                </motion.div>
              )}
            </motion.div>
          </>
        ) : null}

        {/* <motion.div
          className="chat-absolute-container"
          transition={spring}
          animate={{
            opacity: chatVisible ? 1 : 0,
            visibility: chatVisible ? "visible" : "hidden",
          }}
        >
          <Chat />
        </motion.div> */}
      </motion.div>
    </AnimatePresence>
  );
};
