//TODO drag and drop question causes change to its index so consider it before redirecting
import { Button, Dialog, Icon } from "@blueprintjs/core";
import { mergeRefs } from "@blueprintjs/core";
import { Classes, Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { Item } from "framer-motion/types/components/Reorder/Item";
import React, { useCallback, useMemo } from "react";
import Select, {
  GroupProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";

import useLogic from "../../hooks/useLogic";
import {
  ComponentType,
  EndingSectionProps,
  Operators,
  Rule,
} from "../../types";
import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  questions: any[];
  endingSections: EndingSectionProps[];
  getIcon: (componentType: ComponentType) => JSX.Element;
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>;
}

const LogicModal = ({
  isOpen,
  onClose,
  questions,
  endingSections,
  getIcon,
  setQuestions,
}: Props) => {
  const {
    addRuleToQuestion,
    operatorOptions,
    operationLogicOptions,
    updateRule,
    updateCondition,
    addNewCondition,
    deleteCondition,
    deleteRule,
    removeAll,
  } = useLogic(setQuestions, questions);
  const Option = (props: OptionProps<any, true>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className="option-container">
          <div className="icon-container">{getIcon(data.type)}</div>
          {data.type !== ComponentType.endingSection && data.type !== ComponentType.welcomeScreen ? (
            <span className="question-number">
              {getQuestionIndex(data.id) + 1}{" "}
            </span>
          ) : null}

          {data.title}
        </div>
      </components.Option>
    );
  };
  const Group = (props: GroupProps<any, false>) => (
    <components.Group {...props} />
  );

  const SingleValue = (props: SingleValueProps<any>) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <div className="option-container">
          <div className="icon-container">{getIcon(data.type)}</div>
          {data.type != "endingSection" ? (
            <>
              <span className="question-number">
                {getQuestionIndex(data.id) + 1}
              </span>{" "}
            </>
          ) : null}
          {data.title}
        </div>
      </components.SingleValue>
    );
  };
  const getOperatorLabel = useCallback((operatorType: Operators) => {
    switch (operatorType) {
      case "is-equal-to":
        return "is equal to";
      case "is-not-equal-to":
        return "is not equal to";
      case "begins-with":
        return "begins with";
      case "ends-with":
        return "ends with";
      case "contains":
        return "contains";
      case "does-not-contain":
        return "does not contain";
    }
  }, []);
  const getQuestionIndex = useCallback(
    (id: number) => {
      const findIndex = questions.findIndex((item) => item.id === id);
      return findIndex;
    },
    [questions]
  );
  const renderRules = (question: any, questionIndex: number) => {
    const rules: Rule[] = question.rules;
    const data = [...questions, ...endingSections];
    const selectedDestinationIndex = data.findIndex((item) => item.id === question.rules[0].destination)
    let selectedDestinationData;
    if (questionIndex === questions.length - 1) {
      selectedDestinationData = endingSections.filter((item) => item.type === 'endingSection')[0]

    } else {
      selectedDestinationData = selectedDestinationIndex > -1 ? data[selectedDestinationIndex] : data[questionIndex + 1]

    }
    return (
      <>
        {rules.map((item, ruleIndex) => {
          if (!item.default && item.conditions) {
            const conditionDestinationIndex = data.findIndex((item1) => item1.id === item.destination)
            const selectedConditionDestination = data[conditionDestinationIndex]
            return (
              <div key={ruleIndex + "rule"}>
                {item.conditions.map((condition, conditionIndex) => {
                  return (
                    <div
                      className="origin-container"
                      key={conditionIndex + "condition"}
                    >
                      {/*condition*/}
                      <div className="if-section">
                        <Select
                          onChange={(option) => {
                            if (option) {
                              updateRule(
                                "mainCondition",
                                option.value,
                                questionIndex,
                                ruleIndex
                              );
                            }
                          }}
                          value={{
                            label:
                              item.mainCondition === "if" ? "If" : "Always",
                            value: item.mainCondition,
                          }}
                          options={[
                            { label: "If", value: "if" },
                            { label: "Always", value: "always" },
                          ]}
                        />
                      </div>

                      <div className="rule-config-container">
                        {condition.chainBy ? (
                          <Select
                            className="chain-select"
                            onChange={(option) => {
                              if (option) {
                                updateCondition(
                                  "chainBy",
                                  option.value,
                                  questionIndex,
                                  ruleIndex,
                                  conditionIndex
                                );
                              }
                            }}
                            value={{
                              label: condition.chainBy === "and" ? "And" : "Or",
                              value: condition.chainBy,
                            }}
                            options={[
                              { label: "And", value: "and" },
                              { label: "Or", value: "or" },
                            ]}
                          />
                        ) : null}
                        <div style={{ display: "flex", position: "relative" }}>
                          {item.conditions.length === 1 ? (
                            <Tooltip2
                              className="trash-button"
                              content="Delete this rule"
                              usePortal={false}
                            >
                              <Icon
                                icon="trash"
                                onClick={() =>
                                  deleteRule(questionIndex, ruleIndex)
                                }
                              />
                            </Tooltip2>
                          ) : (
                            <Tooltip2
                              className="trash-button"
                              content="Delete this condition"
                              usePortal={false}
                            >
                              <Icon
                                icon="trash"
                                onClick={() =>
                                  deleteCondition(
                                    questionIndex,
                                    ruleIndex,
                                    conditionIndex
                                  )
                                }
                              />
                            </Tooltip2>
                          )}

                          <Select
                            className="origin-selector"
                            options={[{ ...question }]}
                            value={question}
                            getOptionValue={(item) => item.id}
                            components={{
                              Option,
                              SingleValue,
                            }}
                          />
                        </div>

                        <div className="input-group">
                          <Select
                            onChange={(option) => {
                              if (option) {
                                updateCondition(
                                  "operator",
                                  option.value,
                                  questionIndex,
                                  ruleIndex,
                                  conditionIndex
                                );
                              }
                            }}
                            value={{
                              label: getOperatorLabel(condition.operator),
                              value: condition.operator,
                            }}
                            className="operator-select"
                            options={operatorOptions}
                          />
                          <input
                            value={condition.operationValue}
                            onChange={(e) =>
                              updateCondition(
                                "operationValue",
                                e.target.value,
                                questionIndex,
                                ruleIndex,
                                conditionIndex
                              )
                            }
                          />
                        </div>
                      </div>
                      {/*condition*/}
                    </div>
                  );
                })}
                <div
                  className="add-condition"
                  onClick={() => addNewCondition(questionIndex, ruleIndex)}
                >
                  + Add condition
                </div>

                <div className="destination-container">
                  <div className="if-section">Then</div>
                  <div className="rule-config-container">
                    <div className="input-group">
                      <Select
                        value={{
                          label:
                            item.operationLogic === "jump-to" ? "Jump to" : "",
                          value: item.operationLogic,
                        }}
                        className="operator-select"
                        options={operationLogicOptions}
                      />
                      <Select
                        className="block-selector"
                        options={[
                          {
                            label: "questions",
                            options: questions,
                          },
                          {
                            label: "ending",
                            options: endingSections,
                          },
                        ]}
                        value={selectedConditionDestination}
                        getOptionValue={(item) => item.id}
                        onChange={(questionItem) => {
                          updateRule(
                            "destination",
                            // @ts-ignore
                            questionItem.id,
                            questionIndex,
                            ruleIndex
                          );
                        }}
                        components={{
                          //@ts-ignore,
                          Option,
                          SingleValue,
                          Group,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}

        <div className="question-options">
          <div className="jump-to-text">
            <span>Always jump</span> to
          </div>
          <Select
            className="select-question"
            components={{
              // @ts-ignore
              Option,
              Group,
              SingleValue,
            }}
            onChange={(item) => {
              updateRule("destination", item.id, questionIndex, 0);
            }}
            value={selectedDestinationData}
            getOptionValue={(item) => item.id}
            options={[
              {
                label: "questions",
                options: questions,
              },
              {
                label: "ending",
                options: endingSections,
              },
            ]}
          />
        </div>
        <div
          className="add-condition"
          onClick={() => addRuleToQuestion(question.id)}
        >
          +Add Rule
        </div>
      </>
    );
  };
  const renderQuestion = (question: any, index: number) => {
    return (
      <div className="question-rule" key={question.id}>
        {/*question title*/}
        <div className="question-title">
          {index + 1}. {question.title}
        </div>
        {renderRules(question, index)}
      </div>
    );
  };
  return (
    <Dialog
      isOpen={isOpen}
      className="logic-modal"
      onClose={onClose}
      hasBackdrop
    >
      <div className="header">
        <div className="header-title">Logic</div>
      </div>
      <div className="modal-body">
        {questions.map((question, index) => {
          return renderQuestion(question, index);
        })}
      </div>
      <div className="logic-modal-footer">
        {/*<div className="remove-all" onClick={removeAll}>Remove All</div>*/}
        <div>
          <Button text="cancel" className="cancel-button" onClick={onClose} />
          <Button text="Save" className="save-button" onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
};
export default LogicModal;
