import React from "react";
import { useSelector } from "react-redux";
import { firstBy } from "thenby";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";

import { getProductsForTable } from "model/selectors/products";
import { RootState } from "model/store";

import ProductsTable from "../admin/menus&Products/products/ProductsTable";
import Layout from "./Layout";

const Products = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const products: FirebaseProductDoc[] = useSelector(getProductsForTable);
  const modifiers: FirebaseModifierDoc[] = useSelector((state: RootState) =>
    state.modifiers.sort(firstBy("nameExternal", { ignoreCase: true }))
  );
  const menuCategories: FirebaseMenuCategoryDoc[] = useSelector(
    (state: RootState) => state.menuCategories
  );
  const initComplete: boolean = useSelector(
    (state: RootState) => state.enterpriseInitComplete
  );
  const discounts: any[] = [];

  if (!initComplete) {
    return <EnterpriseInitializeLoading />;
  }

  // TODO: Add menu categories
  if (business) {
    return (
      <Layout>
        <ProductsTable
          businessName={business.businessName}
          businessId={business?.id}
          products={products}
          modifiers={modifiers}
          discounts={discounts}
          menuCategories={menuCategories}
        />
      </Layout>
    );
  }

  return null;
};

export default Products;
