import { Button, Intent, Position, Toaster } from "@blueprintjs/core";
import firebase from "config/firebase";
import { AsYouType } from "libphonenumber-js";
import React, { useCallback, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  checkEmailVerificationCode,
  checkPhoneNumberVerificationCode,
} from "controllers/auth";

// import ReactCodeInput from 'react-verification-code-input';
import Box from "components/Box";

import TangoImage from "../../../assets/manager/tango.png";
import "./style.css";

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

const EnterVerificationCode = () => {
  const aYT = new AsYouType("US");

  const [verificationCode, setVerificationCode] = useState("");

  const [loading, setLoading] = useState(false);

  const location: any = useLocation();
  const navigate = useNavigate();
  console.log("location.state", location.state);
  const verificationType: "email" | "phone" | undefined =
    location?.state?.verificationType;

  const phoneNumber: string | undefined = location?.state?.phoneNumber;
  const email: string | undefined = location?.state?.email;

  const verifyCode = async () => {
    try {
      setLoading(true);
      const confirmation = (window as any)
        .confirmationResult as firebase.auth.ConfirmationResult;
      const result = await confirmation.confirm(verificationCode);
      navigate("/dashboard");
      setLoading(false);
      console.log("result", result);
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    }
  };

  const handleVerification = () => {
    if (verificationType === "email") {
      checkEmailVerification();
    } else if (verificationType === "phone") {
      checkPhoneNumberVerification();
    }
  };

  const checkEmailVerification = useCallback(async () => {
    try {
      console.log("verificationCode && email", verificationCode, email);
      if (verificationCode && email) {
        setLoading(true);
        const verificationCheck = await checkEmailVerificationCode(
          email,
          verificationCode
        );
        setLoading(false);
        if (verificationCheck.customToken) {
          await firebase
            .auth()
            .signInWithCustomToken(verificationCheck.customToken);
          navigate("/dashboard");
        } else {
          toaster.show({
            message:
              verificationCheck?.message ?? "Something went wrong here...",
            intent: Intent.DANGER,
          });
        }
      } else {
        toaster.show({
          message: "Please enter a valid code.",
          intent: Intent.DANGER,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toaster.show({
        message: error?.message ?? "Something went wrong here...",
        intent: Intent.DANGER,
      });
      console.log("e", error.message);
    }
  }, [verificationCode, email]);

  const checkPhoneNumberVerification = useCallback(async () => {
    try {
      if (verificationCode && phoneNumber) {
        setLoading(true);
        const verificationCheck = await checkPhoneNumberVerificationCode(
          phoneNumber,
          verificationCode
        );
        setLoading(false);
        if (verificationCheck.customToken) {
          await firebase
            .auth()
            .signInWithCustomToken(verificationCheck.customToken);
          navigate("/dashboard");
        } else {
          toaster.show({
            message:
              verificationCheck?.message ?? "Something went wrong here...",
            intent: Intent.DANGER,
          });
        }
      } else {
        toaster.show({
          message: "Please enter a valid code.",
          intent: Intent.DANGER,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toaster.show({
        message: error?.message ?? "Something went wrong here...",
        intent: Intent.DANGER,
      });
      console.log("e", error);
    }
  }, [verificationCode, phoneNumber]);

  const inputStyle = {
    fontFamily: "Lato-Bold",
    borderRadius: "6px",
    border: "1px solid",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,.10)",
    margin: "4px",
    width: "43px",
    height: "49px",
    fontSize: "38px",
    color: "#fff",
    backgroundColor: "transparent",
    borderColor: "#999CB0",
    padding: "7px",
    paddingTop: "7px",
  };
  return (
    <Box className="manager-sign-up">
      <Box className="header" display="flex" justifyContent="space-between">
        {/* <img src={TangoImage} />
        <div className="login-link">
          Already have an account? <span>Login</span>
        </div> */}
      </Box>

      <Box className="sign-up-form">
        <img src="/static/media/tango-icon.acac10f7.svg" className="absolute w-6 right-6 top-6" />
        <div className=" text-base font-lato-black text-white">Login</div>
        <div className="text-base text-grey-2 font-lato-medium mt-8 mb-3">Enter verification code</div>
        {/* <div className="form-title">Log In</div>
        <div className="form-subtitle">Enter verification code</div> */}
        <Box className="input-container">
          <Box display="flex" flexDirection="row" justifyContent="center">
            <ReactCodeInput
              inputStyle={inputStyle}
              onChange={setVerificationCode}
              name="code-input"
              inputMode="numeric"
              type="text"
              fields={6}
            />
          </Box>
        </Box>
        <Button
          loading={loading}
          onClick={handleVerification}
          text="Next"
          className="next-button"
        />
        <Box
          className="divider"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="bar" />
          <div>Or</div>
          <div className="bar" />
        </Box>
        <Link to="/signIn">
          <Button className="google-signup" text="Log in with Email/Password" />
        </Link>
        <div className="links">
          By clicking <span>Next</span>, you agree to our{" "}
          <span style={{ textDecoration: "underline" }}>
            <a href="https://www.runtango.com/legal">Terms of Service</a>
          </span>{" "}
          and{" "}
          <span style={{ textDecoration: "underline" }}>
            <a href="https://www.runtango.com/legal">Privacy Policy</a>
          </span>
        </div>
      </Box>
    </Box>
  );
};
export default EnterVerificationCode;
