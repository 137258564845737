import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

interface Props {
  onChange: (imageUrl: string) => void;
  color: string;
}
const DigitalSignature = ({ onChange, color }: Props) => {
  const canvasRef = useRef<SignatureCanvas>(null);
  return (
    <SignatureCanvas
      ref={canvasRef}
      penColor={color}
      canvasProps={{
        style: {
          borderColor: color,
          borderRadius: "5px",
        },
      }}
      onEnd={() => {
        if (canvasRef.current) {
          onChange(canvasRef.current.toDataURL());
        }
      }}
    />
  );
};
export default DigitalSignature;
