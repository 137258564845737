import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

const { Checkbox, Icon, Popover } = UIComponents;
type OptionType = "Duplicate" | "Availability" | "Preview" | "Edit" | "Delete";
const options: OptionType[] = [
  "Duplicate",
  "Availability",
  // "Preview",
  "Edit",
  "Delete",
];
interface Props extends React.HTMLProps<HTMLDivElement> {
  heading: string;
  hasShadow?: boolean;
  subHeading: string;
  onChange?: ((e: React.FormEvent<HTMLInputElement>) => void) | undefined;
  onMenuClick?: ((option: OptionType) => void) | undefined;
}

export const MenuCard = ({
  heading,
  subHeading,
  hasShadow = false,
  onChange = undefined,
  onMenuClick = undefined,
  ...props
}: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div
      {...props}
      className={`px-4 pt-4 pb-6 cursor-pointer relative ${
        hasShadow ? "shadow-card" : "border border-grey-1"
      } rounded-2xl ${
        hasShadow ? "hover:shadow-card-hover" : "hover:border-grey-2"
      }`}
    >
      {/* <div className="flex justify-between items-center relative">
        <Checkbox onChange={onChange} type="large" colorType="black" />
        <Popover
          isOpen={showMenu}
          positions={["bottom"]} // if you'd like, you can limit the positions
          padding={10} // adjust padding here!
          onClickOutside={() => setShowMenu(false)} // handle click events outside of the popover/target here!
          content={() => (
            <ul className="bg-white drop-shadow-general-shadow p-2 rounded-2xl w-24 list-none	m-0 box-border">
              {options.map((item, index) => {
                return (
                  <>
                    <li
                      key={item}
                      className={`font-lato-bold text-xs p-[0.375rem] cursor-pointer ${item === "Delete" ? "text-error-red" : "text-grey-3"
                        }`}
                      onClick={() => onMenuClick?.(item)}
                    >
                      {item}
                    </li>
                    {index === 1 ? <li className="h-px bg-grey-1" /> : null}
                  </>
                );
              })}
            </ul>
          )}
        >
          <div>
            <Icon
              onClick={() => setShowMenu(true)}
              name="more"
              fill="#424242"
              size="24"
            />
          </div>
        </Popover>
      </div> */}

      <div className="text-center mt-4 text-base font-lato-black text-black">
        {heading}
      </div>
      <div className="text-center mt-8 text-xs font-lato-regular text-black">
        {subHeading}
      </div>
    </div>
  );
};

export default MenuCard;
