import { DateRange } from "@blueprintjs/datetime";
import { useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { fetchViablePayrollISODateStringEdges } from "controllers/payroll";

import { RootState } from "model/store";

export interface OptionType {
  label: string;
  value: string;
}

export const usePayrollDateConfiguration = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const location = useLocation();

  const locationState: { startDate?: string; endDate?: string } =
    location.state;

  const businessId = useMemo(() => business?.id ?? null, [business?.id]);

  const [selectedStartISODateString, setSelectedStartISODateString] = useState<
    null | string
  >(locationState?.startDate ?? null);
  const [selectedEndISODateString, setSelectedEndISODateString] = useState<
    null | string
  >(locationState?.endDate ?? null);

  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const viablePayrollReportEdges = useQuery(
    ["viablePayrollReportEdges", businessId],
    async () => fetchViablePayrollISODateStringEdges(businessId),
    {
      enabled: !!businessId,
      initialData: [],
      refetchOnWindowFocus: false,
    }
  );

  const sortedViablePayrollReportEdges = useMemo(() => {
    return viablePayrollReportEdges.data.sort((a: string, b: string) =>
      a < b ? -1 : a > b ? 1 : 0
    );
  }, [viablePayrollReportEdges.data]);

  useEffect(() => {
    if (
      !dateRange.startDate &&
      !dateRange.endDate &&
      sortedViablePayrollReportEdges &&
      sortedViablePayrollReportEdges.length > 0
    ) {
      const lastEndEdge =
        sortedViablePayrollReportEdges[
          sortedViablePayrollReportEdges.length - 2
        ];
      const lastStartEdge =
        sortedViablePayrollReportEdges[
          sortedViablePayrollReportEdges.length - 3
        ];
      if (lastEndEdge && lastStartEdge) {
        setSelectedStartISODateString(lastStartEdge);
        setSelectedEndISODateString(lastEndEdge);
        setDateRange({
          startDate: moment(lastStartEdge).toDate(),
          endDate: moment(lastEndEdge).toDate(),
        });
      }
    }
  }, [dateRange, sortedViablePayrollReportEdges]);

  const customDateRangeOptions: OptionType[] = useMemo(() => {
    return [
      {
        label: "This pay period",
        value: "this_pay_period",
      },
      {
        label: "Last pay period",
        value: "last_pay_period",
      },
      {
        label: "Last 7 days",
        value: "last_7_days",
      },
      {
        label: "Last month",
        value: "last_month",
      },
      {
        label: "Last 3 months",
        value: "last_3_months",
      },
      {
        label: "Last 6 months",
        value: "last_6_months",
      },
      {
        label: "Year to Date",
        value: "year_to_date",
      },
    ];
  }, []);

  const maxDateRangePickerDate = useMemo(() => {
    if (!sortedViablePayrollReportEdges) {
      return undefined;
    }
    return moment(
      sortedViablePayrollReportEdges[sortedViablePayrollReportEdges.length - 1]
    ).toDate();
  }, [sortedViablePayrollReportEdges]);

  const minDateRangePickerDate = useMemo(() => {
    if (!sortedViablePayrollReportEdges) {
      return undefined;
    }
    return moment(sortedViablePayrollReportEdges[0]).toDate();
  }, [sortedViablePayrollReportEdges]);

  const dateRangeChangeHandler = useCallback(
    (upcomingDateRange: DateRange) => {
      console.log("upcomingDateRange", upcomingDateRange);
      if (sortedViablePayrollReportEdges) {
        console.log(
          "sortedViablePayrollReportEdges",
          sortedViablePayrollReportEdges
        );
        const potentialNewStartDate = upcomingDateRange[0];

        let actualNewStartDateISOString: string | null = null;
        let actualNewEndDateISOString: string | null = null;

        if (potentialNewStartDate) {
          const edgesSortedBasedOnSelectedStartDate =
            sortedViablePayrollReportEdges.sort((a, b) => {
              const aMoment = moment(a);
              const bMoment = moment(b);

              const distanceA = Math.abs(
                moment(potentialNewStartDate).unix() - aMoment.unix()
              );
              const distanceB = Math.abs(
                moment(potentialNewStartDate).unix() - bMoment.unix()
              );

              return distanceA - distanceB;
            });
          actualNewStartDateISOString = edgesSortedBasedOnSelectedStartDate[0]
            ? edgesSortedBasedOnSelectedStartDate[0]
            : null;
        }
        const potentialNewEndDate = upcomingDateRange[1];
        console.log("potentialNewStartDate", potentialNewStartDate);
        if (potentialNewEndDate) {
          const edgesSortedBasedOnSelectedEndDate =
            sortedViablePayrollReportEdges.sort((a, b) => {
              const aMoment = moment(a);
              const bMoment = moment(b);

              const distanceA = Math.abs(
                moment(potentialNewEndDate).unix() - aMoment.unix()
              );
              const distanceB = Math.abs(
                moment(potentialNewEndDate).unix() - bMoment.unix()
              );

              return distanceA - distanceB;
            });
          actualNewEndDateISOString = edgesSortedBasedOnSelectedEndDate[0]
            ? edgesSortedBasedOnSelectedEndDate[0]
            : null;
        }

        setSelectedStartISODateString(actualNewStartDateISOString);
        setSelectedEndISODateString(actualNewEndDateISOString);

        setDateRange({
          startDate: actualNewStartDateISOString
            ? moment(actualNewStartDateISOString).toDate()
            : null,
          endDate: actualNewEndDateISOString
            ? moment(actualNewEndDateISOString).toDate()
            : null,
        });
      }
    },
    [sortedViablePayrollReportEdges]
  );

  const onCustomDateRangeChange = useCallback(
    (optionId: string) => {
      const option = customDateRangeOptions.find(
        (option) => option.value === optionId
      );
      if (option) {
        switch (option.value) {
          case "this_pay_period":
            if (sortedViablePayrollReportEdges?.length) {
              //TODO figure out a less stinky way to do this

              const resortedDates = sortedViablePayrollReportEdges.sort(
                (a, b) => moment(a).unix() - moment(b).unix()
              );
              const lastEndEdge = resortedDates[resortedDates.length - 2];
              const lastStartEdge = resortedDates[resortedDates.length - 3];
              if (lastEndEdge && lastStartEdge) {
                setSelectedStartISODateString(lastStartEdge);
                setSelectedEndISODateString(lastEndEdge);
                setDateRange({
                  startDate: moment(lastStartEdge).toDate(),
                  endDate: moment(lastEndEdge).toDate(),
                });
              }
            }
            break;
          case "last_pay_period":
            if (sortedViablePayrollReportEdges?.length) {
              //TODO figure out a less stinky way to do this
              const resortedDates = sortedViablePayrollReportEdges.sort(
                (a, b) => moment(a).unix() - moment(b).unix()
              );
              const lastEndEdge = resortedDates[resortedDates.length - 3];
              const lastStartEdge = resortedDates[resortedDates.length - 4];

              console.log("lastEndEdge", lastEndEdge);
              console.log("lastStartEdge", lastStartEdge);
              if (lastEndEdge && lastStartEdge) {
                setSelectedStartISODateString(lastStartEdge);
                setSelectedEndISODateString(lastEndEdge);
                setDateRange({
                  startDate: moment(lastStartEdge).toDate(),
                  endDate: moment(lastEndEdge).toDate(),
                });
              }
            }
            break;
          case "last_7_days":
            const reportEndDate = moment()
              .startOf("day")
              .add(4, "hours")
              .add(1, "day");
            const reportStartDate = reportEndDate.clone().subtract(7, "days");
            setSelectedStartISODateString(reportStartDate.toISOString());
            setSelectedEndISODateString(reportEndDate.toISOString());
            setDateRange({
              startDate: reportStartDate.toDate(),
              endDate: reportEndDate.toDate(),
            });
            break;
          case "last_month":
            const endOfMonth = moment()
              .startOf("day")
              .add(4, "hours")
              .add(1, "day");
            const startOfMonth = endOfMonth.clone().subtract(1, "month");
            setSelectedStartISODateString(startOfMonth.toISOString());
            setSelectedEndISODateString(endOfMonth.toISOString());
            setDateRange({
              startDate: startOfMonth.toDate(),
              endDate: endOfMonth.toDate(),
            });
            break;
          case "last_3_months":
            const endOf3Months = moment()
              .startOf("day")
              .add(4, "hours")
              .add(1, "day");
            const startOf3Months = endOf3Months.clone().subtract(3, "month");
            setSelectedStartISODateString(startOf3Months.toISOString());
            setSelectedEndISODateString(endOf3Months.toISOString());
            setDateRange({
              startDate: startOf3Months.toDate(),
              endDate: endOf3Months.toDate(),
            });
            break;
          case "last_6_months":
            const endOf6Months = moment()
              .startOf("day")
              .add(4, "hours")
              .add(1, "day");
            const startOf6Months = endOf6Months.clone().subtract(6, "month");
            setSelectedStartISODateString(startOf6Months.toISOString());
            setSelectedEndISODateString(endOf6Months.toISOString());
            setDateRange({
              startDate: startOf6Months.toDate(),
              endDate: endOf6Months.toDate(),
            });
            break;
          case "year_to_date":
            const currDate = moment()
              .startOf("day")
              .add(4, "hours")
              .add(1, "day");
            const startOfYear = currDate.clone().startOf("year");
            setSelectedStartISODateString(startOfYear.toISOString());
            setSelectedEndISODateString(currDate.toISOString());
            setDateRange({
              startDate: startOfYear.toDate(),
              endDate: currDate.toDate(),
            });
            break;
          default:
            break;
        }
      }
      console.log("option", option);
    },
    [
      customDateRangeOptions,
      sortedViablePayrollReportEdges,
      selectedStartISODateString,
      setSelectedStartISODateString,
      selectedEndISODateString,
      setSelectedEndISODateString,
    ]
  );

  console.log("selectedStartISODateString", selectedStartISODateString);
  console.log("selectedEndISODateString", selectedEndISODateString);

  return {
    customDateRangeOptions,
    viablePayrollReportEdges,
    sortedViablePayrollReportEdges,
    selectedStartISODateString,
    selectedEndISODateString,
    setSelectedStartISODateString,
    setSelectedEndISODateString,
    maxDateRangePickerDate,
    minDateRangePickerDate,
    dateRangeChangeHandler,
    dateRange,
    setDateRange,
    onCustomDateRangeChange,
  };
};
