import { Icon } from "@blueprintjs/core";
import React from "react";

import ActionButton, { ActionButtonProps } from "./actionButton";
import "./style.css";

interface Props {
  children?: React.ReactNode;
  title: string;
  isOpen: boolean;
  toggleOpen: () => void;
  buttons: ActionButtonProps[];
  containerClass: string;
}

// TODO: should use the card in the UI library
const Card = (props: Props) => {
  const { children, title, toggleOpen, isOpen, buttons, containerClass } =
    props;
  const chevron = isOpen ? "chevron-down" : "chevron-right";
  const cardClass = isOpen
    ? "collapsible-card collapsible-card-open"
    : "collapsible-card";
  return (
    <div className={cardClass}>
      <div className="collapsible-card-header">
        <div className="title">{title}</div>
        {buttons.map((b) => (
          <ActionButton {...b} key={b.text} />
        ))}
        <button className="toggle-button" onClick={toggleOpen}>
          <Icon icon={chevron} iconSize={20} />
        </button>
      </div>
      {isOpen && (
        <div className={`${containerClass} collapsible-card-inner`}>
          {children}
        </div>
      )}
    </div>
  );
};
export default Card;
