import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

import { ReviewResponseTemplate } from "types/reputationManagement";

import { createReviewResponseTemplate } from "../../../models/reputationManagement";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  createSuccess: () => void;
  business: TangoBusiness
}

const NewTemplateModal = ({ isOpen, closeModal, createSuccess, business }: Props) => {
  const [templateSubject, setTemplateSubject] = useState<string>("");
  const [templateText, setTemplateText] = useState<string>("");

  const saveNewTemplate = async () => {
    const newTemplateToCreate: ReviewResponseTemplate = {
      id: "",
      accountId: business.accountId,
      businessId: business.id,
      name: templateSubject, //any reson to eventually distinguish between name and subject?
      subject: templateSubject,
      text: templateText,
      deleted: false,
      enabled: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    const templatedCreated = await createReviewResponseTemplate(
      newTemplateToCreate
    );
    if (templatedCreated) {
      createSuccess();
      return closeModal();
    } else {
      closeModal();
      return alert("There was a problem creating your new template.");
    }
  };

  return (
    <UIComponents.Modal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick
      width={"720"}
    >
      <div className={"text-black font-lato-black text-big"}>
        New Reply Template
      </div>
      <div className={"mt-12"}>
        <div className={"mb-12"}>
          <div className={"text-xl text-black font-lato-black"}>Subject</div>
          <div className={"text-sm font-lato-bold text-grey-2 mt-2.5"}>
            Add a template subject to outline the template’s usecase
          </div>
          <UIComponents.TextField
            onChange={(e: any) => setTemplateSubject(e.target.value)}
            value={templateSubject}
            sizeType={"large"}
            className={"mt-4 text-sm font-lato-regular text-black w-full"}
            placeholder={"Text"}
          />
        </div>
        <div className={"mt-12"}>
          <div className={"text-xl text-black font-lato-black"}>
            Reply Template
          </div>
          <div className={"text-sm font-lato-bold text-grey-2 mt-2.5"}>
            What you will reply with
          </div>
          <UIComponents.ParagraphField
            onChange={(e: any) => setTemplateText(e.target.value)}
            value={templateText}
            sizeType={"large"}
            className={"mt-4 text-sm font-lato-regular text-black w-full"}
            placeholder={"Type your message here..."}
            style={{ height: "144px" }}
          />
        </div>
        <UIComponents.Button
          onClick={() => {
            saveNewTemplate();
          }}
          label={"Save"}
          type={"btn-style-2"}
          size={"btn-medium"}
          className={"w-full justify-center mt-10"}
        />
      </div>
    </UIComponents.Modal>
  );
};
export default NewTemplateModal;
