import moment from "moment";
import { useEffect, useState } from "react";
import { Event, View, Views } from "react-big-calendar";

export interface Invite {
  staffId: string;
  status: "accepted" | "invited" | "rejected";
}
export type CustomEventType = Event & {
  description: string;
  id: string;
  videoCallUrl: string | null;
  calendarId: string;
  businessId: string;
  accountId: string;
  deleted: boolean;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  invites: Invite[];
  notes: string;
};

type Tab = "accounts" | "invites";
const events: CustomEventType[] = [
  {
    start: moment().toDate(),
    end: moment().add(40, "minutes").toDate(),
    title: "All Day event",
    description: "Tango Video Call",
    allDay: true,
    id: "1",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().toDate(),
    end: moment().add(40, "minutes").toDate(),
    title: "Mid-week catch-up 1",
    description: "Tango Video Call 1",
    id: "2",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().toDate(),
    end: moment().add(50, "minutes").toDate(),
    title: "Mid-week catch-up 2",
    description: "Tango Video Call 1",
    id: "3",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().toDate(),
    end: moment().add(55, "minutes").toDate(),
    title: "Mid-week catch-up 3",
    description: "Tango Video Call 1",
    id: "4",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().toDate(),
    end: moment().add(65, "minutes").toDate(),
    title: "Mid-week catch-up 4",
    description: "Tango Video Call 1",
    id: "5",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().toDate(),
    end: moment().add(70, "minutes").toDate(),
    title: "Mid-week catch-up 5",
    id: "6",
    description: "",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
  {
    start: moment().subtract(1, "day").add("40", "minutes").toDate(),
    end: moment().subtract(1, "day").add("120", "minutes").toDate(),
    title: "Mid-week catch-up",
    description: "Tango Video Call",
    videoCallUrl: "test",
    id: "7",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
  },
  {
    start: moment().subtract(3, "day").subtract("3", "hours").toDate(),
    end: moment()
      .subtract(3, "day")
      .subtract("3", "hours")
      .add("40", "minutes")
      .toDate(),
    title: "Mid-week catch-up",
    description: "Tango Video Call",
    id: "8",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    deleted: false,
    enabled: true,
    invites: [
      {
        staffId: "0Ler89j1icqypVwolnLV",
        status: "invited",
      },
      {
        staffId: "yUQM1anrLcfVjSEBVc4J",
        status: "invited",
      },
      {
        staffId: "rgJRkAEoWtuK7kcyvvqz",
        status: "invited",
      },
    ],
    createdAt: new Date(),
    updatedAt: new Date(),
    calendarId: "1",
    notes: "",
    videoCallUrl: null,
  },
];
const useCalendar = () => {
  const [selectedView, setSelectedView] = useState<{
    label: string;
    value: View;
  }>({ label: "Month", value: Views.MONTH });
  const [selectedEvent, setSelectedEvent] = useState<
    | (CustomEventType & {
        showTimePicker?: boolean;
        showAccountsPicker?: boolean;
      })
    | null
  >(null);
  const [showEventDetailPopover, setShowEventDetailPopover] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tab>("accounts");
  const [moreEvents, setMoreEvents] = useState<CustomEventType[] | null>(null);
  return {
    selectedView,
    selectedEvent,
    showEventDetailPopover,
    selectedTab,
    setSelectedEvent,
    setSelectedView,
    setShowEventDetailPopover,
    setSelectedTab,
    events,
    moreEvents,
    setMoreEvents,
  };
};
export default useCalendar;
