import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { INV_INFO_KEY } from "../constants";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

export type InventoryInfo = {
  allergens: { name: string; id: string }[];
  locations: { name: string; id: string }[];
  primaryGroups: {
    name: string;
    id: string;
    secondaryGroups: { name: string; id: string }[];
  }[];
  units: {
    name: string;
    description: string;
    type: string;
    shortName: string;
    id: string;
  }[];
  conversions: {
    toAmount: number;
    fromAmount: number;
    toId: string;
    fromId: string;
    id: string;
  }[];
};

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const fetchInventoryInfo = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<InventoryInfo>(`${businessId}/inventoryInfo`);
  return r.data;
};


export const useInventoryInfo = (businessId: string) => {
  return useQuery([INV_INFO_KEY, businessId], () =>
    fetchInventoryInfo(businessId)
  );
};

// MEASURES

type UnitCreateDTO = {
  shortName: string;
  name: string;
  description: string;
  type: string;
};

const createMeasures = async (businessId: string, units: UnitCreateDTO[]) => {
  if (!businessId) return null;
  const r = await apiClient.post<InventoryInfo>(`${businessId}/inventoryInfo/units`, {
    units,
  });
  return r.data;
};

const updateMeasure = async (
  businessId: string,
  unitId: string,
  unit: Partial<UnitCreateDTO>
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/units/${unitId}`,
    unit
  );
  return r.data;
};

export const useCreateMeasures = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (newValues: UnitCreateDTO[]) =>
      createMeasures(businessId, newValues),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

export const useUpdateMeasure = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { unitId: string; newValue: Partial<UnitCreateDTO> }) =>
      updateMeasure(businessId, args.unitId, args.newValue),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

// CONVERSIONS

type ConversionCreateDTO = {
  fromId: string;
  toId: string;
  fromAmount: number;
  toAmount: number;
};

const createConversions = async (
  businessId: string,
  conversions: ConversionCreateDTO[]
) => {
  if (!businessId) return null;
  const r = await apiClient.post<InventoryInfo>(`${businessId}/inventoryInfo/conversions`, {
    conversions,
  });
  return r.data;
};

const updateConversion = async (
  businessId: string,
  conversionId: string,
  conversion: Partial<ConversionCreateDTO>
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/conversions/${conversionId}`,
    conversion
  );
  return r.data;
};

const deleteConversion = async (businessId: string, conversionId: string) => {
  if (!businessId) return null;
  const r = await apiClient.delete(
    `${businessId}/inventoryInfo/conversions/${conversionId}`
  );
  return r.data;
};

export const useCreateConversions = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (newValues: ConversionCreateDTO[]) =>
      createConversions(businessId, newValues),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

export const useUpdateConversion = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      conversionId: string;
      newValue: Partial<ConversionCreateDTO>;
    }) => updateConversion(businessId, args.conversionId, args.newValue),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};
export const useDeleteConversion = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { conversionId: string }) =>
      deleteConversion(businessId, args.conversionId),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

// LOCATIONS

const setLocations = async (
  businessId: string,
  names: string[]
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/locations`,
    {names}
  );
  return r.data;
};

export const useSetLocations = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { names: string[];}) =>
      setLocations(businessId, args.names),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

// ALLERGENS

const setAllergens = async (
  businessId: string,
  names: string[]
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/allergens`,
    {names}
  );
  return r.data;
};

export const useSetAllergens = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { names: string[];}) =>
      setAllergens(businessId, args.names),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};

// GROUPS

const setPrimaryGroups = async (
  businessId: string,
  names: string[]
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/groups`,
    {names}
  );
  return r.data;
};

const setSecondaryGroups = async (
  businessId: string,
  primaryGroupId: string,
  names: string[]
) => {
  if (!businessId) return null;
  const r = await apiClient.put(
    `${businessId}/inventoryInfo/groups/${primaryGroupId}`,
    {names}
  );
  return r.data;
};

export const useSetPrimaryGroups = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { names: string[]}) =>
      setPrimaryGroups(businessId, args.names),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};
export const useSetSecondaryGroups = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { names: string[]; primaryGroupId: string}) =>
      setSecondaryGroups(businessId, args.primaryGroupId, args.names),
    onSuccess: (data) =>
      client.setQueryData([INV_INFO_KEY, businessId], data),
  });
};
