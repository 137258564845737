import ActionType from "./actionTypes";

/**
 *
 * @param availabilities
 * @returns
 */
export function receiveAvailabilities(availabilities: Availability[]) {
  return {
    type: ActionType.RECEIVE_AVAILABILITIES,
    availabilities,
  };
}

/**
 * Calculates the square root of a number.
 *
 * @param x the number to calculate the root of.
 * @returns the square root if `x` is non-negative or `NaN` if `x` is negative.
 */
export function sqrt(x: number): number {
  return Math.sqrt(x);
}

/**
 *
 * @param schedule
 * @returns
 */
export const setCurrentSchedule = (schedule: CurrentReduxSchedule | null) => ({
  type: ActionType.SET_CURRENT_SCHEDULE,
  payload: schedule,
});

export function updateDuplicateScheduleToDraft(scheduleId: string) {
  return {
    type: ActionType.UPDATE_DUPLICATE_SCHEDULE_TO_DRAFT,
    scheduleId,
  };
}
