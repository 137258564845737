import { Dialog, EditableText } from "@blueprintjs/core";
import moment from "moment";
import React, { FC, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "model/store";

import { updateBusinessSetting } from "../../../../../models/businesses";
import "../modal.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
interface IChannel {
  label: string;
  value: string;
}
const StaffingPreferences: FC<Props> = ({ isOpen, closeModal }) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [enableDropShifts, setEnableDropShifts] = useState(
    businessSettings && businessSettings.enableDropShifts
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [showEndTime, setShowEndTime] = useState(
    businessSettings && businessSettings.shouldShowEndTime
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [turnLocationOff, setTurnLocationOff] = useState(
    businessSettings && businessSettings.turnLocationOff
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [autoApproveAvailabilities, setAutoApproveAvailabilities] = useState(
    businessSettings && businessSettings.autoApproveAvailabilities
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [showFullStaffSchedule, setShowFullStaffSchedule] = useState(
    businessSettings && businessSettings.showFullStaffSchedule
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [newDiscountPercent, setNewDiscountPercent] = useState(
    businessSettings && businessSettings.percentDiscountForStaffMembers
  );
  const [discountPercent, setDiscountPercent] = useState("");

  const [payPeriodStartDate, setPayPeriodStartDate] = useState(
    //@ts-ignore
    businessSettings && businessSettings.payPeriodStartDate.toDate()
  );

  const [amPmSplitTime, setAmPmSplitTime] = useState(
    //@ts-ignore
    businessSettings && businessSettings.amPmSplitTime
  );

  const handleChangeDiscountPercent = async () => {
    if (
      !discountPercent ||
      !isFinite(parseInt(discountPercent)) ||
      !parseInt(discountPercent) ||
      parseInt(discountPercent) < 0
    ) {
      return setDiscountPercent("");
    }

    const success = await updateBusinessSetting(
      business.id,
      "percentDiscountForStaffMembers",
      parseFloat(discountPercent)
    );
    if (success) {
      setNewDiscountPercent(parseFloat(discountPercent));
    } else {
      alert("There was a problem updating discounts for staff members.");
    }
  };

  return (
    <Dialog isOpen={isOpen} className="settings-modal" onClose={closeModal}>
      <div className="modal-header">
        <div className="modal-title">Staffing Preferences</div>
        <div className="modal-icon staffing">
          <img
            style={{ height: 15 }}
            src={require("../../../../../assets/tango-icon.svg").default}
          />
          <div className="icon-title">Staffing</div>
        </div>
      </div>

      <div className="modal-body">
        <div className="section-title">General</div>

        <div className="section-row">
          <div className="row-title">Enable employees to drop shifts</div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "enableDropShifts",
                    option.value
                  );
                  if (success) {
                    setEnableDropShifts(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={enableDropShifts}
            />
          </div>
        </div>

        <div className="section-row">
          <div className="row-title">Show shift end times</div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "shouldShowEndTime",
                    option.value
                  );
                  if (success) {
                    setShowEndTime(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={showEndTime}
            />
          </div>
        </div>

        <div className="section-row">
          <div className="row-title">
            Use location for verified clock-in/out
          </div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "turnLocationOff",
                    !option.value
                  );
                  if (success) {
                    setTurnLocationOff(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={turnLocationOff}
            />
          </div>
        </div>

        <div className="section-row">
          <div className="row-title">
            Auto approve staff availability submissions
          </div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "autoApproveAvailabilities",
                    option.value
                  );
                  if (success) {
                    setAutoApproveAvailabilities(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={autoApproveAvailabilities}
            />
          </div>
        </div>

        <div className="section-row">
          <div className="row-title">Everyone can see the full schedule</div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "showFullStaffSchedule",
                    option.value
                  );
                  if (success) {
                    setShowFullStaffSchedule(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={showFullStaffSchedule}
            />
          </div>
        </div>

        <div className="section-row extra-margin">
          <div className="row-title">(%) discount for staff members</div>
          <div className="row-field only-text">
            <EditableText
              className="editableText"
              maxLength={2}
              defaultValue={
                newDiscountPercent ? JSON.stringify(newDiscountPercent) : "0"
              } //starting value
              onChange={(e) => setDiscountPercent(e)}
              onConfirm={() => {
                handleChangeDiscountPercent();
              }} //on click away this fires
            />
            %
          </div>
        </div>

        <div className="section-row extra-margin">
          <div className="row-title">Pay Period Start Date</div>
          <div className="row-field only-text">
            <DateTimePicker
              clearIcon={null} //dont let them clear the value
              disableClock={true}
              format={"MM/dd/y"}
              onChange={(dateSelected) => {
                setPayPeriodStartDate(dateSelected);
                updateBusinessSetting(
                  business.id,
                  "payPeriodStartDate",
                  dateSelected
                );
              }}
              value={payPeriodStartDate}
            />
          </div>
        </div>
        <div className="section-row extra-margin">
          <div className="row-title">AM/PM split time</div>
          <div className="row-field only-text">
            <input
              className="time-picker"
              value={amPmSplitTime}
              type="time"
              onChange={(e) => {
                setAmPmSplitTime(e.target.value);
                updateBusinessSetting(
                  business.id,
                  "amPmSplitTime",
                  e.target.value
                );
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default StaffingPreferences;
