import firebase from "../../../config/firebase";
import { composeUsableTangoBusiness } from "../../../controllers/composableTypes";

export async function getAllBusinessesByAccountId(accountId: string) {
  const businessResult = await firebase
    .firestore()
    .collection("Businesses")
    .where("accountId", "==", accountId)
    .get();
  return businessResult.docs.map((doc) =>
    composeUsableTangoBusiness(doc.data())
  );
}

export const findOrCreateUser = async (
  name: string,
  email: string,
  phoneNumber: string
) => {
  const db = firebase.firestore();
  return db
    .collection("Users")
    .where("phone", "==", phoneNumber)
    .get()
    .then((docs) => {
      if (!docs.empty) {
        const data = docs.docs[0].data();
        return data.uid ? data.uid : data.id;
      } else {
        let userColor =
          "rgb(" +
          Math.floor(Math.random() * 255) +
          "," +
          Math.floor(Math.random() * 255) +
          "," +
          Math.floor(Math.random() * 255) +
          ")";
        return db
          .collection("Users")
          .add({
            id: null,
            color: userColor,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: false,
            deleted: false,
            userType: "mobile",
            name,
            phone: phoneNumber,
            email,
            imageUrl: "",
            paymentMethods: [],
          })
          .then((docRef) => {
            db.collection("Users").doc(docRef.id).update({ id: docRef.id });
            return docRef.id;
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return error;
    });
};
