import {
  Button,
  Dialog,
  EditableText,
  FileInput,
  Icon,
} from "@blueprintjs/core";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "model/store";

import {
  updateBannerImage,
  updateBusinessSetting,
  updateProfileImage,
  updateSplashImage,
} from "../../../../../models/businesses";
import { saveFile } from "../../../../../utils/storage";
import "../modal.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const OnlineOrdering: FC<Props> = ({ isOpen, closeModal }) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  // const account: TangoAccount = useSelector((state: RootState) => state.account);
  const [newProfileImage, setNewProfileImage] = useState("");
  const [newSplashImage, setNewSplashImage] = useState("");
  const [newBannerImage, setNewBannerImage] = useState("");
  const [completeProfileLoad, setCompleteProfileLoad] = useState(false);
  const [completeSplashLoad, setCompleteSplashLoad] = useState(false);
  const [completeBannerLoad, setCompleteBannerLoad] = useState(false);

  const onProfileImageLoad = (e: any) => setNewProfileImage(e.target.result);
  const onSplashImageLoad = (e: any) => setNewSplashImage(e.target.result);
  const onBannerImageLoad = (e: any) => setNewBannerImage(e.target.result);

  const [scheduledOrders, setScheduledOrders] = useState(
    businessSettings && businessSettings.scheduledOrders
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [scheduledOrdersRequired, setScheduledOrdersRequired] = useState(
    businessSettings && businessSettings.scheduledOrdersRequired
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [orderSpecialInstructions, setOrderSpecialInstructions] = useState(
    businessSettings && businessSettings.orderSpecialInstructions
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [allowItemLevelRemarks, setAllowItemLevelRemarks] = useState(
    businessSettings && businessSettings.allowItemLevelRemarks
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );

  const onProfileInputChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.onload = onProfileImageLoad;
    if (e.target.files.length) {
      setNewProfileImage(e.target.value);
      fileReader.readAsDataURL(e.target.files[0]);
      if (newProfileImage) {
        saveNewProfileImage();
      }
    }
  };

  // console.log("load online ordering settings", business, businessSettings)
  const onSplashInputChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.onload = onSplashImageLoad;

    if (e.target.files.length) {
      setNewSplashImage(e.target.value);
      fileReader.readAsDataURL(e.target.files[0]);
      console.log("selected splash", e.target.files[0]);
      if (newSplashImage) {
        console.log("finish setting");
        saveNewSplashImage();
      }
    }
  };

  const onBannerInputChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.onload = onBannerImageLoad;

    if (e.target.files.length) {
      setNewBannerImage(e.target.value);
      fileReader.readAsDataURL(e.target.files[0]);
      console.log("selected banner", e.target.files[0]);
      if (newBannerImage) {
        console.log("finish setting");
        saveNewBannerImage();
      }
    }
  };
  const [displaySplash, setDisplaySplash] = useState(
    business && business.splashImageUrl
  );
  const [displayBanner, setDisplayBanner] = useState(
    business && business.bannerImageUrl
  );
  const [displayProfile, setDisplayProfile] = useState(
    business && business.profileImageUrl
  );
  const [minAmount, setMinAmount] = useState(
    businessSettings && businessSettings.onlineMinimumTipForPercentagePresets
  );
  const [tipDollars, setTipDollars] = useState(
    businessSettings && businessSettings.onlineTipPresetAmounts
  );
  const [tipPrecentages, setTipPercentages] = useState(
    businessSettings && businessSettings.onlineTipPresetPercentages
  );
  const [deliveryRadius, setDeliveryRadius] = useState(
    businessSettings && businessSettings.deliveryRadius
  );
  const [deliveryFee, setDeliveryFee] = useState(
    businessSettings && businessSettings.deliveryFee
  );
  const [newTipPercent, setNewTipPercent] = useState("");
  const [newDeliveryRadius, setNewDeliveryRadius] = useState("");
  const [newDeliveryFee, setNewDeliveryFee] = useState("");
  const [newTipDollar, setNewTipDollar] = useState("");
  const [minDollar, setNewMinDollar] = useState("");

  const handleChangeMinDollar = async () => {
    console.log("set min dollar", minDollar);
    if (
      !minDollar ||
      !isFinite(parseInt(minDollar)) ||
      !parseInt(minDollar) ||
      parseInt(minDollar) < 0
    ) {
      return setNewMinDollar("");
    }

    const success = await updateBusinessSetting(
      business.id,
      "onlineMinimumTipForPercentagePresets",
      parseFloat(minDollar)
    );
    if (success) {
      console.log("success onlineMinimumTipForPercentagePresets");
      setMinAmount(parseFloat(minDollar));
    } else {
      alert("There was a problem updating minimum value.");
    }
  };

  const handleChangeDeliveryRadius = async () => {
    if (
      !newDeliveryRadius ||
      !isFinite(parseFloat(newDeliveryRadius)) ||
      !parseFloat(newDeliveryRadius) ||
      parseFloat(newDeliveryRadius) < 0
    ) {
      return setNewDeliveryRadius("");
    }

    const success = await updateBusinessSetting(
      business.id,
      "deliveryRadius",
      parseInt(newDeliveryRadius)
    );
    if (success) {
      console.log("success deliveryRadius");
    } else {
      alert("There was a problem updating delivery radius.");
    }
  };

  const handleChangeDeliveryFee = async () => {
    if (
      !newDeliveryFee ||
      !isFinite(parseFloat(newDeliveryFee)) ||
      !parseFloat(newDeliveryFee) ||
      parseFloat(newDeliveryFee) < 0
    ) {
      return setNewDeliveryRadius("");
    }
    const success = await updateBusinessSetting(
      business.id,
      "deliveryFee",
      parseFloat(newDeliveryFee) * 100
    );
    if (success) {
      // console.log('success new delivery fee', business.id);
    } else {
      alert("There was a problem updating your delivery fee.");
    }
  };

  const handleChangeTipPercent = async (option: number) => {
    if (
      !newTipPercent ||
      !isFinite(parseFloat(newTipPercent)) ||
      !parseFloat(newTipPercent) ||
      parseFloat(newTipPercent) < 0
    ) {
      return setNewTipPercent("");
    }

    tipPrecentages[option] = parseInt(newTipPercent);

    const success = await updateBusinessSetting(
      business.id,
      "onlineTipPresetPercentages",
      tipPrecentages
    );
    if (success) {
      console.log("success tipPresetPercentages");
    } else {
      alert("There was a problem updating tip percentages.");
    }
  };

  const handleChangeTipDollar = async (option: number) => {
    if (
      !newTipDollar ||
      !isFinite(parseFloat(newTipDollar)) ||
      !parseFloat(newTipDollar) ||
      parseFloat(newTipDollar) < 0
    ) {
      return setNewTipDollar("");
    }

    tipDollars[option] = parseFloat(newTipDollar);
    console.log("new options", tipDollars);

    const success = await updateBusinessSetting(
      business.id,
      "onlineTipPresetAmounts",
      tipDollars
    );
    if (success) {
      console.log("success tipPresetAmounts");
      // setTipDollars(newTipDollars)
    } else {
      alert("There was a problem updating tip dollar amounts.");
    }
  };

  const saveNewProfileImage = async () => {
    const fileUrl = await saveFile(
      `businesses/${business.id}/profileImage`,
      newProfileImage
    );

    if (fileUrl) {
      console.log("image url generated!", fileUrl);
      const success = await updateProfileImage(
        business.accountId,
        fileUrl,
        business.id
      );
      if (success) {
        setDisplayProfile(fileUrl);
        setCompleteProfileLoad(true);
      } else {
        alert("There was a problem updating your profile image.");
      }
    }
  };

  const saveNewSplashImage = async () => {
    const fileUrl = await saveFile(
      `businesses/${business.id}/splashImage`,
      newSplashImage
    );

    if (fileUrl) {
      console.log("image url generated!", fileUrl);

      const success = await updateSplashImage(
        business.accountId,
        fileUrl,
        business.id
      );
      if (success) {
        setDisplaySplash(fileUrl);
        setCompleteSplashLoad(true);
      } else {
        alert("There was a problem updating your splash image.");
      }
    }
  };

  const saveNewBannerImage = async () => {
    const fileUrl = await saveFile(
      `businesses/${business.id}/bannerImage`,
      newBannerImage
    );
    if (fileUrl) {
      console.log("image url generated!", fileUrl);
      const success = await updateBannerImage(business.id, fileUrl);
      if (success) {
        console.log("success update banner image");
        setDisplayBanner(fileUrl);
        setCompleteBannerLoad(true);
      } else {
        alert("There was a problem updating your banner image.");
      }
    }
  };
  if (business && businessSettings) {
    return (
      <Dialog isOpen={isOpen} className="settings-modal" onClose={closeModal}>
        <div className="modal-header">
          <div className="modal-title">Online Ordering Preferences</div>
          <div className="modal-icon online-order">
            <img
              style={{ height: 15 }}
              src={require("../../../../../assets/tango-icon.svg").default}
            />
            <div className="icon-title">Online</div>
          </div>
        </div>

        <div className="modal-body">
          <div className="section-title">Display Preferences</div>

          <div className="section-row extra-margin">
            <div className="row-title">Profile Image</div>
            <div className="row-field">
              <img src={displayProfile ? displayProfile : ""} />
              <br />

              <span style={{ paddingLeft: "20px" }}>
                {(!newProfileImage || completeProfileLoad) && (
                  <FileInput
                    inputProps={{ accept: ".png,.jpeg" }}
                    onInputChange={(e) => onProfileInputChange(e)}
                    buttonText="update"
                    text="profile image"
                    style={{ width: "65%" }}
                  />
                )}
                {newProfileImage && !completeProfileLoad && (
                  <Button onClick={() => saveNewProfileImage()} minimal small>
                    Save selected image{" "}
                    <Icon
                      icon="saved"
                      iconSize={20}
                      style={{ paddingLeft: "5px" }}
                    />
                  </Button>
                )}
              </span>
            </div>
          </div>

          <div className="section-row extra-margin">
            <div className="row-title">Splash Image</div>
            <div className="row-field">
              <img src={displaySplash ? displaySplash : ""} />
              <br />

              <span style={{ paddingLeft: "20px" }}>
                {(!newSplashImage || completeSplashLoad) && (
                  <FileInput
                    inputProps={{ accept: ".png,.jpeg" }}
                    onInputChange={(e) => onSplashInputChange(e)}
                    buttonText="update"
                    text="splash image"
                    style={{ width: "65%" }}
                  />
                )}
                {newSplashImage && !completeSplashLoad && (
                  <Button onClick={() => saveNewSplashImage()} minimal small>
                    Save selected image{" "}
                    <Icon
                      icon="saved"
                      iconSize={20}
                      style={{ paddingLeft: "5px" }}
                    />
                  </Button>
                )}
              </span>
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Banner Image (Mobile Only)</div>
            <div className="row-field">
              <img src={displayBanner ? displayBanner : ""} />
              <br />

              <span style={{ paddingLeft: "20px" }}>
                {(!newBannerImage || completeBannerLoad) && (
                  <FileInput
                    inputProps={{ accept: ".png,.jpeg" }}
                    onInputChange={(e) => onBannerInputChange(e)}
                    buttonText="update"
                    text="banner image"
                    style={{ width: "65%" }}
                  />
                )}
                {newBannerImage && !completeBannerLoad && (
                  <Button onClick={() => saveNewBannerImage()} minimal small>
                    Save selected image{" "}
                    <Icon
                      icon="saved"
                      iconSize={20}
                      style={{ paddingLeft: "5px" }}
                    />
                  </Button>
                )}
              </span>
            </div>
          </div>

          <br />

          <div className="section-title">Ordering preferences</div>
          <div className="section-row">
            <div className="row-title">Allow scheduled orders</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={async (option) => {
                  if (option) {
                    const success = await updateBusinessSetting(
                      business.id,
                      "scheduledOrders",
                      option.value
                    );
                    if (success) {
                      //if scheduled orders is false - make sure require scheduled is also false
                      if (!option.value) {
                        const update = await updateBusinessSetting(
                          business.id,
                          "scheduledOrdersRequired",
                          option.value
                        );
                        setScheduledOrdersRequired(option);
                      }
                      setScheduledOrders(option);
                    }
                  }
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={scheduledOrders}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Require scheduled orders</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={async (option) => {
                  if (option) {
                    const success = await updateBusinessSetting(
                      business.id,
                      "scheduledOrdersRequired",
                      option.value
                    );
                    if (success) {
                      //if require scheduled orders - make sure allow schedule orders is also true
                      if (option.value) {
                        const update = await updateBusinessSetting(
                          business.id,
                          "scheduledOrders",
                          option.value
                        );
                        setScheduledOrders(option);
                      }
                      setScheduledOrdersRequired(option);
                    }
                  }
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={scheduledOrdersRequired}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">
              Allow customers to add special instructions
            </div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={async (option) => {
                  if (option) {
                    const success = await updateBusinessSetting(
                      business.id,
                      "orderSpecialInstructions",
                      option.value
                    );
                    if (success) {
                      setOrderSpecialInstructions(option);
                    }
                  }
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={orderSpecialInstructions}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">
              Allow customers to add item level notes
            </div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={async (option) => {
                  if (option) {
                    const success = await updateBusinessSetting(
                      business.id,
                      "allowItemLevelRemarks",
                      option.value
                    );
                    if (success) {
                      setAllowItemLevelRemarks(option);
                    }
                  }
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={allowItemLevelRemarks}
              />
            </div>
          </div>

          <div className="section-row" style={{ paddingTop: "7px" }}>
            <div className="row-title">Delivery Radius</div>
            <div className="row-field">
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={4}
                  defaultValue={
                    deliveryRadius ? JSON.stringify(deliveryRadius) : "0.0"
                  } //starting value
                  onChange={(e) => setNewDeliveryRadius(e)}
                  onConfirm={() => {
                    handleChangeDeliveryRadius();
                  }} //on click away this fires
                />
                {business.location.country === "USA" ? (
                  <span>miles</span>
                ) : (
                  <span>kilometers</span>
                )}
              </div>
            </div>
          </div>

          <br />

          <div className="section-row">
            <div className="row-title">Delivery Fee</div>
            <div className="row-field">
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={5}
                  defaultValue={
                    deliveryFee ? (deliveryFee * 0.01).toFixed(2) : "0.0"
                  } //starting value
                  onChange={(e) => setNewDeliveryFee(e)}
                  onConfirm={() => {
                    handleChangeDeliveryFee();
                  }} //on click away this fires
                />
                {business.location.country === "USA" ? (
                  <span style={{ paddingLeft: "15px" }}>USD</span>
                ) : (
                  <span style={{ paddingLeft: "15px" }}>CAD</span>
                )}
              </div>
            </div>
          </div>

          <div className="section-title section-margin">Tip preferences</div>
          <div className="section-row extra-margin">
            <div className="row-title">Tip presets (%)</div>
            <div className="tips-row row-field">
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[0]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(0);
                  }} //on click away this fires
                />
                %
              </div>
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[1]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(1);
                  }} //on click away this fires
                />
                %<div className="tip-selected">default</div>
              </div>
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[2]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(2);
                  }} //on click away this fires
                />
                %
              </div>
            </div>
          </div>

          <br />

          <div className="section-row extra-margin">
            <div className="row-title">($) tip for orders under $xx</div>
            <div className="row-field only-text">
              $
              <EditableText
                className="editableText"
                maxLength={2}
                defaultValue={minAmount ? JSON.stringify(minAmount) : "0.0"} //starting value
                onChange={(e) => setNewMinDollar(e)}
                onConfirm={() => {
                  handleChangeMinDollar();
                }} //on click away this fires
              />
            </div>
          </div>

          <br />

          <div className="section-row">
            <div className="row-title">Tip presets for orders under $xx</div>
            <div className="tips-row row-field">
              <div className="tip-container">
                $
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[0]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(0);
                  }} //on click away this fires
                />
              </div>
              <div className="tip-container">
                ${" "}
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[1]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(1);
                  }} //on click away this fires
                />
                <div className="tip-selected">default</div>
              </div>
              <div className="tip-container">
                ${" "}
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[2]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(2);
                  }} //on click away this fires
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return null;
};

export default OnlineOrdering;
