import { Dialog } from "@blueprintjs/core";
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Box from "components/Box";

import { adminsSelector } from "model/selectors/staffing";

import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import CrossIcon from "../../../icons/crossIcon.svg";
import "./styles.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

interface listOption {
  value: string;
  firstName: string;
  lastName: string;
  label: string;
  id: string;
}

export function UserListModal({ isOpen, closeModal }: Props) {
  const db = firebase.firestore();

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const fellowStaffMembers: StaffMember[] = useSelector(adminsSelector);
  const [listData, setListData]: any = useState<listOption>();
  const [selectedList, setSelectedList]: any = useState();
  const [MasterChecked, setMasterChecked] = useState(false);
  const [tableData, setTableData] = useState([
    {
      emailAddress: "email",
      totalOPens: 1,
      name: "Brandon Herron",
      selected: false,
    },
    {
      emailAddress: "email",
      totalOPens: 2,
      name: "Brandon Herron",
      selected: false,
    },
    {
      emailAddress: "email",
      totalOPens: 2,
      name: "Brandon Herron",
      selected: false,
    },
    {
      emailAddress: "email",
      totalOPens: 2,
      name: "Brandon Herron",
      selected: false,
    },
    {
      emailAddress: "email",
      totalOPens: 2,
      name: "Brandon Herron",
      selected: false,
    },
    {
      emailAddress: "email",
      totalOPens: 1,
      name: "Brandon Herron",
      selected: false,
    },
  ]);

  const onMasterCheck = (e: any, key: number) => {
    let list = tableData.map((item) => item);
    list[key]["selected"] = e.target.checked;
    setTableData(list);
  };

  return (
    <>
      <Dialog isOpen={isOpen} className="user-list-modal" onClose={closeModal}>
        <div className="modal-header">
          <img
            src={CrossIcon}
            style={{ height: 12.83, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
          <div className="modal-titlebody">
            <span className="modal-title">Opened By</span>
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-title">Opened</div>
          <div className="modal-subtitle">
            Showing all <b>90</b> of your roles
          </div>
          <Box className="table-container">
            <table>
              <tr className="table-header">
                <th>
                  <span style={{ marginLeft: "20px" }}>Email Address</span>
                </th>
                <th>Total Opens</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
              {tableData.map((item: any, key: number) => {
                return (
                  <tr>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "20px",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={item.selected}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e, key)}
                        />
                        <span
                          className="email"
                          style={{ marginLeft: "20.5px" }}
                        >
                          {item.emailAddress}
                        </span>
                      </div>
                    </td>
                    <td>{item.totalOPens}</td>
                    <td>{item.name}</td>
                    <td>
                      <div className="subscribe-button">Subscribed</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </Box>
        </div>
      </Dialog>
    </>
  );
}

export default UserListModal;
