import React, { useState } from "react";
import { Link } from "react-router-dom";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import "../style.css";

const PayPeriodLaborReport = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Layout>
      <div id="cash-summary" className="daily-labor-report">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          <Link to="/reporting">
            <div className="button">Sales Summary</div>
          </Link>
          <Link to="/product-analytics">
            <div className="button">Product Analytics</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>
          {/*
          <div  className="button">Cash Summary</div>
          <Link to="/labor-payroll">
            <div  className="button active">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section">
          <div className="body">
            <div className="page-header">
              <div className="page-title">Pay Period Labor Reports</div>
            </div>
            <table>
              <tr>
                <th>DATE</th>
                <th>SALES REVENUE</th>
                <th>LABOR COST</th>
                <th>LABOR COST %</th>
                <th></th>
              </tr>
              <tr>
                <td>June 1, 2021</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>In Progress</td>
              </tr>
              <tr>
                <td>May 31, 2021</td>
                <td>$1,151.10</td>
                <td>$536.43</td>
                <td>35%</td>
                <td onClick={() => setShowModal(true)}>View Report</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default PayPeriodLaborReport;
