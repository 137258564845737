import React, { useCallback, useEffect, useState } from 'react';
import UIComponents from '@tangopay/tango-ui-library';
const { Modal, TextField, Dropdown, RadioButton, DatePickerInput, Button, Tag } = UIComponents;
import './style.css'
import { DisputeEvidenceDTO, TangoDispute, uploadDisputeFile, generateUpdatedEvidenceInTable } from 'controllers/disputes';
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { TangoTab } from 'controllers/payments';
import { useSelector } from "react-redux";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { saveFile } from "../../../utils/storage";
import { RootState } from 'model/store';
import HorizontalTable from 'components/Table/HorizontalTable';

interface SubmitEvidenceFormProps {
    closedCheck: TangoTab;
    dispute: TangoDispute;
    paymentDisputed: any;
    setParentData: (disputeEvidence: DisputeEvidenceDTO) => void;
}

type EvidenceTableProps = {
    evidenceType: string;
    file: string;
    uniqueId: string;
};


const columns: ColumnInstruction<EvidenceTableProps>[] = [
    { type: "data", header: "Evidence Type", attribute: "evidenceType" },
    { type: "data", header: "File", attribute: "file" },
];

const SubmitEvidenceForm = ({ closedCheck, dispute, paymentDisputed, setParentData }: SubmitEvidenceFormProps) => {

    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business
    );
    const country = business.currency.toLocaleLowerCase() === 'cad' ? 'CA' : 'USA'
    const [newImage, setNewImage] = useStateWithCallbackLazy('');
    const [isEditing, setEditing] = useState(false);
    const [data, setData] = useState<EvidenceTableProps[]>([]);
    const instructions: { [x: string]: RenderInstruction<EvidenceTableProps> } = {};
    const [disputeCustomerName, setDisputeCustomerName] = useState(dispute.evidence.customerName);
    const [disputeCustomerEmail, setDisputeCustomerEmail] = useState(dispute.evidence.customerEmailAddress);
    const [receipt, setReceipt] = useState(dispute.evidence.receipt);
    const [customerSignature, setCustomerSignature] = useState(dispute.evidence.customerSignature);
    const [customerCommunication, setCustomerCommunication] = useState(dispute.evidence.customerCommunication);
    const [refundPolicy, setRefundPolicy] = useState(dispute.evidence.refundPolicy);
    const [duplicateChargeDocumentation, setDuplicateChargeDocumentation] = useState(dispute.evidence.duplicateChargeDocumentation);
    const [serviceDocumentation, setServiceDocumentation] = useState(dispute.evidence.serviceDocumentation);
    const [shippingDocumentation, setShippingDocumentation] = useState(dispute.evidence.shippingDocumentation);
    const [uncategorizedFile, setUncategorizedFile] = useState(dispute.evidence.uncategorizedFile);
    const [disputeProductDescription, setDisputeProductDescription] = useState(dispute.evidence.productDescription);
    const [refundRefusal, setRefundRefusal] = useState(dispute.evidence.refundRefusalExplanation);
    const [duplicateChargeExplanation, setDuplicateChargeExplanation] = useState(dispute.evidence.duplicateChargeExplanation);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [evidenceType, setEvidenceType] = useState('');
    const updateCustomerName = useCallback(
        (evt) => setDisputeCustomerName(evt.target.value),
        []
    );
    const updateCustomerEmail = useCallback(
        (evt) => setDisputeCustomerEmail(evt.target.value),
        []
    );
    const updateProductDescription = useCallback(
        (evt) => setDisputeProductDescription(evt.target.value),
        []
    );
    const updateRefundRefusal = useCallback(
        (evt) => setRefundRefusal(evt.target.value),
        []
    );
    const updateDuplicateChargeExplanation = useCallback(
        (evt) => setDuplicateChargeExplanation(evt.target.value),
        []
    );

    const updatedEvidence: DisputeEvidenceDTO = {
        receipt: receipt ? receipt : null,
        cancellation_policy: null,
        customer_communication: customerCommunication ? customerCommunication : null,
        customer_signature: customerSignature ? customerSignature : null,
        duplicate_charge_documentation: duplicateChargeDocumentation ? duplicateChargeDocumentation : null,
        refund_policy: refundPolicy ? refundPolicy : null,
        service_documentation: serviceDocumentation ? serviceDocumentation : null,
        shipping_documentation: shippingDocumentation ? shippingDocumentation : null,
        uncategorized_file: uncategorizedFile ? uncategorizedFile : null,
        access_activity_log: null,
        billing_address: paymentDisputed.payment.paymentType.address ? paymentDisputed.payment.paymentType.address : null,
        cancellation_policy_disclosure: null,
        cancellation_rebuttal: null,
        customer_email_address: disputeCustomerEmail,
        customer_name: disputeCustomerName,
        customer_purchase_ip: null,
        duplicate_charge_explanation: duplicateChargeExplanation,
        duplicate_charge_id: null,
        product_description: disputeProductDescription,
        refund_policy_disclosure: null,
        refund_refusal_explanation: refundRefusal,
        //@ts-ignore
        service_date: new Date(closedCheck.createdAt._seconds * 1000).toDateString(),
        shipping_address: null,
        shipping_carrier: null,
        shipping_date: null,
        shipping_tracking_number: null,
        uncategorized_text: null
    }

    //listen for changes in all the form fields and reset the dispute evidence in parent component and evidence table
    useEffect(() => {
        setParentData(updatedEvidence);
        setData(generateUpdatedEvidenceInTable(updatedEvidence));
    }, [receipt, customerSignature, customerCommunication, refundPolicy, serviceDocumentation, shippingDocumentation, duplicateChargeDocumentation, disputeCustomerName, uncategorizedFile, disputeCustomerEmail, disputeProductDescription, refundRefusal, duplicateChargeExplanation]);

    const onImageLoad = (e: any) => setNewImage(e.target.result, () => {
        saveImageToFirestoreStripe(e, e.target.result);
    });

    const saveImageToFirestoreStripe = async (e: any, newImage: string) => {
        e.preventDefault()
        if (!newImage) {
            return console.log('no image', newImage)
            // alert("Please select an image to submit evidence");
        }
        if (!evidenceType) {
            return alert("Please select an evidence type");
        }

        const fileUrl = await saveFile(
            `businesses/${business.id}/disputes/${dispute.id}/` + evidenceType,
            newImage
        );

        if (fileUrl) {
            const stripeFile = await uploadDisputeFile(business.id, country, { dispute, fileUrl })
            if (evidenceType === 'receipt') {
                setReceipt(stripeFile.id)
            }
            if (evidenceType === 'customer_signature') {
                setCustomerSignature(stripeFile.id)
            }

            if (evidenceType === 'customer_communication') {
                setCustomerCommunication(stripeFile.id)
            }

            if (evidenceType === 'refund_policy') {
                setRefundPolicy(stripeFile.id)
            }

            if (evidenceType === 'duplicate_charge_documentation') {
                setDuplicateChargeDocumentation(stripeFile.id)
            }

            if (evidenceType === 'service_documentation') {
                setServiceDocumentation(stripeFile.id)
            }

            if (evidenceType === 'shipping_documentation') {
                setShippingDocumentation(stripeFile.id)
            }

            if (evidenceType === 'uncategorized_file') {
                setUncategorizedFile(stripeFile.id)
            }
            setLoading(false);
            setNewImage('', () => {
                console.log('reset to empty')
            });
            setEvidenceType('')
        }
    };

    const noOp = () => Promise.resolve(true);

    return (
        <div>
            <div className="text-black text-2xl font-lato-black mb-10">Customer Details</div>

            <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Email
                </div>
                <TextField
                    onChange={updateCustomerEmail}
                    placeholder={disputeCustomerEmail ? disputeCustomerEmail : 'customer@email.com'}
                    sizeType="medium" className='w-full'
                />
            </div>
            <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Customer Name
                </div>
                <TextField
                    onChange={updateCustomerName}
                    placeholder={disputeCustomerName ? disputeCustomerName : 'customer name'}
                    sizeType="medium" className='w-full'
                />
            </div>

            <div>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Billing Address
                </div>
                <Dropdown placeholder="Country" options={[
                    { label: "Canada", value: "canada" },
                    { label: "United States", value: "USA" }
                ]} size="medium" className='tango-dropdown-minimal mb-3' />
                <TextField placeholder='Address line 1' sizeType="medium" className='w-full mb-3' />
                <TextField placeholder='Address line 2' sizeType="medium" className='w-full mb-3' />
                <TextField placeholder='City' sizeType="medium" className='w-full mb-3' />
                <Dropdown options={[
                    { label: "Canada", value: "canada" },
                ]} placeholder="Province" size="medium" className='tango-dropdown-minimal mb-3' />
                <TextField placeholder='Postal code' sizeType="medium" className='w-full' />
            </div>

            <div className="text-black text-2xl font-lato-black mb-10 mt-16">Product or Service Details</div>

            <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Description
                </div>
                <textarea
                    onChange={updateProductDescription}
                    className="w-full mt-3 rounded-2xl border-grey-1 border-solid h-20 py-5 px-6 font-lato-regular text-black text-sm" placeholder={disputeProductDescription ? disputeProductDescription : "A description of the product or service that was sold."}
                />
            </div>


            <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Refund Refusal Explanation
                </div>
                <textarea
                    onChange={updateRefundRefusal}
                    className="w-full mt-3 rounded-2xl border-grey-1 border-solid h-20 py-5 px-6 font-lato-regular text-black text-sm" placeholder={refundRefusal ? refundRefusal : "Your justification for why the customer is not entitled to a refund..."}
                />
            </div>

            {dispute.reason === 'duplicate' &&
                <div className='mb-10'>
                    <div className='text-base font-lato-bold text-black mb-4'>
                        Duplicate Charge Explanation
                    </div>
                    <textarea
                        onChange={updateDuplicateChargeExplanation}
                        className="w-full mt-3 rounded-2xl border-grey-1 border-solid h-20 py-5 px-6 font-lato-regular text-black text-sm" placeholder={duplicateChargeExplanation ? duplicateChargeExplanation : "Explanation of why this is not a duplicate charge..."}
                    />
                </div>
            }

            {/* <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Description
                </div>
                <textarea className="w-full mt-3 rounded-2xl border-grey-1 border-solid h-20 py-5 px-6 font-lato-regular text-black text-sm" placeholder="Add more details about this refund..." />
            </div> */}


            {/* <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    What type of product or service is this?
                </div>
                <ul className='p-0 m-0 list-none'>
                    <li className='flex items-center mb-4'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Physical product</div>
                    </li>
                    <li className='flex items-center mb-4'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Digital product or service</div>
                    </li>
                    <li className='flex items-center mb-4'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Offline service</div>
                    </li>
                    <li className='flex items-center mb-4'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Event</div>
                    </li>
                    <li className='flex items-center mb-4'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Booking or reservation</div>
                    </li>
                    <li className='flex items-center'>
                        <RadioButton type="small" />
                        <div className='font-lato-regular text-tiny text-black ml-4'>Other</div>
                    </li>
                </ul>
            </div> */}


            {/* <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Tracking Number
                </div>
                <div className='flex justify-between items-center'>
                    <TextField placeholder='Tracking Number' sizeType="medium" className='w-7/12' />
                    <TextField placeholder='Address line 2' sizeType="medium" className='w-4/12' />
                </div>
            </div> */}


            {/* <div className='mb-10'>
                <div className='text-base font-lato-bold text-black mb-4'>
                    Shipping Date
                </div>
                <div style={{ width: "fit-content" }}>
                    <DatePickerInput size="large" />
                </div>
            </div> */}


            <div className="flex items-center">
                <div className="text-black text-2xl font-lato-black mb-10 mt-16">Supporting Evidence</div>
                <Button
                    label="Add Evidence"
                    size="btn-medium"
                    type="btn-style-2"
                    onClick={() => {
                        setIsOpen(true)
                    }}
                />
            </div>


            <div className={""} style={{ width: "100%" }}>
                <HorizontalTable
                    title=""
                    columns={columns}
                    data={data}
                    instructions={instructions}
                    saveResults={noOp}
                    setEditing={setEditing}
                    isEditing={false}
                    hideEdit
                    onRowClick={() => { console.log('click evidence') }}
                    customHeaderRightContent={<div className="flex items-center"></div>}
                />
            </div>

            <br />

            <div className='text-grey-3 text-base font-lato-regular'>
                Submit accurate and relevant high-resolution documents to support your case.
            </div>
            <div className='text-grey-3 text-base font-lato-regular mt-10'>Recommended Documents:</div>
            <ul className='m-0 mb-10'>
                <li className='text-grey-3 text-base font-lato-regular'>Customer communication</li>
                <li className='text-grey-3 text-base font-lato-regular'>Customer Signature</li>
                <li className='text-grey-3 text-base font-lato-regular'>Shipping documents</li>
                <li className='text-grey-3 text-base font-lato-regular'>Other Evidence</li>
                <li className='text-grey-3 text-base font-lato-regular'>Receipt</li>
            </ul>


            <Modal isOpen={isOpen} closeModal={() => { setIsOpen(false), setEvidenceType('') }} width={"720"} height={'600'}>
                <div className={"text-big text-black font-lato-black mb-10"}>
                    Add Suporting Evidence
                </div>
                <div className={"mb-10"}>
                    <div className={"text-xl text-black font-lato-black mb-4"}>
                        Evidence Type
                    </div>
                    <Dropdown
                        menuPosition="absolute"
                        options={[
                            {
                                label: "Receipt",
                                value: 'receipt'
                            },
                            {
                                label: "Customer Communication",
                                value: 'customer_communication'
                            },
                            {
                                label: "Customer Signature",
                                value: 'customer_signature'
                            },
                            {
                                label: "Refund Policy",
                                value: 'refund_policy'
                            },
                            {
                                label: "Duplicate Charge Documentation",
                                value: 'duplicate_charge_documentation'
                            },
                            {
                                label: "Service Documentation",
                                value: 'service_documentation'
                            },
                            {
                                label: "Shipping Documentation",
                                value: 'shipping_documentation'
                            },
                            {
                                label: "Uncategorized File",
                                value: 'uncategorized_file'
                            },


                        ]}
                        onChange={(option: any) => {
                            setEvidenceType(option.value)
                        }}
                        size="medium"
                    />
                </div>


                <div className='border-grey-1 border-solid rounded-2xl py-9 px-6 border mb-10'>
                    <input
                        type="file"
                        id="upload-file"
                        name="filename"
                        multiple
                        style={{ display: "none" }}
                    />
                    <Button
                        rightIcon='chevron-down'
                        size={"btn-medium"}
                        type={"btn-style-2"}
                        onClick={async () => {
                            if (!evidenceType) {
                                return alert('Please select an evidence type before uploading an image')
                            }
                            setLoading(true);
                            const fileUploadInput = document.getElementById("upload-file");
                            if (fileUploadInput) {
                                fileUploadInput.click();
                                fileUploadInput.onchange = async (e) => {
                                    // @ts-ignore
                                    const files = e.target?.files;
                                    const fileReader = new FileReader();
                                    fileReader.addEventListener("load", onImageLoad);
                                    if (files.length > 0) {
                                        fileReader.readAsDataURL(files[0]);
                                        // check width and height first // deliverect has 20mgpxl constraints on product image size
                                        const img = new Image();
                                        img.src = window.URL.createObjectURL(files[0]);
                                        img.addEventListener("load", () => {
                                            if (img.naturalWidth * img.naturalHeight > 20000000) {
                                                return alert("image is too large");
                                            }
                                        });
                                    }
                                    //@ts-ignore
                                    document.getElementById("upload-file").value = "";
                                };

                            } else {
                                setLoading(false);
                            }
                        }}
                        onChange={(e) => console.log("File upload", e.target)}
                        label={"File Upload"}
                    />
                    <br />

                    <div className='flex items-center'>
                        {updatedEvidence.receipt &&
                            <Button
                                onClick={() => { setReceipt('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='receipt.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.customer_communication &&
                            <Button
                                onClick={() => { setCustomerCommunication('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='customer_communication.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.customer_signature &&
                            <Button
                                onClick={() => { setCustomerSignature('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='customer_signature.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.duplicate_charge_documentation &&
                            <Button
                                onClick={() => { setDuplicateChargeDocumentation('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='duplicate_charge_documentation.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.refund_policy &&
                            <Button
                                onClick={() => { setRefundPolicy('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='refund_policy.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.service_documentation &&
                            <Button
                                onClick={() => { setServiceDocumentation('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='service_documentation.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.shipping_documentation &&
                            <Button
                                onClick={() => { setShippingDocumentation('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='shipping_documentation.png'
                                rightIcon='cross'
                            />
                        }
                        {updatedEvidence.uncategorized_file &&
                            <Button
                                onClick={() => { setUncategorizedFile('') }}
                                size={"btn-small"}
                                type={"btn-style-outline"}
                                label='uncategorized_file.png'
                                rightIcon='cross'
                            />
                        }
                    </div>

                    {/* <div style={{ width: "fit-content" }} className="flex items-center">
                        <Dropdown options={[{
                            label: "Upload Files",
                            value: "upload files"
                        }]}
                            menuPosition="absolute"
                            placeholder="Upload Files"
                            size="medium"
                        />
                        <div className='font-lato-bold text-sm text-grey-3 ml-6'>Drag and drop files</div>
                    </div> */}


                </div>

                <div className="flex items-center">
                    <Button
                        disabled={loading}
                        onClick={() => {
                            setIsOpen(false)
                        }}
                        label={"Cancel"}
                        type={"btn-style-2"}
                        size={"btn-medium"}
                        className={"w-full justify-center"}
                    />
                    <Button
                        disabled={loading}
                        onClick={(e: any) => {
                            setIsOpen(false)
                        }}
                        label={loading ? "Uploading..." : "Add Evidence"}
                        type={"btn-style-2"}
                        size={"btn-medium"}
                        className={"w-full justify-center"}
                    />
                </div>
            </Modal>



        </div>
    )
}
export default SubmitEvidenceForm;