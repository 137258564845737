import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  DistributionAndLaborReportDistributionDetailRow,
  DistributionAndLaborReportDistributionSummaryRow,
  DistributionAndLaborReportForADay,
  DistributionAndLaborReportTaskStatisticRow,
  DistributionAndLaborReportTaskSummaryRow,
  StaffMemberPunchInReport,
} from "controllers/reporting";

import {
  DataTableCell,
  NoWrap,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 7,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  smallerFooterCellStyle: {
    fontSize: 5,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 3,
  },
  totalFooterCellStyle: {
    fontSize: 7,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 3,
  },
  dataCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  completelyRoundedFooter: {
    borderRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface DailyLaborPdfDocumentProps {
  report: DistributionAndLaborReportForADay;
  businessName: string;
  snapshot?: boolean;
}

const DistributionAndLaborPdfReport = (props: DailyLaborPdfDocumentProps) => {
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} - ${moment(
        props.report.date
      )
        .add(1, "day")
        .format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Daily Distribution And Labor{" "}
            {props.snapshot ? "Snapshot" : "Report"} for {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>

          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Total Sales: {formatter.format(props.report.totalSales / 100)}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Number Of Customers: {props.report.totalCustomersNumberForTheDay}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Number Of Meals: {props.report.totalMealsNumberForTheDay}
          </Text>
        </View>
        <NoWrap>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: "SFProText-SFProTextBold",
                color: "#454545",
                fontSize: 14,
              }}
            >
              Task Summary
            </Text>
          </View>
          <Table data={props.report.taskSummaryData}>
            <TableHeader
              style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
            >
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Task
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Reg. Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Over. Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Double Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Salary
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Fringe Benefits
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Total
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  r.roleName
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  r.regularHours
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  r.overtimeHours
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  r.doubleHours
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  formatter.format(r.salary / 100)
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  formatter.format(r.fringeBenefits / 100)
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskSummaryRow) =>
                  formatter.format(r.total / 100)
                }
              />
            </TableBody>
            <TableFooter style={styles.totalFooter}>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                Total
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.taskSummaryTotal.regularHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.taskSummaryTotal.overtimeHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.taskSummaryTotal.doubleHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(props.report.taskSummaryTotal.salary / 100)}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.taskSummaryTotal.fringeBenefits / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(props.report.taskSummaryTotal.total / 100)}
              </TableCell>
            </TableFooter>
          </Table>
        </NoWrap>
        <NoWrap>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: "SFProText-SFProTextBold",
                color: "#454545",
                fontSize: 14,
              }}
            >
              Task Statistic
            </Text>
          </View>
          <Table data={props.report.taskStatisticData}>
            <TableHeader
              style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
            >
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Task
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Hours %
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor %
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor Cost
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Customer/Hour
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Meals/Hour
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Sales/Hour
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  r.roleName
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  r.totalHours
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfTotalHours).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  formatter.format(r.totalLabor / 100)
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfTotalLabor).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfSales).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.customersPerHour).toFixed(2)}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.mealsPerHour).toFixed(2)}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  formatter.format(r.salesPerHour / 100)
                }
              />
            </TableBody>
            <TableFooter style={styles.totalFooter}>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                Total
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.taskStatisticTotal.totalHours}
              </TableCell>
              <TableCell
                textAlign="center"
                style={styles.footerCellStyle}
              ></TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.taskStatisticTotal.totalLabor / 100
                )}
              </TableCell>
              <TableCell
                textAlign="center"
                style={styles.footerCellStyle}
              ></TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.taskStatisticTotal.percentageOfSales
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.taskStatisticTotal.customersPerHour
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.taskStatisticTotal.mealsPerHour
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.taskStatisticTotal.salesPerHour / 100
                )}
              </TableCell>
            </TableFooter>
          </Table>
        </NoWrap>
        <NoWrap>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: "SFProText-SFProTextBold",
                color: "#454545",
                fontSize: 14,
              }}
            >
              Department Statistic
            </Text>
          </View>
          <Table data={props.report.departmentStatisticData}>
            <TableHeader
              style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
            >
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Department
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Hours %
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor %
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Labor Cost
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Customer/Hour
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Meals/Hour
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Sales/Hour
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  r.departmentName
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  r.totalHours
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfTotalHours).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  formatter.format(r.totalLabor / 100)
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfTotalLabor).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.percentageOfSales).toFixed(2)}%`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.customersPerHour).toFixed(2)}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  `${Number(r.mealsPerHour).toFixed(2)}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r: DistributionAndLaborReportTaskStatisticRow) =>
                  formatter.format(r.salesPerHour / 100)
                }
              />
            </TableBody>
            <TableFooter style={styles.totalFooter}>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                Total
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.departmentStatisticTotal.totalHours}
              </TableCell>
              <TableCell
                textAlign="center"
                style={styles.footerCellStyle}
              ></TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.departmentStatisticTotal.totalLabor / 100
                )}
              </TableCell>
              <TableCell
                textAlign="center"
                style={styles.footerCellStyle}
              ></TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.departmentStatisticTotal.percentageOfSales
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.departmentStatisticTotal.customersPerHour
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.departmentStatisticTotal.mealsPerHour
                ).toFixed(2)}%`}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.departmentStatisticTotal.salesPerHour / 100
                )}
              </TableCell>
            </TableFooter>
          </Table>
        </NoWrap>
        <NoWrap>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: "SFProText-SFProTextBold",
                color: "#454545",
                fontSize: 14,
              }}
            >
              Distribution Summary
            </Text>
          </View>
          <Table data={props.report.distributionSummaryData}>
            <TableHeader
              style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
            >
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Department
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Reg. Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                $ Reg.
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Over. Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                $ Over.
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Double Hours
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                $ Double.
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                Other Paid
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                CPHP
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                PPHP
              </TableCell>
              <TableCell textAlign="center" style={styles.headerCellStyle}>
                % Sales
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => r.departmentName}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => r.regularHours}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => formatter.format(r.regularHoursTotal / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => r.overtimeHours}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => formatter.format(r.overtimeHoursTotal / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => r.doubleHours}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => formatter.format(r.doubleHoursTotal / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => formatter.format(r.otherPaid / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => Number(r.cphp).toFixed(2)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => formatter.format(r.pphp / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(
                  r: DistributionAndLaborReportDistributionSummaryRow
                ) => `${Number(r.percentageOfSales).toFixed(2)}%`}
              />
            </TableBody>
            <TableFooter style={styles.totalFooter}>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                Total
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionSummaryTotal.regularHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionSummaryTotal.regularHoursTotal / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionSummaryTotal.overtimeHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionSummaryTotal.overtimeHoursTotal / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionSummaryTotal.doubleHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionSummaryTotal.doubleHoursTotal / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionSummaryTotal.otherPaid / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {Number(props.report.distributionSummaryTotal.cphp).toFixed(2)}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionSummaryTotal.pphp / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.distributionSummaryTotal.percentageOfSales
                ).toFixed(2)}%`}
              </TableCell>
            </TableFooter>
          </Table>
        </NoWrap>
        <NoWrap>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: "SFProText-SFProTextBold",
                color: "#454545",
                fontSize: 14,
              }}
            >
              Distribution Details
            </Text>
          </View>
          {props.report.distributionDetailData.tables.map((table) => (
            <Table
              data={table.data}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <TableHeader
                style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
              >
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  {table.totals.departmentName}
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  Reg. Hours
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  $ Reg.
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  Over. Hours
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  $ Over.
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  Double Hours
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  $ Double.
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  Other Paid
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  CPHP
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  PPHP
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  % Sales
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => r.roleName}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => r.regularHours}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => formatter.format(r.regularHoursTotal / 100)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => r.overtimeHours}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => formatter.format(r.overtimeHoursTotal / 100)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => r.doubleHours}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => formatter.format(r.doubleHoursTotal / 100)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => formatter.format(r.otherPaid / 100)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => Number(r.cphp).toFixed(2)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => formatter.format(r.pphp / 100)}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(
                    r: DistributionAndLaborReportDistributionDetailRow
                  ) => `${Number(r.percentageOfSales).toFixed(2)}%`}
                />
              </TableBody>
              <TableFooter style={styles.totalFooter}>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  Total
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {table.totals.regularHours}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {formatter.format(table.totals.regularHoursTotal / 100)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {table.totals.overtimeHours}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {formatter.format(table.totals.overtimeHoursTotal / 100)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {table.totals.doubleHours}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {formatter.format(table.totals.doubleHoursTotal / 100)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {formatter.format(table.totals.otherPaid / 100)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {Number(table.totals.cphp).toFixed(2)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {formatter.format(table.totals.pphp / 100)}
                </TableCell>
                <TableCell textAlign="center" style={styles.footerCellStyle}>
                  {`${Number(table.totals.percentageOfSales).toFixed(2)}%`}
                </TableCell>
              </TableFooter>
            </Table>
          ))}
          <Table style={{ marginTop: 10, marginBottom: 10 }}>
            <TableFooter style={styles.completelyRoundedFooter}>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                Total
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionDetailData.total.regularHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionDetailData.total.regularHoursTotal /
                    100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionDetailData.total.overtimeHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionDetailData.total.overtimeHoursTotal /
                    100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {props.report.distributionDetailData.total.doubleHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionDetailData.total.doubleHoursTotal /
                    100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionDetailData.total.otherPaid / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {Number(props.report.distributionDetailData.total.cphp).toFixed(
                  2
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(
                  props.report.distributionDetailData.total.pphp / 100
                )}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(
                  props.report.distributionDetailData.total.percentageOfSales
                ).toFixed(2)}%`}
              </TableCell>
            </TableFooter>
          </Table>
        </NoWrap>

        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Detail Report
          </Text>
        </View>
        {props.report.detailReportData.data.map((roleReport) => (
          <View wrap={false} style={{ marginTop: 10, marginBottom: 10 }}>
            <>
              <Table>
                <TableHeader
                  style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                >
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    {roleReport.roleName}
                  </TableCell>
                </TableHeader>
              </Table>
              <Table>
                <TableHeader>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Date
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Time In
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Time Out
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Reg. Hours
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Over. Hours
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Double Hours
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Rate
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Salary
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Fringe Benefits
                  </TableCell>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Total
                  </TableCell>
                </TableHeader>
              </Table>
              <>
                {roleReport.data.map((staffMemberReport) => {
                  return (
                    <Table data={staffMemberReport.data}>
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          {staffMemberReport.fullName}
                        </TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            moment(r.date).format("DD-MMM")
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) => r.timeIn}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            r.timeOut
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            r.regularHours
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            r.overtimeHours
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            r.doubleHours
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            formatter.format(r.rate / 100)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            formatter.format(r.salary / 100)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            formatter.format(r.fringeBenefits / 100)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: StaffMemberPunchInReport) =>
                            formatter.format(r.total / 100)
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })}
                <Table>
                  <TableFooter style={styles.totalFooter}>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {roleReport.total.regularHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {roleReport.total.overtimeHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {roleReport.total.doubleHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {formatter.format(roleReport.total.salary / 100)}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {formatter.format(roleReport.total.fringeBenefits / 100)}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {formatter.format(roleReport.total.total / 100)}
                    </TableCell>
                  </TableFooter>
                </Table>
              </>
            </>
          </View>
        ))}
        <Table>
          <TableFooter style={styles.totalFooter}>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              Total
            </TableCell>
            <TableCell
              textAlign="center"
              style={styles.footerCellStyle}
            ></TableCell>
            <TableCell
              textAlign="center"
              style={styles.footerCellStyle}
            ></TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {props.report.detailReportData.total.regularHours}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {props.report.detailReportData.total.overtimeHours}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {props.report.detailReportData.total.doubleHours}
            </TableCell>
            <TableCell
              textAlign="center"
              style={styles.footerCellStyle}
            ></TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {formatter.format(
                props.report.detailReportData.total.salary / 100
              )}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {formatter.format(
                props.report.detailReportData.total.fringeBenefits / 100
              )}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {formatter.format(
                props.report.detailReportData.total.total / 100
              )}
            </TableCell>
          </TableFooter>
        </Table>
      </Page>
    </Document>
  );
};

export default DistributionAndLaborPdfReport;
