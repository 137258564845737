import _ from "lodash";

import isDev from "utils/currentEnv";

import firebase from "../config/firebase";
import { FirebaseMenuCategoryDoc } from "../types/menus";
import { FirebaseProductDoc, ProductDoc } from "../types/products";
import { isEqual } from "../utils/data";
import { saveFile } from "../utils/storage";

const db = firebase.firestore();
const productsCollection = db.collection("Products");
const menuCategoriesCollection = db.collection("MenuCategories");

export const performSoftDelete = async (
  products: FirebaseProductDoc[] | ProductDoc[],
  menuCategories: FirebaseMenuCategoryDoc[]
) => {
  const menuCategoriesToUpdate: { id: string; products: string[] }[] = [];
  const menuCategoryIdsToUpdate: string[] = [];

  const existingProducts = [];
  for (const product of products) {
    // Set deleted to true
    if (product.id && product.id.length > 0) {
      existingProducts.push({ id: product.id, deleted: true });
    }

    // Check all menu categories to see if this product is included
    menuCategories.forEach((category) => {
      // Check if id exists
      if (product.id) {
        // Found product id to remove from a menuCategory
        if (category.products.includes(product.id)) {
          if (menuCategoryIdsToUpdate.includes(category.id)) {
            const index = menuCategoriesToUpdate.findIndex(
              (doc) => doc.id === category.id
            );
            menuCategoriesToUpdate[index] = {
              id: category.id,
              products: menuCategoriesToUpdate[index].products.filter(
                (existingProduct) => existingProduct !== product.id
              ),
            };
          } else {
            menuCategoriesToUpdate.push({
              id: category.id,
              products: category.products.filter(
                (existingProduct) => existingProduct !== product.id
              ),
            });
            menuCategoryIdsToUpdate.push(category.id);
          }
        }
      }
    });
  }

  // Batch delete all the existing products
  const existingProductsChunk = _.chunk(existingProducts, 500);
  for await (const chunk of existingProductsChunk) {
    const batch = db.batch();
    chunk.forEach((product) => {
      const docRef = productsCollection.doc(product.id);
      batch.update(docRef, { deleted: product.deleted });
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log("ERROR Occured when deleting products: ", err);
      }
    }
  }

  // Batch update all the existing menu categories
  const menuCategoriesChunk = _.chunk(menuCategoriesToUpdate, 500);
  for await (const chunk of menuCategoriesChunk) {
    const batch = db.batch();
    chunk.forEach((menuCategory) => {
      const docRef = menuCategoriesCollection.doc(menuCategory.id);
      batch.update(docRef, { products: menuCategory.products });
    });

    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log("ERROR Occured when updating menuCategories: ", err);
      }
    }
  }
};

export const findProductNamesFromProductIds = (
  productIds: string[],
  products: FirebaseProductDoc[]
) => {
  return productIds.map((id) => {
    const index = (products || []).findIndex((product) => product.id === id);
    if (index !== -1) {
      return products[index].nameInternal;
    }
    // It shouldn't get here
    return id;
  });
};

export const findProductIdsFromProductNames = (
  productNames: string[],
  products: FirebaseProductDoc[]
) => {
  return productNames.map((name) => {
    const index = (products || []).findIndex(
      (product) => product.nameInternal === name
    );
    if (index !== -1) {
      return products[index].id;
    }
    // It shouldn't get here
    return name;
  });
};

export const generateUniquePlu = (allProductPlus: string[]) => {
  const sortedPlus = allProductPlus.sort(function (a, b) {
    return Number(a) - Number(b);
  });

  const uniquePlu = Number(sortedPlus[sortedPlus.length - 1]) + 1;
  return uniquePlu.toString();
};

export const saveData = async (
  newData: ProductDoc[],
  existingData: ProductDoc[],
  businessId: string,
  modifiersRef: { [T: string]: string },
  allProducts: FirebaseProductDoc[],
  allModifierOptions: FirebaseModifierOptionsDoc[],
  isEnterpriseLevel: boolean,
  plus: string[]
) => {
  const generatedPlus: string[] = [];
  // TODO: Add discounts

  // @ts-ignore
  const firebaseProducts: FirebaseProductDoc[] = newData
    .map((product, index) => ({ ...product, index: index }))
    .filter((product) => {
      const productToCheck = _.omit(product, "index");
      const foundItem = existingData.some((existingProduct) =>
        isEqual(existingProduct, productToCheck)
      );

      // Remove product documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((product, index) => {
      let uniquePlu = "";
      if (!product.plu) {
        if (plus.length > 0) {
          uniquePlu = plus[index];
        } else {
          const joinedPlus = ([] as string[]).concat.apply(plus, generatedPlus);
          const concatPlus = joinedPlus.concat(
            allProducts.map((product) => product.plu || "0"),
            allModifierOptions.map(
              (modifierOption) => modifierOption.plu || "0"
            )
          );
          uniquePlu = generateUniquePlu(concatPlus);
          generatedPlus.push(uniquePlu);
        }
      }

      return {
        index: product.index,
        alcohol: product.alcohol === "Yes" ? true : false,
        businessId: businessId,
        createdAt: new Date(),
        deleted: false,
        description: product.description || "",
        enabled: product.enabled === "Available" ? true : false,
        isMeal: product.isMeal === "Yes" ? true : false,
        id: product.id || "",
        plu: product.plu || uniquePlu,
        imageUrl: product.imageUrl || null,
        ingredients: [],
        ingredientsQuantity: [],
        modifiers: (product.modifiers || []).map(
          (modifierName) => modifiersRef[modifierName]
        ),
        nameExternal: product.nameExternal,
        nameInternal: product.nameInternal,
        popularity: 0,
        preparationInstructions: null,
        price:
          typeof product.price === "string"
            ? parseFloat(product.price) * 100
            : product.price * 100,
        suggestedPairings:
          findProductIdsFromProductNames(
            product.suggestedPairings || [],
            allProducts
          ) || [],
        autoSuggestedPairings:
          findProductIdsFromProductNames(
            product.autoSuggestedPairings || [],
            allProducts
          ) || [],
        tags: [],
        // @ts-ignore
        taxRate: parseFloat(product.taxRate) || null,
        type: product.type,
        subType: product.subType,
        updatedAt: new Date(),
        allergens: product.allergens || [],
        enterpriseUpdatedDocument: isEnterpriseLevel,
      };
    });

  const newProducts = [];
  const existingProducts = [];
  for await (const product of firebaseProducts) {
    // For existing products - just find the differences to update
    // which we can then batch write to the database
    if (product.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === product.id
      );
      const newDataWithPLU = newData.find((d) => d.plu === product.plu);
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[index]
        )
          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingData[index][key], newDataWithPLU[key]) &&
              Object.keys(product).includes(key)
          )
          .reduce(
            (acc, key) => ({
              ...acc,
              // @ts-ignore
              [key]: product[key],
              enterpriseUpdatedDocument: !!product?.enterpriseUpdatedDocument,
            }),
            {}
          );

        if (
          Object.keys(dataToUpdate).includes("imageUrl") &&
          product.imageUrl
        ) {
          if (!product.imageUrl.startsWith("https://")) {
            const fileUrl = await saveFile(
              `businesses/${businessId}/products/${product.id}/productImage`,
              product.imageUrl
            );
            if (fileUrl) {
              dataToUpdate["imageUrl"] = fileUrl;
            }
          }
        }

        existingProducts.push({ id: product.id, ...dataToUpdate });

        // Create a new product if we didn't find a matching one
      } else {
        const docRef = productsCollection.doc();
        product["id"] = docRef.id;
        // New image to add to firebase
        if (product.imageUrl) {
          if (!product.imageUrl.startsWith("https://")) {
            const fileUrl = await saveFile(
              `businesses/${businessId}/products/${docRef.id}/productImage`,
              product.imageUrl
            );
            if (fileUrl) {
              product["imageUrl"] = fileUrl;
            }
          }
        }
        const finalProduct = _.omit(product, "index");
        newProducts.push(finalProduct);
      }

      // For new product then create a new
      // document that we batch write to the database
    } else {
      const docRef = productsCollection.doc();
      product["id"] = docRef.id;
      // New image to add to firebase
      if (product.imageUrl) {
        if (!product.imageUrl.startsWith("https://")) {
          const fileUrl = await saveFile(
            `businesses/${businessId}/products/${docRef.id}/productImage`,
            product.imageUrl
          );
          if (fileUrl) {
            product["imageUrl"] = fileUrl;
          }
        }
      }
      const finalProduct = _.omit(product, "index");
      newProducts.push(finalProduct);
    }
  }

  // Batch write all the new products
  const newProductsChunk = _.chunk(newProducts, 500);
  for await (const chunk of newProductsChunk) {
    const batch = db.batch();
    chunk.forEach((product) => {
      const docRef = productsCollection.doc(product.id);
      batch.set(docRef, product);
    });
    await batch.commit();
  }

  // Batch write all the existing products
  const existingProductsChunk = _.chunk(existingProducts, 500);
  for await (const chunk of existingProductsChunk) {
    const batch = db.batch();
    chunk.forEach((product) => {
      const docRef = productsCollection.doc(product.id);
      batch.update(docRef, product);
    });
    await batch.commit();
  }

  return {
    success: true,
    generatedPlus,
  };
};
