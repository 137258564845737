import React, { ChangeEvent, useRef, useState } from "react";

import UploadImageIcon from "assets/upload-image.svg";

interface Props {
  handleFileChange: (image: string, fileName: string) => void;
  color: string;
  selectedValue: string;
}
const UploadFile = ({ handleFileChange, color, selectedValue }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>("");
  const handleBgImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      //@ts-ignore
      reader.fileName = e.target.files[0].name;
      reader.onload = async (event) => {
        if (event.target) {
          //@ts-ignore
          const fileName = new Date().getTime().toString();
          // @ts-ignore
          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = "";
          }
          const text = event.target.result;

          //@ts-ignore
          handleFileChange(text, fileName);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div
      className="upload-file-component"
      style={{ borderColor: color, color }}
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      {selectedValue ? (
        <img src={selectedValue} className="selected-image" />
      ) : (
        <>
          <img src={UploadImageIcon} />
          <div className="apply-font">Upload</div>
        </>
      )}
      <input ref={inputRef} type="file" hidden onChange={handleBgImageUpload} />
    </div>
  );
};

export default UploadFile;
