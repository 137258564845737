import { UpdatePrepItemDTO, useSetPrepIngredients, useUpdatePrepItem, usePrepItem } from "controllers/inventoryItemInfo";
import { RootState } from "model/store";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { detailColumns, detailInstructions, Ingredient, PrepItem, ingredientColumns, ingredientInstructions } from "./instructions";
import { useSelector } from "react-redux";
import { UpdateState } from "components/Table/HorizontalTable";

const prefix = "new__ingredient";

const usePrepItemDetail = (itemId: string) => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditingDetail, setIsEditingDetail] = useState(false);
  const [isEditingIngredients, setIsEditingIngredients] = useState(false);
  const [isEditingRecipe, setIsEditingRecipe] = useState(false);
  const [editedRecipe, setEditedRecipe] = useState("");
  useEffect(() => {
    if (!isEditingRecipe) setEditedRecipe("")
  }, [isEditingRecipe]);
  
  // call update with false to push, true to clear
  const [addedIds, updateAddedIngredients] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [prefix + curr.length, ...curr];
    },
    []
  );
  useEffect(() => {
    if (!isEditingIngredients) updateAddedIngredients(true);
  }, [isEditingIngredients]);
  const addIngredient = useCallback(() => {
    setIsEditingIngredients(true);
    updateAddedIngredients(false);
  }, []);

  const query = usePrepItem(businessId, itemId);
  const updateItem = useUpdatePrepItem(businessId);
  const setIngredients = useSetPrepIngredients(businessId);

  const itemDetail = useMemo(() => {
    if (!query.data) return null;
    const prepItem = query.data;
    return {
      uniqueId: prepItem.id,
      name: prepItem.name,
      primaryGroup: prepItem.primaryGroup?.id ?? null,
      secondaryGroup: prepItem.secondaryGroup?.id ?? null,
      ingredientUnitName: prepItem.ingredientUnitName,
      productionUnitName: prepItem.productionUnitName,
      conversion: prepItem.ingredientsPerProduct,
      location: prepItem.location?.id ?? null,
      isKey: prepItem.isKey,
      isActualized: prepItem.isActualized,
      shelfLifeHours: prepItem.shelfLifeHours,
      shelfLifeUnits: prepItem.shelfLifeUnits,
      yield: prepItem.yield,
      minPar: prepItem.minPar,
      maxPar: prepItem.maxPar,
      recipe: prepItem.recipe,
      theoreticalStock: prepItem.theoreticalStock,
    } as PrepItem
  }, [query.data]);

  const currIngredients = useMemo(() => (query.data?.ingredients ?? []).map((i: any) => ({
    amount: i.amount,
    uniqueId: i.id,
    item: i.id,
  } as Ingredient)), [query.data]);

  const addedIngredients = useMemo(() => addedIds.map((uniqueId: string) => ({
    uniqueId,
    amount: 1,
    item: null,
  } as Ingredient)), [addedIds]);
  const ingredients: Ingredient[] = useMemo(
    () => [...addedIngredients, ...currIngredients],
    [addedIngredients, currIngredients],
  );

  const updateDetail = useCallback((UpdateState: UpdateState) => {
    const updates = UpdateState[itemId]
    const updatedItem = {} as UpdatePrepItemDTO;
    Object.values(updates).forEach(({key, newValue}) => {
      switch (key) {
        case "name": updatedItem.name = newValue as string; break;
        case "primaryGroup": updatedItem.primaryGroupId = newValue as string; break;
        case "secondaryGroup": updatedItem.secondaryGroupId = newValue as string; break;
        case "productionUnitName": updatedItem.productionUnitName = newValue as string; break;
        case "conversion": updatedItem.ingredientsPerProduct = Number(newValue); break;
        case "location": updatedItem.locationId = newValue as string; break;
        case "isKey": updatedItem.isKey = newValue as boolean; break;
        case "isActualized": updatedItem.isActualized = newValue as boolean; break;
        case "shelfLifeHours": updatedItem.shelfLifeHours = Number(newValue); break;
        case "shelfLifeUnits": updatedItem.shelfLifeUnits = newValue as string; break;
        case "maxPar": updatedItem.maxPar = Number(newValue); break;
        case "minPar": updatedItem.minPar = Number(newValue); break;
        case "yield": updatedItem.yield = Number(newValue); break;
        default: console.warn("Discarding key", key);
      }
    });
    return updateItem.mutateAsync({itemId, updatedItem});
  }, [updateItem, itemId]);
  const updateRecipe = useCallback(async () => {
    const updatedItem = {recipe: editedRecipe} as UpdatePrepItemDTO;
    await updateItem.mutateAsync({itemId, updatedItem});
    setIsEditingRecipe(false);
  }, [updateItem, itemId, editedRecipe]);
  const updateIngredients = useCallback(async (updateState: UpdateState) => {
    const newIngredients = ingredients.map(({uniqueId, amount, item}) => {
      const update = updateState[uniqueId];
      const id = (update?.item?.newValue ?? item) as typeof item;
      if (!id) throw "No item selected";
      return {
        id,
        amount: (update?.amount?.newValue ?? amount) as typeof amount,
        isRaw: true,
      }
    });
    return setIngredients.mutateAsync({itemId, newIngredients});
  }, [ingredients, setIngredients, itemId]);
  const deleteIngredients = useCallback(async (ids: string[]) => {
    const newIngredients = ingredients.filter(({uniqueId}) => {
      if (uniqueId.startsWith(prefix)) return false;
      if (ids.includes(uniqueId)) return false;
      return true;
    }).map(i => ({
      id: i.item as string,
      amount: i.amount,
      isRaw: true,
    }));
    return setIngredients.mutateAsync({itemId, newIngredients});
  }, [ingredients, setIngredients, itemId]);

  return {
    itemDetail,
    detailColumns,
    detailInstructions,
    updateDetail,
    isEditingDetail,
    setIsEditingDetail,
    isEditingIngredients,
    setIsEditingIngredients,
    ingredients,
    ingredientColumns,
    ingredientInstructions,
    addIngredient,
    updateIngredients,
    deleteIngredients,
    isEditingRecipe,
    setIsEditingRecipe,
    setEditedRecipe,
    updateRecipe,
  };
};
export default usePrepItemDetail;
