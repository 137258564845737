import { Intent, Position, Toaster } from "@blueprintjs/core";
import axios from "axios";
import firebase, { firestore, storage } from "config/firebase";
import { AsYouType } from "libphonenumber-js";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { getObjectDiff } from "utils/data";

// @ts-ignore
import { StaffDoc } from "types/staff";

import { apiCall, baseUrl } from "./core";
import { generateBearerToken } from "./init";

export const tangoStaffApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/staff`,
});

tangoStaffApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const generateNewPincode = async (
  businessId: string,
  staffUID: string
) => {
  await tangoStaffApiClient.post("/update-pin", {
    staffUID,
    businessId,
  });
};

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

export const updateStaffMemberStatus = async (
  businessId: string,
  staffUID: string,
  status: StaffMemberStatus
) => {
  try {
    console.log({
      staffUID,
      businessId,
      status,
    });
    await tangoStaffApiClient.put("/update-status", {
      staffUID,
      businessId,
      status,
    });
  } catch (e: any) {
    console.log(e?.response?.data);
    toaster.show({
      message: e?.response?.data?.message ?? "Something went wrong here...",
      intent: Intent.DANGER,
    });
  }
};

export const updateStaffExternalId = async (
  businessId: string,
  staffUID: string,
  externalEmployeeId: string
) => {
  await tangoStaffApiClient.put("/update-external-employee-id", {
    staffUID,
    businessId,
    externalEmployeeId,
  });
};

export type DepartmentId = "foh" | "boh";

export const DEPARTMENTS = {
  foh: {
    id: "foh",
    title: "Front Of House",
    shortTitle: "FOH",
  },
  boh: {
    id: "boh",
    title: "Back Of House",
    shortTitle: "BOH",
  },
};

export const updateShiftByScheduleId = async (
  updateObject: any,
  shiftId: string,
  draftScheduleId: string
) => {
  const scheduleSn = await firestore
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .get();
  const schedule: TangoSchedule = scheduleSn.data() as TangoSchedule;
  console.log("updateObject", updateObject);
  const newShifts = schedule.shifts.map((sh) => {
    if (sh.id === shiftId) {
      return { ...sh, ...updateObject } as TangoShift;
    }
    return sh;
  });
  await firestore
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .update({ shifts: newShifts });
};

export const createDraftBasedOnDuplicateWithSingleShiftUpdate = async (
  updateObject: any,
  shiftId: string,
  duplicateSchedule: TangoSchedule
) => {
  const schedule: TangoSchedule = duplicateSchedule;
  const newShifts = schedule.shifts.map((sh) => {
    if (sh.id === shiftId) {
      return { ...sh, ...updateObject } as TangoShift;
    }
    return sh;
  });
  const newDradftSchedule = firestore.collection("DraftSchedule").doc();
  await firestore
    .collection("DraftSchedule")
    .doc(newDradftSchedule.id)
    .set({
      ...schedule,
      shifts: newShifts,
      isDraft: true,
      isDuplicate: null,
      id: newDradftSchedule.id,
    });
};

export const deleteShiftAndCreateDraftBasedOnDuplicate = async (
  shiftId: string,
  duplicateSchedule: TangoSchedule
) => {
  const newDradftSchedule = firestore.collection("DraftSchedule").doc();
  const schedule = duplicateSchedule;
  const newShifts = schedule.shifts.filter((sh) => sh.id !== shiftId);
  await firestore
    .collection("DraftSchedule")
    .doc(newDradftSchedule.id)
    .set({
      ...schedule,
      shifts: newShifts,
      isDraft: true,
      isDuplicate: null,
      id: newDradftSchedule.id,
    });
};

export const createDraftScheduleFromDuplicateSchedule = async (
  duplicateSchedule: TangoSchedule
) => {
  const newDradftSchedule = firestore.collection("DraftSchedule").doc();
  const schedule = duplicateSchedule;
  await firestore
    .collection("DraftSchedule")
    .doc(newDradftSchedule.id)
    .set({
      ...schedule,
      isDraft: true,
      isDuplicate: null,
      id: newDradftSchedule.id,
    });
  return newDradftSchedule.id;
};

export const deleteShift = async (shiftId: string, draftScheduleId: string) => {
  const scheduleSn = await firestore
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .get();
  const schedule: TangoSchedule = scheduleSn.data() as TangoSchedule;
  const newShifts = schedule.shifts.filter((sh) => sh.id !== shiftId);
  await firestore
    .collection("DraftSchedule")
    .doc(draftScheduleId)
    .update({ shifts: newShifts });
};

export const addStaffMember = async (
  businessId: string,
  isAdmin: boolean,
  primaryRole: string,
  pinCode: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  payRate: string
) => {
  try {
    const staffMember = {
      businessId,
      isAdmin,
      primaryRole,
      pinCode,
      phoneNumber,
      firstName,
      lastName,
      payRate,
    };
    console.log("staffMember", staffMember);
    const res = await axios.post(apiCall("saveStaffMember"), {
      staffMember,
    });
    console.log("Res add staff memnber", res.data);
    return res;
  } catch (e) {
    console.log("error adding staff member", e);
    return { success: false };
  }
};

export const updateStaffPayRate = async (
  staffId: string,
  payRates: { roleId: string | null; role: string | null; amount: number }[]
) => {
  await firestore
    .collection("Staff")
    .doc(staffId)
    .update({ payRates: payRates });
};

export const createShiftType = async (
  businessId: string,
  name: string,
  mainColor: string,
  backgroundColor: string
) => {
  await firestore
    .collection("BusinessSettings")
    .doc(businessId)
    .update({
      shiftTypes: firebase.firestore.FieldValue.arrayUnion({
        id: uuidv4(),
        mainColor,
        backgroundColor,
        name,
        deleted: false,
      }),
    });
};

export const deleteShiftType = async (businessId: string, id: string) => {
  const businessSettingsSN = await firestore
    .collection("BusinessSettings")
    .doc(businessId)
    .get();
  if (businessSettingsSN.exists) {
    const businessSettings: TangoBusinessSettings =
      businessSettingsSN.data() as TangoBusinessSettings;
    const shiftTypes = businessSettings.shiftTypes;
    const newShiftTypes = shiftTypes.map((shiftType) =>
      shiftType.id === id ? { ...shiftType, deleted: true } : shiftType
    );
    await firestore.collection("BusinessSettings").doc(businessId).update({
      shiftTypes: newShiftTypes,
    });
  }
};

export const saveStaffToFirebaseStorage = async (id: string, file: File) => {
  try {
    const path = `Staff/${id}/${file.name}`;
    await storage.ref(path).put(file);
    const url = await storage.ref(path).getDownloadURL();

    return url;
  } catch (err) {
    console.log("Err: err");
  }
};

const convertRoleTitleToRoleKey = (
  title: string,
  jobFunc: { [T: string]: any }
) => _.keys(jobFunc).find((key) => jobFunc[key]?.title === title);
// if (jobFunc && businessSettings && businessSettings.jobFunctions) {
//     const funcs = {...jobFunc, ...businessSettings.jobFunctions}
//     return _.keys(funcs).find(key => funcs[key]?.title === title)
// }
// if (businessSettings && businessSettings.jobFunctions) {
//     return _.keys(businessSettings.jobFunctions).find(key => businessSettings.jobFunctions[key]?.title === title)
// }
// if (jobFunc) {
//     return _.keys(jobFunc).find(key => jobFunc[key]?.title === title)
// }
// return ''

export const convertStaffDataToDBStaffMember = (
  s: any,
  jobFunc: { [T: string]: any }
): StaffMember => {
  const aYT = new AsYouType("US");
  aYT.input(s.phoneNumber);
  const phoneNumber = aYT.getNumber()?.format("E.164");
  return {
    id: s.id,
    contact: {
      firstName: s.firstName,
      lastName: s.lastName,
      phone: phoneNumber || "",
      email: s.emailAddress,
    },
    emergencyContact: {
      phone: s.emergencyContactPhone,
      name: s.emergencyContactName,
      relationship: s.emergencyContactRelationship,
    },
    businessId: s.businessId,
    primaryRole: convertRoleTitleToRoleKey(s.primaryRole, jobFunc) || null,
    secondaryRoles: s.secondaryRoles
      .map((val: string) => convertRoleTitleToRoleKey(val, jobFunc))
      .filter((x: string | undefined) => !!x),
    imageUrl: s.imageUrl,
    isAdmin: s.admin === "True",
    pinCode: Number(s.pinCode),
    // TODO: Add these to the schema
    ssnOrSin: s.SINOrSSN,
    medicalCard: s.medicalCard,
    i9: s.I9,
    w4: s.W4,
    payRates: s.payRates,
    createdAt: s.createdAt || new Date(),
    deleted: false,
    privileges: [],
    updatedAt: s.updatedAt || new Date(),
    isBusinessOwner: false,
    uid: s.uid || "",
    status: s.status || null,
    corporateAccess: !!s.corporateAccess,
    accountId: s.accountId || null,
  };
};

export const updateStaffMember = async (
  tableSm: StaffDoc,
  staff: StaffMember[],
  jobFunc: { [T: string]: any },
  businessId: string
) => {
  const diffKeysToOmit = [
    "uid",
    "id",
    "staffMemberCopyId",
    "businessId",
    "createdAt",
    "deleted",
    "privileges",
    "updatedAt",
    "upcomingEvents",
    "pendingOnBoarding",
    "isBusinessOwner",
    "ssnOrSin",
    "medicalCard",
    "imageUrl",
  ];
  const convertedStaffFromTable = convertStaffDataToDBStaffMember(
    tableSm,
    jobFunc
  );
  const existingStaff = staff.find(
    (sm) => sm.id === tableSm.id && sm.businessId === businessId
  );
  const uid = tableSm.id;
  const id = existingStaff?.staffMemberCopyId;

  if (id && uid) {
    const difference = getObjectDiff(
      convertedStaffFromTable,
      existingStaff
    ).filter((diffKey) => !diffKeysToOmit.includes(diffKey));
    const staffMemberUpdate: any = {};

    for await (const key of difference) {
      if (
        key !== "contact" &&
        key !== "i9" &&
        key !== "w4" &&
        key !== "emergencyContact"
      ) {
        staffMemberUpdate[key] = convertedStaffFromTable[key];
      } else if (key === "i9" || key === "w4") {
        // Save forms to firebase storage and get the url
        if (convertedStaffFromTable[key]) {
          if (convertedStaffFromTable[key]) {
            try {
              const url = await saveStaffToFirebaseStorage(
                id,
                convertedStaffFromTable[key] as unknown as File
              );
              staffMemberUpdate[key] = url;
            } catch (err) {
              console.log("ERR: Failed to save to firebase storage");
            }
          }
        }
      } else if (key === "contact") {
        if (
          convertedStaffFromTable?.contact?.firstName &&
          convertedStaffFromTable?.contact?.firstName !==
            existingStaff?.contact?.firstName
        ) {
          staffMemberUpdate.contact = {
            ...existingStaff?.contact,
            firstName: convertedStaffFromTable?.contact?.firstName,
          };
        }
        if (
          convertedStaffFromTable?.contact?.lastName &&
          convertedStaffFromTable?.contact?.lastName !==
            existingStaff?.contact?.lastName
        ) {
          staffMemberUpdate.contact = staffMemberUpdate.contact
            ? {
                ...staffMemberUpdate?.contact,
                lastName: convertedStaffFromTable?.contact?.lastName,
              }
            : {
                ...existingStaff?.contact,
                lastName: convertedStaffFromTable?.contact?.lastName,
              };
        }
      } else if (key === "emergencyContact") {
        if (
          convertedStaffFromTable?.emergencyContact?.name &&
          convertedStaffFromTable?.emergencyContact?.name !==
            existingStaff?.emergencyContact?.name
        ) {
          staffMemberUpdate.emergencyContact = {
            ...staffMemberUpdate?.emergencyContact,
            name: convertedStaffFromTable?.emergencyContact?.name,
          };
        } else if (
          convertedStaffFromTable?.emergencyContact?.phone &&
          convertedStaffFromTable?.emergencyContact?.phone !==
            existingStaff?.emergencyContact?.phone
        ) {
          staffMemberUpdate.emergencyContact = {
            ...staffMemberUpdate?.emergencyContact,
            phone: convertedStaffFromTable?.emergencyContact?.phone,
          };
        } else if (
          convertedStaffFromTable?.emergencyContact?.relationship &&
          convertedStaffFromTable?.emergencyContact?.relationship !==
            existingStaff?.emergencyContact?.relationship
        ) {
          staffMemberUpdate.emergencyContact = {
            ...staffMemberUpdate?.emergencyContact,
            relationship:
              convertedStaffFromTable?.emergencyContact?.relationship,
          };
        }
      }
    }

    if (
      convertedStaffFromTable?.contact?.email &&
      convertedStaffFromTable?.contact?.email !== existingStaff?.contact?.email
    ) {
      await axios.post(apiCall("updateStaffEmail"), {
        uid: uid,
        id: id,
        email: convertedStaffFromTable?.contact?.email,
      });
    }
    if (
      convertedStaffFromTable?.contact?.phone &&
      convertedStaffFromTable?.contact?.phone !== existingStaff?.contact?.phone
    ) {
      console.log(
        "convertedStaffFromTable?.contact?.phone",
        convertedStaffFromTable?.contact?.phone
      );
      await axios.post(apiCall("updateStaffPhone"), {
        uid: uid,
        id: id,
        phoneNumber: convertedStaffFromTable?.contact?.phone,
      });
    }
    console.log("staffMemberUpdate", staffMemberUpdate);
    console.log("difference", difference);
    await firestore
      .collection("Staff")
      .doc(id)
      .update({ ...staffMemberUpdate, updatedAt: new Date() });
  }
};

export const assignShiftToAStaffMember = async (
  staffId: string,
  shiftId: string,
  draftScheduleId: string
): Promise<Result> => {
  try {
    console.log("draftScheduleId", draftScheduleId);
    const scheduleSN = await firestore
      .collection("DraftSchedule")
      .doc(draftScheduleId)
      .get();
    const schedule: TangoSchedule = scheduleSN.data() as TangoSchedule;
    console.log("schedule", schedule);
    const scheduleShifts: TangoShift[] = schedule.shifts;
    const updatedShifts = scheduleShifts.map((shift) => {
      if (shift.id === shiftId) {
        return { ...shift, staffId };
      }
      return shift;
    });
    await firestore
      .collection("DraftSchedule")
      .doc(draftScheduleId)
      .update({ shifts: updatedShifts });
    return { success: true };
  } catch (e) {
    console.log("error while assigning a shift to staff member", e);
    // @ts-ignore
    return { success: false, error: e.message };
  }
};
