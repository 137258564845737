import React from "react";

import Sidebar from "components/Sidebar";

import { cloudRoutes } from "../routes";

import { Navigate, Route, Routes } from "react-router-dom";
import Spinner from "components/Spinner";
import { CloudFiles } from "./CloudFiles";
import { TrashedFiles } from "./TrashedFiles";
import Layout from "views/Productivity/Layout";





const DocumentsStack = () => {
  return (
    <Layout>

      <div className="absolute top-[63px] bottom-0 w-full">
        <div className="flex h-full">
          <div
            className={"border-r border-grey-1 border-t-0 border-b-0"}
            style={{ width: "10%", borderRightStyle: "solid" }}
          >
            <Sidebar routes={cloudRoutes} />
          </div>
          <Routes>
            <Route
              path="files/*"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <CloudFiles />
                </React.Suspense>
              }
            />
            {/* <Route
            path="starred/*"
            element={
              <React.Suspense fallback={<Spinner />}>
                <CloudFiles />
              </React.Suspense>
            }
          /> */}
            <Route
              path="trashed/*"
              element={
                <React.Suspense fallback={<Spinner />}>
                  <TrashedFiles />
                </React.Suspense>
              }
            />
            <Route path="*" element={<Navigate replace to="files" />} />
          </Routes>
        </div>
      </div>
    </Layout>

  )

};

export default DocumentsStack;
