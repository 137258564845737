import { Icon } from "@blueprintjs/core";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

interface Props {
  options: string[];
  updateChoice: (updatedChoices: string[]) => void;
  color: string;
}
const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const CorrectOrder = ({ options, updateChoice, color }: Props) => {
  return (
    <div className="multiple-choice-container">
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          const items = reorder(
            options,
            result.source.index,
            result.destination.index
          );
          updateChoice(items);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((item, index) => (
                <Draggable key={item + index} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={index}
                      className={`single-choice-container active`}
                    >
                      <div className="key-bind">{index + 1}</div>
                      <div>{item}</div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default CorrectOrder;
