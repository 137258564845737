import tangoComponents from "@tangopay/tango-ui-library";
import { useInventoryInfo } from "controllers/inventoryInfo";
import { useVendor } from "controllers/vendors";
import { UnitQuantityDTO, useRawItemsInfo } from "controllers/inventoryItemInfo";
import { RootState } from "model/store";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const { Dropdown } = tangoComponents;

// safe to call these anywhere, react-query will
// deduplicate it for us
const useInfo = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const info = useInventoryInfo(businessId);
    return info?.data ?? null;
}
const useRawItems = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const info = useRawItemsInfo(businessId);
    return (info?.data ?? []) as {
        name: string;
        id: string;
        defaultCost: number;
        ingredientUnitName: string;
    }[];
}
const useVendorInfo = (vendorId: string) => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const info = useVendor(businessId, vendorId)
    return info.data ?? {
        itemsSold: [],
        vendor: null,
    };
}

type ViewProps<T> = {
    fullObject: T;
}

type EditProps<T> = {
    fullObject: T
    onChange?: (newValue: T) => unknown;
}

type RawIngredientItem = {
    amount: number;
    item: string | null;
};

const RawIngredientView = <I extends RawIngredientItem>(props: ViewProps<I>) => {
    const rawItems = useRawItems();

    const curr = props.fullObject.item;
    const selection = useMemo(
        () => rawItems.find(({ id }) => id == curr),
        [rawItems, curr],
    );
    return <div>{selection?.name}</div>;
};
const RawIngredientEdit = <I extends RawIngredientItem>(props: EditProps<I>) => {
    const rawItems = useRawItems();
    const options = useMemo(() => rawItems.map(i => ({
        label: i.name,
        value: i.id,
    })), [rawItems]);

    const curr = props.fullObject.item;
    const selection = options.find(({ value }) => value == curr);
    const handler = useCallback(
        ({ value }) => {
            props.onChange?.({
                ...props.fullObject,
                item: value,
            });
        },
        [props.onChange, props.fullObject]
    );
    return (
        <Dropdown
            options={options}
            value={selection}
            onChange={handler}
            size="small"
            placeholder="Select an item..."
        />
    );
};
const RawIngredientCostView = <I extends RawIngredientItem>(props: ViewProps<I>) => {
    const rawItems = useRawItems();
    const curr = props.fullObject.item;
    const selection = useMemo(
        () => rawItems.find(({ id }) => id == curr),
        [rawItems, curr],
    );
    const cents = Math.round((selection?.defaultCost ?? 0) * props.fullObject.amount);
    return <div>{(cents / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    })}</div>;
};
const RawIngredientUnitView = <I extends RawIngredientItem>(props: ViewProps<I>) => {
    const rawItems = useRawItems();
    const curr = props.fullObject.item;
    const selection = useMemo(
        () => rawItems.find(({ id }) => id == curr),
        [rawItems, curr],
    );
    return <div>{selection?.ingredientUnitName ?? " - "}</div>;
};
type GroupedItem = {
    primaryGroup: string | null;
    secondaryGroup: string | null;
};

const PrimaryGroupView = <I extends GroupedItem>(props: ViewProps<I>) => {
    const info = useInfo();
    const curr = props.fullObject.primaryGroup;
    const primaryGroups = info?.primaryGroups ?? [];
    const selection = useMemo(
        () => primaryGroups.find(({ id }) => id == curr),
        [primaryGroups, curr],
    );
    return <div>{selection?.name}</div>;
};
const PrimaryGroupEdit = <I extends GroupedItem>(props: EditProps<I>) => {
    const info = useInfo();
    const curr = props.fullObject.primaryGroup;
    const options = useMemo(() => {
        const primaryGroups = info?.primaryGroups ?? [];
        return primaryGroups.map((primaryGroup) => ({
            label: primaryGroup.name,
            value: primaryGroup.id,
        }));
    }, [info?.primaryGroups]);
    const selection = options.find(({ value }) => value == curr)
    const handler = useCallback(
        ({ value }) => {
            props.onChange?.({
                ...props.fullObject,
                primaryGroup: value,
                secondaryGroup: null,
            });
        },
        [props.onChange, props.fullObject]
    );
    return (
        <Dropdown
            options={options}
            value={selection}
            onChange={handler}
            size="small"
            placeholder="Select a group..."
        />
    );
};

const SecondaryGroupView = <I extends GroupedItem>(props: ViewProps<I>) => {
    const info = useInfo();
    const currPrimary = props.fullObject.primaryGroup;
    const curr = props.fullObject.secondaryGroup;
    const secondaryGroups = info?.primaryGroups.find(({ id }) => currPrimary === id)?.secondaryGroups ?? [];

    const selection = useMemo(
        () => secondaryGroups.find(({ id }) => id == curr),
        [secondaryGroups, curr],
    );
    return <div>{selection?.name}</div>;
};
const SecondaryGroupEdit = <I extends GroupedItem>(props: EditProps<I>) => {
    const currPrimary = props.fullObject.primaryGroup;
    const curr = props.fullObject.secondaryGroup;
    const info = useInfo();
    const options = useMemo(() => {
        const secondaryGroups =
            info?.primaryGroups.find(({ id }) => currPrimary === id)?.secondaryGroups ??
            [];
        return secondaryGroups.map((secondaryGroup) => ({
            label: secondaryGroup.name,
            value: secondaryGroup.id,
        }));
    }, [currPrimary, info?.primaryGroups]);
    const selection = options.find(({ value }) => value == curr);
    const handler = useCallback(
        ({ value }) => {
            props.onChange?.({
                ...props.fullObject,
                secondaryGroup: value,
            });
        },
        [props.onChange, props.fullObject]
    );
    return (
        <Dropdown
            options={options}
            value={selection}
            onChange={handler}
            size="small"
            placeholder={options.length ? "Select a group..." : "No groups available"}
        />
    );
};

type LocatedItem = {
    location: string | null;
};

const LocationView = <I extends LocatedItem>(props: ViewProps<I>) => {
    const info = useInfo();
    const locations = info?.locations ?? [];
    const curr = props.fullObject.location;
    const selection = useMemo(
        () => locations.find(({ id }) => id == curr),
        [locations, curr],
    );
    return <div>{selection?.name}</div>;
};
const LocationEdit = <I extends LocatedItem>(props: EditProps<I>) => {
    const info = useInfo();
    const curr = props.fullObject.location;
    const options = useMemo(() => {
        const locations = info?.locations ?? [];
        return locations.map((loc) => ({
            label: loc.name,
            value: loc.id,
        }));
    }, [curr, info?.locations]);
    const selection = options.find(({ value }) => value == curr);
    const handler = useCallback(
        ({ value }) => {
            props.onChange?.({
                ...props.fullObject,
                location: value,
            });
        },
        [props.onChange, props.fullObject]
    );
    return (
        <Dropdown
            options={options}
            value={selection}
            onChange={handler}
            size="small"
            placeholder="Select a location..."
        />
    );
};

type YieldItem = {
    yield: number;
    productionUnitName: string;
};

const YieldView = <I extends YieldItem>(props: { fullObject: I }) => {
    const count = props.fullObject.yield;
    const unit = props.fullObject.productionUnitName;
    return <div>{count} {unit}</div>
}

const YieldEdit = <I extends YieldItem>(props: EditProps<I>) => {
    const count = props.fullObject.yield;
    const unit = props.fullObject.productionUnitName;
    const handle = useCallback(
        (e) => props.onChange?.({
            ...props.fullObject,
            yield: Number(e.target.value),
        }),
        [props.fullObject, props.onChange],
    )

    return <div>
        <input
            type="number"
            value={count}
            onChange={handle}
            className={"border-0 text-grey-3"}
        /> {unit}
    </div>;
}
type TimeUnit = "hours" | "days" | "weeks";
type ShelfLifeItem = {
    shelfLifeHours: number;
    shelfLifeUnits: TimeUnit;
};
const timeOpts = [
    { label: "Hours", value: "hours" },
    { label: "Days", value: "days" },
    { label: "Weeks", value: "weeks" },
];
const hourCounts = {
    hours: 1,
    days: 24,
    weeks: (24 * 7),
};

const timeSingles = {
    hours: "Hour",
    days: "Day",
    weeks: "Week",
}
const timePlurals = {
    hours: "Hours",
    days: "Days",
    weeks: "Weeks",
}

const ShelfLifeView = <I extends ShelfLifeItem>(props: ViewProps<I>) => {
    const hours = props.fullObject.shelfLifeHours;
    const unit = props.fullObject.shelfLifeUnits;
    const divisor = hourCounts[unit];
    const count = Math.floor(hours / divisor)
    const unitName = (count == 1 ? timeSingles : timePlurals)[unit];
    return <div>{count} {unitName}</div>
}
const ShelfLifeEdit = <I extends ShelfLifeItem>(props: EditProps<I>) => {
    const count = props.fullObject.shelfLifeHours;
    const unit = props.fullObject.shelfLifeUnits;
    const divisor = hourCounts[unit];
    const rounded = Math.floor(count / divisor);
    const handleNum = useCallback(
        (e) => props.onChange?.({
            ...props.fullObject,
            shelfLifeHours: divisor * Number(e.target.value),
        }),
        [props.fullObject, props.onChange],
    );

    const selection = timeOpts.find(o => o.value == unit);
    const handleSel = useCallback(
        (e) => props.onChange?.({
            ...props.fullObject,
            shelfLifeUnits: e.value as TimeUnit,
        }),
        [props.fullObject, props.onChange],
    );

    return <div>
        <input
            type="number"
            value={rounded}
            onChange={handleNum}
            className={"border-0 text-grey-3"}
        />
        <Dropdown
            value={selection}
            options={timeOpts}
            onChange={handleSel}
            size="small"
        />
    </div>;
}

type RecipeItem = {
    ingredients: unknown[];
    recipe: string;
}


// class whitespace-nowrap isn't working
const noWrap: React.CSSProperties = {
    whiteSpace: "nowrap"
};

const RecipeWarningView = <I extends RecipeItem>(props: { fullObject: I }) => {
    const {
        fullObject: { recipe, ingredients },
    } = props;
    return (
        <div className={"flex"}>
            {!recipe &&
                <div
                    style={noWrap}
                    className={
                        "h-4 bg-error-red-hover border border-solid border-error-red w-fit rounded-full px-3 text-tiny text-white font-lato-bold flex items-center mr-2"
                    }
                >
                    Missing Instructions
                </div>
            }
            {!ingredients?.length &&
                <div
                    style={noWrap}
                    className={
                        "h-4 bg-error-red-hover border border-solid border-error-red w-fit rounded-full px-3 text-tiny text-white font-lato-bold flex items-center"
                    }
                >
                    Missing Recipe
                </div>
            }
        </div>
    );
}

type StockedItem = {
    theoreticalStock: UnitQuantityDTO[];
    minPar: number;
    maxPar: number;
    productionUnitName: string;
};

const MinParView = <I extends StockedItem>(props: { fullObject: I }) => {
    const { productionUnitName, minPar } = props.fullObject;
    return <div>{minPar} {productionUnitName}</div>
}
const MinParEdit = <I extends StockedItem>(props: { fullObject: I, onChange?: (i: I) => unknown }) => {
    const { productionUnitName, minPar } = props.fullObject;
    const handleNum = useCallback(
        (e) => props.onChange?.({
            ...props.fullObject,
            minPar: Number(e.target.value),
        }),
        [props.fullObject, props.onChange],
    );
    return <div>
        <input
            type="number"
            value={minPar}
            onChange={handleNum}
            className={"border-0 text-grey-3"}
        />
        {productionUnitName}
    </div>;
}

const MaxParView = <I extends StockedItem>(props: { fullObject: I }) => {
    const { productionUnitName, maxPar } = props.fullObject;
    return <div>{maxPar} {productionUnitName}</div>
}
const MaxParEdit = <I extends StockedItem>(props: { fullObject: I, onChange?: (i: I) => unknown }) => {
    const { productionUnitName, maxPar } = props.fullObject;
    const handleNum = useCallback(
        (e) => props.onChange?.({
            ...props.fullObject,
            maxPar: Number(e.target.value),
        }),
        [props.fullObject, props.onChange],
    );
    return <div>
        <input
            type="number"
            value={maxPar}
            onChange={handleNum}
            className={"border-0 text-grey-3"}
        />
        {productionUnitName}
    </div>;
}

const StockView = <I extends StockedItem>(props: { fullObject: I }) => {
    const { theoreticalStock, productionUnitName } = props.fullObject;
    // assume production unit is the first in the array
    let divisor = 1;
    let amount = 0;
    theoreticalStock.forEach((value) => {
        amount += (value.count / divisor)
        divisor *= value.multiplier;
    })
    return <div>{`${amount.toFixed(2)} ${productionUnitName}`}</div>
}

// INSTRUCTIONS

export const primaryGroupInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: PrimaryGroupView,
    editComponent: PrimaryGroupEdit,
};

export const secondaryGroupInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: SecondaryGroupView,
    editComponent: SecondaryGroupEdit,
};

export const locationInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: LocationView,
    editComponent: LocationEdit,
};
export const yieldInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: YieldView,
    editComponent: YieldEdit,
};
export const shelfLifeInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: ShelfLifeView,
    editComponent: ShelfLifeEdit,
};
export const recipeWarningInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: RecipeWarningView,
    editComponent: null,
    readOnly: true,
};
export const rawIngredientInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: RawIngredientView,
    editComponent: RawIngredientEdit,
};
export const rawIngredientCostInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: RawIngredientCostView,
    editComponent: null,
    readOnly: true,
};
export const rawIngredientUnitInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: RawIngredientUnitView,
    editComponent: null,
    readOnly: true,
};
export const minParInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: MinParView,
    editComponent: MinParEdit,
};
export const maxParInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: MaxParView,
    editComponent: MaxParEdit,
};
export const stockInstruction = {
    type: "complex-custom" as "complex-custom",
    viewComponent: StockView,
    editComponent: null,
    readonly: true,
};