import ActionType from "./actionTypes";

export function receiveBusinessSettings(
  businessSettings: TangoBusinessSettings
) {
  return {
    type: ActionType.RECEIVE_BUSINESS_SETTINGS,
    businessSettings,
  };
}
