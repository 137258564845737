import {
  Button,
  HTMLSelect,
  InputGroup,
  Intent,
  Position,
  Toaster,
} from "@blueprintjs/core";
import firebase from "config/firebase";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import {
  sendVerificationToEmail,
  sendVerificationToPhoneNumber,
} from "controllers/auth";

import Box from "components/Box";

import "./style.css";

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const SignIn = () => {
  const [countryCode, setCountryCode] = useState<SupportedCountryCodes>("+1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("response", response);
        },
      }
    );
  }, []);

  const [loading, setLoading] = useState(false);

  const sendPhoneNumberVerificationCode = useCallback(async () => {
    try {
      if (phoneNumber) {
        setLoading(true);
        const res = await sendVerificationToPhoneNumber(
          `${countryCode}${phoneNumber}`
        );
        setLoading(false);
        if (res.success) {
          return navigate("/check-verification", {
            state: {
              verificationType: "phone",
              phoneNumber: `${countryCode}${phoneNumber}`,
            },
          });
        }
        if (res.notFound) {
          toaster.show({
            message:
              "Whoops! It looks like there is no user with that phone number in the system. Please contact your manager.",
            intent: Intent.DANGER,
          });
        } else {
          toaster.show({
            message: res?.message ?? "Something went wrong here...",
            intent: Intent.DANGER,
          });
        }
      } else {
        toaster.show({
          message: "Please enter a valid phone number",
          intent: Intent.DANGER,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toaster.show({
        message: error?.message ?? "Something went wrong here...",
        intent: Intent.DANGER,
      });
      console.log("e", error);
    }
  }, [countryCode, phoneNumber]);

  const sendEmailVerification = useCallback(async () => {
    try {
      if (email && emailIsValid(email)) {
        setLoading(true);
        const res = await sendVerificationToEmail(email);
        setLoading(false);
        if (res.success) {
          return navigate("/check-verification", {
            state: { verificationType: "email", email },
          });
        }
        if (res.notFound) {
          toaster.show({
            message:
              "Whoops! It looks like there is no user with that email address in the system. Please contact your manager.",
            intent: Intent.DANGER,
          });
        } else {
          toaster.show({
            message: res?.message ?? "Something went wrong here...",
            intent: Intent.DANGER,
          });
        }
      } else {
        toaster.show({
          message: "Please enter a valid email.",
          intent: Intent.DANGER,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toaster.show({
        message: error?.message ?? "Something went wrong here...",
        intent: Intent.DANGER,
      });
      console.log("e", error);
    }
  }, [email]);

  const sendVerificationCode = async () => {
    try {
      setLoading(true);
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(
          `${countryCode}${phoneNumber}`,
          (window as any).recaptchaVerifier
        );
      (window as any).confirmationResult = confirmation;
      setLoading(false);
      navigate("/check-verification");
    } catch (error) {
      console.log("e", error);
    }
  };

  const signInWithEmailAndPassword = async () => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      alert("Something went wrong");
      console.log("e", error);
    }
  };

  const renderPhoneAuth = () => (
    <Box className="sign-up-form">
      <img src="/static/media/tango-icon.acac10f7.svg" className="absolute w-6 right-6 top-6" />
      <div className=" text-base font-lato-black text-white">Login</div>
      <div className="text-base text-grey-2 font-lato-medium mt-8 mb-3">Enter your phone number</div>
      <Box className="input-container">
        <Box display="flex" flexDirection="row">
          <HTMLSelect
            value={countryCode}
            options={["+1", "+21", "+91"]}
            onChange={(e) =>
              setCountryCode(e.target.value as SupportedCountryCodes)
            }
            iconProps={{ icon: "chevron-down", iconSize: 10 }}
          />

          <input
            className="text-white bg-transparent border border-solid border-grey-4 px-4 py-3 mb-3 rounded-full w-full"
            placeholder="Phone Number "
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)} />

        </Box>
      </Box>

      <Button
        loading={loading}
        disabled={!phoneNumber}
        onClick={sendPhoneNumberVerificationCode}
        text="Next"
        className="next-button"
      />
      <Box
        className="divider"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="bar" />
        <div>Or</div>
        <div className="bar" />
      </Box>
      <Link to="/signIn/email">
        <Button className="google-signup" text="Email Login (2-Factor)" />
      </Link>
      <Link to="/signIn/legacy-email-password">
        <Button
          className="google-signup"
          text="Email & Password Login"
        />
      </Link>
      <div className="links">
        By clicking <span>Next</span>, you agree to our{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Terms of Service</a>
        </span>{" "}
        and{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Privacy Policy</a>
        </span>
      </div>
    </Box>
  );
  const renderEmail2FactorAuth = () => (
    <Box className="sign-up-form">
      <img src="/static/media/tango-icon.acac10f7.svg" className="absolute w-6 right-6 top-6" />
      <div className=" text-base font-lato-black text-white">Login</div>
      <div className="text-base text-grey-2 font-lato-medium mt-8 mb-3">Enter your email</div>
      <input
        className=" text-white bg-transparent border border-solid border-grey-4 px-4 py-3 mb-3 rounded-full w-full"
        placeholder="Email "
        value={email}
        onChange={(e) => setEmail(e.target.value)} />

      <Button
        loading={loading}
        disabled={!email}
        onClick={sendEmailVerification}
        text="Next"
        className="next-button"
      />
      <Box
        className="divider"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="bar" />
        <div>Or</div>
        <div className="bar" />
      </Box>
      <Link to="/signIn/phone">
        <Button className="google-signup" text="Phone Login (2-Factor)" />
      </Link>
      <Link to="/signIn/legacy-email-password">
        <Button
          className="google-signup"
          text="Email & Password Login"
        />
      </Link>
      <div className="text-grey-4 font-lato-medium text-xs mt-16">
        By clicking <span>Next</span>, you agree to our{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Terms of Service</a>
        </span>{" "}
        and{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Privacy Policy</a>
        </span>
      </div>
    </Box>
  );

  const renderEmailPasswordAuth = () => (
    <Box className="sign-up-form">
      <img src="/static/media/tango-icon.acac10f7.svg" className="absolute w-6 right-6 top-6" />
      <div className=" text-base font-lato-black text-white">Login</div>
      <div className="text-base text-grey-2 font-lato-medium mt-8 mb-3">Enter your email and password</div>

      <input
        className=" text-white bg-transparent border border-solid border-grey-4 px-4 py-3 mb-3 rounded-full w-full"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)} />

      <input type="password"
        className=" text-white bg-transparent border border-solid border-grey-4 px-4 py-3 mb-3 rounded-full w-full"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)} />


      <Button
        loading={loading}
        disabled={!email || !password}
        onClick={signInWithEmailAndPassword}
        text="Next"
        className="next-button"
      />
      <Box
        className="divider"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="bar" />
        <div>Or</div>
        <div className="bar" />
      </Box>
      <Link to="/signIn/phone">
        <Button className="google-signup" text="Phone Login (2-Factor)" />
      </Link>
      <Link to="/signIn/email">
        <Button className="google-signup" text="Email Login (2-Factor)" />
      </Link>
      <div className="links">
        By clicking <span>Next</span>, you agree to our{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Terms of Service</a>
        </span>{" "}
        and{" "}
        <span style={{ textDecoration: "underline" }}>
          <a href="https://www.runtango.com/legal">Privacy Policy</a>
        </span>
      </div>
    </Box>
  );

  return (
    <Box className="manager-sign-up">
      <Box className="header" display="flex" justifyContent="space-between">
        <div className="login-link">
          {/*
          Don't have an account? <Link to="/signUp"><span>Sign Up</span></Link>
          */}
        </div>
      </Box>
      <Routes>
        <Route path="phone" element={renderPhoneAuth()} />
        <Route path="email" element={renderEmail2FactorAuth()} />
        <Route
          path="legacy-email-password"
          element={renderEmailPasswordAuth()}
        />
        <Route path="*" element={<Navigate replace to="email" />} />
      </Routes>
      <div id="recaptcha-container" />
    </Box>
  );
};
export default SignIn;
