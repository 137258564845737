import { Button, Dialog, Icon } from "@blueprintjs/core";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  approveCoverRequest,
  approveRequest,
  approveTradeRequest,
  denyRequest,
} from "controllers/scheduleEvents";

import Box from "components/Box";

import {
  getPositionNameById,
  getShiftTypeById,
} from "model/selectors/businessSettings";
import { RootState } from "model/store";

import "./style.css";

interface Props {
  requestId: string | null;
  request: ScheduleEvent | undefined;
  closeModal: () => void;
  selectedTradeOrCoverRequest: ScheduleEvent | null;
  showAssignStaffModal: (shiftId: string, scheduleId: string) => void;
}

const TradeRequestModal = ({
  requestId,
  closeModal,
  request,
  selectedTradeOrCoverRequest,
}: Props) => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);

  const requestToUse = selectedTradeOrCoverRequest || request;

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const schedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const sendingSchedule = schedules.find(
    (schedule) => schedule.id === requestToUse?.sendingScheduleId
  );
  const sendingShift = sendingSchedule?.shifts.find(
    (s) => s.id === requestToUse?.sendingShiftId
  );

  const receivingSchedule =
    requestToUse?.eventType === "cover"
      ? schedules.find(
          (schedule) => schedule.id === requestToUse?.sendingScheduleId
        )
      : schedules.find(
          (schedule) => schedule.id === requestToUse?.receivingScheduleId
        );
  const receivingShift =
    requestToUse?.eventType === "cover"
      ? sendingSchedule?.shifts.find(
          (s) => s.id === requestToUse?.sendingShiftId
        )
      : receivingSchedule?.shifts.find(
          (s) => s.id === requestToUse?.receivingShiftId
        );

  const sendingPositionName = sendingShift
    ? getPositionNameById(businessSettings, sendingShift?.position)
    : null;
  const receivingPositionName =
    requestToUse?.eventType === "cover"
      ? sendingShift
        ? getPositionNameById(businessSettings, sendingShift?.position)
        : null
      : receivingShift
      ? getPositionNameById(businessSettings, receivingShift?.position)
      : null;

  const senderStaff = fellowStaffMembers.find(
    (sm) => sm?.id === requestToUse?.senderStaff?.id
  );
  const receiverStaff = fellowStaffMembers.find(
    (sm) => sm?.id === requestToUse?.receiverStaff?.id
  );

  const approveRequestHandler = async () => {
    if (requestToUse) {
      setApproveLoading(true);
      console.log("requestToUse", requestToUse);
      if (requestToUse?.eventType === "trade" && requestToUse) {
        await approveTradeRequest(requestToUse);
      } else if (requestToUse?.eventType === "cover" && requestToUse) {
        await approveCoverRequest(requestToUse);
      } else {
        await approveRequest(requestToUse.id);
      }
      setApproveLoading(false);
      closeModal();
    }
  };

  const denyRequestHandler = async () => {
    if (requestToUse) {
      setDenyLoading(true);
      await denyRequest(requestToUse.id);
      setDenyLoading(false);
      closeModal();
    }
  };

  const renderSenderDescription = () => {
    if (requestToUse?.eventType === "trade") {
      return `${senderStaff?.contact?.firstName} ${
        senderStaff?.contact?.lastName
      } is sending their ${sendingPositionName} ${
        getShiftTypeById(businessSettings, sendingShift?.shiftTypeId || "")
          ?.name
      } Shift on ${moment(sendingShift?.startDate?.toDate()).format(
        "dddd, MMMM Do YYYY"
      )}`;
    }
    return `${senderStaff?.contact?.firstName} ${
      senderStaff?.contact?.lastName
    } is requesting their ${sendingPositionName} ${
      getShiftTypeById(businessSettings, sendingShift?.shiftTypeId || "")?.name
    } Shift to be covered on ${moment(sendingShift?.startDate?.toDate()).format(
      "dddd, MMMM Do YYYY"
    )}`;
  };

  const renderReceiverDescription = () => {
    if (requestToUse?.eventType === "trade") {
      return `${receiverStaff?.contact?.firstName} ${
        receiverStaff?.contact?.lastName
      } is sending their ${receivingPositionName} ${
        getShiftTypeById(businessSettings, receivingShift?.shiftTypeId || "")
          ?.name
      } Shift on ${moment(receivingShift?.startDate?.toDate()).format(
        "dddd, MMMM Do YYYY"
      )}`;
    }
    return `${receiverStaff?.contact?.firstName} ${
      receiverStaff?.contact?.lastName
    } is accepting to cover the ${sendingPositionName} ${
      getShiftTypeById(businessSettings, sendingShift?.shiftTypeId || "")?.name
    } Shift on ${moment(sendingShift?.startDate?.toDate()).format(
      "dddd, MMMM Do YYYY"
    )}`;
  };

  return (
    <Dialog
      isOpen={Boolean(requestId)}
      canOutsideClickClose
      onClose={closeModal}
      className="request-modal"
    >
      <Box className="modal-header">
        <div className="modal-back-button">
          <Icon icon="chevron-left" iconSize={8} />
        </div>
        {requestToUse?.eventType === "trade" ? "Trade" : "Cover"} Request
      </Box>
      <Box className="modal-body">
        <Box display="flex" flexDirection="row" className="border-bottom">
          <Box
            className="staff-name body-section"
            display="flex"
            flex={0.4}
            flexDirection="column"
          >
            <div className="section-title">{sendingPositionName}</div>
            <div className="section-body">
              {senderStaff?.contact?.firstName} {senderStaff?.contact?.lastName}
            </div>
          </Box>
          <Box className="shift-time" display="flex" flex={0.6}>
            <Box
              className="shift-start-time  body-section border-left"
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <div className="section-title">Start</div>
              <div className="section-body">
                {moment(sendingShift?.startDate?.toDate()).format("hh:mm a")}
              </div>
            </Box>
            <Box
              className="shift-end-time  body-section  border-left"
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <div className="section-title">End</div>
              <div className="section-body">
                {moment(sendingShift?.endDate?.toDate()).format("hh:mm a")}
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          className="groove"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="groove-text">{renderSenderDescription()}</div>
          <img
            src={require("assets/back-arrow.svg").default}
            alt="previous icon"
            className="down-activity-arrow activity-arrow"
          />
        </Box>
        {requestToUse?.eventType === "trade" && (
          <Box className="activity-arrow-group">
            <img
              src={require("assets/back-arrow.svg").default}
              alt="previous icon"
              className="down-activity-arrow activity-arrow"
            />
            <img
              src={require("assets/back-arrow.svg").default}
              alt="previous icon"
              className="up-activity-arrow activity-arrow"
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          className="border-bottom border-top"
        >
          <Box
            className="staff-name body-section"
            display="flex"
            flex={0.4}
            flexDirection="column"
          >
            <div className="section-title">{receivingPositionName}</div>
            <div className="section-body">
              {receiverStaff?.contact?.firstName}{" "}
              {receiverStaff?.contact?.lastName}
            </div>
          </Box>
          <Box className="shift-time" display="flex" flex={0.6}>
            <Box
              className="shift-start-time  body-section border-left"
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <div className="section-title">Start</div>
              <div className="section-body">
                {moment(receivingShift?.startDate?.toDate()).format("hh:mm a")}
              </div>
            </Box>
            <Box
              className="shift-end-time  body-section  border-left"
              display="flex"
              flex={1}
              flexDirection="column"
            >
              <div className="section-title">End</div>
              <div className="section-body">
                {moment(receivingShift?.endDate?.toDate()).format("hh:mm a")}
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          className="groove"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="groove-text">{renderReceiverDescription()}</div>
          <img
            src={require("assets/back-arrow.svg").default}
            alt="previous icon"
            className="up-activity-arrow activity-arrow"
          />
        </Box>
        {/* <Box display="flex" flexDirection="row" className="overtime-detected border-bottom" alignItems="center" justifyContent="space-between">
          <Box>
            <div className="overtime-title">Overtime Detected</div>
            <div className="overtime-hours">Brandon Herron will go into overtime by 2 hour(s)</div>
          </Box>
          <img src={require('assets/warning.svg').default} className="warning-sign" />
        </Box> */}
        {/* <Box display="flex" flexDirection="row" className="overtime-detected border-bottom" alignItems="center" justifyContent="space-between">
          <Box>
            <div className="overtime-title">Overtime Detected</div>
            <div className="overtime-hours">Brandon Herron will go into overtime by 2 hour(s)</div>
          </Box>
          <img src={require('assets/warning.svg').default} className="warning-sign" />
        </Box> */}
        <Box
          className="modal-footer"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            onClick={denyRequestHandler}
            loading={denyLoading}
            disabled={approveLoading || denyLoading}
            icon="cross"
            text="Deny"
            className="footer-button"
            id="deny-button"
          />
          <Button
            onClick={approveRequestHandler}
            loading={approveLoading}
            disabled={approveLoading || denyLoading}
            icon="small-tick"
            text="Approve"
            className="footer-button"
            id="approve-button"
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default TradeRequestModal;
