import { EditableText } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Select, { components } from "react-select";

import Help from "../../../../assets/help.svg";
import { DocumentData } from "../../../../types/document";
import { MomentDateRange } from "../../../../utils/MomentDateRange";
import "./discountModal.css";

interface ModalProps {
  showModal: boolean;
  closeModal: (index: number | null) => void;
  data: DocumentData;
  setData: (newData: DocumentData) => void;
  options: any;
  additionalInfo: DocumentData;
}

function DiscountModal({
  showModal,
  closeModal,
  data,
  setData,
  options,
  additionalInfo,
}: ModalProps) {
  // Record is defined here
  const [record, setRecord] = useState<DocumentData | null>(null);
  const [editText, setEditText] = useState<string | null>(null);
  const [tempText, setTempText] = useState("");
  const [stageToRender, setStageToRender] = useState(1); // useState(5)

  // Date stuff
  const [date, setDate] = useState({ from: undefined, to: undefined });

  const modalRef = useRef(null);

  useEffect(() => {
    if (data) {
      console.log("ROW: ", data);
      // TEMP TEMP TEMP
      // @ts-ignore
      // data.discountType = "Coupon/Promo"
      // setEditText('datesToExclude')
      setEditText(null);
      setRecord(data);
      setTempText("");
    }
  }, [data]);

  useEffect(() => {
    console.log("Edit text: ", editText);
    if (
      (!editText ||
        editText === "discountAmount" ||
        editText === "productsToDiscount") &&
      record
    ) {
      // Meets the condition for scheduled discount
      if (
        record.discountType === "Scheduled" &&
        (record.scheduledDiscountType === "Price Off" ||
          record.scheduledDiscountType === "Percent Off" ||
          record.scheduledDiscountType === "New Price")
      ) {
        const rule = discountRule(record);
        if (rule) {
          setRecord({
            ...record,
            discountRule: rule,
          });
        }
      }
      // Meets the condition for reward or coupon discount
      if (
        record.discountType === "Coupon/Promo" &&
        (record.discountTriggerAmount ||
          record.discountTriggerItems.length > 0) &&
        record.rewardType
      ) {
        const rule = discountRule(record);
        if (rule) {
          setRecord({
            ...record,
            discountRule: rule,
          });
        }
      }
    }
  }, [editText]);

  useEffect(() => {
    if (record) {
      if (record.discountType === "Coupon/Promo") {
        // Set the appropriate stage to render
        if (record.rewardDiscountAmount) {
          setStageToRender(5);
        } else if (record.discountTriggerType) {
          if (record.discountTriggerAmount) {
            const isAmount =
              typeof record.discountTriggerAmount === "string"
                ? record.discountTriggerAmount.length > 0
                : String(record.discountTriggerAmount).length > 0;
            if (isAmount) {
              setStageToRender(4);
            }
          }

          // TODO: Check if Specific Items Are Purchased, Chosen Items is greater than 0 and Combo Purchased Required are set
          setStageToRender(4);
        } else if (record.isOneTimeUse.length > 0) {
          setStageToRender(3);
        } else if (record.schedule.length > 0 && record.isSpecificPromoCode) {
          setStageToRender(2);
        }
      } else if (record.discountType === "Scheduled") {
        if (record.scheduledDiscountType) {
          setStageToRender(2);
        }
      }
    }
  }, [record]);

  useEffect(() => {
    console.log("STAGE: ", stageToRender);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);
  const handleClick = (e: any) => {
    // @ts-ignore
    if (modalRef.current.contains(e.target)) {
      return;
    }
    // this click is outside
    closeModal(null);
    setStageToRender(1);
  };
  const CustomOption = ({ children, ...props }: any) => {
    return (
      <components.Option {...props}>
        <span
          style={{ backgroundColor: `rgba(${props.data.color.join(",")},0.2)` }}
        >
          {children}
        </span>
      </components.Option>
    );
  };
  const SingleValue = ({ children, ...props }: any) => {
    return (
      <components.SingleValue {...props}>
        <span
          style={{ backgroundColor: `rgba(${props.data.color.join(",")},0.2)` }}
        >
          {children}
        </span>
      </components.SingleValue>
    );
  };

  const getUi = (key: any, value: any, optionKey?: any) => {
    const foundSchema = options.find((item: any) => item.key === key);
    if (foundSchema) {
      if (foundSchema.isEditable) {
        if (foundSchema.isDropdown) {
          const selectedValue = foundSchema.dropdown.options.find(
            (item: DocumentData) => item.optionName === value
          );
          let multipleSelectedValue;
          if (foundSchema.dropdown.isMultiSelect) {
            multipleSelectedValue = foundSchema.dropdown.options.filter(
              (item: DocumentData) => value.includes(item.optionName)
            );
          }
          return (
            <Select
              className="product-modal-select"
              classNamePrefix="react-select"
              getOptionLabel={(option) =>
                optionKey ? option[optionKey] : option.optionName
              }
              getOptionValue={(option) =>
                optionKey ? option[optionKey] : option.optionName
              }
              options={foundSchema.dropdown.options}
              value={
                foundSchema.dropdown.isMultiSelect
                  ? multipleSelectedValue
                  : selectedValue
              }
              components={{ Option: CustomOption, SingleValue }}
              isMulti={foundSchema.dropdown.isMultiSelect}
              styles={{
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: `rgba(${data.color.join(",")}, 0.2)`,
                  };
                },
              }}
              onChange={(opt, meta) => {
                if (foundSchema.dropdown.isMultiSelect) {
                  if (meta.action === "remove-value") {
                    if (editText && record) {
                      if (Array.isArray(record[editText])) {
                        setRecord({
                          ...record,
                          [editText]: [
                            ...record[editText].filter(
                              (option: DocumentData) =>
                                option !== meta.removedValue.optionName
                            ),
                          ].filter(
                            (value, index, arr) => arr.indexOf(value) === index
                          ),
                        });
                      }
                    }
                  } else {
                    if (editText && record) {
                      setRecord({
                        ...record,
                        [editText]: [
                          ...record[editText],
                          ...opt.map(
                            (option: DocumentData) => option.optionName
                          ),
                        ].filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        ),
                      });
                    }
                  }
                } else {
                  if (editText) {
                    setRecord({
                      ...record,
                      [editText]: opt.optionName,
                    });
                    setEditText(null);
                  }
                }
              }}
            />
          );
        }
      } else {
        console.log("else");
        return value;
      }
    } else {
      return <span className="empty">Empty</span>;
    }
  };

  const findItemsUptoLengthN = (array: string[], length: number) => {
    let count = 0;
    let isGreater = false;
    const result = [];
    for (const item of array) {
      if (count + item.length < length) {
        result.push(item);
        count += item.length;
      } else {
        isGreater = true;

        // Break from the loop
        break;
      }
    }
    return { result, isGreater };
  };

  const discountRule = (row: DocumentData | null) => {
    if (row) {
      if (row.discountType === "Scheduled") {
        const noProducts = row.productsToDiscount.length === 0;
        const items = findItemsUptoLengthN(row.productsToDiscount, 40);
        const itemNames = items.isGreater
          ? items.result.join(", ") + " and more"
          : items.result.join(", ");
        const discountText =
          row.scheduledDiscountType === "Price Off" ||
          row.scheduledDiscountType === "Percent Off" ||
          row.scheduledDiscountType === "New Price"
            ? // This should be in dollars by the time a user opens a modal
              row.scheduledDiscountType === "Price Off"
              ? `$${row.discountAmount} off`
              : row.scheduledDiscountType === "Percent Off"
              ? `${row.discountAmount}% off on`
              : `$${row.discountAmount} for`
            : "";
        return discountText && !noProducts
          ? discountText + " " + itemNames
          : "";
      }
      if (row.discountType === "Coupon/Promo") {
        let preText = "";
        let postText = "";
        const itemsTrigger = findItemsUptoLengthN(row.discountTriggerItems, 40);
        const itemNamesTrigger = itemsTrigger.isGreater
          ? itemsTrigger.result.join(", ") + " and more"
          : itemsTrigger.result.join(", ");

        const itemsRewarded = findItemsUptoLengthN(row.rewardDiscountItems, 40);
        const itemNamesRewarded = itemsRewarded.isGreater
          ? itemsRewarded.result.join(", ") + " and more"
          : itemsRewarded.result.join(", ");

        switch (row.discountTriggerType) {
          // minimumDollarSpent
          case "Dollar spend is over a certain amount":
            preText = `Spend $${
              row.discountTriggerAmount ? row.discountTriggerAmount : 0
            } and get`;
            break;
          // specificItemsDiscounted
          case "Specific items are purchased":
            preText = `Buy ${itemNamesTrigger} and get`;
            break;
          default:
            break;
        }

        if (row.rewardType === "Free items") {
          postText = itemNamesRewarded + " free";
        } else {
          const discountText =
            row.rewardDiscountType === "Percent off the total check" ||
            row.rewardDiscountType === "Percent off viable items"
              ? `${row.rewardDiscountAmount}% off`
              : `$${row.rewardDiscountAmount} off`;
          postText = discountText;
        }

        return preText + " " + postText;
      }
      return "";
    }
    // Record is null
    return "";
  };

  // TODO: Make this look better?
  if (editText === "schedule") {
    return (
      <div
        className="modal table-product-modal"
        style={{ display: showModal ? "grid" : "none" }}
      >
        <div
          className="modal-content"
          ref={modalRef}
          style={{
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span className="back" onClick={() => setEditText(null)}>
            &larr;
          </span>
          <div className="title">When will this run?</div>
          <div className="details" style={{ padding: 0 }}>
            <div className="date-picker" style={{ display: "inline" }}>
              <DayPicker
                numberOfMonths={2}
                modifiers={
                  date && date.from && date.to
                    ? { from: date.from, to: date.to }
                    : undefined
                }
                modifiersStyles={{
                  from: {
                    color: "white",
                    backgroundColor: "#ffc107",
                  },
                  to: {
                    color: "white",
                    backgroundColor: "#ffc107",
                  },
                }}
                onDayClick={(day) => {
                  // @ts-ignore
                  const dates = DateUtils.addDayToRange(day, date);
                  console.log("Dates: ", dates);

                  if (dates.from && dates.to) {
                    setRecord({
                      ...record,
                      [editText]: [dates.from, dates.to],
                    });
                    // @ts-ignore
                    setDate({ from: null, to: null });
                    setEditText(null);
                  } else {
                    // @ts-ignore
                    setDate(dates);
                  }
                }}
                // @ts-ignore
                selectedDays={
                  date && date.from && date.to
                    ? [{ from: date.from }, { from: date.from, to: date.to }]
                    : date && date.from
                    ? [{ from: date.from }]
                    : []
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TODO: Make this look better?
  if (editText === "datesToExclude") {
    return (
      <div
        className="modal table-product-modal"
        style={{ display: showModal ? "grid" : "none" }}
      >
        <div className="modal-content" ref={modalRef} style={{ width: "90%" }}>
          <span className="back" onClick={() => setEditText(null)}>
            &larr;
          </span>
          <div className="title">Dates to exclude</div>
          <div className="details" style={{ padding: 0 }}>
            <DayPicker
              numberOfMonths={2}
              modifiers={
                date && date.from && date.to
                  ? { from: date.from, to: date.to }
                  : undefined
              }
              modifiersStyles={{
                from: {
                  color: "white",
                  backgroundColor: "#ffc107",
                },
                to: {
                  color: "white",
                  backgroundColor: "#ffc107",
                },
              }}
              onDayClick={(day) => {
                // @ts-ignore
                const dates = DateUtils.addDayToRange(day, date);
                console.log("Dates: ", dates);

                if (dates.from && dates.to) {
                  if (record && record[editText]) {
                    setRecord({
                      ...record,
                      [editText]: [...record[editText], [dates.from, dates.to]],
                    });
                  } else {
                    setRecord({
                      ...record,
                      [editText]: [dates.from, dates.to],
                    });
                  }
                  // @ts-ignore
                  setDate({ from: null, to: null });
                } else {
                  // @ts-ignore
                  setDate(dates);
                }
              }}
              // @ts-ignore
              selectedDays={
                date && date.from && date.to
                  ? [{ from: date.from }, { from: date.from, to: date.to }]
                  : date && date.from
                  ? [{ from: date.from }]
                  : []
              }
            />
            <div className="date-excluded">
              {record && record["datesToExclude"]
                ? record["datesToExclude"].map((date: [Date, Date]) => {
                    return (
                      <MomentDateRange
                        range={[date[0], date[1]]}
                        center={true}
                        removeFunc={() => {
                          setRecord({
                            ...record,
                            datesToExclude: record.datesToExclude.filter(
                              (dateToExclude: [Date, Date]) =>
                                !_.isEqual(dateToExclude, [date[0], date[1]])
                            ),
                          });
                        }}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="modal table-product-modal"
      style={{ display: showModal ? "grid" : "none" }}
    >
      <div className="modal-content" ref={modalRef}>
        <span
          className="close"
          onClick={() => {
            if (record) {
              setData(record);
            }
            setStageToRender(1);
            closeModal(null);
          }}
        >
          &times;
        </span>

        {record && (
          <div>
            <div className="title">{record.nameExternal}</div>
            <div className="details">
              <div className="detail-row">
                <div>Enabled</div>
                <div
                  className="active no-padding"
                  onClick={() => setEditText("enabled")}
                >
                  {getUi("enabled", record.enabled)}
                </div>
              </div>
              <div className="detail-row">
                <div>Discount Type</div>
                <div
                  className="active no-padding"
                  onClick={() => setEditText("discountType")}
                >
                  {getUi("discountType", record.discountType)}
                </div>
              </div>
              <div className="detail-row">
                <div>Schedule</div>
                {record.schedule.length !== 0 ? (
                  <div>
                    {moment(record.schedule[0]).format("MMM Do") +
                      " - " +
                      moment(record.schedule[1]).format("MMM Do")}
                  </div>
                ) : (
                  <div>
                    <span className="empty">Empty</span>
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Discount Rules</div>
                {record.discountRule.length !== 0 ? (
                  <div>{record.discountRule}</div>
                ) : (
                  <div>
                    <span className="empty">Empty</span>
                  </div>
                )}
              </div>
            </div>

            {/* Custom discounts */}
            {record.discountType === "Custom" && (
              <div className="details">
                <div className="subtitle">Additional Details</div>
                <br />
                <div className="underlined-header">General Information</div>
                {stageToRender >= 1 && (
                  <>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Discount Percent</div>
                      {editText === "customDiscountAmount" ? (
                        <EditableText
                          value={tempText}
                          confirmOnEnterKey={true}
                          onChange={(newText) => setTempText(newText)}
                          onConfirm={(text) => {
                            setRecord({
                              ...record,
                              [editText]: text,
                            });
                            setTempText("");
                            setEditText(null);
                          }}
                        />
                      ) : (
                        <div
                          className={"active"}
                          onClick={() => {
                            setTempText(record.customDiscountAmount);
                            setEditText("customDiscountAmount");
                          }}
                        >
                          {record.customDiscountAmount
                            ? record.customDiscountAmount + "%"
                            : "0%"}
                        </div>
                      )}
                    </div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Is this a staff discount?</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("isStaffDiscount")}
                      >
                        {getUi("isStaffDiscount", record.isStaffDiscount)}
                      </div>
                    </div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        Is this a manager discount?
                      </div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("isManagerDiscount")}
                      >
                        {getUi("isManagerDiscount", record.isManagerDiscount)}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {/* Coupon/Promo */}

            {record.discountType === "Coupon/Promo" && (
              <div className="details">
                <div className="subtitle">Additional Details</div>
                <br />
                <div className="underlined-header">General Information</div>
                {stageToRender >= 1 && (
                  <>
                    {/* TODO: schedule date picker */}
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Schedule</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("schedule")}
                      >
                        {record.schedule.length !== 0 ? (
                          <div
                            style={{
                              backgroundColor: "#e3e3e3",
                              borderRadius: 50,
                              padding: 5,
                            }}
                          >
                            {moment(record.schedule[0]).format("MMM Do") +
                              " - " +
                              moment(record.schedule[1]).format("MMM Do")}
                          </div>
                        ) : (
                          <div>
                            <span className="empty">Empty</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        Will this be tied to a specific promo code?
                      </div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("isSpecificPromoCode")}
                      >
                        {getUi(
                          "isSpecificPromoCode",
                          record.isSpecificPromoCode
                        )}
                      </div>
                    </div>
                    {record.isSpecificPromoCode === "Yes" && (
                      <>
                        <div className="detail-row">
                          <div style={{ flex: 0.8 }}>
                            Will this promo code be public or private?
                            <div className="help">
                              <img
                                className="help"
                                src={Help}
                                alt="help"
                                style={{
                                  marginLeft: 8,
                                  width: 12,
                                  height: 12,
                                  marginTop: 2,
                                }}
                              />
                              <div className="helpMessage">
                                Public - Public promo codes can be posted on
                                social media and shared widely. Each user will
                                still only be able to use it once.
                                <br />
                                <br />
                                Private - Private promo codes are ones that are
                                unique to each guest. For example, one that will
                                be sent to individual users after completing a
                                feedback survey or signing up for a loyalty
                                program. Each user will only be able to use it
                                once.
                              </div>
                            </div>
                          </div>
                          <div
                            className="active no-padding"
                            onClick={() => setEditText("isPrivateDiscount")}
                          >
                            {getUi(
                              "isPrivateDiscount",
                              record.isPrivateDiscount
                            )}
                          </div>
                        </div>
                        <div className="detail-row">
                          <div style={{ flex: 0.8 }}>Promo Code</div>
                          {editText === "promoCode" ? (
                            <EditableText
                              value={tempText}
                              confirmOnEnterKey={true}
                              onChange={(newText) => setTempText(newText)}
                              onConfirm={(text) => {
                                setRecord({
                                  ...record,
                                  [editText]: text,
                                });
                                setTempText("");
                                setEditText(null);
                              }}
                            />
                          ) : (
                            <div
                              className={"active"}
                              onClick={() => {
                                setTempText(record.promoCode);
                                setEditText("promoCode");
                              }}
                            >
                              {record.promoCode}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        Will this be for one time use only?
                      </div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("isOneTimeUse")}
                      >
                        {getUi("isOneTimeUse", record.isOneTimeUse)}
                      </div>
                    </div>
                  </>
                )}
                <br />
                {stageToRender >= 3 && (
                  <div className="underlined-header">Discount Trigger</div>
                )}
                {stageToRender >= 3 && (
                  <>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        What will trigger this discount?
                      </div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("discountTriggerType")}
                      >
                        {getUi(
                          "discountTriggerType",
                          record.discountTriggerType
                        )}
                      </div>
                    </div>
                    {record.discountTriggerType ===
                      "Dollar spend is over a certain amount" && (
                      <div className="detail-row">
                        <div style={{ flex: 0.8 }}>Dollar is spend over:</div>
                        {editText === "discountTriggerAmount" ? (
                          <EditableText
                            value={tempText}
                            confirmOnEnterKey={true}
                            onChange={(newText) => setTempText(newText)}
                            onConfirm={(text) => {
                              setRecord({
                                ...record,
                                [editText]: text,
                              });
                              setTempText("");
                              setEditText(null);
                            }}
                          />
                        ) : (
                          <div
                            className={"active"}
                            onClick={() => {
                              setTempText(record.discountTriggerAmount);
                              setEditText("discountTriggerAmount");
                            }}
                          >
                            $ {record.discountTriggerAmount}
                          </div>
                        )}
                      </div>
                    )}
                    {record.discountTriggerType ===
                      "Specific items are purchased" && (
                      <>
                        <div className="detail-row">
                          <div style={{ flex: 0.8 }}>Choose items:</div>
                          <div
                            className="active no-padding"
                            onClick={() => setEditText("discountTriggerItems")}
                          >
                            {getUi(
                              "discountTriggerItems",
                              record.discountTriggerItems
                            )}
                          </div>
                        </div>
                        <div className="detail-row">
                          <div style={{ flex: 0.8 }}>
                            Combo purchase required?
                          </div>
                          <div
                            className="active no-padding"
                            onClick={() =>
                              setEditText(
                                "discountTriggerAllProductsNeedToBePurchased"
                              )
                            }
                          >
                            {getUi(
                              "discountTriggerAllProductsNeedToBePurchased",
                              record.discountTriggerAllProductsNeedToBePurchased
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <br />
                {stageToRender >= 4 && (
                  <div className="underlined-header">Reward</div>
                )}
                {stageToRender >= 4 && (
                  <>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        What type of reward will be given to the customer?
                      </div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("rewardType")}
                      >
                        {getUi("rewardType", record.rewardType)}
                      </div>
                    </div>
                    {(record.rewardType === "Dollars off the total check" ||
                      record.rewardType === "Percent off the total check" ||
                      record.rewardType === "Dollar off viable items" ||
                      record.rewardType === "Percent off viable items" ||
                      record.rewardType === "New price for viable items") && (
                      <div className="detail-row">
                        <div style={{ flex: 0.8 }}>Reward Amount</div>
                        {editText === "rewardDiscountAmount" ? (
                          <EditableText
                            value={tempText}
                            confirmOnEnterKey={true}
                            onChange={(newText) => setTempText(newText)}
                            onConfirm={(text) => {
                              setRecord({
                                ...record,
                                [editText]: text,
                              });
                              setTempText("");
                              setEditText(null);
                            }}
                          />
                        ) : (
                          <div
                            className={"active"}
                            onClick={() => {
                              setTempText(record.rewardDiscountAmount);
                              setEditText("rewardDiscountAmount");
                            }}
                          >
                            {record.rewardType ===
                              "Dollars off the total check" ||
                            record.rewardType === "Dollar off viable items" ||
                            record.rewardType === "New price for viable items"
                              ? "$ "
                              : ""}
                            {record.rewardDiscountAmount}
                            {record.rewardType ===
                              "Percent off the total check" ||
                            record.rewardType === "Percent off viable items"
                              ? " %"
                              : ""}
                          </div>
                        )}
                      </div>
                    )}
                    {record.rewardType === "Free Items" && (
                      <div className="detail-row">
                        <div style={{ flex: 0.8 }}>
                          Select items customers get for free:
                        </div>
                        <div
                          className="active no-padding"
                          onClick={() => setEditText("rewardDiscountItems")}
                        >
                          {getUi(
                            "rewardDiscountItems",
                            record.rewardDiscountItems
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <br />
                {stageToRender >= 5 && (
                  <div className="underlined-header">Trigger Exclusions</div>
                )}
                {stageToRender >= 5 && (
                  <>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Excluded order types</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("orderTypesToExclude")}
                      >
                        {record.orderTypesToExclude.length > 0 ||
                        editText === "orderTypesToExclude" ? (
                          getUi(
                            "orderTypesToExclude",
                            record.orderTypesToExclude
                          )
                        ) : (
                          <span className="empty">Empty</span>
                        )}
                      </div>
                    </div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        Excluded dates / date ranges
                      </div>
                      <div
                        className="active no-padding"
                        style={{ display: "block" }}
                        onClick={() => setEditText("datesToExclude")}
                      >
                        {record &&
                        record["datesToExclude"] &&
                        record["datesToExclude"].length > 0 ? (
                          record["datesToExclude"].map((date: [Date, Date]) => {
                            return (
                              <MomentDateRange
                                range={[date[0], date[1]]}
                                format={"MMM Do"}
                                showDelete={false}
                                removeFunc={() => {
                                  setRecord({
                                    ...record,
                                    datesToExclude:
                                      record.datesToExclude.filter(
                                        (dateToExclude: [Date, Date]) =>
                                          !_.isEqual(dateToExclude, [
                                            date[0],
                                            date[1],
                                          ])
                                      ),
                                  });
                                }}
                              />
                            );
                          })
                        ) : (
                          <span className="empty">Empty</span>
                        )}
                      </div>
                    </div>
                    {record.discountTriggerType ===
                      "Dollar spend is over a certain amount" && (
                      <div className="detail-row">
                        <div style={{ flex: 0.8 }}>Excluded products</div>
                        <div
                          className="active no-padding"
                          onClick={() => setEditText("orderItemsToExclude")}
                        >
                          {record.orderItemsToExclude.length > 0 ||
                          editText === "orderItemsToExclude" ? (
                            getUi(
                              "orderItemsToExclude",
                              record.orderItemsToExclude
                            )
                          ) : (
                            <span className="empty">Empty</span>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <br />
                {(record.rewardType === "Percent off viable items" ||
                  record.rewardType === "New price for viable items" ||
                  record.rewardType === "Dollar off viable items") &&
                  stageToRender >= 5 && (
                    <>
                      <div className="underlined-header">Reward Exclusions</div>
                      <div className="detail-row">
                        <div style={{ flex: 0.8 }}>Excluded products</div>
                        <div
                          className="active no-padding"
                          onClick={() => setEditText("productsToExclude")}
                        >
                          {record.productsToExclude.length > 0 ||
                          editText === "productsToExclude" ? (
                            getUi("productsToExclude", record.productsToExclude)
                          ) : (
                            <span className="empty">Empty</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            )}

            {/* Scheduled */}

            {record.discountType === "Scheduled" && (
              <div className="details">
                <div className="subtitle">Additional Details</div>
                <br />
                {stageToRender >= 1 && (
                  <>
                    {/* TODO: schedule date picker */}
                    <div className="underlined-header">General Information</div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>When will this run?</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("schedule")}
                      >
                        {record.schedule.length !== 0 ? (
                          <div
                            style={{
                              backgroundColor: "#e3e3e3",
                              borderRadius: 50,
                              padding: 5,
                            }}
                          >
                            {moment(record.schedule[0]).format("MMM Do") +
                              " - " +
                              moment(record.schedule[1]).format("MMM Do")}
                          </div>
                        ) : (
                          <div>
                            <span className="empty">Empty</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <div className="underlined-header">Discount Rules</div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Discount Style</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("scheduledDiscountType")}
                      >
                        {getUi(
                          "scheduledDiscountType",
                          record.scheduledDiscountType
                        )}
                      </div>
                    </div>
                  </>
                )}
                {stageToRender >= 2 && (
                  <>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>New Price</div>
                      {editText === "discountAmount" ? (
                        <EditableText
                          value={tempText}
                          confirmOnEnterKey={true}
                          onChange={(newText) => setTempText(newText)}
                          onConfirm={(text) => {
                            setRecord({
                              ...record,
                              [editText]: text,
                            });
                            setTempText("");
                            setEditText(null);
                          }}
                        />
                      ) : (
                        <div
                          className={"active"}
                          onClick={() => {
                            setTempText(record.discountAmount);
                            setEditText("discountAmount");
                          }}
                        >
                          {record.scheduledDiscountType === "Price Off" ||
                          record.scheduledDiscountType === "New Price"
                            ? "$ "
                            : ""}
                          {record.discountAmount}
                          {record.scheduledDiscountType === "Percent Off"
                            ? "% "
                            : ""}
                        </div>
                      )}
                    </div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Discounted Products</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("productsToDiscount")}
                      >
                        {getUi("productsToDiscount", record.productsToDiscount)}
                      </div>
                    </div>
                    <div className="underlined-header">Exclusions</div>
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>Excluded order types</div>
                      <div
                        className="active no-padding"
                        onClick={() => setEditText("orderTypesToExclude")}
                      >
                        {record.orderTypesToExclude.length !== 0 ||
                        editText === "orderTypesToExclude" ? (
                          getUi(
                            "orderTypesToExclude",
                            record.orderTypesToExclude
                          )
                        ) : (
                          <span className="empty">Empty</span>
                        )}
                      </div>
                    </div>
                    {/* TODO: datesToExclude */}
                    <div className="detail-row">
                      <div style={{ flex: 0.8 }}>
                        Excluded dates / date ranges
                      </div>
                      <div
                        className="active no-padding"
                        style={{ display: "block" }}
                        onClick={() => setEditText("datesToExclude")}
                      >
                        {record &&
                        record["datesToExclude"] &&
                        record["datesToExclude"].length > 0 ? (
                          record["datesToExclude"].map((date: [Date, Date]) => {
                            return (
                              <MomentDateRange
                                range={[date[0], date[1]]}
                                format={"MMM Do"}
                                showDelete={false}
                                removeFunc={() => {
                                  setRecord({
                                    ...record,
                                    datesToExclude:
                                      record.datesToExclude.filter(
                                        (dateToExclude: [Date, Date]) =>
                                          !_.isEqual(dateToExclude, [
                                            date[0],
                                            date[1],
                                          ])
                                      ),
                                  });
                                }}
                              />
                            );
                          })
                        ) : (
                          <span className="empty">Empty</span>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DiscountModal;
