import { Spinner } from "@blueprintjs/core";

const EnterpriseInitializeLoading = () => {
  return (
    <div style={{ marginTop: 100, width: "100%", height: "100%" }}>
      <Spinner size={40} />
      <div
        className="loading-text"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        Give us a moment while we gather all the data for you
      </div>
    </div>
  );
};

export default EnterpriseInitializeLoading;
