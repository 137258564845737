import { useState } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { TangoDispute, useDisputes } from "controllers/disputes";

type DisputeStatus = 'Need Response' | 'Closed' | 'Lost';

type DisputeProps = {
    uniqueId: string;
    date: any;
    amount: number;
    status: string;
    reason: string;
    customer: string;
    last4: string;
    dispute: any;
};

const columns: ColumnInstruction<DisputeProps>[] = [
    { type: "projection", header: "Date", attribute: "date" },
    { type: "data", header: "Total Amount", attribute: "amount" },
    { type: "projection", header: "Status", attribute: "status" },
    { type: "data", header: "Reason", attribute: "reason" },
    { type: "data", header: "Customer", attribute: "customer" },
    { type: "data", header: "Last 4", attribute: "last4" },
];

const instructions: { [x: string]: RenderInstruction<DisputeProps> } = {};

instructions.date = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return <div>
            {new Date(fullObject.date._seconds * 1000).toDateString()}
        </div>
    },
    editComponent: null
}

instructions.amount = {
    type: "currency",
}

instructions.status = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        if (fullObject.status === 'Closed') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl  bg-blue-2 border-blue-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        if (fullObject.status === 'Need Response') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-yellow-2 border-yellow-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-error-red bg-error-red-hover text-xs font-lato-bold text-white border-solid border'>
            {fullObject.status}
        </div>
        )
    },
    editComponent: null,
}

const useDisputesData = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const info = useDisputes(businessId);
    return info?.data ?? [];
}

const useDisputesScreen = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const [isEditing, setEditing] = useState(false);

    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);

    // const disputesData = useDisputesData();
    const query = useDisputes(businessId);
    const disputesData = query.data ?? []; //query will return null if no business id has propogated 


    const data: DisputeProps[] = disputesData.map((dispute: TangoDispute) => {
        return {
            uniqueId: dispute.id,
            date: dispute.createdAt,
            amount: dispute.amount,
            status: dispute.status.replace('_', ' '),
            reason: dispute.reason,
            customer: '',
            last4: '',
            dispute: dispute
        }
    })

    console.log('DISPUTES DATA ', disputesData)

    return {
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
    };
};
export default useDisputesScreen;
