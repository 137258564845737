import { Icon } from "@blueprintjs/core";
import { Grid } from "@mui/material";
import firebase from "config/firebase";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";

import { RootState } from "model/store";

import TemplateNameModal from "../createCampaign/model/templateName";
import "./styles.css";

const Templates = () => {
  const navigate = useNavigate();
  const [templates, setTemplate]: any = useState([]);
  const [showTemplateNameModal, setShowTemplateNameModal] =
    useState<boolean>(false);
  const db = firebase.firestore();
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const getTemplates = () => {
    const unsubscribe = db.collection("Templates").onSnapshot((snapshot) => {
      let TemplateData: any = [];
      snapshot.forEach((doc) => {
        TemplateData = [...TemplateData, doc.data()];
      });
      console.log("TemplateData", TemplateData);
      setTemplate(TemplateData);
    });
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const templateLength =
    templates && templates.filter((item: any) => item.deleted == false).length;

  return (
    <div id="templates-page">
      <Box className="page-head">
        <Box
          display="flex"
          flex-direction="row"
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/create-campaign")}
        >
          <Icon icon="chevron-left" color="#424452" size={12} />
          <span className="backword-text">Leave Creative Studio</span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="pagehead-leftside">
            <div className="email-title">Your Templates</div>
            <div className="email-time">
              Showing all{" "}
              <span className="bold-subheader">{templateLength} Templates</span>{" "}
              below{" "}
            </div>
          </Box>
          <Box
            className="pagehead-rightside"
            display="flex"
            flexDirection="row"
            style={{ marginRight: "54px" }}
          >
            <div
              className="savenexit-button"
              onClick={() => setShowTemplateNameModal(true)}
            >
              New Template
            </div>
          </Box>
        </Box>
      </Box>

      <Box className="page-body">
        <div className="body-title">Templates</div>
        <Box className="template-grid">
          <Grid container spacing={3} columns={24}>
            {templates.length > 0 &&
              templates
                .filter((item: any) => item.deleted == false)
                .map((item: any) => (
                  <Grid item xs={4} xl={4} md={4} lg={4}>
                    <Box
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Box className="template-card-container">
                        <div
                          className={"template-card"}
                          onClick={() => {
                            navigate(`/creative-studio/${item.id}`);
                          }}
                        ></div>
                      </Box>
                      <div className="template-card-title">
                        {item.templateName}
                      </div>
                      <div className="template-card-time">
                        {moment(item.createdAt.toDate()).fromNow()}
                      </div>
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Box>
      <TemplateNameModal
        isOpen={showTemplateNameModal}
        closeModal={() => setShowTemplateNameModal(false)}
        businessId={business && business.id}
      />
    </div>
  );
};

export default Templates;
