import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { useInventoryInfo } from "controllers/inventoryInfo";
import { RootState } from "model/store";
import { CreateCountDTO, useCreateCount } from "controllers/inventoryCounts";
import { UnitQuantityDTO, useAllItemsInfo } from "controllers/inventoryItemInfo";
import { maxParInstruction, minParInstruction, stockInstruction } from "../tableCells";

type StockCount = {
  uniqueId: string;
  isRaw: boolean,
  name: string;
  theoreticalStock: UnitQuantityDTO[];
  productionUnitName: string;
  minPar: number;
  maxPar: number;
};

const columns: ColumnInstruction<StockCount>[] = [
  { type: "data", header: "Item", attribute: "name" },
  { type: "projection", header: "Stock", attribute: "stockInfo" },
  // { type: "data", header: "Production Unit Count", attribute: "productionCount" },
  // { type: "data", header: "Ingredient Unit Count", attribute: "ingredientCount" },
  { type: "data", header: "Minimum Par", attribute: "minPar" },
  { type: "data", header: "Maximum Par", attribute: "maxPar" },
];

const instructions: { [x: string]: RenderInstruction<StockCount> } = {
  stockInfo: stockInstruction,
  minPar: minParInstruction,
  maxPar: maxParInstruction,
};

const useCurrentInventory = () => {
  const [showCountMethodsModal, setShowCountMethodsModal] = useState(false);
  const [showNewTabletCountModal, setShowNewTabletCountModal] = useState(false);

  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;

  const info = useInventoryInfo(businessId);
  const locationOptions = useMemo(() => {
    if (!info.data) return [];
    return info.data.locations.map((loc) => ({
      label: loc.name,
      value: loc.id,
    }));
  }, [info.data]);

  const allItems = useAllItemsInfo(businessId, false);
  const invCounts = useMemo(() => allItems?.data.map(i => ({
    uniqueId: i.id,
    isRaw: i.isRaw,
    name: i.name,
    theoreticalStock: i.theoreticalStock,
    productionUnitName: i.productionUnitName,
    minPar: i.minPar,
    maxPar: i.maxPar,
  })), [allItems?.data])

  const scheduledCountCreator = useCreateCount(businessId);
  const createCount = useCallback(
    (dto: CreateCountDTO) => scheduledCountCreator.mutateAsync(dto).then(() => setShowNewTabletCountModal(false)),
    [scheduledCountCreator],
  );


  return {
    invCounts,
    locationOptions,
    createCount,
    columns,
    instructions,
    showCountMethodsModal,
    setShowCountMethodsModal,
    showNewTabletCountModal,
    setShowNewTabletCountModal,
  };
};
export default useCurrentInventory;
