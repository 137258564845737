import firebase from "config/firebase";

import { receiveAllBoards, receiveAllTickets } from "model/actions/boards";

import store from "../model/store";
import { addListener } from "./listeners";

export const fetchBoards = async (
  staffMember: StaffMember,
  isEnterpriseLevel: boolean = false
) => {
  if (isEnterpriseLevel) {
    const unsubscribe = firebase
      .firestore()
      .collection("Boards")
      .where("accountId", "==", staffMember.accountId)
      .onSnapshot(
        async (listenerSn) => {
          const boards: any = [];
          listenerSn.forEach((doc) => boards.push(doc.data()));
          store.dispatch(receiveAllBoards(boards));
        },
        (err) => {
          console.warn("fetch boards error", err);
        }
      );
    addListener("boards", unsubscribe);
  } else {
    const unsubscribe = firebase
      .firestore()
      .collection("Boards")
      .where("businessId", "==", staffMember.businessId)
      .onSnapshot(
        async (listenerSn) => {
          const boards: any = [];
          listenerSn.forEach((doc) => boards.push(doc.data()));
          store.dispatch(receiveAllBoards(boards));
        },
        (err) => {
          console.warn("fetch boards error", err);
        }
      );
    addListener("boards", unsubscribe);
  }
};

export const fetchBoardTickets = async (
  staffMember: StaffMember,
  isEnterpriseLevel: boolean = false
) => {
  if (isEnterpriseLevel) {
    const unsubscribe = firebase
      .firestore()
      .collection("Tickets")
      .where("accountId", "==", staffMember.accountId)
      .where("Deleted", "==", false)
      .onSnapshot(
        async (listenerSn) => {
          const tickets: any = [];
          listenerSn.forEach((doc) => tickets.push(doc.data()));
          store.dispatch(receiveAllTickets(tickets));
        },
        (err) => {
          console.warn("fetch boards error", err);
        }
      );
    addListener("tickets", unsubscribe);
  } else {
    const unsubscribe = firebase
      .firestore()
      .collection("Tickets")
      .where("businessId", "==", staffMember.businessId)
      .where("Deleted", "==", false)
      .onSnapshot(
        async (listenerSn) => {
          const tickets: any = [];
          listenerSn.forEach((doc) => tickets.push(doc.data()));
          store.dispatch(receiveAllTickets(tickets));
        },
        (err) => {
          console.warn("fetch boards error", err);
        }
      );
    addListener("tickets", unsubscribe);
  }
};
