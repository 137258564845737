import _ from "lodash";
import find from "lodash/find";
import moment from "moment-timezone";

import {
  extractShiftDurationInHours,
  formatCentValue,
} from "controllers/reporting";

import { getScheduleForWeekRange } from "../../../controllers/schedule";
import { getWeekDays } from "../../../utils/manager";

type DailyActual = {
  day: string;
  amount: number;
};

type CostScheduleData = {
  scheduleId: string;
  targetScheduleCost: number;
  actualScheduleCost: number;
  amountOverBudget: string;
  dailyActuals: DailyActual[];
  isOverBudget: boolean;
  optionalPrecomposedDuplicateSchedule?: TangoSchedule | null;
  projectedSales: number;
};

export const calculateLabourCost = (
  business: TangoBusiness,
  draftSchedules: TangoSchedule[],
  duplicatedSchedules: TangoSchedule[],
  staffingData: StaffingRedux,
  fellowStaffMembers: StaffMember[]
): CostScheduleData | null => {
  const currentSchedule = staffingData?.currentSchedule;
  if (!currentSchedule?.scheduleId) {
    return null;
  }
  const scheduleType = currentSchedule?.scheduleType;
  let scheduleForCalculations =
    scheduleType === "draft"
      ? draftSchedules?.find(
          (schedule) => schedule?.id === currentSchedule?.scheduleId
        )
      : null;
  if (!scheduleForCalculations) {
    scheduleForCalculations =
      currentSchedule?.optionalPrecomposedDuplicateSchedule;
  }
  if (!scheduleForCalculations) return null;
  const assignedShifts = scheduleForCalculations?.shifts?.filter((shift) =>
    Boolean(shift?.staffId)
  );
  let targetScheduleCost = 100;
  let projectedSales = 100;
  if (
    !_.isNil(scheduleForCalculations?.projectedSales) ||
    scheduleForCalculations?.projectedSales === 0
  ) {
    projectedSales = scheduleForCalculations.projectedSales;
  }
  if (
    !_.isNil(business?.defaultWeeklyStaffBudget) ||
    business?.defaultWeeklyStaffBudget === 0
  ) {
    targetScheduleCost = business.defaultWeeklyStaffBudget;
  }
  if (
    !_.isNil(scheduleForCalculations?.targetScheduleCost) ||
    scheduleForCalculations?.targetScheduleCost === 0
  ) {
    targetScheduleCost = scheduleForCalculations.targetScheduleCost;
  }
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      fellowStaffMembers.find((s) => s?.id === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };
  const actualCostReducer = (accumulator: number, item: TangoShift) => {
    if (item?.staffId) {
      const hoursDuration = Math.abs(
        moment
          .duration(
            moment(item?.startDate.toMillis()).diff(
              moment(item?.endDate.toMillis())
            )
          )
          .asHours()
      );
      return (
        accumulator +
        getPayrateByPostionAndStaffId(item.position, item.staffId) *
          hoursDuration
      );
    }
    return accumulator;
  };
  const groupedDailyActuals = _.groupBy(assignedShifts, (shift) => {
    const shiftStartDateWithTimezone = moment(shift?.startDate?.toDate());
    shiftStartDateWithTimezone.tz(business.timezone, true);
    return shiftStartDateWithTimezone.day();
  });
  const actualScheduleCost = assignedShifts.reduce(actualCostReducer, 0);
  const weekDays = moment.weekdays();
  const dailyActuals: DailyActual[] = weekDays.map((weekday, index) => {
    const shiftsForTheWeekDay = groupedDailyActuals?.[index];
    if (shiftsForTheWeekDay?.length) {
      const weekdayAmount = shiftsForTheWeekDay.reduce(actualCostReducer, 0);
      return {
        day: weekday,
        amount: weekdayAmount,
      };
    }
    return {
      day: weekday,
      amount: 0,
    };
  });
  const amountOverBudget =
    targetScheduleCost < actualScheduleCost
      ? ((actualScheduleCost - targetScheduleCost) / 100).toLocaleString(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        )
      : (0).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
  return {
    scheduleId: scheduleForCalculations?.id,
    targetScheduleCost,
    actualScheduleCost: actualScheduleCost,
    amountOverBudget,
    isOverBudget: targetScheduleCost < actualScheduleCost,
    dailyActuals,
    optionalPrecomposedDuplicateSchedule:
      currentSchedule?.optionalPrecomposedDuplicateSchedule,
    projectedSales,
  };
};

export const generateWeekRangeForSelectedDate = (
  business: TangoBusiness,
  sd: Date
) => {
  if (business) {
    const sdMoment = moment(sd).startOf("day").add(4, "hours");
    const sdNumber = sdMoment.day();
    const payrollFirstDayNumber = moment()
      .day(business?.payrollStartOfTheWeek)
      .get("day");
    if (sdNumber < payrollFirstDayNumber) {
      const adjustedWeekRangeStart = sdMoment
        .clone()
        .subtract(7, "days")
        .day(payrollFirstDayNumber);
      const adjustedSelectedDays = getWeekDays(adjustedWeekRangeStart.toDate());
      return adjustedSelectedDays;
    } else {
      const regularWeekRangeStart = sdMoment.clone().day(payrollFirstDayNumber);
      const regularSelectedDays = getWeekDays(regularWeekRangeStart.toDate());
      return regularSelectedDays;
    }
  } else {
    return [];
  }
};

const extractWtdLaborCost = (
  staffMembers: StaffMember[],
  dateRange: Date[],
  businessSettings: TangoBusinessSettings,
  scheduleForAWeekRange?: TangoSchedule,
  draftScheduleForAWeekRange?: TangoSchedule
) => {
  const numericDataPoints: NumericData[] = dateRange.map((d) =>
    extractNumericDataForDate(
      staffMembers,
      d,
      businessSettings,
      scheduleForAWeekRange,
      draftScheduleForAWeekRange
    )
  );
  return numericDataPoints.reduce(
    (accumulator: number, current: NumericData) =>
      accumulator + current.fullDayLabourCost,
    0
  );
};

type NumericData = {
  totalLabourCost: number;
  fullDayLabourCost: number;
  fohCost: number;
  bohCost: number;
  projectedSales: number;
};

const extractNumericDataForDate = (
  staffMembers: StaffMember[],
  date: Date,
  businessSettings: TangoBusinessSettings,
  scheduleForAWeekRange?: TangoSchedule,
  draftScheduleForAWeekRange?: TangoSchedule
): NumericData => {
  const jobFunctions = businessSettings.jobFunctions || [];
  let totalLabourCost = 0;
  let fullDayLabourCost = 0;
  let fohCost = 0;
  let bohCost = 0;
  let projectedSales = 0;
  if (scheduleForAWeekRange) {
    projectedSales = draftScheduleForAWeekRange?.projectedSales || 0;
    scheduleForAWeekRange.shifts.forEach((shift) => {
      const staffMember = staffMembers.find((sm) => sm.uid === shift.staffId);
      const totalHours = extractShiftDurationInHours(shift);
      if (staffMember) {
        const payRate = find(
          staffMember.payRates,
          (item: PayRate) => item.roleId === shift.position
        );
        const shiftDate = moment(shift.startDate.toDate());
        if (payRate) {
          totalLabourCost += payRate.amount * totalHours;
          if (
            shiftDate.format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY")
          ) {
            const cost = payRate.amount * totalHours;
            fullDayLabourCost += cost;
            Object.keys(jobFunctions).forEach((key) => {
              if (key === shift.position) {
                const jobFunction = jobFunctions[key];
                if (jobFunction.departmentId?.toLowerCase() === "foh") {
                  fohCost += cost;
                } else if (jobFunction.departmentId?.toLowerCase() === "boh") {
                  bohCost += cost;
                }
              }
            });
          }
        }
      }
    });
  }
  return {
    totalLabourCost: formatCentValue(totalLabourCost),
    fullDayLabourCost: formatCentValue(fullDayLabourCost),
    fohCost: formatCentValue(fohCost),
    bohCost: formatCentValue(bohCost),
    projectedSales: formatCentValue(projectedSales),
  };
};

export const generateWeekRangeToDate = (date: Date, weekRange: Date[]) => {
  const sdMoment = moment(date).startOf("day").add(4, "hours");
  const weekRangeToDate = weekRange.filter((d) =>
    moment(d).isSameOrBefore(sdMoment)
  );
  return weekRangeToDate;
};

export const fetchLabourCost = (
  business: TangoBusiness,
  date: Date,
  staffMembers: StaffMember[],
  businessSettings: TangoBusinessSettings,
  publishedSchedules: TangoSchedule[],
  draftSchedules: TangoSchedule[]
) => {
  const weekRange = generateWeekRangeForSelectedDate(business, date);
  const weekRangeToDate = generateWeekRangeToDate(date, weekRange);

  const draftScheduleForAWeekRange = getScheduleForWeekRange(
    weekRange,
    draftSchedules,
    business.timezone
  );
  const scheduleForAWeekRange = getScheduleForWeekRange(
    weekRange,
    publishedSchedules,
    business.timezone
  );

  console.log("scheduleForAWeekRange", scheduleForAWeekRange);

  console.log("draftScheduleForAWeekRange", draftScheduleForAWeekRange);

  const {
    totalLabourCost,
    fullDayLabourCost,
    fohCost,
    bohCost,
    projectedSales,
  } = extractNumericDataForDate(
    staffMembers,
    date,
    businessSettings,
    scheduleForAWeekRange,
    draftScheduleForAWeekRange
  );

  const wtdLaborCost = extractWtdLaborCost(
    staffMembers,
    weekRangeToDate,
    businessSettings,
    scheduleForAWeekRange,
    draftScheduleForAWeekRange
  );

  const publishedFOHSchedule = publishedSchedules.find((publishedSchedule) => {
    if (!scheduleForAWeekRange?.mergedSchedules?.length) {
      return null;
    }
    return (
      publishedSchedule?.departmentId === "foh" &&
      scheduleForAWeekRange.mergedSchedules.includes(publishedSchedule.id)
    );
  });

  return {
    wtdLaborCost,
    totalLabourCost,
    fullDayLabourCost,
    fohCost,
    bohCost,
    projectedSales,
    publishedScheduleId: publishedFOHSchedule?.id ?? null,
  };
};
