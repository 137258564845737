import { Button } from "@blueprintjs/core";
import React from "react";

import Box from "components/Box";

interface Props {
  onNext: () => void;
}

const UnderWriting = ({ onNext }: Props) => (
  <Box className="form-container">
    <div className="slide-in">
      <div className="welcome-title no-width no-margin">
        Final Step.
        <br />
        Link your bank account to receive payouts.
      </div>
      <div className="secure-info">
        All information entered here is secured with 256-bit encryption.
      </div>

      <div className="input-lable">Name on the bank account</div>
      <input placeholder="type your answer here..." className="form-input" />

      <div className="input-lable">Routing Number</div>
      <input
        placeholder="type your answer here..."
        className="form-input"
        type="number"
      />

      <div className="input-lable">Account Number</div>
      <input placeholder="type your answer here..." className="form-input" />

      <Button text="Done" fill className="next-button" onClick={onNext} />
    </div>
  </Box>
);
export default UnderWriting;
