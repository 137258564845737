import _ from "lodash";

import isDev from "utils/currentEnv";

import firebase from "../config/firebase";
import {
  FirebaseModifierDoc,
  FirebaseModifierOptionsDoc,
  ModifierDoc,
  ModifierOptionsDoc,
} from "../types/modifiers";
import { isEqual } from "../utils/data";
import { generateUniquePlu } from "./products";

const db = firebase.firestore();
const modifierOptionsCollection = db.collection("ModifierOptions");
const modifiersCollection = db.collection("Modifiers");

export const performSoftDelete = async (
  modifierOptions: FirebaseModifierOptionsDoc[] | ModifierOptionsDoc[],
  modifiers: FirebaseModifierDoc[]
) => {
  const existingModifierOptions = [];
  const modifiersToUpdate: FirebaseModifierDoc[] = [];
  for (const modifierOption of modifierOptions) {
    if (modifierOption.id && modifierOption.id.length > 0) {
      existingModifierOptions.push({ id: modifierOption.id, deleted: true });
      for (const modifier of modifiers) {
        if (modifier.options.includes(modifierOption.id)) {
          const indexM = modifiers.findIndex((mod) => mod.id === modifier.id);
          const indexU = modifiersToUpdate.findIndex(
            (mod) => mod.id === modifier.id
          );

          const newOptions = (
            indexU !== -1
              ? modifiersToUpdate[indexU].options
              : indexM !== -1
              ? modifiers[indexM].options
              : ([] as string[])
          ).filter((i) => i != modifierOption.id);
          // If we've already update the modifier then check our
          // modifiersToUpdate first to update those options, otherwise
          // let's find it from the modifiers collection. Note that it
          // is possible to be able find an option not being used
          // so an else condition isn't neccessary.
          if (indexU != -1) {
            const newModifier = modifiersToUpdate[indexU];
            newModifier.options = newOptions;
            modifiersToUpdate.push(newModifier);
          } else if (indexM != -1) {
            const newModifier = modifiers[indexM];
            newModifier.options = newOptions;
            modifiersToUpdate.push(newModifier);
          }
        }
      }
    }
  }

  // Batch delete all the existing modifiers
  const existingModifiersChunk = _.chunk(modifiersToUpdate, 500);
  for await (const chunk of existingModifiersChunk) {
    const batch = db.batch();
    chunk.forEach((modifier) => {
      const docRef = modifiersCollection.doc(modifier.id);
      batch.update(docRef, modifier);
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log("ERROR Occured when deleting modifiers: ", err);
      }
    }
  }

  // Batch delete all the existing modifier options
  const existingModifierOptionsChunk = _.chunk(existingModifierOptions, 500);
  for await (const chunk of existingModifierOptionsChunk) {
    const batch = db.batch();
    chunk.forEach((modifierOption) => {
      const docRef = modifierOptionsCollection.doc(modifierOption.id);
      batch.update(docRef, modifierOption);
    });
    try {
      await batch.commit();
    } catch (err) {
      if (isDev()) {
        console.log("ERROR Occured when deleting modifier options: ", err);
      }
    }
  }
};

export const saveData = async (
  newData: ModifierOptionsDoc[],
  existingData: ModifierOptionsDoc[],
  businessId: string,
  keysRef: { [T: string]: string },
  isEnterpriseLevel: boolean,
  allModifiers: ModifierOptionsDoc[],
  allProducts: FirebaseProductDoc[],
  plus: string[]
) => {
  const generatedPlus: string[] = [];
  const modifierOptions = newData.filter((data) => !!data.id);
  const modifierOptionIds: string[] = modifierOptions.map((data) => data.id);

  const firebaseModifierOptions: FirebaseModifierOptionsDoc[] = newData
    .map((modifierOption, index) => ({ ...modifierOption, index: index }))
    .filter((modifierOption) => {
      const modifierOptionToCheck = _.omit(modifierOption, "index");
      const foundItem = existingData.some((existingModifierOption) =>
        isEqual(existingModifierOption, modifierOptionToCheck)
      );

      // Remove modifier option documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((modifierOption, index) => {
      let uniquePlu = "";
      if (!modifierOption.plu) {
        if (plus.length > 0) {
          uniquePlu = plus[index];
        } else {
          const joinedPlus = ([] as string[]).concat.apply(plus, generatedPlus);
          const concatPlus = joinedPlus.concat(
            allProducts.map((product) => product.plu || "0"),
            allModifiers.map((product) => product.plu || "0")
          );
          uniquePlu = generateUniquePlu(concatPlus);
          generatedPlus.push(uniquePlu);
        }
      }

      const modifierOptionsInBusinessData = allModifiers.find(
        (modifierOptionInBusinessData) =>
          modifierOptionInBusinessData.plu === (modifierOption.plu || uniquePlu)
      );

      return {
        // @ts-ignore
        additionalCost: parseFloat(modifierOption.modifierOptionPrice) || 0,
        businessId: businessId,
        createdAt: new Date(),
        deleted: false,
        enabled: true,
        id: modifierOptionsInBusinessData?.id ?? modifierOption.id,
        ingredients: [],
        ingredientsQuantity: [],
        name: modifierOption.modifierOptionName,
        plu: modifierOption.plu || uniquePlu,
        updatedAt: new Date(),
        enterpriseUpdatedDocument: isEnterpriseLevel,
      };
    });

  const newModifierOptions: {
    id: string;
    plu: string | null;
    modifierOptionName: string;
    modifierOptionPrice: number;
    enterpriseUpdatedDocument: boolean;
  }[] = _.union(
    newData
      .filter((modifierOption) => !!modifierOption.id)
      .map((modifierOption) => ({
        id: modifierOption.id,
        plu: modifierOption.plu || "",
        modifierOptionName: modifierOption.modifierOptionName,
        modifierOptionPrice:
          // @ts-ignore
          parseFloat(modifierOption.modifierOptionPrice) || 0,
        enterpriseUpdatedDocument: isEnterpriseLevel,
      })),
    []
  );

  const newFirebaseModifierOptions: FirebaseModifierOptionsDoc[] = [];
  const newOptions = [];
  const existingOptions = [];
  for (const modifierOption of firebaseModifierOptions) {
    // TODO: This is never true - it always creates a new document (id is always '')
    // For existing modifier options - just find the differences to update
    if (modifierOption.id) {
      // Unlike all the other tables where all the items in the table
      // have the same existingData and newData - in the modifier options
      // modal - you'll only see a subset of the modifier option in the
      // newData
      const existingDataIndex = existingData.findIndex(
        (existingProduct) => existingProduct.id === modifierOption.id
      );
      const newDataWithPLU = newData.find((d) => d.plu === modifierOption.plu);
      // const newDataIndex = newData.findIndex(
      //   (newProduct) => newProduct.id === modifierOption.id
      // );

      if (existingDataIndex !== -1 && newDataWithPLU) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[existingDataIndex]
        )
          // @ts-ignore
          .filter(
            (key) =>
              !isEqual(
                //@ts-ignore
                existingData[existingDataIndex][key],
                //@ts-ignore
                newDataWithPLU[key]
              )
          )
          .reduce(
            (acc, key) => ({
              ...acc,
              // @ts-ignore
              [keysRef[key]]: modifierOption[keysRef[key]],
              enterpriseUpdatedDocument: isEnterpriseLevel,
            }),
            {}
          );

        const finalModifierOption = _.omit(modifierOption, "index");
        newFirebaseModifierOptions.push(finalModifierOption);

        existingOptions.push({ id: modifierOption.id, ...dataToUpdate });

        if (
          !newModifierOptions
            .map((modifierOption) => modifierOption.id)
            .includes(modifierOption.id)
        ) {
          newModifierOptions.push({
            id: modifierOption.id,
            plu: modifierOption.plu || "",
            modifierOptionName: modifierOption.name,
            modifierOptionPrice: modifierOption.additionalCost,
            enterpriseUpdatedDocument: isEnterpriseLevel,
          });
        }
      } else {
        const docRef = modifierOptionsCollection.doc();
        modifierOption["id"] = docRef.id;

        const finalModifierOption = _.omit(modifierOption, "index");
        newFirebaseModifierOptions.push(finalModifierOption);

        modifierOptionIds.push(modifierOption.id);

        newOptions.push(finalModifierOption);

        if (
          !newModifierOptions
            .map((modifierOption) => modifierOption.id)
            .includes(modifierOption.id)
        ) {
          newModifierOptions.push({
            id: modifierOption.id,
            plu: modifierOption.plu || "",
            modifierOptionName: modifierOption.name,
            modifierOptionPrice: modifierOption.additionalCost,
            enterpriseUpdatedDocument: isEnterpriseLevel,
          });
        }
      }
    } else {
      const docRef = modifierOptionsCollection.doc();
      modifierOption["id"] = docRef.id;

      const finalModifierOption = _.omit(modifierOption, "index");
      newFirebaseModifierOptions.push(finalModifierOption);

      modifierOptionIds.push(modifierOption.id);

      newOptions.push(finalModifierOption);

      if (
        !newModifierOptions
          .map((modifierOption) => modifierOption.id)
          .includes(modifierOption.id)
      ) {
        newModifierOptions.push({
          id: modifierOption.id,
          plu: modifierOption.plu || "",
          modifierOptionName: modifierOption.name,
          modifierOptionPrice: modifierOption.additionalCost,
          enterpriseUpdatedDocument: isEnterpriseLevel,
        });
      }
    }
  }

  // Batch write new modifier options
  const newOptionsChunk = _.chunk(newOptions, 500);
  for await (const chunk of newOptionsChunk) {
    const batch = db.batch();
    chunk.forEach((option) => {
      const docRef = modifierOptionsCollection.doc(option.id);
      batch.set(docRef, option);
    });
    await batch.commit();
  }

  // Batch write existing modifier options
  const existingOptionsChunk = _.chunk(existingOptions, 500);
  for await (const chunk of existingOptionsChunk) {
    const batch = db.batch();
    chunk.forEach((option) => {
      const docRef = modifierOptionsCollection.doc(option.id);
      batch.update(docRef, option);
    });
    await batch.commit();
  }
  return {
    modifierOptions: newModifierOptions,
    firebaseModifierOptions: firebaseModifierOptions,
    updated: firebaseModifierOptions.length > 0,
    generatedPlus,
  };
};
