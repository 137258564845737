import { WorkspaceAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function workspaces(
  state = initialState,
  action: WorkspaceAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_WORKSPACES:
      return action.workspaces;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
