import { Icon } from "@blueprintjs/core";
import React from "react";

import Box from "../../../../../components/Box";

interface Props {
  addChoice: () => void;
  options: { leftArray: string[]; rightArray: string[] };
  updateChoice: (value: string, type: string, choiceIndex: number) => void;
  color: string;
  deleteOption: (index: number) => void;
}
const Matching = ({
  addChoice,
  options,
  updateChoice,
  color,
  deleteOption,
}: Props) => {
  return (
    <div className="multiple-choice-container matching-component">
      <div className="choices-container">
        <Box display="flex">
          <div className="left-part">
            {options.leftArray
              ? options.leftArray.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`single-choice-container active`}
                      style={{
                        color,
                        borderColor: color,
                        backgroundColor: color + "0C",
                      }}
                    >
                      <div
                        className="key-bind apply-font"
                        style={{ color, borderColor: color }}
                      >
                        {index + 1}
                      </div>
                      <input
                        style={{ color }}
                        className="choice-name apply-font"
                        value={item}
                        placeholder="Type Choice"
                        onChange={(e) =>
                          updateChoice(e.target.value, "leftArray", index)
                        }
                      />
                    </div>
                  );
                })
              : null}
          </div>
          <div className="right-part">
            {options.rightArray
              ? options.rightArray.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`single-choice-container active`}
                      style={{
                        color,
                        borderColor: color,
                        backgroundColor: color + "0C",
                      }}
                    >
                      <div
                        className="key-bind apply-font"
                        style={{ color, borderColor: color }}
                      >
                        {index + 1}
                      </div>
                      <input
                        style={{ color }}
                        className="choice-name apply-font"
                        value={item}
                        placeholder="Type Choice"
                        onChange={(e) =>
                          updateChoice(e.target.value, "rightArray", index)
                        }
                      />
                      <Icon
                        onClick={() => deleteOption(index)}
                        icon="cross"
                        className="delete-option-icon"
                        color={color}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </Box>
      </div>
      <div
        className="add-choice apply-font"
        onClick={addChoice}
        style={{ color }}
      >
        Add Another choice
      </div>
    </div>
  );
};
export default Matching;
