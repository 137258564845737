import { Dialog, EditableText } from "@blueprintjs/core";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "model/store";

import { updateBusinessSetting } from "../../../../../models/businesses";
import "../modal.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const SpeedStationModal: FC<Props> = ({ isOpen, closeModal }) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [minAmount, setMinAmount] = useState(
    businessSettings && businessSettings.speedMinimumTipForPercentagePresets
  );
  const [tipDollars, setTipDollars] = useState(
    businessSettings && businessSettings.speedTipPresetAmounts
  );
  const [tipPrecentages, setTipPercentages] = useState(
    businessSettings && businessSettings.speedTipPresetPercentages
  );
  const [preAuthAmount, setPreauthAmount] = useState(
    businessSettings && businessSettings.preAuthAmount
  );

  const [newTipPercent, setNewTipPercent] = useState("");
  const [newTipDollar, setNewTipDollar] = useState("");
  const [minDollar, setNewMinDollar] = useState("");
  const [newPreAuth, setNewPreAuth] = useState("");

  const [selfPay, setSelfPay] = useState(
    businessSettings && businessSettings.selfPay
      ? { label: "Enabled", value: true }
      : { label: "Disabled", value: false }
  );

  const [roundingType, setRoundingType] = useState(
    businessSettings && businessSettings.speedCashRoundType === "down"
      ? { label: "Round Down", value: "down" }
      : businessSettings && businessSettings.speedCashRoundType === "to"
      ? { label: "Round Up", value: "to" }
      : { label: "Disabled", value: "off" }
  );

  const [roundingAmount, setRoundingAmount] = useState(
    businessSettings && businessSettings.speedCashRoundAmount === 1
      ? { label: "$1", value: 1 }
      : businessSettings && businessSettings.speedCashRoundAmount === 0.5
      ? { label: "$0.5", value: 0.5 }
      : businessSettings && businessSettings.speedCashRoundAmount === 0.25
      ? { label: "$0.25", value: 0.25 }
      : { label: "$0.0", value: 0.0 }
  );

  const [compsSetting, setCompsSetting] = useState(
    businessSettings && businessSettings.requirePinCodeForCompsInSpeed
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [refundsSetting, setRefundsSetting] = useState(
    businessSettings && businessSettings.requirePinCodeForRefundsInSpeed
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [receiptPrompt, setReceiptPrompt] = useState(
    businessSettings && businessSettings.speedReceiptPrompt
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [discountsSetting, setDiscountsSetting] = useState(
    businessSettings && businessSettings.requirePinCodeForDiscountsInSpeed
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );
  const [carryOut, setCarryOut] = useState(
    businessSettings && businessSettings.carryOutEnabledInSpeed
      ? { label: "Enabled", value: true }
      : { label: "Disabled", value: false }
  );

  const [tableService, setTableService] = useState(
    businessSettings && businessSettings.businessDoesTableService
      ? { label: "Enabled", value: true }
      : { label: "Disabled", value: false }
  );

  const handleChangeTipPercent = async (option: number) => {
    if (
      !newTipPercent ||
      !isFinite(parseFloat(newTipPercent)) ||
      !parseFloat(newTipPercent) ||
      parseFloat(newTipPercent) < 0
    ) {
      return setNewTipPercent("");
    }

    tipPrecentages[option] = parseInt(newTipPercent);

    const success = await updateBusinessSetting(
      business.id,
      "speedTipPresetPercentages",
      tipPrecentages
    );
    if (success) {
      console.log("success tipPresetPercentages");
    } else {
      alert("There was a problem updating tip percentages.");
    }
  };

  const handleChangeTipDollar = async (option: number) => {
    if (
      !newTipDollar ||
      !isFinite(parseFloat(newTipDollar)) ||
      !parseFloat(newTipDollar) ||
      parseFloat(newTipDollar) < 0
    ) {
      return setNewTipDollar("");
    }

    tipDollars[option] = parseFloat(newTipDollar);
    console.log("new options", tipDollars);

    const success = await updateBusinessSetting(
      business.id,
      "speedTipPresetAmounts",
      tipDollars
    );
    if (success) {
      console.log("success tipPresetAmounts");
      // setTipDollars(newTipDollars)
    } else {
      alert("There was a problem updating tip dollar amounts.");
    }
  };
  const handleChangePreAuth = async () => {
    console.log("set new preauth", newPreAuth);
    //@ts-ignore
    if (
      !newPreAuth ||
      !isFinite(parseInt(newPreAuth)) ||
      !parseInt(newPreAuth) ||
      parseInt(newPreAuth) < 0
    ) {
      return setNewPreAuth("");
    }

    const success = await updateBusinessSetting(
      business.id,
      "preAuthAmount",
      parseFloat(newPreAuth)
    );
    if (success) {
      console.log("success preAuthAmount");
      setPreauthAmount(parseFloat(newPreAuth));
    } else {
      alert("There was a problem updating the preauth amount.");
    }
  };

  const handleChangeMinDollar = async () => {
    console.log("set min dollar", minDollar);
    if (
      !minDollar ||
      !isFinite(parseInt(minDollar)) ||
      !parseInt(minDollar) ||
      parseInt(minDollar) < 0
    ) {
      return setNewMinDollar("");
    }

    const success = await updateBusinessSetting(
      business.id,
      "speedMinimumTipForPercentagePresets",
      parseFloat(minDollar)
    );
    if (success) {
      console.log("success speedMinimumTipForPercentagePresets");
      setMinAmount(parseFloat(minDollar));
    } else {
      alert("There was a problem updating minimum value.");
    }
  };

  const handleToggleSelfPay = async (option: any) => {
    const success = await updateBusinessSetting(
      business.id,
      "selfPay",
      option.value
    );
    if (success) {
      console.log("success update self pay");
      setSelfPay(option);
    } else {
      alert("There was a problem updating self pay settings");
    }
  };

  const handleToggleRoundingType = async (option: any) => {
    const success = await updateBusinessSetting(
      business.id,
      "speedCashRoundType",
      option.value
    );
    if (success) {
      console.log("success cashRoundType");
      setRoundingType(option);
    } else {
      alert("There was a problem updating cash rounding setting.");
    }
  };

  const handleToggleRoundingAmount = async (option: any) => {
    console.log("handle toggle self pay", option, business.id);

    const success = await updateBusinessSetting(
      business.id,
      "speedCashRoundAmount",
      option.value
    );
    if (success) {
      console.log("success cashRoundAmount");
      setRoundingAmount(option);
    } else {
      alert("There was a problem updating cash rounding amount.");
    }
  };

  const handleToggleCarryout = async (option: any) => {
    const success = await updateBusinessSetting(
      business.id,
      "carryOutEnabledInSpeed",
      option.value
    );
    if (success) {
      console.log("success carryOutEnabledInSpeed");
      setCarryOut(option);
    } else {
      alert("There was a problem updating carry out settings.");
    }
  };

  const handleToggleTableService = async (option: any) => {
    const success = await updateBusinessSetting(
      business.id,
      "businessDoesTableService",
      option.value
    );
    if (success) {
      console.log("success businessDoesTableService");
      setTableService(option);
    } else {
      alert("There was a problem updating tableService settings.");
    }
  };
  // const handleTogglePreAuthAmount = async (option:any) =>{
  //   console.log('handle toggle self pay', option, business.id)

  //   const success = await updateBusinessSetting(business.id, "preAuthAmount", option.value)
  //   if(success){
  //     console.log("success preAuthAmount")
  //     setPreauthAmount(option)
  //   }else{
  //     alert("There was a problem updating preauth amount.")
  //   }
  // }

  const handleTogglePermission = async (option: any, permission: string) => {
    let keyToUpdate = "requirePinCodeForDiscountsInSpeed";
    if (permission === "comps") {
      keyToUpdate = "requirePinCodeForCompsInSpeed";
    }
    if (permission === "refunds") {
      keyToUpdate = "requirePinCodeForRefundsInSpeed";
    }
    const success = await updateBusinessSetting(
      business.id,
      keyToUpdate,
      option.value
    );
    if (success) {
      console.log("success update speed setting", keyToUpdate);
      if (permission === "comps") {
        setCompsSetting(option);
      }
      if (permission === "refunds") {
        setRefundsSetting(option);
      }
      if (permission === "discounts") {
        setDiscountsSetting(option);
      }
    } else {
      alert("There was a problem updating your setting.");
    }
  };

  if (business && businessSettings) {
    return (
      <Dialog isOpen={isOpen} className="settings-modal" onClose={closeModal}>
        <div className="modal-header">
          <div className="modal-title">Speed Preferences</div>
          <div className="modal-icon speed">
            <img
              style={{ height: 15 }}
              src={require("../../../../../assets/tango-icon.svg").default}
            />
            <div className="icon-title">speed</div>
          </div>
        </div>
        <div className="modal-body">
          <div className="section-title">General</div>
          <div className="section-row">
            <div className="row-title">Guest Self Pay Mode</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleToggleSelfPay(option);
                }}
                options={[
                  {
                    label: "Enabled",
                    value: true,
                  },
                  {
                    label: "Disabled",
                    value: false,
                  },
                ]}
                value={selfPay}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Carry out orders in speed</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleToggleCarryout(option);
                }}
                options={[
                  {
                    label: "Enabled",
                    value: true,
                  },
                  {
                    label: "Disabled",
                    value: false,
                  },
                ]}
                value={carryOut}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Table service in speed</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleToggleTableService(option);
                }}
                options={[
                  {
                    label: "Enabled",
                    value: true,
                  },
                  {
                    label: "Disabled",
                    value: false,
                  },
                ]}
                value={tableService}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Cash Rounding Type</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleToggleRoundingType(option);
                }}
                options={[
                  {
                    label: "Round Down",
                    value: "down",
                  },
                  {
                    label: "Round Up",
                    value: "to",
                  },
                  {
                    label: "Disabled",
                    value: "off",
                  },
                ]}
                value={roundingType}
              />
            </div>
          </div>
          <div className="section-row">
            <div className="row-title">Cash Rounding Amount</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleToggleRoundingAmount(option);
                }}
                options={[
                  {
                    label: "$1",
                    value: 1.0,
                  },
                  {
                    label: "$0.50",
                    value: 0.5,
                  },
                  {
                    label: "$0.25",
                    value: 0.25,
                  },
                ]}
                value={roundingAmount}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Pre-auth Amount (For Tabs)</div>

            <div className="row-field" style={{ width: "50px" }}>
              <span>$ </span>
              <EditableText
                className="editableText"
                maxLength={4}
                defaultValue={
                  preAuthAmount ? JSON.stringify(preAuthAmount) : "0.0"
                } //starting value
                // value={newPreAuth} //after calling onChange
                onChange={(e) => setNewPreAuth(e)}
                onConfirm={() => {
                  handleChangePreAuth();
                }} //on click away this fires
              />
            </div>
          </div>

          <div className="section-title section-margin">Tip preferences</div>
          <div className="section-row extra-margin">
            <div className="row-title">Tip presets (%)</div>
            <div className="tips-row row-field">
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[0]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(0);
                  }} //on click away this fires
                />
                %
              </div>
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[1]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(1);
                  }} //on click away this fires
                />
                %<div className="tip-selected">default</div>
              </div>
              <div className="tip-container">
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipPrecentages ? JSON.stringify(tipPrecentages[2]) : "0.0"
                  } //starting value
                  // value={newTipPercent} //after calling onChange -> setNewTipPercent
                  onChange={(e) => setNewTipPercent(e)}
                  onConfirm={() => {
                    handleChangeTipPercent(2);
                  }} //on click away this fires
                />
                %
              </div>
            </div>
          </div>

          <br />

          <div className="section-row extra-margin">
            <div className="row-title">($) tip for orders under $xx</div>
            <div className="row-field only-text">
              $
              <EditableText
                className="editableText"
                maxLength={2}
                defaultValue={minAmount ? JSON.stringify(minAmount) : "0.0"} //starting value
                onChange={(e) => setNewMinDollar(e)}
                onConfirm={() => {
                  handleChangeMinDollar();
                }} //on click away this fires
              />
            </div>
          </div>

          <br />

          <div className="section-row">
            <div className="row-title">Tip presets for orders under $xx</div>
            <div className="tips-row row-field">
              <div className="tip-container">
                $
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[0]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(0);
                  }} //on click away this fires
                />
              </div>
              <div className="tip-container">
                ${" "}
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[1]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(1);
                  }} //on click away this fires
                />
                <div className="tip-selected">default</div>
              </div>
              <div className="tip-container">
                ${" "}
                <EditableText
                  className="editableText"
                  maxLength={2}
                  defaultValue={
                    tipDollars ? JSON.stringify(tipDollars[2]) : "0.0"
                  } //starting value
                  onChange={(e) => setNewTipDollar(e)}
                  onConfirm={() => {
                    handleChangeTipDollar(2);
                  }} //on click away this fires
                />
              </div>
            </div>
          </div>

          <br />

          <div className="section-title">Permissions</div>

          <div className="section-row">
            <div className="row-title">Only managers can apply comps</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleTogglePermission(option, "comps");
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={compsSetting}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Only managers can apply discounts</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleTogglePermission(option, "discounts");
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={discountsSetting}
              />
            </div>
          </div>

          <div className="section-row">
            <div className="row-title">Only managers can process refunds</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={(option) => {
                  handleTogglePermission(option, "refunds");
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={refundsSetting}
              />
            </div>
          </div>

          <br />

          <div className="section-title">Receipt preferences</div>
          <div className="section-row">
            <div className="row-title">No receipts</div>
            <div className="row-field">
              <Select
                className="flex-react-select"
                onChange={async (option) => {
                  if (option) {
                    const success = await updateBusinessSetting(
                      business.id,
                      "speedReceiptPrompt",
                      option.value
                    );
                    if (success) {
                      setReceiptPrompt(option);
                    }
                  }
                }}
                options={[
                  {
                    label: "True",
                    value: true,
                  },
                  {
                    label: "False",
                    value: false,
                  },
                ]}
                value={receiptPrompt}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return null;
};

export default SpeedStationModal;
