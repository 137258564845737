import { Dialog } from "@blueprintjs/core";
import comps from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo, useState } from "react";

import TextEditor from "../../../../../components/TextEditor";
import "./style.css";

const { Dropdown, TextField, Button } = comps;

export type Note = {
  title: string;
  body: string;
  authorId: string;
  tagIds: string[];
};

type DropdownOption = {
  label: string;
  value: string;
  color?: string;
};

type Person = { id: string; firstName: string; lastName: string };
type Props = {
  open: boolean;
  onClose: () => unknown;
  onSubmit: (note: Note) => unknown;
  people: Person[];
  tagOptions: string[];
};

const NoteDialog = (props: Props) => {
  const { open, onClose, people, tagOptions, onSubmit } = props;
  const labeledPeople: DropdownOption[] = useMemo(
    () =>
      people.map(({ id, firstName, lastName }) => ({
        value: id,
        label: firstName + " " + lastName,
      })),
    [people]
  );
  const valuedPeople = useMemo(
    () =>
      people.map(({ id, firstName, lastName }) => ({
        id,
        value: firstName + " " + lastName,
      })),
    [people]
  );
  const [tagSearch, setTagSearch] = useState("");
  const labeledTags: DropdownOption[] = useMemo(() => {
    const existingOptions = tagOptions.map((label) => ({
      value: label,
      label,
    }));
    if (!tagSearch) return existingOptions;
    if (tagOptions.includes(tagSearch)) return existingOptions;
    existingOptions.unshift({
      value: tagSearch,
      label: tagSearch + " (create tag)",
    });
    return existingOptions;
  }, [tagOptions, tagSearch]);
  const [tags, setTags] = useState<DropdownOption[]>([]);
  const [author, setAuthor] = useState(labeledPeople[0]);
  const updateAuthor = useCallback((opt) => {
    // shouldn't be reachable
    if (Array.isArray(opt)) throw "only one author allowed";
    setAuthor(opt);
  }, []);
  const updateTags = useCallback((opt: unknown) => {
    const remapped = (opt as DropdownOption[]).map((option) => ({
      label: option.value, // remove " (new tag)"
      value: option.value,
      color: option.color,
    }));
    setTags(remapped);
    setTagSearch("");
  }, []);

  const [title, setTitle] = useState("");
  const updateTitle = useCallback((evt) => setTitle(evt.target.value), []);
  const [message, setMessage] = useState("");

  const valid = useMemo(() => {
    if (!title) return false;
    if (!message) return false;
    if (message == "<p><br></p>") return false;

    return true;
  }, [message, title]);

  const submit = useCallback(
    () =>
      onSubmit({
        title,
        body: message,
        authorId: author.value,
        tagIds: tags.map((tag) => tag.value),
      }),
    [title, message, author, tags]
  );
  return (
    <Dialog className="new-note-modal" isOpen={open} onClose={onClose}>
      <button className="close-note-modal-button" onClick={onClose}>
        +
      </button>
      <div className="note-modal-title">New Note</div>
      <div className="note-modal-scroll-body">
        <div className="note-input-label">Author</div>
        <Dropdown
          size="medium"
          className="note-input-element"
          options={labeledPeople}
          value={author}
          onChange={updateAuthor}
        />
        <div className="note-input-label">Title</div>
        <TextField
          className="note-input-element"
          sizeType="small"
          value={title}
          onChange={updateTitle}
        />
        <div className="note-input-label">Tags</div>
        <Dropdown
          isMulti
          value={tags}
          options={labeledTags}
          onChange={updateTags}
          size="small"
          className="note-input-element"
          onInputChange={setTagSearch}
        />
        <div className="note-input-label">Message</div>
        <TextEditor
          initialValue=""
          taggables={valuedPeople}
          placeholder="Type your message here"
          onChange={setMessage}
        />
      </div>
      <div className="note-modal-button-row">
        <Button
          className="note-modal-button close-note-modal"
          label="close"
          type="btn-style-1"
          size="btn-large"
          onClick={onClose}
        />
        <Button
          onClick={submit}
          className="note-modal-button save-note-modal"
          label="save"
          type="btn-style-1"
          size="btn-large"
          disabled={!valid}
        />
      </div>
    </Dialog>
  );
};

export default NoteDialog;
