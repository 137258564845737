import firebase from "config/firebase";
import _ from "lodash";

import { isIterable } from "utils/data";

import {
  composeUsableBusinessSettings,
  composeUsableStaffMember,
  composeUsableTangoAccount,
  composeUsableTangoBusiness,
} from "./composableTypes";

const accountsCollection = firebase.firestore().collection("Accounts");
const businessesCollection = firebase.firestore().collection("Businesses");
const businessSettingsCollection = firebase
  .firestore()
  .collection("BusinessSettings");
const staffCollection = firebase.firestore().collection("Staff");

export const fetchAccountIdsWithParentId = async (accountId: string) => {
  const querySnapshot = await accountsCollection
    .where("id", "==", accountId)
    .get();

  const results: { id: string; parentId: string }[] = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    results.push(data as { id: string; parentId: string });
  });

  const accountIds = _.uniq(results.map((i) => i.parentId)).filter((i) => !!i);
  console.log("Parent idsss: ", accountIds);

  if (accountIds.length > 0) {
    const parentQuerySnapshot = await accountsCollection
      .where("parentId", "==", accountIds[0])
      .get();

    const parentResults: { id: string; parentId: string }[] = [];
    parentQuerySnapshot.forEach((doc) => {
      const data = doc.data();
      parentResults.push(data as { id: string; parentId: string });
    });

    console.log("Parent id: ", accountIds);

    if (parentResults.length > 0) {
      return parentResults;
    }
  }

  return results;
};

export const fetchBusinessesByAccountsIds = async (accountIds: string[]) => {
  const businesses: TangoBusiness[] = [];
  for await (const id of accountIds) {
    const querySnapshot = await businessesCollection
      .where("accountId", "==", id)
      .get();

    querySnapshot.forEach((doc) => {
      const data = composeUsableTangoBusiness(doc.data());
      businesses.push(data);
    });
  }
  return businesses;
};

export const fetchAccountsByIds = async (accountIds: string[]) => {
  const accounts: TangoAccount[] = [];
  for await (const id of accountIds) {
    const doc = await accountsCollection.doc(id).get();
    if (doc.exists) {
      const data = doc.data();
      if (data) {
        accounts.push(composeUsableTangoAccount(data));
      }
    }
  }
  return accounts;
};

export const fetchCollectionByBusinessIds = async <T>(
  businessIds: string[],
  collectionName: string,
  composer: (result: DocumentData) => T
): Promise<T[]> => {
  const results: T[] = [];
  for await (const id of businessIds) {
    console.log("Getting " + collectionName + " for " + id);
    const querySnapshot = await firebase
      .firestore()
      .collection(collectionName)
      .where("businessId", "==", id)
      .where("deleted", "==", false)
      .get();
    querySnapshot.forEach((doc) => {
      const data = composer(doc.data());
      results.push(data);
    });
  }

  return results;
};

export const fetchBusinessSettingsByBusinessIds = async (
  businessIds: string[]
) => {
  let businessSettings: any = {};
  for await (const id of businessIds) {
    const doc = await businessSettingsCollection.doc(id).get();

    if (doc.exists) {
      const response = doc.data();
      const data = response ? composeUsableBusinessSettings(response) : null;

      if (data && typeof data === "object" && Object.keys(data).length > 0) {
        if (!Object.keys(businessSettings).length) {
          businessSettings = data;
        } else {
          Object.keys(data)
            .filter((key) => typeof data[key] === "object")
            .reduce((acc, key) => {
              const canIterate = isIterable(data[key]);
              if (canIterate) {
                if (Array.isArray(acc[key])) {
                  acc[key] = [...acc[key], ...data[key]];
                } else {
                  acc[key] = { ...acc[key], ...data[key] };
                }
              }

              return acc;
            }, businessSettings);
        }
      }
    }

    // querySnapshot.forEach((doc) => {
    //   const data = composeUsableStaffMember(doc.data());
    //   staffMembers.push(data);
    // });
  }
  return businessSettings;
};

export const fetchStaffMembersByBusinessIds = async (businessIds: string[]) => {
  const staffMembers: StaffMember[] = [];
  for await (const id of businessIds) {
    const querySnapshot = await staffCollection
      .where("businessId", "==", id)
      .where("deleted", "==", false)
      .get();

    querySnapshot.forEach((doc) => {
      const data = composeUsableStaffMember(doc.data());
      staffMembers.push(data);
    });
  }
  return staffMembers;
};

export const getAccountByBusinessId = async (businessId: string) => {
  const doc = await businessesCollection.doc(businessId).get();
  if (doc.exists) {
    const bDoc = doc.data();
    if (bDoc?.accountId) {
      const aDoc = await accountsCollection.doc(bDoc.accountId).get();
      if (aDoc.exists) {
        const data = aDoc.data();
        if (data) {
          return composeUsableTangoAccount(data);
        }
      }
    }
  }

  return null;
};
