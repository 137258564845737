import { Dialog, Icon } from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "model/store";

import "./style.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedDays: string[];
  setSelectedDays: (days: string[]) => void;
}
const DaysModal = ({
  isOpen,
  closeModal,
  selectedDays,
  setSelectedDays,
}: Props) => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const momentWeekDays = moment.weekdays();

  const startOfTheWeek = business.payrollStartOfTheWeek || "Monday";
  const reworkedDays = momentWeekDays.slice(
    momentWeekDays.indexOf(startOfTheWeek),
    momentWeekDays.length
  );
  const missingDaysToPrepend = momentWeekDays.slice(
    0,
    momentWeekDays.indexOf(startOfTheWeek)
  );
  const days = [...reworkedDays, ...missingDaysToPrepend];

  const toggleCheckbox = (item: string, index: number) => {
    const copiedDays = [...selectedDays];
    const foundIndex = copiedDays.indexOf(item);
    if (foundIndex === -1) {
      copiedDays.push(item);
    } else {
      copiedDays.splice(foundIndex, 1);
    }
    setSelectedDays(copiedDays);
  };
  const checkRole = (item: string) => {
    const foundIndex = selectedDays.indexOf(item);
    if (foundIndex === -1) {
      return false;
    }
    return true;
  };
  return (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose
      onClose={closeModal}
      className="print-schedule-modal"
    >
      <Box
        className="modal-header"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="modal-back-button" onClick={closeModal}>
          <Icon icon="cross" iconSize={8} />
        </div>
        <Box>
          <div>Select Days</div>
        </Box>
        <div />
      </Box>
      <Box className="modal-body">
        <ul>
          {days.map((item, index) => {
            const checked = checkRole(item);
            return (
              <li
                className="role-item"
                key={index}
                onClick={() => toggleCheckbox(item, index)}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <div className={`checkbox ${checked ? "checked" : ""}`}>
                    {checked && <Icon icon="tick" iconSize={9} color="#fff" />}
                  </div>
                  <div>{item}</div>
                </Box>
              </li>
            );
          })}
        </ul>
      </Box>
    </Dialog>
  );
};
export default DaysModal;
