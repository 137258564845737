import UIComponents from "@tangopay/tango-ui-library";
import { CountFrequency, CreateCountDTO } from "controllers/inventoryCounts";
import moment from "moment";
import React, { useCallback, useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  locationOptions: { label: string; value: string }[];
  onSubmit: (dto: CreateCountDTO) => unknown;
}

type RepeatOption = {
  label: string;
  value: CountFrequency
}
const repeatOptions: RepeatOption[] = [
  {
    label: "Daily",
    value: "day",
  },
  {
    label: "Weekly",
    value: "week",
  },
  {
    label: "Monthly",
    value: "month",
  },
];

type CountType =
  | "all"
  | "prepOnly"
  | "rawOnly"
  | "keyOnly"
  | "keyPrepOnly"
  | "keyRawOnly"

type CountTypeOption = {
  label: string,
  value: CountType,
}
const typeOptions: CountTypeOption[] = [
  {
    label: "All items",
    value: "all",
  },
  {
    label: "Prep items",
    value: "prepOnly",
  },
  {
    label: "Raw items",
    value: "rawOnly",
  },
  {
    label: "Key items",
    value: "keyOnly",
  },
  {
    label: "Key prep items",
    value: "keyPrepOnly",
  },
  {
    label: "Key raw items",
    value: "keyRawOnly",
  },
];

const { Modal, DatePickerInput, Dropdown, Button } = UIComponents;
const NewTabletCountModal = ({ isOpen, onClose, locationOptions, onSubmit }: Props) => {
  const [date, setDate] = useState(new Date());
  const [frequency, setFrequency] = useState<RepeatOption | null>(null);
  const handleFrequencySelect = useCallback((newVal) => {
    setFrequency(newVal);
  }, []);
  const [countType, setCountType] = useState<CountTypeOption>(typeOptions[0]);
  const handleCountTypeSelect = useCallback((newVal) => {
    setCountType(newVal);
  }, []);
  const [locations, setLocations] = useState([]);
  const handleLocationsSelect = useCallback((newVal) => {
    setLocations(newVal);
  }, []);

  const submit = useCallback(() => {
    const freq = frequency?.value;
    if (!freq) return;
    const selDate = moment(date);
    let offsetDays = 1;
    if (freq == "week") {
      // days after sunday
      offsetDays = selDate.day();
    } else if (freq == "month") {
      offsetDays = selDate.date();
    }
    const selectedCountType = countType.value
    const keyItemsOnly = selectedCountType.startsWith("key");
    const rawItemsOnly = (
      selectedCountType == "rawOnly"
      || selectedCountType == "keyRawOnly"
    );
    const prepItemsOnly = (
      selectedCountType == "prepOnly"
      || selectedCountType == "keyPrepOnly"
    );
    const dto = {
      type: "tablet" as "tablet",
      locations: locations.map(({ id }) => id),
      frequency: freq,
      offsetDays,
      keyItemsOnly,
      rawItemsOnly,
      prepItemsOnly,
    }
    onSubmit(dto);
  }, [frequency, date, countType, locations, onSubmit]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose} width={"736"}>
      <div className={"text-big text-black font-lato-black"}>
        New Tablet Inventory Count
      </div>
      <div className={"flex justify-between mt-10"}>
        <div style={{ width: "47%" }}>
          <div className={"text-base text-black font-lato-black mb-3"}>
            Count Date
          </div>
          <DatePickerInput
            size="large"
            value={date}
            onChange={setDate}
          />
        </div>
        <div style={{ width: "47%" }}>
          <div className={"text-base text-black font-lato-black mb-3"}>
            Repeat
          </div>
          <Dropdown
            menuPosition="absolute"
            size={"medium"}
            options={repeatOptions}
            value={frequency}
            onChange={handleFrequencySelect}
          />
        </div>
      </div>
      <div className={"mt-10"}>
        <div className={"text-base text-black font-lato-black mb-3"}>
          Count Type
        </div>
        <Dropdown
          menuPosition="absolute"
          size={"medium"}
          placeholder={"Select count type"}
          options={typeOptions}
          value={countType}
          onChange={handleCountTypeSelect}
        />
      </div>
      <div className={"mt-10"}>
        <div className={"text-base text-black font-lato-black mb-3"}>
          Count Location
        </div>
        <Dropdown
          size={"medium"}
          menuPosition="absolute"
          placeholder={"All locations"}
          options={locationOptions}
          onChange={handleLocationsSelect}
          isMulti

        />
      </div>
      <div className={"flex mt-16 justify-between"}>
        <Button
          label={"Cancel"}
          type={"btn-style-1"}
          className={"justify-center cursor-pointer"}
          size={"btn-large"}
          style={{ width: "48%" }}
          onClick={onClose}
        />
        <Button
          label={"Send to Tablet"}
          className={
            "justify-center bg-black text-white hover:bg-black hover:text-white cursor-pointer"
          }
          type={"btn-style-1"}
          size={"btn-large"}
          style={{ width: "48%" }}
          onClick={submit}
        />
      </div>
    </Modal>
  );
};
export default NewTabletCountModal;
