import { Icon } from "@blueprintjs/core";
import firebase from "config/firebase";
import { Location } from "history";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import EmailEditor, { Design } from "react-email-editor";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";

import { RootState } from "model/store";

import "./styles.css";

const CreativeStudio = () => {
  const db = firebase.firestore();
  const location: any = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ templateId: string }>();
  const user: StaffMember = useSelector((state: RootState) => state.user);

  const emailEditorRef: any = useRef(null);

  const templateId = params.templateId;

  const [templateName, setTemplateName] = useState("");
  const [templateData, setTemplateData]: any = useState();

  const getTemplatedata = (templateId: string) => {
    return db
      .collection("Templates")
      .doc(templateId)
      .get()
      .then((docRef) => {
        let tempData: any = docRef.data();
        setTemplateData(tempData);
        if (tempData.templateBody != null) {
          const jsonFormat = JSON.parse(tempData.templateBody);
          emailEditorRef.current.editor.loadDesign(jsonFormat);
        }
      });
  };

  const SaveDesignToFirebase = (TemplateHtml: Design, html: string) => {
    console.log("TemplateHtml", TemplateHtml);
    const templateData = TemplateHtml;

    try {
      db.collection("Templates")
        .doc(templateId)
        .update({
          templateBody: JSON.stringify(templateData),
          templateHtml: html,
          updatedAt: new Date(),
        })
        .then(() => {
          navigate("/create-campaign");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design: any) => {
      console.log("savedDesign", design);
      // SaveDesignToFirebase(design);
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      console.log("designString", html);
      if (design && html) {
        SaveDesignToFirebase(design, html);
      }
    });
  };

  const onLoad = () => {
    //const templateJson = {};
    //emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    console.log("onReady");
    //  emailEditorRef.current.editor.loadDesign(templateJson);
  };

  useEffect(() => {
    console.log("templateData", templateData);
    //  console.log(JSON.parse(templateData.templateBody));
  }, [templateData]);

  useEffect(() => {
    if (params.templateId) {
      getTemplatedata(params.templateId);
    }
  }, [params]);

  return (
    <div id="creative-studio-page">
      <Box className="page-head">
        <Box
          display="flex"
          flex-direction="row"
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/create-campaign")}
        >
          <Icon icon="chevron-left" color="#424452" size={12} />
          <span className="backword-text">Leave Creative Studio</span>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="pagehead-leftside">
            <div className="email-title">
              Design Template - {templateData && templateData.templateName}
            </div>
            <div className="email-time">
              Added via{" "}
              <span className="bold-subheader">{templateData?.authorName}</span>{" "}
              on{" "}
              <span className="bold-subheader">
                {templateData &&
                  moment(templateData.createdAt.toDate())
                    .format("ddd, MMMM Do h:mm A")
                    .toString()}
              </span>
            </div>
          </Box>
          <Box
            className="pagehead-rightside"
            display="flex"
            flexDirection="row"
            style={{ marginRight: "54px" }}
          >
            <div className="savenexit-button" onClick={() => exportHtml()}>
              Save & Exit
            </div>
          </Box>
        </Box>
      </Box>

      <Box className="page-body">
        <EmailEditor
          style={{ height: "80vh" }}
          ref={emailEditorRef}
          onLoad={onLoad}
          onReady={onReady}
        />
      </Box>
    </div>
  );
};

export default CreativeStudio;
