import { Dialog, Icon, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconButton } from "@mui/material";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { unassignShift, unassignShiftInDuplicate } from "controllers/schedule";

import { updateDuplicateScheduleToDraft } from "model/actions/staffingAC";
import { getPositionNameById } from "model/selectors/businessSettings";
import { staffMemberIsAvailableToWorkTheShift } from "model/selectors/scheduleEvents";
import store, { RootState } from "model/store";

// import "tailwindcss/tailwind.css"
import DeleteShiftImage from "assets/trash_icon.svg";

import "./index.css";

const OverTimeToast = () => (
  <div className="bg-semi-transparent-red flex flex-row items-center justify-evenly px-4 py-2 rounded-lg">
    <div className="h-2 w-2 rounded-full bg-tango-red mr-2" />
    <p className="font-sf-semibold text-sm font-bold text-tango-red">
      Overtime
    </p>
  </div>
);

export interface StaffWeeklySchedule {
  staffId: string;
  isOvertime: boolean;
  totalHours: number;
  shifts: TangoShift[];
  schedule: TangoSchedule;
  staffFullName: string;
}

interface ViewStaffMemberScheduleModalProps {
  open: boolean;
  onClose: () => void;
  weeklySchedule: StaffWeeklySchedule | null;
  scheduleForWeekRange: TangoSchedule | null;
}

const ViewStaffMemberScheduleModal = ({
  open,
  onClose,
  weeklySchedule,
  scheduleForWeekRange,
}: ViewStaffMemberScheduleModalProps) => {
  const [unassignShiftLoading, setUnassignShiftLoading] = useState<
    string | null
  >(null);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );
  console.log("weeklySchedule", weeklySchedule);
  console.log("fixedAvailabilities", fixedAvailabilities);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [selectedTab, setSelectedTab] = useState<"Scheduled" | "Availability">(
    "Scheduled"
  );

  const renderTimeFormat = (time: any) => {
    let timeFirstTwoChar = time?.substring(0, 2);
    const suffix = timeFirstTwoChar > 12 ? "pm" : "am";
    // const Time = time;
    if (timeFirstTwoChar > 12) {
      timeFirstTwoChar = timeFirstTwoChar - 12;
    }

    return (
      parseInt(timeFirstTwoChar, 10) + ":" + time.substring(3, 5) + " " + suffix
    );
  };

  const availability = useMemo(() => {
    if (fixedAvailabilities.length > 0) {
      const filteredAvailbility: FixedAvailability | undefined =
        fixedAvailabilities.find((i) => i.staffId === weeklySchedule?.staffId);
      const obj: {
        [day: string]: {
          start: string;
          end: string;
          available: boolean;
        };
      } = {};
      if (!filteredAvailbility) {
        return obj;
      }
      filteredAvailbility.schedule.forEach(
        (item: FixedAvailabilitySchedule) => {
          obj[item.day] = {
            ...item,
            start: renderTimeFormat(item.startTime),
            end: renderTimeFormat(item.endTime),
            available: item.available,
          };
        }
      );
      return obj;
    }
  }, [weeklySchedule, fixedAvailabilities]);

  const unassignShiftHandler = useCallback(
    async (shiftId: string) => {
      if (weeklySchedule?.schedule && business) {
        try {
          if (weeklySchedule?.schedule?.isDuplicate) {
            setUnassignShiftLoading(shiftId);
            await unassignShiftInDuplicate(
              business.id,
              weeklySchedule?.schedule,
              shiftId
            );
            setUnassignShiftLoading(null);
            // store.dispatch(updateDuplicateScheduleToDraft(newDraft.id));
          } else {
            setUnassignShiftLoading(shiftId);
            await unassignShift(
              business.id,
              weeklySchedule?.schedule?.id,
              shiftId
            );
            setUnassignShiftLoading(null);
          }
        } catch (e) {
          setUnassignShiftLoading(null);
          alert("Opps, something went wrong");
        }
      }
    },
    [weeklySchedule?.schedule, business]
  );

  if (!weeklySchedule) return null;

  const assignedStaffMember = fellowStaffMembers.find(
    (sm) => sm.id === weeklySchedule.staffId
  );

  if (!assignedStaffMember) return null;

  const renderShift = (shift: TangoShift) => {
    const militaryStartTime = `${moment(shift.startTime, "HH:mm").format(
      "hh:mm a"
    )}`;
    const militaryEndTime = `${moment(shift.endTime, "HH:mm").format(
      "hh:mm a"
    )}`;
    const isAvailableToWorkTheShift = assignedStaffMember
      ? staffMemberIsAvailableToWorkTheShift(
          assignedStaffMember,
          fixedAvailabilities,
          business,
          weeklySchedule.schedule,
          shift,
          true
        )
      : false;

    return (
      <div
        className="flex justify-between items-center px-8 border-t-2 border-very-light-gray"
        style={{ justifyContent: "space-around" }}
      >
        <div className=" w-1/2 h-full  flex flex-row justify-between align-center py-4">
          <div>
            <div className="flex flex-row justify-evenly items-center mb-2">
              <p className="font-sf-regular text-letter-black text-xs  ">
                {moment(shift?.startDate?.toDate()).format("dddd, MMM DD")}
              </p>
              {/* {isAvailableToWorkTheShift || true ? null : (
                <img
                  className="h-3 ml-1 "
                  src={require("assets/warning.svg").default}
                  alt="warning"
                  style={{ height: 12 }}
                />
              )} */}
            </div>
            <p className="font-sf-regular text-letter-black text-sm ">
              {getPositionNameById(businessSettings, shift.position || "")}
            </p>
          </div>
          {unassignShiftLoading === shift?.id ? (
            <Spinner className="mr-2" size={SpinnerSize.SMALL} />
          ) : (
            <IconButton
              className="h-10 w-10 "
              onClick={() => unassignShiftHandler(shift.id)}
            >
              <img
                className="h-4 w-4"
                src={DeleteShiftImage}
                alt="delete-shift"
              />
            </IconButton>
          )}
        </div>
        <div className="w-1/3 h-full flex flex-col border-l-2 border-r-2 border-very-light-gray align-center p-4">
          <p className="font-sf-regular text-letter-black text-xs mb-1 ">
            Start
          </p>
          <p className="font-sf-regular text-letter-black text-xs mb-1 ">
            {militaryStartTime}
          </p>
        </div>
        <div className="flex w-1/3 h-full flex-col  p-4">
          <p className="font-sf-regular text-letter-black text-xs mb-1">End</p>
          <p className="font-sf-regular text-letter-black text-xs mb-1">
            {militaryEndTime}
          </p>
        </div>
      </div>
    );
  };

  const renderScheduled = () => {
    return (
      <div className="flex flex-col w-full bg-white">
        <div className="flex flex-row border-t-2 border-very-light-gray justify-between items-center mt-3 px-8  py-4">
          <div>
            <h2 className="font-sf-regular text-base font-extrabold text-letter-black">
              Scheduled this week
            </h2>
            <h3
              className={`font-sf-bold text-base font-bold ${
                weeklySchedule.isOvertime ? "text-red-500" : "text-letter-black"
              }`}
            >
              {weeklySchedule.totalHours} hours
            </h3>
          </div>
          {weeklySchedule.isOvertime ? <OverTimeToast /> : <div />}
        </div>
        {weeklySchedule.shifts.map(renderShift)}
      </div>
    );
  };

  const renderAvailabilityForADay = (dayName: string) => {
    if (availability) {
      if (availability[dayName]?.available) {
        return (
          <>
            <label className="mr-2">{availability[dayName].start}</label>
            <label className="mr-2">-</label>
            <label className="mr-2">{availability[dayName].end}</label>
          </>
        );
      } else {
        return <label className="mr-2">Unavailable</label>;
      }
    } else {
      return <label className="mr-2">Unavailable</label>;
    }
  };

  const renderAvailibility = () => {
    return (
      <div className="flex flex-col w-full bg-white">
        <table className="table-auto">
          <tbody className="f12">
            <tr>
              <td className="border-r border-b">
                <label className="mx-4">Monday</label>
              </td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Monday")}
              </td>
            </tr>
            <tr className="border-all">
              <td className="border-r border-b">Tuesday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Tuesday")}
              </td>
            </tr>
            <tr>
              <td className="border-r border-b">Wednesday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Wesnesday")}
              </td>
            </tr>
            <tr className="border-all">
              <td className="border-r border-b">Thursday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Thursday")}
              </td>
            </tr>
            <tr>
              <td className="border-r border-b">Friday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Friday")}
              </td>
            </tr>
            <tr className="border-all">
              <td className="border-r border-b">Saturday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Saturday")}
              </td>
            </tr>
            <tr>
              <td className="border-r border-b">Sunday</td>
              <td className="text-center border-r border-b">
                {renderAvailabilityForADay("Sunday")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Dialog
      isOpen={open}
      canOutsideClickClose
      canEscapeKeyClose
      className="bg-white overflow-hidden view-staff-member-schedule-modal"
      onClose={onClose}
    >
      {/* {renderScheduled()} */}
      <div className="flex flex-col w-full bg-white">
        <div className="flex flex-row justify-between items-center w-full px-4 pt-3">
          <div className="h-10 w-10">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
            >
              <Icon icon="delete" iconSize={18} />
            </IconButton>
          </div>
          <p className="font-sf-bold text-base font-extrabold text-letter-black">
            {weeklySchedule.staffFullName}
          </p>
          <div className="h-10 w-10" />
        </div>

        <div className="tabs">
          <div
            className={`tab-item ${
              selectedTab === "Scheduled" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("Scheduled")}
          >
            Scheduled
          </div>
          <div
            className={`tab-item ${
              selectedTab === "Availability" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("Availability")}
          >
            Availability
          </div>
        </div>
        <div style={{ width: "100%", height: "50vh", overflowY: "scroll" }}>
          {selectedTab == "Scheduled" && renderScheduled()}
          {selectedTab == "Availability" && renderAvailibility()}
        </div>
      </div>
    </Dialog>
  );
};

export default ViewStaffMemberScheduleModal;
