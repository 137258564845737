import UIComponents from "@tangopay/tango-ui-library";
import React, { PropsWithChildren } from "react";
import { ToolbarProps, View, Views } from "react-big-calendar";

const CalendarToolbar = (
  props: PropsWithChildren<ToolbarProps<Event, Object>> & {
    showEventModal: () => void;
    setSelectedView: React.Dispatch<
      React.SetStateAction<{ label: string; value: View }>
    >;
    selectedView: { label: string; value: View };
  }
) => {
  return (
    <>
      <div className="p-8 flex items-center justify-between">
        <div className="text-big text-black font-lato-black">
          {props.label.split(" ")[0]} {props.date.getFullYear()}
        </div>
        <div className="flex items-center">
          <UIComponents.Dropdown
            size={"medium"}
            value={props.selectedView}
            options={[
              {
                label: "Work week",
                value: Views.WORK_WEEK,
              },
              {
                label: "Week",
                value: Views.WEEK,
              },
              {
                label: "Month",
                value: Views.MONTH,
              },
            ]}
            onChange={(newValue) => {
              if (newValue) {
                //@ts-ignore
                props.onView(newValue.value);
                //@ts-ignore
                props.setSelectedView(newValue);
              }
            }}
            className={"w-52 mr-5"}
          />
          <UIComponents.NestedButton
            label={"Today"}
            className={"mr-5"}
            leftIcon={"chevron-left"}
            rightIcon={"chevron-right"}
            onButtonClick={() => props.onNavigate("TODAY")}
            onLeftIconClick={() => props.onNavigate("PREV")}
            onRightIconClick={() => props.onNavigate("NEXT")}
          />
          <UIComponents.Button
            onClick={props.showEventModal}
            label={"New Event"}
            type={"btn-style-2"}
            size={"btn-large"}
            className={"w-fit"}
          />
        </div>
      </div>
    </>
  );
};
export default CalendarToolbar;
