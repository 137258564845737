import { Dialog } from "@blueprintjs/core";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "model/store";

import { updateBusinessSetting } from "../../../../../models/businesses";
import "../modal.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
interface IChannel {
  label: string;
  value: string;
}
const OrderDisplayPreferences: FC<Props> = ({ isOpen, closeModal }) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [businessUsesOrderDisplay, setBusinessUsesOrderDisplay] = useState(
    businessSettings && businessSettings.businessUsesOrderDisplay
      ? { label: "True", value: true }
      : { label: "False", value: false }
  );

  const [channels, setChannels] = useState([
    businessSettings &&
      businessSettings.autoPrintCarryOutOrders && {
        label: "Carry Out",
        value: "carryOut",
      },
    businessSettings &&
      businessSettings.autoPrintDineInOrders && {
        label: "Dine In",
        value: "dineIn",
      },
    businessSettings &&
      businessSettings.autoPrintCurbsideOrders && {
        label: "Curbside",
        value: "curbside",
      },
    businessSettings &&
      businessSettings.autoPrintDeliveryOrders && {
        label: "Delivery",
        value: "delivery",
      },
  ]);

  const handleSelectChannels = async (channels: any) => {
    const selectedChannelNames: any[] = [];
    channels.map((selectedChannel: any) => {
      return selectedChannelNames.push(selectedChannel.value);
    });

    if (selectedChannelNames.includes("dineIn")) {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintDineInOrders",
        true
      );
      if (success) {
        // console.log("autoPrintDineInOrders updated")
      }
    } else {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintDineInOrders",
        false
      );
      if (success) {
        // console.log("autoPrintDineInOrders updated")
      }
    }

    if (selectedChannelNames.includes("carryOut")) {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintCarryOutOrders",
        true
      );
      if (success) {
        // console.log("autoPrintCarryOutOrders updated")
      }
    } else {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintCarryOutOrders",
        false
      );
      if (success) {
        // console.log("autoPrintCarryOutOrders updated")
      }
    }

    if (selectedChannelNames.includes("curbside")) {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintCurbsideOrders",
        true
      );
      if (success) {
        // console.log("autoPrintCurbsideOrders updated")
      }
    } else {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintCurbsideOrders",
        false
      );
      if (success) {
        // console.log("autoPrintCurbsideOrders updated")
      }
    }

    if (selectedChannelNames.includes("delivery")) {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintDeliveryOrders",
        true
      );
      if (success) {
        // console.log("autoPrintDeliveryOrders updated")
      }
    } else {
      const success = await updateBusinessSetting(
        business.id,
        "autoPrintDeliveryOrders",
        false
      );
      if (success) {
        // console.log("autoPrintDeliveryOrders updated")
      }
    }

    setChannels(channels);
  };

  return (
    <Dialog isOpen={isOpen} className="settings-modal" onClose={closeModal}>
      <div className="modal-header">
        <div className="modal-title">Order Display Preferences</div>
        <div
          style={{ backgroundColor: "rgba(255, 119, 241, 0.6)" }}
          className="modal-icon order-display"
        >
          <img
            style={{ height: 15 }}
            src={require("../../../../../assets/tango-icon.svg").default}
          />
          <div className="icon-title">Display</div>
        </div>
      </div>
      <div className="modal-body">
        <div className="section-title">General</div>

        <div className="section-row">
          <div className="row-title">Use order display to filter orders</div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              onChange={async (option) => {
                if (option) {
                  const success = await updateBusinessSetting(
                    business.id,
                    "businessUsesOrderDisplay",
                    option.value
                  );
                  if (success) {
                    setBusinessUsesOrderDisplay(option);
                  }
                }
              }}
              options={[
                {
                  label: "True",
                  value: true,
                },
                {
                  label: "False",
                  value: false,
                },
              ]}
              value={businessUsesOrderDisplay}
            />
          </div>
        </div>

        <div className="section-row">
          <div className="row-title">Auto-print incoming orders</div>
          <div className="row-field">
            <Select
              className="flex-react-select"
              isMulti
              name="filters"
              placeholder="Filters"
              value={channels}
              options={[
                {
                  label: "Dine In",
                  value: "dineIn",
                },
                {
                  label: "Carry Out",
                  value: "carryOut",
                },
                {
                  label: "Curbside",
                  value: "curbside",
                },
                {
                  label: "Delivery",
                  value: "delivery",
                },
              ]}
              onChange={(option) => {
                handleSelectChannels(option);
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OrderDisplayPreferences;
