import React from "react";

export default function ErrorPage() {
  return (
    <div style={{ textAlign: "center", margin: "6em", color: "black" }}>
      <h2>Error 😔</h2>
      <p>
        We're sorry, something went sideways here. Be sure we are working on it!
      </p>
    </div>
  );
}
