

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { useAllItemsInfo } from "controllers/inventoryItemInfo";
import { RootState } from "model/store";
import { useSelector } from "react-redux";
import UIComponents from "@tangopay/tango-ui-library";
import { useCallback, useMemo } from "react";

const { Dropdown } = UIComponents;
export const newWastedItemPrefix = "new__wasted__item__";

export type WasteGroup = {
    uniqueId: string;
    date: number;
    reason: string;
    totalCost: number;
    wastedItemNames: string;
    // wasted items;
};
export type WastedItem = {
    // all items have these
    uniqueId: string;
    quantity: number;
    reason: string;
    // items from the server have these
    itemName?: string;
    unit?: string;
    unitCost?: number;
    totalCost?: number;
    // items in construction have these
    itemId?: string | null;
    isIngredientUnit?: boolean;
    // isRaw?: boolean;
};
type WastedItemInstruction = RenderInstruction<WastedItem>;

const useItems = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const allItemsQuery = useAllItemsInfo(businessId, true);
    return allItemsQuery.data ?? [];
}
const useItem = (itemId: string | null | undefined) => {
    const allItems = useItems();
    return useMemo(
        () => {
            if (!itemId) return null;
            if (!allItems?.length) return null;
            return allItems.find(({ id }) => id == itemId) ?? null;
        },
        [itemId, allItems],
    )
}

const itemNameInstruction: WastedItemInstruction = {
    type: "complex-custom",
    // Edit is new items, view is existing items
    readOnly: true,
    editablePrefix: newWastedItemPrefix,
    viewComponent: (i => <div>{i.fullObject.itemName}</div>),
    editComponent: (({ fullObject, onChange }) => {
        const allItems = useItems();
        const options = useMemo(
            () => allItems.map(i => ({
                value: i.id,
                label: i.name,
            })),
            [allItems]
        );
        const onSelect = useCallback(
            ({ value }) => {
                onChange?.({ ...fullObject, itemId: value })
            },
            [fullObject, onChange]
        );
        return <div>
            <Dropdown
                menuPosition="absolute"
                options={options}
                value={options.find((opt) => opt.value == fullObject.itemId)}
                onChange={onSelect}
                size="small"
            />
        </div>
    })
}
const unitInstruction: WastedItemInstruction = {
    type: "complex-custom",
    // Edit is new items, view is existing items
    readOnly: true,
    editablePrefix: newWastedItemPrefix,
    viewComponent: (i => <div>{i.fullObject.unit}</div>),
    editComponent: (({ fullObject, onChange }) => {
        const selectedItem = useItem(fullObject.itemId);
        const options = useMemo(
            () => selectedItem ? [
                {
                    label: selectedItem.ingredientUnitName,
                    value: "ing",
                },
                {
                    label: selectedItem.productionUnitName,
                    value: "pro",
                },
            ] : [],
            [selectedItem]
        );
        const key = fullObject.isIngredientUnit ? 'ing' : 'pro';
        const onSelect = useCallback(
            ({ value }) => {
                onChange?.({ ...fullObject, isIngredientUnit: value == 'ing' })
            },
            [fullObject, onChange]
        );
        return <div>
            <Dropdown
                menuPosition="absolute"
                options={options}
                value={options.find((opt) => opt.value == key)}
                onChange={onSelect}
                size="small"
            />
        </div>
    })
}
const unitCostInstruction: WastedItemInstruction = {
    type: "complex-custom",
    readOnly: true,
    viewComponent: (({ fullObject }) => {
        const isNew = fullObject.uniqueId.startsWith(newWastedItemPrefix);
        // skip the find if no selection gets made
        const selected = useItem(isNew ? fullObject.itemId : null);
        let cost = 0;
        if (selected) {
            if (fullObject.isIngredientUnit) {
                cost = selected.defaultCost / 100
            } else {
                cost = selected.defaultCost * selected.ingredientsPerProduct / 100;
            }
        } else if (fullObject.unitCost) {
            cost = fullObject.unitCost / 100;
        }
        return <div>{(cost || 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
    }),
    editComponent: null,
}
const totalCostInstruction: WastedItemInstruction = {
    type: "complex-custom",
    readOnly: true,
    viewComponent: (({ fullObject }) => {
        const isNew = fullObject.uniqueId.startsWith(newWastedItemPrefix);
        // skip the find if no selection gets made
        const selected = useItem(isNew ? fullObject.itemId : null);
        let cost = 0;
        if (selected) {
            if (fullObject.isIngredientUnit) {
                cost = selected.defaultCost / 100
            } else {
                cost = selected.defaultCost * selected.ingredientsPerProduct / 100;
            }
        } else if (fullObject.unitCost) {
            cost = fullObject.unitCost / 100;
        }
        return <div>{((cost * fullObject.quantity) || 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
    }),
    editComponent: null,
}

export const itemInstructions: { [x: string]: WastedItemInstruction } = {
    itemName: itemNameInstruction,
    quantity: {
        type: "number",
        readOnly: true,
        editablePrefix: newWastedItemPrefix,
    },
    unit: unitInstruction,
    reason: {
        type: "default",
        // in the future, this will be editable for existing items
        // sounds like a problem for future David
        readOnly: true,
        editablePrefix: newWastedItemPrefix,
    },
    unitCost: unitCostInstruction,
    totalCost: totalCostInstruction,
};