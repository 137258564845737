import { ClosedChecksProps } from '../ClosedChecks/useClosedChecks';
import { FormattedPayment } from '../Payments';
import { TangoTab } from 'controllers/payments';
import React, { useState } from 'react';

const useDisputeDetail = () => {
  const [showAcceptDispute, setShowAcceptDispute] = useState(false);
  const [showRefundPaymentModal, setShowRefundPaymentModal] = useState(false);
  const [selectedPaymentToRefund, setSelectedPaymentToRefund] = useState<FormattedPayment>();
  const [updatedCheckWithRefund, setUpdateCheckWithRefund] = useState<ClosedChecksProps | null>(null);
  const [refundSuccess, setRefundSuccess] = useState(false);

  return {
    setShowRefundPaymentModal,
    showRefundPaymentModal,
    showAcceptDispute,
    setShowAcceptDispute,
    setSelectedPaymentToRefund,
    selectedPaymentToRefund,
    setUpdateCheckWithRefund,
    updatedCheckWithRefund,
    setRefundSuccess,
    refundSuccess,
  };
};
export default useDisputeDetail;
