import { Button, Dialog } from "@blueprintjs/core";
import React from "react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const CashSummaryModal = ({ isOpen, closeModal }: Props) => (
  <Dialog
    isOpen={isOpen}
    className="cash-summary-modal"
    hasBackdrop
    onClose={closeModal}
  >
    <div className="modal-header">
      <div>
        <div className="modal-title">Operational Cash Summary</div>
        <div className="modal-subtitle">June 1, 2021</div>
      </div>
      <Button
        text="Export"
        rightIcon={
          <img src={require("../../../../../assets/export.svg").default} />
        }
        className="export-button"
      />
    </div>
    <div className="modal-body">
      <table>
        <tr>
          <th>Drawer/Bank</th>
          <th>Main Bar</th>
          <th>Main Bar</th>
          <th>Main Bar</th>
          <th>Total</th>
        </tr>
        <tr>
          <td>Owner</td>
          <td>Zishaan Sunderji</td>
          <td>Zishaan Sunderji</td>
          <td>Zishaan Sunderji</td>
          <td></td>
        </tr>
        <tr>
          <td>Start</td>
          <td>12:15PM 05/11/2021</td>
          <td>12:15PM 05/11/2021</td>
          <td>12:15PM 05/11/2021</td>
          <td>12:15PM 05/11/2021</td>
        </tr>
        <tr>
          <td>End</td>
          <td>6:00PM 05/11/2021</td>
          <td>6:00PM 05/11/2021</td>
          <td>6:00PM 05/11/2021</td>
          <td>6:00PM 05/11/2021</td>
        </tr>
        <tr className="cash-activity-row">
          <td>Cash Activity</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Starting Cash</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
        </tr>
        <tr>
          <td>Cash Sales</td>
          <td>439.00</td>
          <td>439.00</td>
          <td>439.00</td>
          <td>439.00</td>
        </tr>
        <tr>
          <td>Cash Refunds</td>
          <td>- 17.00</td>
          <td>- 17.00</td>
          <td>- 17.00</td>
          <td>- 17.00</td>
        </tr>
        <tr>
          <td>Payouts</td>
          <td>- 6.25</td>
          <td>- 6.25</td>
          <td>- 6.25</td>
          <td>- 6.25</td>
        </tr>
        <tr>
          <td>Card Tip-Outs</td>
          <td>- 331.00</td>
          <td>- 331.00</td>
          <td>- 331.00</td>
          <td>- 331.00</td>
        </tr>
        <tr className="ending-cash">
          <td>Ending Cash</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
          <td>$ 500.00</td>
        </tr>
      </table>
    </div>
  </Dialog>
);
export default CashSummaryModal;
