import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { WASTE_KEY } from "../constants";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

export type WastedItem = {
  id: string;
  itemId: string;
  isRaw: boolean;
  reason: string;
  dateWasted: number;
  itemName: string;
  unit: string;
  unitCost: number;
  wasteAmount: number;
  totalCost: number;
}
export type WasteGroup = {
  id: string;
  reason: string;
  dateWasted: number;
  totalCost: number;
  wastedItems: WastedItem[];
}

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const fetchWasteGroups = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<WasteGroup[]>(`${businessId}/wasteGroups`);
  return r.data;
};

const fetchWasteGroup = async (businessId: string, groupId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<WasteGroup>(`${businessId}/wasteGroups/${groupId}`);
  return r.data;
};

const createWasteGroup = async (businessId: string, dateWasted: number) => {
  if (!businessId) return null;
  const r = await apiClient.post<WasteGroup>(
    `${businessId}/wasteGroups`,
    {
      dateWasted,
      reason: "Waste",
    },
  );
  return r.data;
};
const createWastedItem = async (
  businessId: string,
  groupId: string,

  itemId: string,
  isRaw: boolean,
  isIngredientUnit: boolean,
  reason: string,
  unitCount: number,
) => {
  if (!businessId) return null;
  const r = await apiClient.post<WastedItem>(
    `${businessId}/wasteGroups/${groupId}`,
    {
      itemId,
      isRaw,
      isIngredientUnit,
      reason,
      unitCount,
    },
  );
  return r.data;
};

// const createVendor = async (businessId: string, toCreate: Partial<Vendor>) => {
//   if (!businessId) return null;
//   const r = await apiClient.post<Vendor>(`${businessId}/vendors`, toCreate);
//   return r.data;
// };

// HOOKS
export const useWasteGroups = (businessId: string) => {
  return useQuery([WASTE_KEY, businessId], () => fetchWasteGroups(businessId));
};
export const useWasteGroup = (businessId: string, wasteGroupId: string) => {
  return useQuery([WASTE_KEY, businessId, wasteGroupId], () =>
    fetchWasteGroup(businessId, wasteGroupId)
  );
};
export const useCreateWasteGroup = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { date: number }) =>
      createWasteGroup(businessId, args.date),
    onSuccess: () => client.invalidateQueries([WASTE_KEY, businessId]),
  });
};
export const useCreateWastedItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      groupId: string;
      itemId: string;
      isRaw: boolean;
      isIngredientUnit: boolean;
      reason: string;
      unitCount: number;
     }) =>
      createWastedItem(
        businessId,
        args.groupId,
        args.itemId,
        args.isRaw,
        args.isIngredientUnit,
        args.reason,
        args.unitCount,
      ),
    onSuccess: () => client.invalidateQueries([WASTE_KEY, businessId]),
  });
};
