//review trackers requests
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { updateBusinessSetting } from '../../models/businesses'
import {
  ReviewTrackersResponse,
  ReviewTrackersReview,
} from "../../types/reputationManagement";

const productionURL = 'https://api-gateway.reviewtrackers.com'
const sandboxURL = 'https://api.sandbox.rtxdevelopment.com'

export const fetchReviewTrackersCreds = async () => {
  try {

    const response = await axios.post(
      `${productionURL}/auth`,
      {},
      {
        auth: {
          username: process.env.REACT_APP_REVIEWTRACKERS_USERNAME
            ? process.env.REACT_APP_REVIEWTRACKERS_USERNAME
            : "",
          password: process.env.REACT_APP_REVIEWTRACKERS_PASSWORD
            ? process.env.REACT_APP_REVIEWTRACKERS_PASSWORD
            : "",
        },
      }
    );

    if (response.data) {
      return response.data;
    } else {
      console.log("no response from review trackers token request");
      return { id: "" };
    }
  } catch (error) {
    console.log("error fetch review trackers token", error);
    return { id: "" };
  }
};

export const fetchAccountMetrics = async (
  reviewTrackersCreds: any,
  reviewTrackersAccountId: string,
  filterDate: any
) => {
  try {
    const newFilterDate = moment(filterDate).format("yyyy-MM-DD");
    const response = await axios.get(
      `${productionURL}/metrics/${reviewTrackersAccountId}/overview?account_id=${reviewTrackersAccountId}&month_after=2022-06-01&month_before=${newFilterDate}`,
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        },
      }
    );
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
  } catch (err: any) {
    console.log("error fetching account metrics: ", err.response.data);
    return err.response.data;
  }
};

export const fetchAllReviews = async (
  reviewTrackersCreds: any,
  reviewTrackersAccountId: string,
  countToFetch: string
) => {
  try {
    const response = await axios.get(
      `${productionURL}/v2/reviews?&account_id=${reviewTrackersAccountId}&per_page=${countToFetch}`,
      {
        headers: {
          //authorization uses Tango's credentials
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        },
      }
    );
    if (response.data) {
      const allReviews: ReviewTrackersReview[] = response.data.data;
      const groupedReviews = _.groupBy(
        allReviews,
        (review) => review.source_name
      );

      //get an array of unique locations from all the reviews
      const locationIds: string[] = [
        ...new Set(
          allReviews.map((review: ReviewTrackersReview) => review.location_id)
        ),
      ];

      //Fetch the responses for each location id
      //Will this hit a rate limit if there are lots of locations??
      const allResponses = await Promise.all(
        locationIds.map(async (locationId: string) => {
          const responsesAtLocation: ReviewTrackersResponse[] =
            await fetchListOfResponses(
              reviewTrackersCreds,
              reviewTrackersAccountId,
              locationId
            );
          return responsesAtLocation;
        })
      );

      //for each review, find it's response and add it to the review
      allReviews.forEach((review: ReviewTrackersReview) => {
        allResponses.forEach(
          (responsesByLocation: ReviewTrackersResponse[]) => {
            responsesByLocation.forEach((response: ReviewTrackersResponse) => {
              if (review.id === response.review_id) {
                review.response = response;
              }
            });
          }
        );
      });

      return {
        allReviews: allReviews,
        reviewSites: Object.keys(groupedReviews),
      };
    } else {
      return {
        allReviews: [],
        reviewSites: [],
      };
    }
  } catch (err: any) {
    console.log("error fetching all reviews: ", err.response.data);
    return {
      allReviews: [],
      reviewSites: [],
    };
  }
};

export const fetchListOfResponses = async (
  reviewTrackersCreds: any,
  accountId: string,
  locationId: string
) => {
  try {
    //pass in Tango Toronto location review trackers account id in the url //TO DO - import this from business settings
    const allResponses = await axios.get(
      `${productionURL}/responses?account_id=${accountId}&location_id=${locationId}`,
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        },
      }
    );
    if (allResponses.data) {
      return allResponses.data._embedded.responses;
    } else {
      return [];
    }
  } catch (err: any) {
    console.log("error fetching reviews: ", err.response.data);
    return [];
  }
};

export const deleteReviewTrackersToken = async (reviewTrackersCreds: any) => {
  try {
    const response = await axios.delete(`${productionURL}/auth`, {
      headers: {
        authorization: `Basic ${Buffer.from(
          `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
        ).toString("base64")}`,
      },
    });
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
  } catch (error: any) {
    console.log("error delete token", error.response);
    return false;
  }
};

//Could show if a response is pending published (so the business knows their response was sent to google or facebook)
export const getSingleReviewStatus = async (
  reviewTrackersCreds: any,
  reviewId: string
) => {

  try {
    const response = await axios.get(
      `${productionURL}/review_status_labels/${reviewId}`,
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        },
      }
    );
    if (response.data) {
      return response.data;
    } else {
      return false;
    }
  } catch (error: any) {
    return error.response.data;
  }
};


export const createReviewTrackersOnboarding = async (
  reviewTrackersCreds: any,
  business: TangoBusiness,
  businessSettings: TangoBusinessSettings
) => {
  try {

    //if business has already creat RT account and location just generate the onboarding link
    if (businessSettings.reviewTrackersAccountId && businessSettings.reviewTrackersLocationId && !businessSettings.reviewTrackersEnabled) {
      return createReviewTrackersAuthorizationLink(reviewTrackersCreds, business, businessSettings.reviewTrackersAccountId)
    }

    //if business already has RT account generate a location and then the onbording link
    if (businessSettings.reviewTrackersAccountId && !businessSettings.reviewTrackersLocationId) {
      return createReviewTrackersChildLocation(reviewTrackersCreds, business, businessSettings.reviewTrackersAccountId);
    }

    //if above conditions not met create RT account then location then onboarding link
    const accountPayload = {
      account_owner_email: business.owner.email,
      account_owner_name: business.owner.firstName + ' ' + business.owner.lastName,
      account_owner_phone_number: business.owner.phone,
      name: business.businessName,
      bill_to_parent: true,
      parent_id: '62b9be7762483a418bdd9954',
      plan_id: '0000000069ec4c41f2000001',
      external_id: business.accountId,
    }

    const reviewTrackersAccountResponse = await axios.post(`${productionURL}/child`,
      accountPayload,
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        }
      });
    if (reviewTrackersAccountResponse.data) {
      //save reviewTrackers account id to business settings
      updateBusinessSetting(business.id, 'reviewTrackersAccountId', reviewTrackersAccountResponse.data.id)
      return createReviewTrackersChildLocation(reviewTrackersCreds, business, reviewTrackersAccountResponse.data.id);
    } else {
      console.log('no RT child account created')
      alert('There was a problem creating your Review Trackers account.')
      return false
    }

  } catch (error: any) {
    console.log('error creating RT account', error.response)
    alert('There was a problem creating your Review Trackers account.')
    return false
  }
}

export const createReviewTrackersChildLocation = async (
  reviewTrackersCreds: any,
  business: TangoBusiness,
  reviewTrackersAccountId: string
) => {
  try {
    const addressDetails = business.location.address
      .split(',')
      .map((component: string) => {
        return component = component.trim() //remove white space
      });

    const locationPayload = {
      name: business.businessName,
      account_id: reviewTrackersAccountId,
      google_place_id: business.location.googlePlacesId, //this will propogate a lot of other values on RT backend
      address: business.location.address,
      address_2: '',
      public_name: business.businessName,
      country_id: business.location.country === 'CA' || business.location.country === 'Canada' ? '5602d9457daac3f1480001cc' : '5602d9467daac3f148000eaa',
      state_id: '5602d9467daac3f148000ebb',//hardcoded, api fails if empty?
      category_id: '5e04f44d69ec4c1575542ede', //hardcoded, api api fails if empty?
      city: addressDetails[1],
      phone: business.owner.phone.replaceAll('-', ''),
      zipcode: business.location.postalCode,
      website: 'https://www.tangohq.com',
      external_id: business.id,
      open_status: 'active',
      hours: {
        monday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "22:00",
              open_time: "11:00"
            }
          ]
        },
        tuesday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              "close_time": "22:00",
              "open_time": "11:00"
            }
          ]
        },
        wednesday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "22:00",
              open_time: "11:00"
            }
          ]
        },
        thursday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "22:00",
              open_time: "11:00"
            }
          ]
        },
        friday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "23:00",
              open_time: "11:00"
            }
          ]
        },
        saturday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "23:00",
              open_time: "11:00"
            }
          ]
        },
        sunday: {
          open: true,
          is_24_hours: false,
          hours: [
            {
              close_time: "22:00",
              open_time: "11:00"
            }
          ]
        }
      }
    }


    const reviewTrackersLocationResponse = await axios.post(`${productionURL}/locations`,
      locationPayload,
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        }
      });
    if (reviewTrackersLocationResponse.data) {
      updateBusinessSetting(business.id, 'reviewTrackersLocationId', reviewTrackersLocationResponse.data.id)
      return createReviewTrackersAuthorizationLink(reviewTrackersCreds, business, reviewTrackersAccountId)
    } else {
      console.log('no RT location created')
      alert('There was a problem synching your ReviewTrackers location.')
      return false;
    }

  } catch (error: any) {
    console.log('error creating RT location', error.response.data)
    alert('There was a problem synching your ReviewTrackers location.')
    return false
  }
}


export const createReviewTrackersAuthorizationLink = async (
  reviewTrackersCreds: any,
  business: TangoBusiness,
  reviewTrackersAccountId: string
) => {
  try {
    const reviewTrackersLink = await axios.post(`${productionURL}/partner_integrations`,
      { account_id: reviewTrackersAccountId },
      {
        headers: {
          authorization: `Basic ${Buffer.from(
            `${reviewTrackersCreds.email}:${reviewTrackersCreds.token}`
          ).toString("base64")}`,
        }
      });
    if (reviewTrackersLink.data) {
      //TO DO - how do we know they authorize apps after generating the link? technically reviewTrackersEnabled could still be false if they dont authorize google or facebook etc..
      updateBusinessSetting(business.id, 'reviewTrackersEnabled', true)
      return reviewTrackersLink.data;
    } else {
      console.log('no RT link created')
      alert('There was a problem generating your ReviewTrackers authorization link.')
      return false;
    }

  } catch (error: any) {
    console.log('error RT onboarding link', error.response)
    alert('There was a problem generating your ReviewTrackers link.')
    return false
  }
}