import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "components/Table/Table";
import { OptionType, TableError } from "components/Table/tableTypes";

import { performSoftDelete, saveData } from "models/orderDisplays";

import { FirebaseOrderDisplayDoc, OrderDisplayDoc } from "types/orderDisplay";

import { RootState } from "model/store";

const OrderDisplay = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const orderDisplays = useSelector((state: RootState) => state.orderDisplays);
  const tables = useSelector((state: RootState) => state.tables);
  const [data, setData] = useState<OrderDisplayDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<OrderDisplayDoc[]>([]);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "displayName",
    errors: [],
  });

  const productSubTypes = [
    "appetizers",
    "mains",
    "sides",
    "desserts",
    "NA beverages",
    "bottled wine",
    "glass wine",
    "red wine",
    "white wine",
    "rose",
    "sparkling",
    "vodka",
    "gin",
    "tequila",
    "whiskey",
    "rum",
    "liqueur",
    "cocktail",
    "shot",
    "canned beer",
    "bottled beer",
    "draft beer",
    "cider",
    "seltzer",
    "malt bev",
    "coffee",
    "tea",
  ];

  const orderTypes = ["dineIn", "carryOut", "curbeside", "delivery"];

  const columnHeadingConversions = {
    displayName: "Display Name",
    routedSubTypes: "Routed Sub-Types",
    routedOrderTypes: "Routed Order Types",
    routedTables: "Routed Tables",
    enabled: "Enabled",
  };

  const tableSchema = [
    { key: "displayName", type: "value", data: "text" },
    { key: "routedSubTypes", type: "arrayOfValue", data: "text" },
    { key: "routedOrderTypes", type: "arrayOfValue", data: "text" },
    { key: "routedTables", type: "arrayOfValue", data: "text" },
    { key: "enabled", type: "value", data: "text" },
  ];

  const dataSchema = [
    { key: "id", type: "value", data: "text" },
    { key: "displayName", type: "value", data: "text" },
    { key: "routedSubTypes", type: "arrayOfValue", data: "text" },
    { key: "routedOrderTypes", type: "arrayOfValue", data: "text" },
    { key: "routedTables", type: "arrayOfValue", data: "text" },
    { key: "enabled", type: "value", data: "text" },
  ];

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "routedSubTypes",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: [...productSubTypes, "all"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "routedOrderTypes",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: [...orderTypes, "all"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "routedTables",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: [
          ...tables.map((table) => String(table.tableNumber)),
          "all",
        ].map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const filterData = (data: OrderDisplayDoc[]) =>
    data.map((row) => ({
      displayName: row.displayName,
      routedSubTypes: row.routedSubTypes,
      routedOrderTypes: row.routedOrderTypes,
      routedTables: row.routedTables,
      enabled: row.enabled,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      displayName: row.displayName,
      index,
    }));

    // Filter for duplicates
    const productDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({ displayName: row.displayName })),
          { displayName: item.displayName }
        ) !== index
    );

    // Find the duplicate without the id
    const errors: DocumentData[] = [];
    for (let i = 0; i < productDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) => row.displayName === productDuplicateErrors[i].displayName
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !(row.enabled === "Yes" || row.enabled === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong routed sub type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.routedSubTypes || row.routedSubTypes.length > 0
            ? row.routedSubTypes.filter(
                (subType: string) =>
                  ![...productSubTypes, "all"].includes(subType)
              ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedSubTypes", isEdited: false }],
          isEdited: false,
        })),
      // Wrong routed order type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.routedOrderTypes || row.routedOrderTypes.length > 0
            ? row.routedOrderTypes.filter(
                (orderType: string) =>
                  ![...orderTypes, "all"].includes(orderType)
              ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedOrderTypes", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No display name
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.displayName || row.displayName.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "displayName", isEdited: false }],
          isEdited: false,
        })),
      // No routed subtypes
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.routedSubTypes || row.routedSubTypes.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedSubTypes", isEdited: false }],
          isEdited: false,
        })),
      // No routed ordertypes
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !row.routedOrderTypes || row.routedOrderTypes.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedOrderTypes", isEdited: false }],
          isEdited: false,
        })),
      // No routed tables
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.routedTables || row.routedTables.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedTables", isEdited: false }],
          isEdited: false,
        })),
      // No enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.enabled || row.enabled.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const saveToFirebase = async () => {
    if (business.id) {
      await performSoftDelete(softDeleteData);
      await saveData(
        data,
        transformFirebaseOrderDisplayToOrderDisplay(orderDisplays),
        business.id
      );
      return true;
    }
  };

  const transformFirebaseOrderDisplayToOrderDisplay = (
    firebaseOrderDisplays: FirebaseOrderDisplayDoc[]
  ): OrderDisplayDoc[] => {
    return firebaseOrderDisplays.map((display) => {
      return {
        id: display.id,
        displayName: display.displayName,
        routedSubTypes: display.allowAllRoutedSubTypes
          ? ["all"]
          : display.routedSubTypes,
        routedOrderTypes: display.allowAllRoutedOrderTypes
          ? ["all"]
          : display.routedOrderTypes,
        routedTables: display.allowAllRoutedTables
          ? ["all"]
          : display.routedTables.map((table) => String(table)),
        enabled: display.enabled ? "Yes" : "No",
      };
    });
  };

  useEffect(() => {
    if (orderDisplays.length > 0 && business) {
      const allDisplays =
        transformFirebaseOrderDisplayToOrderDisplay(orderDisplays);
      setData(allDisplays);
    }
  }, [orderDisplays, business]);

  if (business) {
    return (
      <div id="order-display">
        <Table
          // @ts-ignore
          businessId={business.id}
          name="OrderDisplays"
          parentName="Devices"
          allData={data}
          setAllData={setData}
          softDelete={softDeleteData}
          setSoftDelete={setSoftDeleteData}
          allowSoftDelete
          filterData={filterData}
          tableSchema={tableSchema}
          dataSchema={dataSchema}
          options={options}
          setOptions={setOptions}
          hasModal={false}
          ModalComponent={null}
          errorManager={errorManager}
          setErrorManager={setErrorManager}
          closeErrorManager={() => {
            setErrorManager({
              ...errorManager,
              showErrorModal: false,
            });
          }}
          verifySave={verifyData}
          saveToFirebase={saveToFirebase}
          hasSearch={true}
          searchColumnName="displayName"
          hasFilter={false}
          columnHeadingConversions={columnHeadingConversions}
          showSearchName={false}
        />
      </div>
    );
  }
  return null;
};

export default OrderDisplay;
