import { createSelector } from "reselect";
import { firstBy } from "thenby";

import { RootState } from "model/store";

const products = (state: RootState) => state.products as FirebaseProductDoc[];
const productTypes = (state: RootState) =>
  state.productTypes as FirebaseDeliverectProductType[];

export const getProductsForTable = createSelector(
  products,
  productTypes,
  (
    products: FirebaseProductDoc[],
    productTypes: FirebaseDeliverectProductType[]
  ) => {
    // None deliverect businesses have everything working correctly
    if (!productTypes || productTypes.length === 0) {
      return products.sort(
        firstBy("type", { ignoreCase: true })
          .thenBy("subType")
          .thenBy("nameExternal")
      );
    }

    return products
      .map((existingProduct) => {
        const index = productTypes.findIndex(
          (product) => product.plu === existingProduct.plu
        );
        if (index !== -1) {
          return {
            ...existingProduct,
            type: productTypes[index].productType,
            subType: [productTypes[index].productSubType],
          };
        }
        return existingProduct;
      })
      .sort(
        firstBy("plu", { ignoreCase: true })
          .thenBy("subType")
          .thenBy("nameExternal")
      );
  }
);
