import { Button, Icon, Spinner, Switch } from "@blueprintjs/core";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
//@ts-ignore
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import htmlToDraft from "html-to-draftjs";
import _ from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";

import { deleteMemo, sendMemo } from "controllers/announcements";

import { ClickableWraper } from "components/AnimatedComponents";
import Box from "components/Box";

import { RootState } from "model/store";

import OpenedByModal from "./modals/OpenedByModal";
import SearchMemoModal from "./modals/SearchMemoModal";
import "./style.css";

interface Memo {
  id: string;
  title: string;
  sender: string;
  data: string;
  targets: string[];
}
const Memos = () => {
  const [addNewMemo, setAddNewMemo] = useState<boolean>(false);
  const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
  const [rightSideSelectedTab, setRightSideSelectedTab] = useState<
    "attachment" | "memo-info" | "opened-by"
  >("memo-info");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [newMemo, setNewMemo] = useState<{
    title: string;
    data: string;
  } | null>(null);

  const [selectedMemo, setSelectedMemo] = useState<null | Memo>(null);
  const [showOpenedByModal, setShowOpenedByModal] = useState<boolean>(false);
  const [showSearchMemoModal, setShowSearchMemoModal] =
    useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<undefined | string>(
    undefined
  );

  const [memoLoading, setMemoLoading] = useState(false);

  const [files, setFiles] = useState<File[]>([]);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const jobFunctions = businessSettings?.jobFunctions || {};

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const memos: Announcement[] = useSelector(
    (state: RootState) => state.announcements
  );
  const availableLocations: TangoBusiness[] = useSelector(
    (state: RootState) => state.locations
  );
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  useEffect(() => {
    if (business?.id && !selectedLocation) {
      setSelectedLocation(business.id);
    }
  }, [business?.id]);

  useEffect(() => {
    if (editorState) {
      const rawData = convertToRaw(editorState?.getCurrentContent());
      const html = draftToHtml(rawData);
      if (html.length > 0 && newMemo) {
        const clonedNewMemo = { ...newMemo };
        clonedNewMemo.data = DOMPurify.sanitize(html);
        setNewMemo(clonedNewMemo);
      }
    }
  }, [editorState]);

  useEffect(() => {
    if (addNewMemo) {
      setSelectedMemo(null);
      setNewMemo({ data: "", title: "" });
      setEditorState(EditorState.createEmpty());
    }
  }, [addNewMemo]);

  useEffect(() => {
    if (selectedMemo) {
      setAddNewMemo(false);
      setNewMemo(null);
      setRightSideSelectedTab("memo-info");
    }
  }, [selectedMemo]);

  const onTargetValueChange = useCallback(
    (targetId: string, value: boolean) => {
      if (value) {
        if (targetId === "boh") {
          const newTargets = [
            ...selectedTargets,
            targetId,
            ..._.keys(jobFunctions).filter(
              (key) => jobFunctions[key]?.departmentId === targetId
            ),
          ];
          setSelectedTargets(_.uniq(newTargets));
        } else if (targetId === "foh") {
          const newTargets = [
            ...selectedTargets,
            targetId,
            ..._.keys(jobFunctions).filter(
              (key) => jobFunctions[key]?.departmentId === targetId
            ),
          ];
          setSelectedTargets(_.uniq(newTargets));
        } else {
          setSelectedTargets(_.uniq([...selectedTargets, targetId]));
        }
      } else {
        if (targetId === "boh") {
          const selectedBohTargets = selectedTargets.filter(
            (t) => jobFunctions[t]?.departmentId === targetId
          );
          const newTargets = selectedTargets.filter(
            (st) => st !== targetId && !selectedBohTargets.find((t) => t === st)
          );
          setSelectedTargets(_.uniq(newTargets));
        } else if (targetId === "foh") {
          const selectedFohTargets = selectedTargets.filter(
            (t) => jobFunctions[t]?.departmentId === targetId
          );
          const newTargets = selectedTargets.filter(
            (st) => st !== targetId && !selectedFohTargets.find((t) => t === st)
          );
          setSelectedTargets(_.uniq(newTargets));
        } else {
          setSelectedTargets(
            _.uniq(selectedTargets.filter((t) => t !== targetId))
          );
        }
      }
    },
    [selectedTargets, jobFunctions]
  );

  const sendMemoHandler = useCallback(async () => {
    if (newMemo) {
      setMemoLoading(true);
      try {
        await sendMemo(
          business.id,
          newMemo.title,
          user.id,
          `${user.contact.firstName} ${user.contact.lastName}`,
          files,
          DOMPurify.sanitize(newMemo.data),
          newMemo.title,
          selectedTargets
        );
        setNewMemo(null);
        setAddNewMemo(false);
        setMemoLoading(false);
      } catch (e) {
        setMemoLoading(false);
      }
    }
  }, [
    user,
    business,
    businessSettings,
    newMemo,
    selectedTargets,
    files,
    memoLoading,
  ]);

  if (!user || !business || !businessSettings) {
    return <Spinner />;
  }

  const renderMemoLists = () => {
    return (
      <div className="memo-lists">
        {memos
          .sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
          .map((memo) => {
            return (
              <Box
                key={memo.id}
                className="memo-item"
                display="flex"
                onClick={() => {
                  const contentBlock = htmlToDraft(
                    memo.data || memo.message || ""
                  );
                  if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                      contentBlock.contentBlocks
                    );
                    const editorState =
                      EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                    const selectedMemo = {
                      title: memo.title,
                      id: memo.id,
                      data: memo.data,
                      sender: memo.senderName,
                      targets: memo.targets,
                    };
                    setSelectedMemo(selectedMemo);
                  }
                }}
              >
                <div className="header-icon">
                  <img src={require("assets/memo/send-message.svg").default} />
                </div>
                <div className="memo-text">{memo.title || memo.message}</div>
              </Box>
            );
          })}
      </div>
    );
  };

  const renderMemoInfo = () => {
    return (
      <>
        <div className="location-section tab-section">
          <div className="tab-section-title">Location</div>
          <select
            disabled={!addNewMemo}
            onChange={(e) => setSelectedLocation(e.target.value)}
            value={selectedLocation}
          >
            {[business].map((location) => (
              <option key={location.id} value={location.id}>
                {location.businessName}
              </option>
            ))}
          </select>
        </div>
        <div className="groups-section tab-section">
          <div className="tab-section-title">Groups</div>
          <div className="tab-section-subtitle">Send to these roles</div>
          {selectedMemo?.targets?.length ? (
            <ul className="sent-roles">
              {selectedMemo.targets.map((target, index) => {
                if (target === "boh") {
                  return (
                    <li key={target}>
                      <div>BOH</div>
                    </li>
                  );
                }
                if (target === "foh") {
                  return (
                    <li key={target}>
                      <div>FOH</div>
                    </li>
                  );
                }
                return (
                  <li key={target}>
                    <div>{jobFunctions[target]?.title}</div>
                  </li>
                );
              })}
            </ul>
          ) : null}
          {selectedMemo ? (
            <ClickableWraper
              className="delete-button"
              onClick={async () => {
                await deleteMemo(selectedMemo.id);
                setSelectedMemo(null);
              }}
            >
              <Icon icon="trash" />
              <div style={{ marginLeft: 10 }}>Delete Memo</div>
            </ClickableWraper>
          ) : null}
          {!selectedMemo ? (
            <div className="switches-list">
              <Switch
                label={"BOH"}
                large
                checked={Boolean(selectedTargets.find((t) => t === "boh"))}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onTargetValueChange("boh", e.target.checked)
                }
              />
              <Switch
                label={"FOH"}
                large
                checked={Boolean(selectedTargets.find((t) => t === "foh"))}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onTargetValueChange("foh", e.target.checked)
                }
              />
              {_.keys(jobFunctions).map((jobFunctionId) => {
                const jobFunctionData = jobFunctions[jobFunctionId];
                return (
                  <Switch
                    key={`target_switch_${jobFunctionId}`}
                    label={jobFunctionData.title}
                    checked={Boolean(
                      selectedTargets.find((t) => t === jobFunctionId)
                    )}
                    large
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onTargetValueChange(jobFunctionId, e.target.checked)
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const renderAttachmentInfo = () => {
    return (
      <>
        <div className="attachment-upload-section tab-section">
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={(e) => {
              if (e.target.files) {
                setFiles([...files, e.target.files[0]]);
              }
            }}
          />
          <ClickableWraper
            className="upload-button-container"
            onClick={() => {
              if (fileInputRef) {
                fileInputRef.current?.click();
              }
            }}
          >
            <div className="page-title">Upload</div>
            <div className="header-subtitle">Add an attachement</div>
          </ClickableWraper>
        </div>
        <div className="groups-section tab-section">
          <div className="tab-section-title">Files</div>
          <div className="tab-section-subtitle">
            Attached files are shown beloiw
          </div>
          <ul className="attachment-files-list">
            {files.map((file) => (
              <li key={file.name}>
                {file.name}
                <ClickableWraper
                  onClick={() =>
                    setFiles(files.filter((f) => f.name !== file.name))
                  }
                >
                  <Icon icon="cross" color="#B0B0B0" />
                </ClickableWraper>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const renderOpenedBy = () => {
    return (
      <div className="groups-section tab-section">
        <div className="tab-section-title">Groups</div>
        <div className="tab-section-subtitle">Sent to these roles</div>
        <div className="sent-roles-list">
          <ul>
            {[].map((item, index) => (
              <li key={index} onClick={() => setShowOpenedByModal(true)}>
                <div className="server-title">Servers</div>
                <div className="server-number">10/12</div>
                <Icon icon={<img src={require("assets/info.svg").default} />} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderMemoEditor = () => {
    return (
      <>
        <motion.div
          className="memo-editor-container"
          initial={{ opacity: 0, scale: 0.75 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
        >
          <div className="memo-editor-header">
            <input
              placeholder="Memo Title"
              disabled={!addNewMemo}
              style={{ width: "100%" }}
              className="page-title"
              onChange={(e) => {
                if (newMemo) {
                  const clonedNewMemo = { ...newMemo };
                  clonedNewMemo.title = e.target.value;
                  setNewMemo(clonedNewMemo);
                }
              }}
              value={selectedMemo ? selectedMemo.title : newMemo?.title}
            />
            <div className="header-subtitle">
              Sender: {user.contact.firstName} {user.contact.lastName}
            </div>
          </div>
          <Editor
            placeholder="Type memo here.."
            toolbar={{
              options: [
                "blockType",
                "fontSize",
                "list",
                "textAlign",
                "link",
                "history",
              ],
            }}
            toolbarHidden={!addNewMemo}
            editorState={editorState}
            readOnly={!addNewMemo ? true : false}
            wrapperClassName="wrapperClassName"
            editorClassName="memoEditorInput"
            onEditorStateChange={(editorStateData) =>
              setEditorState(editorStateData)
            }
          />
          {!selectedMemo ? (
            <Button
              text="Send Memo"
              id="send-memo"
              disabled={
                newMemo?.title.length === 0 || newMemo?.data.length === 0
              }
              onClick={sendMemoHandler}
              loading={memoLoading}
            />
          ) : null}
        </motion.div>
      </>
    );
  };
  return (
    <AnimatePresence>
      <div id="memos-page" style={{ overflowY: "scroll" }}>
        <div className={`left-part ${emulatorMode ? "emulatorMode" : ""}`}>
          <Box
            className="left-part-header"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <div className="page-title">Memos</div>
              <div className="header-subtitle">Your memo inbox</div>
            </Box>
            <Box display="flex">
              {/* <ClickableWraper className="header-icon" onClick={()=> setShowSearchMemoModal(true)}>
                <img src={require('assets/memo/search.svg').default}/>
              </ClickableWraper> */}
              <ClickableWraper
                className="header-icon"
                onClick={() => setAddNewMemo(!addNewMemo)}
              >
                <img src={require("assets/memo/plus.svg").default} />
              </ClickableWraper>
            </Box>
          </Box>
          <div className="left-part-tabs">
            <div className="tab-item active">Sent</div>
            {/* <div className="tab-item">Received</div> */}
          </div>
          {renderMemoLists()}
        </div>
        {addNewMemo || selectedMemo ? (
          <div className="center-part">{renderMemoEditor()}</div>
        ) : null}

        {/*right part*/}
        {addNewMemo || selectedMemo ? (
          <motion.div
            className="right-part"
            initial={{ opacity: 0, scale: 0.75 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <div className="left-part-tabs">
              <div
                className={`tab-item ${
                  rightSideSelectedTab === "memo-info" ? "active" : ""
                }`}
                onClick={() => setRightSideSelectedTab("memo-info")}
              >
                Memo Info
              </div>
              {selectedMemo ? null : ( // <div className={`tab-item ${rightSideSelectedTab === 'opened-by' ? 'active' : ''}`} onClick={() => setRightSideSelectedTab('opened-by')}>Opened By</div>
                <div
                  className={`tab-item ${
                    rightSideSelectedTab === "attachment" ? "active" : ""
                  }`}
                  onClick={() => setRightSideSelectedTab("attachment")}
                >
                  Add Attachment
                </div>
              )}
            </div>
            <div className="tab-content">
              {rightSideSelectedTab === "attachment" && renderAttachmentInfo()}
              {rightSideSelectedTab === "memo-info" && renderMemoInfo()}
            </div>
          </motion.div>
        ) : null}
      </div>
      <OpenedByModal
        isOpen={showOpenedByModal}
        onClose={() => setShowOpenedByModal(false)}
      />
      <SearchMemoModal
        isOpen={showSearchMemoModal}
        onClose={() => setShowSearchMemoModal(false)}
      />
    </AnimatePresence>
  );
};

export default Memos;
