import { Button, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  IProductMixReport,
  getProductMixForADateRange,
} from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import "../style.css";

const SalesMix = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyReports, setDailyReports] = useState<IProductMixReport[]>([]);
  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const deliverectProductTypes: FirebaseDeliverectProductType[] = useSelector(
    (state: RootState) => state.productTypes
  );

  // Sales	Meals	Average Table	Turn

  const fetchMoreData = useCallback(async () => {
    if (business && earliestFetchedDate) {
      const productTypes = deliverectProductTypes.reduce(
        (acc, val) => ({ ...acc, [val.plu]: val.productType }),
        {}
      );
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract(30, "days")
        .toDate();
      const laborReports = await getProductMixForADateRange(
        business.id,
        newEarliestDate,
        oldEarliestDate,
        productTypes,
        true
      );

      console.log("laborReports", laborReports);

      setEarliestFetchedDate(newEarliestDate);
      setLatestResponseLength(laborReports.length);
      setDailyReports(
        [...dailyReports, ...laborReports].sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        )
      );
    }
  }, [dailyReports, earliestFetchedDate, business?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (business) {
        const productTypes = deliverectProductTypes.reduce(
          (acc, val) => ({ ...acc, [val.plu]: val.productType }),
          {}
        );
        const earliestDate = moment().subtract("days", 30).toDate();
        const reports = await getProductMixForADateRange(
          business.id,
          earliestDate,
          moment().add(1, "day").toDate(),
          productTypes,
          true
        );
        setEarliestFetchedDate(earliestDate);
        setLatestResponseLength(reports.length);
        setDailyReports(
          reports.sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
        );
        console.log("Report: ", reports);
      }
    };

    fetchInitialData();
  }, [business?.id, deliverectProductTypes]);

  const removeWeirdChars = (text: string) => {
    let i = 0;
    let result = "";
    while (i < text.length) {
      if (!/^\d+$/.test(text[i])) {
        break;
      }
      result += text[i];
      i += 1;
    }
    return result;
  };

  const createCsvReport = (report: IProductMixReport, snapshot = false) => {
    const data = report.data;
    // const headers = ',Item,PLU,Type,PLU,Item Price,Quantity,Total Sold\r\n'
    const csvReport = data.reduce((acc, row) => {
      row.products.forEach((product) => {
        acc += (product.serverName || "") + ",";
        acc += product.productName + ",";
        acc += removeWeirdChars(product.plu) + ",";
        acc += product.menuCategory + ",";
        acc += removeWeirdChars(product.plu) + ",";
        acc +=
          (
            Math.round(product.sales / Math.ceil(product.quantity)) / 100
          ).toFixed(2) + ",";
        acc += Math.ceil(product.quantity) + ",";
        acc += (Math.round(product.sales) / 100).toFixed(2) + "\r\n";
      });
      return acc;
      // // Add an empty new line
      // const newLine = ',,,,,,'
    }, "data:text/csv;charset=utf-8,");

    const encodedUri = encodeURI(csvReport);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      snapshot ? "sales_mix_snapshot.csv" : "sales_mix_report.csv"
    );
    document.body.appendChild(link);

    link.click();

    setReportIdLoading(null);
  };

  const hasMoreDocumentsToLoad = _.isNull(latestResponseLength)
    ? true
    : Boolean(latestResponseLength);
  return (
    <div className="w-full">
      <div className="page-header">
        <div className="page-title">Daily Sales Mix by Server CSV</div>
      </div>
      <Box display="flex" flexDirection="column">
        {dailyReports.length === 0 ? (
          <div style={{ marginTop: 20 }}>
            <Spinner size={40} />
            <div
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              This may take a couple minutes to load.
            </div>
          </div>
        ) : (
          <>
            {_.uniqBy(dailyReports, "id").map((report) => {
              const isLoadingBlob = reportIdLoading === report.id;
              const renderButton = () => {
                const lastReportStartDay = moment(report.date);
                const lastReportEndDay = lastReportStartDay
                  .clone()
                  .add(1, "day");
                if (
                  moment().isAfter(lastReportStartDay) &&
                  moment().isBefore(lastReportEndDay)
                ) {
                  return (
                    <div
                      className="report-table-text"
                      onClick={() => {
                        setReportIdLoading(report.id);

                        createCsvReport(report, true);
                        // fetchAndOpenReport()
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View Snapshot
                    </div>
                  );
                }
                if (isLoadingBlob) {
                  return <Spinner size={20} />;
                }
                return (
                  <div
                    className="report-table-text"
                    onClick={() => {
                      setReportIdLoading(report.id);

                      createCsvReport(report);
                      // fetchAndOpenReport()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View Report
                  </div>
                );
              };
              return (
                <Box
                  key={report.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: 5, marginTop: 10 }}
                >
                  <div className="report-table-text">
                    {moment(report.date).format("MMMM D, YYYY")}
                  </div>
                  {renderButton()}
                </Box>
              );
            })}
            {hasMoreDocumentsToLoad && (
              <div className="center-button">
                <Button
                  text="Load more"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await fetchMoreData();
                    setLoading(false);
                  }}
                  className="load-more-button" // create-new-teammate
                  fill
                />
              </div>
            )}
          </>
        )}
      </Box>
    </div>
  );
};
export default SalesMix;
