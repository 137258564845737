import { Icon, Spinner } from "@blueprintjs/core";
import React, { ChangeEvent, useRef, useState } from "react";

import Box from "components/Box";

import UploadImageIcon from "assets/upload-image.svg";

interface Props {
  addChoice: () => void;
  options: string[];
  updateChoice: (value: string, index: number) => void;
  color: string;
  deleteOption: (index: number) => void;
  handleFileChange: (image: string, index: number, fileName: string) => void;
  images: string[];
  uploadingIndex: number | null;
}
const PictureChoice = ({
  addChoice,
  options,
  updateChoice,
  color,
  deleteOption,
  handleFileChange,
  images,
  uploadingIndex,
}: Props) => {
  const refs = useRef<HTMLInputElement[]>([]);
  const handleBgImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      //@ts-ignore
      reader.fileName = e.target.files[0].name;
      reader.onload = async (event) => {
        if (event.target) {
          //@ts-ignore
          const fileName = new Date().getTime().toString();
          // @ts-ignore
          if (refs.current[index]) {
            // @ts-ignore
            refs.current[index].value = "";
          }
          const text = event.target.result;

          //@ts-ignore
          handleFileChange(text, index, fileName);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div className="multiple-choice-container picture-choice-container">
      <Box display="flex">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`single-choice-container active`}
              style={{ borderColor: color, backgroundColor: color + "0C" }}
            >
              {/*@ts-ignore*/}
              <div
                style={{ borderColor: color }}
                className="upload-image"
                // @ts-ignore
                onClick={() => {
                  refs.current[index].click();
                }}
              >
                {uploadingIndex === index ? (
                  <Spinner size={30} />
                ) : images[index] ? (
                  <img src={images[index]} className="option-image" />
                ) : (
                  <>
                    <img src={UploadImageIcon} />
                    <div style={{ color }} className="apply-font">
                      Upload
                    </div>
                  </>
                )}

                {/*@ts-ignore*/}
                <input
                  type="file"
                  onChange={(e) => handleBgImageUpload(e, index)}
                  hidden
                  // @ts-ignore
                  ref={(ref) => (refs.current[index] = ref)}
                />
              </div>
              <Box display="flex">
                <div
                  className="key-bind apply-font"
                  style={{ borderColor: color, color }}
                >
                  {String.fromCharCode(index + 65)}
                </div>
                <input
                  style={{ color }}
                  className="choice-name apply-font"
                  value={item}
                  onChange={(e) => updateChoice(e.target.value, index)}
                />
              </Box>
              <Icon
                onClick={() => deleteOption(index)}
                icon="cross"
                className="delete-option-icon"
                color={color}
              />
            </div>
          );
        })}
      </Box>
      <div
        className="add-choice apply-font"
        onClick={addChoice}
        style={{ color }}
      >
        Add Another choice
      </div>
    </div>
  );
};
export default PictureChoice;
