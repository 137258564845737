import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage, setupRoutes } from "../routes";
import useItems from "./useItems";

const Items = () => {
  const {
    itemsData,
    saveChanges,
    addItem,
    deleteItems,
    columns,
    instructions,
    isEditing,
    setEditing,
  } = useItems();
  const navigate = useNavigate();

  const nav = useCallback((id: string) => {
    if (id.startsWith('new__')) return;
    navigate(`/inventory/rawItems/${id}`);
  }, [navigate]);

  const save = useCallback(
    (i) =>
      saveChanges(i).then((createdId) => {
        if (createdId) nav(createdId);
      }),
    [nav, saveChanges]
  );
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar routes={setupRoutes} />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Items"
            columns={columns}
            data={itemsData}
            instructions={instructions}
            saveResults={save}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="New Item"
            primaryActionHandler={addItem}
            onRowClick={nav}
            deleteIds={deleteItems}
            loading={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Items;
