import { FirebaseTableDoc } from "types/tables";

import ActionType from "./actionTypes";

export function receivePhysicalTables(tables: FirebaseTableDoc[]) {
  return {
    type: ActionType.RECEIVE_PHYSICAL_TABLES,
    tables,
  };
}
