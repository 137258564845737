import React from "react";

export default function NotFound() {
  return (
    <div style={{ textAlign: "center", margin: "6em", color: "black" }}>
      <h3>404</h3>
      <p>We're sorry, but the page you requested in not available.</p>
    </div>
  );
}
