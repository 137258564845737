import React, { useState } from "react";
import { Link } from "react-router-dom";

import Layout from "../../Layout";
import "../style.css";
import CashSummaryModal from "./CashSummaryModal";

const CashSummary = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Layout>
      <div id="cash-summary">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          <Link to="/reporting">
            <div className="button">Sales Summary</div>
          </Link>
          <Link to="/product-analytics">
            <div className="button">Product Analytics</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>
          {/*
          <div  className="button active">Cash Summary</div>
          <Link to="/labor-payroll">
            <div  className="button">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section">
          <div className="body">
            <div className="page-header">
              <div className="page-title">Daily Cash Summary</div>
            </div>
            <table>
              <tr>
                <th>DATE</th>
                <th>STARTING CASH</th>
                <th>CASH SALES</th>
                <th>CASH REFUNDS</th>
                <th>PAYOUTS</th>
                <th>CARD TIP-OUTS</th>
                <th>ENDING CASH</th>
                <th></th>
              </tr>
              <tr>
                <td>June 1, 2021</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td onClick={() => setShowModal(true)}>View Report</td>
              </tr>
              <tr>
                <td>June 1, 2021</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td onClick={() => setShowModal(true)}>View Report</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <CashSummaryModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default CashSummary;
