import { Button, Position } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import React from "react";

import Box from "components/Box";

interface Props {
  onNext: () => void;
}

const UnderWriting = ({ onNext }: Props) => (
  <Box className="form-container">
    <div className="slide-in">
      <div className="welcome-title no-width no-margin">
        Great!
        <br />
        Now a bit of information for our underwriting.
      </div>
      <div className="secure-info">
        All information entered here is secured with 256-bit encryption.
      </div>

      <div className="input-lable">
        What is your <span>home address</span>?
      </div>
      <input placeholder="type your answer here..." className="form-input" />

      <div className="input-lable">
        What is your <span>DOB</span>?
      </div>
      <DateInput
        className="open-date-selector no-margin"
        formatDate={(date) => date.toLocaleDateString("en-US")}
        parseDate={(str) => new Date(Date.parse(str))}
        placeholder="Please select a date"
        defaultValue={new Date()}
        popoverProps={{ position: Position.BOTTOM }}
      />

      <div className="input-lable">
        What is your <span>SSN/SIN</span>?
      </div>
      <input placeholder="type your answer here..." className="form-input" />

      <Button text="Next" fill className="next-button" onClick={onNext} />
    </div>
  </Box>
);
export default UnderWriting;
