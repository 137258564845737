import { ApexOptions } from "apexcharts";
import React, { useMemo } from "react";
import Chart from "react-apexcharts";

import "./style.css";

type Props = {
  sales: number;
  cost: number;
};
const Legend = ({ sales, cost }: Props) => {
  const salesStr = `$${Math.floor(sales / 100).toLocaleString()}`;
  const costStr = `$${Math.floor(cost / 100).toLocaleString()}`;
  return (
    <div className="legend-container">
      <div className="legend-item legend-item-sales">
        <div className="color-indicator" />
        <div className="legend-text">
          <div className="legend-title">{salesStr}</div>
          <div className="legend-subtitle">WTD Sales</div>
        </div>
      </div>
      <div className="legend-item legend-item-lc">
        <div className="color-indicator" />
        <div className="legend-text">
          <div className="legend-title">{costStr}</div>
          <div className="legend-subtitle">WTD LC</div>
        </div>
      </div>
    </div>
  );
};

const Graph = ({ sales, cost }: Props) => {
  const options = useMemo(
    (): ApexOptions => ({
      chart: {
        id: "wtd-sales-vs-lc",
        toolbar: {
          show: false,
        },
      },
      title: {
        text: "WTD Sales vs Labor Cost",
        align: "center",
        style: {
          fontFamily: "Lato-Black",
          fontSize: "20px",
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        colors: ["#CB55A8", "#F4A164"],
      },
      dataLabels: {
        enabled: false,
      },
    }),
    []
  );
  const series = useMemo(() => [sales, cost], [sales, cost]);
  return (
    <div className="wtd-sales-chart">
      <Chart
        dataLabels={options.dataLabels}
        options={options}
        series={series}
        type="donut"
      />
      <Legend sales={sales} cost={cost} />
    </div>
  );
};

export default Graph;
