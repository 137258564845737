import UIComponents from "@tangopay/tango-ui-library";
import React, { PropsWithChildren } from "react";
import { EventWrapperProps, View } from "react-big-calendar";

import CrossIcon from "../../../assets/calendar/status_cross.svg";
import QuestionMarkIcon from "../../../assets/calendar/status_question_mark.svg";
import TickIcon from "../../../assets/calendar/status_tick.svg";
import EventPopover from "./EventPopover";
import useAccounts from "./useAccounts";
import useCalendar, { CustomEventType } from "./useCalendar";

interface Props {
  event: CustomEventType;
  style: (React.CSSProperties & { xOffset: number }) | undefined;
  selectedView: { label: string; value: View };
}
const EventWrapper = (props: Props) => {
  const {
    selectedEvent,
    setShowEventDetailPopover,
    setSelectedEvent,
    showEventDetailPopover,
  } = useCalendar();
  const { calendars } = useAccounts();
  const { event, style } = props;
  const { title, description } = event;
  if (props.selectedView.value === "month") {
    return (
      <EventPopover
        accounts={calendars}
        onClickOutside={() => {
          if (selectedEvent?.showTimePicker) {
          } else {
            setSelectedEvent(null);
            setShowEventDetailPopover(false);
          }
        }}
        isOpen={showEventDetailPopover && selectedEvent?.id == event.id}
        event={event}
        onProposeTimeClick={() => {
          if (selectedEvent) {
            setSelectedEvent({ ...selectedEvent, showTimePicker: true });
          }
        }}
        showTimePicker={selectedEvent?.showTimePicker || false}
        onCloseProposeTime={() => {
          if (selectedEvent) {
            setSelectedEvent({
              ...selectedEvent,
              showTimePicker: false,
            });
          }
        }}
        onEventClick={() => {
          setSelectedEvent(event);
          setShowEventDetailPopover(true);
        }}
        showAccountsPicker={selectedEvent?.showAccountsPicker || false}
        toggleAccountsPicker={() => {
          if (selectedEvent) {
            setSelectedEvent({
              ...selectedEvent,
              showAccountsPicker: !selectedEvent.showAccountsPicker,
            });
          }
        }}
      />
    );
  }
  return (
    <div
      style={{
        height: style?.height + "%",
        top: style?.top + "%",
        width: style?.width + "%",
        borderWidth: "1.5px",
      }}
      className={`${
        event.allDay ? "" : "absolute"
      } bg-blue-1 border-blue-4 rounded-2xl px-[14px] py-[10px]`}
    >
      <div className={"relative h-full"}>
        <div className={"font-lato-black text-xs text-black"}>
          {event.title}
        </div>
        <div className={"font-lato-medium text-veryTiny text-black"}>
          {event.description ?? ""}
        </div>
        {event.videoCallUrl ? (
          <UIComponents.Button
            label={"Join"}
            type={"btn-style-2"}
            size={"btn-small"}
            className={"absolute bottom-0 right-0"}
          />
        ) : null}
      </div>
    </div>
  );
};
export default EventWrapper;
