import { Grid } from "@mui/material";
import React from "react";

import "./style.css";

interface Props {
  children: React.ReactNode[];
}

/**
 * Displays a table in 3 columns. Visually there are only two columns.
 * The first column is small, and has no border. In practical terms,
 * the first column is a left side icon in the left visual column.
 * The left visual column is shaded, the right is not.
 */
const Table = ({ children }: Props) => {
  return (
    <Grid className="stats-table-container" container columns={14} spacing={0}>
      {children.map((child, idx) => {
        const width = [1, 6, 7][idx % 3];
        return (
          <Grid item xs={width} className="stats-grid-cell">
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default Table;
