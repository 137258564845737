import moment from "moment";

export const getWeekRange = (date: any) => ({
  from: moment(date).startOf("week").toDate(),
  to: moment(date).endOf("week").toDate(),
});
export const getWeekDays = (weekStart: Date) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
};

/* eslint-disable import/prefer-default-export */
export function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
}

export const getArrayOfDatesStartingFromADate = (start: Date, end: Date) => {
  let currentDate = moment(start).add(1, "day");
  const endDate = moment(end);
  const days: Date[] = [currentDate.toDate()];
  console.log("currentDate", currentDate.toDate());
  console.log("endDate", endDate.toDate());
  while (currentDate.isBefore(endDate)) {
    currentDate = currentDate.add(1, "day");
    days.push(currentDate.toDate());
  }
  return days;
};

export const getStaffMemberFullName = (staffMember: StaffMember): string =>
  `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;

export const getStaffMemberEmail = (staffMember: StaffMember): string =>
  staffMember.contact?.email;

export const getStaffMemberPhoneNumber = (staffMember: StaffMember): string =>
  staffMember.contact.phone;

export const getStaffMemberJobFunctions = (staffMember: StaffMember): string =>
  staffMember.contact.phone;
