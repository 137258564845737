import tangoComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo } from "react";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { CombinedItem } from "controllers/inventoryItemInfo";

const { Dropdown } = tangoComponents;

export type Ingredient = {
  uniqueId: string;
  itemId: string | null;
  amount: number;
};

type Instruction = RenderInstruction<Ingredient>;

const instructions: { [attr: string]: Instruction } = {};

instructions.amount = {
  type: "number",
};
// instructions.cost = {
//   type: "currency",
// };
// specific components
type ViewProps = {
  fullObject: Ingredient;
};
type EditProps = {
  fullObject: Ingredient;
  onChange?: (newValue: Ingredient) => unknown;
};

type ViewItemProps = ViewProps & {
  items: CombinedItem[];
};
type EditItemProps = EditProps & {
  items: CombinedItem[];
};

const ViewUnit = ({ fullObject, items }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = items.find((opt) => opt.id == selected);
  return <div>{sel?.ingredientUnitName ?? "Select an item"}</div>;
};

const ViewItemType = ({ fullObject, items }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = items.find((opt) => opt.id == selected);
  return <div>{sel?.isRaw ? "Basic" : "Prep"}</div>;
};

const EditItem = ({ fullObject, onChange, items }: EditItemProps) => {
  const options = useMemo(
    () =>
      items.map((i) => ({
        value: i.id,
        label: i.name,
      })),
    [items]
  );
  const handleChange = useCallback(
    (item) => {
      const selected = items.find((i) => i.id == item.value);
      if (!selected) {
        onChange?.({
          ...fullObject,
          itemId: "",
        });
      } else {
        onChange?.({
          ...fullObject,
          itemId: item.value,
        });
      }
    },
    [fullObject, onChange, items]
  );

  return (
    <Dropdown
      options={options}
      value={options.find(({ value }) => value == fullObject.itemId)}
      onChange={handleChange}
      size="small"
    />
  );
};


const ViewItem = ({ fullObject, items }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = items.find((opt) => opt.id == selected);
  return <div>{sel?.name ?? "Select an item"}</div>;
};

export const instructionsFactory = (
  items: CombinedItem[],
  currency: string,
): { [attr: string]: Instruction } => {

  const ViewCost = ({ fullObject, items }: ViewItemProps) => {
    const selected = fullObject.itemId;
    const sel = items.find((opt) => opt.id == selected);
    if (!sel) return <div> - </div>;
    const {
      defaultCost,
    } = sel;
    const totalCost = fullObject.amount * (defaultCost ?? 0) / 100;
    return <div>{totalCost.toLocaleString("en-US", {
      style: "currency",
      currency,
    })}</div>;
  };
  return {
    ...instructions,
    unit: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewUnit fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewUnit fullObject={fullObject} items={items} />
      ),
    },
    itemId: {
      type: "select",
      placeholder: "Select an item",
      options: items.map((i) => ({
        value: i.id,
        label: i.name,
      })),
    },
    cost: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewCost fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewCost fullObject={fullObject} items={items} />
      ),
    },
    itemType: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewItemType fullObject={fullObject} items={items} />
      ),
      editComponent: ({ fullObject }) => (
        <ViewItemType fullObject={fullObject} items={items} />
      ),
    }
  };
};

