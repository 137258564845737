import { TangoDispute, generateReasonText } from 'controllers/disputes';
import { TangoTab } from 'controllers/payments';
import moment from 'moment';
import React from 'react';
import { CustomerModel } from '../ClosedChecks/useClosedChecks';
import './timeline.css';


interface TimelineProps {
    closedCheck: TangoTab,
    dispute: TangoDispute,
}

const Timeline = ({ closedCheck, dispute }: TimelineProps) => {

    return (
        <>
            <div className="timeline relative flex pb-10">
                <div className="timeline-container w-6/12">
                    <div className="content">
                        <div className='text-xs font-lato-bold text-black mb-1'>{moment.unix(dispute.created).format('MMM DD, YYYY, hh:MM A')}</div>
                    </div>
                </div>
                <div className='w-6/12 pl-4'>
                    <div className='text-xs font-lato-bold text-black mb-1'>Payment disputed</div>
                    <div className='text-xs font-lato-regular text-grey-3'>
                        {generateReasonText(dispute.reason)}
                    </div>
                </div>
            </div>


            {closedCheck.customer.map((customer: CustomerModel, index: number) => (
                <div
                    key={index}
                >
                    {customer.payment.paymentType.chargeId === dispute.charge && customer.payment && customer.payment.paymentType &&
                        <div className="timeline relative flex">

                            <div className="timeline-container w-6/12">
                                <div className="content">
                                    <div className='text-xs font-lato-bold text-black mb-1'>
                                        {
                                            moment.unix(customer.payment.paymentType.created).format('MMM DD, YYYY, hh:MM A')
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='w-6/12 pl-4'>
                                <div className='text-xs font-lato-bold text-black mb-1'>

                                    Payment

                                    {customer.payment.paymentType.brand ?
                                        ' using ' + customer.payment.paymentType.brand + ` (${customer.payment.paymentType.lastFour}) succeeded`
                                        :
                                        'succeeded'}

                                </div>
                            </div>

                        </div>
                    }
                </div>
            ))}

        </>


    )
};

export default Timeline;