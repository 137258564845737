import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";

import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
  onDelete: () => void;
}
const DeleteFormConfirmationModal = ({
  showModal,
  onClose,
  onDelete,
}: Props) => {
  return (
    <Dialog
      onClose={onClose}
      hasBackdrop
      isOpen={showModal}
      className="create-board-modal delete-form-confirmation-modal"
    >
      <div className="modal-header">
        <div className="header-title">Delete Form?</div>
        <Icon icon="cross" size={15} color="#424452" onClick={onClose} />
      </div>
      <div className="modal-body">
        <Button text="Delete" onClick={onDelete} />
        <Button text="Cancel" onClick={onClose} />
      </div>
    </Dialog>
  );
};

export default DeleteFormConfirmationModal;
