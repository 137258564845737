import { SidebarRoute } from "./Sidebar";

export const setupRoutes: SidebarRoute[] = [
  {
    label: "Measurements",
    link: "/inventory/measurements",
    subRoutes: [
      { label: "Table", link: "/inventory/measurements" },
      { label: "Conversions", link: "/inventory/conversions" },
    ],
    isExpanded: false,
  },
  {
    label: "Locations",
    link: "/inventory/locations",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Allergens",
    link: "/inventory/allergens",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Groups",
    link: "/inventory/primaryGroups",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Items",
    link: "/inventory/items/basic",
    subRoutes: [
      { label: "Basic Item", link: "/inventory/items/basic" },
      { label: "Prep Item", link: "/inventory/items/prep" },
    ],
    isExpanded: false,
  },
  {
    label: "Vendors",
    link: "/inventory/vendors",
    subRoutes: [],
    isExpanded: false,
  },
];

export const recipesRoute: SidebarRoute[] = [
  {
    label: "Prep",
    link: "/inventory/recipes/prepitems",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Menu",
    link: "/inventory/recipes/menu-items",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Modifiers",
    link: "/inventory/recipes/modifiers",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Manage Vendor Items",
    link: "/inventory/recipes/vendor-items",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Recipes Items",
    link: "/inventory/recipes/items",
    subRoutes: [],
    isExpanded: false,
  },
];

export const inventoryRoute: SidebarRoute[] = [
  {
    label: "Current Inventory",
    link: "/inventory/current",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Counts",
    link: "/inventory/counts",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Invoices",
    link: "/inventory/invoices",
    subRoutes: [],
    isExpanded: false,
  },
  {
    label: "Waste",
    link: "/inventory/waste",
    subRoutes: [],
    isExpanded: false,
  },
];

export const headerRoutes = [
  {
    label: "Menus",
    link: "/menus",
  },
  {
    label: "Products",
    link: "/products",
  },
  {
    label: "Modifiers",
    link: "/modifiers"
  },
  {
    label: "Modifier Options",
    link: "/modifier-options"
  },
  {
    label: "Discounts",
    link: "/discounts"
  },
  {
    label: "Inventory",
    link: "/inventory/items/basic"
  },
];

export const inventorySelectedPage = {
  label: "Food & Bev",
  value: "/products"
};

