import TangoComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useEffect, useState } from "react";

import SearchIcon from "assets/search-icon.svg";

import "./style.css";

const { Button, TextField } = TangoComponents;

type Props = {
  title: string;
  onCreateNote: () => void;
  updateSearchValue: (val: string) => void;
};
const HeaderBar = (props: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const onSearchValueChange = useCallback(
    (evt) => setSearchValue(evt.target.value),
    []
  );
  useEffect(() => {
    props.updateSearchValue(searchValue);
  }, [searchValue, props.updateSearchValue]);
  return (
    <div className="daily-notes-header">
      <div className="daily-notes-title">{props.title}</div>
      <Button
        className="new-note-button"
        onClick={props.onCreateNote}
        label="Create New Note"
        size="btn-large"
        type="btn-style-1"
      />
      <div className="daily-notes-search-icon">
        <img src={SearchIcon} />
      </div>
      <TextField
        sizeType="medium"
        className="daily-notes-search-field"
        value={searchValue}
        onChange={onSearchValueChange}
      />
    </div>
  );
};

export default HeaderBar;
