import * as React from "react";

export const Ongoing = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      d="M16.7917 0.125H2.20833C1.0625 0.125 0.125 1.0625 0.125 2.20833V16.7917C0.125 17.9375 1.0625 18.875 2.20833 18.875H16.7917C17.9375 18.875 18.875 17.9375 18.875 16.7917V2.20833C18.875 1.0625 17.9375 0.125 16.7917 0.125ZM2.20833 16.7917V2.20833H8.45833V16.7917H2.20833ZM16.7917 16.7917H10.5417V9.5H16.7917V16.7917ZM16.7917 7.41667H10.5417V2.20833H16.7917V7.41667Z"
      fill={color}
    />
  </svg>
);
