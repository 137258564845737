import { FirebaseCashDrawerDoc } from "types/cashDrawers";

import ActionType from "./actionTypes";

export function receiveCashDrawers(cashDrawers: FirebaseCashDrawerDoc[]) {
  return {
    type: ActionType.RECEIVE_CASH_DRAWERS,
    cashDrawers,
  };
}
