import { WorkEventsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: WorkEvent[] = [];

export default function workEvents(
  state = initialState,
  action: WorkEventsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_WORK_EVENTS:
      return action.workEvents;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
