import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import moment from "moment";

import { FormItem } from "./useForms";

type Instruction = RenderInstruction<FormItem>;
export const instructions: { [x: string]: Instruction } = {};

instructions.createdAt = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return (
            <div>{moment(fullObject.createdAt.toDate()).format("YYYY-MM-DD")}</div>
        )
    },
    editComponent: null,
}
instructions.type = {
    type: 'complex-custom',
    viewComponent: ({ fullObject }) => {
        if (fullObject.type === 'General Survey') {
            return <div className="py-1 px-3 rounded-full text-tiny font-lato-bold bg-green-2 border border-solid border-green-3 flex" style={{ width: "fit-content" }}>{fullObject.type}</div>
        }
        if (fullObject.type === 'Job Application') {
            return <div className="py-1 px-3 rounded-full text-tiny font-lato-bold bg-blue-2 border border-solid border-blue-3 flex" style={{ width: "fit-content" }}>{fullObject.type}</div>
        }
        if (fullObject.type === 'Ticket') {
            return <div className="py-1 px-3 rounded-full text-tiny font-lato-bold bg-pink-2 border border-solid border-pink-3 flex" style={{ width: "fit-content" }}>{fullObject.type}</div>
        }
        return <div className="py-1 px-3 rounded-full text-tiny font-lato-bold bg-purple-2 border border-solid border-purple-3 flex" style={{ width: "fit-content" }}>{fullObject.type}</div>
    },
    editComponent: null
}
instructions.enabled = {
    type: "boolean",
    useCheckbox: false,
    trueText: "Yes",
    falseText: "No"
};
export const columns: ColumnInstruction<FormItem>[] = [
    { type: "data", attribute: "name", header: "Form Name" },
    { type: "projection", attribute: "createdAt", header: "Date Created" },
    { type: "data", attribute: "responseCount", header: "Responses" },
    { type: "data", attribute: "enabled", header: "Activity" },
    { type: "data", attribute: "type", header: "Form Type" },
];
