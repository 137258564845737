import { SidebarRoute } from "components/Sidebar";

export const cloudRoutes: SidebarRoute[] = [
  {
    label: "Files",
    link: "/documents/files",
    subRoutes: [],
    isExpanded: false,
  },
  // {
  //   label: "Starred",
  //   link: "/manager/documents/starred",
  //   subRoutes: [],
  //   isExpanded: false,
  // },
  {
    label: "Trashed",
    link: "/documents/trashed",
    subRoutes: [],
    isExpanded: false,
  },
];

export const headerRoutes = [
  {
    label: "Staffing",
    link: "/inventory/measurements",
  },
  {
    label: "Team",
    link: "/inventory/recipes/prepitems",
  },
  {
    label: "Daily Logs",
    link: "/inventory/current",
  },
  {
    label: "Hiring",
    link: "/inventory/current",
  },
  {
    label: "Cloud",
    link: "/inventory/current",
  },
  {
    label: "Payrol",
    link: "/inventory/current",
  },
];
