import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import firebase from "../../../../../../config/firebase";
import { RootState } from "../../../../../../model/store";

const db = firebase.firestore();

interface UseUpdateDocument {
  docId?: string;
}

interface TangoDocs {
  businessId: string;
  createdAt: any;
  createdBy: string;
  data: string;
  deleted: boolean;
  deletedAt: any;
  id: string;
  title: string;
  updatedAt: any;
  user: {
    Email: string;
    firstName: string;
    lastName: string;
  };
}

export const useUpdateDocument = ({ docId }: UseUpdateDocument) => {
  const [docData, setDocData]: any = useState<TangoDocs | null>();

  const [docText, setDocText] = useState("");
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const saveDataToDoc = useCallback(
    (data: any) => {
      try {
        db.collection("TangoDocs")
          .doc(docId)
          .update({ data: JSON.stringify(data) });
      } catch (err) {
        console.error(err);
      }
    },
    [docId]
  );

  const debouncedSave = React.useRef(
    debounce(async (data) => {
      saveDataToDoc(data);
    }, 1500)
  ).current;

  const handleChange = (value: string) => {
    setDocText(value);
    debouncedSave(value);
  };

  useEffect(() => {
    if (docId && business) {
      const unsubscribe = db
        .collection("TangoDocs")
        .where("businessId", "==", business.id)
        .where("id", "==", docId)
        .onSnapshot((snapshot) => {
          const data: any = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const docData =
            data[0]?.data.length <= 0 ? "" : JSON.parse(data[0]?.data);
          setDocData(data[0]);
          setDocText(docData);
        });

      return () => unsubscribe();
    }
  }, [docId, business]);

  useEffect(() => {
    return () => {
      debouncedSave.cancel;
    };
  }, [debouncedSave]);

  return {
    docData,
    docText,
    handleChange,
    saveDataToDoc,
  };
};
