import { BusinessesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: TangoBusiness[] = [];

export default function businesses(
  state = initialState,
  action: BusinessesAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_BUSINESSES:
      return [...initialState, ...action.businesses];
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
