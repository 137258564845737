import { Icon, InputGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import firebase from "config/firebase";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Box from "../../../../components/Box";
import { RootState } from "../../../../model/store";

const ViewArchivedEmployees = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const usersLocations = useSelector((state: RootState) => state.locations);

  //@ts-ignore
  const { jobFunc } = location.state;
  //@ts-ignore
  const [staffData, setStaffData] = useState(location.state.staffData);
  const [searchText, setSearchText] = useState("");
  const [rolesForSidebar, setRolesForSidebar] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const [selectedDepartment, setSelectedDepartment] = useState<
    "foh" | "boh" | string
  >("");
  const getDotColor = (status: string) => {
    switch (status) {
      case "active":
        return "#5BEF43";
      case "paused":
        return "yellow";
      case "archive":
        return "orange";
      case "delete":
        return "red";
    }
  };
  const getStatusLabel = (status: string) => {
    switch (status) {
      case "active":
        return "Active";
      case "paused":
        return "Paused";
      case "archive":
        return "Archive";
      case "delete":
        return "Delete";
    }
  };
  useEffect(() => {
    //@ts-ignore
    const { staffData } = location.state;
    const groupedByRoles = _.groupBy(
      staffData.filter((item: any) => item.status === "archive"),
      (item: any) => item.primaryRole
    );
    //@ts-ignore
    setRolesForSidebar(Object.keys(groupedByRoles));
    console.log(staffData);
  }, []);

  useEffect(() => {
    if (selectedRole.length > 0) {
      const filteredStaffData = staffData.filter(
        (item: any) =>
          item.primaryRole.toLowerCase() === selectedRole.toLowerCase()
      );
      setFilterData(filteredStaffData);
    } else {
      setFilterData([]);
    }
  }, [selectedRole]);
  useEffect(() => {
    if (selectedDepartment.length > 0) {
      const filteredData = staffData.filter((item: any) => {
        if (selectedDepartment === "other") {
          return item.departmentId === "";
        } else {
          return item.departmentId === selectedDepartment;
        }
      });
      setFilterData(filteredData);
    } else {
      setFilterData([]);
    }
  }, [selectedDepartment]);
  useEffect(() => {
    if (selectedLocation.length > 0) {
      const filteredData = staffData.filter(
        (item: any) => item.businessId === selectedLocation
      );
      setFilterData(filteredData);
    } else {
      setFilterData([]);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (searchText.length > 0) {
      const filteredData = staffData.filter((item: any) => {
        const position = item.firstName
          .toLowerCase()
          .search(searchText.toLowerCase());
        if (position >= 0) {
          return item;
        }
      });
      setFilterData(filteredData);
    } else {
      setFilterData([]);
    }
  }, [searchText]);
  const updateStauts = async (employeeData: any, status: any) => {
    try {
      const deleted = status.slug === "delete";
      if (deleted) {
        const documentSnapShot = await firebase
          .firestore()
          .collection("Staff")
          .doc(employeeData.staffMemberCopyId)
          .update({
            status: status.slug,
            deleted: true,
            updatedAt: new Date(),
          });
      } else {
        const documentSnapShot = await firebase
          .firestore()
          .collection("Staff")
          .doc(employeeData.staffMemberCopyId)
          .update({ status: status.slug, updatedAt: new Date() });
      }

      const staffIndex = staffData.findIndex(
        (item: any) => item.staffMemberCopyId === employeeData.staffMemberCopyId
      );
      const clonedStaffData = [...staffData];
      clonedStaffData[staffIndex]["status"] = status.slug;
      setStaffData(clonedStaffData);
      if (filterData.length > 0) {
        const staffSearchGridIndex = filterData.findIndex(
          (item: any) =>
            item.staffMemberCopyId === employeeData.staffMemberCopyId
        );
        if (staffSearchGridIndex !== -1) {
          const clonedSearchGridData = [...filterData];
          //@ts-ignore
          clonedSearchGridData[staffSearchGridIndex]["status"] = status.slug;
          //@ts-ignore
          setFilterData(clonedSearchGridData);
        }
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };
  const renderEmployeeRow = (item: any) => {
    if (item.status === "archive") {
      return (
        <tr key={item.id}>
          <td>
            <div>{item.firstName + " " + item.lastName}</div>
            {/* <ul>
                  <li>Cincinnati, OH</li>
                  <li>Full-Time</li>
                  <li>Customer Support Role</li>
                </ul> */}
          </td>
          <td className="status-column">
            {item.status ? (
              <Icon icon="dot" color={getDotColor(item.status)} size={20} />
            ) : null}{" "}
            <Select
              items={[
                { name: "Active", slug: "active" },
                { name: "On Leave", slug: "on-leave" },
                { name: "Archive", slug: "archive" },
                { name: "Delete", slug: "delete" },
              ]}
              filterable={false}
              itemRenderer={(statusItem, { handleClick }) => (
                <MenuItem text={statusItem.name} onClick={handleClick} />
              )}
              onItemSelect={(statusItem) => {
                updateStauts(item, statusItem);
              }}
            >
              <MenuItem
                text={item.status ? getStatusLabel(item.status) : "No Status"}
              />
            </Select>
          </td>
          <td className="applicants-column">
            {item.primaryRole && jobFunc[item.primaryRole]
              ? jobFunc[item.primaryRole]?.title
              : "-"}
          </td>
          <td className="date-column">
            <div>{moment(item.date.seconds * 1000).format("DD/MM/yyyy")}</div>
            <Link to="/view-profile" state={{ uid: item.uid }}>
              <div className="view-application">View Profile</div>
            </Link>
          </td>
        </tr>
      );
    }
  };
  return (
    <div>
      <div
        className={`application-tracker-header ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        id="view-archives"
      >
        <div className="small-title">Archives</div>
        <div className="big-title">Previous Employees</div>
        <Box className="back-button" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" color="#7C7F93" />
          Back to Team
        </Box>
      </div>
      <Box
        className={`application-tracker-page ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        flex={1}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {/*sidebar*/}
        <div className="sidebar-container">
          <div className="sidebar">
            <div className="sidebar-title">Action</div>
            <div className="sidebar-section">
              <InputGroup
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                leftIcon="search"
                intent="none"
              />
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Type:</div>
              <ul>
                <li
                  className={selectedDepartment === "boh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "boh") {
                      setSelectedDepartment("boh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  BOH
                </li>
                <li
                  className={selectedDepartment === "foh" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "foh") {
                      setSelectedDepartment("foh");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  FOH
                </li>
                <li
                  className={selectedDepartment === "other" ? "active" : ""}
                  onClick={() => {
                    if (selectedDepartment !== "other") {
                      setSelectedDepartment("other");
                    } else {
                      setSelectedDepartment("");
                    }
                  }}
                >
                  Other
                </li>
              </ul>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Role:</div>
              <select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select a role</option>
                {rolesForSidebar.map((item, index) => (
                  <option value={item} key={index}>
                    {jobFunc ? jobFunc?.[item]?.["title"] : ""}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Location:</div>
              <select onChange={(e) => setSelectedLocation(e.target.value)}>
                <option value="">Select a Location</option>
                {usersLocations
                  ? usersLocations.map((item, index) => {
                      return (
                        <option value={item.id}>{item.businessName}</option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
        </div>
        <div className="jobs-container archive-table">
          <table>
            <tr>
              <th>Employee Info</th>
              <th>Status</th>
              <th>Primary Role</th>
              <th>Start Date</th>
            </tr>
            {filterData.length > 0
              ? filterData.map((item) => renderEmployeeRow(item))
              : staffData && staffData.length > 0
              ? staffData.map((item: any) => renderEmployeeRow(item))
              : null}
          </table>
        </div>
      </Box>
    </div>
  );
};
export default ViewArchivedEmployees;
