import { composeUsableTab } from "controllers/composableTypes";

import { FirebaseTabDoc } from "types/order";

import firebase from "../config/firebase";

const db = firebase.firestore();
const tabs = db.collection("Tabs");

export const fetchTabs = async (
  lowestDate: Date,
  highestDate: Date,
  businessId: string
): Promise<FirebaseTabDoc[]> => {
  const querySnapshot = tabs
    .where("businessId", "==", businessId)
    .where("createdAt", ">=", lowestDate)
    .where("createdAt", "<=", highestDate)
    .get();

  const results: FirebaseTabDoc[] = [];
  (await querySnapshot).forEach((doc) => {
    if (doc.exists) {
      results.push(composeUsableTab(doc.data()));
    }
  });

  return results;
};
