import { Classes, Icon } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import React, { useState } from "react";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import WeeklyAlphabeticScheduleReportDocument from "views/BusinessAdmin/Reporting/PdfReports/WeeklyAlphabeticScheduleReport";
import WeeklyRoleGroupedScheduleReportDocument from "views/BusinessAdmin/Reporting/PdfReports/WeeklyRoleGroupedScheduleReportDocument";

import {
  generateWeeklyAlphabeticScheduleReport,
  generateWeelyRoleGroupedReport,
} from "controllers/reporting";
import {
  clearDraftSchedule,
  clearDuplicateSchedule,
} from "controllers/schedule";

import Box from "components/Box";

import { RootState } from "model/store";

import "../style.css";

interface WeeklyScheduleMoreActionProps {
  scheduleForAWeekRange: TangoSchedule | null | undefined;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
}

export const WeeklyScheduleViewMoreAction = (
  props: WeeklyScheduleMoreActionProps
) => {
  const [isChoosePrintableOptionView, setIsChoosePrintableOptionView] =
    useState(false);
  const [clearShiftsWarningVisible, setClearShiftsWarningVisible] =
    useState(false);

  const { scheduleForAWeekRange, duplicatedScheduleForAWeekRange } = props;

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const clearWeeksSchedule = async () => {
    if (scheduleForAWeekRange) {
      if (scheduleForAWeekRange.mergedSchedules?.length) {
        await Promise.all(
          scheduleForAWeekRange.mergedSchedules.map((scheduleId) =>
            clearDraftSchedule(scheduleId, business.id)
          )
        );
      } else {
        await clearDraftSchedule(scheduleForAWeekRange.id, business.id);
      }
    } else if (duplicatedScheduleForAWeekRange) {
      await clearDuplicateSchedule(duplicatedScheduleForAWeekRange);
    }
  };

  const generateAlphabeticReport = async () => {
    const scheduleForReport =
      scheduleForAWeekRange || duplicatedScheduleForAWeekRange;
    if (!scheduleForReport) return null;
    const reportData = generateWeeklyAlphabeticScheduleReport(
      businessSettings,
      fellowStaffMembers,
      scheduleForReport,
      business
    );

    if (reportData) {
      const blob = await pdf(
        <WeeklyAlphabeticScheduleReportDocument
          report={reportData}
          businessName={business.businessName}
        />
      ).toBlob();
      // setReportIdLoading(null)
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } else {
      alert("No report data available");
    }
  };
  const generateDepartmentOrientedReport = async () => {
    const scheduleForReport =
      scheduleForAWeekRange || duplicatedScheduleForAWeekRange;
    if (!scheduleForReport) return null;
    const reportData = generateWeelyRoleGroupedReport(
      businessSettings,
      fellowStaffMembers,
      scheduleForReport,
      business
    );

    if (reportData) {
      const blob = await pdf(
        <WeeklyRoleGroupedScheduleReportDocument
          report={reportData}
          businessName={business.businessName}
        />
      ).toBlob();
      // setReportIdLoading(null)
      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } else {
      alert("No report data available");
    }
  };
  if (clearShiftsWarningVisible) {
    return (
      <Box className="more-action">
        <Box
          className="action-header"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Icon
            icon="chevron-left"
            onClick={() => setClearShiftsWarningVisible(false)}
          />
          Are you sure?
          <Box style={{ height: 10, width: 10 }} />
        </Box>
        <Box
          onClick={() => clearWeeksSchedule()}
          className="action-item"
          style={{ color: "#fe7777" }}
        >
          Clear Schedule
        </Box>
        <Box
          onClick={() => setClearShiftsWarningVisible(false)}
          className="action-item"
        >
          Cancel
        </Box>
      </Box>
    );
  }
  if (isChoosePrintableOptionView) {
    return (
      <Box className="more-action">
        <Box
          className="action-header"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Icon
            icon="chevron-left"
            onClick={() => setIsChoosePrintableOptionView(false)}
          />
          Choose printable option
          <Box style={{ height: 10, width: 10 }} />
        </Box>
        <Box onClick={() => generateAlphabeticReport()} className="action-item">
          All staff listed alphabetically
        </Box>
        <Box
          onClick={() => generateDepartmentOrientedReport()}
          className="action-item"
        >
          Split by role
        </Box>
      </Box>
    );
  }
  return (
    <Box className="more-action">
      <Box className="action-header">
        <Icon icon="delete" className={Classes.POPOVER_DISMISS} />
        Other Actions
      </Box>
      <Box
        className="action-item"
        onClick={() => setIsChoosePrintableOptionView(true)}
      >
        Download/Print Weekly Schedule
      </Box>

      <Box
        onClick={() => setClearShiftsWarningVisible(true)}
        className="action-item"
        style={{ color: "#979797" }}
      >
        Delete All Shifts
      </Box>
    </Box>
  );
};
