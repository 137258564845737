import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import MergeCalendarPopover from "./MergeCalendarPopover";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  showMergeCalendar: () => void;
  setShowMergePopOver: () => void;
  hideMergePopover: () => void;
  isMergePopoverOpen: boolean;
}

const DeleteCalendarModal = ({
  isOpen,
  onClose,
  showMergeCalendar,
  setShowMergePopOver,
  isMergePopoverOpen,
  hideMergePopover,
}: Props) => {
  return (
    <UIComponents.Modal isOpen={isOpen} closeModal={onClose}>
      <div className={"relative"}>
        <div className={"text-2xl font-lato-black text-black"}>
          Delete Calendar?
        </div>
        <div className={"text-sm font-lato-bold text-grey-2"}>
          Deleting this calendar will also delete all of its events.
        </div>
        <UIComponents.ModalButton
          label={"Delete"}
          type={"delete"}
          className={"mt-9"}
        />
        <UIComponents.ModalButton
          label={"Cancel"}
          type={"secondary"}
          className={"mt-9"}
          onClick={onClose}
        />
        <div className={"text-2xl font-lato-black text-black mt-14"}>
          Merge?
        </div>
        <div className={"text-sm font-lato-bold text-grey-2"}>
          Merge events with another calendar in the same account and delete this
          calendar.
        </div>
        <div className="relative">
          {isMergePopoverOpen ? (
            <div
              className={
                "bg-white w-fit py-4 px-6 shadow-general-shadow rounded-2xl absolute z-[100] top-4 right-[-32px]"
              }
            >
              <div
                className={"font-lato-extrabold text-tiny text-grey-2 mb-2.5"}
              >
                {" "}
                kishanj918@gmail.com
              </div>
              {["OPs Team", "Company Events"].map((workspace) => (
                <div
                  className={"flex items-center mb-2 cursor-pointer"}
                  onClick={showMergeCalendar}
                >
                  <UIComponents.Checkbox
                    type={"small"}
                    colorType={"green"}
                    checked
                    className={"mr-2"}
                  />
                  <div className={"font-lato-bold text-tiny text-black"}>
                    {workspace}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <UIComponents.ModalButton
            onClick={setShowMergePopOver}
            label={"Merge"}
            type={"secondary"}
            className={"mt-9 relative !bg-black !text-white"}
          />
        </div>
      </div>
    </UIComponents.Modal>
  );
};
export default DeleteCalendarModal;
