//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";

import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

export type WeeklyScheduleViewType = "shift_view" | "staff_member_view";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

interface WeeklyViewSelectProps {
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;
}

export const WeeklyViewSelect = ({
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
}: WeeklyViewSelectProps) => {
  return (
    <>
      <Box className="side-nav-item" display="flex" flexDirection="column">
        <div className="weekly-view-select-title">Views</div>

        <div
          className="animated-staff-controls-container"
          style={{
            justifyContent:
              weeklyScheduleViewType === "shift_view"
                ? "flex-start"
                : "flex-end",
          }}
        >
          <motion.div
            className="left"
            onClick={() => setWeeklyScheduleViewType("shift_view")}
            style={{
              color:
                weeklyScheduleViewType === "shift_view" ? "#FFFFFF" : "black",
            }}
          >
            Shift View
          </motion.div>
          <motion.div
            className="right"
            onClick={() => setWeeklyScheduleViewType("staff_member_view")}
            style={{
              color:
                weeklyScheduleViewType === "staff_member_view"
                  ? "#FFFFFF"
                  : "black",
            }}
          >
            Staff View
          </motion.div>
          <motion.div
            className="animated-weeekly-view-handle"
            layout
            transition={spring}
          />
        </div>

        <div
          className="animated-staff-controls-container"
          style={{
            justifyContent: !departmentScheduleViewType
              ? "flex-start"
              : departmentScheduleViewType === "foh"
              ? "center"
              : "flex-end",
            marginTop: 20,
          }}
        >
          <motion.div
            className="triple-left"
            onClick={() => setDepartmentScheduleViewType(null)}
            style={{
              color: !departmentScheduleViewType ? "#FFFFFF" : "black",
            }}
          >
            All
          </motion.div>
          <motion.div
            className="triple-center"
            onClick={() => setDepartmentScheduleViewType("foh")}
            style={{
              color: departmentScheduleViewType === "foh" ? "#FFFFFF" : "black",
            }}
          >
            FOH
          </motion.div>
          <motion.div
            className="triple-right"
            onClick={() => setDepartmentScheduleViewType("boh")}
            style={{
              color: departmentScheduleViewType === "boh" ? "#FFFFFF" : "black",
            }}
          >
            BOH
          </motion.div>
          <motion.div
            className="animated-weeekly-triple-view-handle"
            layout
            transition={spring}
          />
        </div>
      </Box>
    </>
  );
};
