import {
  Button,
  Icon,
  Intent,
  Position,
  Spinner,
  Toaster,
} from "@blueprintjs/core";
import firebase from "config/firebase";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { generateNewPincode, updateStaffExternalId } from "controllers/staff";

import Box from "components/Box";
import FullScreenSpinner from "components/Spinner";

import { getPositionNameById } from "model/selectors/businessSettings";
import { getAllJobFunctions } from "model/selectors/teamsTable";
import { RootState } from "model/store";

import logo from "assets/normallogo.svg";

import { getAllBusinessesByAccountId } from "../teamFirebaseCalls";
import "./index.css";
import FindLocationModal from "./modals/FindLocationModal";

const EMPLOYEE_PERMISSIONS = [
  "Company Admin",
  "Location Admin",
  "Basic Employee",
];

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

// const initialEmployeeDetails = ;
const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
const Profile = () => {
  const params = useParams<{ staffMemberId: string }>();
  const navigate = useNavigate();

  const staffMemberId = params.staffMemberId;

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );

  const staffMember = useMemo(() => {
    return fellowStaffMembers.find((sm) => sm.id === staffMemberId);
  }, [fellowStaffMembers, staffMemberId]);
  const availabilityForStaffMember = useMemo(() => {
    if (!staffMember) return null;
    console.log("staffMember", staffMember);
    return (
      fixedAvailabilities.find((fa) => fa.staffId === staffMember.id) ?? null
    );
  }, [staffMember, fixedAvailabilities]);
  const jobFunc = useSelector(getAllJobFunctions);

  const [employeeData, setEmployeeData]: any = useState({});

  const [localExternalId, setLocalExternalId] = useState("");

  const [pinCodeLoading, setPinCodeLoading] = useState(false);
  const [externalIdLoading, setExternalIdLoading] = useState(false);

  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [step, setStep] = useState<
    "overview" | "performance" | "documents" | "availability"
  >("overview");

  const [allBusinesses, setAllBusinesses] = useState<TangoBusiness[]>([]);
  const [locations, setLocations] = useState<TangoBusiness[]>([]);
  const [locationsName, setLocationsName]: any = useState([]);
  const [compensationExpanded, setComprensationExpanded] = useState(false);
  const setSelectedEmployeeLocation = () => {
    allBusinesses &&
      allBusinesses.map((item) => {
        if (employeeData.businessId == item.id) {
          if (locations.findIndex((i) => i.id == item.id) == -1) {
            setLocations([...locations, item]);
            setLocationsName([...locationsName, item.businessName]);
          }
        }
      });
  };

  // useEffect(() => {
  //   const locationstate = location.state;
  //   getEmployeeData(locationstate && locationstate?.uid);
  // }, [location]);

  useEffect(() => {
    if (employeeData && allBusinesses) {
      setSelectedEmployeeLocation();
    }
  }, [employeeData, allBusinesses]);

  useEffect(() => {
    const getAllLocationsOfAccount = async () => {
      const businesses = await getAllBusinessesByAccountId(business.accountId);
      setAllBusinesses(businesses);
    };
    if (business) getAllLocationsOfAccount();
  }, [business]);

  const updateStaffPincode = useCallback(async () => {
    try {
      if (business && staffMember) {
        setPinCodeLoading(true);
        await generateNewPincode(business.id, staffMember.id);
        setPinCodeLoading(false);
      }
    } catch (e: any) {
      setPinCodeLoading(false);
      toaster.show({
        message: e?.response?.data?.message ?? "Something went wrong...",
        intent: Intent.DANGER,
      });
    }
  }, [business, staffMember]);

  useEffect(() => {
    if (
      staffMember &&
      (staffMember?.externalEmployeeId ?? "") !== localExternalId
    ) {
      setLocalExternalId(staffMember?.externalEmployeeId ?? "");
    }
  }, [staffMember?.externalEmployeeId]);

  const updateStaffMemberExternalId = useCallback(async () => {
    try {
      if (business && staffMember) {
        setExternalIdLoading(true);
        await updateStaffExternalId(
          business.id,
          staffMember.id,
          localExternalId ?? ""
        );
        setExternalIdLoading(false);
      }
    } catch (e: any) {
      setExternalIdLoading(false);
      toaster.show({
        message: e?.response?.data?.message ?? "Something went wrong...",
        intent: Intent.DANGER,
      });
    }
  }, [business, staffMember, localExternalId]);

  if (!staffMember || !business) {
    return <FullScreenSpinner />;
  }

  const updateLocation = (
    updatedlocation: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      if (index !== -1) {
        let oldLocationsArray = [...locations];
        if (index === oldLocationsArray.length) {
          oldLocationsArray = [];
        } else {
          oldLocationsArray.splice(index, 1);
        }
        setLocations(oldLocationsArray);
      }
    } else setLocations([...locations, updatedlocation]);
  };

  const renderCompensation = (sm: StaffMember) => {
    if (compensationExpanded) {
      return (
        <Icon
          icon={"chevron-down"}
          color={"#424452"}
          size={18}
          onClick={() => setComprensationExpanded(!compensationExpanded)}
        />
      );
    }
    return (
      <span>
        $ {((sm.payRates?.[0]?.amount ?? 0) / 100).toFixed(2)}{" "}
        {sm.payRates.length - 1 > 0 ? (
          <span
            className={"edit-button"}
            onClick={() => setComprensationExpanded(!compensationExpanded)}
          >{`${sm.payRates.length - 1} more`}</span>
        ) : null}{" "}
      </span>
    );
  };

  const renderOverview = () => (
    <motion.div
      key="overview"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={spring}
    >
      <Box className="about-me">
        {/* <div className="about-me-title">About Me</div> */}
        <div className="about-me-description"></div>
      </Box>
      <Box className="employee-overview-list">
        <Box className="employee-overview-list-header">
          <div>Employee Overview</div>
          <Link
            to={`/edit-employee/${staffMember.id}`}
            style={{ cursor: "pointer" }}
            className="edit-button"
          >
            Edit
          </Link>
        </Box>
        <ul>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/primary-role.svg").default} />
              Primary Role
            </div>
            <div className="employee-overview-list-value">
              {getPositionNameById(
                businessSettings,
                staffMember?.primaryRole ?? ""
              )}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img
                src={require("assets/employee/secondary-role.svg").default}
              />
              Secondary Role
            </div>
            <div className="employee-overview-list-value">
              {staffMember.secondaryRoles
                .map((sr) => getPositionNameById(businessSettings, sr))
                .join(", ")}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/phone-number.svg").default} />
              Phone Number
            </div>
            <div className="employee-overview-list-value">
              {staffMember?.contact?.phone}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/email-icon.svg").default} />
              Email Addresss
            </div>
            <div className="employee-overview-list-value">
              {staffMember?.contact?.email}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/location-icon.svg").default} />
              Locations
            </div>
            <div className="employee-overview-list-value">
              {business.businessName}
              {/* {locationsName}{" "}
              {locationsName.length > 1 && (
                <div
                  className="locations-button"
                  onClick={() => setShowLocationModal(true)}
                >
                  +{locationsName.length - 1} more
                </div>
              )} */}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/permissions.svg").default} />
              Permissions
            </div>
            <div className="employee-overview-list-value">
              {staffMember.isAdmin ? "Location Admin" : "Basic Employee"}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">
              <img src={require("assets/employee/compensation.svg").default} />
              Compensation
            </div>
            <div className="employee-overview-list-value">
              {renderCompensation(staffMember)}
            </div>
          </li>
          {compensationExpanded && staffMember.payRates.length - 1 > 0 ? (
            <div style={{ paddingBottom: "15px" }}>
              {staffMember.payRates.map((payRate, index) => {
                return (
                  <div
                    style={{
                      paddingLeft: "41px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "16px",
                      marginBottom: "8px",
                      fontSize: "14px",
                      fontFamily: "Lato-Semibold",
                      color: "#7C7F93",
                    }}
                  >
                    <div>{payRate.role}</div>{" "}
                    <div>${(payRate.amount / 100).toFixed(2)}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
          <li>
            <div className="employee-overview-list-title">Pin Code</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="employee-overview-list-value"
                style={{ marginRight: 10 }}
              >
                {staffMember.pinCode || ""}
              </div>
              {pinCodeLoading ? (
                <Spinner size={20} />
              ) : (
                <motion.div
                  onClick={updateStaffPincode}
                  whileTap={{ opacity: 0.7, scale: 0.9 }}
                  className="edit-button"
                >
                  {staffMember.pinCode ? "Regenerate" : "Generate"}
                </motion.div>
              )}
            </div>
          </li>
          <li>
            <div className="employee-overview-list-title">External Id</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                className="employee-overview-list-value"
                style={{
                  marginRight: 10,
                  borderRadius: 15,
                  padding: 4,
                  maxWidth: 100,
                  border: "1px solid #e9e9e9",
                }}
                onChange={(e) => setLocalExternalId(e.target.value)}
                value={localExternalId}
              />

              {externalIdLoading ? (
                <Spinner size={20} />
              ) : (
                <motion.div
                  onClick={updateStaffMemberExternalId}
                  whileTap={{ opacity: 0.7, scale: 0.9 }}
                  className="edit-button"
                >
                  {staffMember.externalEmployeeId ? "Update" : "Add"}
                </motion.div>
              )}
            </div>
          </li>
        </ul>
      </Box>
    </motion.div>
  );

  const renderDocuments = () => (
    <>
      <Box className="about-me">
        <div className="about-me-title">Documents</div>
        <div className="about-me-description">
          Find documents associated with this employee
        </div>
      </Box>
      <Box className="search-documents-container">
        <div className="input-container">
          <Icon icon="search" color="#424452" iconSize={14} />
          <input placeholder="Search Documents" />
        </div>
        <Button
          className="upload-document-button"
          icon={<img src={require("assets/file_upload_black.svg").default} />}
          text="Upload File"
        />
      </Box>
      <Box className="employee-overview-list documents-list">
        <Box className="employee-overview-list-header">
          <div>Documents</div>
          <div>Statuses</div>
        </Box>
        <ul>
          {[1, 2, 3, 4].map(() => (
            <li>
              <div className="employee-overview-list-title">
                <img src={require("assets/DocumentIcon.svg").default} />
                Offer Letter.PDF
              </div>
              <Box display="flex" alignItems="center">
                <div className="employee-overview-list-value">Completed</div>
                <Icon icon="dot" color="#5BEF43" />
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
  const renderAvailability = () => {
    return (
      <motion.div
        key="availabilities"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={spring}
      >
        {/* <Box className="about-me">
          <div className="about-me-title">Weekly Availability </div>
          <div className="about-me-description">See when they’re avaialble</div>
        </Box> */}
        <Box className="employee-overview-list documents-list">
          <Box className="employee-overview-list-header">
            <div className="header-title">Availability</div>
          </Box>
          <ul>
            {moment.weekdays().map((weekDay) => {
              const availabilityForADay =
                availabilityForStaffMember?.schedule.find(
                  (ds) => ds.day === weekDay
                );
              const availabilityString = availabilityForADay?.available
                ? `${moment(availabilityForADay.startTime, "HH:mm").format(
                    "hh:mm a"
                  )} - ${moment(availabilityForADay.endTime, "HH:mm").format(
                    "hh:mm a"
                  )}`
                : "-";
              return (
                <li key={weekDay}>
                  <div className="employee-overview-list-title">{weekDay}</div>
                  <Box display="flex" alignItems="center">
                    <div className="employee-overview-list-value">
                      {availabilityString}
                    </div>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Box>
      </motion.div>
    );
  };

  const firstName =
    staffMember?.contact?.firstName.charAt(0).toUpperCase() +
    staffMember?.contact?.firstName.slice(1);
  const lastName =
    staffMember?.contact?.lastName.charAt(0).toUpperCase() +
    staffMember?.contact?.lastName.slice(1);
  const roles = [staffMember.primaryRole, ...(staffMember.secondaryRoles || [])]
    .map((item: any) => (jobFunc[item] ? jobFunc[item].title : ""))
    .filter((item) => item.length > 0)
    .join(", ");

  return (
    <div id="add-employee-page" className="profile-page">
      <Box className="container">
        <Box className="header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <Icon icon="arrow-left" size={11} color={"#7C7F93"} />
            <span className="btn-text">Back to Team</span>
          </button>
        </Box>
        <Box className="bodycontent">
          {/*sidebar*/}
          <Box className="sidetab">
            <span className="checklist-header">Employee</span>
            <span className="checklist-action">Manage your employee</span>
            <div className="checklistdiv">
              <ul className="checklist">
                <li
                  className={`${step === "overview" ? "active" : ""}`}
                  onClick={() => setStep("overview")}
                >
                  <img src={require("assets/overview-icon.svg").default} />
                  <span className="navtext">Overview</span>
                </li>
                {/* <li
                  className={`${step === "performance" ? "active" : ""}`}
                  onClick={() => setStep("performance")}
                >
                  <img src={require("assets/performance-icon.svg").default} />
                  <span className="navtext">Performance</span>
                </li>
                <li
                  className={`${step === "documents" ? "active" : ""}`}
                  onClick={() => setStep("documents")}
                >
                  <img src={require("assets/files.svg").default} />
                  <span className="navtext">Documents</span>
                </li> */}
                <li
                  className={`${step === "availability" ? "active" : ""}`}
                  onClick={() => setStep("availability")}
                >
                  <img src={require("assets/clock-icon.svg").default} />
                  <span className="navtext">Availability</span>
                </li>
              </ul>
            </div>
          </Box>
          {/*tabcontent*/}
          <Box className="tabcontent">
            <img src={logo} className="logo" />
            <Box>
              <div className="form-title">Profile</div>
              <div className="form-subtitle">Employee</div>
            </Box>
            <Box display="flex" className="profile-header">
              <Box display="flex" alignItems="center">
                {employeeData?.imageUrl ? (
                  <img className="user-avatar" src={employeeData?.imageUrl} />
                ) : (
                  <div className="user-avatar-name">
                    {staffMember?.contact?.firstName?.charAt(0)?.toUpperCase()}
                    {staffMember?.contact?.lastName?.charAt(0)?.toUpperCase()}
                  </div>
                )}
                <Box>
                  <div className="employee-name">
                    {firstName} {lastName}
                  </div>
                  <div className="employee-role">{roles}</div>
                </Box>
              </Box>
            </Box>
            {step === "overview" && renderOverview()}
            {step === "documents" && renderDocuments()}
            {step === "availability" && renderAvailability()}
          </Box>
        </Box>
      </Box>
      <FindLocationModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        businesses={allBusinesses}
        businessesId={employeeData.businessId}
        updateLocation={updateLocation}
        selectedLocations={locations}
      />
    </div>
  );
};

export default Profile;
