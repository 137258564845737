import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "components/Table/Table";
import { OptionType, TableError } from "components/Table/tableTypes";

import {
  findStaffMemberUsingId,
  performSoftDelete,
  saveData,
} from "models/cashDrawers";

import { CashDrawerDoc, FirebaseCashDrawerDoc } from "types/cashDrawers";
import { FirebasePrintersDoc } from "types/printers";

import { RootState } from "model/store";

const CashDrawers = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const cashDrawers = useSelector((state: RootState) => state.cashDrawers);
  const otherStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const currentStaffMembers: StaffMember = useSelector(
    (state: RootState) => state.user
  );
  const printers: FirebasePrintersDoc[] = useSelector(
    (state: RootState) => state.printers
  );
  const [staffNamesRef, setStaffNamesRef] = useState<string[]>([]);
  const [data, setData] = useState<CashDrawerDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<CashDrawerDoc[]>([]);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "cashDrawerName",
    errors: [],
  });

  const columnHeadingConversions = {
    cashDrawerName: "Cash Drawer Name",
    drawerOwners: "Drawer Owners",
    otherAuthorizedStaff: "Other Authorized Staff",
    enabled: "Enabled",
    closeType: "Close Type",
    printer: "Printer",
  };

  const tableSchema = [
    { key: "cashDrawerName", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "drawerOwners", type: "arrayOfValue", data: "text" },
    { key: "otherAuthorizedStaff", type: "arrayOfValue", data: "text" },
    { key: "closeType", type: "value", data: "text" },
    { key: "printer", type: "value", data: "text" },
  ];

  const dataSchema = [
    ...tableSchema,
    { key: "id", type: "value", data: "text" },
  ];

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "drawerOwners",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options:
          currentStaffMembers && otherStaffMembers
            ? [currentStaffMembers, ...otherStaffMembers]
                .map(
                  (staff) =>
                    staff.contact.firstName + " " + staff.contact.lastName
                )
                .map((type) => ({ optionName: type, color: null }))
            : [],
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "otherAuthorizedStaff",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options:
          currentStaffMembers && otherStaffMembers
            ? [currentStaffMembers, ...otherStaffMembers]
                .map(
                  (staff) =>
                    staff.contact.firstName + " " + staff.contact.lastName
                )
                .map((type) => ({ optionName: type, color: null }))
            : [],
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "closeType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Blind Drop", "Non-Blind Drop"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "printer",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: printers.map((printer) => ({
          optionName: printer.name,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const filterData = (data: CashDrawerDoc[]) =>
    data.map((row) => ({
      cashDrawerName: row.cashDrawerName,
      drawerOwners: row.drawerOwners,
      otherAuthorizedStaff: row.otherAuthorizedStaff,
      enabled: row.enabled,
      closeType: row.closeType,
      printer: row.printer,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      cashDrawerName: row.cashDrawerName,
      index,
    }));

    // Filter for duplicates
    const productDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({ cashDrawerName: row.cashDrawerName })),
          { cashDrawerName: item.cashDrawerName }
        ) !== index
    );

    // Find the duplicate without the id
    const errors: DocumentData[] = [];
    for (let i = 0; i < productDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) => row.cashDrawerName === productDuplicateErrors[i].cashDrawerName
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !(row.enabled === "Yes" || row.enabled === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong close type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !(
              row.closeType === "Blind Drop" ||
              row.closeType === "Non-Blind Drop"
            )
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "closeType", isEdited: false }],
          isEdited: false,
        })),
      // Wrong drawer owners
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => _.difference(row.drawerOwners, staffNamesRef).length > 0
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "drawerOwners", isEdited: false }],
          isEdited: false,
        })),
      // Wrong other authorized stuff
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            _.difference(row.otherAuthorizedStaff, staffNamesRef).length > 0
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [
            { columnName: "otherAuthorizedStaff", isEdited: false },
          ],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No cash drawer name
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.cashDrawerName || row.cashDrawerName.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "cashDrawerName", isEdited: false }],
          isEdited: false,
        })),
      // No drawer owners
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => row.drawerOwners.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "drawerOwners", isEdited: false }],
          isEdited: false,
        })),
      // No other authorized staff
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => row.otherAuthorizedStaff.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [
            { columnName: "otherAuthorizedStaff", isEdited: false },
          ],
          isEdited: false,
        })),
      // No enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.enabled || row.enabled.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // No close type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.closeType || row.closeType.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "closeType", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const saveToFirebase = async () => {
    if (business.id) {
      const staffMembers = [currentStaffMembers, ...otherStaffMembers];
      await performSoftDelete(softDeleteData);
      await saveData(
        data,
        transformFirebaseCashDrawerToCashDrawer(cashDrawers, staffMembers),
        staffMembers,
        printers,
        business.id
      );
      return true;
    }
  };

  const transformFirebaseCashDrawerToCashDrawer = (
    cashDrawers: FirebaseCashDrawerDoc[],
    staffMembers: StaffMember[]
  ): CashDrawerDoc[] => {
    return cashDrawers.map((doc) => {
      const primaryStaff = doc.primaryStaff
        .map((id) => findStaffMemberUsingId(id, staffMembers))
        .filter((i) => !!i);
      const otherStaffWithAccess = doc.otherStaffWithAccess
        .map((id) => findStaffMemberUsingId(id, staffMembers))
        .filter((i) => !!i);
      return {
        id: doc.id,
        cashDrawerName: doc.cashDrawerName,
        drawerOwners: primaryStaff.map(
          (staff) => staff?.contact.firstName + " " + staff?.contact.lastName
        ),
        otherAuthorizedStaff: otherStaffWithAccess.map(
          (staff) => staff?.contact.firstName + " " + staff?.contact.lastName
        ),
        enabled: doc.enabled ? "Yes" : "No",
        closeType: doc.closeType,
        printer:
          printers.filter((printer) => printer.id === doc.printerId).length > 0
            ? printers.filter((printer) => printer.id === doc.printerId)[0].name
            : "",
      };
    });
  };

  useEffect(() => {
    if (
      cashDrawers.length > 0 &&
      business &&
      currentStaffMembers &&
      otherStaffMembers
    ) {
      const staffMembers = [currentStaffMembers, ...otherStaffMembers];
      const allDrawers = transformFirebaseCashDrawerToCashDrawer(
        cashDrawers,
        staffMembers
      );
      setStaffNamesRef(
        staffMembers.map(
          (staff) => staff.contact.firstName + " " + staff.contact.lastName
        )
      );
      setData(allDrawers);
    }
  }, [cashDrawers, business]);

  if (business) {
    return (
      <div id="table-display">
        <Table
          // @ts-ignore
          businessId={business.id}
          name="CashDrawers"
          parentName="Devices"
          allData={data}
          setAllData={setData}
          softDelete={softDeleteData}
          setSoftDelete={setSoftDeleteData}
          allowSoftDelete
          filterData={filterData}
          tableSchema={tableSchema}
          dataSchema={dataSchema}
          options={options}
          setOptions={setOptions}
          hasModal={false}
          ModalComponent={null}
          errorManager={errorManager}
          setErrorManager={setErrorManager}
          closeErrorManager={() => {
            setErrorManager({
              ...errorManager,
              showErrorModal: false,
            });
          }}
          verifySave={verifyData}
          saveToFirebase={saveToFirebase}
          hasSearch={true}
          searchColumnName="cashDrawerName"
          hasFilter={false}
          columnHeadingConversions={columnHeadingConversions}
          showSearchName={false}
        />
      </div>
    );
  }
  return null;
};

export default CashDrawers;
