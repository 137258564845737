import _ from "lodash";

import firebase from "../config/firebase";
import { FirebaseMenuHoursDoc, MenuHoursDoc } from "../types/menus";

const db = firebase.firestore();
const menusCollection = db.collection("Menus");

const fixTime = (time: string) => {
  if (time.includes(":")) {
    const hoursString = time.split(":")[0];
    const minutesString = time.split(":")[1];

    if (!/^\d+$/.test(hoursString)) return "";
    if (!/^\d+$/.test(minutesString)) return "";

    const hours = parseInt(hoursString);
    const minutes = parseInt(minutesString);

    if (isNaN(minutes)) return "";
    if (minutes < 0 || minutes > 59) return "";

    if (isNaN(hours)) return "";
    if (hours < 0 || hours > 24) return "";

    return (
      hours + ":" + (String(minutes).length === 1 ? "0" + minutes : minutes)
    );
  }
  return "";
};

export const saveData = async (data: MenuHoursDoc[], menuId: string) => {
  const generateFirebaseMenuHoursFromMenuHours = (
    doc: MenuHoursDoc,
    menuHours: FirebaseMenuHoursDoc[]
  ) => {
    const newMenuHours = menuHours;
    Object.keys(doc).forEach((key) => {
      if (key !== "hourType") {
        const index = newMenuHours.findIndex((hourDoc) => hourDoc.day === key);
        if (index !== -1) {
          // @ts-ignore
          if (String(doc[key]).trim() === "closed") {
            newMenuHours[index] = {
              ...newMenuHours[index],
              // @ts-ignore
              [_.camelCase(doc.hourType)]: "closed",
              open: false,
            };
          } else {
            newMenuHours[index] = {
              ...newMenuHours[index],
              // @ts-ignore
              [_.camelCase(doc.hourType)]: fixTime(String(doc[key]).trim()),
            };
          }
        }
      }
    });
    return newMenuHours;
  };

  let hours: FirebaseMenuHoursDoc[] = [];
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  for (let i = 0; i < data.length; i += 2) {
    let menuHours: FirebaseMenuHoursDoc[] = days.map((day) => {
      return {
        openTime: "",
        closeTime: "",
        open: true,
        day: day,
      };
    });
    menuHours = generateFirebaseMenuHoursFromMenuHours(data[i], menuHours);
    menuHours = generateFirebaseMenuHoursFromMenuHours(data[i + 1], menuHours);

    // If the openType / closeType is an empty string
    // then it won't get saved to firebase
    hours = [
      ...hours,
      ...menuHours.filter((hour) => hour.openTime && hour.closeTime),
    ];
  }
  console.log("HOURS: ", hours);
  console.log("MENU ID: ", menuId);

  // Update the menu hours based on the result
  await menusCollection
    .doc(menuId)
    .update({ hours: hours, updatedAt: new Date() });
};
