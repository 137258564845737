import { createSelector } from "reselect";

import { getDuplicatedOrders } from "models/orders";

import { FirebaseOrderDoc, FirebaseTabDoc } from "types/order";

import { RootState } from "model/store";

const orders = (state: RootState) =>
  state.orders.filter(
    (order) => order.completed && !order.cancelled && !order.deleted
  );
const tabs = (state: RootState) =>
  state.tabs.filter(
    (tab) => tab.paymentComplete && !tab.deleted
  ) as FirebaseTabDoc[];

export const getOrdersAndTabsForReporting = createSelector(
  orders,
  tabs,
  (rawOrders: FirebaseOrderDoc[], rawTabs: FirebaseTabDoc[]) => {
    // Remove duplciated orders via the charge id
    const { tabIdsToRemove, orderIdsToRemove } = getDuplicatedOrders(
      rawOrders,
      rawTabs
    );

    const filteredOrders = rawOrders.filter(
      (order) => !orderIdsToRemove.includes(order.id)
    );
    const filteredTabs = rawTabs.filter(
      (tab) => !tabIdsToRemove.includes(tab.id)
    );

    return { orders: filteredOrders, tabs: filteredTabs };
  }
);
