import _ from "lodash";

import {
  roundNumberToTwoDecimals,
  sanitizeNumericField,
} from "controllers/reporting";

import { convertUTCDateToLocalDate } from "utils/dateUtils";

import {
  FirebaseOrderDoc,
  FirebaseOrderProduct,
  FirebaseTabDoc,
  TabAndOrder,
  TabAndOrderForRust,
} from "types/order";
import {
  CashDrawer,
  CashEvents,
  IHours,
  TangoOrder,
  TangoProductOrdered,
  TangoTax,
} from "types/reporting";

import { fetchOrders, getDuplicatedOrders } from "./orders";
import { fetchTabs } from "./tabs";

export const getProductFromOrder = (
  rawProducts: DocumentData[]
): TangoProductOrdered[] => {
  const results: TangoProductOrdered[] = [];
  for (const rawProduct of rawProducts) {
    if (rawProduct.productId) {
      results.push({
        productId: rawProduct.productId,
        name: rawProduct.name,
        quantity: rawProduct.quantity,
        price: rawProduct.price,
        taxRate: rawProduct.taxRate ? rawProduct.taxRate : 0,
        categoryType: rawProduct.type,
        modifiers: rawProduct.selectedModifiers
          ? rawProduct.selectedModifiers.map(
              (modifier: DocumentData) => modifier.modifierName
            )
          : [],
        modifierPrices: rawProduct.selectedModifiers
          ? rawProduct.selectedModifiers.map(
              (modifier: DocumentData) => modifier.additionalCost
            )
          : [],
      });
    }
  }

  return results;
};

export const leftJoin = (
  rawProducts: DocumentData[],
  products: DocumentData[]
): DocumentData[] => {
  const merged = _.merge(
    _.keyBy(rawProducts, "productId"),
    _.keyBy(products, "id")
  );
  const values = _.values(merged);
  return values;
};

const resolveCreatedAtDate = (
  date: Date,
  hoursOfSalePastMidnight: IHours[]
): Date => {
  const day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][date.getDay()];

  // Check if any hours of operation are past midnight
  if (
    hoursOfSalePastMidnight.length > 0 &&
    hoursOfSalePastMidnight.map((data) => data.day).includes(day)
  ) {
    const saleHour = date.getHours();
    const saleMinute = date.getMinutes();
    const closingTime = hoursOfSalePastMidnight
      .filter((data) => data.day === day)
      .map((data) => data.closeTime)[0];
    const closingHour = Number.parseInt(closingTime.split(":")[0]);
    const closingMinute = Number.parseInt(closingTime.split(":")[1]);

    // Minute doesn't matter if the time is more than an hour before
    if (saleHour >= 0 && saleHour < closingHour) {
      date.setDate(date.getDate() - 1);
      return date;
    }

    // If the sale happened in the closing hour check the minutes
    if (
      saleHour === closingHour &&
      saleMinute >= 0 &&
      saleMinute <= closingMinute
    ) {
      date.setDate(date.getDate() - 1);
      return date;
    }
  }

  return date;
};

const composeUsableCashDrawer = (rawCashDrawer: DocumentData): CashDrawer => ({
  id: rawCashDrawer?.id,
  businessId: rawCashDrawer?.businessId,
  cashDrawerName: rawCashDrawer?.cashDrawerName,
  closeType: rawCashDrawer?.closeType,
  deleted: rawCashDrawer?.deleted,
  enabled: rawCashDrawer?.enabled,
  createdAt: rawCashDrawer?.createdAt,
  updatedAt: rawCashDrawer?.updatedAt,
  otherStaffWithAccess: rawCashDrawer?.otherStaffWithAccess,
  primaryStaff: rawCashDrawer?.primaryStaff,
  startingCash: rawCashDrawer?.startingCash,
});

const composeUsableCashEvent = (rawCashEvent: DocumentData): CashEvents => ({
  id: rawCashEvent?.id,
  businessId: rawCashEvent?.businessId,
  cashDrawerId: rawCashEvent?.cashDrawerId,
  enabled: rawCashEvent?.enabled,
  deleted: rawCashEvent?.deleted,
  createdAt: rawCashEvent?.createdAt,
  updatedAt: rawCashEvent?.updatedAt,
  event: rawCashEvent?.event,
  description: rawCashEvent?.description,
  payload: rawCashEvent?.payload,
});

export const composeUsableOrder = (
  rawOrder: DocumentData,
  products: DocumentData[],
  hoursOfSalePastMidnight: IHours[],
  allPricingModels: TangoTax[]
): TangoOrder => {
  const pricing = allPricingModels.filter(
    (price) =>
      rawOrder.orderChannel === price.orderChannel &&
      rawOrder.orderType === price.orderType
  );
  return {
    deliveryFee: rawOrder?.amount?.deliveryFee,
    subtotal: rawOrder?.amount?.subTotal,
    tax: rawOrder?.amount?.tax,
    tip: rawOrder?.amount?.tip,
    total: rawOrder?.amount?.grossTotal,
    businessId: rawOrder.businessId,
    id: rawOrder.id,
    products: getProductFromOrder(leftJoin(rawOrder.products, products)),
    cancelled: rawOrder.cancelled,
    completed: rawOrder.completed,
    orderType: rawOrder.orderType,
    staffId: rawOrder.staffId || "",
    status: rawOrder.status,
    createdAt: resolveCreatedAtDate(
      rawOrder.createdAt.toDate(),
      hoursOfSalePastMidnight
    ),
    day: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][rawOrder.createdAt.toDate().getDay()],
    taxRate: pricing.length > 0 ? pricing[0].taxRate : 0,
    tangoFeeCents: pricing.length > 0 ? pricing[0].tangoFeeCents : 0,
    tangoFeePercent: pricing.length > 0 ? pricing[0].tangoFeePercent : 0,
  };
};

type ReportingCustomer = {
  paymentType: string;
  chargeId: string;
  amount: number;
};

interface Sale {
  hour: number;
  amount: number;
  count: number;
}

export const formatSalesFromRust = (sales: number[]): Sale[] => {
  const all_sales: Sale[] = [];
  for (let i = 0; i < sales.length; i += 3) {
    const hour = sales[i];
    const amount = sales[i + 1];
    const count = sales[i + 2];

    // Change UTC date to local date
    const date = new Date();
    date.setHours(hour, 0, 0);
    const localDate = convertUTCDateToLocalDate(date);

    all_sales.push({
      hour: localDate.getHours(),
      amount: roundNumberToTwoDecimals(sanitizeNumericField(amount)),
      count: roundNumberToTwoDecimals(sanitizeNumericField(count)),
    });
  }
  return all_sales;
};

// This is a function that will join multiple orders that share the
// same tab so we can use order.amount.netTotal + order.amount.tip
// to compare against the sum of tab.customer.payment.successfulCharge
export const joinOrdersWithMultipleTabs = (
  orders: FirebaseOrderDoc[],
  tabs: FirebaseTabDoc[]
) => {
  return tabs.reduce((acc, tab) => {
    const ordersToJoin = orders.filter((order) => order.tabId === tab.id);

    // This shouldn't happen - it means no orders for
    // a tab because the order was not completed or deleted or cancelled
    if (ordersToJoin.length === 0) {
      return acc;
    }

    // A single order to single tab
    if (ordersToJoin.length === 1) {
      return [...acc, ordersToJoin[0]];
    }

    const finalOrder = {
      ...ordersToJoin[0],
      products: ordersToJoin.reduce(
        (products, order) => [...products, ...order.products],
        [] as FirebaseOrderProduct[]
      ),
      amount: {
        subTotal: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.subTotal,
          0
        ),
        tax: ordersToJoin.reduce((acc, val) => acc + val.amount.tax, 0),
        deliveryFee: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.deliveryFee,
          0
        ),
        serviceChargeTotal: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.serviceChargeTotal,
          0
        ),
        discountTotal: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.discountTotal,
          0
        ),
        tip: ordersToJoin.reduce((acc, val) => acc + val.amount.tip, 0),
        grossTotal: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.grossTotal,
          0
        ),
        netTotal: ordersToJoin.reduce(
          (acc, val) => acc + val.amount.netTotal,
          0
        ),
        currency: ordersToJoin[0].amount.currency,
      },
    };

    return [...acc, finalOrder];
  }, [] as FirebaseOrderDoc[]);
};

export const composeUsableTabAndOrder = (
  rawOrders: FirebaseOrderDoc[],
  rawTabs: FirebaseTabDoc[],
  productSubTypeRefs: { [T: string]: string[] }
): TabAndOrderForRust[] => {
  // Remove duplciated orders via the charge id
  const { tabIdsToRemove, orderIdsToRemove } = getDuplicatedOrders(
    rawOrders,
    rawTabs
  );

  const orders = rawOrders.filter(
    (order) => !orderIdsToRemove.includes(order.id)
  );
  const tabs = rawTabs.filter((tab) => !tabIdsToRemove.includes(tab.id));

  // TODO: Check for miscalculated prices of items

  // // Charge id to remove duplicates
  // const chargeIds : string[] = []

  // Use only tabs that haven't been deleted, have been paid for and their status is false (or closed)
  const closedTabs = tabs.filter((tab) => !tab.deleted && tab.paymentComplete);

  return joinOrdersWithMultipleTabs(orders, tabs)
    .reduce((tabs_and_orders: TabAndOrder[], order: FirebaseOrderDoc) => {
      const index = closedTabs.findIndex((tab) => tab.id === order.tabId);
      if (index !== -1) {
        return [
          ...tabs_and_orders,
          {
            ...closedTabs[index],
            // Concat order after to avoid replacing 'id' from tab to order
            ...order,
            refundedAmount: closedTabs[index].status
              ? closedTabs[index].status.refundedAmount || 0
              : 0,
          },
        ];
      }
      return tabs_and_orders;
    }, [])
    .map((order) => {
      return {
        id: order.id || "",
        businessId: order.businessId || "",
        tabId: order.tabId ? order.tabId : "",
        updatedAt: order.updatedAt ? order.updatedAt : new Date(),
        cancelled: !!order.cancelled,
        completed: !!order.completed,
        createdAt: order.createdAt ? order.createdAt : new Date(),
        deleted: !!order.deleted,
        staffId: order.staffId ? order.staffId : "", // TODO: Do we need the staff member name?
        renderOrderInQueue: !!order.renderOrderInQueue,
        products: order.products.map((product) => {
          const subType = productSubTypeRefs[product.productId];
          return {
            productId: product.productId,
            price: Math.round(product.price),
            quantity: Math.round(product.quantity),
            name: product.name,
            menuName: product.menuName,
            menuCategory: product.menuCategory,
            productType: product.type,
            productSubType: subType ? subType : ([] as string[]), // TODO: Add product sub types
            taxRate: product.taxRate || -1,
            discountedPrice: product.discountedPrice
              ? Math.round(product.discountedPrice)
              : -1,
            alcohol: product.alcohol,
            selectedModifiers: product.selectedModifiers.map((modifier) => {
              const additionalCost = modifier.additionalCost;
              return {
                modifierId: modifier.modifierId || "",
                additionalCost:
                  typeof additionalCost === "string"
                    ? parseInt(additionalCost)
                    : Math.round(additionalCost),
                modifierName: modifier.modifierName || "",
                optionNames: modifier.optionNames
                  ? modifier.optionNames.filter((option) => !!option)
                  : [],
                options: modifier.options
                  ? modifier.options.map((option) => ({
                      name: option.name,
                      id: option.id,
                      additionalCost: Math.round(option.additionalCost),
                    }))
                  : [],
              };
            }),
          };
        }),
        drawerId: order.drawerId || "None",
        // These should all be integers
        amount: {
          currency: order.amount.currency || "USD",
          deliveryFee: Math.round(order.amount.deliveryFee) || 0,
          discountTotal: Math.round(order.amount.discountTotal) || 0,
          grossTotal: Math.round(order.amount.grossTotal) || 0,
          netTotal: Math.round(order.amount.netTotal) || 0,
          serviceChargeTotal: Math.round(order.amount.serviceChargeTotal) || 0,
          subTotal: Math.round(order.amount.subTotal) || 0,
          tax: Math.round(order.amount.tax) || 0,
          tip: Math.round(order.amount.tip) || 0,
        },
        refundedAmount: order.refundedAmount || 0,
        tableId: order.tableId || "None",
        tableNumber: order.tableNumber ? String(order.tableNumber) : "None",
        orderType: order.orderType,
        customer:
          order.customer && order.customer.length > 0
            ? order.customer.reduce(
                (paymentInfo: ReportingCustomer[], customer) => {
                  if (
                    customer &&
                    customer.payment &&
                    // customer.payment.type &&
                    // customer.payment.paymentType &&
                    customer.payment.successfulCharge &&
                    customer.payment.successfulCharge.amount
                  ) {
                    const amount = customer.payment.successfulCharge.amount;
                    return [
                      ...paymentInfo,
                      {
                        paymentType: customer.payment.type || "stripe",
                        chargeId: customer.payment.paymentType
                          ? customer.payment.paymentType.chargeId || ""
                          : "",
                        amount:
                          typeof amount === "string"
                            ? Math.round(parseFloat(amount))
                            : Math.round(amount),
                      },
                    ];
                  }
                  return [...paymentInfo];
                },
                []
              )
            : ([] as ReportingCustomer[]),
        paymentComplete: !!order.paymentComplete,
        isDeliverect: !!order.deliverect,
      };
      // Drop duplicate charge ids
    });
  // .reduce((tabAndOrders: TabAndOrderForRust[], tabAndOrder: TabAndOrderForRust) => {
  //   if (tabAndOrder.customer.length > 0) {
  //     const chargeId = tabAndOrder.customer[0].chargeId

  //     // Use only the chargeIds that we've not seen
  //     if (chargeId && !chargeIds.includes(chargeId)) {
  //       // Add to the seen chargeId
  //       chargeIds.push(chargeId);
  //       return [...tabAndOrders, tabAndOrder]

  //     // Cash orders won't have a chargeId
  //     } else if (chargeId === "") {
  //       return [...tabAndOrders, tabAndOrder]
  //     }
  //     return tabAndOrders
  //   }
  //   return tabAndOrders
  // }, [])
};

export const fetchOlderTabAndOrders = async (
  lower: Date,
  upper: Date,
  products: FirebaseProductDoc[],
  existingOrders: TabAndOrderForRust[],
  businessId: string
) => {
  const orders = await fetchOrders(lower, upper, businessId);
  const tabs = await fetchTabs(lower, upper, businessId);
  const productsSubTypeRef = products.reduce(
    (acc, val) => ({ ...acc, [val.id]: val.subType }),
    {}
  );
  const ordersForReporting = composeUsableTabAndOrder(
    orders,
    tabs,
    productsSubTypeRef
  );
  const result = _.uniqBy([...existingOrders, ...ordersForReporting], "id");

  return result;
};
