import { FirebaseCashDrawerDoc } from "types/cashDrawers";

import { cashDrawersAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseCashDrawerDoc[] = [];

export default function cashDrawers(
  state = initialState,
  action: cashDrawersAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_CASH_DRAWERS:
      return action.cashDrawers;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
