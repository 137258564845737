import { FixedSchedulesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: TangoSchedule[] = [];

export default function fixedSchedules(
  state = initialState,
  action: FixedSchedulesAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_FIXED_SCHEDULES:
      return action.fixedSchedules;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
