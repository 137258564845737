import { HuddleAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function (state = initialState, action: HuddleAction) {
  switch (action.type) {
    case ActionType.RECEIVE_HUDDLES:
      return {
        // @ts-ignore
        ...state,
        [action.channelKey!]: action.huddles,
      };
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
