import { ApexOptions } from "apexcharts";
import React, { useMemo } from "react";
import Chart from "react-apexcharts";

import "./style.css";

type Props = {
  sales: number[];
  laborCost: number[];
};
const Graph = ({ sales, laborCost }: Props) => {
  const options = useMemo(
    (): ApexOptions => ({
      chart: {
        id: "sales-vs-lc",
        toolbar: {
          show: false,
        },
      },
      fill: {
        // from colors (bottom of bar)
        colors: ["#EB4083", "#F8D17F"],
        gradient: {
          type: "vertical",
          gradientToColors: ["#7F3E84", "#FF9B72"],
        },
        type: "gradient",
      },
      title: {
        text: "Sales vs Labor Cost",
        style: {
          fontFamily: "Lato-black",
          fontSize: "20px",
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
        },
      },
      xaxis: {
        min: 0,
        max: 6,
        tickAmount: 6,
        type: "numeric",
        labels: {
          show: true,
          formatter: (value) => {
            return ["M", "T", "W", "T", "F", "S", "S"][parseInt(value)];
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      yaxis: {
        show: true,
        showAlways: false,
      },
      legend: {
        show: true,
        position: "bottom",
        floating: false,
        horizontalAlign: "right",
        markers: {
          fillColors: ["#7F3E84", "#FF9B72"],
        },
      },
      dataLabels: {
        enabled: false,
      },
    }),
    []
  );
  const series = useMemo(
    () => [
      {
        name: "Sales",
        data: sales.map((sale, idx) => ({
          x: idx,
          y: Math.floor(sale / 100),
        })),
      },
      {
        name: "Labor Cost",
        data: laborCost.map((cost, idx) => ({
          x: idx,
          y: Math.floor(cost / 100),
        })),
      },
    ],
    [sales, laborCost]
  );
  return (
    <div className="svlc-chart-card">
      <Chart
        dataLabels={options.dataLabels}
        options={options}
        series={series}
        type="bar"
      />
    </div>
  );
};

export default Graph;
