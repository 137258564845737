import { LocationsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: TangoBusiness[] = [];

export default function locations(
  state = initialState,
  action: LocationsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_AVAILABLE_LOCATIONS:
      return action.locations;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
