import { Grid } from "@mui/material";
import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, createSearchParams } from "react-router-dom";

import { RootState } from "model/store";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import "../style.css";

const { Icon, DatePickerInput } = UIComponents;

const EnterpriseCoreReports = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const businesses = useSelector((state: RootState) => state.businesses);
  const accounts = useSelector((state: RootState) => state.accounts);
  const businessByType = businesses.reduce((acc, val) => {
    if (Object.keys(acc).includes(val.accountId)) {
      acc[val.accountId].push(val);
    } else {
      acc[val.accountId] = [val];
    }
    return acc;
  }, {} as { [T: string]: TangoBusiness[] });
  console.log("Business by type: ", businessByType);
  return (
    <Layout>
      <div
        className={
          "flex overflow-hidden absolute top-[76px] left-0 right-0 bottom-0"
        }
      >
        <div className={"flex w-5/12 overflow-y-auto"}>
          <ul className={"p-8 m-0 list-none w-full"}>
            {Object.keys(businessByType).map((accountId, index) => {
              const currentAccount = accounts.find(
                (account) => account.id === accountId
              );
              return (
                <li
                  key={index}
                  className={
                    "mb-4  border border-solid border-grey-1 px-6 py-7 rounded-xl"
                  }
                >
                  <Link
                    to={{
                      pathname: "/daily-sales-report",
                      search: createSearchParams({
                        accountId: accountId,
                        isEnterprise: "true",
                      }).toString(),
                    }}
                    className={"flex items-center justify-between"}
                  >
                    <div className={"text-base text-black font-lato-black"}>
                      Daily Sales Report -{" "}
                      {currentAccount?.mobileOrders?.displayName || accountId}
                    </div>
                    <div>
                      <Icon name={"chevron-right"} size={"24"} />
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={"flex w-7/12 p-8"}>
          <div className={"flex"}>
            <div className={"mr-6"}>
              <div className={"text-base text-black font-lato-black"}>
                Start Date
              </div>
              <DatePickerInput size={"large"} />
            </div>
            <div>
              <div className={"text-base text-black font-lato-black"}>
                End Date
              </div>
              <DatePickerInput size={"large"} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
  return (
    <Layout>
      <div id="cash-summary" className="daily-labor-report">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          <Link to="/core-reports">
            <div className="button active">Core Reports</div>
          </Link>
        </div>
        <div className="right-section" id="labor-payroll">
          <div className="body">
            <div className="page-header">
              <div className="page-title">Core Reports</div>
            </div>
            <div className="report-boxes">
              <Grid container spacing={5} columns={18}>
                {Object.keys(businessByType).map((accountId) => {
                  const currentAccount = accounts.find(
                    (account) => account.id === accountId
                  );
                  return (
                    <Grid item xs={6}>
                      <Link
                        to={{
                          pathname: "/daily-sales-report",
                          search: createSearchParams({
                            accountId: accountId,
                            isEnterprise: "true",
                          }).toString(),
                        }}
                        style={{ width: "100%" }}
                      >
                        <div className="report-box">
                          <div className="report-box-header">
                            <div className="report-title">
                              Daily Sales Report -{" "}
                              {currentAccount?.mobileOrders?.displayName ||
                                accountId}
                            </div>
                          </div>
                          <div className="report-description">
                            Exportable daily sales reports.
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Layout>
  );
};
export default EnterpriseCoreReports;
