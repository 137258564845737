import { Button, Icon, Popover } from "@blueprintjs/core";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { DraftIdWithDepartmentId } from "controllers/schedule";

import Box from "components/Box";

import CreateShiftImage from "assets/create_shift.svg";
import DuplicateScheduleImage from "assets/duplicate_schedule.svg";
import PublishScheduleImage from "assets/publish_schedule.svg";

import { WeeklyScheduleViewMoreAction } from "../Actions/WeeklyScheduleMoreAction";
import DuplicateScheduleAction from "../DuplicateScheduleAction";
import PublishScheduleAction from "../PublishScheduleAction";

export type ScheduleStatus =
  | "published"
  | "unpublished_changes"
  | "empty"
  | "duplicated";

interface ShiftViewTypeSidebarContentProps {
  scheduleEvents: ScheduleEvent[];
  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  weekRange: Date[];
  schedulesWithLocalSchedule: TangoSchedule[];
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}

const getScheduleStatusDescription = (status: ScheduleStatus) => {
  switch (status) {
    case "published":
      return "This schedule is published";
    case "unpublished_changes":
    case "duplicated":
      return "This schedule currently has unpublished changes";
    case "empty":
      return "Add your first shift to create a schedule";
  }
};

export const ShiftViewTypeSidebarContent = ({
  scheduleStatus,
  publishScheduleLoading,
  scheduleEvents,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  weekRange,
  schedulesWithLocalSchedule,
  draftsWithDepartmentForAWeek,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
}: ShiftViewTypeSidebarContentProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        onClick={() => {
          navigate("select-request-type");
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item item-drop-pickup-request"
      >
        <div>All Requests</div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <div className="chip">
            <span className="chip-dot" />
            {scheduleEvents.filter((s) => s.status === "pending").length}
          </div>
          <Icon icon="chevron-right" iconSize={10} />
        </Box>
      </Box>
      {mergedScheduleForAWeekRange || duplicatedScheduleForAWeekRange ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className="side-nav-item item-drop-pickup-request"
        >
          <div>Other Actions</div>
          <Popover
            disabled={
              !mergedScheduleForAWeekRange && !duplicatedScheduleForAWeekRange
            }
            className="more-action-popover"
            modifiers={{ arrow: { enabled: false } }}
            position="bottom-right"
            content={
              <WeeklyScheduleViewMoreAction
                scheduleForAWeekRange={mergedScheduleForAWeekRange}
                duplicatedScheduleForAWeekRange={
                  duplicatedScheduleForAWeekRange
                }
              />
            }
            target={<Icon icon="more" iconSize={22} />}
          />
        </Box>
      ) : null}

      <Link
        style={
          weekRange.length &&
          ((scheduleForWeekRange &&
            (scheduleForWeekRange.departmentId ||
              scheduleForWeekRange.mergedSchedules)) ||
            duplicatedScheduleForAWeekRange ||
            !scheduleForWeekRange)
            ? {}
            : { pointerEvents: "none", display: "none" }
        }
        to="create-schedule"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className="side-nav-item item-drop-pickup-request"
        >
          <div style={{ color: "#089AC8" }}>Create Shift</div>
          <img src={CreateShiftImage} />
        </Box>
      </Link>

      {fohScheduleForAWeekRange && bohScheduleForAWeekRange ? (
        <Popover
          disabled={!scheduleForWeekRange}
          className="side-nav-item item-drop-pickup-request"
          modifiers={{ arrow: { enabled: false } }}
          position="bottom-right"
          content={
            <DuplicateScheduleAction
              schedule={scheduleForWeekRange as TangoSchedule}
              fohScheduleForAWeekRange={fohScheduleForAWeekRange}
              bohScheduleForAWeekRange={bohScheduleForAWeekRange}
            />
          }
          targetClassName="duplicate-popover"
          target={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div style={{ color: "#61C554" }}>Duplicate Schedule</div>
              <img src={DuplicateScheduleImage} />
            </Box>
          }
        />
      ) : null}

      <Box
        style={{
          paddingLeft: "27px",
          paddingRight: "27px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        display="flex"
        flexDirection="column"
      >
        <Popover
          disabled={
            !(
              scheduleForWeekRange &&
              (scheduleForWeekRange.departmentId ||
                scheduleForWeekRange.mergedSchedules)
            ) && !duplicatedScheduleForAWeekRange
          }
          modifiers={{ arrow: { enabled: false } }}
          position="bottom-right"
          content={
            <PublishScheduleAction
              scheduleForWeekRange={scheduleForWeekRange}
              duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
              schedule={scheduleForWeekRange}
              schedulesWithLocalSchedule={schedulesWithLocalSchedule}
              draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
            />
          }
          target={
            <Button
              disabled={
                !(
                  scheduleForWeekRange &&
                  (scheduleForWeekRange.departmentId ||
                    scheduleForWeekRange.mergedSchedules)
                ) && !duplicatedScheduleForAWeekRange
              }
              loading={publishScheduleLoading}
              icon={<img src={PublishScheduleImage} />}
              text={
                scheduleForWeekRange?.associatedScheduleId
                  ? "Publish Changes"
                  : "Publish Schedule"
              }
              id="create-shift-button"
              onClick={() => null}
            />
          }
        />
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <div
            className="schedule-status-circle"
            style={{
              backgroundColor:
                bohDraftStatus === "unpublished_changes"
                  ? "#FF7777"
                  : "#089AC8",
            }}
          />
          <div
            className="schedule-status-indicator"
            style={{
              color:
                bohDraftStatus === "unpublished_changes"
                  ? "#FF7777"
                  : "#089AC8",
            }}
          >
            BOH: {getScheduleStatusDescription(bohDraftStatus)}
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <div
            className="schedule-status-circle"
            style={{
              backgroundColor:
                fohDraftStatus === "unpublished_changes"
                  ? "#FF7777"
                  : "#089AC8",
            }}
          />
          <div
            className="schedule-status-indicator"
            style={{
              color:
                fohDraftStatus === "unpublished_changes"
                  ? "#FF7777"
                  : "#089AC8",
            }}
          >
            FOH: {getScheduleStatusDescription(fohDraftStatus)}
          </div>
        </Box>
      </Box>
    </>
  );
};
