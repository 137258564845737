import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { camelCase, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactSortable, Sortable } from "react-sortablejs";

import { RootState } from "../../../../model/store";
import { updateBusinessSetting } from "../../../../models/businesses";
import "./LevelDialog.css";

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogHeaderTitle: string;
}

const LevelDialog = ({ isOpen, setIsOpen, dialogHeaderTitle }: DialogProps) => {
  const [levels, setLevels] = useState<SubCollectionKey[]>([]);
  const [newLevels, setNewLevels] = useState<SubCollectionKey[]>([]);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  useEffect(() => {
    if (businessSettings && businessSettings.levels) {
      setLevels(
        sortBy(
          Object.keys(businessSettings.levels).map(
            (key) => businessSettings.levels![key]
          ),
          "index"
        )
      );
    } else {
      setLevels([]);
    }
  }, [businessSettings]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteOld = (level: SubCollectionKey, index: number) => {
    if (level.id) {
      const levelsTemp = [...levels].filter((item) => item.id !== level.id);
      setLevels(levelsTemp);
    } else {
      const levelsTemp = [...levels];
      levelsTemp.splice(index, 1);
      setLevels(levelsTemp);
    }
  };

  const addNewLevel = () => {
    const newLevel: SubCollectionKey = {
      id: "",
      title: "",
      deleted: false,
      description: "",
    };
    setLevels([...levels, newLevel]);
  };

  const handleDone = async () => {
    const businessSettingsLevels: any = {};
    levels.forEach((level, index) => {
      if (level.id) {
        businessSettingsLevels[level.id] = {
          ...level,
          index,
        };
      } else {
        const newLevelTemp = {
          ...level,
          id: camelCase(level.title),
        };
        businessSettingsLevels[newLevelTemp.id] = {
          ...newLevelTemp,
          index,
        };
      }
    });
    await updateBusinessSetting(
      businessSettings.businessId,
      "levels",
      businessSettingsLevels
    );
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="level_dialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="level_dialog_title">{dialogHeaderTitle}</div>
            <div className="level_dialog_body">
              <ReactSortable list={levels} setList={setLevels}>
                {levels.map((level, levelIndex) => (
                  <div className="level_dialog_content" key={level.id}>
                    <div className="level_dialog_index">{levelIndex + 1}.</div>
                    <div className="level_dialog_details">
                      <input
                        className="level_dialog_name"
                        value={level.title}
                        onChange={(e) => {
                          const value = e.target.value;
                          const newLevelsTemp = [...(levels || [])];
                          newLevelsTemp[levelIndex].title = value;
                          setLevels(newLevelsTemp);
                        }}
                      />
                      <div>
                        <img
                          src={require("assets/drag-reordering.svg").default}
                          className="level_dialog_icon level_dialog_reordering_icon"
                        />
                      </div>
                    </div>
                    <div onClick={() => handleDeleteOld(level, levelIndex)}>
                      <img
                        src={require("assets/level-close.svg").default}
                        className="level_dialog_icon level_dialog_cross_icon"
                      />
                    </div>
                  </div>
                ))}
              </ReactSortable>

              {/*{(newLevels || []).map((level, index) => {*/}
              {/*  return (*/}
              {/*    <div className="level_dialog_content" key={index}>*/}
              {/*      <div className="level_dialog_index">*/}
              {/*        {levels.length + index + 1}.*/}
              {/*      </div>*/}
              {/*      <div className="level_dialog_details">*/}
              {/*        <input*/}
              {/*          className="level_dialog_name"*/}
              {/*          value={level.title}*/}
              {/*          onChange={(e) => {*/}
              {/*            const value = e.target.value;*/}
              {/*            const newLevelsTemp = [...(newLevels || [])];*/}
              {/*            newLevelsTemp[index].title = value;*/}
              {/*            setNewLevels(newLevelsTemp);*/}
              {/*          }}*/}
              {/*        />*/}
              {/*        <div>*/}
              {/*          <img*/}
              {/*            src={require("assets/drag-reordering.svg").default}*/}
              {/*            className="level_dialog_icon level_dialog_reordering_icon"*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div onClick={() => handleDeleteNew(index)}>*/}
              {/*        <img*/}
              {/*          src={require("assets/level-close.svg").default}*/}
              {/*          className="level_dialog_icon level_dialog_cross_icon"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  );*/}
              {/*})}*/}

              <div className="level_dialog_footer">
                <button
                  type="button"
                  className="level_dialog_new_level_btn"
                  onClick={addNewLevel}
                >
                  New Level
                </button>
                <button
                  type="button"
                  className="level_dialog_done_btn"
                  onClick={handleDone}
                >
                  Done
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LevelDialog;
