import { Icon } from "@blueprintjs/core";
import { ButtonBase, Popover as MUIPopover, MenuItem } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import { DraftIdWithDepartmentId } from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { getPositionNameById } from "model/selectors/businessSettings";

import {
  ScheduleStatus,
  ShiftViewTypeSidebarContent,
} from "./ShiftViewTypeSidebarContent";
import { StaffMemberOrientedViewControls } from "./StaffMemberOrientedViewControls";
import { WeeklyScheduleViewType, WeeklyViewSelect } from "./WeeklyViewSelect";

interface SelectRequestTypeView {
  scheduleEvents: ScheduleEvent[];
}

const SelectRequestTypeView = ({ scheduleEvents }: SelectRequestTypeView) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item current-menu-item"
      >
        <Icon
          onClick={() => {
            navigate(-1);
          }}
          icon="chevron-left"
          iconSize={14}
        />
        <div>Requests</div>
        <div />
      </Box>
      <Box
        onClick={() => {
          navigate(`availability`);
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item item-drop-pickup-request"
      >
        <div>Availabilities</div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon icon="chevron-right" iconSize={10} />
        </Box>
      </Box>
      <Box
        onClick={() => {
          navigate(`drop-requests`);
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item item-drop-pickup-request"
      >
        <div>Dropped Shifts</div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <div className="chip">
            <span className="chip-dot" />
            {
              scheduleEvents.filter(
                (s) => s.eventType === "drop" && s.status === "pending"
              ).length
            }
          </div>
          <Icon icon="chevron-right" iconSize={10} />
        </Box>
      </Box>
      <Box
        onClick={() => {
          navigate(`time-off-requests`);
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item time-off-request"
      >
        <div>Time Off Requests</div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <div className="chip">
            <span className="chip-dot" />
            {
              scheduleEvents.filter(
                (s) => s.eventType === "time_off" && s.status === "pending"
              ).length
            }
          </div>
          <Icon icon="chevron-right" iconSize={10} />
        </Box>
      </Box>
      <Box
        onClick={() => {
          navigate(`trades-and-covers`);
        }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="side-nav-item trade-request"
      >
        <div>Trades & Covers</div>
        <Box display="flex" flexDirection="row" alignItems="center">
          <div className="chip">
            <span className="chip-dot" />
            {
              scheduleEvents.filter(
                (s) =>
                  (s.eventType === "trade" || s.eventType === "cover") &&
                  s.status === "pending"
              ).length
            }
          </div>
          <Icon icon="chevron-right" iconSize={10} />
        </Box>
      </Box>
    </>
  );
};

interface AvailabilityViewControlsProps {
  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
}

const AvailabilityViewControls = ({
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,
  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
}: AvailabilityViewControlsProps) => {
  const handleClick = (event: any) => {
    setAvailabilityViewFilterAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAvailabilityViewFilterAnchorEl(null);
  };

  const open = Boolean(availabilityViewFilterAnchorEl);

  return (
    <Box className="side-nav-item" display="flex" flexDirection="column">
      <ButtonBase
        onClick={handleClick}
        TouchRippleProps={{ style: { opacity: 0.5 } }}
        style={{
          backgroundColor: "rgba(249, 249, 249, 1)",
          borderRadius: 10,
          overflow: "hidden",
          marginTop: 20,
        }}
      >
        <Box
          className="weeekly-view-select-filter"
          onClick={() => null}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="weeekly-view-select-search-title">Filter by role</div>
          <div className="weeekly-view-select-filter-value">
            {availabilityRoleFilterId
              ? getPositionNameById(businessSettings, availabilityRoleFilterId)
              : "Any"}
          </div>
        </Box>
      </ButtonBase>
      <MUIPopover
        open={open}
        anchorEl={availabilityViewFilterAnchorEl}
        onClose={handleClose}
        style={{ margin: 5 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setAvailabilityRoleFilterId(null);
            handleClose();
          }}
        >
          Any
        </MenuItem>
        {_.keys(jobFunctions).map((jobFunctionKey) => (
          <MenuItem
            key={jobFunctionKey}
            onClick={() => {
              setAvailabilityRoleFilterId(jobFunctionKey);
              handleClose();
            }}
          >
            {jobFunctions[jobFunctionKey].title}
          </MenuItem>
        ))}
      </MUIPopover>
      <Box
        style={{ marginTop: 20 }}
        className="weeekly-view-select-search"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <div style={{ flex: 4 }}>
          <div className="weeekly-view-select-search-title">Search by name</div>
          <input
            style={{ fontSize: 10 }}
            placeholder="Start typing an employee’s name"
            onChange={onAvailabilityFullNameInputChangeDebounced}
          />
        </div>
        <Box
          flex={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Icon color="#888888" icon="search" iconSize={14} />
        </Box>
      </Box>
    </Box>
  );
};

interface SideBarContentProps {
  selectedRequestType?: ScheduleEventType;
  isSelectRequestType: boolean | undefined;
  isAvailabilityView: boolean;
  scheduleEvents: ScheduleEvent[];
  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;

  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  weekRange: Date[];
  schedulesWithLocalSchedule: TangoSchedule[];
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;

  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}

export const SideBarContent = ({
  selectedRequestType,
  isSelectRequestType = false,
  isAvailabilityView = false,
  scheduleEvents,
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,
  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  scheduleStatus,
  publishScheduleLoading,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  weekRange,
  schedulesWithLocalSchedule,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,
  draftsWithDepartmentForAWeek,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
}: SideBarContentProps) => {
  if (!selectedRequestType) {
    if (isSelectRequestType) {
      return <SelectRequestTypeView scheduleEvents={scheduleEvents} />;
    } else {
      return (
        <>
          {isAvailabilityView ? (
            <AvailabilityViewControls
              setAvailabilityViewFilterAnchorEl={
                setAvailabilityViewFilterAnchorEl
              }
              availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
              setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
              availabilityRoleFilterId={availabilityRoleFilterId}
              businessSettings={businessSettings}
              jobFunctions={jobFunctions}
              onAvailabilityFullNameInputChangeDebounced={
                onAvailabilityFullNameInputChangeDebounced
              }
            />
          ) : (
            <>
              <WeeklyViewSelect
                weeklyScheduleViewType={weeklyScheduleViewType}
                setWeeklyScheduleViewType={setWeeklyScheduleViewType}
                departmentScheduleViewType={departmentScheduleViewType}
                setDepartmentScheduleViewType={setDepartmentScheduleViewType}
              />
              {weeklyScheduleViewType === "shift_view" ? (
                <ShiftViewTypeSidebarContent
                  scheduleStatus={scheduleStatus}
                  publishScheduleLoading={publishScheduleLoading}
                  scheduleEvents={scheduleEvents}
                  scheduleForWeekRange={scheduleForWeekRange}
                  duplicatedScheduleForAWeekRange={
                    duplicatedScheduleForAWeekRange
                  }
                  weekRange={weekRange}
                  schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                  draftsWithDepartmentForAWeek={draftsWithDepartmentForAWeek}
                  bohDraftStatus={bohDraftStatus}
                  fohDraftStatus={fohDraftStatus}
                  mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
                  fohScheduleForAWeekRange={fohScheduleForAWeekRange}
                  bohScheduleForAWeekRange={bohScheduleForAWeekRange}
                />
              ) : (
                <StaffMemberOrientedViewControls
                  setWeeklyStaffMemberViewFilterAnchorEl={
                    setWeeklyStaffMemberViewFilterAnchorEl
                  }
                  weeklyStaffMemberViewFilterAnchorEl={
                    weeklyStaffMemberViewFilterAnchorEl
                  }
                  onFullNameInputChangeDebounced={
                    onFullNameInputChangeDebounced
                  }
                  roleFilterId={roleFilterId}
                  businessSettings={businessSettings}
                  jobFunctions={jobFunctions}
                  setRoleFilterId={setRoleFilterId}
                />
              )}
            </>
          )}
        </>
      );
    }
  }
  return null;
};
