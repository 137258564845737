import { Button, Dialog, Icon } from "@blueprintjs/core";
import firebase from "config/firebase";
import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

import { editStatusProperty } from "models/boards";

import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedStatus: any;
  selectedBoard: any;
}

export const EditStatusModal = ({
  isOpen,
  closeModal,
  selectedStatus,
  selectedBoard,
}: Props) => {
  const [status, setStatus] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const db = firebase.firestore();

  useEffect(() => {
    if (selectedStatus) {
      setStatus(selectedStatus.name);
      if (selectedStatus?.bgcolor) setSelectedColor(selectedStatus?.bgcolor);
    }
  }, [selectedStatus]);

  return (
    <Dialog
      portalClassName="location-modal-portal"
      isOpen={isOpen}
      onClose={closeModal}
      className="edit-status"
    >
      <div className="header">
        <Icon
          icon="cross"
          className="close-button"
          color="#424452"
          iconSize={20}
          onClick={() => closeModal()}
        />
        <span className="modal-title">Edit Status</span>
        <span></span>
      </div>

      <div className="search-location">
        <div className="to">Rename Status :</div>
        <input
          placeholder="Status Name"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        />
      </div>
      <div className="color-picker grid">
        <div>Pick a Color :</div>
        <div className="color-pick-pellet">
          <HexColorPicker color={selectedColor} onChange={setSelectedColor} />
          color:{selectedColor}
        </div>
      </div>

      <div className="body">
        <Button
          text="Save"
          className="continue-button"
          onClick={() => {
            if (status.length > 0 && selectedColor.length > 0) {
              editStatusProperty(
                status,
                selectedColor,
                selectedStatus?.name,
                selectedBoard?.statuses,
                selectedBoard?.id,
                selectedStatus?.items
              );
              closeModal();
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export default EditStatusModal;
