import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { MENU_ITEMS_KEY, MODIFIERS_KEY } from "../constants";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

export type BasicItem = {
  id: string;
  isModifier: boolean;
  plu: string;
  price: number;
  cost: number;
  recipe: string;
  ingredients: {
    id: string;
    amount: number;
    cost: number;
    isRaw: boolean;
    name: string;
    unitName: string;
  }[];
  // allergens: AllergenDTO[];
}

export type MenuItem = BasicItem & {
  isModifier: false;
  nameInternal: string;
  nameExternal: string;
  type: string;
  subType: string;
};

export type ModifierItem = BasicItem & {
  isModifier: true;
  modifierId: string;
  modifierName: string;
  optionName: string;
};

export type GenericMenuItem = MenuItem | ModifierItem;

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const fetchMenuItems = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<MenuItem[]>(`${businessId}/menuitems`, {
    params: {
      includeIngredients: true,
      itemType: "products",
    },
  });
  return r.data;
};
const fetchModifiers = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<ModifierItem[]>(`${businessId}/menuitems`, {
    params: {
      includeIngredients: true,
      itemType: "modifiers",
    },
  });
  return r.data;
};

const fetchMenuItem = async (businessId: string, isModifier: boolean, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<GenericMenuItem>(
    `${
      businessId
    }/menuItems/${
      isModifier ? "modifiers" : "products"
    }/${
      itemId
    }`, {
    params: {
      includeIngredients: true,
    },
  });
  return r.data;
};
type SetIngredient = {id: string; isRaw: boolean; amount: number};
const updateIngredients = async (
  businessId: string,
  isModifier: boolean,
  itemId: string,
  ingredients: SetIngredient[],
  ) => {
  if (!businessId) return null;
  const r = await apiClient.put<GenericMenuItem>(
    `${
      businessId
    }/menuItems/${
      isModifier ? "modifiers" : "products"
    }/${
      itemId
    }`, {
    ingredients
  });
  return r.data;
};
const updateRecipe = async (
  businessId: string,
  isModifier: boolean,
  itemId: string,
  recipe: string,
  ) => {
  if (!businessId) return null;
  const r = await apiClient.put<GenericMenuItem>(
    `${
      businessId
    }/menuItems/${
      isModifier ? "modifiers" : "products"
    }/${
      itemId
    }`, {
    recipe
  });
  return r.data;
};


export const useMenuItems = (businessId: string) => {
  return useQuery([MENU_ITEMS_KEY, businessId], () => fetchMenuItems(businessId));
};
export const useModifiers = (businessId: string) => {
  return useQuery([MODIFIERS_KEY, businessId], () => fetchModifiers(businessId));
};
export const useMenuItem = (businessId: string, isModifier: boolean, itemId: string) => {
  const key = isModifier ? MODIFIERS_KEY : MENU_ITEMS_KEY;
  return useQuery([key, businessId, itemId], () => fetchMenuItem(businessId, isModifier, itemId));
};
export const useUpdateIngredients = (businessId: string, isModifier: boolean, itemId: string) => {
  const client = useQueryClient();
  const key = isModifier ? MODIFIERS_KEY : MENU_ITEMS_KEY;
  return useMutation({
    mutationFn: (args: SetIngredient[]) => updateIngredients(businessId, isModifier, itemId, args),
    onSuccess: (data) =>
      client.setQueryData([key, businessId, itemId], data),
  });
};
export const useUpdateRecipe = (businessId: string, isModifier: boolean, itemId: string) => {
  const client = useQueryClient();
  const key = isModifier ? MODIFIERS_KEY : MENU_ITEMS_KEY;
  return useMutation({
    mutationFn: (args: string) => updateRecipe(businessId, isModifier, itemId, args),
    onSuccess: (data) =>
      client.setQueryData([key, businessId, itemId], (curr: GenericMenuItem | undefined) => ({
        ...(curr as GenericMenuItem), // it's not undefined
        recipe: data?.recipe ?? curr?.recipe ?? "",
      })),
  });
};
