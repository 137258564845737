import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  ICloseOutReport,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface CloseOutReportProps {
  report: ICloseOutReport;
  businessName: string;
  snapshot?: boolean;
}

const CloseOutDocument = (props: CloseOutReportProps) => {
  const data = props.report.data;
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} -
  ${moment(props.report.date).add(1, "day").format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Activity {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>
        </View>
        <Table>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Gross Sales w/ tax
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Net Sales
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Tax
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Tips
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Refunds
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Adjustment
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Tango Fees
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Total
            </TableCell>
          </TableHeader>
        </Table>
        <Table data={data}>
          {" "}
          {/*
            cardQuantity: 43
            cardSales: 4557
            cashQuantity: 20
            cashSales: 0
            discountTotal: 0
            grossTotal: 5294
            netTotal: 5294
            ordersAndTabs: (70) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
            refunds: 1486
            salesByProductType: (3) [{…}, {…}, {…}]
            salesTax: 276
            tangoFees: 2047
            tax: 276
            tips: 350
        */}
          {data.length > 0 ? (
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${formatter.format(r.grossTotal / 100)}`}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) =>
                  `${formatter.format((r.netTotal - r.tax) / 100)}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${formatter.format(r.tax / 100)}`}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${formatter.format(r.tips / 100)}`}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${formatter.format(r.refunds / 100)}`}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) =>
                  `${formatter.format(
                    (r.cashSales +
                      r.cardSales -
                      (r.netTotal + r.tips - r.refunds)) /
                      100
                  )}`
                }
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${formatter.format(r.tangoFees / 100)}`}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) =>
                  `${formatter.format(
                    (r.cashSales + r.cardSales - r.tangoFees) / 100
                  )}`
                }
              />
            </TableBody>
          ) : (
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={() => "0"}
              />
            </TableBody>
          )}
        </Table>
      </Page>
    </Document>
  );
};

export default CloseOutDocument;
