import { FirebaseServiceAreaDoc } from "types/serviceArea";

import { serviceAreasAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseServiceAreaDoc[] = [];

export default function serviceAreas(
  state = initialState,
  action: serviceAreasAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_SERVICE_AREAS:
      return action.serviceAreas;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
