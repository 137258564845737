import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import Box from "components/Box";

import { RootState } from "model/store";

import TangoImage from "../../assets/manager/tango.png";
import "./style.css";
import Header from "components/Header";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const launchpad = useLaunchpad();

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;
  const routes = [{
    label: "Reporting",
    link: "/core-reports",
  },
  {
    label: "Business Info",
    link: "/business-info",
  },
  {
    label: "Settings",
    link: "/settings"
  },
  ]
  return (
    <Box className="business-admin-module">
      <Header
        routes={!isEnterpriseLevel ? routes : [routes[0]]}
        selectedRoute={location.pathname}
        selectedPage={{
          label: "Business Admin",
          value: "/core-reports"
        }}
      />
      {/* <Box>{user?.contact?.firstName} {user?.contact?.lastName}</Box> */}
      <div className="pt-16">
        {children}

      </div>
    </Box>
  );
};
export default Layout;
