import { FirebaseDiscountDoc } from "views/admin/menus&Products/discounts/discountTypes";

import ActionType from "./actionTypes";

export function receiveDiscounts(discounts: FirebaseDiscountDoc[]) {
  return {
    type: ActionType.RECEIVE_DISCOUNTS,
    discounts,
  };
}
