import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  showPopover: () => void;
}

const groupedOptions = [
  {
    label: "People",
    options: [
      { value: "kishan", label: "Kishan" },
      { value: "spiderman", label: "spiderman" },
    ],
  },
  {
    label: "Group",
    options: [
      {
        value: "group1",
        label: "Group1",
        members: [
          { value: "kishan", label: "Kishan" },
          { value: "spiderman", label: "spiderman" },
        ],
      },
      {
        value: "group2",
        label: "Group2",
        members: [
          { value: "kishan", label: "Kishan" },
          { value: "spiderman", label: "spiderman" },
        ],
      },
    ],
  },
];
const ViewMembersPopover = ({ isOpen, showPopover, onClose }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const renderGroupMembers = () => {
    return (
      <div>
        <div className={"flex items-center"}>
          <UIComponents.Icon
            name={"chevron-left"}
            size={"16"}
            className={"mr-3 cursor-pointer"}
            onClick={() => setSelectedGroup(null)}
          />
          <div
            className={
              "bg-blue-grey-2 py-2.5 rounded-full w-[361px] flex items-center pl-6"
            }
          >
            <UIComponents.Icon
              name={"search"}
              size={"16"}
              color={"#7C7F93"}
              className={"mr-4"}
            />
            <input
              placeholder={`Search in [${selectedGroup.label}]`}
              className={"bg-transparent font-lato-regular text-sm text-black"}
            />
          </div>
        </div>
        <ul className={"max-h-[300px] overflow-y-auto"}>
          {selectedGroup.members.map((member: any) => (
            <li
              className={
                "my-3 flex items-center justify-between cursor-pointer"
              }
            >
              <div className={"flex items-center"}>
                <UIComponents.Tag
                  text={member.label.substring(0, 2)}
                  color={"blue"}
                  className={"mr-4"}
                  type={"medium"}
                />
                <div>
                  <div className={"text-sm font-lato-bold text-black"}>
                    {member.label}
                  </div>
                  <div className={"text-tiny font-lato-bold text-grey-2"}>
                    Store Manager
                  </div>
                </div>
              </div>
              <UIComponents.Icon name={"chevron-right"} size={"16"} />
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const renderGroupedOptions = () => {
    return (
      <>
        <div
          className={
            "bg-blue-grey-2 py-2.5 rounded-full w-[361px] flex items-center pl-6"
          }
        >
          <UIComponents.Icon
            name={"search"}
            size={"16"}
            color={"#7C7F93"}
            className={"mr-4"}
          />
          <input
            placeholder={"Search"}
            className={"bg-transparent font-lato-regular text-sm text-black"}
          />
        </div>
        <ul>
          {groupedOptions.map((item, index) => {
            return (
              <li className={"mt-[22px]"}>
                <div className={"text-tiny font-lato-bold text-grey-2"}>
                  {item.label}
                </div>
                <ul>
                  {item.options.map((option) => {
                    return (
                      <li
                        className={
                          "my-3 flex items-center justify-between cursor-pointer"
                        }
                        onClick={() => {
                          if (item.label === "Group") {
                            setSelectedGroup(option);
                          }
                        }}
                      >
                        <div
                          className={
                            "flex items-center text-sm font-lato-bold text-black"
                          }
                        >
                          {item.label === "Group" ? (
                            <UIComponents.Icon
                              name={"people"}
                              size={"16"}
                              className={"mr-4"}
                            />
                          ) : (
                            <UIComponents.Tag
                              text={option.label.substring(0, 2)}
                              color={"blue"}
                              className={"mr-4"}
                              type={"small"}
                            />
                          )}
                          {item.label === "Group"
                            ? `[${option.label}]`
                            : option.label}
                        </div>
                        <UIComponents.Icon name={"chevron-right"} size={"16"} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </>
    );
  };
  return (
    <UIComponents.Popover
      containerClassName={"z-10"}
      positions={["right"]}
      isOpen={isOpen}
      onClickOutside={onClose}
      content={
        <div className={"bg-white py-4 px-6 shadow-general-shadow rounded-2xl"}>
          {selectedGroup ? renderGroupMembers() : renderGroupedOptions()}
        </div>
      }
    >
      <div
        onClick={showPopover}
        className={"font-lato-bold text-tiny text-grey-3 mt-4 cursor-pointer"}
      >
        View Members...
      </div>
    </UIComponents.Popover>
  );
};
export default ViewMembersPopover;
