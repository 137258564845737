import uiComponents from "@tangopay/tango-ui-library";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventoryRoute, inventorySelectedPage } from "../routes";
import useItems from "./useCounts";

const { Modal, ModalButton } = uiComponents;
const Counts = () => {
  const {
    itemsData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
    showModal,
    setShowModal,
  } = useItems();
  const navigate = useNavigate();
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar routes={inventoryRoute} />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Upcoming Counts"
            columns={columns}
            data={itemsData}
            instructions={instructions}
            saveResults={noOp}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="View Recurring Counts"
            primaryActionHandler={noOp}
            deleteIds={logIt}
            onRowClick={(rowId) => navigate("/inventory/count/1")}
          />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        width={"560"}
      >
        <div className={"text-2xl text-black font-lato-black"}>
          What kind of item?
        </div>
        <ModalButton
          label={"Basic Item"}
          type={"white"}
          className={"mt-9"}
          subheading={
            "e.g. avocado, salt, canned beans, Bud Light, Heinz ketchup"
          }
        />
        <ModalButton
          label={"Prep Item"}
          type={"white"}
          className={"mt-9"}
          subheading={"e.g. ranch salad dressing, guacamole, spice mix"}
        />
      </Modal>
    </div>
  );
};

export default Counts;
