import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  EmployeeSalaryTableRow,
  WeeklyEmployeeSalaryReport,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 2,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  smallerFooterCellStyle: {
    fontSize: 5,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 3,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 5,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 2,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface WeeklyEmployeeSalaryReportPdfDocumentProps {
  report: WeeklyEmployeeSalaryReport;
  businessName: string;
  snapshot?: boolean;
}

const WeeklySalaryReportPdfDocument = (
  props: WeeklyEmployeeSalaryReportPdfDocumentProps
) => {
  const reportDateRange = props.snapshot
    ? `${moment(props.report.startDate).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.startDate).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment(props.report.endDate).format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 12,
            }}
          >
            Weekly Salary {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} {reportDateRange}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
        </View>
        <>
          {props.report.data.map((staffTable) => {
            return (
              <>
                <Text
                  style={{
                    fontFamily: "SFProText-Semibold",
                    color: "#454545",
                    fontSize: 7,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {staffTable.staffFullName}
                </Text>
                <Table data={staffTable.data}>
                  <TableHeader
                    style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                  >
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Task
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Time In
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Time Out
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Reg. Hours
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Over. Hours
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Double Hours
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Rate
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Salary
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) => r.roleName}
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) =>
                        moment(r.date).format("DD-MMM")
                      }
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) => r.timeIn}
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) => r.timeOut}
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) => r.regularHours}
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) =>
                        r.overtimeHours
                      }
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) => r.doubleHours}
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) =>
                        formatter.format(r.rate / 100)
                      }
                    />
                    <DataTableCell
                      style={styles.dataCellStyle}
                      getContent={(r: EmployeeSalaryTableRow) =>
                        formatter.format(r.salary / 100)
                      }
                    />
                  </TableBody>

                  <TableFooter style={{ ...styles.totalFooter }}>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>

                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {staffTable.total.regularHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {staffTable.total.overtimeHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {staffTable.total.doubleHours}
                    </TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    ></TableCell>
                    <TableCell
                      textAlign="center"
                      style={styles.smallerFooterCellStyle}
                    >
                      {formatter.format(staffTable.total.salary / 100)}
                    </TableCell>
                  </TableFooter>
                </Table>
              </>
            );
          })}
        </>
      </Page>
    </Document>
  );
};

export default WeeklySalaryReportPdfDocument;
