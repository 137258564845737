import { createSelector } from "reselect";

import { RootState } from "model/store";

const businessSettings = (state: RootState) => state.businessSettings;
const tangoJobFunctions = (state: RootState) => state.jobFunctions;

export const getAllJobFunctions = createSelector(
  businessSettings,
  tangoJobFunctions,
  (
    businessSettings: TangoBusinessSettings,
    tangoJobFunctions: TangoJobFunctions
  ) => {
    return businessSettings &&
      businessSettings.jobFunctions &&
      tangoJobFunctions
      ? { ...tangoJobFunctions, ...businessSettings.jobFunctions }
      : businessSettings && businessSettings.jobFunctions
      ? businessSettings.jobFunctions
      : tangoJobFunctions
      ? tangoJobFunctions
      : {};
  }
);
