import { Button, Icon, InputGroup } from "@blueprintjs/core";
import _, { toLower } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { RootState } from "model/store";

import CustomGroupIcon from "assets/custom-group.svg";

import Box from "../../../../components/Box";
import "./index.css";

const CreateCustomGroup = () => {
  const getBusinessCityAndProvinceOrState = (business: TangoBusiness) => {
    if (business) {
      if (business.location) {
        if (business.location.address) {
          const splitBusiness = business.location.address.split(",");
          if (splitBusiness.length >= 3) {
            const city = splitBusiness[1].trim();
            const address = splitBusiness[2].trim();
            if (address.length > 2) {
              return { city, stateOrProvince: address.substring(0, 2) };
            }
            return { city, stateOrProvince: address || "" };
          }
        }
      }
    }
    return { city: "", stateOrProvince: "" };
  };

  const staffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const cityAndAddress = getBusinessCityAndProvinceOrState(business);
  const jobFunctions = businessSettings?.jobFunctions;

  // Filterable
  const [filteredDepartment, setFilteredDepartment] = useState<
    "foh" | "boh" | "other" | null
  >(null);
  const [filteredRole, setFilteredRole] = useState<string>("");
  const [filteredLocation, setFilteredLocation] = useState<string>("");

  // View
  const [showMembersList, setShowMembersList] = useState(false);

  const [allRoles, setAllRoles] = useState<string[]>([]);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<
    StaffMember[]
  >([]);

  const [searchText, setSearchText] = useState<string>("");
  const [selectedStaff, setSelectedStaff] = useState<StaffMember[]>([]);
  const [currentStaffMembers, setCurrentStaffMembers] = useState<StaffMember[]>(
    []
  );
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const navigate = useNavigate();

  useEffect(() => {
    // All the staff members in this state will be
    // presented as potential staff members for access
    // to a group - with the filters applied
    const currentFilteredStaff = staffMembers
      .filter(
        showMembersList ? showAlreadyListedMembers : removeAlreadyListedMembers
      )
      .filter(filterByRole)
      .filter(filterByLocation)
      .filter(filterByJobFunction);
    setFilteredStaffMembers([...currentFilteredStaff]);

    // Get all roles
    const allJobs = staffMembers.reduce(
      (allRoles, staff) =>
        staff.primaryRole
          ? [...allRoles, staff.primaryRole, ...staff.secondaryRoles]
          : [...allRoles, ...staff.secondaryRoles],
      [] as string[]
    );
    const roles = _.uniq(allJobs);
    setAllRoles([...roles]);
  }, [
    staffMembers,
    showMembersList,
    currentStaffMembers,
    filteredDepartment,
    filteredRole,
    filteredLocation,
  ]);

  const showAlreadyListedMembers = (staffMember: StaffMember) => {
    const existingStaff = currentStaffMembers.map((staff) => staff.id);
    return existingStaff.includes(staffMember.id);
  };

  const removeAlreadyListedMembers = (staffMember: StaffMember) => {
    const existingStaff = currentStaffMembers.map((staff) => staff.id);
    return !existingStaff.includes(staffMember.id);
  };

  const filterByRole = (staffMember: StaffMember) => {
    return filteredRole
      ? staffMember.primaryRole?.toLowerCase() === filteredRole.toLowerCase() ||
          staffMember.secondaryRoles
            .map(toLower)
            .includes(filteredRole.toLowerCase())
      : true;
  };

  const filterByLocation = (staffMember: StaffMember) => {
    return filteredLocation
      ? staffMember.businessId === filteredLocation
      : true;
  };

  const filterByJobFunction = (staffMember: StaffMember) => {
    return jobFunctions
      ? filteredDepartment
        ? staffMember.primaryRole
          ? !!(
              jobFunctions[staffMember.primaryRole]?.departmentId ===
              filteredDepartment
            )
          : false
        : true
      : true;
  };

  const isAllStaffSelected = () => {
    if (selectedStaff.length === 0) return false;

    const selectedStaffIds = selectedStaff.map((staff) => staff.id);
    return !filteredStaffMembers.filter(
      (staff) => !selectedStaffIds.includes(staff.id)
    ).length;
  };

  const renderGroupRow = (item: StaffMember, index: number) => {
    const isSelected = selectedStaff.includes(item);
    return (
      <tr key={index} className={isSelected ? "active-row" : ""}>
        <td>
          <div>{item.contact.firstName + " " + item.contact.lastName}</div>
          <ul>
            <li>
              {cityAndAddress.city}, {cityAndAddress.stateOrProvince}
            </li>
            <li>Full-Time</li>
          </ul>
        </td>
        <td className="status-column">
          {/*
          <Icon icon="dot" color={"green"} size={20} />
          */}
          No status
        </td>
        <td className="status-column">
          <div>{_.startCase(_.toLower(item.primaryRole || ""))}</div>
        </td>
        <td className="date-column">
          <div>{moment(item.createdAt.toDate()).format("MM/DD/yyyy")}</div>
          <Link to={"/view-profile/" + item.uid} state={{ uid: item.uid }}>
            <div className="view-application">View Profile</div>
          </Link>
        </td>
        <div
          className={`checkbox-round ${isSelected ? "checked" : ""}`}
          onClick={() => {
            if (isSelected) {
              setSelectedStaff([
                ...selectedStaff.filter((staff) => staff.id !== item.id),
              ]);
            } else {
              setSelectedStaff([...selectedStaff, item]);
            }
          }}
        >
          {isSelected ? <Icon icon="dot" color="#4385EF" size={28} /> : null}
        </div>
      </tr>
    );
  };
  const renderSidebar = () => (
    <div className="sidebar-container">
      <Button
        text="Save Group"
        onClick={() => navigate("/manager/create-custom-group")}
        className="create-new-teammate save-group"
        fill
      />
      <div className="sidebar">
        <div className="sidebar-title">Action</div>
        <div className="sidebar-section">
          <InputGroup
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            leftIcon="search"
            intent="none"
          />
        </div>
        <div className="sidebar-section">
          <div className="sidebar-section-title">Filter By Type:</div>
          <ul>
            <li
              className={filteredDepartment === "boh" ? "active" : ""}
              onClick={() => {
                if (filteredDepartment !== "boh") {
                  setFilteredDepartment("boh");
                } else {
                  setFilteredDepartment(null);
                }
              }}
            >
              BOH
            </li>
            <li
              className={filteredDepartment === "foh" ? "active" : ""}
              onClick={() => {
                if (filteredDepartment !== "foh") {
                  setFilteredDepartment("foh");
                } else {
                  setFilteredDepartment(null);
                }
              }}
            >
              FOH
            </li>
            <li
              className={filteredDepartment === "other" ? "active" : ""}
              onClick={() => {
                if (filteredDepartment !== "other") {
                  setFilteredDepartment("other");
                } else {
                  setFilteredDepartment(null);
                }
              }}
            >
              Other
            </li>
          </ul>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-section-title">Filter By Role:</div>
          <select onChange={(e) => setFilteredRole(e.target.value)}>
            <option value="">Select a role</option>
            {allRoles.map((role, index) => {
              return (
                <option value={role} key={index}>
                  {_.startCase(_.toLower(role))}
                </option>
              );
            })}
          </select>
        </div>
        <div className="sidebar-section">
          <div className="sidebar-section-title">Filter By Location:</div>
          <select>
            <option value="">Select a Location</option>
          </select>
        </div>
      </div>
    </div>
  );
  const renderHeader = () => (
    <div className="create-custom-group-header">
      <div className="header-box group-name-box">
        <div className="box-title">Group Name</div>
        <div className="box-subtitle">Type the name of the new group below</div>
        <input placeholder="Group Name" />
      </div>
      <div className="header-box group-members-box">
        <Box display="flex" justifyContent="space-between">
          <div className="box-title">Group Members</div>
          <img src={CustomGroupIcon} />
        </Box>
        <div className="members-count">{currentStaffMembers.length}</div>
        {showMembersList ? (
          <div
            className="view-member-list"
            onClick={() => setShowMembersList(false)}
          >
            <Icon icon="arrow-left" color="#7C7F93" /> Add new members
          </div>
        ) : (
          <div
            className="view-member-list"
            onClick={() => setShowMembersList(true)}
          >
            View members list <Icon icon="arrow-right" color="#7C7F93" />
          </div>
        )}
      </div>
      <div className="header-box date-box">
        <div className="box-title">Created Date</div>
        <div className="date">{moment().format("MM/DD/yyyy")}</div>
      </div>
    </div>
  );
  return (
    <div>
      <div className="application-tracker-header" id="view-archives">
        <div className="small-title">Custom Groups</div>
        <div className="big-title">Create a custom group</div>
        <Box className="back-button" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" color="#7C7F93" />
          Back to Team
        </Box>
      </div>
      <Box
        className={`application-tracker-page ${
          emulatorMode ? "emulatorMode" : ""
        }  create-custom-group`}
        flex={1}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {renderSidebar()}
        <div className="jobs-container archive-table">
          {renderHeader()}
          <div className="action-button-group">
            {showMembersList ? (
              selectedStaff.length > 0 ? (
                <Button
                  text="Delete Selected Members"
                  className="delete-member-button"
                  onClick={() => {
                    const staffIds = selectedStaff.map((staff) => staff.id);
                    setCurrentStaffMembers([
                      ...currentStaffMembers.filter(
                        (staff) => !staffIds.includes(staff.id)
                      ),
                    ]);
                    setSelectedStaff([]);
                  }}
                />
              ) : (
                <></>
              )
            ) : selectedStaff.length > 0 ? (
              <>
                <Button
                  text="Add Selected Members"
                  className="add-selected-member-button"
                  onClick={() => {
                    setCurrentStaffMembers([
                      ...currentStaffMembers,
                      ...selectedStaff,
                    ]);
                    setSelectedStaff([]);
                  }}
                />
                <Button
                  className="add-group-member-button"
                  onClick={() => navigate("/manager/add-employee")}
                  text="+"
                  rightIcon={<img src={CustomGroupIcon} />}
                />
              </>
            ) : (
              <Button
                className="add-group-member-button"
                onClick={() => navigate("/manager/add-employee")}
                text="+"
                rightIcon={<img src={CustomGroupIcon} />}
              />
            )}
          </div>
          <table>
            <tr>
              <th>Group Members</th>
              <th>Status</th>
              <th>Primary Role</th>
              <th>Start Date</th>
              <div
                className="checkbox-round header-checkbox"
                onClick={() => {
                  if (isAllStaffSelected()) {
                    setSelectedStaff([]);
                  } else {
                    setSelectedStaff(filteredStaffMembers);
                  }
                }}
              >
                {isAllStaffSelected() ? (
                  <Icon icon="dot" color="#4385EF" size={28} />
                ) : null}
              </div>
            </tr>
            {filteredStaffMembers.map((item, index) =>
              renderGroupRow(item, index)
            )}
          </table>
        </div>
      </Box>
    </div>
  );
};
export default CreateCustomGroup;
