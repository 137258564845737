import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";

import { fetchCollectionByBusinessIds } from "controllers/accounts";
import {
  composeUsableModifierOptions,
  composeUsableModifiers,
  composeUsableProducts,
} from "controllers/composableTypes";

import { RootState } from "model/store";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import { performSoftDelete, saveData } from "../../../../models/modifiers";
import { DocumentData } from "../../../../types/document";
import {
  FirebaseModifierOptionsDoc,
  ModifierDoc,
} from "../../../../types/modifiers";
import { FirebaseProductDoc } from "../../../../types/products";
import { saveStitchedProductsToFirebase } from "../SaveStitchedProducts";
import ModifierModal from "./ModifierModal";

interface Props {
  businessId: string;
  modifiers: DocumentData[];
  modifierOptions: DocumentData[];
  products: FirebaseProductDoc[];
}

const ModifiersTable = ({
  businessId,
  modifiers,
  modifierOptions,
  products,
}: Props) => {
  const modifierOptionsRef = modifierOptions.reduce(
    (acc, val) => ({ ...acc, [val.name]: val.id }),
    {}
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );

  const initComplete: boolean = useSelector(
    (state: RootState) => state.enterpriseInitComplete
  );
  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings);

  const [allowModifierUpdates, setAllowModifierUpdates] = useState<boolean>(false)
  const [allModifierOptions, setAllModifierOptions] = useState<
    FirebaseModifierOptionsDoc[]
  >(modifierOptions as FirebaseModifierOptionsDoc[]);
  const [allModifierOptionsUpdated, setAllModifierOptionsUpdated] =
    useState<boolean>(false);
  // const [existingData, setExistingData] = useState<ModifierDoc[]>([])
  const [data, setData] = useState<ModifierDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<ModifierDoc[]>([]);
  const [loading, setLoading] = useState(true);
  const [savingToFirebaseLoading, setSavingToFirebaseLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    businessId: businessId,
    // allModifierOptions: allModifierOptions,
    // setAllModifierOptions: setAllModifierOptions,
    // modifiers: modifiers,
  });
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "nameExternal",
    errors: [],
  });
  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "modifierOptions",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: modifierOptions.map((type) => ({
          optionName: type.name,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const columnHeadingConversions = {
    nameExternal: "Modifier Name",
    nameInternal: "Modifier Prompt",
    min: "Min Required",
    max: "Max Allowed",
    modifierOptions: "Modifier Options",
    description: "Modifier Description",
  };

  const tableSchema = [
    { key: "nameExternal", type: "value", data: "text" },
    { key: "nameInternal", type: "value", data: "text" },
    { key: "min", type: "value", data: "number" },
    { key: "max", type: "value", data: "number" },
    { key: "modifierOptions", type: "arrayOfValue", data: "text" },
    { key: "description", type: "value", data: "text" },
  ];
  const dataSchema = [
    { key: "id", type: "value", data: "text" },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "nameInternal", type: "value", data: "text" },
    { key: "min", type: "value", data: "number" },
    { key: "max", type: "value", data: "number" },
    { key: "modifierOptions", type: "arrayOfValue", data: "text" },
    { key: "description", type: "value", data: "text" },
    { key: "options", type: "arrayOfValue", data: "text" },
  ];

  // Which columns in the grid to render based on the data
  const filterData = (data: ModifierDoc[]) => {
    return data.map((row) => ({
      nameExternal: row.nameExternal,
      nameInternal: row.nameInternal,
      min: row.min,
      max: row.max,
      modifierOptions: row.modifierOptions || [],
      description: row.description,
    }));
  };

  const transformData = (modifiers: DocumentData[]): ModifierDoc[] => {
    return modifiers.map((modifier) => {
      return {
        // Meta
        id: modifier.id,

        // Table info
        nameExternal: modifier.nameExternal,
        nameInternal: modifier.nameInternal,
        min: modifier.min,
        max: modifier.max,
        modifierOptions: modifier.options
          .filter((i: string | undefined) => !!i)
          .map((option: string) => {
            const optionIndex = modifierOptions.findIndex(
              (modifierOption) => modifierOption.id === option
            );
            if (optionIndex !== -1) {
              return modifierOptions[optionIndex].name;
            }
          }),
        description: modifier.description,

        // Modal extra
        options: modifier.options
          .filter((i: string | undefined) => !!i)
          .map((option: string) => {
            const optionIndex = modifierOptions.findIndex(
              (modifierOption) => modifierOption.id === option
            );
            if (optionIndex !== -1) {
              return {
                id: modifierOptions[optionIndex].id,
                modifierOptionName: modifierOptions[optionIndex].name,
                modifierOptionPrice:
                  modifierOptions[optionIndex].additionalCost,
              };
            }
          }),
        plu: modifier.plu,
        enterpriseUpdatedDocument: !!isEnterpriseLevel,
      };
    });
  };

  useEffect(() => {
    if (modifiers && modifierOptions) {
      const transformedData = transformData(modifiers);
      setData([...transformedData]);
      // This is so that the additional info for modifier options modal
      // updates along with the modifier data
      setAdditionalInfo({
        businessId: businessId,
        // allModifierOptions: allModifierOptions,
        // setAllModifierOptions: setAllModifierOptions,
        // modifiers: modifiers,
      });
      setLoading(false);
      setSaved(false);
    }
  }, [modifiers, modifierOptions, loading, saved]);

  useEffect(() => {
    if (allModifierOptionsUpdated) {
      setAllModifierOptionsUpdated(false);
    }
  }, [allModifierOptionsUpdated]);

  useEffect(() => {
    // If modifier options from the modal gets updated
    setAllModifierOptionsUpdated(true);
  }, [allModifierOptions]);

  useEffect(() => {
    if (businessSettings) {
      const allowUpdates = businessSettings.menuBuilderPermissions.allowModifierUpdates;
      setAllowModifierUpdates(!allowUpdates)
    }
  }, [businessSettings])

  const checkDuplicates = (data: ModifierDoc[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      nameInternal: row.nameInternal,
      nameExternal: row.nameExternal,
      index: index,
    }));

    // Filter for duplicates
    const modifierDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({
            nameInternal: row.nameInternal,
            nameExternal: row.nameExternal,
          })),
          { nameInternal: item.nameInternal, nameExternal: item.nameExternal }
        ) !== index
    );

    // Find the duplicate without the productId
    const errors: DocumentData[] = [];
    for (let i = 0; i < modifierDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) =>
          row.nameInternal === modifierDuplicateErrors[i].nameInternal &&
          row.nameExternal === modifierDuplicateErrors[i].nameExternal
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: ModifierDoc[]) => {
    const errors = [
      // Wrong min
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter((row: any) => {
          const reg = new RegExp("^[0-9]+$");
          return !reg.test(row.min);
        })
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "min", isEdited: false }],
          isEdited: false,
        })),
      // Wrong max
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter((row: any) => {
          const reg = new RegExp("^[0-9]+$");
          return !reg.test(row.max);
        })
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "max", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: ModifierDoc[]) => {
    const errors = [
      // No name external
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter(
          (row: any) => !row.nameExternal || row.nameExternal.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameExternal", isEdited: false }],
          isEdited: false,
        })),
      // No name internal
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter(
          (row: any) => !row.nameInternal || row.nameInternal.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameInternal", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal: showErrorModal,
        // @ts-ignore
        errors: errors,
      });
      return true;
      // All errors have been resolved
    } else {
      setErrorManager({
        ...errorManager,
        showErrorModal: false,
        errors: [],
      });
    }
    return false;
  };

  const saveToFirebase = async () => {
    if (isEnterpriseLevel) {
      await saveToFirebaseFromEnterprise();
      return true;
    } else {
      await performSoftDelete(softDeleteData, products);
      await saveData(
        data,
        transformData(modifiers),
        businessId,
        modifierOptionsRef,
        isEnterpriseLevel,
        [],
      );
      setSaved(true);
      return true;
    }
  };

  const saveToFirebaseFromEnterprise = async () => {
    if (businesses.length > 0) {
      for await (const [index, business] of businesses.entries()) {
        const businessModifiers = await fetchCollectionByBusinessIds(
          [business.id],
          "Modifiers",
          composeUsableModifiers
        );

        const businessProducts = await fetchCollectionByBusinessIds(
          [business.id],
          "Products",
          composeUsableProducts
        );

        const modifiersToDelete = softDeleteData.map(
          (softDeleteModifier) => softDeleteModifier.nameExternal
        );
        const filteredModifiersToDelete: FirebaseModifierDoc[] =
          businessModifiers.filter((modifier) =>
            modifiersToDelete.includes(modifier.nameExternal)
          );

        await performSoftDelete(filteredModifiersToDelete, businessProducts);

        const businessModifierOptions = await fetchCollectionByBusinessIds(
          [business.id],
          "ModifierOptions",
          composeUsableModifierOptions
        );
        const businessModifierOptionsRef = businessModifierOptions.reduce(
          (acc, val) => ({ ...acc, [val.name]: val.id }),
          {}
        );

        const newData = data.map((m) => {
          if (m.nameExternal) {
            const businessModifier = businessModifiers.find(
              (businessProduct) => businessProduct.nameExternal === m.nameExternal
            );

            if (businessModifier) {
              return {
                ...m,
                businessId: businessModifier.businessId,
                id: businessModifier.id,
              };
            }
          }

          return m;
        });

        const result = await saveData(
          newData,
          transformData(businessModifiers),
          business.id,
          businessModifierOptionsRef,
          isEnterpriseLevel,
          businessModifiers,
        );

        if (index === businesses.length - 1) {
          setSaved(true);
          return true;
        }
      }
    }
  };

  if (!initComplete) {
    return <EnterpriseInitializeLoading />;
  }

  return (
    <div className="modifiers-table">
      <Table
        businessId={businessId}
        name={"Modifiers"}
        parentName={"MenuBuilder"}
        allData={data}
        setAllData={setData}
        softDelete={softDeleteData}
        setSoftDelete={setSoftDeleteData}
        allowSoftDelete={true}
        filterData={filterData}
        tableSchema={tableSchema}
        dataSchema={dataSchema}
        options={options}
        setOptions={setOptions}
        hasModal={true}
        modalColumTitle="View Product"
        ModalComponent={ModifierModal}
        errorManager={errorManager}
        setErrorManager={setErrorManager}
        closeErrorManager={() => {
          setErrorManager({
            ...errorManager,
            showErrorModal: false,
          });
        }}
        verifySave={verifyData}
        saveToFirebase={saveToFirebase}
        hasSearch={true}
        searchColumnName={"nameExternal"}
        hasFilter={false}
        columnHeadingConversions={columnHeadingConversions}
        additionalInfo={additionalInfo}
        searchPlaceHolderText={"Find a modifier"}
        readOnly={isEnterpriseLevel ? false : allowModifierUpdates}
      />
    </div>
  );
};

export default ModifiersTable;
