import { FC, useState } from "react";

import FlexPreferencesModal from "../modals/FlexPreferences";
import OnlineOrdering from "../modals/OnlineOrderingModal";
import OrderDisplayPreferencesModal from "../modals/OrderDisplayPreferences";
import SpeedStationModal from "../modals/SpeedStationModal";
import StaffingPreferencesModals from "../modals/StaffingPreferences";
import "./index.css";

const Preferences: FC = () => {
  const [showSpeedModal, setShowSpeedModal] = useState<boolean>(false);
  const [showOnlineOrderModal, setShowOnlineOrderModal] =
    useState<boolean>(false);
  const [showOrderDisplayModal, setShowOrderDisplayModal] =
    useState<boolean>(false);
  const [showFlexModal, setShowFlexModal] = useState<boolean>(false);
  const [showStaffingModal, setShowStaffingModal] = useState<boolean>(false);

  return (
    <div id="tango-preferences">
      <div className="section-name">
        <span>Tango Preferences</span>
        <div className="section-subtitle">
          Select a specific Tango product below to see and manage preferences
        </div>
      </div>
      <div className="section-body">
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            className="preference-item"
            onClick={() => setShowFlexModal(true)}
          >
            <div
              className="preference-logo"
              style={{ background: "rgba(140, 50, 255, 0.6)" }}
            >
              <img src={require("../../../../assets/tango-icon.svg").default} />
              <div>Flex</div>
            </div>
            <div>
              <div className="title">Flex</div>
              <div className="subtitle">
                Tableside server tablet + dine-in customer order & pay
              </div>
            </div>
          </div>
          <div
            className="preference-item"
            onClick={() => setShowOnlineOrderModal(true)}
          >
            <div
              className="preference-logo"
              style={{ background: "rgba(255, 154, 3, 0.6)" }}
            >
              <img src={require("../../../../assets/tango-icon.svg").default} />
              <div>Online</div>
            </div>
            <div>
              <div className="title">Online Ordering</div>
              <div className="subtitle">Online ordering for customers</div>
            </div>
          </div>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            className="preference-item"
            onClick={() => setShowSpeedModal(true)}
          >
            <div
              className="preference-logo"
              style={{ background: "rgba(255, 3, 3, 0.6)" }}
            >
              <img src={require("../../../../assets/tango-icon.svg").default} />
              <div>Speed</div>
            </div>
            <div>
              <div className="title">Speed</div>
              <div className="subtitle">Speed POS</div>
            </div>
          </div>
          <div
            className="preference-item"
            onClick={() => setShowOrderDisplayModal(true)}
          >
            <div
              className="preference-logo"
              style={{ background: "rgba(255, 119, 241, 0.6)" }}
            >
              <img src={require("../../../../assets/tango-icon.svg").default} />
              <div>Display</div>
            </div>
            <div>
              <div className="title">Order Displays</div>
              <div className="subtitle">Display incoming and past orders</div>
            </div>
          </div>
        </div>

        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            className="preference-item"
            onClick={() => setShowStaffingModal(true)}
          >
            <div
              className="preference-logo"
              style={{ background: "rgb(85,200,124, 0.8)" }}
            >
              <img src={require("../../../../assets/tango-icon.svg").default} />
              <div>Staffing</div>
            </div>
            <div>
              <div className="title">Staffing</div>
              <div className="subtitle">Employee Scheduling</div>
            </div>
          </div>
        </div>
      </div>

      <SpeedStationModal
        isOpen={showSpeedModal}
        closeModal={() => setShowSpeedModal(false)}
      />
      <OnlineOrdering
        isOpen={showOnlineOrderModal}
        closeModal={() => setShowOnlineOrderModal(false)}
      />
      <OrderDisplayPreferencesModal
        isOpen={showOrderDisplayModal}
        closeModal={() => setShowOrderDisplayModal(false)}
      />
      <FlexPreferencesModal
        isOpen={showFlexModal}
        closeModal={() => setShowFlexModal(false)}
      />
      <StaffingPreferencesModals
        isOpen={showStaffingModal}
        closeModal={() => setShowStaffingModal(false)}
      />
    </div>
  );
};
export default Preferences;
