import { firestore, storage } from "config/firebase";
import { v4 as uuidv4 } from "uuid";

export const sendAnnouncement = async (
  businessId: string,
  jobFunctions: string[],
  message: string,
  senderId: string,
  senderName: string,
  files: any[]
) => {
  try {
    const announcementsRef = firestore.collection("Announcements").doc();
    await firestore.collection("Announcements").doc(announcementsRef.id).set({
      businessId,
      jobFunctions,
      message,
      senderId,
      senderName,
    });

    const attachments: TangoAttachment[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const file of files) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (file) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const path = `Announcements/${announcementsRef.id}/${file.name}`;
        await storage.ref(path).put(file);
        const url = await storage.ref(path).getDownloadURL();
        const metadata = await storage.ref(path).getMetadata(); // name contentType
        attachments.push({
          id: uuidv4(),
          contentType: metadata.contentType as string,
          name: metadata.name as string,
          url: url as string,
        });
        console.log("url", url, metadata);
      }
    }
    await firestore
      .collection("Announcements")
      .doc(announcementsRef.id)
      .update({
        attachments,
      });
  } catch (e) {
    console.log("send announcement error,", e);
  }
};

export const deleteMemo = async (memoId: string) => {
  await firestore
    .collection("Announcements")
    .doc(memoId)
    .update({ deleted: true });
};

export const sendMemo = async (
  businessId: string,
  message: string,
  senderId: string,
  senderName: string,
  files: File[],
  data: string,
  title: string,
  targetIds: string[]
) => {
  try {
    const announcementsRef = firestore.collection("Announcements").doc();
    await firestore.collection("Announcements").doc(announcementsRef.id).set({
      businessId,
      jobFunctions: targetIds,
      id: announcementsRef.id,
      message,
      senderId,
      senderName,
      data,
      title,
      targets: targetIds,
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    const attachments: TangoAttachment[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for await (const file of files) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (file) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const path = `Announcements/${announcementsRef.id}/${file.name}`;
        await storage.ref(path).put(file);
        const url = await storage.ref(path).getDownloadURL();
        const metadata = await storage.ref(path).getMetadata(); // name contentType
        attachments.push({
          id: uuidv4(),
          contentType: metadata.contentType as string,
          name: metadata.name as string,
          url: url as string,
        });
        console.log("url", url, metadata);
      }
    }
    await firestore
      .collection("Announcements")
      .doc(announcementsRef.id)
      .update({
        attachments,
      });
  } catch (e) {
    console.log("send announcement error,", e);
  }
};
