import _ from "lodash";

let listeners = {};

export function addListener(key: string, l: any) {
  const existingUnsubscribe: any = _.get(listeners, key);
  if (existingUnsubscribe) existingUnsubscribe();
  // @ts-ignore
  listeners[key] = l;
}

export function hasListener(key: string) {
  return _.has(listeners, key);
}

export function clearListeners() {
  console.log("clear listeners:");
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in listeners) {
    console.log("clear listener", key);
    // @ts-ignore
    const l = listeners[key];
    // console.log('off:', l);
    // l.off()
    l();
  }

  listeners = {};
}

export function offListener(key: string) {
  const l: any = _.get(listeners, key);
  if (l) {
    // l.off()
    l();
    // @ts-ignore
    delete listeners[key];
  }
}

let userUnsubscribe: any;

export function setUserUnsubscribe(unsubscribe: any) {
  userUnsubscribe = unsubscribe;
}

export function clearUserListener() {
  if (userUnsubscribe) userUnsubscribe();
}
