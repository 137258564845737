import {
  Button,
  Dialog,
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import "tailwindcss/tailwind.css"
import { v4 as uuidv4 } from "uuid";

import {
  composeAdditionalShiftsFromShiftStarters,
  createDraftScheduleFromDuplicateScheduleWithShiftStarters,
  createEmptyScheduleFromShiftStarters,
} from "controllers/schedule";
import { createShiftType, deleteShiftType } from "controllers/staff";

import Box from "components/Box";

import { updateDuplicateScheduleToDraft } from "model/actions/staffingAC";
import { getPositionNameById } from "model/selectors/businessSettings";
import store, { RootState } from "model/store";

import DaysModal from "../DaysModal";
import PublishIcon from "./PublishIcon";
import "./style.css";

interface Props {
  weekRange: Date[];
  scheduleForWeekRange?: TangoSchedule | null;
}
type ShiftTypeColor = {
  mainColor: string;
  backgroundColor: string;
  name: string;
};
const availableColorsForShiftTypes: ShiftTypeColor[] = [
  { backgroundColor: "#EDFAED", mainColor: "#26C940", name: "Green" },
  { backgroundColor: "#E3F6FA", mainColor: "#089AC8", name: "Blue" },
  { backgroundColor: "#FFF0FF", mainColor: "#FF77F1", name: "Pink" },
  { backgroundColor: "#F5DDFF", mainColor: "#D673FF", name: "Magenta" },
  { backgroundColor: "#FFF6E3", mainColor: "#FFA700", name: "Orange" },
  { backgroundColor: "#FFEDEE", mainColor: "#FF6D76", name: "Red" },
];

const ShiftStudio = ({ weekRange, scheduleForWeekRange }: Props) => {
  const [jobFunctionForRolesShiftsModal, setJobFunctionForRolesShiftsModal] =
    useState<string | null>(null);
  const [showSelectDaysModal, setShowSelectDaysModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([
    moment().format("dddd"),
  ]);

  const [newShiftTypeName, setNewShiftTypeName] = useState("");

  const [selectedShiftTypeColor, setSelectedShiftTypeColor] =
    useState<ShiftTypeColor>(availableColorsForShiftTypes[0]);
  const [colorPopoverVisible, setColorPopoverVisible] = useState(false);

  const [shiftStart, setShiftStart] = useState("09:00");
  const [shiftEnd, setShiftEnd] = useState("18:00");
  const [loading, setLoading] = useState(false);
  const [shiftType, setShiftType] = useState<string>("");
  const [showColorPopover, setShowColorPopover] = useState<boolean>(false);
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const shiftTypes = (businessSettings?.shiftTypes || []).filter(
    (shiftT) => !shiftT?.deleted
  );
  // if (!shiftTypes) return null;
  const jobFunctions = businessSettings?.jobFunctions || {};
  const [shiftWorkers, setShiftWorkers] = useState(
    _.keys(jobFunctions).map((key) => ({ id: key, quantity: 0 }))
  );
  const [shiftStarters, setShiftStarters] = useState<ShiftStarter[]>([]);
  const navigate = useNavigate();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const schedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );

  const saveShifts = async () => {
    const processedShiftStarters = shiftStarters.map((shStarter) => ({
      ...shStarter,
      startTime: shiftStart,
      endTime: shiftEnd,
    }));
    if (scheduleForWeekRange) {
      setLoading(true);
      if (scheduleForWeekRange?.isDuplicate) {
        const newDraftId =
          await createDraftScheduleFromDuplicateScheduleWithShiftStarters(
            scheduleForWeekRange,
            business.id,
            processedShiftStarters,
            weekRange,
            shiftType,
            selectedDays
          );
        if (newDraftId) {
          store.dispatch(updateDuplicateScheduleToDraft(newDraftId));
        }
        setLoading(false);
      } else {
        await composeAdditionalShiftsFromShiftStarters(
          processedShiftStarters,
          weekRange,
          shiftType,
          selectedDays,
          scheduleForWeekRange.id,
          business.id
        );
        setLoading(false);
      }
    } else {
      setLoading(true);
      await createEmptyScheduleFromShiftStarters(
        weekRange,
        processedShiftStarters,
        business.id,
        shiftType,
        selectedDays
      );
      setLoading(false);
    }
    navigate(-1);
  };

  const increaseShiftWorkerQuantity = (id: string) => () => {
    const newShiftWorkers = shiftWorkers.map((shiftWorker) => {
      if (shiftWorker.id === id) {
        return { ...shiftWorker, quantity: shiftWorker.quantity + 1 };
      }
      return shiftWorker;
    });
    const additionalShiftStarter: ShiftStarter = {
      id: uuidv4(),
      startTime: shiftStart,
      endTime: shiftEnd,
      position: id,
      staffId: null,
    };
    setShiftStarters([...shiftStarters, additionalShiftStarter]);
    setShiftWorkers(newShiftWorkers);
  };

  const decreaseShiftWorkerQuantity = (id: string) => () => {
    const newShiftWorkers = shiftWorkers.map((shiftWorker) => {
      if (shiftWorker.id === id) {
        return {
          ...shiftWorker,
          quantity: shiftWorker.quantity
            ? shiftWorker.quantity - 1
            : shiftWorker.quantity,
        };
      }
      return shiftWorker;
    });

    setShiftWorkers(newShiftWorkers);
    const existingShifStartersForThisId = shiftStarters.filter(
      (shiftStarter) => shiftStarter?.position === id
    );

    if (!existingShifStartersForThisId?.length) {
      return;
    }
    const newShiftStartersForThisId = existingShifStartersForThisId
      .sort((a, b) => Number(Boolean(a?.staffId)) - Number(Boolean(b?.staffId)))
      .slice(0, -1);
    setShiftStarters([
      ...shiftStarters.filter((shiftStarter) => shiftStarter?.position !== id),
      ...newShiftStartersForThisId,
    ]);
  };

  const onShiftTypeInputKeyPress = async (e: any) => {
    if (e.code === "Enter" && newShiftTypeName && selectedShiftTypeColor) {
      await createShiftType(
        business.id,
        newShiftTypeName,
        selectedShiftTypeColor.mainColor,
        selectedShiftTypeColor.backgroundColor
      );
      setColorPopoverVisible(false);
      setNewShiftTypeName("");
    }
  };

  const getShiftWorker = (id: string) =>
    shiftWorkers.find((shiftWorker) => shiftWorker.id === id);

  const renderJobFunctions = (): JSX.Element[] =>
    _.values(jobFunctions)
      .filter((jf) => !jf?.deleted)
      .map((jobFunction: any) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          className="staff-shift-stripe"
        >
          <div className="staff-role">{jobFunction.title}</div>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ width: "60%" }}
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                onClick={decreaseShiftWorkerQuantity(jobFunction.id)}
                size="small"
              >
                <Icon icon="small-minus" className="quantity-button" />
              </IconButton>
              <div className="quanity-label">
                {getShiftWorker(jobFunction.id)?.quantity}
              </div>
              <IconButton
                onClick={increaseShiftWorkerQuantity(jobFunction.id)}
                size="small"
              >
                <Icon icon="small-plus" className="quantity-button" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ));

  const colors = (
    <div className="colors-popover">
      <div className="popover-title">COLORS</div>
      {availableColorsForShiftTypes.map((color) => (
        <div
          className="color-item"
          onClick={() => setSelectedShiftTypeColor(color)}
        >
          <div
            className="color-box"
            style={{ backgroundColor: color.mainColor }}
          />
          <div className="color-name">{color.name}</div>
        </div>
      ))}
    </div>
  );

  const deleteShiftTypeById = async (shiftTypeId: string) => {
    try {
      await deleteShiftType(business.id, shiftTypeId);
    } catch (e) {
      console.log("Error deleting shift type", e);
      alert("Opps, something went wrong!");
    }
  };

  const ShiftNameList = (
    <div className="shift-list-dropdown">
      <div className="shift-name-item">
        <input
          onKeyPress={onShiftTypeInputKeyPress}
          value={newShiftTypeName}
          onChange={(e) => setNewShiftTypeName(e.target.value)}
          className="color-span"
          style={{
            backgroundColor: selectedShiftTypeColor.backgroundColor,
            color: "black",
          }}
        />
        <Icon
          icon="more"
          color="#454545"
          iconSize={12}
          onClick={() => setShowColorPopover(true)}
        />
      </div>
      <div className="seperator-text">Select an option or create one</div>
      <div style={{ width: "100%", maxHeight: "30vh", overflowY: "scroll" }}>
        {shiftTypes.map((st) => (
          <div
            key={st.id}
            className="flex items-center justify-between pr-1"
            style={{ display: "flex" }}
          >
            <div
              onClick={() => setShiftType(st.id)}
              className="shift-name-item"
            >
              <span
                className="color-span"
                style={{ backgroundColor: st.backgroundColor }}
              >
                {st.name}
              </span>
            </div>
            <IconButton onClick={() => deleteShiftTypeById(st.id)}>
              <Icon icon="cross" iconSize={10} />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );

  const updateShiftStarterById = (update: object, id: string) => {
    setShiftStarters(
      shiftStarters.map((shiftStarter) => {
        if (shiftStarter.id === id) {
          return { ...shiftStarter, ...update };
        }
        return shiftStarter;
      })
    );
  };

  const renderShiftsModalContents = () => {
    const shiftStartersToRender = shiftStarters.filter(
      (shiftStarter) =>
        shiftStarter?.position === jobFunctionForRolesShiftsModal
    );
    if (!shiftStartersToRender?.length) {
      return (
        <Box
          className="shift-row"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            display="flex"
            flex={0.5}
            flexDirection="column"
            className="shift-row-cell"
          >
            <div className="row-title">Empty</div>
          </Box>
        </Box>
      );
    }
    return shiftStartersToRender.map((shiftStarter, index) => (
      <Box
        key={shiftStarter.id}
        className="shift-row"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          display="flex"
          flex={0.5}
          flexDirection="column"
          className="shift-row-cell"
        >
          <div className="row-title">
            {getPositionNameById(
              businessSettings,
              shiftStarter?.position || ""
            )}{" "}
            {index + 1}
          </div>
        </Box>
        <Box
          display="flex"
          flex={0.25}
          flexDirection="column"
          className="shift-row-cell"
        >
          <div className="row-title">Start</div>
          <input
            className="time-picker"
            value={shiftStarter.startTime}
            type="time"
            onChange={(e) => {
              updateShiftStarterById(
                { startTime: e.target.value },
                shiftStarter.id
              );
            }}
          />
        </Box>
        <Box
          display="flex"
          flex={0.25}
          flexDirection="column"
          className="shift-row-cell"
        >
          <div className="row-title">End</div>
          <input
            className="time-picker"
            value={shiftStarter.endTime}
            type="time"
            onChange={(e) => {
              updateShiftStarterById(
                { endTime: e.target.value },
                shiftStarter.id
              );
            }}
          />
        </Box>
      </Box>
    ));
  };

  return (
    <Box
      className="shift-studio"
      style={{ overflow: "scroll", height: "90vh" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        className="side-nav-item current-menu-item"
        flex={1}
      >
        <Icon onClick={() => navigate(-1)} icon="chevron-left" iconSize={14} />
        <div className="header-title">Tango Shift Studio</div>
        <div />
      </Box>
      <Box
        className="sidebar-section"
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "20px",
          paddingRight: "23px",
        }}
      >
        <div className="section-title">First, let’s name your shift</div>
        <Popover
          onClose={() => setColorPopoverVisible(false)}
          isOpen={colorPopoverVisible}
          content={ShiftNameList}
          position={Position.TOP}
          modifiers={{ arrow: { enabled: true } }}
          interactionKind={PopoverInteractionKind.CLICK}
        >
          <div
            className="shift-name"
            onClick={() => setColorPopoverVisible(true)}
          >
            {shiftTypes.find((st) => st.id === shiftType)?.name ||
              "Please enter a shift type"}
          </div>
        </Popover>
        <Popover
          portalClassName="colors-popover-container"
          position={Position.TOP}
          isOpen={showColorPopover}
          onClose={() => setShowColorPopover(false)}
          content={colors}
          modifiers={{ arrow: { enabled: false } }}
        >
          <div style={{ fontSize: 0 }}>testt</div>
        </Popover>
      </Box>
      <Box className="sidebar-section" display="flex" flexDirection="row">
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          style={{ padding: "20px 0px", paddingLeft: "20px" }}
        >
          <div className="section-title">Start</div>
          <input
            className="time-picker"
            value={shiftStart}
            type="time"
            onChange={(e) => {
              setShiftStart(e.target.value);
            }}
          />
        </Box>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          className="border-left"
          style={{ padding: "20px 0px", paddingLeft: "20px" }}
        >
          <div className="section-title">End</div>
          <input
            min={shiftStart}
            className="time-picker"
            value={shiftEnd}
            type="time"
            onChange={(e) => setShiftEnd(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        className="sidebar-section"
        style={{ paddingTop: "20px", border: "0px" }}
      >
        <div
          className="section-title"
          style={{ marginBottom: "0px", paddingLeft: "20px" }}
        >
          Who works this shift?
        </div>
        {renderJobFunctions()}
        <Box
          className="sidebar-section"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "23px",
          }}
        >
          <div className="section-title">
            What days does this shift apply to?
          </div>
          <Box
            className="section-body"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className="days">
              {selectedDays
                .map((d) => moment().day(d).format("ddd"))
                .join(", ")}
            </div>
            <IconButton onClick={() => setShowSelectDaysModal(true)}>
              <Icon icon="chevron-right" iconSize={10} />
            </IconButton>
          </Box>
        </Box>
        <Box style={{ paddingLeft: "27px", paddingRight: "27px" }}>
          <Button
            disabled={!shiftTypes?.length || !shiftType}
            loading={loading}
            onClick={saveShifts}
            icon={<Icon icon={<PublishIcon />} iconSize={13} color="#089AC8" />}
            text="Save shifts"
            id="create-shift-button"
          />
        </Box>
      </Box>
      <DaysModal
        selectedDays={selectedDays}
        setSelectedDays={(d) => setSelectedDays(d)}
        isOpen={showSelectDaysModal}
        closeModal={() => setShowSelectDaysModal(false)}
      />
      <Dialog
        isOpen={Boolean(jobFunctionForRolesShiftsModal)}
        canOutsideClickClose
        onClose={() => setJobFunctionForRolesShiftsModal(null)}
        className="roles-shift-modal"
      >
        <Box className="modal-header">
          <IconButton
            size="small"
            className="modal-back-button"
            onClick={() => setJobFunctionForRolesShiftsModal(null)}
          >
            <Icon icon="cross" iconSize={8} />
          </IconButton>
          Schedule
        </Box>
        <Box className="modal-body">{renderShiftsModalContents()}</Box>
      </Dialog>
    </Box>
  );
};

export default ShiftStudio;
