import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import GoogleIcon from "../../../assets/calendar/goole-icon.png";
import OutlookIcon from "../../../assets/calendar/outlook-icon.png";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  showSuccessModal: () => void;
}
const AddAccountModal = ({ isOpen, closeModal, showSuccessModal }: Props) => {
  return (
    <UIComponents.Modal
      portalClassName={"z-[100]"}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className={"font-lato-bold text-2xl text-black"}>
        Please link your work email account
      </div>
      <div
        onClick={showSuccessModal}
        className={
          "p-8 relative text-center rounded-2xl mt-9 font-lato-bold text-xl text-black cursor-pointer"
        }
        style={{
          boxShadow:
            "1px 1px 6px rgba(0, 0, 0, 0.03), 4px 6px 30px rgba(0, 0, 0, 0.12)",
        }}
      >
        <img src={OutlookIcon} className={"absolute top-[25px] left-8"} />
        Link Outlook email
        <UIComponents.Icon
          name={"arrow-right"}
          className={"absolute right-8 top-[35px]"}
          size={"24"}
        />
      </div>
      <div
        className={
          "p-8 relative text-center rounded-2xl mt-4 font-lato-bold text-xl text-black cursor-pointer"
        }
        style={{
          boxShadow:
            "1px 1px 6px rgba(0, 0, 0, 0.03), 4px 6px 30px rgba(0, 0, 0, 0.12)",
        }}
      >
        <img src={GoogleIcon} className={"absolute top-[25px] left-8"} />
        Link Google email
        <UIComponents.Icon
          name={"arrow-right"}
          className={"absolute right-8 top-[35px]"}
          size={"24"}
        />
      </div>
    </UIComponents.Modal>
  );
};
export default AddAccountModal;
