import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "components/Table/Table";
import { OptionType, TableError } from "components/Table/tableTypes";

import { performSoftDelete, saveData } from "models/printers";

import { FirebasePrintersDoc, PrintersDoc } from "types/printers";
import { FirebaseServiceAreaDoc } from "types/serviceArea";

import { RootState } from "model/store";

const PrinterSetup = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const printers = useSelector((state: RootState) => state.printers);
  const serviceAreas = useSelector((state: RootState) => state.serviceAreas);

  // If I keep product types in a useState and specify a type for it, it doens't render any of the options. Possible bug in the Table?
  const uniqueProductTypes = _.uniq(
    useSelector((state: RootState) => state.products).map(
      (product) => product.type
    )
  );
  const productTypes = [...uniqueProductTypes, "all"];

  const [data, setData] = useState<PrintersDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<PrintersDoc[]>([]);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "name",
    errors: [],
  });

  const orderTypes = ["dineIn", "carryOut", "curbeside", "delivery"];

  const ticketTypes = ["Service", "Customer"];

  const columnHeadingConversions = {
    name: "Printer Name",
    macAddress: "MAC Address",
    printerType: "Printer Type",
    routedSubTypes: "Routed Sub-Types",
    routedOrderTypes: "Routed Order Types",
    ticketType: "Ticket Type",
    serviceAreas: "Service Areas",
    autoPrint: "Auto-Print",
    enabled: "Enabled",
  };

  const tableSchema = [
    { key: "name", type: "value", data: "text" },
    { key: "macAddress", type: "value", data: "text" },
    { key: "printerType", type: "value", data: "text" },
    { key: "routedSubTypes", type: "arrayOfValue", data: "text" },
    { key: "routedOrderTypes", type: "arrayOfValue", data: "text" },
    { key: "ticketType", type: "arrayOfValue", data: "text" },
    { key: "serviceAreas", type: "arrayOfValue", data: "text" },
    { key: "autoPrint", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
  ];

  const dataSchema = [
    ...tableSchema,
    { key: "id", type: "value", data: "text" },
  ];

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "routedSubTypes",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productTypes.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "routedOrderTypes",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: [...orderTypes, "all"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "ticketType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ticketTypes.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "serviceAreas",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: serviceAreas
          .map((area) => area.serviceAreaName)
          .map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "autoPrint",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const filterData = (data: PrintersDoc[]) =>
    data.map((row) => ({
      name: row.name,
      macAddress: row.macAddress,
      printerType: row.printerType,
      routedSubTypes: row.routedSubTypes,
      routedOrderTypes: row.routedOrderTypes,
      ticketType: row.ticketType,
      serviceAreas: row.serviceAreas,
      autoPrint: row.autoPrint,
      enabled: row.enabled,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      name: row.name,
      index,
    }));

    // Filter for duplicates
    const productDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({ name: row.name })),
          { name: item.name }
        ) !== index
    );

    // Find the duplicate without the id
    const errors: DocumentData[] = [];
    for (let i = 0; i < productDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) => row.name === productDuplicateErrors[i].name
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !(row.enabled === "Yes" || row.enabled === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong autoPrint
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !(row.autoPrint === "Yes" || row.autoPrint === "No")
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "autoPrint", isEdited: false }],
          isEdited: false,
        })),
      // Wrong routed sub type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.routedSubTypes || row.routedSubTypes.length > 0
            ? row.routedSubTypes.filter(
                (subType: string) => !productTypes.includes(subType)
              ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedSubTypes", isEdited: false }],
          isEdited: false,
        })),
      // Wrong routed order type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.routedOrderTypes || row.routedOrderTypes.length > 0
            ? row.routedOrderTypes.filter(
                (orderType: string) =>
                  ![...orderTypes, "all"].includes(orderType)
              ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedOrderTypes", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No printer name
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.name || row.name.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "name", isEdited: false }],
          isEdited: false,
        })),
      // No printer type
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.printerType || row.printerType.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "printerType", isEdited: false }],
          isEdited: false,
        })),
      // No MAC address
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.macAddress || row.macAddress.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "macAddress", isEdited: false }],
          isEdited: false,
        })),
      // No routed subtypes
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.routedSubTypes || row.routedSubTypes.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedSubTypes", isEdited: false }],
          isEdited: false,
        })),
      // No routed ordertypes
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !row.routedOrderTypes || row.routedOrderTypes.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "routedOrderTypes", isEdited: false }],
          isEdited: false,
        })),
      // No ticket types
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.ticketType || row.ticketType.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "ticketType", isEdited: false }],
          isEdited: false,
        })),
      // No service areas
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.serviceAreas || row.serviceAreas.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "serviceAreas", isEdited: false }],
          isEdited: false,
        })),
      // No auto print
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.autoPrint || row.autoPrint.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "autoPrint", isEdited: false }],
          isEdited: false,
        })),
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.enabled || row.enabled.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const saveToFirebase = async () => {
    if (business.id) {
      await performSoftDelete(softDeleteData);
      await saveData(
        data,
        transformFirebasePrintersToPrinters(printers),
        business.id
      );
      return true;
    }
  };

  const transformFirebasePrintersToPrinters = (
    printers: FirebasePrintersDoc[]
  ): PrintersDoc[] => {
    return printers.map((doc) => {
      return {
        id: doc.id,
        name: doc.name,
        macAddress: doc.macAddress,
        printerType: doc.printerType,
        routedSubTypes: doc.allowAllRoutedSubTypes
          ? ["all"]
          : doc.routedSubTypes,
        routedOrderTypes: doc.allowAllRoutedOrderTypes
          ? ["all"]
          : doc.routedOrderTypes,
        ticketType: doc.ticketType,
        serviceAreas: doc.serviceAreas,
        autoPrint: doc.autoPrint ? "Yes" : "No",
        enabled: doc.enabled ? "Yes" : "No",
      };
    });
  };

  useEffect(() => {
    if (printers.length > 0 && business) {
      const allPrinters = transformFirebasePrintersToPrinters(printers);
      setData(allPrinters);
    }
  }, [printers, business]);

  // useEffect(() => {
  //   if (products.length > 0) {
  //     const uniqueProductTypes = products.map((product) => product.type);
  //     setProductTypes([...uniqueProductTypes, 'all']);
  //   }
  // }, [products]);

  if (business && productTypes.length > 0) {
    console.log("Unique Product Types: ", productTypes);
    return (
      <div id="table-display">
        <Table
          // @ts-ignore
          businessId={business.id}
          name="Printers"
          parentName="Devices"
          allData={data}
          setAllData={setData}
          softDelete={softDeleteData}
          setSoftDelete={setSoftDeleteData}
          allowSoftDelete
          filterData={filterData}
          tableSchema={tableSchema}
          dataSchema={dataSchema}
          options={options}
          setOptions={setOptions}
          hasModal={false}
          ModalComponent={null}
          errorManager={errorManager}
          setErrorManager={setErrorManager}
          closeErrorManager={() => {
            setErrorManager({
              ...errorManager,
              showErrorModal: false,
            });
          }}
          verifySave={verifyData}
          saveToFirebase={saveToFirebase}
          hasSearch={true}
          searchColumnName="name"
          hasFilter={false}
          columnHeadingConversions={columnHeadingConversions}
          showSearchName={false}
        />
      </div>
    );
  }
  return null;
};

export default PrinterSetup;
