import tangoComponents from "@tangopay/tango-ui-library";
import React, { useCallback, useMemo } from "react";

import { RawItemDTO } from "controllers/inventoryItemInfo";

import CurrencyInput from "components/Inputs/CurrencyInput";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";

const { Dropdown } = tangoComponents;

export type SaleInfo = {
  uniqueId: string;

  name: string;
  isDefault: boolean;
  itemId: string;

  pricePerSaleUnit: number;
  saleUnitName: string;
  splitUnitsPerSaleUnit: number;
  splitUnitName: string;
  productionUnitsPerSplitUnit: number;
  productionUnitName: string;
};

type Instruction = RenderInstruction<SaleInfo>;

const instructions: { [attr: string]: Instruction } = {};

instructions.isDefault = {
  type: "boolean",
  useCheckbox: true,
};

type ViewPriceRatioProps = {
  price: number;
  unit: string;
};
type EditPriceRatioProps = ViewPriceRatioProps & {
  onChangePrice: (price: number) => unknown;
  onChangeUnit: (unit: string) => unknown;
};
type ViewUnitRatioProps = {
  count: number;
  unitOne: string;
  unitTwo: string;
};
type EditUnitRatioProps = ViewUnitRatioProps & {
  onChangeUnit: (unit: string) => unknown;
  onChangeCount: (count: number) => unknown;
};
type ViewUnitQuantityProps = {
  count: number;
  unitOne: string;
  unitTwo: string;
};
type EditUnitQuantityProps = ViewUnitQuantityProps & {
  onChangeCount: (count: number) => unknown;
};
export const EditPriceRatio = (props: EditPriceRatioProps) => {
  const handleUnitChange = useCallback(
    (e) => props.onChangeUnit(e.target.value),
    [props.onChangeUnit]
  );
  return (
    <div>
      <CurrencyInput value={props.price} onValueChange={props.onChangePrice} />
      /
      <input
        type="text"
        value={props.unit}
        onChange={handleUnitChange}
        className={"border-0 text-grey-3"}
      />
    </div>
  );
};
export const ViewPriceRatio = (props: ViewPriceRatioProps) => {
  return (
    <div>
      ${(props.price / 100).toLocaleString()} / {props.unit}
    </div>
  );
};

// ratio where the numerator unit is adjustable
export const EditUnitRatio = (props: EditUnitRatioProps) => {
  const handleCountChange = useCallback(
    (e) => props.onChangeCount(Number(e.target.value)),
    [props.onChangeCount]
  );
  const handleUnitChange = useCallback(
    (e) => props.onChangeUnit(e.target.value),
    [props.onChangeUnit]
  );
  return (
    <div>
      <input
        type="number"
        value={props.count.toString()}
        onChange={handleCountChange}
        className={"border-0 text-grey-3"}
      />
      <input
        type="text"
        value={props.unitOne}
        onChange={handleUnitChange}
        className={"border-0 text-grey-3"}
      />
      {" / "}
      {props.unitTwo}
    </div>
  );
};
export const ViewUnitRatio = (props: ViewUnitRatioProps) => {
  return (
    <div>
      {props.count} {props.unitOne} / {props.unitTwo}
    </div>
  );
};
// ratio with no adjustable units
export const EditUnitQuantity = (props: EditUnitQuantityProps) => {
  const handleCountChange = useCallback(
    (e) => props.onChangeCount(Number(e.target.value)),
    [props.onChangeCount]
  );
  return (
    <div>
      <input
        type="number"
        value={props.count.toString()}
        onChange={handleCountChange}
        className={"border-0 text-grey-3"}
      />
      {props.unitOne}
      {" / "}
      {props.unitTwo}
    </div>
  );
};

// specific components
type ViewProps = {
  fullObject: SaleInfo;
};
type EditProps = {
  fullObject: SaleInfo;
  onChange?: (newValue: SaleInfo) => unknown;
};
const ViewPurchasePrice = ({ fullObject }: ViewProps) => {
  return (
    <ViewPriceRatio
      price={fullObject.pricePerSaleUnit}
      unit={fullObject.saleUnitName}
    />
  );
};

const EditPurchasePrice = ({ fullObject, onChange }: EditProps) => {
  const onChangePrice = useCallback(
    (pricePerSaleUnit) =>
      onChange?.({
        ...fullObject,
        pricePerSaleUnit,
      }),
    [fullObject]
  );
  const onChangeUnit = useCallback(
    (saleUnitName) =>
      onChange?.({
        ...fullObject,
        saleUnitName,
      }),
    [fullObject]
  );
  return (
    <EditPriceRatio
      price={fullObject.pricePerSaleUnit}
      unit={fullObject.saleUnitName}
      onChangePrice={onChangePrice}
      onChangeUnit={onChangeUnit}
    />
  );
};

const ViewSplitUnit = ({ fullObject }: ViewProps) => {
  return (
    <ViewUnitRatio
      unitOne={fullObject.splitUnitName}
      count={fullObject.splitUnitsPerSaleUnit}
      unitTwo={fullObject.saleUnitName}
    />
  );
};

const EditSplitUnit = ({ fullObject, onChange }: EditProps) => {
  const onChangeCount = useCallback(
    (splitUnitsPerSaleUnit) =>
      onChange?.({
        ...fullObject,
        splitUnitsPerSaleUnit,
      }),
    [fullObject]
  );
  const onChangeUnit = useCallback(
    (splitUnitName) =>
      onChange?.({
        ...fullObject,
        splitUnitName,
      }),
    [fullObject]
  );
  return (
    <EditUnitRatio
      unitOne={fullObject.splitUnitName}
      count={fullObject.splitUnitsPerSaleUnit}
      unitTwo={fullObject.saleUnitName}
      onChangeCount={onChangeCount}
      onChangeUnit={onChangeUnit}
    />
  );
};

const ViewPakUnit = ({ fullObject }: ViewProps) => {
  return (
    <ViewUnitRatio
      unitOne={fullObject.productionUnitName}
      count={fullObject.productionUnitsPerSplitUnit}
      unitTwo={fullObject.splitUnitName}
    />
  );
};

const EditPakUnit = ({ fullObject, onChange }: EditProps) => {
  const onChangeCount = useCallback(
    (productionUnitsPerSplitUnit) =>
      onChange?.({
        ...fullObject,
        productionUnitsPerSplitUnit,
      }),
    [fullObject]
  );
  return (
    <EditUnitQuantity
      count={fullObject.productionUnitsPerSplitUnit}
      unitOne={fullObject.productionUnitName}
      unitTwo={fullObject.splitUnitName}
      onChangeCount={onChangeCount}
    />
  );
};

type ViewItemProps = ViewProps & {
  rawItems: RawItemDTO[];
};
type EditItemProps = EditProps & {
  rawItems: RawItemDTO[];
};

const ViewItem = ({ fullObject, rawItems }: ViewItemProps) => {
  const selected = fullObject.itemId;
  const sel = rawItems.find((opt) => opt.id == selected);
  return <div>{sel?.name ?? "Select an item"}</div>;
};

const EditItem = ({ fullObject, onChange, rawItems }: EditItemProps) => {
  const options = useMemo(
    () =>
      rawItems.map((i) => ({
        value: i.id,
        label: i.name,
      })),
    [rawItems]
  );
  const handleChange = useCallback(
    (item) => {
      const selected = rawItems.find((i) => i.id == item.value);
      if (!selected) {
        onChange?.({
          ...fullObject,
          productionUnitName: "unit",
          itemId: "",
        });
      } else {
        onChange?.({
          ...fullObject,
          productionUnitName: selected.productionUnitName,
          itemId: item.value,
        });
      }
    },
    [fullObject, onChange, rawItems]
  );
  return (
    <Dropdown
      options={options}
      value={options.find(({ value }) => value == fullObject.itemId)}
      onChange={handleChange}
      size="small"
    />
  );
};

instructions.purchasePrice = {
  type: "complex-custom",
  viewComponent: ViewPurchasePrice,
  editComponent: EditPurchasePrice,
};
instructions.splitUnit = {
  type: "complex-custom",
  viewComponent: ViewSplitUnit,
  editComponent: EditSplitUnit,
};
instructions.pakUnit = {
  type: "complex-custom",
  viewComponent: ViewPakUnit,
  editComponent: EditPakUnit,
};

export const instructionsFactory = (
  rawItems: RawItemDTO[]
): { [attr: string]: Instruction } => {
  return {
    ...instructions,
    item: {
      type: "complex-custom",
      viewComponent: ({ fullObject }) => (
        <ViewItem fullObject={fullObject} rawItems={rawItems} />
      ),
      editComponent: ({ fullObject, onChange }) => (
        <EditItem
          fullObject={fullObject}
          onChange={onChange}
          rawItems={rawItems}
        />
      ),
      readOnly: true,
      editablePrefix: "new__",
    },
  };
};

// export const RatioViewComponent = ({ fullObject }: ViewProps) => {
//     const ratio = fullObject?.toAmount / fullObject?.fromAmount;
//     return <>{ratio}</>;
// };
// export const RatioEditComponent = ({ fullObject, onChange }: EditProps) => {
//     const ratio = fullObject?.toAmount / fullObject?.fromAmount;
//     const handleChange = useCallback(
//         (e) => {
//             const newRatio = e.target.value;
//             const newObject = { ...fullObject };
//             newObject.toAmount = newObject.fromAmount * newRatio;
//             if (onChange) onChange(newObject);
//         },
//         [fullObject, onChange]
//     );

//     return (
//         <input
//             type="number"
//             value={ratio.toString()}
//             onChange={handleChange}
//             className={"border-0 text-grey-3"}
//         />
//     );
// };
