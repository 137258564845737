import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

import Layout from "../../Layout";
import "../style.css";

const chartSeries = [
  {
    name: "Selected Day",
    data: [
      0, 0, 0, 0, 0, 0, 1, 4, 50, 60, 70, 80, 90, 120, 180, 190, 200, 210, 220,
      230, 300, 310, 320, 330,
    ],
  },
  {
    name: "Yesterday",
    data: [
      8, 30, 40, 35, 45, 50, 65, 55, 100, 70, 50, 30, 50, 40, 30, 70, 80, 90,
      60, 30, 70, 65, 30, 20,
    ],
  },
];

const chartOptions: ApexOptions = {
  chart: {
    id: "basic-bar",
    dropShadow: {
      enabled: true,
      color: "#fff",
      top: 5,
      left: 0,
      blur: 10,
      opacity: 0.5,
    },
    toolbar: {
      show: false,
    },
  },
  grid: {
    borderColor: "#EDEFF1",
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    labels: {
      formatter: (value) => `$${value}`,
    },
  },
  xaxis: {
    categories: [
      "4:00 am",
      "5:00 am",
      "6:00 am",
      "7:00 am",
      "8:00 am",
      "9:00 am",
      "10:00 am",
      "11:00 am",
      "12:00 pm",
      "1:00 pm",
      "2:00 pm",
      "3:00 pm",
      "4:00 pm",
      "5:00 pm",
      "6:00 pm",
      "7:00 pm",
      "8:00 pm",
      "9:00 pm",
      "10:00 pm",
      "11:00 pm",
      "12:00 am",
      "1:00 am",
      "2:00 am",
      "3:00 am",
      "3:59 am",
    ],
    labels: {
      format: "h:mm tt",
      datetimeFormatter: {
        hour: "h",
        minute: "mm",
      },
      rotate: 360,
      offsetY: 20,
      offsetX: 5,
    },
  },
  stroke: {
    width: 3,
    colors: ["#5E55C8", "#C2C9D1"],
  },
  legend: {
    show: false,
  },
  colors: ["#5E55C8", "#C2C9D1"],
};
const LaborPayroll = () => (
  <Layout>
    <div id="labor-payroll">
      <div className="navigation">
        <div className="navigation-title">Report</div>
        <div className="navigation-subtitle">Select to view below</div>
        <Link to="/reporting">
          <div className="button">Sales Summary</div>
        </Link>
        <Link to="/product-analytics">
          <div className="button">Product Analytics</div>
        </Link>
        <Link to="/bank-deposit">
          <div className="button">Bank Deposit Report</div>
        </Link>
        {/*
          <Link to="/cash-summary">
            <div  className="button">Cash Summary</div>
          </Link>
          <Link to="/labor-payroll">
            <div  className="button active">Labor/Payroll</div>
          </Link>
          <div className="button">Inventory  Reports</div>
          <div className="button" >Customer  Reports</div>
          <div className="button">Transaction  Reports</div>
          */}
      </div>
      <div className="right-section">
        <div className="body">
          <div className="page-header">
            <div className="page-title">Labor/Payroll Analytics</div>
          </div>
          {/* chart starts */}
          <div className="chart-container">
            <div className="chart-body">
              <div className="chart">
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div className="stats-container">
                <div className="stat-container">
                  <div className="stat-title">Sales Revenue</div>
                  <div className="stat-figure">$763.40</div>
                </div>
                <div className="stat-container">
                  <div className="stat-title">Labor cost</div>
                  <div className="stat-figure">$562.20</div>
                </div>
              </div>
            </div>
          </div>
          {/* chart ends */}
          {/* table starts */}
          <div className="table-title">Staff Breakdown</div>
          <table>
            <tr>
              <th>EMPLOYEE</th>
              <th>ROLE</th>
              <th>HOURS</th>
              <th>ESTIMATED WAGES</th>
              <th>ALLOCATED</th>
              <th>CASH PAYOUT</th>
              <th>ESTIMATED TOTAL</th>
              <th>EFFECTIVE RATE (p/h)</th>
            </tr>
            {[1, 2, 3, 4, 5].map(() => (
              <tr>
                <td>Jen Wyatt</td>
                <td>Manager</td>
                <td>36</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$1,151.10</td>
                <td>$25</td>
              </tr>
            ))}
          </table>
          {/* table ends */}
          {/* reports boxes starts */}
          <div className="table-title">Reports</div>
          <div className="report-boxes">
            <Link to="/daily-labor-report">
              <div className="report-box">
                <div className="report-box-header">
                  <img
                    src={require("../../../../assets/24-hour.svg").default}
                  />
                  <div className="report-title">Daily Labor Reports</div>
                </div>
                <div className="report-description">
                  Exportable reports breaking down labor costs by day.
                </div>
              </div>
            </Link>
            <Link to="/pay-period-labor-report">
              <div className="report-box">
                <div className="report-box-header">
                  <img
                    src={require("../../../../assets/calendar.svg").default}
                  />
                  <div className="report-title">Pay Period Labor Reports</div>
                </div>
                <div className="report-description">
                  Exportable reports breaking down labor costs for the duration
                  of a given pay period.
                </div>
              </div>
            </Link>
          </div>
          {/* reports boxes ends */}
        </div>
      </div>
    </div>
  </Layout>
);
export default LaborPayroll;
