import React from "react";

const Sidebar = () => {
  return (
    <div>
      <ul className={"p-0 m-0 list-none"}>
        <li>
          <div
            className={
              "font-lato-medium text-grey-3 text-xs py-2 px-5 hover:bg-blue-grey-2 cursor-pointer hover:text-black"
            }
          >
            Reply Templates
          </div>
        </li>


        {/* maybe could have group templates by subject and all them to filter */}

        {/* <li>
          <div
            className={
              "font-lato-medium text-grey-3 text-xs py-2 px-5 hover:bg-blue-grey-2 cursor-pointer hover:text-black"
            }
          >
            SMS Templates
          </div>
        </li> */}

      </ul>
    </div>
  );
};
export default Sidebar;
