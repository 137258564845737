import { useEffect, useState } from "react";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { TangoDispute, useDisputes } from "controllers/disputes";
import { useDiscountedPayments, useDiscountedPaymentsBetweenDates } from "controllers/discountedPayments";
import { TangoTab } from "controllers/payments";
import { CompletedCheckStaff } from "../ClosedChecks/useClosedChecks";
import moment from "moment";

export type DiscountedPaymentProps = {
    uniqueId: string;
    date: any;
    checkNumber: string | number;
    discountName: string;
    serverName: string;
    customer: string;
    itemName: string;
    itemQuantity: number;
    price: string | number;
    discountedPrice: string | number;
    finalPrice: string | number;
    tab: TangoTab,
    staffMember: CompletedCheckStaff | null;
    paginateTabId: string
};

const columns: ColumnInstruction<DiscountedPaymentProps>[] = [
    { type: "projection", header: "Date", attribute: "date" },
    { type: "data", header: "Check Number", attribute: "checkNumber" },
    { type: "data", header: "Discount Name", attribute: "discountName" },
    { type: "data", header: "Server Name", attribute: "serverName" },
    { type: "data", header: "Customer", attribute: "customer" },
    { type: "data", header: "Item Name", attribute: "itemName" },
    { type: "data", header: "Item Quantity", attribute: "itemQuantity" },
    { type: "data", header: "Original Price", attribute: "price" },
    { type: "data", header: "Discounted Price", attribute: "discountedPrice" },
    { type: "data", header: "Final Price", attribute: "finalPrice" },
];

const instructions: { [x: string]: RenderInstruction<DiscountedPaymentProps> } = {};

instructions.date = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        return <div>
            {moment(new Date(fullObject.date._seconds * 1000)).format("MM/DD/YYYY")}
        </div>
    },
    editComponent: null
}

instructions.amount = {
    type: "currency",
}


const useDiscountsScreen = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const currency: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.currency;
    const [loading, setLoading] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<any>('')
    const [endDate, setEndDate] = useState<any>('')
    const [isEditing, setEditing] = useState(false);
    const [customDatePicker, setShowCustomDatePicker] = useState<boolean>(false)
    const [chunkedDiscounts, setChunkedDiscounts] = useState<any>([])
    const [discountsReport, setDiscountsReport] = useState<{
        value: "Default",
        label: "Default"
    } | any>(null);

    const [lastTabId, setLastTabId] = useState('start')
    const [previousPageTabIds, setPreviousPageTabIds] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - ' + 20)

    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);

    const query = startDate && endDate && startDate < endDate ? useDiscountedPaymentsBetweenDates(businessId, startDate, endDate) : useDiscountedPayments(businessId, lastTabId);
    const discountedPaymentsData = query.data ?? [];
    const data: DiscountedPaymentProps[] = []
    discountedPaymentsData.length > 0 && discountedPaymentsData.forEach((discountedPayment: any) => {
        data.push({
            uniqueId: discountedPayment.product.productOrderId,
            date: discountedPayment.tab.createdAt,
            checkNumber: discountedPayment.tab.tabNumber,
            discountName: discountedPayment.discountApplied.discountName,
            serverName: discountedPayment.staffMember ? discountedPayment.staffMember.contact.firstName + ' ' + discountedPayment.staffMember.contact.lastName : '',
            customer: discountedPayment.customer && discountedPayment.customer.firstName ? discountedPayment.customer.firstName + ' ' + discountedPayment.customer.lastName : "Guest",
            itemName: discountedPayment.product.name,
            itemQuantity: discountedPayment.product.quantity,
            price: '$' + (discountedPayment.product.price * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            discountedPrice: '$' + (discountedPayment.discountApplied.discountTotal * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            finalPrice: '$' + (discountedPayment.product.discountedPrice * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            tab: discountedPayment.tab,
            staffMember: discountedPayment.staffMember,
            paginateTabId: discountedPayment.tab.id
        })
    })

    return {
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        setStartDate,
        startDate,
        setEndDate,
        endDate,
        setDiscountsReport,
        discountsReport,
        setLastTabId,
        setShowCustomDatePicker,
        customDatePicker,
        setChunkedDiscounts,
        chunkedDiscounts,
        setPreviousPageTabIds,
        previousPageTabIds,
        setPageNumber,
        pageNumber,
        setDisplayCounterMessage,
        displayCounterMessage,
        setLoading,
        loading

    };
};
export default useDiscountsScreen;
