import { AccountAction } from "model/types";

import ActionType from "./actionTypes";

export function receiveAccount(account: TangoAccount): AccountAction {
  return {
    type: ActionType.RECEIVE_ACCOUNT,
    account,
  };
}
