import React from "react";

interface Props {
  onChange: () => void;
  color: string;
}
const ShortText = ({ onChange, color }: Props) => {
  return (
    <input
      style={{ color, borderColor: color + "8C", borderWidth: "0.5px" }}
      placeholder="Type answer here"
      className="short-text-component apply-font"
      onChange={onChange}
      disabled
    />
  );
};

export default ShortText;
