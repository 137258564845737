import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import { checkForMissingSettings } from "../../../models/businesses";
import Billing from "../Billing";
import Layout from "../Layout";
import Channels from "./Channels";
import Preferences from "./Preferences";
import "./style.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}
type SelectedTabType = "preferences" | "channels-taxes" | "billing";

const Settings: FC<Props> = () => {
  const [selectedTab, setSelectedTab] =
    useState<SelectedTabType>("preferences");
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    if (business && businessSettings) {
      checkForMissingSettings(business.id, businessSettings);
    }
  }, [business, businessSettings]);

  if (business && businessSettings) {
    return (
      <Layout>
        <div className="settings-container">
          <div className="buttons-container">
            <div
              className={`button ${
                selectedTab === "preferences" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("preferences")}
            >
              Tango Preferences
            </div>
            <div
              className={`button ${
                selectedTab === "channels-taxes" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("channels-taxes")}
            >
              Channels & Taxes
            </div>
            <div
              className={`button ${selectedTab === "billing" ? "active" : ""}`}
              onClick={() => setSelectedTab("billing")}
            >
              Billing
            </div>
          </div>
          {selectedTab === "preferences" && <Preferences />}
          {selectedTab === "billing" && <Billing />}
          {selectedTab === "channels-taxes" && <Channels />}
        </div>
      </Layout>
    );
  }
  return null;
};

export default Settings;
