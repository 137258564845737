import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  useCreateMeasures,
  useInventoryInfo,
  useUpdateMeasure,
} from "controllers/inventoryInfo";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";

type MeasurementProps = {
  uniqueId: string;
  name: string;
  shortName: string;
  type: "Unit" | "Volume" | "Weight";
  description: string;
};

const columns: ColumnInstruction<MeasurementProps>[] = [
  { type: "data", header: "Measure", attribute: "name" },
  { type: "data", header: "Short Name", attribute: "shortName" },
  { type: "data", header: "Type", attribute: "type" },
  { type: "data", header: "Description", attribute: "description" },
];

const instructions: { [x: string]: RenderInstruction<MeasurementProps> } = {};

instructions.type = {
  type: "select",
  options: [
    {
      value: "Weight",
      label: "Weight",
    },
    {
      value: "Unit",
      label: "Unit",
    },
    {
      value: "Volume",
      label: "Volume",
    },
  ],
  placeholder: "select a type",
};

const prefix = "new__measurement__";
const deleteIds = (args: string[]) => {
  console.log(
    "I would like to delete the following ids from the measures list: ",
    args
  );
};
const useMeasurements = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditing, setEditing] = useState(false);
  // call update with false to push, true to clear
  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );
  useEffect(() => {
    if (!isEditing) updateItems(true);
  }, [isEditing]);
  const add = useCallback(() => {
    setEditing(true);
    updateItems(false);
  }, []);
  const query = useInventoryInfo(businessId);
  const create = useCreateMeasures(businessId);
  const update = useUpdateMeasure(businessId);
  const dataFromServer = query.data?.units ?? [];
  const dataWithAdditions = useMemo(() => {
    const adding = addedIds.map(
      (uniqueId: string) =>
        ({
          uniqueId,
          name: "",
          shortName: "",
          type: "Unit",
          description: "",
        } as MeasurementProps)
    );
    const curr = dataFromServer.map(
      (data) =>
        ({
          uniqueId: data.id,
          name: data.name,
          shortName: data.shortName,
          type: data.type,
          description: data.description,
        } as MeasurementProps)
    );
    return [...adding, ...curr];
  }, [addedIds, dataFromServer]);

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const toCreate = addedIds.map((id) => {
        const updates = instructions[id];
        return {
          name: (updates?.name?.newValue ?? "") as string,
          shortName: (updates?.shortName?.newValue ?? "") as string,
          type: (updates?.type?.newValue ?? "Unit") as string,
          description: (updates?.description?.newValue ?? "") as string,
        };
      });
      return Promise.all([
        ...Object.entries(instructions)
          .filter(([id]) => !id.startsWith(prefix))
          .map(([unitId, newValue]) => {
            const updateDTO = Object.keys(newValue).reduce(
              (acc, key) => ({
                ...acc,
                [key]: newValue[key].newValue,
              }),
              {}
            );
            update.mutate({ unitId, newValue: updateDTO });
          }),
        create.mutate(toCreate),
      ]);
    },
    [addedIds, create]
  );

  return {
    measurementsData: dataWithAdditions,
    addItem: add,
    deleteIds,
    saveChanges,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default useMeasurements;
