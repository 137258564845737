import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { UnitQuantityDTO } from "controllers/inventoryItemInfo";
import { locationInstruction, maxParInstruction, minParInstruction, primaryGroupInstruction, rawIngredientCostInstruction, rawIngredientInstruction, rawIngredientUnitInstruction, recipeWarningInstruction, secondaryGroupInstruction, shelfLifeInstruction, stockInstruction, yieldInstruction } from "../tableCells";

export type PrepItem = {
    uniqueId: string;
    name: string;
    primaryGroup: string | null;
    secondaryGroup: string | null;
    ingredientUnitName: string;
    productionUnitName: string;
    conversion: number;
    location: string | null;
    isKey: boolean;
    isActualized: boolean;
    inStock: boolean;
    shelfLifeHours: number;
    shelfLifeUnits: "hours" | "days" | "weeks";
    yield: number;
    minPar: number;
    maxPar: number;
    theoreticalStock: UnitQuantityDTO[];
    recipe: string;
};

export type Ingredient = {
    amount: number;
    uniqueId: string;
    item: string | null;
};

export const detailColumns: ColumnInstruction<PrepItem>[] = [
    { type: "data", header: "Item Name", attribute: "name" },
    { type: "data", header: "Primary Group", attribute: "primaryGroup" },
    { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
    { type: "data", header: "Ingredient Unit", attribute: "ingredientUnitName" },
    { type: "data", header: "Production Unit", attribute: "productionUnitName" },
    { type: "data", header: "Conversion", attribute: "conversion" },
    { type: "data", header: "Location", attribute: "location" },
    { type: "data", header: "Key Item?", attribute: "isKey" },
    { type: "data", header: "Actualize Usage Values?", attribute: "isActualized" },
    { type: "data", header: "Shelf Life", attribute: "shelfLifeHours" },
    { type: "data", header: "Yield", attribute: "yield" },
    // { type: "data", header: "Prep time", attribute: "prepTime" },
    { type: "data", header: "Minimum Par", attribute: "minPar" },
    { type: "projection", header: "Current Stock", attribute: "stock" },
    { type: "data", header: "Maximum Par", attribute: "maxPar" },
];

export const detailInstructions: { [x: string]: RenderInstruction<PrepItem> } = {
    primaryGroup: primaryGroupInstruction,
    secondaryGroup: secondaryGroupInstruction,
    shelfLifeHours: shelfLifeInstruction,
    location: locationInstruction,
    isKey: {
        type: "boolean",
    },
    inStock: {
        type: "boolean",
    },
    isActualized: {
        type: "boolean",
    },
    yield: yieldInstruction,
    minPar: minParInstruction,
    maxPar: maxParInstruction,
    stock: stockInstruction,
};

export const ingredientColumns: ColumnInstruction<Ingredient>[] = [
    {type: "data", header: "Ingredient", attribute: "item"},
    {type: "data", header: "Amount", attribute: "amount"},
    {type: "projection", header: "Unit", attribute: "unitName"},
    {type: "projection", header: "Cost", attribute: "cost"},
];

export const ingredientInstructions: { [x: string]: RenderInstruction<Ingredient> } = {
    item: rawIngredientInstruction,
    amount: {
        type: "number",
    },
    unitName: rawIngredientUnitInstruction,
    cost: rawIngredientCostInstruction,
};