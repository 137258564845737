import { ChannelAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function channels(state = initialState, action: ChannelAction) {
  switch (action.type) {
    case ActionType.RECEIVE_CHANNELS:
      return action.channels;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
