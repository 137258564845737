import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import firebase from "../../../config/firebase";
import { RootState } from "../../../model/store";
import { saveFile } from "../../../utils/storage";
import { ComponentTypeProp } from "../data";
import { ComponentType, EndingSectionProps, Question } from "../types";

const useQuestions = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
    null
  );
  const [uploadingIndex, setUploadingIndex] = useState<number | null>(null);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [showSaveAsDraftModal, setShowSaveAsDraftModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const createNewQuestion = (type: ComponentType, endingSections: EndingSectionProps[]) => {
    const clonedQuestions = [...questions];
    const newQuestion = {
      id: endingSections.length + questions.length + 1,
      type,
      title: "",
      subtitle: "",
      values: [],
      required: false,
      hasAnswer: false,
      images: [],
      answer: null,
      points: 0,
      rules: [
        {
          origin: questions.length + 1,
          operationLogic: "jump-to",
          destination: "",
          default: true,
        },
      ],
    };
    clonedQuestions.push(newQuestion);
    setQuestions(clonedQuestions);
    setSelectedQuestion(newQuestion);
  };
  const deleteOption = (index: number) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      clonedSelectedQuestion["values"].splice(index, 1);
      setSelectedQuestion(clonedSelectedQuestion);
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...clonedSelectedQuestion };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
    }
  };
  const deleteMatchingOption = (index: number) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      clonedSelectedQuestion["values"]["leftArray"].splice(index, 1);
      clonedSelectedQuestion["values"]["rightArray"].splice(index, 1);
      setSelectedQuestion(clonedSelectedQuestion);
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...clonedSelectedQuestion };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
    }
  };

  const handleFileChange = async (
    image: string,
    index: number,
    fileName: string
  ) => {
    if (selectedQuestion) {
      try {
        setUploadingIndex(index);
        let fileUrl = "";
        if (image && !image.startsWith("https://")) {
          fileUrl = await saveFile(
            `businesses/${business.id}/forms/` + fileName,
            image
          );
        }
        const clonedSelectedQuestion = { ...selectedQuestion };
        clonedSelectedQuestion.images[index] = fileUrl;
        setSelectedQuestion(clonedSelectedQuestion);
        const updatedValues = [...questions].map((item) => {
          if (item.id === selectedQuestion.id) {
            return { ...clonedSelectedQuestion };
          } else {
            return item;
          }
        });
        setQuestions(updatedValues);
        setUploadingIndex(null);
      } catch (error) {
        // @ts-ignore
        const err: firebase.firestore.FirestoreError = error;
        return { error: err.message };
      }
    }
  };

  const updateDropdownOptions = (options: string[]) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      const existingQuestionIndex = questions.findIndex(
        (item) => item.id === selectedQuestion.id
      );
      const clonedQuestions = [...questions];
      setSelectedQuestion({
        ...clonedSelectedQuestion,
        values: options,
      });
      clonedQuestions[existingQuestionIndex]["values"] = options;
      setQuestions(clonedQuestions);
    }
  };

  const updateMatchingChoice = (value: string, type: string, index: number) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      clonedSelectedQuestion["values"][type][index] = value;
      setSelectedQuestion(clonedSelectedQuestion);
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...clonedSelectedQuestion };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
    }
  };

  const addMatchingChoice = () => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      if (
        selectedQuestion.values.leftArray &&
        selectedQuestion.values.rightArray
      ) {
        clonedSelectedQuestion.values.leftArray.push("");
        clonedSelectedQuestion.values.rightArray.push("");
        const updatedValues = [...questions].map((item) => {
          if (item.id === selectedQuestion.id) {
            return { ...clonedSelectedQuestion };
          } else {
            return item;
          }
        });
        setSelectedQuestion(clonedSelectedQuestion);
        setQuestions(updatedValues);
      } else {
        const newChoice = { leftArray: [""], rightArray: [""] };
        const updatedValues = [...questions].map((item) => {
          if (item.id === selectedQuestion.id) {
            return { ...item, values: newChoice };
          } else {
            return item;
          }
        });
        setQuestions(updatedValues);
        setSelectedQuestion({
          ...selectedQuestion,
          values: newChoice,
        });
      }
    }
  };

  const addFillInTheBlankChoice = () => {
    if (selectedQuestion) {
      const newChoice = ["", "", ""];
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...item, values: [...item.values, { data: newChoice }] };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
      setSelectedQuestion({
        ...selectedQuestion,
        values: [...selectedQuestion.values, { data: newChoice }],
      });
    }
  };

  const updateFillInTheBlankChoice = (
    value: any,
    optionIndex: number,
    choiceIndex: number
  ) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      const existingQuestionIndex = questions.findIndex(
        (item) => item.id === selectedQuestion.id
      );
      const clonedQuestions = [...questions];
      const clonedChoicesArray = [
        ...clonedSelectedQuestion["values"][optionIndex]["data"],
      ].map((item, index) => {
        if (index === choiceIndex) {
          return value;
        } else {
          return item;
        }
      });
      clonedSelectedQuestion["values"][optionIndex]["data"] =
        clonedChoicesArray;
      clonedQuestions[existingQuestionIndex] = { ...clonedSelectedQuestion };
      setSelectedQuestion(clonedSelectedQuestion);
      setQuestions(clonedQuestions);
    }
  };

  const updateMultipleChoice = (value: string, index: number) => {
    if (selectedQuestion) {
      const clonedSelectedQuestion = { ...selectedQuestion };
      const existingQuestionIndex = questions.findIndex(
        (item) => item.id === selectedQuestion.id
      );

      const clonedQuestions = [...questions];
      const updatedChoices = clonedQuestions[existingQuestionIndex][
        "values"
      ].map((item: any, choiceIndex: number) => {
        if (choiceIndex === index) {
          item = value;
          return item;
        }
        return item;
      });
      clonedQuestions[existingQuestionIndex]["values"] = updatedChoices;
      clonedSelectedQuestion["values"] = updatedChoices;
      setSelectedQuestion(clonedSelectedQuestion);
      setQuestions(clonedQuestions);
    }
  };

  const addMultipleChoiceOption = () => {
    if (selectedQuestion) {
      const updatedValues = [...questions].map((item) => {
        if (item.id === selectedQuestion.id) {
          return { ...item, values: [...item.values, ""] };
        } else {
          return item;
        }
      });
      setQuestions(updatedValues);
      setSelectedQuestion({
        ...selectedQuestion,
        values: [...selectedQuestion.values, ""],
      });
    }
  };

  const updateQuestion = (type: string, value: string | any[] | number) => {
    if (selectedQuestion) {
      if (type === "title") {
        const updatedQuestions = [...questions].map((question) => {
          if (question.id === selectedQuestion.id) {
            return { ...question, title: value as string };
          } else {
            return { ...question };
          }
        });
        setQuestions(updatedQuestions);
        setSelectedQuestion({ ...selectedQuestion, title: value as string });
      }
      if (type === "subtitle") {
        const updatedQuestions = [...questions].map((question) => {
          if (question.id === selectedQuestion.id) {
            return { ...question, subtitle: value as string };
          } else {
            return { ...question };
          }
        });
        setQuestions(updatedQuestions);
        setSelectedQuestion({ ...selectedQuestion, subtitle: value as string });
      }
      if (type === "required" || type === "hasAnswer") {
        const updatedQuestions = [...questions].map((question) => {
          if (question.id === selectedQuestion.id) {
            return { ...question, [type]: !selectedQuestion[type] };
          } else {
            return { ...question };
          }
        });
        setQuestions(updatedQuestions);
        setSelectedQuestion({
          ...selectedQuestion,
          [type]: !selectedQuestion[type],
        });
      }
      if (type === "answer" || type === "points") {
        const updatedQuestions = [...questions].map((question) => {
          if (question.id === selectedQuestion.id) {
            return { ...question, [type]: value };
          } else {
            return { ...question };
          }
        });
        setQuestions(updatedQuestions);
        setSelectedQuestion({
          ...selectedQuestion,
          [type]: value,
        });
      }
    }
  };

  const deleteQuestion = () => {
    const clonedQuestions = [...questions];
    const questionIndex = clonedQuestions.findIndex(
      (question) => selectedQuestion?.id === question.id
    );
    if (questionIndex !== -1) {
      clonedQuestions.splice(questionIndex, 1);
      setQuestions(clonedQuestions);
      setSelectedQuestion(clonedQuestions[questionIndex]);
    }
  };

  const updateComponentType = (type: ComponentTypeProp) => {
    if (selectedQuestion) {
      setSelectedQuestion({
        ...selectedQuestion,
        type: type.slug,
        values: [],
      });
      const updatedQuestions = [...questions].map((question, index) => {
        if (question.id === selectedQuestion.id) {
          return { ...question, type: type.slug, values: [] };
        }
        return { ...question };
      });
      setQuestions(updatedQuestions);
    }
  };

  const closeSaveDraftConfirmationModal = useCallback(() => {
    setShowSaveAsDraftModal(false);
    navigate("/manager/forms");
  }, []);
  useEffect(() => {
    if (questions.length === 1) {
      setSelectedQuestion(questions[0]);
    }
  }, [questions]);
  return {
    questions,
    selectedQuestion,
    createNewQuestion,
    deleteOption,
    deleteMatchingOption,
    handleFileChange,
    uploadingIndex,
    updateDropdownOptions,
    updateMatchingChoice,
    addMatchingChoice,
    addFillInTheBlankChoice,
    updateFillInTheBlankChoice,
    updateMultipleChoice,
    addMultipleChoiceOption,
    updateQuestion,
    updateComponentType,
    setQuestions,
    setSelectedQuestion,
    showSaveAsDraftModal,
    setShowSaveAsDraftModal,
    closeSaveDraftConfirmationModal,
    deleteQuestion,
  };
};

export default useQuestions;
