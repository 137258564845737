import { ModifierOptionsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseModifierOptionsDoc[] = [];

export default function modifierOptions(
  state = initialState,
  action: ModifierOptionsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_MODIFIER_OPTIONS:
      return action.modifierOptions;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
