import moment from "moment-timezone";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  roundNumberToTwoDecimals,
  sanitizeNumericField,
} from "controllers/reporting";

import Box from "components/Box";

import { checkForUnavailableIcon } from "model/selectors/scheduleEvents";
import { RootState } from "model/store";

import SlotCard from "../SlotCard";
import ViewStaffMemberScheduleModal, {
  StaffWeeklySchedule,
} from "../ViewStaffMemberScheduleModal";

declare type SingleDayGridProps = {
  groupedShifts: GroupedShiftsByPosition[];
  jobFunctions: TangoJobFunctions;
  onShiftPress: (shift: TangoShift) => void;
  scheduleId: string | undefined;
  duplicateScheduleToUse?: TangoSchedule | null;
  scheduleForWeekRange: TangoSchedule | null;
};

const weeklyHourWorkLimit = 40;

export const SingleDayGrid = (props: SingleDayGridProps) => {
  const [
    selectedStaffMemberForDetailedScheduleView,
    setSelectedStaffMemberForDetailedScheduleView,
  ] = useState<string | null>(null);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const fixedAvailabilities: FixedAvailability[] = useSelector(
    (state: RootState) => state.fixedAvailabilities
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const draftSchedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.draftSchedules
  );

  const schedule = draftSchedules.find(
    ({ id }: TangoSchedule) => id === props.scheduleId
  );

  const scheduleToUse = schedule || props.duplicateScheduleToUse;

  const { shiftTypes } = businessSettings;
  if (!shiftTypes || !scheduleToUse) return null;

  const totalHoursReducer = (accumulator: number, item: TangoShift) => {
    if (item?.staffId) {
      const hoursDuration = roundNumberToTwoDecimals(
        sanitizeNumericField(
          Math.abs(
            moment
              .duration(
                moment(item?.startDate.toMillis()).diff(
                  moment(item?.endDate.toMillis())
                )
              )
              .asHours()
          )
        )
      );
      return accumulator + hoursDuration;
    }
    return accumulator;
  };

  const generateWeeklyStaffMemberSchedule = (): StaffWeeklySchedule | null => {
    if (!selectedStaffMemberForDetailedScheduleView) return null;
    const staffShifts = scheduleToUse?.shifts
      ?.filter(
        (shift) => shift?.staffId === selectedStaffMemberForDetailedScheduleView
      )
      ?.sort((a, b) => a?.startDate?.toDate() - b?.startDate?.toDate());
    if (!staffShifts?.length) return null;

    const totalWorkingHours = staffShifts?.reduce(totalHoursReducer, 0);
    const assignedStaffMember = fellowStaffMembers.find(
      (sm) => sm.id === selectedStaffMemberForDetailedScheduleView
    );
    if (!assignedStaffMember) return null;

    const smFirstName = assignedStaffMember?.contact.firstName || "";
    const smLastName = assignedStaffMember?.contact.lastName || "";

    return {
      staffId: selectedStaffMemberForDetailedScheduleView,
      staffFullName: `${smFirstName} ${smLastName}`,
      schedule: scheduleToUse,
      isOvertime: totalWorkingHours > weeklyHourWorkLimit,
      totalHours: totalWorkingHours,
      shifts: staffShifts,
    };
  };

  return (
    <Box display="flex" flexDirection="row">
      {props.groupedShifts.map((gs) => (
        <Box
          className="day-column"
          key={gs.positionId}
          flex={1}
          style={{ maxWidth: 350, minWidth: 250 }}
        >
          <Box className="day-header">
            <div className="day-name">
              {props.jobFunctions[gs?.positionId]?.title}
            </div>
            <div className="day-date">
              {gs.shifts.length} Shift{gs.shifts.length === 1 ? "" : "s"}
            </div>
          </Box>
          <Box className="day-body">
            {gs.shifts.map((shift: TangoShift) => {
              const assignedStaffMember = fellowStaffMembers.find(
                (sm) => sm.id === shift.staffId
              );
              const smFirstName = assignedStaffMember?.contact.firstName || "";
              const smLastName = assignedStaffMember?.contact.lastName || "";
              const shiftType = shiftTypes.find(
                (st) => st.id === shift.shiftTypeId
              );

              const shiftTypeName = shiftType?.name ?? "";

              const isUnavailableToWorkTheShift = assignedStaffMember
                ? Boolean(
                    checkForUnavailableIcon(
                      assignedStaffMember,
                      fixedAvailabilities,
                      business,
                      scheduleToUse,
                      shift,
                      false,
                      []
                    )
                  )
                : false;

              const militaryStartTime = `${moment(
                shift.startTime,
                "HH:mm"
              ).format("hh:mm a")}`;
              const militaryEndTime = `${moment(shift.endTime, "HH:mm").format(
                "hh:mm a"
              )}`;
              return (
                <SlotCard
                  onClick={() => {
                    props.onShiftPress(shift);
                  }}
                  onWeeklyScheduleClick={() =>
                    setSelectedStaffMemberForDetailedScheduleView(
                      shift?.staffId || null
                    )
                  }
                  shiftTypeName={shiftTypeName}
                  backgroundColor={shiftType?.backgroundColor || ""}
                  mainColor={shiftType?.mainColor || ""}
                  assigned={Boolean(shift.staffId)}
                  title={
                    shift.staffId
                      ? `${smFirstName} ${smLastName}`
                      : "Unassigned"
                  }
                  subtitle={`${militaryStartTime} - ${militaryEndTime}`}
                  availabilityWarningVisible={isUnavailableToWorkTheShift}
                />
              );
            })}
          </Box>
        </Box>
      ))}
      <ViewStaffMemberScheduleModal
        open={Boolean(selectedStaffMemberForDetailedScheduleView)}
        onClose={() => setSelectedStaffMemberForDetailedScheduleView(null)}
        weeklySchedule={generateWeeklyStaffMemberSchedule()}
        scheduleForWeekRange={props.scheduleForWeekRange}
      />
    </Box>
  );
};
