import { EnterpriseFilesAndFoldersTypesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FilesAndFolders[] = [];

export default function enterpriseDocs(
  state = initialState,
  action: EnterpriseFilesAndFoldersTypesAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_ENTERPRISE_DOCS:
      return action.enterpriseDocs;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
