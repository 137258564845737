import _, { transform } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";

import { fetchCollectionByBusinessIds } from "controllers/accounts";
import {
  composeUsableDiscount,
  composeUsableModifierOptions,
  composeUsableModifiers,
  composeUsableProducts,
} from "controllers/composableTypes";
import { downloadProductsCSV } from "controllers/reporting";

import { RootState } from "model/store";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import {
  findProductNamesFromProductIds,
  performSoftDelete,
  saveData,
} from "models/products";
import { DocumentData } from "../../../../types/document";
import { FirebaseMenuCategoryDoc } from "../../../../types/menus";
import { ProductDoc } from "../../../../types/products";
import { saveStitchedProductsToFirebase } from "../SaveStitchedProducts";
import ProductModal from "./ProductModal";
import "./productTable.css";

interface Props {
  businessId: string | undefined;
  products: FirebaseProductDoc[];
  discounts: DocumentData[];
  modifiers: FirebaseModifierDoc[];
  menuCategories: DocumentData[];
  businessName: string;
}

const ProductsTable = ({
  businessName,
  businessId,
  products,
  discounts,
  modifiers,
  menuCategories,
}: Props) => {
  const productModifiers = modifiers.map((modifier) => modifier.nameInternal);
  const modifiersRef = modifiers.reduce(
    (acc, val) => ({ ...acc, [val.nameInternal]: val.id }),
    {}
  );
  const scheduledDiscounts = discounts
    .filter((discount) => !!discount.scheduledDiscount)
    .map((type) => type.nameExternal);
  // const [existingData, setExistingData] = useState<ProductDoc[]>([])
  const [data, setData] = useState<ProductDoc[]>([]);
  const [savingToFirebaseLoading, setSavingToFirebaseLoading] = useState(false);
  const [softDeleteData, setSoftDeleteData] = useState<ProductDoc[]>([]);

  const [exportCsvLoading, setExportCsvLoading] = useState(false);

  const [allowProductUpdates, setAllowProductUpdates] = useState<boolean>(false)

  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings);
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;
  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );

  const exportProductsAsCsv = useCallback(async () => {
    if (businessId && businessName) {
      setExportCsvLoading(true);
      await downloadProductsCSV(businessId, businessName);
      setExportCsvLoading(false);
    }
  }, [businessId, businessName]);

  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "nameExternal",
    errors: [
      // {
      //   errorType: 'duplicateRows',
      //   rowIndex: 0,
      //   columnNames: [],
      //   isEdited: false,
      // },
      // {
      //   errorType: 'wrongDataType',
      //   rowIndex: 3,
      //   columnNames: [{ columnName: 'price', isEdited: false }],
      //   isEdited: false,
      // },
      // {
      //   errorType: 'missingCells',
      //   rowIndex: 3,
      //   columnNames: [{ columnName: 'subType', isEdited: false }],
      //   isEdited: false
      // }
    ],
  });
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);

  const toPrice = (text: number) => {
    if (typeof text === "number") return text.toFixed(2);
    return text;
  };

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "plu",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: false,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "nameExternal",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: false,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "price",
      isEditable: true,
      isImage: false,
      isCustomText: true,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: {
        prefix: "$ ",
        suffix: null,
        customFunction: toPrice.toString(),
      },
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Available", "Not Available"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "isMeal",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "alcohol",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "type",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: null,
      // dropdown: {
      //   isMultiSelect: false,
      //   isIndependent: true,
      //   dependentColumn: null,
      //   options: productTypes.map((type) => ({
      //     optionName: type,
      //     color: null,
      //   })),
      // },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "subType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: null,
      // dropdown: {
      //   isMultiSelect: true,
      //   isIndependent: false,
      //   dependentColumn: "type",
      //   options: [
      //     {
      //       dependantKey: "food",
      //       options: ["appetizers", "mains", "sides", "desserts"].map(
      //         (type) => ({ optionName: type, color: null })
      //       ),
      //     },
      //     {
      //       dependantKey: "beverage (non-alcoholic)",
      //       options: ["NA beverages"].map((type) => ({
      //         optionName: type,
      //         color: null,
      //       })),
      //     },
      //     {
      //       dependantKey: "wine",
      //       options: [
      //         "bottled wine",
      //         "glass wine",
      //         "red wine",
      //         "white wine",
      //         "rose",
      //         "sparkling",
      //       ].map((type) => ({
      //         optionName: type,
      //         color: null,
      //       })),
      //     },
      //     {
      //       dependantKey: "liquor & shots",
      //       options: [
      //         "vodka",
      //         "gin",
      //         "tequila",
      //         "whiskey",
      //         "rum",
      //         "liqueur",
      //         "cocktail",
      //         "shot",
      //       ].map((type) => ({
      //         optionName: type,
      //         color: null,
      //       })),
      //     },
      //     {
      //       dependantKey: "beer & cider",
      //       options: ["canned beer", "bottled beer", "draft beer", "cider"].map(
      //         (type) => ({ optionName: type, color: null })
      //       ),
      //     },
      //     {
      //       dependantKey: "seltzer & malt beverages",
      //       options: ["seltzer", "malt bev"].map((type) => ({
      //         optionName: type,
      //         color: null,
      //       })),
      //     },
      //     {
      //       dependantKey: "merchandise",
      //       options: [].map((type) => ({ optionName: type, color: null })),
      //     },
      //     {
      //       dependantKey: "coffee & tea",
      //       options: ["coffee", "tea"].map((type) => ({
      //         optionName: type,
      //         color: null,
      //       })),
      //     },
      //     {
      //       dependantKey: "other",
      //       options: [].map((type) => ({ optionName: type, color: null })),
      //     },
      //   ],
      // },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "modifiers",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: modifiers.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "products",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: products.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "modifiers",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: modifiers.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "modifiers",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: modifiers.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "discounts",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: discounts
          .filter((discount) => !!discount.scheduledDiscount)
          .map((type) => ({ optionName: type.nameInternal, color: null })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "imageUrl",
      isEditable: true,
      isImage: true,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: false,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const columnHeadingConversions = {
    plu: "PLU",
    nameExternal: "Item name",
    nameInternal: "Abbreviated Name",
    enabled: "Available",
    isMeal: "Meal?",
    alcohol: "Alcohol?",
    price: "Price",
    type: "Product Type",
    subType: "Product Sub-Type",
    modifiers: "Modifiers",
    discounts: "Scheduled Discounts",
    imageUrl: "Image",
    description: "Product Description",
  };
  const tableSchema = [
    { key: "plu", type: "value", data: "text" },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "nameInternal", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "isMeal", type: "value", data: "text" },
    { key: "alcohol", type: "value", data: "text" },
    { key: "price", type: "value", data: "number" },
    { key: "type", type: "value", data: "text" },
    { key: "subType", type: "arrayOfValue", data: "text" },
    { key: "modifiers", type: "arrayOfValue", data: "text" },
    { key: "discounts", type: "arrayOfValue", data: "text" },
    { key: "imageUrl", type: "value", data: "object" },
    { key: "description", type: "value", data: "text" },
  ];
  const dataSchema = [
    { key: "id", type: "value", data: "text" },
    { key: "plu", type: "value", data: "text", default: false },
    { key: "alcohol", type: "value", data: "text", default: false },
    { key: "businessId", type: "value", data: "string", default: businessId },
    { key: "createdAt", type: "value", data: "object", default: new Date() },
    { key: "deleted", type: "value", data: "text", default: false },
    { key: "description", type: "value", data: "text", default: "" },
    { key: "enabled", type: "value", data: "text", default: true },
    { key: "isMeal", type: "value", data: "text", default: false },
    { key: "id", type: "value", data: "text", default: null },
    { key: "imageUrl", type: "value", data: "object", default: null },
    { key: "ingredients", type: "arrayOfValue", data: "text", default: [] },
    {
      key: "ingredientsQuantity",
      type: "arrayOfValue",
      data: "text",
      default: [],
    },
    { key: "modifiers", type: "arrayOfValue", data: "text", default: [] },
    { key: "nameInternal", type: "value", data: "text" },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "popularity", type: "value", data: "number", default: 0 },
    {
      key: "preparationInstructions",
      type: "value",
      data: "object",
      default: null,
    },
    { key: "price", type: "value", data: "number" },
    {
      key: "suggestedPairings",
      type: "arrayOfValue",
      data: "text",
      default: [],
    },
    { key: "tags", type: "arrayOfValue", data: "text", default: [] },
    { key: "discounts", type: "arrayOfValue", data: "text", default: [] },
    { key: "taxRate", type: "value", data: "object", default: null },
    { key: "type", type: "value", data: "text" },
    { key: "subType", type: "arrayOfValue", data: "text" },
    { key: "updatedAt", type: "value", data: "object", default: new Date() },
    { key: "discounts", type: "arrayOfValue", data: "text", default: [] },
  ];

  const findModifierNameFromId = (id: string) => {
    const index = modifiers.findIndex((modifier) => modifier.id === id);
    if (index !== -1) {
      return modifiers[index].nameInternal;
    }
    return "Not found";
  };

  const findModifierIdFromName = (name: string) => {
    const index = modifiers.findIndex(
      (modifier) => modifier.nameInternal === name
    );
    if (index !== -1) {
      return modifiers[index].id;
    }
    return "Not found";
  };

  // Which columns in the grid to render based on the data
  const filterData = (data: DocumentData[]) =>
    data.map((row) => ({
      plu: row.plu,
      nameExternal: row.nameExternal,
      nameInternal: row.nameInternal,
      enabled: row.enabled,
      isMeal: row.isMeal,
      alcohol: row.alcohol,
      price: row.price,
      type: row.type,
      subType: row.subType,
      modifiers: row.modifiers,
      discounts: row.discounts || [],
      imageUrl: row.imageUrl,
      description: row.description,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      plu: row.plu,
      index,
    }));

    // Filter for duplicates
    const productDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({
            plu: row.plu,
          })),
          {
            plu: item.plu,
          }
        ) !== index
    );

    // Find the duplicate without the productId
    const errors: DocumentData[] = [];
    for (let i = 0; i < productDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter((row) => {
        if (row.plu !== "") {
          return row.plu === productDuplicateErrors[i].plu;
        }

        return false;
      });
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !(row.enabled === "Available" || row.enabled === "Not Available")
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong is meal
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !(row.isMeal === "Yes" || row.isMeal === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "isMeal", isEdited: false }],
          isEdited: false,
        })),
      // Wrong price
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => isNaN(row.price))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "price", isEdited: false }],
          isEdited: false,
        })),
      // // Wrong type
      // ...data
      //   .map((row, index) => ({ ...row, index }))
      //   .filter((row: any) => !productTypes.includes(row.type))
      //   .map((row) => ({
      //     errorType: "wrongDataType",
      //     rowIndex: row.index,
      //     columnNames: [{ columnName: "type", isEdited: false }],
      //     isEdited: false,
      //   })),
      // // Wrong subtype
      // ...data
      //   .map((row, index) => ({ ...row, index }))
      //   .filter((row: any) =>
      //     row.subType || row.subType.length > 0
      //       ? row.subType.filter(
      //           (subType: string) => !productSubTypes.includes(subType)
      //         ).length > 0
      //       : false
      //   )
      //   .map((row) => ({
      //     errorType: "wrongDataType",
      //     rowIndex: row.index,
      //     columnNames: [{ columnName: "subType", isEdited: false }],
      //     isEdited: false,
      //   })),
      // Wrong modifiers
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.modifiers || row.modifiers.length > 0
            ? row.modifiers.filter(
              (modifier: string) => !productModifiers.includes(modifier)
            ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "modifiers", isEdited: false }],
          isEdited: false,
        })),
      // Wrong discounts
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.discounts || row.discounts.length > 0
            ? row.discounts.filter(
              (discount: string) => !scheduledDiscounts.includes(discount)
            ).length > 0
            : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "discounts", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No name external
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.nameExternal || row.nameExternal.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameExternal", isEdited: false }],
          isEdited: false,
        })),
      // No name internal
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.nameInternal || row.nameInternal.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameInternal", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const saveToFirebaseFromEnterprise = async () => {
    if (businesses.length > 0) {
      let generatedPlus: string[] = [];
      for await (const [index, business] of businesses.entries()) {
        const businessProducts = await fetchCollectionByBusinessIds(
          [business.id],
          "Products",
          composeUsableProducts
        );
        const businessModifiers = await fetchCollectionByBusinessIds(
          [business.id],
          "Modifiers",
          composeUsableModifiers
        );
        const businessModifierOptions = await fetchCollectionByBusinessIds(
          [business.id],
          "ModifierOptions",
          composeUsableModifierOptions
        );
        const businessDiscounts = await fetchCollectionByBusinessIds(
          [business.id],
          "Discounts",
          composeUsableDiscount
        );

        const productsToDelete = softDeleteData.map(
          (softDeleteProduct) => softDeleteProduct.plu
        );
        const filteredProductsToDelete = businessProducts.filter((product) =>
          productsToDelete.includes(product.plu)
        );

        await performSoftDelete(
          filteredProductsToDelete,
          menuCategories as FirebaseMenuCategoryDoc[]
        );

        const businessModifiersRef = businessModifiers.reduce(
          (acc, val) => ({ ...acc, [val.nameInternal]: val.id }),
          {}
        );

        const newData = data.map((product) => {
          if (product.plu) {
            const businessProduct = businessProducts.find(
              (businessProduct) => businessProduct.plu === product.plu
            );

            if (businessProduct) {
              return {
                ...product,
                businessId: businessProduct.businessId,
                id: businessProduct.id,
              };
            }
          }

          return product;
        });

        const result = await saveData(
          newData,
          getProductsWithModifiersAndDiscounts(
            businessProducts,
            businessModifiers,
            businessDiscounts
          ),
          business.id,
          businessModifiersRef,
          businessProducts,
          businessModifierOptions,
          isEnterpriseLevel,
          generatedPlus
        );

        generatedPlus = result.generatedPlus;

        // TODO: Optimize the save stitched products function
        await saveStitchedProductsToFirebase(business.id);

        if (index === businesses.length - 1) {
          setSaved(true);
          return true;
        }
      }
    }
  };

  const saveToFirebase = async () => {
    if (isEnterpriseLevel) {
      await saveToFirebaseFromEnterprise();
      return true;
    } else {
      if (businessId) {
        const businessModifierOptions = await fetchCollectionByBusinessIds(
          [businessId],
          "ModifierOptions",
          composeUsableModifierOptions
        );

        // TODO: Somehow refresh the page once "Save" has been pressed
        await performSoftDelete(
          softDeleteData,
          menuCategories as FirebaseMenuCategoryDoc[]
        );
        await saveData(
          data,
          getProductsWithModifiersAndDiscounts(products, modifiers, discounts),
          businessId,
          modifiersRef,
          products,
          businessModifierOptions,
          isEnterpriseLevel,
          []
        );
        // TODO: Optimize the save stitched products function
        await saveStitchedProductsToFirebase(businessId);
        setSaved(true);
        return true;
      }
    }
  };

  const getProductsWithModifiersAndDiscounts = (
    products: FirebaseProductDoc[],
    modifiers: FirebaseModifierDoc[],
    discounts: DocumentData[]
  ): ProductDoc[] =>
    // TODO: Add ingredients
    products.map((product) => {
      const allScheduledDiscounts = discounts.filter((discount) =>
        discount.discountType === "Scheduled"
          ? discount.scheduledDiscount.productsToDiscount.includes(product.id)
          : false
      );

      const productDoc: ProductDoc = {
        // Meta
        id: product.id,
        businessId: product.businessId,

        // Table info
        nameExternal: product.nameExternal,
        nameInternal: product.nameInternal,
        enabled: product.enabled ? "Available" : "Not Available",
        isMeal: product.isMeal ? "Yes" : "No",
        alcohol: product.alcohol ? "Yes" : "No",
        price: parseFloat((product.price / 100).toFixed(2)),
        type: product.type,
        subType: product.subType || [],
        modifiers: (product.modifiers || []).map((id: string) => {
          const modifierIndex = modifiers.findIndex(
            (modifier) => modifier.id === id
          );
          if (modifierIndex !== -1) {
            return modifiers[modifierIndex].nameInternal;
          }
          // It should never reach this
          return id;
        }),
        scheduledDiscounts: (allScheduledDiscounts || []).map(
          (discount) => discount.nameExternal
        ),
        imageUrl: product.imageUrl || null,
        description: product.description,

        // Modal extra
        tags: product.tags || [],
        discounts: (allScheduledDiscounts || []).map(
          (discount) => discount.nameExternal
        ),
        suggestedPairings:
          findProductNamesFromProductIds(product.suggestedPairings, products) ||
          [],
        autoSuggestedPairings:
          findProductNamesFromProductIds(
            product.autoSuggestedPairings,
            products
          ) || [],
        taxRate: product.taxRate || null,
        ingredients: product.ingredients || [],
        ingredientsQuantity: product.ingredientsQuantity || [],
        plu: product.plu || "",
        allergens: product.allergens || [],
        enterpriseUpdatedDocument: !!isEnterpriseLevel,
      };

      return productDoc;
    });

  useEffect(() => {
    // Transform data for grid
    const transformedData = getProductsWithModifiersAndDiscounts(
      products,
      modifiers,
      discounts
    );

    setData(transformedData);

    setLoading(false);
    setSaved(false);
  }, [products, modifiers, discounts, options, loading, saved]);

  useEffect(() => {
    if (businessSettings) {
      const allowUpdates = businessSettings.menuBuilderPermissions.allowProductUpdates;
      setAllowProductUpdates(!allowUpdates)
    }
  }, [businessSettings])

  if (loading) {
    return <div className="loadingData">Loading...</div>;
  }

  return (
    <div className="productsTable">
      <Table
        // @ts-ignore
        exportCsv={exportProductsAsCsv}
        // @ts-ignore
        exportCsvLoading={exportCsvLoading}
        businessId={businessId}
        name="Products"
        parentName="MenuBuilder"
        allData={data}
        setAllData={setData}
        softDelete={softDeleteData}
        setSoftDelete={setSoftDeleteData}
        allowSoftDelete
        filterData={filterData}
        tableSchema={tableSchema}
        dataSchema={dataSchema}
        options={options}
        setOptions={setOptions}
        modalColumTitle="Additional Details"
        hasModal
        ModalComponent={ProductModal}
        errorManager={errorManager}
        setErrorManager={setErrorManager}
        closeErrorManager={() => {
          setErrorManager({
            ...errorManager,
            showErrorModal: false,
          });
        }}
        verifySave={verifyData}
        saveToFirebase={saveToFirebase}
        hasSearch
        searchColumnName="nameExternal"
        hasFilter
        columnHeadingConversions={columnHeadingConversions}
        searchPlaceHolderText={"Find a product"}
        refresh={() => {
          setLoading(true);
        }}
        readOnly={isEnterpriseLevel ? false : allowProductUpdates}
      />
    </div>
  );
};

export default ProductsTable;
