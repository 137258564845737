/* eslint-disable no-restricted-syntax */
import {
  Button,
  Classes,
  Dialog,
  Icon,
  Intent,
  Position,
  Toaster,
} from "@blueprintjs/core";
import moment from "moment";
import React, { useCallback, useState } from "react";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";

import {
  DraftIdWithDepartmentId,
  publishDraft,
  publishDraftSchedule,
  publishDuplicateSchedule,
  publishMultipleDrafts,
} from "controllers/schedule";

import Box from "components/Box";

import { RootState } from "model/store";

import PublishWhiteIcon from "assets/publish-white-icon.svg";

type Props = {
  schedule?: TangoSchedule | null;
  duplicatedScheduleForAWeekRange?: TangoSchedule | null;
  scheduleForWeekRange?: TangoSchedule | null;
  schedulesWithLocalSchedule: TangoSchedule[];
  draftsWithDepartmentForAWeek: DraftIdWithDepartmentId[];
};

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

const PublishScheduleAction = (props: Props) => {
  const { schedule } = props;

  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [readyToPublish, setReadyToPublish] = useState(false);

  const [thisWeekOnly, setThisWeekOnly] = useState(false);
  const [multipleWeeks, setMultipleWeeks] = useState(false);

  const [departmentToPublish, setDepartmentToPublish] = useState<
    "foh" | "boh" | "both" | null
  >(null);

  const [indefinitely, setIndefinitely] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedSchedules, setSelectedSchedules] = useState<TangoSchedule[]>(
    []
  );

  const fixedSchedules: TangoFixedSchedule[] = useSelector(
    (state: RootState) => state.fixedSchedules
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const clearState = () => {
    setSelectedDay(null);
    setReadyToPublish(false);
    setThisWeekOnly(false);
    setMultipleWeeks(false);
    setIndefinitely(false);
    setDepartmentToPublish(null);
  };

  const publishSchedulesHandler = useCallback(async () => {
    const scheduleToUse = schedule || props.duplicatedScheduleForAWeekRange;
    if (scheduleToUse && business) {
      setLoading(true);
      if (scheduleToUse?.isDuplicate && departmentToPublish) {
        await publishDuplicateSchedule(
          business.id,
          scheduleToUse,
          departmentToPublish
        );
        setLoading(false);
        clearState();
      } else {
        if (departmentToPublish === "boh") {
          const bohSchedule = props.draftsWithDepartmentForAWeek.find(
            (ds) => ds.departmentId === "boh"
          );
          if (bohSchedule) {
            await publishDraft(business.id, bohSchedule.draftId);
          } else {
            setLoading(false);
            toaster.show({
              message:
                "Something went wrong here, weren't able to find boh draft.",
              intent: Intent.DANGER,
            });
          }
        } else if (departmentToPublish === "foh") {
          const fohSchedule = props.draftsWithDepartmentForAWeek.find(
            (ds) => ds.departmentId === "foh"
          );
          if (fohSchedule) {
            await publishDraft(business.id, fohSchedule.draftId);
          } else {
            setLoading(false);
            toaster.show({
              message:
                "Something went wrong here, weren't able to find foh draft.",
              intent: Intent.DANGER,
            });
          }
        } else if (departmentToPublish === "both") {
          const fohSchedule = props.draftsWithDepartmentForAWeek.find(
            (ds) => ds.departmentId === "foh"
          );
          const bohSchedule = props.draftsWithDepartmentForAWeek.find(
            (ds) => ds.departmentId === "boh"
          );
          if (fohSchedule && bohSchedule) {
            await publishMultipleDrafts(business.id, [
              fohSchedule.draftId,
              bohSchedule.draftId,
            ]);
          } else {
            setLoading(false);
            toaster.show({
              message:
                "Something went wrong here, weren't able to find drafts.",
              intent: Intent.DANGER,
            });
          }
        }
        setLoading(false);
        clearState();
      }
    }
  }, [
    props.schedule,
    props.duplicatedScheduleForAWeekRange,
    business,
    props.draftsWithDepartmentForAWeek,
    departmentToPublish,
  ]);

  const renderStepBasedOnState = () => {
    if (!departmentToPublish) {
      return (
        <Box className="more-action">
          <Box className="action-header">
            <Icon
              icon="delete"
              className={Classes.POPOVER_DISMISS}
              onClick={clearState}
            />
            Publish Schedule
          </Box>
          <Box
            onClick={() => {
              setDepartmentToPublish("foh");
              setReadyToPublish(true);
            }}
            className="action-item"
            style={{ color: "#089AC8" }}
          >
            FOH Schedule Only
          </Box>
          <Box
            onClick={() => {
              setDepartmentToPublish("boh");
              setReadyToPublish(true);
            }}
            className="action-item"
            style={{ color: "#089AC8" }}
          >
            BOH Schedule Only
          </Box>
          <Box
            onClick={() => {
              setDepartmentToPublish("both");
              setReadyToPublish(true);
            }}
            className="action-item"
            style={{ color: "#089AC8" }}
          >
            Both FOH & BOH Schedules
          </Box>
        </Box>
      );
    }
    // if (!multipleWeeks && !thisWeekOnly) {
    //   return (
    //     <Box className="more-action">
    //       <Box className="action-header">
    //         <Icon
    //           icon="delete"
    //           className={Classes.POPOVER_DISMISS}
    //           onClick={clearState}
    //         />
    //         Publish Schedule
    //       </Box>
    //       <Box
    //         onClick={() => {
    //           setThisWeekOnly(true);
    //           setReadyToPublish(true);
    //         }}
    //         className="action-item"
    //         style={{ color: "#089AC8" }}
    //       >
    //         This week only
    //       </Box>
    //       <Box
    //         onClick={() => setMultipleWeeks(true)}
    //         className="action-item"
    //         style={{ color: "#089AC8" }}
    //       >
    //         Multiple weeks
    //       </Box>
    //     </Box>
    //   );
    // }
    // if (multipleWeeks) {
    //   let selectedScheduleDays: Date[] = [];
    //   selectedSchedules.forEach((schedule) => {
    //     const daysForASchedule = getWeekDays(schedule?.startDate.toDate());
    //     selectedScheduleDays = [...selectedScheduleDays, ...daysForASchedule];
    //   });
    //   return (
    //     <Box className="more-action" style={{ width: 400 }}>
    //       <Box
    //         className="action-header"
    //         style={{ display: "flex", justifyContent: "space-evenly" }}
    //       >
    //         <Icon
    //           icon="chevron-left"
    //           onClick={() => {
    //             setMultipleWeeks(false);
    //           }}
    //         />
    //         Choose schedules to publish
    //         {loading ? <Spinner size={SpinnerSize.SMALL} /> : <div />}
    //       </Box>
    //       <Box
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           paddingTop: 20,
    //         }}
    //       >
    //         <DayPicker
    //           className="Selectable"
    //           renderDay={(day) => {
    //             const dayToBeRendered = moment(day);
    //             const startOfWeekNumber = moment()
    //               .day(business?.payrollStartOfTheWeek)
    //               .get("day");
    //             dayToBeRendered.set("day", startOfWeekNumber);
    //             const selectedDays = getWeekDays(dayToBeRendered.toDate());
    //             const shiftsForADay = getShiftsForADay(
    //               day,
    //               selectedDays,
    //               props.schedulesWithLocalSchedule,
    //               business.timezone,
    //               fixedSchedules
    //             );

    //             return (
    //               <div>
    //                 <div>{moment(day).format("D")}</div>
    //                 {shiftsForADay.length ? (
    //                   <div
    //                     style={{
    //                       height: 4,
    //                       width: 4,
    //                       borderRadius: "50%",
    //                       backgroundColor: "#089AC8",
    //                       position: "absolute",
    //                       marginLeft: "0.7vh",
    //                       marginTop: "0.3vh",
    //                     }}
    //                   />
    //                 ) : null}
    //               </div>
    //             );
    //           }}
    //           selectedDays={selectedScheduleDays}
    //           onDayClick={onDayPress}
    //           navbarElement={<NavBar />}
    //         />
    //       </Box>
    //       <Box style={{ paddingLeft: "27px", paddingRight: "27px" }}>
    //         <Button
    //           disabled={!selectedDay}
    //           loading={loading}
    //           text="Next"
    //           id="create-shift-button"
    //           onClick={() => setReadyToPublish(true)}
    //         />
    //       </Box>
    //     </Box>
    //   );
    // }

    return null;
  };

  const sortedSchedules = selectedSchedules.sort(
    (a, b) => a?.startDate.toMillis() - b?.startDate.toMillis()
  );

  return (
    <>
      {renderStepBasedOnState()}
      <Dialog
        canOutsideClickClose
        onClose={() => setReadyToPublish(false)}
        style={{ backgroundColor: "white", width: 600 }}
        isOpen={readyToPublish}
      >
        <Box
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 40,
            paddingBottom: 5,
            backgroundColor: "white",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            style={{
              fontSize: 24,
              fontWeight: "800",
              fontFamily: "SFProText-Bold",
            }}
          >
            Publish Schedule
          </Box>
          <Box
            style={{
              fontSize: 14,
              marginTop: 20,
              fontWeight: "800",
              fontFamily: "SFProText-Bold",
            }}
          >
            As soon as this schedule is published staff members will be
            notified.
          </Box>
          <Box
            style={{
              fontSize: 14,
              marginTop: 20,
              fontWeight: "500",
              fontFamily: "SFProText-Regular",
            }}
          >
            You can go back and edit a schedule after it has been published as
            needed.
          </Box>
          <Box
            style={{
              fontSize: 14,
              marginTop: 40,
              fontWeight: "500",
              fontFamily: "SFProText-Regular",
            }}
          >
            You are publising the schedule(s) for the following dates:
          </Box>
          <Box>
            {`${moment(
              (
                schedule || props.duplicatedScheduleForAWeekRange
              )?.startDate.toDate()
            ).format("MMMM Do")} - ${moment(
              (
                schedule || props.duplicatedScheduleForAWeekRange
              )?.endDate.toDate()
            ).format("MMMM Do")}`}
          </Box>
          <Box>
            <Button
              style={{ marginTop: 20 }}
              icon={<img src={PublishWhiteIcon} />}
              loading={loading}
              text="Publish Schedule"
              id="publish-schedule-blue-button"
              onClick={publishSchedulesHandler}
            />
            <Button
              text="Cancel"
              id="cancel-publish-schedule-white-button"
              onClick={() => setReadyToPublish(false)}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default PublishScheduleAction;
