import { Intent, Position, Spinner, Toaster } from "@blueprintjs/core";
import { useSwitchLocation } from "App";
//@ts-ignore
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "model/store";

import "./style.css";

interface Props {
  visible: boolean;
  setVisibility: (val: boolean) => void;
}

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

export const SwitchLocationOverlay = (props: Props) => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const locations: TangoBusiness[] = useSelector(
    (state: RootState) => state.locations
  );

  const [locationIdLoading, setLocationIdLoading] = useState<string | null>(
    null
  );

  const locationControls = useSwitchLocation();

  const locationsWithoutCurrentLocation = useMemo(() => {
    return locations.filter((l) => l.id !== business.id);
  }, [locations, business.id]);

  const switchLocationHandler = useCallback(
    async (locationId: string) => {
      const locationToBeChangedTo = (locations || []).find(
        (l) => l.id === locationId
      );
      if (user && business && locationToBeChangedTo) {
        try {
          setLocationIdLoading(locationToBeChangedTo.id);
          await locationControls.switchLocation(user.id, locationId);
          props.setVisibility(false);
          setLocationIdLoading(null);
        } catch (e) {
          setLocationIdLoading(null);
          toaster.show({
            message: "Something went wrong switching location...",
            intent: Intent.DANGER,
          });
        }
      }
    },
    [user?.id, business?.id, locations]
  );

  return (
    <div
      onClick={() => {
        props.setVisibility(false);
      }}
      style={{ pointerEvents: props.visible ? "auto" : "none" }}
      className={`switch-location-overlay ${
        props.visible ? "overlay-open" : "overlay-close"
      }`}
    >
      <Box className="launchpad">
        <Box className="container">
          <Box className="body">
            <AnimatePresence>
              <motion.div
                style={{
                  width: 400,

                  borderRadius: 20,
                  backgroundColor: "#3F5A63",
                  backdropFilter: "blur(10px)",
                  marginTop: 80,
                  position: "absolute",
                  zIndex: 1,
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontFamily: "SFProText-Regular",
                  color: "white",
                  boxShadow: "0px 6.76px 36.05px rgba(0, 0, 0, 0.25)",
                }}
                initial={{ opacity: 0, scale: 0.75 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Box style={{ padding: 20 }}>
                    <p style={{ fontSize: 15 }}>Currently Viewing:</p>
                    <motion.div
                      onClick={() => null}
                      whileTap={{ scale: 0.9, opacity: 0.9 }}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        borderRadius: 20,
                        padding: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 15 }}>
                        {business.businessName}
                      </p>
                      <motion.svg
                        style={{ marginLeft: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                      >
                        <motion.path
                          animate={{ pathLength: "first" === "first" ? 1 : 0 }}
                          initial={{ pathLength: 0 }}
                          end={{ pathLength: 0 }}
                          // animate={{ pathLength: 1 }}
                          d="M 3.333 10 L 7.5 14.167 L 16.667 5"
                          fill="transparent"
                          strokeWidth="2"
                          stroke="#FFFFFF"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </motion.svg>
                    </motion.div>
                  </Box>
                  {locationsWithoutCurrentLocation?.length ? (
                    <>
                      <Box
                        style={{ width: "100%", border: "1px solid #4A6872" }}
                      />
                      <Box
                        style={{
                          padding: 20,
                          width: "100%",
                          maxHeight: 500,
                          overflowY: "scroll",
                        }}
                      >
                        <p style={{ fontSize: 15 }}>Switch To:</p>
                        {locationsWithoutCurrentLocation.map((location) => {
                          return (
                            <motion.div
                              key={location.id}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                switchLocationHandler(location?.id);
                              }}
                              whileTap={{ scale: 0.9, opacity: 0.9 }}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                borderRadius: 20,
                                padding: 10,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ margin: 0, fontSize: 15 }}>
                                {location.businessName}
                              </p>
                              {locationIdLoading === location.id ? (
                                <div style={{ marginLeft: 20 }}>
                                  <Spinner size={20} />
                                </div>
                              ) : null}
                            </motion.div>
                          );
                        })}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </motion.div>
            </AnimatePresence>
            <Box></Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
