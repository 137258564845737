import { Dialog, Icon, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Box from "components/Box";

import { adminsSelector } from "model/selectors/staffing";

import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import CrossIcon from "../../../icons/crossIcon.svg";
import "./styles.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  openTemplateNameModal: () => void;
  setSelectedTemplate: any;
  selectedTemplate: any;
}

export function TemplateModal({
  isOpen,
  closeModal,
  openTemplateNameModal,
  setSelectedTemplate,
  selectedTemplate,
}: Props) {
  const [templates, setTemplate]: any = useState([]);
  const db = firebase.firestore();
  const navigate = useNavigate();

  const getTemplates = () => {
    const unsubscribe = db.collection("Templates").onSnapshot((snapshot) => {
      let TemplateData: any = [];
      snapshot.forEach((doc) => {
        TemplateData = [...TemplateData, doc.data()];
      });
      console.log("TemplateData", TemplateData);
      setTemplate(TemplateData);
    });

    return () => unsubscribe();
  };

  const deleteTemplate = (templateId: string) => {
    try {
      db.collection("Templates")
        .doc(templateId)
        .update({ deleted: true })
        .then((docRef) => {
          console.log("deleted template, Id=", templateId);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const chooseTemplate = (currentitem: any, selectedTemplate: any) => {
    if (selectedTemplate.id == currentitem.id) {
      setSelectedTemplate({});
    } else {
      setSelectedTemplate(currentitem);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    console.log("selectedTemplate", selectedTemplate);
  }, [selectedTemplate]);

  return (
    <>
      <Dialog isOpen={isOpen} className="template-modal" onClose={closeModal}>
        <div className="modal-header">
          <img
            src={CrossIcon}
            style={{ height: 12.83, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
          <div className="modal-titlebody">
            <span className="modal-title">Choose Template</span>
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-title">Templates</div>
          <div className="modal-subtitle">
            How would you like to build this email
          </div>
          <Box className="template-grid">
            <Grid container spacing={3} columns={18}>
              <Grid
                item
                xs={6}
                //onClick={() => navigate("/creative-studio")}
                onClick={() => {
                  openTemplateNameModal();
                  closeModal();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="template-card"></div>
                <div className="template-card-title">Blank</div>
                <div className="template-card-time">New template</div>
              </Grid>

              {templates.length > 0 &&
                templates
                  .filter((item: any) => item.deleted == false)
                  .map((item: any) => (
                    <Grid item xs={6}>
                      <Box
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Box className="template-card-container">
                          <div
                            className={`${
                              item.id == selectedTemplate.id
                                ? "template-card-active"
                                : "template-card"
                            }`}
                            onClick={() => {
                              chooseTemplate(item, selectedTemplate);
                            }}
                          ></div>
                          <Box className="more-icon">
                            <Popover2
                              content={
                                <div className="action-popover">
                                  <div className="popover-title">Actions</div>
                                  <div
                                    className="popover-content"
                                    onClick={() => {
                                      navigate(`/creative-studio/${item.id}`);
                                    }}
                                  >
                                    <img
                                      src={
                                        require("assets/renameIcon.svg").default
                                      }
                                    />
                                    Edit Template
                                  </div>
                                  <div
                                    className="popover-content deleteicon"
                                    onClick={() => {
                                      deleteTemplate(item.id);
                                    }}
                                  >
                                    <img
                                      src={
                                        require("assets/deleteIcon.svg").default
                                      }
                                    />
                                    Delete Template
                                  </div>
                                </div>
                              }
                            >
                              <img
                                src={require("assets/more-icon.svg").default}
                              />
                            </Popover2>
                          </Box>
                        </Box>
                        <div className="template-card-title">
                          {item.templateName}
                        </div>
                        <div className="template-card-time">
                          {moment(item.createdAt.toDate()).fromNow()}
                        </div>
                      </Box>
                    </Grid>
                  ))}
            </Grid>
          </Box>

          <Box
            className="footer"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <button
              className={`${
                selectedTemplate.id
                  ? "continue-btn-disable"
                  : "continue-btn-active"
              }`}
              disabled={selectedTemplate.id ? false : true}
              onClick={() => closeModal()}
            >
              Continue
            </button>
          </Box>
        </div>
      </Dialog>
    </>
  );
}

export default TemplateModal;
