import * as React from "react";

export const BasicInfo = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M14 4.25H10.25V2C10.25 1.175 9.575 0.5 8.75 0.5H7.25C6.425 0.5 5.75 1.175 5.75 2V4.25H2C1.175 4.25 0.5 4.925 0.5 5.75V14C0.5 14.825 1.175 15.5 2 15.5H14C14.825 15.5 15.5 14.825 15.5 14V5.75C15.5 4.925 14.825 4.25 14 4.25ZM7.25 4.25V2H8.75V4.25V5.75H7.25V4.25ZM14 14H2V5.75H5.75C5.75 6.575 6.425 7.25 7.25 7.25H8.75C9.575 7.25 10.25 6.575 10.25 5.75H14V14Z"
      fill={color}
    />
  </svg>
);
