import firebase from "../config/firebase";
import { saveFile } from "../utils/storage";
import {
  EndingSectionProps,
  Question,
  ThemeProp,
} from "../views/SurveyBuilder/types";
import { Form } from "../views/ViewForms/useForms";
import { createTicket } from "./boards";

const FORM_COLLECTION = "Forms";
const THEME_COLLECTION = "FormThemes";
const FORM_RESPONSES = "FormResponses";
export const createForm = async (
  questions: Question[],
  theme: ThemeProp,
  surveyInfo: { typeformDisplayName: string; typeformDescription: string },
  formData: { name: string; type: string },
  businessId: string,
  accountId: string,
  endingSections: EndingSectionProps[],
  welcomeScreen: EndingSectionProps | null,
  enabled: boolean,
  status: "published" | "draft"
) => {
  const docRef = firebase.firestore().collection(FORM_COLLECTION).doc();
  const selectedEndingSection = endingSections.filter(
    (item) => item.type === "endingSection"
  )[0];

  const data = {
    questions,
    ...surveyInfo,
    selectedTheme: theme,
    ...formData,
    createdAt: new Date(),
    updatedAt: new Date(),
    businessId: businessId,
    accountId,
    enabled,
    deleted: false,
    endingSections,
    selectedEndingSection: selectedEndingSection ?? null,
    isAnonymous: true,
    responseCount: 0,
    welcomeScreen,
    status,
  };
  try {
    const newData = { ...data, id: docRef.id };
    await docRef.set(newData);
    return { data: newData };
  } catch (error) {
    // @ts-ignore
    console.log(error.message);
    return { error: true };
  }
};

export const updateForm = async (
  questions: Question[],
  theme: ThemeProp,
  surveyInfo: { typeformDisplayName: string; typeformDescription: string },
  businessId: string,
  endingSections: EndingSectionProps[],
  formId: string | undefined,
  welcomeScreen: EndingSectionProps | null
) => {
  const selectedEndingSection = endingSections.filter(
    (item) => item.type === "endingSection"
  )[0];

  const data = {
    questions,
    ...surveyInfo,
    selectedTheme: theme,
    updatedAt: new Date(),
    endingSections,
    selectedEndingSection: selectedEndingSection ?? null,
    welcomeScreen,
    status: "published",
    enabled: true,
  };
  try {
    await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .doc(formId)
      .update({ ...data });
    return { data: true };
  } catch (e) {
    // @ts-ignore
    console.log(e.message);
    return { error: true };
  }
};

export const updateFormField = async (
  formId: string,
  key: keyof Pick<Form, "enabled" | "deleted">,
  value: boolean
) => {
  try {
    await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .doc(formId)
      .update({ [key]: value, updatedAt: new Date() });
    return { data: true };
  } catch (e) {
    // @ts-ignore
    console.log(error.message);
    return { error: true };
  }
};
export const fetchTypeform = async (id: string) => {
  try {
    const docSnapshot = await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .doc(id)
      .get();
    return { data: docSnapshot.data() };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const fetchThemes = async (businessId: string) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .where("businessId", "==", businessId)
      .get();
    const defaultThemeQuerySnapshot = await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .where("businessId", "==", "")
      .get();
    const defaultThemesData = defaultThemeQuerySnapshot.docs.map((doc) =>
      doc.data()
    );
    const themesData = querySnapshot.docs.map((doc) => doc.data());
    return { data: [...themesData, ...defaultThemesData] as ThemeProp[] };
  } catch (error) {
    console.log(error);
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const addTheme = async (
  theme: ThemeProp,
  backgroundImage: string | null
) => {
  try {
    let themeData = { ...theme };
    let fileUrl = "";

    const docRef = firebase.firestore().collection(THEME_COLLECTION).doc();
    const fileUrlData = await saveBackgroundImage(
      backgroundImage,
      docRef.id,
      themeData.businessId
    );
    if (fileUrlData.data) {
      fileUrl = fileUrlData.data;
    }
    if (fileUrlData.error) {
      return { error: fileUrlData.error };
    }
    themeData = {
      ...themeData,
      id: docRef.id,
      backgroundImage: fileUrl?.length > 0 ? fileUrl : "",
    };
    await docRef.set(themeData);
    return { data: themeData };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const updateTheme = async (
  theme: ThemeProp,
  backgroundImage: string | null
) => {
  try {
    let fileUrl = "";
    let themeData = { ...theme };

    const fileUrlData = await saveBackgroundImage(
      backgroundImage,
      themeData.id,
      themeData.businessId
    );
    if (fileUrlData.data) {
      fileUrl = fileUrlData.data;
    }
    if (fileUrlData.error) {
      return { error: fileUrlData.error };
    }
    themeData = {
      ...themeData,
      backgroundImage: fileUrl?.length > 0 ? fileUrl : "",
    };

    await firebase
      .firestore()
      .collection(THEME_COLLECTION)
      .doc(theme.id)
      .update(themeData);
    return { data: themeData };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
export const saveBackgroundImage = async (
  image: string | null,
  themeId: string,
  businessId: string
) => {
  try {
    let fileUrl = "";
    if (image && !image.startsWith("https://")) {
      fileUrl = await saveFile(
        `businesses/${businessId}/typeform/${themeId}/backgroundImage`,
        image
      );
    }
    return { data: fileUrl };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchForm = async (id: string) => {
  try {
    const documentSnapshot = await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .doc(id)
      .get();
    return { data: documentSnapshot.data() };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const addResponse = async (
  response: any,
  formId: string,
  businessId: string,
  username: string
) => {
  try {
    const docRef = firebase.firestore().collection(FORM_RESPONSES).doc();
    const data = {
      response,
      formId,
      id: docRef.id,
      createdAt: new Date(),
      updatedAt: new Date(),
      businessId,
      enabled: true,
      deleted: false,
      username,
    };
    console.log(data, "form response data");
    let ticketData;
    const boardsQuerySnapshot = await firebase
      .firestore()
      .collection("Boards")
      .where("formId", "==", formId)
      .get();
    await firebase
      .firestore()
      .collection(FORM_RESPONSES)
      .doc(docRef.id)
      .set(data);
    if (boardsQuerySnapshot.docs.length > 0) {
      const boardData = boardsQuerySnapshot.docs[0].data();
      console.log("boardData", boardData);
      await createTicket(
        boardData.id,
        businessId,
        boardData.accountId,
        "",
        "",
        "",
        boardData.statuses[0].name,
        "",
        docRef.id
      );
    }
    await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .doc(formId)
      .update({ responseCount: firebase.firestore.FieldValue.increment(1) });

    return { data: true };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchTypeForms = async (businessId: string) => {
  try {
    const querySnapShot = await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .where("businessId", "==", businessId)
      .where("deleted", "==", false)
      .get();
    const data = querySnapShot.docs.map((item) => item.data() as Form);
    return {
      data,
    };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchFormResponses = async (formId: string) => {
  try {
    const querySnapShot = await firebase
      .firestore()
      .collection(FORM_RESPONSES)
      .where("formId", "==", formId)
      .where("deleted", "==", false)
      .get();
    const data = querySnapShot.docs.map((item) => item.data());
    return { data };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const updateFormResponseData = async (response: any) => {
  try {
    await firebase
      .firestore()
      .collection(FORM_RESPONSES)
      .doc(response.id)
      .update({ response: response.response, updatedAt: new Date() });
    return { data: true };
  } catch (e) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const fetchFormsByAccountId = async (accountId: string) => {
  try {
    const querySnapShot = await firebase
      .firestore()
      .collection(FORM_COLLECTION)
      .where("accountId", "==", accountId)
      .get();
    const data = querySnapShot.docs.map((item) => item.data() as Form);
    return {
      data,
    };
  } catch (error) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};

export const deleteFormResponses = async (formId: string) => {
  try {
    const formResponses = await firebase
      .firestore()
      .collection(FORM_RESPONSES)
      .where("formId", "==", formId)
      .get();
    const batch = firebase.firestore().batch();
    formResponses.forEach((doc) => {
      batch.update(doc.ref, { deleted: true });
    });
    await batch.commit();
    return { data: true };
  } catch (e) {
    // @ts-ignore
    const err: firebase.firestore.FirestoreError = error;
    return { error: err.message };
  }
};
