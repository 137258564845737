import { Icon, InputGroup } from "@blueprintjs/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

interface Props {
  campaigns: any;
  status: string;
}

const CampaignTable = ({ campaigns, status }: Props) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [filteredCampaigns, setFilteredCampaigns]: any = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleChange = (value: any) => {
    setSearchText(value);
  };

  useEffect(() => {
    const AllData = campaigns.map((item: any) => item);
    const filteredData = filteredCampaigns.map((item: any) => item);
    switch (status) {
      case "All":
        setFilteredCampaigns(
          AllData.filter((item: any) =>
            item.campaignName.includes(searchText)
          )
        );
        setIsSearching(true);
        setIsFiltered(true);
        break;
      case "Ongoing":
        if (searchText.length > 2) {
          setFilteredCampaigns(
            filteredData.filter(
              (item: any) =>
                item.campaignName.includes(searchText) &&
                item.status === "Ongoing"
            )
          );
          setIsSearching(true);
        } else {
          setFilteredCampaigns(
            AllData.filter((item: any) => item.status === "Ongoing")
          );
        }
        setIsFiltered(true);
        break;
      case "Drafts":
        if (searchText.length > 2) {
          setFilteredCampaigns(
            filteredData.filter(
              (item: any) =>
                item.campaignName.includes(searchText) && item.status === "Draft"
            )
          );
          setIsSearching(true);
        } else {
          setFilteredCampaigns(
            AllData.filter((item: any) => item.status === "Draft")
          );
        }
        setIsFiltered(true);

        break;
      case "Completed":
        if (searchText.length > 2) {
          setFilteredCampaigns(
            filteredData.filter(
              (item: any) =>
                item.campaignName.includes(searchText) &&
                item.status === "Completed"
            )
          );
          setIsSearching(true);
        } else {
          setFilteredCampaigns(
            AllData.filter((item: any) => item.status === "Completed")
          );
        }
        setIsFiltered(true);
        break;
    }
  }, [searchText]);

  useEffect(() => {
    let filteredData = campaigns.map((item: any) => item);

    switch (status) {
      case "All":
        setIsFiltered(false);
        setIsSearching(false);
        break;
      case "Ongoing":
        filteredData = filteredData.filter(
          (item: any) => item.status === "Ongoing"
        );
        setFilteredCampaigns(filteredData);
        setIsFiltered(true);
        break;
      case "Drafts":
        filteredData = filteredData.filter(
          (item: any) => item.status === "Draft"
        );
        setFilteredCampaigns(filteredData);
        setIsFiltered(true);
        break;
      case "Completed":
        filteredData = filteredData.filter(
          (item: any) => item.status === "Completed"
        );
        setFilteredCampaigns(filteredData);
        setIsFiltered(true);
        break;
    }
  }, [status]);

  return (
    <div>
      {" "}
      <Box className="search-input">
        <InputGroup
          placeholder="Search"
          value={searchText}
          onChange={(e) => handleChange(e.target.value)}
          leftIcon="search"
          intent="none"
        />
      </Box>
      <Box className="Email-container">
        {/*normal data*/}
        {isFiltered == false &&
          campaigns.length > 0 &&
          campaigns.map((item: any) => {
            const LastEdited =
              item &&
              item.updatedAt &&
              moment(item.updatedAt.toDate())
                .format("ddd, MMMM Do h:mm A")
                .toString();

            return (
              <Box
                className="Email-box"
                display="flex"
                flex-direction="row"
                justifyContent="space-between"
                onClick={() => navigate(`/edit-campaign/${item.id}`)}
              >
                <Box className="Email-box-left-body">
                  <div className="Email-title">{item?.campaignName}</div>
                  <div className="Email-time">
                    Last edited <span className="bold">{LastEdited}</span> by
                    You
                  </div>
                </Box>
                <Box className="Email-box-right-body">
                  <div className="draft-button">
                    {item?.status ? item?.status : "unavailable"}
                  </div>
                  <Icon icon="chevron-right" color="#7C7F93" size={12} />
                </Box>
              </Box>
            );
          })}

        {/*filtered data*/}
        {filteredCampaigns.length > 0 &&
          (isFiltered || isSearching) &&
          filteredCampaigns.map((item: any) => {
            const LastEdited =
              item &&
              item.updatedAt &&
              moment(item.updatedAt.toDate())
                .format("ddd, MMMM Do h:mm A")
                .toString();

            return (
              <Box
                className="Email-box"
                display="flex"
                flex-direction="row"
                justifyContent="space-between"
                onClick={() => navigate(`/edit-campaign/${item.id}`)}
              >
                <Box className="Email-box-left-body">
                  <div className="Email-title">{item?.campaignName}</div>
                  <div className="Email-time">
                    Last edited <span className="bold">{LastEdited}</span> by
                    You
                  </div>
                </Box>
                <Box className="Email-box-right-body">
                  <div className="draft-button">Draft</div>
                  <Icon icon="chevron-right" color="#7C7F93" size={12} />
                </Box>
              </Box>
            );
          })}
      </Box>
    </div>
  );
};

export default CampaignTable;
