import ActionType from "./actionTypes";

export function receiveModifierOptions(
  modifierOptions: FirebaseModifierOptionsDoc[]
) {
  return {
    type: ActionType.RECEIVE_MODIFIER_OPTIONS,
    modifierOptions,
  };
}
