import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  IActivityReport,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface ActivityReportProps {
  report: IActivityReport;
  businessName: string;
  snapshot?: boolean;
}

const ActivityReportDocument = (props: ActivityReportProps) => {
  const data = props.report.data;
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} -
  ${moment(props.report.date).add(1, "day").format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Activity {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
        </View>
        <Table>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Service Area
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Sales
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Meals
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Average Table
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Turn
            </TableCell>
          </TableHeader>
        </Table>
        <Table data={data}>
          <TableBody>
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => r.serviceAreaName}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => `${formatter.format(r.sales / 100)}`}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => `${r.meals.toLocaleString()}`}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => `${formatter.format(r.averageTable / 100)}`}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => `${r.turn.toLocaleString()}`}
            />
          </TableBody>
          <TableFooter
            style={{ borderTopWidth: 0, backgroundColor: "#f4f3f3" }}
          >
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              Total
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {`${formatter.format(
                data.reduce((acc, val) => acc + val.sales, 0) / 100
              )}`}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {`${data.reduce((acc, val) => acc + val.meals, 0)}`}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {`${formatter.format(
                data.reduce((acc, val) => acc + val.averageTable, 0) / 100
              )}`}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {`${data.reduce((acc, val) => acc + val.turn, 0)}`}
            </TableCell>
          </TableFooter>
        </Table>
      </Page>
    </Document>
  );
};

export default ActivityReportDocument;
