import React from "react";

import Box from "components/Box";

interface Props {
  onNext: () => void;
}

const SpeedFlexForm = (props: Props) => (
  <Box className="form-container">
    <div className="slide-in">
      <div className="welcome-title">
        Awesome!
        <br />
        Ok, a few housekeeping questions..
      </div>
      <div className="select-tango-products-text">
        Are you you an{" "}
        <span style={{ fontFamily: "SFProText-Heavy" }}>owner</span> of this
        business?
      </div>
      <Box className="yes-no-container" display="flex" flexDirection="row">
        <div onClick={props.onNext}>Yes</div>
        <div onClick={props.onNext}>No</div>
      </Box>
    </div>
  </Box>
);
export default SpeedFlexForm;
