import { Button, Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteFormConfirmationModal from "views/FormResponses/DeleteFormConfirmationModal";

import RenderForm from "../SurveyBuilder/renderForm";
import "./index.css";
import useFormPreview from "./useFormPreview";

const FormPreview = () => {
  const navigate = useNavigate();
  const {
    formData,
    selectedPreview,
    setSelectedPreview,
    id,
    updateFormSettings,
    showDeleteFormConfirmationModal,
    setShowDeleteFormConfirmationModal,
    loading,
  } = useFormPreview();
  if (formData) {
    return (
      <div id="form-preview-page">
        <div className="page-subheader second-header">
          <div className="button-group">
            <Icon
              icon="chevron-left"
              iconSize={20}
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={() => navigate("/forms")}
            />
            <div className="form-name">{formData.name}</div>
            <Select
              className="navigation-dropdown"
              filterable={false}
              matchTargetWidth
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              items={["Form Preview", "Responses", "Edit Form"]}
              itemRenderer={(item, { handleClick }) => (
                <MenuItem text={item} onClick={handleClick} />
              )}
              onItemSelect={(item) => {
                if (item === "Form Preview") {
                  navigate(`/form/${id}/preview`);
                } else if (item === "Responses") {
                  navigate(`/form/${id}/responses`);
                } else {
                  navigate(`/form/${id}/edit`);
                }
              }}
            >
              <Button rightIcon="chevron-down">Form Preview</Button>
            </Select>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="publish-date">
              {formData.enabled
                ? ` Form published on ${formData.createdAt
                  .toDate()
                  .toLocaleString()}`
                : "Inactive"}
            </div>
            <Select
              className="settings-dropdown"
              filterable={false}
              matchTargetWidth
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              items={[
                formData?.enabled ? "Set as inactive" : "Set as active",
                "Delete form",
              ]}
              itemRenderer={(item, { handleClick }) => (
                <MenuItem
                  onClick={handleClick}
                  text={
                    <div>
                      <div className="menu-item-title">{item}</div>
                      <div className="menu-item-subtitle">
                        {item === "Delete form"
                          ? "This cannnot be undone"
                          : `You will ${formData.enabled ? "no longer" : "be able to"
                          } receive responses`}
                      </div>
                    </div>
                  }
                />
              )}
              onItemSelect={async (item) => {
                if (item === "Set as inactive") {
                  await updateFormSettings("enabled", false);
                } else if (item === "Set as active") {
                  await updateFormSettings("enabled", true);
                } else {
                  setShowDeleteFormConfirmationModal(true);
                }
              }}
            >
              <Button rightIcon="chevron-down" loading={loading}>
                Settings
              </Button>
            </Select>
          </div>
        </div>
        <div
          className={`page-body form-preview ${selectedPreview === "mobile" ? "mobile" : ""
            }`}
        >
          <RenderForm isPreview={true} formData={formData} />
        </div>
        <div className="preview-buttons">
          <Button
            text="Desktop"
            onClick={() => setSelectedPreview("desktop")}
            className={`${selectedPreview === "desktop" ? "active" : ""}`}
          />
          <Button
            text="Mobile"
            onClick={() => setSelectedPreview("mobile")}
            className={`${selectedPreview === "mobile" ? "active" : ""}`}
          />
        </div>
        <DeleteFormConfirmationModal
          showModal={showDeleteFormConfirmationModal}
          onClose={() => setShowDeleteFormConfirmationModal(false)}
          onDelete={async () => {
            await updateFormSettings("deleted", true);
          }}
        />
      </div>
    );
  }
  return null;
};
export default FormPreview;
