import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const SuccessModal = ({ isOpen, closeModal }: Props) => {
  return (
    <UIComponents.Modal isOpen={isOpen} closeModal={closeModal}>
      <div className={"p-5 text-2xl text-black text-center lato-font-bold"}>
        Successfully linked!
      </div>
    </UIComponents.Modal>
  );
};
export default SuccessModal;
