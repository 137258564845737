import _ from "lodash";

import firebase from "../config/firebase";
import { FormField } from "../views/manager/Boards/Modal/ticketPropertiesModal";
import { fetchFormResponses } from "./surveyBuilder";

const db = firebase.firestore();

export const archiveBoard = async (boardId: string) => {
  await db.collection("Boards").doc(boardId).update({
    Enabled: false,
  });
};

export const createBoard = async (
  name: string,
  description: string,
  color: string,
  businessID: string,
  accountId: string,
  parentId: string,
  fixedProperties: any,
  customProperties: any,
  icon: string,
  formFields: FormField[],
  formId: string | null
) => {
  const customprop = Object.keys(customProperties).map((item) => {
    return {
      name: customProperties[item].name,
      value: customProperties[item].value,
      automationId: customProperties[item].automationId,
      type: customProperties[item].type,
      enable: customProperties[item].enable,
    };
  });
  const docRef = db.collection("Boards").doc();

  const payload = {
    id: docRef.id,
    name: name,
    description: description,
    color: color,
    createdAt: new Date(),
    updatedAt: new Date(),
    Deleted: false,
    Enabled: true,
    Icon: icon,
    businessId: businessID,
    accountId: accountId,
    parentId: parentId,
    fixedProperties: fixedProperties,
    customProperties: customprop,
    formId,
    statuses: [
      {
        name: "ToDo",
        color: "",
        automationId: null,
      },
    ],
    formFields,
  };
  try {
    console.log("payload", payload);
    await db.collection("Boards").doc(docRef.id).set(payload);

    if (formId) {
      const formResponses = await fetchFormResponses(formId);
      if (formResponses.data) {
        console.log("before promise");
        await Promise.all(
          formResponses.data.map(async (formResponse) => {
            await createTicket(
              docRef.id,
              businessID,
              accountId,
              "",
              "",
              "",
              payload.statuses[0].name,
              "",
              formResponse.id
            );
          })
        );
        console.log("afer promise");
      }
      // console.log(formResponses);
    }
  } catch (e) {}
};

export const checkIsFormLinkedToBoard = async (formId: string) => {
  try {
    const querySnapShot = await db
      .collection("Boards")
      .where("formId", "==", formId)
      .get();
    return { data: querySnapShot.docs.map((doc) => doc.data()) };
  } catch (e) {
    //@ts-ignore
    return { error: e.message };
  }
};

export const createTicket = async (
  boardId: string,
  businessId: string,
  accountId: string,
  parentId: string,
  title: string,
  description: string,
  status: string,
  customProperties: string,
  formResponseId?: string
) => {
  const docRef = db.collection("Tickets").doc();

  const payload = {
    id: docRef.id,
    boardId: boardId,
    createdAt: new Date(),
    updatedAt: new Date(),
    Deleted: false,
    Enabled: false,
    businessId: businessId,
    accountId: accountId,
    parentId: parentId,
    Title: title,
    Description: description,
    Status: status,
    fixedProperties: {
      Assignpeopletoticket: [],
      Ticketduedate: null,
      Ticketpriority: null,
      Attachfilestoticket: [],
    },
    customProperties: customProperties,
    Comments: [],
    formResponseId: formResponseId || null,
  };
  try {
    await db.collection("Tickets").doc(docRef.id).set(payload);
    return { data: true };
  } catch (err) {
    return { error: err };
  }
};

export const AddBoardStatus = (
  boardId: string,
  oldstatuses: any,
  newStatus: any
) => {
  let statuses = [].concat(oldstatuses, newStatus);
  try {
    db.collection("Boards")
      .doc(boardId)
      .update({ statuses: statuses, updatedAt: new Date() });
  } catch (err) {
    console.error(err);
  }
};

export const SaveDraggedCard = (
  index: number,
  status: string,
  ticketId: string
) => {
  try {
    db.collection("Tickets")
      .doc(ticketId)
      .update({ index: index, Status: status });
  } catch (err) {
    console.error(err);
  }
};

export const updateBothStatusesCardsinDB = async (
  sourceColName: string,
  sourceItems: any[],
  destiColName: string,
  destItems: any[]
) => {
  const updatesourceColTickets = () => {
    if (sourceItems.length > 0) {
      sourceItems.map((item: any, index: any) => {
        SaveDraggedCard(index, sourceColName, item.id);
      });
    }
  };

  const destiColNameTickets = () => {
    if (destItems.length > 0) {
      destItems.map((item: any, index: any) => {
        SaveDraggedCard(index, destiColName, item.id);
      });
    }
  };

  await updatesourceColTickets();
  await destiColNameTickets();
};

export const updateSingleStatusesCardsinDB = async (
  sourceColName: string,
  Items: any[]
) => {
  if (Items.length > 0) {
    Items.map((item: any, index: any) => {
      SaveDraggedCard(index, sourceColName, item.id);
    });
  }
};

export const editStatusProperty = (
  updatedStatusName: string,
  statusColor: string,
  oldStatusName: string,
  statuses: any[],
  boardId: string,
  statusTickets: any[]
) => {
  let updatedStatus: any[] = statuses || [];
  let index = -1;
  if (updatedStatus.length > 0) {
    index = updatedStatus.findIndex((item: any) => item.name === oldStatusName);
  }

  if (index !== -1) {
    if (updatedStatus[index].name !== updatedStatusName) {
      updatedStatus[index].name = updatedStatusName;
      //update tickets
      statusTickets.length > 0 &&
        statusTickets.forEach(async (ticket: any) => {
          await ChangeTicketStatus(ticket.id, updatedStatusName);
        });
    }
    if (statusColor) {
      updatedStatus[index].bgcolor = statusColor;
    }
    try {
      db.collection("Boards").doc(boardId).update({ statuses: updatedStatus });
    } catch (err) {
      console.error(err);
    }
  }
};

export const deleteStatus = (
  statusName: string,
  statuses: any[],
  boardId: string
) => {
  let updatedStatus: any[] = statuses || [];
  let index = -1;
  if (updatedStatus.length > 0) {
    index = updatedStatus.findIndex((item: any) => item.name === statusName);
  }

  if (index !== -1) {
    updatedStatus[index].Deleted = true;
    try {
      db.collection("Boards").doc(boardId).update({ statuses: updatedStatus });
    } catch (err) {
      console.error(err);
    }
  }
};

export const ArchieveTicket = (
  index: number,
  status: string,
  ticketId: string
) => {
  try {
    db.collection("Tickets").doc(ticketId).update({ Enabled: false });
  } catch (err) {
    console.error(err);
  }
};

export const EditTicketProperty = (name: string, ticketId: string) => {
  try {
    db.collection("Tickets").doc(ticketId).update({ Title: name });
  } catch (err) {
    console.error(err);
  }
};

export const EditTicketData = (
  title: string,
  ticketId: string,
  dueDate: Date,
  priority: string,
  fixedProperties: any,
  ticketCustomProperties: any,
  assignedMember: any
) => {
  let editedFixedProperties = { ...fixedProperties };
  if (dueDate) {
    editedFixedProperties.Ticketduedate = dueDate;
  }
  if (priority) {
    editedFixedProperties.Ticketpriority = priority;
  }
  if (assignedMember.length >= 0 && assignedMember != undefined) {
    console.log("editedFixedProperties", editedFixedProperties);
    console.log("assignedMember", assignedMember);
    editedFixedProperties.Assignpeopletoticket = assignedMember;
  }
  const payload = {
    Title: title,
    fixedProperties: editedFixedProperties,
    customProperties: Object.keys(ticketCustomProperties).map(
      (item) => ticketCustomProperties[item]
    ),
    updatedAt: new Date(),
    Comments: [],
  };

  console.log("payload", payload);

  try {
    db.collection("Tickets").doc(ticketId).update(payload);
  } catch (err) {
    console.error(err);
  }
};

export const EditTicketAssignPeople = (
  ticketId: string,
  fixedProperties: any,
  assignedMember: any
) => {
  let editedFixedProperties = { ...fixedProperties };

  if (assignedMember.length >= 0 && assignedMember != undefined) {
    console.log("editedFixedProperties", editedFixedProperties);
    console.log("assignedMember", assignedMember);
    editedFixedProperties.Assignpeopletoticket = assignedMember;
  }
  try {
    db.collection("Tickets")
      .doc(ticketId)
      .update({ fixedProperties: editedFixedProperties });
  } catch (err) {
    console.error(err);
  }
};

export const EditTicketCustomProperty = (
  ticketId: string,
  ticketCustomProperties: any
) => {
  let customProperties = Object.keys(ticketCustomProperties).map(
    (item) => ticketCustomProperties[item]
  );

  try {
    db.collection("Tickets")
      .doc(ticketId)
      .update({ customProperties: customProperties });
  } catch (err) {
    console.error(err);
  }
};

export const AddComment = (
  ticketId: string,
  userId: string,
  firstName: string,
  lastName: string,
  description: string
) => {
  const payload = {
    author: {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      createdAt: new Date(),
    },
    description: description,
  };

  try {
    db.collection("Tickets")
      .doc(ticketId)
      .update({ Comments: firebase.firestore.FieldValue.arrayUnion(payload) });
  } catch (err) {
    console.error(err);
  }
};

export const AddAssigneesToTicket = (
  ticketId: string,
  AssignedMember: any,
  fixedProperties: any
) => {
  let editedFixedProperties = { ...fixedProperties };
  editedFixedProperties.Assignpeopletoticket = AssignedMember;

  try {
    db.collection("Tickets")
      .doc(ticketId)
      .update({ fixedProperties: editedFixedProperties });
  } catch (err) {
    console.error(err);
  }
};

export const ChangeTicketStatus = (ticketId: string, status: string) => {
  try {
    db.collection("Tickets").doc(ticketId).update({ Status: status });
  } catch (err) {
    console.error(err);
  }
};

export const DeleteTicket = (ticketId: string) => {
  try {
    db.collection("Tickets").doc(ticketId).update({ Deleted: true });
  } catch (err) {
    console.error(err);
  }
};
