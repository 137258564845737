import React, { useReducer, useCallback } from "react";

type Actions =
    | { action: "clear" }
    | { action: "push", customPrefix?: string }
    | { action: "pop", ids: string[] }

type State = {
    ids: string[]; // current added ids
    count: number; // number of ids ever added
};

export const useUniqueIdList = (defaultPrefix: string) => {
    const [state, updateState] = useReducer(
        (added: State, update: Actions) => {
            const curr = added.ids;
            const count = added.count;
            if (update.action == "push") {
                const newCount = count + 1;
                const prefix = update.customPrefix ?? defaultPrefix;
                return {
                    ids: [prefix + newCount, ...curr],
                    count: newCount,
                };
            }
            if (update.action == "clear") {
                return {
                    count,
                    ids: [],
                };
            }
            if (update.action == "pop") {
                const toRemove = update.ids ?? []
                return {
                    ids: curr.filter(i => !toRemove.includes(i)),
                    count,
                };
            }
            console.warn("Unreachable code reached");
            return added;
        },
        { count: 0, ids: [] },
    );
    const push = useCallback(
        (customPrefix?: string) => {
            if (customPrefix) updateState({ action: "push", customPrefix })
            else updateState({ action: "push" })
        },
        [],
    );
    const clear = useCallback(
        () => updateState({ action: "clear" }),
        [],
    );
    const remove = useCallback(
        (ids: string[]) => updateState({
            action: "pop",
            ids,
        }),
        [],
    );
    return {
        addedIds: state.ids,
        push,
        clear,
        remove,
    };
}