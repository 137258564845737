import {
  Button,
  ButtonGroup,
  Dialog,
  Icon,
  Menu,
  MenuItem,
  Position,
  Spinner,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { AsYouType } from "libphonenumber-js";
import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";

import BackArrowIcon from "assets/back-arrow.svg";

import firebase from "../../config/firebase";
import IncrementDecrementCounter from "../IncrementDecrementCounter";
import CellRenderer from "./CellRenderer";
import EditCellRenderer from "./EditCellRenderer";
import RowRenderer from "./RowRenderer";
import SheetRenderer from "./SheetRenderer";
import ShowPreview from "./ShowPreview";
import { concatArrayToObject, findCorrectValue, getDataType } from "./Utils";
import "./table.css";

const db = firebase.firestore();

function Table({
  /** Business id
   *  ::: string
   *  ::: for finding existing options in the database
   * */
  businessId,
  /** Name of the table
   *  ::: string
   *  ::: for finding existing options in the database
   * */
  name,
  /** Name of the parent of table
   *  ::: string
   *  ::: for finding existing options in the database
   * */
  parentName,
  /** Underlying data
   *  ::: {[T: string]: any}[]
   *  ::: to showcase as a table
   * */
  allData,
  /** Setter function for underlying data
   *  ::: (newData: {[T: string]: any}[]) => void
   *  ::: to set the table data back to the original data (with possible checks and transformations)
   * */
  setAllData,
  /** A function to only select the columns necessary
   *  ::: (rows: {[T: string]: any}[]) => {[T: string]: any}[]
   *  ::: for composing usable elements on the table
   * */
  filterData,
  /** An array of options (including dropdown and if images are for this column)
   *  ::: {[T: string]: any}[]
   *  ::: to get the options for the column
   * */
  options,
  /** Setter function for an array of options (including dropdown and if images are for this column)
   *  ::: (options: {[T: string]: any}[]) => void
   *  ::: for setting the options - usually changing the color of the tags in the dropdown
   * */
  setOptions,
  /** Show a modal column on the right to open a pop-up
   *  ::: boolean
   *  ::: if the table should have a modal column
   * */
  hasModal,
  /** The component to show
   *  ::: React.FC<{{ showModal : boolean,
   *                  closeModal : (index | number) => void,
   *                  data : {[T: string]: any}[],
   *                  setData : (newData: {[T: string]: any}[]) => void }} : Props>
   *  ::: if the table should have a modal column
   * */
  ModalComponent,
  /** Show a search bar
   *  ::: boolean
   *  ::: Search the table based on a column
   * */
  hasSearch,
  /** Show a filter bar
   *  ::: boolean
   *  ::: Filter the table based on a column with dropdown
   * */
  hasFilter,
  /** What column to search on
   *  ::: string
   *  ::: Search the table based on this column
   * */
  searchColumnName,
  /** Pass the errors for the table
   *  ::: {
         showErrorModal: boolean,
         errorColumn: string,
         errors: {
             errorType: 'duplicateRows' | 'wrongDataType' | 'missingCells',
             rowIndex: number,
             columnNames: {columnName: string, isEdited: boolean}[]
         }[],
         isEdited: boolean

     }
     ::: Check errors in the table
   */
  errorManager,
  /** Set the error manager
   *  ::: ({
         showErrorModal: boolean,
         errorColumn: string,
         errors: {
             errorType: 'duplicateRows' | 'wrongDataType' | 'missingCells',
             rowIndex: number,
             columnNames: {columnName: string, isEdited: boolean}[]
         }[],
         isEdited: boolean
     }) => void
     ::: Set the state of the error manager
   */
  setErrorManager,
  /* NOTE: This might not be useful if we have setErrorManager */
  /** Close error modal
   * ::: () => void
   * ::: Close the error modal whilst errors still exist for them to be fixed
   */
  closeErrorManager,
  /** Verify save
   * ::: () => boolean
   * ::: Check if there are any errors
   */
  verifySave,
  /** Save to firebase
   * ::: () => boolean
   * ::: Save to firebase all the documents of the table
   */
  saveToFirebase,
  /** Does this table do soft or hard deletes
   *
   */
  allowSoftDelete = false,
  /** Records that have been deleted
   * ::: {[T: string]: any}[]
   * ::: Values to check if items have been deleted
   */
  softDelete = [],
  /**
   * Get prompted if all items from the table
   * have been deleted
   * ::: (isEmpty: boolean) => void
   */
  setIsAllDelete = (_) => {},
  /** Set records that have been deleted
   * ::: (recordToDelete: {[T: string]: any}[]) => void
   * ::: Values to check if items have been deleted
   */
  setSoftDelete = (_) => {},
  /** Columns to
   *
   */
  isModalViewOnly = false,
  /**
   * Key value pairs of the actual key and the column name we want to show
   *  ::: {[T: string]: string}
   *  ::: Change the name on the headers to support the different headers from key names
   */
  columnHeadingConversions = null || {},
  /** An array of table schema
   *  ::: { key: string, type: 'value' | 'arrayOfValue', data: 'text' | 'number' | 'date' }[]
   *  ::: to get the schema for all the columns visible (where key is column)
   * */
  tableSchema = null || [],
  /** An array of data schema
   *  ::: { key: string, type: 'value' | 'arrayOfValue', data: 'text' | 'number' | 'date', default?: any }[]
   *  ::: to get the schema for all the underlying data by column (where key is column)
   * */
  dataSchema = null || [],
  /** Additional documents for the products
   * ::: {[T: string]: any}
   * ::: any necessary data that could help the modal
   */
  additionalInfo = {},
  /** Disable shortcuts for using the table within a modal
   * ::: boolean
   * ::: disable shortcuts in the modal
   */
  disableShortcuts = false,
  /** Search placeholder text
   * ::: string
   * ::: the text to show in the search box as a placeholder
   */
  searchPlaceHolderText = "",
  /** Show search name
   * ::: boolean
   * ::: Show name next to search
   */
  showSearchName = true,
  /** Custom component next to search
   *  ::: component
   *  ::: custom component next to search
   */
  customerComponentNextToSearch = null,
  /***
   * header title for modal column
   * :::string
   */
  modalColumTitle = "",
  /***
   * Loading indicator for csv export button
   * :::boolean
   */
  exportCsvLoading = undefined,
  /***
   * export csv handler function
   * :::() => void
   */
  exportCsv = undefined,
  /***
   * refresh page after saving data
   * :::() => void
   */
  refresh = (_) => {},
  /***
   * show back arrow next to title
   * :::boolean
   */
  showBackArrow = false,
  /***
   * back button action
   * :::() => void
   */
  goBack = (_) => {},
  /** Custom components next to title
   *  ::: component
   *  ::: custom component next to title
   */
  customComponentsNextToTitle = null,
  /***
   * Data is read only
   * :::boolean
   */
  readOnly = false,
}) {
  // Component variables
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [allDataRows, setAllDataRows] = useState(dataSchema);
  const [tableId, setTableId] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [historicalData, setHistoricalData] = useState([allData]);
  const [redoData, setRedoData] = useState([]);
  const [historicalSoftDeleteData, setHistoricalSoftDeleteData] = useState([
    softDelete,
  ]);
  const [redoSoftDeleteData, setRedoSoftDeleteData] = useState([]);
  const [numberOfNewRows, setNumberOfNewRows] = useState("1");
  const MAX_UNDOS = 5;

  // Table variables
  const [schema, setSchema] = useState(tableSchema);
  const [columns, setColumns] = useState([]);
  const [grid, setGrid] = useState([]);
  const [selections, setSelections] = useState([]);
  const [columnsThatAreId, setColumnsThatAreId] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);

  // Search variables
  const [isSearching, setIsSearching] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchGrid, setSearchGrid] = useState([]);
  const [allFilterableColumns, setAllFilterableColumns] = useState([]);
  const [allFilterableColumnOptions, setAllFilterableColumnOptions] = useState(
    {}
  );
  const [columnToFilter, setColumnToFilter] = useState(null);
  const [filterableColumnOptions, setFilterableColumnOptions] = useState([]);

  // Debug variables
  const debug = false;
  const [isEditSchema, setIsEditSchema] = useState(false);
  const [schemaUpdated, setSchemaUpdated] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);

  // Modal variables
  const [openModalIndex, setOpenModalIndex] = useState(null);

  // Dropdown tag variables
  const [optionToChangeColor, setOptionToChangeColor] = useState(null);
  const [currentColorOptions, setCurrentColorOptions] = useState(null);
  const [currentCursor, setCurrentCursor] = useState([null, null]);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [isOptionsUpdated, setIsOptionsUpdated] = useState(false);

  // Keep track of current cell for select purposes
  //
  // This is a bug in the library,
  // the component is built using class
  // based components and the hook
  // setState causes undefined behaviour

  let selectedCells = {
    start: { i: 0, j: 0 },
    end: { i: 0, j: 0 },
  };

  let closePopOver = true;

  const colors = [
    [97, 197, 84],
    [255, 119, 241],
    [255, 154, 3],
    [140, 50, 255],
    [8, 154, 200],
    [147, 123, 144],
    [255, 3, 3],
    [3, 119, 255],
    [3, 255, 58],
    [3, 13, 255],
  ];
  const colorsRef = {
    0: "Green",
    1: "Pink",
    2: "Yellow",
    3: "Light Purple",
    4: "Light Blue",
    5: "Grey",
    6: "Salmon",
    7: "Blue",
    8: "Lime",
    9: "Purple",
  };

  const navigate = useNavigate();

  const getOptions = async () => {
    setLoading;
    const tablesRef = db.collection("TablesUI");
    const query = await tablesRef
      .where("name", "==", name)
      .where("parentName", "==", parentName)
      .where("businessId", "==", businessId)
      .get();

    if (query.empty) {
      // Add new colors to the dropdown
      const newOptions = options.map((option) => {
        if (option.isDropdown) {
          return {
            ...option,
            dropdown: {
              ...option.dropdown,
              // Give a random color assignment to new options
              options: option.dropdown.isIndependent
                ? option.dropdown.options.map((dropdownOption) => ({
                    optionName: dropdownOption.optionName,
                    color: colors[Math.floor(Math.random() * colors.length)],
                  }))
                : option.dropdown.options.map((dependentOption) => ({
                    ...dependentOption,
                    options: dependentOption.options.map((dropdownOption) => ({
                      optionName: dropdownOption.optionName,
                      color: colors[Math.floor(Math.random() * colors.length)],
                    })),
                  })),
            },
          };
        }
        return option;
      });

      setOptions([...newOptions]);

      // Save to firebase
      const docRef = db.collection("TablesUI").doc();
      const res = await docRef.set(
        {
          id: docRef.id,
          businessId,
          name,
          parentName,
          options: newOptions,
        },
        { merge: true }
      );

      setIsOptionsUpdated(true);
    } else {
      // BUG: If a new key is added - it doesn't update the colors and firebase
      // Compare existing options vs. options passed as props
      query.forEach(async (doc) => {
        setTableId(doc.id);
        const result = doc.data();

        const existingKeys = result.options.map((item) => item.key);
        const optionsMissing = options
          .filter((option) => option.isDropdown)
          .map((option) => option.key)
          .filter((key) => !existingKeys.includes(key));

        let newOptions = [];

        // New options added
        if (optionsMissing.length > 0) {
          newOptions = options
            .filter((option) => optionsMissing.includes(option.key))
            .map((option) => {
              if (option.isDropdown) {
                return {
                  ...option,
                  dropdown: {
                    ...option.dropdown,
                    // Give a random color assignment to new options
                    options: option.dropdown.isIndependent
                      ? option.dropdown.options.map((dropdownOption) => ({
                          optionName: dropdownOption.optionName,
                          color:
                            colors[Math.floor(Math.random() * colors.length)],
                        }))
                      : option.dropdown.options.map((dependentOption) => ({
                          ...dependentOption,
                          options: dependentOption.options.map(
                            (dropdownOption) => ({
                              optionName: dropdownOption.optionName,
                              color:
                                colors[
                                  Math.floor(Math.random() * colors.length)
                                ],
                            })
                          ),
                        })),
                  },
                };
              }
              return option;
            });
        }

        // Grab the existing color tags
        const allOptions = options
          .filter((option) => !optionsMissing.includes(option.key))
          .map((option) => {
            if (option.isDropdown) {
              const optionIndex = result.options.findIndex(
                (existingOption) => existingOption.key === option.key
              );

              if (optionIndex !== -1) {
                return {
                  ...option,
                  dropdown: {
                    ...option.dropdown,
                    // Differentiate between dependent and independent dropdown options
                    options: option.dropdown.isIndependent
                      ? _.map(option.dropdown.options, (obj) => {
                          const dropdownColors = _.find(
                            result.options[optionIndex].dropdown.options,
                            {
                              optionName: obj.optionName,
                            }
                          );
                          return _.assign(
                            obj,
                            dropdownColors || {
                              optionName: obj.optionName,
                              color: obj.color
                                ? colors[
                                    Math.floor(Math.random() * colors.length)
                                  ]
                                : colors[0],
                            }
                          );
                        })
                      : option.dropdown.options.map((dependentOption) => ({
                          dependantKey: dependentOption.dependantKey,
                          options: _.map(dependentOption.options, (obj) => {
                            // Complicated nested lookup for dependent dropdowns
                            const optionsMatch = result.options[
                              optionIndex
                            ].dropdown.options
                              .map((option) => {
                                const dropdownColors = _.find(option.options, {
                                  optionName: obj.optionName,
                                });
                                return dropdownColors;
                              })
                              .filter((option) => !!option);

                            if (optionsMatch.length > 0) {
                              return _.assign(obj, optionsMatch[0]);
                            }
                            return _.assign(obj, {
                              optionName: obj.optionName,
                              color:
                                colors[
                                  Math.floor(Math.random() * colors.length)
                                ],
                            });
                          }),
                        })),
                  },
                };
              }
            }
            return option;
          });

        const newAndExistingOptions = [...allOptions, ...newOptions];

        const diff =
          _.differenceWith(newAndExistingOptions, result.options, _.isEqual)
            .length > 0;

        if (diff) {
          // If more options are added or more keys are made then resave it
          const docRef = db.collection("TablesUI").doc(result.id);
          const res = await docRef.set(
            {
              id: docRef.id,
              businessId,
              name,
              parentName,
              options: newAndExistingOptions,
            },
            { merge: true }
          );
          setOptions([...allOptions]);
          setIsOptionsUpdated(true);
        } else {
          setOptions([...result.options]);
          setIsOptionsUpdated(true);
        }
      });
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  // useEffect(() => {
  //   const highlightedCells = Array.from(
  //     document.querySelectorAll('cell wrap selected')
  //   );
  //   console.log({ highlightedCells, length: highlightedCells.length });
  // }, []);

  /**
   * Find schema for existing data with no tableSchema
   */
  const findSchemaFromData = (row) =>
    Object.keys(row).map((key) => {
      if (typeof row[key] === "object") {
        if (Array.isArray(row[key])) {
          if (row[key].length > 0) {
            if (typeof row[key][0] !== "object") {
              return {
                key,
                type: "arrayOfValue",
                data: getDataType(row[key][0]),
              };
            }
            return { key, type: "arrayOfObject", data: "text" };
          }
          // Assume it's just an array of values
          return { key, type: "arrayOfValue", data: "text" };
        }
      }
      return { key, type: "value", data: getDataType(row[key]) };
    });

  /**
   * Update to the original data
   */
  const preprocessData = () => {
    const existingData = filterData(allData);
    if (existingData.length > 0) {
      const headings = Object.keys(existingData[0]);

      // Only find the schema if it's not there
      if (!schema && !tableSchema) {
        const types = findSchemaFromData(existingData[0]);
        setSchema([...types]);
      } else if (tableSchema) {
        setSchema([...tableSchema]);
      }

      // Only create columns if it's not there
      if (columns.length === 0) {
        const cols = headings.map((heading) => ({
          label: heading,
          width: `${(1 / headings.length) * 100}%`,
        }));
        setColumns([...cols]);
      }

      // Get all the rows for the existing data so adding a new row
      // copies the name and the type of data to be used
      if (!allDataRows) {
        const orginalDataTypes = findSchemaFromData(allData[0]);
        setAllDataRows([...orginalDataTypes]);
      } else {
        setAllDataRows([...allDataRows]);
      }

      const gridData = convertOriginalDataToGrid(existingData);
      const selects = existingData.map((_) => false);
      setGrid([...gridData]);
      setSelections([...selects]);
    } else if (tableSchema) {
      // If no existing data and tableSchema is specified then use the tableSchema
      setSchema([...tableSchema]);
      const headings = tableSchema.map((headerTypes) => headerTypes.key);
      const cols = headings.map((heading) => ({
        label: heading,
        width: `${(1 / headings.length) * 100}%`,
      }));
      setColumns([...cols]);
      if (dataSchema) {
        setAllDataRows([...dataSchema]);
      } else {
        setAllDataRows([...tableSchema]);
      }
    }

    setLoading(false);
  };

  //
  // Data functionality
  //    --> This has to update the table as well
  //
  const addImage = (imageUrl, cell) => {
    // Update using index is fine for allData
    const newData = allData.map((data, index) => {
      if (index === cell.index) {
        return {
          ...data,
          [cell.key]: imageUrl,
        };
      }
      return data;
    });

    appendToHistory(allData);

    setAllData([...newData]);
    setUpdating(true);
  };

  const convertOriginalDataToGrid = (dataToFlatten, globalIndex = undefined) =>
    dataToFlatten.map((row, index) =>
      Object.keys(row).map((cell) => {
        const cellOptions = options.filter((option) => option.key === cell);

        const rowDataSchema = allDataRows
          ? allDataRows[allDataRows.findIndex((column) => column.key === cell)]
          : null;
        if (typeof row[cell] === "object") {
          // Array of values
          if (Array.isArray(row[cell])) {
            const cellInfo = concatArrayToObject(cellOptions, {
              index: globalIndex !== undefined ? globalIndex : index,
              key: cell,
              value: row[cell].join(","),
              overflow: "wrap",
              type: allDataRows ? rowDataSchema.type : "arrayOfValue",
              readOnly: readOnly,
              data:
                row[cell].length > 0
                  ? getDataType(row[cell][0])
                  : allDataRows
                  ? rowDataSchema.data
                  : "text",
              forceComponent:
                cellOptions.length > 0
                  ? !!cellOptions[0].forceComponent
                  : false,
            });
            return cellInfo.isDropdown
              ? { ...cellInfo, component: selector(cellInfo) }
              : cellInfo;
          }
          // NOTE: Objects get translated into strings
          const cellInfo = concatArrayToObject(cellOptions, {
            index: globalIndex !== undefined ? globalIndex : index,
            key: cell,
            value: JSON.stringify(row[cell]),
            overflow: "wrap",
            type: allDataRows ? rowDataSchema.type : "value",
            readOnly: readOnly,
            data: "text",
          });
          return cellInfo.isDropdown
            ? { ...cellInfo, component: selector(cellInfo) }
            : cellInfo;
        }
        // Number / Text / All other data types
        const cellInfo = concatArrayToObject(cellOptions, {
          index: globalIndex !== undefined ? globalIndex : index,
          key: cell,
          value: row[cell],
          overflow: "wrap",
          type: allDataRows ? rowDataSchema.type : "value",
          readOnly: readOnly,
          data: row[cell] ? getDataType(row[cell]) : "text",
        });
        return cellInfo.isDropdown
          ? { ...cellInfo, component: selector(cellInfo) }
          : cellInfo;
      })
    );

  //
  // Table functionality
  //    --> This has to update the underlying data as well
  //

  // Add to history
  const appendToHistory = (data) => {
    // Don't allow redo if more changes have taken place
    setRedoData([]);

    // Limit the length of historical data to MAX_UNDOS
    if (historicalData.length < MAX_UNDOS) {
      setHistoricalData([...historicalData, data]);
      if (allowSoftDelete) {
        setHistoricalSoftDeleteData([...historicalSoftDeleteData, softDelete]);
      }
    } else {
      setHistoricalData([
        ...historicalData.slice(1, historicalData.length),
        data,
      ]);
      if (allowSoftDelete) {
        setHistoricalSoftDeleteData([
          ...historicalSoftDeleteData.slice(1, historicalData.length),
          softDelete,
        ]);
      }
    }
  };

  // Add rows
  const addRow = (rows = 1) => {
    getClipboardContent();
    if (convertGridDataToOriginal) {
      if (searchGrid.length === 0) {
        setNumberOfNewRows("1");
        console.log("Rows: ", rows);
        // appendToHistory(allData);

        if (tableSchema || schema) {
          const newGridRows = [].concat.apply(
            Array.from({ length: rows }).map((_) =>
              columns.map((column) => {
                const cellOptions = options.filter(
                  (option) => option.key === column.label
                );
                const index = tableSchema
                  ? tableSchema.findIndex((s) => s.key === column.label)
                  : schema.findIndex((s) => s.key === column.label);
                if (index !== -1) {
                  const cellInfo = concatArrayToObject(cellOptions, {
                    index: allData.length,
                    key: column.label,
                    value: "",
                    overflow: "wrap",
                    type: tableSchema
                      ? tableSchema[index].type
                      : schema[index].type,
                    data: tableSchema
                      ? tableSchema[index].data
                      : schema[index].data,
                  });
                  return cellInfo;
                }

                return {
                  index: allData.length,
                  key: column.label,
                  value: "",
                  overflow: "wrap",
                  type: "value",
                  data: "text",
                };
              })
            )
          );

          const newGrid = [...newGridRows, ...grid];
          setGrid(newGrid);

          const newDataRows = [].concat.apply(
            Array.from({ length: rows }).map((_) =>
              allDataRows.map((column) => ({
                index: allData.length,
                key: column.key,
                value: "",
                type: column.type,
                overflow: "wrap",
                data: column.data,
              }))
            )
          );

          console.log("New data rows: ", newDataRows);
          setAllData([...convertGridDataToOriginal(newDataRows), ...allData]);
        } else {
          const newGridRows = [].concat.apply(
            Array.from({ length: rows }).map((_) =>
              columns.map((column) => ({
                index: 0,
                key: column.label,
                value: "",
                type: "value",
                overflow: "wrap",
                data: "text",
              }))
            )
          );
          const newGrid = [...newGridRows, ...grid];
          setGrid(newGrid);

          const newDataRows = [].concat.apply(
            Array.from({ length: rows }).map((_) =>
              allDataRows.map((column) => ({
                index: 0,
                key: column.key,
                value: "",
                type: column.type,
                overflow: "wrap",
                data: column.data,
              }))
            )
          );
          setAllData([...convertGridDataToOriginal(newDataRows), ...allData]);
        }
        const s = [].concat.apply(
          Array.from({ length: rows }).map((_) => false)
        );
        setSelections([...s, ...selections]);
      }
    }
  };

  const setColumnWithIdAsEmpty = (doc, docType) => {
    if (docType === "grid") {
      return doc.map((row) =>
        row.map((data) => {
          if (columnsThatAreId.includes(data.key)) {
            return {
              ...data,
              value: "",
            };
          }
          return data;
        })
      );
    }
    if (docType === "data") {
      return doc.map((row) =>
        Object.keys(row).reduce((acc, val) => {
          if (columnsThatAreId.includes(val)) {
            return { ...acc, [val]: "" };
          }
          return { ...acc, [val]: row[val] };
        }, {})
      );
    }

    return doc;
  };

  // Delete rows
  const deleteRows = () => {
    if (searchGrid.length === 0) {
      appendToHistory(allData);

      if (allowSoftDelete) {
        const selectionsToDelete = selections
          .map((selection, index) => (selection === false ? -1 : index))
          .filter((index) => index !== -1);
        setSoftDelete(
          selectionsToDelete
            .filter((index) => index < allData.length)
            .map((index) => allData[index])
        );
      }

      const newSelections = selections
        .map((selection, index) => (selection === true ? -1 : index))
        .filter((index) => index !== -1);
      const newGrid = newSelections
        .filter((index) => index < grid.length)
        .map((index, id) =>
          grid[index].map((data) => ({ ...data, index: id }))
        );
      const newData = newSelections
        .filter((index) => index < allData.length)
        .map((index) => allData[index]);
      setGrid([...newGrid]);
      setAllData([...newData]);
      setSelections([...newData.map((_) => false)]);

      if (newData.length === 0) {
        setIsAllDelete(true);
      }
    }
  };

  // Select all rows using the checkbox on the title bar
  const handleSelectAllChanged = (selected) => {
    const select = selections.map((s) => selected);
    setSelections(select);
  };

  // Select a row using a checkbox
  const handleSelectChanged = (index, selected) => {
    console.log({ index, selected });
    const select = [...selections];
    select[index] = selected;
    setSelections(select);
  };

  // // Delete an unused column
  // const isRowEmpty = (row) => {
  //     return row.filter((cell) => (!(cell.value === '' || cell.value === 0) ? true : false)).length === 0;
  // };

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const updateScroll = (direction) => {
    const highlightedCells = Array.from(
      document.getElementsByClassName("cell wrap selected")
    ).sort((a, b) => a.getBoundingClientRect().y - b.getBoundingClientRect().y);
    let element = null;
    switch (direction) {
      case "DOWN":
        if (highlightedCells.length > 0) {
          element = highlightedCells[highlightedCells.length - 1];
        }
        break;
      case "UP":
        if (highlightedCells.length > 0) {
          const index = _.findLastIndex(
            highlightedCells,
            (elem) => elem.getBoundingClientRect().y < 0
          );
          element = highlightedCells[index > 0 ? index - 1 : 0];
        }
        break;
      default:
        break;
    }
    if (element) {
      // Check to see if we're away from the screen and scroll to it
      if (!isInViewport(element)) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const addRowWithThrottle = debounce(addRow, 100);

  const getClipboardContent = async () => {
    const text = await navigator.clipboard.readText();
    console.log(text);
  };

  // Shortcuts
  const onKeyPress = (event) => {
    if (!disableShortcuts) {
      switch (event.keyCode) {
        // ESCAPE - Unselect all selected cells
        case 27:
          setSelections([...selections.map((_) => false)]);
          break;
        // ENTER - Add a new row
        case 13:
          if (event.ctrlKey) {
            addRowWithThrottle();
          }
          break;
        // CTRL + B or CMD + B - Duplicate a row
        case 66:
          if (event.ctrlKey || event.metaKey) {
            const { i } = selectedCells.start;
            const newData = [
              ...allData.slice(0, i),
              ...allData.slice(i, i + 1),
              ...setColumnWithIdAsEmpty(allData.slice(i, i + 1), "data"),
              ...allData.slice(i + 1, allData.length),
            ];
            // Update the grid and the index
            const newGrid = [
              ...grid.slice(0, i),
              ...grid.slice(i, i + 1),
              ...setColumnWithIdAsEmpty(grid.slice(i, i + 1), "grid"),
              ...grid.slice(i + 1, allData.length),
            ].map((row, rowIndex) =>
              row.map((col) => ({
                ...col,
                index: rowIndex,
              }))
            );
            selectedCells.start.i = i + 1;
            appendToHistory(allData);
            setAllData([...newData]);
            setGrid([...newGrid]);
          }
          break;
        // CTRL + Z or CMD + Z - Undo a previous change
        case 90:
          if (event.ctrlKey || event.metaKey) {
            if (historicalData.length > 2) {
              const lastIndex = historicalData.length - 1;
              setAllData([...historicalData[lastIndex - 1]]);
              setHistoricalData([...historicalData.slice(0, lastIndex)]);
              setRedoData([...redoData, historicalData[lastIndex]]);

              // TODO: Test this?
              if (allowSoftDelete) {
                setSoftDelete([...historicalSoftDeleteData[lastIndex - 1]]);
                setHistoricalSoftDeleteData([
                  ...historicalSoftDeleteData.slice(0, lastIndex),
                ]);
                setRedoSoftDeleteData([
                  ...redoSoftDeleteData,
                  historicalSoftDeleteData[lastIndex],
                ]);
              }
            }
          }
          break;
        // TODO: How do you figure out a redo on softDelete data
        // CTRL + Y or CMD + Y - Redo an undo
        case 89:
          if (event.ctrlKey || event.metaKey) {
            if (redoData.length > 0) {
              const lastIndex = redoData.length - 1;
              if (lastIndex !== -1 || lastIndex < redoData.length) {
                setAllData([...redoData[lastIndex]]);
                setHistoricalData([...historicalData, redoData[lastIndex]]);
                setRedoData([...redoData.slice(0, lastIndex)]);

                // TODO: Test this?
                if (
                  allowSoftDelete &&
                  lastIndex < redoSoftDeleteData.length + 1 &&
                  lastIndex > 0
                ) {
                  setSoftDelete([...redoSoftDeleteData[lastIndex - 1]]);
                  setHistoricalSoftDeleteData([
                    ...historicalSoftDeleteData,
                    redoSoftDeleteData[lastIndex],
                  ]);
                  setRedoSoftDeleteData([
                    ...redoSoftDeleteData.slice(0, lastIndex),
                  ]);
                }
              }
            }
          }
          break;
        // DELETE - Remove rows
        case 8:
          // CTRL + Delete or CMD + Delete - Remove all unused rows
          // if (event.ctrlKey || event.metaKey) {
          //     const newGrid = grid.filter((_, index) =>
          //         grid
          //             .map((row, index) => (isRowEmpty(row) ? -1 : index))
          //             .filter((_, index) => index !== -1)
          //             .includes(index),
          //     );
          //     const newData = allData.filter((_, index) =>)
          //     appendToHistory(allData);
          //     setGrid([...newGrid]);
          //     setAllData([...convertGridDataToOriginal(newGrid)]);
          //     setSelections([...newGrid.map((_) => false)]);
          //     // Shift + Delete - Remove all selected rows
          // } else if (event.shiftKey) {
          if (event.shiftKey) {
            deleteRows();
          }
          break;
        // SELECT COLUMN
        // CTRL + Shift + Down Arrow or CMD + Shift + Down Arrow - Select all cells below
        case 40:
          if (
            (event.ctrlKey && event.shiftKey) ||
            (event.metaKey && event.shiftKey)
          ) {
            //
            // BUG: If selectedCells.start doesn't change smartly then it selects the wrong range.
            //      This can only be fixed from the library side to support hooks.
            //
            console.log("Does this get triggered?");
            setSelections([
              ...selections.map((_, index) => index >= selectedCells.start.i),
            ]);
            // DOWN - Go down
          } else {
            updateScroll("DOWN");
          }
          break;
        // CTRL + Shift + Up Arrow or CMD + Shift + Up Arrow - Select all cells above
        case 38:
          if (
            (event.ctrlKey && event.shiftKey) ||
            (event.metaKey && event.shiftKey)
          ) {
            //
            // BUG: If selectedCells.start doesn't change smartly then it selects the wrong range.
            //      This can only be fixed from the library side to support hooks.
            //
            setSelections([
              ...selections.map((_, index) => index <= selectedCells.start.i),
            ]);
            // UP - Go up
          } else {
            updateScroll("UP");
          }
          break;
        // CTRL + Shift + Right Arrow or CMD + Shift + Right Arrow - Select all cells in the row
        case 39:
          if (
            (event.ctrlKey && event.shiftKey) ||
            (event.metaKey && event.shiftKey)
          ) {
            setSelections([
              ...selections.map(
                (_, index) =>
                  !!(
                    index >= selectedCells.start.i &&
                    index <= selectedCells.end.i
                  )
              ),
            ]);
          }
          break;
        default:
          break;
      }
    }
  };

  const handleRowUpdateByModal = (row) => {
    // Update the data in the search modal if applicable
    setAllData([
      ...allData.map((data, index) => {
        if (openModalIndex === index) {
          return row;
        }
        return data;
      }),
    ]);
    setUpdating(true);
  };

  const handleFilter = (gridData, filtering = null) => {
    console.log("gridData", gridData);
    if (filtering || isFiltered) {
      console.log("columnHeadingConversions", columnHeadingConversions);
      const reverseColumnLookup = Object.keys(columnHeadingConversions).reduce(
        (acc, val) => ({ ...acc, [columnHeadingConversions[val]]: val }),
        {}
      );
      console.log("reverseColumnLookup", reverseColumnLookup);
      const searchResults = gridData.filter((row) => {
        if (columns.length > 0) {
          console.log("columns", columns);
          const index = columns.findIndex(
            (column) => column.label === reverseColumnLookup[columnToFilter]
          );
          if (index !== -1) {
            const normalizedTitle = row[index].value;
            console.log("normalizedTitle", normalizedTitle);
            const normalizedQuery = filterableColumnOptions;
            console.log("normalizedQuery", normalizedTitle);
            console.log(
              "normalizedTitle.split(',').some((word) => normalizedQuery.includes(word))",
              normalizedTitle
                .split(",")
                .some((word) => normalizedQuery.includes(word))
            );
            return normalizedTitle
              .split(",")
              .some((word) => normalizedQuery.includes(word));
          }
        }
        return false;
      });

      return searchResults;
    }
    return gridData;
  };

  const handleSearch = (event) => {
    event.preventDefault();

    const textToSearch = event.target.value;
    setSearchText(textToSearch);
    if (event.target.value === "" || event.target.value === null) {
      if (isFiltered) {
        setSearchGrid([...handleFilter(grid, true)]);
      } else {
        setSearchGrid([]);
      }
      setIsSearching(false);
    } else {
      const searchResults = grid.filter((row) => {
        if (columns.length > 0) {
          const index = columns.findIndex(
            (column) => column.label === searchColumnName
          );
          if (index !== -1) {
            const rowValues = row[index].value.includes(",")
              ? row[index].value.split(",").join(" ").toLowerCase()
              : Array.isArray(row[index].value)
              ? row[index].value.join(" ").toLowerCase()
              : row[index].value.toLowerCase();

            const normalizedTitle = rowValues;
            const normalizedQuery = textToSearch
              .toLowerCase()
              .split(" ")
              .filter((word) => word.length > 0);

            return normalizedTitle
              .toLowerCase()
              .split(" ")
              .some(
                (word) =>
                  normalizedQuery.filter((query) => word.startsWith(query))
                    .length > 0
              );
          }
        }
        return false;
      });

      if (searchResults) {
        const results = handleFilter(searchResults);
        if (results) {
          setSearchGrid([...results]);
        }
      }
      setIsSearching(true);
    }
  };

  const convertGridDataToOriginal = (grid) => {
    const newData = grid.map((row) =>
      row.reduce(
        (acc, val) => ({
          ...acc,
          [val.key]: findCorrectValue(val.value, val.type, val.data) || "",
        }),
        {}
      )
    );
    return newData;
  };

  // For dependent dropdown
  const findDependantOptions = (info) => {
    if (info.index < allData.length) {
      const independentRow = allData[info.index];
      const { dependentColumn } = info.dropdown;
      if (Object.keys(independentRow).includes(dependentColumn)) {
        const independentValue = independentRow[dependentColumn];
        const optionIndex = info.dropdown.options.findIndex(
          (option) => option.dependantKey === independentValue
        );
        if (optionIndex !== -1) {
          return info.dropdown.options[optionIndex].options;
        }
        return info.dropdown.options[0].options;
      }
    }

    return null;
  };

  const removeAllEmptyRows = (data) => {
    const isEmptyRow = (doc) => {
      for (const key of Object.keys(doc)) {
        const cellData = doc[key];

        // Arrays, objects, null and undefined
        if (typeof cellData === "object") {
          if (Array.isArray(cellData)) {
            if (cellData.length > 0) {
              return false;
            }

            if (cellData !== null || cellData !== undefined) {
              if (Object.keys(cellData).length > 0) {
                return false;
              }
            }
          }

          // String and number
        } else {
          if (typeof cellData === "string" && cellData.length > 0) {
            return false;
            // 0 is set as the default number to ignore
          }
          if (typeof cellData === "number" && cellData !== 0) {
            return false;
          }
        }
      }
      return true;
    };

    return data.filter((row) => !isEmptyRow(row));
  };

  // For dependent columns
  const findSubOptions = (info, allData) => {
    // Find dropdown options if dropdown is dependant on another column
    if (info.index < allData.length) {
      const independentRow = allData[info.index];
      const { independentColumn } = info.dependantColumn;
      if (Object.keys(independentRow).includes(independentColumn)) {
        const independentValue = independentRow[independentColumn];
        const optionIndex = info.dependantColumn.data.findIndex(
          (option) => option.type === independentValue
        );
        if (optionIndex !== -1) {
          return info.dropdown.options[optionIndex].options;
        }
      }
    }

    return [];
  };
  const CustomOption = ({ children, ...props }) => (
    <components.Option {...props}>
      <span>{children}</span>
    </components.Option>
  );
  const SingleValue = ({ children, ...props }) => {
    const selectedOption = props.options.find(
      (item) => item.value === props.data.value
    );
    // New rows don't have selectedOption
    if (selectedOption) {
      return (
        <components.SingleValue {...props}>
          <span>{children}</span>
        </components.SingleValue>
      );
    }
    return (
      <components.SingleValue {...props}>
        <span />
      </components.SingleValue>
    );
  };
  const selector = (info) => (
    <Select
      className="table-cell-select"
      classNamePrefix="table-cell-select"
      autoFocus
      // openOnFocus
      // isClearable
      isSearchable
      // defaultMenuIsOpen
      isMulti={info.dropdown.isMultiSelect}
      closeOnSelect={!info.dropdown.isMultiSelect}
      // tabSelectsValue
      // menuIsOpen
      backspaceToRemoveMessage=""
      onSelectResetsInput={false}
      // openOnFocus
      value={
        info.dropdown.isMultiSelect
          ? allData[info.index][info.key].map((data) => ({
              label: data,
              value: data,
            }))
          : { label: info.value, value: info.value }
      }
      // captureMenuScroll
      components={{
        Option: CustomOption,
        SingleValue,
        DropdownIndicator: () => <Icon icon={"chevron-down"} />,
        IndicatorSeparator: null,
      }}
      onChange={(opt, meta) => {
        let newData;

        // Find the index depending on whether we're in the allData view or the filtered view
        // const rowIndex = searchGrid.length > 0 ? (searchGrid[info.index] ? searchGrid[info.index][0].index : info.index) : info.index;
        const rowIndex = info.index;

        // Removing single value
        if (meta.action === "remove-value") {
          newData = allData.map((data, index) => {
            // Updating index on allData is fine
            if (index === rowIndex) {
              return {
                ...data,
                [info.key]: info.dropdown.isMultiSelect
                  ? allData[info.index][info.key].filter(
                      (item) => item !== meta.removedValue.value
                    )
                  : opt.value,
              };
            }
            return data;
          });
        } else {
          // Removing all values
          if (opt.length === 0) {
            // Updating index on allData is fine
            newData = allData.map((data, index) => {
              if (index === rowIndex) {
                return {
                  ...data,
                  [info.key]: info.dropdown.isMultiSelect ? [] : opt.value,
                };
              }
              return data;
            });
          } else {
            // Adding a new value
            // Updating index on allData is fine
            newData = allData.map((data, index) => {
              if (index === rowIndex) {
                return {
                  ...data,
                  [info.key]: info.dropdown.isMultiSelect
                    ? [...opt.map((item) => item.value)]
                    : opt.value,
                };
              }
              return data;
            });
          }
        }

        appendToHistory(allData);
        setAllData([...newData]);
        setUpdating(true);
      }}
      options={
        info.dropdown.isIndependent
          ? info.dropdown.options.map((option) => ({
              label: option.optionName,
              value: option.optionName,
              color: option.color,
            }))
          : info.dropdown.isDependantColumn
          ? findSubOptions(info, allData).map((option) => ({
              label: option.optionName,
              value: option.optionName,
              color: option.color,
            }))
          : findDependantOptions(info)
          ? findDependantOptions(info).map((option) => ({
              label: option.optionName,
              value: option.optionName,
              color: option.color,
            }))
          : []
      }
    />
  );

  const changeColorTag = async (index, color) => {
    const allColorsRef =
      currentColorOptions.length > 0 &&
      !currentColorOptions[0].dropdown.isIndependent
        ? currentColorOptions[0].dropdown.options
            .reduce((acc, val) => [...acc, ...val.options], [])
            .map((existingColor, colorIndex) => {
              if (colorIndex === index) {
                return { optionName: existingColor.optionName, color };
              }
              return existingColor;
            })
        : [];

    // Make changes locally
    const newOptions = options.map((option) => {
      if (option.key === currentColumn) {
        if (option.dropdown.isIndependent) {
          return {
            ...option,
            dropdown: {
              ...option.dropdown,
              // Give a random color assignment to new options
              options: option.dropdown.options.map((dropdownOption) => {
                if (
                  dropdownOption.optionName ===
                  currentColorOptions[0].dropdown.options[index].optionName
                ) {
                  return { optionName: dropdownOption.optionName, color };
                }
                return dropdownOption;
              }),
            },
          };
        }
        return {
          ...option,
          dropdown: {
            ...option.dropdown,
            options: option.dropdown.options.map((dropdownOption) => ({
              dependantKey: dropdownOption.dependantKey,
              options: dropdownOption.options.map((optionColor) => {
                const optionIndex = allColorsRef.findIndex(
                  (newColor) => newColor.optionName === optionColor.optionName
                );
                return allColorsRef[optionIndex];
              }),
            })),
          },
        };
      }
      return option;
    });
    setOptions([...newOptions]);

    if (tableId) {
      const docRef = db.collection("TablesUI").doc(tableId);
      await docRef.update({
        options: newOptions,
      });
    }

    setIsOptionsUpdated(true);
    setUpdating(true);
  };

  /*
   * The use of the two useEffects below seem to lead to only the satisfying behaviour
   * when dealing with updating a cell with multiple values in a dropdown.
   * The reasons to which are unknown and quite possibly never be understood,
   * but as every wise software engineer knows, do not touch what isn't broken :)
   */
  useEffect(() => {
    // isOptionsUpdated is always true after
    // the options is downloaded from firebase
    // in the initial mount -- This should only be called once
    if (loading && isOptionsUpdated) {
      preprocessData();

      const filterableOptions = options
        .filter(
          (option) =>
            option.isDropdown && !!columnHeadingConversions[option.key]
        )
        .reduce(
          (acc, option) => ({
            ...acc,
            [columnHeadingConversions[option.key]]: option.dropdown.options.map(
              (dropdownOptions) => dropdownOptions.optionName
            ),
          }),
          {}
        );
      const idColumns = options
        .filter((option) => option.isPrimaryId)
        .map((option) => option.key);

      // Keys that are dropdown in options but specifically for the modal won't render here
      setAllFilterableColumns([
        ...Object.keys(filterableOptions).filter((option) =>
          filterableOptions[option].some((item) => !!item)
        ),
      ]);
      setAllFilterableColumnOptions(filterableOptions);
      setColumnsThatAreId(idColumns);
      console.log("filterableOptions", filterableOptions);
    }
  }, [loading, isOptionsUpdated]);

  // TODO: Problematic
  useEffect(() => {
    const existingData = filterData(allData);
    const gridData = convertOriginalDataToGrid(existingData);
    setGrid([...gridData]);

    if (loading && isOptionsUpdated) {
      setLoading(false);
    }

    // TODO: Figure out a way to do fast safe checks???
    // Check for errors only when new input to cell
    // verifySave(false)
  }, [allData]);

  useEffect(() => {
    if (updating) {
      setUpdating(false);

      // Update the dropdown data in the search grid if it's length > 0
      if (searchGrid.length > 0) {
        setSearchGrid([
          ...searchGrid.map((data) => {
            const index = data.length > 0 ? data[0].index : -1;
            if (index !== -1) {
              const newData = convertOriginalDataToGrid(
                filterData([allData[index]]),
                index
              )[0];
              return newData;
            }
            return data;
          }),
        ]);
      }
    }
  }, [updating]);

  useEffect(() => {
    const saveData = async () => {
      const errorResult = verifySave();
      if (!errorResult) {
        setSaveLoading(true);
        const saved = await saveToFirebase();
        // Reset historical data after save
        setHistoricalData([allData]);
        setSaveLoading(false);
        if (saved) {
          setShowSuccessModal(true);
        }
      }
      setSaveClicked(false);
    };

    if (saveClicked) {
      saveData();
    }
  }, [saveClicked]);

  //
  // Debug functionality
  //

  const convertDataFromSchema = (data, schema) =>
    data.map((row) =>
      row.map((cell, index) => {
        const cellOptions = options.filter((option) => option.key === cell.key);
        const cellInfo = concatArrayToObject(cellOptions, {
          index,
          key: cell.key,
          value: cell.value,
          overflow: cell.overflow,
          type: schema[index].type,
          data: schema[index].data,
        });
        return cellInfo.isDropdown
          ? { ...cellInfo, component: selector(cellInfo) }
          : cellInfo;
      })
    );

  // Error functionality
  const getErrorMessage = (error, columnName) => {
    if (error.rowIndex < allData.length) {
      const name = allData[error.rowIndex][columnName];
      if (error.errorType === "duplicateRows") {
        return `Duplicate Rows - It looks like there are identical rows for ${name}`;
      }
      if (error.errorType === "wrongDataType") {
        return `Wrong Data - It looks like there are cells with wrong information for ${name}`;
      }
      if (error.errorType === "missingCells") {
        return `Missing Cells - It looks like there are empty cells for ${name}`;
      }
      return `Something looks wrong for ${name}`;
    }
  };

  // DEBUG := Schema update
  useEffect(() => {
    // Reflect the schema in the update
    if (schemaUpdated) {
      if (schema.length === grid[0].length) {
        const newGrid = convertDataFromSchema(grid, schema);
        setGrid([...newGrid]);
        setSchemaUpdated(false);
      }
    }
  }, [schemaUpdated]);

  if (loading) {
    return (
      <div style={{ marginTop: 100, width: "100%", height: "100%" }}>
        <Spinner size={40} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20,
          }}
        >
          Loading...
        </div>
      </div>
    );
  }

  if (updating) {
    // Placeholder table for updating
    return (
      <>
        <div
          className="button"
          style={{ textAlign: "right", marginBottom: "20px" }}
        >
          <motion.button className="add-row">Add Row</motion.button>
          {selections.filter((selection) => selection).length > 0 && (
            <button className="delete-row" style={{ marginLeft: 10 }}>
              Delete Row
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {hasSearch && (
            <div className="search-box" style={{ display: "inline-flex" }}>
              <div style={{ alignItems: "center", display: "flex" }}>
                {showSearchName && (
                  <div className="search-column-name">
                    {name}
                    {/* Object.keys(columnHeadingConversions).includes(searchColumnName)
                                        ? columnHeadingConversions[searchColumnName]
                                    : _.startCase(_.camelCase(searchColumnName)) */}
                  </div>
                )}

                {customerComponentNextToSearch || null}
                <input
                  type="text"
                  defaultValue={searchText}
                  placeholder={
                    `Search ${Object.keys(columnHeadingConversions).includes(
                      searchColumnName
                    )}`
                      ? columnHeadingConversions[searchColumnName]
                      : _.startCase(_.camelCase(searchColumnName))
                  }
                />
              </div>
              <Icon icon="search" iconSize={12} color="#454545" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {hasFilter && (
              <div
                className="filterDropdown"
                style={{ display: "inline-flex" }}
              >
                <Menu>
                  <ButtonGroup>
                    <MenuItem
                      icon="properties"
                      text="Filter"
                      popoverProps={{ position: Position.BOTTOM_LEFT }}
                    >
                      <div className="filterInfo" style={{ padding: 13 }}>
                        <div
                          style={{
                            fontSize: "10px",
                            fontFamily: "SFProText-Medium",
                            color: "#969696",
                            marginBottom: "10px",
                          }}
                        >
                          Filter by:
                        </div>
                        <div
                          style={{
                            fontSize: "10px",
                            fontFamily: "SFProText-Medium",
                            color: "#969696",
                            marginBottom: "10px",
                            marginTop: "26px",
                          }}
                        >
                          Filter on:
                        </div>
                        <div style={{ textAlign: "center", marginTop: "12px" }}>
                          <button className="applyFilter">Apply Filter</button>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <button className="clearFilter">Clear Filter</button>
                        </div>
                      </div>
                    </MenuItem>
                  </ButtonGroup>
                </Menu>
              </div>
            )}
            <Button
              text="Save"
              className={
                historicalData.length > 1 ? "save-button active" : "save-button"
              }
            />
          </div>
        </div>
        <ReactDataSheet
          data={isSearching || isFiltered ? searchGrid : grid}
          onSelect={(cells) => {
            selectedCells = cells;
          }}
          valueRenderer={(cell) => cell.value}
          isCellNavigable={(cell, row, col) => true}
          sheetRenderer={(props) => (
            <SheetRenderer
              columns={columns}
              selections={selections}
              onSelectAllChanged={handleSelectAllChanged}
              as="table"
              headerAs="thead"
              bodyAs="tbody"
              rowAs="tr"
              cellAs="th"
              showModalColumn={hasModal}
              setCurrentColumn={setCurrentColumn}
              setCurrentCursor={setCurrentCursor}
              setCurrentColorOptions={setCurrentColorOptions}
              options={options}
              columnHeadingConversions={columnHeadingConversions}
              {...props}
            />
          )}
          rowRenderer={(props) => (
            <RowRenderer
              as="tr"
              cellAs="td"
              rowSelected={selections[props.row]}
              onSelectChanged={handleSelectChanged}
              className="data-row"
              showModalRow={hasModal}
              setIndexOfModalToOpen={setOpenModalIndex}
              isModalViewOnly={isModalViewOnly}
              {...props}
            />
          )}
          cellRenderer={(props) => {
            return (
              <CellRenderer
                as="td"
                columns={columns}
                grid={grid}
                errorManager={errorManager}
                {...props}
              />
            );
          }}
        />
      </>
    );
  }

  const addRowModal = (
    <div className="add-row-modal">
      <div
        className="add-row-header"
        style={{ padding: 16, fontFamily: "SFProText-Bold" }}
      >
        How many rows would you like to add?
      </div>
      <IncrementDecrementCounter
        count={numberOfNewRows}
        setCount={(count) => {
          if (!isNaN(count)) {
            setNumberOfNewRows(count);
          } else {
            setNumberOfNewRows("1");
          }
        }}
      />
      <br />
      <div
        className="add-button"
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Button onClick={() => addRow(Number(numberOfNewRows))}>Add</Button>
      </div>
      <br />
    </div>
  );

  return (
    <div
      className="table"
      style={{
        display: "block",
      }}
      tabIndex="0"
      onKeyDown={onKeyPress}
      onClick={() => {
        if (
          (currentCursor[0] && optionToChangeColor) ||
          (currentCursor[0] && closePopOver)
        ) {
          setCurrentCursor([null, null]);
          setCurrentColorOptions(null);
          setCurrentColumn(null);
          setOptionToChangeColor(null);
          closePopOver = true;
        }
      }}
    >
      {/* Buttons to show only while debugging */}
      {debug && (
        <>
          <div className="button" style={{ textAlign: "left", margin: 10 }}>
            <button
              className="changeSchema"
              onClick={() => {
                setIsEditSchema(!isEditSchema);
                setSchemaUpdated(true);
              }}
            >
              {isEditSchema ? "Accept Schema" : "Edit Schema"}
            </button>
            <br /> <br />
            <button
              className="showPreview"
              onClick={() => {
                setIsShowPreview(!isShowPreview);
              }}
            >
              Show Preview
            </button>
          </div>
        </>
      )}

      {isShowPreview && (
        <ShowPreview
          data={allData}
          isOpen={isShowPreview}
          onClose={() => setIsShowPreview(false)}
        />
      )}

      {/* Table functionality to add row, delete row, search, filter and save table */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {showSearchName && (
          <>
            {showBackArrow ? (
              <div className="subheader-left">
                <img
                  src={BackArrowIcon}
                  className={"back-button"}
                  onClick={goBack}
                />
                <div className="text-lg text-black font-lato-black">{name}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {customComponentsNextToTitle || null}
                </div>
              </div>
            ) : (
              <div className="search-column-name">{name}</div>
            )}

            {/* Object.keys(columnHeadingConversions).includes(searchColumnName)
                                        ? columnHeadingConversions[searchColumnName]
                                    : _.startCase(_.camelCase(searchColumnName)) */}
          </>
        )}
        {/* Search */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="search-box" style={{ display: "inline-flex" }}>
            {hasSearch && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {customerComponentNextToSearch || null}
                <div style={{ position: "relative" }}>
                  <Icon icon="search" iconSize={12} color="#7C7F93" />
                  <input
                    className="search"
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder={
                      searchPlaceHolderText ||
                      (`Search ${Object.keys(columnHeadingConversions).includes(
                        searchColumnName
                      )}`
                        ? columnHeadingConversions[searchColumnName]
                        : _.startCase(_.camelCase(searchColumnName)))
                    }
                    style={{ color: "#454545" }}
                  />
                </div>
              </div>
            )}

            <div
              className="button"
              style={{
                textAlign: "right",
                marginLeft: hasSearch ? "20px" : "0px",
                display: "flex",
              }}
            >
              {/*
            <div style={{ position: "relative" }}>
              <Icon icon="add" iconSize={14} color="#454545" />
              <button
                className="add-teammate"
                onClick={() => navigate("add-employee")}
              >
                Add Employee
              </button>
            </div>
            */}
            </div>
            <div
              className="button"
              style={{
                textAlign: "right",
                marginLeft: hasSearch ? "20px" : "0px",
                display: "flex",
              }}
            >
              {!readOnly ? (
                <div style={{ position: "relative" }}>
                  <Popover2
                    className="add-row-popover"
                    interactionKind={"hover"}
                    content={addRowModal}
                    autoFocus={false}
                    onClose={() => {
                      // addRow(numberOfNewRows);
                    }}
                  >
                    <button className={"add-row-btn"}>Add Row</button>
                  </Popover2>
                </div>
              ) : null}
              {selections.filter((selection) => selection).length > 0 && (
                <button
                  className="delete-row"
                  onClick={deleteRows}
                  style={{ marginLeft: 10 }}
                >
                  Delete Row
                </button>
              )}
              {exportCsv ? (
                <motion.button
                  onClick={exportCsv}
                  className="add-row"
                  style={{
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Export as CSV
                  {exportCsvLoading ? <Spinner size={15} /> : null}
                </motion.button>
              ) : null}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* Filter */}

            {hasFilter && (
              <div
                className="filterDropdown"
                style={{ display: "inline-flex" }}
              >
                <Menu>
                  <ButtonGroup>
                    <MenuItem
                      icon="properties"
                      text="Filter"
                      popoverProps={{ position: Position.BOTTOM_LEFT }}
                    >
                      <div className="filterInfo" style={{ padding: 13 }}>
                        <div
                          style={{
                            fontSize: "10px",
                            fontFamily: "SFProText-Medium",
                            color: "#969696",
                            marginBottom: "10px",
                          }}
                        >
                          Filter by:
                        </div>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={allFilterableColumns.map((column) => ({
                            label: column,
                            value: column,
                          }))}
                          value={{
                            label: columnToFilter,
                            value: columnToFilter,
                          }}
                          onChange={(opt) => {
                            setColumnToFilter(opt.value);
                            setFilterableColumnOptions([]);
                          }}
                          components={{
                            SingleValue: ({ children, ...props }) => (
                              <components.SingleValue {...props}>
                                <span
                                  style={{
                                    backgroundColor: props.data.label
                                      ? `rgba(147, 123, 144, 0.2)`
                                      : "transparent",
                                  }}
                                >
                                  {children}
                                </span>
                              </components.SingleValue>
                            ),
                            Option: ({ children, ...props }) => (
                              <components.Option {...props}>
                                <span
                                  style={{
                                    backgroundColor: `rgba(147, 123, 144, 0.2)`,
                                  }}
                                >
                                  {children}
                                </span>
                              </components.Option>
                            ),
                          }}
                        />
                        <div
                          style={{
                            fontSize: "10px",
                            fontFamily: "SFProText-Medium",
                            color: "#969696",
                            marginBottom: "10px",
                            marginTop: "26px",
                          }}
                        >
                          Filter on:
                        </div>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isMulti
                          options={
                            allFilterableColumnOptions[columnToFilter]
                              ? allFilterableColumnOptions[columnToFilter].map(
                                  (column) => ({ label: column, value: column })
                                )
                              : []
                          }
                          value={filterableColumnOptions.map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          onChange={(opt, meta) => {
                            // Remove single value
                            if (meta.action === "remove-value") {
                              setFilterableColumnOptions([
                                ...filterableColumnOptions.filter(
                                  (item) => item !== meta.removedValue.value
                                ),
                              ]);
                            } else {
                              // Removing all values
                              if (opt.length === 0) {
                                setFilterableColumnOptions([]);
                                // Adding a new value
                              } else {
                                setFilterableColumnOptions([
                                  ...opt.map((item) => item.value),
                                ]);
                              }
                            }
                          }}
                          components={{
                            Option: ({ children, ...props }) => {
                              const keyIndex = Object.values(
                                columnHeadingConversions
                              ).findIndex((item) => item === columnToFilter);
                              const keyName = Object.keys(
                                columnHeadingConversions
                              )[keyIndex];

                              const foundSchema = options.find(
                                (item) => item.key === keyName
                              );
                              const selectedOption =
                                foundSchema.dropdown.options.find(
                                  (item) => item.optionName === props.data.label
                                );

                              if (
                                Object.keys(selectedOption).includes("color")
                              ) {
                                return (
                                  <components.Option {...props}>
                                    <span
                                      style={{
                                        backgroundColor: `rgba(${selectedOption.color.join(
                                          ","
                                        )},0.2)`,
                                      }}
                                    >
                                      {children}
                                    </span>
                                  </components.Option>
                                );
                              }
                            },
                          }}
                          styles={{
                            multiValue: (styles, { data }) => {
                              const keyIndex = Object.values(
                                columnHeadingConversions
                              ).findIndex((item) => item === columnToFilter);
                              const keyName = Object.keys(
                                columnHeadingConversions
                              )[keyIndex];

                              const foundSchema = options.find(
                                (item) => item.key === keyName
                              );
                              const selectedOption =
                                foundSchema.dropdown.options.find(
                                  (item) => item.optionName === data.label
                                );
                              return {
                                ...styles,
                                backgroundColor: `rgba(${selectedOption.color.join(
                                  ","
                                )},0.2)`,
                              };
                            },
                          }}
                        />
                        <div style={{ textAlign: "center", marginTop: "12px" }}>
                          <button
                            className="applyFilter"
                            onClick={() => {
                              setIsFiltered(true);
                              if (isSearching) {
                                setSearchGrid([
                                  ...handleFilter(searchGrid, true),
                                ]);
                              } else {
                                setSearchGrid([...handleFilter(grid, true)]);
                              }
                            }}
                          >
                            Apply Filter
                          </button>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <button
                            className="clearFilter"
                            onClick={() => {
                              setIsFiltered(false);
                              setFilterableColumnOptions([]);
                              setColumnToFilter(null);
                              // Remove all values from searchGrid if not filtering
                              if (!isSearching) {
                                setSearchGrid([]);
                              } else {
                                setSearchGrid([
                                  ...handleFilter(searchGrid, true),
                                ]);
                              }
                            }}
                          >
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </MenuItem>
                  </ButtonGroup>
                </Menu>
              </div>
            )}
            {!readOnly ? (
              <Button
                loading={saveLoading}
                text="Save"
                className={
                  historicalData.length > 1
                    ? "save-button active"
                    : "save-button"
                }
                onClick={async () => {
                  const cleanedData = removeAllEmptyRows(allData);

                  // Saving is being done in a useEffect because
                  // all data has to update with cleanedData before
                  // being able to be saved
                  setAllData(cleanedData);
                  setSaveClicked(true);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* Color editor via column */}

      {currentColumn && (
        <div
          className="dropdownTagEditor"
          style={{
            position: "fixed",
            width: "242px",
            height: 200,
            left: currentCursor[0] ? currentCursor[0] : 100,
            top: currentCursor[1] ? currentCursor[1] : 100,
            backgroundColor: "#ffffff",
            filter: "drop-shadow(4px 4px 2px #c3c3c3)",
            color: "#000",
            zIndex: 100,
            paddingTop: 10,
            overflow: "scroll",
          }}
        >
          {/* Color picking is different for independent and dependant dropdowns */}
          <div
            style={{
              fontSize: "10px",
              fontFamily: "SFProText-Medium",
              color: "#969696",
              padding: "5px 13px",
            }}
          >
            Select an option to change color
          </div>
          {(currentColorOptions[0].dropdown.isIndependent
            ? currentColorOptions[0].dropdown.options
            : currentColorOptions[0].dropdown.options.reduce(
                (acc, val) => [...acc, ...val.options],
                []
              )
          ).map((colorOption, index) => (
            <div
              className={`currentOption ${index}`}
              onClick={() => {
                closePopOver = false;
                setOptionToChangeColor(index);
              }}
              style={{
                backgroundColor: index === optionToChangeColor && "#ADD8E6",
                color: index === optionToChangeColor && "white",
                display: "flex",
                padding: "5px 13px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                }}
              >
                <div
                  className="color-text"
                  style={{
                    backgroundColor: `rgba(${colorOption.color[0]}, ${colorOption.color[1]}, ${colorOption.color[2]}, 0.2)`,
                    padding: "2px 8px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    fontFamily: "SFProText-Semibold",
                    color: "#454545",
                  }}
                >
                  {colorOption.optionName}
                </div>
                <Icon icon="more" iconSize={10} />
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        className="tagColorPicker"
        style={{
          display:
            optionToChangeColor || optionToChangeColor === 0 ? "block" : "none",
          position: "fixed",
          width: 125,
          height: 200,
          left: currentCursor[0] ? currentCursor[0] + 150 : 250,
          top: currentCursor[1] ? currentCursor[1] + 50 : 150,
          backgroundColor: "#ffffff",
          filter: "drop-shadow(4px 4px 2px #c3c3c3)",
          color: "#000",
          zIndex: 101,
          paddingTop: 10,
          overflow: "scroll",
        }}
      >
        <div
          style={{
            fontSize: "8px",
            color: "#969696",
            fontFamily: "SFProText-Medium",
            padding: "0px 6px",
            marginBottom: "9px",
          }}
        >
          COLORS
        </div>
        {Object.values(colorsRef).map((color, index) => (
          <div
            className={`colorPicker ${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px 6px",
              marginBottom: "9px",
            }}
            onClick={async () => {
              setIsOptionsUpdated(false);
              const option = optionToChangeColor;
              const color = colors[index];
              setCurrentCursor([null, null]);
              setCurrentColorOptions(null);
              setCurrentColumn(null);
              setOptionToChangeColor(null);
              await changeColorTag(option, color);
            }}
          >
            <div
              className="color-image"
              style={{
                width: 18,
                height: 18,
                backgroundColor: `rgba(${colors[index][0]}, ${colors[index][1]}, ${colors[index][2]}, 0.2)`,
                borderRadius: "2px",
              }}
            />{" "}
            <div
              className="color"
              style={{
                marginLeft: 20,
                fontSize: "12px",
                fontFamily: "SFProText-Semibold",
                color: "#454545",
              }}
            >
              {color}
            </div>
            <br />
          </div>
        ))}
      </div>

      {/* Table */}

      {!isEditSchema && (
        <>
          <ReactDataSheet
            data={isSearching || isFiltered ? searchGrid : grid}
            onSelect={(cells) => {
              selectedCells = cells;
            }}
            valueRenderer={(cell) => cell.value}
            isCellNavigable={(cell, row, col) => true}
            onCellsChanged={(changes) => {
              const newGrid = grid; // .map((row) => [...row]);
              const newData = allData;

              // Add data to historical data for undo and redo
              appendToHistory(allData);

              changes.forEach(({ cell, row, col, value }) => {
                /*
                                Avoid using row because the index after searching could affect
                                the wrong cell.
                                */
                const columnName = newGrid[cell.index][col].key;
                if (cell.index) {
                  if (cell.key === "phoneNumber") {
                    console.log("errorManager", errorManager);
                    const aYT = new AsYouType("US");

                    console.log(
                      "newData[cell.index][columnName]",
                      newData[cell.index][columnName]
                    );
                    // console.log("aYT input", aYT.input(newData[cell.index][columnName]));
                    // console.log("parsePhoneNumber", parsePhoneNumber(newData[cell.index][columnName]).isValid());
                    console.log(
                      "newGrid[cell.index][col].data)",
                      newGrid[cell.index][col].data
                    );
                    console.log("value", value);
                    const newInput = aYT.input(value);
                    console.log("newInput", newInput);
                    const numberObject = aYT.getNumber();
                    newGrid[cell.index][col] = {
                      ...newGrid[cell.index][col],
                      value: numberObject
                        ? numberObject.formatInternational()
                        : newInput,
                    };
                    newData[cell.index][columnName] = findCorrectValue(
                      value,
                      newGrid[cell.index][col].type,
                      newGrid[cell.index][col].data
                    );
                    if (!numberObject || !numberObject.isValid()) {
                      setErrorManager({
                        ...errorManager,
                        errors: [
                          ...errorManager.errors,
                          {
                            errorType: "wrongDataType",
                            rowIndex: cell.index,
                            columnNames: [
                              { columnName: "phoneNumber", isEdited: false },
                            ],
                            isEdited: false,
                          },
                        ],
                      });
                    } else {
                      const hasErrorForTheCurrentCell =
                        errorManager.errors.find(
                          (e) =>
                            e.rowIndex === cell.index &&
                            e.columnNames.find(
                              (c) => c.columnName === "phoneNumber"
                            )
                        );
                      if (hasErrorForTheCurrentCell) {
                        const newErrors = errorManager.errors
                          .map((e) => {
                            if (
                              e.rowIndex === cell.index &&
                              e.columnNames.find(
                                (c) => c.columnName === "phoneNumber"
                              )
                            ) {
                              const columnNameErrorsWithoutFixedOne =
                                e.columnNames.filter(
                                  (c) => c.columnName !== "phoneNumber"
                                );
                              if (columnNameErrorsWithoutFixedOne.length) {
                                return {
                                  ...e,
                                  columnNames: columnNameErrorsWithoutFixedOne,
                                };
                              }
                              return null;
                            }
                          })
                          .filter((x) => !!x);
                        setErrorManager({
                          ...errorManager,
                          errors: newErrors,
                        });
                      }
                    }
                  } else {
                    newGrid[cell.index][col] = {
                      ...newGrid[cell.index][col],
                      value,
                    };
                    newData[cell.index][columnName] = findCorrectValue(
                      value,
                      newGrid[cell.index][col].type,
                      newGrid[cell.index][col].data
                    );
                  }

                  // Cell without an index can just be appended the value
                } else if (cell.key === "phoneNumber") {
                  console.log("errorManager", errorManager);
                  const aYT = new AsYouType("US");

                  console.log(
                    "newData[cell.index][columnName]",
                    newData[cell.index][columnName]
                  );
                  // console.log("aYT input", aYT.input(newData[cell.index][columnName]));
                  // console.log("parsePhoneNumber", parsePhoneNumber(newData[cell.index][columnName]).isValid());
                  console.log(
                    "newGrid[cell.index][col].data)",
                    newGrid[cell.index][col].data
                  );
                  console.log("value", value);
                  const newInput = aYT.input(value);
                  console.log("newInput", newInput);
                  const numberObject = aYT.getNumber();

                  newGrid[cell.index][col] = {
                    ...newGrid[row][col],
                    value: numberObject
                      ? numberObject.formatInternational()
                      : newInput,
                  };
                  newData[cell.index][columnName] = findCorrectValue(
                    value,
                    newGrid[cell.index][col].type,
                    newGrid[cell.index][col].data
                  );
                  if (!numberObject || !numberObject.isValid()) {
                    setErrorManager({
                      ...errorManager,
                      errors: [
                        ...errorManager.errors,
                        {
                          errorType: "wrongDataType",
                          rowIndex: cell.index,
                          columnNames: [
                            { columnName: "phoneNumber", isEdited: false },
                          ],
                          isEdited: false,
                        },
                      ],
                    });
                  } else {
                    const hasErrorForTheCurrentCell = errorManager.errors.find(
                      (e) =>
                        e.rowIndex === cell.index &&
                        e.columnNames.find(
                          (c) => c.columnName === "phoneNumber"
                        )
                    );
                    if (hasErrorForTheCurrentCell) {
                      const newErrors = errorManager.errors
                        .map((e) => {
                          if (
                            e.rowIndex === cell.index &&
                            e.columnNames.find(
                              (c) => c.columnName === "phoneNumber"
                            )
                          ) {
                            const columnNameErrorsWithoutFixedOne =
                              e.columnNames.filter(
                                (c) => c.columnName !== "phoneNumber"
                              );
                            if (columnNameErrorsWithoutFixedOne.length) {
                              return {
                                ...e,
                                columnNames: columnNameErrorsWithoutFixedOne,
                              };
                            }
                            return null;
                          }
                        })
                        .filter((x) => !!x);
                      setErrorManager({
                        ...errorManager,
                        errors: newErrors,
                      });
                    }
                  }
                } else {
                  newGrid[row][col] = { ...newGrid[row][col], value };
                  newData[row][columnName] = findCorrectValue(
                    value,
                    newGrid[cell.index][col].type,
                    newGrid[cell.index][col].data
                  );
                }
              });

              if (searchGrid.length > 0) {
                const newSearchGrid = searchGrid.map((row) => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  newSearchGrid[row][col] = {
                    ...newSearchGrid[row][col],
                    value,
                  };
                });
                setSearchGrid(newSearchGrid);
              }

              setGrid(newGrid);
              setAllData(newData);
            }}
            sheetRenderer={(props) => (
              <SheetRenderer
                columns={columns}
                selections={selections}
                onSelectAllChanged={handleSelectAllChanged}
                as="table"
                headerAs="thead"
                bodyAs="tbody"
                rowAs="tr"
                cellAs="th"
                modalColumTitle={modalColumTitle}
                showModalColumn={hasModal}
                setCurrentColumn={setCurrentColumn}
                setCurrentCursor={setCurrentCursor}
                setCurrentColorOptions={setCurrentColorOptions}
                options={options}
                columnHeadingConversions={columnHeadingConversions}
                {...props}
              />
            )}
            rowRenderer={(props) => {
              return (
                <RowRenderer
                  as="tr"
                  cellAs="td"
                  rowSelected={!!selections[props.row]}
                  onSelectChanged={handleSelectChanged}
                  className="data-row"
                  showModalRow={hasModal}
                  setIndexOfModalToOpen={setOpenModalIndex}
                  isModalViewOnly={isModalViewOnly}
                  {...props}
                />
              );
            }}
            cellRenderer={(props) =>
              props.editing ? (
                <EditCellRenderer
                  as="td"
                  columns={columns}
                  addImage={addImage}
                  errorManager={errorManager}
                  setErrorManager={setErrorManager}
                  {...props}
                />
              ) : (
                <CellRenderer
                  as="td"
                  columns={columns}
                  grid={grid}
                  errorManager={errorManager}
                  {...props}
                />
              )
            }
          />
        </>
      )}

      {/* Debug schema edit */}

      {isEditSchema && (
        <div className="schema">
          {schema.map((type) => (
            <div
              className="type"
              key={type.key}
              style={{
                display: "grid",
                gridTemplateColumns: "300px 250px 200px",
              }}
            >
              <b>{type.key}</b>
              <div style={{ width: "80%" }}>
                <Select
                  autoFocus
                  openOnFocus
                  closeOnSelect
                  defaultMenuIsOpen
                  value={{ label: _.capitalize(type.data), value: type.data }}
                  onChange={(value) => {
                    const newSchema = schema.map((column) => {
                      if (column.key === type.key) {
                        return {
                          key: type.key,
                          type: column.type,
                          data: value.value,
                        };
                      }
                      return column;
                    });
                    setSchema([...newSchema]);
                  }}
                  options={[
                    { label: "Text", value: "text" },
                    { label: "Number", value: "number" },
                    { label: "Date", value: "date" },
                  ]}
                />
              </div>
              <Select
                autoFocus
                openOnFocus
                closeOnSelect
                defaultMenuIsOpen
                value={{
                  label: type.type === "value" ? "Single" : "Multiple",
                  value: type.type,
                }}
                onChange={(value) => {
                  const newSchema = schema.map((column) => {
                    if (column.key === type.key) {
                      return {
                        key: type.key,
                        type: value.value,
                        data: column.data,
                      };
                    }
                    return column;
                  });
                  setSchema([...newSchema]);
                }}
                options={[
                  { label: "Single", value: "value" },
                  { label: "Multiple", value: "arrayOfValue" },
                ]}
              />
              <br />
            </div>
          ))}
        </div>
      )}

      {/* Modal to open */}

      {hasModal && (
        <ModalComponent
          showModal={openModalIndex || openModalIndex === 0}
          closeModal={() => setOpenModalIndex(null)}
          data={allData[openModalIndex]}
          setData={handleRowUpdateByModal}
          options={options}
          additionalInfo={additionalInfo}
          viewOnly={isModalViewOnly}
        />
      )}
      <Dialog
        isOpen={errorManager.showErrorModal}
        className="error-modal"
        onClose={closeErrorManager}
      >
        <div className="modal-body">
          <div className="modal-title">REQUIRED: Quick Fixes</div>
          <div className="modal-message">
            It looks like there are a few things that need fixing before your
            products can be updated. Don’t worry though, we’ll walk you through
            it.
          </div>
          <ul style={{ overflow: "scroll" }}>
            {errorManager.errors.map((error, index) => {
              const errorMessage = getErrorMessage(
                error,
                errorManager.errorColumn
              );
              if (errorMessage) {
                return <li key={index}>{errorMessage}</li>;
              }
            })}
          </ul>
        </div>
        <div className="modal-footer">
          <Button
            text="Let’s get these fixed"
            className="get-fixed-button"
            onClick={closeErrorManager}
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showSuccessModal}
        className="success-modal"
        onClose={() => {
          setShowSuccessModal(false);
          refresh();
          document.location.reload();
        }}
      >
        <div className="modal-body">
          <div className="modal-title">SUCCESS!</div>
          <div className="modal-message">Your data has been updated.</div>
        </div>
        <div className="modal-footer">
          <div className="check-mark-container">
            <Icon icon="tick" iconSize={87} color="#fff" />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Table;
