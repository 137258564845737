import { Button, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import { useIsScrollable } from "hooks/useIsScrollable";
import _ from "lodash";
import moment from "moment";
import { report } from "process";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  IDailySalesReport,
  getDailySalesReportForADateRange,
} from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import DailySalesReportDocument from "../PdfReports/DailySalesReportDocument";
import "../style.css";

interface DailySalesReportInterface {
  isMediaReport: boolean;
}

const DailySalesReport = ({ isMediaReport }: DailySalesReportInterface) => {
  const location = useLocation();
  const search = (location.search || "")
    .replace("?", "")
    .split("&")
    .map((i) => i.split("="));
  console.log(location, search);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyReports, setDailyReports] = useState<IDailySalesReport[]>([]);
  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);
  const [isScrollable, ref, node] = useIsScrollable([dailyReports]);

  const businesses = useSelector((state: RootState) => state.businesses);
  const accounts = useSelector((state: RootState) => state.accounts);
  const businessByType = businesses.reduce((acc, val) => {
    if (Object.keys(acc).includes(val.accountId)) {
      acc[val.accountId].push(val);
    } else {
      acc[val.accountId] = [val];
    }
    return acc;
  }, {} as { [T: string]: TangoBusiness[] });

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const deliverectProductTypes: FirebaseDeliverectProductType[] = useSelector(
    (state: RootState) => state.productTypes
  );
  const [hasMoreDocumentsToLoad, setHasMoreDocumentsToLoad] = useState(
    _.isNull(latestResponseLength) ? true : Boolean(latestResponseLength)
  );

  // useEffect(() => {
  //   if (!node || isLoading) return;

  //   if (!isScrollable && hasMoreDocumentsToLoad) {
  //     fetchMoreData();
  //   }
  // }, [isLoading, isScrollable, hasMoreDocumentsToLoad, node]);

  // Sales	Meals	Average Table	Turn

  const fetchMoreData = useCallback(async () => {
    const isEnterprise = search.join().length > 0;
    if (business && earliestFetchedDate && !isEnterprise) {
      setIsLoading(true);
      const productTypes = deliverectProductTypes.reduce(
        (acc, val) => ({ ...acc, [val.plu]: val.productType }),
        {}
      );
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract(7, "days")
        .toDate();

      const salesReports = (
        await getDailySalesReportForADateRange(
          [business.id],
          newEarliestDate,
          oldEarliestDate,
          productTypes,
          isMediaReport
        )
      ).sort((a, b) => a.date.getTime() - b.date.getTime());

      const finalSalesReport: IDailySalesReport[] = salesReports.map(
        (report, index) => ({
          ...report,
          id: String(dailyReports.length + index + 1),
        })
      );

      setEarliestFetchedDate(newEarliestDate);
      setLatestResponseLength(salesReports.length);

      const newReports = _.uniqBy(
        [...dailyReports.slice(), ...finalSalesReport].sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        ),
        "id"
      );
      if (dailyReports.length < newReports.length) {
        setDailyReports([...newReports]);
      }
      setHasMoreDocumentsToLoad(
        _.isNull(salesReports.length) ? true : Boolean(salesReports.length)
      );
      setIsLoading(false);
    } else if (earliestFetchedDate && businesses.length > 0) {
      setIsLoading(true);
      console.log("AAAA: ", search);
      const params = search.filter((i) => i[0].toLowerCase() === "accountid");
      console.log("Params: ", params);
      const accountId = params.length > 0 ? params[0][1] : "";
      console.log(accountId);
      if (accountId) {
        const productTypes = deliverectProductTypes.reduce(
          (acc, val) => ({ ...acc, [val.plu]: val.productType }),
          {}
        );
        const oldEarliestDate = earliestFetchedDate;
        const newEarliestDate = moment(oldEarliestDate)
          .subtract(7, "days")
          .toDate();

        const salesReports = (
          await getDailySalesReportForADateRange(
            businessByType[accountId].map((i) => i.id),
            newEarliestDate,
            oldEarliestDate,
            productTypes,
            isMediaReport
          )
        ).sort((a, b) => a.date.getTime() - b.date.getTime());

        const finalSalesReport: IDailySalesReport[] = salesReports.map(
          (report, index) => ({
            ...report,
            id: String(dailyReports.length + index + 1),
          })
        );

        setEarliestFetchedDate(newEarliestDate);
        setLatestResponseLength(salesReports.length);

        const newReports = _.uniqBy(
          [...dailyReports.slice(), ...finalSalesReport].sort(
            (a, b) => moment(b.date).unix() - moment(a.date).unix()
          ),
          "id"
        );
        if (dailyReports.length < newReports.length) {
          setDailyReports([...newReports]);
        }
        setHasMoreDocumentsToLoad(
          _.isNull(salesReports.length) ? true : Boolean(salesReports.length)
        );
      }

      setIsLoading(false);
    }
  }, [dailyReports, earliestFetchedDate, business, businesses]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const isEnterprise = search.join().length > 0;
      if (business && !isEnterprise) {
        console.log("AAAA");
        const productTypes = deliverectProductTypes.reduce(
          (acc, val) => ({ ...acc, [val.plu]: val.productType }),
          {}
        );
        const earliestDate = moment()
          .startOf("week")
          .subtract(7, "days")
          .toDate();
        const salesReports = (
          await getDailySalesReportForADateRange(
            [business.id],
            earliestDate,
            moment().add(1, "day").toDate(),
            productTypes,
            isMediaReport
          )
        ).sort((a, b) => a.date.getTime() - b.date.getTime());
        console.log("ALL SALES REPORT: ", salesReports);

        setEarliestFetchedDate(earliestDate);
        setLatestResponseLength(salesReports.length);
        setDailyReports(
          [...dailyReports, ...salesReports].sort(
            (a, b) => moment(b.date).unix() - moment(a.date).unix()
          )
        );
        setIsLoading(false);
      } else if (businesses.length > 0) {
        console.log("AAAA: ", search);
        const params = search.filter((i) => i[0].toLowerCase() === "accountid");
        console.log("Params: ", params);
        const accountId = params.length > 0 ? params[0][1] : "";
        console.log(accountId);
        if (accountId) {
          const productTypes = deliverectProductTypes.reduce(
            (acc, val) => ({ ...acc, [val.plu]: val.productType }),
            {}
          );
          const earliestDate = moment()
            .startOf("week")
            .subtract(7, "days")
            .toDate();
          const salesReports = (
            await getDailySalesReportForADateRange(
              businessByType[accountId].map((i) => i.id),
              earliestDate,
              moment().add(1, "day").toDate(),
              productTypes,
              isMediaReport
            )
          ).sort((a, b) => a.date.getTime() - b.date.getTime());

          setEarliestFetchedDate(earliestDate);
          setLatestResponseLength(salesReports.length);
          setDailyReports(
            [...dailyReports, ...salesReports].sort(
              (a, b) => moment(b.date).unix() - moment(a.date).unix()
            )
          );
        }

        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [business, businesses, deliverectProductTypes]);

  const createBlob = async (
    report: IDailySalesReport,
    mediaReport: boolean
  ) => {
    let blob: any;
    try {
      // Business DSR report
      if (search.join().length === 0) {
        blob = await pdf(
          <DailySalesReportDocument
            report={report}
            businessName={business.businessName}
            numberOfBusinesses={1}
            businesses={[]}
            isMediaReport={mediaReport}
          />
        ).toBlob();
        // Enterprise DSR report
      } else {
        const params = search.filter((i) => i[0].toLowerCase() === "accountid");
        const accountId = params.length > 0 ? params[0][1] : "";
        const length = businessByType[accountId].map((i) => i.id).length;
        const currentAccount = accounts.find(
          (account) => account.id === accountId
        );
        blob = await pdf(
          <DailySalesReportDocument
            report={report}
            businessName={
              currentAccount?.mobileOrders?.displayName || business.businessName
            }
            numberOfBusinesses={length}
            businesses={businesses}
            isMediaReport={isMediaReport}
          />
        ).toBlob();
      }
      if (blob) {
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
      setReportIdLoading(null);
    } catch (e) {
      setReportIdLoading(null);
      alert("Opps, something went wrong generating your report");
      console.log("error generating report", e);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="page-header">
          <div className="page-title">
            Daily {isMediaReport ? "Media" : "Sales"} Report
          </div>
        </div>
        <Box display="flex" flexDirection="column">
          <div
            id="scrollable-div"
            ref={ref}
            style={{ overflow: "scroll", height: 750 }}
          >
            {dailyReports.length === 0 ? (
              <div style={{ marginTop: 20 }}>
                <Spinner size={40} />
                <div
                  className="loading-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 20,
                  }}
                >
                  This may take a couple minutes to load.
                </div>
              </div>
            ) : (
              <>
                {dailyReports.map((report) => {
                  const isLoadingBlob = reportIdLoading === report.id;
                  const renderButton = () => {
                    const lastReportStartDay = moment(report.date);
                    const lastReportEndDay = lastReportStartDay
                      .clone()
                      .add(1, "day");
                    if (
                      moment().isAfter(lastReportStartDay) &&
                      moment().isBefore(lastReportEndDay)
                    ) {
                      return (
                        <div
                          className="report-table-text"
                          onClick={() => {
                            setReportIdLoading(report.id);

                            createBlob(report, isMediaReport);
                            // fetchAndOpenReport()
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          View Snapshot
                        </div>
                      );
                    }
                    if (isLoadingBlob) {
                      return <Spinner size={20} />;
                    }
                    return (
                      <div
                        className="report-table-text"
                        onClick={() => {
                          setReportIdLoading(report.id);

                          createBlob(report, isMediaReport);
                          // fetchAndOpenReport()
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        View Report
                      </div>
                    );
                  };
                  return (
                    <Box
                      key={report.id}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ padding: 5, marginTop: 10 }}
                    >
                      <div className="report-table-text">
                        {moment(report.date).format("MMMM D, YYYY")}-
                        {moment(report.date)
                          .endOf("week")
                          .add(1, "day")
                          .format("MMMM D, YYYY")}
                      </div>
                      {renderButton()}
                    </Box>
                  );
                })}
                {hasMoreDocumentsToLoad && (
                  <div className="center-button">
                    <Button
                      text="Load more"
                      loading={loading}
                      onClick={async () => {
                        setLoading(true);
                        await fetchMoreData();
                        setLoading(false);
                      }}
                      className="load-more-button" // create-new-teammate
                      fill
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </div>
      <LaborReportModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  );
};
export default DailySalesReport;
