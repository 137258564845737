import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isElectron } from "utils";
import UIComponents from "@tangopay/tango-ui-library";

import { IProductMix, salesMixForDateRange } from "controllers/reporting";

import { RootState } from "model/store";

import "../style.css";

const { Button, DatePickerInput, Modal } = UIComponents;

const SalesMix = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedReportType, setSelectedReportType] = useState<string>("")
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const reportTypes = ["Daily", "Weekly", "Monthly", "Quarterly", "Annually", "Custom"]

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const deliverectProductTypes: FirebaseDeliverectProductType[] = useSelector(
    (state: RootState) => state.productTypes
  );

  const generateReport = async () => {
    if (business) {
      const productTypes = deliverectProductTypes.reduce(
        (acc, val) => ({ ...acc, [val.plu]: val.productType }),
        {}
      );

      const newStartDate = moment(startDate).startOf("day").add(4, "hours").toDate();
      const newEndDate = moment(endDate).endOf("day").add(4, "hours").toDate();

      const reports = await salesMixForDateRange(
        business.id,
        newStartDate,
        newEndDate,
        productTypes,
      );

      return reports
    }

    return null;
  };

  const removeWeirdChars = (text: string) => {
    let i = 0;
    let result = "";
    while (i < text.length) {
      if (!/^\d+$/.test(text[i])) {
        break;
      }
      result += text[i];
      i += 1;
    }
    return result;
  };

  const createCsvReport = (data: IProductMix[], snapshot = false) => {
    const csvReport = data.reduce((acc, row) => {
      row.products.forEach((product) => {
        acc += product.productName + ",";
        acc += removeWeirdChars(product.plu) + ",";
        acc += product.menuCategory + ",";
        acc += removeWeirdChars(product.plu) + ",";
        acc +=
          (
            Math.round(product.sales / Math.ceil(product.quantity)) / 100
          ).toFixed(2) + ",";
        acc += Math.ceil(product.quantity) + ",";
        acc += (Math.round(product.sales) / 100).toFixed(2) + "\r\n";
      });
      return acc;
    }, "data:text/csv;charset=utf-8,");

    const encodedUri = encodeURI(csvReport);
    if (isElectron()) {
      //@ts-ignore
      window.electron.downloadFileByUrl({
        url: encodedUri,
        fileName: snapshot ? "sales_mix_snapshot.csv" : "sales_mix_report.csv",
      });
    } else {
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        snapshot ? "sales_mix_snapshot.csv" : "sales_mix_report.csv"
      );
      document.body.appendChild(link);

      link.click();
    }
  };

  const renderDatePickerBasedOnReportType = (reportType: string) => {
    if (reportType === "Daily" || reportType === "") {
      return (
        <div className="w-full">
          {reportType === "" ? <div style={{ position: "absolute", zIndex: 1000, width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,.50)" }}> </div> : null}
          <div className={"text-base text-black font-lato-black"}>
            Date *
          </div>
          <DatePickerInput size="large" value={startDate} onChange={(date: Date) => {
            setStartDate(date)
            setEndDate(date);
          }} />
        </div>
      )
    } else {
      return (
        <div className="w-full flex flex-row">
          <div className="w-full mr-3">
            <div className={"text-base text-black font-lato-black"}>
              Start Date *
            </div>
            <DatePickerInput size="large" value={startDate} onChange={(date: Date) => {
              setSelectedReportType("Custom")
              setStartDate(date)
            }} />
          </div>
          <div className="w-full ml-3">
            <div className={"text-base text-black font-lato-black"}>
              End Date *
            </div>
            <DatePickerInput size="large" value={endDate} onChange={(date: Date) => {
              setSelectedReportType("Custom")
              setEndDate(date)
            }} />
          </div>
        </div>
      )
    }
  }

  const updateSelectedReportType = (reportType: string) => {
    switch (reportType) {
      case "Daily":
        setStartDate(new Date());
        setEndDate(new Date());

        setSelectedReportType(reportType);
        break;
      case "Weekly":
        const startOfWeek = moment(new Date()).startOf("week").add(1, "day").toDate();
        const endOfWeek = moment(new Date()).toDate();
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);

        setSelectedReportType(reportType);
        break;
      case "Monthly":
        const startOfMonth = moment(new Date()).startOf("month").toDate();
        const endOfMonth = moment(new Date()).toDate();
        setStartDate(startOfMonth);
        setEndDate(endOfMonth);

        setSelectedReportType(reportType);
        break;
      case "Quarterly":
        const startOfQuarter = moment(new Date()).startOf("quarter").toDate();
        const endOfQuarter = moment(new Date()).toDate();
        setStartDate(startOfQuarter);
        setEndDate(endOfQuarter);

        setSelectedReportType(reportType);
        break;
      case "Annually":
        const startOfYear = moment(new Date()).startOf("year").toDate();
        const endOfYear = moment(new Date()).toDate();
        setStartDate(startOfYear);
        setEndDate(endOfYear);

        setSelectedReportType(reportType);
        break;
      case "Custom":
        setStartDate(new Date());
        setEndDate(new Date());

        setSelectedReportType(reportType);
        break;
      case "":
        setSelectedReportType(reportType);
        break;
    }
  }

  return (
    <>
      <div className="w-full h-full">
        <div>
          <div className="justify-between flex flex-row">
            <div className={"text-2xl text-black font-lato-black"}>
              Sales Mix CSV
            </div>
            <div className="self-end">
              <Button
                className="bg-black text-white"
                label="Generate Report"
                type="btn-style-1"
                size="btn-large"
                disabled={loading}
                onClick={async () => {
                  if (selectedReportType !== "") {
                    setShowModal(true);
                    setLoading(true);

                    const salesMixReport = await generateReport();
                    if (salesMixReport && salesMixReport.length > 0) {
                      createCsvReport(salesMixReport);
                    }

                    setLoading(false);
                  }
                }}
              />
            </div>
          </div>
          <div className={"text-sm text-black font-lato-regular mt-6"}>
            A CSV (excel) file with all products sold over a given period, including PLU, quantity, price and total dollar value sold.
          </div>
          <div className="mt-10">
            <div className={"text-base text-black font-lato-black"}>
              Report Type
            </div>
            <div className="flex flex-row mt-6 flex-wrap">
              {reportTypes.map((reportType, index) => {
                const isCurrentlySelected = reportType === selectedReportType
                return (
                  <Button
                    className="border-solid mt-2.5"
                    style={{ backgroundColor: isCurrentlySelected ? "#424452" : "", color: isCurrentlySelected ? "white" : "#AAADB6", marginLeft: index === 0 ? 0 : 5, borderColor: isCurrentlySelected ? "" : "#AAADB6" }}
                    label={reportType}
                    type={isCurrentlySelected ? "btn-style-1" : "btn-style-outline"}
                    size="btn-medium"
                    rightIcon={isCurrentlySelected ? "checkmark" : "outline-circle"}
                    rightIconColor={isCurrentlySelected ? "#FFFFFF" : "#AAADB6"}
                    onClick={() => updateSelectedReportType(reportType === selectedReportType ? "" : reportType)}
                  />
                )
              })}
            </div>
          </div>
          <div className="mt-10 flex justify-between">
            {renderDatePickerBasedOnReportType(selectedReportType)}
          </div>
        </div>
      </div>
      {/* Downloading Report Modal */}
      <Modal
        isOpen={showModal}
        closeModal={() => {
          if (!loading) {
            setShowModal(false)
          }
        }}
        width={"472"}
      >
        {loading ?
          <div>
            <div className={"text-2xl text-black font-lato-black"}>
              Downloading Report...
            </div>
            <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
              <div className="loading-spinner"></div>
            </div>
          </div>
          :
          <div className="flex center m-6  justify-center content-center items-center">
            <div className={"text-2xl text-black font-lato-black"}>
              Report successfully downloaded!
            </div>
          </div>
        }
      </Modal>
      {/* End Downloading Report Modal */}
    </>
  );
};
export default SalesMix;
