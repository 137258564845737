import React, { useCallback, useEffect, useState } from "react";
import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import useClosedChecks from "./useClosedChecks";
import UIComponents from '@tangopay/tango-ui-library';
import { Route, Routes, useNavigate } from "react-router-dom";
import ClosedCheckDetail from "../ClosedCheckDetail";
const { TextField, Button, Icon } = UIComponents;

const Conversions = () => {
    const {
        data,
        noOp,
        columns,
        instructions,
        isEditing,
        setEditing,
        setLastTabId
    } = useClosedChecks();

    const navigate = useNavigate();
    const [previousPageTabId, setPreviousPageTabId] = useState(['start'])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [allChecksToDisplay, setAllChecksToDisplay] = useState<any>([])
    const [displayCounterMessage, setDisplayCounterMessage] = useState<string>('1 - 20')

    useEffect(() => {
        if (allChecksToDisplay.length > 0 && pageNumber) {
            setDisplayCounterMessage(allChecksToDisplay[pageNumber - 1].length * (pageNumber + 1) - 20 + ' - ' + allChecksToDisplay[pageNumber - 1].length * (pageNumber + 1))
        } else {
            setDisplayCounterMessage('1 - 20')
        }
    }, [allChecksToDisplay])

    const nav = useCallback((id: string) => {
        const item = data.filter((completedCheck: any) => {
            return completedCheck.uniqueId === id
        })
        console.log('clicked completed check ', item[0])
        navigate(`${id}`, { state: item[0] });
    }, [navigate, data]);


    const returnTable = () => {
        return (
            <div className="absolute top-[63px] bottom-0 w-full overflow-hidden">
                <Header routes={headerRoutes} selectedRoute={"/payments/closedchecks"} selectedPage={{ label: "Customers", value: "/payments" }} />
                <div className="flex h-full">
                    <div
                        className={"border-r border-grey-1 border-t-0 border-b-0"}
                        style={{ width: "10%", borderRightStyle: "solid" }}
                    >
                        <Sidebar routes={sidebarRoutes} />
                    </div>

                    <div className={"flex px-10 py-6 overflow-y-auto flex-col"} style={{ width: "90%" }}>

                        <HorizontalTable
                            title="Closed Checks"
                            columns={columns}
                            data={data}
                            instructions={instructions}
                            saveResults={noOp}
                            setEditing={setEditing}
                            isEditing={isEditing}
                            hideEdit
                            onRowClick={nav}
                            customHeaderRightContent={<div className="flex items-center">
                                <Button
                                    label="Export"
                                    size="btn-medium"
                                    type="btn-style-1"
                                />
                                <TextField
                                    className="hori-table-search-input bg-blue-grey-2 border-0"
                                    value={''}
                                    onChange={
                                        () => console.log('close check yey')

                                    }
                                    sizeType="medium"
                                    placeholder="Search"
                                />
                            </div>}
                        />

                        {data.length > 0 &&
                            <div className="flex justify-between items-center">
                                <div className="mt-7 flex items-center justify-start">
                                    Viewing {displayCounterMessage}
                                </div>
                                <div className="mt-7 flex items-center justify-end">
                                    <Icon name="chevron-left" className="cursor-pointer" size="20" onClick={() => {
                                        if (pageNumber > 0 && previousPageTabId.length > 0 && allChecksToDisplay.length > 0) {
                                            setLastTabId(previousPageTabId[pageNumber])
                                            setPreviousPageTabId([
                                                ...previousPageTabId.slice(0, previousPageTabId.length - 1),
                                                ...previousPageTabId.slice(previousPageTabId.length)
                                            ]);
                                            setAllChecksToDisplay([
                                                ...allChecksToDisplay.slice(0, allChecksToDisplay.length - 1),
                                                ...allChecksToDisplay.slice(allChecksToDisplay.length)
                                            ]);
                                            setPageNumber(pageNumber - 1)
                                        }
                                    }} />
                                    <Icon name="chevron-right" className="cursor-pointer ml-3" size="20" onClick={() => {
                                        setPageNumber(pageNumber + 1)
                                        setPreviousPageTabId([...previousPageTabId, data[0].uniqueId])
                                        setLastTabId(data[data.length - 1].uniqueId)
                                        setAllChecksToDisplay([...allChecksToDisplay, data])
                                    }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    const returnClosedCheck = () => {
        return (
            <ClosedCheckDetail />
        )
    }

    return (
        <div className="bottom-0 w-full overflow-hidden">
            <div className="flex h-full">
                <Routes>
                    <Route index element={returnTable()} />
                    <Route
                        path=":id"
                        element={returnClosedCheck()}
                    />
                </Routes>
            </div>
        </div>
    )
};

export default Conversions;
