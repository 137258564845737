import { Dialog, Spinner } from "@blueprintjs/core";
import React from "react";
import TangoLogo from "../../../assets/OrganizationalLogo.svg";
import Header from "../../../components/Header";
import { ReviewTrackersReview } from "../../../types/reputationManagement";
import Filters from "./Filters";
import RequestReviewModal from "./RequestReviewModal";
import ReviewCard from "./ReviewCard";
import Sidebar from "./Sidebar";
import useReviews from "./useReviews";
import { useNavigate } from "react-router-dom";
import { createReviewTrackersOnboarding } from '../utils'
import UIComponents from "@tangopay/tango-ui-library";

const { Button } = UIComponents;

const Reviews = () => {
  const {
    setShowRequestReviewModal,
    showRequestReviewModal,
    allReviews,
    reviewTrackersCreds,
    accountMetrics,
    loading,
    setSelectedRatingFilter,
    selectedRatingFilter,
    filterByRatings,
    filterByReviewSite,
    filterByReviewTags,
    filterReviewsByDate,
    filterByReviewLocation,
    setSelectedReviewLocationFilter,
    selectedReviewLocationFilter,
    reviewSites,
    reviewTags,
    setSelectedDateFilter,
    selectedDateReviewsFilter,
    setSelectedReviewTagsFilter,
    selectedReviewTagsFilter,
    setSelectedReviewSiteFilter,
    selectedReviewSiteFilter,
    setOpenAuthorizeDialog,
    openAuthorizeDialog,
    reviewTemplates,
    businessSettings,
    business,
    businesses
  } = useReviews();

  const navigate = useNavigate();

  let filteredReviews: ReviewTrackersReview[];
  filteredReviews = filterByRatings(allReviews);
  filteredReviews = filterByReviewSite(filteredReviews);
  filteredReviews = filterReviewsByDate(filteredReviews);
  filteredReviews = filterByReviewTags(filteredReviews);
  filteredReviews = filterByReviewLocation(filteredReviews);

  return (
    <div className="absolute top-[63px] bottom-0 w-full">

      {openAuthorizeDialog && business &&
        <UIComponents.Modal
          isOpen={true}
          closeModal={() => {
            setOpenAuthorizeDialog(false)
          }}
          width={"500"}
        >
          <div className="">
            {/* <h2>Access review data?</h2> */}
            <div className={"text-black font-lato-black text-big"}>
              Access review data?
            </div>
            {/* <img style={{ width: '20%', marginRight: '5%' }} src={TangoLogo} alt="profile image" />
            +
            <img style={{ width: '20%', marginLeft: '5%' }} src={business.profileImageUrl ? business.profileImageUrl : ''} alt="profile image" />
            <br /> <br /> */}

            <div className={"text-sm font-lato-bold text-grey-2 mt-2.5"}>
              Tango uses ReviewTrackers to gather review information. Once authorized Tango can list all your reviews and respond to Google and Facebook reviews.
            </div>

            <br />

            <UIComponents.Button
              onClick={async () => {
                const onboardingLink = await createReviewTrackersOnboarding(reviewTrackersCreds, business, businessSettings)
                if (onboardingLink) {
                  window.open(onboardingLink.integrations_url, '_blank');
                  return navigate("/dashboard");
                }
              }}
              label={"Authorize"}
              type={"btn-style-2"}
              size={"btn-large"}
              className={"w-full justify-center"}
            />
            <UIComponents.Button
              onClick={() => {
                navigate("/dashboard");
              }}
              label={"Maybe Later"}
              type={"btn-style-1"}
              size={"btn-large"}
              className={"w-full justify-center mt-10"}
            />

          </div>
        </UIComponents.Modal>
      }

      <Header
        routes={[
          {
            label: "Reviews",
            link: "/reviews",
          },
          {
            label: "Templates",
            link: "/reviews-templates",
          },
        ]}
        selectedRoute={"/reviews"}
      />
      <div className="flex h-full overflow-hidden">

        {/* Sidebar is redundant since filters allow you to pick responded vs non responded */}
        {/* improvement items ui not created yet */}
        {/* <div
          className={
            "border-r border-grey-1 border-solid border-t-0 border-b-0 border-l-0"
          }
          style={{ width: "10%" }}
        >
          <Sidebar />
        </div> */}

        {loading && (
          <div style={{ marginLeft: "35%", marginTop: "15%" }}>
            <Spinner />
          </div>
        )}

        <div className={"flex overflow-y-auto"} style={{ width: "90%" }}>
          <div className={"w-9/12 pr-10 pl-12 pt-6"}>
            {accountMetrics && allReviews.length > 0 && (
              <div className={"flex justify-between items-center mb-4"}>
                <div className={"text-black text-2xl font-lato-black"}>
                  {filteredReviews.length > 0 ?
                    <span>All Reviews ({filteredReviews.length})</span>
                    :
                    <span>All Reviews ({allReviews.length})</span>
                  }
                </div>
                <div
                  className={
                    "flex items-center p-4 rounded-2xl bg-blue-grey-1 border border-blue-grey-3"
                  }
                >
                  <div className={"text-base text-black font-lato-regular"}>
                    Average Rating:
                  </div>
                  <div
                    className={
                      "ml-2 h-6 bg-green-2 border border-green-4 px-5 rounded-full flex justify-center items-center text-xs font-lato-bold text-black"
                    }
                  >
                    {accountMetrics &&
                      accountMetrics.avg_rating &&
                      accountMetrics.avg_rating.toFixed(2)}
                  </div>
                </div>
              </div>
            )}

            {filteredReviews.length > 0 && business &&
              filteredReviews.map((review: ReviewTrackersReview) => {
                return (
                  <ReviewCard
                    key={review.id}
                    business={business}
                    customerReview={review}
                    reviewTrackersCreds={reviewTrackersCreds}
                    reviewTemplates={reviewTemplates}
                  />
                );
              })}
          </div>

          <div className={"w-3/12 pt-9 pr-12"}>

            {/* <UIComponents.Button
              label={"Request Review"}
              type={"btn-style-1"}
              size={"btn-large"}
              className={"mx-auto bg-black !text-white"}
              onClick={() => setShowRequestReviewModal(true)}
            /> */}
            <Filters
              updateRatingFilter={(filterValue) =>
                setSelectedRatingFilter(filterValue)
              }
              selectedRatingFilter={selectedRatingFilter}
              updateReviewSiteFilter={(filterValue) =>
                setSelectedReviewSiteFilter(filterValue)
              }
              selectedReviewLocationFilter={selectedReviewLocationFilter}
              updateReviewLocationFilter={(filterValue, businessSettingsForSelectedLocation) => {
                setSelectedReviewLocationFilter({ filterValue, businessSettingsForSelectedLocation })
              }
              }
              selectedReviewSiteFilter={selectedReviewSiteFilter}
              updateReviewTagsFilter={(filterValue) => {
                setSelectedReviewTagsFilter(filterValue);
              }}
              selectedReviewTagsFilter={selectedReviewTagsFilter}
              updateReviewDateFilter={(date: string) =>
                setSelectedDateFilter(date)
              }
              selectedDateFilter={selectedDateReviewsFilter}
              reviewTags={reviewTags}
              reviewSites={reviewSites}
              businesses={businesses}
            />
          </div>
        </div>
      </div>
      <RequestReviewModal
        isOpen={showRequestReviewModal}
        closeModal={() => setShowRequestReviewModal(false)}
      />
    </div>
  );
};

export default Reviews;
