/**
 *  Get the data type from the original data for the table
 */
export const getDataType = (text) => {
  const isNumeric = (str) => {
    if (str && str.length > 0 && str[0] === "+") return false;
    if (typeof str !== "string" || typeof str !== "number") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  if (Array.isArray(text)) {
    if (text.length > 0) {
      if (isNumeric(text[0].trim())) {
        return "number";
      }
      if (Date.parse(text[0])) {
        return "date";
      }
      return "text";
    }
    return "text";
  }

  if (typeof text === "string") {
    if (isNumeric(text.trim())) {
      return "number";
    }
    if (Date.parse(text)) {
      return "date";
    }
    return "text";
  }
  return typeof text;
};

/**
 * Join 2 objects for each element in an array of objects
 *  --> This is being used to add the appropriate options to a table
 */
export const concatArrayToObject = (array, existingObject = {}) =>
  array.reduce((acc, val) => ({ ...acc, ...val }), existingObject);

const convertToCorrectItemType = (item, valueType) =>
  // TODO: Parse the correct date value for the original date.
  //       Right now, this causes a NaN or a crash because the data in the
  //       table is being stored as a string
  valueType === "number"
    ? item.length === 0
      ? 0
      : parseFloat(item)
    : valueType === "date"
    ? item
    : item;
/**
 * Get the data type from the table for the original data
 */
export const findCorrectValue = (data, cellType, valueType) => {
  switch (cellType) {
    case "arrayOfValue":
      if (data.length === 0) return [];
      return data
        .split(",")
        .map((item) => convertToCorrectItemType(item.trim(), valueType));
    default:
      return convertToCorrectItemType(data, valueType);
  }
};

/**
 * Remove the fields with null, NaN or undefined as well as if they've been converted to a string
 */
export const removeNullableKeysInObject = (obj) =>
  Object.keys(obj)
    .filter(
      (col) =>
        !!obj[col] &&
        obj[col] !== "null" &&
        obj[col] !== "NaN" &&
        obj[col] !== "undefined"
    )
    .reduce((acc, val) => ({ ...acc, [val]: obj[val] }), {});
