import { Widget } from "@typeform/embed-react";
import React from "react";

import Layout from "../Layout";
import "./index.css";

const ReportBug = () => {
  return (
    <Layout>
      <Widget id="yidO1TMT" className="my-form" />
    </Layout>
  );
};

export default ReportBug;
