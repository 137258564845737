import * as React from "react";

export const Completed = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      d="M5.20833 5.20835H7.29167V8.33335H17.7083V5.20835H19.7917V10.4167H21.875V5.20835C21.875 4.06252 20.9375 3.12502 19.7917 3.12502H15.4375C15 1.91669 13.8542 1.04169 12.5 1.04169C11.1458 1.04169 10 1.91669 9.5625 3.12502H5.20833C4.0625 3.12502 3.125 4.06252 3.125 5.20835V19.7917C3.125 20.9375 4.0625 21.875 5.20833 21.875H11.4583V19.7917H5.20833V5.20835ZM12.5 3.12502C13.0729 3.12502 13.5417 3.59377 13.5417 4.16669C13.5417 4.7396 13.0729 5.20835 12.5 5.20835C11.9271 5.20835 11.4583 4.7396 11.4583 4.16669C11.4583 3.59377 11.9271 3.12502 12.5 3.12502Z"
      fill={color}
    />
    <path
      d="M21.875 11.9792L16.1562 17.7084L13.0208 14.5834L11.4583 16.1459L16.1562 20.8334L23.4375 13.5417L21.875 11.9792Z"
      fill={color}
    />
  </svg>
);
