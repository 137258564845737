import { ProductTypesAction } from "model/types";

import ActionType from "./actionTypes";

export function receiveProductTypes(
  productTypes: FirebaseDeliverectProductType[]
): ProductTypesAction {
  return {
    type: ActionType.RECEIVE_PRODUCT_TYPES,
    productTypes,
  };
}
