import React from "react";

export type SidebarItem = {
  id: string;
  title: string;
  active: boolean;
};

interface SidebarProps {
  items: SidebarItem[];
  onItemClick: (id: string) => void;
}

const Sidebar = (props: SidebarProps) => {
  return (
    <div className="h-full border border-solid border-grey-1 pt-8">
      {props.items.map((i) => (
        <div
          className={`cursor-pointer text-md font-lato-${
            i.active ? "bold" : "medium"
          } hover:text-black text-${
            i.active ? "black" : "grey-3"
          } pl-5 py-2 mb-3`}
          key={i.id}
          onClick={() => props.onItemClick(i.id)}
        >
          {i.title}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
