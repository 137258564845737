import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  TimeCardSummaryReport,
  TimeCardSummaryReportRow,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 2,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 5,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 2,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface TimeCardSummaryPdfDocumentProps {
  report: TimeCardSummaryReport;
  businessName: string;
  snapshot?: boolean;
}

const TimeCardSummaryPdfDocument = (props: TimeCardSummaryPdfDocumentProps) => {
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} - ${moment(
        props.report.date
      )
        .add(1, "day")
        .format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 12,
            }}
          >
            Time Card Summary {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>
        </View>
        <Table data={props.report.data}>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              # pos
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Code
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Employee
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Task
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Date
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Time In
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Time Out
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Total Hours
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Other Paid
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Adjusted
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => ""}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.employeeCode}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.employeeFullName}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.roleName}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) =>
                moment(r.date).format("DD-MMM")
              }
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.timeIn}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.timeOut}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => r.totalHours}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => "-"}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r: TimeCardSummaryReportRow) => ""}
            />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
};

export default TimeCardSummaryPdfDocument;
