import _ from "lodash";
import { FirebaseDiscountDoc } from "views/admin/menus&Products/discounts/discountTypes";

import isDev from "utils/currentEnv";
import { isEqual } from "utils/data";

import { DiscountDoc } from "types/discounts";

import firebase from "../config/firebase";

const db = firebase.firestore();
const discountsCollection = db.collection("Discounts");

export const performSoftDelete = async (discounts: DiscountDoc[]) => {
  for await (const discount of discounts) {
    try {
      if (discount.id && discount.id.length > 0) {
        await discountsCollection.doc(discount.id).update({ deleted: true });
      }
    } catch (err) {
      if (isDev()) {
        console.log("Failed to update: ", discount);
      }
    }
  }
};

const convertProductNameToProductId = (
  productNames: string[],
  productNameRef: { [T: string]: string }
): string[] => {
  return productNames
    .map((name) => productNameRef[name] || "")
    .filter((name) => !!name);
};

const convertMenuNameToMenuId = (
  menuNames: string[],
  menuNameRef: { [T: string]: string }
): string[] => {
  return menuNames
    .map((name) => menuNameRef[name] || "")
    .filter((name) => !!name);
};

const convertMenuCategoryNameToMenuCategoryId = (
  menuCategories: string[],
  menuCategoriesNameRef: { [T: string]: string }
): string[] => {
  return menuCategories
    .map((name) => menuCategoriesNameRef[name] || "")
    .filter((name) => !!name);
};

const convertDiscountToFirebaseDiscount = (
  discount: DiscountDoc,
  index: number,
  businessId: string,
  productNameRef: { [T: string]: string },
  menuCategoriesNameRef: { [T: string]: string },
  menuNameRef: { [T: string]: string }
): FirebaseDiscountDoc & { index: number } => {
  return {
    index: index,
    id: discount.id || "",
    businessId: businessId,
    nameInternal: discount.nameExternal,
    nameExternal: discount.nameExternal,
    createdAt: new Date(),
    updatedAt: new Date(),
    enabled: discount.enabled === "Yes",
    deleted: false,
    oneTimeUse: discount.isOneTimeUse === "Yes",
    redeemedUsers: [],
    discountType:
      discount.discountType === "Scheduled"
        ? "Scheduled"
        : discount.discountType === "RewardOrCoupon"
        ? "RewardOrCoupon"
        : "Custom",
    isScheduled: discount.discountType === "Scheduled",
    isRewardOrCoupon: discount.discountType === "RewardOrCoupon",
    isCustomDiscount: discount.discountType === "Custom",
    customDiscount:
      discount.discountType === "Custom"
        ? {
            discountAmount: discount.customDiscountAmount
              ? parseFloat(discount.customDiscountAmount)
              : 0,
            isStaffDiscount: discount.isStaffDiscount === "Yes",
            isManagerDiscount: discount.isManagerDiscount === "Yes",
          }
        : {
            discountAmount: null,
            isStaffDiscount: null,
            isManagerDiscount: null,
          },
    // TODO: Allow multiple time slots for scheduled
    hours: [
      {
        dateOrDay: "date" as "date" | "day",
        // TODO: Allow day
        day: null,
        // TODO: Change the time so that startDate is after 4am in the same day, and endDate is after 4am the next day
        startDate:
          discount.schedule && typeof discount.schedule === "object"
            ? (discount.schedule[0] as Date)
            : null,
        endDate:
          discount.schedule && typeof discount.schedule === "object"
            ? (discount.schedule[1] as Date)
            : null,
        // TODO: Allow time constraints
        startTime: null,
        endTime: null,
      },
    ],
    orderTypesToExclude: discount.orderTypesToExclude,
    datesToExclude: discount.datesToExclude.map((datesToExclude) => {
      return {
        dateOrDay: "date" as "date" | "day",
        day: null,
        startDate: datesToExclude[0],
        endDate: datesToExclude[1],
        startTime: null,
        endTime: null,
      };
    }),
    productsToExclude: convertProductNameToProductId(
      discount.productsToExclude,
      productNameRef
    ),
    orderItemsToExclude: convertProductNameToProductId(
      discount.orderItemsToExclude,
      productNameRef
    ),
    menusToExclude: convertMenuNameToMenuId(
      discount.menusToExclude,
      menuNameRef
    ),
    menuCategoriesToExclude: convertMenuCategoryNameToMenuCategoryId(
      discount.menuCategoriesToExclude,
      menuCategoriesNameRef
    ),
    scheduledDiscount:
      discount.discountType === "Scheduled"
        ? {
            productsToDiscount: convertProductNameToProductId(
              discount.productsToDiscount,
              productNameRef
            ),
            discountType:
              discount.scheduledDiscountType.trim() === "Percent Off"
                ? "percentOff"
                : discount.scheduledDiscountType.trim() === "Price Off"
                ? "priceOff"
                : "newPrice",
            // Store in cents if discountType is not percentOff
            discountAmount:
              discount.scheduledDiscountType.trim() === "Percent Off"
                ? parseFloat(discount.discountAmount)
                : parseFloat(discount.discountAmount) * 100,
          }
        : {
            productsToDiscount: null,
            discountType: null,
            discountAmount: null,
          },
    rewardOrCouponDiscount:
      discount.discountType === "Coupon/Promo"
        ? {
            promoCode: discount.promoCode ? discount.promoCode : null,
            isPrivateDiscount:
              discount.isPrivateDiscount === "Yes" ? true : false,
            validUsers: [],
            redeemedValidUsers: [],
            discountTriggerType:
              discount.discountTriggerType ===
              "Dollar spend is over a certain amount"
                ? "minimumDollarSpent"
                : discount.discountTriggerType ===
                  "Specific items are purchased"
                ? discount.discountTriggerAllProductsNeedToBePurchased ===
                  "All chosen items must be purchased"
                  ? "specificItemsDiscounted"
                  : "anyItemsDiscounted"
                : "anyItemsDiscounted",
            rewardType:
              discount.rewardType === "Free items"
                ? "freeItem"
                : "discountedAmount",
            // Store in cents if rewardType is discountedAmount and rewardDiscountType is not percentOff or percentOffEachViableItem
            discountTriggerAmount:
              discount.discountTriggerType ===
              "Dollar spend is over a certain amount"
                ? parseFloat(discount.discountTriggerAmount) * 100
                : null,
            discountTriggerItems:
              discount.discountTriggerType === "Specific items are purchased"
                ? convertProductNameToProductId(
                    discount.discountTriggerItems,
                    productNameRef
                  )
                : null,
            // [
            //     'Free items',
            //     'Percent off the total check',
            //     'Percent off viable items',
            //     'Dollars off the total check',
            //     'Dollar off viable items',
            //     'New price for viable items'
            // ]
            rewardDiscountType:
              discount.rewardType === "Dollars off viable items"
                ? "priceOffEachViableItem"
                : discount.rewardType === "Percent off viable items"
                ? "percentOffEachViableItem"
                : discount.rewardType === "New price for viable items"
                ? "newPriceForEachViableItem"
                : discount.rewardType === "Dollars off the total check"
                ? "priceOff"
                : discount.rewardType === "Percent off the total check"
                ? "percentOff"
                : "percentOff",
            rewardDiscountAmount:
              discount.rewardType === "Free items"
                ? null
                : discount.rewardType === "Percent off the total check" ||
                  discount.rewardType === "Percent off viable items"
                ? parseFloat(discount.rewardDiscountAmount)
                : parseFloat(discount.rewardDiscountAmount) * 100,
            rewardDiscountItems:
              discount.rewardType === "Free items"
                ? discount.rewardDiscountItems
                : null,
            orderItemsToExclude: convertProductNameToProductId(
              discount.orderItemsToExclude,
              productNameRef
            ),
          }
        : {
            promoCode: null,
            isPrivateDiscount: null,
            validUsers: null,
            redeemedValidUsers: null,
            discountTriggerType: null,
            rewardType: null,
            // Store in cents if rewardType is discountedAmount and rewardDiscountType is not percentOff or percentOffEachViableItem
            discountTriggerAmount: null,
            discountTriggerItems: null,
            rewardDiscountType: null,
            rewardDiscountAmount: null,
            rewardDiscountItems: null,
            orderItemsToExclude: null,
          },
  };
};

export const saveData = async (
  newData: DiscountDoc[],
  existingData: DiscountDoc[],
  businessId: string,
  productNameRef: { [T: string]: string },
  menuNameRef: { [T: string]: string },
  menuCategoriesNameRef: { [T: string]: string }
) => {
  const firebaseDiscounts: FirebaseDiscountDoc[] = newData
    .map((discount, index) => ({ ...discount, index: index }))
    .filter((discount) => {
      const discountToCheck = _.omit(discount, "index");
      const foundItem = existingData.some((existingDiscount) =>
        isEqual(existingDiscount, discountToCheck)
      );

      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((discount) => {
      return convertDiscountToFirebaseDiscount(
        discount,
        discount.index,
        businessId,
        productNameRef,
        menuCategoriesNameRef,
        menuNameRef
      );
    }) as FirebaseDiscountDoc[];

  const existingFirebaseDiscounts: FirebaseDiscountDoc[] = existingData
    .map((discount, index) => ({ ...discount, index: index }))
    .map((discount) => {
      return convertDiscountToFirebaseDiscount(
        discount,
        discount.index,
        businessId,
        productNameRef,
        menuCategoriesNameRef,
        menuNameRef
      );
    }) as FirebaseDiscountDoc[];

  for await (const discount of firebaseDiscounts) {
    // For existing discounts - just find the differences to update
    if (discount.id) {
      const index = existingFirebaseDiscounts.findIndex(
        (existingDiscount) => existingDiscount.id === discount.id
      );
      if (index !== -1) {
        const dataToUpdate = Object.keys(existingFirebaseDiscounts[index])
          // createdAt is not necessary to update
          .filter(
            (key: string) =>
              // @ts-ignore
              !isEqual(existingFirebaseDiscounts[index][key], discount[key]) &&
              key !== "createdAt"
          )
          // @ts-ignore
          .reduce((acc, key) => ({ ...acc, [key]: discount[key] }), {});

        try {
          await discountsCollection.doc(discount.id).update(dataToUpdate);
        } catch (err) {
          if (isDev()) {
            console.log("Failed to update: ", discount.id);
          }
        }
      }

      // For new discounts create a new document
    } else {
      const docRef = discountsCollection.doc();
      discount["id"] = docRef.id;
      const finalDiscount = _.omit(discount, "index");

      try {
        await docRef.set(finalDiscount, { merge: true });
      } catch (err) {
        if (isDev()) {
          console.log("Failed to create: ", discount);
        }
      }
    }
  }
};
