import { FileInput, Icon } from "@blueprintjs/core";
import UIComponents from "@tangopay/tango-ui-library";
import { useState } from "react";

const EditCellRenderer = (props) => {
  const {
    as: Tag,
    cell,
    row,
    col,
    columns,
    attributesRenderer,
    selected,
    editing,
    updated,
    style,
    addImage,
    errorManager,
    setErrorManager,
    ...rest
  } = props;
  const [imageSrc, setImageSrc] = useState(null);
  const [newImage, setNewImage] = useState("");

  // // If any edit is made remove the red error cell
  // if (errorManager.errors.length > 0) {
  //   const newErrors = errorManager.errors.map(error => {
  //     // Select the entire row if a duplicateRows error
  //     if (error.errorType === 'duplicateRows') {
  //       if (error.rowIndex === row) {
  //         return ({
  //           ...error,
  //           isEdited: true
  //         })
  //       }
  //     } else {
  //       // Find the cells with issues using error.columnNames
  //       const colIndex = columns.findIndex(column => error.columnNames.includes(column.label))
  //       if (colIndex !== -1) {
  //         if (col === colIndex) {
  //           return ({
  //             ...error,
  //             columnNames: error.columnNames.map(errorColumn => {
  //               console.log("COMPARING ", errorColumn.columnName, " ---> ", columns[colIndex].label)
  //               if (errorColumn.columnName === columns[colIndex].label) {
  //                 return ({ columnName: errorColumn.columnName, isEdited: true })
  //               }
  //               return errorColumn
  //             })
  //           })
  //         }
  //       }
  //     }
  //     return error
  //   })
  //   console.log("THIS GOT CALLED!")
  //   console.log("NEW ERROR MANAGER: ", {
  //     ...errorManager,
  //     errors: newErrors
  //   })

  // NOTE: We can't do this because it's the cellRenderer and a setState in the
  //      cellRenderer triggers a maximum depth recursive call.
  // // Set the error manager to what it's supposed to be
  // setErrorManager({
  //   ...errorManager,
  //   errors: newErrors
  // })

  // }

  /*
   * display: 'contents' needed to hide the cell to show the dropdown (under some circumstances)
   */
  const attributes = cell.attributes || {};
  attributes.style = { width: columns[col].width, display: "contents" };
  if (col === 0) {
    attributes.title = cell.label;
  }

  const onImageLoad = (e) => setNewImage(e.target.result);

  const onFileInputChange = (e) => {
    const fileReader = new FileReader();

    const previewImage = document.querySelector(".previewImage");

    fileReader.onload = onImageLoad;
    if (e.target.files.length) {
      fileReader.readAsDataURL(e.target.files[0]);

      // deliverect has 20mp constraints on product image size
      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (img.naturalWidth * img.naturalHeight > 20000000) {
          return alert("Image is too large");
        }
      };
      previewImage.src = img.src;
      setImageSrc(img.src);
      setNewImage(e.target.value);
    }
  };
  if (props.children.props.isMulti) {
    return (
      <Tag {...rest} {...attributes}>
        <div
          style={{
            zIndex: 100,
            margin: 20,
            width: 242,
            height: 249,
            overflow: "auto",
            backgroundColor: "#fff",
            position: "absolute",
            background: "#FFFFFF",
            boxShadow: "0px 4px 14.4px rgba(0, 0, 0, 0.32)",
            borderRadius: "12px",
            // border: '5px solid red'
          }}
        >
          <div
            className={"py-2.5 px-6 flex border-b border-grey-1"}
            style={{ flexWrap: "wrap", borderBottomStyle: "solid" }}
          >
            {props.children.props.value.map(
              (singleModifier, singleModifierIndex) => (
                <div
                  className={
                    "border flex cursor-pointer border-green-4 border-solid bg-green-2 rounded-[100px] text-xs text-black rounded-full px-3 mb-2 mr-2"
                  }
                >
                  <div className={"mr-3"} style={{ fontFamily: "Lato-Bold" }}>
                    {singleModifier.label}
                  </div>
                  <UIComponents.Icon
                    name={"cross"}
                    size={"12"}
                    onClick={() => {
                      const actionData = {
                        action: "remove-value",
                        removedValue: singleModifier,
                        name: undefined,
                      };
                      const remainingValues = [...props.children.props.value];
                      remainingValues.splice(singleModifierIndex, 1);
                      props.children.props.onChange(
                        remainingValues,
                        actionData
                      );
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div className={"py-4 px-6"}>
            <div
              className={"font-lato-medium text-veryTiny text-black mb-4"}
              style={{ fontFamily: "Lato-Medium" }}
            >
              Select an option or create one
            </div>
            <ul className={"p-0 m-0 list-none"}>
              {props.children.props.options.map(
                (singleModifier, singleModifierIndex) => {
                  if (
                    !props.children.props.value.some(
                      (item) => item.value === singleModifier.value
                    )
                  ) {
                    return (
                      <div
                        key={singleModifierIndex}
                        onClick={() => {
                          const actionData = {
                            action: "select-option",
                            option: singleModifier,
                            name: undefined,
                          };
                          const optionsData = [
                            ...props.children.props.value,
                            singleModifier,
                          ];
                          props.children.props.onChange(
                            optionsData,
                            actionData
                          );
                        }}
                        className={
                          "border flex cursor-pointer w-fit rounded-[100px] text-xs text-black rounded-full px-3 mb-2"
                        }
                        style={{
                          backgroundColor: `rgba(${singleModifier.color.join(
                            ","
                          )},0.2)`,
                        }}
                      >
                        <div style={{ fontFamily: "Lato-Bold" }}>
                          {singleModifier.label}
                        </div>
                      </div>
                    );
                  }
                }
              )}
            </ul>
          </div>
        </div>
      </Tag>
    );
  }
  if (
    Object.keys(props.children.props).includes("cell") &&
    Object.keys(props.children.props.cell).includes("isImage") &&
    props.children.props.cell.isImage
  ) {
    return (
      <Tag {...rest} {...attributes}>
        <div
          className="imagePicker"
          style={{
            zIndex: 100,
            margin: 20,
            width: 242,
            height: 249,
            overflow: "auto",
            backgroundColor: "#fff",
            position: "absolute",
            background: "#FFFFFF",
            boxShadow: "0px 4px 14.4px rgba(0, 0, 0, 0.32)",
            borderRadius: "2px",
            // border: '5px solid red'
          }}
        >
          <div style={{ position: "relative" }}>
            {newImage.length === 0 && (
              <FileInput
                inputProps={{ accept: ".png,.jpeg" }}
                onInputChange={onFileInputChange}
                large
                buttonText="Select file"
                text="Image"
                className="table-file-input"
                style={{ width: "50%" }}
              />
            )}
            <img
              className="previewImage"
              src=""
              alt=""
              width="182px"
              height="182px"
              style={{
                display: newImage.length !== 0 ? "block" : "none",
                objectFit: "cover",
                margin: "15px",
              }}
            />
            <div
              onClick={() => addImage(newImage, cell)}
              style={{
                marginTop: "10px",
                /* margin-left: 15px; */
                borderTop: "1px solid #F4F3F3",
                paddingLeft: "15px",
                paddingTop: "10px",
                color: "#969696",
              }}
            >
              + Add or change image
            </div>
            <Icon
              icon="trash"
              color="#A81616"
              iconSize={15}
              style={{
                position: "absolute",
                top: "25px",
                right: "14px",
              }}
            />
          </div>
        </div>
      </Tag>
    );
  }

  return (
    <Tag {...rest} {...attributes}>
      <div className={`${!cell.isDropdown && "zoom-div"}`}>
        {props.children}
      </div>
    </Tag>
  );
};

export default EditCellRenderer;
