import moment from "moment-timezone";

import { getWeekDays } from "./manager";

export const convertUTCDateToLocalDate = (date: Date): Date => {
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const getWeekRangeBySelectedDateAndPayrollStartDate = (
  sd: Date,
  payrollStartOfTheWeek = "Monday"
) => {
  const sdMoment = moment(sd).startOf("day").add(4, "hours");
  const sdNumber = sdMoment.day();
  const payrollFirstDayNumber = moment().day(payrollStartOfTheWeek).get("day");
  if (sdNumber < payrollFirstDayNumber) {
    const adjustedWeekRangeStart = sdMoment
      .clone()
      .subtract(7, "days")
      .day(payrollFirstDayNumber);
    const adjustedSelectedDays = getWeekDays(adjustedWeekRangeStart.toDate());
    return adjustedSelectedDays;
  } else {
    const regularWeekRangeStart = sdMoment.clone().day(payrollFirstDayNumber);
    const regularSelectedDays = getWeekDays(regularWeekRangeStart.toDate());
    return regularSelectedDays;
  }
};

export const sortHours = (hours: number[], now: number) => {
  // All elements in hours are integers from 0 to 24 that is already
  // sorted correctly. We need to find a way to move a time slot
  // that is an integer higher than now to be the first
  // elements.
  //
  // This implies [1, 3, 5, 7, 9, 11, 23] if now is 11 should be
  // [23, 1, 3, 5, 7, 9, 11]. Likewise [4, 6, 8, 10, 12, 14, 16]
  // if now is  17 should result in the same hours array.

  // Found the exact index
  const exact_index = hours.findIndex((hour) => hour == now);
  if (exact_index !== -1) {
    // The elements from exact_index + 1 to the length of the array
    // are greater than the time now.
    if (exact_index < hours.length) {
      return [
        ...hours.slice(exact_index + 1),
        ...hours.slice(0, exact_index + 1),
      ];
    }

    // The last element in hours is the latest time
    else if (exact_index === hours.length) {
      return hours;
    }
  }

  // Find the first element greater than the current hour
  const greater_index = hours.findIndex((hour) => hour > now);
  if (greater_index !== -1) {
    // All elements at greater_index and onwards should be shifted
    // to the first few elements.
    return [...hours.slice(greater_index), ...hours.slice(0, greater_index)];
  }

  return hours;
};

export const convertToBusinessTimezoneMoment = (
  date: Date,
  business: TangoBusiness
) => {
  const timezone = business.timezone || "America/Toronto";
  const businessTimeZoneMoment = moment(date);
  return businessTimeZoneMoment.tz(timezone, true);
};

export const convertDateBackToBusinessTimezone = (
  date: Date,
  business: TangoBusiness
) => {
  const timezone = business.timezone || "America/Toronto";
  const timezoneOffsetInHours =
    moment().tz(timezone).hour() - new Date().getHours();

  return moment(date).add(timezoneOffsetInHours, "hours").toDate();
};

export const convertGMTToBusinessTimezone = (
  date: Date,
  business: TangoBusiness
) => {
  const timezone = business.timezone || "America/Toronto";
  const hoursDiff = moment().tz(timezone).toDate().getTimezoneOffset() / 60;
  return moment(date).add(hoursDiff, "hours");
};
