import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";

import { fetchCollectionByBusinessIds } from "controllers/accounts";
import {
  composeUsableModifierOptions,
  composeUsableModifiers,
  composeUsableProducts,
} from "controllers/composableTypes";

import { RootState } from "model/store";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import {
  performSoftDelete,
  saveData,
} from "../../../../models/modifierOptions";
import { DocumentData } from "../../../../types/document";
import { ModifierDoc, ModifierOptionsDoc } from "../../../../types/modifiers";

interface Props {
  // modifier: ModifierDoc;
  // modifierOptions: ModifierOptionsDoc[],
  // setModifierOptions: (modifierOption: ModifierOptionsDoc[]) => void;
  businessId: string;
  // allModifierOptions: FirebaseModifierOptionsDoc[];
  // setAllModifierOptions: (newModifierOptions: FirebaseModifierOptionsDoc[]) => void;
  // modifiers: FirebaseModifierDoc[];
}

const ModifierOptionsTable = ({ businessId }: Props) => {
  const modifierOptions: ModifierOptionsDoc[] = useSelector(
    (state: RootState) => state.modifierOptions
  ).map((option) => {
    return {
      id: option.id,
      plu: option.plu || "",
      modifierOptionName: option.name,
      modifierOptionPrice: option.additionalCost,
    };
  });
  const modifiers = useSelector((state: RootState) => state.modifiers);
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );

  const initComplete: boolean = useSelector(
    (state: RootState) => state.enterpriseInitComplete
  );
  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings);

  const [allowModifierOptionsUpdates, setAllowModifierOptionsUpdates] = useState<boolean>(false)
  const [data, setData] = useState<ModifierOptionsDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<ModifierOptionsDoc[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(true);
  const [isAllDelete, setIsAllDelete] = useState<boolean>(false);

  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "nameExternal",
    errors: [],
  });

  const toPrice = (text: number) => {
    return text;
  };

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "modifierOptionPrice",
      isEditable: true,
      isImage: false,
      isCustomText: true,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: {
        prefix: "$ ",
        suffix: null,
        customFunction: toPrice.toString(),
      },
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const columnHeadingConversions = {
    plu: "PLU",
    modifierOptionName: "Option Name",
    modifierOptionPrice: "Option Price (in dollars)",
  };

  const tableSchema = [
    { key: "plu", type: "value", data: "text" },
    { key: "modifierOptionName", type: "value", data: "text" },
    { key: "modifierOptionPrice", type: "value", data: "number" },
  ];
  const dataSchema = [
    { key: "id", type: "value", data: "text" },
    { key: "plu", type: "value", data: "text" },
    { key: "modifierOptionName", type: "value", data: "text" },
    { key: "modifierOptionPrice", type: "value", data: "number" },
  ];

  useEffect(() => {
    if (!loading && saved && modifierOptions.length > 0) {
      const sortedData = modifierOptions.sort(function (a, b) {
        return Number(a.plu || "0") - Number(b.plu || "0");
      });
      setData(sortedData);
      setSaved(false);
    }
  }, [loading, saved]);

  useEffect(() => {
    // Realtime rendering is an issue and this is a
    // crude way to allow data to load when it's gotten
    // updated. The only caveat is if all the items
    // are deleted then it won't updated the table.
    // Hence looking like nothing was deleted
    //
    // TODO: Let's fix this in a bit.
    if (loading && modifierOptions.length > 0 && saved) {
      setLoading(false);
    }
  }, [loading, saved, modifierOptions]);

  useEffect(() => {
    if (businessSettings) {
      const allowUpdates = businessSettings.menuBuilderPermissions.allowModifierOptionsUpdates;
      setAllowModifierOptionsUpdates(!allowUpdates)
    }
  }, [businessSettings])

  // Which columns in the grid to render based on the data
  const filterData = (data: DocumentData[]) => {
    return data.map((row) => ({
      plu: row.plu || "",
      modifierOptionName: row.modifierOptionName,
      modifierOptionPrice: row.modifierOptionPrice / 100,
    }));
  };

  const isFloat = (value: any) =>
    !isNaN(value) && value.toString().indexOf(".") != -1;
  const isInt = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

  const checkDuplicates = (data: ModifierOptionsDoc[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      plu: row.plu,
      index: index,
    }));

    // Filter for duplicates
    const modifierDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({
            plu: row.plu,
          })),
          { plu: item.plu }
        ) !== index
    );

    // Find the duplicate without the productId
    const errors: DocumentData[] = [];
    for (let i = 0; i < modifierDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter((row) => {
        if (row.plu !== "") {
          return row.plu === modifierDuplicateErrors[i].plu;
        }

        return false;
      });
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: ModifierOptionsDoc[]) => {
    const errors = [
      // Wrong min
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter((row: any) => {
          return (
            !isFloat(row.modifierOptionPrice) && !isInt(row.modifierOptionPrice)
          );
        })
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "modifierOptionPrice", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: ModifierOptionsDoc[]) => {
    const errors = [
      // No modifier name
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter(
          (row: any) =>
            !row.modifierOptionName || row.modifierOptionName.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "modifierOptionName", isEdited: false }],
          isEdited: false,
        })),
      // No price
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter((row: any) =>
          !row.modifierOptionPrice ||
            typeof row.modifierOptionPrice === "string"
            ? row.modifierOptionPrice.length === 0
            : String(row.modifierOptionPrice).length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "modifierOptionPrice", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal: showErrorModal,
        // @ts-ignore
        errors: errors,
      });
      return true;
      // All errors have been resolved
    } else {
      setErrorManager({
        ...errorManager,
        showErrorModal: false,
        errors: [],
      });
    }
    return false;
  };

  const saveToFirebase = async () => {
    if (isEnterpriseLevel) {
      await saveToFirebaseFromEnterprise();
      return true;
    } else {
      setLoading(true);
      const businessProducts = await fetchCollectionByBusinessIds(
        [businessId],
        "Products",
        composeUsableProducts
      );

      const keysRef: { [T: string]: string } = {
        id: "id",
        plu: "plu",
        modifierOptionName: "name",
        modifierOptionPrice: "additionalCost",
      };

      await performSoftDelete([...softDeleteData], modifiers);
      const modifierOptionsResult = await saveData(
        data.map((newModifierOption) => ({
          ...newModifierOption,
          id: newModifierOption.id,
          plu: newModifierOption.plu,
          modifierOptionPrice:
            parseFloat(String(newModifierOption.modifierOptionPrice) || "0") *
            100,
        })),
        modifierOptions.map((existingModifierOption) => ({
          ...existingModifierOption,
          modifierOptionPrice:
            parseFloat(
              String(existingModifierOption.modifierOptionPrice) || "0"
            ) * 100,
        })),
        businessId,
        keysRef,
        isEnterpriseLevel,
        modifierOptions,
        businessProducts,
        []
      );

      setSaved(true);
      setLoading(false);

      return true;
    }
  };

  const saveToFirebaseFromEnterprise = async () => {
    if (businesses.length > 0) {
      setLoading(true);
      const keysRef: { [T: string]: string } = {
        id: "id",
        plu: "plu",
        modifierOptionName: "name",
        modifierOptionPrice: "additionalCost",
      };

      let generatedPlus: string[] = [];

      for await (const [index, business] of businesses.entries()) {
        const businessModifierOptions: FirebaseModifierOptionsDoc[] =
          await fetchCollectionByBusinessIds(
            [business.id],
            "ModifierOptions",
            composeUsableModifierOptions
          );

        const businessModifiers = await fetchCollectionByBusinessIds(
          [business.id],
          "Modifiers",
          composeUsableModifiers
        );

        const businessProducts = await fetchCollectionByBusinessIds(
          [business.id],
          "Products",
          composeUsableProducts
        );
        const modifierOptionsToDelete = softDeleteData.map(
          (softDeleteModifierOption) =>
            softDeleteModifierOption.modifierOptionName
        );
        const filteredModifierOptionsToDelete: FirebaseModifierOptionsDoc[] =
          businessModifierOptions.filter((modifierOption) =>
            modifierOptionsToDelete.includes(modifierOption.name)
          );

        await performSoftDelete(
          filteredModifierOptionsToDelete,
          businessModifiers
        );

        const convertedModifierOptions: ModifierOptionsDoc[] =
          businessModifierOptions.map((option) => {
            return {
              id: option.id,
              plu: option.plu || "",
              modifierOptionName: option.name,
              modifierOptionPrice: option.additionalCost,
            };
          });

        const modifierOptionsResult = await saveData(

          data.map((newModifierOption) => ({
            ...newModifierOption,
            id: newModifierOption.id,
            plu: newModifierOption.plu,
            modifierOptionPrice:
              parseFloat(String(newModifierOption.modifierOptionPrice) || "0") *
              100,
          })),
          convertedModifierOptions.map((existingModifierOption) => ({
            ...existingModifierOption,
            modifierOptionPrice:
              parseFloat(
                String(existingModifierOption.modifierOptionPrice) || "0"
              ) * 100,
          })),
          business.id,
          keysRef,
          isEnterpriseLevel,
          convertedModifierOptions,
          businessProducts,
          generatedPlus
        );

        generatedPlus = modifierOptionsResult.generatedPlus;

        if (index === businesses.length - 1) {
          setSaved(true);
          setLoading(false);

          return true;
        }
      }
    }
  };

  if (!initComplete) {
    return <EnterpriseInitializeLoading />;
  }

  return (
    <div className="modifier-options-table" style={{ width: "90%" }}>
      <Table
        businessId={"all"}
        name={"Modifier Options"}
        parentName={"MenuBuilder"}
        allData={data}
        setAllData={setData}
        softDelete={softDeleteData}
        setSoftDelete={setSoftDeleteData}
        allowSoftDelete={true}
        setIsAllDelete={setIsAllDelete}
        filterData={filterData}
        tableSchema={tableSchema}
        dataSchema={dataSchema}
        options={options}
        setOptions={setOptions}
        hasModal={false}
        ModalComponent={null}
        errorManager={errorManager}
        setErrorManager={setErrorManager}
        closeErrorManager={() => {
          setErrorManager({
            ...errorManager,
            showErrorModal: false,
          });
        }}
        verifySave={verifyData}
        saveToFirebase={saveToFirebase}
        hasSearch={false}
        searchColumnName={"modifierOptionName"}
        hasFilter={false}
        columnHeadingConversions={columnHeadingConversions}
        disableShortcuts={true}
        readOnly={isEnterpriseLevel ? false : allowModifierOptionsUpdates}
      />
    </div>
  );
};

export default ModifierOptionsTable;
