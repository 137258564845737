import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Assign } from "utility-types";
import moment from "moment-timezone"
import UIComponents from "@tangopay/tango-ui-library";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

import {
  addFile,
  addFileWithWaterfall,
  addFolder,
  addFolderWithWaterfall,
  deleteFileOrFolder,
  getFileSize,
  getFileType,
  renameFileOrFolder,
  shareFileOrFolder,
  shareFolderWithWaterfall,
  getAllFilesAndFoldersForFolder,
  updateDocIdsInFolderTags,
  updateFileOrFolderName,
  updateFolderTags,
  updateTheAbsolutePath,
  invitePeopleToFolderWithWaterfall,
} from "models/docs";

import { getFilesBasedOnCurrentUser, getTrashedFilesBasedOnCurrentUser, getAllFilesBasedOnCurrentUser } from "model/selectors/docs";
import { RootState } from "model/store";

import DocxIcon from "assets/clouds/docx.svg";
import FolderIcon from "assets/clouds/folder.svg";
import JpegIcon from "assets/clouds/jpeg.svg";
import PdfIcon from "assets/clouds/pdf.svg";
import PptIcon from "assets/clouds/ppt.svg";
import SvgIcon from "assets/clouds/svg.svg";
import XlxsIcon from "assets/clouds/xlxs.svg";
import PngIcon from "assets/clouds/png.svg";
import PsdIcon from "assets/clouds/psd.svg";
import UnknownIcon from "assets/clouds/unknown.svg";

import _ from "lodash";
import { SearchableBusinessOption } from "views/Cloud/ShareModal";

const { Icon, Button, NumberTag, TextField } = UIComponents;

export type ExtendedFilesAndFolders = Assign<
  FilesAndFolders,
  {
    uniqueId: string;
    sharing: string;
    formattedSize: string;
    lastModified: Date;
  }
>;

const getIcon = (type: string | null, isFolder: boolean) => {
  let icon = "";

  if (isFolder) {
    icon = FolderIcon;
  } else if (type === "docx" || type === "DOCX") {
    icon = DocxIcon;
  } else if (type === "image/jpeg" || type === "JPEG") {
    icon = JpegIcon;
  } else if (type === "PDF") {
    icon = PdfIcon;
  } else if (type === "PPT") {
    icon = PptIcon;
  } else if (type === "SVG") {
    icon = SvgIcon;
  } else if (type === "XLSX") {
    icon = XlxsIcon;
  } else if (type === "PSD") {
    icon = PsdIcon;
  } else if (type === "PNG") {
    icon = PngIcon;
  } else {
    icon = UnknownIcon;
  }
  return <img src={icon} />;
};

export const DocumentRow = ({
  document,
}: {
  document: ExtendedFilesAndFolders;
}) => {
  const [editMode, setEditMode] = useState(false)
  const [nameValue, setNameValue] = useState(document.name ?? "");
  const onInputBlur = useCallback(() => {
    setEditMode(false)
    updateFileOrFolderName(
      nameValue,
      document.uniqueId,
      document.businessId,
      document.accountId,
      document.parentAccountId
    )
  }, [setEditMode, document, nameValue])

  return (
    <div>
      {getIcon(document.fileExtension, document.isFolder)}
      {editMode ? (
        <TextField
          onBlur={onInputBlur}
          sizeType="small"
          type='text'
          value={nameValue}
          //@ts-ignore
          onChange={e => setNameValue(e.target.value)}
        />
      ) : (
        <span onClick={() => setEditMode(!editMode)} className="ml-4">{document.name}</span>
      )}
    </div>
  );
};

export type ActionTypes =
  | "move-to"
  | "share"
  | "open-file"
  | "download"
  | "duplicate"
  | "delete";


export type UserLevelSharingEntry = {
  userId: string;
  fullName: string;
  abbrName: string;
  profileURL: string | null;
  accessType: DocAccessType;
  roleTitle: string;
}
export type BusinessLevelSharingEntry = {
  businessId: string;
  businessName: string;
  generalAccessType: DocAccessType;
  users: UserLevelSharingEntry[];
  accessBreadth?: "all-staff" | "admins"
}

export type GroupLevelSharingEntry = {
  groupId: string;
  name: string;
  generalAccessType: DocAccessType;
  users: UserLevelSharingEntry[]
}

export type FileSharingOptions = {
  owner: UserLevelSharingEntry | null;
  users: UserLevelSharingEntry[];
  businesses: BusinessLevelSharingEntry[];
  groups: GroupLevelSharingEntry[];
  searchableUsers: UserLevelSharingEntry[];
  searchableBusinesses: BusinessLevelSharingEntry[];
}

export const useDocs = () => {
  // const navigate = useNavigate();

  const location = useLocation()


  const locationName = useMemo(() => {
    if (location.pathname.includes("files")) {
      return "files"
    }
    if (location.pathname.includes("trash")) {
      return "trash"
    }
    if (location.pathname.includes("starred")) {
      return "starred"
    }
    return null
  }, [location])



  const [selectedPage, setSelectedPage] = useState<
    "files" | "folders" | "starred"
  >("folders");
  const [parentToSelectedFileAndFolder, setParentToSelectedFileAndFolder] =
    useState<FilesAndFolders | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<FilesAndFolders | null>(
    null
  );
  const [showFilePreview, setShowFilePreview] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<FilesAndFolders | null>(
    null
  );

  const [showCreateNewFolderModal, setShowCreateNewFolderModal] =
    useState<boolean>(false);
  const [showInviteFileModal, setShowInviteFileModal] =
    useState<boolean>(false);
  const [showRenameFolderModal, setShowRenameFolderModal] =
    useState<boolean>(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] =
    useState<boolean>(false);
  const [showUploadFileModal, setShowUploadFileModal] =
    useState<boolean>(false);
  const [showAddDocModal, setShowAddDocModal] = useState<boolean>(false);
  const [showAddSheetModal, setShowAddSheetModal] = useState<boolean>(false);
  // With every update to the filesAndFolders selector we navigate to the
  // root directory because of the useEffect, so this will help not navigate
  // the user back once this screen has been mounted
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const filesAndFolders: FilesAndFolders[] =
    useSelector(getFilesBasedOnCurrentUser)?.files || [];
  const trashedFilesAndFolders: FilesAndFolders[] = useSelector(getTrashedFilesBasedOnCurrentUser)?.files || [];

  const parentDocReference: { [T: string]: string } =
    useSelector(getAllFilesBasedOnCurrentUser)?.parentDocReference || {};
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const account: TangoAccount | null = useSelector(
    (state: RootState) => state.account
  );
  const allStaff: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const allBusinesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );

  const [currentFolder, setCurrentFolder] =
    useState<FilesAndFoldersWithDepth | null>(null);
  const [currentChildrenFolders, setCurrentChildrenFolders] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const [currentChildrenFiles, setCurrentChildrenFiles] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const [currentTrashedFolder, setCurrentTrashedFolder] =
    useState<FilesAndFoldersWithDepth | null>(null);
  const [currentTrashedChildrenFolders, setCurrentTrashedChildrenFolders] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const [currentTrashedChildrenFiles, setCurrentTrashedChildrenFiles] = useState<
    FilesAndFoldersWithDepth[]
  >([]);

  const [allFiles, setAllFiles] = useState<FilesAndFoldersWithDepth[]>([]);
  const [folderDepthHistory, setFolderDepthHistory] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const [folderTrashDepthHistory, setFolderTrashDepthHistory] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchedFiles, setSearchedFiles] = useState<
    FilesAndFoldersWithDepth[]
  >([]);
  const navigate = useNavigate();
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const getChildFilesAndFoldersFromCurrentFolderWithCustomSetOfFilesAndFolders = (
    currentFolder: FilesAndFoldersWithDepth,
    customFilesAndFolders: FilesAndFolders[]
  ) => {
    const childItems = customFilesAndFolders.filter((file) =>
      currentFolder.childrenIds.includes(file.id)
    );
    const childFolders = childItems
      .filter((item) => !!item.isFolder)
      .map((item) => ({ ...item, depthIndex: currentFolder.depthIndex + 1 }));
    const childFiles = childItems
      .filter((item) => !item.isFolder)
      .map((item) => ({ ...item, depthIndex: currentFolder.depthIndex + 1 }));
    return {
      folders: childFolders,
      files: childFiles,
    };
  }

  const getChildFilesAndFoldersFromCurrentFolder = (
    currentFolder: FilesAndFoldersWithDepth
  ) => {
    const childItems = filesAndFolders.filter((file) =>
      currentFolder.childrenIds.includes(file.id)
    );
    const childFolders = childItems
      .filter((item) => !!item.isFolder)
      .map((item) => ({ ...item, depthIndex: currentFolder.depthIndex + 1 }));
    const childFiles = childItems
      .filter((item) => !item.isFolder)
      .map((item) => ({ ...item, depthIndex: currentFolder.depthIndex + 1 }));
    return {
      folders: childFolders,
      files: childFiles,
    };
  };

  const getParentToFileOrFolder = (item: FilesAndFolders) => {
    const parentFolder = filesAndFolders.filter(
      (folder) => folder.id === item.parentId
    );
    if (parentFolder.length) {
      return parentFolder[0];
    }
    return null;
  };

  const replaceChildrenPaths = async (
    file: FilesAndFolders,
    oldName: string,
    newName: string
  ) => {
    for await (const id of file.childrenIds) {
      const childFile = filesAndFolders.filter((file) => file.id === id);
      if (childFile.length > 0) {
        const fileToUpdate = childFile[0];
        const absolutePath = fileToUpdate.absolutePath.replace(
          "/" + oldName,
          "/" + newName
        );
        await updateTheAbsolutePath(
          fileToUpdate.businessId,
          fileToUpdate,
          absolutePath
        );
        if (fileToUpdate.childrenIds.length > 0) {
          await replaceChildrenPaths(fileToUpdate, oldName, newName);
        }
      }
    }
  };

  useEffect(() => {
    // On mount go to root
    if (!isMounted && filesAndFolders && filesAndFolders.length) {
      const root = filesAndFolders.filter(
        (file) => file.isFolder && file.id === file.parentId
      );

      if (root.length > 0) {
        const currentRoot = root[0];
        setCurrentFolder({ ...currentRoot, depthIndex: 0 });
        setCurrentTrashedFolder({ ...currentRoot, depthIndex: 0 });
        setIsMounted(true);
      }
    } else {
      // Files and folders got an update so current folder needs
      // to be updated with the new data
      if (currentFolder) {
        const currentDir = filesAndFolders.filter(
          (file) => file.isFolder && file.id === currentFolder.id
        );

        if (currentDir.length > 0) {
          const newCurrentFolder = {
            ...currentDir[0],
            depthIndex: currentFolder.depthIndex,
          };
          setCurrentFolder(newCurrentFolder);
          setCurrentTrashedFolder(newCurrentFolder);
        }
      }
    }

    // On files and folders update the all files
    if (filesAndFolders && filesAndFolders.length) {
      const files = filesAndFolders.filter((file) => !file.isFolder);
      if (files.length > 0) {
        // This will be used only for the files and starred files
        // layout so we might not need the depthIndex in this scope
        setAllFiles([...files.map((file) => ({ ...file, depthIndex: 0 }))]);
      }
    }
  }, [filesAndFolders]);


  const findFilesAndFoldersDepth = (
    fileOrFolder: FilesAndFolders
  ): FilesAndFoldersWithDepth => {
    // TODO: Make sure we convert '/' to '-'
    const depth = fileOrFolder.absolutePath.split("/").length - 1;
    return {
      ...fileOrFolder,
      depthIndex: depth,
    };
  };

  const updateTrashFolder = (currentDir: FilesAndFoldersWithDepth) => {
    const newFilesAndFolders =
      getChildFilesAndFoldersFromCurrentFolderWithCustomSetOfFilesAndFolders(currentDir, trashedFilesAndFolders);
    setCurrentTrashedChildrenFolders([...newFilesAndFolders.folders]);
    setCurrentTrashedChildrenFiles([...newFilesAndFolders.files]);

    // Use the current folder and folder depth history to determine whether
    // this is going in deeper or rolling back to a previous directory.
    // This useEffect will control the time travel automatically by simply
    // setting state to the current folder.
    if (
      folderTrashDepthHistory.length > 0 &&
      folderTrashDepthHistory[folderTrashDepthHistory.length - 1].depthIndex >=
      currentDir.depthIndex
    ) {
      // Roll back to the directory of interest
      const newFolderHistory = folderTrashDepthHistory.filter(
        (history) => history.depthIndex <= currentDir.depthIndex
      );
      setFolderTrashDepthHistory([...newFolderHistory]);
    } else {
      // Append because we're going into a new directory
      setFolderTrashDepthHistory([...folderTrashDepthHistory, currentDir]);
    }
  }

  const updateFolder = (currentDir: FilesAndFoldersWithDepth) => {
    const newFilesAndFolders =
      getChildFilesAndFoldersFromCurrentFolder(currentDir);
    setCurrentChildrenFolders([...newFilesAndFolders.folders]);
    setCurrentChildrenFiles([...newFilesAndFolders.files]);

    // Use the current folder and folder depth history to determine whether
    // this is going in deeper or rolling back to a previous directory.
    // This useEffect will control the time travel automatically by simply
    // setting state to the current folder.
    if (
      folderDepthHistory.length > 0 &&
      folderDepthHistory[folderDepthHistory.length - 1].depthIndex >=
      currentDir.depthIndex
    ) {
      // Roll back to the directory of interest
      const newFolderHistory = folderDepthHistory.filter(
        (history) => history.depthIndex <= currentDir.depthIndex
      );
      setFolderDepthHistory([...newFolderHistory]);
    } else {
      // Append because we're going into a new directory
      setFolderDepthHistory([...folderDepthHistory, currentDir]);
    }
  };

  useEffect(() => {
    // If the current folder changes set the child folders
    if (currentFolder) {
      updateFolder(currentFolder);
    }
    if (currentTrashedFolder) {
      updateTrashFolder(currentTrashedFolder)
    }
  }, [currentFolder, filesAndFolders, currentTrashedFolder]);

  // TODO: Find out if this logic is necessary
  // useEffect(() => {
  //   setSelectedFolder(null);
  // }, [selectedPage]);

  const columns: ColumnInstruction<ExtendedFilesAndFolders>[] = [
    { type: "projection", header: "Name", attribute: "fileName" },
    { type: "projection", header: "Last Modified", attribute: "lastModified" },
    { type: "data", header: "Size", attribute: "formattedSize" },
    { type: "data", header: "Kind", attribute: "fileExtension" },
    { type: "data", header: "Sharing", attribute: "sharing" },
  ];

  const data: ExtendedFilesAndFolders[] = [
    ...currentChildrenFolders,
    ...currentChildrenFiles,
  ].map((fileOrFolder) => {
    return {
      ...fileOrFolder,
      uniqueId: fileOrFolder.id,
      lastModified: fileOrFolder.updatedAt,
      fileExtension: fileOrFolder.isFolder ? "Folder" : getFileType(fileOrFolder.fileExtension ?? "") ?? "Unknown",
      formattedSize: getFileSize(fileOrFolder.fileSize),
      sharing: "View Members",
    };
  });

  const trashedData: ExtendedFilesAndFolders[] = [
    ...currentTrashedChildrenFolders,
    ...currentTrashedChildrenFiles,
  ].map((fileOrFolder) => {
    return {
      ...fileOrFolder,
      uniqueId: fileOrFolder.id,
      lastModified: fileOrFolder.updatedAt,
      fileExtension: fileOrFolder.isFolder ? "Folder" : getFileType(fileOrFolder.fileExtension ?? "") ?? "Unknown",
      formattedSize: getFileSize(fileOrFolder.fileSize),
      sharing: "View Members",
    };
  });


  const instructions: {
    [x: string]: RenderInstruction<ExtendedFilesAndFolders, ActionTypes>;
  } = {};

  instructions.fileName = {
    type: "complex-custom",
    viewComponent: (props) => {
      const { fullObject } = props;
      return <DocumentRow document={fullObject} />;
    },
    editComponent: () => null,
  };

  instructions.lastModified = {
    type: "complex-custom",
    viewComponent: (props) => {
      const { fullObject } = props;
      return (
        <div>
          <span>{moment(fullObject.lastModified).format("MM / DD / YYYY")}</span>
          {/* <span className="font-lato-regular text-xs text-grey-2 ml-2.5">
            John Smith
          </span> */}
        </div>
      );
    },
    editComponent: null,
  };



  const deleteFileOrFolderHandler = useCallback(async (fileOrFolderId: string) => {
    const fileOrFolder = filesAndFolders.find(
      (f) => f.id === fileOrFolderId
    );

    const fileOrFolderParent = filesAndFolders.find(
      (f) => f.id === fileOrFolder?.parentId
    );


    if (fileOrFolder && fileOrFolderParent) {

      const childrenFilesAndFolders: FilesAndFolders[] =
        getAllFilesAndFoldersForFolder(
          fileOrFolder,
          filesAndFolders,
          []
        );


      await deleteFileOrFolder(
        fileOrFolder.businessId,
        fileOrFolder,
        fileOrFolderParent,
        childrenFilesAndFolders,
        filesAndFolders
      );
    }

  }, [
    filesAndFolders,
    getAllFilesAndFoldersForFolder,
    deleteFileOrFolder
  ])

  const downloadHandler = useCallback((id) => {
    const fileOrFolder = filesAndFolders.find(
      (f) => f.id === id
    );

    if (fileOrFolder && fileOrFolder.downloadUrl) {
      window.open(fileOrFolder.downloadUrl, "_blank")?.focus();
    }

  }, [filesAndFolders])

  instructions.sharing = {
    type: "complex-custom",
    viewComponent: (props) => {
      return (
        <div style={{ width: "fit-content" }} className="flex">
          {/* <Button type="btn-style-1" size="btn-small" label="View members" /> */}
          <Icon name="more" className="ml-11 cursor-pointer" />
        </div>
      );
    },
    editComponent: null,
    actionPopup: ({ fullObject, onSelect }) => {
      const ownerId = fullObject.authorId?.staffId
      const owner = user.id === ownerId ? user : allStaff.find(user => user.id === ownerId)
      const ownerName = owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown'
      return (
        <div className="absolute w-72 shadow-card z-10 bg-white p-2 rounded-lg top-0">
          {fullObject.isFolder ? null : (
            <div className="flex items-center justify-between">
              <Button
                label="Open File"
                onClick={() => downloadHandler(fullObject.id)}
                rightIcon="open-link"
                size="btn-small"
                type="btn-style-2"
              />
              <Icon name="cross" size="15" className="cursor-pointer" />
            </div>
          )}
          <div>
            <ul className="list-none p-0 ml-3 mb-0 mt-2 border-b border-grey-1 border-solid border-t-0 border-l-0 border-r-0">
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Name</div>
                <div className="w-8/12 font-lato-regular">
                  {fullObject.name}
                </div>
              </li>
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Kind</div>
                <div className="w-8/12 font-lato-regular">{fullObject.fileExtension}</div>
              </li>
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Location</div>
                <div className="w-8/12 font-lato-regular">
                  <img src={FolderIcon} className="w-4 mr-2" />
                  {fullObject.absolutePath}
                </div>
              </li>
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Owner</div>
                <div className="w-8/12 font-lato-regular flex items-center">
                  <NumberTag
                    text="js"
                    color="blue"
                    type="small"
                    className="mr-2"
                  />
                  {ownerName}
                </div>
              </li>
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Modified</div>
                <div className="w-8/12 font-lato-regular flex items-center">
                  <span>{moment(fullObject.updatedAt).format("DD / MM / YYYY")}</span>
                  {/* <span className="text-grey-2 ml-2">Billy Joel</span> */}
                </div>
              </li>
              <li className="text-black text-xs flex items-center py-4">
                <div className="w-4/12  font-lato-black">Created</div>
                <div className="w-8/12 font-lato-regular flex items-center">
                  <span>{moment(fullObject.createdAt).format("DD / MM / YYYY")}</span>
                  <span className="text-grey-2 ml-2">{ownerName}</span>
                </div>
              </li>
            </ul>
            <ul className="list-none p-0 ml-3 mb-0 mt-2 border-b border-grey-1 border-solid border-t-0 border-l-0 border-r-0">
              {/* <li
                className="text-black text-xs flex items-center py-4 cursor-pointer"
                onClick={() =>
                  onSelect ? onSelect(fullObject, "move-to") : null
                }
              >
                <div className="font-lato-black">Move to...</div>
              </li> */}
              <li
                className="text-black text-xs flex items-center py-4 cursor-pointer"
                onClick={() => (onSelect ? onSelect(fullObject, "share") : null)}
              >
                <div className="font-lato-black">Share...</div>
              </li>
              {!fullObject.isFolder ? (
                <li className="text-black text-xs flex items-center py-4 cursor-pointer">
                  <div className="font-lato-black" onClick={() => downloadHandler(fullObject.id)}>Download</div>
                </li>
              ) : null}

            </ul>
            <ul className="list-none p-0 ml-3 mb-0 mt-2">
              {/* <li className="text-black text-xs flex items-center py-4 cursor-pointer">
                <div className="font-lato-black">Duplicate</div>
              </li> */}
              {locationName === "files" ? (
                <li className="text-error-red text-xs flex items-center py-4 cursor-pointer">
                  <div onClick={() => deleteFileOrFolderHandler(fullObject.id)} className="font-lato-black">Delete</div>
                </li>
              ) : null}

            </ul>
          </div>
        </div>
      );
    },
  };



  const handleCloudFilesRowClick = useCallback((id: string) => {
    const fileOrFolder = [...currentChildrenFolders, ...currentChildrenFiles].find((f) => f.id === id);

    if (fileOrFolder?.isFolder) {
      setCurrentFolder(fileOrFolder);
    }

  }, [setCurrentFolder, filesAndFolders, currentChildrenFolders, currentChildrenFiles])

  const handleTrashedFilesRowClick = useCallback((id: string) => {
    const fileOrFolder = [...currentTrashedChildrenFolders, ...currentTrashedChildrenFiles].find((f) => f.id === id);

    if (fileOrFolder?.isFolder) {
      setCurrentTrashedFolder(fileOrFolder);
    }

  }, [setCurrentTrashedFolder, filesAndFolders, currentTrashedChildrenFiles, currentTrashedChildrenFolders])

  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings)

  const isEnterpriseLevelUpload = useMemo(() => {
    return !emulatorMode && user && user.corporateAccess;
  }, [
    emulatorMode,
    user,
  ])

  const uploadFileHandler = useCallback(() => {
    const fileUploadInput = document.getElementById("upload-file");
    if (fileUploadInput && currentFolder) {
      fileUploadInput.click();
      fileUploadInput.onchange = async (e) => {
        // Hacky way to reference the input div with
        // documentGetElementById syntax without adding
        // an event listener to a useEffect - I should
        // probably learn React better :)
        // @ts-ignore
        const files = e.target?.files;



        // This is blank because multiple files can be selected from this workflow
        await addFileWithWaterfall(
          filesAndFolders,
          allStaff,
          business?.id || "",
          "",
          currentFolder,
          user?.staffMemberCopyId || "",
          files,
          "",
          isEnterpriseLevelUpload ? user.accountId : "",
          isEnterpriseLevelUpload
            ? account
              ? account.parentId || ""
              : ""
            : ""
        );
        setLoading(false);
      };
    }
  }, [
    filesAndFolders,
    allStaff,
    business?.id,
    currentFolder,
    user?.staffMemberCopyId,
    emulatorMode,
    user?.corporateAccess,
    user?.accountId,
    account,
  ])



  const createNewFolderHandler = useCallback(async () => {
    if (business && currentFolder && user?.staffMemberCopyId) {
      const name = "New Folder"

      const newFolderBusinessAccess = currentFolder
        .businessAccess
        .map(bId => allBusinesses.find(b => b.id === bId))
        .filter(b => !!b) as TangoBusiness[]

      const isEnterpriseLevelUpload =
        !emulatorMode && user.corporateAccess;

      // await addFolderWithWaterfall(
      //   filesAndFolders,
      //   allStaff,
      //   business.id,
      //   name,
      //   currentFolder,
      //   user.staffMemberCopyId,
      //   [],
      //   [],
      //   [],
      //   undefined,
      //   currentFolder.accountId,
      //   currentFolder.id,
      //   newFolderBusinessAccess
      // )

      const folder = await addFolderWithWaterfall(
        filesAndFolders,
        allStaff,
        business?.id || "",
        name,
        currentFolder,
        user?.staffMemberCopyId || "",
        [],
        [],
        [],
        "",
        isEnterpriseLevelUpload ? user.accountId : "",
        isEnterpriseLevelUpload
          ? account
            ? account.parentId || ""
            : ""
          : "",
        isEnterpriseLevelUpload ? newFolderBusinessAccess || [] : []
      );

    }


  }, [allStaff, filesAndFolders, data, business, currentFolder, currentChildrenFolders])

  const [selectedFileOrFolderIdForSharing, setSelectedFileOrFolderIdForSharing] = useState<string | null>(null)

  const selectedFileOrFolderForSharing = useMemo(() => {
    return filesAndFolders.find(f => f.id === selectedFileOrFolderIdForSharing) ?? null
  }, [selectedFileOrFolderIdForSharing, filesAndFolders])

  const handleInvites = useCallback(async (userIds: string[], businessesOptions: SearchableBusinessOption[]) => {
    const usersToInvite = userIds.map(id => allStaff.find(s => s.uid === id)).filter(s => !!s) as StaffMember[]
    if (selectedFileOrFolderForSharing && selectedFileOrFolderForSharing.isFolder) {
      await invitePeopleToFolderWithWaterfall(
        selectedFileOrFolderForSharing,
        usersToInvite,
        businessesOptions,
        filesAndFolders,
      )
    }


  }, [allStaff, filesAndFolders, data, business, currentFolder, currentChildrenFolders, selectedFileOrFolderForSharing])

  const staffMemberSharingOptions = useMemo(() => {
    const initialAccess: FileSharingOptions = {
      owner: null,
      users: [],
      businesses: [],
      groups: [],
      searchableBusinesses: [],
      searchableUsers: [],
    }
    if (!selectedFileOrFolderForSharing) {
      return initialAccess
    }

    const fileOrFolderOwner = allStaff.find(s => s.staffMemberCopyId === selectedFileOrFolderForSharing.authorId?.staffId) ?? null
    if (fileOrFolderOwner) {
      initialAccess.owner = {
        userId: fileOrFolderOwner.id,
        fullName: `${fileOrFolderOwner.contact.firstName} ${fileOrFolderOwner.contact.lastName}`,
        abbrName: `${fileOrFolderOwner.contact.firstName[0]}${fileOrFolderOwner.contact.lastName[0]}`,
        profileURL: fileOrFolderOwner.imageUrl,
        accessType: "editor",
        roleTitle: "",
      }
    }

    const businessesToDisplayAsOptions = isEnterpriseLevelUpload ? allBusinesses : [business]

    const businessLevelAccess = selectedFileOrFolderForSharing.businessLevelStaffAccess?.filter(b => businessesToDisplayAsOptions.find(bb => bb.id === b.businessId)) ?? []
    console.log("businessLevelAccess", businessLevelAccess)
    if (businessLevelAccess.length && selectedFileOrFolderForSharing) {
      const enrichedBusinessLevelAccess: BusinessLevelSharingEntry[] = businessLevelAccess.map((ba) => {
        const business = businessesToDisplayAsOptions.find(b => b.id === ba.businessId) ?? null
        const businessUsers = (allStaff.filter(s => s.businessId === business?.id) ?? [])
          .map(sm => {
            const accessTypeForStaffMember = selectedFileOrFolderForSharing.access.find(s => s.staffId === sm.staffMemberCopyId)?.accessType
            const businessLevelAccessForStaffMember = ba.accessType
            const accessTypeToUse = accessTypeForStaffMember ?? businessLevelAccessForStaffMember
            return {
              userId: sm.id,
              fullName: `${sm.contact.firstName} ${sm.contact.lastName}`,
              abbrName: `${sm.contact.firstName[0]}${sm.contact.lastName[0]}`,
              profileURL: sm.imageUrl,
              accessType: accessTypeToUse,
            }
          })
        if (business) {
          return {
            businessId: business.id,
            businessName: business.businessName,
            generalAccessType: ba.accessType,
            accessBreadth: ba.type,
            users: businessUsers
          }
        }
        return null
      }).filter(x => !!x) as BusinessLevelSharingEntry[]
      initialAccess.businesses = enrichedBusinessLevelAccess
    }

    const allUsersWithAccess = allStaff.map(sm => {
      const accessTypeForStaffMember = selectedFileOrFolderForSharing.access.find(s => s.staffId === sm.staffMemberCopyId)?.accessType
      const accessTypeToUse = accessTypeForStaffMember
      if (!accessTypeToUse || accessTypeToUse === "removed") {
        return null
      }
      const primaryRole = sm.primaryRole
      const roleTitle = primaryRole ? businessSettings.jobFunctions?.[primaryRole]?.title : ""
      return {
        userId: sm.id,
        fullName: `${sm.contact.firstName} ${sm.contact.lastName}`,
        abbrName: `${sm.contact.firstName[0]}${sm.contact.lastName[0]}`,
        profileURL: sm.imageUrl,
        roleTitle,
        accessType: accessTypeToUse
      }
    }).filter(x => !!x) as UserLevelSharingEntry[]

    const searchableUsers: UserLevelSharingEntry[] = allStaff.filter(sm => !allUsersWithAccess.find(u => u.userId === sm.staffMemberCopyId)).map(sm => {
      const primaryRole = sm.primaryRole
      const roleTitle = primaryRole ? businessSettings.jobFunctions?.[primaryRole]?.title : ""
      return {
        userId: sm.id,
        fullName: `${sm.contact.firstName} ${sm.contact.lastName}`,
        abbrName: `${sm.contact.firstName[0]}${sm.contact.lastName[0]}`,
        profileURL: sm.imageUrl,
        accessType: "viewer",
        roleTitle,
      }
    })

    initialAccess.users = allUsersWithAccess

    initialAccess.searchableUsers = searchableUsers

    const searchableBusinesses: BusinessLevelSharingEntry[] = businessesToDisplayAsOptions.filter(b => !initialAccess.businesses.find(bb => bb.businessId === b.id)).map(b => {
      return {
        businessId: b.id,
        businessName: b.businessName,
        generalAccessType: "viewer",
        users: []
      }
    })

    initialAccess.searchableBusinesses = searchableBusinesses

    initialAccess.groups = []

    return initialAccess

  }, [user, selectedFileOrFolderForSharing, filesAndFolders, allStaff, isEnterpriseLevelUpload, allBusinesses])


  return {
    currentChildrenFolders,
    currentChildrenFiles,
    data,
    columns,
    instructions,
    handleTrashedFilesRowClick,
    handleCloudFilesRowClick,
    folderDepthHistory,
    setCurrentFolder,
    uploadFileHandler,
    createNewFolderHandler,
    setSelectedFileOrFolderIdForSharing,
    selectedFileOrFolderForSharing,
    staffMemberSharingOptions,

    folderTrashDepthHistory,
    setCurrentTrashedFolder,
    updateTrashFolder,
    trashedData,
    handleInvites
  };
};
