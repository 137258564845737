import { EditableText, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "model/store";

import { updateTangoProductSetting } from "../../../../models/businesses";
import "./index.css";

const Channels = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  //DECLARE EACH VALUE TO BE USED IN TABLE CELLS
  //get tax rate, preptime, and enabled from tangoProducts - "Tango POS" is the default values (ie Tango Speed)
  const taxRate =
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "dineIn"
    )[0].taxRate;
  const dineInTax =
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "dineIn"
    )[0].taxRate;
  const carryoutTax =
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "carryOut"
    )[0].taxRate;
  const curbsideTax =
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "curbside"
    )[0].taxRate;
  const deliveryTax =
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "delivery"
    )[0].taxRate;

  const dineInPrep =
    business &&
      business.tangoProducts[0].orderTypes.filter(
        (orderType: any) => orderType.name === "dineIn"
      )[0].waitTime[0] === 5
      ? {
        label: "5-15m",
        value: [5, 15],
      }
      : business &&
        business.tangoProducts[0].orderTypes.filter(
          (orderType: any) => orderType.name === "dineIn"
        )[0].waitTime[0] === 1
        ? {
          label: "0-5m",
          value: [1, 5], //save as 1 minute min prep time for deliverect
        }
        : business &&
          business.tangoProducts[0].orderTypes.filter(
            (orderType: any) => orderType.name === "dineIn"
          )[0].waitTime[0] === 15
          ? {
            label: "15-25m",
            value: [15, 25],
          }
          : business &&
            business.tangoProducts[0].orderTypes.filter(
              (orderType: any) => orderType.name === "dineIn"
            )[0].waitTime[0] === 25
            ? {
              label: "25-35m",
              value: [25, 35],
            }
            : business &&
              business.tangoProducts[0].orderTypes.filter(
                (orderType: any) => orderType.name === "dineIn"
              )[0].waitTime[0] === 35
              ? {
                label: "35-45m",
                value: [35, 45],
              }
              : business &&
                business.tangoProducts[0].orderTypes.filter(
                  (orderType: any) => orderType.name === "dineIn"
                )[0].waitTime[0] === 45
                ? {
                  label: "45-60m",
                  value: [45, 60],
                }
                : business &&
                  business.tangoProducts[0].orderTypes.filter(
                    (orderType: any) => orderType.name === "dineIn"
                  )[0].waitTime[0] === 60
                  ? {
                    label: "60-90m",
                    value: [60, 90],
                  }
                  : {
                    label: "Select Option",
                    value: [5, 15],
                  };

  const carryoutPrep =
    business &&
      business.tangoProducts[0].orderTypes.filter(
        (orderType: any) => orderType.name === "carryOut"
      )[0].waitTime[0] === 5
      ? {
        label: "5-15m",
        value: [5, 15],
      }
      : business &&
        business.tangoProducts[0].orderTypes.filter(
          (orderType: any) => orderType.name === "carryOut"
        )[0].waitTime[0] === 1
        ? {
          label: "0-5m",
          value: [1, 5], //save as 1 minute min prep time for deliverect
        }
        : business &&
          business.tangoProducts[0].orderTypes.filter(
            (orderType: any) => orderType.name === "carryOut"
          )[0].waitTime[0] === 15
          ? {
            label: "15-25m",
            value: [15, 25],
          }
          : business &&
            business.tangoProducts[0].orderTypes.filter(
              (orderType: any) => orderType.name === "carryOut"
            )[0].waitTime[0] === 25
            ? {
              label: "25-35m",
              value: [25, 35],
            }
            : business &&
              business.tangoProducts[0].orderTypes.filter(
                (orderType: any) => orderType.name === "carryOut"
              )[0].waitTime[0] === 35
              ? {
                label: "35-45m",
                value: [35, 45],
              }
              : business &&
                business.tangoProducts[0].orderTypes.filter(
                  (orderType: any) => orderType.name === "carryOut"
                )[0].waitTime[0] === 45
                ? {
                  label: "45-60m",
                  value: [45, 60],
                }
                : business &&
                  business.tangoProducts[0].orderTypes.filter(
                    (orderType: any) => orderType.name === "carryOut"
                  )[0].waitTime[0] === 60
                  ? {
                    label: "60-90m",
                    value: [60, 90],
                  }
                  : {
                    label: "Select Option",
                    value: [5, 15],
                  };

  const curbsidePrep =
    business &&
      business.tangoProducts[0].orderTypes.filter(
        (orderType: any) => orderType.name === "curbside"
      )[0].waitTime[0] === 5
      ? {
        label: "5-15m",
        value: [5, 15],
      }
      : business &&
        business.tangoProducts[0].orderTypes.filter(
          (orderType: any) => orderType.name === "curbside"
        )[0].waitTime[0] === 1
        ? {
          label: "0-5m",
          value: [1, 5],
        }
        : business &&
          business.tangoProducts[0].orderTypes.filter(
            (orderType: any) => orderType.name === "curbside"
          )[0].waitTime[0] === 15
          ? {
            label: "15-25m",
            value: [15, 25],
          }
          : business &&
            business.tangoProducts[0].orderTypes.filter(
              (orderType: any) => orderType.name === "curbside"
            )[0].waitTime[0] === 25
            ? {
              label: "25-35m",
              value: [25, 35],
            }
            : business &&
              business.tangoProducts[0].orderTypes.filter(
                (orderType: any) => orderType.name === "curbside"
              )[0].waitTime[0] === 35
              ? {
                label: "35-45m",
                value: [35, 45],
              }
              : business &&
                business.tangoProducts[0].orderTypes.filter(
                  (orderType: any) => orderType.name === "curbside"
                )[0].waitTime[0] === 45
                ? {
                  label: "45-60m",
                  value: [45, 60],
                }
                : business &&
                  business.tangoProducts[0].orderTypes.filter(
                    (orderType: any) => orderType.name === "curbside"
                  )[0].waitTime[0] === 60
                  ? {
                    label: "60-90m",
                    value: [60, 90],
                  }
                  : {
                    label: "Select Option",
                    value: [5, 15],
                  };

  const deliveryPrep =
    business &&
      business.tangoProducts[0].orderTypes.filter(
        (orderType: any) => orderType.name === "delivery"
      )[0].waitTime[0] === 5
      ? {
        label: "5-15m",
        value: [5, 15],
      }
      : business &&
        business.tangoProducts[0].orderTypes.filter(
          (orderType: any) => orderType.name === "delivery"
        )[0].waitTime[0] === 1
        ? {
          label: "0-5m",
          value: [1, 5],
        }
        : business &&
          business.tangoProducts[0].orderTypes.filter(
            (orderType: any) => orderType.name === "delivery"
          )[0].waitTime[0] === 15
          ? {
            label: "15-25m",
            value: [15, 25],
          }
          : business &&
            business.tangoProducts[0].orderTypes.filter(
              (orderType: any) => orderType.name === "delivery"
            )[0].waitTime[0] === 25
            ? {
              label: "25-35m",
              value: [25, 35],
            }
            : business &&
              business.tangoProducts[0].orderTypes.filter(
                (orderType: any) => orderType.name === "delivery"
              )[0].waitTime[0] === 35
              ? {
                label: "35-45m",
                value: [35, 45],
              }
              : business &&
                business.tangoProducts[0].orderTypes.filter(
                  (orderType: any) => orderType.name === "delivery"
                )[0].waitTime[0] === 45
                ? {
                  label: "45-60m",
                  value: [45, 60],
                }
                : business &&
                  business.tangoProducts[0].orderTypes.filter(
                    (orderType: any) => orderType.name === "delivery"
                  )[0].waitTime[0] === 60
                  ? {
                    label: "60-90m",
                    value: [60, 90],
                  }
                  : {
                    label: "Select Option",
                    value: [5, 15],
                  };

  const [dineInEnabled, setDineInEnabled] = useState(
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "dineIn"
    )[0].enabled
  );
  const [carryoutEnabled, setCarryoutEnabled] = useState(
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "carryOut"
    )[0].enabled
  );
  const [curbsideEnabled, setCurbsideEnabled] = useState(
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "curbside"
    )[0].enabled
  );
  const [deliveryEnabled, setDeliveryEnabled] = useState(
    business &&
    business.tangoProducts[0].orderTypes.filter(
      (orderType: any) => orderType.name === "delivery"
    )[0].enabled
  );

  //Handle Change each table cell
  const [newTaxRate, setNewTaxRate] = useState(taxRate);
  const [newDineInTax, setDineInTax] = useState(dineInTax);
  const [newCarryoutTax, setCarryoutTax] = useState(carryoutTax);
  const [newCurbsideTax, setCurbsideTax] = useState(curbsideTax);
  const [newDeliveryTax, setDeliveryTax] = useState(deliveryTax);

  const handleGlobalTaxChange = () => {
    if (!parseFloat(newTaxRate) || parseFloat(newTaxRate) < 0) {
      console.log("not a float", newTaxRate);
      return setNewTaxRate(taxRate); // if entered negative or invalid number just set back to original taxrate
    } else {
      console.log("confirm valid tax rate", newTaxRate);
      //call save to firebase
    }
  };

  const handleChannelTaxChange = async (channel: string) => {
    //handle invalid tax input by setting back to original value
    if (
      channel === "dineIn" &&
      (!newDineInTax ||
        !isFinite(newDineInTax) ||
        !parseFloat(newDineInTax) ||
        parseFloat(newDineInTax) < 0)
    ) {
      return setDineInTax(dineInTax);
    }
    if (
      channel === "carryOut" &&
      (!newCarryoutTax ||
        !isFinite(newCarryoutTax) ||
        !parseFloat(newCarryoutTax) ||
        parseFloat(newCarryoutTax) < 0)
    ) {
      return setCarryoutTax(carryoutTax);
    }
    if (
      channel === "curbside" &&
      (!newCurbsideTax ||
        !isFinite(newCurbsideTax) ||
        !parseFloat(newCurbsideTax) ||
        parseFloat(newCurbsideTax) < 0)
    ) {
      return setCurbsideTax(curbsideTax);
    }
    if (
      channel === "delivery" &&
      (!newDeliveryTax ||
        !isFinite(newDeliveryTax) ||
        !parseFloat(newDeliveryTax) ||
        parseFloat(newDeliveryTax) < 0)
    ) {
      return setDeliveryTax(deliveryTax);
    }

    //map over each tango product and update respective tax
    business.tangoProducts.map((tangoProduct: any) => {
      tangoProduct.orderTypes.map((orderType: any) => {
        if (channel === "dineIn" && orderType.name === channel) {
          orderType.taxRate = parseFloat(newDineInTax);
          console.log("set new dine in tax", orderType);
        }
        if (channel === "carryOut" && orderType.name === channel) {
          orderType.taxRate = parseFloat(newCarryoutTax);
        }
        if (channel === "curbside" && orderType.name === channel) {
          orderType.taxRate = parseFloat(newCurbsideTax);
        }
        if (channel === "delivery" && orderType.name === channel) {
          orderType.taxRate = parseFloat(newDeliveryTax);
        }
      });
    });

    const success = await updateTangoProductSetting(
      business.id,
      business.tangoProducts
    );
    if (success) {
      return console.log("updated tango products with new tax");
    } else {
      return alert("There was a problem updating your business settings");
    }
  };

  const handleSelectPrepTime = async (channel: string, selectedOption: any) => {
    console.log(channel, selectedOption);

    business.tangoProducts.map((tangoProduct: any) => {
      tangoProduct.orderTypes.map((orderType: any) => {
        if (channel === "dineIn" && orderType.name === channel) {
          orderType.waitTime = selectedOption.value;
        }
        if (channel === "carryOut" && orderType.name === channel) {
          orderType.waitTime = selectedOption.value;
        }
        if (channel === "curbside" && orderType.name === channel) {
          orderType.waitTime = selectedOption.value;
        }
        if (channel === "delivery" && orderType.name === channel) {
          orderType.waitTime = selectedOption.value;
        }
      });
    });

    const success = await updateTangoProductSetting(
      business.id,
      business.tangoProducts
    );
    if (success) {
      return console.log("updated tango products with new prep time");
    } else {
      return alert("There was a problem updating your business settings");
    }
  };

  const handleToggleChannel = async (channel: string) => {
    //get the enabled setting in speed which is considered "default"
    let isEnabled: boolean;
    business.tangoProducts[0].orderTypes.map((orderType: any) => {
      if (orderType.name === channel) {
        isEnabled = !orderType.enabled;
      }
    });

    business.tangoProducts.map((tangoProduct: any) => {
      tangoProduct.orderTypes.map((orderType: any) => {
        if (orderType.name === channel) {
          orderType.enabled = isEnabled;
        }
      });
    });

    const success = await updateTangoProductSetting(
      business.id,
      business.tangoProducts
    );
    //if success update in firestore then change it in local state
    if (success) {
      console.log("updated tango products with toggle channel");

      if (channel === "dineIn") {
        setDineInEnabled(!dineInEnabled);
      }
      if (channel === "carryOut") {
        setCarryoutEnabled(!carryoutEnabled);
      }
      if (channel === "curbside") {
        setCurbsideEnabled(!curbsideEnabled);
      }
      if (channel === "delivery") {
        setDeliveryEnabled(!deliveryEnabled);
      }
    } else {
      return alert("There was a problem updating your business settings");
    }
  };

  if (business) {
    return (
      <div id="channels-taxes">
        <div className="section-name">Channels & Taxes</div>
        <div className="section-body">
          <div className="subtitle">General</div>
          <div className="section-row">
            <div className="field-title">Global Tax Rate</div>
            <div className="field-value">{taxRate ? taxRate : ""} %</div>

            {/* <div className="field-value">
          <EditableText
            className="editableText"
            // intent={"success"} //green color
            maxLength={5}
            defaultValue={taxRate || 0.0} //starting value
            value={newTaxRate} //after calling onChange -> setNewTaxRate
            onChange={(e)=>setNewTaxRate(e)}
            onConfirm={()=>{console.log("on confirm", newTaxRate); handleGlobalTaxChange()}} //on click away this fires
          />
          <span style={{paddingLeft:"8px"}}>%</span>
          </div> */}
          </div>
          <div className="info-container">
            <div className="subtitle">Channels & Taxes</div>
            <div className="info-text">
              <img
                src={require("../../../../assets/information.svg").default}
              />
              <div>
                Channel Specific Tax Rates will override the global tax rate
              </div>
            </div>
          </div>

          <div style={{ marginTop: "40px" }}>
            <table>
              <tr>
                <th>Channel</th>
                <th>Enabled</th>
                <th>Default Prep Time</th>
                <th>Channel Specific Tax Rate</th>
              </tr>

              <tr>
                <td>Dine In</td>
                <td>
                  {dineInEnabled ? (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("dineIn");
                      }}
                      className="enabledTag"
                      intent="primary"
                    >
                      Enabled
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("dineIn");
                      }}
                      className="enabledTag"
                      intent="danger"
                    >
                      Disabled
                    </Tag>
                  )}
                </td>
                <td>
                  <Select
                    className="flex-react-select"
                    classNamePrefix="flex-react-select"
                    onChange={(option) => {
                      handleSelectPrepTime("dineIn", option);
                    }}
                    options={[
                      {
                        label: "0-5m",
                        value: [1, 5],
                      },
                      {
                        label: "5-15m",
                        value: [5, 15],
                      },
                      {
                        label: "15-25m",
                        value: [15, 25],
                      },
                      {
                        label: "25-35m",
                        value: [25, 30],
                      },
                      {
                        label: "35-45m",
                        value: [35, 45],
                      },
                      {
                        label: "45-60m",
                        value: [45, 60],
                      },
                      {
                        label: "60-90m",
                        value: [60, 90],
                      },
                    ]}
                    value={dineInPrep}
                  />
                </td>
                <td>
                  <EditableText
                    className="editableText"
                    // intent={"success"} //green color
                    maxLength={5}
                    defaultValue={dineInTax ? dineInTax : 0.0} //starting value
                    value={newDineInTax} //after calling onChange -> setNewTaxRate
                    onChange={(e) => setDineInTax(e)}
                    onConfirm={() => {
                      handleChannelTaxChange("dineIn");
                    }} //on click away this fires
                  />
                  %
                </td>
              </tr>
              <tr>
                <td>Carry Out</td>
                <td>
                  {carryoutEnabled ? (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("carryOut");
                      }}
                      className="enabledTag"
                      intent="primary"
                    >
                      Enabled
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("carryOut");
                      }}
                      className="enabledTag"
                      intent="danger"
                    >
                      Disabled
                    </Tag>
                  )}
                </td>
                <td>
                  <Select
                    className="flex-react-select"
                    classNamePrefix="flex-react-select"
                    onChange={(option) => {
                      handleSelectPrepTime("carryOut", option);
                    }}
                    options={[
                      {
                        label: "0-5m",
                        value: [1, 5],
                      },
                      {
                        label: "5-15m",
                        value: [5, 15],
                      },
                      {
                        label: "15-25m",
                        value: [15, 25],
                      },
                      {
                        label: "25-35m",
                        value: [25, 30],
                      },
                      {
                        label: "35-45m",
                        value: [35, 45],
                      },
                      {
                        label: "45-60m",
                        value: [45, 60],
                      },
                      {
                        label: "60-90m",
                        value: [60, 90],
                      },
                    ]}
                    value={carryoutPrep}
                  />
                </td>
                <td>
                  <EditableText
                    className="editableText"
                    // intent={"success"} //green color
                    maxLength={5}
                    defaultValue={carryoutTax ? carryoutTax : 0.0} //starting value
                    value={newCarryoutTax} //after calling onChange -> setNewTaxRate
                    onChange={(e) => setCarryoutTax(e)}
                    onConfirm={() => {
                      handleChannelTaxChange("carryOut");
                    }} //on click away this fires
                  />
                  %
                </td>
              </tr>
              <tr>
                <td>Curbside</td>
                <td>
                  {curbsideEnabled ? (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("curbside");
                      }}
                      className="enabledTag"
                      intent="primary"
                    >
                      Enabled
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("curbside");
                      }}
                      className="enabledTag"
                      intent="danger"
                    >
                      Disabled
                    </Tag>
                  )}
                </td>
                <td>
                  <Select
                    className="flex-react-select"
                    classNamePrefix="flex-react-select"
                    onChange={(option) => {
                      handleSelectPrepTime("curbside", option);
                    }}
                    options={[
                      {
                        label: "0-5m",
                        value: [1, 5],
                      },
                      {
                        label: "5-15m",
                        value: [5, 15],
                      },
                      {
                        label: "15-25m",
                        value: [15, 25],
                      },
                      {
                        label: "25-35m",
                        value: [25, 30],
                      },
                      {
                        label: "35-45m",
                        value: [35, 45],
                      },
                      {
                        label: "45-60m",
                        value: [45, 60],
                      },
                      {
                        label: "60-90m",
                        value: [60, 90],
                      },
                    ]}
                    value={curbsidePrep}
                  />
                </td>
                <td>
                  <EditableText
                    className="editableText"
                    // intent={"success"} //green color
                    maxLength={5}
                    defaultValue={curbsideTax ? curbsideTax : 0.0} //starting value
                    value={newCurbsideTax} //after calling onChange -> setNewTaxRate
                    onChange={(e) => setCurbsideTax(e)}
                    onConfirm={() => {
                      handleChannelTaxChange("curbside");
                    }} //on click away this fires
                  />
                  %
                </td>
              </tr>
              <tr>
                <td>Delivery</td>
                <td>
                  {deliveryEnabled ? (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("delivery");
                      }}
                      className="enabledTag"
                      intent="primary"
                    >
                      Enabled
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        handleToggleChannel("delivery");
                      }}
                      className="enabledTag"
                      intent="danger"
                    >
                      Disabled
                    </Tag>
                  )}
                </td>
                <td>
                  <Select
                    className="flex-react-select"
                    classNamePrefix="flex-react-select"
                    onChange={(option) => {
                      handleSelectPrepTime("delivery", option);
                    }}
                    options={[
                      {
                        label: "0-5m",
                        value: [1, 5],
                      },
                      {
                        label: "5-15m",
                        value: [5, 15],
                      },
                      {
                        label: "15-25m",
                        value: [15, 25],
                      },
                      {
                        label: "25-35m",
                        value: [25, 30],
                      },
                      {
                        label: "35-45m",
                        value: [35, 45],
                      },
                      {
                        label: "45-60m",
                        value: [45, 60],
                      },
                      {
                        label: "60-90m",
                        value: [60, 90],
                      },
                    ]}
                    value={deliveryPrep}
                  />
                </td>

                <td>
                  <EditableText
                    className="editableText"
                    // intent={"success"} //green color
                    maxLength={5}
                    defaultValue={deliveryTax ? deliveryTax : 0.0} //starting value
                    value={newDeliveryTax} //after calling onChange -> setNewTaxRate
                    onChange={(e) => setDeliveryTax(e)}
                    onConfirm={() => {
                      handleChannelTaxChange("delivery");
                    }} //on click away this fires
                  />
                  %
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Channels;
