import { Button, HTMLSelect } from "@blueprintjs/core";
import React from "react";

import Box from "components/Box";

const StaffingForm = () => (
  <Box className="form-container">
    <div className="slide-in">
      <div className="welcome-title">
        Awesome!
        <br />
        One more quick thing.
      </div>
      <div className="select-tango-products-text">
        When does your payroll week start?
      </div>
      <HTMLSelect
        options={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ]}
        iconProps={{ icon: "chevron-down", iconSize: 10 }}
        className="payroll-week"
      />
      <Button text="Next" fill className="next-button" />
    </div>
  </Box>
);
export default StaffingForm;
