import { UserAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function user(state = initialState, action: UserAction) {
  switch (action.type) {
    case ActionType.RECEIVE_USER:
      return action.user;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
