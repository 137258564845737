import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Assign } from "utility-types";
import tangoComponents from "@tangopay/tango-ui-library";

import { apiErrorHandler } from "controllers/core";
import { formatCentValue } from "controllers/reporting";
import {
  EmployeeCompensationItem,
  EmployeeDetails as IEmployeeDetails,
  updateCompensationInfo,
  updateEmployementInfo,
} from "controllers/team";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import HorizontalTable, {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import {
  EmploymentDetailInstruction,
  PermissionEditComponent,
  PermissionViewComponent,
  PrimaryRoleEditComponent,
  PrimaryRoleViewComponent,
  SecondaryEditRolesComponent,
  SecondaryRolesViewComponent,
} from "./components/TableExtentions";
import { generateNewPincode } from "controllers/staff";

const { Button } = tangoComponents;


const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface PaymentInfoProps {
  teamTableData: IEmployeeDetails;
}

const PaymentInfo = (props: PaymentInfoProps) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const [employmentInfoLoading, setEmploymentInfoLoading] =
    React.useState(true);

  const [employmentInfoEditing, setEmploymentInfoEditing] =
    React.useState(false);
  const [compensationInfoEditing, setCompensationInfoEditing] =
    React.useState(false);

  const onEmploymentInfoEditStart = useCallback(
    (v: boolean) => {
      setEmploymentInfoEditing(v);
    },
    [setEmploymentInfoEditing]
  );

  const onCompensationInfoEditStart = useCallback(
    (v: boolean) => {
      setCompensationInfoEditing(v);
    },
    [setCompensationInfoEditing]
  );

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const queryClient = useQueryClient();

  const invalidateDetailsRequest = useCallback(() => {
    queryClient.invalidateQueries([
      "employeeDetails",
      business?.id,
      props.teamTableData.uid,
    ]);
  }, [queryClient, business?.id, props.teamTableData.uid]);

  const generateNewPincodeMutation = useMutation(
    (data: {
      businessId: string;
      uid: string;
    }) => {
      return generateNewPincode(
        data.businessId,
        data.uid,
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const saveEmploymentInfoMutation = useMutation(
    (data: {
      uid: string;
      businessId: string;
      primaryRole: string;
      secondaryRoles: string[];
      isAdmin: boolean;
      externalId?: string | null;
    }) => {
      return updateEmployementInfo(
        data.businessId,
        data.uid,
        data.primaryRole,
        data.secondaryRoles,
        data.isAdmin,
        data.externalId
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const onEmploymentInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);
      const userId = props.teamTableData.uid;
      const businessId = business.id;

      const updatedPrimaryRole =
        (//@ts-ignore
          updates.find((update) => update.key === "primaryRole")?.newValue
            //@ts-ignore
            ?.roleId as string) ??
        props.teamTableData.employmentInfo.primaryRole.roleId;

      console.log("updatedPrimaryRole", updatedPrimaryRole);

      const updatedSecondaryRoles =
        (updates
          .find((update) => update.key === "secondaryRoles")
          //@ts-ignore
          ?.newValue?.map((r) => r?.roleId) as string[]) ??
        props.teamTableData.employmentInfo.secondaryRoles.map(
          (role) => role.roleId
        );

      const oldIsAdmin = props.teamTableData.employmentInfo.isAdmin
      const newIsAdmin = updates.find((update) => update.key === "isAdmin")?.newValue
      const updatedIsAdmin = !_.isNil(newIsAdmin) ? newIsAdmin as boolean : oldIsAdmin

      const updatedExternalId = (updates.find((update) => update.key === "externalId")?.newValue ?? props.teamTableData.employmentInfo.externalId) as string | null


      await saveEmploymentInfoMutation.mutateAsync({
        uid: userId,
        businessId,
        primaryRole: updatedPrimaryRole,
        secondaryRoles: updatedSecondaryRoles,
        isAdmin: updatedIsAdmin,
        externalId: updatedExternalId
      });
    },
    [
      saveEmploymentInfoMutation,
      props.teamTableData.employmentInfo,
      business?.id,
    ]
  );

  const saveCompensationInfoMutation = useMutation(
    (data: { uid: string; businessId: string; payRates: PayRate[] }) => {
      return updateCompensationInfo(data.businessId, data.uid, data.payRates);
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const generateNewPincodeHandler = useCallback(() => {
    if (props.teamTableData.uid && business?.id) {
      generateNewPincodeMutation.mutateAsync({
        uid: props.teamTableData.uid,
        businessId: business.id,
      });
    }

  }, [generateNewPincodeMutation, business?.id, props.teamTableData])

  const onCompensationInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);
      const userId = props.teamTableData.uid;
      const businessId = business.id;

      const existingPayRate = props.teamTableData.compensationInfo;

      const payRatesFromUpdates: PayRate[] = updates.map((update) => {
        const updateKey = update.key as string;
        const jobId = updateKey.split(".")[0];
        const rate = Number(update.newValue) * 100;
        return {
          role: businessSettings?.jobFunctions?.[jobId]?.title,
          roleId: jobId,
          amount: rate,
        };
      });

      const payRatesThatDontExistInDB = payRatesFromUpdates.filter(
        (payRate) => !existingPayRate.find((pr) => pr.roleId === payRate.roleId)
      );

      const finalPayRates = [
        ...existingPayRate.map((pr) => {
          if (payRatesFromUpdates.find((p) => p.roleId === pr.roleId)) {
            return payRatesFromUpdates.find((p) => p.roleId === pr.roleId);
          }
          return {
            role: pr.roleTitle,
            roleId: pr.roleId,
            amount: pr.rate,
          };
        }),
        ...payRatesThatDontExistInDB,
      ].filter((x) => !!x) as PayRate[];

      console.log("updates", updates);
      console.log("finalPayRates", finalPayRates);

      await saveCompensationInfoMutation.mutateAsync({
        uid: userId,
        businessId,
        payRates: finalPayRates,
      });
    },
    [
      saveCompensationInfoMutation,
      props.teamTableData.compensationInfo,
      business?.id,
      businessSettings,
    ]
  );

  const employmentInfoColumns: ColumnInstruction<EmploymentDetailInstruction>[] =
    [
      { type: "data", header: "Employment Type", attribute: "employmentType" },
      {
        type: "data",
        header: "Start Date",
        attribute: "formattedEmploymentStartDate",
      },
      { type: "data", header: "Reports To", attribute: "managerName" },
      { type: "data", header: "Locations", attribute: "locations" },
      { type: "data", header: "Permission", attribute: "permission" },
      { type: "data", header: "Department", attribute: "department" },
      { type: "data", header: "Level", attribute: "level" },
      { type: "data", header: "Primary Role", attribute: "primaryRoleTitle" },
      {
        type: "data",
        header: "Secondary Roles",
        attribute: "secondaryRolesTitles",
      },
      {
        type: "data",
        header: "Compensation Type",
        attribute: "compensationType",
      },
      {
        type: "data",
        header: "External Id",
        attribute: "externalId",
      },
    ];

  const employmentInfoData: EmploymentDetailInstruction[] = useMemo(() => {
    const jobFunctions = businessSettings?.jobFunctions ?? {};
    const availableRoles = _.values(jobFunctions).filter(jfd => !jfd.deleted).map((role) => ({
      roleId: role.id,
      roleTitle: role.title,
    }));
    return [
      {
        ...props.teamTableData.employmentInfo,
        uniqueId: props.teamTableData.uid,
        primaryRoleTitle:
          props.teamTableData.employmentInfo?.primaryRole?.roleTitle ?? "-",
        secondaryRolesTitles:
          props.teamTableData.employmentInfo?.secondaryRoles
            ?.map((role) => role.roleTitle)
            .join(", ") ?? "-",
        businessAvailableRoles: availableRoles,
      },
    ];
  }, [props.teamTableData.employmentInfo, businessSettings]);

  const compensationInfoColumns: ColumnInstruction<
    Assign<{ [roleId: string]: EmployeeCompensationItem }, { uniqueId: string }>
  >[] = useMemo(() => {
    return props.teamTableData.compensationInfo.map((compensation) => ({
      type: "data",
      header: `${compensation.roleTitle}`,
      attribute: `${compensation.roleId}.rate`,
    }));
  }, [props.teamTableData.compensationInfo]);


  //@ts-ignore
  const compensationInfoData: Assign<
    { [roleId: string]: EmployeeCompensationItem },
    { uniqueId: string }
  >[] = useMemo(() => {
    return [
      {
        ..._.keyBy(
          props.teamTableData.compensationInfo.map((ci) => ({
            ...ci,
            rate: formatCentValue(ci.rate),
          })),
          "roleId"
        ),
        uniqueId: props.teamTableData.uid,
      },
    ];
  }, [props.teamTableData.compensationInfo, businessSettings]);

  console.log("compensationInfoData", compensationInfoData);

  const employmentInfoInstructions: {
    [x: string]: RenderInstruction<EmploymentDetailInstruction>;
  } = {};

  employmentInfoInstructions.secondaryRolesTitles = {
    type: "complex-custom",
    viewComponent: SecondaryRolesViewComponent,
    editComponent: SecondaryEditRolesComponent,
  };

  employmentInfoInstructions.primaryRoleTitle = {
    type: "complex-custom",
    viewComponent: PrimaryRoleViewComponent,
    editComponent: PrimaryRoleEditComponent,
  };

  employmentInfoInstructions.permission = {
    type: "complex-custom",
    viewComponent: PermissionViewComponent,
    editComponent: PermissionEditComponent,
  };

  return (
    <>
      <div className="mb-10">
        <HorizontalTable
          disabledAttributes={[
            "numberOfEmployees",
            "formattedEmploymentStartDate",
            "level",
            "locations",
            "managerName",
            "employmentType"
          ]}
          title="Employment Info"
          isEditing={employmentInfoEditing}
          columns={employmentInfoColumns}
          data={employmentInfoData}
          setEditing={onEmploymentInfoEditStart}
          instructions={employmentInfoInstructions}
          saveResults={onEmploymentInfoSaveHandler}
          loading={saveEmploymentInfoMutation.isLoading}
          isVertical
        />

        <div className="flex justify-between align-center mt-4 border-t border-b rounded-xl  border-grey-1" style={{ borderStyle: "solid", borderBottomStyle: "solid", overflow: "hidden" }}>
          <div
            className="bg-blue-grey-2 align-center "
            style={{ flex: 1 }}
          >
            <div className="text-xs font-lato-black px-6 py-2">
              Pin Code
            </div>
          </div>
          <div className="flex justify-between align-center " style={{ flex: 1 }}>
            <div className="px-6 py-2 text-xs font-lato-grey">{props.teamTableData.employmentInfo.pinCode}</div>
            <Button onClick={generateNewPincodeHandler} disabled={generateNewPincodeMutation.isLoading} label="Generate" type="btn-style-minimal" size="btn-small" />
          </div>
        </div>
      </div>
      <div className="mb-10">
        <HorizontalTable
          loading={saveCompensationInfoMutation.isLoading}
          title="Compensation Info"
          isVertical
          isEditing={compensationInfoEditing}
          //@ts-ignore
          columns={compensationInfoColumns}
          //@ts-ignore
          data={compensationInfoData}
          setEditing={onCompensationInfoEditStart}
          //@ts-ignore
          instructions={{}}
          saveResults={onCompensationInfoSaveHandler}
        />
      </div>
    </>
  );
};

export default PaymentInfo;
