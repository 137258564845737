import { ReviewResponseTemplate, ReviewTrackersReview } from '../../../types/reputationManagement';
import { fetchAccountMetrics, fetchAllReviews, fetchReviewTrackersCreds } from '../utils';
import _ from 'lodash';
import { RootState } from 'model/store';
import { getAllReviewResponseTemplates } from 'models/reputationManagement';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type RatingFilterType = 'All' | '1' | '2' | '3' | '4' | '5';
const useReviews = () => {
  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings);
  const business: TangoBusiness = useSelector((state: RootState) => state.business);
  const businesses: TangoBusiness[] = useSelector((state: RootState) => state.businesses);

  //Toppers '633341f4e684833ac5970119'//T JACKS '62b9be9ab9f8534172548f26'// Tango '62d5dc33f576a2c07452b2a5' //
  const reviewTrackersAccountId = businessSettings?.reviewTrackersAccountId ? businessSettings.reviewTrackersAccountId : '';
  const reviewTrackersLocationId = businessSettings?.reviewTrackersLocationId ? businessSettings.reviewTrackersLocationId : '';
  const reviewTrackersEnabled = businessSettings?.reviewTrackersEnabled ? businessSettings.reviewTrackersEnabled : false;

  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false);
  const [reviewTrackersCreds, setReviewTrackersCreds] = useState({ token: '' });
  const [allReviews, setAllreviews] = useState<ReviewTrackersReview[]>([]);
  const [accountMetrics, setAccountMetrics] = useState({ avg_rating: 0.0 });
  const [loading, setLoading] = useState(true);
  const [selectedRatingFilter, setSelectedRatingFilter] = useState<RatingFilterType>('All');
  const [reviewSites, setReviewSites] = useState<string[]>([]);
  const [reviewTags, setReviewTags] = useState<string[]>([]);
  const [selectedReviewTagsFilter, setSelectedReviewTagsFilter] = useState<string>('');
  const [selectedReviewSiteFilter, setSelectedReviewSiteFilter] = useState<string>('All');
  const [selectedReviewLocationFilter, setSelectedReviewLocationFilter] = useState<any>({
    filterValue: 'All Locations',
    businessSettingsForSelectedLocation: null,
  });
  const [selectedDateReviewsFilter, setSelectedDateFilter] = useState<string>(moment().subtract(6, 'month').format('yyyy-MM-DD')); //pass in defualt date
  const [reviewTemplates, setReviewTemplates] = useState<ReviewResponseTemplate[]>([]);
  const [openAuthorizeDialog, setOpenAuthorizeDialog] = useState(false);

  const filterByRatings = (reviews: ReviewTrackersReview[]) => {
    if (selectedRatingFilter === 'All') {
      return reviews;
    }
    return reviews.filter((reviewItem) => reviewItem.exact_rating === selectedRatingFilter);
  };

  const filterByReviewTags = (reviews: ReviewTrackersReview[]) => {
    switch (true) {
      case selectedReviewTagsFilter === 'responded':
        return reviews.filter((reviewItem: ReviewTrackersReview) => reviewItem.response);
      case selectedReviewTagsFilter === 'unresponded':
        return reviews.filter((reviewItem: ReviewTrackersReview) => !reviewItem.response);
      case selectedReviewTagsFilter === 'positive':
        return reviews.filter((reviewItem: ReviewTrackersReview) => reviewItem.rating > 3);
      case selectedReviewTagsFilter === 'negative':
        return reviews.filter((reviewItem: ReviewTrackersReview) => reviewItem.rating <= 3);
      case selectedReviewTagsFilter === 'pictures':
        return reviews.filter((reviewItem: ReviewTrackersReview) => reviewItem.images.length > 0);
      default:
        return reviews;
    }
  };

  const filterByReviewLocation = (reviews: ReviewTrackersReview[]) => {
    if (selectedReviewLocationFilter.filterValue === 'All Locations') {
      return reviews;
    } else {
      if (selectedReviewLocationFilter.businessSettingsForSelectedLocation) {
        return reviews.filter(
          (reviewItem) => reviewItem.location_id === selectedReviewLocationFilter.businessSettingsForSelectedLocation.reviewTrackersLocationId,
        );
      } else {
        return reviews;
      }
    }
  };

  const filterByReviewSite = (reviews: ReviewTrackersReview[]) => {
    if (selectedReviewSiteFilter === 'All') {
      return reviews;
    }
    return reviews.filter((reviewItem) => reviewItem.source_name === selectedReviewSiteFilter);
  };

  const filterReviewsByDate = (reviews: ReviewTrackersReview[]) => {
    //subtract 1 day from filter date to include any reivews on the date selected
    const newFilterDate = moment(selectedDateReviewsFilter).subtract(1, 'day').format('yyyy-MM-DD');
    const filteredReviewsByDate = reviews.filter((reviewItem) => {
      const reivewDate = moment(reviewItem.published_at).format('yyyy-MM-DD');
      if (moment(reivewDate).isAfter(newFilterDate)) {
        return reviewItem;
      }
    });
    return filteredReviewsByDate;
  };

  const getReviewTrackersCreds = async () => {
    const credentials = await fetchReviewTrackersCreds();
    //if sucessful get credentials then get review data
    //(account_id is Tango api account, not to be confused with reviewTrackersAccountId which is for the business)
    if (credentials.account_id && credentials.token) {
      setReviewTrackersCreds(credentials); //set credentials in state if subsequent api calls needed
      console.log('review trackers creds', credentials);

      if (!reviewTrackersEnabled) {
        setLoading(false);
        return setOpenAuthorizeDialog(true);
      } else {
        setOpenAuthorizeDialog(false);
      }

      getAllReviews(credentials);
      getAccountMetrics(credentials);
    }
  };

  const getAccountMetrics = async (credentials: any) => {
    const success = await fetchAccountMetrics(credentials, reviewTrackersAccountId, selectedDateReviewsFilter);
    setAccountMetrics(success);
  };

  const getAllReviews = async (credentials: any) => {
    const success = await fetchAllReviews(credentials, reviewTrackersAccountId, '300'); //default to 300 reviews
    console.log('found allReviews --> ', success);
    setAllreviews(success.allReviews);
    setLoading(false);
  };

  const fetchTemplates = async () => {
    if (business) {
      const allTemplates = await getAllReviewResponseTemplates(business.accountId);
      setReviewTemplates(allTemplates);
    }
  };

  //on component load fetch Tango's Review Tracker credentials - needed for all review trackers api calls
  useEffect(() => {
    if (business && businessSettings) {
      getReviewTrackersCreds();
      fetchTemplates();
    }
  }, [business, businessSettings]);

  return {
    setLoading,
    loading,
    setShowRequestReviewModal,
    showRequestReviewModal,
    setAllreviews,
    allReviews,
    setReviewTrackersCreds,
    reviewTrackersCreds,
    setAccountMetrics,
    accountMetrics,
    reviewTrackersAccountId,
    fetchAllReviews,
    selectedRatingFilter,
    setSelectedRatingFilter,
    filterByRatings,
    reviewSites,
    reviewTags,
    filterByReviewTags,
    filterByReviewSite,
    filterReviewsByDate,
    setSelectedReviewTagsFilter,
    selectedReviewTagsFilter,
    setSelectedReviewSiteFilter,
    selectedReviewSiteFilter,
    filterByReviewLocation,
    setSelectedReviewLocationFilter,
    selectedReviewLocationFilter,
    setSelectedDateFilter,
    selectedDateReviewsFilter,
    setOpenAuthorizeDialog,
    openAuthorizeDialog,
    reviewTemplates,
    business,
    businessSettings,
    businesses,
  };
};
export default useReviews;
