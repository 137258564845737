import React from "react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";

import HorizontalTable from "components/Table/HorizontalTable";
import { useRolesTable } from "../../hooks/useRolesTable";


const { TextField } = tangoComponents;


interface JobRolesTableHeaderProps {
  title: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onRefreshClick: () => void;
  loading: boolean;
}

const JobRolesTableHeader = (props: JobRolesTableHeaderProps) => {
  return (
    <>
      <div className={"font-lato-black text-xl text-black"}>{props.title}</div>
      <div className={"flex items-center"}>
        <div
          onClick={props.onRefreshClick}
          className={"cursor-pointer text-xs font-lato-bold text-black mr-7"}
        >
          Refresh
        </div>

        {props.loading ? (
          <Spinner size={SpinnerSize.SMALL} className="mr-7" />
        ) : null}

        <div className={"h-9 w-px bg-grey-1 mr-7"} />
        <TextField
          className="hori-table-search-input"
          value={props.searchValue}
          // @ts-ignore
          onChange={(e) => props.onSearchChange(e.target.value)}
          sizeType="medium"
          placeholder="Search"
        />
      </div>
    </>
  );
};

const JobRolesTable = () => {

  const {
    jobRolesTableData,
    columns,
    onSearchChange,
    addNewRowHandler,
    searchFilter,
    deleteRowsHandler,
    loading,
    tableDataCombinedWithAddedRows,
    rolesInfoInstructions,
    rolesInfoEditing,
    onRolesInfoEditStart,
    saveResultsHandler,
    rowErrors
  } = useRolesTable();

  return (
    <div>
      <HorizontalTable
        title="Archived Employees"
        columns={columns}
        disabledAttributes={["numberOfEmployees"]}
        data={tableDataCombinedWithAddedRows}
        instructions={rolesInfoInstructions}
        isEditing={rolesInfoEditing}
        setEditing={onRolesInfoEditStart}
        primaryActionLabel="Add new role"
        primaryActionHandler={addNewRowHandler}
        saveResults={saveResultsHandler}
        errors={rowErrors}
        deleteIds={deleteRowsHandler}
        customHeaderLeftContent={
          <JobRolesTableHeader
            title="Job Roles"
            searchValue={searchFilter}
            onSearchChange={onSearchChange}
            loading={loading}
            onRefreshClick={() => jobRolesTableData.refetch()}
          />
        }
      />
    </div>
  );
};

export default JobRolesTable;
