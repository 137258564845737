import React, { useCallback, useEffect, useState } from "react";

// copy pasted, bad practice but this is a proof of concept
type conversionProps = {
  uniqueId: string;
  fromAmount: number;
  fromId: string | null;
  toAmount: number;
  toId: string | null;
};

type ViewProps = {
  fullObject: conversionProps;
};
type EditProps = {
  fullObject: conversionProps;
  onChange?: (newValue: conversionProps) => unknown;
};

export const RatioViewComponent = ({ fullObject }: ViewProps) => {
  const ratio = fullObject?.toAmount / fullObject?.fromAmount;
  return <>{ratio}</>;
};
export const RatioEditComponent = ({ fullObject, onChange }: EditProps) => {
  const ratio = fullObject?.toAmount / fullObject?.fromAmount;
  const handleChange = useCallback(
    (e) => {
      const newRatio = e.target.value;
      const newObject = { ...fullObject };
      newObject.toAmount = newObject.fromAmount * newRatio;
      if (onChange) onChange(newObject);
    },
    [fullObject, onChange]
  );

  return (
    <input
      type="number"
      value={ratio.toString()}
      onChange={handleChange}
      className={"border-0 text-grey-3"}
    />
  );
};
