import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  UpdateSaleDTO,
  Vendor,
  useCreateSale,
  useDeleteSale,
  useUpdateSale,
  useUpdateVendor,
  useVendor,
} from "controllers/vendors";
import {
  useRawItemsInfo,
} from "controllers/inventoryItemInfo";

import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { SimpleRenderInstruction } from "../../../components/Table/GenericCell/TableCell";
import { RootState } from "../../../model/store";
import { SaleInfo, instructionsFactory } from "./supplies";

type VendorDetail = {
  uniqueId: string;
  name: string;
  accountNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactAddress: string;
};

const infoHeaders: ColumnInstruction<VendorDetail>[] = [
  { header: "Vendor Name", type: "data", attribute: "name" },
  { header: "Account Number", type: "data", attribute: "accountNumber" },
];
const contactHeaders: ColumnInstruction<VendorDetail>[] = [
  { header: "Contact Name", type: "data", attribute: "contactName" },
  { header: "Phone Number", type: "data", attribute: "contactPhone" },
  { header: "Email", type: "data", attribute: "contactEmail" },
  { header: "Address", type: "data", attribute: "contactAddress" },
];
const saleHeaders: ColumnInstruction<SaleInfo>[] = [
  { header: "Item", type: "projection", attribute: "item" },
  { header: "Purchase Price", type: "projection", attribute: "purchasePrice" },
  { header: "Split Unit", type: "projection", attribute: "splitUnit" },
  { header: "Pak Unit", type: "projection", attribute: "pakUnit" },
  { header: "Default Vendor", type: "data", attribute: "isDefault" },
];

const prefix = "new__vendor__sale";
const vendorInstructions: { [s: string]: SimpleRenderInstruction } = {};

const useVendorDetail = (vendorId: string) => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [editingVendor, setEditingVendor] = useState(false);
  const [editingContact, setEditingContact] = useState(false);
  const [editingSales, setEditingSales] = useState(false);
  useEffect(() => {
    if (!editingSales) updateItems(true);
  }, [editingSales]);
  // call update with false to push, true to clear
  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );
  const add = useCallback(() => {
    setEditingSales(true);
    updateItems(false);
  }, []);

  const vendorsQuery = useVendor(businessId, vendorId);
  const updateVendor = useUpdateVendor(businessId);
  const updateSale = useUpdateSale(businessId);
  const createSale = useCreateSale(businessId);
  const deleteSale = useDeleteSale(businessId);
  const rawItemsQuery = useRawItemsInfo(businessId);
  const rawItems = rawItemsQuery.data ?? [];

  const saleInstructions = useMemo(
    () => instructionsFactory(rawItems.map(i => ({ ...i, isRaw: true }))),
    [rawItems]
  );

  // const create = useCreateVendor(businessId);

  const vendorFromServer = vendorsQuery.data?.vendor ?? null;
  const vendor: VendorDetail = useMemo(
    () => ({
      uniqueId: vendorId,
      name: vendorFromServer?.name ?? "",
      accountNumber: vendorFromServer?.accountNumber ?? "",
      contactName: vendorFromServer?.contactName ?? "",
      contactEmail: vendorFromServer?.contactEmail ?? "",
      contactPhone: vendorFromServer?.contactPhone ?? "",
      contactAddress: vendorFromServer?.contactAddress ?? "",
    }),
    [vendorFromServer, vendorId]
  );
  const salesFromServer = vendorsQuery.data?.itemsSold ?? null;
  const sales: SaleInfo[] = useMemo(() => {
    const existing =
      salesFromServer?.map((sale) => {
        const item = sale.rawItem as { name: string; id: string };
        return {
          uniqueId: sale.id,
          name: item.name,
          isDefault: sale.isDefaultVendor,
          itemId: item.id,
          pricePerSaleUnit: sale.pricePerSaleUnit,
          saleUnitName: sale.saleUnit,
          splitUnitsPerSaleUnit: sale.splitUnitsPerSaleUnit,
          splitUnitName: sale.splitUnit,
          productionUnitsPerSplitUnit: sale.productionUnitsPerSplitUnit,
          productionUnitName: sale.productionUnit,
        };
      }) ?? [];
    const added = addedIds.map((uniqueId) => ({
      uniqueId,
      name: "",
      isDefault: false,
      itemId: "",
      pricePerSaleUnit: 100,
      saleUnitName: "unit",
      splitUnitsPerSaleUnit: 1,
      splitUnitName: "unit",
      productionUnitsPerSplitUnit: 1,
      productionUnitName: "unit",
    }));
    return [...added, ...existing];
  }, [salesFromServer, addedIds]);
  // const dataWithAdditions = useMemo(
  //   () => [...addedVendors, ...vendorsFromServer],
  //   [addedVendors, vendorsFromServer]
  // );

  const saveVendor = useCallback(
    async (instructions: UpdateState) => {
      const updates = instructions[vendorId] ?? {};
      const newValue: Partial<Vendor> = {};
      if ("name" in updates) {
        newValue.name = updates.name.newValue as string;
      }
      if ("accountNumber" in updates) {
        newValue.accountNumber = updates.accountNumber.newValue as string;
      }
      if ("contactName" in updates) {
        newValue.contactName = updates.contactName.newValue as string;
      }
      if ("contactPhone" in updates) {
        newValue.contactPhone = updates.contactPhone.newValue as string;
      }
      if ("contactEmail" in updates) {
        newValue.contactEmail = updates.contactEmail.newValue as string;
      }
      if ("contactAddress" in updates) {
        newValue.contactAddress = updates.contactAddress.newValue as string;
      }
      updateVendor.mutate({ vendorId, newValue });
    },
    [updateVendor, vendorId]
  );

  const saveSales = useCallback(
    async (instructions: UpdateState) => {
      Object.keys(instructions)
        .filter((id) => !id.startsWith(prefix))
        .forEach((saleId) => {
          const updates = instructions[saleId];
          const newValue: Partial<UpdateSaleDTO> = {};
          if ("isDefault" in updates)
            newValue.isDefaultVendor = updates.isDefault.newValue as boolean;

          if ("pricePerSaleUnit" in updates)
            newValue.pricePerSaleUnit = updates.pricePerSaleUnit
              .newValue as number;

          if ("saleUnitName" in updates)
            newValue.saleUnit = updates.saleUnitName.newValue as string;

          if ("splitUnitName" in updates)
            newValue.splitUnit = updates.splitUnitName.newValue as string;

          if ("productionUnitsPerSplitUnit" in updates)
            newValue.productionUnitsPerSplitUnit = updates
              .productionUnitsPerSplitUnit.newValue as number;

          if ("splitUnitsPerSaleUnit" in updates)
            newValue.splitUnitsPerSaleUnit = updates.splitUnitsPerSaleUnit
              .newValue as number;

          updateSale.mutate({
            vendorId,
            saleId,
            newValue,
          });
        });
      addedIds.forEach((id) => {
        const update = instructions[id];
        // TODO: validate these before sending
        createSale.mutate({
          vendorId,
          newValue: {
            itemId: (update.itemId?.newValue ?? "") as string,
            isDefaultVendor: (update.isDefaultVendor?.newValue ??
              false) as boolean,
            pricePerSaleUnit: (update.pricePerSaleUnit?.newValue ??
              100) as number,
            saleUnit: (update.saleUnitName?.newValue ?? "unit") as string,
            splitUnit: (update.splitUnitName?.newValue ?? "unit") as string,
            splitUnitsPerSaleUnit: (update.splitUnitsPerSaleUnit?.newValue ??
              1) as number,
            productionUnitsPerSplitUnit: (update.productionUnitsPerSplitUnit
              ?.newValue ?? 1) as number,
          },
        });
      });
    },
    [updateSale, vendorId, addedIds]
  );

  const deleteSales = useCallback(
    async (ids: string[]) => {
      await Promise.all(
        ids.map((saleId) =>
          deleteSale.mutateAsync({
            vendorId,
            saleId,
          })
        )
      );
    },
    [deleteSale, vendorId]
  );
  //   async (instructions: UpdateState) => {
  // const saveChanges = useCallback(
  //   async (instructions: UpdateState) => {
  //     const toCreate = addedIds.map((id) => {
  //       const updates = instructions[id];
  //       return {
  //         name: (updates?.name?.newValue ?? "") as string,
  //         accountNumber: (updates?.accountNumber?.newValue ?? "") as string,

  //         contactName: "",
  //         contactPhone: "",
  //         contactEmail: "",
  //         contactAddress: "",
  //       };
  //     });
  //     return Promise.all([
  //       ...Object.entries(instructions)
  //         .filter(([id]) => !id.startsWith(prefix))
  //         .map(([vendorId, newValue]) => {
  //           const updateDTO = Object.keys(newValue).reduce(
  //             (acc, key) => ({
  //               ...acc,
  //               [key]: newValue[key].newValue,
  //             }),
  //             {}
  //           );
  //           return update.mutate({ vendorId, newValue: updateDTO });
  //         }),
  //       ...toCreate.map((el) => create.mutateAsync(el)),
  //     ]).then((result) => {
  //       if (toCreate.length === 1) {
  //         // we created a new vendor, return the new ID
  //         // if we created more than one, just ignore it
  //         const created = result[result.length - 1];
  //         return created?.id ?? null;
  //       }
  //       return null;
  //     });
  //   },
  //   [addedIds, create, update]
  // );

  return {
    vendorInstructions,
    saleInstructions,

    sales,
    editingSales,
    setEditingSales,
    saleHeaders,
    addSale: add,
    saveSales,
    deleteSales,

    vendor,
    saveVendor,
    editingVendor,
    setEditingVendor,
    editingContact,
    setEditingContact,
    infoHeaders,
    contactHeaders,
  };
};
export default useVendorDetail;
