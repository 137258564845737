// @ts-nocheck
import { EditableText, FileInput, Icon } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";

type Document = { [T: string]: any };

interface ModalProps {
  showModal: boolean;
  closeModal: (index: number | null) => void;
  data: Document;
  setData: (newData: Document) => void;
  options: any;
  viewOnly: boolean;
  additionalInfo: { [T: string]: any };
}

interface ProductModalRecord {
  nameExternal: string;
  nameInternal: string;
  enabled: string;
  price: number;
  type: string;
  tags: string;
  modifiers: string[];
  image: "";
  description: "";
  suggestedPairings: string[];
  taxRate: number | null;
  ingredients: string[];
  additionalInfo: { [T: string]: any };
}

function ProductsModal({
  showModal,
  closeModal,
  data,
  setData,
  options,
  viewOnly,
  additionalInfo,
}: ModalProps) {
  const [record, setRecord] = useState<ProductModalRecord | null>(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [newImage, setNewImage] = useState("");
  const modalRef = useRef(null);

  const [editText, setEditText] = useState<string | null>(null);
  const [tempText, setTempText] = useState("");
  const [errors, setErrors] = useState<string[]>([]);

  const preprocessData = (existingData: ProductModalRecord) => {
    if (existingData) {
      const result = existingData;
      console.log("EXISTING DATA: ", existingData);
      console.log("ADDITIONAL INFO: ", additionalInfo);
      if (viewOnly) {
        // Modifiers
        result.modifierNames = result.modifiers.map((modifierId: string) => {
          const index = additionalInfo.modifiers.findIndex(
            (modifier: { id: string }) => modifier.id === modifierId
          );
          return additionalInfo.modifiers[index].nameInternal;
        });
      }

      return result;
    }
  };

  useEffect(() => {
    if (data) {
      setRecord(preprocessData(data));
    }
    setEditText(null);
    setTempText("");
    setNewImage("");

    if (true) {
      console.log("DATA: ", data);
      console.log("OPTIONS: ", options);
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);
  const handleClick = (e: any) => {
    if (modalRef.current.contains(e.target)) {
      return;
    }
    // this click is outside
    closeModal(null);
  };
  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <span
          style={{ backgroundColor: `rgba(${props.data.color.join(",")},0.2)` }}
        >
          {children}
        </span>
      </components.Option>
    );
  };
  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <span
          style={{ backgroundColor: `rgba(${props.data.color.join(",")},0.2)` }}
        >
          {children}
        </span>
      </components.SingleValue>
    );
  };

  const getUi = (key: any, value: any, optionKey: any, editable = true) => {
    const foundSchema = options.find((item: any) => item.key === key);
    if (foundSchema) {
      if (foundSchema.isEditable) {
        if (foundSchema.isDropdown) {
          const selectedValue = foundSchema.dropdown.options.find(
            (item) => item.optionName === value
          );
          let multipleSelectedValue;
          if (foundSchema.dropdown.isMultiSelect) {
            multipleSelectedValue = foundSchema.dropdown.options.filter(
              (item) => value.includes(item.optionName)
            );
          }
          return (
            <Select
              className="product-modal-select"
              classNamePrefix="react-select"
              getOptionLabel={(option) =>
                optionKey ? option[optionKey] : option.optionName
              }
              getOptionValue={(option) =>
                optionKey ? option[optionKey] : option.optionName
              }
              options={foundSchema.dropdown.options}
              value={
                foundSchema.dropdown.isMultiSelect
                  ? multipleSelectedValue
                  : selectedValue
              }
              components={{ Option: CustomOption, SingleValue }}
              isMulti={foundSchema.dropdown.isMultiSelect}
              isOptionDisabled={(option) => (viewOnly ? editable : false)}
              styles={{
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    backgroundColor: `rgba(${data.color.join(",")}, 0.2)`,
                  };
                },
              }}
              onChange={(opt, meta) => {
                if (foundSchema.dropdown.isMultiSelect) {
                  if (meta.action === "remove-value") {
                    if (editText) {
                      setRecord({
                        ...record,
                        [editText]: [
                          ...record[editText].filter(
                            (option) => option !== meta.removedValue.optionName
                          ),
                        ].filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        ),
                      });
                    }
                  } else {
                    if (editText) {
                      setRecord({
                        ...record,
                        [editText]: [
                          ...record[editText],
                          ...opt.map((option) => option.optionName),
                        ].filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        ),
                      });
                    }
                  }
                } else {
                  if (editText) {
                    setRecord({
                      ...record,
                      [editText]: opt.optionName,
                    });
                    setEditText(null);
                  }
                }
              }}
            />
          );
        }
      } else {
        console.log("else");
        return value;
      }
    } else {
      return <span className="empty">Empty</span>;
    }
  };

  const onImageLoad = (e) => setNewImage(e.target.result);

  const onFileInputChange = (e) => {
    const fileReader = new FileReader();

    const previewImage = document.querySelector(".previewImage");

    fileReader.onload = onImageLoad;
    if (e.target.files.length) {
      fileReader.readAsDataURL(e.target.files[0]);

      // deliverect has 20mp constraints on product image size
      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = function () {
        if (img.naturalWidth * img.naturalHeight > 20000000) {
          return alert("Image is too large");
        }
      };
      previewImage.src = img.src;
      setImageSrc(img.src);
      setNewImage(e.target.value);
    }
  };

  const isFloat = (value: any) =>
    !isNaN(value) && value.toString().indexOf(".") != -1;
  const isInt = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

  const findErrors = () => {
    const newErrors = [];
    if (!isFloat(record?.price || 0) && !isInt(record?.price || 0)) {
      newErrors.push("price");
    }

    if (record?.taxRate && !isFloat(record.taxRate) && !isInt(record.taxRate)) {
      newErrors.push("taxRate");
    }
    setErrors(newErrors);

    return newErrors;
  };

  return (
    <div
      className="modal table-product-modal"
      style={{ display: showModal ? "grid" : "none" }}
    >
      <div className="modal-content" ref={modalRef}>
        <span
          className="close"
          onClick={() => {
            if (!viewOnly) {
              const errors = findErrors();
              if (errors.length === 0) {
                closeModal(null);
                if (record) {
                  setData({
                    ...data,
                    nameExternal: record.nameExternal,
                    nameInternal: record.nameInternal,
                    enabled: record.enabled,
                    price:
                      typeof record.price === "string"
                        ? parseFloat(record.price)
                        : record.price,
                    type: record.type,
                    tags: record.tags,
                    modifiers: record.modifiers,
                    imageUrl: record.imageUrl,
                    description: record.description.trim(),
                    suggestedPairings: record.suggestedPairings,
                    taxRate:
                      typeof record.taxRate === "string"
                        ? parseFloat(record.taxRate)
                        : record.taxRate,
                  });
                }
              }
            } else {
              closeModal(null);
            }
          }}
        >
          &times;
        </span>

        {record && data && (
          <div>
            <div className="title">{record.nameExternal}</div>
            <div className="details">
              <div className="detail-row">
                <div>External Name</div>
                {!viewOnly && editText === "nameExternal" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        nameExternal: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className={viewOnly ? "view-only" : "active"}
                    onClick={() => {
                      setTempText(record.nameExternal);
                      setEditText("nameExternal");
                    }}
                  >
                    {record.nameExternal}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Internal Name</div>
                {!viewOnly && editText === "nameInternal" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        nameInternal: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className={viewOnly ? "view-only" : "active"}
                    onClick={() => {
                      setTempText(record.nameInternal);
                      setEditText("nameInternal");
                    }}
                  >
                    {record.nameInternal}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Available</div>
                <div
                  className={
                    viewOnly ? "view-only no-padding" : "active no-padding"
                  }
                  onClick={() => !viewOnly && setEditText("enabled")}
                >
                  {getUi("enabled", record.enabled)}
                </div>
              </div>
              <div className="detail-row">
                <div>Price</div>
                {!viewOnly && editText === "price" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        [editText]: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className={viewOnly ? "view-only" : "active"}
                    onClick={() => {
                      setTempText(record.price.toString());
                      setEditText("price");
                    }}
                    style={{
                      backgroundColor: errors.includes("price")
                        ? "rgba(255, 119, 119, 0.2)"
                        : "#ffffff",
                    }}
                  >
                    ${record.price?.toFixed(2)}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Product Type</div>
                <div
                  className={
                    viewOnly ? "view-only no-padding" : "active no-padding"
                  }
                  onClick={() => !viewOnly && setEditText("type")}
                >
                  {viewOnly
                    ? getUi("type", record.productType)
                    : getUi("type", record.type)}
                </div>
              </div>
              {/*
              <div className="detail-row">
                <div>Tags</div>
                <div className={viewOnly ? "view-only" : "active"}>{data.tags.length > 0 ? getUi('tags', record.tags) : <span className="empty">Empty</span>}</div>
              </div>
              */}
              <div className="detail-row">
                <div>Modifiers</div>
                {!viewOnly && editText === "modifiers" ? (
                  <div
                    className={
                      viewOnly ? "view-only no-padding" : "active no-padding"
                    }
                  >
                    {getUi("modifiers", record.modifiers)}
                  </div>
                ) : (
                  <div
                    className={
                      viewOnly ? "view-only no-padding" : "active no-padding"
                    }
                    onClick={() => setEditText("modifiers")}
                  >
                    {record.modifiers.length > 0 ? (
                      viewOnly ? (
                        getUi("modifiers", record.modifierNames)
                      ) : (
                        getUi("modifiers", record.modifiers)
                      )
                    ) : (
                      <span className="empty" style={{ paddingLeft: "5px" }}>
                        Empty
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Image</div>
                <div className={viewOnly ? "view-only image" : "active image"}>
                  {!viewOnly && editText === "image" ? (
                    <div
                      className="imagePicker active"
                      style={{
                        zIndex: 100,
                        margin: 20,
                        width: 242,
                        height: 249,
                        overflow: "auto",
                        backgroundColor: "#fff",
                        position: "absolute",
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 14.4px rgba(0, 0, 0, 0.32)",
                        borderRadius: "2px",
                        // border: '5px solid red'
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        {newImage.length === 0 && (
                          <FileInput
                            inputProps={{ accept: ".png,.jpeg" }}
                            onInputChange={onFileInputChange}
                            large
                            buttonText="Select file"
                            text="Image"
                            className="table-file-input"
                            style={{ width: "50%" }}
                          />
                        )}
                        <img
                          className="previewImage"
                          src=""
                          alt=""
                          width="182px"
                          height="182px"
                          style={{
                            display:
                              newImage && newImage.length !== 0
                                ? "block"
                                : "none",
                            objectFit: "cover",
                            margin: "15px",
                          }}
                        />
                        <div
                          onClick={() => {
                            if (imageSrc) {
                              setRecord({
                                ...record,
                                imageUrl: imageSrc,
                              });
                            }
                            setEditText(null);
                            setNewImage("");
                          }}
                          style={{
                            marginTop: "10px",
                            /* margin-left: 15px; */
                            borderTop: "1px solid #F4F3F3",
                            paddingLeft: "15px",
                            paddingTop: "10px",
                            color: "#969696",
                          }}
                        >
                          + Add or change image
                        </div>
                        <Icon
                          icon="trash"
                          color="#A81616"
                          iconSize={15}
                          style={{
                            position: "absolute",
                            top: "25px",
                            right: "14px",
                          }}
                          onClick={() => {
                            setEditText(null);
                            setRecord({ ...record, imageUrl: null });
                            setNewImage("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => (viewOnly ? null : setEditText("image"))}
                    >
                      {record.imageUrl || record.image ? (
                        <img
                          src={record.imageUrl || record.image}
                          alt="Broken Image"
                          width="182px"
                          height="182px"
                          style={{
                            display: "block",
                            objectFit: "cover",
                            margin: "15px",
                          }}
                        />
                      ) : (
                        <span className="empty">Empty</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="detail-row">
                <div>Product Description</div>
                {!viewOnly && editText === "description" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        description: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className={viewOnly ? "view-only" : "active"}
                    onClick={() => {
                      setTempText(record.description);
                      setEditText("description");
                    }}
                  >
                    {record.description &&
                    record.description.trim().length > 0 ? (
                      <EditableText value={record.description} />
                    ) : (
                      <span className="empty" style={{ paddingLeft: "5px" }}>
                        Empty
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="details">
              <div className="detail-row">
                <div>Auto-Suggested Pairings</div>
                <div className={viewOnly ? "view-only" : "active"}>
                  {record.autoSuggestedPairings.length > 0 ? (
                    getUi("products", record.autoSuggestedPairings)
                  ) : (
                    <span className="empty" style={{ paddingLeft: "5px" }}>
                      Empty
                    </span>
                  )}
                </div>
              </div>
              <div className="detail-row">
                <div>Manual Suggested Pairings</div>
                {!viewOnly && editText === "suggestedPairings" ? (
                  <div
                    className={
                      viewOnly ? "view-only no-padding" : "active no-padding"
                    }
                  >
                    {getUi("products", record.suggestedPairings)}
                  </div>
                ) : (
                  <div
                    className={
                      viewOnly ? "view-only no-padding" : "active no-padding"
                    }
                    onClick={() => setEditText("suggestedPairings")}
                  >
                    {record.suggestedPairings.length > 0 ? (
                      getUi("products", record.suggestedPairings)
                    ) : (
                      <span className="empty" style={{ paddingLeft: "5px" }}>
                        Empty
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Sales Tax Rate</div>
                {!viewOnly && editText === "taxRate" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        taxRate: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className={viewOnly ? "view-only" : "active"}
                    onClick={() => {
                      setTempText(
                        record.taxRate ? record.taxRate.toString() : ""
                      );
                      setEditText("taxRate");
                    }}
                    style={{
                      backgroundColor: errors.includes("taxRate")
                        ? "rgba(255, 119, 119, 0.2)"
                        : "#ffffff",
                    }}
                  >
                    {record.taxRate ? (
                      record.taxRate + "%"
                    ) : (
                      <span className="empty">Empty</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProductsModal;
