import UIComponents from "@tangopay/tango-ui-library";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DataPoint,
  RenderInstruction,
} from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";

import DocxIcon from "assets/clouds/docx.svg";
import FolderIcon from "assets/clouds/folder.svg";
import JpegIcon from "assets/clouds/jpeg.svg";
import PdfIcon from "assets/clouds/pdf.svg";
import PptIcon from "assets/clouds/ppt.svg";
import SvgIcon from "assets/clouds/svg.svg";
import XlxsIcon from "assets/clouds/xlxs.svg";

const { Icon, Button, NumberTag, TextField } = UIComponents;

type FileTypes = "folder" | "docx" | "jpg" | "pdf" | "ppt" | "svg" | "xlxs";

export type FilesProps = {
  uniqueId: string;
  fileName: string;
  lastModified: Date;
  size: string;
  star: boolean;
  kind: FileTypes;
  sharing: string;
  parentId: string | null;
  children: FilesProps[];
  [key: string]: any;
};
export type ActionTypes =
  | "move-to"
  | "share"
  | "open-file"
  | "download"
  | "duplicate"
  | "delete";
const getIcon = (type: FileTypes) => {
  let icon = "";

  if (type === "folder") {
    icon = FolderIcon;
  } else if (type === "docx") {
    icon = DocxIcon;
  } else if (type === "jpg") {
    icon = JpegIcon;
  } else if (type === "pdf") {
    icon = PdfIcon;
  } else if (type === "ppt") {
    icon = PptIcon;
  } else if (type === "svg") {
    icon = SvgIcon;
  } else if (type === "xlxs") {
    icon = XlxsIcon;
  }
  return <img src={icon} />;
};
const filesData: FilesProps[] = [
  {
    uniqueId: "1",
    fileName: "1st Folder",
    lastModified: new Date(),
    size: "2 MB",
    star: true,
    kind: "folder",
    sharing: "View Memembers",
    parentId: null,
    children: [],
  },
  {
    uniqueId: "2",
    fileName: "2nd Folder",
    lastModified: new Date(),
    size: "2 MB",
    star: true,
    kind: "folder",
    sharing: "View Memembers",
    parentId: "1",
    children: [],
  },
  {
    uniqueId: "3",
    fileName: "3 Folder",
    lastModified: new Date(),
    size: "2 MB",
    star: true,
    kind: "folder",
    sharing: "View Memembers",
    parentId: "2",
    children: [],
  },

  {
    uniqueId: "4",
    fileName: "October Proposal",
    lastModified: new Date(),
    size: "2 MB",
    star: false,
    kind: "pdf",
    sharing: "View Memembers",
    parentId: "3",
    children: [],
  },
];

const converToTree = (list: FilesProps[]) => {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    //@ts-ignore
    map[list[i].uniqueId] = i; // initialize the map
    list[i].childrenIds = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== null) {
      // if you have dangling branches check that map[node.parentId] exists
      //@ts-ignore
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

const columns: ColumnInstruction<FilesProps>[] = [
  { type: "projection", header: "Name", attribute: "fileName" },
  { type: "projection", header: "Last Modified", attribute: "lastModified" },
  { type: "data", header: "Size", attribute: "size" },
  { type: "data", header: "Star", attribute: "star" },
  { type: "data", header: "Kind", attribute: "kind" },
  { type: "data", header: "Sharing", attribute: "sharing" },
];

const instructions: {
  [x: string]: RenderInstruction<FilesProps, ActionTypes>;
} = {};

export const DocumentRow = ({ document }: { document: FilesProps }) => {
  const [editMode, setEditMode] = useState(false)
  const [nameValue, setNameValue] = useState(document.fileName ?? "");
  return (
    <div>
      {getIcon(document.kind)}
      {editMode ? (
        <TextField
          sizeType="small"
          type='text'
          value={nameValue}
          //@ts-ignore
          onChange={e => setNameValue(e.target.value)}
        />
      ) : (
        <span onClick={() => setEditMode(!editMode)} className="ml-4">{document.fileName}</span>
      )}
    </div>
  );
};
instructions.fileName = {
  type: "complex-custom",
  viewComponent: (props) => {
    const { fullObject } = props;
    return <DocumentRow document={fullObject} />;
  },
  editComponent: () => null,
};

instructions.lastModified = {
  type: "complex-custom",
  viewComponent: (props) => {
    const { fullObject } = props;
    return (
      <div>
        <span>{moment(fullObject.lastModified).format("MM / DD / YYYY")}</span>
        <span className="font-lato-regular text-xs text-grey-2 ml-2.5">
          John Smith
        </span>
      </div>
    );
  },
  editComponent: null,
};

instructions.star = {
  type: "boolean",
  useStar: true,
};

instructions.sharing = {
  type: "complex-custom",
  viewComponent: (props) => {
    return (
      <div style={{ width: "fit-content" }} className="flex">
        <Button type="btn-style-1" size="btn-small" label="View members" />
        <Icon name="more" className="ml-11 cursor-pointer" />
      </div>
    );
  },
  editComponent: null,
  actionPopup: ({ fullObject, onSelect }) => {
    return (
      <div className="absolute w-72 shadow-card z-10 bg-white p-2 rounded-lg top-0">
        <div className="flex items-center justify-between">
          <Button
            label="Open File"
            onClick={() =>
              onSelect ? onSelect(fullObject, "open-file") : null
            }
            rightIcon="open-link"
            size="btn-small"
            type="btn-style-2"
          />
          <Icon name="cross" size="15" className="cursor-pointer" />
        </div>
        <div>
          <ul className="list-none p-0 ml-3 mb-0 mt-2 border-b border-grey-1 border-solid border-t-0 border-l-0 border-r-0">
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Name</div>
              <div className="w-8/12 font-lato-regular">
                important_document.docx
              </div>
            </li>
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Kind</div>
              <div className="w-8/12 font-lato-regular">Word Document</div>
            </li>
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Location</div>
              <div className="w-8/12 font-lato-regular">
                <img src={FolderIcon} className="w-4 mr-2" />
                Another Folder
              </div>
            </li>
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Owner</div>
              <div className="w-8/12 font-lato-regular flex items-center">
                <NumberTag
                  text="js"
                  color="blue"
                  type="small"
                  className="mr-2"
                />
                John Smith
              </div>
            </li>
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Modified</div>
              <div className="w-8/12 font-lato-regular flex items-center">
                <span>10 / 07 / 2022</span>
                <span className="text-grey-2 ml-2">Billy Joel</span>
              </div>
            </li>
            <li className="text-black text-xs flex items-center py-4">
              <div className="w-4/12  font-lato-black">Created</div>
              <div className="w-8/12 font-lato-regular flex items-center">
                <span>10 / 07 / 2022</span>
                <span className="text-grey-2 ml-2">Billy Joel</span>
              </div>
            </li>
          </ul>
          <ul className="list-none p-0 ml-3 mb-0 mt-2 border-b border-grey-1 border-solid border-t-0 border-l-0 border-r-0">
            <li
              className="text-black text-xs flex items-center py-4 cursor-pointer"
              onClick={() =>
                onSelect ? onSelect(fullObject, "move-to") : null
              }
            >
              <div className="font-lato-black">Move to...</div>
            </li>
            <li
              className="text-black text-xs flex items-center py-4 cursor-pointer"
              onClick={() => (onSelect ? onSelect(fullObject, "share") : null)}
            >
              <div className="font-lato-black">Share...</div>
            </li>
            <li className="text-black text-xs flex items-center py-4 cursor-pointer">
              <div className="font-lato-black">Download</div>
            </li>
          </ul>
          <ul className="list-none p-0 ml-3 mb-0 mt-2">
            <li className="text-black text-xs flex items-center py-4 cursor-pointer">
              <div className="font-lato-black">Duplicate</div>
            </li>
            <li className="text-error-red text-xs flex items-center py-4 cursor-pointer">
              <div className="font-lato-black">Delete</div>
            </li>
          </ul>
        </div>
      </div>
    );
  },
};
const useFiles = () => {
  const [isEditing, setEditing] = useState(false);
  const [data, setData] = useState<FilesProps[]>(filesData);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState<FilesProps[]>([]);

  const noOp = () => Promise.resolve(true);

  const updateData = (uniqueId: string, attr: string, value: DataPoint) => {
    const newData = [...data];
    const index = parseInt(uniqueId) - 1;
    newData[index][attr] = value;
    setData(newData);
  };

  const treeData = useMemo(() => {
    console.log(selectedFolders);
    if (selectedFolders.length === 0) {
      return converToTree(data);
    } else {
      return data.filter(
        (item) =>
          item.parentId === selectedFolders[selectedFolders.length - 1].uniqueId
      );
    }
  }, [selectedFolders]);

  const goBack = useCallback(
    (folderIndex: number) => {
      const startIndex = folderIndex + 1;
      const endIndex = selectedFolders.length - (folderIndex + 1);
      console.log(startIndex);
      console.log(endIndex);
      const remainingFolders = [...selectedFolders];
      remainingFolders.splice(startIndex, endIndex);
      setSelectedFolders(remainingFolders);
    },
    [selectedFolders]
  );
  return {
    data,
    noOp,
    columns,
    instructions,
    isEditing,
    setEditing,
    updateData,
    showShareModal,
    setShowShareModal,
    showMoveModal,
    setShowMoveModal,
    treeData,
    setSelectedFolders,
    selectedFolders,
    goBack,
  };
};
export default useFiles;
