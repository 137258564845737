import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment-timezone";
import React from "react";

import { IDailySalesReport } from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  yellowDataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
    margin: 0,
    backgroundColor: "#FFDB58",
    borderColor: "#FFDB58",
  },
  greenDataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#B3D9A5",
    borderColor: "#B3D9A5",
  },
  greyDataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#b4b3b3",
    borderColor: "#b4b3b3",
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface ProductMixReportProps {
  report: IDailySalesReport;
  businessName: string;
  numberOfBusinesses: number;
  businesses: TangoBusiness[];
  isMediaReport: boolean;
}

interface IMultipleValueCell {
  left: string;
  right: string;
}

const MultipleValueCell = ({ left, right }: IMultipleValueCell) => {
  return (
    <View
      fixed
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <Text>{left}</Text>
      <Text>{right}</Text>
    </View>
  );
};

const DailySalesReportDocument = (props: ProductMixReportProps) => {
  const report = props.report;

  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Daily Sales Report for {props.businessName} between{" "}
            {moment(report.date).format("DD/MM/YYYY")} to{" "}
            {moment(report.date).add(6, "days").format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
        </View>

        {Array.from({ length: props.numberOfBusinesses }).map((p, i) => {
          return (
            <>
              <Text
                style={{
                  fontFamily: "SFProText-SFProTextBold",
                  color: "#454545",
                  fontSize: 14,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {props.businesses.find(
                  (business) => report.data[i].businessId === business.id
                )?.businessName || "Business"}
              </Text>
              <Table style={{ marginBottom: 5 }}>
                <TableHeader
                  style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                >
                  {/* All the keys */}
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  {/* All the dates */}
                  {Array.from(Array(7).keys()).map((day) => {
                    return (
                      <TableCell
                        textAlign="center"
                        style={styles.headerCellStyle}
                        key={day}
                      >
                        {moment(report.date)
                          .add(day, "days")
                          .format("MMM D, YYYY")}
                      </TableCell>
                    );
                  })}
                  {/* Total */}
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    Total
                  </TableCell>
                </TableHeader>
              </Table>

              {/* Sales by product type */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.salesByProductType}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          SALES
                        </TableCell>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        ></TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            _.toLower(p.name).replace(/\w+/g, _.capitalize)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[1]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[1])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[2]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[2])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[3]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[3])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[4]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[4])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[5]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[5])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[6]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[6])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[7]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[7])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[8]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={`${Math.round(p?.totalPercent[8])
                                .toFixed(2)
                                .toLocaleString()}%`}
                            />
                          )}
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Total sales by product type */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.salesByProductTypeTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[1]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[1] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[2]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[2] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[3]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[3] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[4]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[4] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[5]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[5] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[6]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[6] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[7]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[7] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => (
                            <MultipleValueCell
                              left={`$${(Math.round(p.total[8]) / 100)
                                .toFixed(2)
                                .toLocaleString()}`}
                              right={p.total[8] ? "100.00%" : "0.00%"}
                            />
                          )}
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Total meals */}
              {[report.data[i]].map((row, index) => {
                return (
                  <Table
                    data={row.totalMeals}
                    key={index}
                    style={{
                      marginTop: index === 0 ? 0 : 10,
                      marginBottom: index === 0 ? 0 : 10,
                    }}
                  >
                    <TableBody>
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.name}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[1]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[2]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[3]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[4]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[5]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[6]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[7]}
                      />
                      <DataTableCell
                        style={styles.yellowDataCellStyle}
                        getContent={(p) => p.total[8]}
                      />
                    </TableBody>
                  </Table>
                );
              })}

              {/* Taxes */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  console.log("Row taxes: ", row.taxes);
                  return (
                    <Table
                      data={row.taxes}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 20 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          TAXES
                        </TableCell>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        ></TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Total Taxes */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.taxesTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Charges */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.feesAndCharges}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Totals */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.salesTotals}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Payments */}
              {[report.data[i]].map((row, index) => {
                return (
                  <Table
                    data={
                      props.isMediaReport
                        ? [...row.payments, ...row.cashPayments]
                        : row.payments
                    }
                    key={index}
                    style={{
                      marginTop: index === 0 ? 30 : 10,
                      marginBottom: index === 0 ? 0 : 10,
                    }}
                  >
                    <TableHeader>
                      <TableCell
                        textAlign="center"
                        style={styles.headerCellStyle}
                      >
                        PAYMENTS
                      </TableCell>
                      <TableCell
                        textAlign="center"
                        style={styles.headerCellStyle}
                      ></TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          _.toLower(p.name).replace(/\w+/g, _.capitalize)
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[1]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[2]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[3]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[4]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[5]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[6]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[7]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[8]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                    </TableBody>
                  </Table>
                );
              })}

              {/* Payments total */}
              {[report.data[i]].map((row, index) => {
                return (
                  <Table
                    data={row.paymentsSubTotal}
                    key={index}
                    style={{
                      marginTop: index === 0 ? 0 : 10,
                      marginBottom: index === 0 ? 0 : 10,
                    }}
                  >
                    <TableBody>
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) => p.name}
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[1]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[2]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[3]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[4]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[5]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[6]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[7]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                      <DataTableCell
                        style={styles.greenDataCellStyle}
                        getContent={(p) =>
                          `$${(Math.round(p.total[8]) / 100)
                            .toFixed(2)
                            .toLocaleString() || "0"
                          }`
                        }
                      />
                    </TableBody>
                  </Table>
                );
              })}

              {/* Gift cards */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.giftCardsByCard}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 30 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          GIFT CARDS
                        </TableCell>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        ></TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            _.toLower(p.name).replace(/\w+/g, _.capitalize)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Gift cards */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.giftCardsByCash}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            _.toLower(p.name).replace(/\w+/g, _.capitalize)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Gift cards total */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.giftCardsTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Payouts */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.payoutsTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 30 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          PAID OUT
                        </TableCell>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        ></TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            _.toLower(p.name).replace(/\w+/g, _.capitalize)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Payouts total */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.payoutsTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Total payments */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.paymentsWithGiftCardsAndPayoutsSubTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Discounts */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.discounts}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 30 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableHeader>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          DISCOUNTS
                        </TableCell>
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        ></TableCell>
                      </TableHeader>
                      <TableBody>
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={
                            (p) => p.name // _.toLower().replace(/\w+/g, _.capitalize)
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Discounts total */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.discountsTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.yellowDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Total receipts */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.finalTotal}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 30 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greenDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}

              {/* Net deposit */}
              {!props.isMediaReport
                ? [report.data[i]].map((row, index) => {
                  return (
                    <Table
                      data={row.netDeposit}
                      key={index}
                      style={{
                        marginTop: index === 0 ? 0 : 10,
                        marginBottom: index === 0 ? 0 : 10,
                      }}
                    >
                      <TableBody>
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) => p.name}
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[1]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[2]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[3]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[4]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[5]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[6]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[7]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                        <DataTableCell
                          style={styles.greyDataCellStyle}
                          getContent={(p) =>
                            `$${(Math.round(p.total[8]) / 100)
                              .toFixed(2)
                              .toLocaleString() || "0"
                            }`
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                })
                : null}
            </>
          );
        })}
      </Page>
    </Document>
  );
};

export default DailySalesReportDocument;
