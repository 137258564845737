import { Button, Spinner } from "@blueprintjs/core";
import { pdf } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isElectron } from "utils";

import {
  LaborReportForADay,
  formatCentValue,
  getLaborReportForADateRange,
  roundNumberToTwoDecimals,
} from "controllers/reporting";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../../Layout";
import LaborReportModal from "../LaborReportModal";
import DailyLaborPdfDocument, {
  GroupedLaborReportByDepartment,
  TotalsForReport,
} from "../PdfReports/DailyLaborPdfDocument";
import "../style.css";

const DailyLaborReport = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dailyReports, setDailyReports] = useState<LaborReportForADay[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportIdLoading, setReportIdLoading] = useState<string | null>(null);

  const [earliestFetchedDate, setEarliestFetchedDate] = useState<Date | null>(
    null
  );
  const [latestResponseLength, setLatestResponseLength] = useState<
    null | number
  >(null);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const fetchMoreData = useCallback(async () => {
    setLoading(true);
    if (business && earliestFetchedDate) {
      const oldEarliestDate = earliestFetchedDate;
      const newEarliestDate = moment(oldEarliestDate)
        .subtract("days", 30)
        .toDate();
      const laborReports = await getLaborReportForADateRange(
        business.id,
        newEarliestDate,
        oldEarliestDate
      );
      setEarliestFetchedDate(newEarliestDate);
      setLatestResponseLength(laborReports.length);
      setDailyReports(
        [...dailyReports, ...laborReports].sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        )
      );
    }
    setLoading(false);
  }, [dailyReports, earliestFetchedDate, business?.id]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (business) {
        setLoading(true);
        const earliestDate = moment().subtract(30, "days").toDate();
        const laborReports = await getLaborReportForADateRange(
          business.id,
          earliestDate,
          moment().add(10, "days").toDate()
        );
        console.log("laborReports", laborReports);
        setEarliestFetchedDate(earliestDate);
        setLatestResponseLength(laborReports.length);
        setDailyReports(
          laborReports.sort(
            (a, b) => moment(b.date).unix() - moment(a.date).unix()
          )
        );
        setLoading(false);
      }
    };
    fetchInitialData();
  }, [business?.id]);

  const createBlob = async (report: LaborReportForADay, snapshot = false) => {
    try {
      const groupedData = _.groupBy(report.data, "departmentName");
      const groupedReport: GroupedLaborReportByDepartment = {};
      _.keys(groupedData).forEach((departmentName: string) => {
        const dataForDepartment = groupedData[departmentName];
        const totalLaborForDepartment = dataForDepartment.reduce(
          (accumulator, currentRow) => {
            return accumulator + currentRow.totalLabor;
          },
          0
        );
        const totalHoursForDepartment = dataForDepartment.reduce(
          (accumulator, currentRow) => {
            return accumulator + currentRow.totalHours;
          },
          0
        );
        const totalPercentageOfSalesForDepartment = dataForDepartment.reduce(
          (accumulator, currentRow) => {
            return accumulator + currentRow.percentageOfSales;
          },
          0
        );
        groupedReport[departmentName] = {
          data: dataForDepartment,
          totalLabor: roundNumberToTwoDecimals(totalLaborForDepartment),
          totalHours: roundNumberToTwoDecimals(totalHoursForDepartment),
          totalSales: roundNumberToTwoDecimals(
            totalPercentageOfSalesForDepartment
          ),
        };
      });
      const totalsForReport: TotalsForReport = {
        totalHours: roundNumberToTwoDecimals(
          report.data.reduce((accumulator, currentRow) => {
            return accumulator + currentRow.totalHours;
          }, 0)
        ),
        totalLabor: roundNumberToTwoDecimals(
          report.data.reduce((accumulator, currentRow) => {
            return accumulator + currentRow.totalLabor;
          }, 0)
        ),
        totalSales: roundNumberToTwoDecimals(
          report.data.reduce((accumulator, currentRow) => {
            return accumulator + currentRow.percentageOfSales;
          }, 0)
        ),
      };
      console.log("groupedReport: ", groupedReport);
      const blob = await pdf(
        <DailyLaborPdfDocument
          groupedData={groupedReport}
          totalsForReport={totalsForReport}
          totalSales={formatCentValue(report.totalSales)}
          reportDate={report.date}
          businessName={business.businessName}
          snapshot={snapshot}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      setReportIdLoading(null);
      window.open(url, "_blank");
    } catch (e) {
      setReportIdLoading(null);
      alert("Opps, something went wrong generating your report");
      console.log("error generating report", e);
    }
  };

  const hasMoreDocumentsToLoad = _.isNull(latestResponseLength)
    ? true
    : Boolean(latestResponseLength);
  return (
    <div className={"w-full"}>
      <div className="page-header">
        <div className="page-title">Daily Labor Reports</div>
      </div>
      <Box display="flex" flexDirection="column">
        {loading ? (
          <div style={{ marginTop: 20 }}>
            <Spinner size={40} />
            <div
              className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              This may take a couple minutes to load.
            </div>
          </div>
        ) : (
          <>
            {_.uniqBy(dailyReports, "id").map((report) => {
              const isLoadingBlob = reportIdLoading === report.id;
              const renderButton = () => {
                const lastReportStartDay = moment(report.date);
                const lastReportEndDay = lastReportStartDay
                  .clone()
                  .add(1, "day");
                if (
                  moment().isAfter(lastReportStartDay) &&
                  moment().isBefore(lastReportEndDay)
                ) {
                  return (
                    <div
                      className="report-table-text"
                      onClick={() => {
                        setReportIdLoading(report.id);

                        createBlob(report, true);
                        // fetchAndOpenReport()
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View Snapshot
                    </div>
                  );
                }
                if (isLoadingBlob) {
                  return <Spinner size={20} />;
                }
                return (
                  <div
                    className="report-table-text"
                    onClick={() => {
                      setReportIdLoading(report.id);

                      createBlob(report);
                      // fetchAndOpenReport()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    View Report
                  </div>
                );
              };
              return (
                <Box
                  key={report.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: 5, marginTop: 10 }}
                >
                  <div className="report-table-text">
                    {moment(report.date).format("MMMM D, YYYY")}
                  </div>
                  {renderButton()}
                </Box>
              );
            })}

            {hasMoreDocumentsToLoad && (
              <div className="center-button">
                <Button
                  text="Load more"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await fetchMoreData();
                    setLoading(false);
                  }}
                  className="load-more-button" // create-new-teammate
                  fill
                />
              </div>
            )}
          </>
        )}
      </Box>
    </div>
    // <LaborReportModal
    //   isOpen={showModal}
    //   closeModal={() => setShowModal(false)}
    // />
  );
};
export default DailyLaborReport;
