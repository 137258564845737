import { motion } from "framer-motion";
import React from "react";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 50,
};
export type NavSliderItem = {
  index: number;
  id: string;
  title: string;
};
interface NavSliderProps {
  currentIndex: number;
  items: NavSliderItem[];
  onItemPress: (itemId: string) => void;
}

const NavSlider = (props: NavSliderProps) => {
  const itemWidthInPercent = ((1 / props.items.length) * 100).toFixed(2);
  const itemClassName =
    "pointer text-md font-lato-bold text-grey-3 px-5 py-1 flex items-center justify-center";

  return (
    <div className="rounded-full py-2 h-15 flex flex-row bg-blue-grey-2 border-radius-2 mr-5 relative">
      {props.items.map((item) => {
        return (
          <motion.div
            key={item.id}
            className={`${itemClassName}`}
            style={{ zIndex: 2, width: `${itemWidthInPercent}%` }}
            onClick={() => props.onItemPress(item.id)}
          >
            {item.title}
          </motion.div>
        );
      })}

      <motion.div
        animate={{
          transform: `translateX(${props.currentIndex * 100}%)`,
        }}
        layout
        transition={spring}
        className={`pointer bg-white rounded-full shadow-general-shadow absolute`}
        style={{ zIndex: 1, height: "62%", width: `${itemWidthInPercent}%` }}
      />
    </div>
  );
};

export default NavSlider;
