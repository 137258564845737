import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { firstBy } from "thenby";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";
import { productTypes } from "views/menuBuilder/menuConstants";

import { findProductNamesFromProductIds } from "models/products";

import { FirebaseModifierDoc } from "types/modifiers";

import { RootState } from "model/store";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import { performSoftDelete, saveData } from "../../../../models/menus";
import { DocumentData } from "../../../../types/document";
import {
  FirebaseMenuDoc,
  MenuAndCategoriesData,
  MenuCategoryDoc,
} from "../../../../types/menus";
import { FirebaseProductDoc } from "../../../../types/products";
import { saveStitchedProductsToFirebase } from "../SaveStitchedProducts";
import ProductsModal from "../products/ProductModal";

interface Props {
  businessId: string;
  menu: FirebaseMenuDoc | null;
  products: FirebaseProductDoc[];
  modifiers: FirebaseModifierDoc[];
  allMenuAndCategoriesData: MenuAndCategoriesData[];
  discounts: DocumentData[];
  customComponent: any;
  goBack?: () => void;
}

const MenusTable = ({
  businessId,
  menu,
  products,
  modifiers,
  allMenuAndCategoriesData,
  discounts,
  customComponent,
  goBack,
}: Props) => {
  const [data, setData] = useState<MenuCategoryDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<MenuCategoryDoc[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [savingToFirebaseLoading, setSavingToFirebaseLoading] = useState(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [allowMenuUpdates, setAllowMenuUpdates] = useState<boolean>(false)

  const productNames = products.map((product) => product.nameExternal);

  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings);
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const toPrice = (text: number) => {
    if (typeof text === "number") return text.toFixed(2);
    return text;
  };

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isPrimaryId: true,
      isDropdown: false,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isPrimaryId: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Available", "Not Available"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "nameExternal",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isPrimaryId: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: products
          .map((product) => product.nameExternal)
          .map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "price",
      isEditable: false,
      isImage: false,
      isCustomText: true,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: {
        prefix: "$ ",
        suffix: null,
        customFunction: toPrice.toString(),
      },
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "scheduledDiscounts",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    // The below options are for the read-only products modal
    {
      key: "type",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: productTypes.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "modifiers",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: modifiers.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "products",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: products.map((type) => ({
          optionName: type.nameInternal,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "nameExternal",
    errors: [],
  });

  const columnHeadingConversions = {
    menuCategory: "Menu Category",
    nameExternal: "Item Name",
    enabled: "Available",
    price: "Base Price",
    scheduledDiscounts: "Scheduled Discounts",
  };

  const tableSchema = [
    { key: "menuCategory", type: "value", data: "text" },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "price", type: "value", data: "number" },
    { key: "scheduledDiscounts", type: "arrayOfValue", data: "text" },
  ];

  const dataSchema = [
    { key: "menuId", type: "value", data: "text" },
    { key: "id", type: "value", data: "text" },

    { key: "menuCategory", type: "value", data: "text" },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "price", type: "value", data: "number" },
    { key: "scheduledDiscounts", type: "arrayOfValue", data: "text" },

    { key: "nameInternal", type: "value", data: "text" },
    { key: "productType", type: "value", data: "text" },
    { key: "tags", type: "arrayOfValue", data: "text" },
    { key: "modifiers", type: "arrayOfValue", data: "text" },
    { key: "discounts", type: "arrayOfValue", data: "text" },
    { key: "image", type: "value", data: "text" },
    { key: "description", type: "value", data: "text" },
    { key: "suggestedPairings", type: "arrayOfValue", data: "text" },
    { key: "taxRate", type: "value", data: "number" },
    { key: "ingredients", type: "arrayOfValue", data: "text" },
  ];

  // TODO: Fix this saveToFirebase function
  const saveToFirebase = async () => {
    if (menu) {
      const currentMenuIndex = allMenuAndCategoriesData.findIndex(
        (existingMenu) => existingMenu.menuId === menu.id
      );

      if (currentMenuIndex !== -1) {
        const currentMenu = allMenuAndCategoriesData[currentMenuIndex];

        const categoriesRef = currentMenu.data.reduce(
          (acc: { [T: string]: string }, val: any) => ({
            ...acc,
            [val.name]: val.id,
          }),
          {}
        );

        const allCategoriesInData = data.map((category) =>
          category.menuCategory.trim()
        );
        const categoriesToDelete = Object.keys(categoriesRef).filter(
          (categoryName) => !allCategoriesInData.includes(categoryName)
        );
        const categoryIdsToDelete = categoriesToDelete.map(
          (category) => categoriesRef[category]
        );

        await performSoftDelete(categoryIdsToDelete);

        const scheduledDiscounts = discounts.filter(
          (discount) => discount.discountType === "Scheduled"
        );
        const finalData = data
          .map((category) => ({
            ...category,
            menuCategory: category.menuCategory.trim(),
          }))
          .map((category) => {
            if (category.id) {
              return category;
            } else {
              const existingCategoryId: string =
                categoriesRef[category.menuCategory];
              if (existingCategoryId) {
                return {
                  ...category,
                  menuId: menu.id,
                  id: existingCategoryId,
                  enterpriseUpdatedDocument: !!isEnterpriseLevel,
                };
              } else {
                return {
                  ...category,
                  menuId: menu.id,
                  id: "",
                  enterpriseUpdatedDocument: !!isEnterpriseLevel,
                };
              }
            }
          });

        const currentMenuCategoryRef = allMenuAndCategoriesData[
          currentMenuIndex
        ].data.reduce(
          (acc: { [T: string]: string }, val: FirebaseMenuCategoryDoc) => {
            return { ...acc, [val.name]: val.id };
          },
          {}
        );

        await saveData(
          finalData,
          getProductsDataByCategory(
            allMenuAndCategoriesData[currentMenuIndex].data,
            products,
            scheduledDiscounts
          ),
          businessId,
          products,
          menu.id,
          isEnterpriseLevel,
          currentMenuCategoryRef
        );

        // TODO: Optimize the save stitched products function
        setSavingToFirebaseLoading(true);
        if (!isEnterpriseLevel) {
          await saveStitchedProductsToFirebase(businessId);
        }
      }
    }

    return true;
  };

  const filterData = (data: DocumentData[]) =>
    data.map((row) => ({
      menuCategory: row.menuCategory.trim(),
      nameExternal: row.nameExternal,
      enabled: row.enabled,
      price: row.price,
      scheduledDiscounts: row.scheduledDiscounts,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      menuCategory: row.menuCategory.trim(),
      nameExternal: row.nameExternal,
      index,
    }));

    // Filter for duplicates
    const menuCategoryDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({
            menuCategory: row.menuCategory.trim(),
            nameExternal: row.nameExternal,
          })),
          {
            menuCategory: item.menuCategory.trim(),
            nameExternal: item.nameExternal,
          }
        ) !== index
    );

    // Find the duplicate without the id
    const errors: DocumentData[] = [];
    for (let i = 0; i < menuCategoryDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) =>
          row.menuCategory.trim() ===
          menuCategoryDuplicateErrors[i].menuCategory.trim() &&
          row.nameExternal === menuCategoryDuplicateErrors[i].nameExternal
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong price
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => isNaN(row.price))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "price", isEdited: false }],
          isEdited: false,
        })),
      // Wrong availability
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !(row.enabled === "Available" || row.enabled === "Not Available")
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong products
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !productNames.includes(row.nameExternal))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameExternal", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No menu category
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.menuCategory || row.menuCategory.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "menuCategory", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const getProductsDataByCategory = (
    categoryData: DocumentData[],
    products: FirebaseProductDoc[],
    scheduledDiscounts: DocumentData[]
  ): MenuCategoryDoc[] => {
    return [].concat.apply(
      [],
      // @ts-ignore
      products.map((product) => {
        const productCategoryInfo = categoryData.filter((row) =>
          row.products.includes(product.id)
        );
        return productCategoryInfo.map((category) => {
          const viableDiscounts = scheduledDiscounts.filter((discount) =>
            discount.scheduledDiscount.productsToDiscount.includes(product.id)
          );
          return {
            // Meta
            menuId: menu ? menu.id : "",
            id: category.id,

            // Table info
            menuCategory: category.name.trim(),
            nameExternal: product.nameExternal,
            enabled: product.enabled ? "Available" : "Not Available",
            price: product.price / 100,
            scheduledDiscounts: viableDiscounts.map(
              (discount) => discount.nameExternal
            ),

            // Modal extra
            nameInternal: product.nameInternal,
            productType: product.type,
            tags: product.tags,
            modifiers: product.modifiers,
            discounts: [],
            image: product.imageUrl || "",
            description: product.description || "",
            suggestedPairings:
              findProductNamesFromProductIds(
                product.suggestedPairings,
                products
              ) || [],
            autoSuggestedPairings:
              findProductNamesFromProductIds(
                product.autoSuggestedPairings,
                products
              ) || [],
            taxRate: product.taxRate,
            ingredients: product.ingredients,
          };
        });
      })
    );
  };

  useEffect(() => {
    if (menu) {
      setLoading(true);
      const scheduledDiscounts = discounts.filter(
        (discount) => discount.discountType === "Scheduled"
      );
      const currentMenuIndex = allMenuAndCategoriesData.findIndex(
        (existingMenu) => existingMenu.menuId === menu.id
      );

      // const currentMenuAndMenuCategories = allMenuAndCategoriesData.filter((menuAndCategoriesDoc) => menuAndCategoriesDoc.menuId === menu.id);
      if (currentMenuIndex !== -1) {
        // Set the data
        const transformedData = getProductsDataByCategory(
          allMenuAndCategoriesData[currentMenuIndex].data,
          products,
          scheduledDiscounts
        ).sort(
          firstBy("menuCategory", { ignoreCase: true }).thenBy("nameExternal", {
            ignoreCase: true,
          })
        );
        setData([...transformedData]);
        setLoading(false);
        setSaved(false);
      }

      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
      setSaved(false);
    }
  }, [allMenuAndCategoriesData, menu, products, loading, saved]);

  useEffect(() => {
    if (businessSettings) {
      const allowUpdates = businessSettings.menuBuilderPermissions.allowMenuUpdates;
      setAllowMenuUpdates(!allowUpdates)
    }
  }, [businessSettings])

  if (!loading) {
    return (
      <div className="menus-table">
        <Table
          businessId={businessId}
          name={menu?.nameExternal}
          parentName="MenuBuilder"
          allData={data}
          setAllData={setData}
          softDelete={softDeleteData}
          setSoftDelete={setSoftDeleteData}
          allowSoftDelete
          filterData={filterData}
          tableSchema={tableSchema}
          dataSchema={dataSchema}
          options={options}
          setOptions={setOptions}
          hasModal
          modalColumTitle="View Product"
          ModalComponent={ProductsModal}
          errorManager={errorManager}
          setErrorManager={setErrorManager}
          closeErrorManager={() => {
            setErrorManager({
              ...errorManager,
              showErrorModal: false,
            });
          }}
          verifySave={verifyData}
          saveToFirebase={saveToFirebase}
          hasSearch
          searchColumnName="nameExternal"
          hasFilter
          columnHeadingConversions={columnHeadingConversions}
          isModalViewOnly
          additionalInfo={{ modifiers: modifiers }}
          searchPlaceHolderText={"Find an item"}
          customComponentsNextToTitle={customComponent}
          showBackArrow={true}
          goBack={goBack}
          readOnly={isEnterpriseLevel ? false : allowMenuUpdates}
        />
      </div>
    );
  }

  return null;
};

export default MenusTable;
