import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import Box from "components/Box";

import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const launchpad = useLaunchpad();

  return (
    <Box className="help-support-page">
      <Box
        className="layout_header staff-more"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/dashboard">
          {/* <img src={TangoImage} alt="tango" /> */}
        </Link>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link to="/help/tutorials" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/help/tutorials" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              How To Videos
            </Box>
          </Link>
          <Link to="/report-a-bug" className="menu-item">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/report-a-bug" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Report a Bug
            </Box>
          </Link>
          {/*
          <Link to="/employees">
            <Box
              className={`layout_menu-item ${
                location.pathname === "/employees" ? "active" : ""
              }`}
              style={{ marginLeft: "35px" }}
            >
              Employees
            </Box>
          </Link>
          */}
          <img
            src={
              require("../../assets/headers/help-videos-launch-icon.svg")
                .default
            }
            className="launch-icon"
            onClick={() => launchpad.setVisibility(true)}
          />
          <img
            src={
              require("../../assets/headers/help-videos-logo-mask.svg").default
            }
            className="launch-icon-mask"
          />
          <img
            src={require("../../assets/tango-icon.svg").default}
            className="tango-logo"
          />
        </Box>
        {/* <Box>{user?.contact?.firstName} {user?.contact?.lastName}</Box> */}
      </Box>
      <div className="children" style={{ marginTop: 20 }}>
        {children}
      </div>
    </Box>
  );
};
export default Layout;
