import { FirebaseProductDoc } from "types/products";

import ActionType from "./actionTypes";

export function receiveProducts(products: FirebaseProductDoc[]) {
  return {
    type: ActionType.RECEIVE_PRODUCTS,
    products,
  };
}
