import React from "react";
import { useParams } from "react-router-dom";

import { saveSpreadSheetData } from "models/docs";
import { exportSpreadSheetFile } from "models/exportSheet";

import { useUpdateSpreadsheet } from "./hooks/useUpdateSpreadsheet";
import "./index.css";

const Spreadsheet = () => {
  const luckyCss: any = {
    margin: "0px",
    padding: "0px",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0px",
    top: "0px",
  };

  const params = useParams<{ sheetId: string }>();

  const { getAllSheets, onFileChange, handleFileClick, hiddenFileInput } =
    useUpdateSpreadsheet({
      sheetId: params.sheetId,
    });

  const exportSheets = async () => {
    let allSheetData = await window.luckysheet.getAllSheets();
    let sheet1 = allSheetData[0];
    let downOriginData = sheet1.data;
    exportSpreadSheetFile(downOriginData, sheet1);
  };

  return (
    <>
      <div className="spreadsheetdiv">
        <div className="sheet-tool-div">
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={onFileChange}
            style={{ display: "none" }}
          />
          <div
            className="excel-button"
            onClick={() => {
              handleFileClick();
            }}
          >
            Import Excel
          </div>
          <div
            className="save-button"
            onClick={() => {
              getAllSheets();
            }}
          >
            Save
          </div>
        </div>
        <div className="export-button" onClick={() => exportSheets()}>
          Export
        </div>
        <div id="luckysheet" style={luckyCss}></div>
      </div>
    </>
  );
};

export default Spreadsheet;
