import _ from "lodash";

import { isEqual } from "utils/data";

import { CashDrawerDoc, FirebaseCashDrawerDoc } from "types/cashDrawers";
import { FirebasePrintersDoc } from "types/printers";

import firebase from "../config/firebase";

const db = firebase.firestore();
const cashDrawersCollection = db.collection("CashDrawer");

export const performSoftDelete = async (cashDrawers: CashDrawerDoc[]) => {
  for await (const cashDrawer of cashDrawers) {
    try {
      if (cashDrawer.id && cashDrawer.id.length > 0) {
        await cashDrawersCollection
          .doc(cashDrawer.id)
          .update({ deleted: true });
      }
    } catch (err) {
      console.log("Failed to update: ", cashDrawer.id);
    }
  }
};

export const findStaffMemberUsingId = (
  staffId: string,
  staffMembers: StaffMember[]
): StaffMember | null => {
  const index = staffMembers.findIndex((staff) => staff.id === staffId);
  if (index !== -1) {
    return staffMembers[index];
  }
  return null;
};

const findStaffMemberUsingName = (
  staffName: string,
  staffMembers: StaffMember[]
): StaffMember | null => {
  const splitName = staffName.split(" ");
  const index = staffMembers.findIndex(
    (staff) =>
      staff.contact.firstName === splitName[0] &&
      staff.contact.lastName === splitName[1]
  );
  if (index !== -1) {
    return staffMembers[index];
  }
  return null;
};

const findPrinterByName = (
  printerName: string,
  printers: FirebasePrintersDoc[]
): FirebasePrintersDoc | null => {
  const index = printers.findIndex((printer) => printer.name === printerName);

  if (index !== -1) {
    return printers[index];
  }

  return null;
};

export const saveData = async (
  newData: CashDrawerDoc[],
  existingData: CashDrawerDoc[],
  staffMembers: StaffMember[],
  printers: FirebasePrintersDoc[],
  businessId: string
) => {
  const firebaseCashDrawers: FirebaseCashDrawerDoc[] = newData
    .map((cashDrawer, index) => ({ ...cashDrawer, index: index }))
    .filter((cashDrawer) => {
      const cashDrawerToCheck = _.omit(cashDrawer, "index");
      const foundItem = existingData.some((existingDrawer) =>
        isEqual(existingDrawer, cashDrawerToCheck)
      );

      // Remove cash drawer documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((cashDrawer) => {
      return {
        businessId: businessId,
        cashDrawerName: cashDrawer.cashDrawerName,
        closeType: cashDrawer.closeType,
        createdAt: new Date(),
        deleted: false,
        enabled: cashDrawer.enabled === "Yes",
        id: cashDrawer.id,
        otherStaffWithAccess: cashDrawer.otherAuthorizedStaff
          .map((staff) => findStaffMemberUsingName(staff, staffMembers))
          .filter((staff) => !!staff)
          .map((staff) => staff?.id || ""),
        primaryStaff: cashDrawer.drawerOwners
          .map((staff) => findStaffMemberUsingName(staff, staffMembers))
          .filter((staff) => !!staff)
          .map((staff) => staff?.id || ""),
        // TODO: Update the timeStart and timeEnd
        startingCash: {
          amount: 0,
          timeEnd: "12:00",
          timeStart: "04:00",
        },
        printerId: findPrinterByName(cashDrawer.printer, printers)?.id || "",
        updatedAt: new Date(),
      };
    });

  for await (const cashDrawer of firebaseCashDrawers) {
    // For existing cash drawers - just find the differences to update
    if (cashDrawer.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === cashDrawer.id
      );
      if (index !== -1) {
        let existingCashDrawer: DocumentData = existingData[index];
        existingCashDrawer.otherStaffWithAccess =
          existingCashDrawer.otherAuthorizedStaff
            .map((staff: string) =>
              findStaffMemberUsingName(staff, staffMembers)
            )
            .filter((staff: StaffMember | null) => !!staff)
            .map((staff: StaffMember | null) => staff?.id || "");
        existingCashDrawer.primaryStaff = existingCashDrawer.drawerOwners
          .map((staff: string) => findStaffMemberUsingName(staff, staffMembers))
          .filter((staff: StaffMember | null) => !!staff)
          .map((staff: StaffMember | null) => staff?.id || "");
        existingCashDrawer.enabled = existingCashDrawer.enabled === "Yes";
        existingCashDrawer.printerId =
          findPrinterByName(existingCashDrawer.printer, printers)?.id || "";

        existingCashDrawer = _.omit(existingCashDrawer, "otherAuthorizedStaff");
        existingCashDrawer = _.omit(existingCashDrawer, "drawerOwners");

        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingCashDrawer
        )
          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingCashDrawer[key], cashDrawer[key]) &&
              Object.keys(cashDrawer).includes(key)
          )
          // @ts-ignore
          .reduce((acc, key) => ({ ...acc, [key]: cashDrawer[key] }), {});

        try {
          console.log("DATA TO UPDATE: ", dataToUpdate);
          await cashDrawersCollection.doc(cashDrawer.id).update(dataToUpdate);
        } catch (err) {
          console.log("Failed to update: ", cashDrawer.id);
        }
      }
    } else {
      const docRef = cashDrawersCollection.doc();
      cashDrawer["id"] = docRef.id;
      const finalDoc = _.omit(cashDrawer, "index");

      try {
        await docRef.set(finalDoc, { merge: true });
      } catch (err) {
        console.log("Failed to update: ", cashDrawer.id);
      }
    }
  }
};
