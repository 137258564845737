
import React, { useState } from "react";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import { useLocation, useNavigate } from "react-router-dom";
import Payments from "../Payments";
import OrderReciept from "../OrderReciept";
import SubmitEvidenceForm from "./Form";
import { DisputeEvidenceDTO, TangoDispute, updateDispute } from "controllers/disputes";
import { TangoTab, useStaffMember } from "controllers/payments";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { CustomerModel } from "../ClosedChecks/useClosedChecks";

const { TextField, Button, Icon, CallToActionModal } = UIComponents;


const SubmitEvidence = () => {
    const navigate = useNavigate();
    const loc = useLocation();
    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business as TangoBusiness
    );
    const dispute: TangoDispute = loc.state.dispute
    const closedCheck: TangoTab = loc.state.tab
    const country = business.currency.toLowerCase() === 'cad' || business.currency.toLowerCase() === 'ca' ? 'CA' : 'USA'

    const paymentDisputed = closedCheck.customer.filter((customer: CustomerModel, index: number) => {
        return customer.payment.paymentType.chargeId === dispute.charge
    })
    const [loading, setLoading] = useState(false)

    const fetchStaffMemberData = (staffMemberId: string) => {
        const businessId: string = useSelector(
            (state: RootState) => state.business as TangoBusiness
        )?.id;
        const info = useStaffMember(businessId, staffMemberId);
        return info?.data ?? null;
    }

    const staffMember: any = closedCheck.staffId ? fetchStaffMemberData(closedCheck.staffId) : null;

    const [disputeEvidence, setDisputeEvidence] = useState<DisputeEvidenceDTO>({
        receipt: null,
        cancellation_policy: null,
        customer_communication: null,
        customer_signature: null,
        duplicate_charge_documentation: null,
        refund_policy: null,
        service_documentation: null,
        shipping_documentation: null,
        uncategorized_file: null,
        access_activity_log: null,
        billing_address: null,
        cancellation_policy_disclosure: null,
        cancellation_rebuttal: null,
        customer_email_address: null,
        customer_name: null,
        customer_purchase_ip: null,
        duplicate_charge_explanation: null,
        duplicate_charge_id: null,
        product_description: null,
        refund_policy_disclosure: null,
        refund_refusal_explanation: null,
        service_date: null,
        shipping_address: null,
        shipping_carrier: null,
        shipping_date: null,
        shipping_tracking_number: null,
        uncategorized_text: null
    });


    const handleSaveEvidence = async () => {
        setLoading(true)
        console.log('clicked handleSaveEvidence', business.id, country, dispute.id, disputeEvidence)
        const updateDisputeSuccess = await updateDispute(business.id, country, dispute.id, disputeEvidence)
        if (updateDisputeSuccess) {
            console.log('saved evidence', updateDisputeSuccess)
            setDisputeEvidence(updateDisputeSuccess)
        }
        setLoading(false)
    }

    return (
        <div className="absolute top-[63px] bottom-0 w-full">
            <Header routes={headerRoutes} selectedRoute={"/payments/closedchecks/"} />
            <div className="flex h-full">
                <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div>

                <div style={{ width: "90%" }}>
                    {/* Header: starts */}
                    <div
                        className={
                            "flex p-8 justify-between items-center bg-white border-b border-grey-1"
                        }
                        style={{ borderBottomStyle: "solid" }}
                    >
                        <div className="flex items-center ">
                            <Icon
                                name={"arrow-left"}
                                size={"20"}
                                className={"cursor-pointer"}
                                onClick={() => navigate(-1)}
                            />

                            <div className={"text-big text-black font-lato-black ml-10"}>
                                Submit Dispute Evidence
                            </div>
                        </div>

                        <div className="flex items-center">
                            <Button
                                label="Submit Evidence"
                                size="btn-medium"
                                type="btn-style-2"
                                onClick={() => {
                                    handleSaveEvidence()
                                }}
                            />
                            {/* <Button 
                                label="Submit Evidence" 
                                size="btn-medium" 
                                type="btn-style-1" 
                                className="ml-5" 
                            /> */}
                        </div>
                    </div>
                    {/* Header: ends */}
                    {/* container starts */}
                    <div className="pt-10 pl-10 pr-16 flex justify-start">
                        {paymentDisputed &&
                            <div className="w-4/12">
                                <SubmitEvidenceForm
                                    closedCheck={closedCheck}
                                    dispute={dispute}
                                    paymentDisputed={paymentDisputed[0]}
                                    setParentData={setDisputeEvidence}
                                />
                            </div>
                        }
                        <div className="w-8/12 flex">
                            <div className="w-8/12 pl-12">

                                <OrderReciept
                                    closedCheck={closedCheck}
                                    staffMember={staffMember}
                                />

                            </div>
                            <div className="w-4/12 ml-8">
                                <div className='font-lato-black text-2xl text-black mb-5'>Dispute Details</div>

                                <div className="shadow-card py-8 px-8 rounded-2xl">
                                    <ul className="p-0 m-0 list-none">
                                        <li className="mb-8">
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Evidence Due</div>
                                            <div className=" text-sm font-lato-medium text-black">
                                                {moment.unix(dispute.evidenceDetails.dueBy).format('MMM DD, YYYY')}
                                                {/* Jul 21 (next month) */}
                                            </div>
                                        </li>
                                        <li className="mb-8">
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Amount</div>
                                            <div className=" text-sm font-lato-medium text-black">
                                                ${(dispute.amount * 0.01).toFixed(2)} {business && business.currency.toUpperCase()}
                                            </div>
                                        </li>
                                        <li className="mb-8">
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Reason</div>
                                            <div className=" text-sm font-lato-medium text-black">{dispute.reason.replaceAll('_', '')}</div>
                                        </li>
                                        <li className="mb-8">
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Payment Date</div>
                                            <div className=" text-sm font-lato-medium text-black">Jun 11</div>
                                        </li>
                                        <li className="mb-8">
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Payment Method</div>
                                            <div className=" text-sm font-lato-medium text-black">
                                                **** {paymentDisputed[0].payment.paymentType.lastFour}
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" text-xs font-lato-bold text-grey-2 mb-2">Device Location</div>
                                            {/* <div className=" text-sm font-lato-medium text-black">CA 24.141.140.150</div>
                                            <div className=" text-sm font-lato-medium text-black">Waterdown, ON, Canada</div> */}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitEvidence;
