import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage, setupRoutes } from "../routes";
import useVendors from "./useVendors";

const Vendors = () => {
  const {
    dataWithAdditions,
    addItem,
    saveChanges,
    columns,
    instructions,
    isEditing,
    setEditing,
    deleteVendors,
  } = useVendors();
  const navigate = useNavigate();
  const openDetails = useCallback(
    (id: string) => navigate(`/inventory/vendor/${id}`),
    [navigate]
  );
  const save = useCallback(
    (i) =>
      saveChanges(i).then((createdId) => {
        if (createdId) openDetails(createdId);
      }),
    [openDetails, saveChanges]
  );
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar routes={setupRoutes} />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Vendors"
            columns={columns}
            data={dataWithAdditions}
            instructions={instructions}
            saveResults={save}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="New Vendor"
            primaryActionHandler={addItem}
            deleteIds={deleteVendors}
            onRowClick={openDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default Vendors;
