import { EnterpriseInitComplete } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = false;

export default function enterpriseInitComplete(
  state = initialState,
  action: EnterpriseInitComplete
) {
  switch (action.type) {
    case ActionType.ENTERPRISE_INIT_COMPLETE:
      return action.enterpriseInitComplete;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
