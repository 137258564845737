import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";

import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
  onSaveDraft: () => void;
}
const SaveDraftConfirmationModal = ({
  showModal,
  onClose,
  onSaveDraft,
}: Props) => {
  return (
    <Dialog
      onClose={onClose}
      hasBackdrop
      isOpen={showModal}
      className="save-draft-confirmation-modal"
    >
      <div className="modal-header">
        <div className="header-title">Save as Draft?</div>
        <div className="header-subtitle">
          Save this form as a draft to continue editing it later.
        </div>
        <Icon icon="cross" size={15} color="#424452" onClick={onClose} />
      </div>
      <div className="modal-body">
        <Button text="Save as Draft" onClick={onSaveDraft} />
        <Button text="Discard Changes" onClick={onClose} />
      </div>
    </Dialog>
  );
};

export default SaveDraftConfirmationModal;
