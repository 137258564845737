import UIComponents from "@tangopay/tango-ui-library";
import moment from "moment";
import React, { useState } from "react";
import { createReviewTrackersResponse } from "models/reputationManagement";
import FacebookIcon from "assets/reputation/facebook.svg";
import GoogleIcon from "assets/reputation/google.svg";
import OpenTableIcon from "assets/reputation/opentable-ar21.svg";
import TripAdvisorIcon from "assets/reputation/tripadvisor.svg";
import YelpIcon from "assets/reputation/yelp.svg";

//TO DO add 4 square icon
import {
  ReviewResponseTemplate,
  ReviewTrackersCreateResponse,
  ReviewTrackersReview,
  ReviewTrackersResponse
} from "../../../types/reputationManagement";
import ReviewSettingsPopover from "./ReviewSettingsPopover";
import { Dialog } from "@blueprintjs/core";

interface Props {
  business: TangoBusiness;
  customerReview: ReviewTrackersReview;
  reviewTrackersCreds: any;
  reviewTemplates: ReviewResponseTemplate[]
}

const ReviewCard = ({
  business,
  customerReview,
  reviewTrackersCreds,
  reviewTemplates
}: Props) => {
  const starRatingLength = new Array(customerReview.rating).fill("Star");
  const [showPopover, setShowPopover] = useState(false);
  const [showDeleteReviewModal, setShowDeleteReviewModal] = useState(false);
  const [replyText, setReplyText] = useState<string>("");
  const [openTemplates, setOpenTemplates] = useState(false);
  const [successfulResponse, setSuccessfulResponse] = useState<ReviewTrackersResponse>();

  const onMenuItemSelect = (selectedItem: string) => {
    if (selectedItem === "Delete Review") {
      setShowDeleteReviewModal(true);
      setShowPopover(false);
    }
  };

  const submitReply = async () => {
    if (!replyText) {
      return false;
    }
    const replyData: ReviewTrackersCreateResponse = {
      token: reviewTrackersCreds.token,
      username: reviewTrackersCreds.email,
      content: replyText,
      reviewTrackersAccountId: customerReview.account_id,
      reviewTrackersLocationId: customerReview.location_id,
      sourceId: customerReview.source_id,
      reviewId: customerReview.id,
      status: "pending_published",
    };

    console.log("creating reply ?", replyData);

    const success = await createReviewTrackersResponse(replyData);
    if (success) {
      console.log("created reply", success);
      alert('Your review has been submitted! It may take a few minutes to update.')
      setSuccessfulResponse(success)
    } else {
      return false;
    }
  };

  return (
    <div
      className={
        "mb-11 rounded-2xl border border-grey-1 border-solid overflow-hidden"
      }
    >
      <div className={"pt-6 px-8 pb-10"}>
        <div className={"flex items-center justify-between mb-6"}>
          <div className={"flex items-center"}>
            <div className={"mr-4 text-tiny font-lato-medium text-grey-2"}>
              {customerReview.location_name}
            </div>
            <div
              className={
                "h-4 px-4 flex justify-center items-center text-veryTiny text-black font-lato-bold bg-blue-2 border border-blue-3 rounded-full"
              }
            >
              New
            </div>
          </div>
          <ReviewSettingsPopover
            isOpen={showPopover}
            onItemSelect={onMenuItemSelect}
            onPopoverClose={() => setShowPopover(false)}
          >
            <div onClick={() => setShowPopover(true)}>
              <UIComponents.Icon name={"more"} size={"24"} />
            </div>
          </ReviewSettingsPopover>
        </div>
        <div className={"flex justify-between items-center mb-4"}>
          <div className={"flex items-center"}>
            {/* TO DO - SHOW ICONS FOR OTHER CHANNELS, NOT JUST GOOGLE */}
            {customerReview.metadata.google_author_url ? (
              <div
                className={"h-10 w-10 mr-4 flex justify-center items-center"}
              >
                <img
                  src={customerReview.metadata.google_author_url}
                  className={"w-full"}
                />
              </div>
            ) : (
              <div
                className={
                  "h-10 w-10 mr-4 flex justify-center items-center text-xs font-lato-extrabold text-grey-3 bg-blue-grey-4 rounded-[50%]"
                }
              >
                CN
              </div>
            )}

            <div>
              <div className={"text-sm text-black font-lato-bold"}>
                {customerReview.author ? customerReview.author : "Anonymous"}
              </div>

              <div className={"text-tiny text-grey-3 font-lato-medium"}>
                {/* published at vs created at are different - published at is probably when it was published on google/yelp/etc  */}
                {moment(customerReview.published_at).format("MM/DD/YYYY")}
                {/* 2 days ago */} via{" "}
                <a target="_blank" href={customerReview.permalink}>
                  <span className={"text-hyperlink-blue"}>
                    {customerReview.source_name}
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* customerReview.source_icon is poor quality so conditional render our own icon */}
          {customerReview.source_name === "Google" ? (
            <img src={GoogleIcon} />
          ) : customerReview.source_name === "Facebook" ? (
            <img src={FacebookIcon} />
          ) : customerReview.source_name === "Yelp" ? (
            <img src={YelpIcon} />
          ) : customerReview.source_name === "TripAdvisor" ? (
            <img style={{ width: "6%" }} src={TripAdvisorIcon} />
          ) : customerReview.source_name === "OpenTable" ? (
            <img style={{ width: "16%" }} src={OpenTableIcon} />
          ) : (
            <img style={{ width: "3.5em" }} src={customerReview.source_icon} />
          )}
        </div>
        <div className={"flex"}>
          {starRatingLength.map((star: string, index: number) => (
            <UIComponents.Icon
              key={index}
              name={"star-filled"}
              color={"#FAD861"}
              size={"24"}
              className={"mr-2"}
            />
          ))}
        </div>
        <div className={"text-black font-lato-regular text-xs mt-4"}>
          {customerReview.content}
        </div>
        {customerReview.images.length > 0 ? (
          <div className={"mt-6 flex"}>
            {customerReview.images.map((image) => (
              <img
                src={image.low_resolution_url}
                className={"w-16 h-16 mr-4 rounded"}
              />
            ))}
          </div>
        ) : null}


        {/* if the review has a response already then show it */}
        {customerReview.response &&
          <div className="border-t border-grey-1 border-solid border-l-0 border-b-0 border-r-0 mt-8 pt-8 pl-10">
            <div className={"flex items-center"}>
              <img
                src={business.profileImageUrl ? business.profileImageUrl : ""}
                className={"h-9 w-9 rounded-full mr-6"}
              />
              <div>
                <div className={"text-sm text-black font-lato-bold"}>
                  {/* Technically we might be able to find the person who responded based on the customerReview.response.created_by_user_id and show their name and image? */}
                  {business.businessName}
                </div>
                <div className={"text-tiny text-grey-3 font-lato-medium"}>
                  {moment(customerReview.response.created_at).fromNow()}
                </div>
              </div>
            </div>
            <div className={"text-black font-lato-regular text-xs mt-4"}>
              {customerReview.response.content}
            </div>
          </div>
        }
        {/* if RT returns successful programtic reply show it (technically it may take a few minutes to publish) */}

        {successfulResponse &&
          <div className="border-t border-grey-1 border-solid border-l-0 border-b-0 border-r-0 mt-8 pt-8 pl-10">
            <div className={"flex items-center"}>
              <img
                src={business.profileImageUrl ? business.profileImageUrl : ""}
                className={"h-9 w-9 rounded-full mr-6"}
              />
              <div>
                <div className={"text-sm text-black font-lato-bold"}>
                  {/* Technically we might be able to find the person who responded based on the customerReview.response.created_by_user_id and show their name and image? */}
                  {business.businessName}
                </div>
                <div className={"text-tiny text-grey-3 font-lato-medium"}>
                  {moment(successfulResponse.created_at).fromNow()}
                </div>
              </div>
            </div>
            <div className={"text-black font-lato-regular text-xs mt-4"}>
              {successfulResponse.content}
            </div>
          </div>
        }



      </div>
      <div
        className={
          "pt-6 px-8 pb-10 bg-blue-grey-1 flex border-t border-grey-1 justify-between items-center"
        }
      >
        <div
          className={
            "h-10 w-10 flex justify-center items-center text-xs font-lato-extrabold text-grey-3 bg-blue-grey-4 rounded-[50%]"
          }
        >
          CN
        </div>
        <input
          className={
            "h-10 bg-white rounded-full text-sm text-black font-lato-regular px-4 w-full border-0"
          }
          placeholder={
            customerReview.respondable
              ? "Type a reply"
              : "This review is not respondable"
          }
          style={{ width: "calc(100% - 250px)" }}
          disabled={!customerReview.respondable}
          onChange={(e: any) => {
            setReplyText(e.target.value);
          }}
          value={replyText}
        />

        <div className={"flex"}>
          <div
            className={
              "h-10 w-10 rounded-[50%] bg-black flex justify-center items-center"
            }
            style={{ cursor: "pointer" }}
          >
            <UIComponents.Icon
              onClick={() => {
                if (!customerReview.respondable) {
                  return console.log("review not respondable");
                }
                console.log('reply with text', replyText)
                submitReply();
              }}
              name={"send"}
              size={"19"}
              color={"#fff"}
            />
          </div>

          <UIComponents.Button
            label={"Template"}
            type={"btn-style-2"}
            size={"btn-large"}
            className={"ml-6"}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenTemplates(true)
            }}
          />
        </div>
      </div>
      <UIComponents.DeleteModal
        isOpen={showDeleteReviewModal}
        closeModal={() => setShowDeleteReviewModal(false)}
        modalTitle={"Delete this Review?"}
        onDelete={() => console.log("delete review")}
      />



      {openTemplates && business &&
        <Dialog
          isOpen={true}
          hasBackdrop
          onClose={() => {
            setOpenTemplates(false)
          }}
          className=""
          style={{ textAlign: 'left', padding: '5%', fontFamily: "Axiforma-Regular" }}
        >
          <div className="">

            <h2>Select your response from one of your templates</h2>
            <br />

            {reviewTemplates.length > 0 ?
              reviewTemplates.map((template: ReviewResponseTemplate) => {
                return (
                  <div
                    onClick={() => {
                      console.log('select template')
                      setReplyText(template.text)
                      setOpenTemplates(false)
                    }}
                    key={template.id} style={{ marginBottom: '8%' }}>
                    <p style={{ cursor: 'pointer' }}><span style={{ fontWeight: 'bold' }}>{template.name}</span> <br />{template.text}</p>
                  </div>
                )
              })
              :
              <p>Please create a new response template</p>
            }
          </div>
        </Dialog>
      }

    </div>
  );
};
export default ReviewCard;
