import _ from "lodash";
import moment from "moment-timezone";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  getShiftsForADay,
  groupShiftsByShiftTypeAndTimeFrame,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { getPositionById } from "model/selectors/businessSettings";

import ShiftCard from "../ShiftCard";
import { SmallSlotCard } from "../SlotCard";

const groupShiftsByJobFunction = (
  shifts: TangoShift[],
  jobFunctions: TangoJobFunctions
): { name: string; quantity: number; total: number }[] => {
  const groupedShifts = _.groupBy(
    shifts,
    (shift: TangoShift) => shift.position
  );
  return _.keys(groupedShifts).map((key) => ({
    name: jobFunctions[key]?.title,
    total: groupedShifts[key]?.length,
    quantity: groupedShifts[key].filter(({ staffId }) => staffId).length,
  }));
};

interface WeeklyShiftsProps {
  dayOfWeek: Date;
  weekRange: Date[];
  schedulesWithLocalSchedule: TangoSchedule[];
  business: TangoBusiness;
  fixedSchedules: TangoFixedSchedule[];
  selectedDepartment: "boh" | "foh" | undefined;
  departmentScheduleViewType: DepartmentId | null;
  businessSettings: TangoBusinessSettings;
  setShowSingleDay: (v: boolean) => void;
  jobFunctions: TangoJobFunctions;
}

export const WeeklyShifts = ({
  dayOfWeek,
  weekRange,
  schedulesWithLocalSchedule,
  business,
  fixedSchedules,
  selectedDepartment,
  departmentScheduleViewType,
  businessSettings,
  setShowSingleDay,
  jobFunctions,
}: WeeklyShiftsProps) => {
  const navigate = useNavigate();
  let shiftsForADay = getShiftsForADay(
    dayOfWeek,
    weekRange,
    schedulesWithLocalSchedule,
    business.timezone,
    fixedSchedules,
    selectedDepartment
  );
  if (departmentScheduleViewType) {
    shiftsForADay = shiftsForADay.filter((shift) => {
      const shiftPosition = shift.position;
      const positionData = getPositionById(businessSettings, shiftPosition);
      return positionData?.departmentId === departmentScheduleViewType;
    });
  }
  const shiftsByShiftType = groupShiftsByShiftTypeAndTimeFrame(shiftsForADay);
  if (shiftsByShiftType.length) {
    return (
      <>
        {shiftsByShiftType
          .sort(
            (a, b) =>
              moment(a.startTime, "HH:mm").unix() -
              moment(b.startTime, "HH:mm").unix()
          )
          .map((groupedShits, i) => (
            <ShiftCard
              key={`${groupedShits.shiftTypeId}_${groupedShits.startTime}_${groupedShits.endTime}`}
              onClick={() => {
                setShowSingleDay(true);
                navigate(
                  `daily-schedule/${moment(dayOfWeek).format("YYYY-MM-DD")}`
                );
              }}
              id={i}
              shiftTypeId={groupedShits.shiftTypeId}
              startTime={groupedShits.startTime}
              endTime={groupedShits.endTime}
              type="time-off"
              shiftPositions={groupShiftsByJobFunction(
                groupedShits.shifts,
                jobFunctions
              )}
            />
          ))}
      </>
    );
  }
  return null;
};
