import React, { useCallback, useMemo, useState } from "react";
import UIComponents from "@tangopay/tango-ui-library";
import { useSelector } from 'react-redux'

import { RootState } from "model/store";
import { FileSharingOptions } from "./Files/hooks/useDocs";
import BusinessSharingIcon from 'assets/clouds/business-sharing-icon.svg'

const { Modal, SearchBar, Button, Tag, Icon } = UIComponents;
interface Props {
  isOpen: boolean;
  closeModal: () => void;
  fileOrFolderForSharing: FilesAndFolders | null;
  staffMemberSharingOptions?: FileSharingOptions;
  handleInvites: (userIds: string[], businessesOptions: SearchableBusinessOption[]) => Promise<void>
}


const availableOptions = [
  {
    value: "editor",
    label: "Editor"
  },
  {
    value: "viewer",
    label: "Viewer"
  },
  {
    value: "remove",
    label: "Remove",
    danger: true
  }
]

const convertAccessBreadthToLabel = (breadth: "admins" | "all-staff") => {
  if (breadth === "admins") {
    return "All Admins"
  }
  return "All Staff"

}

export type SearchableBusinessOption = {
  value: string;
  label: string;
  isBusiness: boolean;
  businessId: string;
  type: "admins" | "all-staff";
}
type SearchOption = SearchableBusinessOption | {
  value: string;
  label: string;
}
const ShareModal = ({ isOpen, closeModal, fileOrFolderForSharing, staffMemberSharingOptions, handleInvites }: Props) => {
  const businessSettings: TangoBusinessSettings = useSelector((state: RootState) => state.businessSettings)
  const [selectedOptions, setSelectedOptions] = useState<SearchOption[]>([])
  const searchableGroups = useMemo(() => {
    const groupedSearchOptions = []
    if (!staffMemberSharingOptions) {
      return []
    }
    const searchableBusinessesOptions: SearchableBusinessOption[] = []
    staffMemberSharingOptions.searchableBusinesses.forEach((business) => {
      searchableBusinessesOptions.push({
        value: `${business.businessId}_admins`,
        label: `${business.businessName} (All Admins)`,
        isBusiness: true,
        businessId: business.businessId,
        type: "admins"
      })
      searchableBusinessesOptions.push({
        value: `${business.businessId}_all_staff`,
        label: `${business.businessName} (All Staff)`,
        isBusiness: true,
        businessId: business.businessId,
        type: "all-staff"
      })
    })
    const searchableUsrs = staffMemberSharingOptions.searchableUsers.map((user) => {
      return {
        value: user.userId,
        label: user.fullName
      }
    })
    if (searchableBusinessesOptions.length) {
      groupedSearchOptions.push({
        label: "Business",
        options: searchableBusinessesOptions
      })
    }
    if (searchableUsrs.length) {
      groupedSearchOptions.push({
        label: "People",
        options: searchableUsrs
      })
    }
    return groupedSearchOptions
  }, [staffMemberSharingOptions])

  console.log("fileOrFolderForSharing", fileOrFolderForSharing)
  console.log("staffMemberSharingOptions", staffMemberSharingOptions)

  const submitAccess = useCallback(async () => {
    //@ts-ignore
    const businessOptions = selectedOptions.filter((option) => option.isBusiness) as SearchableBusinessOption[]
    //@ts-ignore
    const usersOptions = selectedOptions.filter((option) => !option.isBusiness).map(u => u.value)
    await handleInvites(usersOptions, businessOptions)
    setSelectedOptions([])
  }, [handleInvites, selectedOptions])

  const [businessIdToSeeDetails, setBusinessIdToSeeDetails] = useState<string | null>(null)

  const handleClose = useCallback(() => {
    setSelectedOptions([])
    setBusinessIdToSeeDetails(null)
    closeModal()
  }, [closeModal, setSelectedOptions])

  const handleBusinessClick = useCallback((businessId: string) => {
    setBusinessIdToSeeDetails(businessId)
  }, [staffMemberSharingOptions, setBusinessIdToSeeDetails])

  const handleBusinessBackClick = useCallback(() => {
    setBusinessIdToSeeDetails(null)
  }, [setBusinessIdToSeeDetails])

  const businessToDisplayForDetails = useMemo(() => {
    const businessToDisplay = staffMemberSharingOptions?.businesses.find((business) => business.businessId === businessIdToSeeDetails)
    return businessToDisplay ?? null

  }, [businessIdToSeeDetails, staffMemberSharingOptions])

  if (!fileOrFolderForSharing || !staffMemberSharingOptions) return null
  console.log("staffMemberSharingOptions", staffMemberSharingOptions)
  console.log("businessIdToSeeDetails", businessIdToSeeDetails)
  console.log("businessToDisplayForDetails", businessToDisplayForDetails)

  const renderContent = () => {
    if (businessToDisplayForDetails) {
      return (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row">
              <Icon size="30" name="chevron-left" onClick={handleBusinessBackClick} />
              <div className="text-xl text-black font-lato-black mb-4">Members | {businessToDisplayForDetails.businessName}</div>
            </div>
            <ul className="p-0 m-0 list-none h-52 overflow-y-auto">
              {businessToDisplayForDetails.users.map((item) => {
                return (
                  <li key={item.userId} className="flex justify-between py-1 mb-3">
                    <div className="flex">
                      <Tag color="blue" type="medium" text={item.abbrName} />
                      <div className="font-lato-bold text-sm text-black ml-2">
                        {item.fullName}
                      </div>
                    </div>
                    {/* <div className="text-xs font-lato-bold text-black">
                  {roleTitle}
                </div> */}
                    <select onChange={e => console.log(e.target.value)} className="text-xs font-lato-bold text-black" style={{ border: "0" }}>
                      {availableOptions.map((option) => {
                        return <option selected={option.value === item.accessType} className="text-xs font-lato-bold text-black">{option.label}</option>
                      })}
                      {/* <option className="text-xs font-lato-bold text-black" value={item.role}>{item.role}</option>
                  <option value="one">One</option>
                  <option value="two">Two</option> */}
                    </select>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )
    }
    return (
      <>
        {staffMemberSharingOptions.businesses.length ? (
          <>
            <div className="text-xl text-black font-lato-black mb-4">Businesses</div>
            {staffMemberSharingOptions.businesses.map((item) => {
              return (
                <li key={item.businessId} className="flex justify-between py-1 mb-3 items-center">
                  <div className="flex row" style={{ width: '100%' }}>
                    <img src={BusinessSharingIcon} />
                    <div className="font-lato-bold text-sm text-black ml-2 mr-2 mt-1">
                      {item.businessName} {item.accessBreadth ? `(${convertAccessBreadthToLabel(item.accessBreadth)})` : null}
                    </div>
                    <Icon size="30" name="chevron-right" onClick={() => handleBusinessClick(item.businessId)} />

                  </div>
                  <select onChange={e => console.log(e.target.value)} className="text-xs font-lato-bold text-black" style={{ border: "0" }}>
                    {availableOptions.map((option) => {
                      return <option selected={option.value === item.generalAccessType} className="text-xs font-lato-bold text-black">{option.label}</option>
                    })}
                    {/* <option className="text-xs font-lato-bold text-black" value={item.role}>{item.role}</option>
                  <option value="one">One</option>
                  <option value="two">Two</option> */}
                  </select>
                </li>
              )
            })}
          </>
        ) : null}
        <div className="text-xl text-black font-lato-black mb-4">Members</div>

        <ul className="p-0 m-0 list-none h-52 overflow-y-auto">
          {staffMemberSharingOptions.users.map((item) => {
            const roleTitle = item.roleTitle
            return (
              <li key={item.userId} className="flex justify-between py-1 mb-3">
                <div className="flex">
                  <Tag color="blue" type="medium" text={item.abbrName} />
                  <div className="font-lato-bold text-sm text-black ml-2">
                    {item.fullName}
                  </div>
                </div>
                {/* <div className="text-xs font-lato-bold text-black">
                  {roleTitle}
                </div> */}
                <select onChange={e => console.log(e.target.value)} className="text-xs font-lato-bold text-black" style={{ border: "0" }}>
                  {availableOptions.map((option) => {
                    return <option selected={option.value === item.accessType} className="text-xs font-lato-bold text-black">{option.label}</option>
                  })}
                  {/* <option className="text-xs font-lato-bold text-black" value={item.role}>{item.role}</option>
                  <option value="one">One</option>
                  <option value="two">Two</option> */}
                </select>
              </li>
            );
          })}
        </ul>
      </>
    )
  }
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      closeModal={handleClose}
      width="736"
    >
      <div className="flex items-center">
        <div className="text-big text-black font-lato-black">Share File</div>
        <div className="pl-5 ml-5 text-base text-black font-lato-regular border-l border-grey-1 border-solid border-b-0 border-t-0 border-r-0">
          {fileOrFolderForSharing.name}
        </div>
      </div>
      {businessToDisplayForDetails ? null : (
        <>
          <div className="text-black text-xl font-lato-black mt-10">Invite</div>
          <div className="flex mt-4">

            <SearchBar
              isMulti
              menuPosition="absolute"
              className="w-full"
              // value={[]}
              size="medium"
              value={selectedOptions}
              //@ts-ignore
              onChange={setSelectedOptions}
              filters={[]}
              //@ts-ignore
              options={searchableGroups}
            />
            <Button
              size="btn-medium"
              type="btn-style-1"
              label="Invite"
              className="ml-4"
              onClick={submitAccess}
            />
          </div>
        </>
      )}

      <div className="mt-10">
        {renderContent()}
        <Button
          type="btn-style-2"
          label="Done"
          size="btn-medium"
          className="w-full justify-center mt-14"
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
