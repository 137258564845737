import { FirebaseServiceAreaDoc } from "types/serviceArea";

import ActionType from "./actionTypes";

export function receiveServiceAreas(serviceAreas: FirebaseServiceAreaDoc[]) {
  return {
    type: ActionType.RECEIVE_SERVICE_AREAS,
    serviceAreas,
  };
}
