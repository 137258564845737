import { ButtonBase } from "@mui/material";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "model/store";

interface Props {
  id: string | number;
  type: string;
  shiftPositions: { name: string; quantity: number; total: number }[];
  startTime: string;
  endTime: string;
  shiftTypeId: string;
  onClick: () => void;
}
export const CheckMark = ({ fill }: { fill: string }) => (
  <svg
    className="checkmark"
    width="9"
    height="7"
    fill="none"
    viewBox="0 0 9 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M4.07394 6.54851C3.66589 6.95657 3.00381 6.95657 2.59596 6.54851L0.306033 4.25853C-0.102011 3.85068 -0.102011 3.18858 0.306033 2.78072C0.713879 2.37267 1.37597 2.37267 1.78401 2.78072L3.14836 4.1449C3.25135 4.2477 3.41855 4.2477 3.52174 4.1449L7.21599 0.450571C7.62384 0.0425181 8.28592 0.0425181 8.69397 0.450571C8.88991 0.646522 9 0.912387 9 1.18948C9 1.46657 8.88991 1.73243 8.69397 1.92838L4.07394 6.54851Z"
    />
  </svg>
);

const ShiftCard = ({
  id,
  type,
  startTime,
  endTime,
  shiftTypeId,
  shiftPositions,
  onClick,
}: Props) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const shiftTypes = businessSettings.shiftTypes || [];
  const shiftType = shiftTypes.find((st) => st.id === shiftTypeId);
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      key={id}
      className={`shift-card-${type} shift-card`}
      style={{
        border: `2px ${shiftType?.mainColor} solid`,
        overflow: "hidden",
        cursor: "pointer",
      }}
    >
      <div
        onClick={onClick}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="shift-card_header"
          style={{ backgroundColor: shiftType?.backgroundColor, width: "100%" }}
        >
          <div className="shift-type" style={{ textAlign: "left" }}>
            {shiftType?.name}
          </div>
          <div className="shift-time" style={{ textAlign: "left" }}>
            {moment(startTime, "HH:mm").format("hh:mm a")} -{" "}
            {moment(endTime, "HH:mm").format("hh:mm a")}
          </div>
          <div
            className="dot"
            style={{ backgroundColor: shiftType?.mainColor, marginTop: 0.7 }}
          />
        </Box>
        <Box className="shift-card_body" style={{ width: "100%" }}>
          <ul style={{ width: "100%" }}>
            {shiftPositions.map((shiftPosition) => (
              <li
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor:
                    shiftPosition.quantity === shiftPosition.total
                      ? shiftType?.backgroundColor
                      : "",
                  color:
                    shiftPosition.quantity === shiftPosition.total
                      ? shiftType?.mainColor
                      : "#6E6A6B",
                }}
              >
                {shiftPosition.quantity}/{shiftPosition.total}{" "}
                {shiftPosition.name}s
                {shiftPosition.quantity === shiftPosition.total && (
                  <CheckMark fill={shiftType?.mainColor || ""} />
                )}
              </li>
            ))}
          </ul>
        </Box>
      </div>
    </motion.div>
  );
};
export default ShiftCard;
