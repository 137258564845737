import UIComponents from "@tangopay/tango-ui-library";
import React, { ReactElement } from "react";

interface Props {
  isOpen: boolean;
  children: ReactElement;
  onItemSelect: (menuItem: string) => void;
  onPopoverClose: () => void;
}
const ReviewSettingsPopover = ({
  isOpen,
  children,
  onItemSelect,
  onPopoverClose,
}: Props) => {
  return (
    <UIComponents.Popover
      positions={["bottom"]}
      isOpen={isOpen}
      onClickOutside={onPopoverClose}
      content={
        <ul
          className={
            "bg-white shadow-general-shadow rounded-2xl px-6 py-5 list-none"
          }
        >
          <li
            className={"mb-6 text-black text-sm font-lato-bold cursor-pointer"}
            onClick={() => onItemSelect("View Source")}
          >
            View Source
          </li>
          <li
            className={"mb-6 text-black text-sm font-lato-bold cursor-pointer"}
            onClick={() => onItemSelect("Add Improvement Item")}
          >
            Add Improvement Item
          </li>
          <li
            className={"mb-6 text-black text-sm font-lato-bold cursor-pointer"}
            onClick={() => onItemSelect("Share Review")}
          >
            Share Review
          </li>
          <li
            className={
              "text-black text-error-red font-lato-bold cursor-pointer"
            }
            onClick={() => onItemSelect("Delete Review")}
          >
            Delete Review
          </li>
        </ul>
      }
    >
      {children}
    </UIComponents.Popover>
  );
};
export default ReviewSettingsPopover;
