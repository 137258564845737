import _ from "lodash";
import moment from "moment";
import {
  FirebaseDiscountDoc,
  ScheduledDiscount,
} from "views/admin/menus&Products/discounts/discountTypes";

import { FirebaseCashDrawerDoc, FirebaseCashEvents } from "types/cashDrawers";
import { DocumentData } from "types/document";
import { FirebaseMenuDoc } from "types/menus";
import { FirebaseOrderDoc, FirebaseTabDoc } from "types/order";
import { FirebaseOrderDisplayDoc } from "types/orderDisplay";
import { FirebasePrintersDoc } from "types/printers";
import { FirebaseServiceAreaDoc } from "types/serviceArea";
import { FirebaseTableDoc } from "types/tables";

export const composeUsableStaffMember = (
  staffMember: DocumentData
): StaffMember => ({
  businessId: staffMember.businessId,
  contact: staffMember.contact
    ? {
        email: staffMember.contact.email,
        firstName: staffMember.contact.firstName,
        lastName: staffMember.contact.lastName,
        phone: staffMember.contact.phone,
      }
    : {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
  createdAt: staffMember.createdAt,
  primaryRole: staffMember.primaryRole || null,
  secondaryRoles: staffMember.secondaryRoles || [],
  deleted: staffMember.deleted,
  uid: staffMember.uid || null,
  id: staffMember?.uid || staffMember?.id,
  staffMemberCopyId: staffMember?.id,
  imageUrl: staffMember.imageUrl || null,
  isAdmin: staffMember.isAdmin,
  pinCode: staffMember.pinCode,
  privileges: staffMember.privileges || [],
  updatedAt: staffMember.updatedAt,
  upcomingEvents: [],
  pendingOnBoarding: undefined,
  isBusinessOwner: staffMember.isBusinessOwner || false,
  status: staffMember.status || null,
  // TODO: Add these to the schema
  ssnOrSin: staffMember.ssnOrSin || null,
  medicalCard: staffMember.medicalCard || null,
  i9: staffMember.i9 || null,
  w4: staffMember.w4 || null,
  emergencyContact: {
    name: staffMember.emergencyContact?.name || null,
    relationship: staffMember.emergencyContact?.relationship || null,
    phone: staffMember.emergencyContact?.phone || null,
  },
  payRates:
    staffMember.payRates && Array.isArray(staffMember.payRates)
      ? staffMember.payRates.map((rate: any) => ({
          role: rate.role,
          amount: rate.amount,
          roleId: rate.roleId,
          type: rate.type ?? "",
        }))
      : [],
  typeOfEmployee: staffMember.typeOfEmployee,
  annualSalary: staffMember.annualSalary,
  externalEmployeeId: staffMember?.externalEmployeeId ?? "",
  corporateAccess: !!staffMember.corporateAccess,
  accountId: staffMember.accountId || null,
  levelId: staffMember.levelId || null,
  departmentId: staffMember.departmentId || null,
  parentStaffIds: staffMember.parentStaffIds || [],
  idOfTheGroups: staffMember.idOfTheGroups || [],
});

export const composeUsableTangoAccount = (
  tangoAccount: DocumentData
): TangoAccount => ({
  id: tangoAccount.id,
  owner: {
    firstName: tangoAccount?.owner?.firstName || "",
    lastName: tangoAccount?.owner?.lastName || "",
    phone: tangoAccount?.owner?.phone || "",
    email: tangoAccount?.owner?.email || "",
  },
  parentId: tangoAccount?.parentId || null,
  updatedAt: tangoAccount?.updatedAt.toDate() || new Date(),
  createdAt: tangoAccount?.createdAt.toDate() || new Date(),
  deleted: !!tangoAccount.deleted,
  mobileOrders: tangoAccount.mobileOrders,
  enabled: !!tangoAccount.enabled,
  settings: tangoAccount.settings,
  stripeConnect: tangoAccount.stripeConnect,
});

export const composeUsableTangoBusiness = (
  tangoBusiness: DocumentData
): TangoBusiness => ({
  accountId: tangoBusiness.accountId,
  contact: tangoBusiness.contact,
  location: tangoBusiness.location,
  createdAt: tangoBusiness.createdAt,
  deleted: tangoBusiness.deleted,
  id: tangoBusiness.id,
  splashImageUrl: tangoBusiness.splashImageUrl,
  bannerImageUrl: tangoBusiness.bannerImageUrl,
  profileImageUrl: tangoBusiness.profileImageUrl,
  businessName: tangoBusiness.businessName,
  businessType: tangoBusiness.businessType,
  businessDomain: tangoBusiness.businessDomain,
  hours: tangoBusiness.hours,
  owner: tangoBusiness.owner,
  phones: tangoBusiness.phones,
  updatedAt: tangoBusiness.updatedAt,
  disabled: tangoBusiness.disabled,
  pendingOnBoarding: tangoBusiness.pendingOnBoard || false,
  tangoProducts: tangoBusiness.tangoProducts,
  paymentInfo: tangoBusiness.paymentInfo,
  payrollStartOfTheWeek: tangoBusiness.payrollStartOfTheWeek,
  availabilityBufferInWeeks: tangoBusiness.availabilityBufferInWeeks,
  timezone: tangoBusiness.timezone || "America/Toronto",
  currency: tangoBusiness.currency || "USD",
});
export const composeUsableBusinessSettings = (
  businessSettings: DocumentData
): TangoBusinessSettings => {
  return {
    ackrooDeviceId: businessSettings.ackrooDeviceId || "",
    businessHasFullyOnboarded: !!businessSettings.businessHasFullyOnboarded,
    autoApproveAvailabilities: !!businessSettings.autoApproveAvailabilities,
    autoPrintDineInOrders: !!businessSettings.autoPrintDineInOrders,
    autoPrintCarryOutOrders: !!businessSettings.autoPrintCarryOutOrders,
    autoPrintCurbsideOrders: !!businessSettings.autoPrintCurbsideOrders,
    autoPrintDeliveryOrders: !!businessSettings.autoPrintDeliveryOrders,
    docsTags:
      (businessSettings.docsTags || [])
        .map((tag: any) => ({
          name: tag.name || "",
          count: tag.count || 0,
          deleted: !!tag.deleted,
          ids: tag.ids || [],
        }))
        .filter((tag: DocTag) => !tag.deleted) || [],
    folderTags:
      (businessSettings?.folderTags || [])
        .map((tag: any) => ({
          name: tag.name || "",
          count: tag.count || 0,
          deleted: !!tag.deleted,
          ids: tag.ids || [],
        }))
        .filter((tag: DocTag) => !tag.deleted) || [],
    businessUsesInternetReaders: !!businessSettings.businessUsesInternetReaders,
    businessDoesTableService: !!businessSettings.businessDoesTableService,
    carryOutEnabledInSpeed: !!businessSettings.carryOutEnabledInSpeed,
    carryOutEnabledInFlex: !!businessSettings.carryOutEnabledInFlex,
    percentDiscountForStaffMembers:
      businessSettings.percentDiscountForStaffMembers || 0,
    businessId: businessSettings.businessId || "",
    id: businessSettings.businessId || "",
    audienceTags: businessSettings.audienceTags,
    flexCashRoundAmount: businessSettings.flexCashRoundAmount || 0,
    flexCashRoundType: businessSettings.flexCashRoundType || "none",
    speedCashRoundAmount: businessSettings.speedCashRoundAmount || 0,
    speedCashRoundType: businessSettings.speedCashRoundType || "none",
    preAuthAmount: businessSettings.preAuthAmount || 0,
    scheduledOrders: !!businessSettings.scheduledOrders,
    scheduledOrdersRequired: !!businessSettings.scheduledOrdersRequired,
    selfPay: !!businessSettings.selfPay,
    specialInstructions: !!businessSettings.specialInstructions,
    onlineTipPresetPercentages:
      businessSettings.onlineTipPresetPercentages || [],
    onlineTipPresetAmounts: businessSettings.onlineTipPresetAmounts || [],
    onlineMinimumTipForPercentagePresets:
      businessSettings.onlineMinimumTipForPercentagePresets || [],
    payPeriodStartDate: businessSettings.payPeriodStartDate || new Date(),
    speedTipPresetPercentages: businessSettings.speedTipPresetPercentages || [],
    speedTipPresetAmounts: businessSettings.speedTipPresetAmounts || [],
    speedMinimumTipForPercentagePresets:
      businessSettings.speedMinimumTipForPercentagePresets || [],
    flexTipPresetPercentages: businessSettings.flexTipPresetPercentages || [],
    flexTipPresetAmounts: businessSettings.flexTipPresetAmounts || [],
    flexMinimumTipForPercentagePresets:
      businessSettings.flexMinimumTipForPercentagePresets || [],
    directSendOrder: !!businessSettings.directSendOrder,
    requirePinCodeForDiscountsInSpeed:
      !!businessSettings.requirePinCodeForDiscountsInSpeed,
    requirePinCodeForDiscountsInFlex:
      !!businessSettings.requirePinCodeForDiscountsInFlex,
    requirePinCodeForRefundsInSpeed:
      !!businessSettings.requirePinCodeForRefundsInSpeed,
    requirePinCodeForRefundsInFlex:
      !!businessSettings.requirePinCodeForRefundsInFlex,
    requirePinCodeForCompsInSpeed:
      !!businessSettings.requirePinCodeForCompsInSpeed,
    requirePinCodeForCompsInFlex:
      !!businessSettings.requirePinCodeForCompsInFlex,
    shiftTypes: businessSettings.shiftTypes || [],
    jobFunctions: businessSettings.jobFunctions || [],
    levels: businessSettings.levels || [],
    customGroups: businessSettings.customGroups || [],
    amPmSplitTime: businessSettings.amPmSplitTime ?? "16:00",
    // {
    //   [key: string]: {
    //     id: string;
    //     title: string;
    //     deleted: boolean;
    //     departmentId?: string;
    //   };
    // };
    speedReceiptPrompt: !!businessSettings.speedReceiptPrompt,
    orderSpecialInstructions: !!businessSettings.orderSpecialInstructions,
    deliveryRadius: businessSettings.deliveryRadius || 0,
    deliveryFee: businessSettings.deliveryFee || 0,
    isFastCashCheckout: !!businessSettings.isFastCashCheckout,
    showFullStaffSchedule: !!businessSettings.showFullStaffSchedule,
    turnLocationOff: !!businessSettings.turnLocationOff,
    enableDropShifts: !!businessSettings.enableDropShifts,
    shouldShowEndTime: !!businessSettings.shouldShowEndTime,
    blackOutDates: businessSettings.blackOutDates || {}, // { [key: string]: { date: string; staffMembers: number } };
    dailyLogTags: businessSettings.dailyLogTags || [],
    benifits: businessSettings.benifits || [],
    allowItemLevelRemarks: !!businessSettings.allowItemLevelRemarks,
    businessUsesOrderDisplay: !!businessSettings.businessUsesOrderDisplay,
    reviewTrackersEnabled: !!businessSettings.reviewTrackersEnabled,
    reviewTrackersAccountId: businessSettings.reviewTrackersAccountId,
    reviewTrackersLocationId: businessSettings.reviewTrackersLocationId,
    menuBuilderPermissions: businessSettings.menuBuilderPermissions
      ? {
          allowMenuUpdates:
            businessSettings.menuBuilderPermissions.allowMenuUpdates,
          allowModifierOptionsUpdates:
            businessSettings.menuBuilderPermissions.allowModifierOptionsUpdates,
          allowModifierUpdates:
            businessSettings.menuBuilderPermissions.allowModifierUpdates,
          allowProductUpdates:
            businessSettings.menuBuilderPermissions.allowProductUpdates,
        }
      : {
          allowMenuUpdates: true,
          allowModifierOptionsUpdates: true,
          allowModifierUpdates: true,
          allowProductUpdates: true,
        },
  };
};

export const composeUsableTangoSchedule = (
  schedule: DocumentData
): TangoSchedule => ({
  id: schedule.id,
  availabilityType: schedule.availabilityType,
  businessId: schedule.businessId,
  startDate: schedule.startDate,
  endDate: schedule.endDate,
  hasTimeOffPay: schedule.hasTimeOffPay,
  overtimeHourLimit: schedule.overtimeHourLimit,
  recur: schedule.recur,
  shifts: schedule.shifts,
  isDraft: schedule.isDraft,
  createdAt: schedule.createdAt,
  updatedAt: schedule.updatedAt,
  deleted: false,
  projectedSales: schedule.projectedSales,
  actualDailySalesOverrides: schedule.actualDailySalesOverrides,
  departmentId: schedule.departmentId || "foh",
});

export const composeUsableProducts = (
  product: DocumentData
): FirebaseProductDoc => ({
  alcohol: product.alcohol,
  allergens: product.allergens || [],
  businessId: product.businessId,
  createdAt: product.createdAt,
  deleted: product.deleted,
  description: product.description,
  enabled: product.enabled,
  id: product.id,
  imageUrl: product.imageUrl || null,
  ingredients: product.ingredients || [],
  ingredientsQuantity: product.ingredientsQuantity || [],
  modifiers:
    product.modifiers.filter((i: string | null | undefined) => !!i) || [],
  nameExternal: product.nameExternal || "",
  nameInternal: product.nameInternal || "",
  popularity: product.popularity || 0,
  preparationInstructions: product.preparationInstructions || null,
  price: product.price,
  suggestedPairings: product.suggestedPairings || [],
  autoSuggestedPairings: product.autoSuggestedPairings || [],
  tags: product.tags || [],
  taxRate: product.taxRate || null,
  type: product.type || "",
  subType: product.subType || [],
  updatedAt: product.updatedAt,
  plu: product.plu || null,
  isMeal: !!product.isMeal,
});

export const composeUsableProductTypes = (
  product: DocumentData
): FirebaseDeliverectProductType => ({
  businessId: product.businessId,
  plu: product.plu || null,
  productType: product.productType,
  productSubType: product.productSubType,
  productName: product.productName,
  createdAt: product.createdAt.toDate(),
  updatedAt: product.updatedAt.toDate(),
  enabled: product.enabled,
  deleted: product.deleted,
});

export const composeUsableDocs = (doc: DocumentData): FilesAndFolders => ({
  id: doc.id,
  businessId: doc.businessId,
  createdAt: doc.createdAt.toDate(),
  updatedAt: doc.updatedAt.toDate(),
  deleted: doc.deleted || false,
  parentId: doc.parentId || "",
  childrenIds: doc.childrenIds || [],
  absolutePath: doc.absolutePath || "",
  downloadUrl: doc.downloadUrl || null,
  fileSize: doc.fileSize || 0,
  fileExtension: doc.fileExtension || null,
  isFolder: !!doc.isFolder,
  deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : null,
  deletedTemporarily: !!doc.deletedTemporarily,
  name: doc.name,
  starred: !!doc.starred,
  description: doc.description || "",
  authorId: doc.authorId || { staffId: "", accessType: "viewer" },
  access: doc.access || [],
  tags: doc.tags || [],
  fileID: doc.fileID || "",
  accountId: doc.accountId || "",
  parentAccountId: doc.parentAccountId || "",
  businessAccess: doc.businessAccess || [],
  trashed: !!doc.trashed,
  deletedConfig: doc.deletedConfig || null,
  businessLevelStaffAccess: doc.businessLevelStaffAccess || [],
  businessLevelAccessOverrides: doc.businessLevelAccessOverrides || [],
});

export const composeUsableModifierOptions = (
  modifierOptions: DocumentData
) => ({
  additionalCost: modifierOptions.additionalCost,
  businessId: modifierOptions.businessId,
  createdAt: modifierOptions.createdAt,
  deleted: modifierOptions.deleted || false,
  enabled: modifierOptions.enabled,
  id: modifierOptions.id,
  ingredients: modifierOptions.ingredients || [],
  ingredientsQuantity: modifierOptions.ingredientsQuantity || [],
  name: modifierOptions.name,
  plu: modifierOptions.plu || null,
  updatedAt: modifierOptions.updatedAt,
});

export const composeUsableModifiers = (modifier: DocumentData) => ({
  businessId: modifier.businessId,
  createdAt: modifier.createdAt,
  deleted: modifier.deleted,
  description: modifier.description || "",
  enabled: modifier.enabled,
  id: modifier.id,
  imageUrl: modifier.imageUrl || null,
  max: modifier.max,
  min: modifier.min,
  nameExternal: modifier.nameExternal || "",
  nameInternal: modifier.nameInternal || "",
  options: modifier.options.filter((i: string | null | undefined) => !!i) || [],
  tags: modifier.tags || [],
  updatedAt: modifier.updatedAt,
  plu: modifier.plu || null,
});

export const composeUsableWorkEvent = (workEvent: DocumentData): WorkEvent => ({
  id: workEvent.id,
  businessId: workEvent.businessId,
  staffId: workEvent.staffId,
  position: workEvent.position,
  clockedIn: workEvent.clockedIn || null,
  clockedOut: workEvent.clockedOut || null,
  createdAt: workEvent.createdAt,
  updatedAt: workEvent.updatedAt,
  deleted: workEvent.deleted,
  enabled: workEvent.enabled,
});

export const composeUsableFixedAvailability = (
  fa: DocumentData
): FixedAvailability => ({ ...fa } as FixedAvailability);
export const composeUsableAnnouncement = (a: DocumentData): Announcement =>
  ({ ...a } as Announcement);

export const composeUsableFixedSchedule = (
  fs: DocumentData
): TangoFixedSchedule | null => {
  if (
    !fs?.recurrence ||
    _.isEmpty(fs?.recurrence) ||
    !_.isEmpty(fs?.reccurrence) ||
    !_.isEmpty(fs?.recurence) ||
    !_.isEmpty(fs?.reccurence)
  ) {
    return null;
  }
  return {
    ...fs,
    departmentId: fs?.departmentId || "foh",
  } as TangoFixedSchedule;
};

export const composeUsableDraftSchedule = (
  ds: DocumentData
): TangoDraftSchedule => ({ ...ds } as TangoDraftSchedule);

export const composeUsableAvailability = (
  availability: DocumentData
): Availability => ({
  businessId: availability.businessId,
  endTime: availability.endTime,
  staffId: availability.staffId,
  id: availability.id,
  date: availability.date,
  startTime: availability.startTime,
  startDate: availability.startDate,
  endDate: availability.endDate,
  extraDetails: availability.extraDetails || null,
});

export const compouseUsableMenuCategories = (
  menuCategory: DocumentData
): FirebaseMenuCategoryDoc => ({
  businessId: menuCategory.businessId,
  createdAt: menuCategory.createdAt,
  deleted: menuCategory.deleted || false,
  description: menuCategory.description || "",
  enabled: menuCategory.enabled,
  id: menuCategory.id,
  name: menuCategory.name,
  products: menuCategory.products.filter((i: string | null | undefined) => !!i),
  updatedAt: menuCategory.updatedAt,
});

export const composeUsableMenus = (menu: DocumentData): FirebaseMenuDoc => ({
  businessId: menu.businessId,
  categories: menu.categories.filter((i: string | null | undefined) => !!i),
  createdAt: menu.createdAt.toDate() || menu.createdAt,
  deleted: menu.deleted || false,
  enabled: menu.enabled,
  endDate: menu.endDate,
  hours: menu.hours,
  id: menu.id,
  index: menu.index,
  isDineInMenu: menu.isDineInMenu || true,
  isDeliveryAndCarryOutMenu: menu.isDeliveryAndCarryOutMenu || true,
  isDineInPwaMenu: menu.isDineInPwaMenu || false,
  isInternalMenu: menu.isInternalMenu || false,
  nameExternal: menu.nameExternal,
  nameInternal: menu.nameInternal,
  privateMenu: menu.privateMenu || false,
  spendingLimit: menu.spendingLimit,
  startDate: menu.startDate,
  tags: menu.tags,
  updatedAt: menu.updatedAt.toDate() || menu.updatedAt,
  enterpriseUpdatedDocument: menu.enterpriseUpdatedDocument || false,
});

export const composeUsableScheduleEvent = (
  scheduleEvent: DocumentData
): ScheduleEvent => {
  if (scheduleEvent.eventType === "cover") {
    return {
      ...scheduleEvent,
      openCoverConstraints: scheduleEvent?.openOfferConstraints,
    } as ScheduleEvent;
  }

  if (scheduleEvent.eventType === "trade") {
    return {
      ...scheduleEvent,
      openTradeConstraints: scheduleEvent?.openOfferConstraints,
    } as ScheduleEvent;
  }

  return { ...scheduleEvent } as ScheduleEvent;
};

export const composeUsableOrderDisplay = (
  orderDisplay: DocumentData
): FirebaseOrderDisplayDoc => ({
  id: orderDisplay.id,
  businessId: orderDisplay.businessId,
  enabled: orderDisplay.enabled,
  deleted: orderDisplay.deleted,
  displayName: orderDisplay.displayName,
  allowAllRoutedSubTypes: orderDisplay.allowAllRoutedSubTypes,
  allowAllRoutedOrderTypes: orderDisplay.allowAllRoutedOrderTypes,
  allowAllRoutedTables: orderDisplay.allowAllRoutedTables,
  routedSubTypes: orderDisplay.routedSubTypes,
  routedOrderTypes: orderDisplay.routedOrderTypes,
  routedTables: orderDisplay.routedTables,
  createdAt: orderDisplay.createdAt.toDate(),
  updatedAt: orderDisplay.updatedAt.toDate(),
});

export const composeUsableServiceArea = (
  serviceArea: DocumentData
): FirebaseServiceAreaDoc => ({
  id: serviceArea.id,
  serviceAreaName: serviceArea.serviceAreaName,
  enabled: serviceArea.enabled,
  maxOccupancy: serviceArea.maxOccupancy,
  weatherDependent: serviceArea.weatherDependent,
  businessId: serviceArea.businessId,
  deleted: serviceArea.deleted,
  createdAt: serviceArea.createdAt.toDate(),
  updatedAt: serviceArea.updatedAt.toDate(),
});

export const composeUsableTable = (table: DocumentData): FirebaseTableDoc => ({
  businessId: table.businessId,
  minCapacity: table.minCapacity || 0,
  maxCapacity: table.maxCapacity || 0,
  createdAt: table.createdAt.toDate(),
  deleted: table.deleted,
  description: table.description,
  enabled: table.enabled,
  id: table.id,
  notes: table.notes,
  qrCodeImageUrl: table.qrCodeImageUrl,
  tableNumber: table.tableNumber,
  updatedAt: table.updatedAt.toDate(),
  serviceArea: table.serviceArea || null,
});

export const compuseUsableCashDrawer = (
  cashDrawer: DocumentData
): FirebaseCashDrawerDoc => ({
  businessId: cashDrawer.businessId,
  cashDrawerName: cashDrawer.cashDrawerName,
  closeType: cashDrawer.closeType,
  createdAt: cashDrawer.createdAt.toDate(),
  deleted: cashDrawer.deleted,
  enabled: cashDrawer.enabled,
  id: cashDrawer.id,
  otherStaffWithAccess: cashDrawer.otherStaffWithAccess,
  primaryStaff: cashDrawer.primaryStaff,
  startingCash: cashDrawer.startingCash,
  printerId: cashDrawer.printerId,
  updatedAt: cashDrawer.updatedAt.toDate(),
});

export const composeUsableOrder = (order: DocumentData): FirebaseOrderDoc => ({
  DSP: order.DSP,
  amount: order.amount,
  businessId: order.businessId,
  cancelled: order.cancelled,
  completed: order.completed,
  createdAt: order.createdAt.toDate(),
  deleted: order.deleted,
  deliverect: order.deliverect,
  discountsAppliedToOrder: order.discountsAppliedToOrder,
  drawerId: order.drawerId,
  id: order.id,
  isPrinted: order.isPrinted,
  orderChannel: order.orderChannel,
  orderNumber: order.orderNumber,
  orderType: order.orderType,
  pickupTime: order.pickupTime,
  products: order.products,
  renderOrderInQueue: order.renderOrderInQueue,
  specialInstructions: order.specialInstructions,
  staffId: order.staffId,
  status: order.status,
  tabId: order.tabId,
  updatedAt: order.updatedAt.toDate(),
});

export const composeUsableTab = (tab: DocumentData): FirebaseTabDoc => ({
  id: tab.id,
  businessId: tab.businessId,
  isExplicitTab: tab.isExplicitTab,
  name: tab.name,
  deleted: tab.deleted,
  staffId: tab.staffId,
  createdAt: tab.createdAt,
  updatedAt: tab.updatedAt,
  tableId: tab.tableId,
  tableNumber: tab.tableNumber,
  customer: tab.customer,
  status: tab.status,
  paymentComplete: tab.paymentComplete,
  refundPayload: tab.refundPayload,
  numberOfGuests: tab.numberOfGuests,
  allSplitTypes: tab.allSplitTypes || null,
});

export const composeUsableDiscount = (
  discount: DocumentData
): FirebaseDiscountDoc => ({
  id: discount.id,
  businessId: discount.businessId,
  nameInternal: discount.nameInternal,
  nameExternal: discount.nameExternal,
  createdAt: discount.createdAt.toDate(),
  updatedAt: discount.updatedAt.toDate(),
  enabled: discount.enabled,
  deleted: discount.deleted,
  oneTimeUse: discount.oneTimeUse,
  redeemedUsers: discount.redeemedUsers,
  discountType: discount.discountType,
  isScheduled: !!discount?.isScheduled,
  isRewardOrCoupon: !!discount?.isRewardOrCoupon,
  isCustomDiscount: !!discount?.isCustomDiscount,
  customDiscount: discount?.customDiscount,
  // TODO: Fix the toDate issue here
  hours: discount.hours.map((hours: ScheduledDiscount) => ({
    dateOrDay: hours.dateOrDay,
    day: hours.day,
    // @ts-ignore
    endDate: hours.endDate ? hours.endDate.toDate() : null,
    endTime: hours.endTime,
    // @ts-ignore
    startDate: hours.startDate ? hours.startDate.toDate() : null,
    startTime: hours.startTime,
  })),
  orderTypesToExclude: discount.orderTypesToExclude,
  datesToExclude: discount.datesToExclude,
  productsToExclude: discount.productsToExclude,
  orderItemsToExclude: discount.orderItemsToExclude,
  menusToExclude: discount.menusToExclude,
  menuCategoriesToExclude: discount.menuCategoriesToExclude,
  scheduledDiscount: discount.scheduledDiscount,
  rewardOrCouponDiscount: discount.rewardOrCouponDiscount,
});

export const composeUsableCashDrawerEvent = (
  cashEvent: DocumentData
): FirebaseCashEvents => ({
  id: cashEvent.id,
  businessId: cashEvent.businessId,
  cashDrawerId: cashEvent.cashDrawerId,
  enabled: cashEvent.enabled,
  deleted: cashEvent.deleted,
  createdAt: cashEvent.createdAt.toDate(),
  updatedAt: cashEvent.updatedAt.toDate(),
  description: cashEvent.description,
  event: cashEvent.event as
    | "payout"
    | "openDrawer"
    | "closeDrawer"
    | "order"
    | "refund",
  payload: cashEvent.payload,
});

export const composeUsableManualPayout = (
  payout: DocumentData
): ManualPayouts => {
  return {
    amount: payout.amount,
    businessDate: payout.businessDate
      ? moment(payout.businessDate.toDate()).subtract(1, "day").toDate()
      : payout.createdAt.toDate(),
    arrivalDate: payout.arrivalDate.toDate(),
    balanceTransaction: payout.balanceTransaction,
    businessId: payout.businessId,
    createdAt: payout.createdAt.toDate(),
    currency: payout.currency,
    deleted: payout.deleted,
    description: payout.description,
    destination: payout.destination,
    id: payout.id,
    method: payout.method,
    object: payout.object,
    originalPayout: payout.originalPayout,
    paymentObjectId: payout.paymentObjectId,
    reversedBy: payout.reversedBy,
    sourceType: payout.sourceType,
    statementDescriptor: payout.statementDescriptor,
    status: payout.status,
    type: payout.type,
    updatedAt: payout.updatedAt.toDate(),
    applicationFeeAmount: payout.applicationFeeAmount || 0,
    numberOfTransactions: payout.numberOfTransactions || 0,
    amountRefunded: payout.amountRefunded || 0,
    disputeTotal: payout.disputeTotal || 0,
  };
};

export const composeUsablePayoutLedgers = (
  payout: DocumentData
): PayoutLedger => {
  return {
    id: payout.id,
    createdAt: payout.createdAt.toDate(),
    updatedAt: payout.updatedAt.toDate(),
    deleted: payout.deleted,
    enabled: payout.enabled,
    businessId: payout.businessId,
    queryStartDate: payout.queryStartDate.toDate(),
    queryEndDate: payout.queryEndDate.toDate(),
    currentBalance: payout.currentBalance,
    currentBalanceCurrency: payout.currentBalanceCurrency,
    wasPayoutSuccessful: payout.wasPayoutSuccessful,
    completed: payout.completed,
    amountEarned: payout.amountEarned,
    applicationFeeAmount: payout.applicationFeeAmount,
    numberOfTransactions: payout.numberOfTransactions,
    amountRefunded: payout.amountRefunded,
    disputeTotal: payout.disputeTotal,
  };
};

export const composeUsablePrinters = (
  printer: DocumentData
): FirebasePrintersDoc => {
  return {
    id: printer.id,
    businessId: printer.businessId,
    macAddress: printer.macAddress,
    printerType: printer.printerType,
    name: printer.name,
    routedSubTypes: printer.routedSubTypes,
    routedOrderTypes: printer.routedOrderTypes,
    ticketType: printer.ticketType,
    serviceAreas: printer.serviceAreas,
    autoPrint: printer.autoPrint,
    allowAllRoutedSubTypes: printer.allowAllRoutedSubTypes,
    allowAllRoutedOrderTypes: printer.allowAllRoutedOrderTypes,
    enabled: printer.enabled,
    deleted: printer.deleted,
    qrIds: printer.qrIds,
    createdAt: printer.createdAt.toDate(),
    updatedAt: printer.updatedAt.toDate(),
  };
};

export const composeUsableCashEvent = (
  rawCashEvent: DocumentData
): FirebaseCashEvents => {
  return {
    id: rawCashEvent?.id,
    businessId: rawCashEvent?.businessId,
    cashDrawerId: rawCashEvent?.cashDrawerId,
    enabled: rawCashEvent?.enabled,
    deleted: rawCashEvent?.deleted,
    createdAt: rawCashEvent?.createdAt,
    updatedAt: rawCashEvent?.updatedAt,
    event: rawCashEvent?.event,
    description: rawCashEvent?.description,
    payload: rawCashEvent?.payload,
  };
};
