import { FilesAndFoldersTypesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FilesAndFolders[] = [];

export default function docs(
  state = initialState,
  action: FilesAndFoldersTypesAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_DOCS:
      return action.docs;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
