import React, { useState } from 'react';
import { ClosedChecksProps } from '../ClosedChecks/useClosedChecks';
import { FormattedPayment } from '../Payments';

const useDetails = () => {
    const [showRefundPaymentModal, setShowRefundPaymentModal] = useState(false);
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState(false);
    const [selectedPaymentToRefund, setSelectedPaymentToRefund] = useState<FormattedPayment>();
    const [updatedCheck, setUpdateCheck] = useState<ClosedChecksProps | null>(null);
    const [refundSuccess, setRefundSuccess] = useState(false);
    const [showBackButton, setShowBackButton] = useState(true);
    const [loading, setLoading] = useState(false);

    return {
        showRefundPaymentModal,
        setShowRefundPaymentModal,
        showCapturePaymentModal,
        setShowCapturePaymentModal,
        setSelectedPaymentToRefund,
        selectedPaymentToRefund,
        setUpdateCheck,
        updatedCheck,
        setRefundSuccess,
        refundSuccess,
        setShowBackButton,
        showBackButton,
        setLoading,
        loading
    }
}
export default useDetails;