import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import TangoImage from "assets/manager/tango.png";

import "./style.css";

const SelectBusinessType = () => {
  const navigate = useNavigate();

  return (
    <Box className="manager-sign-up select-business-type">
      <Box className="header" display="flex" justifyContent="space-between">
        <img src={TangoImage} alt="tango" />
      </Box>
      <Box className="sign-up-form">
        <div className="form-title">Let’s take care of the basics</div>
        <div className="form-subtitle">Some basic info to get started</div>
        <div className="option-title">
          How many businesses are you setting up on Tango?
        </div>
        <Box
          className="select-boxes"
          display="flex"
          justifyContent="space-between"
        >
          <div onClick={() => navigate("/onboarding")}>One</div>
          <div onClick={() => navigate("/onboarding")}>Multiple</div>
        </Box>
      </Box>
    </Box>
  );
};
// eslint-disable-next-line import/no-default-export
export default SelectBusinessType;
