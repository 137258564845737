import { Button, Icon } from "@blueprintjs/core";
import React, { FC, useState } from "react";

import Box from "components/Box";

import SpeedFlexForm from "./components/SpeedFlexForm";
import BankDetails from "./components/SpeedFlexForm/BankDetails";
import HouseKeeping from "./components/SpeedFlexForm/HouseKeeping";
import UnderWriting from "./components/SpeedFlexForm/UnderWriting";
import StaffingForm from "./components/StaffingForm";
import "./style.css";

type TangoApps = "speed" | "flex" | "staffing" | null;

const GetStarted: FC = () => {
  const [selectedApp, setSelectedApp] = useState<TangoApps>(null);
  const [showStaffingForm, setShowStaffingForm] = useState<boolean>(false);
  const [isDivHiding, setIsDivHiding] = useState<boolean>(false);
  const [showSpeedForm, setShowSpeedForm] = useState<boolean>(false);
  const [showHouseKeeping, setShowHouseKeeping] = useState<boolean>(false);
  const [showUnderWriting, setShowUnderWriting] = useState<boolean>(false);
  const [showBankDetails, setShowBankDetails] = useState<boolean>(false);
  if (showBankDetails) {
    return (
      <Box className="onboarding-signup">
        <Box className="bg-image">
          <BankDetails onNext={() => console.log("end")} />
        </Box>
      </Box>
    );
  }
  if (showUnderWriting) {
    return (
      <Box className="onboarding-signup">
        <Box className="bg-image">
          <UnderWriting onNext={() => setShowBankDetails(true)} />
        </Box>
      </Box>
    );
  }
  if (showHouseKeeping) {
    return (
      <Box className="onboarding-signup">
        <Box className="bg-image">
          <HouseKeeping onNext={() => setShowUnderWriting(true)} />
        </Box>
      </Box>
    );
  }
  if (showStaffingForm) {
    return (
      <Box className="onboarding-signup">
        <Box className="bg-image">
          <StaffingForm />
        </Box>
      </Box>
    );
  }
  if (showSpeedForm) {
    return (
      <Box className="onboarding-signup">
        <Box className="bg-image">
          <SpeedFlexForm onNext={() => setShowHouseKeeping(true)} />
        </Box>
      </Box>
    );
  }
  return (
    <Box className="onboarding-signup">
      <Box className="bg-image">
        {/* <StaffingOnBoarding/> */}
        {/* <SpeedFlexForm /> */}
        {/* <HouseKeeping/> */}
        {/* <BankDetails/> */}

        <Box className={`form-container ${isDivHiding ? "fadeOut" : ""}`}>
          <div className="welcome-title">
            Welcome to Tango, Alan! Let’s get started.
          </div>
          <div className="select-tango-products-text">
            Select the Tango products you’re starting with.
          </div>
          <div className="select-tang-products-subtitle">
            Don’t worry you can always add more later.
          </div>
          <Box
            className="tango-apps-container"
            display="flex"
            flexDirection="row"
            style={{ gap: "42px" }}
          >
            <Box
              className={`tango-app speed-app ${
                selectedApp === "speed" ? "active" : ""
              }`}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => setSelectedApp("speed")}
            >
              <img
                src={require("assets/tango-icon.svg").default}
                alt="speed app"
              />
              <div>Speed</div>
              {selectedApp === "speed" && (
                <Box className="check-container">
                  <Icon icon="small-tick" color="#fff" />
                </Box>
              )}
            </Box>
            <Box
              className={`tango-app flex-app ${
                selectedApp === "flex" ? "active" : ""
              }`}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => setSelectedApp("flex")}
            >
              <img
                src={require("assets/tango-icon.svg").default}
                alt="flex app"
              />
              <div>Flex</div>
              {selectedApp === "flex" && (
                <Box className="check-container">
                  <Icon icon="small-tick" color="#fff" />
                </Box>
              )}
            </Box>
            <Box
              className={`tango-app staffing-app ${
                selectedApp === "staffing" ? "active" : ""
              }`}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => setSelectedApp("staffing")}
            >
              <img
                src={require("assets/tango-icon.svg").default}
                alt="staffing app"
              />
              <div>Staffing</div>
              {selectedApp === "staffing" && (
                <Box className="check-container">
                  <Icon icon="small-tick" color="#fff" />
                </Box>
              )}
            </Box>
          </Box>
          <Button
            text="Next"
            fill
            className="next-button"
            onClick={() => {
              if (selectedApp === "staffing") {
                setIsDivHiding(true);
                setShowStaffingForm(true);
              }
              if (selectedApp === "speed" || selectedApp === "flex") {
                setShowSpeedForm(true);
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GetStarted;
