import { ApexOptions } from "apexcharts";
import React, { useMemo } from "react";
import Chart from "react-apexcharts";

import "./style.css";

type Props = {
  actual: number[];
  projected: number[];
};

const Graph = ({ actual: actualRaw, projected: projectedRaw }: Props) => {
  const actual = useMemo(
    () => actualRaw.map((a) => Math.floor(a / 100)),
    [actualRaw]
  );
  const projected = useMemo(
    () => projectedRaw.map((p) => Math.floor(p / 100)),
    [projectedRaw]
  );
  const length = actual.length;
  const options = useMemo(
    (): ApexOptions => ({
      chart: {
        id: "actual-vs-projected",
        toolbar: {
          show: false,
        },
      },
      title: {
        text: "Actual vs Projected Sales",
        style: {
          fontFamily: "Lato-black",
          fontSize: "20px",
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        type: "numeric",
        labels: {
          show: true,
          formatter: (value) => {
            return ["M", "T", "W", "T", "F", "S", "S"][parseInt(value)];
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        min: 0,
        max: 6,
        tickAmount: 6,
      },
      tooltip: {
        enabled: false,
      },
      yaxis: {
        show: true,
        showAlways: false,
      },
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: length - 1,
            fillColor: "#A73C87",
            strokeColor: "white",
            size: 4,
          },
          {
            seriesIndex: 1,
            dataPointIndex: length - 1,
            fillColor: "#F5B073",
            strokeColor: "white",
            size: 4,
          },
        ],
      },
      legend: {
        show: true,
        position: "bottom",
        floating: false,
        horizontalAlign: "right",
        markers: {
          fillColors: ["#7F3E84", "#FF9B72"],
        },
      },
      stroke: {
        width: 1,
        curve: "smooth" as const,
        colors: ["#A73C87", "#F5B274"],
      },
      fill: {
        colors: ["#CB55A8", "#F4A164"],
      },
      dataLabels: {
        enabled: false,
      },
    }),
    [length]
  );
  const series = useMemo(
    () => [
      {
        name: "Actual Sales",
        data: actual.map((val, idx) => ({
          x: idx,
          y: val,
        })),
      },
      {
        name: "Projected Sales",
        data: projected.map((val, idx) => ({
          x: idx,
          y: val,
        })),
      },
    ],
    [actual, projected]
  );
  return (
    <div className="avp-chart-card">
      <Chart
        dataLabels={options.dataLabels}
        options={options}
        series={series}
        type="area"
      />
    </div>
  );
};

export default Graph;
