import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { RAW_ITEM_KEY, PREP_ITEM_KEY, VENDOR_ITEM_KEY, RAW_MENU_USAGE_KEY, RAW_PREP_USAGE_KEY, PREP_MENU_USAGE_KEY } from '../constants';

export enum TimeUnit {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
}

type AllergenDTO = {
  name: string;
  id: string;
};

export type UnitQuantityDTO = {
  unit: string;
  count: number;
  multiplier: number;
};

type LocationDTO = {
  name: string;
  id: string;
};

type SecondaryGroupDTO = {
  name: string;
  id: string;
};

type PrimaryGroupDTO = {
  name: string;
  id: string;
  secondaryGroups: SecondaryGroupDTO[];
};

type GenericItem = {
  id: string;
  name: string;
  productionUnitName: string;
  ingredientUnitName: string;
  ingredientsPerProduct: number;
  location: LocationDTO;
  primaryGroup: PrimaryGroupDTO;
  secondaryGroup: SecondaryGroupDTO;
  allergens: AllergenDTO[];
  isKey: boolean;
  isActualized: boolean;
  shelfLifeHours: number;
  shelfLifeUnits: "hours" | "days" | "weeks";
  defaultCost: number;
  minPar: number;
  maxPar: number;
  lastCountTime: Date;
  lastCountAmount: UnitQuantityDTO[];
  theoreticalStock: UnitQuantityDTO[];
}

export type RawItemDTO = GenericItem;

export type PrepItemDTO = GenericItem & {
  recipe: string;
  ingredients: IngredientDTO[];
  yield: number;
};

export type CombinedItem = (PrepItemDTO & { isRaw: false }) | (RawItemDTO & { isRaw: true });

type CreateRawItemDTO = {
  name: string;
  productionUnitName: string;
  ingredientUnitName: string;
  ingredientsPerProduct: number;
  locationId: string;
  primaryGroupId: string;
  secondaryGroupId: string;
  allergenIds: string[];
  isKey: boolean; // default false
  isActualized: boolean; // default false
  shelfLifeHours: number;
  shelfLifeUnits: string;
  minPar: number;
  maxPar: number;
};

export type UpdateRawItemDTO = {
  // itemName: string;
  name?: string;
  productionUnitName?: string;
  ingredientsPerProduct?: number;
  locationId?: string;
  primaryGroupId?: string;
  secondaryGroupId?: string;
  allergenIds?: string[];
  isKey?: boolean;
  isActualized?: boolean;
  shelfLifeHours?: number;
  shelfLifeUnits?: string;
  minPar?: number;
  maxPar?: number;
};

type CreatePrepItemDTO = {
  name: string;
  productionUnitName: string;
  ingredientUnitName: string;
  ingredientsPerProduct: number;
  locationId: string;
  primaryGroupId: string;
  secondaryGroupId: string;
  allergenIds: string[];
  isKey: boolean; // default false
  isActualized: boolean; // default false
  shelfLifeHours: number;
  shelfLifeUnits: string;
  minPar: number;
  maxPar: number;
  ingredients: IngredientDTO[];
  recipe: string;
};

export type UpdatePrepItemDTO = {
  name?: string;
  productionUnitName?: string;
  ingredientUnitName?: string;
  ingredientsPerProduct?: number;
  locationId?: string;
  primaryGroupId?: string;
  secondaryGroupId?: string;
  allergenIds?: string[];
  isKey?: boolean; // default false
  isActualized?: boolean; // default false
  shelfLifeHours?: number;
  shelfLifeUnits?: string;
  yield?: number;
  minPar?: number;
  maxPar?: number;
  ingredients?: IngredientDTO[];
  recipe?: string;
};

type IngredientDTO = {
  id: string;
  isRaw: boolean;
  amount: number;
  name: string;
  unitName: string;
};

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

// API CALLS

const fetchRawItems = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<RawItemDTO[]>(`${businessId}/rawItems`);
  return r.data;
};

const fetchPrepItems = async (businessId: string, includeIngredients: boolean) => {
  if (!businessId) return null;
  const r = await apiClient.get<PrepItemDTO[]>(`${businessId}/prepItems`, {
    params: {includeIngredients},
  });
  return r.data;
};

const fetchVendorForItem = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get(`${businessId}/vendors?itemIds=${itemId}`);
  return r.data;
}

const fetchRawItem = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<RawItemDTO>(`${businessId}/rawItems/${itemId}`);
  return r.data;
};

const fetchPrepItem = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<PrepItemDTO>(`${businessId}/prepItems/${itemId}`);
  return r.data;
};

const createRawItem = async (businessId: string, newItem: CreateRawItemDTO) => {
  if (!businessId) return null;
  const r = await apiClient.post<RawItemDTO>(`${businessId}/rawItems`, newItem);
  return r.data;
};

const createPrepItem = async (businessId: string, newItem: CreatePrepItemDTO) => {
  if (!businessId) return null;
  const r = await apiClient.post<PrepItemDTO>(`${businessId}/prepItems`, newItem);
  return r.data;
};

const updateRawItem = async (businessId: string, itemId: string, updatedItem: UpdateRawItemDTO) => {
  if (!businessId) return null;
  const r = await apiClient.put<RawItemDTO>(`${businessId}/rawItems/${itemId}`, updatedItem);
  return r.data;
};

const updatePrepItem = async (businessId: string, itemId: string, updatedItem: UpdatePrepItemDTO) => {
  if (!businessId) return null;
  const r = await apiClient.put<PrepItemDTO>(`${businessId}/prepItems/${itemId}`, updatedItem);
  return r.data;
};

const preparePrepItem = async (businessId: string, itemId: string, amount: number) => {
if (!businessId) return null;
  const r = await apiClient.patch<PrepItemDTO>(`${businessId}/prepItems/${itemId}/prepare`, {
    count: amount
  });
  return r.data;
};

const deleteRawItem = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.delete(`${businessId}/rawItems/${itemId}`);
  if (r.status !== 200) throw new Error(r.statusText);
  return true;
};

const deletePrepItem = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.delete(`${businessId}/prepItems/${itemId}`);
  if (r.status !== 200) throw new Error(r.statusText);
  return true;
};

type Ingredient = {id: string; isRaw: boolean; amount: number};
const setPrepIngredients = async (
  businessId: string,
  itemId: string,
  ingredients: Ingredient[],
  ) => {
  if (!businessId) return null;
  const r = await apiClient.put<PrepItemDTO>(
    `${businessId}/prepItems/${
      itemId
    }`, {
      ingredients
    },
  );
  return r.data;
};

const fetchRawItemMenuUsage = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get(`${businessId}/menuItems/usingRawItem/${itemId}`);
  return r.data;
};
const fetchRawItemPrepUsage = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get(`${businessId}/prepItems/usingRawItem/${itemId}`);
  return r.data;
};
const fetchPrepItemUsage = async (businessId: string, itemId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get(`${businessId}/menuItems/usingPrepItem/${itemId}`);
  return r.data;
};

// HOOKS


export const useRawItemsInfo = (businessId: string) => {
  return useQuery([RAW_ITEM_KEY, businessId], () => fetchRawItems(businessId));
};
export const usePrepItemsInfo = (businessId: string, includeIngredients: boolean) => {
  return useQuery([PREP_ITEM_KEY, businessId], () => fetchPrepItems(businessId, includeIngredients));
};

export const useCreateRawItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (newItem: CreateRawItemDTO) => createRawItem(businessId, newItem),
    onSuccess: () => client.invalidateQueries([RAW_ITEM_KEY, businessId]),
  });
};
export const useCreatePrepItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (newItem: CreatePrepItemDTO) => createPrepItem(businessId, newItem),
    onSuccess: () => client.invalidateQueries([PREP_ITEM_KEY, businessId]),
  });
};
export const useUpdateRawItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { itemId: string; updatedItem: UpdateRawItemDTO }) =>
      updateRawItem(businessId, args.itemId, args.updatedItem),
      onSuccess: (data, {itemId}) => {
        client.invalidateQueries([RAW_ITEM_KEY, businessId]);
        client.setQueryData([RAW_ITEM_KEY, businessId, itemId], data);
      }
  });
};
export const useUpdatePrepItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { itemId: string; updatedItem: UpdatePrepItemDTO }) =>
      updatePrepItem(businessId, args.itemId, args.updatedItem),
    onSuccess: (data, {itemId}) => {
      client.invalidateQueries([PREP_ITEM_KEY, businessId]);
      client.setQueryData([PREP_ITEM_KEY, businessId, itemId], data);
    }
  });
};
export const usePreparePrepItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: { itemId: string; amount: number }) =>
      preparePrepItem(businessId, args.itemId, args.amount),
    onSuccess: (data, {itemId}) => {
      client.invalidateQueries([PREP_ITEM_KEY, businessId]);
      client.setQueryData([PREP_ITEM_KEY, businessId, itemId], data);
    }
  });
};
export const useDeleteRawItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (itemId: string) => deleteRawItem(businessId, itemId),
      onSuccess: (success) => {
        if (success) client.invalidateQueries([RAW_ITEM_KEY, businessId]);
      }
  });
};
export const useDeletePrepItem = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (itemId: string) => deletePrepItem(businessId, itemId),
    onSuccess: (success) => {
      if (success) client.invalidateQueries([PREP_ITEM_KEY, businessId]);
    }
  });
};
export const useSetPrepIngredients = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (args: {
      itemId: string;
      newIngredients: Ingredient[];
    }) =>
      setPrepIngredients(businessId, args.itemId, args.newIngredients),
    onSuccess: (data, {itemId}) => {
      client.invalidateQueries([PREP_ITEM_KEY, businessId]);
      client.setQueryData([PREP_ITEM_KEY, businessId, itemId], data);
    }
  });
};

export const useVendorInfoForItem = (businessId: string, itemId: string) => {
  return useQuery([VENDOR_ITEM_KEY, businessId, itemId], () => fetchVendorForItem(businessId, itemId));
}
export const useRawItemInfo = (businessId: string, itemId: string) => {
  return useQuery([RAW_ITEM_KEY, businessId, itemId], () =>
    fetchRawItem(businessId, itemId)
  );
}
export const usePrepItemInfo = (businessId: string, itemId: string) => {
  return useQuery([PREP_ITEM_KEY, businessId, itemId], () =>
    fetchPrepItem(businessId, itemId)
  );
}
export const useItemInfo = (businessId: string, itemId: string, isRaw: boolean) => {
  if (isRaw) {
    return useRawItemInfo(businessId, itemId);
  } else {
    return usePrepItemInfo(businessId, itemId);
  }
}
export const usePrepItem = (businessId: string, itemId: string) => {
  return useQuery([PREP_ITEM_KEY, businessId, itemId], () =>
    fetchPrepItem(businessId, itemId)
  );
}
export const useRawItem = (businessId: string, itemId: string) => {
  return useQuery([RAW_ITEM_KEY, businessId, itemId], () =>
    fetchRawItem(businessId, itemId)
  );
}
export const useAllItemsInfo = (businessId: string, includeIngredients=false) => {
  const raw = useRawItemsInfo(businessId);
  const prep = usePrepItemsInfo(businessId, includeIngredients);
  return useMemo(() => {
    if (raw.isLoading || prep.isLoading) return { loading: true, data: [] };
    const rawData = (raw.data ?? []).map((i) => ({
      ...i,
      isRaw: true,
    }));
    const prepData = (prep.data ?? []).map((i) => ({
      ...i,
      isRaw: false,
    }));
    return {
      loading: false,
      data: [...rawData, ...prepData] as CombinedItem[],
    };
  }, [raw.isLoading, prep.isLoading, raw.data, prep.data]);
};
const useRawItemInMenus = (businessId: string, itemId: string) => {
  return useQuery([RAW_MENU_USAGE_KEY, businessId, itemId], () =>
    fetchRawItemMenuUsage(businessId, itemId)
  );
}
const useRawItemInPrep = (businessId: string, itemId: string) => {
  return useQuery([RAW_PREP_USAGE_KEY, businessId, itemId], () =>
  fetchRawItemPrepUsage(businessId, itemId)
  );
}

export const useRawItemUsage = (businessId: string, itemId: string) => {
  const prep = useRawItemInPrep(businessId, itemId);
  const menu = useRawItemInMenus(businessId, itemId);
  return useMemo(() => {
    if (menu.isLoading || prep.isLoading) return { loading: true, data: [] };
    const menuData = (menu.data ?? []);
    const prepData = (prep.data ?? []);
    return {
      loading: false,
      data: [...menuData, ...prepData],
    };
  }, [menu.isLoading, prep.isLoading, menu.data, prep.data]);
};
export const usePrepItemUsage = (businessId: string, itemId: string) => {
  return useQuery([PREP_MENU_USAGE_KEY, businessId, itemId], () =>
    fetchPrepItemUsage(businessId, itemId)
  );
}

export const useItemUsage = (businessId: string, itemId: string, isRaw: boolean) => {
  if (isRaw) {
    return useRawItemUsage(businessId, itemId);
  } else {
    return usePrepItemUsage(businessId, itemId);
  }
}


