import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Table from "components/Table/Table";
import { OptionType, TableError } from "components/Table/tableTypes";

import { performSoftDelete, saveData } from "models/serviceAreas";

import { FirebaseServiceAreaDoc, ServiceAreaDoc } from "types/serviceArea";

import { RootState } from "model/store";

const ServiceAreas = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const serviceAreas = useSelector((state: RootState) => state.serviceAreas);
  const [data, setData] = useState<ServiceAreaDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<ServiceAreaDoc[]>([]);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "serviceAreaName",
    errors: [],
  });

  const columnHeadingConversions = {
    serviceAreaName: "Service Area Name",
    enabled: "Enabled",
    maxOccupancy: "Max Occupancy",
    weatherDependent: "Weather Dependent?",
  };

  const tableSchema = [
    { key: "serviceAreaName", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "maxOccupancy", type: "value", data: "text" },
    { key: "weatherDependent", type: "value", data: "text" },
  ];

  const dataSchema = [
    ...tableSchema,
    { key: "id", type: "value", data: "text" },
  ];

  const [options, setOptions] = useState<OptionType[]>([
    {
      key: "id",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isPrimaryId: true,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
    {
      key: "weatherDependent",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      isPrimaryId: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
    },
  ]);

  const isInt = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

  const filterData = (data: ServiceAreaDoc[]) =>
    data.map((row) => ({
      serviceAreaName: row.serviceAreaName,
      enabled: row.enabled,
      maxOccupancy: row.maxOccupancy,
      weatherDependent: row.weatherDependent,
    }));

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      serviceAreaName: row.serviceAreaName,
      index,
    }));

    // Filter for duplicates
    const productDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({
            serviceAreaName: row.serviceAreaName,
          })),
          { serviceAreaName: item.serviceAreaName }
        ) !== index
    );

    // Find the duplicate without the id
    const errors: DocumentData[] = [];
    for (let i = 0; i < productDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) =>
          row.serviceAreaName === productDuplicateErrors[i].serviceAreaName
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !(row.enabled === "Yes" || row.enabled === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong occupancy
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) =>
          row.maxOccupancy ? !isInt(row.maxOccupancy) : false
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "maxOccupancy", isEdited: false }],
          isEdited: false,
        })),
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !(row.weatherDependent === "Yes" || row.weatherDependent === "No")
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "weatherDependent", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No service area name
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) => !row.serviceAreaName || row.serviceAreaName.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "serviceAreaName", isEdited: false }],
          isEdited: false,
        })),
      // No enabled
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter((row: any) => !row.enabled || row.enabled.length === 0)
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // No max occupancy
      // ...data
      //     .map((row, index) => ({ ...row, index }))
      //     .filter((row: any) => !row.maxOccupancy || row.maxOccupancy.length === 0)
      //     .map((row) => ({
      //         errorType: 'missingCells',
      //         rowIndex: row.index,
      //         columnNames: [{ columnName: 'maxOccupancy', isEdited: false }],
      //         isEdited: false,
      //     })),
      // No weather dependent
      ...data
        .map((row, index) => ({ ...row, index }))
        .filter(
          (row: any) =>
            !row.weatherDependent || row.weatherDependent.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "weatherDependent", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(data);
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal,
        // @ts-ignore
        errors,
      });
      return true;
      // All errors have been resolved
    }
    setErrorManager({
      ...errorManager,
      showErrorModal: false,
      errors: [],
    });

    return false;
  };

  const saveToFirebase = async () => {
    if (business.id) {
      await performSoftDelete(softDeleteData);
      await saveData(
        data,
        transformFirebaseServiceAreasToServiceAreas(serviceAreas),
        business.id
      );
      return true;
    }
  };

  const transformFirebaseServiceAreasToServiceAreas = (
    serviceAreas: FirebaseServiceAreaDoc[]
  ): ServiceAreaDoc[] => {
    return serviceAreas.map((doc) => {
      return {
        id: doc.id,
        serviceAreaName: doc.serviceAreaName,
        enabled: doc.enabled ? "Yes" : "No",
        maxOccupancy: doc.maxOccupancy ? String(doc.maxOccupancy) : "",
        weatherDependent: doc.weatherDependent ? "Yes" : "No",
      };
    });
  };

  useEffect(() => {
    if (serviceAreas.length > 0 && business) {
      const allServiceAreas =
        transformFirebaseServiceAreasToServiceAreas(serviceAreas);
      setData(allServiceAreas);
    }
  }, [serviceAreas, business]);

  if (business) {
    return (
      <div id="service-area-display">
        <Table
          // @ts-ignore
          businessId={business.id}
          name="ServiceArea"
          parentName="TableManagement"
          allData={data}
          setAllData={setData}
          softDelete={softDeleteData}
          setSoftDelete={setSoftDeleteData}
          allowSoftDelete
          filterData={filterData}
          tableSchema={tableSchema}
          dataSchema={dataSchema}
          options={options}
          setOptions={setOptions}
          hasModal={false}
          ModalComponent={null}
          errorManager={errorManager}
          setErrorManager={setErrorManager}
          closeErrorManager={() => {
            setErrorManager({
              ...errorManager,
              showErrorModal: false,
            });
          }}
          verifySave={verifyData}
          saveToFirebase={saveToFirebase}
          hasSearch={false}
          searchColumnName="serviceAreaName"
          hasFilter={false}
          columnHeadingConversions={columnHeadingConversions}
        />
      </div>
    );
  }
  return null;
};

export default ServiceAreas;
