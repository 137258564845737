import { FellowStaffMembersAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: StaffMember[] = [];

export default function fellowStaffMembers(
  state = initialState,
  action: FellowStaffMembersAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_FELLOW_STAFF_MEMBERS:
      return action.fellowStaffMembers;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
