import _ from "lodash";
import mime from "mime";
import { SearchableBusinessOption } from "views/Cloud/ShareModal";

import { apiErrorHandler } from "controllers/core";
import { BASE_TANGO_STORAGE_FOLDER } from "controllers/tangoStorage";

import firebase from "../config/firebase";
import { storage } from "../config/firebase";

const db = firebase.firestore();
const docsCollection = db.collection("Docs");
const enterpriseDocsCollection = db.collection("EnterpriseDocs");
const businessSettingsCollection = db.collection("BusinessSettings");

const getDoc = async (businessId: string, accountId = "", parentId = "") => {
  return parentId
    ? await enterpriseDocsCollection.doc(parentId).get()
    : accountId
    ? await enterpriseDocsCollection.doc(accountId).get()
    : await docsCollection.doc(businessId).get();
};

const createFirstParentDoc = (
  businessId: string,
  accountId = "",
  parentId = ""
) => {
  return parentId
    ? enterpriseDocsCollection.doc(parentId)
    : accountId
    ? enterpriseDocsCollection.doc(accountId)
    : docsCollection.doc(businessId);
};

const createDoc = (businessId: string, accountId = "", parentId = "") => {
  return parentId
    ? enterpriseDocsCollection.doc(parentId).collection("FilesAndFolders").doc()
    : accountId
    ? enterpriseDocsCollection
        .doc(accountId)
        .collection("FilesAndFolders")
        .doc()
    : docsCollection.doc(businessId).collection("FilesAndFolders").doc();
};

const getDocReference = (
  docId: string,
  businessId: string,
  accountId = "",
  parentId = ""
) => {
  return parentId
    ? enterpriseDocsCollection
        .doc(parentId)
        .collection("FilesAndFolders")
        .doc(docId)
    : accountId
    ? enterpriseDocsCollection
        .doc(accountId)
        .collection("FilesAndFolders")
        .doc(docId)
    : docsCollection.doc(businessId).collection("FilesAndFolders").doc(docId);
};

export const generateFirstDocsDocument = async (
  businessId: string,
  accountId = "",
  parentId = ""
) => {
  try {
    // All the docs will be saved in the parent ID
    const doc = await getDoc(businessId, accountId, parentId);
    // If no document exists then create a document with 1 subcollection
    // which is the root directory for Docs
    if (!doc.exists) {
      const mainDocRef = createFirstParentDoc(businessId, accountId, parentId);
      const mainDoc = {
        id: businessId,
        businessId: businessId,
        createdAt: new Date(),
        updatedAt: new Date(),
        deleted: false,
      };
      await mainDocRef.set(mainDoc);

      const rootDocRef = createDoc(businessId, accountId, parentId);
      const rootDoc: FilesAndFolders = {
        id: "",
        parentAccountId: parentId,
        accountId: accountId,
        businessId: businessId,
        createdAt: new Date(),
        updatedAt: new Date(),
        deleted: false,
        parentId: "",
        childrenIds: [],
        absolutePath: "",
        downloadUrl: null,
        isFolder: true,
        deletedAt: null,
        deletedTemporarily: false,
        fileSize: 0,
        fileExtension: null,
        name: "root",
        starred: false,
        description: "",
        authorId: { staffId: "", accessType: "editor" as DocAccessType },
        access: [],
        tags: [],
        fileID: "",
        businessAccess: accountId ? (businessId ? [businessId] : []) : [],
      };
      rootDoc.id = rootDocRef.id;
      rootDoc.parentId = rootDocRef.id;
      await rootDocRef.set(rootDoc);
    }
  } catch (err) {
    console.log("ERR: " + err);
  }
};

export const getAllParentAccess = (
  folder: FilesAndFolders,
  allFiles: FilesAndFolders[],
  users: DocUser[]
): DocUser[] => {
  const currentPermissions = users;
  const additionalPermissions = folder.access;
  const combinedPermissions = [...users, ...additionalPermissions];
  const combinedUniquePermissions = _.uniqBy(
    combinedPermissions,
    (d) => d.staffId
  );
  users = combinedUniquePermissions;
  const nextFolder = folder.parentId
    ? allFiles.find((file) => file.id === folder.parentId)
    : null;

  console.log("nextFolder", nextFolder);

  if (folder.absolutePath !== "/root" && nextFolder) {
    return getAllParentAccess(nextFolder, allFiles, users);
  }
  return users;
};

export const getAllFilesAndFoldersForFolder = (
  folder: FilesAndFolders,
  allFiles: FilesAndFolders[],
  current: FilesAndFolders[]
): FilesAndFolders[] => {
  for (const id of folder.childrenIds) {
    const index = allFiles.findIndex((f) => f.id === id);
    if (index !== -1) {
      const fileFound = allFiles[index];
      current.push(fileFound);

      if (fileFound.childrenIds.length > 0) {
        return getAllFilesAndFoldersForFolder(fileFound, allFiles, current);
      }
    }
  }
  return current;
};

export const revokeAccessForFolderWaterfall = async (
  folder: FilesAndFolders,
  selectedUser: DocUser,
  allFilesAndFolders: FilesAndFolders[]
) => {
  await getDocReference(
    folder.id,
    folder.businessId,
    folder.accountId || "",
    folder.parentAccountId || ""
  ).update({
    access: folder.access.filter(
      (staff) => staff.staffId !== selectedUser.staffId
    ),
  });
  const allChildren = getAllFilesAndFoldersForFolder(
    folder,
    allFilesAndFolders,
    []
  );

  console.log("revokeAccessForFolderWaterfall", revokeAccessForFolderWaterfall);

  await Promise.all(
    allChildren.map(async (child) => {
      await getDocReference(
        child.id,
        child.businessId,
        child.accountId || "",
        child.parentAccountId || ""
      ).update({
        access: child.access.filter(
          (staff) => staff.staffId !== selectedUser.staffId
        ),
      });
    })
  );
};

export const revokeAccessForFolderWaterfallMultipleUsers = async (
  folder: FilesAndFolders,
  usersToRemove: StaffMember[],
  allFilesAndFolders: FilesAndFolders[]
) => {
  const staffIds = usersToRemove.map((i) => i.staffMemberCopyId);
  await getDocReference(
    folder.id,
    folder.businessId,
    folder.accountId || "",
    folder.parentAccountId || ""
  ).update({
    access: _.uniq([
      ...folder.access.filter((staff) => !staffIds.includes(staff.staffId)),
    ]).filter((i) => !!i),
  });
  const allChildren = getAllFilesAndFoldersForFolder(
    folder,
    allFilesAndFolders,
    []
  );

  await Promise.all(
    allChildren.map(async (child) => {
      await getDocReference(
        child.id,
        child.businessId,
        child.accountId || "",
        child.parentAccountId || ""
      ).update({
        access: _.uniq([
          ...child.access.filter((staff) => !staffIds.includes(staff.staffId)),
        ]).filter((i) => !!i),
      });
    })
  );
};

export const revokeAccessForFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  usersToRemove: StaffMember[]
) => {
  const staffIds = usersToRemove.map((i) => i.staffMemberCopyId);
  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    access: _.uniq([
      ...fileOrFolder.access.filter(
        (staff) => !staffIds.includes(staff.staffId)
      ),
    ]).filter((i) => !!i),
  });
};

export const updateFileInfo = async (
  fileOrFolder: FilesAndFolders,
  fileInfo: { name: string; description: string; tags: string[] }
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update(fileInfo);
};

export const revokeFileOrFolderFromStaff = async (
  fileOrFolder: FilesAndFolders,
  selectedUser: DocUser
) => {
  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    access: fileOrFolder.access.filter(
      (staff) => staff.staffId !== selectedUser.staffId
    ),
  });
};

export const revokeFileOrFolderFromBusiness = async (
  fileOrFolder: FilesAndFolders,
  selectedBusiness: TangoBusiness
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update({
    businessAccess: (fileOrFolder.businessAccess || []).filter(
      (businessId) => businessId !== selectedBusiness.id
    ),
  });
};

export const updateGeneralBusinessStaffAccessToFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  staffMember: StaffMember,
  newAccessLevel: DocAccessType,
  allChidren: FilesAndFolders[]
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update({
    businessLevelAccessOverrides: [
      ...(fileOrFolder.businessLevelAccessOverrides ?? []).filter(
        (bo) =>
          bo.staffId !== staffMember.staffMemberCopyId &&
          bo.businessId !== staffMember.businessId
      ),
      {
        staffId: staffMember.staffMemberCopyId,
        businessId: staffMember.businessId,
        accessType: newAccessLevel,
      },
    ],
  });
  if (fileOrFolder.isFolder) {
    await Promise.all(
      allChidren.map(async (child) => {
        await getDocReference(
          child.id,
          child.businessId,
          child.accountId || "",
          child.parentAccountId || ""
        ).update({
          businessLevelAccessOverrides: [
            ...(child.businessLevelAccessOverrides ?? []).filter(
              (bo) =>
                bo.staffId !== staffMember.staffMemberCopyId &&
                bo.businessId !== staffMember.businessId
            ),
            {
              staffId: staffMember.staffMemberCopyId,
              businessId: staffMember.businessId,
              accessType: newAccessLevel,
            },
          ],
        });
      })
    );
  }
};

export const updateUserAccessToFileOrFilder = async (
  fileOrFolder: FilesAndFolders,
  staffMember: StaffMember,
  newAccessLevel: DocAccessType,
  allChidren: FilesAndFolders[]
) => {
  const docRef = getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  );
  await docRef.update({
    access: [
      ...fileOrFolder.access.filter(
        (staff) => staff.staffId !== staffMember.staffMemberCopyId
      ),
      {
        staffId: staffMember.staffMemberCopyId,
        accessType: newAccessLevel,
      },
    ],
  });
  if (fileOrFolder.isFolder) {
    await Promise.all(
      allChidren.map(async (child) => {
        await getDocReference(
          child.id,
          child.businessId,
          child.accountId || "",
          child.parentAccountId || ""
        ).update({
          access: [
            ...child.access.filter(
              (staff) => staff.staffId !== staffMember.staffMemberCopyId
            ),
          ],
        });
      })
    );
  }
};

export const invitePeopleToFolderWithWaterfall = async (
  fileOrFolder: FilesAndFolders,
  usersToInvite: StaffMember[],
  businessesToInvite: SearchableBusinessOption[],
  allFilesAndFolders: FilesAndFolders[]
) => {
  if (fileOrFolder.isFolder) {
    console.log("businessesToInvite", businessesToInvite);
    const businessAccessesToAdd = _.groupBy(businessesToInvite, "businessId");
    console.log("usersToInvite", usersToInvite);
    console.log("businessAccessesToAdd", businessAccessesToAdd);
    const businessAccessesFilteredByPriority = _.keys(businessAccessesToAdd)
      .map((businessId) => {
        const value = businessAccessesToAdd[businessId];
        if (value.length === 1) {
          return value[0];
        }
        if (value.length === 2) {
          const businessAccessWithHigherPriority = value.find(
            (i) => i.type === "all-staff"
          );
          if (businessAccessWithHigherPriority) {
            return businessAccessWithHigherPriority;
          }
          return value[0];
        }
      })
      .filter((x) => !!x) as SearchableBusinessOption[];
    console.log(
      "businessAccessesFilteredByPriority",
      businessAccessesFilteredByPriority
    );

    const userAccessesToAdd: DocUser[] = usersToInvite
      .map((u) => {
        if (!u.staffMemberCopyId) return null;
        return {
          staffId: u.staffMemberCopyId,
          accessType: "viewer",
        };
      })
      .filter((x) => !!x) as DocUser[];

    const existingFolderUserAccess = fileOrFolder.access;

    const existingFolderBusinessAccess =
      fileOrFolder.businessLevelStaffAccess ?? [];

    const newUserAccess = _.uniqBy(
      [...existingFolderUserAccess, ...userAccessesToAdd],
      "staffId"
    );
    const newBusinessAccess = _.uniqBy(
      [...existingFolderBusinessAccess, ...businessAccessesFilteredByPriority],
      "businessId"
    ).map((ba): BusinessLevelStaffAccess => {
      return {
        businessId: ba.businessId,
        type: ba.type,
        accessType: "viewer",
      };
    });

    const allFilesAndFoldersForFolder = getAllFilesAndFoldersForFolder(
      fileOrFolder,
      allFilesAndFolders,
      []
    );
    await inviteToFileOrFolder(fileOrFolder, newUserAccess, newBusinessAccess);
    await Promise.all(
      allFilesAndFoldersForFolder.map((f) => {
        return inviteToFileOrFolder(f, newUserAccess, newBusinessAccess);
      })
    );
    console.log("allFilesAndFoldersForFolder", allFilesAndFoldersForFolder);
  }
};

export const shareFolderWithWaterfall = async (
  fileOrFolder: FilesAndFolders,
  selectedUsers: StaffMember[],
  staffPriveleges: DocUser[],
  businessAccess: TangoBusiness[],
  allFilesAndFolders: FilesAndFolders[],
  businessAccessAsArrayOfBusinessId?: string[]
) => {
  if (fileOrFolder.isFolder) {
    console.log("businessAccess", businessAccess);
    const allFilesAndFoldersForFolder = getAllFilesAndFoldersForFolder(
      fileOrFolder,
      allFilesAndFolders,
      []
    );
    await shareFileOrFolder(
      fileOrFolder,
      selectedUsers,
      staffPriveleges,
      businessAccess,
      businessAccessAsArrayOfBusinessId
    );
    await Promise.all(
      allFilesAndFoldersForFolder.map((f) =>
        shareFileOrFolder(
          f,
          selectedUsers,
          staffPriveleges,
          businessAccess,
          businessAccessAsArrayOfBusinessId
        )
      )
    );
    console.log("allFilesAndFoldersForFolder", allFilesAndFoldersForFolder);
  }
};

export const inviteToFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  updatedStaffPrivileges: DocUser[],
  newBusinessAccess: BusinessLevelStaffAccess[]
) => {
  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    // Updated access comes after new users because
    businessLevelStaffAccess: newBusinessAccess,
    access: updatedStaffPrivileges,
  });
};

export const shareFileOrFolder = async (
  fileOrFolder: FilesAndFolders,
  selectedUsers: StaffMember[],
  staffPriveleges: DocUser[],
  businessAccess: TangoBusiness[] | null,
  businessAccessAsArrayOfBusinessIds?: string[]
) => {
  const newUsers = selectedUsers.map((user) => ({
    staffId: user.staffMemberCopyId || "",
    accessType: "editor",
  }));
  console.log("shareFileOrFolder", shareFileOrFolder);
  console.log("newUsers"), newUsers;
  const updatedAccess = fileOrFolder.access.map((staff) => {
    const index = staffPriveleges.findIndex(
      (updatedStaff) => updatedStaff.staffId === staff.staffId
    );
    if (index !== -1) {
      return staffPriveleges[index];
    }
    return staff;
  });

  const updatedBusinessAccess = businessAccessAsArrayOfBusinessIds
    ? businessAccessAsArrayOfBusinessIds
    : businessAccess
    ? _.uniq(businessAccess.map((business) => business.id))
    : [];

  await getDocReference(
    fileOrFolder.id,
    fileOrFolder.businessId,
    fileOrFolder.accountId || "",
    fileOrFolder.parentAccountId || ""
  ).update({
    // Updated access comes after new users because
    access: _.uniqBy([...updatedAccess, ...newUsers], "staffId").filter(
      (i) => !!i.staffId
    ),
    businessAccess: updatedBusinessAccess,
  });
};

export const getStaffMemberName = (
  staffId: string,
  allStaffMembers: StaffMember[]
) => {
  const staff = allStaffMembers.filter(
    (staff) => staff.staffMemberCopyId === staffId
  );
  if (staff.length) {
    return staff[0].contact.firstName + " " + staff[0].contact.lastName;
  }
  return "";
};

export const updateDocTags = async (businessId: string, tags: DocTag[]) => {
  if (businessId) {
    await businessSettingsCollection.doc(businessId).update({
      docsTags: tags,
    });
  }
};

export const updateFolderTags = async (businessId: string, tags: DocTag[]) => {
  try {
    if (businessId) {
      await businessSettingsCollection.doc(businessId).update({
        folderTags: tags,
      });
    }
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const updateDocIdsInFolderTags = async (
  businessId: string,
  folderTags: DocTag[],
  currentTags: string[],
  deletedTags: string[],
  fileId: string
) => {
  // businessId could be null if we're in the enterprise level
  if (businessId) {
    // Remove the tags from deleted if they've been re-added - and it was just an accident
    const nonAccidentalDeletes = _.uniq(
      deletedTags.filter((tag) => !currentTags.includes(tag))
    );
    const updatedTags = folderTags.reduce((acc, val, i) => {
      if (nonAccidentalDeletes.includes(val.name)) {
        // Remove the document ID from the array
        const newIds = acc[i].ids.filter((id) => fileId !== id);
        acc[i].ids = newIds;

        // Add a new document id
      } else if (currentTags.includes(val.name)) {
        const newIds = [...acc[i].ids, fileId];
        acc[i].ids = newIds;
      }

      return acc;
    }, folderTags);

    // Add any new tags to the business settings
    const allTags = updatedTags.map((tag) => tag.name);
    const newTags = currentTags
      .filter((tag) => !allTags.includes(tag))
      .map((tag) => ({ name: tag, count: 1, deleted: false, ids: [fileId] }));

    const finalTags = [...updatedTags, ...newTags];
    await updateFolderTags(businessId, finalTags);
  }
};

export const getFinalTags = (
  businessSettingTags: DocTag[],
  currentTags: string[],
  deletedTags: string[],
  file?: FilesAndFolders
) => {
  // Remove the tags from deleted if they've been re-added - and it was just an accident
  const nonAccidentalDeletes = _.uniq(
    deletedTags.filter((tag) => !currentTags.includes(tag))
  );

  const updatedTags = businessSettingTags.reduce((acc, val, i) => {
    // Delete an unused tag from that file
    if (nonAccidentalDeletes.includes(val.name)) {
      if (businessSettingTags[i].count === 1) {
        acc[i].deleted = true;
        acc[i].count = 0;
      } else {
        acc[i].count -= 1;
      }

      if (file) {
        // Remove the document ID from the array
        const newIds = acc[i].ids.filter((id) => id !== file.id);
        acc[i].ids = newIds;
      }

      // Increment the tag count for an existing tag
    } else if (currentTags.includes(val.name)) {
      acc[i].count += 1;

      if (file) {
        // Add the document ID from the array
        const newIds = [...acc[i].ids, file.id];
        acc[i].ids = newIds;
      }
    }

    return acc;
  }, businessSettingTags);

  // Add any new tags to the business settings
  const allTags = updatedTags.map((tag) => tag.name);
  const newTags = currentTags
    .filter((tag) => !allTags.includes(tag))
    .map((tag) => {
      const ids = file ? [file.id] : [];
      return { name: tag, count: 1, deleted: false, ids };
    });

  return [...updatedTags, ...newTags];
};

export const updateFileOrFolderName = async (
  newName: string,
  id: string,
  businessId: string,
  accountId?: string,
  parentId?: string
) => {
  try {
    const docRef = getDocReference(id, businessId, accountId, parentId);
    if (docRef) {
      await docRef.update({ name: newName });
    }
  } catch (e) {
    console.log("ERR:updateFileOrFolderName ", e);
    apiErrorHandler(e);
  }
};

export const addFolderWithWaterfall = async (
  allFiles: FilesAndFolders[],
  allStaffMembers: StaffMember[],
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  selectedUsers: StaffMember[],
  folderTags: DocTag[],
  currentTags: string[],
  description = "",
  accountId = "",
  parentId = "",
  businessAccess: TangoBusiness[] = []
) => {
  const parentBusinessAccess = parentFolder.businessAccess;
  console.log("parentBusinessAccess", parentBusinessAccess);
  const folder = await addFolder(
    businessId,
    name,
    parentFolder,
    staffId,
    selectedUsers,
    folderTags,
    currentTags,
    description,
    accountId,
    parentId,
    businessAccess,
    parentBusinessAccess
  );

  const allAccessToAdd = getAllParentAccess(folder, allFiles, []);
  const staffMembersToAdd = allAccessToAdd
    .map((usrDoc) =>
      allStaffMembers.find((s) => s.staffMemberCopyId === usrDoc.staffId)
    )
    .filter((x) => !!x) as StaffMember[];
  const filteredAccessToAdd = allAccessToAdd.filter((usrDoc) =>
    staffMembersToAdd.find((s) => s.staffMemberCopyId === usrDoc.staffId)
  );

  console.log("allAccessToAdd", allAccessToAdd);
  console.log("filteredAccessToAdd", filteredAccessToAdd);
  console.log("staffMembersToAdd", staffMembersToAdd);

  const updatedBusinessAccess = _.uniq([
    ...businessAccess.map((b) => b.id),
    ...parentBusinessAccess,
  ]);

  await shareFolderWithWaterfall(
    folder,
    staffMembersToAdd,
    filteredAccessToAdd,
    businessAccess,
    allFiles,
    updatedBusinessAccess
  );
  return folder;
};

export const addFolder = async (
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  selectedUsers: StaffMember[],
  folderTags: DocTag[],
  currentTags: string[],
  description = "",
  accountId = "",
  parentId = "",
  businessAccess: TangoBusiness[] = [],
  parentBusinessAccessAsArrayOfBusinessId: string[] = []
) => {
  await updateFolderTags(businessId, folderTags);
  // Create a new doc
  const docRef = createDoc(businessId, accountId, parentId);
  const userSelectedBusinessAccess =
    businessAccess && businessAccess.length
      ? _.uniq(businessAccess.map((i) => i.id))
      : accountId
      ? businessId
        ? [businessId]
        : []
      : [];

  console.log(
    "Final Folder Business access",
    _.uniq([
      ...userSelectedBusinessAccess,
      ...parentBusinessAccessAsArrayOfBusinessId,
    ])
  );
  const doc: FilesAndFolders = {
    id: "",
    parentAccountId: parentId,
    accountId: accountId,
    businessId: businessId,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    parentId: parentFolder.id,
    childrenIds: [],
    absolutePath: parentFolder.absolutePath + "/" + parentFolder.name,
    downloadUrl: null,
    isFolder: true,
    deletedAt: null,
    deletedTemporarily: false,
    fileSize: 0,
    fileExtension: null,
    name: name.replace("/", "-"),
    starred: false,
    description: description,
    authorId: { staffId: staffId, accessType: "editor" as DocAccessType },
    access: _.uniqBy(
      [
        { staffId: staffId, accessType: "editor" as DocAccessType },
        ...selectedUsers.map((user) => ({
          staffId: user.staffMemberCopyId || "",
          accessType: "editor" as DocAccessType,
        })),
      ],
      "staffId"
    ).filter((i) => !!i.staffId),
    tags: currentTags,
    fileID: "",
    businessAccess: _.uniq([
      ...userSelectedBusinessAccess,
      ...parentBusinessAccessAsArrayOfBusinessId,
    ]),
    businessLevelStaffAccess: parentFolder.businessLevelStaffAccess ?? [],
  };
  doc.id = docRef.id;
  await docRef.set(doc);
  console.log("doc", doc);

  // Add the children IDs reference to the parent doc
  const parentDocRef = getDocReference(
    parentFolder.id,
    businessId,
    accountId,
    parentId
  );
  await parentDocRef.update({
    childrenIds: [...parentFolder.childrenIds, doc.id],
  });

  return doc;
};

export const createDirectoryForBusinessIfNew = async (businessId: string) => {
  try {
    const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${businessId}`);
    const listResult = await rootRef.list({
      maxResults: 2,
    });
    const files = listResult.items.map((i) => i.name);
    // This means the folder doesn't exist so we should create a folder
    if (files.length === 0) {
      const ghostfile = new File([], ".ghostfile");
      await rootRef.child(".ghostfile").put(ghostfile);
    }
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

export const addFileInStorage = async (
  id: string,
  docName: string,
  data: File
) => {
  try {
    const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${id}`);
    const result = await rootRef.child(docName).put(data);
    const url = await result.ref.getDownloadURL();

    if (!url) {
      // Firebase Storage returns any for getDownloadURL so it's possible
      // if the subscriber from their end fails that we might not have
      // had a successful operation
      return null;
    }

    const fileSizeInKB = result.metadata.size / 1000;
    const fileType = result.metadata.contentType || "";
    const metadata = {
      url,
      fileSizeInKB,
      fileType,
    };
    return metadata;
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

export const addFileWithWaterfall = async (
  allFiles: FilesAndFolders[],
  allStaffMembers: StaffMember[],
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  files: File[],
  description = "",
  accountId = "",
  parentId = ""
) => {
  const parentFolderBusinessAccess = parentFolder.businessAccess;
  const addedFiles = await addFile(
    businessId,
    name,
    parentFolder,
    staffId,
    files,
    description,
    accountId,
    parentId
  );

  const allAccessToAdd = getAllParentAccess(parentFolder, allFiles, []);
  const staffMembersToAdd = allAccessToAdd
    .map((usrDoc) =>
      allStaffMembers.find((s) => s.staffMemberCopyId === usrDoc.staffId)
    )
    .filter((x) => !!x) as StaffMember[];
  const filteredAccessToAdd = allAccessToAdd.filter((usrDoc) =>
    staffMembersToAdd.find((s) => s.staffMemberCopyId === usrDoc.staffId)
  );

  console.log("allAccessToAdd", allAccessToAdd);
  console.log("filteredAccessToAdd", filteredAccessToAdd);
  console.log("staffMembersToAdd", staffMembersToAdd);

  await Promise.all(
    addedFiles.map((f) =>
      shareFileOrFolder(
        f,
        staffMembersToAdd,
        filteredAccessToAdd,
        null,
        parentFolderBusinessAccess
      )
    )
  );

  return files;
};

export const addFile = async (
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  files: File[],
  description = "",
  accountId = "",
  parentId = ""
) => {
  const folderId = parentId || accountId || businessId;

  await createDirectoryForBusinessIfNew(folderId);
  const addedFiles: FilesAndFolders[] = [];
  const childrenIds: string[] = [];
  for await (const file of files) {
    // If multiple files are selected - we have to default
    // to the name used in the file as opposed to a custom
    // name passed from the modal

    // TODO: Add tags
    const docRef = createDoc(businessId, accountId, parentId);
    const fileName = name && files.length === 1 ? name : file.name;
    console.log(
      "businessAccess when Uploading file",
      accountId ? (businessId ? [businessId] : []) : []
    );
    const doc: FilesAndFolders = {
      id: "",
      parentAccountId: parentId,
      accountId: accountId,
      businessId: businessId,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      parentId: parentFolder.id,
      childrenIds: [],
      absolutePath: parentFolder.absolutePath + "/" + parentFolder.name,
      downloadUrl: null,
      isFolder: false,
      deletedAt: null,
      deletedTemporarily: false,
      fileSize: 0,
      fileExtension: null,
      // Remove all instances of / in the name to avoid issues in the absolute path
      name: fileName.replace("/", "-"),
      starred: false,
      description: description,
      authorId: { staffId: staffId, accessType: "editor" as DocAccessType },
      access: [{ staffId: staffId, accessType: "editor" as DocAccessType }],
      tags: [],
      fileID: "",
      businessAccess: accountId ? (businessId ? [businessId] : []) : [],
      businessLevelStaffAccess: parentFolder.businessLevelStaffAccess ?? [],
    };
    doc.id = docRef.id;

    // Use the document ID as the final name because 2 files with the same
    // name can be used
    const metadata = await addFileInStorage(folderId, doc.id, file);

    addedFiles.push(doc);

    // Metadata can be null if the operation failed
    if (metadata) {
      doc.downloadUrl = metadata.url;
      doc.fileSize = metadata.fileSizeInKB;
      doc.fileExtension = metadata.fileType;

      await docRef.set(doc);
      childrenIds.push(doc.id);
    }
  }

  // Add the children IDs reference to the parent doc
  const parentDocRef = getDocReference(
    parentFolder.id,
    businessId,
    accountId,
    parentId
  );
  await parentDocRef.update({
    childrenIds: [...parentFolder.childrenIds, ...childrenIds],
  });

  return addedFiles;
};

export const addDocOrSheetToFolder = async (
  businessId: string,
  name: string,
  parentFolder: FilesAndFolders,
  staffId: string,
  description = "",
  fileType: "DocFile" | "SpreadSheet",
  fileid: string
) => {
  await createDirectoryForBusinessIfNew(businessId);

  const childrenIds: string[] = [];
  // If multiple files are selected - we have to default
  // to the name used in the file as opposed to a custom
  // name passed from the modal

  // TODO: Add tags and access
  const docRef = docsCollection
    .doc(businessId)
    .collection("FilesAndFolders")
    .doc();
  const fileName = name;
  const doc: any = {
    id: "",
    businessId: businessId,
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
    parentId: parentFolder.id,
    childrenIds: [],
    absolutePath: parentFolder.absolutePath + "/" + parentFolder.name,
    downloadUrl: null,
    isFolder: false,
    deletedAt: null,
    deletedTemporarily: false,
    fileSize: 0,
    // Remove all instances of / in the name to avoid issues in the absolute path
    name: fileName.replace("/", "-"),
    starred: false,
    description: description,
    authorId: { staffId: staffId, accessType: "editor" as DocAccessType },
    access: [{ staffId: staffId, accessType: "editor" as DocAccessType }],
    tags: [],
    fileExtension: fileType,
    fileID: fileid,
  };
  doc.id = docRef.id;
  await docRef.set(doc);
  childrenIds.push(doc.id);
  // Use the document ID as the final name because 2 files with the same
  // name can be used
  // const metadata = await addFileInStorage(businessId, doc.id, file);

  // Metadata can be null if the operation failed
  // if (metadata) {
  //   doc.downloadUrl = metadata.url;
  //   doc.fileSize = metadata.fileSizeInKB;
  //   doc.fileExtension = 'DocFile';

  //   await docRef.set(doc);
  //
  // }

  // Add the children IDs reference to the parent doc
  const parentDocRef = docsCollection
    .doc(businessId)
    .collection("FilesAndFolders")
    .doc(parentFolder.id);
  await parentDocRef.update({
    childrenIds: [...parentFolder.childrenIds, ...childrenIds],
  });
};

export const changeStarredStatus = async (
  businessId: string,
  item: FilesAndFolders,
  status: boolean
) => {
  try {
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    docRef.update({
      starred: status,
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const updateTheAbsolutePath = async (
  businessId: string,
  item: FilesAndFolders,
  newAbsolutePath: string
) => {
  try {
    // Set the new absolute path for file and folder
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      absolutePath: newAbsolutePath,
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const renameFileOrFolder = async (
  businessId: string,
  item: FilesAndFolders,
  newName: string
) => {
  try {
    // Set the new name of the file or folder
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      name: newName.replace("/", "-"),
    });
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const deleteFileOrFolder = async (
  businessId: string,
  item: FilesAndFolders,
  parentDoc: FilesAndFolders,
  childrenFiles: FilesAndFolders[],
  filesAndFolders: FilesAndFolders[]
) => {
  try {
    const deletedAbsolutePath = parentDoc.absolutePath + "/" + parentDoc.name;
    const rootFile = filesAndFolders.find(
      (f) => f.isFolder && f.id === f.parentId
    );

    console.log("rootFile", rootFile);
    if (!rootFile) {
      return null;
    }
    const deletedConfig: DeletedFileOrFolderConfig = {
      deletedAt: new Date(),
      absolutePath: "/root",
      childrenIds: item.childrenIds,
      parentId: rootFile.id,
    };
    console.log("parent deletedConfig", deletedConfig);
    // Set the deleted flag to true
    const docRef = getDocReference(
      item.id,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    await docRef.update({
      trashed: true,
      deletedConfig,
    });

    const splitUpOriginalPath = _.clone(item.absolutePath).split("/");
    const numberOfLevelsToRemove = splitUpOriginalPath.length - 2;
    const remainderOutOfPath = _.clone(splitUpOriginalPath).slice(
      numberOfLevelsToRemove + 1,
      splitUpOriginalPath.length
    );

    // Remove the children IDs from the parent IDs
    const parentDocRef = getDocReference(
      item.parentId,
      businessId,
      item.accountId || "",
      item.parentAccountId || ""
    );
    const newChildrenIds: string[] = parentDoc.childrenIds.filter(
      (id) => id !== item.id
    );
    // await parentDocRef.update({
    //   childrenIds: newChildrenIds,
    // });
    console.log("childrenFiles", childrenFiles);
    // If it's a folder then we should delete the children IDs as well
    if (childrenFiles && childrenFiles.length > 0) {
      for await (const file of childrenFiles) {
        const absoluteChildrenFilePath = file.absolutePath;
        const splitUpChildrenFilePath = absoluteChildrenFilePath.split("/");
        const updatedDeletedAbsolutePathForAChild = _.clone(
          splitUpChildrenFilePath
        )
          .slice(numberOfLevelsToRemove + 1, splitUpChildrenFilePath.length - 1)
          .join("/");
        console.log(
          "updatedDeletedAbsolutePathForAChild",
          updatedDeletedAbsolutePathForAChild
        );

        const childDeletedConfig: DeletedFileOrFolderConfig = {
          deletedAt: new Date(),
          absolutePath: updatedDeletedAbsolutePathForAChild,
          childrenIds: file.childrenIds,
          parentId: file.parentId,
        };
        console.log("childDeletedConfig", childDeletedConfig);

        const docRef = getDocReference(
          file.id,
          businessId,
          item.accountId || "",
          item.parentAccountId || ""
        );
        await docRef.update({
          trashed: true,
          deletedConfig: childDeletedConfig,
        });
      }
    }
  } catch (err) {
    console.log("ERR: ", err);
  }
};

export const getFileType = (type: string) => {
  return mime.getExtension(type)?.toUpperCase();
  // const splitType = type.split('/');
  // if (splitType.length > 1) {
  //   switch (splitType[0].toLowerCase()) {
  //     case 'text':
  //       return 'Text';
  //     default:
  //       return splitType[1].split(' ').length === 1 ? splitType[1].toUpperCase() : _.startCase(_.toLower(splitType[1]));
  //   }
  // }
  // return splitType[0];
};

export const getFileSize = (size: number) => {
  const sizeInString = String(size);
  const sizeLength = sizeInString.split(".")[0].length;
  if (sizeLength <= 3) {
    return size.toFixed(2) + " KB";
  } else if (sizeLength <= 6) {
    return (size / 1000).toFixed(2) + " MB";
  } else if (sizeLength <= 9) {
    return (size / 1000000).toFixed(2) + " GB";
  } else if (sizeLength <= 12) {
    return (size / 1000000000).toFixed(2) + " TB";
  }
  return size.toFixed(2) + " KB";
};

export const DocumentSaveNRedirect = (
  title: string,
  businessId: string,
  firstName: string,
  lastName: string,
  email: string,
  currentFolder: any,
  staffId: string,
  onClose: any
) => {
  const payload = {
    id: null,
    title: title,
    businessId: businessId,
    data: "",
    user: {
      firstName: firstName,
      lastName: lastName,
      Email: email,
    },
    updatedAt: new Date(),
    deletedAt: new Date(),
    createdAt: new Date(),
    createdBy: firstName + " " + lastName,
    deleted: false,
  };

  try {
    db.collection("TangoDocs")
      .add(payload)
      .then((docRef) => {
        const docId = docRef.id;
        db.collection("TangoDocs")
          .doc(docRef.id)
          .update({ id: docRef.id })
          .then(async () => {
            console.log("docRef.id", docRef.id);
            await addDocOrSheetToFolder(
              businessId,
              title,
              currentFolder,
              staffId,
              "",
              "DocFile",
              docRef?.id
            );
            window.open(`/document/${docRef.id}`, "_blank");
            onClose();
            // navigate(`/document/${docRef.id}`);
          });
      });
  } catch (err) {
    console.log(err);
  }
};

export const saveSpreadSheetData = (
  data: any,
  sheetId: string,
  title: string
) => {
  try {
    if (sheetId) {
      db.collection("TangoSpreadsheets")
        .doc(sheetId)
        .update({
          title: title,
          updatedAt: new Date(),
          data: JSON.stringify(data),
        });
    }
  } catch (err) {
    console.error(err);
  }
};
