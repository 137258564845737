import { View } from "@react-pdf/renderer";
import * as React from "react";

import { DataTableCell } from "./DataTableCell";
import { TableBodyProps } from "./TableBody";
import { TableBorder, TableCell } from "./TableCell";
import { getDefaultBorderIncludes } from "./Utils";

export interface TableRowProps extends TableBorder {
  /**
   * The font size as a valid unit defined in react-pdf.
   */
  fontSize?: number | string;

  /**
   * Whether to align the text. Defaults to left.
   */
  textAlign?: "left" | "center" | "right";

  /**
   * Any data associated, relevant if the parent is a {@see DataTableCell}.
   */
  data?: any;
  style?: any;
}

/**
 * This component describes how to display a row.
 */
export class TableRow extends React.PureComponent<Partial<TableBodyProps>> {
  render() {
    const rowCells: any[] = React.Children.toArray(this.props.children);
    const {
      includeLeftBorder,
      includeBottomBorder,
      includeRightBorder,
      includeTopBorder,
    } = getDefaultBorderIncludes(this.props);
    const { style = {} } = this.props;

    let remainingWeighting = 1;
    let numberOfWeightingsDefined = 0;
    rowCells.forEach((i: TableCell | DataTableCell) => {
      if (i.props.weighting !== undefined) {
        remainingWeighting -= i.props.weighting;
        numberOfWeightingsDefined++;
      }
    });

    const weightingsPerNotSpecified = Math.ceil(
      remainingWeighting / (rowCells.length - numberOfWeightingsDefined)
    );

    return (
      <View
        wrap={false}
        // @ts-ignore
        style={{
          borderBottom: includeBottomBorder && "1pt solid #f4f3f3",
          borderRight: includeRightBorder && "1pt solid #f4f3f3",
          borderLeft: includeLeftBorder && "1pt solid #f4f3f3",
          borderTop: includeTopBorder && "1pt solid #f4f3f3",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "stretch",
          ...style,
        }}
      >
        {rowCells.map((rc, columnIndex) =>
          React.cloneElement(rc, {
            weighting: rc.props.weighting ?? weightingsPerNotSpecified,
            data: this.props.data,
            key: columnIndex,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: columnIndex !== rowCells.length - 1,
          })
        )}
      </View>
    );
  }
}
