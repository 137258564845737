import React, { ChangeEvent } from "react";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  color: string;
}
const Email = ({ onChange, color }: Props) => {
  return (
    <input
      style={{ color, borderColor: color + "8C", borderWidth: "0.5px" }}
      type="email"
      placeholder="name@email.com"
      className="short-text-component apply-font"
      onChange={onChange}
    />
  );
};

export default Email;
