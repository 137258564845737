import { Button, Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";

interface Props {
  options: string[];
  onChange: (option: string) => void;
  selectedVale: string | null;
  color: string;
}
const Dropdown = ({ options, onChange, selectedVale, color }: Props) => {
  return (
    <div>
      <Select
        popoverProps={{
          modifiers: {
            arrow: {
              enabled: false,
            },
          },
          popoverClassName: "dropdown-select",
        }}
        matchTargetWidth
        items={options}
        fill={true}
        filterable={false}
        itemRenderer={(item, { handleClick }) => (
          <MenuItem onClick={handleClick} text={item} />
        )}
        onItemSelect={(item) => onChange(item)}
      >
        <div className="short-text-component" style={{ borderColor: color }}>
          <Button
            style={{ color }}
            text={selectedVale}
            className="dropdown-value-button apply-font"
            rightIcon={
              <Icon icon="double-caret-vertical" color={color} size={20} />
            }
            minimal
            fill
            alignText={"left"}
          />
        </div>
      </Select>
    </div>
  );
};
export default Dropdown;
