const SheetRenderer = (props) => {
  const {
    as: Tag,
    headerAs: Header,
    bodyAs: Body,
    rowAs: Row,
    cellAs: Cell,
    className,
    columns,
    selections,
    onSelectAllChanged,
    showModalColumn,
    setCurrentColumn,
    setCurrentCursor,
    setCurrentColorOptions,
    columnHeadingConversions,
    options,
  } = props;
  return (
    <Tag className={className}>
      <Header className="data-header">
        <Row>
          <Cell className="action-cell cell">
            <input
              type="checkbox"
              checked={selections.every((s) => s === true)}
              onChange={(e) => onSelectAllChanged(e.target.checked)}
            />
          </Cell>
          {columns.map((column) => (
            <Cell
              className="cell"
              key={column.label}
              style={{ width: column.width }}
              onClick={(e) => {
                const dropDownOptions = options.filter(
                  (option) => option.key === column.label && option.dropdown
                );
                if (dropDownOptions.length > 0) {
                  setTimeout(() => {
                    setCurrentCursor([e.clientX, e.clientY]);
                    setCurrentColorOptions(dropDownOptions);
                    setCurrentColumn(column.label);
                  }, 10);
                } else {
                  setCurrentCursor([null, null]);
                  setCurrentColorOptions(null);
                  setCurrentColumn(null);
                }
              }}
            >
              {columnHeadingConversions
                ? Object.keys(columnHeadingConversions).includes(column.label)
                  ? columnHeadingConversions[column.label]
                  : column.label
                : column.label}
            </Cell>
          ))}
          {showModalColumn && (
            <Cell className="modal-cell cell">
              <span className="value-viewer">{props.modalColumTitle}</span>
            </Cell>
          )}
        </Row>
      </Header>
      <Body className="data-body">{props.children}</Body>
    </Tag>
  );
};

export default SheetRenderer;
