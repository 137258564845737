import { Icon, InputGroup } from "@blueprintjs/core";
import { width } from "@mui/system";
import firebase from "config/firebase";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampaignTable from "views/admin/campaign/CampaignTable";

import Box from "components/Box";

import { RootState } from "model/store";

import Layout from "../MarketingFeedback/Layout";
import { All } from "./icons/All";
import { Completed } from "./icons/Completed";
import { Drafts } from "./icons/Drafts";
import { Ongoing } from "./icons/Ongoing";
import "./styles.css";

const Campaigns = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const navigate = useNavigate();
  const db = firebase.firestore();

  const [status, setStatus] = useState("All");
  const [campaigns, setCampaigns]: any = useState([]);

  const getCampaigns = (businessId: string) => {
    try {
      db.collection("Campaigns")
        .where("businessId", "==", businessId)
        .get()
        .then((docRef: any) => {
          let campaignsList: any = [];
          docRef.docs.forEach((doc: any) => {
            campaignsList = [...campaignsList, doc.data()];
          });
          console.log("campaignsList", campaignsList);
          setCampaigns(campaignsList);
        });
    } catch (err) {}
  };

  useEffect(() => {
    if (business) {
      business.id && getCampaigns(business.id);
    }
  }, [business]);

  const renderSidebar = () => {
    return (
      <Box className="sidebar">
        <div className="section">
          <div className="sidebar-head">View by Status</div>
          <div className="statuslistdiv">
            <ul className="statuslist">
              <li onClick={() => setStatus("All")}>
                <All
                  height={16}
                  width={16}
                  color={status === "All" ? "#424452" : "#7C7F93"}
                />
                <span
                  className={
                    status === "All"
                      ? "status-list-active-text"
                      : "status-list-text"
                  }
                >
                  All
                </span>

                {status === "All" && <div className="active-element"></div>}
              </li>
              <li onClick={() => setStatus("Ongoing")}>
                <Ongoing
                  height={15}
                  width={15}
                  color={status === "Ongoing" ? "#424452" : "#7C7F93"}
                />
                <span
                  className={
                    status === "Ongoing"
                      ? "status-list-active-text"
                      : "status-list-text"
                  }
                >
                  Ongoing
                </span>
                {status === "Ongoing" && <div className="active-element"></div>}
              </li>
              <li onClick={() => setStatus("Drafts")}>
                <Drafts
                  height={15}
                  width={15}
                  color={status === "Drafts" ? "#424452" : "#7C7F93"}
                />
                <span
                  className={
                    status === "Drafts"
                      ? "status-list-active-text"
                      : "status-list-text"
                  }
                >
                  Drafts
                </span>
                {status === "Drafts" && <div className="active-element"></div>}
              </li>
              <li onClick={() => setStatus("Completed")}>
                <Completed
                  height={17}
                  width={15}
                  color={status === "Completed" ? "#424452" : "#7C7F93"}
                />
                <span
                  className={
                    status === "Completed"
                      ? "status-list-active-text"
                      : "status-list-text"
                  }
                >
                  Completed
                </span>
                {status === "Completed" && (
                  <div className="active-element"></div>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="section">
          <div className="sidebar-head">Actions</div>
          <div className="sidebar-actions">
            <div
              className="action-item"
              onClick={() => {
                navigate("/templates");
              }}
            >
              <span className="action-item-name">Templates</span>
              <Icon icon="chevron-right" color="#7C7F93" size={12} />
            </div>
            <div
              className="action-item"
              onClick={() => navigate("/create-campaign")}
            >
              <span className="action-item-name">Create Campaign</span>
              <Icon icon="chevron-right" color="#7C7F93" size={12} />
            </div>
          </div>
        </div>
      </Box>
    );
  };

  const renderBody = () => {
    return (
      <Box className="body" style={{ width: "100%" }}>
        <Box className="body-container">
          <Box display="flex" flexDirection="column" className="body-head-text">
            <span>All Campaigns</span>
            <span>Showing all {campaigns.length} of your Campaigns</span>
          </Box>
          <CampaignTable campaigns={campaigns} status={status} />
        </Box>
      </Box>
    );
  };
  return (
    <Layout>
      <div id="campaign-page">
        <Box className="page-head">
          <div>Your Campaigns</div>
          {/* <div>
            Your audience has <span className="bold">{campaigns.length}</span>{" "}
            contacts. <span className="bold">101</span> of these contacts are
            subscribed
          </div> */}
        </Box>
        <Box display="flex" flexDirection="row">
          {renderSidebar()}
          {renderBody()}
        </Box>
      </div>
    </Layout>
  );
};

export default Campaigns;
