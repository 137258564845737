import React, { useEffect } from "react";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../model/store";
import { saveFile } from "../../../utils/storage";
import {
  EndingSectionProps,
  EndingSectionType,
  ImageLayoutType,
  Question,
} from "../types";

const useEndingSection = (
  endingSectionBgImageRef: React.RefObject<HTMLInputElement>,
  questions: Question[]
) => {
  const [endingSections, setEndingSections] = useState<EndingSectionProps[]>(
    []
  );
  const [welcomeScreen, setWelcomeScreen] = useState<EndingSectionProps | null>(
    null
  );
  const [selectedEndingSection, setSelectedEndingSection] =
    useState<EndingSectionProps | null>(null);
  const [showEndingSection, setShowEndingSection] = useState<boolean>(false);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  useEffect(() => {
    const findWelcomeScreen = endingSections.find(
      (item) => item.type === "welcomeScreen"
    );
    if (findWelcomeScreen) {
      setWelcomeScreen(findWelcomeScreen);
    }
  }, [endingSections]);
  const updateEndingSectionStep = (key: string, value: string | number) => {
    const clonedEndingSections = [...endingSections];
    let obj = {};
    if (selectedEndingSection) {
      if (key === "showButton" || key === "showButtonLink") {
        obj = { [key]: !selectedEndingSection[key] };
        setSelectedEndingSection({ ...selectedEndingSection, ...obj });
      } else {
        obj = { [key]: value };
        setSelectedEndingSection({ ...selectedEndingSection, ...obj });
      }
      const updatedEndingSections = clonedEndingSections.map((item, index) => {
        if (item.id === selectedEndingSection.id) {
          return { ...item, ...obj };
        }
        return item;
      });
      setEndingSections(updatedEndingSections);
    }
  };

  const uploadEndingSectionImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    //@ts-ignore
    reader.onload = async (event) => {
      if (event.target) {
        if (endingSectionBgImageRef.current) {
          endingSectionBgImageRef.current.value = "";
        }
        const text = event.target.result;
        if (e.target && text && selectedEndingSection) {
          //@ts-ignore

          try {
            const fileUrl = await saveFile(
              //@ts-ignore
              `businesses/${business.id}/forms/` + e.target.fileName,
              //@ts-ignore
              text
            );
            const updatedEndingSection = { ...selectedEndingSection };
            const clonedEndingSections = [...endingSections];
            updatedEndingSection.image = fileUrl;
            clonedEndingSections[updatedEndingSection.index] =
              updatedEndingSection;

            setSelectedEndingSection(updatedEndingSection);
            setEndingSections(clonedEndingSections);
          } catch (e) {
            //@ts-ignore
            alert(e.message);
          }
        }
      }
    };
    if (e.target.files) {
      reader.readAsDataURL(e.target.files[0]);
      //@ts-ignore
      reader.fileName = e.target.files[0].name;
    }
  };
  const addNewSection = (type: EndingSectionType) => {
    const newSection: EndingSectionProps = {
      title: "",
      subtitle: "",
      showButton: true,
      buttonLink: "",
      buttonLabel: type === "welcomeScreen" ? "Start" : "Done",
      image: "",
      showButtonLink: type === "welcomeScreen" ? false : true,
      index: endingSections.length,
      imageBrightness: 0,
      imageLayout: "top",
      id: questions.length + endingSections.length + 1,
      type: type,
    };
    setEndingSections([
      ...endingSections,
      {
        ...newSection,
      },
    ]);
    setSelectedEndingSection(newSection);
    setShowEndingSection(true);
  };
  const deleteEndingSection = () => {
    const clonedEndingSections = [...endingSections];
    const endingSectionIndex = clonedEndingSections.findIndex(
      (section) => selectedEndingSection?.id === section.id
    );
    if (endingSectionIndex !== -1) {
      clonedEndingSections.splice(endingSectionIndex, 1);
      if (selectedEndingSection?.type === "welcomeScreen") {
        setWelcomeScreen(null);
      }
      setEndingSections(clonedEndingSections);
      setSelectedEndingSection(null);
      setShowEndingSection(false);
    }
  };
  return {
    endingSections,
    selectedEndingSection,
    setSelectedEndingSection,
    updateEndingSectionStep,
    uploadEndingSectionImage,
    addNewSection,
    showEndingSection,
    setShowEndingSection,
    setEndingSections,
    welcomeScreen,
    deleteEndingSection,
  };
};
export default useEndingSection;
