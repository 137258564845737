import { Button, Checkbox, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import HorizontalTable from "components/Table/HorizontalTable";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UIComponents from "@tangopay/tango-ui-library";
import { checkIsFormLinkedToBoard } from "../../models/boards";
import { ComponentType, Question } from "../SurveyBuilder/types";
import CreateBoardModal from "./CreateBoardModal";
import DeleteFormConfirmationModal from "./DeleteFormConfirmationModal";
import FormModal from "./FormModal";
import "./index.css";
import useFormResponses from "./useFormResponses";
import { instructions } from './instructions'
import Sidebar from "components/Sidebar";
import { getSidebarRoutes } from './routes';

const { Icon } = UIComponents;
const FormResponses = () => {
  const navigate = useNavigate();
  const {
    showModal,
    setShowModal,
    id,
    formData,
    formResponses,
    selectedResponse,
    setSelectedResponse,
    checkIsScorePending,
    updateFormResponse,
    updateScore,
    calculateScore,
    showCreateBoardModal,
    setShowCreateBoardModal,
    redirectToBoards,
    updateFormSettings,
    showDeleteFormConfirmationModal,
    setShowDeleteFormConfirmationModal,
    loading,
    responseRows,
    customColumns
  } = useFormResponses(navigate);
  console.log("formResponses", formResponses);
  const renderValue = (response: any) => {
    if (response) {
      if (
        response.type === ComponentType.uploadFile ||
        response.type === ComponentType.digitalSignature ||
        response.type === ComponentType.matching ||
        response.type === ComponentType.correctOrder ||
        response.type === ComponentType.fillInBlank
      ) {
        return "click to view response";
      }
      if (response.type === ComponentType.multipleChoice) {
        return response.selectedChoices.toString();
      }
    }
    return response?.value;
  };

  if (formData) {
    return (
      <div className="absolute top-0 bottom-0 w-full">

        <div className="flex h-full overflow-hidden flex-1">
          <div
            className={"border-r border-grey-1 border-t-0 border-b-0 relative"}
            style={{ width: "10%", borderRightStyle: "solid" }}
          >

            {id ? <Sidebar routes={getSidebarRoutes(id)} /> : null}
            <div className="absolute bottom-0 left-0 right-0">
              <ul className="p-0 m-0 list-none pt-7 pl-6 pb-6 border-t border-solid border-grey-1 border-r-0 border-b-0 border-l-0">
                {/* <li className="font-lato-medium text-xs text-black cursor-pointer py-1 mb-3">Get Shareable Link</li> */}

                <li className="font-lato-medium text-xs text-black cursor-pointer py-1 mb-3"
                  onClick={async () => {
                    if (formData.enabled) {
                      await updateFormSettings("enabled", false);
                    } else {
                      await updateFormSettings("enabled", true);
                    }
                  }}
                >
                  {
                    formData.enabled ? 'Set as Inactive' : 'Set as Active'
                  }
                </li>
                <li className="font-lato-medium text-xs text-grey-2 cursor-pointer py-1" onClick={() => {
                  setShowDeleteFormConfirmationModal(true);
                }}>
                  Delete Form
                </li>
              </ul>
            </div>
          </div>

          <div
            style={{ width: "90%" }}
            className={"overflow-y-auto"}
          >
            <div
              className={
                "flex p-8 justify-between items-center bg-white border-b border-grey-1"
              }
              style={{ borderBottomStyle: "solid" }}
            >
              <div className={"flex items-center"}>
                <Icon
                  name={"arrow-left"}
                  size={"20"}
                  className={"cursor-pointer"}
                  onClick={() => navigate(-1)}
                />


                <div className={"text-big text-black font-lato-black ml-10"}>
                  {formData.name}
                </div>
                <div className={"h-9 w-px bg-grey-1 mx-7"} />
                <div className="font-lato-bold text-xl text-black">Responses</div>
              </div>
              <div className={"flex items-center"}>
                <div className="font-lato-medium text-sm text-grey-2">
                  {formData.enabled
                    ? ` Form published on ${formData.createdAt
                      .toDate()
                      .toLocaleString()}`
                    : "Inactive"}
                </div>
              </div>
            </div>
            <div className={"flex p-8 justify-between"}>
              <HorizontalTable
                title=""
                columns={customColumns}
                data={responseRows}
                instructions={instructions}
                setEditing={() => console.log('yey')}
                isEditing={false}
                hideEdit
                onRowClick={(rowId) => {
                  const responseId = rowId.split('-')
                  const selectedResponse = formResponses.find((item) => item.id === responseId[1])
                  if (selectedResponse) {
                    setSelectedResponse(selectedResponse);
                    setShowModal(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <FormModal
          showModal={showModal}
          onClose={() => {
            updateFormResponse();
            setShowModal(false);
            setSelectedResponse(null);
          }}
          selectedResponse={selectedResponse}
          formData={formData}
          updateScore={updateScore}
        />
        <CreateBoardModal
          showModal={showCreateBoardModal}
          onClose={() => setShowCreateBoardModal(false)}
          redirectToBoards={redirectToBoards}
        />
        <DeleteFormConfirmationModal
          showModal={showDeleteFormConfirmationModal}
          onClose={() => setShowDeleteFormConfirmationModal(false)}
          onDelete={async () => {
            await updateFormSettings("deleted", true);
          }}
        />
      </div>
    )
  }
  return null;
};
export default FormResponses;
