import ActionType from "../actions/actionTypes";

const initialState: ScheduleEvent[] = [];

export default function scheduleEvents(state = initialState, action: any) {
  switch (action.type) {
    case ActionType.RECEIVE_SCHEDULE_EVENTS:
      return action.scheduleEvents;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
