import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiErrorHandler } from "controllers/core";
import {
  archiveEmployee,
  fetchTeamTableData,
  unArchiveEmployee,
} from "controllers/team";

import { ColumnInstruction } from "components/Table/HorizontalTable";

import { RootState } from "model/store";

export type TableStaffMember = {
  uniqueId: string;
  name: string;
  primaryRoleTitle: string;
  locationName: string;
  formattedStartDate: string;
  employmentType: string;
};

export const useTeamTable = () => {
  const noOp = () => Promise.resolve(true);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [searchFilter, setSearchFilter] = useState("");
  const navigate = useNavigate();

  const onSearchChange = useCallback(
    (v: string) => {
      setSearchFilter(v.toLowerCase());
    },
    [setSearchFilter]
  );

  const columns: ColumnInstruction<TableStaffMember>[] = [
    { type: "data", header: "Emploee", attribute: "name" },
    { type: "data", header: "Primary Role", attribute: "primaryRoleTitle" },
    { type: "data", header: "Location", attribute: "locationName" },
    { type: "data", header: "Start Date", attribute: "formattedStartDate" },
    { type: "data", header: "Employment Type", attribute: "employmentType" },
  ];

  const archiveEmployeeMutation = useMutation(
    (mutationData: { businessId: string; uid: string }) => {
      return archiveEmployee(mutationData.businessId, mutationData.uid);
    },
    {
      onError: apiErrorHandler,
    }
  );

  const businessId = useMemo(() => {
    return business?.id ?? null;
  }, [business?.id]);

  const teamTableData = useQuery(
    ["teamTableData", businessId],
    async () => fetchTeamTableData(businessId),
    {
      enabled: !!businessId,
      initialData: [],
      refetchOnWindowFocus: false,
      select: (employees) =>
        employees.filter((employee) =>
          employee.name.toLowerCase().includes(searchFilter)
        ),
    }
  );

  const archiveEmployeesHandler = useCallback(
    async (uids: string[]) => {
      await Promise.all(
        uids.map(async (uid) => {
          await archiveEmployeeMutation.mutateAsync({
            businessId: business.id,
            uid: uid,
          });
        })
      );
      teamTableData.refetch();
    },
    [archiveEmployeeMutation, teamTableData]
  );

  return {
    columns,
    teamTableData,
    onSearchChange,
    searchFilter,
    navigate,
    noOp,
    archiveEmployeeMutation,
    archiveEmployeesHandler,
  };
};
