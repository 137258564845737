import ActionType from "./actionTypes";

export function receiveMenuCategories(
  menuCategories: FirebaseMenuCategoryDoc[]
) {
  return {
    type: ActionType.RECEIVE_MENU_CATEGORY,
    menuCategories,
  };
}
