import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

import { updateReviewResponseTemplate } from "models/reputationManagement";

import {
  ReviewResponseTemplate,
  ReviewResponseTemplateUpdate,
} from "../../../types/reputationManagement";

interface Props {
  setSelectedTemplate: (template: ReviewResponseTemplate | null) => void;
  selectedTemplate: ReviewResponseTemplate;
  updateSuccess: () => void;
}
const ViewTemplate = ({
  setSelectedTemplate,
  selectedTemplate,
  updateSuccess,
}: Props) => {
  const { name, text, id } = selectedTemplate;
  const [templateSubject, setTemplateSubject] = useState<string>(name);
  const [templateText, setTemplateText] = useState<string>(text);

  const updateTemplate = async () => {
    if (!templateSubject || !templateText) {
      return alert(
        "Your review response must have both a subject and reply message."
      );
    }

    const newTemplateData: ReviewResponseTemplateUpdate = {
      id: id,
      name: templateSubject,
      subject: templateSubject,
      text: templateText,
      updatedAt: new Date(),
      deleted: false,
      enabled: true,
    };

    const templatUpdated = await updateReviewResponseTemplate(newTemplateData);
    if (templatUpdated) {
      updateSuccess();
      return setSelectedTemplate(null);
    } else {
      setSelectedTemplate(null);
      return alert("There was a problem creating your new template.");
    }
  };

  const deleteTemplate = async () => {
    const newTemplateData: ReviewResponseTemplateUpdate = {
      id: id,
      name: templateSubject,
      subject: templateSubject,
      text: templateText,
      updatedAt: new Date(),
      deleted: true, //set deleted to true
      enabled: true,
    };

    const templatUpdated = await updateReviewResponseTemplate(newTemplateData);
    if (templatUpdated) {
      updateSuccess();
      return setSelectedTemplate(null);
    } else {
      setSelectedTemplate(null);
      return alert("There was a problem creating your new template.");
    }
  };

  return (
    <div className={"w-full"}>
      <div className={"flex justify-between items-center p-10 bg-blue-grey-1"}>
        <div className={"flex items-center"}>
          <UIComponents.Icon
            name={"arrow-left"}
            size={"20"}
            className={"cursor-pointer"}
            onClick={() => setSelectedTemplate(null)}
          />
          <div className={"ml-10 text-black font-lato-black text-big"}>
            View Reply Template
          </div>
        </div>
        <UIComponents.Button
          onClick={() => {
            updateTemplate();
          }}
          label={"Save"}
          type={"btn-style-2"}
          size={"btn-medium"}
        />

        <UIComponents.Button
          onClick={() => {
            deleteTemplate();
          }}
          label={"Delete"}
          type={"btn-style-2"}
          size={"btn-medium"}
        />
      </div>
      <div className={"mt-12 pl-12"}>
        <div className={"mb-12"}>
          <div className={"text-xl text-black font-lato-black"}>Subject</div>
          <div className={"text-sm font-lato-bold text-grey-2 mt-2.5"}>
            Add a template subject to outline the template’s usecase
          </div>
          <UIComponents.TextField
            onChange={(e: any) => setTemplateSubject(e.target.value)}
            sizeType={"large"}
            className={"mt-4 text-sm font-lato-regular text-black"}
            value={templateSubject}
            style={{ width: "580px" }}
          />
        </div>
        <div className={"mt-12"}>
          <div className={"text-xl text-black font-lato-black"}>
            Reply Template
          </div>
          <div className={"text-sm font-lato-bold text-grey-2 mt-2.5"}>
            What you will reply with
          </div>
          <UIComponents.ParagraphField
            onChange={(e: any) => setTemplateText(e.target.value)}
            sizeType={"large"}
            className={"mt-4 text-sm font-lato-regular text-black"}
            value={templateText}
            style={{ width: "580px", height: "144px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTemplate;
