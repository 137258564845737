import React, { useEffect, useMemo, useState } from "react";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { headerRoutes, sidebarRoutes } from "../routes";
import UIComponents from '@tangopay/tango-ui-library';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Payments from "../Payments";
import RefundPaymentModal from "./RefundPaymentModal";
import useDetails from "./useDetails";
import OrderReciept from "../OrderReciept";
import { calculateCompletedCheckTotal, getCompletedCheckType } from "../utils";
import { RootState } from "model/store";
import { useSelector } from "react-redux";
import { ClosedChecksProps } from "../ClosedChecks/useClosedChecks";
import moment from "moment";
import { fetchTab, useTab } from "controllers/disputes";
import { fetchTabWithStaff, TabWithStaff, TangoTab } from "controllers/payments";
const { Icon, CallToActionModal, Modal } = UIComponents;
const ClosedCheckDetail = () => {
    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business as TangoBusiness
    );
    const navigate = useNavigate();
    const params = useParams()
    const loc = useLocation();
    const {
        showRefundPaymentModal,
        setShowRefundPaymentModal,
        setSelectedPaymentToRefund,
        selectedPaymentToRefund,
        setUpdateCheck,
        updatedCheck,
        setRefundSuccess,
        refundSuccess,
        setShowBackButton,
        showBackButton,
        setLoading,
        loading
    } = useDetails();

    //if refund is processed or check has to be fetched directly from backend use updated check 
    //else use data passes in loc.state (navigated from table)
    const closedCheckWithStaff: ClosedChecksProps = updatedCheck ? updatedCheck : loc.state

    const getTab = async (businessId: string, tabId: string) => {
        setLoading(true)
        const tabWithStaff: TabWithStaff = await fetchTabWithStaff(businessId, tabId)
        setUpdateCheck({
            completedCheck: tabWithStaff.tab,
            staffMember: tabWithStaff.staffMember,
            uniqueId: tabWithStaff.tab.id,
            checkNumber: tabWithStaff.tab.tabNumber,
            date: tabWithStaff.tab.createdAt,
            timeClosed: tabWithStaff.tab.updatedAt,
            totalAmount: '$' + (calculateCompletedCheckTotal(tabWithStaff.tab.customer) * 0.01).toFixed(2) + ' ' + business.currency.toUpperCase(),
            payments: tabWithStaff.tab.customer.length,
            serverName: tabWithStaff.staffMember ? tabWithStaff.staffMember.contact.firstName + ' ' + tabWithStaff.staffMember.contact.lastName : '',
            type: getCompletedCheckType(tabWithStaff.tab)
        })
        setShowBackButton(false)
        setLoading(false)
    }

    useEffect(() => {
        if (!closedCheckWithStaff && params.id && business && !updatedCheck) {
            getTab(business.id, params.id)
        }
    }, [business, params, closedCheckWithStaff, updatedCheck])


    //prevents trying to render check while loading
    // will only show set when navigating directly to check screen (ie the url was shared) 
    if (loading) {
        return <Modal
            isOpen={loading}
            closeModal={() => { }}
            width={"472"}
        >
            <div>
                <div className={"text-2xl text-black font-lato-black"}>
                    Please wait, getting closed check details...
                </div>
                <div className="flex mt-8 center h-full w-full justify-center content-center items-center">
                    <div className="loading-spinner"></div>
                </div>
            </div>
        </Modal>
    }

    if (!closedCheckWithStaff) {
        return null
    }

    return (
        <div className="absolute top-[63px] bottom-0 w-full">
            <Header routes={headerRoutes} selectedRoute={"/payments/closedchecks/:id"} selectedPage={{ label: "Customers", value: "/payments/closedchecks/:id" }} />
            <div className="flex h-full">
                <div
                    className={"border-r border-grey-1 border-t-0 border-b-0"}
                    style={{ width: "10%", borderRightStyle: "solid" }}
                >
                    <Sidebar routes={sidebarRoutes} />
                </div>

                <div style={{ width: "90%" }}>
                    <div
                        className={
                            "flex p-8 justify-between items-center bg-white border-b border-grey-1"
                        }
                        style={{ borderBottomStyle: "solid" }}
                    >
                        <div className={"flex items-center"}>

                            {showBackButton &&
                                <Icon
                                    name={"arrow-left"}
                                    size={"20"}
                                    className={"cursor-pointer"}
                                    onClick={() => navigate(-1)}
                                />
                            }

                            <div className={"text-big text-black font-lato-black ml-10"}>
                                ${(calculateCompletedCheckTotal(closedCheckWithStaff.completedCheck.customer) * 0.01).toFixed(2)}
                            </div>

                            <div className="text-grey-2 font-lato-regular text-2xl ml-2">
                                {business && business.currency.toUpperCase()}
                            </div>

                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className="text-black text-base font-lato-bold">
                                {/* @ts-ignore */}
                                {moment(new Date(closedCheckWithStaff.completedCheck.createdAt._seconds * 1000)).format('MM/DD/YYYY')}
                            </div>


                            <div className="w-px h-8 bg-grey-1 mx-6" />
                            <div className="text-black text-base font-lato">
                                {closedCheckWithStaff.completedCheck.tabNumber}
                            </div>

                        </div>
                    </div>



                    {/* Header: ends */}
                    {/* container starts */}
                    {closedCheckWithStaff &&
                        <div className="pt-10 pl-10 pr-16 flex justify-start">
                            <div className="w-4/12">

                                <OrderReciept
                                    closedCheck={closedCheckWithStaff.completedCheck}
                                    staffMember={closedCheckWithStaff.staffMember}
                                />

                            </div>

                            <div className="w-7/12">
                                <Payments
                                    closedCheck={closedCheckWithStaff.completedCheck}
                                    setShowRefundPaymentModal={setShowRefundPaymentModal}
                                    setSelectedPaymentToRefund={setSelectedPaymentToRefund}
                                    setRefundSuccess={setRefundSuccess}
                                    refundSuccess={refundSuccess}
                                    allowRefund={true}
                                />

                            </div>
                        </div>
                    }
                </div>
            </div>

            {selectedPaymentToRefund && closedCheckWithStaff &&
                <RefundPaymentModal
                    isOpen={showRefundPaymentModal}
                    selectedPaymentToRefund={selectedPaymentToRefund}
                    setRefundSuccess={setRefundSuccess}
                    setUpdateCheckWithRefund={setUpdateCheck}
                    closedCheckWithStaff={closedCheckWithStaff}
                    closeModal={() => setShowRefundPaymentModal(false)}
                />
            }

            {/* Not applicable currently - eventually could process charges */}
            {/* <CallToActionModal
                isOpen={showCapturePaymentModal}
                modalTitle="Capture Payment?"
                modalSubtitle={"Capturing this payment will charge the customer and secure and complete this payment."}
                buttonLabel="Capture Payment"
                onOptionClick={() => console.log('close check detail')}
                closeModal={() => setShowCapturePaymentModal(false)}
            /> */}

        </div>
    );
};

export default ClosedCheckDetail;
