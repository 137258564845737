import Header from "components/Header";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import { RootState } from "../../model/store";
import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const launchpad = useLaunchpad();
  return (
    <Box className="marketing-feedback">
      <Header
        routes={[
          // {
          //   label: "Campaigns",
          //   link: "/campaigns",
          // },
          {
            label: "Payments",
            link: "/payments",
          },
          // {
          //   label: "Audiences",
          //   link: "/audiences",
          // },
          // {
          //   label: "Gift Cards",
          //   link: "/gift-cards"
          // },
          // {
          //   label: "Customers",
          //   link: "/customers",
          // },
        ]}
        selectedRoute={location.pathname}
        selectedPage={{
          label: "Customers",
          value: "/payments"
        }}
      />
      <div className="pt-16">
        {children}
      </div>

    </Box>
  );
};
export default Layout;
