import axios from "axios";
import firebase from "firebase";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/daily-logs`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const getSummaryForDate = async (
  businessId: string,
  date: number, //YYYYMMDD
  ignoreSales = false
) => {
  const r = await apiClient.get(`${businessId}/projections`, {
    params: {
      date,
      ignoreSales,
    },
  });
  return r.data as {
    fohCost: number;
    bohCost: number;
    totalCost: number;
    targetAll: number;
    targetFoh: number;
    targetBoh: number;
    actualSales: number;
    projectedSales: number;
    projectionId: string | null;
  };
};

const db = firebase.firestore();

export const setOverallTarget = async (
  businessId: string,
  projectionId: string | null,
  day: number,
  targetAll: number
) => {
  try {
    if (!projectionId && !day) return Promise.reject("illegal arguments");
    if (projectionId) {
      await db.collection("Projections").doc(projectionId).update({
        targetFoh: null,
        targetBoh: null,
        targetAll,
        updatedAt: new Date(),
      });
      return projectionId;
    } else {
      const result = await db.collection("Projections").add({
        date: day,
        targetAll,
        targetFoh: null,
        targetBoh: null,
        salesProj: null,
        enabled: true,
        deleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        businessId,
      });
      return result.id;
    }
  } catch (e) {
    console.warn(e);
    return "";
  }
};

export const setSplitTarget = async (
  businessId: string,
  projectionId: string | null,
  day: number,
  targetFoh: number,
  targetBoh: number
) => {
  try {
    if (!projectionId && !day) return Promise.reject("illegal arguments");
    if (projectionId) {
      await db.collection("Projections").doc(projectionId).update({
        targetAll: null,
        targetFoh,
        targetBoh,
        updatedAt: new Date(),
      });
      return projectionId;
    } else {
      const result = await db.collection("Projections").add({
        date: day,
        targetAll: null,
        targetFoh,
        targetBoh,
        salesProj: null,
        enabled: true,
        deleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        businessId,
      });
      return result.id;
    }
  } catch (e) {
    console.warn(e);
    return "";
  }
};

export const setSalesProjection = async (
  businessId: string,
  projectionId: string | null,
  day: number,
  salesProj: number
) => {
  try {
    if (!projectionId && !day) return Promise.reject("illegal arguments");
    if (projectionId) {
      await db.collection("Projections").doc(projectionId).update({
        salesProj,
        updatedAt: new Date(),
      });
      return projectionId;
    } else {
      const result = await db.collection("Projections").add({
        date: day,
        targetAll: null,
        targetFoh: null,
        targetBoh: null,
        salesProj,
        enabled: true,
        deleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        businessId,
      });
      return result.id;
    }
  } catch (e) {
    console.warn(e);
    return "";
  }
};
