import { Dialog, Icon } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "model/store";

import "./style.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const AssignedPersonModal = ({ isOpen, closeModal }: Props) => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  return (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose
      onClose={closeModal}
      className="assigned-person-modal"
    >
      <Box className="modal-header">
        <Icon
          icon="delete"
          iconSize={18}
          color="#454545"
          onClick={closeModal}
        />
        Brandon Herron
      </Box>
      <Box className="modal-body">
        <Box
          className="modal-hero"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <div className="title">Scheduled This Week:</div>
            <div className="hours">22 hours</div>
          </Box>
          <Box>
            <div className="tag">
              <span className="dot" />
              Part Time
            </div>
          </Box>
        </Box>
        <Box className="assignment-row" display="flex" flexDirection="row">
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.5}
            flexDirection="column"
          >
            <Box>
              <div className="title">Monday, Mar 29</div>
              <div className="subtitle">Bartender</div>
              <Icon icon="trash" iconSize={14} color="#EC6A5E" />
            </Box>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
        </Box>
        <Box className="assignment-row" display="flex" flexDirection="row">
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.5}
            flexDirection="column"
          >
            <Box>
              <div className="title">Monday, Mar 29</div>
              <div className="subtitle">Bartender</div>
              <Icon icon="trash" iconSize={14} color="#EC6A5E" />
            </Box>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
        </Box>
        <Box className="assignment-row" display="flex" flexDirection="row">
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.5}
            flexDirection="column"
          >
            <Box>
              <div className="title">Monday, Mar 29</div>
              <div className="subtitle">Bartender</div>
              <Icon icon="trash" iconSize={14} color="#EC6A5E" />
            </Box>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
          <Box
            className="assignment-cell"
            display="flex"
            flex={0.25}
            flexDirection="column"
          >
            <div className="title">Start</div>
            <div className="subtitle">9:00 am</div>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
export default AssignedPersonModal;
