import { Button, Dialog } from "@blueprintjs/core";
import React from "react";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
const LaborReportModal = ({ isOpen, closeModal }: Props) => (
  <Dialog
    isOpen={isOpen}
    className="labor-report-modal"
    hasBackdrop
    onClose={closeModal}
  >
    <div className="modal-header">
      <div>
        <div className="modal-title">Daily Labor Report</div>
        <div className="modal-subtitle">June 1, 2021</div>
      </div>
      <Button
        text="Export"
        rightIcon={
          <img src={require("../../../../assets/export.svg").default} />
        }
        className="export-button"
      />
    </div>
    <div className="modal-body">
      <table>
        <tr>
          <th>EMPLOYEE</th>
          <th>ROLE</th>
          <th>HOURS</th>
          <th>ESTIMATED WAGES</th>
          <th>ALLOCATED</th>
          <th>CASH PAYOUT</th>
          <th>ESTIMATED TOTAL</th>
          <th>EFFECTIVE RATE (p/h)</th>
        </tr>
        {[1, 2, 3, 4, 5].map(() => (
          <tr>
            <td>Jen Wyatt</td>
            <td>Manager</td>
            <td>36</td>
            <td>$1,151.10</td>
            <td>$1,151.10</td>
            <td>$1,151.10</td>
            <td>$1,151.10</td>
            <td>$25</td>
          </tr>
        ))}
      </table>
    </div>
  </Dialog>
);
export default LaborReportModal;
