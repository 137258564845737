import _ from "lodash";

import isDev from "utils/currentEnv";

import { DraggableItem, DraggableType } from "types/document";

import firebase from "../config/firebase";
import {
  FirebaseMenuCategoryDoc,
  FirebaseMenuDoc,
  MenuAndCategoriesData,
  MenuCategoryDoc,
  ProductIndexingByOrders,
} from "../types/menus";
import { FirebaseProductDoc } from "../types/products";
import { isEqual } from "../utils/data";

const db = firebase.firestore();
const menuCollection = db.collection("Menus");
const menuCategoriesCollection = db.collection("MenuCategories");

export const performSoftDelete = async (menuCategoryIds: string[]) => {
  const existingMenuCategories = [];
  for await (const id of menuCategoryIds) {
    if (id && id.length > 0) {
      existingMenuCategories.push({ id, deleted: true });
    }
  }

  // Batch delete menu categories
  _.chunk(existingMenuCategories, 500).forEach((menuCategories) => {
    const batch = db.batch();
    menuCategories.forEach((category) => {
      const docRef = menuCategoriesCollection.doc(category.id);
      batch.update(docRef, category);
    });
    batch.commit();
  });
};

export const saveData = async (
  newData: MenuCategoryDoc[],
  existingData: MenuCategoryDoc[],
  businessId: string,
  products: FirebaseProductDoc[],
  menuId: string,
  isEnterpriseLevel: boolean,
  currentMenuCategoryRef: { [T: string]: string }
) => {
  const productsRef: { [T: string]: string } = products.reduce(
    (acc, val) => ({ ...acc, [val.nameExternal]: val.id }),
    {}
  );

  const convertMenuCategoryDocToFirebaseMenuCategoryDoc = (
    data: MenuCategoryDoc[]
  ): FirebaseMenuCategoryDoc[] => {
    return data.reduce((acc: FirebaseMenuCategoryDoc[], menuCategory) => {
      const existingProducts: string[] = acc
        .filter(
          (category) =>
            category.id === menuCategory.id &&
            category.name.trim() === menuCategory.menuCategory.trim()
        )
        .reduce((allProducts: string[], category) => {
          return [...allProducts, ...category.products];
        }, []);

      const productId: string = productsRef[menuCategory.nameExternal];

      if (productId) {
        if (existingProducts.length === 0) {
          const menuCategoryIsSame =
            currentMenuCategoryRef[menuCategory.menuCategory.trim()] ===
            menuCategory.id;
          return [
            ...acc,
            {
              id: menuCategoryIsSame ? menuCategory.id : "",
              name: menuCategory.menuCategory.trim(),
              products: [productId],
              enabled: menuCategory.enabled === "Available",
              createdAt: new Date(),
              updatedAt: new Date(),
              description: "",
              deleted: false,
              businessId: businessId,
              enterpriseUpdatedDocument: isEnterpriseLevel,
            },
          ];
        } else {
          return acc.map((category) => {
            if (
              category.id === menuCategory.id &&
              category.name.trim() === menuCategory.menuCategory.trim()
            ) {
              const menuCategoryIsSame =
                currentMenuCategoryRef[menuCategory.menuCategory.trim()] ===
                menuCategory.id;
              return {
                id: menuCategoryIsSame ? category.id : "",
                name: menuCategory.menuCategory.trim(),
                products: [...category.products, productId],
                enabled: menuCategory.enabled === "Available",
                createdAt: category.createdAt,
                updatedAt: new Date(),
                businessId: businessId,
                deleted: false,
                description: category.description,
                enterpriseUpdatedDocument: isEnterpriseLevel,
              };
            }
            return category;
          });
        }
      }
      return acc;
    }, []);
  };

  const newMenuCategories =
    convertMenuCategoryDocToFirebaseMenuCategoryDoc(newData);
  const existingMenuCategories =
    convertMenuCategoryDocToFirebaseMenuCategoryDoc(existingData);

  const firebaseMenuCategories = newMenuCategories.filter((menuCategory) => {
    const menuCategoryToCheck = _.omit(
      _.omit(menuCategory, "createdAt"),
      "updatedAt"
    );

    const foundItem = existingMenuCategories.some((existingMenuCategory) => {
      const existingMenuCategoryToCheck = _.omit(
        _.omit(existingMenuCategory, "createdAt"),
        "updatedAt"
      );
      return isEqual(existingMenuCategoryToCheck, menuCategoryToCheck);
    });

    if (foundItem) {
      return false;
    }
    return true;
  });

  const menuCategoryIds: string[] = _.union(
    newMenuCategories
      .filter((category) => !!category.id)
      .map((category) => category.id),
    []
  );

  const existingCategories = [];
  const newCategories = [];
  for (const menuCategory of firebaseMenuCategories) {
    // For existing menu categories - just find the differences
    // to update
    if (menuCategory.id) {
      const index = existingMenuCategories.findIndex(
        (existingMenuCategory) => existingMenuCategory.id === menuCategory.id
      );
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingMenuCategories[index]
        )
          .filter(
            (key) =>
              !isEqual(
                // @ts-ignore
                existingMenuCategories[index][key],
                // @ts-ignore
                newMenuCategories[index][key]
              ) && Object.keys(menuCategory).includes(key)
          )
          .reduce(
            (acc, key) => ({
              ...acc,
              // @ts-ignore
              [key]: menuCategory[key],
              enterpriseUpdatedDocument: isEnterpriseLevel,
            }),
            {}
          );

        existingCategories.push({ id: menuCategory.id, ...dataToUpdate });

        if (!menuCategoryIds.includes(menuCategory.id)) {
          menuCategoryIds.push(menuCategory.id);
        }
      }

      // For new menu categories then create a new document
    } else {
      const docRef = menuCategoriesCollection.doc();
      menuCategory["id"] = docRef.id;

      newCategories.push(menuCategory);
      if (!menuCategoryIds.includes(menuCategory.id)) {
        menuCategoryIds.push(menuCategory.id);
      }
    }
  }

  // Batch write new menu categories
  _.chunk(newCategories, 500).forEach((categories) => {
    const batch = db.batch();
    categories.forEach((category) => {
      const docRef = menuCategoriesCollection.doc(category.id);
      batch.set(docRef, category);
    });
    batch.commit();
  });

  // Batch update existing menu categories
  _.chunk(existingCategories, 500).forEach((categories) => {
    const batch = db.batch();
    categories.forEach((category) => {
      const docRef = menuCategoriesCollection.doc(category.id);
      batch.update(docRef, category);
    });
    batch.commit();
  });

  // Only update menus if there's an update to menu categories
  if (firebaseMenuCategories.length > 0) {
    await menuCollection
      .doc(menuId)
      .update({ categories: menuCategoryIds, updatedAt: new Date() });
  }
};

export const toggleMenuEnabled = async (menuId: string, flag: boolean) => {
  try {
    await menuCollection
      .doc(menuId)
      .update({ enabled: flag, updatedAt: new Date() });
  } catch (err) {
    if (isDev()) {
    }
    console.log("Failed to set menu as disabled. Id: ", menuId);
  }
};

export const changeMenuName = async (menuId: string, name: string) => {
  try {
    await menuCollection.doc(menuId).update({
      nameExternal: name,
      nameInternal: name,
      updatedAt: new Date(),
    });
  } catch (err) {
    if (isDev()) {
      console.log("Failed to change menu name. Id: ", menuId);
    }
  }
};

export const setMenuAsDeleted = async (menuId: string) => {
  try {
    await menuCollection
      .doc(menuId)
      .update({ deleted: true, updatedAt: new Date() });
  } catch (err) {
    if (isDev()) {
      console.log("Failed to set menu as deleted. Id: ", menuId);
    }
  }
};

export const deleteMenuForBusinesses = async (menuName: string) => {
  try {
    const documents = await menuCollection
      .where("nameExternal", "==", menuName)
      .where("deleted", "==", false)
      .get();
    if (documents.docs.length > 0) {
      for await (const document of documents.docs) {
        await menuCollection
          .doc(document.id)
          .update({ deleted: true, updatedAt: new Date() });
      }
    }
  } catch (err) {
    if (isDev()) {
      console.log("Failed to delete menus from locations", err);
    }
  }
};

export const createDuplicateMenu = async (
  menu: FirebaseMenuDoc,
  menuName: string,
  index: number,
  allMenuCategoriesAndData: MenuAndCategoriesData[],
  isEnterpriseLevel: boolean
) => {
  const existingMenu = allMenuCategoriesAndData.filter(
    (existingMenu) => existingMenu.menuName === menu.nameExternal
  );

  if (existingMenu.length > 0) {
    const categoryIds = [];

    for await (const menuCategory of existingMenu[0].data) {
      try {
        const newCategory = menuCategory;
        const menuCategoryDocRef = menuCategoriesCollection.doc();
        newCategory["id"] = menuCategoryDocRef.id;
        newCategory["createdAt"] = new Date();
        newCategory["updatedAt"] = new Date();

        categoryIds.push(newCategory["id"]);

        await menuCategoryDocRef.set(newCategory, { merge: true });
      } catch (err) {
        if (isDev()) {
          console.log("Failed to create a category for document");
        }
      }
    }

    const duplicateMenu: FirebaseMenuDoc = {
      businessId: menu.businessId,
      categories: categoryIds,
      createdAt: new Date(),
      deleted: false,
      enabled: true,
      endDate: menu.endDate || null,
      hours: menu.hours,
      id: "",
      index: index,
      isDineInMenu: true,
      isInternalMenu: false,
      isDeliveryAndCarryOutMenu: true,
      isDineInPwaMenu: true,
      nameExternal: menuName,
      nameInternal: menuName,
      privateMenu: menu.privateMenu || false,
      spendingLimit: menu.spendingLimit || null,
      startDate: menu.startDate || null,
      tags: [],
      updatedAt: new Date(),
      enterpriseUpdatedDocument: isEnterpriseLevel,
    };
    try {
      const docRef = menuCollection.doc();
      duplicateMenu["id"] = docRef.id;

      await docRef.set(duplicateMenu, { merge: true });
    } catch (err) {
      if (isDev()) {
        console.log("Failed to create duplicate");
      }
    }
  }
};

export const addNewMenu = async (
  menu: FirebaseMenuDoc,
  menusAndMenuCategories: {
    category: FirebaseMenuCategoryDoc;
    menu: FirebaseMenuDoc;
  }[]
) => {
  const categoryIds = [];
  // Give each copied category a new id and store it in firebase
  for await (const menus of menusAndMenuCategories) {
    const category = menus.category;

    try {
      const newCategory = category;
      const menuCategoryDocRef = menuCategoriesCollection.doc();
      newCategory["id"] = menuCategoryDocRef.id;
      newCategory["createdAt"] = new Date();
      newCategory["updatedAt"] = new Date();

      categoryIds.push(newCategory["id"]);

      await menuCategoryDocRef.set(newCategory, { merge: true });
    } catch (err) {
      if (isDev()) {
        console.log("Failed to create a category for document");
      }
    }
  }

  try {
    const newMenu = menu;
    const docRef = menuCollection.doc();
    newMenu["id"] = docRef.id;
    newMenu["categories"] = categoryIds;

    await docRef.set(newMenu, { merge: true });
  } catch (err) {
    if (isDev()) {
      console.log("Failed to create new document");
    }
  }
};

export const reOrderCategoriesAndProducts = async (
  menuToUpdate: FirebaseMenuDoc,
  categoriesOrder: DraggableType,
  productsOrder: ProductIndexingByOrders
) => {
  if (categoriesOrder.length > 0) {
    const categoryIds = categoriesOrder[0].items.map((item) => item.id);
    await menuCollection.doc(menuToUpdate.id).update({
      categories: categoryIds,
    });

    for await (const id of categoryIds) {
      const categoryOrder = productsOrder[id];
      if (categoryOrder) {
        const productIds = categoryOrder.items.map(
          (item: DraggableItem) => item.id
        );
        await menuCategoriesCollection.doc(id).update({
          products: productIds,
        });
      }
    }
  }
};
