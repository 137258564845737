import { Dialog } from "@blueprintjs/core";
import React from "react";

import BoardIcon from "assets/BoardIcon.svg";

import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedTicketData: any;
  selectedBoard: any;
}

export function ModalBoardInfo({ isOpen, closeModal, selectedBoard }: Props) {
  return (
    <>
      <Dialog isOpen={isOpen} className="modalBoardInfo" onClose={closeModal}>
        <div className="modal-header">
          <div className="modal-title">
            <div className="action-div-row">
              <img src={BoardIcon} height="40px" width="40px" />
              <h2>{selectedBoard?.name}</h2>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <div>
            <label>Board Description</label>
          </div>
          <div className="info-container">
            <p>{selectedBoard?.description}</p>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ModalBoardInfo;
