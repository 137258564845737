import { DateRange } from "@blueprintjs/datetime";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _, { debounce } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Assign } from "utility-types";

import {
  StaffMemberOrientedData,
  fetchStaffMemberOrientedPayrollData,
  fetchViablePayrollISODateStringEdges,
} from "controllers/payroll";
import {
  extractWorkEventDurationInHours,
  formatCentValue,
} from "controllers/reporting";

import { ColumnInstruction } from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import { usePayrollDateConfiguration } from "./usePayrollDateConfiguration";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const usePayroll = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<
    StaffMember[]
  >([]);
  const [tableSortFields, setTableSortFields] = useState({
    Employee: null,
    TotalGrossPay: null,
    TotalHours: null,
  });
  const [showHourModal, setShowHourModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [workEvents, setWorkEvents] = useState<WorkEvent[]>([]);
  const [selectedEmp, setSelectedEmp] = useState<StaffMember>();
  const [nameFilter, setNameFilter] = useState("");

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const businessId = useMemo(() => business?.id ?? null, [business?.id]);

  const {
    viablePayrollReportEdges,
    selectedStartISODateString,
    selectedEndISODateString,
    maxDateRangePickerDate,
    minDateRangePickerDate,
    dateRangeChangeHandler,
    dateRange,
    setDateRange,
    customDateRangeOptions,
    onCustomDateRangeChange,
  } = usePayrollDateConfiguration();

  const staffMemberOrientedPayroll = useQuery(
    [
      "staffMemberOrientedPayroll",
      businessId,
      selectedStartISODateString,
      selectedEndISODateString,
    ],
    async () => {
      if (selectedStartISODateString && selectedEndISODateString) {
        return fetchStaffMemberOrientedPayrollData(
          businessId,
          selectedStartISODateString,
          selectedEndISODateString
        );
      }
      return null;
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        Boolean(businessId) &&
        Boolean(selectedStartISODateString) &&
        Boolean(selectedEndISODateString),
    }
  );

  console.log(
    "staffMemberOrientedPayroll",
    staffMemberOrientedPayroll.data?.staffMemberOrientedData?.filter(
      (s) => s.fullName === "Leslie McIntosh"
    )
  );

  const totalGrossPay = useMemo(() => {
    if (!staffMemberOrientedPayroll.data) return 0;
    return staffMemberOrientedPayroll.data.staffMemberOrientedData.reduce(
      (acc, curr) => acc + curr.totalGrossPay,
      0
    );
  }, [staffMemberOrientedPayroll.data]);

  const formattedTotalGrossPay = useMemo(() => {
    return formatter.format(formatCentValue(totalGrossPay));
  }, [totalGrossPay]);

  const columns: ColumnInstruction<
    Assign<
      StaffMemberOrientedData,
      { uniqueId: string; formattedTotalGrossPay: string }
    >
  >[] = [
    { type: "data", header: "Emploee", attribute: "fullName" },
    { type: "data", header: "Primary Role", attribute: "primaryRoleTitle" },
    { type: "data", header: "Total Hours", attribute: "totalHours" },
    {
      type: "data",
      header: "Total Gross Pay",
      attribute: "formattedTotalGrossPay",
    },
  ];
  const [searchFilter, setSearchFilter] = useState("");

  const tableData = useMemo(() => {
    if (!staffMemberOrientedPayroll.data) return [];
    return _.uniqBy(
      staffMemberOrientedPayroll.data.staffMemberOrientedData
        .filter((sm) =>
          sm.fullName.toLocaleLowerCase().includes(searchFilter.toLowerCase())
        )
        .map((smData) => ({
          ...smData,
          formattedTotalGrossPay: formatter.format(
            formatCentValue(smData.totalGrossPay)
          ),
          uniqueId: smData.staffUID,
        })),
      "uniqueId"
    );
  }, [staffMemberOrientedPayroll.data, searchFilter]);

  console.log(
    "tableData",
    tableData.filter((s) => s.fullName === "Leslie McIntosh")
  );

  const onSearchChange = useCallback(
    (v: string) => {
      setSearchFilter(v);
    },
    [setSearchFilter]
  );

  const sortedStaffMembersWithPayrollData = useMemo(() => {
    const staffWithData = (
      staffMemberOrientedPayroll.data?.staffMemberOrientedData ?? []
    ).filter((sm) =>
      sm.fullName.toLowerCase().includes(nameFilter.toLowerCase())
    );
    if (!staffWithData) {
      return [];
    }
    if (tableSortFields.TotalGrossPay) {
      return _.orderBy(
        staffWithData,
        ["totalGrossPay"],
        [tableSortFields.TotalGrossPay]
      );
    }
    if (tableSortFields.TotalHours) {
      return _.orderBy(
        staffWithData,
        ["totalHours"],
        [tableSortFields.TotalHours]
      );
    }

    if (tableSortFields.Employee) {
      return _.orderBy(staffWithData, ["fullName"], [tableSortFields.Employee]);
    }
    return staffWithData;
  }, [
    staffMemberOrientedPayroll.data?.staffMemberOrientedData,
    tableSortFields?.TotalHours,
    tableSortFields?.TotalGrossPay,
    tableSortFields?.Employee,
    nameFilter,
  ]);

  const calculateHours = useCallback(
    (payrates: PayRate[], workevent: WorkEvent[], staff: StaffMember) => {
      if (payrates.length > 0) {
        return payrates.reduce(function (a: number, b: PayRate) {
          const getEventforRoleId = () => {
            const events: WorkEvent[] = [];
            workevent.forEach((item: WorkEvent) => {
              if (item.position == b.roleId && item.staffId == staff.id) {
                // count = count + 1;
                events.push(item);
              }
            });
            return events;
          };

          const events = getEventforRoleId();

          //calculate events duration and sum
          let durationofRoleId = 0;
          if (events.length > 0) {
            durationofRoleId = events.reduce(function (
              a: number,
              b: WorkEvent
            ) {
              return a + extractWorkEventDurationInHours(b);
            },
            0);
          }

          if (durationofRoleId != undefined) {
            return a + durationofRoleId;
          } else {
            return 0;
          }
        }, 0);
      } else {
        return 0;
      }
    },
    []
  );

  const calculateGrossPay = useCallback(
    (payrates: PayRate[], workevent: WorkEvent[], staff: StaffMember) => {
      if (payrates.length > 0) {
        return payrates.reduce(function (a: number, b: PayRate) {
          const getEventforRoleId = () => {
            const events: WorkEvent[] = [];
            workevent.forEach((item: WorkEvent) => {
              if (item.position == b.roleId && item.staffId == staff.id) {
                // count = count + 1;
                events.push(item);
              }
            });
            return events;
          };
          const amount = b?.amount;
          const events = getEventforRoleId();

          //calculate events duration and sum
          let durationofRoleId = 0;
          if (events.length > 0) {
            durationofRoleId = events.reduce(function (
              a: number,
              b: WorkEvent
            ) {
              return a + extractWorkEventDurationInHours(b) * amount;
            },
            0);
          }

          if (durationofRoleId != undefined) {
            return a + durationofRoleId;
          } else {
            return 0;
          }
        }, 0);
      } else {
        return 0;
      }
    },
    []
  );

  const [
    selectedEmployeeIdForPayrollDetails,
    setSelectedEmployeeIdForPayrollDetails,
  ] = useState<string | null>(null);

  const selectEmployeeForPayrollDetails = useCallback((v: string | null) => {
    setSelectedEmployeeIdForPayrollDetails(v);
  }, []);

  const selectedEmployeeDetails = useMemo(() => {
    if (!selectedEmployeeIdForPayrollDetails) return null;
    const fetchedDataForSelectedStaffMember =
      sortedStaffMembersWithPayrollData.find(
        (sd) => sd.staffUID === selectedEmployeeIdForPayrollDetails
      );
    if (!fetchedDataForSelectedStaffMember) {
      return null;
    }
    return fetchedDataForSelectedStaffMember;
  }, [selectedEmployeeIdForPayrollDetails, sortedStaffMembersWithPayrollData]);

  const getWorkEventAndStaff = useCallback(async () => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.startDate;
    const filteredEvents = workEvents.filter((a: WorkEvent) => {
      const date = a.clockedIn?.toDate();
      return date >= startDate! && date <= endDate!;
    });
    const data = fellowStaffMembers.map((item) => {
      const Hours = calculateHours(item?.payRates, filteredEvents, item);
      const GrossPay = calculateGrossPay(item?.payRates, filteredEvents, item);
      const tag =
        item?.contact?.firstName[0]?.toUpperCase() +
        item?.contact?.lastName[0]?.toUpperCase();
      const fullName = item?.contact?.firstName + " " + item?.contact?.lastName;
      return {
        selected: false,
        ...item,
        // totalGrossPAy: item?.payRates?.reduce(function (a: any, b: any) {
        //   return a + b?.amount;
        // }, 0),
        fullName: fullName,
        tagName: tag,
        totalGrossPAy: GrossPay,
        TotalHours: Hours,
      };
    });
    setStaffMembers(data);
  }, [workEvents, dateRange, fellowStaffMembers]);

  useEffect(() => {
    if (
      business &&
      fellowStaffMembers &&
      workEvents.length >= 0 &&
      dateRange.startDate !== null
    ) {
      getWorkEventAndStaff();
    }
  }, [fellowStaffMembers, business, workEvents, dateRange]);

  const updateSortingFieldStates = (columnName: string, orderBy: string) => {
    const columnFields: any = {
      Employee: null,
      TotalGrossPay: null,
      TotalHours: null,
    };

    columnFields[columnName] = orderBy;
    setTableSortFields(columnFields);
  };

  const sortTableOnField = (columnName: string, orderBy: string) => {
    const tableData = staffMembers.map((item) => item);
    let sortData: any[] = [];
    switch (columnName) {
      case "Employee":
        if (orderBy == "asc") {
          sortData = _.orderBy(tableData, (o) => o.fullName, ["asc"]);
          updateSortingFieldStates(columnName, orderBy);
        } else if (orderBy == "desc") {
          sortData = _.orderBy(tableData, (o) => o.fullName, ["desc"]);
          updateSortingFieldStates(columnName, orderBy);
        }
        if (sortData.length > 0) {
          setStaffMembers(sortData);
        }

        break;
      case "TotalGrossPay":
        if (orderBy == "asc") {
          sortData = _.orderBy(tableData, (o) => o.totalGrossPAy, ["asc"]);
          updateSortingFieldStates(columnName, orderBy);
        } else if (orderBy == "desc") {
          sortData = _.orderBy(tableData, (o) => o.totalGrossPAy, ["desc"]);
          updateSortingFieldStates(columnName, orderBy);
        }
        if (sortData.length > 0) {
          setStaffMembers(sortData);
        }
        break;
      case "TotalHours":
        if (orderBy == "asc") {
          sortData = _.orderBy(tableData, (o) => o.TotalHours, ["asc"]);
          updateSortingFieldStates(columnName, orderBy);
        } else if (orderBy == "desc") {
          sortData = _.orderBy(tableData, (o) => o.TotalHours, ["desc"]);
          updateSortingFieldStates(columnName, orderBy);
        }
        if (sortData.length > 0) {
          setStaffMembers(sortData);
        }
        break;
    }
  };

  const debouncedfilter = React.useRef(
    debounce(async (text) => {
      setNameFilter(text);
    }, 300)
  ).current;

  useEffect(() => {
    debouncedfilter(searchText);
  }, [searchText]);

  useEffect(() => {
    return () => {
      debouncedfilter.cancel;
    };
  }, [debouncedfilter]);

  const generalLoading =
    viablePayrollReportEdges.isLoading ||
    viablePayrollReportEdges.isFetching ||
    staffMemberOrientedPayroll.isFetching ||
    staffMemberOrientedPayroll.isLoading;

  return {
    setSelectedEmp,
    setShowHourModal,
    dateRange,
    setDateRange,
    searchText,
    setSearchText,
    isSearching,
    staffMembers,
    filteredStaffMembers,
    selectedEmp,
    showHourModal,
    workEvents,
    tableSortFields,
    setTableSortFields,
    sortTableOnField,
    dateRangeChangeHandler,
    viablePayrollReportEdges,
    maxDateRangePickerDate,
    minDateRangePickerDate,
    staffMemberOrientedPayroll,
    sortedStaffMembersWithPayrollData,
    selectedEmployeeDetails,
    selectEmployeeForPayrollDetails,
    businessId,
    columns,
    tableData,
    selectedStartISODateString,
    selectedEndISODateString,
    customDateRangeOptions,
    onCustomDateRangeChange,
    generalLoading,
    onSearchChange,
    searchFilter,
    formattedTotalGrossPay,
  };
};
