import React, { useEffect, useState } from "react";

import {
  Condition,
  MainCondition,
  OperationLogic,
  OperationLogicOptions,
  OperatorOptions,
  Operators,
  Rule,
} from "../types";

const useLogic = (
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>,
  questions: any[]
) => {
  const [originalQuestions, setOriginalQuestions] = useState<any[]>([]);

  useEffect(() => {
    setOriginalQuestions([...questions]);
  }, []);
  const operatorOptions: OperatorOptions[] = [
    {
      label: "is equal to",
      value: "is-equal-to",
    },
    {
      label: "is not equal to",
      value: "is-not-equal-to",
    },
    {
      label: "begins with",
      value: "begins-with",
    },
    {
      label: "ends with",
      value: "ends-with",
    },
    {
      label: "contains",
      value: "contains",
    },
    {
      label: "does not contain",
      value: "does-not-contain",
    },
  ];
  const operationLogicOptions: OperationLogicOptions[] = [
    {
      label: "Jump to",
      value: "jump-to",
    },
  ];
  const removeAll = () => {
    setQuestions(originalQuestions);
  };
  const addRuleToQuestion = (questionId: number) => {
    const updatedQuestions = [...questions].map((item, index) => {
      if (item.id === questionId) {
        const newRule: Rule = {
          origin: index + 1,
          mainCondition: "if",
          operationLogic: "jump-to",
          destination: null,
          default: false,
          conditions: [
            {
              operator: "is-equal-to",
              operationValue: "",
              chainBy: null,
            },
          ],
        };
        return { ...item, rules: [...item.rules, newRule] };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };

  const updateCondition = (
    key: keyof Condition,
    value: Operators | OperationLogic | MainCondition | string,
    questionIndex: number,
    ruleIndex: number,
    conditionIndex: number
  ) => {
    const clonedQuestions = [...questions];
    const clonedRules = clonedQuestions[questionIndex]["rules"];
    const updatedRules = clonedRules.map((rule: Rule, index: number) => {
      if (index === ruleIndex) {
        const clonedConditions = rule.conditions;
        // @ts-ignore
        clonedConditions[conditionIndex][key] = value;
        return { ...rule, conditions: clonedConditions };
      } else {
        return rule;
      }
    });
    const updatedQuestions = clonedQuestions.map((item, index) => {
      if (index === questionIndex) {
        return { ...item, rules: updatedRules };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };

  const updateRule = (
    key: keyof Rule,
    value: MainCondition | number,
    questionIndex: number,
    ruleIndex: number
  ) => {
    const clonedQuestions = [...questions];
    const clonedRules = clonedQuestions[questionIndex]["rules"];
    const updatedRules = clonedRules.map((rule: Rule, index: number) => {
      if (index === ruleIndex) {
        return { ...rule, [key]: value };
      } else {
        return rule;
      }
    });
    const updatedQuestions = clonedQuestions.map((item, index) => {
      if (index === questionIndex) {
        return { ...item, rules: updatedRules };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };
  const deleteRule = (questionIndex: number, ruleIndex: number) => {
    const clonedQuestions = [...questions];
    const clonedRules = clonedQuestions[questionIndex]["rules"];
    clonedRules.splice(ruleIndex, 1);
    const updatedQuestions = clonedQuestions.map((item, index) => {
      if (index === questionIndex) {
        return { ...item, rules: clonedRules };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };
  const addNewCondition = (questionIndex: number, ruleIndex: number) => {
    const clonedQuestions = [...questions];
    const clonedRules = clonedQuestions[questionIndex]["rules"];
    const updatedRules = clonedRules.map((rule: Rule, index: number) => {
      if (index === ruleIndex) {
        return {
          ...rule,
          conditions: [
            ...rule.conditions,
            {
              operator: "is-equal-to",
              operationValue: "",
              chainBy: "and",
            },
          ],
        };
      } else {
        return rule;
      }
    });
    const updatedQuestions = clonedQuestions.map((item, index) => {
      if (index === questionIndex) {
        return { ...item, rules: updatedRules };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };

  const deleteCondition = (
    questionIndex: number,
    ruleIndex: number,
    conditionIndex: number
  ) => {
    const clonedQuestions = [...questions];
    const clonedRules = clonedQuestions[questionIndex]["rules"];
    const updatedRules = clonedRules.map((rule: Rule, index: number) => {
      if (index === ruleIndex) {
        const clonedConditions = rule.conditions;
        clonedConditions.splice(conditionIndex, 1);
        if (clonedConditions.length === 1) {
          clonedConditions[0].chainBy = null;
        }
        return { ...rule, conditions: clonedConditions };
      } else {
        return rule;
      }
    });
    const updatedQuestions = clonedQuestions.map((item, index) => {
      if (index === questionIndex) {
        return { ...item, rules: updatedRules };
      } else {
        return item;
      }
    });
    setQuestions(updatedQuestions);
  };
  return {
    addRuleToQuestion,
    operatorOptions,
    operationLogicOptions,
    updateRule,
    updateCondition,
    addNewCondition,
    deleteCondition,
    deleteRule,
    removeAll,
  };
};
export default useLogic;
