import _ from "lodash";
import moment from "moment-timezone";
import React from "react";

import {
  roundNumberToTwoDecimals,
  sanitizeNumericField,
} from "controllers/reporting";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import { convertToBusinessTimezoneMoment } from "utils/dateUtils";

import {
  getPositionById,
  getPositionNameById,
  getShiftTypeById,
} from "model/selectors/businessSettings";

import { SmallSlotCard } from "../SlotCard";

interface StaffMembersWeeklySchedule {
  staffId: string;
  staffFullName: string;
  shifts: TangoShift[];
  totalPay: number;
  totalHours: number;
  isOverTime: boolean;
}

interface StaffMemberOrientedWeeklyViewProps {
  weekRange: Date[];
  business: TangoBusiness;
  businessSettings: TangoBusinessSettings;
  departmentScheduleViewType: DepartmentId | null;
  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  roleFilterId: string | null;
  staffMemberNameFilter: string;
  fellowStaffMembers: StaffMember[];
  setSelectedShiftForEdit: (v: TangoShift | null) => void;
}

export const StaffMemberOrientedWeeklyView = ({
  weekRange,
  business,
  businessSettings,
  departmentScheduleViewType,
  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  roleFilterId,
  staffMemberNameFilter,
  fellowStaffMembers,
  setSelectedShiftForEdit,
}: StaffMemberOrientedWeeklyViewProps) => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      fellowStaffMembers.find((s) => s?.id === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const shiftsForTheWeek =
    scheduleForWeekRange?.shifts ||
    duplicatedScheduleForAWeekRange?.shifts ||
    [];
  const shiftsGroupedByStaffId = _.groupBy(shiftsForTheWeek, "staffId");
  const nameFilter = (staffMember: StaffMember) =>
    staffMemberNameFilter
      ? `${staffMember?.contact?.firstName} ${staffMember?.contact?.lastName}`.includes(
          staffMemberNameFilter
        )
      : true;
  const roleFilter = (staffMember: StaffMember) =>
    roleFilterId
      ? staffMember?.primaryRole === roleFilterId ||
        staffMember?.secondaryRoles?.includes(roleFilterId)
      : true;
  const departmentFilter = (staffMember: StaffMember) =>
    departmentScheduleViewType
      ? [staffMember?.primaryRole, ...(staffMember?.secondaryRoles || [])].find(
          (roleId) =>
            getPositionById(businessSettings, roleId || "")?.departmentId ===
            departmentScheduleViewType
        )
      : true;
  const filteredStaffMembers = fellowStaffMembers.filter(
    (staffMember) =>
      departmentFilter(staffMember) &&
      nameFilter(staffMember) &&
      roleFilter(staffMember)
  );
  const staffSchedules = _.keys(shiftsGroupedByStaffId)
    .filter((key) => key !== "null")
    .map((staffId: string): StaffMembersWeeklySchedule | null => {
      const staffMember = filteredStaffMembers?.find(
        (staff) => staff?.id === staffId
      );
      if (!staffMember) return null;
      const staffFirstName = staffMember?.contact?.firstName;
      const staffLastName = staffMember?.contact?.lastName;
      const shifts = shiftsGroupedByStaffId?.[staffId] || [];

      const totalPayReducer = (accumulator: number, item: TangoShift) => {
        if (item?.staffId) {
          const hoursDuration = roundNumberToTwoDecimals(
            sanitizeNumericField(
              Math.abs(
                moment
                  .duration(
                    moment(item?.startDate.toMillis()).diff(
                      moment(item?.endDate.toMillis())
                    )
                  )
                  .asHours()
              )
            )
          );
          return (
            accumulator +
            roundNumberToTwoDecimals(
              sanitizeNumericField(
                getPayrateByPostionAndStaffId(item.position, item.staffId) *
                  hoursDuration
              )
            )
          );
        }
        return accumulator;
      };
      const totalHoursReducer = (accumulator: number, item: TangoShift) => {
        if (item?.staffId) {
          const hoursDuration = roundNumberToTwoDecimals(
            sanitizeNumericField(
              Math.abs(
                moment
                  .duration(
                    moment(item?.startDate.toMillis()).diff(
                      moment(item?.endDate.toMillis())
                    )
                  )
                  .asHours()
              )
            )
          );
          return accumulator + hoursDuration;
        }
        return accumulator;
      };
      const totalPay = shifts.reduce(totalPayReducer, 0);
      const totalHours = shifts.reduce(totalHoursReducer, 0);
      const isOverTime = totalHours > 40;
      return {
        staffId: staffId,
        staffFullName: `${staffFirstName} ${staffLastName}`,
        shifts,
        totalPay: roundNumberToTwoDecimals(sanitizeNumericField(totalPay)),
        totalHours: roundNumberToTwoDecimals(sanitizeNumericField(totalHours)),
        isOverTime,
      };
    })
    .filter((x) => !!x) as StaffMembersWeeklySchedule[];
  const highestNumberOfShiftsInADay = (
    sch: StaffMembersWeeklySchedule
  ): number => {
    const staffShiftsGroupedByDay = _.groupBy(sch.shifts, (shift) => {
      const momentWithTimezone = moment(shift?.startDate?.toDate());
      momentWithTimezone.tz(business.timezone, false);
      return convertToBusinessTimezoneMoment(
        shift?.startDate?.toDate(),
        business
      ).day();
    });
    const lengths = _.keys(staffShiftsGroupedByDay).map(
      (key) => staffShiftsGroupedByDay?.[key]?.length
    );
    return _.max(lengths) || 0;
  };

  return (
    <Box
      style={{
        width: "80vw",
        overflowY: "scroll",
        overflowX: "scroll",
        height: "93vh",
      }}
    >
      <Box
        display="flex"
        className="staff-member-view"
        style={{
          paddingBottom: "15%",
          minWidth: 1500,
          width: "100%",
          overflowX: "scroll",
        }}
      >
        <Box className="day-column">
          <Box className="day-header"></Box>
          {staffSchedules.map((staffSchedule) => {
            const highestNumberOfShifts =
              highestNumberOfShiftsInADay(staffSchedule);
            return (
              <>
                <Box className="grid-cell">
                  <div
                    style={{
                      color: "#454545",
                      fontSize: "0.775rem",
                      fontFamily: "SFProText-Semibold",
                    }}
                  >
                    {staffSchedule?.staffFullName}
                  </div>
                </Box>
                {_.range(highestNumberOfShifts - 1).map((i) => (
                  <Box
                    key={`${staffSchedule.staffId}_name_cell_${i}`}
                    className="grid-cell"
                  />
                ))}
              </>
            );
          })}
        </Box>
        {weekRange.map((item: any, index: number) => (
          <Box className="day-column" key={new Date(item).toString()}>
            <Box className="day-header">
              <div className="day-name">{moment(item).format("ddd")}</div>
              <div className="day-date">{moment(item).format("D")}</div>
            </Box>
            <>
              {staffSchedules.map((staffSchedule) => {
                const shiftsFoADay = staffSchedule?.shifts?.filter(
                  (shift) =>
                    convertToBusinessTimezoneMoment(
                      shift?.startDate?.toDate(),
                      business
                    ).day() ===
                    convertToBusinessTimezoneMoment(item, business).day()
                );

                const highestNumberOfShifts =
                  highestNumberOfShiftsInADay(staffSchedule);
                return (
                  <>
                    {shiftsFoADay.map((shift) => {
                      const color = getShiftTypeById(
                        businessSettings,
                        shift?.shiftTypeId || ""
                      );
                      const shiftTypeName = color?.name ?? "";
                      return (
                        <Box className="grid-cell" key={shift.id}>
                          <SmallSlotCard
                            title={`${moment(shift?.startTime, "HH:mm").format(
                              "hh:mm a"
                            )} - ${moment(shift?.endTime, "HH:mm").format(
                              "hh:mm a"
                            )}`}
                            subtitle={getPositionNameById(
                              businessSettings,
                              shift?.position || ""
                            )}
                            mainColor={color?.mainColor || "black"}
                            backgroundColor={color?.backgroundColor || "white"}
                            onWeeklyScheduleClick={() => null}
                            shiftTypeName={shiftTypeName}
                            onClick={() => setSelectedShiftForEdit(shift)}
                          />
                        </Box>
                      );
                    })}
                    {highestNumberOfShifts - shiftsFoADay?.length
                      ? _.range(
                          highestNumberOfShifts - shiftsFoADay?.length
                        ).map((i) => (
                          <Box
                            key={`${staffSchedule.staffId}_ext_cell_${i}`}
                            className="grid-cell"
                          />
                        ))
                      : null}
                  </>
                );
              })}
            </>
          </Box>
        ))}
        <Box className="day-column">
          <Box className="day-header"></Box>
          {staffSchedules.map((staffSchedule) => {
            const highestNumberOfShifts =
              highestNumberOfShiftsInADay(staffSchedule);
            const totalPay = (staffSchedule?.totalPay / 100).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            );
            return (
              <div key={staffSchedule.staffId}>
                <Box
                  className="grid-cell"
                  display="flex"
                  flexDirection="column"
                >
                  <div
                    style={{
                      color: staffSchedule.isOverTime ? "#EC6A5E" : "#454545",
                      fontSize: "0.775rem",
                      fontFamily: "SFProText-Semibold",
                    }}
                  >
                    {staffSchedule?.totalHours} hours
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      color: staffSchedule.isOverTime ? "#EC6A5E" : "#454545",
                      fontSize: "0.775rem",
                      fontFamily: "SFProText-Semibold",
                    }}
                  >
                    {totalPay}
                  </div>
                </Box>
                {_.range(highestNumberOfShifts - 1).map((i) => (
                  <Box
                    key={`${staffSchedule.staffId}_total_ext_cell_${i}`}
                    className="grid-cell"
                  />
                ))}
              </div>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
