import { useEffect, useState } from "react";
import { NavigateFunction, useParams } from "react-router-dom";

import { ColumnInstruction } from "components/Table/HorizontalTable";

import {
  deleteFormResponses,
  fetchForm,
  fetchFormResponses,
  updateFormField,
  updateFormResponseData,
} from "../../models/surveyBuilder";
import { Form } from "../ViewForms/useForms";
import { ResponseItem } from "./instructions";

export const columns: ColumnInstruction<ResponseItem>[] = [
  { type: "projection", attribute: "date", header: "Date Created" },
  { type: "data", attribute: "score", header: "Score" },
];

const useFormResponses = (navigate: NavigateFunction) => {
  const [customColumns, setCustomColumns] = useState(columns);

  const [formData, setFormData] = useState<Form>();
  const [selectedPreview, setSelectedPreview] = useState<"desktop" | "mobile">(
    "desktop"
  );
  const [formResponses, setFormResponses] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedResponse, setSelectedResponse] = useState<any | null>(null);
  const [showCreateBoardModal, setShowCreateBoardModal] =
    useState<boolean>(false);
  const [showDeleteFormConfirmationModal, setShowDeleteFormConfirmationModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseRows, setResponseRows] = useState<ResponseItem[]>([]);

  const { id } = useParams<{ id: string | undefined }>();
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await fetchForm(id);
        console.log("response", response);
        const formResponsesData = await fetchFormResponses(id);
        console.log("formResponsesData", formResponsesData);
        if (response.data && formResponsesData.data) {
          const columnsWithQuestion = response.data.questions.map(
            (item: any, index: number) => {
              return {
                type: "data",
                attribute: `question${index + 1}`,
                header: item.title,
              };
            }
          );
          setCustomColumns([...customColumns, ...columnsWithQuestion]);
          setFormData(response.data as Form);
          setFormResponses(formResponsesData.data);
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (formResponses.length) {
      //@ts-ignore
      const newData: ResponseItem[] = formResponses.map((item) => {
        let responseRow = {};
        item.response.forEach((innerItem: any, innerIndex: number) => {
          //@ts-ignore
          responseRow["question" + (innerIndex + 1)] = innerItem.value;
        });
        //@ts-ignore
        responseRow["score"] = checkIsScorePending(item.response)
          ? "Pending"
          : calculateScore(item.response) || 0;
        //@ts-ignore
        responseRow["date"] = item.updatedAt;
        //@ts-ignore
        responseRow["uniqueId"] = item.id;
        return responseRow;
      });
      setResponseRows(newData);
    }
  }, [formResponses]);
  const checkIsScorePending = (responses: any) => {
    const remainingResponses = responses.filter(
      (item: any) => item.isScorePending
    );
    return remainingResponses.length > 0 ? true : false;
  };
  const updateFormResponse = () => {
    updateFormResponseData(selectedResponse);
  };
  const calculateScore = (responses: any) => {
    return responses.reduce(
      (acc: number, current: any) => acc + current.score,
      0
    );
  };
  const updateScore = (responseIndex: number, score: number) => {
    const index = formResponses.findIndex(
      (item) => item.id === selectedResponse.id
    );
    console.log(selectedResponse);

    const clonedQuestion = { ...selectedResponse };
    clonedQuestion["response"][responseIndex].score = score || 0;
    clonedQuestion["response"][responseIndex].isScorePending = false;
    setSelectedResponse(clonedQuestion);
    console.log(clonedQuestion);
    if (formResponses.length > 1) {
      setFormResponses([
        ...formResponses.slice(0, index),
        clonedQuestion,
        ...formResponses[index + 1],
      ]);
    } else {
      setFormResponses([clonedQuestion]);
    }
  };
  const redirectToBoards = () => {
    navigate(`/manager/boards?formId=${id}`);
  };
  const updateFormSettings = async (
    key: keyof Pick<Form, "enabled" | "deleted">,
    value: boolean
  ) => {
    setLoading(true);
    if (id && formData) {
      const response = await updateFormField(id, key, value);
      if (response.data && key === "enabled") {
        setFormData({ ...formData, enabled: value });
      }
      if (key === "deleted" && response.data) {
        const formDeleteResponse = await deleteFormResponses(id);
        if (formDeleteResponse.data) {
          navigate("/manager/forms", { replace: true });
        }
      }
    }
    setLoading(false);
  };
  return {
    formData,
    selectedPreview,
    id,
    formResponses,
    showModal,
    setSelectedPreview,
    setShowModal,
    selectedResponse,
    setSelectedResponse,
    checkIsScorePending,
    updateFormResponse,
    updateScore,
    calculateScore,
    showCreateBoardModal,
    setShowCreateBoardModal,
    redirectToBoards,
    updateFormSettings,
    showDeleteFormConfirmationModal,
    setShowDeleteFormConfirmationModal,
    loading,
    responseRows,
    customColumns,
  };
};
export default useFormResponses;
