import { Icon } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Box from "components/Box";

import Layout from "../../MarketingFeedback/Layout";
import hashIcon from "../icons/hashIcon.svg";
import InfoIcon from "../icons/infoIcon.svg";
import "./styles.css";

const VerticleLign = () => {
  return (
    <div
      style={{
        height: "auto",
        width: "4px",
        backgroundColor: "#E9E9E9",
      }}
    ></div>
  );
};

const AudienceProfile = () => {
  const navigate = useNavigate();
  const [recipients, setRecipients] = useState(null);
  const [showUserListModal, setShowUserListModal] = useState<boolean>(false);

  const [emailInfo, setEmailInfo] = useState({
    To: null,
    senderName: "",
    senderEmail: "",
    subjectLine: "",
  });

  const updateEmailInfo = (key: string, value: string | number) => {
    setEmailInfo({
      ...emailInfo,
      [key]: value,
    });
  };

  const listData = [
    { value: "max@gmail.com", label: "Max" },
    { value: "skote@gmail.com", label: "Skote" },
    { value: "christian@gmail.com", label: "Christian" },
  ];

  return (
    <Layout>
      <div id="audience-profile">
        {/*header*/}
        <Box className="page-head">
          <Box
            display="flex"
            flex-direction="row"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/audiences")}
          >
            <Icon icon="chevron-left" color="#424452" size={12} />
            <span className="backword-text">Back to Audience</span>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="pagehead-leftside">
              <div className="email-title">Brandon Herron</div>
              <div className="email-time">
                Added via <span className="bold-subheader">Admin Add</span> on{" "}
                <span className="bold-subheader">Mon, March 7th 3:33 PM</span>
              </div>
            </Box>
            <Box
              className="pagehead-rightside"
              display="flex"
              flexDirection="row"
              style={{ marginRight: "54px" }}
            >
              <Box className="rates" display="flex" flex-direction="row">
                <img src={InfoIcon} height="18.33px" width="18.33px" />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: "21.83px" }}
                >
                  <div className="percentage-rate">100%</div>
                  <div className="percentage-rate-text">Open Rate</div>{" "}
                </Box>
              </Box>

              <Box
                className="rates"
                display="flex"
                flex-direction="row"
                style={{ marginLeft: "41px" }}
              >
                <img src={InfoIcon} height="18.33px" width="18.33px" />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: "21.83px" }}
                >
                  <div className="percentage-rate">100%</div>
                  <div className="percentage-rate-text">Open Rate</div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/*body*/}

        {/*notes-container*/}
        <Box className="Notes-container">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <div className="notes-title">Notes</div>
            <div className="notes-characterinfo">60 characters remaining</div>
          </Box>
          <Box className="notes-body">
            Physiological respiration involves the mechanisms that ensure that
            the composition of the functional residual capacity is kept
            constant, and equilibrates with the gases dissolved in the pulmonary
            capillary blood, and thus throughout the body. Thus, in precise
            usage, the words breathing and ventilation are hyponyms, not
            synonyms, of respiration; but this prescription is not consistently
            followed, even by most health care providers, because the term
            respiratory rate (RR) is a well-established term in health care,
          </Box>
        </Box>

        <Box display="flex" style={{ marginBottom: "20px" }}>
          {/*body-left*/}

          <Box className="email-layout">
            <div className="layout-title">User Feed Activity</div>
            {[1, 2, 3, 4].map((item: any) => (
              <Box className="Email-box" display="flex" flex-direction="column">
                <div className="Email-title">Email sent</div>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{ marginTop: "11px" }}
                >
                  <div className="Email-time">
                    This is where the email body would be displayed up until
                    this point and then it would do the classic...
                  </div>
                  <Icon icon="chevron-right" color="#7C7F93" size={12} />
                </Box>
              </Box>
            ))}
          </Box>

          {/*body-right*/}
          <Box className="tags-container">
            <div className="layout-title" style={{ marginBottom: "30px" }}>
              Profile Information
            </div>
            <div className="emailinfo-section">
              <div className="input-label">Email</div>
              <Box
                className="stats-values"
                display="flex"
                flex-direction="row"
                justifyContent="space-between"
              >
                <span>herronbp@gmail.com</span>
                <Icon icon="chevron-right" color="#424452" size={12} />
              </Box>
            </div>
            <div className="emailinfo-section">
              <div className="input-label">Phone Number</div>
              <Box
                className="stats-values"
                display="flex"
                flex-direction="row"
                justifyContent="space-between"
              >
                <span>513-617-9942</span>
                <Icon icon="chevron-right" color="#424452" size={12} />
              </Box>
            </div>
            <div className="emailinfo-section">
              <div className="input-label">Location</div>
              <Box
                className="stats-values"
                display="flex"
                flex-direction="row"
                justifyContent="space-between"
              >
                <span>Cincinnati, OH USA</span>
                <Icon icon="chevron-right" color="#424452" size={12} />
              </Box>
            </div>
            <div className="layout-title">Tags</div>
            <Box
              className="tag-container-body"
              display="flex"
              flex-direction="column"
            >
              <Box className="tag">
                <img src={hashIcon} height="14.67px" width="14.67px" />
                <div>Brunch Promo 2022</div>
              </Box>
              <Box className="tag">
                <img src={hashIcon} height="14.67px" width="14.67px" />
                <div>Loyalty Program</div>
              </Box>{" "}
              <Box className="tag">
                <img src={hashIcon} height="14.67px" width="14.67px" />
                <div>Brunch Promo 2022</div>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default AudienceProfile;
