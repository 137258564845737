import { BusinessSettingsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState = null;

export default function businessSettings(
  state = initialState,
  action: BusinessSettingsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_BUSINESS_SETTINGS:
      return action.businessSettings;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
