import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  WeeklyRoleGroupedScheduleReport,
} from "controllers/reporting";

import { Table, TableCell, TableHeader } from "components/pdfTable";

const horizontalPadding = 8;

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 8,
    border: 0,
    fontFamily: "LatoBold",
    color: "#000000",
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#ededed",
  },
  shiftTitle: {
    fontSize: 13,
    fontFamily: "LatoBold",
    color: "#000000",
    marginBottom: 10,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  },
  shiftSubtitle: {
    fontSize: 10,
    fontFamily: "LatoSemiBold",
    color: "#000000",
    marginBottom: 10,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  },
  footerCellStyle: {
    fontSize: 7,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 7,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 3,
  },
  dataCellStyle: {
    fontSize: 6,
    fontFamily: "LatoSemiBold",
    color: "#000000",
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 4,
    paddingBottom: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  completelyRoundedFooter: {
    borderRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  roleHeaderContainer: {
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 7,
    paddingBottom: 7,
    backgroundColor: "#dbdbdb",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  roleHeaderCell: {
    fontFamily: "LatoBold",
    color: "black",
    fontWeight: "bold",
    fontSize: 8,
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface WeeklyLaborPdfDocumentProps {
  report: WeeklyRoleGroupedScheduleReport;
  businessName: string;
}

const WeeklyRoleGroupedScheduleReportDocument = (
  props: WeeklyLaborPdfDocumentProps
) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Text style={styles.shiftTitle}>
              Week of {props.report.weekDaysWithoutDayName[0]} -{" "}
              {
                props.report.weekDaysWithoutDayName[
                  props.report.weekDaysWithoutDayName.length - 1
                ]
              }
            </Text>
            <Text style={styles.shiftSubtitle}>
              {props.businessName} | {props.report.totalShiftsDuration} total
              staff hours
            </Text>
          </View>

          {props.report.roleReports.map((roleReport) => {
            return (
              <>
                <View style={styles.roleHeaderContainer}>
                  <Text style={styles.roleHeaderCell}>
                    {roleReport.roleTitle} | {roleReport.totalShifts} shifts,{" "}
                    {roleReport.totalHours} hours
                  </Text>
                  <Text style={styles.roleHeaderCell}>
                    {roleReport.departmentTitle}
                  </Text>
                </View>
                <Table>
                  <TableHeader>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                    >
                      Name
                    </TableCell>
                    {props.report.dateStringsForSchedule.map(
                      (weekDayString) => (
                        <TableCell
                          includeRightBorder={false}
                          includeLeftBorder={false}
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          {weekDayString}
                        </TableCell>
                      )
                    )}
                  </TableHeader>
                </Table>
                {roleReport.staffReports.map((staffReport) => {
                  return (
                    <Table>
                      <TableHeader>
                        <TableCell
                          includeRightBorder={false}
                          includeLeftBorder={false}
                          includeBottomBorder
                          includeTopBorder
                          style={[
                            styles.dataCellStyle,
                            {
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                            },
                          ]}
                        >
                          {staffReport.staffFullName}
                        </TableCell>
                        {staffReport.staffMemberWeeklySchedule.map(
                          (dailySchedule) => {
                            return (
                              <TableCell
                                includeRightBorder
                                includeLeftBorder
                                includeBottomBorder
                                includeTopBorder
                                style={styles.dataCellStyle}
                              >
                                <View>
                                  {dailySchedule.shifts.map((shift) => (
                                    <View
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "flex-start",
                                          marginVertical: 2,
                                        }}
                                      >
                                        <View
                                          style={{
                                            height: 8,
                                            width: 2,
                                            backgroundColor: shift.shiftColor,
                                            marginRight: 4,
                                          }}
                                        />
                                        <Text>
                                          {shift.startTime} - {shift.endTime}
                                        </Text>
                                      </View>
                                    </View>
                                  ))}
                                </View>
                              </TableCell>
                            );
                          }
                        )}
                      </TableHeader>
                    </Table>
                  );
                })}
              </>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default WeeklyRoleGroupedScheduleReportDocument;
