import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { TangoTab } from './payments';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

//http://localhost:9001/tango-2/us-central1/nestApi/disputes/x1lrS2aqfDBbVlUNQ2jF/USA/disputeEvidence
const testURl = 'http://localhost:9001/tango-2/us-central1';

const disputesApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/disputes`,
});

disputesApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export interface DisputeFileDataDTO {
  fileUrl: string;
  dispute: TangoDispute;
}

export interface TangoDispute {
  id: string;
  businessId: string;
  createdAt: Date;
  updatedAt: Date;
  object: string;
  amount: number;
  balanceTransactions: string[];
  charge: string;
  created: number;
  currency: string;
  evidence: {
    accessActivityLog: string | null;
    billingAddress: string | null;
    cancellationPolicy: string | null;
    cancellationPolicyDisclosure: string | null;
    cancellationRebuttal: string | null;
    customerCommunication: string | null;
    customerEmailAddress: string | null;
    customerName: string | null;
    customerPurchaseIp: string | null;
    customerSignature: string | null;
    duplicateChargeDocumentation: string | null;
    duplicateChargeExplanation: string | null;
    duplicateChargeId: string | null;
    productDescription: string | null;
    receipt: string | null;
    refundPolicy: string | null;
    refundPolicyDisclosure: string | null;
    refundRefusalExplanation: string | null;
    serviceDate: string | null;
    serviceDocumentation: string | null;
    shippingAddress: string | null;
    shippingCarrier: string | null;
    shippingDate: string | null;
    shippingDocumentation: string | null;
    shippingTrackingNumber: string | null;
    uncategorizedFile: string | null;
    uncategorizedText: string | null;
  };
  evidenceDetails: {
    dueBy: number;
    hasEvidence: boolean;
    pastDue: boolean;
    submissionCount: 0;
  };
  isChargeRefundable: boolean;
  livemode: boolean;
  metadata: {};
  paymentIntent: string;
  reason: string;
  status: string; //warning_needs_response, warning_under_review, warning_closed, needs_response, under_review, charge_refunded, won, or lost
  tabId: string;
}

export interface DisputeEvidenceDTO {
  //any of this data should be the ID of a file uploaded to stripe - see docs https://stripe.com/docs/file-upload
  //most likely use case will be uploading a receipt
  receipt: string | null;
  cancellation_policy: string | null;
  customer_communication: string | null;
  customer_signature: string | null;
  duplicate_charge_documentation: string | null;
  refund_policy: string | null;
  service_documentation: string | null;
  shipping_documentation: string | null;
  uncategorized_file: string | null;

  //any of these are strings or most likely not relevant and not needed
  access_activity_log: string | null;
  billing_address: string | null;
  cancellation_policy_disclosure: string | null;
  cancellation_rebuttal: string | null;
  customer_email_address: string | null;
  customer_name: string | null;
  customer_purchase_ip: string | null;
  duplicate_charge_explanation: string | null;
  duplicate_charge_id: string | null;
  product_description: string | null;
  refund_policy_disclosure: string | null;
  refund_refusal_explanation: string | null;
  service_date: string | null;
  shipping_address: string | null;
  shipping_carrier: string | null;
  shipping_date: string | null;
  shipping_tracking_number: string | null;
  uncategorized_text: string | null;
}

type EvidenceTableProps = {
  evidenceType: string;
  file: string;
  uniqueId: string;
};

export const fetchTab = async (businessId: string, tabId: string) => {
  // if (!businessId) return null;
  const res = await disputesApiClient.get<TangoTab>(`${businessId}/tab/${tabId}`);
  return res.data;
};

export const fetchDisputes = async (businessId: string) => {
  if (!businessId) return null;
  const res = await disputesApiClient.get<TangoDispute[]>(`${businessId}`);
  return res.data;
};

export const useTab = (businessId: string, tabId: string) => {
  console.log('fetch tab ', businessId, tabId);
  return useQuery(['tabInfo', businessId], () => fetchTab(businessId, tabId));
};

export const useDisputes = (businessId: string) => {
  return useQuery(['disputesInfo', businessId], () => fetchDisputes(businessId));
};

//when updating a dispute the first step will be to send a file to stripe (usually an image of receipt)
export const uploadDisputeFile = async (businessId: string, country: string, disputeFileData: DisputeFileDataDTO) => {
  try {
    const success = await disputesApiClient.post(`${businessId}/${country}/disputeEvidence`, disputeFileData);
    return success.data;
  } catch (error: any) {
    console.log('error updating dispute image', error.response.data);
    alert(error.response.data.message);
    return false;
  }
};

//after dispute file is uploaded, use this function to update the actual dispute in stripe backend
export const updateDispute = async (businessId: string, country: string, disputeId: string, disputeEvidence: DisputeEvidenceDTO) => {
  try {
    const success = await disputesApiClient.patch(`${businessId}/${country}/dispute/${disputeId}`, disputeEvidence);
    console.log('uploaded evidence >', success.data);
    return success.data;
  } catch (error: any) {
    console.log('error closing dispute', error.response.data);
    alert(error.response.data.message);
    return false;
  }
};

export const closeDispute = async (businessId: string, country: string, disputeId: string) => {
  try {
    const success = await disputesApiClient.patch(`${businessId}/${country}/closeDispute/${disputeId}`, {});
    return success.data;
  } catch (error: any) {
    console.log('error closing dispute', error.response.data);
    alert(error.response.data.message);
    return false;
  }
};

export const generateReasonText = (reason: string) => {
  switch (reason) {
    case 'fraudulent':
      return 'The cardholder doesn’t recognize this charge and reported it as fraudulent';
    case 'customer_initiated':
      return 'The cardholder initiated this dispute';
    case 'duplicate':
      return 'The cardholder believes this is a duplicate charge';
    default:
      return 'A dispute was initiated for this charge';
  }
};

export const generateUpdatedEvidenceInTable = (updatedEvidence: any) => {
  const newTableData: EvidenceTableProps[] = [];

  const evidenceTypes: Array<string> = [
    'receipt',
    'customer_signature',
    'customer_communication',
    'refund_policy',
    'service_documentation',
    'shipping_documentation',
    'duplicate_charge_documentation',
    'uncategorized_file',
  ];

  Object.keys(updatedEvidence).forEach((key: string) => {
    if (evidenceTypes.includes(key) && updatedEvidence[key]) {
      newTableData.push({
        evidenceType: key.replaceAll('_', ' '),
        file: key + '.png',
        uniqueId: key,
      });
    }
  });
  return newTableData;
};
