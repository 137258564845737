import { Dialog, Icon, Switch } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export function NoteModal({ isOpen, closeModal }: Props) {
  const db = firebase.firestore();

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [switches, setSwitches] = useState({
    emailSwitch: false,
    Safetytraing: false,
    createEmployee: false,
  });

  const handleSwithChange = (key: any, e: any) => {
    setSwitches({ ...switches, [key]: e.target.checked });
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        className="autoWorkflow-modal"
        onClose={closeModal}
      >
        <div className="modal-header">
          <div className="modal-title">
            Trigger these workflow automation actions?
          </div>
          {/* <span
            className="closebtn"
            // onClick={() => {
            //   clearState();
            //   closeModal();
            // }}
          >
            <img src={Closeicon} style={{ height: 12.83 }} />
          </span> */}
        </div>

        {/* <div className="modal-groups">
          <span className="modal-groupstxt">
            <Select
              classNamePrefix="new-note-modal"
              isMulti
              placeholder={"Groups or individuals"}
              options={listData}
              onChange={(e: any) => setSelectedItems(e)}
            />
            select
          </span>
        </div> */}

        <div className="modal-body">
          <div className="action-div-row">
            <Switch
              checked={switches.emailSwitch}
              onChange={(e) => handleSwithChange("emailSwitch", e)}
              innerLabel={"No"}
              innerLabelChecked={"Yes"}
            />

            <div className="action-div-container">
              <Icon icon="arrow-right" size={24} />
              Send
              <Select
                classNamePrefix="autoWorkflow-modal"
                placeholder={"Application Received"}
                //   options={listData}
                //   onChange={(e: any) => setSelectedItems(e)}
              />
              email template select
              <Select
                classNamePrefix="autoWorkflow-modal-emailselect"
                placeholder={"to applicant"}
                //   options={listData}
                //   onChange={(e: any) => setSelectedItems(e)}
              />
            </div>
            {/* <textarea
              className="note-input"
              placeholder="Type note here.."
              dir="auto"
              //   onChange={(e) => setNotesTxt(e.target.value)}
            /> */}
          </div>
          <div className="action-div-row">
            <Switch
              checked={switches.Safetytraing}
              onChange={(e) => handleSwithChange("Safetytraing", e)}
              innerLabel={"No"}
              innerLabelChecked={"Yes"}
            />

            <div className="action-div-container">
              <Icon icon="arrow-right" size={24} />
              Assign
              <Select
                classNamePrefix="autoWorkflow-modal"
                placeholder={"Application Received"}
                //   options={listData}
                //   onChange={(e: any) => setSelectedItems(e)}
              />
              training
            </div>
            {/* <textarea
              className="note-input"
              placeholder="Type note here.."
              dir="auto"
              //   onChange={(e) => setNotesTxt(e.target.value)}
            /> */}
          </div>
          <div className="action-div-row">
            <Switch
              checked={switches.createEmployee}
              onChange={(e) => handleSwithChange("createEmployee", e)}
              innerLabel={"No"}
              innerLabelChecked={"Yes"}
            />

            <div className="action-div-container">
              <Icon icon="arrow-right" size={24} />
              <span>
                Create new employee (
                <span className="color-blue-3">Autofill from contract</span>)
              </span>
            </div>
            {/* <textarea
              className="note-input"
              placeholder="Type note here.."
              dir="auto"
              //   onChange={(e) => setNotesTxt(e.target.value)}
            /> */}
          </div>
        </div>

        <div className="modal-footer">
          <span className="color-blue pointer" onClick={() => closeModal()}>
            Cancel
          </span>
          <button className="trigger-btn">Trigger</button>
        </div>
      </Dialog>
    </>
  );
}

export default NoteModal;
