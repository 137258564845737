import { Icon, InputGroup } from "@blueprintjs/core";
import firebase from "config/firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getAllJobFunctions } from "model/selectors/teamsTable";

import Box from "../../../../components/Box";
import { RootState } from "../../../../model/store";
import "./index.css";

const ViewArchives = () => {
  const db = firebase.firestore();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const jobFunc = useSelector(getAllJobFunctions);
  const [employeeApplicationsData, setEmployeeApplicationsData] =
    useState<TangoBusiness[]>(); //data from firebase
  const navigate = useNavigate();
  const transformDataForGrid = (data: any[]): any[] =>
    data.map((row: any) => {
      return {
        id: row.id,
        uid: row.uid,
        businessId: row.businessId,
        name: row.name,
        emailAddress: row.email || "",
        role: row?.primaryRole.toString(),
        pinCode: String(row.pinCode),
        date: moment(row.createdAt.toDate()).format("MM/DD/YYYY").toString(),
        status: row.status,
        result: { color: "#EF4377", status: "No Hire" },
        location: row.location,
      };
    });

  const getEmployeeApplications = () => {
    const unsubscribe = db
      .collection("EmployeeApplications")
      .where("businessId", "==", business.id)
      .onSnapshot((snapshot) => {
        let EmployeeApplications: any = [];
        snapshot.forEach((doc) => {
          EmployeeApplications = [...EmployeeApplications, doc.data()];
        });
        console.log("EmployeeApplications", EmployeeApplications);
        const filterDeletedData = EmployeeApplications.filter(
          (item: any) => item.deleted == true && item.status == "No Hire"
        );

        setEmployeeApplicationsData(transformDataForGrid(filterDeletedData));
      });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (business && jobFunc) {
      getEmployeeApplications();
    }
  }, [business, jobFunc]);

  useEffect(() => {
    if (employeeApplicationsData) {
      console.log("employeeApplicationsData", employeeApplicationsData);
    }
  }, [employeeApplicationsData]);

  return (
    <div>
      <div
        className={`application-tracker-header ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        id="view-archives"
      >
        <div className="small-title">Archives</div>
        <div className="big-title">Previous Applicants</div>
        <Box className="back-button" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" color="#7C7F93" />
          Back to Applications
        </Box>
      </div>
      <Box
        className={`application-tracker-page ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        flex={1}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {/*sidebar*/}
        <div className="sidebar-container">
          <div className="sidebar">
            <div className="sidebar-title">Action</div>
            <div className="sidebar-section">
              <InputGroup
                placeholder="Search"
                leftIcon="search"
                intent="none"
              />
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Type:</div>
              <ul>
                <li className="active">BOH</li>
                <li>FOH</li>
                <li>Other</li>
              </ul>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Role:</div>
              <select>
                <option value="">Select a role</option>
                <option value="one">One</option>
                <option value="two">Two</option>
              </select>
            </div>
            <div className="sidebar-section">
              <div className="sidebar-section-title">Filter By Location:</div>
              <select>
                <option value="">Appleyby #3020</option>
                <option value="one">One</option>
                <option value="two">Two</option>
              </select>
            </div>
          </div>
        </div>
        <div className="jobs-container archive-table">
          <table>
            <tr>
              <th>Employee Info</th>
              <th>Result</th>
              <th>Role Applied For</th>
              <th>Applied Date</th>
            </tr>
            {employeeApplicationsData &&
              employeeApplicationsData.map((item) => {
                console.log({ item });
                return (
                  <tr>
                    <td className="employee-info-column">
                      <div>{item.name}</div>
                      <ul>
                        <li>{item.location?.name}</li>
                      </ul>
                    </td>
                    <td className="status-column">
                      <Icon icon="dot" color="#EF4377" size={20} /> No Hire
                    </td>
                    <td className="applicants-column">
                      {item.role ? item.role : "Not Available"}
                    </td>
                    <td className="date-column">
                      <div>{item.date}</div>
                      <div className="view-application">View Profile</div>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </Box>
    </div>
  );
};
export default ViewArchives;

//table layout
// {
//   employeeApplicationsData && employeeApplicationsData.map((item)=>(
//  <tr>
//    <td className='employee-info-column'>
//      <img src='https://randomuser.me/api/portraits/women/91.jpg'/>
//      <div>Customer Success Agent</div>
//      <ul>
//        <li>Cincinnati, OH</li>
//        <li>Full-Time</li>
//      </ul>
//    </td>
//    <td className='status-column'>
//      <Icon icon='dot' color='#EF4377' size={20}/> No Hire
//    </td>
//    <td className='applicants-column'>
//    Server
//    </td>
//    <td className='date-column'>
//     <div>02/14/2022</div>
//     <div className='view-application'>View Profile</div>
//    </td>
//  </tr>
//    ))
//  }
