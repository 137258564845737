import "./index.css";

interface IIncrementDecrementCounter {
  count: string;
  setCount: (num: string) => void;
}

const IncrementDecrementCounter = ({
  count,
  setCount,
}: IIncrementDecrementCounter) => {
  // const [count, setCount] = useState(1)
  return (
    <div className="quantity">
      <a
        className="quantity__minus"
        onClick={() => setCount(String(parseInt(count) - 1))}
      >
        <span>-</span>
      </a>
      <input
        name="quantity"
        type="text"
        className="quantity__input"
        value={count}
        onChange={(e) => setCount(e.target.value)}
      />
      <a
        className="quantity__plus"
        onClick={() => setCount(String(parseInt(count) + 1))}
      >
        <span>+</span>
      </a>
    </div>
  );
};

export default IncrementDecrementCounter;
