import * as React from "react";

export const HashIcon = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M15.3334 6.16669V4.33335H11.6667V0.666687H9.83341V4.33335H6.16675V0.666687H4.33341V4.33335H0.666748V6.16669H4.33341V9.83335H0.666748V11.6667H4.33341V15.3334H6.16675V11.6667H9.83341V15.3334H11.6667V11.6667H15.3334V9.83335H11.6667V6.16669H15.3334ZM9.83341 9.83335H6.16675V6.16669H9.83341V9.83335Z"
      fill={color}
    />
  </svg>
);
