import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment-timezone";
import React from "react";

import { DailyLaborReportDocumentRow } from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 10,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface DailyLaborPdfDocumentProps {
  groupedData: GroupedLaborReportByDepartment;
  totalsForReport: TotalsForReport;
  businessName: string;
  reportDate: Date;
  snapshot?: boolean;
  totalSales: number;
}

const DailyLaborPdfDocument = (props: DailyLaborPdfDocumentProps) => {
  const groupedData = props.groupedData;
  const reportDateRange = props.snapshot
    ? `${moment(props.reportDate).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.reportDate).format("DD/MM/YYYY hh:mm a")} - ${moment(
        props.reportDate
      )
        .add(1, "day")
        .format("DD/MM/YYYY hh:mm a")}`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Daily Labor {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.reportDate).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>

          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontWeight: "bold",
              fontSize: 10,
              marginTop: 5,
              width: "100%",
              textAlign: "right",
            }}
          >
            Sales: {formatter.format(props.totalSales)}
          </Text>
        </View>
        <Table>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Role Name
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Labor $
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Hours
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              % of sales
            </TableCell>
          </TableHeader>
        </Table>
        {_.keys(groupedData).map((departmentName) => (
          <Table data={groupedData[departmentName].data}>
            <TableBody>
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => r.roleName}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => formatter.format(r.totalLabor / 100)}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => r.totalHours}
              />
              <DataTableCell
                style={styles.dataCellStyle}
                getContent={(r) => `${Number(r.percentageOfSales).toFixed(2)}%`}
              />
            </TableBody>
            <TableFooter
              style={{ borderTopWidth: 0, backgroundColor: "#f4f3f3" }}
            >
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {departmentName}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {formatter.format(groupedData[departmentName].totalLabor / 100)}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {groupedData[departmentName].totalHours}
              </TableCell>
              <TableCell textAlign="center" style={styles.footerCellStyle}>
                {`${Number(groupedData[departmentName].totalSales).toFixed(
                  2
                )}%`}
              </TableCell>
            </TableFooter>
          </Table>
        ))}
        <Table data={[{}]}>
          <TableBody>
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => ""}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => ""}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => ""}
            />
            <DataTableCell
              style={styles.dataCellStyle}
              getContent={(r) => ""}
            />
          </TableBody>
          <TableFooter style={styles.totalFooter}>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              Total Labor
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {formatter.format(props.totalsForReport.totalLabor / 100)}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {props.totalsForReport.totalHours}
            </TableCell>
            <TableCell textAlign="center" style={styles.footerCellStyle}>
              {`${Number(props.totalsForReport.totalSales).toFixed(2)}%`}
            </TableCell>
          </TableFooter>
        </Table>
      </Page>
    </Document>
  );
};

export default DailyLaborPdfDocument;
