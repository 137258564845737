import { InputGroup } from "@blueprintjs/core";
import Menu from "@mui/material/Menu";
import { Box } from "@mui/system";
import { Tree } from "antd";
import "antd/dist/antd.css";
import dagre from "dagre";
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  ConnectionLineType,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "react-flow-renderer";
import { useSelector } from "react-redux";

import { staffSelector } from "model/selectors/staffing";

import RoleCard from "../RoleCard";
import "../index.css";
import SelectedTreeNode from "./SelectedTreeNode";

const initialNodes: Node[] = [
  // {
  //   id: "1",
  //   data: {
  //     label: <></>,
  //   },
  //   position: { x: 0, y: 0 },
  // },
  // {
  //   id: "2",
  //   // you can also pass a React component as a label
  //   data: {
  //     label: <></>,
  //   },
  //   position: { x: 0, y: 0 },
  // },
  // {
  //   id: "3",
  //   data: {
  //     label: <></>,
  //   },
  //   position: { x: 0, y: 0 },
  // },
];

type Node = {
  id: string;
  data: {
    label: any;
  };
  position: { x: number; y: number };
};

type Edge = {
  id: string;
  source: string;
  target: string;
  type: string;
};

const initialEdges: Edge[] = [
  // { id: "e1-2", source: "1", target: "2", type: "smoothstep" },
  // // { id: "e2-3", source: "2", target: "3", type: "smoothstep" },
  // { id: "e2-4", source: "1", target: "3", type: "smoothstep" },
];

type TreeData = {
  title: string;
  key: string;
  isLeaf?: boolean;
  children: TreeData[];
  myId: string;
  parentIds: string[];
  deleted: boolean;
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const EdgesFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isShowCollapsedView, setIsShowCollapsedView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDisplayTreeNode, setIsDisplayTreeNode] = useState(false);
  const [staffMembersLoaded, setStaffMembersLoaded] = useState(false);

  const [collapsedTreeData, setCollapsedTreeData] = useState<TreeData[]>([]);

  const roleCardDetails = () => {
    setIsDisplayTreeNode(false);
  };

  const staffMembers: StaffMember[] = useSelector(staffSelector);

  const createCollapsedTreeData = () => {
    const allNodes: TreeData[] = staffMembers.map((staff) => {
      return {
        title: `${staff.contact.firstName} ${staff.contact.lastName}`,
        key: staff.staffMemberCopyId || "",
        myId: staff.staffMemberCopyId || "",
        isLeaf: true,
        parentIds: staff.parentStaffIds || [],
        children: [],
        deleted: false,
      };
    });

    for (let i = 0; i < allNodes.length; i++) {
      const parentNode = allNodes[i];
      for (let j = 0; j < allNodes.length; j++) {
        const childNode = allNodes[j];
        if (childNode.parentIds.includes(parentNode.myId)) {
          childNode.deleted = true;
          parentNode.children.push(childNode);
          parentNode.isLeaf = false;
        }
      }
    }
    return allNodes
      .filter((node) => !node.deleted)
      .map((node) => ({
        ...node,
        isLeaf: node.children.length === 0,
      }));
  };

  const createNodesAndEdges = () => {
    return staffMembers.reduce(
      (acc, staff) => {
        // Create a node
        if (staff.staffMemberCopyId) {
          acc.nodes.push({
            id: staff.staffMemberCopyId,
            data: {
              label: (
                <div>
                  <RoleCard
                    employee={{
                      id: staff.staffMemberCopyId || "",
                      firstName: staff.contact.firstName || "",
                      lastName: staff.contact.lastName || "",
                      url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI0AAACNCAMAAAC9gAmXAAAAMFBMVEXk5ueutLeyuLvp6+yrsbTh4+TS1dfKztCnrrHY29zb3t/P0tS1u76/w8bBxsjHy81ElUJxAAADL0lEQVR4nO2a227DIAxAy7VAgPz/347Srk2XLmAnJn3wkSatb0fGXGL7cmEYhmEYhmEYhmEYpok6W+CJCXaaJuuNOVlEGZujcLriRJytOi9KPhUJsaD8yuEUFeXju8pDSGQzPD7KRLdWufu4eXQGTeJDXJ4+cexy5Q2X6mPHrZaJDZmik4fptGWKznWQTo+MEC6PcFGpS6ZEZxogc+2UKTr0Oyt0ywghyc+d2C9DvrHUDAhNyWTitQLJCJFIZVpn8F+0J5QxEiYjRKTLHDUBQ1OgyxwFDo3QM5lNAMuUpSKz6T+GX9Bt8t4bagndXQ53KSQiG/PfS3gTqsSxKBuiqxPwlniD5jhW0GvhjrY0NgkVGrbZsKF5Hn9ZbPJX2cBeoU+IHlyI101BEl2b/pvO4i+7p0DfUr/QfVNhrgZHs6UKFvPaopK5GLgM4fddd7FkERrCsomHLxWdDHxX6ZmySgHOY9IKDvBrk/BLswLMHOK6KGhbET0mlvSvlR5Qo+0vDdAVBF70F3HGtD6mvpdFGFPa7/roJK34vdOMjpaDIlPxcjM8Oo3t3pm0ER53HepyqU3Wzz66BOaMNrSV66avFnFc+v71ycIthLST88jsXROuKUZZiDFNZ88ulARSF3Oj/HNqWFQVCd5bW/68DyGYs5SCfazRbRvVeYq6Xmm2Q1vzSgU7p9tkyT873OmYJz8kSMbnWzS2TuKqVL6kLG1SK+WTdN2PPyfTRDe15GcNrVJolyyJkI/N9fnoo+Pxx5CVqOLNXUhcD7257PYDou0jDxsQUgHTl1r5HDORYzJ+jd584v4L9TYrdohMTZ+9NvMxgXn47AuP2Zm9a/DfwsofrCJ2NF0xkwEdOrhCII0MslaA7WJ26CB60kSRqTrQ6ChMoRqgA4sOZFYMowPbWfDRFqAO5O18wDXZANC1Ik2aO4CNRe4iANUm3IQClM4aJabvg6BvIBrR9kHSExrMqBiKrtYV2f20oidzqA++Fx3jbuHIp+c27VYadlgCRXupMB14NK2lgk/J7qDZv0J0dXfYtBJnwIW5oHV1jrmjfpHbNirLoTS3+FAaMgzDMAzzTfwA+XMmLtWzWB4AAAAASUVORK5CYII=",
                      role: staff.primaryRole || "",
                    }}
                    onClickRoleCard={roleCardDetails}
                  />
                </div>
              ),
            },
            position: {
              x: 0,
              y: 0,
            },
          });

          // Create the edges
          (staff.parentStaffIds || []).forEach(
            (parentStaffId: string, index: number) => {
              acc.edges.push({
                id:
                  (staff.staffMemberCopyId || "") +
                  "-" +
                  String(index) +
                  "-" +
                  parentStaffId,
                source: parentStaffId,
                target: staff.staffMemberCopyId || "",
                type: "smoothstep",
              });
            }
          );
        }

        return acc;
      },
      {
        nodes: [] as Node[],
        edges: [] as Edge[],
      }
    );
  };

  const { zoomIn, zoomOut } = useReactFlow();

  useEffect(() => {
    if (staffMembersLoaded) {
      const nodesAndEdges = createNodesAndEdges();
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodesAndEdges.nodes, nodesAndEdges.edges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
      const data = createCollapsedTreeData();
      console.log({ createCollapsedTreeData: data });
      setCollapsedTreeData(data);
    }
  }, [staffMembersLoaded]);

  useEffect(() => {
    if (staffMembers.length) {
      setStaffMembersLoaded(true);
    }
  }, [staffMembers]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("##################");
    setAnchorEl(event.currentTarget);
  };

  function openFullscreen() {
    setIsFullScreen(true);
    const elem = document.getElementById("tree-view-area") as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem && elem.mozRequestFullScreen) {
      /* Safari */
      elem.mozRequestFullScreen();
    } else if (elem && elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem && elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  function closeFullscreen() {
    setIsFullScreen(false);
    if (document["exitFullscreen"]) {
      document.exitFullscreen();
    }
  }

  // const initTreeData: TreeData[] = [
  //   {
  //     title: 'Expand to load',
  //     key: '0',
  //     children: [
  //       {
  //         title: 'Expand to load',
  //         key: '0-1',
  //         children: [
  //           {
  //             title: 'Expand to load 1',
  //             key: '0-1-1',
  //             children: [{
  //               title: 'Expand to load 1',
  //               key: 'random',
  //             }]
  //           },
  //           {
  //             title: 'Expand to load 2',
  //             key: '0-1-2'
  //           },
  //           {
  //             title: 'Expand to load 3',
  //             key: '0-1-3'
  //           }
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Expand to load',
  //     key: '1',
  //     children: [
  //       {
  //         title: 'Expand to load',
  //         key: '1-1'
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Tree Node',
  //     key: '2',
  //     children: [
  //       {
  //         title: 'Expand to load',
  //         key: '2-1',
  //         children: [
  //           {
  //             title: 'Expand to load',
  //             key: '2-1-1',
  //             children: [
  //               {
  //                 title: 'Expand to load',
  //                 key: '2-1-1-1'
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ];

  const getLayoutedElements = (nodes: any, edges: any, direction = "TB") => {
    const nodeWidth = 250;
    const nodeHeight = 150;

    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node: any) => {
      dagreGraph.setNode(node.id, {
        width: nodeWidth,
        height: nodeHeight,
      });
    });

    edges.forEach((edge: any) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node: any) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return {
      nodes,
      edges,
    };
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: ConnectionLineType.SmoothStep,
            animated: true,
          },
          eds
        )
      ),
    []
  );

  return (
    <>
      {!isDisplayTreeNode ? (
        <>
          <div className="tree-view" id="tree-view-area">
            <Box className="controls">
              <Box className="left-controls">
                <Box className="zoom-controls">
                  <img
                    src={require("assets/ZoomInButton.svg").default}
                    className="zoom-in"
                    onClick={() => zoomIn({ duration: 800 })}
                  />
                  <img
                    src={require("assets/ZoomOutButton.svg").default}
                    className="zoom-out"
                    onClick={() => zoomOut({ duration: 800 })}
                  />
                </Box>
                <button className="zoom-to-fit-button">Zoom to fit</button>
                <button
                  className={`collapsed-view-button ${
                    isShowCollapsedView ? "selected-collapsed" : ""
                  }`}
                  onClick={() => setIsShowCollapsedView(!isShowCollapsedView)}
                >
                  Collapsed View
                </button>
              </Box>
              <Box className="right-controls">
                <Box className="tree-search-section">
                  <InputGroup
                    placeholder="Search Employee"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    leftIcon="search"
                    intent="none"
                  />
                </Box>
                <button className="export-png-button">Export PNG</button>
              </Box>
            </Box>

            <Box className="tree-view-box">
              <Box
                className={`${
                  isShowCollapsedView
                    ? "tree-header"
                    : "tree-header-without-collapesd"
                }`}
              >
                {isShowCollapsedView && (
                  <button
                    className="collapsed-button"
                    onClick={(e) => handleClick(e)}
                  >
                    <img
                      src={require("assets/TreeListViewButton.svg").default}
                    />
                  </button>
                )}
              </Box>

              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                connectionLineType={ConnectionLineType.SmoothStep}
                fitView={true}
                className="transition"
              >
                <div
                  onClick={() =>
                    !isFullScreen ? openFullscreen() : closeFullscreen()
                  }
                  style={{
                    position: "absolute",
                    right: 0,
                    zIndex: 1000,
                  }}
                >
                  {!isFullScreen ? (
                    <img
                      src={require("assets/FullscreenButton.svg").default}
                      className="full-screen-button"
                    />
                  ) : (
                    <img
                      src={require("assets/FullscreenOutButton.svg").default}
                      className="full-screen-out-button"
                    />
                  )}
                  <Background />
                </div>
              </ReactFlow>
            </Box>
          </div>
          <Menu
            className="callapsed-tree"
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            disableScrollLock={true}
            container={document.getElementById("tree-view-area")}
          >
            <div>
              <Tree treeData={collapsedTreeData} defaultExpandAll={true} />
            </div>
          </Menu>
        </>
      ) : (
        <>
          <SelectedTreeNode
            isDisplayTreeNode={isDisplayTreeNode}
            setIsDisplayTreeNode={setIsDisplayTreeNode}
          />
        </>
      )}
    </>
  );
};

export default () => (
  <ReactFlowProvider>
    <EdgesFlow />
  </ReactFlowProvider>
);
