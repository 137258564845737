import { Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import firebase from "config/firebase";
import lodash from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface props {
  businessId: string;
  employeeApplications: any;
  setSelectedOption: any;
  setSelectedRole: any;
  selectedRole: string;
  selectedLocation: string;
}

const RenderJobs = ({
  businessId,
  employeeApplications,
  setSelectedOption,
  setSelectedRole,
  selectedRole,
  selectedLocation,
}: props) => {
  const [jobs, setJobs]: any = useState([]);
  const db = firebase.firestore();
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const transformDataforGrid = (data: any[]) =>
    data.map((row: any) => {
      let applicantcount = 0;
      let hiredApplicant = 0;
      if (employeeApplications) {
        employeeApplications.map((item: any) => {
          if (row.jobTitle.toLowerCase() == item.jobTitle?.toLowerCase()) {
            applicantcount++;
            if (item.status == "Hire") {
              hiredApplicant++;
            }
          }
        });
      }

      return {
        ...row,
        jobAddress: row.location.jobAddress,
        date: moment(row.createdAt.toDate()).format("MM/DD/YYYY").toString(),
        department: row.department ? row.department : "",
        Applicants: applicantcount,
        openPositions: row?.openPositions,
        hired: hiredApplicant,
      };
    });

  const getJobsData = () => {
    const unsubscribe = db
      .collection("JobPosting")
      .where("businessId", "==", businessId)
      .onSnapshot((snapshot) => {
        let jobsData: any[] = [];
        snapshot.forEach((doc) => {
          jobsData = [...jobsData, doc.data()];
        });
        const sortedData = jobsData.sort(
          (b, a) => a.createdAt.toDate() - b.createdAt.toDate()
        );
        console.log("sortedData", sortedData);
        setJobs(transformDataforGrid(sortedData));
      });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (businessId && employeeApplications) {
      getJobsData();
    }
  }, [businessId, employeeApplications]);

  useEffect(() => {
    if (selectedRole.length > 0) {
      const filteredJobsData = jobs.filter(
        (item: any) =>
          item.jobTitle.toLowerCase() === selectedRole.toLowerCase()
      );
      setFilteredData(filteredJobsData);
    } else {
      setFilteredData([]);
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedLocation.length > 0) {
      const filteredJobsData = jobs.filter(
        (item: any) => item.businessId === selectedLocation
      );
      setFilteredData(filteredJobsData);
    } else {
      setFilteredData([]);
    }
  }, [selectedLocation]);

  const updateStauts = async (jobData: any, status: any) => {
    try {
      const documentSnapShot = await firebase
        .firestore()
        .collection("JobPosting")
        .doc(jobData.id)
        .update({ status: status.slug, updatedAt: new Date() });
      const jobIndex = jobs.findIndex((item: any) => item.id === jobData.id);
      const clonedJobsData = [...jobs];
      clonedJobsData[jobIndex]["status"] = status.slug;
      setJobs(clonedJobsData);
      if (filteredData.length > 0) {
        const clonedFilterData = [...filteredData];
        const jobSearchIndex = clonedFilterData.findIndex(
          (item: any) => item.id === jobData.id
        );
        //@ts-ignore
        clonedFilterData[jobSearchIndex]["status"] = status.slug;
        setFilteredData(clonedFilterData);
      }
    } catch (e: any) {
      console.log(e.message);
    }
  };
  const getDotColor = (status: string) => {
    switch (status) {
      case "active":
        return "#5BEF43";
      case "paused":
        return "yellow";
      case "archive":
        return "orange";
      case "delete":
        return "red";
    }
  };
  const getStatusLabel = (status: string) => {
    switch (status) {
      case "active":
        return "Active";
      case "paused":
        return "Paused";
      case "archive":
        return "Archive";
      case "delete":
        return "Delete";
    }
  };
  const renderJobRow = (item: any) => {
    console.log("Item: ", item);
    if (item.status !== "delete" && item.status !== "archive") {
      return (
        <tr style={{ cursor: "pointer" }}>
          <td
            onClick={() => {
              navigate("/edit-job-post", { state: { jobDetail: item } });
            }}
          >
            <div>{item.jobTitle}</div>
            <ul>
              <li>
                {item.location && item.location.name ? item.location.name : ""}
              </li>
              <li>{item.jobType}</li>
              {/* <li>{item.department ? item.department : ""}</li> */}
              <li>
                Hired {item.hired}/{item.openPositions}
              </li>
            </ul>
          </td>
          <td className="status-column">
            {item.status ? (
              <Icon icon="dot" color={getDotColor(item.status)} size={20} />
            ) : null}{" "}
            <Select
              items={[
                { name: "Active", slug: "active" },
                { name: "Paused", slug: "paused" },
                { name: "Archive", slug: "archive" },
                { name: "Delete", slug: "delete" },
              ]}
              filterable={false}
              itemRenderer={(statusItem, { handleClick }) => (
                <MenuItem text={statusItem.name} onClick={handleClick} />
              )}
              onItemSelect={(statusItem) => updateStauts(item, statusItem)}
            >
              <MenuItem
                text={item.status ? getStatusLabel(item.status) : "No Status"}
              />
            </Select>
          </td>
          <td
            className="applicants-column"
            onClick={() => {
              navigate("/edit-job-post", { state: { jobDetail: item } });
            }}
          >
            {item.Applicants} applicants
          </td>
          <td
            className="date-column"
            onClick={() => {
              navigate("/edit-job-post", { state: { jobDetail: item } });
            }}
          >
            <div>{item.date}</div>
            <div
              className="view-application"
              onClick={(e) => {
                e.stopPropagation();
                // if (item.Applicants > 0) {
                setSelectedOption("applications");
                // setSelectedRole(lodash.capitalize(item.jobTitle));
                navigate("/view-applications", {
                  state: {
                    filter: lodash.capitalize(item.jobTitle),
                    jobdetail: {
                      noOfApplicant: item.Applicants,
                      jobType: item.jobType,
                      address: item.address,
                    },
                  },
                });

                // }
              }}
            >
              View Applications
            </div>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="jobs-container">
      <table>
        <tr>
          <th>Job Title</th>
          <th>Status</th>
          <th>Applicants</th>
          <th>Posted Date</th>
        </tr>
        {filteredData.length > 0
          ? filteredData.map((item) => renderJobRow(item))
          : jobs.map((item: any) => renderJobRow(item))}
      </table>
    </div>
  );
};

export default RenderJobs;
