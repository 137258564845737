import { ModifierItem, useMenuItem, useUpdateIngredients, useUpdateRecipe } from "controllers/menuItems";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../model/store";
import { SimpleRenderInstruction } from "../../../components/Table/GenericCell/TableCell";
import { ColumnInstruction, UpdateState } from "components/Table/HorizontalTable";
import { useAllItemsInfo } from "controllers/inventoryItemInfo";
import { Ingredient, instructionsFactory } from "./ingredients";


type MenuItem = {
  uniqueId: string;
  optionName: string;
  modifierName: string;
  plu: string;
}

const detailsColumns: ColumnInstruction<MenuItem>[] = [
  { type: "data", header: "Modifier Option", attribute: "optionName" },
  { type: "data", header: "Modifier", attribute: "modifierName" },
  { type: "data", header: "PLU", attribute: "plu" },
];
const detailsInstructions: { [x: string]: SimpleRenderInstruction } = {};
const ingredientsColumns: ColumnInstruction<Ingredient>[] = [
  { type: "data", header: "Ingredient", attribute: "itemId" },
  { type: "data", header: "Quantity", attribute: "amount" },
  { type: "projection", header: "Production Unit", attribute: "unit" },
  { type: "projection", header: "Cost", attribute: "cost" },
  { type: "projection", header: "Item Type", attribute: "itemType" },
];

const prefix = "new__ingredient";

const useModifierDetail = (itemId: string) => {
  const business = useSelector(
    (state: RootState) => state.business as TangoBusiness
  );
  const businessId = business?.id;
  const currency = business?.currency;
  const [isEditingIngredients, setIsEditingIngredients] = useState(false);
  const [isEditingRecipe, setIsEditingRecipe] = useState(false);

  // call update with false to push, true to clear
  const [addedIds, updateAddedIngredients] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [prefix + curr.length, ...curr];
    },
    []
  );
  useEffect(() => {
    if (!isEditingIngredients) updateAddedIngredients(true);
  }, [isEditingIngredients]);
  const addIngredient = useCallback(() => {
    setIsEditingIngredients(true);
    updateAddedIngredients(false);
  }, []);
  const query = useMenuItem(businessId, true, itemId);
  const mod = query.data as ModifierItem;
  const allItemsQuery = useAllItemsInfo(businessId, true);
  const updateIngredients = useUpdateIngredients(businessId, true, itemId);
  const updateRecipe = useUpdateRecipe(businessId, true, itemId);
  const item = useMemo(() =>
    mod ? {
      uniqueId: mod.id,
      optionName: mod.optionName,
      modifierName: mod.modifierName,
      plu: mod.plu,
    } : null,
    [mod]
  );
  const ingredientsFromServer = useMemo(() =>
    mod?.ingredients
      ? mod.ingredients.map(i => ({
        uniqueId: i.id,
        itemId: i.id,
        amount: i.amount,
      })) as Ingredient[]
      : [],
    [mod]
  );
  const ingredients = useMemo(() => [
    ...addedIds.map(uniqueId => ({
      uniqueId,
      amount: 1,
      itemId: null,
    } as Ingredient)),
    ...ingredientsFromServer,
  ], [addedIds, ingredientsFromServer])
  const saveIngredientsChanges = useCallback((instructions: UpdateState) => {
    const items = allItemsQuery.data ?? [];
    const newVals = ingredients.map(ingredient => {
      const update = instructions[ingredient.uniqueId];
      const itemId = (update?.itemId?.newValue ?? ingredient.itemId) as string;
      const item = items.find(i => i.id == itemId);
      if (!item) throw "Item not found";
      return ({
        id: itemId,
        isRaw: item.isRaw,
        amount: (update?.amount?.newValue ?? ingredient.amount) as number,
      });
    });
    return updateIngredients.mutateAsync(newVals);
  }, [updateIngredients, ingredients, allItemsQuery.data]);
  const deleteIngredients = useCallback((ids: string[]) => {
    if (!mod?.ingredients) return;

    const newVals = mod?.ingredients
      .filter(i => !ids.includes(i.id))
      .map(ingredient => ({
        isRaw: ingredient.isRaw,
        id: ingredient.id,
        amount: ingredient.amount,
      }));
    updateIngredients.mutate(newVals);
  }, [updateIngredients, mod]);
  const ingredientsInstructions = instructionsFactory(allItemsQuery.data ?? [], currency);

  const recipe = mod?.recipe;
  const saveRecipeChanges = useCallback(
    (newRecipe) => updateRecipe.mutateAsync(newRecipe).then(
      () => setIsEditingRecipe(false)
    ),
    [updateRecipe],
  );
  return {
    item,
    detailsColumns,
    detailsInstructions,
    ingredients,
    isEditingIngredients,
    setIsEditingIngredients,
    addIngredient,
    ingredientsColumns,
    ingredientsInstructions,
    saveIngredientsChanges,
    deleteIngredients,
    recipe,
    isEditingRecipe,
    setIsEditingRecipe,
    saveRecipeChanges,
  };
};
export default useModifierDetail;
