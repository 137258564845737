import UIComponents from "@tangopay/tango-ui-library";
import { TangoTab } from "controllers/payments";
import { useSelector } from "react-redux";
import HorizontalTable, { ColumnInstruction } from "components/Table/HorizontalTable";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import React, { useState } from "react";
import { RootState } from "model/store";
import { CompletedCheckStaff } from "../ClosedChecks/useClosedChecks";
const { Modal, Button, TextField } = UIComponents;


interface Props {
    isOpen: boolean;
    closeModal: () => void;
    discountedItemsInCheck: any[];
    closedCheck: TangoTab;
    staffMember: CompletedCheckStaff | null;
}


type DiscountedPaymentProps = {
    uniqueId: string;
    // date: any;
    checkNumber: string | number;
    discountName: string;
    serverName: string;
    customer: string;
    itemName: string;
    itemQuantity: number;
    price: string | number;
    discountedPrice: string | number;
    finalPrice: string | number;
    tab: TangoTab
};

const columns: ColumnInstruction<DiscountedPaymentProps>[] = [
    // { type: "projection", header: "Date", attribute: "date" },
    { type: "data", header: "Check Number", attribute: "checkNumber" },
    { type: "data", header: "Discount Name", attribute: "discountName" },
    { type: "data", header: "Server Name", attribute: "serverName" },
    { type: "data", header: "Customer", attribute: "customer" },
    { type: "data", header: "Item Name", attribute: "itemName" },
    { type: "data", header: "Item Quantity", attribute: "itemQuantity" },
    { type: "data", header: "Original Price", attribute: "price" },
    { type: "data", header: "Discounted Price", attribute: "discountedPrice" },
    { type: "data", header: "Final Price", attribute: "finalPrice" },
];

const instructions: { [x: string]: RenderInstruction<DiscountedPaymentProps> } = {};

// instructions.date = {
//     type: "complex-custom",
//     viewComponent: ({ fullObject }) => {
//         return <div>
//             {new Date(fullObject.date._seconds * 1000).toDateString()}
//         </div>
//     },
//     editComponent: null
// }

instructions.amount = {
    type: "currency",
}

const DiscountedItemsModal = ({ isOpen, closeModal, discountedItemsInCheck, closedCheck, staffMember }: Props) => {

    const [loading, setLoading] = useState(false)
    const currency: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.currency;
    const noOp = () => Promise.resolve(true);

    const data: DiscountedPaymentProps[] = discountedItemsInCheck.map((discountedPayment: any) => {
        return {
            uniqueId: discountedPayment.product.productOrderId,
            date: closedCheck.createdAt,
            checkNumber: closedCheck.tabNumber,
            discountName: discountedPayment.discountApplied.discountName,
            serverName: staffMember && staffMember.contact ? staffMember.contact.firstName + ' ' + staffMember.contact.lastName : '',
            customer: discountedPayment.customer && discountedPayment.customer.firstName ? discountedPayment.customer.firstName + ' ' + discountedPayment.customer.lastName : 'Guest',
            itemName: discountedPayment.product.name,
            itemQuantity: discountedPayment.product.quantity,
            price: '$' + (discountedPayment.product.price * discountedPayment.product.quantity * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            discountedPrice: '$' + (discountedPayment.discountApplied.discountTotal * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            finalPrice: '$' + (discountedPayment.product.discountedPrice * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
            tab: closedCheck
        }
    })

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} width={"1100"} height={"500"}>
            <div className={"text-big text-black font-lato-black"}>
                Discounts
            </div>

            {data &&
                <div className={"flex px-10 py-6 overflow-y-auto"} >
                    <HorizontalTable
                        title=""
                        columns={columns}
                        data={data}
                        instructions={instructions}
                        saveResults={noOp}
                        setEditing={() => { }}
                        isEditing={false}
                        hideEdit
                        onRowClick={() => { }}
                    />
                </div>
            }
        </Modal>
    );
};
export default DiscountedItemsModal;
