import { Popover2 } from "@blueprintjs/popover2";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import { RootState } from "model/store";

import CustomGroups from "./CustomGroups";
import Departments from "./Departments";
import Levels from "./Levels";
import CustomGroupDialog from "./Modals/CustomGroupDialog";
import LevelDialog from "./Modals/LevelDialog";
import RoleForm from "./RoleForm";
import Roles from "./Roles";
import TreeView from "./TreeView";
import "./index.css";

const OrganizationalHierarchy = () => {
  const navigate = useNavigate();
  const [shouldShowCreateRole, setShouldShowCreateRole] =
    useState<boolean>(false);

  const tabList = [
    {
      id: 1,
      text: "Levels",
    },
    {
      id: 2,
      text: "Tree",
    },
    {
      id: 3,
      text: "Roles",
    },
    {
      id: 4,
      text: "Department",
    },
    {
      id: 5,
      text: "Custom Groups",
    },
  ];
  const [activeTab, setActiveTab] = useState(1);
  const [isActionPopupOpen, setIsActionPopup] = useState(false);
  const [isEditLevelPopupOpen, setIsEditLevelPopupOpen] = useState(false);
  const [isEditCustomGroupPopupOpen, setIsEditCustomGroupPopupOpen] =
    useState(false);

  const launchpad = useLaunchpad();
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const [modifyRole, setModifyRole] = useState<JobWithDepartment | undefined>();

  const renderContent = () => {
    if (activeTab === 1) {
      return <Levels />;
    } else if (activeTab === 2) {
      return <TreeView />;
    } else if (activeTab === 3) {
      if (shouldShowCreateRole) {
        return (
          <RoleForm
            setShouldShowCreateRole={setShouldShowCreateRole}
            selectedRole={modifyRole}
          />
        );
      }
      return (
        <Roles
          setShouldShowCreateRole={setShouldShowCreateRole}
          setModifyRole={setModifyRole}
        />
      );
    } else if (activeTab === 4) {
      return <Departments />;
    } else if (activeTab === 5) {
      return <CustomGroups />;
    }
  };

  const content = renderContent();

  return (
    <>
      <Box className="organizational-header">
        <Box className="header-logos">
          <img src={require("assets/OrganizationalLogo.svg").default} />
          <img
            src={require("assets/OrganizationalLauncher.svg").default}
            onClick={() => {
              if (isEnterpriseLevel) {
                navigate("/enterprise");
              } else {
                launchpad.setVisibility(true);
              }
            }}
          />
        </Box>
        <Box className="header-tabs">
          <p className="selected-tab" style={{ marginRight: 20 }}>
            Organization
            <div className="selected-border"></div>
          </p>
          {/*
           <p>
           People
           <div className="selected-border"></div>
           </p>
           */}
        </Box>
      </Box>
      <Box className="organizational-page">
        <Box className="organizational-sidebar">
          {(activeTab === 1 || activeTab === 4 || activeTab === 5) && (
            <Box
              style={{
                marginBottom: "24px",
              }}
            >
              <Popover2
                interactionKind="click"
                placement="bottom"
                content={
                  <div className="role-popover-content">
                    <p
                      onClick={() => {
                        tabList[activeTab - 1].text === "Levels"
                          ? setIsEditLevelPopupOpen(true)
                          : "";
                        tabList[activeTab - 1].text === "Custom Groups"
                          ? setIsEditCustomGroupPopupOpen(true)
                          : "";
                        setIsActionPopup(!isActionPopupOpen);
                      }}
                    >
                      Edit {tabList[activeTab - 1].text}
                    </p>
                    <p
                      onClick={() => {
                        navigate("/manager/add-employee");
                        setIsActionPopup(!isActionPopupOpen);
                      }}
                    >
                      Add Employee
                    </p>
                  </div>
                }
                isOpen={isActionPopupOpen}
              >
                <button
                  className="action-button"
                  onClick={() => setIsActionPopup(!isActionPopupOpen)}
                >
                  Actions{" "}
                  <img src={require("assets/DropdownIcon.svg").default} />
                </button>
              </Popover2>
            </Box>
          )}

          {tabList.map((tabitem) => (
            <Box
              className={
                activeTab === tabitem.id
                  ? "selected-tab sidebar-item"
                  : "sidebar-item"
              }
              onClick={() => setActiveTab(tabitem.id)}
            >
              {tabitem.text}
            </Box>
          ))}
        </Box>
        <Box className="organizational-content">{content}</Box>
        {isEditLevelPopupOpen && (
          <LevelDialog
            isOpen={isEditLevelPopupOpen}
            setIsOpen={setIsEditLevelPopupOpen}
            dialogHeaderTitle="Edit Levels"
          />
        )}
        {isEditCustomGroupPopupOpen && (
          <CustomGroupDialog
            isOpen={isEditCustomGroupPopupOpen}
            setIsOpen={setIsEditCustomGroupPopupOpen}
            dialogHeaderTitle="Edit Custom Groups"
          />
        )}
      </Box>
    </>
  );
};

export default OrganizationalHierarchy;
