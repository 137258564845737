import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Assign } from "utility-types";

import {
  PayrollPunchIn,
  createClockPunch,
  deleteClockPunch,
  fetchStaffDetailsPayrollData,
  updateClockPunch,
  updateMultipleClockPunches,
} from "controllers/payroll";
import { formatCentValue } from "controllers/reporting";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  RowError,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import {
  DateEditComponent,
  DateViewComponent,
  TimeEditComponent,
  TimeViewComponent,
} from "../components/DateTimeInputTableExtensions";
import { usePayrollDateConfiguration } from "./usePayrollDateConfiguration";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type CreateClockPunchMutationData = {
  clockedIn: string;
  clockedOut: string | null;
  roleId: string;
  staffUID: string;
  payRate: number;
};

type DeleteClockPunchMutationData = {
  workEventId: string;
};

type WorkEventUpdateRequest = {
  clockedInISOString: string;
  clockedOutISOString: string | null;
  workEventId: string;
  roleId: string;
};

type UpdateClockPunchMutationData = {
  updateRequests: WorkEventUpdateRequest[];
};

type PayrollDetailItem = Assign<
  PayrollPunchIn,
  {
    uniqueId: string;
    formattedClockedInDate: string;
    formattedClockedOutDate: string | null;
    formattedClockedInTime: string;
    formattedClockedOutTime: string | null;
    formattedHourlyWage: string;
    formattedTotalGrossPay: string;
  }
>;

export const usePayrollDetails = () => {
  const params = useParams<{ employeeId: string }>();

  const [edit, setEdit] = useState(false);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const staffMember = useMemo(() => {
    console.log("params.employeeId", params.employeeId);
    return fellowStaffMembers.find((sm) => sm.id === params.employeeId) ?? null;
  }, [params.employeeId, fellowStaffMembers]);

  const [rowErrors, setRowErrors] = useState<RowError[]>([]);

  const businessId = useMemo(() => business?.id ?? null, [business?.id]);

  const {
    viablePayrollReportEdges,
    sortedViablePayrollReportEdges,
    selectedStartISODateString,
    selectedEndISODateString,
    maxDateRangePickerDate,
    minDateRangePickerDate,
    dateRangeChangeHandler,
    dateRange,
    customDateRangeOptions,
    onCustomDateRangeChange,
  } = usePayrollDateConfiguration();

  const staffPayrollDetails = useQuery(
    [
      "staffPayrollDetails",
      businessId,
      params.employeeId,
      selectedStartISODateString,
      selectedEndISODateString,
    ],
    async () => {
      if (
        selectedStartISODateString &&
        selectedEndISODateString &&
        params.employeeId
      ) {
        return fetchStaffDetailsPayrollData(
          businessId,
          params.employeeId,
          selectedStartISODateString,
          selectedEndISODateString
        );
      }
      return null;
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        Boolean(businessId) &&
        Boolean(params.employeeId) &&
        Boolean(selectedStartISODateString) &&
        Boolean(selectedEndISODateString),
    }
  );

  const columns: ColumnInstruction<PayrollDetailItem>[] = [
    { type: "data", header: "Role", attribute: "positionTitle" },
    {
      type: "data",
      header: "Clocked In Date",
      attribute: "formattedClockedInDate",
    },
    {
      type: "data",
      header: "Clocked In Time",
      attribute: "formattedClockedInTime",
    },
    {
      type: "data",
      header: "Clocked Out Date",
      attribute: "formattedClockedOutDate",
    },
    {
      type: "data",
      header: "Clocked Out Time",
      attribute: "formattedClockedOutTime",
    },
    { type: "data", header: "Total Hours", attribute: "totalHours" },
    { type: "data", header: "Hourly Wage", attribute: "formattedHourlyWage" },
    {
      type: "data",
      header: "Total Gross Pay",
      attribute: "formattedTotalGrossPay",
    },
  ];

  const instructions: {
    [x: string]: RenderInstruction<PayrollDetailItem>;
  } = {};

  instructions.positionTitle = useMemo((): RenderInstruction<PayrollDetailItem> => {
    if (!staffMember) {
      return { type: "default", readOnly: true };
    }
    const payRates = staffMember.payRates ?? [];
    console.log("payRates", payRates);

    if (!payRates.length) {
      return { type: "default", readOnly: true };
    }
    return {
      type: "select",
      options: payRates
        .filter((pr) => pr.role)
        .map((pr) => ({
          value: pr.role as string,
          label: pr.role as string,
        })),
      placeholder: "Select a role",
    };
  }, [staffMember]);

  instructions.totalHours = {
    type: "default",
    readOnly: true,
  };

  instructions.formattedHourlyWage = {
    type: "default",
    readOnly: true,
  };

  instructions.formattedTotalGrossPay = {
    type: "default",
    readOnly: true,
  };

  instructions.formattedClockedInDate = {
    type: "custom",
    viewComponent: DateViewComponent,
    editComponent: DateEditComponent,
  };

  instructions.formattedClockedOutDate = {
    type: "custom",
    viewComponent: DateViewComponent,
    editComponent: DateEditComponent,
  };

  instructions.formattedClockedInTime = {
    type: "custom",
    viewComponent: TimeViewComponent,
    editComponent: TimeEditComponent,
  };

  instructions.formattedClockedOutTime = {
    type: "custom",
    viewComponent: TimeViewComponent,
    editComponent: TimeEditComponent,
  };

  const tableData = staffPayrollDetails.data
    ? staffPayrollDetails.data.staffMemberOrientedData.punchIns.map(
        (punchIn) => {
          return {
            ...punchIn,
            uniqueId: punchIn.workEventId,
            formattedClockedInDate: moment(punchIn.clockedInISOString).format(
              "MM/DD/YYYY"
            ),
            formattedClockedOutDate: punchIn.clockedOutISOString
              ? moment(punchIn.clockedOutISOString).format("MM/DD/YYYY")
              : null,
            formattedClockedInTime: moment(punchIn.clockedInISOString).format(
              "hh:mm a"
            ),
            formattedClockedOutTime: punchIn.clockedOutISOString
              ? moment(punchIn.clockedOutISOString).format("hh:mm a")
              : null,
            formattedHourlyWage: formatter.format(
              formatCentValue(punchIn.payRate)
            ),
            formattedTotalGrossPay: formatter.format(
              formatCentValue(punchIn.totalPay)
            ),
          };
        }
      )
    : [];

  const [addedRows, setAddedRows] = useState<PayrollDetailItem[]>([]);

  const onEditToggle = useCallback(
    (v: boolean) => {
      if (!v && addedRows.length) {
        setAddedRows([]);
      }
      setEdit(v);
    },
    [setEdit, addedRows, setAddedRows]
  );

  const addRowHandler = useCallback(() => {
    const id = nanoid();
    const clockInMoment = moment();
    const clockOutMoment = moment().add(1, "hour");
    const additionalRow: PayrollDetailItem = {
      uniqueId: id,
      workEventId: id,
      positionTitle: "",
      positionId: "",
      formattedClockedInDate: clockInMoment.format("MM/DD/YYYY"),
      formattedClockedOutDate: null,
      formattedClockedInTime: clockInMoment.format("hh:mm a"),
      formattedClockedOutTime: null,
      clockedInISOString: clockInMoment.toISOString(),
      clockedOutISOString: clockOutMoment.toISOString(),
      payRate: 0,
      autoClockOut: false,
      totalHours: 1,
      formattedHourlyWage: formatter.format(0),
      formattedTotalGrossPay: formatter.format(0),
      totalPay: 0,
    };
    setAddedRows([...addedRows, additionalRow]);
    if (!edit) {
      setEdit(true);
    }
  }, [setAddedRows, addedRows, edit, setEdit]);

  const tableDataWithAddedRows = useMemo(() => {
    return [...addedRows, ...tableData];
  }, [tableData, addedRows]);

  const createClockPunchMutation = useMutation(
    (mutationData: CreateClockPunchMutationData) => {
      return createClockPunch(
        businessId,
        mutationData.clockedIn,
        mutationData.clockedOut,
        mutationData.roleId,
        mutationData.staffUID,
        mutationData.payRate
      );
    }
  );

  const deleteClockPunchMutation = useMutation(
    (mutationData: DeleteClockPunchMutationData) => {
      return deleteClockPunch(businessId, mutationData.workEventId);
    }
  );

  const updateClockPunchMutation = useMutation(
    (mutationData: UpdateClockPunchMutationData) => {
      return updateMultipleClockPunches(
        businessId,
        mutationData.updateRequests
      );
    }
  );

  const saveResultsHandler = useCallback(
    async (updateState: UpdateState) => {
      setRowErrors([]);
      console.log("updateState", updateState);
      if (!params.employeeId) return;

      const workEventsWithUpdates = _.keys(updateState).map((workEventId) => ({
        workEventId,
        updates: updateState[workEventId],
      }));

      console.log("workEventsWithUpdates", workEventsWithUpdates);

      const editErrors: RowError[] = workEventsWithUpdates
        .map((upd) => {
          const { workEventId, updates } = upd;
          const originalData = tableData.find(
            (d) => d.workEventId === workEventId
          );

          if (
            updates.formattedClockedInDate ||
            updates.formattedClockedInTime ||
            updates.formattedClockedOutDate ||
            updates.formattedClockedOutTime
          ) {
            const clockedInDate =
              updates.formattedClockedInDate?.newValue ||
              originalData?.formattedClockedInDate;
            const clockedInTime =
              updates.formattedClockedInTime?.newValue ||
              originalData?.formattedClockedInTime;
            const clockedOutDate =
              updates.formattedClockedOutDate?.newValue ||
              originalData?.formattedClockedOutDate;
            const clockedOutTime =
              updates?.formattedClockedOutTime?.newValue ||
              originalData?.formattedClockedOutTime;

            const positionTitle =
              updates.positionTitle?.newValue || originalData?.positionTitle;
            const positionId = staffMember?.payRates?.find(
              (pr) => pr.role === positionTitle
            )?.roleId;
            let positionTitleError: string | null = null;
            if (!positionId) {
              positionTitleError = "Position is required";
            }
            const clockedInISOString = moment(
              `${clockedInDate} ${clockedInTime}`,
              "MM/DD/YYYY hh:mm a"
            ).toISOString();
            const clockedOutISOString = moment(
              `${clockedOutDate} ${clockedOutTime}`,
              "MM/DD/YYYY hh:mm a"
            ).toISOString();
            const clockedIn = moment(clockedInISOString).toDate();
            const clockedOut = moment(clockedOutISOString).toDate();
            let formattedClockedInDateError: string | null = null;
            if (clockedIn > clockedOut || clockedIn === clockedOut) {
              formattedClockedInDateError =
                "Clocked in date must be before clocked out date";
            }
            console.log("positionTitleError", positionTitleError);
            const combinedErrorsForRow: RowError = {
              rowId: workEventId,
              generalErrorMessage: null,
              attributeErrors: {
                positionTitle: positionTitleError,
                formattedClockedInDate: formattedClockedInDateError,
              },
            };

            return combinedErrorsForRow;
          }
          return null;
        })
        .filter((x) => !!x) as RowError[];
      const addRowsErrors: RowError[] = addedRows
        .map((addedRow) => {
          const updateStateForAddedRow = updateState[addedRow.uniqueId];

          const originalData = addedRow;

          const clockedInDate =
            updateStateForAddedRow?.formattedClockedInDate?.newValue ||
            originalData?.formattedClockedInDate;
          const clockedInTime =
            updateStateForAddedRow?.formattedClockedInTime?.newValue ||
            originalData?.formattedClockedInTime;
          const clockedOutDate =
            updateStateForAddedRow?.formattedClockedOutDate?.newValue ||
            originalData?.formattedClockedOutDate;
          const clockedOutTime =
            updateStateForAddedRow?.formattedClockedOutTime?.newValue ||
            originalData?.formattedClockedOutTime;

          const positionTitle =
            updateStateForAddedRow?.positionTitle?.newValue ||
            originalData?.positionTitle;
          const positionId = staffMember?.payRates?.find(
            (pr) => pr.role === positionTitle
          )?.roleId;
          let positionTitleError: string | null = null;
          if (!positionId) {
            positionTitleError = "Position is required";
          }
          const clockedInISOString = moment(
            `${clockedInDate} ${clockedInTime}`,
            "MM/DD/YYYY hh:mm a"
          ).toISOString();
          const clockedOutISOString = moment(
            `${clockedOutDate} ${clockedOutTime}`,
            "MM/DD/YYYY hh:mm a"
          ).toISOString();
          const clockedIn = moment(clockedInISOString).toDate();
          const clockedOut = moment(clockedOutISOString).toDate();
          let formattedClockedInDateError: string | null = null;
          if (clockedIn > clockedOut || clockedIn === clockedOut) {
            formattedClockedInDateError =
              "Clocked in date must be before clocked out date";
          }
          console.log("positionTitleError", positionTitleError);
          const combinedErrorsForRow: RowError = {
            rowId: addedRow.uniqueId,
            generalErrorMessage: null,
            attributeErrors: {
              positionTitle: positionTitleError,
              formattedClockedInDate: formattedClockedInDateError,
            },
          };

          if (positionTitleError || formattedClockedInDateError) {
            return combinedErrorsForRow;
          }
          return null;
        })
        .filter((x) => !!x) as RowError[];
      console.log("editErrors", editErrors);
      console.log("addRowsErrors", addRowsErrors);
      const hasErrors =
        (editErrors.length &&
          (editErrors.some((e) => e.generalErrorMessage) ||
            editErrors.some(
              (e) =>
                e.attributeErrors &&
                _.values(e.attributeErrors).some((v) => !!v)
            ))) ||
        (addRowsErrors.length &&
          (addRowsErrors.some((e) => e.generalErrorMessage) ||
            addRowsErrors.some(
              (e) =>
                e.attributeErrors &&
                _.values(e.attributeErrors).some((v) => !!v)
            )));
      console.log("hasErrors", hasErrors);
      if (!hasErrors) {
        const workEventsToCreate = workEventsWithUpdates.filter((we) => {
          return !tableData.find((d) => d.workEventId === we.workEventId);
        });
        console.log("workEventsToCreate", workEventsToCreate);
        const workEventsToUpdate = workEventsWithUpdates.filter((we) => {
          return tableData.find((d) => d.workEventId === we.workEventId);
        });
        console.log("workEventsToUpdate", workEventsToUpdate);
        await Promise.all(
          workEventsToCreate.map(async (weToCreate) => {
            try {
              const originalData = addedRows.find(
                (d) => d.workEventId === weToCreate.workEventId
              );

              console.log("originalData", originalData);

              const updateFormattedClockedInDate =
                (weToCreate.updates.formattedClockedInDate
                  ?.newValue as string) ??
                (originalData?.formattedClockedInDate as string);
              const updateFormattedClockedInTime =
                weToCreate.updates.formattedClockedInTime?.newValue ??
                originalData?.formattedClockedInTime;
              const updateFormattedClockedOutDate =
                (weToCreate.updates.formattedClockedOutDate
                  ?.newValue as string) ??
                (originalData?.formattedClockedOutDate as string);
              const updateFormattedClockedOutTime =
                weToCreate.updates.formattedClockedOutTime?.newValue ??
                originalData?.formattedClockedOutTime;
              if (
                !updateFormattedClockedInDate ||
                !updateFormattedClockedInTime
              ) {
                return null;
              }
              if (
                (updateFormattedClockedOutDate &&
                  !updateFormattedClockedOutTime) ||
                (!updateFormattedClockedOutDate &&
                  updateFormattedClockedOutTime)
              ) {
                return null;
              }
              const clockedInISOString = moment(
                `${moment(updateFormattedClockedInDate).format(
                  "MM/DD/YYYY"
                )} ${updateFormattedClockedInTime}`,
                "MM/DD/YYYY hh:mm a"
              ).toISOString();
              const clockedOutISOString = updateFormattedClockedOutDate
                ? moment(
                    `${moment(updateFormattedClockedOutDate).format(
                      "MM/DD/YYYY"
                    )} ${updateFormattedClockedOutTime}`,
                    "MM/DD/YYYY hh:mm a"
                  ).toISOString()
                : null;
              const positionTitle = (weToCreate.updates.positionTitle
                ?.newValue ?? originalData?.positionTitle) as unknown as string;
              const payRateData = positionTitle
                ? staffMember?.payRates?.find(
                    (pr) => pr?.role === positionTitle
                  )
                : null;

              if (payRateData) {
                const positionId = payRateData.roleId as string;
                const payRate = payRateData.amount;
                await createClockPunchMutation.mutateAsync({
                  clockedIn: clockedInISOString,
                  clockedOut: clockedOutISOString,
                  roleId: positionId,
                  staffUID: params.employeeId as string,
                  payRate: payRate,
                });
              }
            } catch (e) {
              console.log(e);
            }
          })
        );

        const workEventsUpdateRequests = workEventsToUpdate.map(
          (weToUpdate) => {
            const originalData = tableData.find(
              (d) => d.workEventId === weToUpdate.workEventId
            );
            const updateFormattedClockedInDate =
              (weToUpdate.updates.formattedClockedInDate?.newValue as string) ??
              originalData?.formattedClockedInDate;
            const updateFormattedClockedInTime =
              weToUpdate.updates.formattedClockedInTime?.newValue ??
              originalData?.formattedClockedInTime;
            const updateFormattedClockedOutDate =
              (weToUpdate.updates.formattedClockedOutDate
                ?.newValue as string) ?? originalData?.formattedClockedOutDate;
            const updateFormattedClockedOutTime =
              weToUpdate.updates.formattedClockedOutTime?.newValue ??
              originalData?.formattedClockedOutTime;
            if (
              !updateFormattedClockedInDate ||
              !updateFormattedClockedInTime ||
              !updateFormattedClockedOutDate ||
              !updateFormattedClockedOutTime
            ) {
              return null;
            }
            const clockedInISOString = moment(
              `${moment(updateFormattedClockedInDate).format(
                "MM/DD/YYYY"
              )} ${updateFormattedClockedInTime}`,
              "MM/DD/YYYY hh:mm a"
            ).toISOString();
            const clockedOutISOString = moment(
              `${moment(updateFormattedClockedOutDate).format(
                "MM/DD/YYYY"
              )} ${updateFormattedClockedOutTime}`,
              "MM/DD/YYYY hh:mm a"
            ).toISOString();

            const positionTitle =
              weToUpdate.updates.positionTitle?.newValue ||
              originalData?.positionTitle;
            const positionId = staffMember?.payRates?.find(
              (pr) => pr.role === positionTitle
            )?.roleId as string;
            return {
              clockedInISOString,
              clockedOutISOString,
              workEventId: weToUpdate.workEventId,
              roleId: positionId,
            };
          }
        );

        await updateClockPunchMutation.mutateAsync({
          updateRequests: workEventsUpdateRequests.filter((x) => !!x) as {
            clockedInISOString: string;
            clockedOutISOString: string;
            workEventId: string;
            roleId: string;
          }[],
        });
        console.log("before set added rows");
        onEditToggle(false);

        setAddedRows([]);
        staffPayrollDetails.refetch();
      } else {
        console.log("addRowsErrors", addRowsErrors);
        setRowErrors([...editErrors, ...addRowsErrors]);
      }
    },
    [
      tableData,
      params.employeeId,
      setAddedRows,
      addedRows,
      staffPayrollDetails,
      staffMember,
      onEditToggle,
      addedRows,
    ]
  );

  const deleteRowsHandler = useCallback(
    async (args: string[]) => {
      const tableRowsToDelete = tableData.filter((tr) =>
        args.includes(tr.workEventId)
      );

      setAddedRows(addedRows.filter((ar) => !args.includes(ar.workEventId)));

      await Promise.all(
        tableRowsToDelete.map(async (tr) => {
          try {
            await deleteClockPunchMutation.mutateAsync({
              workEventId: tr.workEventId,
            });
          } catch (e) {
            console.log(e);
          }
        })
      );
      staffPayrollDetails.refetch();
    },
    [addedRows, tableData]
  );

  const generalLoading =
    updateClockPunchMutation.isLoading ||
    createClockPunchMutation.isLoading ||
    deleteClockPunchMutation.isLoading ||
    viablePayrollReportEdges.isFetching ||
    staffPayrollDetails.isFetching ||
    staffPayrollDetails.isLoading ||
    viablePayrollReportEdges.isLoading ||
    viablePayrollReportEdges.isFetching ||
    staffPayrollDetails.isFetching ||
    staffPayrollDetails.isLoading;

  const formattedTotalGrossPay = useMemo(() => {
    if (staffPayrollDetails.data) {
      return formatter.format(
        formatCentValue(
          staffPayrollDetails.data?.staffMemberOrientedData.totalGrossPay
        )
      );
    }
    return "";
  }, [staffPayrollDetails.data]);

  return {
    columns,
    instructions,
    tableData,
    maxDateRangePickerDate,
    minDateRangePickerDate,
    selectedStartISODateString,
    selectedEndISODateString,
    staffPayrollDetails,
    edit,
    setEdit,
    viablePayrollReportEdges,
    dateRange,
    dateRangeChangeHandler,
    saveResultsHandler,
    addedRows,
    addRowHandler,
    tableDataWithAddedRows,
    generalLoading,
    deleteRowsHandler,
    customDateRangeOptions,
    onCustomDateRangeChange,
    rowErrors,
    formattedTotalGrossPay,
    onEditToggle,
  };
};
