import firebase from "../config/firebase";

const db = firebase.firestore();

const addBusinessUpdate = (businessId: string, code: number, message: any) =>
  // console.log("update notifications collection", businessId, code, message)

  // FIRST SEE IF Business has a document in the collection
  db
    .collection("BusinessUpdates")
    .doc(businessId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        // IF doc exists in BusinessUpdates append new message in logs array

        return db
          .collection("BusinessUpdates")
          .doc(businessId)
          .update({
            businessId,
            updateCode: firebase.firestore.FieldValue.arrayUnion(code),
            logs: firebase.firestore.FieldValue.arrayUnion(message),
            updateRequired: true,
          })
          .then(() => {
            // console.log("Document successfully updated!");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating BusinessUpdates document:", error);
          });
      }

      // IF NO doc in BusinessUpdates create thie first one fro businessID
      db.collection("BusinessUpdates")
        .doc(businessId)
        .set({
          businessId,
          updateCode: [code],
          logs: [message],
        })
        .then(() => {
          // console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing to BusinessUpdates document:", error);
        });
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

const fetchAccountTempPassword = async (accountId: string) => {
  return db
    .collection("Accounts")
    .doc(accountId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        //@ts-ignore
        if (doc.data().autoGeneratedPassword) {
          return true;
        } else {
          return false;
        }
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return false;
    });
};

const updateCurrentUserPassword = async (newPassword: string) => {
  const user = firebase.auth().currentUser;
  return user
    ?.updatePassword(newPassword)
    .then(() => {
      //after update password set autogenerated password to null so no more alerts to set new password
      return db
        .collection("Accounts")
        .doc(user?.uid)
        .update({ autoGeneratedPassword: null, updatedAt: new Date() })
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("error", error);
          return true; //password is updated in frebase just not set in the account so still return true
        });
    })
    .catch((error) => {
      console.log("error updating current user password", error);
      return false;
    });
};

export {
  addBusinessUpdate,
  fetchAccountTempPassword,
  updateCurrentUserPassword,
};
