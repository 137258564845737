import { ReviewResponseTemplate } from '../../../types/reputationManagement';
import { RootState } from 'model/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const useTemplates = () => {
  const business: TangoBusiness = useSelector((state: RootState) => state.business);

  const [templates, setTemplates] = useState<ReviewResponseTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewResponseTemplate | null>(null);
  const [showNewTemplateModal, setShowNewTemplateModal] = useState(false);
  return {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    showNewTemplateModal,
    setShowNewTemplateModal,
    setTemplates,
    business,
  };
};
export default useTemplates;
