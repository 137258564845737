//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";

interface ClickableWrapperProps {
  children: any;
  onClick?: (e: any) => void;
  className?: string;
  disabled?: boolean;
}

export const ClickableWraper = (props: ClickableWrapperProps) => (
  <motion.div
    disabled={props.disabled}
    onClick={props.disabled ? undefined : props.onClick}
    whileTap={props.disabled ? undefined : { scale: 0.9, opacity: 0.9 }}
    className={props.className}
  >
    {props.children}
  </motion.div>
);
