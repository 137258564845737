import {
  Button,
  Dialog,
  EditableText,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  Position,
  Slider,
  Switch,
  Toaster,
} from "@blueprintjs/core";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { MultiSelect, Select } from "@blueprintjs/select";
import FontPicker from "font-picker-react";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SketchPicker } from "react-color";
import { ReactFlowProvider } from "react-flow-renderer";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteFormConfirmationModal from "views/FormResponses/DeleteFormConfirmationModal";
import { Form } from "views/ViewForms/useForms";

import Box from "components/Box";

import BottomImage from "assets/bottom-image.svg";
import ChartIcon from "assets/chart-icon.svg";
import LeftImage from "assets/left-image.svg";
import RightImage from "assets/right-image.svg";
import ChevronDown from "assets/survery-builder/chevron-down-icon.svg";
import EmailIcon from "assets/survery-builder/email-icon.svg";
import EndStepIcon from "assets/survery-builder/end-step-icon.svg";
import LinkIcon from "assets/survery-builder/link-icon.svg";
import LongTextIcon from "assets/survery-builder/long-text-icon.svg";
import PhoneIcon from "assets/survery-builder/phone-icon.svg";
import PictureChoiceIcon from "assets/survery-builder/picture-choice-icon.svg";
import RatingsIcon from "assets/survery-builder/rating-icon.svg";
import ShortTextIcon from "assets/survery-builder/short-text-icon.svg";
import TickIcon from "assets/survery-builder/tick-icon.svg";
import YesNoIcon from "assets/survery-builder/yes-no-icon.svg";
import TopImage from "assets/top-image.svg";

import { RootState } from "../../model/store";
import {
  createForm,
  deleteFormResponses,
  fetchForm,
  updateForm,
  updateFormField,
} from "../../models/surveyBuilder";
import CorrectOrder from "./components/admin/CorrectOrder";
import DigitalSignature from "./components/admin/DigitalSignature";
import DropDown from "./components/admin/Dropdown";
import Email from "./components/admin/Email";
import FillIntheBlank from "./components/admin/FillIntheBlank";
import LabelPicture from "./components/admin/LabelPicture";
import Legal from "./components/admin/Legal";
import Logic from "./components/admin/Logic";
import Matching from "./components/admin/Matching";
import MultipleChoice from "./components/admin/MultipleChoice";
import PhoneInput from "./components/admin/Phone";
import PictureChoice from "./components/admin/PictureChoice";
import Ratings from "./components/admin/Ratings";
import ShortText from "./components/admin/ShortText";
import UploadFile from "./components/admin/UploadFile";
import Url from "./components/admin/Url";
import YesNo from "./components/admin/YesNo";
import CorrectOrderClient from "./components/client/CorrectOrder";
import FillInTheBlankClient from "./components/client/FillInTheBlank";
import MatchingClient from "./components/client/Matching";
import RatingsClient from "./components/client/Ratings";
import { componentTypes, themesData } from "./data";
import useEndingSection from "./hooks/useEndingSection";
import useQuestions from "./hooks/useQuestions";
import useThemes from "./hooks/useThemes";
import LogicModal from "./modals/LogicModal/LogicModal";
import SaveDraftConfirmationModal from "./modals/SaveDraftConfirmationModal";
import SharableLinkModal from "./modals/SharableLinkModal";
import "./style.css";
import { ComponentType, FormType, ThemeProp } from "./types";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const formTypes: FormType[] = [
  "Quiz/Training",
  "General Survey",
  "Ticket",
  "Job Application",
];
const toaster = Toaster.create({
  position: Position.TOP,
});

const SurveyBuilder = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const bgImageInputRef = useRef<HTMLInputElement>(null);
  const endingSectionBgImageRef = useRef<HTMLInputElement>(null);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const [showLogicModal, setShowLogicModal] = useState<boolean>(false);
  const [showSharableLinkModal, setShowSharableLinkModal] =
    useState<boolean>(false);
  const [showPublishedText, setShowPublishedText] = useState<boolean>(false);
  const [showDeleteFormConfirmationModal, setShowDeleteFormConfirmationModal] =
    useState<boolean>(false);

  const {
    questions,
    selectedQuestion,
    uploadingIndex,
    createNewQuestion,
    deleteOption,
    deleteMatchingOption,
    handleFileChange,
    updateDropdownOptions,
    updateMatchingChoice,
    addMatchingChoice,
    addFillInTheBlankChoice,
    updateFillInTheBlankChoice,
    updateMultipleChoice,
    addMultipleChoiceOption,
    updateQuestion,
    updateComponentType,
    setQuestions,
    setSelectedQuestion,
    showSaveAsDraftModal,
    setShowSaveAsDraftModal,
    closeSaveDraftConfirmationModal,
    deleteQuestion,
  } = useQuestions();
  const {
    themes,
    selectedTheme,
    selectedFile,
    showThemeSettings,
    selectedBrightness,
    uploadThemeImage,
    revertThemeSettings,
    saveNewTheme,
    saveChangesOfTheme,
    addNewThemeData,
    handleEditThemeButton,
    setShowThemeSettings,
    setSelectedTheme,
    setSelectedBrightness,
    setSelectedFile,
  } = useThemes(bgImageInputRef);

  const {
    endingSections,
    selectedEndingSection,
    showEndingSection,
    updateEndingSectionStep,
    uploadEndingSectionImage,
    addNewSection,
    setSelectedEndingSection,
    setShowEndingSection,
    setEndingSections,
    welcomeScreen,
    deleteEndingSection,
  } = useEndingSection(endingSectionBgImageRef, questions);
  const [selectedTab, setSelectedTab] = useState<
    "question" | "design" | "logic"
  >("question");
  const [selectedSegment, setSelectedSegment] = useState<
    "my-themes" | "gallery"
  >("my-themes");

  // const [activeTab]
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [surveyInfo, setSurveyInfo] = useState<{
    typeformDisplayName: string;
    typeformDescription: string;
  }>({
    typeformDisplayName: "Survey Name",
    typeformDescription: "Give your survey a description..",
  });
  const [formId, setFormId] = useState<string>(id || "");
  const [formData, setFormData] = useState<{ name: string; type: string }>({
    name: "",
    type: "",
  });
  const [existingFormData, setExistingFormData] = useState<Form | null>(null);
  const [showCreateFormModal, setShowCreateFormModa] = useState<boolean>(false);

  useEffect(() => {
    if (!id && formData.type.length === 0 && formData.name.length === 0) {
      setShowCreateFormModa(true);
    }
    if (id) {
      const fetchData = async () => {
        const response = await fetchForm(id);
        if (response.data) {
          setQuestions(response.data.questions);
          setSelectedEndingSection(response.data.selectedEndingSection);
          setEndingSections(response.data.endingSections);
          setSelectedTheme(response.data.selectedTheme);
          setSelectedQuestion(response.data.questions[0]);
          setFormData({ name: response.data.name, type: response.data.type });
          //@ts-ignore
          setExistingFormData(response.data);
        }
      };
      fetchData();
    }
  }, []);

  const getIcon = useCallback((componentType: ComponentType) => {
    switch (componentType) {
      case ComponentType.shortText:
        return <img src={ShortTextIcon} />;
      case ComponentType.longText:
        return <img src={LongTextIcon} />;
      case ComponentType.email:
        return <img src={EmailIcon} />;
      case ComponentType.phone:
        return <img src={PhoneIcon} />;
      case ComponentType.url:
        return <img src={LinkIcon} />;
      case ComponentType.multipleChoice:
        return <img src={TickIcon} />;
      case ComponentType.pictureChoice:
        return <img src={PictureChoiceIcon} />;
      case ComponentType.yesNo:
        return <img src={YesNoIcon} />;
      case ComponentType.dropdown:
        return <img src={ChevronDown} />;
      case ComponentType.ratings:
        return <img src={RatingsIcon} />;
      case ComponentType.uploadFile:
        return <img src={RatingsIcon} />;
      case ComponentType.legal:
        return <img src={RatingsIcon} />;
      case ComponentType.correctOrder:
        return <img src={LongTextIcon} />;
      case ComponentType.digitalSignature:
        return <img src={RatingsIcon} />;
      case ComponentType.labelPicture:
        return <img src={RatingsIcon} />;
      case ComponentType.fillInBlank:
        return <img src={RatingsIcon} />;
      case ComponentType.endingSection:
        return <img src={EndStepIcon} />;
      default:
        return <img src={ShortTextIcon} />;
    }
  }, []);

  const getTypeLabelFromSlug = useMemo(() => {
    if (selectedQuestion) {
      if (selectedQuestion.type === ComponentType.shortText) {
        return "Short Text";
      }
      if (selectedQuestion.type === ComponentType.longText) {
        return "Long Text";
      }
      if (selectedQuestion.type === ComponentType.email) {
        return "Email";
      }
      if (selectedQuestion.type === ComponentType.phone) {
        return "Phone";
      }
      if (selectedQuestion.type === ComponentType.url) {
        return "URL";
      }
      if (selectedQuestion.type === ComponentType.multipleChoice) {
        return "Multiple Choice";
      }
      if (selectedQuestion.type === ComponentType.pictureChoice) {
        return "Picture Choice";
      }
      if (selectedQuestion.type === ComponentType.yesNo) {
        return "Yes/No";
      }
      if (selectedQuestion.type === ComponentType.dropdown) {
        return "Dropdown";
      }
      if (selectedQuestion.type === ComponentType.ratings) {
        return "Ratings";
      }
      if (selectedQuestion.type === ComponentType.uploadFile) {
        return "Upload File";
      }
      if (selectedQuestion.type === ComponentType.legal) {
        return "Legal Accept/Decline";
      }
      if (selectedQuestion.type === ComponentType.correctOrder) {
        return "Correct Order";
      }
      if (selectedQuestion.type === ComponentType.digitalSignature) {
        return "Digital Signature";
      }
      if (selectedQuestion.type === ComponentType.labelPicture) {
        return "Label Picture";
      }
      if (selectedQuestion.type === ComponentType.fillInBlank) {
        return "Fill In The Blank";
      }
      if (selectedQuestion.type === ComponentType.matching) {
        return "Matching";
      }
      if (selectedQuestion.type === ComponentType.statement) {
        return "Statement";
      }
    }
    return "";
  }, [selectedQuestion]);

  const getComponent = (componentType: ComponentType) => {
    if (selectedQuestion) {
      switch (componentType) {
        case ComponentType.shortText:
          return (
            <ShortText
              onChange={() => console.log("yey")}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.longText:
          return (
            <ShortText
              onChange={() => console.log("yey")}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.email:
          return (
            <Email
              onChange={() => console.log("yey")}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.phone:
          return (
            <PhoneInput
              phoneNumber={""}
              onChange={() => console.log("yey")}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.url:
          return (
            <Url
              onChange={() => console.log("yey")}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.multipleChoice:
          return (
            <MultipleChoice
              deleteOption={deleteOption}
              color={selectedTheme.answerColor}
              updateChoice={updateMultipleChoice}
              addChoice={addMultipleChoiceOption}
              options={selectedQuestion.values}
            />
          );
        case ComponentType.pictureChoice:
          return (
            <PictureChoice
              deleteOption={deleteOption}
              color={selectedTheme.answerColor}
              updateChoice={updateMultipleChoice}
              addChoice={addMultipleChoiceOption}
              options={selectedQuestion.values}
              images={selectedQuestion.images}
              handleFileChange={handleFileChange}
              uploadingIndex={uploadingIndex}
            />
          );
        case ComponentType.yesNo:
          return (
            <YesNo
              updateChoice={() => console.log("yey")}
              value={""}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.dropdown:
          return (
            <DropDown
              color={selectedTheme.answerColor}
              options={selectedQuestion.values}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                const dropdownOptions = e.target.value.split("\n");
                updateDropdownOptions(dropdownOptions);
              }}
            />
          );
        case ComponentType.ratings:
          return <Ratings color={selectedTheme.answerColor} />;
        case ComponentType.uploadFile:
          return <UploadFile color={selectedTheme.answerColor} />;
        case ComponentType.legal:
          return (
            <Legal
              color={selectedTheme.answerColor}
              updateChoice={() => console.log("yey")}
              value=""
            />
          );
        case ComponentType.correctOrder:
          return (
            <CorrectOrder
              color={selectedTheme.answerColor}
              deleteOption={deleteOption}
              updateChoice={updateMultipleChoice}
              addChoice={addMultipleChoiceOption}
              options={selectedQuestion.values}
            />
          );
        case ComponentType.digitalSignature:
          return <DigitalSignature color={selectedTheme.answerColor} />;
        case ComponentType.labelPicture:
          return (
            <LabelPicture
              color={selectedTheme.answerColor}
              images={selectedQuestion.images}
              handleFileChange={handleFileChange}
              uploadingIndex={uploadingIndex}
            />
          );
        case ComponentType.fillInBlank:
          return (
            <FillIntheBlank
              deleteOption={deleteOption}
              color={selectedTheme.answerColor}
              updateChoice={updateFillInTheBlankChoice}
              addChoice={addFillInTheBlankChoice}
              options={selectedQuestion.values}
            />
          );
        case ComponentType.matching:
          return (
            <Matching
              deleteOption={deleteMatchingOption}
              updateChoice={updateMatchingChoice}
              addChoice={addMatchingChoice}
              options={selectedQuestion.values}
              color={selectedTheme.answerColor}
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };
  const getAnswerComponent = () => {
    if (selectedQuestion) {
      const componentType = selectedQuestion.type;
      switch (componentType) {
        case ComponentType.multipleChoice:
          return (
            <MultiSelect
              className="answer-select-dropdown"
              placeholder="Select Correct responses"
              popoverProps={{
                usePortal: false,
                fill: true,
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              tagInputProps={{
                leftIcon: <Icon icon="chevron-down" />,
              }}
              onRemove={(item, index) => {
                const answers = [...selectedQuestion.answer];
                answers.splice(index, 1);
                updateQuestion("answer", answers);
              }}
              fill
              selectedItems={selectedQuestion.answer || []}
              tagRenderer={(item: string) => {
                const answerIndex = selectedQuestion.answer.findIndex(
                  (answer: any) => answer === item
                );
                return (
                  <div className="selected-answer-tag">
                    <span>
                      {answerIndex || answerIndex === 0
                        ? String.fromCharCode(answerIndex + 65)
                        : ""}
                    </span>
                    {item}
                  </div>
                );
              }}
              //@ts-ignore
              items={selectedQuestion.values ? selectedQuestion.values : []}
              onItemSelect={(item) => {
                if (
                  selectedQuestion.answer &&
                  selectedQuestion.answer.length > 0
                ) {
                  updateQuestion("answer", [...selectedQuestion.answer, item]);
                } else {
                  updateQuestion("answer", [item]);
                }
              }}
              itemRenderer={(item: string, { handleClick, index }) => {
                const isSelected =
                  selectedQuestion.answer &&
                  selectedQuestion.answer.includes(item);
                return (
                  <div
                    className={`answer-dropdown-item ${isSelected ? "active" : ""
                      }`}
                    onClick={handleClick}
                  >
                    <span>
                      {index || index === 0
                        ? String.fromCharCode(index + 65)
                        : ""}
                    </span>
                    {item}
                    {isSelected ? <Icon icon="tick" /> : null}
                  </div>
                );
              }}
            />
          );
        case ComponentType.longText:
        case ComponentType.shortText:
        case ComponentType.email:
        case ComponentType.phone:
        case ComponentType.url:
        case ComponentType.uploadFile:
        case ComponentType.digitalSignature:
          return (
            <Select
              className="score-dropdown"
              items={["1", "2", "3"]}
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              fill
              matchTargetWidth
              filterable={false}
              itemRenderer={(item, { handleClick }) => {
                const isSelected = parseInt(item) === selectedQuestion.points;
                return (
                  <div
                    className={`score-dropdown-item ${isSelected ? "active" : ""
                      }`}
                    onClick={handleClick}
                  >
                    <span>{item}</span>
                    marks allocated to this question
                    {isSelected ? <Icon icon="tick" /> : null}
                  </div>
                );
              }}
              onItemSelect={(item) => {
                updateQuestion("points", item);
              }}
            >
              <Button
                minimal
                text={
                  selectedQuestion.points
                    ? selectedQuestion.points +
                    " marks allocated to this question"
                    : "How many marks is this question worth?"
                }
                fill
                rightIcon="chevron-down"
              />
            </Select>
          );
        case ComponentType.correctOrder:
          return (
            <CorrectOrderClient
              options={
                selectedQuestion.answer
                  ? selectedQuestion.answer
                  : selectedQuestion.values
              }
              updateChoice={(choices) => {
                updateQuestion("answer", choices);
              }}
              color={selectedTheme.answerColor}
            />
          );
        case ComponentType.labelPicture:
          return (
            <div className="label-picture-component">
              <div
                className={`single-choice-container active`}
                style={{ borderColor: themesData[0].answerColor }}
              >
                <Box display="flex">
                  <input
                    style={{ color: themesData[0].answerColor }}
                    placeholder="Add a label answer here"
                    className="choice-name apply-font"
                    value={selectedQuestion.answer || ""}
                    onChange={(e) => updateQuestion("answer", e.target.value)}
                  />
                </Box>
              </div>
            </div>
          );
        case ComponentType.matching:
          return (
            <MatchingClient
              value={
                selectedQuestion.answer
                  ? selectedQuestion.answer
                  : selectedQuestion.values
              }
              updateChoice={(type, choices) => {
                const answer = selectedQuestion.answer
                  ? { ...selectedQuestion.answer }
                  : { ...selectedQuestion.values };
                answer[type] = choices;
                updateQuestion("answer", answer);
              }}
              color={themesData[0].answerColor}
            />
          );
        case ComponentType.yesNo:
        case ComponentType.pictureChoice:
        case ComponentType.legal:
        case ComponentType.dropdown:
          return (
            <Select
              className="score-dropdown"
              items={
                componentType === ComponentType.yesNo
                  ? ["yes", "no"]
                  : componentType === ComponentType.legal
                    ? ["I accept", "I don't accept"]
                    : selectedQuestion.values
              }
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              fill
              matchTargetWidth
              filterable={false}
              itemRenderer={(item, { handleClick, index }) => {
                const isSelected = selectedQuestion.answer
                  ? selectedQuestion.answer.includes(item)
                  : false;
                return (
                  <div
                    className={`score-dropdown-item ${isSelected ? "active" : ""
                      }`}
                    onClick={handleClick}
                  >
                    <span>
                      {index || index === 0
                        ? String.fromCharCode(index + 65)
                        : ""}
                    </span>
                    {item}
                    {isSelected ? <Icon icon="tick" /> : null}
                  </div>
                );
              }}
              onItemSelect={(item) => {
                // @ts-ignore
                updateQuestion("answer", item);
              }}
            >
              <Button
                minimal
                text={
                  selectedQuestion.answer
                    ? selectedQuestion.answer
                    : "Select the correct response."
                }
                fill
                rightIcon="chevron-down"
              />
            </Select>
          );
        case ComponentType.ratings:
          return (
            <RatingsClient
              rating={selectedQuestion.answer ? selectedQuestion.answer : 0}
              onRating={(newRatings) => {
                updateQuestion("answer", newRatings);
              }}
              color={themesData[0].answerColor}
            />
          );
        case ComponentType.fillInBlank:
          return (
            <FillInTheBlankClient
              value={selectedQuestion.values}
              selectedAnswers={
                selectedQuestion.answer && selectedQuestion.answer.length > 0
                  ? selectedQuestion.answer
                  : []
              }
              updateAnswer={(index, value) => {
                if (
                  selectedQuestion.answer &&
                  selectedQuestion.answer.length > 0
                ) {
                  const clonedAnswer = [...selectedQuestion.answer];
                  clonedAnswer[index] = value;
                  updateQuestion("answer", clonedAnswer);
                } else {
                  updateQuestion("answer", [value]);
                }
              }}
              color={themesData[0].answerColor}
            />
          );
        default:
          return <div>yey</div>;
      }
    }
  };
  const updateSurveyDetail = (
    key: "typeformDisplayName" | "typeformDescription",
    value: string
  ) => {
    const clonedSurveyInfo = { ...surveyInfo };
    clonedSurveyInfo[key] = value;
    setSurveyInfo(clonedSurveyInfo);
  };

  const renderTheme = () => {
    //TODO: show revert modal when go back to previous from theme
    return (
      <>
        <div className="segment-header">
          <Icon
            icon="chevron-left"
            onClick={() => setShowThemeSettings(false)}
          />
          <EditableText
            value={selectedTheme?.themeName}
            onChange={(themeName) => {
              setSelectedTheme({
                ...selectedTheme,
                themeName: themeName,
                status: "draft",
              });
            }}
            className="editable-theme-name"
          />
        </div>
        <div className="type-section">
          <div
            className="upload-background"
            onClick={() => bgImageInputRef.current?.click()}
          >
            {selectedFile ? (
              <>
                <img src={selectedFile} />
              </>
            ) : (
              <>
                <div className="upload-background-title">Upload</div>
                <div className="upload-background-subtitle">
                  Customize the background image
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              ref={bgImageInputRef}
              onChange={uploadThemeImage}
            />
          </div>
          {selectedFile ? (
            <>
              <div className="brightness-slider-text">Brightness</div>
              <Slider
                labelRenderer={false}
                min={-100}
                max={100}
                value={selectedBrightness}
                onChange={(number) => {
                  setSelectedBrightness(number);
                  setSelectedTheme({
                    ...selectedTheme,
                    backgroundImageBrightness: number,
                    status: "draft",
                  });
                }}
              />
            </>
          ) : null}
        </div>
        <div className="settings-section">
          <div className="settings-section-title">Font</div>
          <div className="settings-section-subtitle">
            Customize font for your survey
          </div>
          <FontPicker
            apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
            activeFontFamily={selectedTheme.systemFont}
            onChange={(nextFont) => {
              setSelectedTheme({
                ...selectedTheme,
                systemFont: nextFont.family,
              });
            }}
          />
          <Box className="header-color-picker-container">
            <div>Questions</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.questionColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      questionColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.questionColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Answers</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.answerColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      answerColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.answerColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Buttons</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.buttonColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      buttonColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.buttonColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Buttons text</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.buttonTextColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      buttonTextColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.buttonTextColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
          <Box className="header-color-picker-container">
            <div>Background Color</div>
            <Popover2
              position="bottom"
              className="color-picker-dropdown"
              content={
                <SketchPicker
                  color={selectedTheme?.backgroundColor}
                  onChangeComplete={(newColor) => {
                    const clonedTheme = {
                      ...selectedTheme,
                      backgroundColor: newColor.hex,
                      status: "draft",
                    };
                    //@ts-ignore
                    setSelectedTheme(clonedTheme);
                  }}
                />
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref}>
                  <div
                    className="color-picker-square"
                    style={{ backgroundColor: selectedTheme?.backgroundColor }}
                  />
                  <Icon icon="chevron-down" color="#C4C4C4" />
                </div>
              )}
            />
          </Box>
        </div>
        {selectedTheme.status === "draft" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "10px",
            }}
          >
            <Button
              minimal
              text="Revert"
              onClick={revertThemeSettings}
              className="revert-changes-button"
            />
            <Button
              minimal
              className="save-theme-changes-button"
              text={
                !selectedTheme.businessId ||
                  selectedTheme.businessId.length === 0
                  ? "Save as New Theme"
                  : "Save Changes"
              }
              onClick={
                !selectedTheme.businessId ||
                  selectedTheme.businessId.length === 0
                  ? saveNewTheme
                  : saveChangesOfTheme
              }
            />
          </div>
        ) : null}
      </>
    );
  };
  const renderMyThemes = () => {
    return (
      <>
        <div
          style={{
            marginTop: "20px",
            paddingLeft: "44px",
            paddingRight: "44px",
          }}
        >
          <Button
            onClick={addNewThemeData}
            icon="plus"
            text="Add new theme"
            className="add-new-theme"
          />
        </div>
        {themes.map((item, index) => {
          if (item.businessId === business.id) {
            return renderThemeRow(item, index);
          }
        })}
      </>
    );
  };
  const renderThemeRow = (item: ThemeProp, index: number) => {
    return (
      <div
        className={`gallery-row ${selectedTheme.id === item.id ? "selected" : ""
          }`}
        onClick={() => {
          // @ts-ignore
          setSelectedFile(item.backgroundImage ? item.backgroundImage : "");
          setSelectedTheme(item);
        }}
      >
        <div
          style={{ color: item.questionColor, fontFamily: "Lato-Extrabold" }}
        >
          Question
        </div>
        <div
          style={{
            color: item.answerColor,
            fontFamily: "Lato-Medium",
            marginBottom: "10px",
          }}
        >
          Answer
        </div>
        <div
          style={{
            height: "20px",
            width: "50px",
            backgroundColor: item.buttonColor,
          }}
        />
        <div className="theme-name">{item.themeName}</div>
        <Popover2
          usePortal={false}
          className="gallery-more-option-menu"
          content={
            <Menu style={{ minWidth: "auto" }}>
              <MenuItem
                text="Edit"
                onClick={() => handleEditThemeButton(item)}
              />
            </Menu>
          }
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <div {...targetProps} ref={ref}>
              <Icon icon="more" />
            </div>
          )}
        />
      </div>
    );
  };
  const renderThemeGallery = () => {
    return themesData.map((item, index) => {
      if (item.type === "system") {
        return renderThemeRow(item, index);
      }
    });
  };
  const renderQuestionTab = () => {
    if (selectedQuestion) {
      return (
        <>
          <div className="type-section">
            <div className="type-section-title">Type</div>
            {/* @ts-ignore */}
            <Select
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              matchTargetWidth
              className="dropdown-popover"
              activeItem={componentTypes.find(
                (item) => selectedQuestion.type === item.slug
              )}
              fill={true}
              items={componentTypes}
              filterable={false}
              itemRenderer={(item, { handleClick, modifiers }) => (
                <MenuItem
                  onClick={handleClick}
                  active={modifiers.active}
                  text={item.label}
                />
              )}
              onItemSelect={(item) => {
                updateComponentType(item);
              }}
            >
              <div className="type-dropdown-input">
                {/* ts-ignore */}
                {selectedQuestion ? getTypeLabelFromSlug : ""}
                <Icon icon="chevron-down" color="#C4C4C4" />
              </div>
            </Select>
          </div>
          <div className="settings-section">
            <div className="settings-section-title">Settings</div>
            <div className="settings-section-subtitle">
              Customize settings on this question
            </div>
            <div className="switch-group">
              <div className="switch-container">
                <div>Required</div>
                <Switch
                  checked={selectedQuestion.required}
                  large
                  onChange={(e) => updateQuestion("required", "")}
                />
              </div>
              {/*<div className="switch-container">*/}
              {/*  <div>Max Characters</div>*/}
              {/*  <Switch large />*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="settings-section">
            <div className="settings-section-title">Answer</div>
            <div className="switch-group">
              <div className="switch-container answer-switch">
                {selectedQuestion.type === ComponentType.shortText ||
                  selectedQuestion.type === ComponentType.longText ? (
                  <div>Correct Response (Scoring Required)</div>
                ) : (
                  <div>Correct Response</div>
                )}
                <Switch
                  checked={
                    selectedQuestion.hasAnswer
                      ? selectedQuestion.hasAnswer
                      : false
                  }
                  large
                  onChange={(e) => updateQuestion("hasAnswer", "")}
                />
              </div>
            </div>
            {selectedQuestion && selectedQuestion.hasAnswer ? (
              <div className="answer-section">
                {selectedQuestion ? getAnswerComponent() : null}
              </div>
            ) : null}
          </div>
          <div className={"settings-section"}>
            <button
              className={"delete-question-button"}
              onClick={() => deleteQuestion()}
            >
              Delete Question
            </button>
          </div>
        </>
      );
    }
  };
  const renderDesignTab = () => {
    return (
      <>
        {!showThemeSettings ? (
          <div className="design-tabs">
            <div
              onClick={() => setSelectedSegment("my-themes")}
              className={`${selectedSegment === "my-themes" ? "active" : ""}`}
            >
              My Themes
            </div>
            <div
              onClick={() => setSelectedSegment("gallery")}
              className={`${selectedSegment === "gallery" ? "active" : ""}`}
            >
              Gallery
            </div>
          </div>
        ) : null}
        <div className="tab-body">
          {selectedSegment === "gallery"
            ? !showThemeSettings
              ? renderThemeGallery()
              : null
            : !showThemeSettings
              ? renderMyThemes()
              : ""}
          {showThemeSettings ? renderTheme() : null}
        </div>
      </>
    );
  };
  const renderEndingSectionSettings = () => {
    return (
      <>
        <div className="settings-section ending-section-settings">
          <div className="settings-section-title">Settings</div>
          <div className="settings-section-subtitle">
            Customize settings on this question
          </div>
          <div className="switch-group">
            <div className="switch-container">
              <div>Button</div>
              <Switch
                checked={selectedEndingSection?.showButton}
                large
                onChange={(e) => updateEndingSectionStep("showButton", "")}
              />
            </div>
            {selectedEndingSection?.showButton ? (
              <input
                value={selectedEndingSection.buttonLabel}
                onChange={(e) =>
                  updateEndingSectionStep("buttonLabel", e.target.value)
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="switch-group">
            <div className="switch-container">
              <div>Button Link</div>
              <Switch
                checked={selectedEndingSection?.showButtonLink}
                large
                onChange={(e) => updateEndingSectionStep("showButtonLink", "")}
              />
            </div>
            {selectedEndingSection?.showButtonLink ? (
              <input
                value={selectedEndingSection.buttonLink}
                onChange={(e) =>
                  updateEndingSectionStep("buttonLink", e.target.value)
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="type-section ending-section-settings">
          <div
            className="upload-background"
            onClick={() => endingSectionBgImageRef.current?.click()}
          >
            {selectedEndingSection?.image ? (
              <>
                <img src={selectedEndingSection.image} />
              </>
            ) : (
              <>
                <div className="upload-background-title">Upload</div>
                <div className="upload-background-subtitle">
                  Customize the background image
                </div>
              </>
            )}
            <input
              type="file"
              hidden
              ref={endingSectionBgImageRef}
              onChange={uploadEndingSectionImage}
            />
          </div>
          {selectedEndingSection?.image &&
            selectedEndingSection.imageLayout === "background" ? (
            <>
              <div className="brightness-slider-text">Brightness</div>
              <Slider
                labelRenderer={false}
                min={-100}
                max={100}
                value={selectedBrightness}
                onChange={(number) => {
                  updateEndingSectionStep("imageBrightness", number);
                }}
              />
            </>
          ) : null}
        </div>
        {selectedEndingSection?.image ? (
          <div className="type-section ending-section-settings image-layout-option">
            <img
              src={LeftImage}
              onClick={() => updateEndingSectionStep("imageLayout", "left")}
              className={`${selectedEndingSection.imageLayout === "left" ? "active" : ""
                }`}
            />
            <img
              src={RightImage}
              onClick={() => updateEndingSectionStep("imageLayout", "right")}
              className={`${selectedEndingSection.imageLayout === "right" ? "active" : ""
                }`}
            />
            <img
              src={TopImage}
              onClick={() => updateEndingSectionStep("imageLayout", "top")}
              className={`${selectedEndingSection.imageLayout === "top" ? "active" : ""
                }`}
            />
            <img
              src={BottomImage}
              onClick={() =>
                updateEndingSectionStep("imageLayout", "background")
              }
              className={`${selectedEndingSection.imageLayout === "background"
                ? "active"
                : ""
                }`}
            />
          </div>
        ) : null}
        <div className={"settings-section"}>
          <button
            className={"delete-question-button"}
            onClick={() => deleteEndingSection()}
          >
            Delete
          </button>
        </div>
      </>
    );
  };
  const renderRightPart = () => {
    return (
      <div className="right-section">
        <div className="tabs">
          <div
            className={`tab-item ${selectedTab === "question" ? "active" : ""}`}
            onClick={() => setSelectedTab("question")}
          >
            Question
          </div>
          <div
            className={`tab-item ${selectedTab === "design" ? "active" : ""}`}
            onClick={() => setSelectedTab("design")}
          >
            Design
          </div>
          <div
            className={`tab-item ${selectedTab === "logic" ? "active" : ""}`}
            onClick={() => setShowLogicModal(true)}
          >
            Logic
          </div>
        </div>
        {showEndingSection
          ? renderEndingSectionSettings()
          : selectedTab === "question"
            ? renderQuestionTab()
            : renderDesignTab()}
        <div
          style={{
            paddingTop: "15px",
            paddingLeft: "44px",
            paddingRight: "44px",
          }}
        >
          {/*questions[]*/}
          {/*responses[]*/}
          {/*formDetails{}*/}
          {/*theme*/}
        </div>
      </div>
    );
  };

  const renderLeftPart = () => {
    return (
      <div className="left-section">
        <div className="left-section-header">
          <div className="left-section-title">Content</div>
          <div className="left-section-subtitle">Add parts to this survey</div>
          <Popover2
            captureDismiss={true}
            content={
              <div className="component-types-popover">
                <div className="popover-header">
                  <InputGroup
                    placeholder="Find a question type"
                    className="component-search-input"
                    leftIcon={
                      <Icon icon="search" color="#888888" iconSize={12} />
                    }
                  />
                </div>
                <div className="popover-body">
                  <div className="popover-title">All Question Types</div>
                  <Box display="flex" justifyContent="space-between">
                    <div className="component-column">
                      <div className="column-title">Form Structure</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "form-structure") {
                            return (
                              <div
                                className={`component-container ${Classes.POPOVER2_DISMISS}`}
                                onClick={() => addNewSection("welcomeScreen")}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="component-column">
                      <div className="column-title">Contact Info</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "contact-info") {
                            return (
                              <div
                                className={`component-container ${Classes.POPOVER2_DISMISS}`}
                                onClick={() => createNewQuestion(item.slug, endingSections)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="component-column">
                      <div className="column-title">Text</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "text") {
                            return (
                              <div
                                className={`component-container ${Classes.POPOVER2_DISMISS}`}
                                onClick={() => createNewQuestion(item.slug, endingSections)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className="component-column">
                      <div className="column-title">Choices</div>
                      <div>
                        {componentTypes.map((item, index) => {
                          if (item.type === "choices") {
                            return (
                              <div
                                className={`component-container ${Classes.POPOVER2_DISMISS}`}
                                onClick={() => createNewQuestion(item.slug, endingSections)}
                              >
                                <div className="component-icon">
                                  <img src={ChartIcon} />
                                </div>
                                <div className="component-name">
                                  {item.label}
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            }
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <div {...targetProps} ref={ref} className="add-part-button">
                <Icon icon="plus" color="#878B91" />
              </div>
            )}
          />
        </div>
        <div className="steps">
          {welcomeScreen ? (
            <div
              className={`single-step ${showEndingSection &&
                selectedEndingSection?.id === welcomeScreen.id
                ? "active"
                : ""
                }`}
              onClick={() => {
                setSelectedEndingSection(welcomeScreen);
                setShowEndingSection(true);
              }}
            >
              <div className="step-indicator">
                {getIcon(ComponentType.endingSection)}
              </div>
              <div className="step-question">Welcome Screen</div>
            </div>
          ) : null}

          <DragDropContext
            onDragEnd={(result) => {
              if (!result.destination) {
                return;
              }
              const items = reorder(
                questions,
                result.source.index,
                result.destination.index
              );
              setQuestions(items);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions.length > 0 &&
                    questions.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`single-step ${!showEndingSection &&
                              selectedQuestion?.id === item.id
                              ? "active"
                              : ""
                              }`}
                            onClick={() => {
                              setSelectedQuestion(item);
                              setShowEndingSection(false);
                            }}
                            key={item.id}
                          >
                            <div className="step-indicator">
                              {getIcon(item.type)}
                              <div>{index + 1}</div>
                            </div>
                            <div className="step-question">
                              {item.title.length > 100
                                ? item.title.substring(0, 100) + "..."
                                : item.title}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  };
  const renderEndingSection = () => (
    <div className="ending-section">
      <div className="left-section-header">
        <div className="left-section-title">Ending</div>
        <div className="left-section-subtitle">Add parts to this survey</div>
        <div
          className="add-part-button"
          onClick={() => addNewSection("endingSection")}
        >
          <Icon icon="plus" color="#878B91" />
        </div>
      </div>
      <div>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }
            const items = reorder(
              endingSections,
              result.source.index,
              result.destination.index
            );
            setEndingSections(items);
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {endingSections.length > 0 &&
                  endingSections.map((item, index) => {
                    if (item.type === "endingSection") {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`end-step ${showEndingSection &&
                                selectedEndingSection?.id === item.id
                                ? "active"
                                : ""
                                }`}
                              onClick={() => {
                                setSelectedEndingSection(item);
                                setShowEndingSection(true);
                              }}
                              key={item.id}
                            >
                              <div className="end-step-indicator">
                                <img src={EndStepIcon} />
                                <div>Aa</div>
                              </div>
                              <div className="step-question">{item.title}</div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
  const renderCenterSection = () => {
    return (
      <div className="center-section">
        <div
          className="center-form"
          style={{ backgroundColor: selectedTheme.backgroundColor }}
        >
          {selectedTheme.backgroundImage ? (
            <>
              <div
                className="form-bg-image"
                style={{
                  backgroundImage: `url(${selectedTheme.backgroundImage})`,
                }}
              />
              <div
                className="form-bg-image bg-color"
                style={{
                  backgroundColor:
                    selectedTheme.backgroundImageBrightness > 0
                      ? "#fff"
                      : "#000",
                  opacity:
                    selectedTheme.backgroundImageBrightness > 0
                      ? selectedTheme.backgroundImageBrightness / 100
                      : -selectedTheme.backgroundImageBrightness / 100,
                }}
              />
            </>
          ) : null}
          {!showEndingSection && selectedQuestion ? (
            <>
              <div className="center-component">
                <div className="question-container">
                  <div
                    className="question-number apply-font"
                    style={{ color: selectedTheme?.questionColor }}
                  >
                    {questions.findIndex(
                      (item) => item.id === selectedQuestion.id
                    ) + 1}
                  </div>
                  <Box display="flex" flexDirection="column" flex={1}>
                    <textarea
                      // oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                      onKeyDown={(e) => {
                        //@ts-ignore
                        e.target.style.height = "inherit";
                        //@ts-ignore
                        e.target.style.height = `${e.target.scrollHeight}px`;
                      }}
                      className="question-title apply-font"
                      value={selectedQuestion.title}
                      placeholder="Your text can be added here"
                      style={{
                        color: selectedTheme?.questionColor,
                        overflow: "auto",
                      }}
                      onChange={(e) => updateQuestion("title", e.target.value)}
                    />
                    <input
                      style={{ color: selectedTheme?.questionColor }}
                      placeholder="Description on this is optional"
                      className="question-subtitle apply-font"
                      value={selectedQuestion.subtitle}
                      onChange={(e) =>
                        updateQuestion("subtitle", e.target.value)
                      }
                    />
                  </Box>
                </div>
                {getComponent(selectedQuestion.type)}
                <Button
                  text="ok"
                  className="okay-button apply-font"
                  style={{
                    backgroundColor: selectedTheme?.buttonColor,
                    color: selectedTheme?.buttonTextColor,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {selectedEndingSection &&
                selectedEndingSection.image &&
                selectedEndingSection.imageLayout === "background" ? (
                <>
                  <div
                    className="form-bg-image"
                    style={{
                      backgroundImage: `url(${selectedEndingSection.image})`,
                    }}
                  />
                  <div
                    className="form-bg-image bg-color"
                    style={{
                      backgroundColor:
                        selectedEndingSection.imageBrightness > 0
                          ? "#fff"
                          : "#000",
                      opacity:
                        selectedEndingSection.imageBrightness > 0
                          ? selectedEndingSection.imageBrightness / 100
                          : -selectedEndingSection.imageBrightness / 100,
                    }}
                  />
                </>
              ) : null}
              <div className="center-component ending-section-component">
                <div
                  className={`question-container ${selectedEndingSection?.imageLayout}`}
                >
                  {selectedEndingSection &&
                    selectedEndingSection.image &&
                    selectedEndingSection.imageLayout !== "background" ? (
                    <div className="thank-you-image">
                      <img src={selectedEndingSection.image} />
                    </div>
                  ) : null}
                  <Box display="flex" flexDirection="column" flex={1}>
                    <input
                      multiple
                      className="question-title apply-font"
                      value={selectedEndingSection?.title}
                      placeholder="Your text can be edited here"
                      style={{ color: selectedTheme?.questionColor }}
                      onChange={(e) =>
                        updateEndingSectionStep("title", e.target.value)
                      }
                    />
                    <input
                      style={{ color: selectedTheme?.questionColor }}
                      placeholder="Description on this is optional"
                      className="question-subtitle apply-font"
                      value={selectedEndingSection?.subtitle}
                      onChange={(e) =>
                        updateEndingSectionStep("subtitle", e.target.value)
                      }
                    />
                  </Box>
                </div>
                {selectedEndingSection && selectedEndingSection.showButton ? (
                  <Button
                    onClick={() => {
                      if (selectedEndingSection?.buttonLink.length > 0) {
                        window.location.href =
                          selectedEndingSection?.buttonLink;
                      }
                    }}
                    text={selectedEndingSection.buttonLabel}
                    className="okay-button apply-font"
                    style={{
                      backgroundColor: selectedTheme?.buttonColor,
                      color: selectedTheme?.buttonTextColor,
                    }}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
        <div className="survey-info">
          {/*<input*/}
          {/*  className="question-title survey-name"*/}
          {/*  onChange={(e) =>*/}
          {/*    updateSurveyDetail("typeformDisplayName", e.target.value)*/}
          {/*  }*/}
          {/*  value={surveyInfo.typeformDisplayName}*/}
          {/*/>*/}
          {/*<input*/}
          {/*  className="question-subtitle survey-description"*/}
          {/*  onChange={(e) =>*/}
          {/*    updateSurveyDetail("typeformDescription", e.target.value)*/}
          {/*  }*/}
          {/*  value={surveyInfo.typeformDescription}*/}
          {/*/>*/}
        </div>
      </div>
    );
  };
  const renderPublishDate = useMemo(() => {
    if (!existingFormData) {
      return null;
    }
    if (!existingFormData.enabled) {
      return `Inactive`;
    }
    return `Form published on ${existingFormData.createdAt
      .toDate()
      .toLocaleString()}`;
  }, [existingFormData]);

  const saveFormAsDraft = useCallback(async () => {
    const status = "draft";
    const enabled = false;
    const response = await createForm(
      questions,
      selectedTheme,
      surveyInfo,
      formData,
      business.id,
      business.accountId,
      endingSections,
      welcomeScreen,
      enabled,
      status
    );
    if (response.data) {
      navigate("/manager/forms");
    } else {
      console.log(response);
    }
  }, [
    questions,
    selectedTheme,
    surveyInfo,
    formData,
    business,
    endingSections,
    welcomeScreen,
  ]);

  const onBackButtonPress = useCallback(() => {
    if (!id) {
      setShowSaveAsDraftModal(true);
    } else {
      navigate("/forms");
    }
  }, []);
  return (
    <div
      id={`survey-builder-page`}
      className={`${emulatorMode ? "emulatorMode" : ""}`}
    >
      <div className="page-subheader second-header">
        <div className="button-group">
          <Icon
            icon="chevron-left"
            iconSize={20}
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={onBackButtonPress}
          />
          {id ? <div className="form-name">{formData.name}</div> : null}
          {id ? (
            <Select
              className="navigation-dropdown"
              filterable={false}
              matchTargetWidth
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              items={["Form Preview", "Responses", id ? "Edit Form" : ""]}
              itemRenderer={(item, { handleClick }) => (
                <MenuItem text={item} onClick={handleClick} />
              )}
              onItemSelect={(item) => {
                if (item === "Form Preview") {
                  navigate(`/form/${id}/preview`);
                } else if (item === "Responses") {
                  navigate(`/form/${id}/responses`);
                } else {
                  navigate(`/form/${id}/edit`);
                }
              }}
            >
              <Button rightIcon="chevron-down">Edit Form</Button>
            </Select>
          ) : null}
          <div className="publish-button-container">
            <Button
              minimal
              onClick={async () => {
                if (id) {
                  const response = await updateForm(
                    questions,
                    selectedTheme,
                    surveyInfo,
                    business.id,
                    endingSections,
                    id,
                    welcomeScreen
                  );
                  if (response.data) {
                    setShowPublishedText(true);
                    setShowSharableLinkModal(true);
                    // @ts-ignore
                    // navigate(-1);
                  } else {
                    console.log(response);
                  }
                } else {
                  const enabled = true;
                  const status = "published";
                  const response = await createForm(
                    questions,
                    selectedTheme,
                    surveyInfo,
                    formData,
                    business.id,
                    business.accountId,
                    endingSections,
                    welcomeScreen,
                    enabled,
                    status
                  );
                  if (response.data) {
                    setShowPublishedText(true);
                    setFormId(response.data.id);
                    setShowSharableLinkModal(true);
                    // @ts-ignore
                    // navigate(`/form/${response.data.id}/${business.id}`, {
                    //   state: { questions, theme: selectedTheme, surveyInfo },
                    // });
                  } else {
                    console.log(response);
                  }
                }
              }}
              text="Publish"
              className="publish-button"
            />
            <Popover2
              placement="bottom"
              modifiers={{
                arrow: {
                  enabled: false,
                },
              }}
              disabled={formId.length === 0}
              content={
                <div
                  style={{
                    padding: "16px",
                    width: "100px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowSharableLinkModal(true)}
                >
                  Get Link
                </div>
              }
            >
              <Icon icon="chevron-down" />
            </Popover2>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="publish-date">
            {/* @ts-ignore */}
            {renderPublishDate}
          </div>
          {id && existingFormData ? (
            <Select
              className="settings-dropdown"
              filterable={false}
              matchTargetWidth
              popoverProps={{
                modifiers: {
                  arrow: {
                    enabled: false,
                  },
                },
              }}
              items={[
                /* @ts-ignore */
                existingFormData?.enabled ? "Set as inactive" : "Set as active",
                "Delete form",
              ]}
              itemRenderer={(item, { handleClick }) => (
                <MenuItem
                  onClick={handleClick}
                  text={
                    <div>
                      <div className="menu-item-title">{item}</div>
                      <div className="menu-item-subtitle">
                        {item === "Delete form"
                          ? "This cannnot be undone"
                          : /* @ts-ignore */
                          `You will ${existingFormData.enabled
                            ? "no longer"
                            : "be able to"
                          } receive responses`}
                      </div>
                    </div>
                  }
                />
              )}
              onItemSelect={async (item) => {
                if (item === "Set as inactive") {
                  const response = await updateFormField(id, "enabled", false);
                  setExistingFormData({ ...existingFormData, enabled: false });
                } else if (item === "Set as active") {
                  const response = await updateFormField(id, "enabled", true);
                  setExistingFormData({ ...existingFormData, enabled: true });
                } else {
                  setShowDeleteFormConfirmationModal(true);
                }
              }}
            >
              <Button rightIcon="chevron-down">Settings</Button>
            </Select>
          ) : null}
        </div>
      </div>
      {selectedTab === "logic" ? (
        <ReactFlowProvider>
          <Logic />
        </ReactFlowProvider>
      ) : (
        <>
          {/* left section */}
          {renderLeftPart()}
          {/* left section */}

          {/* ending section */}
          {renderEndingSection()}
          {/* ending section */}

          {selectedQuestion || selectedEndingSection
            ? renderCenterSection()
            : null}

          {/* right section */}
          {selectedQuestion || selectedEndingSection ? renderRightPart() : null}
          {/* right section */}
        </>
      )}

      <Dialog isOpen={showCreateFormModal} className="add-option-dropdown">
        <div className="modal-header">
          <div className="modal-header-title">Create Form</div>
          <div className="modal-header-subtitle">
            Bring your new form to life with a name
          </div>
          <Icon
            icon="cross"
            className="close-button"
            color="#231F20"
            iconSize={20}
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="modal-body">
          <input
            type="text"
            placeholder="Give your form a name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <Select
            popoverProps={{
              className: "form-type-dropdown",
            }}
            fill
            matchTargetWidth
            filterable={false}
            items={formTypes}
            itemRenderer={(item, { handleClick }) => (
              <MenuItem text={item} onClick={handleClick} />
            )}
            onItemSelect={(item) => setFormData({ ...formData, type: item })}
          >
            <div className="form-type-placeholder">
              {formData.type.length > 0
                ? formData.type
                : "What type of form is this"}
            </div>
          </Select>
        </div>
        <Button
          text="Continue"
          className="save-button"
          disabled={formData.name.length === 0 || formData.type.length === 0}
          onClick={() => {
            setShowCreateFormModa(false);
          }}
        />
      </Dialog>
      <div style={{ position: "absolute", opacity: 0 }}>
        <FontPicker
          apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
          activeFontFamily={selectedTheme.systemFont}
        />
      </div>
      <LogicModal
        isOpen={showLogicModal}
        onClose={() => setShowLogicModal(false)}
        questions={questions}
        endingSections={endingSections}
        getIcon={getIcon}
        setQuestions={setQuestions}
      />
      <SharableLinkModal
        formId={formId}
        showPublishedText={showPublishedText}
        businessId={business?.id || ""}
        showModal={showSharableLinkModal}
        onClose={() => {
          if (showPublishedText) {
            setShowPublishedText(false);
          }
          setShowSharableLinkModal(false);
          if (!id) {
            navigate(-1);
          }
        }}
      />
      {id && existingFormData ? (
        <DeleteFormConfirmationModal
          showModal={showDeleteFormConfirmationModal}
          onClose={() => setShowDeleteFormConfirmationModal(false)}
          onDelete={async () => {
            const response = await updateFormField(id, "deleted", true);
            if (response.data) {
              const formDeleteResponse = await deleteFormResponses(id);
              if (formDeleteResponse.data) {
                navigate("/manager/forms", { replace: true });
              }
            }
          }}
        />
      ) : null}
      <SaveDraftConfirmationModal
        showModal={showSaveAsDraftModal}
        onClose={closeSaveDraftConfirmationModal}
        onSaveDraft={saveFormAsDraft}
      />
    </div>
  );
};

export default SurveyBuilder;
