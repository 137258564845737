import { Icon } from "@blueprintjs/core";
import { ButtonBase, Popover as MUIPopover, MenuItem } from "@mui/material";
import _ from "lodash";
import React from "react";

import Box from "components/Box";

import { getPositionNameById } from "model/selectors/businessSettings";

interface StaffMemberOrientedViewControlsProps {
  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  setRoleFilterId: (v: any) => void;
}

export const StaffMemberOrientedViewControls = ({
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  businessSettings,
  jobFunctions,
  setRoleFilterId,
}: StaffMemberOrientedViewControlsProps) => {
  const handleClick = (event: any) => {
    setWeeklyStaffMemberViewFilterAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setWeeklyStaffMemberViewFilterAnchorEl(null);
  };

  const open = Boolean(weeklyStaffMemberViewFilterAnchorEl);

  return (
    <Box className="side-nav-item" display="flex" flexDirection="column">
      <div className="weekly-view-select-title">Actions</div>
      <Box
        className="weeekly-view-select-search"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <div style={{ flex: 4 }}>
          <div className="weeekly-view-select-search-title">
            Search by staff member
          </div>
          <input
            style={{ fontSize: 10 }}
            placeholder="Start typing an employee’s name"
            onChange={onFullNameInputChangeDebounced}
          />
        </div>
        <Box
          flex={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Icon color="#888888" icon="search" iconSize={14} />
        </Box>
      </Box>
      <ButtonBase
        onClick={handleClick}
        TouchRippleProps={{ style: { opacity: 0.5 } }}
        style={{
          backgroundColor: "rgba(249, 249, 249, 1)",
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        <Box
          className="weeekly-view-select-filter"
          onClick={() => null}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="weeekly-view-select-search-title">Filter by role</div>
          <div className="weeekly-view-select-filter-value">
            {roleFilterId
              ? getPositionNameById(businessSettings, roleFilterId)
              : "Any"}
          </div>
        </Box>
      </ButtonBase>
      <MUIPopover
        open={open}
        anchorEl={weeklyStaffMemberViewFilterAnchorEl}
        onClose={handleClose}
        style={{ margin: 5 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            setRoleFilterId(null);
            handleClose();
          }}
        >
          Any
        </MenuItem>
        {_.keys(jobFunctions).map((jobFunctionKey) => (
          <MenuItem
            key={jobFunctionKey}
            onClick={() => {
              setRoleFilterId(jobFunctionKey);
              handleClose();
            }}
          >
            {jobFunctions[jobFunctionKey].title}
          </MenuItem>
        ))}
      </MUIPopover>
    </Box>
  );
};
