import UIComponents from "@tangopay/tango-ui-library";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";

import MoveIconImage from "../../../assets/calendar/move-icon.svg";
import AddAccountModal from "./AddAccountModal";
import DeleteCalendarModal from "./DeleteCalendarModal";
import InvitePeopleModal from "./InvitePeopleModal";
import MergeCalendarModal from "./MergeCalendarModal";
import MergeCalendarPopover from "./MergeCalendarPopover";
import SuccessModal from "./SuccessModal";
import ViewMembersPopover from "./ViewMembersPopover";
import useAccounts, { CalendarAccount } from "./useAccounts";

const Accounts = () => {
  const {
    showPopover,
    setShowPopover,
    showViewMembers,
    setShowViewMembers,
    setShowInvitePeopleModal,
    setShowDeleteCalendarModal,
    setShowMergeCalendarModal,
    showMergeCalendarPopover,
    setShowMergeCalendarPopover,
    showInvitePeopleModal,
    showDeleteCalendarModal,
    showAddAccountModal,
    showSuccessModal,
    setShowSuccessModal,
    setShowAddAccountModal,
    showMergeCalendarModal,
    onDragEnd,
    addWorkSpace,
    selectedCalendarId,
    setSelectedCalendarId,
    onCalendarNameChange,
    onSaveCalendar,
    groupedCalendars,
  } = useAccounts();

  const renderCalendar = (
    calendarItem: CalendarAccount,
    provided: DraggableProvided
  ) => {
    return (
      <div
        className={
          "flex mb-5 cursor-pointer items-center justify-between group"
        }
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className={"flex items-center relative"}>
          <img
            src={MoveIconImage}
            className={"hidden group-hover:block absolute left-[-16px]"}
            style={{ height: "6px", width: "6px" }}
          />
          <UIComponents.Checkbox
            type={"small"}
            //@ts-ignore
            colorType={calendarItem.color}
            checked
            className={"mr-3"}
          />
          {selectedCalendarId === calendarItem.id ? (
            <input
              value={calendarItem.calendarName}
              className={"text-grey-3 font-lato-bold text-sm"}
              autoFocus
              onKeyDown={onSaveCalendar}
              onChange={onCalendarNameChange}
            />
          ) : (
            <div className={"font-lato-bold text-black text-sm"}>
              {calendarItem.calendarName}
            </div>
          )}
        </div>
        <UIComponents.Popover
          onClickOutside={(e) => {
            if (showViewMembers || showMergeCalendarPopover) {
              return false;
            }
            setSelectedCalendarId(null);
            setShowPopover(false);
          }}
          isOpen={showPopover && selectedCalendarId === calendarItem.id}
          content={
            <div className={"z-10"}>
              <div className={"shadow-card rounded-2xl p-4 py-3.5 bg-white "}>
                <div>
                  {["pink", "orange", "yellow", "green", "blue", "purple"].map(
                    (checkBoxColor, colorIndex) => (
                      <UIComponents.Checkbox
                        key={colorIndex}
                        type={"small"}
                        //@ts-ignore
                        colorType={checkBoxColor}
                        className={"bg-pink-2 mr-2"}
                      />
                    )
                  )}
                </div>
                <div
                  className={
                    "font-lato-bold text-tiny text-grey-3 mt-4 cursor-pointer"
                  }
                  onClick={() => {
                    setShowPopover(false);
                    setShowInvitePeopleModal(true);
                  }}
                >
                  Invite People...
                </div>
                <ViewMembersPopover
                  isOpen={showViewMembers}
                  onClose={() => setShowViewMembers(false)}
                  showPopover={() => setShowViewMembers(true)}
                />
                <div className={"h-px bg-grey-1 mt-4"} />
                <div
                  className={
                    "font-lato-bold text-tiny text-grey-3 mt-4 cursor-pointer"
                  }
                  onClick={() => {
                    setSelectedCalendarId(calendarItem.id);
                    setShowPopover(false);
                  }}
                >
                  Rename
                </div>
                <MergeCalendarPopover
                  isOpen={showMergeCalendarPopover}
                  onClose={() => setShowMergeCalendarPopover(false)}
                  workspaces={["OPs Team", "Company Events"]}
                  email={"Kishanj918@gmail.com"}
                  showMergeCalendarModal={() => {
                    setShowMergeCalendarPopover(false);
                    setShowPopover(false);
                    setShowMergeCalendarModal(true);
                  }}
                >
                  <div
                    onClick={() => setShowMergeCalendarPopover(true)}
                    className={
                      "font-lato-bold text-tiny text-grey-3 mt-4 cursor-pointer"
                    }
                  >
                    Merge
                  </div>
                </MergeCalendarPopover>
                <div
                  className={
                    "font-lato-bold text-tiny text-error-red mt-4 cursor-pointer"
                  }
                  onClick={() => {
                    setShowPopover(false);
                    setShowDeleteCalendarModal(true);
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          }
        >
          <div>
            <UIComponents.Icon
              name={"more"}
              className={`${
                showPopover && selectedCalendarId === calendarItem.id
                  ? "block"
                  : "hidden"
              } group-hover:block`}
              size={"12"}
              color={"#AAADB6"}
              onClick={() => {
                setSelectedCalendarId(calendarItem.id);
                setShowPopover(true);
              }}
            />
          </div>
        </UIComponents.Popover>
      </div>
    );
  };

  return (
    <div>
      <div className={"p-6"}>
        <UIComponents.Button
          leftIcon={"plus"}
          label={"Add Account"}
          type={"btn-style-1"}
          size={"btn-small"}
          className={"w-full justify-center"}
          onClick={() => setShowAddAccountModal(true)}
        />
      </div>

      <div
        className={"px-6 overflow-y-auto pb-10"}
        style={{ height: "calc(100vh - 451px)" }}
      >
        {Object.keys(groupedCalendars).map((email: string, accountIndex) => (
          <div key={email} className={"mt-6"}>
            <div className={"flex justify-between items-center mb-3.5"}>
              <div className={"font-lato-black text-grey-2 text-xs"}>
                {email}
              </div>
              <div className={"flex items-center"}>
                <UIComponents.Icon
                  name={"plus"}
                  color={"#AAADB6"}
                  size={"16"}
                  className={"mr-2"}
                  onClick={() => addWorkSpace(email)}
                />
                <UIComponents.Icon
                  name={"chevron-right"}
                  color={"#AAADB6"}
                  size={"16"}
                />
              </div>
            </div>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, accountIndex)}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {groupedCalendars[email].map(
                      (calendarItem, calendarIndex: number) => (
                        <Draggable
                          key={calendarItem.id}
                          draggableId={calendarItem.id}
                          index={calendarIndex}
                        >
                          {(provided, snapshot) => {
                            return renderCalendar(calendarItem, provided);
                          }}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ))}

        <div>
          <div className={"font-lato-black text-grey-2 text-xs mb-3.5"}>
            Business Locations
          </div>
          <UIComponents.Dropdown
            placeholder={"Search"}
            size={"medium"}
            enableCheckbox
            options={[
              {
                label: "One",
                value: "one",
              },
              {
                label: "two",
                value: "two",
              },
            ]}
          />
        </div>
      </div>
      <InvitePeopleModal
        isOpen={showInvitePeopleModal}
        closeModal={() => setShowInvitePeopleModal(false)}
      />
      <DeleteCalendarModal
        isOpen={showDeleteCalendarModal}
        onClose={() => {
          setShowMergeCalendarPopover(false);
          setShowDeleteCalendarModal(false);
        }}
        setShowMergePopOver={() => setShowMergeCalendarPopover(true)}
        isMergePopoverOpen={showMergeCalendarPopover}
        hideMergePopover={() => setShowMergeCalendarPopover(false)}
        showMergeCalendar={() => {
          setShowDeleteCalendarModal(false);
          setShowMergeCalendarPopover(false);
          setShowMergeCalendarModal(true);
        }}
      />
      <AddAccountModal
        isOpen={showAddAccountModal}
        closeModal={() => setShowAddAccountModal(false)}
        showSuccessModal={() => {
          setShowAddAccountModal(false);
          setShowSuccessModal(true);
        }}
      />
      <SuccessModal
        isOpen={showSuccessModal}
        closeModal={() => setShowSuccessModal(false)}
      />
      <MergeCalendarModal
        isOpen={showMergeCalendarModal}
        onClose={() => setShowMergeCalendarModal(false)}
      />
    </div>
  );
};
export default Accounts;
