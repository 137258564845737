import { FilesAndFoldersTypesAction } from "model/types";

import ActionType from "./actionTypes";

export function receiveDocs(
  docs: FilesAndFolders[]
): FilesAndFoldersTypesAction {
  return {
    type: ActionType.RECEIVE_DOCS,
    docs,
  };
}
