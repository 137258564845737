import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const testURl = 'http://localhost:9001/tango-2/us-central1';
const discountedPaymentsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/discounted-payments`,
});

discountedPaymentsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const fetchDiscountedPayments = async (businessId: string, lastOrderId: string) => {
  if (!businessId) return null;
  const res = await discountedPaymentsApiClient.get<any[]>(`${businessId}/${lastOrderId}`);
  return res.data ? res.data : [];
};
export const fetchDiscountedPaymentsBetweenDates = async (businessId: string, start: Date, end: Date) => {
  if (!businessId) return null;
  const res = await discountedPaymentsApiClient.get<any[]>(`${businessId}/${start}/${end}`);
  return res.data ? res.data : [];
};

export const useDiscountedPayments = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['discountedPaymentsInfo', businessId, lastCompletedTabId],
    queryFn: () => fetchDiscountedPayments(businessId, lastCompletedTabId),
    keepPreviousData: true, //this keeps old fetchDiscountedPayments in table until new data is returned
    retry: 2,
    // retryDelay: 10000,
  });
};

export const useDiscountedPaymentsBetweenDates = (businessId: string, start: Date, end: Date) => {
  return useQuery(['discountedPaymentsInfoBetweenDates', businessId, start, end], () => fetchDiscountedPaymentsBetweenDates(businessId, start, end), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 2,
    keepPreviousData: true,
    // retryDelay: 10000,
  });
};
