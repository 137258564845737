import moment from "moment-timezone";
import React from "react";
import { useSelector } from "react-redux";

import { ScheduleEventType } from "controllers/schedule";

import Box from "components/Box";

import { getStaffMemberFullName } from "utils/manager";

import { getPositionNameById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

import CoverArrowImage from "../../../../assets/arrow-down.svg";
import TradeArrowsImage from "../../../../assets/trade-arrows.svg";

interface Props {
  type: ScheduleEventType;
  onClick: () => void;
  title: string;
  subtitle: string;
  request: ScheduleEvent;
}
const CheckMark = () => (
  <svg
    width="9"
    height="7"
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.07394 6.54851C3.66589 6.95657 3.00381 6.95657 2.59596 6.54851L0.306033 4.25853C-0.102011 3.85068 -0.102011 3.18858 0.306033 2.78072C0.713879 2.37267 1.37597 2.37267 1.78401 2.78072L3.14836 4.1449C3.25135 4.2477 3.41855 4.2477 3.52174 4.1449L7.21599 0.450571C7.62384 0.0425181 8.28592 0.0425181 8.69397 0.450571C8.88991 0.646522 9 0.912387 9 1.18948C9 1.46657 8.88991 1.73243 8.69397 1.92838L4.07394 6.54851Z" />
  </svg>
);
const getType = (type: ScheduleEventType, status: ScheduleEventStatus) => {
  if (status === "approved") return "approved";
  if (status === "denied") return "denied";

  switch (type) {
    case ScheduleEventType.drop:
      return "trade-request";
    case ScheduleEventType.time_off:
      return "time-off";
    case ScheduleEventType.trade:
      return "trade-request";
    case ScheduleEventType.cover:
      return "cover";
    default:
      return "";
  }
};

const getTitleByScheduleEventType = (type: ScheduleEventType) => {
  switch (type) {
    case ScheduleEventType.drop:
      return "Drop Request";
    case ScheduleEventType.time_off:
      return "Time Off Request";
    case ScheduleEventType.trade:
      return "Trade Request";
    case ScheduleEventType.cover:
      return "Cover Request";
    default:
      return "";
  }
};

const getTitleByScheduleEvent = (scheduleEvent: ScheduleEvent) => {
  if (scheduleEvent?.eventType === ScheduleEventType.drop) {
    return "Drop Request";
  }
  if (scheduleEvent?.eventType === ScheduleEventType.time_off) {
    return "Time Off Request";
  }
  if (scheduleEvent?.eventType === ScheduleEventType.trade) {
    if (scheduleEvent?.openTradeConstraints) {
      return "Open Trade Request";
    }
    return "Trade Request";
  }
  if (scheduleEvent?.eventType === ScheduleEventType.cover) {
    if (scheduleEvent?.openCoverConstraints) {
      return "Open Cover Request";
    }
    return "Cover Request";
  }
  return "";
};

const RequestCard = ({ onClick, title, subtitle, request }: Props) => {
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const schedules: TangoSchedule[] = useSelector(
    (state: RootState) => state.schedules
  );
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const senderStaffMember = fellowStaffMembers.find(
    (sm) => sm.id === request?.senderStaff?.id
  );
  const receiverStaffMember = fellowStaffMembers.find(
    (sm) => sm.id === request?.receiverStaff?.id
  );

  const sendingSchedule = schedules.find(
    (s) => s.id === request.sendingScheduleId
  );
  const receivingSchedule = schedules.find(
    (s) => s.id === request.receivingScheduleId
  );

  const singleSchedule = schedules.find((s) => s.id === request.scheduleId);
  const singleShift = singleSchedule?.shifts.find(
    (sh) => sh.id === request.shiftId
  );

  const sendingShift = sendingSchedule?.shifts.find(
    (sh) => sh.id === request.sendingShiftId
  );

  const receivingShift = receivingSchedule?.shifts.find(
    (sh) => sh.id === request.receivingShiftId
  );
  const type = request?.eventType;

  const renderActionBasedOnStatus = (status: ScheduleEventStatus) => {
    if (status === "approved") {
      return <Box className="shift-card_footer">Approved</Box>;
    }

    if (status === "denied") {
      return <Box className="shift-card_footer">denied</Box>;
    }

    return <Box className="shift-card_footer">Approve or Deny</Box>;
  };

  const onCardPress = () => {
    if (request.status === "pending" || request.status === "approved") {
      return onClick();
    }
    return null;
  };

  if (type === ScheduleEventType.time_off) {

    const sortedTimeOffDates = request?.timeOffRequestDates?.sort(
      (a, b) => a?.toMillis() - b?.toMillis()
    );
    if (!sortedTimeOffDates && !request?.timeOffRequestStartDate && !request?.timeOffRequestEndDate) return null;
    return (
      <Box
        onClick={onCardPress}
        className={`shift-card-${getType(
          type,
          request.status
        )} shift-card request-card-${getType(
          type,
          request.status
        )} request-card`}
        style={{ overflow: "hidden" }}
      >
        <Box className="shift-card_header">
          <div className="shift-type">{getTitleByScheduleEventType(type)}</div>
          <div className="dot" />
        </Box>
        <Box className="shift-card_body">
          <div className="staff-name">
            Submitted:{" "}
            {moment(request?.createdAt?.toDate()).format("YYYY MMMM D hh:mm a")}
          </div>
          <div className="staff-name">
            Name: {senderStaffMember?.contact?.firstName}{" "}
            {senderStaffMember?.contact?.lastName}
          </div>

          <div className="staff-name">
            Reason: {request?.description || "Empty"}
          </div>
          {request?.timeOffRequestStartDate && request?.timeOffRequestEndDate ? (
            <div>
              <div className="staff-name">
                Start Date:{" "}
                {moment(request?.timeOffRequestStartDate?.toDate()).format(
                  "ddd MMM DD, YYYY hh:mm a"
                )}
              </div>
              <div className="staff-name">
                End Date:{" "}
                {moment(request?.timeOffRequestEndDate?.toDate()).format(
                  "ddd MMM DD, YYYY hh:mm a"
                )}
              </div>
            </div>
          ) : null}
          {sortedTimeOffDates && sortedTimeOffDates.length > 1 && !(request?.timeOffRequestStartDate && request?.timeOffRequestEndDate) ? (
            <div className="staff-name">
              Dates:{" "}
              {moment(sortedTimeOffDates[0]?.toDate()).format(
                "ddd MMM DD, YYYY"
              )}{" "}
              -{" "}
              {moment(
                sortedTimeOffDates[sortedTimeOffDates.length - 1]?.toDate()
              ).format("ddd MMM DD, YYYY")}
            </div>
          ) : sortedTimeOffDates?.length ? (
            <div className="staff-name">
              Date:{" "}
              {sortedTimeOffDates?.length ? moment(sortedTimeOffDates[0]?.toDate()).format(
                "ddd MMM DD, YYYY"
              ) : ""}
            </div>
          ) : null}
        </Box>
        {renderActionBasedOnStatus(request.status)}
      </Box>
    );
  }

  if (type === ScheduleEventType.trade) {
    if (!senderStaffMember || !receiverStaffMember) return null;

    return (
      <Box
        onClick={onCardPress}
        className={`shift-card-${getType(
          type,
          request.status
        )} shift-card request-card-${getType(
          type,
          request.status
        )} request-card`}
        style={{ overflow: "hidden" }}
      >
        <Box className="shift-card_header">
          <div className="shift-type">{getTitleByScheduleEventType(type)}</div>
          <div className="dot" />
        </Box>
        <Box className="shift-card_body">
          <div className="staff-name">
            {getStaffMemberFullName(senderStaffMember)}
          </div>
          <div className="staff-name">
            {getPositionNameById(
              businessSettings,
              sendingShift?.position || ""
            )}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startTime, "HH:mm").format("HH:mm a")}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startDate.toDate()).format("MMM DD, YYYY")}
          </div>
        </Box>
        <Box className="shift-card_body">
          <img src={TradeArrowsImage} />
        </Box>

        <Box className="shift-card_body">
          <div className="staff-name">
            {getStaffMemberFullName(receiverStaffMember)}
          </div>
          <div className="staff-name">
            {getPositionNameById(
              businessSettings,
              receivingShift?.position || ""
            )}
          </div>
          <div className="staff-name">
            {moment(receivingShift?.startTime, "HH:mm").format("HH:mm a")}
          </div>
          <div className="staff-name">
            {moment(receivingShift?.startDate.toDate()).format("MMM DD, YYYY")}
          </div>
        </Box>
        {renderActionBasedOnStatus(request.status)}
      </Box>
    );
  }

  if (type === ScheduleEventType.cover) {
    if (!senderStaffMember || !receiverStaffMember) return null;

    return (
      <Box
        onClick={onCardPress}
        className={`shift-card-${getType(
          type,
          request.status
        )} shift-card request-card-${getType(
          type,
          request.status
        )} request-card`}
        style={{ overflow: "hidden" }}
      >
        <Box className="shift-card_header">
          <div className="shift-type">{getTitleByScheduleEvent(request)}</div>
          <div className="dot" />
        </Box>
        <Box className="shift-card_body">
          <div className="staff-name">
            {getStaffMemberFullName(senderStaffMember)}
          </div>
          <div className="staff-name">
            {getPositionNameById(
              businessSettings,
              sendingShift?.position || ""
            )}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startTime, "HH:mm").format("HH:mm a")}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startDate.toDate()).format("MMM DD, YYYY")}
          </div>
        </Box>
        <Box className="shift-card_body">
          <img src={CoverArrowImage} />
        </Box>

        <Box className="shift-card_body">
          <div className="staff-name">
            {getStaffMemberFullName(receiverStaffMember)}
          </div>
          <div className="staff-name">
            {getPositionNameById(
              businessSettings,
              sendingShift?.position || ""
            )}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startTime, "HH:mm").format("HH:mm a")}
          </div>
          <div className="staff-name">
            {moment(sendingShift?.startDate.toDate()).format("MMM DD, YYYY")}
          </div>
        </Box>
        {renderActionBasedOnStatus(request.status)}
      </Box>
    );
  }

  if (type === ScheduleEventType.drop) {
    if (!senderStaffMember) return null;
    console.log("Request to Use", request);
    if (request?.localOptionalRequestId && receiverStaffMember) {
      return (
        <Box
          onClick={onCardPress}
          className={`shift-card-${getType(
            type,
            request.status
          )} shift-card request-card-${getType(
            type,
            request.status
          )} request-card`}
          style={{ overflow: "hidden" }}
        >
          <Box className="shift-card_header">
            <div className="shift-type">Pick up</div>
            <div className="dot" />
          </Box>
          <Box className="shift-card_body">
            <div className="staff-name">
              {getStaffMemberFullName(receiverStaffMember)}
            </div>
            <div className="staff-name">
              {getPositionNameById(
                businessSettings,
                singleShift?.position || ""
              )}
            </div>
            <div className="staff-name">
              {moment(singleShift?.startTime, "HH:mm").format("HH:mm a")}
            </div>
            <div className="staff-name">
              {moment(singleShift?.startDate.toDate()).format("MMM DD, YYYY")}
            </div>
          </Box>
          {renderActionBasedOnStatus(request.status)}
        </Box>
      );
    }
    return (
      <Box
        onClick={onCardPress}
        className={`shift-card-${getType(
          type,
          request.status
        )} shift-card request-card-${getType(
          type,
          request.status
        )} request-card`}
        style={{ overflow: "hidden" }}
      >
        <Box className="shift-card_header">
          <div className="shift-type">{getTitleByScheduleEventType(type)}</div>
          <div className="dot" />
        </Box>
        <Box className="shift-card_body">
          <div className="staff-name">
            {getStaffMemberFullName(senderStaffMember)}
          </div>
          <div className="staff-name">
            {getPositionNameById(businessSettings, singleShift?.position || "")}
          </div>
          <div className="staff-name">
            {moment(singleShift?.startTime, "HH:mm").format("HH:mm a")}
          </div>
          <div className="staff-name">
            {moment(singleShift?.startDate.toDate()).format("MMM DD, YYYY")}
          </div>
        </Box>
        <Box className="shift-card_footer">View</Box>
      </Box>
    );
  }
  return (
    <Box
      onClick={onCardPress}
      className={`shift-card-${getType(
        type,
        request.status
      )} shift-card request-card-${getType(type, request.status)} request-card`}
      style={{ overflow: "hidden" }}
    >
      <Box className="shift-card_header">
        <div className="shift-type">{getTitleByScheduleEventType(type)}</div>
        <div className="dot" />
      </Box>
      <Box className="shift-card_body">
        <div className="staff-name">{title}</div>
        <div className="request-time">{subtitle}</div>
      </Box>
      {renderActionBasedOnStatus(request.status)}
    </Box>
  );
};
export default RequestCard;
