import { InputGroup } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import _, { find } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";

import { staffSelector } from "model/selectors/staffing";

import { RootState } from "../../../../model/store";
import { updateBusinessSetting } from "../../../../models/businesses";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";
import RoleForm from "../RoleForm";
import "../index.css";

export interface RolesProps {
  id: number;
  role: string;
  asignee: string;
  permissions: string[];
  isSelected: boolean;
}

interface Props {
  setShouldShowCreateRole: (value: boolean) => void;
  setModifyRole: (value: JobWithDepartment) => void;
}

const Roles = ({ setShouldShowCreateRole, setModifyRole }: Props) => {
  const [searchText, setSearchText] = useState("");
  const [originalRoles, setOriginalRoles] = useState<JobWithDepartment[]>([]);
  const [roles, setRoles] = useState<JobWithDepartment[]>([]);
  const [isEditPermissions, setIsEditPermissions] = useState(false);
  const [isDeterminate, setIsDeterminate] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isRoleSort, setRoleSort] = useState(false);
  const [isAsigneeSort, setAsigneeSort] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [staffMemberLoaded, setStaffMembersLoaded] = useState(false);
  const navigate = useNavigate();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const staffMembers: StaffMember[] = useSelector(staffSelector);
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  useEffect(() => {
    let rolesTemp: JobWithDepartment[] = [];
    if (businessSettings && staffMemberLoaded) {
      rolesTemp = [
        ...Object.keys(businessSettings.jobFunctions)
          .filter((job) => !businessSettings?.jobFunctions[job]?.deleted)
          .map((job) => {
            const staffMembersByRole = staffMembers.filter(
              (member) =>
                member.primaryRole === job ||
                member.secondaryRoles.includes(job)
            );
            return {
              jobId: job,
              departmentId:
                businessSettings?.jobFunctions[job]?.departmentId || "other",
              title: businessSettings?.jobFunctions[job]?.title,
              staffMembers: staffMembersByRole,
              isSelected: false,
            };
          }),
      ];
    }
    setRoles(rolesTemp);
    setOriginalRoles(rolesTemp);
  }, [businessSettings, staffMemberLoaded]);

  useEffect(() => {
    if (staffMembers.length) {
      setStaffMembersLoaded(true);
    }
  }, [staffMembers]);

  // const jobsWithDepartment: JobWithDepartment[] = useMemo(() => {
  //   if (businessSettings && staffMembers &&
  //     staffMembers.length > 0) {
  //     return [
  //       ...Object.keys(businessSettings.jobFunctions)
  //         .filter((job) => !businessSettings?.jobFunctions[job]?.deleted)
  //         .map((job) => {
  //           const staffMembersByRole = staffMembers.filter(
  //             (member) =>
  //               member.primaryRole === job || member.secondaryRoles.includes(job)
  //           );
  //           return {
  //             jobId: job,
  //             departmentId:
  //               businessSettings?.jobFunctions[job]?.departmentId || "other",
  //             title: businessSettings?.jobFunctions[job]?.title,
  //             staffMembers: staffMembersByRole
  //           }
  //         }),
  //     ];
  //   }
  //   return [];
  // }, [businessSettings, staffMembers]);

  const manageSearchText = (searchTextValue: string) => {
    setSearchText(searchTextValue);
    setRoles([]);
    const filteredRoles: JobWithDepartment[] = JSON.parse(
      JSON.stringify(originalRoles)
    );
    if (!searchTextValue) {
      setRoles(filteredRoles);
    } else {
      const searchedRoles = filteredRoles.filter((roleItem) => {
        const defaultTextRole = roleItem.jobId;
        roleItem.jobId = roleItem.jobId.toLowerCase();
        const roleAvailable = !searchTextValue
          ? true
          : roleItem.jobId.includes(searchTextValue.toLowerCase());
        roleItem.jobId = defaultTextRole;
        return roleAvailable;
      });
      setRoles(searchedRoles);
    }
  };

  const selectAllRoles = (checkedValue: boolean) => {
    setRoles([]);
    setIsAllSelected(checkedValue);
    setIsDeterminate(false);
    let selectedRoles: JobWithDepartment[] = JSON.parse(
      JSON.stringify(originalRoles)
    );
    if (checkedValue) {
      selectedRoles = selectedRoles.map((role: JobWithDepartment) => ({
        ...role,
        isSelected: true,
      }));
    } else {
      selectedRoles = selectedRoles.map((role: JobWithDepartment) => ({
        ...role,
        isSelected: false,
      }));
    }
    setRoles(selectedRoles);
    console.log({ selectedRoles });
  };

  const selectRole = (roleIndex: number, checkedValue: boolean) => {
    const selectedRoles: JobWithDepartment[] = JSON.parse(
      JSON.stringify(roles)
    );
    setRoles([]);
    selectedRoles[roleIndex].isSelected = !selectedRoles[roleIndex].isSelected;

    // check for inderminate role
    const indeterminateRoles = selectedRoles.every((item) => !item.isSelected);
    if (indeterminateRoles) {
      setIsDeterminate(false);
      setIsAllSelected(false);
    } else {
      setIsDeterminate(true);
    }

    // check for all roles is selected
    const selectedAll = selectedRoles.every((item) => item.isSelected);

    if (selectedAll) {
      setIsDeterminate(false);
      setIsAllSelected(true);
    }

    setRoles(selectedRoles);
  };

  const handleRolePermissions = (
    roleIndex: number,
    permissionIndex: number
  ) => {
    const permissionRoles: JobWithDepartment[] = JSON.parse(
      JSON.stringify(roles)
    );
    // if (roleIndex > -1 && permissionIndex > -1) {
    //   permissionRoles[roleIndex].permissions.splice(permissionIndex, 1);
    // }
    setRoles(permissionRoles);
  };

  const handleSort = (
    isOpen: boolean,
    column: string,
    type: "desc" | "asc"
  ) => {
    setRoles([]);
    let columnName = "jobId";
    if (column === "asignee") {
      columnName = "staffMembers";
    }
    const sortRoles = _.orderBy(originalRoles, columnName, type);
    console.log({
      sortRoles,
      originalRoles,
      column,
      type,
    });
    setRoles(sortRoles);
    setRoleSort(isOpen);
    setAsigneeSort(isOpen);
  };

  const handleSortPopover = (isOpen: boolean, column: string) => {
    if (column === "role") {
      setRoleSort(isOpen);
      setAsigneeSort(false);
    } else {
      setRoleSort(false);
      setAsigneeSort(isOpen);
    }
  };

  // const redirectRole = () => {
  //   navigate("/role");
  // };

  const handleYes = async () => {
    const jobFunctionsOfBS = { ...businessSettings.jobFunctions };
    roles.forEach((role) => {
      if (role.isSelected) {
        jobFunctionsOfBS[role.jobId] = {
          ...jobFunctionsOfBS[role.jobId],
          deleted: true,
        };
      }
    });
    await updateBusinessSetting(
      businessSettings.businessId,
      "jobFunctions",
      jobFunctionsOfBS
    );
    setIsOpen(false);
  };

  const getStaffMemberNames = useCallback((staffMembers?: StaffMember[]) => {
    return (
      staffMembers
        ? staffMembers.map(
            (member) => `${member.contact.firstName} ${member.contact.lastName}`
          )
        : []
    ).join(",");
  }, []);

  const handleModifyRole = () => {
    const selectedRoles = roles.filter((role) => role.isSelected);
    if (selectedRoles.length > 0) {
      setModifyRole(selectedRoles[0]);
      setShouldShowCreateRole(true);
    }
  };

  const getDeleteDialogTitle = () => {
    return roles
      .filter((role) => role.isSelected)
      .map((role) => role.title)
      .toString();
  };

  return (
    <Box className="roles-wrapper">
      <Box className="roles-search-section">
        <InputGroup
          placeholder="Search Role"
          value={searchText}
          onChange={(e) => manageSearchText(e.target.value)}
          leftIcon="search"
          intent="none"
          className="role_input_search"
        />

        <Box className="roles-btn-wrapper">
          <button type="button" className="btn" onClick={handleModifyRole}>
            Modify
          </button>
          <button type="button" className="btn" onClick={() => setIsOpen(true)}>
            Delete
          </button>
          <button
            type="button"
            className="create-role-btn"
            onClick={() => setShouldShowCreateRole(true)}
          >
            Create Role
          </button>
        </Box>
      </Box>
      <Box className="roles-content">
        <table className="roles-table">
          <thead>
            <tr key="role-header">
              <th style={{ width: "10%", paddingLeft: "24px" }}>
                <Checkbox
                  onChange={(event) => selectAllRoles(event.target.checked)}
                  indeterminate={isDeterminate}
                  checked={isAllSelected}
                />
              </th>
              <th
                style={{
                  width: "20%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box>Role</Box>
                  <Popover2
                    interactionKind="click"
                    placement="bottom"
                    content={
                      <div className="role-popover-content">
                        <p onClick={() => handleSort(false, "role", "asc")}>
                          A - Z
                        </p>
                        <p onClick={() => handleSort(false, "role", "desc")}>
                          Z - A
                        </p>
                      </div>
                    }
                    isOpen={isRoleSort}
                  >
                    <Box
                      className="role-sort"
                      onClick={() => handleSortPopover(!isRoleSort, "role")}
                    >
                      <span>Sort</span>
                      <img
                        src={require("assets/sort_down.svg").default}
                        className="role-sort-img"
                      />
                    </Box>
                  </Popover2>
                </Box>
              </th>
              <th style={{ width: "25" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box>Assignee</Box>
                  <Popover2
                    interactionKind="click"
                    placement="bottom"
                    content={
                      <div className="role-popover-content">
                        <p onClick={() => handleSort(false, "asignee", "asc")}>
                          A - Z
                        </p>
                        <p onClick={() => handleSort(false, "asignee", "desc")}>
                          Z - A
                        </p>
                      </div>
                    }
                    isOpen={isAsigneeSort}
                  >
                    <Box
                      className="role-sort"
                      onClick={() =>
                        handleSortPopover(!isAsigneeSort, "asignee")
                      }
                    >
                      <span>Sort</span>
                      <img
                        src={require("assets/sort_down.svg").default}
                        className="role-sort-img"
                      />
                    </Box>
                  </Popover2>
                </Box>
              </th>
              <th
                style={{
                  width: "45%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box>Default Permissions</Box>
                  <Box
                    className="role-edit-permission"
                    onClick={() => setIsEditPermissions(!isEditPermissions)}
                  >
                    {!isEditPermissions ? "Edit" : "Done"}
                  </Box>
                </Box>
              </th>
            </tr>
          </thead>
          <tbody>
            {roles.length ? (
              roles.map((role, roleIndex) => (
                <tr
                  key={`role-row-${roleIndex}`}
                  className={role.isSelected ? "selected" : ""}
                >
                  <td
                    style={{
                      width: "10%",
                      verticalAlign: "top",
                      paddingTop: "24px",
                      paddingLeft: "24px",
                    }}
                  >
                    <Checkbox
                      checked={role.isSelected}
                      onChange={(event) =>
                        selectRole(roleIndex, event.target.checked)
                      }
                    />
                  </td>
                  <td
                    style={{
                      width: "20%",
                      verticalAlign: "top",
                      paddingTop: "22px",
                    }}
                  >
                    {role.title}
                  </td>
                  <td style={{ width: "25%", verticalAlign: "top" }}>
                    {getStaffMemberNames(role.staffMembers)}
                  </td>
                  <td style={{ width: "45%", verticalAlign: "top" }}>
                    <Chip
                      label={"permission"}
                      variant="outlined"
                      className="role-permissions-chip"
                    />
                    {/*{role.permissions.map((permission, permissionIndex) =>*/}
                    {/*  isEditPermissions ? (*/}
                    {/*    <Chip*/}
                    {/*      label={permission}*/}
                    {/*      variant="outlined"*/}
                    {/*      className="role-permissions-chip"*/}
                    {/*      onDelete={() =>*/}
                    {/*        handleRolePermissions(roleIndex, permissionIndex)*/}
                    {/*      }*/}
                    {/*    />*/}
                    {/*  ) : (*/}
                    {/*    // </Stack>*/}
                    {/*    <Chip*/}
                    {/*      label={permission}*/}
                    {/*      variant="outlined"*/}
                    {/*      className="role-permissions-chip"*/}
                    {/*    />*/}
                    {/*  )*/}
                    {/*)}*/}
                  </td>
                </tr>
              ))
            ) : (
              <tr key="role-row-notfound">
                <td colSpan={4} className="roles-not-found">
                  Roles Not Found...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      {isOpen && (
        <DeleteConfirmationDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleYes={handleYes}
          dialogHeaderTitle={`Delete ${getDeleteDialogTitle()} Role(s)?`}
        />
      )}
    </Box>
  );
};

export default Roles;
