import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getTaxRateAndTangoFees } from "models/orders";

import { RootState } from "model/store";

import Layout from "../../Layout";
import "../style.css";

// interface IBankDepositSalesSummary {
//   startDate: Date;
//   endDate: Date;
//   numberOfSales: number;
//   netSales: number;
//   transactionFees: number;
//   salesFees: number;
//   netDeposit: number;
//   status: string;
//   depositDate: Date;
// }

const BankDeposit = () => {
  const today = new Date();
  const payouts: ManualPayouts[] = useSelector(
    (state: RootState) => state.payouts
  );
  const payoutsLedger: PayoutLedger[] = useSelector(
    (state: RootState) => state.payoutLedgers
  );
  // const { tabs } = useSelector(getOrdersAndTabsForReporting);
  const business = useSelector(
    (state: RootState) => state.business
  ) as TangoBusiness;
  const allPricingModels = [].concat.apply(
    [],
    business?.tangoProducts.map((model: DocumentData) => {
      const { name } = model;
      return model.orderTypes.map((data: DocumentData) => {
        return {
          orderChannel: name,
          orderType: data.name,
          taxRate: data.taxRate,
          tangoFeeCents: data.tangoFeeCents,
          tangoFeePercent: data.tangoFeePercent,
          waitTime: data.waitTime || 0,
        };
      });
    })
  );
  const pricingModel = getTaxRateAndTangoFees(allPricingModels);
  console.log("Pricing models: ", pricingModel);

  // In Transit or Deposited
  // Sales Fees       -> Percent (e.g. 2.6%)
  // Transaction Fees -> Cents   (e.g. $0.05)

  // const getPayoutSummary = () => {
  //   // Get the payout summary
  //   const payoutSummary = payouts.reduce(
  //     (acc, val, index) => [
  //       ...acc,
  //       {
  //         startDate: val.createdAt,
  //         endDate:
  //           index + 1 < payouts.length
  //             ? payouts[index + 1].createdAt
  //             : new Date(),
  //         numberOfSales: 0,
  //         netSales: 0,
  //         transactionFees: 0,
  //         salesFees: 0,
  //         netDeposit: val.amount,
  //         status: "",
  //         depositDate: val.arrivalDate,
  //       },
  //     ],
  //     [] as IBankDepositSalesSummary[]
  //   );

  //   const summary = tabs.reduce((acc, tab) => {
  //     const index = acc.findIndex((payout) => {
  //       return (
  //         // @ts-ignore
  //         payout.startDate <= tab.createdAt.toDate() &&
  //         // @ts-ignore
  //         payout.endDate >= tab.createdAt.toDate()
  //       );
  //     });

  //     // // TODO: Get the right application fee amount distribution
  //     if (index !== -1) {

  //     const customerSummary = tab.customer.reduce(
  //       (customerSummary, customer) => {
  //         if (
  //           customer &&
  //           customer.payment &&
  //           customer.payment.successfulCharge &&
  //           customer.payment.successfulCharge.amount
  //         ) {
  //           const applicationFeeAmount = Object.keys(
  //             customer.payment.successfulCharge
  //           ).includes("applicationFeeAmount")
  //             ? customer.payment.successfulCharge.applicationFeeAmount || 0
  //             : 0;
  //           return {
  //             netSales:
  //               customerSummary.netSales +
  //               customer.payment.successfulCharge.amount,
  //             numberOfSales: customerSummary.numberOfSales + 1,
  //             transactionFees: applicationFeeAmount,
  //             salesFees: applicationFeeAmount,
  //           };
  //         }
  //         return customerSummary;
  //       },
  //       { netSales: 0, transactionFees: 0, salesFees: 0, numberOfSales: 0 }
  //     );

  //       acc[index] = {
  //         ...acc[index],
  //         numberOfSales:
  //           acc[index].numberOfSales + customerSummary.numberOfSales,
  //         netSales: acc[index].netSales + customerSummary.netSales,
  //         transactionFees:
  //           acc[index].transactionFees + customerSummary.transactionFees,
  //         salesFees: acc[index].salesFees + customerSummary.salesFees,
  //       };
  //     }
  //     return acc;
  //   }, payoutSummary as IBankDepositSalesSummary[]);

  //   return summary;
  // };

  // useEffect(() => {
  //   if (tabs.length > 0) {
  //     // @ts-ignore
  //     console.log('Original payouts: ', payouts.sort((a, b) => a.createdAt - b.createdAt))
  //     const summary = getPayoutSummary();
  //     setBankDepositSummary([...summary]);
  //   }
  // }, [tabs]);

  return (
    <div className="w-full">
      <div className="page-header">
        <div className="page-title">Deposits</div>
      </div>
      <table>
        <tr>
          <th>Business Date</th>
          <th>Number of Sales</th>
          <th>Net Sales</th>
          <th>Transaction Fees</th>
          <th>Sales Fees</th>
          <th>Net Deposits</th>
          <th>Status</th>
          <th>Deposit Date</th>
          <th>Disputes/Refunds</th>
        </tr>
        {payoutsLedger
          .sort(
            (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
          )
          .map((payout) => {
            return (
              <tr>
                <td>
                  {moment(payout.createdAt).subtract(1, "day").format("MMM D")}
                </td>
                <td>{payout.numberOfTransactions}</td>
                <td>
                  $
                  {(
                    (payout.amountEarned +
                      payout.applicationFeeAmount +
                      payout.amountRefunded +
                      payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (pricingModel.tangoFeeCents * payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (payout.applicationFeeAmount -
                      pricingModel.tangoFeeCents *
                        payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>${(payout.amountEarned / 100).toFixed(2)}</td>
                {/* TODO: Make this more concrete */}
                <td className="status">
                  <span className={payout.completed ? "deposited" : "transit"}>
                    {payout.completed ? "Deposited" : "In Transit"}
                  </span>
                </td>
                <td></td>
                <td>
                  $
                  {(
                    (payout.amountRefunded + payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
            );
          })}
        {/* Manual Payouts */}
        {payouts
          .sort(
            (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
          )
          .map((payout) => {
            return (
              <tr>
                <td>{moment(payout.businessDate).format("MMM D")}</td>
                <td>{payout.numberOfTransactions}</td>
                <td>
                  $
                  {(
                    (payout.amount +
                      payout.applicationFeeAmount +
                      payout.amountRefunded +
                      payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (pricingModel.tangoFeeCents * payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>
                  $
                  {(
                    (payout.applicationFeeAmount -
                      pricingModel.tangoFeeCents *
                        payout.numberOfTransactions) /
                    100
                  ).toFixed(2)}
                </td>
                <td>${(payout.amount / 100).toFixed(2)}</td>
                {/* TODO: Make this more concrete */}
                <td className="status">
                  {today < payout.arrivalDate ? (
                    <span className="transit">In Transit</span>
                  ) : (
                    <span className="deposited">Deposited</span>
                  )}
                </td>
                {/* HACK: Adding a day because it seems like there's a 1 day difference between Stripe and our system */}
                <td>
                  {moment(payout.arrivalDate).add(1, "day").format("MMM D")}
                </td>
                <td>
                  $
                  {(
                    (payout.amountRefunded + payout.disputeTotal) /
                    100
                  ).toFixed(2)}
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
};

export default BankDeposit;
