//@ts-ignore
import _ from "lodash";
import React from "react";

import Box from "components/Box";
import CurrencyInput from "components/Inputs/CurrencyInput";

import EditIcon from "assets/edit-budget.svg";

import "./style.css";

interface CostScheduleCardProps {
  title: string;
  value?: number;
  stringValue?: string;
  onChange?: (v: number) => void;
  disabled?: boolean;
  showWarning?: boolean;
  removeExtraMargin?: boolean;
}

export function CostScheduleCard(props: CostScheduleCardProps) {
  const renderValueInput = () => {
    if (props.onChange && !_.isNil(props.value)) {
      return (
        <CurrencyInput
          disabled={props.disabled}
          value={props.value}
          className={
            props.showWarning
              ? "cost-schedule-subtitle-warning"
              : "cost-schedule-subtitle"
          }
          onValueChange={props.onChange}
        />
      );
    }
    if (props.stringValue) {
      return (
        <div
          className={
            props.showWarning
              ? "cost-schedule-subtitle-warning"
              : "cost-schedule-subtitle"
          }
        >
          {props.stringValue}
        </div>
      );
    }
    if (!_.isNil(props.value)) {
      const valueDisplay = (props.value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      return (
        <div
          className={
            props.showWarning
              ? "cost-schedule-subtitle-warning"
              : "cost-schedule-subtitle"
          }
        >
          {valueDisplay}
        </div>
      );
    }
    return null;
  };
  return (
    <Box
      className="cost-schedule-card"
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={props.removeExtraMargin ? { marginRight: 0 } : {}}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <div className="cost-schedule-title">{props.title}</div>
        {props.onChange ? <img src={EditIcon} /> : <div />}
      </Box>
      {renderValueInput()}
    </Box>
  );
}
