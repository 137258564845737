import { Icon } from "@blueprintjs/core";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";

import { useUpdateDocument } from "./hooks/useUpdateDocument";
import "./index.css";

const Document = () => {
  const params = useParams<{ docId: string }>();
  const navigate = useNavigate();

  const { docText, handleChange, docData } = useUpdateDocument({
    docId: params.docId,
  });

  const modules: any = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      ["image", "blockquote", "code-block"],
      ["clean"],
    ],
  };
  // const debouncedSave = React.useRef(
  //   debounce(async (data) => {
  //     saveDataToDoc(data);
  //   }, 1000)
  // ).current;
  //
  // const handleChange = async (value: any) => {
  //   await setDocText(value);
  //   debouncedSave(value);
  // };

  return (
    <div>
      <div className="doc-container top-bar">
        <div
          className="align-left"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <Icon icon="arrow-left" />
        </div>
        <div className="align-right">{docData?.title}</div>
      </div>
      <div className="doc-container">
        {/* <CustomToolbar /> */}
        <ReactQuill
          value={docText}
          onChange={handleChange}
          theme="snow"
          modules={modules}
        />
        ;
      </div>
    </div>
  );
};

export default Document;
