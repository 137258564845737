import UIComponents from "@tangopay/tango-ui-library";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

import Header from "../../../components/Header";
import CalendarToolbar from "./CalendarToolbar";
import EventModal from "./EventModal";
import EventPopover from "./EventPopover";
import EventWrapper from "./EventWrapper";
import Sidebar from "./Sidebar";
import SuccessModal from "./SuccessModal";
import useAccounts from "./useAccounts";
import useCalendar from "./useCalendar";

const localizer = momentLocalizer(moment);

const TangoCalendar = () => {
  const {
    events,
    selectedView,
    setSelectedView,
    moreEvents,
    setMoreEvents,
    selectedEvent,
    setSelectedEvent,
  } = useCalendar();
  const [showEventModal, setShowEventModal] = useState(false);
  const { calendars } = useAccounts();
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={[
          {
            label: "Calendar",
            link: "/calendar",
          },
          {
            label: "Email",
            link: "/email",
          },
          {
            label: "Notes",
            link: "/notes",
          },
        ]}
        selectedRoute={"/calendar"}
      />
      <div className="flex h-full">
        <div style={{ width: "20%" }} className={"relative sidebar"}>
          <Sidebar />
        </div>
        <div style={{ width: "80%" }} className="border-l border-grey-1">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            className={"tango-calendar h-full"}
            views={{
              work_week: true,
              month: true,
              week: true,
            }}
            view={selectedView.value}
            popup={false}
            scrollToTime={new Date()}
            messages={{
              //@ts-ignore
              showMore: (total) => (
                <UIComponents.Popover
                  containerClassName={"z-10"}
                  onClickOutside={() => {
                    if (!selectedEvent) setMoreEvents(null);
                  }}
                  isOpen={!!(moreEvents && moreEvents.length > 0)}
                  content={
                    <div
                      className={"bg-white drop-shadow-card p-3.5 rounded-2xl"}
                    >
                      <ul>
                        {moreEvents?.map((moreEvent) => {
                          return (
                            <EventPopover
                              accounts={calendars}
                              onClickOutside={() => {
                                if (selectedEvent?.showTimePicker) {
                                } else {
                                  setSelectedEvent(null);
                                }
                              }}
                              isOpen={selectedEvent?.id == moreEvent.id}
                              event={moreEvent}
                              onProposeTimeClick={() => {
                                if (selectedEvent) {
                                  setSelectedEvent({
                                    ...selectedEvent,
                                    showTimePicker: true,
                                  });
                                }
                              }}
                              showTimePicker={
                                selectedEvent?.showTimePicker || false
                              }
                              onCloseProposeTime={() => {
                                if (selectedEvent) {
                                  setSelectedEvent({
                                    ...selectedEvent,
                                    showTimePicker: false,
                                  });
                                }
                              }}
                              onEventClick={() => {
                                setSelectedEvent(moreEvent);
                              }}
                              showAccountsPicker={
                                selectedEvent?.showAccountsPicker || false
                              }
                              toggleAccountsPicker={() => {
                                if (selectedEvent) {
                                  setSelectedEvent({
                                    ...selectedEvent,
                                    showAccountsPicker:
                                      !selectedEvent.showAccountsPicker,
                                  });
                                }
                              }}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  }
                >
                  <div>{total} more...</div>
                </UIComponents.Popover>
              ),
            }}
            onShowMore={(moreEvents, date) => {
              setMoreEvents(moreEvents);
            }}
            components={{
              timeGutterHeader: (props) => {
                return <div className={"all-day-text"}>All Day</div>;
              },
              eventWrapper: (props) => (
                <EventWrapper
                  event={props.event}
                  style={props.style}
                  selectedView={selectedView}
                />
              ),
              toolbar: (props) => (
                <CalendarToolbar
                  {...props}
                  showEventModal={() => setShowEventModal(true)}
                  setSelectedView={setSelectedView}
                  selectedView={selectedView}
                />
              ),
            }}
          />
        </div>
      </div>
      <EventModal
        isOpen={showEventModal}
        onClose={() => setShowEventModal(false)}
      />
    </div>
  );
};

export default TangoCalendar;
