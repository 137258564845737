import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";

import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedColor: string;
  changeSelectedColor: any;
  addNewStatus: any;
}

const AddStatusModal = ({
  isOpen,
  onClose,
  selectedColor,
  changeSelectedColor,
  addNewStatus,
}: Props) => {
  const [status, setStatus] = useState("");

  return (
    <Dialog
      portalClassName="location-modal-portal"
      isOpen={isOpen}
      onClose={onClose}
      className="add-status"
    >
      <div className="header">
        <Icon
          icon="cross"
          className="close-button"
          color="#424452"
          iconSize={20}
          onClick={onClose}
        />
        <span className="modal-title">Add Status</span>
        <span></span>
      </div>
      <div className="search-location">
        <div className="to">Add Status :</div>
        <input
          placeholder="New Status"
          onChange={(e) => setStatus(e.target.value)}
        />
      </div>
      <div className="color-picker">
        <HexColorPicker color={selectedColor} onChange={changeSelectedColor} />
        color:{selectedColor}
      </div>

      <div className="body">
        {/*
        selectedLocations.length > 0 && <Button text="Continue" className="continue-button" onClick={onClose}/>
      */}
        <Button
          onClick={() => addNewStatus(status, selectedColor)}
          text="Add"
          className="continue-button"
        />
      </div>
    </Dialog>
  );
};

export default AddStatusModal;
