import { AccountsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: TangoAccount[] = [];

export default function accounts(state = initialState, action: AccountsAction) {
  switch (action.type) {
    case ActionType.RECEIVE_ACCOUNTS:
      return action.accounts;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
