import { Dialog, Icon, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  publishMenus,
  saveStitchedProductsToFirebase,
} from "views/admin/menus&Products/SaveStitchedProducts";

import { FirebaseMenuDoc, MenuSummary } from "types/menus";

import { RootState } from "model/store";

import BackArrowIcon from "assets/back-arrow.svg";
import RightChevronIcon from "assets/channel-search-arrow.svg";
import CheckMarkIcon from "assets/check.svg";
import FilterIcon from "assets/filter-icon.svg";
import SearchIcon from "assets/search-icon.svg";

import Layout from "./Layout";
import "./publishMenu.css";

const PublishMenu = () => {
  const navigate = useNavigate();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const allMenus: FirebaseMenuDoc[] = useSelector(
    (state: RootState) => state.menus
  );

  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );

  const menus = allMenus
    .filter((filteredMenu) => filteredMenu.enterpriseUpdatedDocument)
    .map((i) => ({ label: i.nameExternal, value: i.id }));
  const channels = ["Tango Dine-in", "Tango Online", "Tango Dine-in PWA"];
  const locations = businesses.map((i) => ({ name: i.businessName, channels }));

  const [selectedChannels, setSelectedChannels] = useState<
    { location: string; channelName: string }[]
  >([]);
  const [selectedMenus, setSelectedMenus] = useState<
    { label: string; value: string }[]
  >([]);
  const [query, setQuery] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPublishedModal, setShowPublishedModal] = useState<boolean>(false);

  const toggleChannel = (location: string, channelName: string) => {
    const exisitngChannels = [...selectedChannels];
    const channelIndex = getChannelIndex(location, channelName);
    if (channelIndex > -1) {
      exisitngChannels.splice(channelIndex, 1);
    } else {
      exisitngChannels.push({ location, channelName });
    }
    setSelectedChannels(exisitngChannels);
  };

  const getChannelIndex = (location: string, channelName: string) => {
    const channelIndex = selectedChannels.findIndex((channel) => {
      if (
        location === channel.location &&
        channelName === channel.channelName
      ) {
        return true;
      }
      return false;
    });
    return channelIndex;
  };

  const getBusinessIdByName = (businessName: string) => {
    return (
      businesses.find((business) => business.businessName === businessName) ??
      ""
    );
  };

  const saveToFirebase = async () => {
    // Create a reference with the business name
    // and an array of channels that the business uses
    const channelByBusiness = selectedChannels.reduce(
      (acc, val: { location: string; channelName: string }) => {
        if (Object.keys(acc).includes(val.location)) {
          acc[val.location].push(val.channelName);
        } else {
          acc[val.location] = [val.channelName];
        }
        return acc;
      },
      {} as { [T: string]: string[] }
    );

    const menuSummary = selectedMenus.reduce((acc, menu) => {
      // Get the business and channels by Business ID to publish to a specific menu
      const businessSummary = Object.keys(channelByBusiness).reduce(
        (acc, businessName) => {
          const businessId: TangoBusiness | undefined = businesses.find(
            (businessDoc) => businessDoc.businessName === businessName
          );
          if (businessId) {
            return { ...acc, [businessId.id]: channelByBusiness[businessName] };
          }
          return acc;
        },
        {}
      );

      const summary = {
        id: menu.value,
        name: menu.label,
        businesses: businessSummary,
      };
      acc.push(summary);
      return acc;
    }, [] as MenuSummary[]);

    const success = await publishMenus(menuSummary, business.accountId);

    const businessIds = menuSummary.flatMap((summary) =>
      Object.keys(summary.businesses)
    );
    for await (const businessId of businessIds) {
      await saveStitchedProductsToFirebase(businessId);
    }

    return success;
  };

  return (
    <Layout>
      <div className={"publish-menu-page"}>
        {/*<div className={"sidebar"}>sidebar</div>*/}
        <div className={"content"}>
          <div className={"subheader"}>
            <div className={"subheader-left"}>
              {/*<UIComponents.Icon name={"arrow-left"} size={"20"}/>*/}
              <Link to="/menus">
                <img src={BackArrowIcon} className={"back-button"} />
              </Link>
              <div className={"subheader-title"}>Publish Menu</div>
            </div>
            <Link to="/menus">
              <div className={"text-base text-black font-lato-black"}>
                Cancel
              </div>
            </Link>
          </div>
          <div className={"content-body"}>
            <div className={"text-black font-lato-black text-2xl"}>
              Selected Menus
            </div>
            <Select
              classNamePrefix={"react-select"}
              className={"selected-menu-dropdown"}
              options={menus}
              isMulti
              components={{ IndicatorSeparator: null }}
              onChange={(opt) => {
                const selectedOptions = opt.map((i) => ({
                  label: i.label,
                  value: i.value,
                }));
                setSelectedMenus([...selectedOptions]);
              }}
            />
            <div className={"border border-grey-1 rounded-2xl bg-white mt-10"}>
              <div
                className={
                  "flex items-center justify-between table-header border-b border-grey-1"
                }
              >
                <div className={"text-black font-lato-black text-xl"}>
                  Publish to
                </div>
                <div className={"flex items-center"}>
                  <div
                    className={
                      " bg-blue-grey-2 rounded-full w-64 px-6 flex items-center"
                    }
                  >
                    <img src={SearchIcon} className={"mr-4"} />
                    <input
                      placeholder={"Search"}
                      className={
                        "py-2 border-0 bg-transparent text-sm text-black font-lato-regular"
                      }
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                  <button
                    className={
                      "bg-black publish-button rounded-full text-sm font-lato-bold"
                    }
                    onClick={async () => {
                      setShowLoading(true);
                      const success = await saveToFirebase();
                      setShowLoading(false);
                      setShowPublishedModal(true);

                      // setTimeout(() => {
                      //   saveToFirebase();

                      // }, 10000);
                    }}
                  >
                    {showLoading ? (
                      <>
                        <Spinner size={20} />
                      </>
                    ) : (
                      <>Publish</>
                    )}
                  </button>
                  {/*<UIComponents.SearchBar options={[{label:"test", value:"test"}]} size={"medium"} filters={[]} />*/}
                  {/*<UIComponents.Button label={"Publish"} type={"btn-style-1"} size={"btn-medium"} className={"publish-button"}/>*/}
                </div>
              </div>
              <table className={"w-full"}>
                <tr className={"bg-blue-grey-2 border-b border-grey-1"}>
                  <th className={"flex items-center"}>
                    Location
                    {/*
                    <img
                      src={RightChevronIcon}
                      className={"location-chevron ml-px"}
                    />
                    <img
                      src={FilterIcon}
                      className={"ml-px location-filter-image"}
                    />
                  */}
                  </th>
                  <th>Channels (Select one or more)</th>
                </tr>
                {locations
                  .filter((location) => {
                    if (!query) return true;
                    return location.name
                      .toLowerCase()
                      .includes(query.toLowerCase());
                  })
                  .map((location, locationIndex) => (
                    <tr
                      key={locationIndex}
                      className={"border-b border-grey-1"}
                    >
                      <td className="text-2xl font-lato-regular text-black">
                        {location.name}
                      </td>
                      <td>
                        <div className={"flex"}>
                          {location.channels.map((channel, channelIndex) => {
                            const isSelected =
                              getChannelIndex(location.name, channel) > -1;
                            return (
                              <div
                                className={`multiselect-tag ${isSelected ? "active" : ""
                                  } font-lato-bold rounded-full flex items-center`}
                                onClick={() =>
                                  toggleChannel(location.name, channel)
                                }
                              >
                                <span className={"mr-4 text-lg"}>
                                  {channel}
                                </span>
                                {isSelected ? (
                                  <img src={CheckMarkIcon} />
                                ) : (
                                  <div className={"placeholder-icon"} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
        </div>
        <Dialog
          isOpen={showPublishedModal}
          className="success-modal"
          onClose={() => {
            setShowPublishedModal(false);
            navigate(-1);
          }}
        >
          <div className="modal-body">
            <div className="modal-title">SUCCESS!</div>
            <div className="modal-message">
              All the menus have been published.
            </div>
          </div>
          <div className="modal-footer">
            <div className="check-mark-container">
              <Icon icon="tick" iconSize={87} color="#fff" />
            </div>
          </div>
        </Dialog>
      </div>
    </Layout>
  );
};

export default PublishMenu;
