import { Spinner } from "@blueprintjs/core";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import componentLoader from "utils/codeSplitting";

import { RootState } from "model/store";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import SurveyBuilder from "../SurveyBuilder";
import ViewForms from "../ViewForms";
import Boards from "./Boards";
import ViewApplications from "./Hiring/ViewApplications";
import ViewArchives from "./Hiring/ViewArchives";
import Memos from "./Memos";
import NewTeamFlow from "./NewTeam";
import Staffing from "./Staffing";
import { CostScheduleChatModule } from "./Staffing/CostScheduleChatModule";
import Docs from "./Staffing/Docs";
import Payroll from "./Staffing/Payroll";
import NewPayroll from "./Staffing/new_Payroll";
import Team from "./Team";
import AddEmployee from "./Team/AddEmployee";
import ApplicationTracker from "./Team/ApplicationTracker";
import ArchivedEmployees from "./Team/ArchivedEmployees";
import ArchivedJobs from "./Team/ArchivedJobs";
import CreateCustomGroup from "./Team/CreateCustomGroup";
import CreateJobPosting from "./Team/CreateJobPosting";
import CustomGroups from "./Team/CustomGroups";
import EventCalendar from "./Team/EventCalendar";
import DailyLogsII from "./dailyLogsII";
import "./style.css";
import CloudFiles from "views/Cloud/Files";
import DocumentsStack from "views/Cloud/Files";
import PaymentsScreen from "views/Payments/PaymentsScreen";
import Header from "components/Header";

const Manager = () => {
  const location = useLocation();

  const launchpad = useLaunchpad();
  const navigate = useNavigate();

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  return (
    <Box className="staffing-page">

      {location.pathname.includes("/manager/add-employee") ||
        location.pathname.includes("/manager/view-archives") ||
        location.pathname.includes("/manager/custom-groups") ? null : (
        <Header
          routes={[
            {
              label: "Staffing",
              link: "/manager/scheduling",
            },
            {
              label: "Team",
              link: "/manager/team",
            },
            {
              label: "Daily Logs",
              link: "/manager/daily-logs"
            },
            {
              label: "Hiring",
              link: "/manager/application-tracker"
            },

            {
              label: "Payroll",
              link: "/manager/payroll"
            },

          ]}
          selectedPage={location.pathname === '/manager/boards' ?
            {
              label: "Productivity",
              value: "/manager/boards"
            } : {
              label: "People",
              value: "/manager/team"
            }}
          selectedRoute={location.pathname}
        />
      )}



      <div className=" pt-16">
        <Routes>
          <Route
            path="scheduling/*"
            element={
              <React.Suspense fallback={<Spinner />}>
                <Staffing />
              </React.Suspense>
            }
          />
          <Route
            path="memos"
            element={
              <motion.div key="memos">
                <React.Suspense fallback={<Spinner />}>
                  <Memos />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path="team/*"
            element={
              <motion.div key="team">
                <React.Suspense fallback={<Spinner />}>
                  <NewTeamFlow />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/daily-logs`}
            element={
              <motion.div key="dailyLogs">
                <React.Suspense fallback={<Spinner />}>
                  <DailyLogsII />
                </React.Suspense>
              </motion.div>
            }
          />
          {/* <Route
            path={`/docs`}
            element={
              <motion.div key="docs">
                <React.Suspense fallback={<Spinner />}>
                  <Docs />
                </React.Suspense>
              </motion.div>
            }
          /> */}



          <Route
            path={`/payroll/*`}
            element={
              <motion.div key="payroll">
                <React.Suspense fallback={<Spinner />}>
                  <NewPayroll />
                </React.Suspense>
              </motion.div>
            }
          />
          {/* <Route
            path={`/team`}
            element={
              <motion.div key="team">
                <React.Suspense fallback={<Spinner />}>
                  <Team />
                </React.Suspense>
              </motion.div>
            }
          /> */}
          <Route
            path={`/application-tracker`}
            element={
              <motion.div key="application-tracker">
                <React.Suspense fallback={<Spinner />}>
                  <ApplicationTracker />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/view-archives`}
            element={
              <motion.div key="view-archives">
                <React.Suspense fallback={<Spinner />}>
                  <ViewArchives />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/view-archived-jobs`}
            element={
              <motion.div key="view-archived-jobs">
                <React.Suspense fallback={<Spinner />}>
                  <ArchivedJobs />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/view-archived-employees`}
            element={
              <motion.div key="view-archived-jobs">
                <React.Suspense fallback={<Spinner />}>
                  <ArchivedEmployees />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/view-applications`}
            element={
              <motion.div key="view-applications">
                <React.Suspense fallback={<Spinner />}>
                  <ViewApplications />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/survey-builder`}
            element={
              <motion.div key="survey-builder">
                <React.Suspense fallback={<Spinner />}>
                  <SurveyBuilder />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/create-job-post`}
            element={
              <motion.div key="create-job-post">
                <React.Suspense fallback={<Spinner />}>
                  <CreateJobPosting />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/add-employee`}
            element={
              <motion.div key="add-employee">
                <React.Suspense fallback={<Spinner />}>
                  <AddEmployee />
                </React.Suspense>
              </motion.div>
            }
          />



          <Route
            path={`/event-calendar`}
            element={
              <motion.div
                key="event-calendar"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.15 }}
              >
                <React.Suspense fallback={<Spinner />}>
                  <EventCalendar />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/custom-groups`}
            element={
              <motion.div
                key="custom-groups"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.15 }}
              >
                <React.Suspense fallback={<Spinner />}>
                  <CustomGroups />
                </React.Suspense>
              </motion.div>
            }
          />
          <Route
            path={`/create-custom-group`}
            element={
              <motion.div
                key="create-custom-group"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.15 }}
              >
                <React.Suspense fallback={<Spinner />}>
                  <CreateCustomGroup />
                </React.Suspense>
              </motion.div>
            }
          />

          <Route
            path={`/payments`}
            element={
              <motion.div
                key="custom-groups"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 20 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.15 }}
              >
                <React.Suspense fallback={<Spinner />}>
                  <PaymentsScreen/>
                  {/* <CustomGroups /> */}
                </React.Suspense>
              </motion.div>
            }
          />

          <Route path="*" element={<Navigate replace to="scheduling" />} />
        </Routes>
      </div>
      <CostScheduleChatModule />
    </Box>
  );
};
export default Manager;
