import React from "react";

import UploadImageIcon from "assets/upload-image.svg";

interface Props {
  color: string;
}

const UploadFile = ({ color }: Props) => {
  return (
    <div
      className="upload-file-component"
      style={{ borderColor: color, backgroundColor: color + "0C" }}
    >
      <img src={UploadImageIcon} />
      <div style={{ color, borderColor: color }} className="apply-font">
        Upload
      </div>
    </div>
  );
};

export default UploadFile;
