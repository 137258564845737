import { FirebasePrintersDoc } from "types/printers";

import { PrintersAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebasePrintersDoc[] = [];

export default function printers(state = initialState, action: PrintersAction) {
  switch (action.type) {
    case ActionType.RECEIVE_PRINTERS:
      return action.printers;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
