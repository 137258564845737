import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import HowToVideos from "./HowToVideos";
import Layout from "./Layout";

const HelpSupport = () => {
  return (
    <Layout>
      <Routes>
        <Route path="tutorials" element={<HowToVideos />} />
        <Route path="*" element={<Navigate to="/notFound" />} />
      </Routes>
    </Layout>
  );
};

export default HelpSupport;
