import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onTabletClick: () => void;
}
const CountMethodModal = ({ isOpen, onClose, onTabletClick }: Props) => {
  return (
    <UIComponents.Modal isOpen={isOpen} closeModal={onClose} width={"600"}>
      <div className={"font-lato-bold text-2xl text-black mb-9"}>
        Inventory Count Method
      </div>
      <UIComponents.ModalButton
        label={"Print Sheet"}
        className={"mb-4"}
        type={"white"}
        subheading={
          "Print a count sheet, write down the count, and then manually input into Tango"
        }
      />
      <UIComponents.ModalButton
        onClick={onTabletClick}
        label={"Tablet"}
        type={"white"}
        subheading={"Use Tango Tablet to input inventory count"}
      />
    </UIComponents.Modal>
  );
};
export default CountMethodModal;
