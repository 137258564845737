import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

import Accounts from "./Accounts";
import Invites from "./Invites";
import useCalendar from "./useCalendar";

const Sidebar = () => {
  const { selectedTab, setSelectedTab } = useCalendar();

  const [invites, setInvites] = useState([
    {
      title: "Mid-week catch-up",
      description: "Tango Video Call",
      organiser: "Billy Joel  (organizer)",
      isExpanded: false,
    },
    {
      title: "Mid-week catch-up",
      description: "Tango Video Call",
      organiser: "Billy Joel  (organizer)",
      isExpanded: false,
    },
  ]);
  return (
    <>
      <div className={"flex p-6 justify-between border-b border-grey-1"}>
        <UIComponents.Button
          label={"Accounts"}
          type={"btn-style-1"}
          size={"btn-small"}
          onClick={() => setSelectedTab("accounts")}
          className={`${
            selectedTab === "accounts" ? "!bg-black !text-white" : ""
          } w-full mr-2.5 !px-0 justify-center`}
        />
        <UIComponents.Button
          label={"Invites"}
          type={"btn-style-1"}
          size={"btn-small"}
          onClick={() => setSelectedTab("invites")}
          className={`${
            selectedTab === "invites" ? "!bg-black !text-white" : ""
          } w-full !px-0 justify-center`}
        />
      </div>
      {selectedTab === "accounts" ? <Accounts /> : null}
      {selectedTab === "invites" ? (
        <Invites
          invites={invites}
          expandInvite={(index) => {
            const newInvites = [...invites];
            newInvites[index]["isExpanded"] = !newInvites[index]["isExpanded"];
            setInvites(newInvites);
          }}
        />
      ) : null}
      <div
        className={"fixed left-0 right-0 bottom-0 border-t border-grey-1"}
        style={{ width: "20%" }}
      >
        <UIComponents.Calendar />
      </div>
    </>
  );
};
export default Sidebar;
