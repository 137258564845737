import { baseUrl } from './core';
import { generateBearerToken } from './init';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const testURl = 'http://localhost:9001/tango-2/us-central1';
const paymentsApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/payments`,
});

paymentsApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export interface TangoTab {
  id: string;
  businessId: string;
  name: string | null;
  isExplicitTab: boolean;
  deleted: boolean;
  staffId: string;
  createdAt: Date;
  updatedAt: Date;
  tableId: string;
  tableNumber: number | null;
  numberOfGuests: number;
  customer: CustomerModel[];
  status: OrderStatus;
  paymentComplete: boolean;
  refundPayload: any | null;
  allSplitTypes: string[];
  sessionId: string;
  serviceAreaId: string;
  tabNumber: string | number;
  voidedProducts: any;
  tableAlias: any;
}

interface CustomerModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  userId: string;
  payment: OrderPayment;
  splitType: string | null;
  orderChannel: string;
  orderType: string;
  productsPaid: ProductsPaid[];
  isDineInOrder: boolean;
  amount: CustomerAmount;
}

export interface ProductsPaid {
  productId: string;
  productName: string;
  productCategory: string;
  menuCategory: string;
  menuName: string;
  quantity: number;
  price: number;
  taxRate: number | null;
  productOrderId: string;
  selectedModifiers: any;
  name: string;
}

export interface OrderStatus {
  open: boolean;
  data: string;
  error: number;
  refunded: boolean;
  refundedAmount: number;
  deleted: boolean;
}

interface OrderPayment {
  type: string;
  successfulCharge: SuccessfulCharge;
  paymentType: CashPaymentType | StripePaymentType | any;
}
interface CashPaymentType {
  staffId: string;
  amount: number;
  drawerId: string;
  type: string;
}

interface StripePaymentType {
  customerTokenId: string;
  acct_stripeConnectId: string;
  merchantTokenId: string;
  type: string;
}
interface SuccessfulCharge {
  id: string | null;
  amount: number;
  timestampCharged: Date | null;
  paymentVendor: 'stripe' | 'fattmerchant' | 'cash' | 'external' | 'gift-card' | null;
  applicationFeeAmount: number;
}

interface Product {
  productOrderId: string;
  productId: string;
  businessId: string;
  imageUrl: string;
  menuName: string;
  menuCategory: string;
  name: string;
  quantity: number;
  price: number;
  discountedPrice: number | null;
  taxRate: number | null;
  type: string;
  alcohol: boolean;
  discountsAppliedToProduct: DiscountApplied[];
  selectedModifiers: SelectedModifiers[];
  note: string | null;
  sentQuantity: number;
  orderQuantity: number;
  plu: string | null;
  orderId?: string;
  orderCancelled?: boolean;
  seat: string;
  course: string;
  fulfilled: boolean;
  status: string;
  isPrinted: boolean;
}
interface SelectedModifiers {
  modifierId: string;
  additionalCost: number;
  modifierName: string;
  optionNames: string[];
  options: {
    name: string;
    plu: string | null;
    id: string;
    additionalCost: number;
  }[];
}

export interface CustomerAmount {
  netTotal: number;
  refundPayload: any;
  refundedAmount: number;
  tip: number;
  tax: number;
  deliveryFee: number;
}

interface DiscountApplied {
  discountName: string;
  discountId: string;
  discountTotal: number;
}

interface StaffMember {
  id: string;
  contact: {
    firstName: string;
    lastName: string;
  };
  managerPin: any;
}

export interface RefundPaymentDTO {
  customerIndex: number;
  amount: number;
  paymentIntentId: string;
  reverseTransfer: boolean; //always true
  reason: string;
  cashRefund: boolean;
}

export interface GiftCardPurcahse {
  id: string;
  createdAt: any;
  deleted: boolean;
  accountId: string;
  amount: number;
  businessId: string;
  paymentType: string;
  transactionType: string; //send, reload, credit, debit
  updatedAt: any;
  userId: string;
}

export interface TabWithStaff {
  tab: TangoTab;
  staffMember: StaffMember;
}

export const fetchTabWithStaff = async (businessId: string, tabId: string) => {
  const res = await paymentsApiClient.get<TabWithStaff>(`${businessId}/tab/${tabId}`);
  return res.data;
};

export const fetchCompletedTabs = async (businessId: string, lastCompletedTabId: string) => {
  if (!businessId) return null;
  const res = await paymentsApiClient.get<TabWithStaff[]>(`${businessId}/${lastCompletedTabId}`);
  return res.data;
};

export const useCompletedTabs = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['completedTabsInfo', lastCompletedTabId],
    queryFn: () => fetchCompletedTabs(businessId, lastCompletedTabId),
    keepPreviousData: true, //this keeps old data in table until new data is returned
    retry: 2,
  });
};

export const fetchCompletedTabsAndGiftCardPurchases = async (businessId: string, lastCompletedTabId: string) => {
  console.log('fetchCompletedTabsAndGiftCardPurchases', businessId, lastCompletedTabId);
  if (!businessId) return null;
  const res = await paymentsApiClient.get<TabWithStaff[]>(`${businessId}/${lastCompletedTabId}/giftCards`);
  return res.data;
};

export const useCompletedTabsAndGiftCardPurchases = (businessId: string, lastCompletedTabId: string) => {
  return useQuery({
    queryKey: ['paymentsAndGiftCardPurchasesInfo', businessId, lastCompletedTabId],
    queryFn: () => fetchCompletedTabsAndGiftCardPurchases(businessId, lastCompletedTabId),
    keepPreviousData: true,
    retry: 2,
  });
};

export const fetchStaffMember = async (businessId: string, staffId: string) => {
  const res = await paymentsApiClient.get<StaffMember>(`${businessId}/staffMember/${staffId}`);
  return res.data;
};

export const useStaffMember = (businessId: string, staffId: string) => {
  return useQuery(['staffInfo', businessId, staffId], () => fetchStaffMember(businessId, staffId));
};

export const createRefund = async (businessId: string, country: string, tabId: string, refundData: RefundPaymentDTO) => {
  try {
    const success = await paymentsApiClient.post(`${businessId}/${country}/${tabId}/refundPayment`, refundData);
    return success.data;
  } catch (error: any) {
    console.log('error refund', error.response.data);
    alert(error.response.data.message);
    return false;
  }
};
