import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setupRoutes,
  recipesRoute,
  inventoryRoute,
} from "./routes";


const { Icon } = UIComponents;
export type SidebarRoute = {
  label: string;
  link: string;
  subRoutes: { label: string; link: string }[];
  isExpanded: boolean;
};

type Props = {
  routes: SidebarRoute[];
};
const Sidebar = ({ routes }: Props) => {
  const [menuItems, setMenuItems] = useState([...routes]);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  console.log(location);
  const currPath = pathname + search;
  return (
    <ul className={"p-0 m-0 list-none mt-6"}>
      <li className="font-lato-medium text-grey-2 py-2 pl-2 pr-1 text-xs">SETUP</li>
      {setupRoutes.map((item, index) => {
        const hasSubRoutes = item.subRoutes.length > 0;
        const selected = item.link == currPath || item.subRoutes?.some(sr => sr.link == currPath)
        return (
          <>
            <li
              key={index}
              className={`py-2 pl-5 pr-1 ${selected
                ? "bg-blue-grey-2 font-lato-bold text-black"
                : "font-lato-medium text-grey-3"
                } text-xs hover:bg-blue-grey-2 cursor-pointer`}
              onClick={() => {
                if (hasSubRoutes) {
                  if (item.isExpanded) {
                    navigate(item.link);
                  }
                  const updatedRoutes = [...menuItems];
                  updatedRoutes[index]["isExpanded"] =
                    !updatedRoutes[index]["isExpanded"];
                  setMenuItems(updatedRoutes);
                } else {
                  navigate(item.link);
                }
              }}
            >
              <div className={"flex items-center justify-between"}>
                {item.label}
                {hasSubRoutes ? (
                  <Icon
                    name={`${item.isExpanded ? "chevron-down" : "chevron-right"
                      }`}
                    size={"12"}
                  />
                ) : null}
              </div>
            </li>
            {hasSubRoutes && item.isExpanded
              ? item.subRoutes.map((subRoute: any) => {
                return (
                  <li
                    key={subRoute.link}
                    className={`py-2 pl-9 ${pathname + search === subRoute.link
                      ? "font-lato-bold text-black"
                      : "font-lato-medium text-grey-3"
                      } text-tiny hover:bg-blue-grey-2 cursor-pointer`}
                    onClick={() => navigate(subRoute.link)}
                  >
                    {subRoute.label}
                  </li>
                );
              })
              : null}
          </>
        );
      })}
      <div className="w-full border-b border-t border-solid border-grey-1" />
      <li className="font-lato-medium text-grey-2 py-2 pl-2 pr-1 text-xs">RECIPES</li>
      {recipesRoute.map((item, index) => {
        const hasSubRoutes = item.subRoutes.length > 0;
        const selected = item.link == currPath || item.subRoutes?.some(sr => sr.link == currPath)
        return (
          <>
            <li
              key={index}
              className={`py-2 pl-5 pr-1 ${selected
                ? "bg-blue-grey-2 font-lato-bold text-black"
                : "font-lato-medium text-grey-3"
                } text-xs hover:bg-blue-grey-2 cursor-pointer`}
              onClick={() => {
                if (hasSubRoutes) {
                  if (item.isExpanded) {
                    navigate(item.link);
                  }
                  const updatedRoutes = [...menuItems];
                  updatedRoutes[index]["isExpanded"] =
                    !updatedRoutes[index]["isExpanded"];
                  setMenuItems(updatedRoutes);
                } else {
                  navigate(item.link);
                }
              }}
            >
              <div className={"flex items-center justify-between"}>
                {item.label}
                {hasSubRoutes ? (
                  <Icon
                    name={`${item.isExpanded ? "chevron-down" : "chevron-right"
                      }`}
                    size={"12"}
                  />
                ) : null}
              </div>
            </li>
            {hasSubRoutes && item.isExpanded
              ? item.subRoutes.map((subRoute: any) => {
                return (
                  <li
                    key={subRoute.link}
                    className={`py-2 pl-9 ${pathname + search === subRoute.link
                      ? "font-lato-bold text-black"
                      : "font-lato-medium text-grey-3"
                      } text-tiny hover:bg-blue-grey-2 cursor-pointer`}
                    onClick={() => navigate(subRoute.link)}
                  >
                    {subRoute.label}
                  </li>
                );
              })
              : null}
          </>
        );
      })}
      <div className="w-full border-b border-t border-solid border-grey-1" />
      <li className="font-lato-medium text-grey-2 py-2 pl-2 pr-1 text-xs">INVENTORY</li>
      {inventoryRoute.map((item, index) => {
        const hasSubRoutes = item.subRoutes.length > 0;
        const selected = item.link == currPath || item.subRoutes?.some(sr => sr.link == currPath)
        return (
          <>
            <li
              key={index}
              className={`py-2 pl-5 pr-1 ${selected
                ? "bg-blue-grey-2 font-lato-bold text-black"
                : "font-lato-medium text-grey-3"
                } text-xs hover:bg-blue-grey-2 cursor-pointer`}
              onClick={() => {
                if (hasSubRoutes) {
                  if (item.isExpanded) {
                    navigate(item.link);
                  }
                  const updatedRoutes = [...menuItems];
                  updatedRoutes[index]["isExpanded"] =
                    !updatedRoutes[index]["isExpanded"];
                  setMenuItems(updatedRoutes);
                } else {
                  navigate(item.link);
                }
              }}
            >
              <div className={"flex items-center justify-between"}>
                {item.label}
                {hasSubRoutes ? (
                  <Icon
                    name={`${item.isExpanded ? "chevron-down" : "chevron-right"
                      }`}
                    size={"12"}
                  />
                ) : null}
              </div>
            </li>
            {hasSubRoutes && item.isExpanded
              ? item.subRoutes.map((subRoute: any) => {
                return (
                  <li
                    key={subRoute.link}
                    className={`py-2 pl-9 ${pathname + search === subRoute.link
                      ? "font-lato-bold text-black"
                      : "font-lato-medium text-grey-3"
                      } text-tiny hover:bg-blue-grey-2 cursor-pointer`}
                    onClick={() => navigate(subRoute.link)}
                  >
                    {subRoute.label}
                  </li>
                );
              })
              : null}
          </>
        );
      })}
    </ul>
  );
};
export default Sidebar;
