import { Button, Dialog, EditableText, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import { updateBusinessName, updatePhone } from "../../../models/businesses";
import Layout from "../Layout";
import "./index.css";
import Timeslots from "./timeslots";

const BusinessInfo = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businessPhone =
    business &&
    business.phones.filter((phone: any) => phone.name === "default")[0].number;
  const [openAddPhone, setOpenAddPhone] = useState(false);
  const [phoneInput, setPhoneInput] = useState("");

  const [businessNameInput, setBusinessNameInput] = useState("");

  const savePhone = async () => {
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (
      !phoneInput ||
      !parseInt(phoneInput) ||
      parseFloat(phoneInput) < 0 ||
      !phoneInput.match(phoneno)
    ) {
      console.log("no phone input");
      return setPhoneInput("");
    }
    //map over busines phone
    business.phones.map((phone: any) => {
      if (phone.type === "default") {
        phone.number = phoneInput;
      }
    });
    const success = await updatePhone(business.id, business.phones);
    if (success) {
      console.log("success");
    } else {
      alert("There was a problem updating your phone number.");
    }
  };

  const saveBusinessName = async () => {
    if (!businessNameInput) {
      return setBusinessNameInput("");
    }

    const success = await updateBusinessName(business.id, businessNameInput);
    if (success) {
      console.log("success");
    } else {
      alert("There was a problem updating your business name.");
    }
  };

  if (business) {
    return (
      <Layout>
        <div id="business-info">
          <div className="section-name">Business Info</div>
          <div className="section-body">
            <div className="subtitle">General Information</div>
            <div className="section-row">
              <div className="field-title">Business Name</div>
              {/* <div className="field-value">{business.businessName}</div> */}
              <div className="field-value">
                <EditableText
                  className="editableInput"
                  defaultValue={business.businessName} //starting value
                  value={
                    businessNameInput
                      ? businessNameInput
                      : business.businessName
                  }
                  onChange={(e) => setBusinessNameInput(e)}
                  onConfirm={() => {
                    saveBusinessName();
                  }} //on click away this fires
                />
              </div>
            </div>
            <div className="section-row">
              <div className="field-title">Business Address</div>
              <div className="field-value">{business.location.address}</div>
            </div>
            <div className="section-row">
              <div className="field-title">Business Phone</div>
              <div className="field-value">
                {business && businessPhone ? (
                  <EditableText
                    className="editableInput"
                    maxLength={10}
                    defaultValue={businessPhone} //starting value
                    value={phoneInput ? phoneInput : businessPhone}
                    onChange={(e) => setPhoneInput(e)}
                    onConfirm={() => {
                      savePhone();
                    }} //on click away this fires
                  />
                ) : (
                  <span
                    onClick={() => setOpenAddPhone(true)}
                    style={{ cursor: "pointer", color: "#55AAD7" }}
                  >
                    Add phone
                  </span>
                )}
              </div>
            </div>

            {openAddPhone && (
              <Dialog
                isOpen={openAddPhone}
                icon="phone"
                onClose={() => setOpenAddPhone(false)}
                title="Add a phone number"
                style={{ width: "50%" }}
              >
                <div style={{ padding: "10px", textAlign: "center" }}>
                  <br />
                  <InputGroup
                    onChange={(e) => setPhoneInput(e.target.value)}
                    value={phoneInput}
                    placeholder="2225559999"
                    style={{ width: "50%" }}
                  ></InputGroup>
                  <br />
                  <Button
                    onClick={() => savePhone()}
                    minimal
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      width: "50%",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Dialog>
            )}
            <div className="subtitle">General Information</div>

            <table>
              <tr>
                <th></th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
              </tr>
              <tr>
                <td>Open Time</td>
                {business.hours.map((hour: any) => {
                  if (hour.day === "monday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "monday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "tuesday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "tuesday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "wednesday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "wednesday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "thursday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "thursday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "friday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "friday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "saturday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "saturday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "sunday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.openTime}
                          day={hour.day}
                          type={"open"}
                        />
                      </td>
                    );
                  } else if (hour.day === "sunday" && !hour.open) {
                    return <td>set open time</td>;
                  }
                })}
              </tr>

              <tr>
                <td>Close Time</td>
                {business.hours.map((hour: any) => {
                  if (hour.day === "monday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "monday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "tuesday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "tuesday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "wednesday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "wednesday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "thursday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "thursday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "friday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "friday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "saturday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "saturday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
                {business.hours.map((hour: any) => {
                  if (hour.day === "sunday" && hour.open) {
                    return (
                      <td>
                        <Timeslots
                          defaultTime={hour.closeTime}
                          day={hour.day}
                          type={"close"}
                        />
                      </td>
                    );
                  } else if (hour.day === "sunday" && !hour.open) {
                    return <td>set close time</td>;
                  }
                })}
              </tr>
            </table>
          </div>
        </div>
      </Layout>
    );
  }

  return null;
};

export default BusinessInfo;
