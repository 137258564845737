import ActionType from "../actions/actionTypes";

const initialState: StaffingRedux = { currentSchedule: null };

export default function staffing(
  state: StaffingRedux = initialState,
  action: any
) {
  switch (action.type) {
    case ActionType.SET_CURRENT_SCHEDULE:
      return { ...state, currentSchedule: action.payload };
    case ActionType.UPDATE_DUPLICATE_SCHEDULE_TO_DRAFT:
      return {
        ...state,
        currentSchedule: {
          ...state.currentSchedule,
          scheduleId: action.scheduleId,
          scheduleType: "draft",
          optionalPrecomposedDuplicateSchedule: null,
        },
      };
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
