import { Button, Icon, InputGroup } from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import { Popover2 } from "@blueprintjs/popover2";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { EditTicketData } from "models/boards";

import { RootState } from "model/store";

import ProfilePic from "assets/ProfilePic.png";
import placeholderPic from "assets/ToDo/AssignToTicketIcon.svg";
import search from "assets/ToDo/SearchIcon.svg";
import blueFlagIcon from "assets/ToDo/blueFlag.svg";
import calenderIcon from "assets/ToDo/calender.svg";
import grayFlagIcon from "assets/ToDo/grayFlag.svg";
import redFlagIcon from "assets/ToDo/redFlag.svg";
import yellowFlagIcon from "assets/ToDo/yellowFlag.svg";

import ExpandTicket from "./Modal/expandTicket";
import { FormField } from "./Modal/ticketPropertiesModal";

interface Props {
  data: any;
  setSelectedTicketData: any;
  closeExpandTicketModal: () => void;
  selectedTicketData: any;
  selectedBoard: any;
  formResponsesData: any;
  formData: any | null;
}

const StatusTicket = ({
  data,
  setSelectedTicketData,
  closeExpandTicketModal,
  selectedTicketData,
  selectedBoard,
  formResponsesData,
  formData,
}: Props) => {
  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const [dateSelect, setDateSelect]: any = useState(new Date());
  const [ticketName, setTicketName] = useState(data?.Title || "");
  const [selectedColor, setSelectedColor] = useState("#333333");
  const [expandTicket, setExpandTicket] = useState(false);
  const [ticketCustomProperties, setTicketCustomProperties]: any = useState({});
  const [priority, setPriority]: any = useState({});
  const [editbtn, setEditbtn] = useState(
    selectedBoard.formId ? false : data?.Title === ""
  );
  const [assignedMember, setAssignedMember]: any[] = useState([]);
  const [responseData, setResponseData] = useState<any | null>(null);
  const [isExpandTicketModalOpen, setIsExpandTicketModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (data.formResponseId) {
      const singleResponseData = formResponsesData.find(
        (item: any) => item.id === data.formResponseId
      );
      setResponseData(singleResponseData);
    }
  }, [formResponsesData]);
  useEffect(() => {
    if (responseData) {
      const titleEnabledFieldIndex = selectedBoard.formFields.findIndex(
        (item: FormField) => item.isTitle
      );
      setTicketName(responseData.response[titleEnabledFieldIndex].value);
    }
  }, [responseData]);
  const priorities = [
    { label: "Low", color: "color-blue", Icon: blueFlagIcon },
    { label: "Mid", color: "color-yellow", Icon: yellowFlagIcon },
    { label: "High", color: "color-red", Icon: redFlagIcon },
  ];

  const toggleExpandTicket = () => {
    setExpandTicket(!expandTicket);
  };

  const handleCustomPropertyValChange = (key: string, value: string) => {
    setTicketCustomProperties({
      ...ticketCustomProperties,
      [key]: {
        ...ticketCustomProperties[key],
        value,
      },
    });
  };

  const validateTicketPropertyButton = () => {
    let name = ticketName;
    if (name.length > 2) {
      return false;
    } else {
      return true;
    }
  };

  const assignedFirstMember = useMemo(() => {
    if (assignedMember?.length > 0) {
      return assignedMember[0];
    }
    return {};
  }, [assignedMember]);

  const toggleAssignedMember = (item: any) => {
    if (assignedMember.length >= 0) {
      let index = assignedMember?.findIndex(
        (member: any) => member.id === item.staffMemberCopyId
      );
      if (index != -1) {
        let members = assignedMember.map((item: any) => item);
        members.splice(index, 1);
        setAssignedMember(members);
      } else if (index == -1) {
        const payload = {
          id: item?.staffMemberCopyId,
          contact: item.contact,
          imageUrl: item.imageUrl,
          primaryRole: item.primaryRole,
        };
        setAssignedMember([...assignedMember, payload]);
      }
    }
  };

  const showSaveButton = () => {
    if (data && data.id) {
      if (data.Title != ticketName) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (data && data?.customProperties?.length > 0) {
      let obj: any = {};
      data.customProperties.forEach((element: any) => {
        obj[element.name] = element;
      });
      setTicketCustomProperties(obj);
    }

    if (data && data.fixedProperties) {
      let fixedProperties = data.fixedProperties;
      if (
        fixedProperties?.Ticketduedate != null &&
        fixedProperties?.Ticketduedate.hasOwnProperty("value") == false
      ) {
        setDateSelect(fixedProperties?.Ticketduedate.toDate());
        console.log("date", fixedProperties?.Ticketduedate);
        console.log("date", fixedProperties?.Ticketduedate.toDate());
      }
      let index = priorities.findIndex(
        (item: any) => item.label == data.fixedProperties.Ticketpriority
      );
      if (index != -1) {
        setPriority(priorities[index]);
      }
      if (data?.fixedProperties?.Assignpeopletoticket) {
        setAssignedMember(data?.fixedProperties?.Assignpeopletoticket);
      }
    }

    console.log("data", data);
  }, [data]);

  const renderProfilePic = () => {
    if (assignedMember?.length > 0) {
      if (assignedFirstMember?.imgUrl != null) {
        return (
          <img
            src={assignedFirstMember?.imgUrl}
            className="more-icon"
            height="24px"
            width="24px"
          />
        );
      } else {
        return (
          <div className="user-avatar">
            {assignedFirstMember?.contact?.firstName?.charAt(0)?.toUpperCase()}
            {assignedFirstMember?.contact?.lastName?.charAt(0)?.toUpperCase()}
          </div>
        );
      }
    } else {
      return (
        <img
          src={placeholderPic}
          className="more-icon"
          height="24px"
          width="24px"
        />
      );
    }
  };

  return (
    <div className={`${editbtn ? "card-header-border" : ""}`}>
      <Box
        className="card-header"
        display="flex"
        flex-direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          if (!editbtn) {
            setSelectedTicketData(data);
            setIsExpandTicketModalOpen(true);
          }
        }}
      >
        <div className="card-title">
          <InputGroup
            autoFocus
            placeholder="Create Hiring Board"
            className="input-card-title"
            disabled={!editbtn}
            value={ticketName}
            onChange={(e) => {
              if (editbtn) {
                setTicketName(e.target.value);
              }
            }}
          />
        </div>

        <Popover2
          interactionKind="click"
          disabled={!editbtn}
          // popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
          placement="bottom"
          minimal={true}
          content={
            <div className="pic-selection-container">
              <div className="title-assign p1">Assign to</div>
              <div className="searchInput">
                {/* <Icon icon="search" className="icon-search" /> */}
                <img src={search} className="icon-search" />
                <input type="text" className="bp4-input" placeholder="Search" />
              </div>
              <div className="profile-result">
                {fellowStaffMembers?.length > 0 &&
                  fellowStaffMembers?.map((item: StaffMember) => {
                    let checkMemberFunction = () => {
                      if (
                        assignedMember?.length > 0 &&
                        assignedMember?.some(
                          (member: any) => member.id === item.staffMemberCopyId
                        )
                      ) {
                        return true;
                      }
                      return false;
                    };
                    let memberCheck = checkMemberFunction();
                    return (
                      <div
                        className={` select-profile ${
                          memberCheck ? "profile-selected" : ""
                        } `}
                        onClick={(e) => {
                          if (data?.id && item) {
                            toggleAssignedMember(item);
                          }
                        }}
                      >
                        <img src={ProfilePic} className="profilepic" />
                        <label>
                          {item?.contact?.firstName} {item?.contact?.lastName}
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          }
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <div {...targetProps} ref={ref}>
              {renderProfilePic()}
            </div>
          )}
        />
      </Box>
      <div className="card-footer">
        <div className="alignment">
          <Box className="footer-icon-div">
            <Popover2
              interactionKind="click"
              // popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              placement="bottom"
              content={
                <div>
                  <div className="date-title">
                    <label>Due Date</label>
                  </div>
                  <DatePicker
                    shortcuts={false}
                    value={dateSelect}
                    onChange={(selectedDate: Date) =>
                      setDateSelect(selectedDate)
                    }
                    highlightCurrentDay={true}
                  />
                </div>
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref} style={{ display: "flex" }}>
                  <img
                    src={calenderIcon}
                    style={{ height: "13px", width: "12px" }}
                  />
                  <span className="color-grey">
                    {" "}
                    {(dateSelect != null || dateSelect != "") &&
                      moment(dateSelect).format("MMM DD").toString()}
                  </span>
                </div>
              )}
            />
          </Box>
          <Box className="footer-icon-div">
            <Popover2
              interactionKind="click"
              // popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
              placement="bottom"
              minimal={true}
              content={
                <div className="pic-selection-container">
                  <div className="title-assign p2">Priority</div>
                  <div className="priority-result">
                    {priorities.map((item: any) => (
                      <div
                        className="select-priority"
                        onClick={() => setPriority(item)}
                      >
                        <img src={item.Icon} className="profilepic" />
                        <label className={item.color}>{item.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
              }
              renderTarget={({ isOpen, ref, ...targetProps }) => (
                <div {...targetProps} ref={ref} style={{ display: "flex" }}>
                  <img
                    src={grayFlagIcon}
                    style={{ height: "13px", width: "12px" }}
                  />
                </div>
              )}
            />
            <span className={priority.color} style={{ fontSize: "10px" }}>
              {priority.label || "none"}
            </span>
          </Box>
        </div>
        {editbtn === false ? (
          <>
            <div className="color-blue" onClick={() => setEditbtn(true)}>
              edit
            </div>
          </>
        ) : (
          <>
            <div>
              <Button
                className={`${
                  validateTicketPropertyButton()
                    ? "disable-save-btn"
                    : "save-btn"
                }`}
                disabled={validateTicketPropertyButton()}
                onClick={() => {
                  // EditTicketProperty(ticketName, data?.id);
                  EditTicketData(
                    ticketName,
                    data?.id,
                    dateSelect,
                    priority.label,
                    data.fixedProperties,
                    ticketCustomProperties,
                    assignedMember
                  );

                  setEditbtn(false);
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
      {selectedBoard.formId
        ? selectedBoard.formFields.map((item: FormField, index: number) => {
            if (item.enabled && !item.isTitle) {
              return (
                <div
                  className="second-bottom-content"
                  onClick={() => {
                    if (!editbtn) {
                      setSelectedTicketData(data);
                      setIsExpandTicketModalOpen(true);
                    }
                  }}
                >
                  <label className="bottom-title">{item.name}</label>
                  <br />
                  <div className="content-area">
                    {responseData?.response[index].value}
                  </div>
                </div>
              );
            }
          })
        : data?.customProperties?.length > 0
        ? data.customProperties.map((item: any, key: number) => {
            return (
              <div
                className="second-bottom-content"
                onClick={() => {
                  if (!editbtn) {
                    setSelectedTicketData(data);
                    setIsExpandTicketModalOpen(true);
                  }
                }}
              >
                {item?.enable == false ? (
                  <>
                    <div className="title-div">
                      <Icon icon="chevron-right" size={8} />
                      <label className="bottom-title">{item.name}</label>
                    </div>
                  </>
                ) : (
                  <>
                    <label className="bottom-title">{item.name}</label>
                    <br />
                    <div className="content-area">{item.value}</div>
                  </>
                )}
              </div>
            );
          })
        : null}

      {/* <div className="location-content">
        <label>
          <Icon icon="chevron-right" iconSize={10} />
          Location
        </label>
      </div> */}

      <ExpandTicket
        isOpen={isExpandTicketModalOpen}
        closeModal={() => setIsExpandTicketModalOpen(false)}
        selectedTicketData={selectedTicketData}
        selectedBoard={selectedBoard}
        ticketTitle={ticketName}
        formResponseData={responseData}
      />
    </div>
  );
};

export default StatusTicket;
