import tangoComponents from "@tangopay/tango-ui-library";
import React, { useCallback } from "react";

import CurrencyInput from "components/Inputs/CurrencyInput";

const { Dropdown, Checkbox, Icon } = tangoComponents;

export type DataPoint = string | number | Object | boolean | null;
type CustomEditProps = {
  onChange: (val: DataPoint) => unknown;
  value: DataPoint;
};
type CustomViewProps = {
  value: DataPoint;
};
type ComplexCustomEditProps<T> = {
  onChange?: (fullObject: T) => unknown;
  fullObject: T;
};
type ComplexCustomViewProps<T> = {
  fullObject: T;
};

type DefaultInstruction = {
  type: "default";
};

type SelectorInstruction = {
  type: "select";
  options: { value: string; label: string }[];
  placeholder: string;
  disableEditing?: boolean;
};
type NumericInstruction = {
  type: "number";
  disableEditing?: boolean;
};
type CurrencyInstruction = {
  type: "currency";
  currency?: string;
  disableEditing?: boolean;
};
type PercentageInstruction = {
  type: "percentage";
};
type NumericDateInstruction = {
  type: "numericDate";
};
type BooleanInstruction = {
  type: "boolean";
  useCheckbox?: boolean;
  trueText?: string;
  falseText?: string;
  disableEditing?: boolean;
  useStar?: boolean;
};
type CustomInstruction = {
  type: "custom";
  viewComponent: null | React.ComponentType<CustomViewProps>;
  editComponent: null | React.ComponentType<CustomEditProps>;
  disableEditing?: boolean;
};
type ReadonlyInstruction = {
  readOnly?: boolean;
  editablePrefix?: string;
};
export type SimpleRenderInstruction = ReadonlyInstruction &
  (
    | DefaultInstruction
    | SelectorInstruction
    | PercentageInstruction
    | NumericInstruction
    | NumericDateInstruction
    | CurrencyInstruction
    | BooleanInstruction
    | CustomInstruction
  );

type ComplexCustomInstruction<T, ActionPopupType = {}> = ReadonlyInstruction & {
  type: "complex-custom";
  viewComponent: null | React.ComponentType<ComplexCustomViewProps<T>>;
  editComponent: null | React.ComponentType<ComplexCustomEditProps<T>>;
  disableEditing?: boolean;

  actionPopup?: React.ComponentType<
    ComplexCustomViewProps<T> & {
      onSelect: ((fullObject: T, event: ActionPopupType) => unknown) | null;
    }
  >;
};

export type RenderInstruction<T, ActionPopupType = {}> =
  | SimpleRenderInstruction
  | ComplexCustomInstruction<T, ActionPopupType>;

type Props<T, ActionTypes = {}> = {
  idx: number;
  editing: boolean;
  onChange: (idx: number, value: DataPoint) => unknown;
  instruction: RenderInstruction<T, ActionTypes>;
  value: DataPoint | null;
  fullObject: T | null;
  onChangeFullObject?: (newValue: T) => unknown;
};
const TableCell = <T extends Object, ActionTypes = {}>(
  props: Props<T, ActionTypes>
) => {
  const { instruction, editing, value, fullObject } = props;
  const eventHandler = useCallback(
    (e) => props.onChange(props.idx, e.target.value),
    [props.idx, props.onChange]
  );
  const numericEventHandler = useCallback(
    (e) => props.onChange(props.idx, Number(e.target.value)),
    [props.idx, props.onChange]
  );
  const boolHandler = useCallback(
    (e) => props.onChange(props.idx, e.target.checked),
    [props.idx, props.onChange]
  );
  const starHandler = useCallback(
    (status: boolean) => {
      console.log(status);
      props.onChange(props.idx, status);
    },
    [props.idx, props.onChange]
  );
  const rawHandler = useCallback(
    (value) => props.onChange(props.idx, value),
    [props.idx, props.onChange]
  );
  const dropdownHandler = useCallback(
    (selected) => {
      if (!selected) {
        props.onChange(props.idx, null);
        return;
      }
      props.onChange(props.idx, selected.value ?? null);
    },
    [props.idx, props.onChange]
  );

  let content = <></>;
  if (
    !instruction ||
    instruction.type == "default" ||
    (instruction.type == "custom" &&
      ((editing && !instruction.disableEditing && !instruction.editComponent) ||
        (!editing && !instruction.viewComponent)))
  ) {
    content = editing ? (
      <input
        type="text"
        value={(value as string) ?? undefined}
        onChange={eventHandler}
        className={"border-0 text-grey-3"}
      />
    ) : (
      <div>{value}</div>
    );
  } else if (instruction.type == "number") {
    content = editing ? (
      <input
        type="number"
        value={(value as string) ?? undefined}
        onChange={numericEventHandler}
        className={"border-0 text-grey-3"}
      />
    ) : (
      <div>{value}</div>
    );
  } else if (instruction.type == "numericDate") {
    const val = value?.toString() ?? "YYYYMMDD";
    const day = val.substring(6, 8);
    const month = val.substring(4, 6);
    const year = val.substring(0, 4);
    content = <div>{`${month}/${day}/${year}`}</div>
  } else if (instruction.type == "select") {
    if (editing) {
      content = (
        <Dropdown
          options={instruction.options}
          value={instruction.options.find((opt) => opt.value == value)}
          onChange={dropdownHandler}
          size="small"
        />
      );
    } else {
      const sel = instruction.options.find((opt) => opt.value == value);
      content = <div>{sel?.label ?? instruction.placeholder}</div>;
    }
  } else if (instruction.type == "currency") {
    if (editing) {
      content = (
        <CurrencyInput value={value as number} onValueChange={rawHandler} />
      );
    } else {
      const dollars = (value as number) / 100;
      content = (
        <div>
          {dollars.toLocaleString("en-US", {
            style: "currency",
            currency: instruction.currency ?? "USD",
          })}
        </div>
      );
    }
  } else if (instruction.type == "percentage") {
    if (editing) {
      <input
        type="number"
        value={(value as string) ?? undefined}
        onChange={numericEventHandler}
        className={"border-0 text-grey-3"}
      />;
    } else {
      if (Number.isFinite(value)) {
        return <div>{(value as number).toFixed(1)}%</div>;
      } else {
        return <div>-</div>;
      }
    }
  } else if (instruction.type == "boolean") {
    // if star, always star
    if (instruction.useStar) {
      if (props.value) {
        content = (
          <Icon
            name="star-filled"
            size="16"
            color="#FAD861"
            className="cursor-pointer"
            onClick={() => {
              starHandler(false);
            }}
          />
        );
      } else {
        content = (
          <Icon
            name="star-outline"
            size="16"
            className="cursor-pointer"
            onClick={() => {
              starHandler(true);
            }}
          />
        );
      }
    } else if (instruction.useCheckbox) {
      content = (
        <Checkbox
          disabled={!editing}
          onChange={boolHandler}
          type="small"
          checked={!!value}
        />
      );
    } else if (editing) {
      // edit defaults to checkbox
      content = (
        <Checkbox onChange={boolHandler} type="small" checked={!!value} />
      );
    } else {
      // non editing default
      content = <div>{value
        ? instruction.trueText ?? "YES"
        : instruction.falseText ?? "NO"
      }</div>;
    }
  } else if (instruction.type == "custom") {
    if (editing && !instruction.disableEditing) {
      const CustomComponent = instruction.editComponent;
      if (CustomComponent) {
        content = <CustomComponent value={value} onChange={rawHandler} />;
      }
    } else {
      const CustomComponent = instruction.viewComponent;
      if (CustomComponent) {
        content = <CustomComponent value={value} />;
      }
    }
  } else if (instruction.type == "complex-custom") {
    if (editing) {
      const CustomComponent = instruction.editComponent;
      if (CustomComponent) {
        content = (
          <CustomComponent
            onChange={props.onChangeFullObject}
            fullObject={fullObject as T}
          />
        );
      }
    } else {
      const CustomComponent = instruction.viewComponent;
      if (CustomComponent) {
        content = <CustomComponent fullObject={fullObject as T} />;
      }
    }
  }

  return (
    <div className="generic-table-cell generic-table-data-cell">{content}</div>
  );
};

export default TableCell;
