import { FirebaseDiscountDoc } from "views/admin/menus&Products/discounts/discountTypes";

import { DiscountsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseDiscountDoc[] = [];

export default function discounts(
  state = initialState,
  action: DiscountsAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_DISCOUNTS:
      return action.discounts;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
