import { SidebarRoute } from "components/Sidebar";

export const getSidebarRoutes = (id: string): SidebarRoute[] => {
  return [
    {
      label: "Responses",
      link: `/form/${id}/responses`,
      subRoutes: [],
      isExpanded: false,
    },
    {
      label: "Preview",
      link: `/form/${id}/preview`,
      subRoutes: [],
      isExpanded: false,
    },
    {
      label: "Edit Form",
      link: `/form/${id}/edit`,
      subRoutes: [],
      isExpanded: false,
    },
  ];
};
