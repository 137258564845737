import ActionType from "./actionTypes";

export function receiveUser(user: StaffMember) {
  return {
    type: ActionType.RECEIVE_USER,
    user,
  };
}

export function logoutUser() {
  return {
    type: ActionType.LOG_OUT,
  };
}
