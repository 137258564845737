/**
 * Deterministically convert a string to a number
 * @param input The string to be hashed
 * @param radix output will be between 0 and radix - 1
 * @returns a number based on the string
 */
export const hashString = (input: string, radix: number, init = 0) => {
  if (!input) return init;
  const hash = input
    .split("")
    .map((char) => char.charCodeAt(0))
    .reduce((acc, curr) => acc + curr, init);
  if (radix) return hash % radix;
  return hash;
};

export const selectColor = (input: string, init = 0) => {
  return ["#faf7e0", "#faeddf", "#fdf2f9", "#f4f4fe", "#ecf5f8", "#f6fef1"][
    hashString(input, 6, init)
  ];
};
export const getInitials = (name: string) => {
  const firstLetter = name.charAt(0);
  const firstSpaceIdx = name.indexOf(" ");
  if (firstSpaceIdx == -1 || firstSpaceIdx + 1 == name.length)
    return firstLetter.toUpperCase();
  const secondLetter = name.charAt(firstSpaceIdx + 1);
  return (firstLetter + secondLetter).toUpperCase();
};
