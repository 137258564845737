import React from "react";
import ReactFlow, {
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";

import RoleCard from "../RoleCard";
import "../index.css";

const EmployeeData = {
  id: "1",
  firstName: "Joi",
  lastName: "Parker",
  url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI0AAACNCAMAAAC9gAmXAAAAMFBMVEXk5ueutLeyuLvp6+yrsbTh4+TS1dfKztCnrrHY29zb3t/P0tS1u76/w8bBxsjHy81ElUJxAAADL0lEQVR4nO2a227DIAxAy7VAgPz/347Srk2XLmAnJn3wkSatb0fGXGL7cmEYhmEYhmEYhmEYpok6W+CJCXaaJuuNOVlEGZujcLriRJytOi9KPhUJsaD8yuEUFeXju8pDSGQzPD7KRLdWufu4eXQGTeJDXJ4+cexy5Q2X6mPHrZaJDZmik4fptGWKznWQTo+MEC6PcFGpS6ZEZxogc+2UKTr0Oyt0ywghyc+d2C9DvrHUDAhNyWTitQLJCJFIZVpn8F+0J5QxEiYjRKTLHDUBQ1OgyxwFDo3QM5lNAMuUpSKz6T+GX9Bt8t4bagndXQ53KSQiG/PfS3gTqsSxKBuiqxPwlniD5jhW0GvhjrY0NgkVGrbZsKF5Hn9ZbPJX2cBeoU+IHlyI101BEl2b/pvO4i+7p0DfUr/QfVNhrgZHs6UKFvPaopK5GLgM4fddd7FkERrCsomHLxWdDHxX6ZmySgHOY9IKDvBrk/BLswLMHOK6KGhbET0mlvSvlR5Qo+0vDdAVBF70F3HGtD6mvpdFGFPa7/roJK34vdOMjpaDIlPxcjM8Oo3t3pm0ER53HepyqU3Wzz66BOaMNrSV66avFnFc+v71ycIthLST88jsXROuKUZZiDFNZ88ulARSF3Oj/HNqWFQVCd5bW/68DyGYs5SCfazRbRvVeYq6Xmm2Q1vzSgU7p9tkyT873OmYJz8kSMbnWzS2TuKqVL6kLG1SK+WTdN2PPyfTRDe15GcNrVJolyyJkI/N9fnoo+Pxx5CVqOLNXUhcD7257PYDou0jDxsQUgHTl1r5HDORYzJ+jd584v4L9TYrdohMTZ+9NvMxgXn47AuP2Zm9a/DfwsofrCJ2NF0xkwEdOrhCII0MslaA7WJ26CB60kSRqTrQ6ChMoRqgA4sOZFYMowPbWfDRFqAO5O18wDXZANC1Ik2aO4CNRe4iANUm3IQClM4aJabvg6BvIBrR9kHSExrMqBiKrtYV2f20oidzqA++Fx3jbuHIp+c27VYadlgCRXupMB14NK2lgk/J7qDZv0J0dXfYtBJnwIW5oHV1jrmjfpHbNirLoTS3+FAaMgzDMAzzTfwA+XMmLtWzWB4AAAAASUVORK5CYII=",
  role: "CEO",
};

export interface TreeNodeProps {
  isDisplayTreeNode: boolean;
  setIsDisplayTreeNode: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialEdges = [
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-3", source: "2", target: "3" },
];

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <div>
          <RoleCard employee={EmployeeData} />
        </div>
      ),
    },
    position: { x: 250, y: 25 },
  },

  {
    id: "2",
    // you can also pass a React component as a label
    data: {
      label: (
        <div>
          <RoleCard employee={EmployeeData} />
        </div>
      ),
    },
    position: { x: 100, y: 125 },
  },
  {
    id: "3",
    type: "output",
    data: {
      label: (
        <div>
          <RoleCard employee={EmployeeData} />
        </div>
      ),
    },
    position: { x: 250, y: 250 },
  },
];

const SelectedTreeNode = ({
  isDisplayTreeNode,
  setIsDisplayTreeNode,
}: TreeNodeProps) => {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  return (
    <ReactFlowProvider>
      {isDisplayTreeNode && (
        <div className="selected-tree-wrapper">
          <div
            onClick={() => setIsDisplayTreeNode(false)}
            className="selected-tree-header"
          >
            <img src={require("assets/back_icon.svg").default}></img>
            <div className="back-text">Back</div>
          </div>
          <div className="selected-tree-content">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              fitView={false}
              className="transition"
            ></ReactFlow>
          </div>
        </div>
      )}
    </ReactFlowProvider>
  );
};

export default SelectedTreeNode;
