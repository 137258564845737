import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { baseUrl } from "./core";
import { generateBearerToken } from "./init";

type CountType = "tablet" | "sheet";
export type CountFrequency = "week" | "month" | "day";

export type ScheduledCount = {
  id: string;
  type: CountType;
  scheduledFor: string;
  lastCountId: string | null;
  rawItemsOnly: boolean;
  prepItemsOnly: boolean;
  keyItemsOnly: boolean;
  inProgressId: string;
  lastSubmitted: string;
  locations: {id: string; name: string}[];
}

export type CreateCountDTO = {
  type: CountType;
  locations: string[];
  frequency: CountFrequency;
  offsetDays: number;
  keyItemsOnly: boolean;
  rawItemsOnly: boolean;
  prepItemsOnly: boolean;
}

const apiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/inventory`,
});

apiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

const fetchCounts = async (businessId: string) => {
  if (!businessId) return null;
  const r = await apiClient.get<ScheduledCount[]>(`${businessId}/countSchedules`);
  return r.data;
};
const createCount = async (businessId: string, dto: CreateCountDTO) => {
  if (!businessId) return null;
  const r = await apiClient.post<ScheduledCount>(
    `${businessId}/countSchedules`,
    dto,
  );
  return r.data;
};

export const useInventoryInfo = (businessId: string) => {
  return useQuery(["scheduledCounts", businessId], () =>
    fetchCounts(businessId)
  );
};


export const useCreateCount = (businessId: string) => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (newValue: CreateCountDTO) =>
      createCount(businessId, newValue),
    onSuccess: (data) =>
      client.setQueryData(["scheduledCounts", businessId, data?.id], data),
  });
};
