import { ProductTypesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseDeliverectProductType[] = [];

export default function productTypes(
  state = initialState,
  action: ProductTypesAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_PRODUCT_TYPES:
      return action.productTypes;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
