import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  useCreateVendor,
  useDeleteVendor,
  useUpdateVendor,
  useVendors,
} from "controllers/vendors";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";

type VendorProps = {
  uniqueId: string;
  name: string;
  accountNumber: string;
  supplies: string;
};

const columns: ColumnInstruction<VendorProps>[] = [
  { type: "data", header: "Vendor Name", attribute: "name" },
  { type: "data", header: "Account Number", attribute: "accountNumber" },
  // TODO: backend doesn't report this information
  // { type: "data", header: "Supplies", attribute: "supplies" },
];

const instructions: { [x: string]: RenderInstruction<VendorProps> } = {};
const prefix = "new__vendor";
const useVendorsState = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditing, setEditing] = useState(false);
  useEffect(() => {
    if (!isEditing) updateItems(true);
  }, [isEditing]);
  // call update with false to push, true to clear
  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );
  const add = useCallback(() => {
    setEditing(true);
    updateItems(false);
  }, []);

  const query = useVendors(businessId);
  const update = useUpdateVendor(businessId);
  const create = useCreateVendor(businessId);
  const deleteVendor = useDeleteVendor(businessId);
  const vendorsFromServer = useMemo(() => {
    return (
      query.data?.map(({ vendor }) => ({
        uniqueId: vendor.id,
        name: vendor.name,
        accountNumber: vendor.accountNumber,
        supplies: "Stuff",
      })) ?? []
    );
  }, [query.data]);
  const addedVendors = useMemo(() => {
    return addedIds.map((uniqueId) => ({
      uniqueId,
      name: "",
      accountNumber: "",
      supplies: "Nothing",
    }));
  }, [addedIds]);
  const dataWithAdditions = useMemo(
    () => [...addedVendors, ...vendorsFromServer],
    [addedVendors, vendorsFromServer]
  );

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const toCreate = addedIds.map((id) => {
        const updates = instructions[id];
        return {
          name: (updates?.name?.newValue ?? "") as string,
          accountNumber: (updates?.accountNumber?.newValue ?? "") as string,

          contactName: "",
          contactPhone: "",
          contactEmail: "",
          contactAddress: "",
        };
      });
      return Promise.all([
        ...Object.entries(instructions)
          .filter(([id]) => !id.startsWith(prefix))
          .map(([vendorId, newValue]) => {
            const updateDTO = Object.keys(newValue).reduce(
              (acc, key) => ({
                ...acc,
                [key]: newValue[key].newValue,
              }),
              {}
            );
            return update.mutate({ vendorId, newValue: updateDTO });
          }),
        ...toCreate.map((el) => create.mutateAsync(el)),
      ]).then((result) => {
        if (toCreate.length === 1) {
          // we created a new vendor, return the new ID
          // if we created more than one, just ignore it
          const created = result[result.length - 1];
          return created?.id ?? null;
        }
        return null;
      });
    },
    [addedIds, create, update]
  );

  const deleteVendors = useCallback(
    async (ids: string[]) =>
      Promise.all(
        ids.map((vendorId) => deleteVendor.mutateAsync({ vendorId }))
      ),
    [deleteVendor]
  );

  return {
    dataWithAdditions,
    addItem: add,
    columns,
    instructions,
    isEditing,
    setEditing,
    saveChanges,
    deleteVendors,
  };
};
export default useVendorsState;
