import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { StaffMemberOrientedData } from "controllers/payroll";

import Box from "components/Box";
import HorizontalTable from "components/Table/HorizontalTable";

import AdArrow from "assets/manager/ascdecorder.svg";

import PayrollDetails from "./PayrollDetails";
import PayrollTable from "./PayrollTable";
import "./index.css";

const NewPayroll = () => {
  return (
    <div style={{ height: "93vh", overflowY: "scroll" }}>
      <Routes>
        <Route index element={<PayrollTable />} />
        <Route path=":employeeId" element={<PayrollDetails />} />
        {/* <Route path="*" element={<Navigate replace to="" />} /> */}
      </Routes>
    </div>
  );
};

export default NewPayroll;
