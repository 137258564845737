import { InputGroup } from "@blueprintjs/core";
import { Divider, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { find, sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { staffSelector } from "../../../../model/selectors/staffing";
import { RootState } from "../../../../model/store";
import RoleCard from "../RoleCard";
import SelectedTreeNode from "../TreeView/SelectedTreeNode";
import "../index.css";

const CustomGroups = () => {
  const [searchText, setSearchText] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  const [isDisplayTreeNode, setIsDisplayTreeNode] = useState(false);
  // const [staffMemberLoaded, setStaffMembersLoaded] = useState(false);

  const staffMembers: StaffMember[] = useSelector(staffSelector);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const customGroups = useMemo(() => {
    if (businessSettings && businessSettings.customGroups) {
      return sortBy(
        Object.keys(businessSettings.customGroups).map(
          (key) => businessSettings.customGroups![key]
        ),
        "index"
      );
    }
    return [];
  }, [businessSettings]);
  console.log({ customGroupsMem: customGroups });

  const jobFunctions = useMemo(() => {
    return businessSettings
      ? Object.keys(businessSettings.jobFunctions).map(
          (key) => businessSettings.jobFunctions[key]
        )
      : [];
  }, [businessSettings]);

  const customGroupsEmployeeData = useMemo(() => {
    // console.log({customGroups, jobFunctions})
    if (customGroups && jobFunctions && staffMembers) {
      const finalData: any = [];

      customGroups.forEach((customGroup) => {
        const employees: any = [...staffMembers]
          .filter((staffMember) => {
            if (!staffMember.idOfTheGroups) {
              return false;
            }
            const fullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
            if (!fullName.toLowerCase().includes(searchText.toLowerCase())) {
              return false;
            }
            return staffMember.idOfTheGroups.includes(customGroup!.id!);
          })
          .map((staffMember) => {
            const roleName = find(jobFunctions, {
              id: staffMember.primaryRole,
            }) as any;
            console.log("Staff: ", staffMember);
            return {
              id: staffMember.id,
              firstName: staffMember.contact.firstName,
              lastName: staffMember.contact.lastName,
              url: staffMember.imageUrl,
              role: roleName.title,
            };
          });
        finalData.push({
          id: customGroup.id,
          name: customGroup.title,
          employees,
        });
      });
      console.log({ finalData });
      return finalData;
    }
    return [];
  }, [customGroups, jobFunctions, searchText, staffMembers]);
  console.log({ customGroupsEmployeeData });

  useEffect(() => {
    requestAnimationFrame(() => {
      const element = document.querySelector("[data-type='level-selected']");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    });
  }, [activeStep]);

  // const LevelsArray = [
  //   {
  //     id: 1,
  //     name: "CEO",
  //     employees: [
  //       {
  //         id: "1",
  //         firstName: "Terrence",
  //         lastName: "Field",
  //         url: dummyURL,
  //         role: "CEO",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "C-Suite",
  //     employees: [
  //       {
  //         id: "1",
  //         firstName: "Terrence",
  //         lastName: "Field",
  //         url: dummyURL,
  //         role: "CEO",
  //       },
  //     ],
  //   },
  // ];

  // type Employee = {
  //   id: number;
  //   firstName: string;
  //   lastName: string;
  //   url?: string;
  //   role: string;
  // };

  const roleCardDetails = () => {
    setIsDisplayTreeNode(false);
  };

  const StepIcon = ({
    id,
    label,
    selected,
  }: {
    id: number;
    label: string;
    selected: boolean;
  }) => (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        ...(selected ? { color: "#4385EF" } : {}),
      }}
      onClick={() => setActiveStep(id)}
    >
      {label}
    </div>
  );
  return (
    <>
      {!isDisplayTreeNode ? (
        <>
          <Grid spacing={6} container>
            <Grid item md={9}>
              <Box className="level-search-section">
                <InputGroup
                  placeholder="Search Employee"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  leftIcon="search"
                  intent="none"
                />
              </Box>
              {customGroupsEmployeeData.map((customGroupEmpData: any) => (
                <Box className="levels">
                  <div
                    className="level-title"
                    data-type={
                      customGroupEmpData.id === activeStep
                        ? "level-selected"
                        : ""
                    }
                  >
                    {customGroupEmpData.name}
                  </div>
                  <Grid container spacing={3} display="flex" marginBottom={5}>
                    {customGroupEmpData.employees.map(
                      (ele: any, index: number) => (
                        <Grid item key={index} md={3} display={"flex"}>
                          <RoleCard
                            employee={ele}
                            onClickRoleCard={roleCardDetails}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  <Divider />
                </Box>
              ))}
            </Grid>
            <Grid item md={3}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                className="level-step"
              >
                {customGroupsEmployeeData.map((customGrupEmpData: any) => (
                  <Step key={customGrupEmpData.name}>
                    <StepLabel
                      icon={
                        <StepIcon
                          id={customGrupEmpData.id}
                          label={customGrupEmpData.name}
                          selected={
                            customGrupEmpData.id === activeStep ? true : false
                          }
                        />
                      }
                    />
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <SelectedTreeNode
            isDisplayTreeNode={isDisplayTreeNode}
            setIsDisplayTreeNode={setIsDisplayTreeNode}
          />
        </>
      )}
    </>
  );
};

export default CustomGroups;
