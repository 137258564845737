import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { DraggableItem, DraggableType } from "../../types/document";
import "./draggable-list.css";

interface DraggableProps {
  data: DraggableType;
  setData: (newData: DraggableType) => void;
  isSelectable: boolean;
}

function DraggableList({ data, setData, isSelectable }: DraggableProps) {
  return (
    <div className="draggable-list">
      <div className="layout__wrapper">
        <ListOverview
          data={data}
          setData={setData}
          isSelectable={isSelectable}
        />
      </div>
    </div>
  );
}

function ListOverview({ data, setData, isSelectable }: DraggableProps) {
  const [groups, setGroups] = useState<{ [T: string]: number }>({});

  useEffect(() => {
    buildAndSave(data);
  }, []);

  function buildAndSave(items: DraggableType) {
    const groups: { [T: string]: number } = {};
    for (let i = 0; i < Object.keys(items).length; ++i) {
      const currentGroup = items[i];
      groups[currentGroup.id] = i;
    }

    // Makes the groups searchable via their id.
    setGroups(groups);
  }

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
          return;
        }

        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }

        if ("group" === type) {
          const sourceIndex = source.index;
          const targetIndex = destination.index;

          const workValue = data.slice();
          const [deletedItem] = workValue.splice(sourceIndex, 1);
          workValue.splice(targetIndex, 0, deletedItem);

          buildAndSave(workValue);

          return;
        }

        const sourceDroppableIndex = groups[source.droppableId];
        const targetDroppableIndex = groups[destination.droppableId];
        const sourceItems = data[sourceDroppableIndex].items.slice();
        const targetItems =
          source.droppableId !== destination.droppableId
            ? data[targetDroppableIndex].items.slice()
            : sourceItems;

        // Pull the item from the source.
        const [deletedItem] = sourceItems.splice(source.index, 1);
        targetItems.splice(destination.index, 0, deletedItem);

        const workValue = data.slice();
        workValue[sourceDroppableIndex] = {
          ...data[sourceDroppableIndex],
          items: sourceItems,
        };
        workValue[targetDroppableIndex] = {
          ...data[targetDroppableIndex],
          items: targetItems,
        };

        setData(workValue);
      }}
    >
      <Droppable droppableId="ROOT" type="group">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {data.map((item, index) => (
              <Draggable draggableId={item.id} key={item.id} index={index}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <DroppableList
                      key={item.id}
                      data={data}
                      setData={setData}
                      isSelectable={isSelectable}
                      {...item}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function DroppableList({ id, items, label, data, setData, isSelectable }: any) {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className={`holder holder--tint-1`}>
            <div className="holder__title">{label}</div>
            <div className="holder__content">
              <ul className="list">
                {items.map((item: DraggableItem, index: number) => (
                  <li
                    className="list__item"
                    key={item.id}
                    onClick={() => {
                      if (isSelectable) {
                        const newData = data.map(
                          (originalData: DraggableType) => {
                            if (
                              // @ts-ignore
                              originalData.items
                                .map(
                                  (existingItems: DraggableItem) =>
                                    existingItems.id
                                )
                                .includes(item.id)
                            ) {
                              return {
                                ...originalData,
                                items: [
                                  // @ts-ignore
                                  ...originalData.items.map(
                                    (existingItem: DraggableItem) => {
                                      if (existingItem.id === item.id) {
                                        return {
                                          id: item.id,
                                          label: item.label,
                                          selected: true,
                                        };
                                      }
                                      return {
                                        id: existingItem.id,
                                        label: existingItem.label,
                                        selected: false,
                                      };
                                    }
                                  ),
                                ],
                              };
                            }
                            return originalData;
                          }
                        );
                        setData(newData);
                      }
                    }}
                  >
                    <Draggable draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          className={item.selected ? "card__selected" : "card"}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {item.label}
                          {item.sublabel && (
                            <div className="sublabel" style={{ fontSize: 10 }}>
                              {item.sublabel}
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  </li>
                ))}
                {provided.placeholder}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
}

export default DraggableList;
