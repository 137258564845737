import { Icon } from "@blueprintjs/core";
import { InputGroup, MenuItem } from "@blueprintjs/core";
import { Omnibar, Suggest } from "@blueprintjs/select";
import firebase from "config/firebase";
import parsePhoneNumber, {
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";
import _, { find, findIndex } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Spinner from "components/Spinner";

import { RootState } from "model/store";

import Layout from "../../MarketingFeedback/Layout";
import { HashIcon } from "./icon/HashIcon";
import "./styles.css";

const AddSubscrber = () => {
  const ayt = new AsYouType("US");
  const navigate = useNavigate();
  const db = firebase.firestore();
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const [showOmnibar, setShowOmnibar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriberInfo, setSubscriberInfo] = useState({
    emailAddress: "",
    fullName: "",
    phoneNo: "",
  });
  const [activeTags, setActiveTags]: any = useState([]);
  const [tagQuery, setTagQuery] = useState<string>("");
  const [tags, setTags]: any = useState([]);
  const [emailpermissionCheck, setEmailPermissionCheck] = useState(false);
  const [updateContactCheck, setUpdateContactCheck] = useState(false);

  const updateSubscriberInfo = (key: string, value: any) => {
    setSubscriberInfo({
      ...subscriberInfo,
      [key]: value,
    });
  };

  const toggleTag = (key: number) => {
    const tagValues = tags.map((item: any) => item);
    tagValues[key]["active"] = !tagValues[key]["active"];
    setTags(tagValues);
  };

  const isActiveTagSelected = (tag: string) => {
    const availableTags = find(activeTags, (item: any) => {
      if (item === tag) {
        return true;
      }
      return false;
    });
    return availableTags;
  };
  const updateActiveTags = (tagItem: string, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(activeTags, (item) => item === tagItem);
      if (indexToRemove !== -1) {
        const oldTagsArray = [...activeTags];
        oldTagsArray.splice(indexToRemove, 1);
        setActiveTags(oldTagsArray);
      }
    } else {
      setActiveTags([...activeTags, tagItem]);
    }
  };

  const updateTags = (tagItem: string, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(tags, (item) => item === tagItem);
      if (indexToRemove !== -1) {
        const oldTagsArray = [...tags];
        oldTagsArray.splice(indexToRemove, 1);
        setTags(oldTagsArray);
      }
    } else {
      setTags([...tags, tagItem]);
    }
  }; //ignore this one for now

  const isTagSelected = (tag: string) => {
    const availableTags = find(tags, (item: any) => {
      if (item === tag) {
        return true;
      }
      return false;
    });
    return availableTags;
  }; //ignore this one for now

  const valiadateFields = () => {
    const name = subscriberInfo.fullName.length > 2 ? true : false;
    const phoneNo = isValidPhoneNumber(`+1 ${subscriberInfo.phoneNo}`);
    const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      subscriberInfo.emailAddress
    );
    if (name && phoneNo && email) {
      return true;
    }
    return false;
  };

  const addTagsToDB = async (data: string) => {
    const clonedTags = businessSettings.audienceTags
      ? [...businessSettings.audienceTags]
      : [];
    clonedTags.push(data);
    try {
      await db
        .collection("BusinessSettings")
        .doc(businessSettings.id)
        .update({ audienceTags: clonedTags });
      return true;
    } catch (error) {
      console.log({ newError: error });
      //@ts-ignore
      return { error: error.message };
    }
  };

  const addSubsciber = () => {
    const payload = {
      id: null,
      businessId: business.id,
      tags: [...new Set(activeTags)],
      emailAddress: subscriberInfo.emailAddress,
      name: subscriberInfo.fullName,
      phoneNumber: `+1${subscriberInfo.phoneNo}`,
      permissions: {
        recieveEmail: emailpermissionCheck,
        updateContacts: updateContactCheck,
      },
      status: "subscribed",
      updatedAt: new Date(),
      deletedAt: null,
      createdAt: new Date(),
    };

    try {
      setLoading(true);
      db.collection("Audiences")
        .add(payload)
        .then((docRef: any) => {
          db.collection("Audiences")
            .doc(docRef.id)
            .update({ id: docRef.id })
            .then(() => {
              setLoading(false);
              navigate("/audiences");
            });
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(tags);
  }, [tags]);

  useEffect(() => {
    if (businessSettings) {
      if (businessSettings.audienceTags) {
        setTags([...businessSettings.audienceTags]);
      }
    }
  }, [businessSettings]);

  return (
    <Layout>
      <div id="add-subscriber">
        {/*head*/}
        <Box className="page-head">
          <Box
            display="flex"
            flex-direction="row"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/audiences")}
          >
            <Icon icon="chevron-left" color="#424452" size={12} />
            <span className="backword-text">Back to Audiences</span>
          </Box>
          <div className="email-title">Add Subscriber</div>
          {/* <div className="email-time">
          Added via <b>Admin Add</b> on <b>Mon, March 7th 3:33 PM</b>
        </div> */}
        </Box>

        {/*body*/}

        <Box className="body">
          <div className="body-title">Subscriber Info</div>
          <Box className="body-form">
            <div className="input-container">
              <div className="input-label">Email-Address</div>
              <InputGroup
                onChange={(e) =>
                  updateSubscriberInfo("emailAddress", e.target.value)
                }
                placeholder="Type here.."
                value={subscriberInfo.emailAddress}
                className="emailinputs"
              />
              {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                subscriberInfo.emailAddress
              ) && (
                <Icon
                  icon="tick"
                  size={20}
                  color="#424452"
                  className="input-check"
                />
              )}
            </div>
            <div className="input-container">
              <div className="input-label">Full Name</div>
              <InputGroup
                onChange={(e) =>
                  updateSubscriberInfo("fullName", e.target.value)
                }
                placeholder="Type here.."
                value={subscriberInfo.fullName}
                className="emailinputs"
              />
              {subscriberInfo.fullName.length > 2 && (
                <Icon
                  icon="tick"
                  size={20}
                  color="#424452"
                  className="input-check"
                />
              )}
            </div>
            <div className="input-container">
              <div className="input-label">Phone Number</div>
              <InputGroup
                onChange={(e) => {
                  const number = e.target.value
                    .replace("(", "")
                    .replace(")", "")
                    .replace("-", "")
                    .replace(" ", "");

                  if (number.length <= 10) {
                    // onChange(number);
                    updateSubscriberInfo("phoneNo", e.target.value);
                  }
                }}
                placeholder="Type here.."
                value={ayt.input(subscriberInfo.phoneNo)}
                className="emailinputs"
              />
              {isValidPhoneNumber(`+1 ${subscriberInfo.phoneNo}`) && (
                <Icon
                  icon="tick"
                  size={20}
                  color="#424452"
                  className="input-check"
                />
              )}
            </div>
          </Box>

          <Box className="tags-section">
            <div className="body-title">Tags</div>
            <Box
              className="tags-body"
              style={{ width: "100%", marginTop: "20px" }}
            >
              {tags.map((item: any, key: any) => (
                <div
                  className={isActiveTagSelected(item) ? "active-tag" : "tag"}
                  onClick={() =>
                    updateActiveTags(item, isActiveTagSelected(item))
                  }
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <HashIcon
                      height="14.67px"
                      width="14.67px"
                      color={isActiveTagSelected(item) ? "#FFFFFF" : "#424452"}
                    />
                    <div className="tag-name">{item}</div>
                  </Box>
                </div>
              ))}

              <div className="tag">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={() => setShowOmnibar(true)}
                >
                  <Icon icon={"plus"} color="#424452" size={16} />
                  <div className="tag-name">Add Tag</div>
                </Box>
              </div>
              <Omnibar
                resetOnSelect
                overlayProps={{
                  portalClassName: "benefits-omnibar",
                }}
                query={tagQuery}
                isOpen={showOmnibar}
                onClose={() => {
                  setTagQuery("");
                  setShowOmnibar(false);
                }}
                onItemSelect={async (item: string, event) => {
                  // updateTags(item, isTagSelected(item));
                  const response = await addTagsToDB(item);
                  if (response) {
                    updateTags(item, isTagSelected(item));
                  }
                }}
                onQueryChange={(query) => {
                  setTagQuery(query);
                }}
                createNewItemFromQuery={(query) => query}
                createNewItemRenderer={(
                  query: string,
                  active: boolean,
                  handleClick: React.MouseEventHandler<HTMLElement>
                ) => (
                  <MenuItem
                    icon="add"
                    text={`Create "${query}"`}
                    active={active}
                    onClick={async (e) => {
                      const response = await addTagsToDB(query);
                      if (response) {
                        handleClick(e);
                      }
                    }}
                    shouldDismissPopover={false}
                  />
                )}
                itemPredicate={(query, film, _index, exactMatch) => {
                  const normalizedTitle = film.toLowerCase();
                  const normalizedQuery = query.toLowerCase();

                  if (exactMatch) {
                    return normalizedTitle === normalizedQuery;
                  } else {
                    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
                  }
                }}
                itemRenderer={(item, { handleClick }) => {
                  if (isTagSelected(item)) {
                    return null;
                  } else {
                    return <MenuItem text={item} onClick={handleClick} />;
                  }
                }}
                itemsEqual={(filmA: string, filmB: string) => {
                  // Compare only the titles (ignoring case) just for simplicity.
                  return filmA.toLowerCase() === filmB.toLowerCase();
                }}
                items={
                  businessSettings?.audienceTags
                    ? businessSettings.audienceTags
                    : []
                }
              />
            </Box>
          </Box>

          <Box className="form-checkbox-section">
            <input
              type="checkbox"
              className="form-check-input"
              checked={emailpermissionCheck}
              id="mastercheck"
              onChange={(e) => setEmailPermissionCheck(e.target.checked)}
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: "17.75px" }}
            >
              <div className="checkbox-section-title">
                This person gave me permission to email them
              </div>
              <div className="checkbox-section-subtitle">
                This person will not recieve a confirmation email from Tango.
                Since you’re adding this recipent manually, they won’t have to
                opt-in IP adress or date in your records, so be extra sure you
                have permission first
              </div>
            </Box>
          </Box>

          <Box className="form-checkbox-section">
            <input
              type="checkbox"
              className="form-check-input"
              checked={updateContactCheck}
              id="mastercheck"
              onChange={(e) => setUpdateContactCheck(e.target.checked)}
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ marginLeft: "17.75px" }}
            >
              <div className="checkbox-section-title">Update my contacts</div>
              <div className="checkbox-section-subtitle">
                If this person is already in my contacts, please update their
                profile
              </div>
            </Box>
          </Box>

          <div
            className={`${
              valiadateFields()
                ? "addsubscriber-button-active"
                : "addsubscriber-button"
            }`}
            onClick={() => {
              if (valiadateFields()) {
                addSubsciber();
              }
            }}
          >
            Add New Subscriber
          </div>
        </Box>
        {loading && <Spinner />}
      </div>
    </Layout>
  );
};

export default AddSubscrber;
