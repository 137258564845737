import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  WeeklySummarySalesReport,
  WeeklySummaryServiceAreaGroupedSalesReport,
  WeeklyTimeSlotSalesTable,
  WeeklyTimeSlotSalesTableRow,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 7,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 7,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 3,
  },
  dataCellStyle: {
    fontSize: 6,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  completelyRoundedFooter: {
    borderRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface DailyLaborPdfDocumentProps {
  report: WeeklySummarySalesReport;
  businessName: string;
}

const WeeklySummaryPdfDocument = (props: DailyLaborPdfDocumentProps) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Weekly Sales Summary Report for {props.businessName} on{" "}
            {moment(props.report.startDate).format("DD/MM/YYYY hh:mm a")}{" "}
            {moment(props.report.endDate).format("DD/MM/YYYY hh:mm a")}
          </Text>
          {/* <Text style={{fontFamily: 'SFProText-Semibold', color: '#454545', fontSize: 8, marginTop: 5}}>Generated on {moment().format("DD/MM/YYYY")} at {moment().format("hh:mm a")}</Text>  
          <Text style={{fontFamily: 'SFProText-SFProTextBold', color: '#454545', fontSize: 8, marginTop: 5}}>Total Sales: {formatter.format(props.report.totalSales / 100)}</Text>
          <Text style={{fontFamily: 'SFProText-SFProTextBold', color: '#454545', fontSize: 8, marginTop: 5}}>Number Of Customers: {props.report.totalCustomersNumberForTheDay}</Text>
          <Text style={{fontFamily: 'SFProText-SFProTextBold', color: '#454545', fontSize: 8, marginTop: 5}}>Number Of Meals: {props.report.totalMealsNumberForTheDay}</Text> */}
        </View>
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Service Areas
          </Text>
        </View>
        {props.report.serviceAreas.map(
          (serviceAreaReport: WeeklySummaryServiceAreaGroupedSalesReport) => (
            <>
              <Table>
                <TableHeader style={{ borderRadius: 5, marginTop: 15 }}>
                  <TableCell textAlign="center" style={styles.headerCellStyle}>
                    {serviceAreaReport.serviceAreaName}
                  </TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                  <TableCell
                    textAlign="center"
                    style={styles.headerCellStyle}
                  ></TableCell>
                </TableHeader>
              </Table>
              {serviceAreaReport.timeSlotSalesTables
                .sort((a, b) => {
                  const order = ["Lunch", "Late Lunch", "Dinner", "Late"];
                  const i: number = order.indexOf(a.total.title);
                  const j: number = order.indexOf(b.total.title);
                  return i - j;
                })
                .map((timeSlotTable: WeeklyTimeSlotSalesTable) => (
                  <Table data={timeSlotTable.data}>
                    <TableHeader style={{ marginTop: 5 }}>
                      {timeSlotTable.header.map((headerTitle) => (
                        <TableCell
                          textAlign="center"
                          style={styles.headerCellStyle}
                        >
                          {headerTitle}
                        </TableCell>
                      ))}
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(r: WeeklyTimeSlotSalesTableRow) => r.title}
                      />
                      {_.range(0, 7).map((i) => (
                        <DataTableCell
                          style={styles.dataCellStyle}
                          getContent={(r: WeeklyTimeSlotSalesTableRow) =>
                            formatter.format(r.salesRange[i] / 100)
                          }
                        />
                      ))}
                      <DataTableCell
                        style={styles.dataCellStyle}
                        getContent={(r: WeeklyTimeSlotSalesTableRow) =>
                          formatter.format(r.total / 100)
                        }
                      />
                    </TableBody>
                    <TableFooter style={styles.totalFooter}>
                      <TableCell
                        textAlign="center"
                        style={styles.footerCellStyle}
                      >
                        {timeSlotTable.total.title}
                      </TableCell>
                      {timeSlotTable.total.salesRange.map((salesData) => (
                        <TableCell
                          textAlign="center"
                          style={styles.footerCellStyle}
                        >
                          {formatter.format(salesData / 100)}
                        </TableCell>
                      ))}
                      <TableCell
                        textAlign="center"
                        style={styles.footerCellStyle}
                      >
                        {formatter.format(timeSlotTable.total.total / 100)}
                      </TableCell>
                    </TableFooter>
                  </Table>
                ))}
              <Table>
                <TableFooter
                  style={{
                    ...styles.totalFooter,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    marginTop: 5,
                  }}
                >
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {serviceAreaReport.total.title}
                  </TableCell>
                  {serviceAreaReport.total.salesRange.map((salesData) => (
                    <TableCell
                      textAlign="center"
                      style={styles.footerCellStyle}
                    >
                      {formatter.format(salesData / 100)}
                    </TableCell>
                  ))}
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {formatter.format(serviceAreaReport.total.total / 100)}
                  </TableCell>
                </TableFooter>
              </Table>
            </>
          )
        )}
      </Page>
    </Document>
  );
};

export default WeeklySummaryPdfDocument;
