import { FirebaseCashEvents } from "types/cashDrawers";

import ActionType from "./actionTypes";

export function receiveCashEvents(cashEvents: FirebaseCashEvents[]) {
  return {
    type: ActionType.RECEIVE_CASH_EVENTS,
    cashEvents,
  };
}
