import { Icon, InputGroup, Intent, Position, Toaster } from "@blueprintjs/core";
import firebase from "config/firebase";
import parsePhoneNumber, {
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { find } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { OptionTypeBase } from "react-select/src/types";

import {
  updateBasicInfo,
  updateEmployeePaymentInfo,
  updateEmployeeRoles,
  updateStaffMemberEmail,
  updateStaffMemberPhoneNumber,
} from "controllers/auth";

import Box from "components/Box";
import Spinner from "components/Spinner";

import { RootState } from "model/store";

import logo from "assets/normallogo.svg";

import {
  findOrCreateUser,
  getAllBusinessesByAccountId,
} from "../teamFirebaseCalls";
import { BasicInfo } from "./Icons/BasicInfo";
import { EmployeeRole } from "./Icons/EmployeeRole";
import { PaymentInfo } from "./Icons/PaymentInfo";
import tickArrow from "./Icons/tickarrow.svg";
import "./index.css";
import FindLocationModal from "./modals/FindLocationModal";

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

const EMPLOYEE_PERMISSIONS = [
  // "Company Admin",
  "Location Admin",
  "Basic Employee",
];

// const initialEmployeeDetails = ;

const EditEmployee = () => {
  const navigate = useNavigate();

  const params = useParams<{ staffMemberId: string }>();
  const staffMemberId = params.staffMemberId;

  if (!staffMemberId) {
    return <Navigate to="/manager/team" />;
  }

  const [selectedDepartment, setSelectedDepartment] =
    useState<OptionTypeBase | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<OptionTypeBase | null>(
    null
  );
  const [selectedManagers, setSelectedManagers] = useState<
    OptionTypeBase[] | null
  >(null);

  const [selectedCustomGroups, setSelectedCustomGorups] = useState<
    OptionTypeBase[] | null
  >(null);

  const [departments, setDepartments] = useState([
    {
      label: "FOH",
      value: "foh",
    },
    {
      label: "BOH",
      value: "boh",
    },
    {
      label: "Other",
      value: "other",
    },
  ]);

  const ayt = new AsYouType("US");
  const db = firebase.firestore();
  const [loading, setLoading] = useState(false);
  const [basicInfoLoading, setBasicInfoLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [latestEmpData, setLatestEmpData]: any = useState();
  const [employeeDetails, setEmployeeDetails] = useState<
    EmployeeDetails & { payRates: any[] }
  >({
    uid: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    employeeType: "",
    jobRoles: [],
    employeePermission: "",
    locations: [],
    compensationType: null,
    payRate: 0,
    employeeHiringType: null,
    annualSalary: 0,
    payRates: [],
  });

  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [allBusinesses, setAllBusinesses] = useState<TangoBusiness[]>([]);
  const [step, setStep] = useState<number>(2);
  const [emailCheckError, setEmailCheckError] = useState<string | null>(null);
  const [phoneNumberCheckError, setPhoneNumberCheckError] = useState<
    string | null
  >(null);
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const levels: Level[] = useMemo(() => {
    if (businessSettings && businessSettings.levels) {
      const levelsTemp: Level[] = [];
      Object.keys(businessSettings.levels).map((key) => {
        const businessSettingLevel = businessSettings.levels![key];
        levelsTemp.push({
          title: businessSettingLevel.title,
          levelId: businessSettingLevel.id,
          description: businessSettingLevel.description,
          label: businessSettingLevel.title,
          value: businessSettingLevel.id,
          index: businessSettingLevel.index,
        });
      });
      return levelsTemp;
    }
    return [];
  }, [businessSettings]);

  const customGroups: CustomGroupOrg[] = useMemo(() => {
    if (businessSettings && businessSettings.customGroups) {
      const customGroupsTemp: CustomGroupOrg[] = [];
      Object.keys(businessSettings.customGroups).map((key) => {
        const businessSettingLevel = businessSettings.customGroups![key];
        customGroupsTemp.push({
          title: businessSettingLevel.title,
          customGroupId: businessSettingLevel.id,
          description: businessSettingLevel.description,
          label: businessSettingLevel.title,
          value: businessSettingLevel.id,
        });
      });
      return customGroupsTemp;
    }
    return [];
  }, [businessSettings]);

  const customGroupsAsOptionType: OptionTypeBase[] = useMemo(() => {
    if (customGroups) {
      return customGroups.map((customGroup) => ({
        value: customGroup.customGroupId,
        label: customGroup.title,
      }));
    }
    return [];
  }, [customGroups]);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const staffMembersAsOptionType: OptionTypeBase[] = useMemo(() => {
    if (fellowStaffMembers) {
      return fellowStaffMembers.map((staffMember) => ({
        value: staffMember.staffMemberCopyId,
        label: `${staffMember.contact.firstName} ${staffMember.contact.lastName}`,
      }));
    }
    return [];
  }, [fellowStaffMembers]);

  const staffMember = fellowStaffMembers.find((sm) => sm.id === staffMemberId);
  // console.log("staffMember", staffMember);

  useEffect(() => {
    if (levels && fellowStaffMembers && staffMember && customGroups) {
      const idOfTheGroups = staffMember.idOfTheGroups;
      const userLevelId = staffMember.levelId;
      const userDepartmentId = staffMember.departmentId;
      const userManagers = staffMember.parentStaffIds;
      if (userLevelId) {
        const levelFromLevel = find(levels, { levelId: userLevelId }) as any;
        // console.log({levels})
        if (levelFromLevel) {
          setSelectedLevel({
            value: levelFromLevel.id,
            label: levelFromLevel.title,
          });
        }
      }
      if (userDepartmentId) {
        const department = find(departments, { value: userDepartmentId });
        if (department) {
          setSelectedDepartment(department);
        }
      }
      if (userManagers) {
        const userManagersAsBaseType = userManagers.map((manager) => {
          const user = find(fellowStaffMembers, {
            staffMemberCopyId: manager,
          }) as StaffMember;
          return {
            label: `${user.contact.firstName} ${user.contact.lastName}`,
            value: user.staffMemberCopyId,
          };
        });
        setSelectedManagers(userManagersAsBaseType);
      }

      if (idOfTheGroups) {
        const customGroupsAsBaseType = customGroups
          .filter((customGroup) => {
            return staffMember.idOfTheGroups?.includes(
              customGroup.customGroupId
            );
          })
          .map((customGroup) => {
            return {
              label: customGroup.title,
              value: customGroup.customGroupId,
            };
          });
        setSelectedCustomGorups(customGroupsAsBaseType);
      }
    }
  }, [levels, fellowStaffMembers, staffMember, customGroups]);

  const jobRoles = useMemo(() => {
    if (businessSettings) {
      return Object.keys(businessSettings.jobFunctions).map(
        (key) => businessSettings.jobFunctions[key]
      );
    }
    return [];
  }, [businessSettings]);

  const updateForm = async (staffMember: any, id: string) => {
    try {
      await db.collection("Staff").doc(id).update(staffMember);
      //setSuccessPage(true);
    } catch (err) {
      console.log(err);
    }
  };

  const submitForm = async (staffMember: any) => {
    try {
      return db
        .collection("Staff")
        .doc(latestEmpData.id)
        .update(staffMember)
        .catch((error) => console.log(error));

      //setSuccessPage(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getAllLocationsOfAccount = async () => {
      const businesses = await getAllBusinessesByAccountId(business.accountId);
      setAllBusinesses(businesses);
    };
    if (business) getAllLocationsOfAccount();
  }, [business]);

  const setSelectedEmployeeLocation = (id: string) => {
    allBusinesses &&
      allBusinesses.map((item) => {
        if (id == item.id) {
          if (
            employeeDetails.locations.findIndex((i) => i.id == item.id) == -1
          ) {
            setEmployeeDetails({
              ...employeeDetails,
              locations: [...employeeDetails.locations, item],
            });
          }
        }
      });
  };

  const renderAnnualSalary = (employeeData: any) => {
    let salary = 0;
    const as = employeeData.annualSalary / 100;
    if (as) {
      if (as < 1) {
        salary = employeeData.annualSalary / 100;
      } else if (as >= 1) {
        salary = Math.floor(employeeData.annualSalary / 100);
      }
    }
    return salary;
  };

  const preFillData = (employeeData: StaffMember) => {
    if (employeeData) {
      let employeePermission = "";
      if (!employeeData.isAdmin) {
        employeePermission = "Basic Employee";
      } else {
        employeePermission = "Location Admin";
      }

      const empJobRoles = [
        employeeData?.primaryRole,
        ...(employeeData?.secondaryRoles ?? []),
      ];

      setEmployeeDetails({
        ...employeeDetails,
        firstName: employeeData.contact.firstName,
        lastName: employeeData.contact.lastName,
        emailAddress: employeeData.contact.email,
        phoneNumber: employeeData?.contact?.phone?.substring(2),
        jobRoles: empJobRoles,
        employeeType: employeeData.typeOfEmployee ?? "",
        annualSalary: employeeData && renderAnnualSalary(employeeData),
        payRate: employeeData?.payRates[0]?.amount,
        employeePermission,
        employeeHiringType: "existing",
        //@ts-ignore
        compensationType: employeeData?.payRates[0]?.type,
        payRates: employeeData.payRates,
      });
      // compensationType: employeeData.compensationType,

      setSelectedEmployeeLocation(employeeData.businessId);
    }
  };

  const isJobRoleSelected = (jobRoleId: string) => {
    const jobRoleById = employeeDetails.jobRoles.find((jr) => jr === jobRoleId);
    return !!jobRoleById;
  };

  const isJobRoleFirstIndexed = (item: any) => {
    return employeeDetails.jobRoles[0] == item.id;
  };

  const updateJobRole = (jobRole: any, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = employeeDetails.jobRoles.indexOf(jobRole.id);
      if (indexToRemove !== -1) {
        const oldJobRolesArray = [...employeeDetails.jobRoles];
        oldJobRolesArray.splice(indexToRemove, 1);
        setEmployeeDetails({
          ...employeeDetails,
          jobRoles: oldJobRolesArray,
        });
      }
    } else {
      setEmployeeDetails({
        ...employeeDetails,
        jobRoles: [...employeeDetails.jobRoles, jobRole.id],
      });
    }
  };

  const updateLocation = (
    location: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      if (index !== -1) {
        let oldLocationsArray = [...employeeDetails.locations];
        if (index === oldLocationsArray.length) {
          oldLocationsArray = [];
        } else {
          oldLocationsArray.splice(index, 1);
        }
        setEmployeeDetails({
          ...employeeDetails,
          locations: oldLocationsArray,
        });
      }
    } else {
      setEmployeeDetails({
        ...employeeDetails,
        locations: [...employeeDetails.locations, location],
      });
    }
  };
  const [successPage, setSuccessPage] = useState(false);

  useEffect(() => {
    if (successPage) {
      setTimeout(() => {
        setSuccessPage(false);
        window.scrollTo(0, 0);
        // setStep(1);
        setEmployeeDetails({
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          employeeType: "",
          jobRoles: [],
          employeePermission: "",
          locations: [],
          compensationType: null,
          payRate: 0,
          employeeHiringType: null,
          annualSalary: 0,
          payRates: [],
        });
        navigate(-1);
      }, 2000);
    }
  }, [successPage]);

  // useEffect(() => {
  //   setLatestEmpData(staffMember);
  // }, [staffMember]);

  useEffect(() => {
    if (staffMember) {
      preFillData(staffMember);
    }
  }, [staffMember]);

  const updateEmployeeDetail = (
    key: string,
    value: string | number,
    payRateIndex?: number
  ) => {
    if (payRateIndex === undefined) {
      setEmployeeDetails({
        ...employeeDetails,
        [key]: value,
      });
    } else {
      const existingPayRates = [...employeeDetails.payRates];
      existingPayRates[payRateIndex][key] = Number(value) * 100;

      setEmployeeDetails({ ...employeeDetails, payRates: existingPayRates });
    }
  };

  const updatePaymentInfoHandler = useCallback(async () => {
    try {
      if (business && staffMember) {
        setPaymentLoading(true);
        const payRates = employeeDetails?.payRates.map((payRate, index) => {
          return { ...payRate, type: employeeDetails.compensationType };
        });
        await updateEmployeePaymentInfo(
          business.id,
          payRates,
          employeeDetails.annualSalary
            ? Number(employeeDetails.annualSalary) * 100
            : 0,
          staffMember.id
        );
        setPaymentLoading(false);
      }
    } catch (e: any) {
      setPaymentLoading(false);
      toaster.show({
        message: e?.response?.data?.message ?? "Something went wrong...",
        intent: Intent.DANGER,
      });
    }
  }, [
    business?.id,
    employeeDetails?.payRate,
    employeeDetails?.annualSalary,
    employeeDetails?.jobRoles,
    staffMember,
  ]);

  const updateRolesHandler = useCallback(async () => {
    try {
      if (business && staffMember) {
        const accountId =
          employeeDetails.employeePermission == "Company Admin"
            ? business.accountId
            : null;
        const isAdmin = employeeDetails.employeePermission != "Basic Employee";
        const roles = [...employeeDetails.jobRoles];
        console.log({ employeeDetails, roles });
        const primaryRole = employeeDetails?.jobRoles[0];
        roles.shift();

        setRolesLoading(true);
        await updateEmployeeRoles(
          business.id,
          primaryRole,
          roles,
          isAdmin,
          accountId,
          staffMember.id,
          selectedLevel ? selectedLevel.value : null,
          selectedDepartment ? selectedDepartment.value : null,
          selectedManagers
            ? selectedManagers.map((manager) => manager.value)
            : null,
          selectedCustomGroups
            ? selectedCustomGroups.map((customGroup) => customGroup.value)
            : null
        );
        setRolesLoading(false);
      }
    } catch (e: any) {
      setRolesLoading(false);
      toaster.show({
        message: e?.response?.data?.message ?? "Something went wrong...",
        intent: Intent.DANGER,
      });
    }
  }, [
    business?.id,
    business?.accountId,
    employeeDetails.jobRoles,
    employeeDetails.employeePermission,
    staffMember,
    selectedManagers,
    selectedDepartment,
    selectedLevel,
    selectedCustomGroups,
  ]);

  const updateBasicInfoHandler = useCallback(async () => {
    try {
      setEmailCheckError(null);
      setPhoneNumberCheckError(null);
      if (business && staffMember) {
        const parsedPhoneNumber = parsePhoneNumber(
          employeeDetails.phoneNumber,
          "US"
        )?.formatInternational();
        setBasicInfoLoading(true);
        if (
          employeeDetails.firstName !== staffMember?.contact?.firstName ||
          employeeDetails.lastName !== staffMember?.contact?.lastName ||
          employeeDetails.employeeType !== staffMember.typeOfEmployee
        ) {
          await updateBasicInfo(
            business.id,
            employeeDetails.firstName,
            employeeDetails.lastName,
            employeeDetails.employeeType,
            staffMember.id
          );
        }

        if (employeeDetails.emailAddress !== staffMember?.contact?.email) {
          try {
            await updateStaffMemberEmail(
              business.id,
              employeeDetails.emailAddress,
              staffMember.id
            );
          } catch (e: any) {
            if (e?.response?.data?.message) {
              setEmailCheckError(e.response.data.message);
            } else {
              toaster.show({
                message: "Something went wrong updating email...",
                intent: Intent.DANGER,
              });
            }
          }
        }

        if (
          parsedPhoneNumber &&
          parsedPhoneNumber !== staffMember?.contact?.phone
        ) {
          try {
            await updateStaffMemberPhoneNumber(
              business.id,
              parsedPhoneNumber,
              staffMember.id
            );
          } catch (e: any) {
            if (e?.response?.data?.message) {
              setPhoneNumberCheckError(e.response.data.message);
            } else {
              toaster.show({
                message: "Something went wrong updating phone number...",
                intent: Intent.DANGER,
              });
            }
          }
        }

        setBasicInfoLoading(false);
      }
    } catch (e: any) {
      setBasicInfoLoading(false);
      toaster.show({
        message: e?.response?.data?.message ?? "Something went wrong...",
        intent: Intent.DANGER,
      });
    }
  }, [
    business?.id,
    employeeDetails?.firstName,
    employeeDetails?.lastName,
    employeeDetails?.employeeType,
    employeeDetails?.emailAddress,
    employeeDetails?.phoneNumber,
    staffMember,
  ]);

  const setObjectAndSubmit = async () => {
    const name = employeeDetails.firstName + " " + employeeDetails.lastName;
    const email = employeeDetails.emailAddress;
    const phoneNumber = "+1" + employeeDetails.phoneNumber.toString();
    const uid = await findOrCreateUser(name, email, phoneNumber);

    updateEmployeeDetail("uid", uid);

    const roles = [...employeeDetails.jobRoles];
    console.log({ employeeDetails, roles });
    const primaryRole = employeeDetails?.jobRoles[0]?.id;
    roles.shift();
    console.log({ primaryRole, roles });

    let numberOfLocations = employeeDetails.locations.length;
    const formSubmit =
      (await employeeDetails) &&
      employeeDetails.locations.map(async (item) => {
        const query = await db
          .collection("Staff")
          .where("uid", "==", uid)
          .where("businessId", "==", item.id)
          .get();
        if (!query.empty) {
          const staffMember = {
            uid: uid,
            annualSalary: Number(employeeDetails.annualSalary),
            contact: {
              firstName: employeeDetails.firstName,
              lastName: employeeDetails.lastName,
              phone: "+1" + employeeDetails.phoneNumber.toString(),
              email: employeeDetails.emailAddress,
            },
            deleted: false,
            primaryRole,
            secondaryRoles: (roles || []).map((item: any) => item.id),
            pinCode: "",
            emergencyContact: {
              name: "",
              phone: "",
              relationship: "",
            },
            imageUrl: null,
            // Modal info
            SINOrSSN: "",
            medicalCard: "",
            I9: "",
            W4: "",
            payRates: employeeDetails?.jobRoles.map((item) => ({
              role: item.title,
              amount: employeeDetails.payRate,
              roleId: item.id,
            })),
            businessId: item.id,
            typeOfEmployee: employeeDetails.employeeType,
            accountId:
              employeeDetails.employeePermission == "Company Admin"
                ? business.accountId
                : null,
            isAdmin: employeeDetails.employeePermission != "Basic Employee",
            createdAt: new Date(),
            updatedAt: new Date(),
          };
          console.log("hakhsjdkajs", staffMember);
          await updateForm(staffMember, query.docs[0].id);

          // toaster.show({
          //   message: `Staff member already exist for location - ${item.location.address}`,
          //   intent: Intent.DANGER
          // });
          numberOfLocations--;
        } else {
          const staffMember = {
            id: null,
            uid: uid,
            annualSalary: Number(employeeDetails.annualSalary),
            contact: {
              firstName: employeeDetails.firstName,
              lastName: employeeDetails.lastName,
              phone: "+1" + employeeDetails.phoneNumber.toString(),
              email: employeeDetails.emailAddress,
            },
            deleted: false,
            primaryRole,
            secondaryRoles: (roles || []).map((item: any) => item.id),
            pinCode: "",
            emergencyContact: {
              name: "",
              phone: "",
              relationship: "",
            },
            imageUrl: null,
            // Modal info
            SINOrSSN: "",
            medicalCard: "",
            I9: "",
            W4: "",
            payRates: employeeDetails?.jobRoles.map((item) => ({
              role: item.title,
              amount: employeeDetails.payRate * 10,
              roleId: item.id,
            })),
            businessId: item.id,
            typeOfEmployee: employeeDetails.employeeType,
            accountId:
              employeeDetails.employeePermission == "Company Admin"
                ? business.accountId
                : null,
            isAdmin: employeeDetails.employeePermission != "Basic Employee",
            createdAt: new Date(),
            updatedAt: new Date(),
          };
          await submitForm(staffMember);
        }
      });
    await Promise.all(formSubmit);
    if (numberOfLocations === 0) {
      window.scrollTo(0, 0);
      setStep(1);
    } else {
      setSuccessPage(true);
    }
  };

  const setPayrateZero = () => {
    setEmployeeDetails({
      ...employeeDetails,
      compensationType: "annually",
      payRate: 0,
    });
  };

  const setAnnualSalaryZero = () => {
    setEmployeeDetails({
      ...employeeDetails,
      compensationType: "hourly",
      annualSalary: 0,
    });
  };

  const sidebarList = [
    {
      name: "Basic Information",
      icon: BasicInfo,
      index: 1,
    },
    {
      name: "Employee Roles",
      icon: EmployeeRole,
      index: 2,
    },
    {
      name: "Payment Information",
      icon: PaymentInfo,
      index: 3,
    },
    // {
    //   name: 'Employee Type',
    //   icon: EmployeeType,
    //   index: 4
    // },
    // {
    //   name: 'Review',
    //   icon: Review,
    //   index: 5
    // },
    // {
    //   name: 'Offer Letter',
    //   icon: OfferLetter,
    //   index: 6
    // }
  ];

  const isFirstStepContinueButtonEnable = () => {
    console.log("employeeDetails", employeeDetails);
    console.log(
      "email test",
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        employeeDetails.emailAddress
      )
    );
    console.log(
      "isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`)",
      isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`)
    );

    return (
      employeeDetails.firstName.length > 1 &&
      employeeDetails.lastName.length > 1 &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        employeeDetails.emailAddress
      ) &&
      isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`) &&
      // employeeDetails.employeeType !== "" &&
      !basicInfoLoading
    );
  };

  const isSecondStepContinueButtonEnable = () => {
    return (
      employeeDetails.jobRoles.length > 0 &&
      employeeDetails.employeePermission.length > 0 &&
      !rolesLoading
    );
  };

  const isPayRatefilled = () => {
    if (
      employeeDetails.compensationType == "hourly" &&
      Number(employeeDetails.payRate) >= 0
    ) {
      return true;
    } else if (
      employeeDetails.compensationType == "annually" &&
      Number(employeeDetails.annualSalary) >= 0
    ) {
      return true;
    }
    return false;
  };

  const renderEmployeeInformation = () => (
    <Box display="flex" flex-direction="column">
      <div className="section">
        <div className="section-title">Employee Information</div>
        <div className="section-subtitle">Update basics</div>
        <Box
          display="flex"
          flexDirection="column"
          style={{ marginTop: "15px" }}
        >
          <div className="input-container">
            <div className="input-label">First Name</div>
            <InputGroup
              onChange={(e) =>
                updateEmployeeDetail("firstName", e.target.value)
              }
              value={employeeDetails.firstName}
              className="employeeinputs"
            />
            {employeeDetails.firstName.length > 2 && (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            )}
          </div>
          <div className="input-container">
            <div className="input-label">Last Name</div>
            <InputGroup
              onChange={(e) => updateEmployeeDetail("lastName", e.target.value)}
              value={employeeDetails.lastName}
              className="employeeinputs"
            />
            {employeeDetails.lastName.length > 2 && (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            )}
          </div>
          <div
            className="input-container"
            style={
              emailCheckError
                ? { border: "1px solid red", color: "red" }
                : undefined
            }
          >
            <div className="input-label">Email</div>
            <InputGroup
              type="email"
              onChange={(e) =>
                updateEmployeeDetail("emailAddress", e.target.value)
              }
              value={employeeDetails.emailAddress}
              className="employeeinputs"
            />
            {emailCheckError ? <div>{emailCheckError}</div> : null}
            {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              employeeDetails.emailAddress
            ) && !emailCheckError ? (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            ) : null}
          </div>
          <div
            className="input-container"
            style={
              phoneNumberCheckError
                ? { border: "1px solid red", color: "red" }
                : undefined
            }
          >
            <div className="input-label">Phone Number</div>
            <InputGroup
              onChange={(e) => {
                const number = e.target.value
                  .replace("(", "")
                  .replace(")", "")
                  .replace("-", "")
                  .replace(" ", "");

                // console.log(number);
                if (number.length <= 10) {
                  // onChange(number);
                  updateEmployeeDetail("phoneNumber", e.target.value);
                }
              }}
              // maxLength={10}
              value={ayt.input(employeeDetails.phoneNumber)}
              className="employeeinputs"
            />
            {phoneNumberCheckError ? <div>{phoneNumberCheckError}</div> : null}
            {isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`) &&
            !phoneNumberCheckError ? (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            ) : null}
          </div>
        </Box>
      </div>
      <Box className="section option-section">
        <div className="section-title">Type of Employee</div>
        <div className="section-subtitle">Update employee type</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <button
            className={`select-button ${
              employeeDetails.employeeType === "full-time" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "full-time")}
          >
            <Icon
              icon={
                employeeDetails.employeeType === "full-time" ? "tick" : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Full Time Employee
          </button>
          <button
            className={`select-button ${
              employeeDetails.employeeType === "part-time" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "part-time")}
          >
            <Icon
              icon={
                employeeDetails.employeeType === "part-time" ? "tick" : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Part Time Employee
          </button>
          <button
            className={`select-button ${
              employeeDetails.employeeType === "intern" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "intern")}
          >
            <Icon
              icon={employeeDetails.employeeType === "intern" ? "tick" : "plus"}
              size={12}
              color={"#4385EF"}
            />{" "}
            Intern
          </button>
        </Box>
      </Box>
      <div className="control-button-container">
        <button
          className={
            isFirstStepContinueButtonEnable()
              ? "continue-button"
              : "continue-button-disabled"
          }
          onClick={updateBasicInfoHandler}
          disabled={!isFirstStepContinueButtonEnable()}
        >
          {basicInfoLoading ? "Loading..." : "Save"}
        </button>
        <button
          className={"nav-button"}
          onClick={() => {
            window.scrollTo(0, 0);
            setStep(2);
          }}
          disabled={!isFirstStepContinueButtonEnable()}
        >
          To Employee Roles
        </button>
      </div>
    </Box>
  );

  const renderEmployeeRolesForm = () => {
    // if(latestEmpData){
    //   const accountId=latestEmpData?.accountId ? latestEmpData?.accountId : null;
    //   if()
    //   // for basic employee
    //   accountId: null,
    //   isAdmin: false

    //   // for location employee
    //   accountId: null,
    //     isAdmin: true

    //   // for company admin employee
    //   accountId: business.accountId,
    //     isAdmin: true
    // }

    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Location</div>
          <div className="section-subtitle">
            Select where this employee will be working
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <button className="select-button active">
              <Icon icon="tick" size={12} color={"#4385EF"} />{" "}
              {business.businessName}
            </button>
            {/* {employeeDetails.locations.map((item, index) => (
              <button className="select-button active" key={`${item}-${index}`}>
                <Icon icon="tick" size={12} color={"#4385EF"} />{" "}
                {business.businessName}
              </button>
            ))} */}
            {/* <button
              className="select-button"
              onClick={() => setShowLocationModal(true)}
            >
              <Icon icon="plus" size={12} color={"#4385EF"} /> Please add a
              location for this employee
            </button> */}
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Job Role</div>
          <div className="section-subtitle">Update roles</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            {jobRoles.map((item) => (
              <button
                className={
                  isJobRoleSelected(item.id)
                    ? "select-button active"
                    : "select-button"
                }
                onClick={() => updateJobRole(item, isJobRoleSelected(item.id))}
                key={item.id}
              >
                {isJobRoleSelected(item.id) && isJobRoleFirstIndexed(item) && (
                  <div className="PrimaryTag">Primary</div>
                )}
                <Icon
                  icon={isJobRoleSelected(item.id) ? "tick" : "plus"}
                  size={12}
                  color={"#4385EF"}
                />{" "}
                {item.title}
              </button>
            ))}
          </Box>
        </Box>

        <Box className="section option-section">
          <div className="section-title">Employee Permissions</div>
          <div className="section-subtitle">Update employee permissions</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            {EMPLOYEE_PERMISSIONS.map((item) => (
              <button
                className={
                  employeeDetails.employeePermission === item
                    ? "select-button active"
                    : "select-button"
                }
                onClick={() => updateEmployeeDetail("employeePermission", item)}
                id={item}
              >
                <Icon
                  icon={
                    employeeDetails.employeePermission === item
                      ? "tick"
                      : "plus"
                  }
                  size={12}
                  color={"#4385EF"}
                />{" "}
                {item}
              </button>
            ))}
          </Box>
        </Box>

        <Box className="section option-section">
          <div className="section-title">Department</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <Select
              className="flex-react-select employee-section"
              placeholder=""
              value={selectedDepartment}
              options={departments}
              onChange={setSelectedDepartment}
            />
            {/*<div className="selected_employee_option">*/}
            {/*  <div className="option-text">Legal</div>*/}
            {/*  <Icon*/}
            {/*    icon="small-cross"*/}
            {/*    iconSize={20}*/}
            {/*    className="option-icon"*/}
            {/*  ></Icon>*/}
            {/*</div>*/}
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Level</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <Select
              className="flex-react-select employee-section"
              placeholder=""
              value={selectedLevel}
              onChange={setSelectedLevel}
              options={levels}
            />
          </Box>
        </Box>
        <Box className="section option-section">
          <div className="section-title">Manager(s)</div>
          <div className="section-subtitle">Who this employee reports to</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <Select
              style={{ width: "100% !improtant" }}
              className="flex-react-select employee-section"
              placeholder=""
              isMulti
              value={selectedManagers}
              onChange={(value: any) => {
                // console.log({ value })
                setSelectedManagers(value);
              }}
              options={staffMembersAsOptionType}
            />
          </Box>
        </Box>

        <Box className="section option-section">
          <div className="section-title">Custom Group(s)</div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <Select
              className="flex-react-select employee-section"
              placeholder=""
              isMulti
              value={selectedCustomGroups}
              onChange={(value: any) => {
                console.log({ value });
                setSelectedCustomGorups(value);
              }}
              options={customGroupsAsOptionType}
            />
          </Box>
        </Box>

        <div className="control-button-container">
          <button
            className={"nav-button"}
            onClick={() => {
              window.scrollTo(0, 0);
              setStep(1);
            }}
          >
            To Basic Information
          </button>
          <button
            className={
              isSecondStepContinueButtonEnable()
                ? "continue-button"
                : "continue-button-disabled"
            }
            onClick={updateRolesHandler}
            disabled={!isSecondStepContinueButtonEnable()}
          >
            {rolesLoading ? "Loading..." : "Save"}
          </button>
          <button
            className={"nav-button"}
            onClick={() => {
              window.scrollTo(0, 0);
              setStep(3);
            }}
          >
            To Payment Information
          </button>
        </div>
      </Box>
    );
  };

  const renderPaymentInformationForm = () => {
    return (
      <Box display="flex" flex-direction="column">
        <Box className="section option-section">
          <div className="section-title">Hourly Rate</div>
          <div className="section-subtitle">
            Update the desired pay rate for this employee
          </div>
          {employeeDetails.payRates.map((payRate, index) => (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              style={{ marginTop: "15px" }}
            >
              <div className="role-title">
                {payRate.role}
                {index === 0 ? (
                  <span className="primary-role-badge"> Primary</span>
                ) : null}
              </div>
              <div className="input-container">
                <div className="input-label">Pay Rate</div>
                <span className="input-currencyprefix">$</span>
                <input
                  value={payRate.amount / 100}
                  className="employeeinputspayrate"
                  type="number"
                  onChange={(e) =>
                    updateEmployeeDetail("amount", e.target.value, index)
                  }
                />
                {Number(payRate.amount) > 0 && (
                  <img
                    src={require("assets/input-check.svg").default}
                    className="input-check"
                  />
                )}
              </div>
            </Box>
          ))}
        </Box>
        <div className="control-button-container">
          <button
            className={"nav-button"}
            onClick={() => {
              window.scrollTo(0, 0);
              setStep(2);
            }}
          >
            To Employee Roles
          </button>
          <button
            className="continue-button"
            disabled={!isPayRatefilled() || paymentLoading}
            style={{
              backgroundColor:
                isPayRatefilled() && !paymentLoading ? "#4385EF" : "#D0E0FB",
            }}
            onClick={updatePaymentInfoHandler}
          >
            {paymentLoading ? "Loading" : "Save"}
          </button>
        </div>
      </Box>
    );
  };

  const renderEmployeeType = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">New hire or existing employee?</div>
        <div className="section-subtitle">
          Please let us know if this is a new hire or an existing employee
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <button
            className={`${
              employeeDetails.employeeHiringType === "new-hire" && "active"
            } select-button`}
            onClick={() =>
              updateEmployeeDetail("employeeHiringType", "new-hire")
            }
          >
            <Icon
              icon={
                employeeDetails.employeeHiringType === "new-hire"
                  ? "tick"
                  : "plus"
              }
              size={12}
              color={"#4385EF"}
            />
            This is a new hire
          </button>
          <button
            className={`${
              employeeDetails.employeeHiringType === "existing" && "active"
            } select-button`}
            onClick={() =>
              updateEmployeeDetail("employeeHiringType", "existing")
            }
          >
            <Icon
              icon={
                employeeDetails.employeeHiringType === "existing"
                  ? "tick"
                  : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            I’m onboarding an existing employee onto Tango
          </button>
        </Box>
      </Box>
      <button
        className="continue-button"
        onClick={async () => {
          if (employeeDetails.employeeHiringType === "existing") {
            setLoading(true);
            try {
              await setObjectAndSubmit();
            } catch (e) {
              console.log(e);
            } finally {
              setLoading(false);
            }
          } else {
            window.scrollTo(0, 0);
            setStep(5);
          }
        }}
      >
        Continue
      </button>
    </Box>
  );
  const renderEmployeeCheckList = () => (
    <Box display="flex" flex-direction="column">
      {/*{*/}
      {/*  employeeDetails.employeeHiringType === 'existing' && <Box className='section option-section'>*/}
      {/*    <div className='section-title'>Things We'll Do</div>*/}
      {/*    <div className='section-subtitle'>We will take care of the following for you</div>*/}
      {/*    <Box className='Employee-type-options' display='flex' flexDirection='row'>*/}
      {/*      <button className={`select-button active`}>*/}
      {/*        <Icon icon={'tick'} size={12} color={'#4385EF'}/>Get Brandon Set up on the Tango Staffing App*/}
      {/*      </button>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*}*/}
      {employeeDetails.employeeHiringType === "new-hire" && (
        <>
          <Box className="section option-section">
            <div className="section-title">Documents They Sign</div>
            <div className="section-subtitle">
              We will take care of the following for you
            </div>
            <Box
              className="Employee-type-options column-type"
              display="flex"
              flexDirection="column"
            >
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Offer Letter
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Team Member Sign Off
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                SOP Sign Off
              </button>
            </Box>
          </Box>
          <Box className="section option-section">
            <div className="section-title">Things We’ll Do</div>
            <div className="section-subtitle">
              We will take care of the following for you
            </div>
            <Box
              className="Employee-type-options column-type"
              display="flex"
              flexDirection="column"
            >
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Get Brandon Set up on the Tango Staffing App
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Ensure Brandon takes the mandatory awarness training
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Ensure Brandon takes his role based training tests
              </button>
            </Box>
          </Box>
        </>
      )}
      <button
        className="continue-button"
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(6);
        }}
      >
        Done
      </button>
    </Box>
  );
  const renderSelectOfferLetter = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">Select an Offer Letter</div>
        <div className="section-subtitle">
          Let us know which type of letter to send
        </div>
        <ul className="offer-letter-lists">
          {[1, 2].map((item, index) => (
            <li className="doc-container">
              <img src={require("assets/pdf-icon.svg").default} />
              <div className="doc-title">General Staff Offer Letter</div>
              <div className="doc-size">2.4 MB Doc File</div>
            </li>
          ))}
          <li className="doc-container create-template">
            <img src={require("assets/create-file-icon.svg").default} />
            <div className="doc-size">Create a Template</div>
          </li>
        </ul>
      </Box>
      <button
        className="continue-button"
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(6);
        }}
      >
        Preview & Send
      </button>
    </Box>
  );
  const renderSuccessAdded = () => {
    return (
      <Box style={{ textAlign: "center" }}>
        <img src={logo} className="successlogo" />
        <p className="successtextheader">success</p>
        <p className="succestext">
          {employeeDetails.firstName + " " + employeeDetails.lastName} has been
          successfully added to the team
        </p>
        <div className="arrowicon">
          <img src={tickArrow} height="23.47px" width="17.87" />
        </div>
      </Box>
    );
  };

  const renderContent = () => {
    if (!staffMember) {
      if (!fellowStaffMembers.length) {
        return <Spinner />;
      }
      if (fellowStaffMembers.length) {
        return <Navigate to="/manager/team" />;
      }
    }
    if (loading) {
      return <Spinner />;
    } else {
      return (
        <div id="add-employee-page">
          <Box className="container">
            <Box className="header">
              <button className="back-button" onClick={() => navigate(-1)}>
                <Icon icon="arrow-left" size={11} color={"#7C7F93"} />
                <span className="btn-text">Back</span>
              </button>
              <img src={logo} className="logo" />
              <Box>
                <div className="form-title">Edit Employee</div>
                <div className="form-subtitle">
                  {employeeDetails.firstName} {employeeDetails.lastName}
                </div>
              </Box>
            </Box>
            <Box className="bodycontent">
              <Box className="sidetab">
                <span className="checklist-header">Profile Categories</span>
                <span className="checklist-action">Update Information</span>
                <div className="checklistdiv">
                  <ul className="checklist">
                    {sidebarList.map((item) => {
                      const IconComponent = item.icon;
                      return (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            setStep(item.index);
                          }}
                          className={`${
                            item.index === step ? "checkedlistitem" : ""
                          }`}
                        >
                          <IconComponent
                            height={15}
                            width={15}
                            color={item.index === step ? "#4385EF" : ""}
                          />
                          <span className="navtext">{item.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Box>
              <Box className="tabcontent">
                {step === 1 && renderEmployeeInformation()}
                {step === 2 && renderEmployeeRolesForm()}
                {step === 3 && renderPaymentInformationForm()}
                {step === 4 && renderEmployeeType()}
                {step === 5 && renderEmployeeCheckList()}
                {step === 6 && renderSelectOfferLetter()}
              </Box>
            </Box>
            <FindLocationModal
              isOpen={showLocationModal}
              businesses={[business]}
              onClose={() => setShowLocationModal(false)}
              updateLocation={updateLocation}
              selectedLocations={employeeDetails.locations}
            />
          </Box>
        </div>
      );
    }
  };

  return <>{successPage ? renderSuccessAdded() : renderContent()}</>;
};

export default EditEmployee;
