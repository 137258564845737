import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import React from "react";
import { useNavigate } from "react-router-dom";

import HorizontalTable from "components/Table/HorizontalTable";

import { usePayroll } from "./hooks/usePayroll";
import { OptionType } from "./hooks/usePayrollDateConfiguration";

const { TextField, DateRangePickerInput } = tangoComponents;

interface PayrollTableHeaderProps {
  loading: boolean;
  onRefreshClick: () => void;
  title: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
  viablePayrollReportEdgesData: string[];
  onDateRangePickerChange: (value: string | Date) => void;
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  customDateRangeOptions: OptionType[];
  onCustomDateRangeChange: (value: string) => void;
  formattedTotalGrossPay: string;
}

const PayrollTableHeader = (props: PayrollTableHeaderProps) => {
  return (
    <div className={"flex items-center p-6 justify-between"}>
      <div className="flex items-center">
        <div className={"font-lato-black text-xl text-black mr-5"}>{props.title}</div>
        <div className="w-px bg-grey-1 h-9" />
        <div className="font-lato-medium text-sm text-black ml-5">Total Gross pay: {props.formattedTotalGrossPay}</div>
      </div>
      <div className={"flex items-center"}>
        <div
          onClick={props.onRefreshClick}
          className={"cursor-pointer text-xs font-lato-bold text-black mr-7"}
        >
          Refresh
        </div>

        {props.loading ? (
          <Spinner size={SpinnerSize.SMALL} className="mr-7" />
        ) : null}

        <div className={"h-9 w-px bg-grey-1 mr-7"} />

        <DateRangePickerInput
          // @ts-ignore
          onSelect={(o: OptionType) => props.onCustomDateRangeChange(o.value)}
          size="small"

          onChange={props.onDateRangePickerChange}
          //@ts-ignore
          value={[props.dateRange.startDate, props.dateRange.endDate]}
          customRangeOptions={props.customDateRangeOptions}
        />

        <TextField
          className="hori-table-search-input"
          value={props.searchValue}
          // @ts-ignore
          onChange={(e) => props.onSearchChange(e.target.value)}
          sizeType="medium"
          placeholder="Search"
        />
      </div>
    </div>
  );
};

const PayrollTable = () => {
  const {
    columns,
    tableData,
    viablePayrollReportEdges,
    dateRangeChangeHandler,
    dateRange,
    selectedStartISODateString,
    selectedEndISODateString,
    customDateRangeOptions,
    onCustomDateRangeChange,
    generalLoading,
    staffMemberOrientedPayroll,
    onSearchChange,
    searchFilter,
    formattedTotalGrossPay
  } = usePayroll();

  console.log("searchFilter", searchFilter);

  const navigate = useNavigate();

  return (
    <div style={{ height: "93vh", overflowY: "scroll", marginLeft: "32px", marginRight: "32px" }}>
      <HorizontalTable
        title="Payroll"
        columns={columns}
        data={tableData}
        instructions={{}}
        isEditing={false}
        primaryActionLabel="Add new employee"
        primaryActionHandler={() => null}
        onRowClick={(uid) =>
          navigate(uid, {
            state: {
              startDate: selectedStartISODateString,
              endDate: selectedEndISODateString,
            },
          })
        }
        hideCheckboxes={true}
        customHeaderComponent={
          <PayrollTableHeader
            loading={generalLoading}
            viablePayrollReportEdgesData={viablePayrollReportEdges.data}
            onRefreshClick={staffMemberOrientedPayroll.refetch}
            title="Payroll"
            searchValue={searchFilter}
            onSearchChange={onSearchChange}
            //@ts-ignore
            onDateRangePickerChange={dateRangeChangeHandler}
            dateRange={dateRange}
            customDateRangeOptions={customDateRangeOptions}
            onCustomDateRangeChange={onCustomDateRangeChange}
            formattedTotalGrossPay={formattedTotalGrossPay}
          />
        }
      />
    </div>
  );
};

export default PayrollTable;
