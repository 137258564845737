import { Button, Icon, Intent, Tag } from "@blueprintjs/core";
import moment from "moment";

const FORMAT = "dddd, LL";
const FORMAT_TIME = "dddd, LL LT";

export const MomentDate: React.FunctionComponent<{
  date: Date;
  format?: string;
  withTime?: boolean;
}> = ({ date, withTime = false, format = withTime ? FORMAT_TIME : FORMAT }) => {
  const m = moment(date);
  if (m.isValid()) {
    return (
      <Tag intent={Intent.PRIMARY} style={{ marginRight: 10 }}>
        {m.format(format)}
      </Tag>
    );
  }
  return (
    <Tag minimal style={{ marginRight: 10 }}>
      no date
    </Tag>
  );
};

export const MomentDateRange: React.FunctionComponent<{
  range: [Date, Date];
  removeFunc: (() => void) | null;
  format?: string;
  withTime?: boolean;
  showDelete?: boolean;
  center?: boolean;
}> = ({
  range: [start, end],
  removeFunc,
  withTime = false,
  format = withTime ? FORMAT_TIME : FORMAT,
  showDelete = true,
  center = false,
}) => (
  <div
    className="docs-date-range"
    style={
      center
        ? { display: "flex", justifyContent: "center", width: "100%" }
        : { marginBottom: 5 }
    }
  >
    {showDelete ? (
      <Button
        minimal
        icon="cross"
        onClick={() => {
          if (removeFunc) {
            removeFunc();
          }
        }}
      >
        <MomentDate withTime={withTime} date={start} format={format} />
        <Icon icon="arrow-right" style={{ marginRight: 10 }} />
        <MomentDate withTime={withTime} date={end} format={format} />
      </Button>
    ) : (
      <>
        <MomentDate withTime={withTime} date={start} format={format} />
        <Icon icon="arrow-right" style={{ marginRight: 10 }} />
        <MomentDate withTime={withTime} date={end} format={format} />
      </>
    )}
  </div>
);
