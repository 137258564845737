import { Dialog, Icon, InputGroup, TextArea } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  AddComment,
  ChangeTicketStatus,
  DeleteTicket,
  EditTicketCustomProperty,
} from "models/boards";

import SelectPhoto from "assets/selectPhoto.svg";

import Closeicon from "../../../../../assets/Closeicon.svg";
import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import FormView from "../../../../FormResponses/FormView";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedTicketData: any;
  selectedBoard: any;
  ticketTitle: string;
  formResponseData: any;
}

export function ExpandTicket({
  isOpen,
  closeModal,
  selectedTicketData,
  selectedBoard,
  ticketTitle,
  formResponseData,
}: Props) {
  const db = firebase.firestore();
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const [comment, setComment] = useState("");
  const [ticketCustomProperties, setTicketCustomProperties]: any = useState({});

  const renderTicketPriority = (priority: string) => {
    switch (priority) {
      case "Low":
        return <span className="priority color-red">Low Priority</span>;
      case "Mid":
        return <span className="priority color-yellow">Mid Priority</span>;
      case "High":
        return <span className="priority color-blue">High Priority</span>;
      default:
        return "";
    }
  };

  const handleCustomPropertyValChange = (key: string, value: string) => {
    setTicketCustomProperties({
      ...ticketCustomProperties,
      [key]: {
        ...ticketCustomProperties[key],
        value,
      },
    });
  };

  const assignedMember = useMemo(() => {
    if (
      Object.keys(selectedTicketData).length > 0 &&
      selectedTicketData.fixedProperties
    ) {
      let property = selectedTicketData?.fixedProperties;
      if (property.Assignpeopletoticket.length > 0) {
        return property.Assignpeopletoticket[0];
      }
    }
    return {};
  }, [selectedTicketData]);

  // const [selectedStatus, setSelectedStatus] = useState({
  //   name: "selected",
  //   bgcolor: "#333333",
  // });

  const selectedStatusColor: any = useMemo(() => {
    if (selectedBoard && selectedTicketData) {
      let Arr: any[] = [];
      Arr = selectedBoard?.statuses?.filter(
        (item: any) => item.name === selectedTicketData.Status
      );
      console.log("Arr", Arr);
      if (Arr?.length > 0 && Arr[0]?.hasOwnProperty("bgcolor")) {
        return Arr[0]?.bgcolor;
      }
      return "";
    }
  }, [selectedBoard, selectedTicketData]);

  useEffect(() => {
    if (
      selectedTicketData &&
      selectedTicketData?.customProperties?.length > 0
    ) {
      let obj: any = {};
      selectedTicketData.customProperties.forEach((element: any) => {
        obj[element.name] = element;
      });
      setTicketCustomProperties(obj);
    }
  }, [selectedTicketData]);

  // const statusColor = selectedBoard.color;
  // console.log("helllo hello", selectedBoard.color);

  const renderName = () => {
    let name = "";
    if (firstName || lastName) {
      if (firstName) {
        name = firstName + " ";
      }
      if (lastName) {
        name = name + lastName;
      }
      return "Assigned to" + " " + name;
    } else {
      return "Assigned to no one";
    }
  };

  const firstName = assignedMember?.contact?.firstName;
  const lastName = assignedMember?.contact?.lastName;
  // console.log(selectedBoard?.statuses)
  return (
    <>
      <Dialog isOpen={isOpen} className="expandTicket" onClose={closeModal}>
        <div className="modal-header">
          <div className="modal-title">
            <div className="action-div-row">
              <div
                className="close-img"
                style={{ cursor: "pointer" }}
                onClick={() => closeModal()}
              >
                <img src={Closeicon} />
              </div>
              <div className="right-side-header">
                <div className="action-div-row">
                  <div className="title-emp">{renderName()}</div>
                  <div className="emp-photo">
                    {assignedMember?.imgUrl != null ? (
                      <div>
                        <img src={SelectPhoto} />
                      </div>
                    ) : (
                      <div className="user-avatar">
                        {firstName?.charAt(0)?.toUpperCase()}
                        {lastName?.charAt(0)?.toUpperCase()}
                      </div>
                    )}
                  </div>
                  {/* <div>
                    <img src={SelectPhoto} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <div className="action-div-row">
            <div className="col-6 border-right">
              <div className="action-div-row-inner border-bottom p10">
                <div>
                  <div>
                    <label className="name">{ticketTitle}</label>
                  </div>
                  {renderTicketPriority(
                    selectedTicketData?.fixedProperties?.Ticketpriority
                  )}
                </div>
                <div className="select">
                  <Popover2
                    interactionKind="click"
                    placement="bottom"
                    minimal={true}
                    content={
                      <div className="selection-position-container">
                        <div className="title">Status</div>
                        {selectedBoard?.statuses
                          ?.filter((status: any) => !status.Deleted)
                          .map((item: any) => {
                            // console.log("selected board", item);
                            return (
                              <div
                                className="select-position"
                                onClick={() => {
                                  if (selectedTicketData.id && item.name) {
                                    ChangeTicketStatus(
                                      selectedTicketData.id,
                                      item.name
                                    );
                                  }
                                }}
                              >
                                <Icon icon="dot" color={item.bgcolor} />
                                <label>{item.name}</label>
                              </div>
                            );
                          })}
                      </div>
                    }
                    renderTarget={({ isOpen, ref, ...targetProps }) => (
                      <div {...targetProps} ref={ref}>
                        <div
                          className="top-border"
                          style={{
                            backgroundColor: selectedStatusColor,
                          }}
                        ></div>
                        <div className="select-part">
                          <label>
                            {selectedTicketData?.Status}{" "}
                            <Icon
                              icon="dot"
                              className="select-data-icon"
                              color={selectedStatusColor}
                            />
                          </label>
                          <Icon icon="chevron-down" iconSize={12} />
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="custom-property-div">
                {selectedTicketData?.customProperties?.length > 0
                  ? selectedTicketData.customProperties.map(
                      (item: any, key: number) => {
                        return (
                          <div className="inputs">
                            <label className="i-title">{item?.name}</label>
                            <InputGroup
                              type="text"
                              placeholder="Server Position"
                              className="TextBox"
                              dir="auto"
                              value={
                                selectedTicketData &&
                                ticketCustomProperties[item?.name]?.value
                              }
                              onChange={(e) => {
                                handleCustomPropertyValChange(
                                  item?.name,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        );
                      }
                    )
                  : null}
                {formResponseData ? (
                  <FormView selectedResponse={formResponseData} />
                ) : null}
              </div>

              {/* <div className="inputs">
                <label className="i-title">Imported Form Properties</label>
              </div> */}
              <div style={{ width: "100%" }}>
                <button
                  className="save-btn"
                  onClick={() => {
                    if (selectedTicketData?.id) {
                      EditTicketCustomProperty(
                        selectedTicketData?.id,
                        ticketCustomProperties
                      );
                      closeModal();
                    }
                  }}
                >
                  Save
                </button>
              </div>

              <div className="action-div-row-bottom p10">
                <div>
                  <label
                    className="delete pointer"
                    onClick={() => {
                      if (selectedTicketData?.id) {
                        DeleteTicket(selectedTicketData?.id);
                        closeModal();
                      }
                    }}
                  >
                    Delete Ticket
                  </label>
                </div>
                <div>
                  <label className="archive">
                    <Icon icon="import" className="icon" /> Archive Ticket
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6 blue-color">
              {/* messge area */}
              <div className="message-area">
                {selectedTicketData?.Comments?.map((item: any) => {
                  return (
                    <div>
                      <div className="action-div-row-right-side">
                        <div>
                          <label className="msg-credit">
                            {" "}
                            {item?.author?.firstName} {item?.author?.lastName}
                          </label>
                        </div>
                        <div>
                          <label className="msg-credit">
                            {moment(item?.author?.createdAt?.toDate())
                              .format("MM/DD/YY h:mm A")
                              .toString()}
                          </label>
                          {/* <label className="msg-credit">02/14/2022 3:41 PM</label> */}
                        </div>
                      </div>
                      <div className="msg-container">
                        <div className="comment-description">
                          {item?.description}
                        </div>
                        {/* <p>
                          <span>@Kevin Manning</span> - this person looks pretty
                          solid
                        </p> */}
                      </div>
                    </div>
                  );
                })}

                {/* second messgae */}
                {/* <div className="action-div-row-right-side">
                  <div>
                    <label className="msg-credit">Shae Salazer</label>
                  </div>
                  <div>
                    <label className="msg-credit">02/14/2022 3:41 PM</label>
                  </div>
                </div>
                <div className="msg-container">
                  <p>
                    This is just how a comment would work in this screen with a
                    ton of words on this screen to show how it will go down and
                    more down.
                  </p>
                </div> */}
              </div>

              {/* messgae type area */}
              <div className="message-type-area">
                <TextArea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  dir="auto"
                  placeholder="Type to send a comment.."
                  className="textArea"
                />
                <div>
                  <button
                    className="trigger-btn"
                    disabled={comment?.length > 2 ? false : true}
                    onClick={() => {
                      if (comment.length > 2) {
                        AddComment(
                          selectedTicketData?.id,
                          user?.id,
                          user.contact.firstName,
                          user.contact.lastName,
                          comment
                        );
                        setComment("");
                      }
                    }}
                  >
                    Enter Note
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer">
          <button className="trigger-btn">Enter</button>
        </div> */}
      </Dialog>
    </>
  );
}

export default ExpandTicket;
