import { Dialog, Icon } from "@blueprintjs/core";
import React from "react";

import "../OpenedByModal/index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const SearchMemoModal = ({ isOpen, onClose }: Props) => {
  return (
    <Dialog
      isOpen={isOpen}
      className="openedby-modal"
      hasBackdrop
      onClose={onClose}
    >
      <div>
        <div className="openedby-modal-search-container">
          <Icon icon="search" color="#888888" iconSize={12} />
          <input placeholder="Find a memo" />
        </div>
        <div className="openedby-modal-body">
          <div className="openedby-modal-header">
            <div>Memo Results</div>
          </div>
          <div className="table-container">
            <div className="table-column">
              <div className="column-title">Sender</div>
              <div className="column-cell">Memo Sender</div>
              <div className="column-cell">Memo Sender</div>
              <div className="column-cell">Memo Sender</div>
            </div>
            <div className="table-column">
              <div className="column-title">Memo Title</div>
              <div className="column-cell">Memo Title</div>
              <div className="column-cell">Memo Title</div>
              <div className="column-cell">Memo Title</div>
            </div>
            <div className="table-column">
              <div className="column-title">Date</div>
              <div className="column-cell">11/02/2021</div>
              <div className="column-cell">11/02/2021</div>
              <div className="column-cell">11/02/2021</div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default SearchMemoModal;
