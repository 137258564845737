import { Button, Dialog } from "@blueprintjs/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import StripeCardInput from "./StripeCardInput";
import "./index.css";

//@ts-ignore
const stripePromise = loadStripe(
  // @ts-ignore
  process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY
);

const Billing = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [openCreditCard, setOpenCard] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);
  const [showBillingSummary, setShowBillingSummary] = useState(false);

  const handleRemoveCard = async () => {
    setProcessing(true);
    try {
      const response = await axios({
        method: "post",
        url: "https://us-central1-tango-2.cloudfunctions.net/linkCardStripe",
        data: {
          removeCard: true,
          businessId: business.id,
          stripeId: business.paymentInfo.stripeId,
          cardId: business.paymentInfo.card.id,
        },
      });
      return response;
    } catch (error) {
      setProcessing(false);
      //@ts-ignore
      alert(error.response.data);
    } finally {
      setProcessing(false);
    }
  };

  if (business) {
    return (
      <div id="billing-info">
        <div className="section-name">Billing</div>
        <div className="section-body">
          <div className="subtitle">Product Information</div>
          <div className="section-row">
            <div className="field-title">Tango Products</div>
            <div className="field-value">
              Tango Speen, Tango Staffing, Tango Flex
            </div>
          </div>
          <div className="section-row">
            <div className="field-title">Billing Summary</div>
            <div className="field-value">
              Your set up comes to a total of $150 per month and will renew on
              August 27th, 2021.
            </div>
          </div>

          <div className="subtitle">Billing Information</div>
          <div className="section-row">
            <div className="field-title">Payment Method</div>
            {business.paymentInfo.card ? (
              <div className="field-value">
                {business.paymentInfo.card.brand} ending in{" "}
                {business.paymentInfo.card.last4}{" "}
                <span
                  onClick={() => setOpenCard(true)}
                  style={{
                    cursor: "pointer",
                    paddingLeft: "100px",
                    color: "#55AAD7",
                  }}
                >
                  Update
                </span>
              </div>
            ) : (
              <div className="field-value">
                <span
                  onClick={() => setOpenCard(true)}
                  style={{ cursor: "pointer", color: "#55AAD7" }}
                >
                  Add payment method
                </span>
              </div>
            )}
          </div>
          <div className="section-row">
            <div className="field-title">Billing Interval</div>
            <div className="field-value">Monthly</div>
          </div>
          <div className="section-row">
            <div className="field-title">Billing Email</div>
            <div className="field-value">
              {business.owner.firstName} {business.owner.lastName}{" "}
            </div>
          </div>
          <div className="section-row">
            <div className="field-title">Billing Address</div>
            <div className="field-value">{business.location.address}</div>
          </div>

          <div className="subtitle">Invoices</div>
          <div className="section-row invoice-row">
            <div className="field-title">May 27th, 2021</div>
            <div className="field-value">
              <div className="paid">PAID</div>
              <div className="amount">$155.0</div>
              <div
                className="view-invoice"
                onClick={() => setShowBillingSummary(true)}
              >
                View Summary
              </div>
            </div>
          </div>
          <div className="section-row invoice-row">
            <div className="field-title">April 27th, 2021</div>
            <div className="field-value">
              <div className="paid">PAID</div>
              <div className="amount">$155.0</div>
              <div
                className="view-invoice"
                onClick={() => setShowBillingSummary(true)}
              >
                View Summary
              </div>
            </div>
          </div>
        </div>

        <Dialog
          icon="credit-card"
          isOpen={openCreditCard}
          onClose={() => setOpenCard(false)}
          title="Update Payment Method"
          style={{ width: "55%", fontFamily: "SFProText-Regular" }}
        >
          <div style={{ padding: "4%" }}>
            <div style={{ width: "60%" }}>
              {business && business.paymentInfo.card && (
                <h4>
                  {business.paymentInfo.card.brand} ending in{" "}
                  {business.paymentInfo.card.last4}
                  <Button
                    loading={processing}
                    minimal
                    style={{ float: "right", color: "#55AAD7" }}
                    onClick={() => handleRemoveCard()}
                  >
                    Remove
                  </Button>
                </h4>
              )}
              <br />

              <Elements stripe={stripePromise}>
                <StripeCardInput />
              </Elements>
            </div>
          </div>
        </Dialog>

        <Dialog
          className="billing-summary-modal"
          isOpen={showBillingSummary}
          onClose={() => setShowBillingSummary(false)}
        >
          <div className="header">
            <div className="header-left">
              <div className="header-title">Billing Summary</div>
              <div className="header-subtitle">May 1 - May 31</div>
            </div>
            <div>
              <Button
                text="Export"
                rightIcon="share"
                className="export-button"
                onClick={() => {
                  setShowBillingSummary(false);
                  setShowExportModal(true);
                }}
              />
            </div>
          </div>
          <div className="body">
            <div className="product-subscription-summary">
              <div className="title">Product Subscription Summary</div>
              <div className="price">$74</div>
            </div>
            <div className="subscriptions">
              <div className="subscription-item">
                <div>
                  <div className="product-name">Tango Staffing</div>
                  <div className="subscription-plan">$40 per month</div>
                </div>
                <div className="product-price">$40</div>
              </div>
              <div className="subscription-item">
                <div>
                  <div className="product-name">Tango Staffing</div>
                  <div className="subscription-plan">$40 per month</div>
                </div>
                <div className="product-price">$40</div>
              </div>
            </div>
            <div className="product-subscription-summary">
              <div className="title">Processing Summary</div>
              <div className="price">$1,460</div>
            </div>
            <div className="subscriptions processing-summary">
              <div className="subscription-item">
                <div>
                  <div className="product-name">
                    $88,460 in net sales at 2.6%
                  </div>
                </div>
                <div className="product-price">$800</div>
              </div>
              <div className="subscription-item">
                <div>
                  <div className="product-name">544 sales at $0.15</div>
                </div>
                <div className="product-price">$660</div>
              </div>
            </div>
          </div>
          <table className="summary-table">
            <tr>
              <th>Date</th>
              <th>Number of Sales</th>
              <th>Net Sales</th>
              <th>Transaction Fees</th>
              <th>Sales Fees</th>
              <th>Net Deposits</th>
            </tr>
            <tr>
              <td>May 1</td>
              <td>84</td>
              <td>$3,461</td>
              <td>$12.60</td>
              <td>$89.98</td>
              <td>$3,358</td>
            </tr>
          </table>
        </Dialog>
        <Dialog
          isOpen={showExportModal}
          className="export-modal"
          onClose={() => setShowExportModal(false)}
        >
          <div className="header">
            <div className="header-title">Export</div>
            <div className="header-subtitle">
              Billing Summary | May 1 - May 31
            </div>
            <img
              src={require("../../../assets/back-arrow.svg").default}
              className="back-arrow"
              onClick={() => {
                setShowBillingSummary(true);
                setShowExportModal(false);
              }}
            />
          </div>
          <div className="body">
            <div className="export-methods-container">
              <div onClick={() => setSelectedMethod("Email")}>
                <div
                  className={`export-method ${
                    selectedMethod === "Email" ? "active" : ""
                  }`}
                />
                <div className="method-name">Email</div>
              </div>
              <div onClick={() => setSelectedMethod("PDF")}>
                <div
                  className={`export-method ${
                    selectedMethod === "PDF" ? "active" : ""
                  }`}
                />
                <div className="method-name">PDF</div>
              </div>
              <div onClick={() => setSelectedMethod("Excel")}>
                <div
                  className={`export-method ${
                    selectedMethod === "Excel" ? "active" : ""
                  }`}
                />
                <div className="method-name">Excel</div>
              </div>
            </div>
            <Button
              text={`Export ${selectedMethod}`}
              className="export-button"
            />
          </div>
        </Dialog>
      </div>
    );
  }

  return null;
};

export default Billing;
