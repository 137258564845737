import { Button, Dialog, Icon } from "@blueprintjs/core";
import firebase from "config/firebase";
import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  editType: string;
  selectedStatushData: any;
  businessId: string;
}

const EditStatusModal = ({
  isOpen,
  onClose,
  editType,
  selectedStatushData,
  businessId,
}: Props) => {
  const [status, setStatus] = useState(selectedStatushData?.title);
  const [selectedColor, setSelectedColor] = useState("");
  const db = firebase.firestore();

  const editStatusName = (name: string) => {
    if (selectedStatushData.id && name) {
      return db
        .collection("EmployeeApplicationStatuses")
        .doc(selectedStatushData.id)
        .update({ name: name })
        .then(() => {
          db.collection("EmployeeApplications")
            .where("businessId", "==", businessId)
            .where("status", "==", selectedStatushData.title)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                doc.ref.update({ status: name });
              });
            });
          onClose();
        })
        .catch((err) => console.log(err));
    }
  };

  const editStatusColor = (color: string) => {
    if (selectedStatushData?.id && selectedColor) {
      return db
        .collection("EmployeeApplicationStatuses")
        .doc(selectedStatushData.id)
        .update({ colorCode: color })
        .then(() => {
          onClose();
        })
        .catch((err) => console.log(err));
    }
  };

  const renderButton = () => {
    if (editType == "name") {
      if (status?.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (selectedStatushData) {
      setStatus(selectedStatushData.title);
    }
  }, [selectedStatushData]);

  return (
    <Dialog
      portalClassName="location-modal-portal"
      isOpen={isOpen}
      onClose={onClose}
      className="edit-status"
    >
      <div className="header">
        <Icon
          icon="cross"
          className="close-button"
          color="#424452"
          iconSize={20}
          onClick={() => onClose()}
        />
        <span className="modal-title">Edit Status</span>
        <span></span>
      </div>
      {editType == "name" && (
        <div className="search-location">
          <div className="to">Rename Status :</div>
          <input
            placeholder="Status Name"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </div>
      )}
      {editType == "color" && (
        <div className="color-picker">
          <HexColorPicker color={selectedColor} onChange={setSelectedColor} />
          color:{selectedColor}
        </div>
      )}

      <div className="body">
        <Button
          onClick={() =>
            editType == "color"
              ? editStatusColor(selectedColor)
              : editStatusName(status)
          }
          text="Save"
          className="continue-button"
          disabled={renderButton()}
        />
      </div>
    </Dialog>
  );
};

export default EditStatusModal;
