import UIComponents from "@tangopay/tango-ui-library";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Header from "components/Header";

import HorizontalTable from "../../../components/Table/HorizontalTable";
import Sidebar from "../Sidebar";
import { headerRoutes, recipesRoute } from "../routes";
import useAllergens from "./useVendorDetail";

const { Icon, Button } = UIComponents;
const VendorDetail = () => {
  const navigate = useNavigate();
  const {
    allergensData,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
  } = useAllergens();

  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header
        routes={headerRoutes}
        selectedRoute={"/inventory/items/basic"}
      />
      <div className="flex h-full">
        <div
          className={
            "border-r border-grey-1 border-solid border-t-0 border-b-0"
          }
          style={{ width: "10%" }}
        >
          <Sidebar routes={recipesRoute} />
        </div>

        <div style={{ width: "90%" }} className={"bg-off-white"}>
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                Avocado Vendors
              </div>
            </div>
            <div className={"flex items-center"}>
              <Button label={"Print"} type={"btn-style-2"} size={"btn-large"} />
            </div>
          </div>
          <div className={"flex p-8"}>
            <HorizontalTable
              title="Vendors"
              columns={columns}
              data={allergensData}
              instructions={instructions}
              saveResults={noOp}
              setEditing={setEditing}
              isEditing={isEditing}
              primaryActionLabel="New Allergens"
              primaryActionHandler={noOp}
              deleteIds={logIt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDetail;
