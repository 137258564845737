import { Button } from "@blueprintjs/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import "./index.css";

const StripeCardInput: FC = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const stripe = useStripe(); //stripe
  const elements = useElements(); //stripe
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmitCard = (event: any) => {
    event.preventDefault();
    setProcessing(true);

    //@ts-ignore
    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements) {
      return setProcessing(false);
    }

    //@ts-ignore
    stripe.createToken(cardElement).then(async (response) => {
      if (!cardElement) {
        console.log("no card input");
        return setProcessing(false);
      } else if (!response.token) {
        return setProcessing(false);
      } else {
        try {
          //send token to cloud function to link card
          const responseFromFunction = await axios({
            method: "post",
            url: "https://us-central1-tango-2.cloudfunctions.net/linkCardStripe",
            data: {
              stripeToken: response.token.id,
              businessId: business.id,
              stripeId: business.paymentInfo.stripeId,
            },
          });

          return responseFromFunction;
        } catch (error) {
          console.log("error link payment method", error);
          alert("There was an error linking your payment method");
          setProcessing(false);
        } finally {
          setSuccess(true);
          setProcessing(false);
        }
      }
    });
  };
  return (
    <div id="billing-info">
      <form onSubmit={handleSubmitCard}>
        {!success && (
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "3px",
              padding: "10px",
            }}
          >
            <CardElement
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: { color: "#9e2146" },
                },
              }}
            />
            {/* 
                  <span style={{fontSize:'.75em'}}>
                      Secure 256 bit encryption
                  </span> */}
          </div>
        )}

        {success ? (
          <Button
            style={{ backgroundColor: "green", color: "white" }}
            minimal
            large
          >
            Success
          </Button>
        ) : (
          <div>
            <br /> <br />
            <Button type="submit" loading={processing}>
              Link New Payment Method
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default StripeCardInput;
