import tangoComponents from "@tangopay/tango-ui-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { apiErrorHandler } from "controllers/core";
import {
  AvailabilityEntry,
  EmployeeDetails as IEmployeeDetails,
  updateAvailabilityInfo,
} from "controllers/team";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import HorizontalTable, {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";

const { Dropdown, TimePickerInput, Switch, Icon } = tangoComponents;

interface AvailabilityInfoProps {
  teamTableData: IEmployeeDetails;
}

const AvailabilityInfo = (props: AvailabilityInfoProps) => {
  const [availabilityInfoEditing, setAvailabilityInfoEditing] =
    React.useState(false);

  const onAvailabilityInfoEditStart = useCallback(
    (v: boolean) => {
      setAvailabilityInfoEditing(v);
    },
    [setAvailabilityInfoEditing]
  );
  const availabilityInfoColumns: ColumnInstruction<any>[] = moment
    .weekdays()
    .map((weekDay) => ({ type: "data", header: weekDay, attribute: weekDay }));
  console.log("availabilityInfoColumns", availabilityInfoColumns);
  const availabilityInfoData = useMemo(() => {
    return [
      {
        ..._.mapValues(
          _.keyBy(props.teamTableData.availabilityInfo, "day"),
          (d) => d
        ),
        uniqueId: props.teamTableData.uid,
      },
    ];
  }, [props.teamTableData.availabilityInfo]);

  const availabilityInfoInstructions: {
    [x: string]: RenderInstruction<any>;
  } = {};

  moment.weekdays().forEach((weekDay) => {
    availabilityInfoInstructions[weekDay] = {
      type: "custom",
      //@ts-ignore
      viewComponent: (props: { value: AvailabilityEntry }) => {
        if (props.value.available) {
          return (
            <div className="flex flex-row justify-start items-center">
              <div className="mr-5">{props.value.startTime}</div>
              <Icon size="small" name="arrow-right" color="black" />
              <div className="ml-5"> {props.value.endTime}</div>
            </div>
          );
        }

        return (
          <div className="flex flex-row justify-start items-center">
            <div>Not Available</div>
          </div>
        );
      },
      //@ts-ignore
      editComponent: (props: {
        value: AvailabilityEntry;
        onChange: (v: AvailabilityEntry) => void;
      }) => {
        return (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center">
              <TimePickerInput
                size="small"
                value={moment(props.value.startTime, "HH:mm").toDate()}
                onChange={(v) =>
                  props.onChange({
                    ...props.value,
                    startTime: moment(v).format("HH:mm"),
                  })
                }
              />
              <Icon size="small" name="arrow-right" color="black" />
              <TimePickerInput
                size="small"
                value={moment(props.value.endTime, "HH:mm").toDate()}
                onChange={(v) =>
                  props.onChange({
                    ...props.value,
                    endTime: moment(v).format("HH:mm"),
                  })
                }
              />
            </div>
            <div className="flex flex-row items-center">
              <Switch
                showLabel={false}
                checked={props.value.available}
                size="small"
                onChange={(nv) =>
                  props.onChange({ ...props.value, available: nv })
                }
              />
              <div className="ml-2">
                {props.value.available ? "Available" : "Not Available"}
              </div>
            </div>
          </div>
        );
      },
    };
  });

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const queryClient = useQueryClient();

  const invalidateDetailsRequest = useCallback(() => {
    queryClient.invalidateQueries([
      "employeeDetails",
      business?.id,
      props.teamTableData.uid,
    ]);
  }, [queryClient, business?.id, props.teamTableData.uid]);

  const saveAvailabilityInfoMutation = useMutation(
    (data: {
      uid: string;
      businessId: string;
      availabilityEntries: {
        day: string;
        startTime: string;
        endTime: string;
        available: boolean;
      }[];
    }) => {
      return updateAvailabilityInfo(
        data.businessId,
        data.uid,
        data.availabilityEntries
      );
    },
    {
      onSuccess: invalidateDetailsRequest,
      onError: apiErrorHandler,
    }
  );

  const onCompensationInfoSaveHandler = useCallback(
    async (updateState: UpdateState) => {
      if (!business?.id || !props.teamTableData.uid) {
        return;
      }
      const incomingUpdates = updateState[props.teamTableData.uid] ?? {};
      if (!_.keys(incomingUpdates).length) {
        return;
      }

      const updates = _.values(incomingUpdates);
      const userId = props.teamTableData.uid;
      const businessId = business.id;

      console.log("updates", updates);

      const existingEntries = props.teamTableData.availabilityInfo;

      const updatedEntries = existingEntries.map((ae) => {
        const update = updates.find((u) => u.key === ae.day);
        if (update) {
          return update?.newValue as AvailabilityEntry;
        }
        return ae;
      });

      await saveAvailabilityInfoMutation.mutateAsync({
        uid: userId,
        businessId,
        availabilityEntries: updatedEntries,
      });
    },
    [
      saveAvailabilityInfoMutation,
      props.teamTableData.availabilityInfo,
      business?.id,
    ]
  );

  return (
    <>
      <div className="mb-10">
        <HorizontalTable
          title="Availability Info"
          loading={saveAvailabilityInfoMutation.isLoading}
          isEditing={availabilityInfoEditing}
          columns={availabilityInfoColumns}
          data={availabilityInfoData}
          setEditing={onAvailabilityInfoEditStart}
          instructions={availabilityInfoInstructions}
          saveResults={onCompensationInfoSaveHandler}
          isVertical
        />
      </div>
    </>
  );
};

export default AvailabilityInfo;
