import _ from "lodash";
import {
  ChangeEvent,
  EventHandler,
  KeyboardEvent,
  useMemo,
  useState,
} from "react";
import { DropResult } from "react-beautiful-dnd";

export interface Invite {
  staffId: string;
  status: "accepted" | "invited" | "rejected";
}
export interface GroupedAccounts {
  [email: string]: CalendarAccount[];
}
export interface CalendarAccount {
  id: string;
  email: string;
  calendarName: string;
  businessId: string;
  accountId: string;
  members: string[];
  color: string;
  deleted: boolean;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}
const accountsData: CalendarAccount[] = [
  {
    email: "joe@gmail.com",
    id: "1",
    calendarName: "Personal",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
    color: "pink",
    deleted: false,
    enabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    email: "joe@gmail.com",
    id: "2",
    calendarName: "Ops Team",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
    color: "blue",
    deleted: false,
    enabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    email: "joe@gmail.com",
    id: "3",
    calendarName: "Company Events",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
    color: "purple",
    deleted: false,
    enabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    email: "joe@outlook.com",
    id: "4",
    calendarName: "Client Project 1",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
    color: "orange",
    deleted: false,
    enabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    email: "joe@outlook.com",
    id: "5",
    calendarName: "Client Project 2",
    businessId: "FMiMLZ56yq4lLMQMz7aw",
    accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
    members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
    color: "yellow",
    deleted: false,
    enabled: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

// const reorder = (calendars: CalendarAccount, startIndex: number, endIndex: number) => {
//   const result = Array.from(calendars);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);
//
//   return result;
// };

const useAccounts = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedWorkSpace, setSelectedWorkspace] =
    useState<CalendarAccount | null>(null);
  const [showViewMembers, setShowViewMembers] = useState(false);
  const [showInvitePeopleModal, setShowInvitePeopleModal] = useState(false);
  const [showDeleteCalendarModal, setShowDeleteCalendarModal] = useState(false);
  const [showMergeCalendarModal, setShowMergeCalendarModal] = useState(false);
  const [showMergeCalendarPopover, setShowMergeCalendarPopover] =
    useState(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [calendars, setCalendars] = useState<CalendarAccount[]>(accountsData);
  const [selectedCalendarId, setSelectedCalendarId] = useState<string | null>(
    null
  );
  const groupedCalendars: GroupedAccounts = useMemo(() => {
    return _.groupBy(calendars, (accountItem) => accountItem.email);
  }, [calendars]);
  const onDragEnd = (result: DropResult, accountIndex: number) => {
    // if (!result.destination) {
    //   return;
    // }
    // const selectedAccount = accounts[accountIndex];
    // const updatedWorkspaces = reorder(
    //   selectedAccount.workspaces,
    //   result.source.index,
    //   result.destination.index
    // );
    // const clonedAccounts = [...accounts];
    // clonedAccounts[accountIndex]["workspaces"] = updatedWorkspaces;
    // setAccounts(clonedAccounts);
  };
  const addWorkSpace = (email: string) => {
    const clonedCalendars = [...calendars];
    const calendarData = {
      email: email,
      id: "6",
      calendarName: "Client Project 3",
      businessId: "FMiMLZ56yq4lLMQMz7aw",
      accountId: "YAttWcDeQzggJc6uSjc5EblHgCZ2",
      members: ["0Ler89j1icqypVwolnLV", "yUQM1anrLcfVjSEBVc4J"],
      color: "pink",
      deleted: false,
      enabled: true,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    clonedCalendars.push(calendarData);
    setCalendars(clonedCalendars);
  };

  const onCalendarNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedCalendarId) {
      const updatedCalendars = [...calendars].map((calendarItem, index) => {
        if (calendarItem.id === selectedCalendarId) {
          return { ...calendarItem, calendarName: e.target.value };
        }
        return calendarItem;
      });
      setCalendars(updatedCalendars);
    }
  };
  const onSaveCalendar = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSelectedCalendarId(null);
    }
  };
  return {
    showPopover,
    setShowPopover,
    selectedWorkSpace,
    setSelectedWorkspace,
    showViewMembers,
    setShowViewMembers,
    showInvitePeopleModal,
    setShowInvitePeopleModal,
    showDeleteCalendarModal,
    setShowDeleteCalendarModal,
    showMergeCalendarModal,
    setShowMergeCalendarModal,
    showMergeCalendarPopover,
    setShowMergeCalendarPopover,
    showAddAccountModal,
    showSuccessModal,
    setShowSuccessModal,
    setShowAddAccountModal,
    calendars,
    setCalendars,
    onDragEnd,
    addWorkSpace,
    selectedCalendarId,
    setSelectedCalendarId,
    onCalendarNameChange,
    onSaveCalendar,
    groupedCalendars,
  };
};
export default useAccounts;
