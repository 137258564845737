import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { fetchArchivedTeamTableData, unArchiveEmployee } from "controllers/team";

import HorizontalTable, {
  ColumnInstruction,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";
import { apiErrorHandler } from "controllers/core";

const { TextField } = tangoComponents;

type ArchivedTableStaffMember = {
  uniqueId: string;
  name: string;
  primaryRoleTitle: string;
  locationName: string;
  formattedStartDate: string;
  formattedArchivedDate: string;
  employmentType: string;
};

interface ArchivedTeamTableHeaderProps {
  title: string;
  onUnarchiveClick: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onRefreshClick: () => void;
  loading: boolean;
}

const ArchivedTeamTableHeader = (props: ArchivedTeamTableHeaderProps) => {
  return (
    <>
      <div className={"flex items-center"}>


        <div
          onClick={props.onRefreshClick}
          className={"cursor-pointer text-xs font-lato-bold text-black mr-7"}
        >
          Refresh
        </div>

        {props.loading ? (
          <Spinner size={SpinnerSize.SMALL} className="mr-7" />
        ) : null}

        <div className={"h-9 w-px bg-grey-1 mr-7"} />
        <TextField
          className="hori-table-search-input"
          value={props.searchValue}
          // @ts-ignore
          onChange={(e) => props.onSearchChange(e.target.value)}
          sizeType="medium"
          placeholder="Search"
        />
      </div>
    </>
  );
};

const ArchivedTeamTable = () => {
  const noOp = () => Promise.resolve(true);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const [searchFilter, setSearchFilter] = useState("");

  const onSearchChange = useCallback(
    (v: string) => {
      setSearchFilter(v.toLowerCase());
    },
    [setSearchFilter]
  );

  const columns: ColumnInstruction<ArchivedTableStaffMember>[] = [
    { type: "data", header: "Emploee", attribute: "name" },
    { type: "data", header: "Primary Role", attribute: "primaryRoleTitle" },
    { type: "data", header: "Location", attribute: "locationName" },
    { type: "data", header: "Start Date", attribute: "formattedStartDate" },
    { type: "data", header: "Employment Type", attribute: "employmentType" },
    {
      type: "data",
      header: "Archived Date",
      attribute: "formattedArchivedDate",
    },
  ];

  const businessId = useMemo(() => {
    return business?.id ?? null;
  }, [business?.id]);

  const archivedTeamTableData = useQuery(
    ["archivedTeamTableData", businessId],
    async () => fetchArchivedTeamTableData(businessId),
    {
      enabled: !!businessId,
      initialData: [],
      refetchOnWindowFocus: false,
      select: (employees) =>
        employees.filter((employee) =>
          employee.name.toLowerCase().includes(searchFilter)
        ),
    }
  );

  const unArchiveEmployeeMutation = useMutation(
    (mutationData: { businessId: string; uid: string }) => {
      return unArchiveEmployee(mutationData.businessId, mutationData.uid);
    },
    {
      onError: apiErrorHandler,
    }
  );

  const unArchiveEmployeesHandler = useCallback(
    async (uids: string[]) => {
      await Promise.all(
        uids.map(async (uid) => {
          await unArchiveEmployeeMutation.mutateAsync({
            businessId: business.id,
            uid: uid,
          });
        })
      );
      archivedTeamTableData.refetch();
    },
    [unArchiveEmployeeMutation, archivedTeamTableData]
  );

  return (
    <div>
      <HorizontalTable
        title="Archived Employees"
        columns={columns}
        data={archivedTeamTableData.data}
        instructions={{}}
        isEditing={false}
        hideEdit
        displayDeleteToTheLeftOfCustomContent
        deleteText="Unarchive"
        deleteIds={unArchiveEmployeesHandler}
        customHeaderLeftContent={
          <ArchivedTeamTableHeader
            title="Archived Employees"
            onUnarchiveClick={noOp}
            searchValue={searchFilter}
            onSearchChange={onSearchChange}
            loading={archivedTeamTableData.isFetching || unArchiveEmployeeMutation.isLoading}
            onRefreshClick={() => archivedTeamTableData.refetch()}
          />
        }
      />
    </div>
  );
};

export default ArchivedTeamTable;
