import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { primaryGroupInstruction, recipeWarningInstruction, secondaryGroupInstruction, shelfLifeInstruction, yieldInstruction } from "../tableCells";

export type PrepItem = {
    uniqueId: string;
    name: string;
    primaryGroup: string;
    secondaryGroup: string;
    yield: number;
    productionUnitName: string;
    defaultCost: number;
    shelfLifeHours: number;
    shelfLifeUnits: "hours" | "days" | "weeks";
    recipe: string;
    ingredients: unknown[];
};
export const columns: ColumnInstruction<PrepItem>[] = [
    { type: "data", header: "Item Name", attribute: "name" },
    { type: "data", header: "Primary Group", attribute: "primaryGroup" },
    { type: "data", header: "Secondary Group", attribute: "secondaryGroup" },
    { type: "data", header: "Yield", attribute: "yield" },
    { type: "data", header: "Production Cost", attribute: "defaultCost" },
    { type: "data", header: "Shelf Life", attribute: "shelfLifeHours" },
    { type: "projection", header: "", attribute: "recipeWarnings" },
];

export const instructions: { [x: string]: RenderInstruction<PrepItem> } = {};

instructions.recipeWarnings = recipeWarningInstruction;

instructions.primaryGroup = primaryGroupInstruction;
instructions.secondaryGroup = secondaryGroupInstruction;
instructions.defaultCost = {
    type: "currency",
    readOnly: true,
};
instructions.yield = yieldInstruction;
instructions.shelfLifeHours = shelfLifeInstruction;

