 import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  useInventoryInfo,
  useSetPrimaryGroups,
} from "controllers/inventoryInfo";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "../../../model/store";
import { useUniqueIdList } from "../hooks";

type PrimaryGroupInfo = {
  uniqueId: string;
  name: string;
};

const columns: ColumnInstruction<PrimaryGroupInfo>[] = [
  { type: "data", header: "Name", attribute: "name" },
];

const instructions: { [x: string]: RenderInstruction<PrimaryGroupInfo> } = {};

const prefix = "new__pg__";
const usePrimaryGroups = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditing, setEditing] = useState(false);
  const {addedIds, push, remove, clear} = useUniqueIdList(prefix);
  const [deletedIds, setDeletedIds] = useState<{[id: string]: true}>({});
  const deleteIds = useCallback((ids: string[]) => {
    setEditing(true);
    ids.forEach(id => {
      if (id.startsWith(prefix)) return;
      setDeletedIds(curr => ({...curr, [id]: true}));
    })
    remove(ids);
  }, []);
  useEffect(() => {
    if (!isEditing) {
      clear();
      setDeletedIds({});
    }
  }, [isEditing, clear]);
  const addItem = useCallback(() => {
    setEditing(true);
    push();
  }, [push]);
  const query = useInventoryInfo(businessId);
  const update = useSetPrimaryGroups(businessId);
  const dataFromServer = query.data?.primaryGroups ?? [];
  const dataWithAdditions = useMemo(() => {
    const adding = addedIds.map(
      (uniqueId: string) =>
        ({
          uniqueId,
          name: "",
        } as PrimaryGroupInfo)
    );
    const curr = dataFromServer.filter(({id}) => !deletedIds[id]).map(
      (data) =>
        ({
          uniqueId: data.id,
          name: data.name,
        } as PrimaryGroupInfo)
    );
    return [...adding, ...curr];
  }, [deletedIds, addedIds, dataFromServer]);

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const names = dataWithAdditions.map(item => {
        const currName = item.name;
        const newName = instructions[item.uniqueId]?.name?.newValue;
        return (newName ?? currName) as string;
      });
      return update.mutateAsync({names})
    },
    [dataWithAdditions, update]
  );

  return {
    data: dataWithAdditions,
    addItem,
    deleteIds,
    saveChanges,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default usePrimaryGroups;
