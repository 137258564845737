import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackArrowIcon from "assets/back-arrow.svg";

import {
  EmployeeDetails as IEmployeeDetails,
  fetchEmployeeDetails,
} from "controllers/team";



import AvailabilityInfo from "./AvailabilityInfo";
import PaymentInfo from "./PaymentInfo";
import PersonalInfo from "./PersonalInfo";
import NavSlider, { NavSliderItem } from "./components/NavSlider";
import { RootState } from "model/store";

const { Modal, ModalButton } = tangoComponents;

const initialData: IEmployeeDetails = {
  uid: "",
  basicInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    formattedDOB: "",
    profileUrl: "",
    abbreviatedName: "",
  },
  addressInfo: {
    streetAddress: "",
    unit: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
  },
  emergencyContactInfo: {
    name: "",
    phone: "",
    relation: "",
  },
  employmentInfo: {
    employmentType: "",
    formattedEmploymentStartDate: "",
    managerName: "",
    locations: [],
    permission: "",
    isAdmin: false,
    department: "",
    level: "",
    primaryRole: {
      roleId: "",
      roleTitle: "",
    },
    secondaryRoles: [],
    compensationType: "",
    pinCode: null,
    externalId: null,
  },
  compensationInfo: [],
  availabilityInfo: [],
};

interface EmployeeDetailsHeaderProps {
  title: string;
  onRefreshClick: () => void;
  loading: boolean;
  navSliderItems: NavSliderItem[];
  currentNavSliderIndex: number;
  onNavSliderTabPress: (tabId: string) => void;
  basicInfo: IEmployeeDetails["basicInfo"];
}

export type TabID = "personal_info" | "pay_info" | "availability_info";

const EmployeeDetailsHeader = (props: EmployeeDetailsHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className={"flex justify-between items-center p-6"}>
      <div className="flex justify-start items-center">
        <img
          src={BackArrowIcon}
          className={"back-button"}
          onClick={() => { navigate(-1) }}
        />
        <div className="mr-5 w-9 h-9 bg-yellow-2 rounded-full flex justify-center items-center" >
          {props.basicInfo.abbreviatedName &&
            <div className="font-lato-regular text-tiny tracking-tighter text-black">{props.basicInfo.abbreviatedName}
            </div>
          }
        </div>
        <div className={"font-lato-black text-xl text-black"}>{props.basicInfo.firstName} {props.basicInfo.lastName}</div>
      </div>
      <div className={"flex items-center"}>
        <NavSlider
          items={props.navSliderItems}
          currentIndex={props.currentNavSliderIndex}
          onItemPress={props.onNavSliderTabPress}
        />

        <div className="w-20 h-10 flex flex-row justify-evenly items-center mr-7	">
          <div
            onClick={props.onRefreshClick}
            className={"cursor-pointer text-xs font-lato-bold text-black mr-3"}
          >
            Refresh
          </div>
          <Spinner
            className={`${props.loading ? "visible" : "invisible"}`}
            size={SpinnerSize.SMALL}
          />
        </div>

        <div className={"h-9 w-px bg-grey-1 mr-7"} />
      </div>
    </div>
  );
};

const EmployeeDetails = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const params = useParams<{ employeeId: string }>();

  const [currenTabId, setCurrentTabId] =
    React.useState<string>("personal_info");
  const onTabPress = useCallback(
    (tId: string) => {
      setCurrentTabId(tId);
    },
    [setCurrentTabId]
  );
  const navSliderItems = useMemo(() => {
    return [
      {
        index: 0,
        id: "personal_info",
        title: "Personal",
      },
      {
        index: 1,
        id: "pay_info",
        title: "Job/Pay",
      },
      {
        index: 2,
        id: "availability_info",
        title: "Availability",
      },
    ];
  }, []);
  const currentIndex = useMemo(() => {
    const correspondingItem = navSliderItems.find(
      (item) => item.id === currenTabId
    );
    return correspondingItem ? correspondingItem.index : 0;
  }, [currenTabId, navSliderItems]);

  const businessId = useMemo(() => {
    return business?.id ?? null;
  }, [business?.id]);

  const employeeId = useMemo(() => {
    return params.employeeId ?? null;
  }, [params.employeeId]);

  const teamTableData = useQuery(
    ["employeeDetails", businessId, employeeId],
    async () =>
      businessId && employeeId
        ? fetchEmployeeDetails(businessId, employeeId)
        : initialData,
    {
      enabled: !!businessId && !!employeeId,
      initialData: initialData,
      refetchOnWindowFocus: false,
    }
  );

  const renderPersonalInfo = () => {
    return (
      <PersonalInfo
        teamTableData={teamTableData.data}
        refetch={teamTableData.refetch}
      />
    );
  };

  const renderEmploymentInfo = () => {
    return <PaymentInfo teamTableData={teamTableData.data} />;
  };

  const renderAvailabilityInfo = () => {
    return <AvailabilityInfo teamTableData={teamTableData.data} />;
  };

  const renderContentBasedOnTabState = () => {
    switch (currenTabId) {
      case "personal_info":
        return renderPersonalInfo();
      case "pay_info":
        return renderEmploymentInfo();
      case "availability_info":
        return renderAvailabilityInfo();
      default:
        return null;
    }
  };

  return (
    <div>
      <EmployeeDetailsHeader
        title={"Employee Details"}
        basicInfo={teamTableData.data?.basicInfo}
        onRefreshClick={teamTableData.refetch}
        loading={teamTableData.isFetching}
        navSliderItems={navSliderItems}
        currentNavSliderIndex={currentIndex}
        onNavSliderTabPress={onTabPress}
      />

      <div className="px-10 py-10">{renderContentBasedOnTabState()}</div>
    </div>
  );
};

export default EmployeeDetails;
