import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";

import "../SurveyBuilder/form.css";
import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
  redirectToBoards: () => void;
}
const CreateBoardModal = ({ showModal, onClose, redirectToBoards }: Props) => {
  return (
    <Dialog
      onClose={onClose}
      hasBackdrop
      isOpen={showModal}
      className="create-board-modal"
    >
      <div className="modal-header">
        <div className="header-title">
          Create a process board for form responses?
        </div>
        <Icon icon="cross" size={15} color="#424452" />
      </div>
      <div className="modal-body">
        <Button
          text="Link to a process board"
          onClick={redirectToBoards}
          rightIcon="arrow-right"
          minimal
          color="#424452"
        />
      </div>
    </Dialog>
  );
};

export default CreateBoardModal;
