import { storageRef } from "../config/firebase";

// the method receives local url
export async function saveFile(storagePath: string, base64: string) {
  console.log("uploading file in utils/save file");
  const fileType = base64.split(";")[0].split("/")[1];
  const base64WithoutType = base64.split(",")[1];
  const snapshot = await storageRef
    .child(`${storagePath}.${fileType}`)
    .putString(base64WithoutType, "base64");
  // console.log('saving image to storage path')
  return snapshot.ref.getDownloadURL();
}
