import { ProductsAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseProductDoc[] = [];

export default function products(state = initialState, action: ProductsAction) {
  switch (action.type) {
    case ActionType.RECEIVE_PRODUCTS:
      return action.products;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
