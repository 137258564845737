import { Icon } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Box from "components/Box";

import Layout from "../../MarketingFeedback/Layout";
import hashIcon from "../icons/hashIcon.svg";
import InfoIcon from "../icons/infoIcon.svg";
import UserListModal from "./model/userListModal";
import "./styles.css";

const VerticleLign = () => {
  return (
    <div
      style={{
        height: "auto",
        width: "4px",
        backgroundColor: "#E9E9E9",
      }}
    ></div>
  );
};

const CampaignDashboard = () => {
  const navigate = useNavigate();
  const [recipients, setRecipients] = useState(null);
  const [showUserListModal, setShowUserListModal] = useState<boolean>(false);

  const [emailInfo, setEmailInfo] = useState({
    To: null,
    senderName: "",
    senderEmail: "",
    subjectLine: "",
  });

  const updateEmailInfo = (key: string, value: string | number) => {
    setEmailInfo({
      ...emailInfo,
      [key]: value,
    });
  };

  const listData = [
    { value: "max@gmail.com", label: "Max" },
    { value: "skote@gmail.com", label: "Skote" },
    { value: "christian@gmail.com", label: "Christian" },
  ];

  return (
    <Layout>
      <div id="campaign-dashboard-page">
        {/*header*/}
        <Box className="page-head">
          <Box
            display="flex"
            flex-direction="row"
            alignItems="center"
            onClick={() => navigate("/campaigns")}
            style={{ cursor: "pointer" }}
          >
            <Icon icon="chevron-left" color="#424452" size={12} />
            <span className="backword-text">Back to Campaigns</span>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="pagehead-leftside">
              <div className="email-title">Coop Burlington Holiday Sale</div>
              {/* <div className="email-time">
              Last Edited via{" "}
              <span className="bold-subheader">Brandon Herron</span> on{" "}
              <span className="bold-subheader">Mon, March 7th 3:33 PM</span>
            </div> */}
            </Box>
            <Box
              className="pagehead-rightside"
              display="flex"
              flexDirection="row"
              style={{ marginRight: "54px" }}
            >
              <Box className="rates" display="flex" flexDirection="row">
                <img src={InfoIcon} height="18.33px" width="18.33px" />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: "21.83px" }}
                >
                  <div className="percentage-rate">12%</div>
                  <div className="percentage-rate-text">Open Rate</div>{" "}
                </Box>
              </Box>

              <Box
                className="rates"
                display="flex"
                flex-direction="row"
                style={{ marginLeft: "41px" }}
              >
                <img src={InfoIcon} height="18.33px" width="18.33px" />
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginLeft: "21.83px" }}
                >
                  <div className="percentage-rate">86%</div>
                  <div className="percentage-rate-text">Open Rate</div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/*body*/}

        <Box display="flex" style={{ marginBottom: "20px" }}>
          {/*body-left*/}
          <Box className="email-layout">
            <div className="layout-title">Email layout</div>
            <Box className="email-layout-body">
              <Box display="flex" flexDirection="column">
                {/*boxes*/}
                <Box display="flex" flexDirection="row" className="boxes">
                  <div
                    className="box-items"
                    style={{ borderRadius: "6px 0px 0px 6px" }}
                    onClick={() => setShowUserListModal(true)}
                  >
                    <Box display="flex" flexDirection="column">
                      <div>80</div>
                      <div>Opened</div>
                    </Box>
                  </div>
                  <VerticleLign />
                  <div className="box-items">
                    <Box display="flex" flexDirection="column">
                      <div>11</div>
                      <div>Clicked</div>
                    </Box>
                  </div>
                  <VerticleLign />
                  <div className="box-items">
                    <Box display="flex" flexDirection="column">
                      <div>0</div>
                      <div>Bounced</div>
                    </Box>
                  </div>
                  <VerticleLign />
                  <div
                    className="box-items"
                    style={{ borderRadius: "0px 6px 6px 0px" }}
                  >
                    <Box display="flex" flexDirection="column">
                      <div>0</div>
                      <div>Unsubscribed</div>
                    </Box>
                  </div>
                </Box>

                {/*dashboard stats*/}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Successful Deliveries</div>
                      <div className="stats-values">
                        <b>92</b> 100%
                      </div>
                    </div>
                  </Box>

                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Clicks Per Unique Opens</div>
                      <div className="stats-values">41.00%</div>
                    </div>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Total Opens</div>
                      <div className="stats-values">80</div>
                    </div>
                  </Box>

                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Total Clicks</div>
                      <div className="stats-values">70</div>
                    </div>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Last Opened</div>
                      <div className="stats-values">N/A</div>
                    </div>
                  </Box>

                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Last Clicks</div>
                      <div className="stats-values">N/A</div>
                    </div>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "47%" }}>
                    <div className="input-container">
                      <div className="input-label">Forwarded</div>
                      <div className="stats-values">0</div>
                    </div>
                  </Box>

                  <Box style={{ width: "47%" }}>
                    <div className="report-input">
                      <div className="input-label">Abuse Reports</div>
                      <InputGroup
                        onChange={(e) =>
                          updateEmailInfo("senderEmail", e.target.value)
                        }
                        placeholder="Type here.."
                        value={emailInfo.senderEmail}
                        className="emailinputs"
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/*body-right*/}
          <Box className="tags-container">
            {/* <div className="layout-title" style={{ marginBottom: "30px" }}>
            Email Information
          </div>
          <div className="emailinfo-section">
            <div className="input-label">Audience</div>
            <div className="stats-values">Subscribers</div>
          </div>
          <div className="emailinfo-section">
            <div className="input-label">Subject</div>
            <div className="stats-values">Coop Burlington Holiday Sale </div>
          </div>
          <div className="emailinfo-section">
            <div className="input-label">Delivered</div>
            <div className="stats-values">Mon, March 7th 3:33 PM</div>
          </div> */}
            <div className="emailinfo-section">
              <div className="input-label">Actions</div>
              <span className="stats-label">Print</span>
              <span className="stats-label">Download</span>
              <span className="stats-label">Share</span>
            </div>
            {/* <div className="layout-title">Tags</div>
          <Box
            className="tag-container-body"
            display="flex"
            flex-direction="column"
          >
            <Box className="tag">
              <img src={hashIcon} height="14.67px" width="14.67px" />
              <div>Brunch Promo 2022</div>
            </Box>
            <Box className="tag">
              <img src={hashIcon} height="14.67px" width="14.67px" />
              <div>Loyalty Program</div>
            </Box>{" "}
            <Box className="tag">
              <img src={hashIcon} height="14.67px" width="14.67px" />
              <div>Brunch Promo 2022</div>
            </Box>
          </Box> */}
          </Box>
        </Box>
        <UserListModal
          isOpen={showUserListModal}
          closeModal={() => setShowUserListModal(false)}
        />
      </div>
    </Layout>
  );
};

export default CampaignDashboard;
