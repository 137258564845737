import React from "react";

import "./style.css";

export interface ActionButtonProps {
  text: string;
  onClick: undefined | (() => void);
  style: "focus" | "red" | "normal" | "disabled";
}

const ActionButton = ({ style, onClick, text }: ActionButtonProps) => {
  const className = `action-button action-button-${style}`;
  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );
};
export default ActionButton;
