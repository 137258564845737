import _ from "lodash";

import firebase from "../config/firebase";
import { TangoBusinessDoc } from "../types/business";

const db = firebase.firestore();
const businessesCollection = db.collection("Businesses");
const businessesSettingsCollection = db.collection("BusinessSettings");
const accountCollection = db.collection("Accounts");

export const saveData = async (
  newData: TangoBusinessDoc[],
  existingData: TangoBusinessDoc[],
  businessId: string
) => {
  console.log(businessId, "save new data", newData, "old data", existingData);
};

export const updateTangoProductSetting = async (
  businessId: string,
  newTangoProducts: any
) => {
  console.log(businessId, "save new data", newTangoProducts);
  try {
    return await businessesCollection
      .doc(businessId)
      .update({ tangoProducts: newTangoProducts, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update tango products", err);
    return false;
  }
};

export const updatePhone = async (businessId: string, newPhones: any) => {
  console.log(businessId, "save phone", newPhones);
  try {
    return await businessesCollection
      .doc(businessId)
      .update({ phones: newPhones, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update phone", err);
    return false;
  }
};
export const updateBusinessName = async (businessId: string, newName: any) => {
  console.log(businessId, "save name", newName);
  try {
    return await businessesCollection
      .doc(businessId)
      .update({ businessName: newName, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error update name", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update name", err);
    return false;
  }
};

export const updateBusinessHours = async (
  businessId: string,
  newHours: any
) => {
  console.log(businessId, "save hours", newHours);
  try {
    return await businessesCollection
      .doc(businessId)
      .update({ hours: newHours, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update phone", err);
    return false;
  }
};

export const convertJobFunctionTitleToId = (title: string) =>
  _.camelCase(title);

export const overrideJobFunctionsWithDepartments = async (
  businessId: string,
  jobFunctions: JobWithDepartment[],
  deletedJobFunctions: JobWithDepartment[]
) => {
  // TODO: Update job functions
  const jobs = jobFunctions.reduce((acc, val) => {
    const id = convertJobFunctionTitleToId(val?.jobId);
    return {
      ...acc,
      [id]: {
        id,
        departmentId: val?.departmentId,
        description: "",
        deleted: false,
        title: val?.title,
      },
    };
  }, {} as DocumentData);

  const deletedJobs = deletedJobFunctions.reduce((acc, val) => {
    const id = convertJobFunctionTitleToId(val.jobId);
    return {
      ...acc,
      [id]: {
        id,
        departmentId: val?.departmentId,
        description: "",
        deleted: true,
        title: val?.title,
      },
    };
  }, {} as DocumentData);

  console.log("{ ...jobs, ...deletedJobs }", { ...jobs, ...deletedJobs });

  await businessesSettingsCollection.doc(businessId).update({
    jobFunctions: { ...jobs, ...deletedJobs },
  });
};

export const overrideJobFunctions = async (
  businessId: string,
  jobFunctions: string[],
  deletedJobFunctions: string[]
) => {
  // TODO: Update job functions
  const jobs = jobFunctions.reduce((acc, val) => {
    const id = convertJobFunctionTitleToId(val);
    return {
      ...acc,
      [id]: {
        id,
        department: "admin",
        description: "",
        deleted: false,
        title: val,
      },
    };
  }, {} as DocumentData);

  const deletedJobs = deletedJobFunctions.reduce((acc, val) => {
    const id = convertJobFunctionTitleToId(val);
    return {
      ...acc,
      [id]: {
        id,
        department: "admin",
        description: "",
        deleted: true,
        title: val,
      },
    };
  }, {} as DocumentData);

  await businessesSettingsCollection.doc(businessId).update({
    jobFunctions: { ...jobs, ...deletedJobs },
  });
};

export const updateBusinessSetting = async (
  businessId: string,
  settingName: string,
  newValue: any
) => {
  console.log("update business setting", businessId, settingName, newValue);

  const newSetting: { [key: string]: any } = {};
  newSetting[settingName] = newValue;

  console.log("new setting", newSetting, "biz", businessId);
  try {
    return await businessesSettingsCollection
      .doc(businessId)
      .update(newSetting)
      .then(() => {
        console.log("updated setting success");
        return true;
      })
      .catch((error) => {
        console.log("error", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update business setting", err);
    return false;
  }
};

export const updateSplashImage = async (
  accountId: string,
  imgUrl: string,
  businessId: string
) => {
  console.log(accountId, "save splash image", imgUrl);
  try {
    // update the parent account with splash
    await accountCollection
      .doc(accountId)
      .update({ "mobileOrders.splashImageUrl": imgUrl, updatedAt: new Date() });
    // update the business doc
    return await businessesCollection
      .doc(businessId)
      .update({ splashImageUrl: imgUrl, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error update image", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update image", err);
    return false;
  }
};

export const updateBannerImage = async (businessId: string, imgUrl: string) => {
  console.log(businessId, "save banner image", imgUrl);
  try {
    return await businessesCollection
      .doc(businessId)
      .update({ bannerImageUrl: imgUrl, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error update image", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update image", err);
    return false;
  }
};

export const updateProfileImage = async (
  accountId: string,
  imgUrl: string,
  businessId: string
) => {
  try {
    await accountCollection
      .doc(accountId)
      .update({ "mobileOrders.faviconUrl": imgUrl, updatedAt: new Date() });
    // update the business doc
    return await businessesCollection
      .doc(businessId)
      .update({ profileImageUrl: imgUrl, updatedAt: new Date() })
      .then(() => true)
      .catch((error) => {
        console.log("error update image", error);
        return false;
      });
  } catch (err) {
    console.log("Failed to update image", err);
    return false;
  }
};

export const checkForMissingSettings = async (
  businessId: string,
  currentSettings: any
) => {
  // declare settings object with "default" values //any time we add new setting just put the default key value in here and it will be created when business lands on the business settings page
  const exampleSettings = {
    ackrooDeviceId: "",
    allowItemLevelRemarks: false,
    businessHasFullyOnboarded: false,
    businessUsesOrderDisplay: false,
    autoApproveAvailabilities: true,
    autoPrintDineInOrders: false,
    autoPrintCarryOutOrders: false,
    autoPrintCurbsideOrders: false,
    autoPrintDeliveryOrders: false,
    businessDoesTableService: false,
    businessUsesInternetReaders: false,
    carryOutEnabledInSpeed: true,
    carryOutEnabledInFlex: true,
    payPeriodStartDate: new Date(),
    percentDiscountForStaffMembers: 0,
    flexCashRoundAmount: 1,
    flexCashRoundType: "up",
    speedCashRoundAmount: 1,
    speedCashRoundType: "up",
    preAuthAmount: 5,
    scheduledOrders: false,
    scheduledOrdersRequired: false,
    selfPay: false,
    specialInstructions: false,
    onlineTipPresetPercentages: [18, 20, 25],
    onlineTipPresetAmounts: [2, 4, 6],
    onlineMinimumTipForPercentagePresets: 10,
    speedTipPresetPercentages: [18, 20, 25],
    speedTipPresetAmounts: [2, 4, 6],
    speedMinimumTipForPercentagePresets: 10,
    flexTipPresetPercentages: [18, 20, 25],
    flexTipPresetAmounts: [2, 4, 6],
    flexMinimumTipForPercentagePresets: 10,
    directSendOrder: false,
    requirePinCodeForDiscountsInSpeed: true,
    requirePinCodeForDiscountsInFlex: true,
    requirePinCodeForRefundsInSpeed: true,
    requirePinCodeForRefundsInFlex: true,
    requirePinCodeForCompsInSpeed: true,
    requirePinCodeForCompsInFlex: true,
    speedReceiptPrompt: false,
    orderSpecialInstructions: false,
    deliveryRadius: 5,
    deliveryFee: 0,
    isFastCashCheckout: false,
    showFullStaffSchedule: true,
    turnLocationOff: false,
    enableDropShifts: false,
    shouldShowEndTime: true,
    blackOutDates: [
      {
        date: "January 1, 2022 at 12:00:00 AM UTC-5",
        staffMembers: 2,
      },
    ],
  };

  // example data vs existing data
  const exampleSettingKeys = Object.keys(exampleSettings);
  const currentSettingKeys = Object.keys(currentSettings);

  // find missing fields between existing data and the default example
  // @ts-ignore
  const missingValues = exampleSettingKeys.filter(
    (x: any) => !currentSettingKeys.includes(x)
  );

  const settingsToCreate = {};

  // if existing data is missing key value create new key value
  if (missingValues.length) {
    missingValues.map((key: string) => {
      // @ts-ignore
      settingsToCreate[key] = exampleSettings[key];
    });
  }

  // if settings to create has any fields add them to the business settings collection
  if (Object.keys(settingsToCreate).length) {
    // @ts-ignore
    settingsToCreate.businessId = businessId;
    console.log("create these settings", settingsToCreate);
    try {
      return await businessesSettingsCollection
        .doc(businessId)
        .update(settingsToCreate)
        .then(() => {
          console.log("added missing fields");
          return true;
        })
        .catch((error) => {
          console.log("error update missing biz settings", error);
          return false;
        });
    } catch (err) {
      console.log("Failed to update missing biz settings", err);
      return false;
    }
  } else {
    return console.log("business is not missing any business settings");
  }
};
