import React, { useCallback, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Sidebar from "./components/Sidebar";
import ArchivedTable from "./views/ArchivedTeamTable";
import EditRolesTable from "./views/EditRoles";
import EmployeeDetails from "./views/EmployeeDetails";
import TeamTable from "./views/TeamTable";

const NewTeamFlow = () => {
  const location = useLocation();

  const activeSidebarItemId = useMemo(() => {
    const lastPath = location.pathname.split("/").pop();
    console.log("lastPath", lastPath);
    switch (lastPath) {
      case "roles":
        return "edit_job_roles";
      case "groups":
        return "groups";
      case "archived":
        return "archived";
    }
    return "team";
  }, [location.pathname]);

  const navigate = useNavigate();

  const sidebarItems = useMemo(() => {
    return [
      { id: "team", title: "Team", active: activeSidebarItemId === "team" },
      {
        id: "edit_job_roles",
        title: "Edit Job Roles",
        active: activeSidebarItemId === "edit_job_roles",
      },
      // {
      //   id: "groups",
      //   title: "Groups",
      //   active: activeSidebarItemId === "groups",
      // },
      {
        id: "archived",
        title: "Archived Employees",
        active: activeSidebarItemId === "archived",
      },
    ];
  }, [activeSidebarItemId]);
  const handleSidebarItemClick = useCallback(
    (itemId: string) => {
      if (itemId === "team") {
        return navigate("");
      }
      if (itemId === "edit_job_roles") {
        return navigate("roles");
      }
      if (itemId === "groups") {
        return navigate("groups");
      }
      if (itemId === "archived") {
        return navigate("archived");
      }
      return navigate("");
    },
    [navigate]
  );

  return (
    <div className="flex-1 relative ">
      <div className=" flex flex-row " style={{ height: "93vh" }}>
        <div className="flex-1">
          <Sidebar items={sidebarItems} onItemClick={handleSidebarItemClick} />
        </div>
        <div className="flex-5">
          <Routes>
            <Route index element={<TeamTable />} />
            <Route path="roles" element={<EditRolesTable />} />
            {/* <Route path="groups" element={<GroupsTable />} /> */}
            <Route path="archived" element={<ArchivedTable />} />
            <Route path=":employeeId" element={<EmployeeDetails />} />

            <Route path="*" element={<Navigate replace to="" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default NewTeamFlow;
