import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import { updateBusinessHours } from "../../../models/businesses";
import "./index.css";

const timeSlotData = [
  { time: "08:00", displayTime: "8:00am" },
  { time: "08:30", displayTime: "8:30am" },
  { time: "09:00", displayTime: "9:00am" },
  { time: "09:30", displayTime: "9:30am" },
  { time: "10:00", displayTime: "10:00am" },
  { time: "10:30", displayTime: "10:30am" },
  { time: "11:00", displayTime: "11:00am" },
  { time: "11:30", displayTime: "11:30am" },
  { time: "12:00", displayTime: "12:00pm" },
  { time: "12:30", displayTime: "12:30pm" },
  { time: "13:00", displayTime: "01:00pm" },
  { time: "13:30", displayTime: "01:30pm" },
  { time: "14:00", displayTime: "02:00pm" },
  { time: "14:30", displayTime: "02:30pm" },
  { time: "15:00", displayTime: "03:00pm" },
  { time: "15:30", displayTime: "03:30pm" },
  { time: "16:00", displayTime: "04:00pm" },
  { time: "16:30", displayTime: "04:30pm" },
  { time: "17:00", displayTime: "05:00pm" },
  { time: "17:30", displayTime: "05:30pm" },
  { time: "18:00", displayTime: "06:00pm" },
  { time: "18:30", displayTime: "06:30pm" },
  { time: "19:00", displayTime: "07:00pm" },
  { time: "19:30", displayTime: "07:30pm" },
  { time: "20:00", displayTime: "08:00pm" },
  { time: "20:30", displayTime: "08:30pm" },
  { time: "21:00", displayTime: "09:00pm" },
  { time: "21:30", displayTime: "09:30pm" },
  { time: "22:00", displayTime: "10:00pm" },
  { time: "22:30", displayTime: "10:30pm" },
  { time: "23:00", displayTime: "11:00pm" },
  { time: "23:30", displayTime: "11:30pm" },
  { time: "23:59", displayTime: "11:59pm" },
  { time: "00:00", displayTime: "12:00am" },
  { time: "00:30", displayTime: "12:30am" },
  { time: "01:00", displayTime: "1:00am" },
  { time: "01:30", displayTime: "1:30am" },
  { time: "02:00", displayTime: "2:00am" },
  { time: "02:30", displayTime: "2:30am" },
  { time: "03:00", displayTime: "3:00am" },
  { time: "03:30", displayTime: "3:30am" },
  { time: "04:00", displayTime: "4:00am" },
  { time: "04:30", displayTime: "4:30am" },
  { time: "05:00", displayTime: "5:00am" },
  { time: "05:30", displayTime: "5:30am" },
  { time: "06:00", displayTime: "6:00am" },
  { time: "06:30", displayTime: "6:30am" },
  { time: "07:00", displayTime: "7:00am" },
  { time: "07:30", displayTime: "7:30am" },
];

export default function timeslots(props: any) {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const handleChangeTime = async (e: any) => {
    console.log("set time", e.target.value);

    if (props.type === "open") {
      business.hours.map((availablity: any) => {
        if (availablity.day === props.day) {
          availablity.openTime = e.target.value;
        }
      });
    }
    if (props.type === "close") {
      business.hours.map((availablity: any) => {
        if (availablity.day === props.day) {
          availablity.closeTime = e.target.value;
        }
      });
    }

    const success = await updateBusinessHours(business.id, business.hours);
    if (success) {
      console.log("success!!");
    } else {
      alert("There was a problem updating your business hours.");
    }
  };
  return (
    <div>
      <select
        onChange={(e) => {
          handleChangeTime(e);
        }}
        name="timeslot"
        id="timeslotSelect"
      >
        <option className="timeOption" value="" selected disabled hidden>
          {timeSlotData.filter((slot: any) => slot.time === props.defaultTime)
            .length
            ? timeSlotData.filter(
                (slot: any) => slot.time === props.defaultTime
              )[0].displayTime
            : "Set Time"}
        </option>

        {timeSlotData.map((slot, index) => (
          <option value={slot.time} key={index}>
            {slot.displayTime}
          </option>
        ))}
      </select>
    </div>
  );
}
