export const exportSpreadSheetFile = (downOriginData: any, sheet1: any) => {
  const arr: any = []; // 2D array of all cell data
  const bgConfig: any = {};
  const percentageReg: any = /%$/;
  let cellValue: any = null;
  // Convert column subscript numbers to letters

  function chatatABC(n: any) {
    const orda = "a".charCodeAt(0);
    const ordz = "z".charCodeAt(0);
    const len = ordz - orda + 1;
    let s = "";
    while (n >= 0) {
      s = String.fromCharCode((n % len) + orda) + s;
      n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  // Get the background color of the cell
  function setBackground(row: any, col: any, bg: any) {
    const colA = chatatABC(col);
    const key = colA + (row + 1);
    bgConfig[key] = bg.replace(/\#?/, "");
  }

  // Determine whether the value type is a percentage %
  function isPercentage(value: any) {
    return percentageReg.test(value.m) && value.ct && value.ct.t === "n";
  }

  // Get a two-dimensional array
  for (let row = 0; row < downOriginData.length; row++) {
    const arrRow = [];
    for (let col = 0; col < downOriginData[row].length; col++) {
      if ((cellValue = downOriginData[row][col])) {
        // Handle the background color of the cell
        if (cellValue.bg) {
          setBackground(row, col, cellValue.bg);
        }
        if (
          cellValue.ct != null &&
          cellValue.ct != undefined &&
          cellValue.ct.t == "d"
        ) {
          //   d is the time format 2019-01-01 or 2019-01-01 10:10:10
          arrRow.push(new Date(cellValue.m.replace(/\-/g, "/"))); // Compatible with IE
        } else if (cellValue.m && isPercentage(cellValue)) {
          // Percentage problem
          if (cellValue.m !== undefined) {
            arrRow.push(cellValue.m);
          }
        } else {
          if (cellValue.v !== undefined) {
            arrRow.push(cellValue.v);
          }
        }
      }
    }
    arr.push(arrRow);
  }

  const opts = {
    dateNF: "m/d/yy h:mm",
    cellDates: true,
    cellStyles: true,
  };
  let ws = window.XLSX.utils.aoa_to_sheet(arr, opts);

  const reg1 = /[\u4e00-\u9fa5]/g;
  for (const key in ws) {
    const item = ws[key];
    if (item.t === "d") {
      if (item.w) {
        const arr = item.w.split(" ");
        if (arr[1] && arr[1] == "0:00") {
          ws[key].z = "m/d/yy";
        } else {
          item.z = "yyyy/m/d h:mm:ss";
        }
      }
    } else if (item.t === "s") {
      // Percentage setting format
      if (item.v && !item.v.match(reg1) && item.v.indexOf("%") > -1) {
        item.t = "n";
        item.z = "0.00%";
        item.v = Number.parseFloat(item.v) / 100;
      } else if (item.v && item.v.match(reg1)) {
        // Set centering style with Chinese
        item["s"] = {
          alignment: { vertical: "center", horizontal: "center" },
        };
      }
    }
    // 设置单元格样式
    if (bgConfig[key]) {
      ws[key]["s"] = {
        alignment: { vertical: "center", horizontal: "center" },
        fill: { bgColor: { indexed: 32 }, fgColor: { rgb: bgConfig[key] } },
        border: {
          top: { style: "thin", color: { rgb: "999999" } },
          bottom: { style: "thin", color: { rgb: "999999" } },
          left: { style: "thin", color: { rgb: "999999" } },
          right: { style: "thin", color: { rgb: "999999" } },
        },
      };
    }
  }

  const reg2 = /[\u4e00-\u9fa5]/g;
  for (const key in ws) {
    const item = ws[key];
    if (item.t === "d") {
      if (item.w) {
        //时间格式的设置
        const arr = item.w.split(" ");
        if (arr[1] && arr[1] == "0:00") {
          ws[key].z = "m/d/yy";
        } else {
          item.z = "yyyy/m/d h:mm:ss";
        }
      }
    } else if (item.t === "s") {
      // Percentage setting format
      if (item.v && !item.v.match(reg2) && item.v.indexOf("%") > -1) {
        item.t = "n";
        item.z = "0.00%";
        item.v = Number.parseFloat(item.v) / 100;
      } else if (item.v && item.v.match(reg2)) {
        // Set centering style with Chinese
        item["s"] = {
          alignment: { vertical: "center", horizontal: "center" },
        };
      }
    }
    // 设置单元格样式
    if (bgConfig[key]) {
      ws[key]["s"] = {
        alignment: { vertical: "center", horizontal: "center" },
        fill: { bgColor: { indexed: 32 }, fgColor: { rgb: bgConfig[key] } },
        border: {
          top: { style: "thin", color: { rgb: "999999" } },
          bottom: { style: "thin", color: { rgb: "999999" } },
          left: { style: "thin", color: { rgb: "999999" } },
          right: { style: "thin", color: { rgb: "999999" } },
        },
      };
    }
  }

  const regcell: any = /[\u4e00-\u9fa5]/g;
  for (const key in ws) {
    const item = ws[key];
    if (item.t === "d") {
      if (item.w) {
        //时间格式的设置
        const arr = item.w.split(" ");
        if (arr[1] && arr[1] == "0:00") {
          ws[key].z = "m/d/yy";
        } else {
          item.z = "yyyy/m/d h:mm:ss";
        }
      }
    } else if (item.t === "s") {
      // Percentage setting format
      if (item.v && !item.v.match(regcell) && item.v.indexOf("%") > -1) {
        item.t = "n";
        item.z = "0.00%";
        item.v = Number.parseFloat(item.v) / 100;
      } else if (item.v && item.v.match(regcell)) {
        // Set centering style with Chinese
        item["s"] = {
          alignment: { vertical: "center", horizontal: "center" },
        };
      }
    }
    // 设置单元格样式
    if (bgConfig[key]) {
      ws[key]["s"] = {
        alignment: { vertical: "center", horizontal: "center" },
        fill: { bgColor: { indexed: 32 }, fgColor: { rgb: bgConfig[key] } },
        border: {
          top: { style: "thin", color: { rgb: "999999" } },
          bottom: { style: "thin", color: { rgb: "999999" } },
          left: { style: "thin", color: { rgb: "999999" } },
          right: { style: "thin", color: { rgb: "999999" } },
        },
      };
    }
  }

  const name = "sheet1";
  const tmpWB = {
    SheetNames: [name], // Saved sheet title
    Sheets: {
      [name]: Object.assign({}, ws), // Content
    },
  };

  const mergeConfig = sheet1.config.merge;
  let mergeArr = [];
  if (JSON.stringify(mergeConfig) !== "{}") {
    mergeArr = handleMergeData(mergeConfig);
    tmpWB.Sheets[name]["!merges"] = mergeArr;
  }
  // Process merged cell config data
  function handleMergeData(origin: any) {
    const result = [];
    if (origin instanceof Object) {
      const r = "r",
        c = "c",
        cs = "cs",
        rs = "rs";
      for (const key in origin) {
        const startR = origin[key][r];
        let endR = origin[key][r];
        const startC = origin[key][c];
        let endC = origin[key][c];

        // If only one row is 1, if two rows are 2
        if (origin[key][cs] > 0) {
          endC = startC + (origin[key][cs] - 1);
        }
        if (origin[key][rs] > 0) {
          endR = startR + (origin[key][rs] - 1);
        }
        // s is the start coordinate of the merged cell e is the end coordinate
        const obj = { s: { r: startR, c: startC }, e: { r: endR, c: endC } };
        result.push(obj);
      }
    }
    return result;
  }

  const fileName = "test";
  // method of sheetjs js-xlsx, cannot set cell format
  window.XLSX.writeFile(tmpWB, fileName + ".xlsx");

  function s2ab(s: any) {
    if (typeof ArrayBuffer !== "undefined") {
      const buf: any = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    } else {
      const buf: any = new Array(s.length);
      for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
  }
  function saveAs(obj: any, fileName: any) {
    const tmpa = document.createElement("a");
    tmpa.download = fileName || "download";
    tmpa.href = URL.createObjectURL(obj);
    tmpa.click();
    setTimeout(function () {
      URL.revokeObjectURL(obj);
    }, 100);
  }

  ws = new Blob([
    s2ab(
      window.S_XLSX.write(tmpWB, {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
      }) // The data here is used to define the exported format type
    ),
  ]);
  saveAs(ws, fileName + ".xlsx");
};
