import _ from "lodash";

import { isEqual } from "utils/data";

import { FirebaseServiceAreaDoc, ServiceAreaDoc } from "types/serviceArea";

import firebase from "../config/firebase";

const db = firebase.firestore();
const serviceAreasCollection = db.collection("ServiceAreas");

export const performSoftDelete = async (serviceAreas: ServiceAreaDoc[]) => {
  for await (const serviceArea of serviceAreas) {
    try {
      if (serviceArea.id && serviceArea.id.length > 0) {
        await serviceAreasCollection
          .doc(serviceArea.id)
          .update({ deleted: true });
      }
    } catch (err) {
      console.log("Failed to update: ", serviceArea.id);
    }
  }
};

export const saveData = async (
  newData: ServiceAreaDoc[],
  existingData: ServiceAreaDoc[],
  businessId: string
) => {
  const firebaseServiceAreas: FirebaseServiceAreaDoc[] = newData
    .map((serviceArea, index) => ({ ...serviceArea, index: index }))
    .filter((serviceArea) => {
      const serviceAreaToCheck = _.omit(serviceArea, "index");
      const foundItem = existingData.some((existingArea) =>
        isEqual(existingArea, serviceAreaToCheck)
      );

      // Remove service area documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((serviceArea) => {
      return {
        id: serviceArea.id,
        serviceAreaName: serviceArea.serviceAreaName,
        enabled: serviceArea.enabled === "Yes",
        maxOccupancy: serviceArea.maxOccupancy
          ? parseInt(serviceArea.maxOccupancy)
          : null,
        weatherDependent: serviceArea.weatherDependent === "Yes",
        businessId: businessId,
        deleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    });

  for await (const serviceArea of firebaseServiceAreas) {
    // For existing service areas - just find the differences to update
    if (serviceArea.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === serviceArea.id
      );
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[index]
        )

          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingData[index][key], newData[index][key]) &&
              Object.keys(serviceArea).includes(key)
          )
          // @ts-ignore
          .reduce((acc, key) => ({ ...acc, [key]: serviceArea[key] }), {});

        try {
          await serviceAreasCollection.doc(serviceArea.id).update(dataToUpdate);
        } catch (err) {
          console.log("Failed to update: ", serviceArea.id);
        }
      }
    } else {
      const docRef = serviceAreasCollection.doc();
      serviceArea["id"] = docRef.id;
      const finalDoc = _.omit(serviceArea, "index");

      try {
        await docRef.set(finalDoc, { merge: true });
      } catch (err) {
        console.log("Failed to update: ", serviceArea.id);
      }
    }
  }
};
