import { Button, Icon, InputGroup } from "@blueprintjs/core";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "../../../../components/Box";
import { RootState } from "../../../../model/store";

const CustomGroups = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [groups, setGroups] = useState<CustomGroup[]>([]);
  const navigate = useNavigate();
  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);
  const renderGroupRow = (item: CustomGroup, index: number) => {
    return (
      <tr key={index}>
        <td>
          <div>{item.groupName}</div>
        </td>
        <td className="status-column">{item.members} members</td>
        <td className="status-column">
          <div>{moment(item.createdAt).format("DD/MM/yyyy")}</div>
        </td>
        <td>
          <div className="view-application">View Group</div>
        </td>
      </tr>
    );
  };
  const renderSidebar = () => (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="sidebar-title">Action</div>
        <div className="sidebar-section">
          <Button
            text="Create New group"
            rightIcon={<Icon icon="plus" color="#FFFFFF" />}
            onClick={() => navigate("/manager/create-custom-group")}
            className="create-new-teammate"
            fill
          />
        </div>
        <div className="sidebar-section">
          <InputGroup
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            leftIcon="search"
            intent="none"
          />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <div
        className={`application-tracker-header ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        id="view-archives"
      >
        <div className="small-title">Team</div>
        <div className="big-title">Custom Groups</div>
        <Box className="back-button" onClick={() => navigate(-1)}>
          <Icon icon="arrow-left" color="#7C7F93" />
          Back to Team
        </Box>
      </div>
      <Box
        className={`application-tracker-page ${
          emulatorMode ? "emulatorMode" : ""
        }`}
        flex={1}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        {renderSidebar()}
        <div className="jobs-container archive-table">
          <table>
            <tr>
              <th>Employee Info</th>
              <th>Status</th>
              <th>Primary Role</th>
              <th>Start Date</th>
            </tr>
            {groups.map((item, index) => renderGroupRow(item, index))}
          </table>
        </div>
      </Box>
    </div>
  );
};
export default CustomGroups;
