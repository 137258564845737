import { Button, Icon, MenuItem } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Popover2 } from "@blueprintjs/popover2";
import { Select } from "@blueprintjs/select";
import { StyleSheet } from "@react-pdf/renderer";
import { ApexOptions } from "apexcharts";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  composeUsableTabAndOrder,
  fetchOlderTabAndOrders,
} from "models/reporting";

import { TabAndOrderForRust } from "types/order";

import { getOrdersAndTabsForReporting } from "model/selectors/reporting";
import { RootState } from "model/store";

import Layout from "../Layout";
import "./style.css";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 7,
    fontWeight: "bold",
    padding: 4,
  },
  footerCellStyle: {
    fontSize: 9,
    fontWeight: "bold",
    padding: 4,
    backgroundColor: "#fffe05",
  },
  dataCellStyle: {
    fontSize: 6,
    padding: 10,
  },
});

const chartOptions: ApexOptions = {
  chart: {
    id: "basic-bar",
    dropShadow: {
      enabled: true,
      color: "#fff",
      top: 5,
      left: 0,
      blur: 10,
      opacity: 0.5,
    },
    toolbar: {
      show: false,
    },
  },
  grid: {
    borderColor: "#EDEFF1",
    show: true,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  yaxis: {
    labels: {
      formatter: (value) => `$${value}`,
    },
  },
  xaxis: {
    categories: [
      "4:00 am",
      "5:00 am",
      "6:00 am",
      "7:00 am",
      "8:00 am",
      "9:00 am",
      "10:00 am",
      "11:00 am",
      "12:00 pm",
      "1:00 pm",
      "2:00 pm",
      "3:00 pm",
      "4:00 pm",
      "5:00 pm",
      "6:00 pm",
      "7:00 pm",
      "8:00 pm",
      "9:00 pm",
      "10:00 pm",
      "11:00 pm",
      "12:00 am",
      "1:00 am",
      "2:00 am",
      "3:00 am",
      "3:59 am",
    ],
    labels: {
      format: "h:mm tt",
      datetimeFormatter: {
        hour: "h",
        minute: "mm",
      },
      rotate: 360,
      offsetY: 20,
      offsetX: 5,
    },
  },
  stroke: {
    width: 3,
    colors: ["#5E55C8", "#C2C9D1"],
  },
  legend: {
    show: false,
  },
  colors: ["#5E55C8", "#C2C9D1"],
};

interface ProductAnalysis {
  name: string;
  price: number;
  quantity: number;
  total: number;
}

const Reporting = () => {
  let reporting: any | null = null;
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const { orders, tabs } = useSelector(getOrdersAndTabsForReporting);
  const products = useSelector((state: RootState) => state.products);
  const [allOrders, setAllOrders] = useState<TabAndOrderForRust[]>([]);

  // Sales Graph

  // TODO: Change currentDate and pastDate to be moment().toDate() and moment().subtract(1, 'day').toDate() respectively
  const [currentGraphName, setCurrentGraphName] = useState("Sales Revenue");
  const [currentDate, setCurrentDate] = useState(
    moment().hour() <= 4
      ? moment().subtract(1, "day").startOf("day").toDate()
      : moment().startOf("day").toDate()
  );
  const [currentGraphData, setCurrentGraphData] = useState<number[]>([]);
  const [pastGraphName, setPastGraphName] = useState("Yesterday");
  const [pastDate, setPastDate] = useState(
    moment().hour() <= 4
      ? moment().subtract(2, "day").startOf("day").toDate()
      : moment().subtract(1, "day").startOf("day").toDate()
  );
  const [pastGraphData, setPastGraphData] = useState<number[]>([]);

  const [showCurrentPicker, setShowCurrentPicker] = useState(false);
  const [showPastPicker, setShowPastPicker] = useState(false);
  const currentDayPickerRef = useRef<DayPickerInput>(null);
  const pastDayPickerRef = useRef<DayPickerInput>(null);
  // Sales Overview

  // const [firstStartDate, setFirstStartDate] = useState(moment().subtract(7, 'days').toDate());
  // const [firstEndDate, setFirstEndDate] = useState(moment().toDate());
  // const [secondStartDate, setSecondStartDate] = useState(moment().subtract(14, 'days').toDate());
  // const [secondEndDate, setSecondEndDate] = useState(moment().subtract(7, 'days').toDate());
  const [range, setRange] = useState<DateRange>([
    moment().hour() <= 4
      ? moment().subtract(8, "day").startOf("day").toDate()
      : moment().subtract(7, "day").startOf("day").toDate(),
    moment().hour() <= 4
      ? moment().subtract(1, "day").startOf("day").toDate()
      : moment().startOf("day").toDate(),
  ]);
  const [secondRange, setSecondRange] = useState<DateRange>([
    moment().hour() <= 4
      ? moment().subtract(22, "day").startOf("day").toDate()
      : moment().subtract(21, "day").startOf("day").toDate(),
    moment().hour() <= 4
      ? moment().subtract(15, "day").startOf("day").toDate()
      : moment().subtract(14, "day").startOf("day").toDate(),
  ]);
  // const [range, setRange] = useState<DateRange>([moment().subtract(7, 'days').toDate(), moment().toDate()]);
  // const [secondRange, setSecondRange] = useState<DateRange>([moment().subtract(14, 'days').toDate(), moment().subtract(7, 'days').toDate()]);
  const firstPickerRef = useRef<DayPickerInput>(null);
  const secondPickerRef = useRef<DayPickerInput>(null);

  const [salesOverview, setSalesOverview] = useState([
    {
      title: "Sales",
      percentage: 0.0,
      value: 0.0,
      showPercentage: true,
    },
    {
      title: "Cash Sales",
      isInteger: true,
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
    {
      title: "Card sales",
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
    {
      title: "Card Tips",
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
    {
      title: "Number of transactions",
      percentage: null,
      value: 0.0,
      showPercentage: true,
    },
    {
      title: "Cash tax",
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
    {
      title: "Card tax",
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
    {
      title: "Card deposit",
      percentage: 0.0,
      value: 0.0,
      showPercentage: false,
    },
  ]);

  const [topProductSubTypes, setTopProductSubTypes] = useState<any[]>([]);
  const [topProductTypes, setTopProductTypes] = useState([
    {
      title: "Beer & Cider",
      percentage: 0.0,
      value: 0.0,
    },
    {
      title: "Wine",
      percentage: 0.0,
      value: 0.0,
    },
    {
      title: "Liquor & Shots",
      percentage: 0.0,
      value: 0.0,
    },
    {
      title: "Food",
      percentage: 0.0,
      value: 0.0,
    },
  ]);

  const [topProducts, setTopProducts] = useState<
    {
      title: string;
      percentage: number;
      value: number;
      qty: number;
      totalSales: number;
    }[]
  >([
    /*
    {
      title: 'Vodka Cran',
      percentage: 8.52,
      value: 452.91,
      qty: 95,
      totalSales: 51,
    },
    {
      title: 'Bud Light',
      percentage: 11.2,
      value: 200.5,
      qty: 70,
      totalSales: 14,
    },

    {
      title: 'Lemon Drop Shot',
      percentage: 6.25,
      value: 98.1,
      qty: 12,
      totalSales: 8,
    },

    {
      title: 'Budwiser',
      percentage: 9.15,
      value: 88.72,
      qty: 40,
      totalSales: 7,
    },
    */
  ]);

  const aggregateDataHourly = (sales: number[][]) => {
    const result = Array(24).fill(0);
    if (sales.length > 0) {
      for (let i = 0; i < sales[0].length; i += 4) {
        if (i + 1 <= sales[0].length) {
          const hour = sales[0][i];
          const amount = sales[0][i + 1];

          // After midnight
          if (hour < 4) {
            result[hour + 20] += parseFloat(
              (Math.round(amount * 100) / 100).toFixed(2)
            );
            // Past 4 am
          } else {
            result[hour - 4] += parseFloat(
              (Math.round(amount * 100) / 100).toFixed(2)
            );
          }
        }
      }
    }

    return result;
  };

  const getGraphData = async () => {
    const currentDateHourly = reporting.total_sales_hourly(
      currentDate,
      new Date().getTimezoneOffset() / 100
    );
    const pastDateHourly = reporting.total_sales_hourly(
      pastDate,
      new Date().getTimezoneOffset() / 100
    );

    setCurrentGraphData(aggregateDataHourly(currentDateHourly));
    setPastGraphData(aggregateDataHourly(pastDateHourly));
  };

  const preprocessAndSortProductType = (
    salesData: [string[], number[]],
    defaultKeys: string[]
  ): [string, number][] => {
    const result: [string, number][] = salesData[0].map((key, i) => [
      key,
      salesData[1][i],
    ]);
    result.sort((a: [string, number], b: [string, number]) => b[1] - a[1]);

    if (result.length < defaultKeys.length) {
      return defaultKeys.reduce((acc, val) => {
        const index = acc.findIndex((data) => data[0] === val);
        if (index === -1 && acc.length < defaultKeys.length) {
          acc.push([val, 0.0]);
        }

        return acc;
      }, result);
    }

    return result.slice(0, defaultKeys.length);
  };

  const preprocessProducts = (
    salesData: [string[], number[], number[], number[]]
  ): ProductAnalysis[] => {
    const products: ProductAnalysis[] = salesData[0].map((key, i) => ({
      name: key,
      price: salesData[1][i],
      quantity: salesData[2][i],
      total: salesData[1][i] * salesData[2][i],
    }));
    const result = _.sortBy(products, "total").reverse();

    return result;
  };

  const percentageDiff = (current: number, past: number) =>
    past === 0
      ? current === 0
        ? parseFloat((0.0).toFixed(2))
        : parseFloat((100.0).toFixed(2))
      : parseFloat((((current - past) / past) * 100).toFixed(2));

  const getSalesOverviewData = async () => {
    if (range[0] && range[1] && secondRange[0] && secondRange[1]) {
      const currentOverview = reporting.summarize_orders(
        range[0],
        range[1],
        new Date().getTimezoneOffset() / 100
      );
      const pastOverview = reporting.summarize_orders(
        secondRange[0],
        secondRange[1],
        new Date().getTimezoneOffset() / 100
      );

      const currentCashSales = currentOverview[0];
      const currentCardSales = currentOverview[1];
      const currentCashTax = currentOverview[2];
      const currentCardTax = currentOverview[3];
      const currentCashTips = currentOverview[4];
      const currentCardTips = currentOverview[5];
      const currentTips = currentOverview[6];
      const currentTax = currentOverview[7];
      const currentTotalTransactions = currentOverview[8];
      const currentNewCustomers = currentOverview[9];
      const currentSalesRevenue = currentOverview[10];
      const currentSpendPerCustomer = currentOverview[11] || 0;

      const pastCashSales = pastOverview[0];
      const pastCardSales = pastOverview[1];
      const pastCashTax = pastOverview[2];
      const pastCardTax = pastOverview[3];
      const pastCashTips = pastOverview[4];
      const pastCardTips = pastOverview[5];
      const pastTips = pastOverview[6];
      const pastTax = pastOverview[7];
      const pastTotalTransactions = pastOverview[8];
      const pastNewCustomers = pastOverview[9];
      const pastSalesRevenue = pastOverview[10];
      const pastSpendPerCustomer = pastOverview[11] || 0;

      const updatedSalesOverview = [
        {
          title: "Sales",
          showPercentage: true,
          percentage: percentageDiff(currentSalesRevenue, pastSalesRevenue),
          value: currentSalesRevenue,
        },
        {
          title: "Cash Sales",
          // isInteger: true,
          // // Total transactions
          // percentage: percentageDiff(currentTotalTransactions, pastTotalTransactions),
          // value: currentTotalTransactions,
          // Cash sales
          showPercentage: false,
          percentage: 0, // percentageDiff(currentCashSales, pastCashSales),
          value: currentCashSales,
        },
        {
          title: "Card sales",
          showPercentage: false,
          percentage: 0, // percentageDiff(currentCardSales, pastCardSales),
          value: currentCardSales,
        },
        {
          title: "Card Tips",
          showPercentage: false,
          percentage: 0, // percentageDiff(currentCardTips, pastCardTips),
          value: currentCardTips,
        },
        {
          title: "Number of transactions",
          isInteger: true,
          showPercentage: true,
          percentage: percentageDiff(
            currentTotalTransactions,
            pastTotalTransactions
          ),
          value: currentTotalTransactions,
        },
        {
          // title: 'Spend per customer',
          // percentage: percentageDiff((currentSpendPerCustomer - (currentTips + currentTax) / currentTotalTransactions) || 0,
          //                            (pastSpendPerCustomer - (pastTips + pastTax) / pastTotalTransactions) || 0),
          // value: (currentSpendPerCustomer - (currentTips + currentTax) / currentTotalTransactions) || 0,
          title: "Cash Tax",
          showPercentage: false,
          percentage: 0, // percentageDiff(currentCashTax, pastCashTax),
          value: currentCashTax,
        },
        {
          title: "Card Tax",
          showPercentage: false,
          percentage: 0, // percentageDiff(currentCardTax, pastCardTax),
          value: currentCardTax,
        },
        {
          title: "Card Deposit",
          showPercentage: false,
          percentage: -1, // percentageDiff(currentNewCustomers, pastNewCustomers),
          value: currentCardSales + currentCardTax + currentCardTips,
        },
      ];

      setSalesOverview(updatedSalesOverview);

      const currentProductType: [string, number][] =
        preprocessAndSortProductType(
          reporting.summarize_orders_for_product_type(
            range[0],
            range[1],
            new Date().getTimezoneOffset() / 100
          ),
          ["beer & cider", "wine", "liquor & shots", "food"]
        );
      const pastProductType: [string, number][] = preprocessAndSortProductType(
        reporting.summarize_orders_for_product_type(
          secondRange[0],
          secondRange[1],
          new Date().getTimezoneOffset() / 100
        ),
        ["beer & cider", "wine", "liquor & shots", "food"]
      );

      const updatedProductTypes = currentProductType.map((data) => {
        const index = pastProductType.findIndex(
          (existingData) => existingData[0] === data[0]
        );
        return {
          title: _.upperFirst(data[0]),
          percentage:
            index !== -1
              ? percentageDiff(data[1], pastProductType[index][1])
              : 100,
          value: data[1],
        };
      });

      setTopProductTypes(updatedProductTypes);

      const currentProductSubType = preprocessAndSortProductType(
        reporting.summarize_orders_for_product_subtype(
          range[0],
          range[1],
          new Date().getTimezoneOffset() / 100
        ),
        ["bottled beer", "shot", "vodka", "cocktail"]
      );
      const pastProductSubType = preprocessAndSortProductType(
        reporting.summarize_orders_for_product_subtype(
          secondRange[0],
          secondRange[1],
          new Date().getTimezoneOffset() / 100
        ),
        ["bottled beer", "shot", "vodka", "cocktail"]
      );

      const updatedProductSubTypes = currentProductSubType.map((data) => {
        const index = pastProductSubType.findIndex(
          (existingData) => existingData[0] === data[0]
        );
        return {
          title: _.upperFirst(data[0]),
          percentage:
            index !== -1
              ? percentageDiff(data[1], pastProductSubType[index][1])
              : 100,
          value: data[1],
        };
      });

      setTopProductSubTypes(updatedProductSubTypes);

      const currentProducts = preprocessProducts(
        reporting.summarize_orders_by_product(
          range[0],
          range[1],
          new Date().getTimezoneOffset() / 100
        )
      );
      const pastProducts = preprocessProducts(
        reporting.summarize_orders_by_product(
          secondRange[0],
          secondRange[1],
          new Date().getTimezoneOffset() / 100
        )
      );
      const currentTotal = currentProducts.reduce(
        (acc, val) => acc + val.total,
        0
      );
      const pastTotal = pastProducts.reduce((acc, val) => acc + val.total, 0);

      const updatedTopProducts = currentProducts.slice(0, 4).map((product) => {
        const index = pastProducts.findIndex(
          (existingData) => existingData.name === product.name
        );
        return {
          title: product.name,
          percentage:
            index !== -1
              ? percentageDiff(product.total, pastProducts[index].total)
              : percentageDiff(product.total, 0),
          value: product.total,
          qty: product.quantity,
          totalSales: parseFloat(
            ((product.total / currentTotal) * 100).toFixed(2)
          ),
        };
      });

      setTopProducts(updatedTopProducts);
    }
  };

  const updateGraphData = async (ordersForReporting: TabAndOrderForRust[]) => {
    const { Reporting: RustReporting } = await import("../../../rustUtils");
    reporting = RustReporting.new(ordersForReporting);
    await getGraphData();
  };

  const updateOverviewData = async (
    ordersForReporting: TabAndOrderForRust[]
  ) => {
    const { Reporting: RustReporting } = await import("../../../rustUtils");
    reporting = RustReporting.new(ordersForReporting);
    await getSalesOverviewData();
  };

  // Updating the sales overview
  useEffect(() => {
    const updateData = async () => {
      const productsSubTypeRef = products.reduce(
        (acc, val) => ({ ...acc, [val.id]: val.subType }),
        {}
      );
      const lowestCurrentDate = moment
        .min((allOrders || orders).map((order) => moment(order.createdAt)))
        .toDate();

      // Requires older data to be fetched
      if (range[0] && secondRange[0]) {
        if (
          range[0] < lowestCurrentDate ||
          secondRange[0] < lowestCurrentDate
        ) {
          const lowestDate = moment
            .min([moment(range[0]), moment(secondRange[0])])
            .toDate();

          if (business) {
            const updatedTabAndOrders = await fetchOlderTabAndOrders(
              lowestDate,
              lowestCurrentDate,
              products,
              allOrders,
              business.id
            );

            setAllOrders(updatedTabAndOrders || []);
            updateOverviewData(updatedTabAndOrders || []);
          }

          // Existing data could be updated
        } else {
          const ordersForReporting = composeUsableTabAndOrder(
            orders,
            tabs,
            productsSubTypeRef
          );
          setAllOrders(ordersForReporting);
          updateOverviewData(ordersForReporting);
        }
      }
    };

    if (orders && tabs && products) {
      updateData();
    }
  }, [orders, tabs, products, range, secondRange]);

  // Updating the sales graph
  useEffect(() => {
    const updateData = async () => {
      const productsSubTypeRef = products.reduce(
        (acc, val) => ({ ...acc, [val.id]: val.subType }),
        {}
      );
      const lowestCurrentDate = moment
        .min((allOrders || orders).map((order) => moment(order.createdAt)))
        .toDate();

      // Requires older data to be fetched
      if (currentDate < lowestCurrentDate || pastDate < lowestCurrentDate) {
        const lowestDate = moment
          .min([moment(currentDate), moment(pastDate)])
          .toDate();

        if (business) {
          const updatedTabAndOrders = await fetchOlderTabAndOrders(
            lowestDate,
            lowestCurrentDate,
            products,
            allOrders,
            business.id
          );

          setAllOrders(updatedTabAndOrders || []);
          updateGraphData(updatedTabAndOrders || []);
        }

        // Existing data could be updated
      } else {
        const ordersForReporting = composeUsableTabAndOrder(
          orders,
          tabs,
          productsSubTypeRef
        );
        setAllOrders(ordersForReporting);
        updateGraphData(ordersForReporting);
      }
    };

    if (orders && tabs && products) {
      updateData();
    }
  }, [orders, tabs, products, currentDate, pastDate]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  // return (
  //   <Layout>
  //     <PDFViewer style={{border: '1px solid', height: 1600, width: '100%'}}>
  //         <Document>
  //             <Page>
  //                 <Table
  //                     data={[
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},

  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},

  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},

  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645, subtotal: true},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},

  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                       {businessDate: new Date(), numberOfSales: 25, netSales: 32000, transactionFees: 430, salesFees: 919, netDeposits: 29000, status: 'pending', depositDate: new Date(), disputesRefunds: 25645},
  //                     ]}
  //                 >
  //                     <TableHeader>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Business Date
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Number of Sales
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Net Sales
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Transaction Fees
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Sales Fees
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Net Deposits
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Status
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Deposit Date
  //                         </TableCell>
  //                         <TableCell textAlign="center" style={styles.headerCellStyle}>
  //                           Disputes/Refunds
  //                         </TableCell>
  //                     </TableHeader>
  //                     <TableBody>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => moment(r.businessDate).format("MMM DD")}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.numberOfSales / 100)}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.netSales / 100)}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.transactionFees / 100)}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.salesFees / 100)}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.netDeposits / 100)}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => r.status}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => moment(r.depositDate).format("MMM DD")}/>
  //                         <DataTableCell style={styles.dataCellStyle} getContent={(r) => formatter.format(r.disputesRefunds / 100)}/>
  //                     </TableBody>
  //                     <TableFooter>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         Total
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>

  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>

  //                       </TableCell>
  //                       <TableCell textAlign="center" style={styles.footerCellStyle}>
  //                         $$$
  //                       </TableCell>
  //                     </TableFooter>
  //                 </Table>
  //             </Page>
  //         </Document>
  //     </PDFViewer>
  //   </Layout>
  // )

  return (
    <Layout>
      <div id="reporting">
        <div className="navigation">
          <div className="navigation-title">Report</div>
          <div className="navigation-subtitle">Select to view below</div>
          <div className="button active">Sales Summary</div>
          <Link to="/product-analytics">
            <div className="button">Product Analytics</div>
          </Link>
          <Link to="/bank-deposit">
            <div className="button">Bank Deposit Report</div>
          </Link>
          <Link to="/core-reports">
            <div className="button">Core Reports</div>
          </Link>
          {/*
          <Link to="/cash-summary">
            <div className="button">Cash Summary</div>
          </Link>
          <Link to="/labor-payroll">
            <div  className="button">Labor/Payroll</div>
          </Link>
          <div  className="button">Inventory  Reports</div>
          <div  className="button">Customer  Reports</div>
          <div  className="button">Transaction  Reports</div>
          */}
        </div>
        <div className="right-section">
          <div className="chart-container">
            <div className="chart-header">
              <Select
                items={[]}
                disabled
                filterable={false}
                onItemSelect={() => console.log("yey")}
                itemRenderer={(item) => <MenuItem text={item} />}
              >
                <Button text="Sales Revenue" />
              </Select>
            </div>
            <div className="chart-body">
              <div className="chart">
                <ReactApexChart
                  options={chartOptions}
                  series={[
                    {
                      name: currentGraphName,
                      data: currentGraphData,
                    },
                    {
                      name: pastGraphName,
                      data: pastGraphData,
                    },
                  ]}
                  type="line"
                  height={350}
                />
              </div>
              <div className="stats-container">
                <div className="stat-container">
                  <Popover2
                    isOpen={showCurrentPicker}
                    enforceFocus={false}
                    // onClose={() => setShowCurrentPicker(false)}
                    content={
                      <DayPickerInput
                        onDayChange={(date) => {
                          setCurrentGraphName(
                            moment(date).format("MMM Do YYYY")
                          );
                          setCurrentDate(date);
                        }}
                        ref={currentDayPickerRef}
                      />
                    }
                  >
                    <Button
                      minimal
                      text={currentGraphName}
                      onClick={() => {
                        setShowCurrentPicker(true);
                        setTimeout(() => {
                          if (
                            currentDayPickerRef &&
                            currentDayPickerRef.current
                          ) {
                            currentDayPickerRef.current.showDayPicker();
                          }
                        }, 100);
                      }}
                      rightIcon={<Icon icon="chevron-down" iconSize={18} />}
                      tabIndex={0}
                    />
                  </Popover2>

                  <div className="stat-figure">
                    ${_.sum(currentGraphData).toFixed(2)}
                  </div>
                </div>
                <div className="stat-container">
                  <Popover2
                    isOpen={showPastPicker}
                    enforceFocus={false}
                    // onClose={() => setShowPastPicker(false)}
                    content={
                      <div className="stat-date-picker">
                        <DayPickerInput
                          onDayChange={(date) => {
                            setPastGraphName(
                              moment(date).format("MMM Do YYYY")
                            );
                            setPastDate(date);
                          }}
                          ref={pastDayPickerRef}
                        />
                      </div>
                    }
                  >
                    <Button
                      minimal
                      text={pastGraphName}
                      onClick={() => {
                        setShowPastPicker(true);
                        setTimeout(() => {
                          if (pastDayPickerRef && pastDayPickerRef.current) {
                            pastDayPickerRef.current.showDayPicker();
                          }
                        }, 10);
                      }}
                      rightIcon={<Icon icon="chevron-down" iconSize={18} />}
                      tabIndex={0}
                    />
                  </Popover2>
                  <div className="stat-figure">
                    ${_.sum(pastGraphData).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sales-overview">
            <div className="header-section">
              <div className="title">Sales Overview</div>

              <div className="date-pickers">
                <div style={{ position: "relative" }}>
                  <DateRangeInput
                    allowSingleDayRange
                    formatDate={(date) => moment(date).format("MMM Do")}
                    parseDate={(str) => moment(str, "MMM Do").toDate()}
                    placeholder={`${"MMM DO"} (moment)`}
                    singleMonthOnly
                    shortcuts={false}
                    value={range}
                    onChange={(dateRange: DateRange) => setRange(dateRange)}
                    className="date-range"
                  />
                  <Icon icon="chevron-down" color="#fff" />
                </div>
                <div className="compared-to">compared to</div>
                <div style={{ position: "relative" }}>
                  <DateRangeInput
                    allowSingleDayRange
                    formatDate={(date) => moment(date).format("MMM Do")}
                    parseDate={(str) => moment(str, "MMM Do").toDate()}
                    placeholder={`${"MMM DO"} (moment)`}
                    singleMonthOnly
                    shortcuts={false}
                    value={secondRange}
                    onChange={(dateRange: DateRange) =>
                      setSecondRange(dateRange)
                    }
                    className="date-range"
                  />
                  <Icon icon="chevron-down" color="#fff" />
                </div>
              </div>
            </div>
            <div className="cell-containers">
              {salesOverview.map((item) => (
                <div className="cell">
                  <div className="cell-header">
                    <div className="cell-title">{item.title}</div>
                    {item.showPercentage &&
                      (item.percentage || item.percentage === 0) && (
                        <div
                          className={`cell-percentage ${
                            Math.sign(item.percentage) === -1 ? "red" : "green"
                          }`}
                        >
                          {`${
                            Math.sign(item.percentage) === -1 ? "" : "+"
                          }${item.percentage.toFixed(2)}`}
                          %
                        </div>
                      )}
                  </div>
                  <div className="cell-price">
                    {item.isInteger ? item.value : `$${item.value.toFixed(2)}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {[
            { sectionName: "Top Product Types", data: topProductTypes },
            { sectionName: "Top Product Sub-Types", data: topProductSubTypes },
            { sectionName: "Top Products", data: topProducts },
          ].map((section) => (
            <div className="top-section">
              <div className="header-section">
                <div className="section-title">{section.sectionName}</div>
                <div className="more-text-container">
                  <Link to="/product-analytics">
                    <div className="more-text">More product analytics</div>
                  </Link>
                  <img
                    src={
                      require("../../../assets/purple-back-arrow.svg").default
                    }
                  />
                </div>
              </div>
              <div className="cell-containers">
                {section.data.map((item: any) => (
                  <div
                    className={`cell ${
                      section.sectionName === "Top Products"
                        ? "product-cell"
                        : ""
                    }`}
                  >
                    <div className="cell-header">
                      <div className="cell-title">{item.title}</div>
                      {(item.percentage || item.percentage === 0) && (
                        <div
                          className={`cell-percentage ${
                            Math.sign(item.percentage) === -1 ? "red" : "green"
                          }`}
                        >
                          {`${
                            Math.sign(item.percentage) === -1 ? "" : "+"
                          }${item.percentage.toFixed(2)}`}
                          %
                        </div>
                      )}
                    </div>
                    <div className="cell-price">${item.value.toFixed(2)}</div>
                    {(item.qty || item.qty === 0) && (
                      <div className="other-details">
                        <div className="other-detail-item">
                          <div>Qty</div>
                          <div>{item.qty}</div>
                        </div>
                        <div className="other-detail-item">
                          <div>Percent of total sales</div>
                          <div>{item.totalSales}%</div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};
export default Reporting;
