import { Button, Icon } from "@blueprintjs/core";
import firebase from "config/firebase";
import { find } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
// @ts-ignore
// import BigCalendar from 'react-big-calendar-like-google';
import {
  Calendar,
  EventProps,
  ToolbarProps,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";

import { RootState } from "model/store";

import "./index.css";
import AddEvent from "./modals/AddEvent";

// BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
const localizer = momentLocalizer(moment);

const EventCalendar = () => {
  const db = firebase.firestore();

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
  const [eventList, setEventList]: any = useState([]);
  const [selectedEvent, setSelectedEvent]: any = useState();
  const [modalMode, setModalMode] = useState<"edit" | "create" | "read">(
    "create"
  );

  const emulatorMode = useSelector((state: RootState) => state.emulatorMode);

  const transformDataForEvent = (data: any) => {
    const filterEventsBasedOnUser = data.filter((event: any) => {
      const isInvitedUser =
        find(event.invitedPeople, ["id", user.id]) !== undefined;
      return isInvitedUser || event.user.id === user.id;
    });

    return filterEventsBasedOnUser.map((event: any) => {
      return {
        title: event.eventName,
        bgColor: event.color,
        allDay: event.eventDuration.allDay,
        start: moment(event.eventDuration.fromDateTime.toMillis()),
        end: moment(event.eventDuration.toDateTime.toMillis()),
        eventAllData: {
          ...event,
        },
      };
    });
  };

  const getEvents = (businessId: string) => {
    try {
      db.collection("CalenderEvents")
        .where("businessId", "==", businessId)
        .where("deleted", "==", false)
        .onSnapshot((snapshot: any) => {
          let EventArr: any = [];
          snapshot.forEach((doc: any) => {
            EventArr = [...EventArr, doc.data()];
          });
          console.log("EventArr", EventArr);
          setEventList(transformDataForEvent(EventArr));
        });
    } catch (error) {
      console.error(error);
    }
  };

  const MyToolbar = (toolbar: ToolbarProps) => {
    return (
      <div className="custom-toolbar">
        <div>
          <div className="current-moth-container">
            <div className="current-moth">{toolbar.label}</div>
            <Icon
              icon="chevron-left"
              size={25}
              color="#424452"
              onClick={() => toolbar.onNavigate("PREV")}
            />
            <Icon
              icon="chevron-right"
              size={25}
              color="#424452"
              onClick={() => toolbar.onNavigate("NEXT")}
            />
          </div>
          <div className="today-date">
            Today is {moment().format("dddd, MMMM Do YYYY")}
          </div>
        </div>
        <div>
          <Icon icon="search" color="#7C7F93" />
          <Button
            text="New Event"
            className="new-event"
            onClick={() => {
              setModalMode("create");
              setShowAddEventModal(true);
            }}
          />
        </div>
      </div>
    );
  };
  const CustomEvent = ({ event }: EventProps<any>) => {
    return (
      <div
        className="single-event"
        style={{ backgroundColor: event.bgColor + "1F" }}
        onClick={() => {
          setModalMode("read");
          setSelectedEvent(event);
          setShowAddEventModal(true);
        }}
      >
        {" "}
        <Icon icon="dot" color={event.bgColor} /> {event.title}
      </div>
    );
  };

  useEffect(() => {
    if (business && user) {
      business.id && getEvents(business.id);
    }
  }, [business, user]);

  return (
    <div
      id="event-calendar-page"
      className={`${emulatorMode ? "emulatorMode" : ""}`}
    >
      <Calendar
        popup
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        components={{
          toolbar: MyToolbar,
          event: CustomEvent,
        }}
      />
      <AddEvent
        isOpen={showAddEventModal}
        onClose={() => setShowAddEventModal(false)}
        selectedEvent={selectedEvent}
        modalMode={modalMode}
        setModalMode={setModalMode}
      />
    </div>
  );
};
export default EventCalendar;
