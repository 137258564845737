import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

import HorizontalTable from "components/Table/HorizontalTable";

import MoveFolder from "../MoveModal";
import ShareModal from "../ShareModal";

import useFiles from "./useFiles";
import { useDocs } from "./hooks/useDocs";



const { Icon, Dropdown } = UIComponents;

export const TrashedFiles = () => {
  const {
    noOp,

    isEditing,
    setEditing,
    updateData,
    setShowShareModal,
    setShowMoveModal,

  } = useFiles();

  const {
    columns,
    instructions,
    trashedData,
    folderDepthHistory,
    folderTrashDepthHistory,
    setCurrentTrashedFolder,
    handleTrashedFilesRowClick
  } = useDocs()

  const renderTopBarFolderNavigation = () => {
    // This is the root directory
    if (folderTrashDepthHistory.length === 1) {
      return (
        <span
          className="text-big font-lato-black text-black"
          onClick={() => {
            // Set the current folder to the first folder
            if (folderTrashDepthHistory.length) {
              setCurrentTrashedFolder(folderDepthHistory[0]);
            }
          }}
        >
          Trash
        </span>
      );
    }

    // With more that one directory set the folder depth history
    // to the directory name selected
    return folderTrashDepthHistory.map((directory, index) => {
      return (
        <span
          className="text-big font-lato-black text-black"
          onClick={() => {
            const newFolderDepth = folderDepthHistory.slice(0, index + 1);
            setCurrentTrashedFolder(newFolderDepth[newFolderDepth.length - 1]);
          }}
        >
          {directory.name === "root" ? "Trash" : directory.name}
          {index < folderTrashDepthHistory.length - 1 && (
            <Icon size="30" name="chevron-right" />
          )}
        </span>
      );
    });
  };


  return (
    <>
      <div className={"flex px-10 py-6 flex-col"} style={{ width: "90%" }}>
        <div className="flex items-center justify-between">
          <ul className="p-0 m-0 list-none flex items-center">
            <ul className="p-0 m-0 list-none flex items-center">

              {renderTopBarFolderNavigation()}
            </ul>

          </ul>

        </div>

        <HorizontalTable
          title=""
          columns={columns}
          data={trashedData}
          instructions={instructions}
          saveResults={noOp}
          setEditing={setEditing}
          isEditing={isEditing}
          hideEdit
          hideCheckboxes
          recievedUpdate={updateData}
          onRowClick={(uniqueId: string) => {
            console.log("uniqueId", uniqueId)
            handleTrashedFilesRowClick(uniqueId);
            // const folderIndex = parseInt(data);
            // if (treeData[folderIndex]?.kind === "folder") {
            //   setSelectedFolders((folders) => [
            //     ...folders,
            //     treeData[folderIndex],
            //   ]);
            // }
          }}
          onPopupEvent={(object, event) => {
            if (event === "share") {
              setShowShareModal(true);
            }
            if (event === "move-to") {
              setShowMoveModal(true);
            }
          }}
        />
      </div>
      {/* <ShareModal
        isOpen={showShareModal}
        closeModal={() => setShowShareModal(false)}
        fileOrFolderForSharing={null}
      /> */}
      {/* <MoveFolder
        isOpen={showMoveModal}
        closeModal={() => setShowMoveModal(false)}
        data={data}
      /> */}
    </>
  );
};