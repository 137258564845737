import { FirebaseOrderDisplayDoc } from "types/orderDisplay";

import ActionType from "./actionTypes";

export function receiveOrderDisplays(orderDisplays: FirebaseOrderDisplayDoc[]) {
  return {
    type: ActionType.RECEIVE_ORDER_DISPLAYS,
    orderDisplays,
  };
}
