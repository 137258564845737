import { MouseEvent, useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  MarkerType,
  Node,
  StepEdge,
  StraightEdge,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from "react-flow-renderer";

import CustomEdge from "./CustomEdge";

const onNodeDragStop = (_: MouseEvent, node: Node) =>
  console.log("drag stop", node);
const onNodeClick = (_: MouseEvent, node: Node) => console.log("click", node);

const initialNodes: Node[] = [
  {
    id: "1",
    data: { label: "Node 1" },
    position: { x: 0, y: 100 },
    className: "light",
  },
  {
    id: "2",
    data: { label: "Node 2" },
    position: { x: 250, y: 100 },
    className: "light",
  },
  {
    id: "3",
    data: { label: "Node 3" },
    position: { x: 500, y: 100 },
    className: "light",
  },
  {
    id: "4",
    data: { label: "Node 4" },
    position: { x: 750, y: 100 },
    className: "light",
  },
];
const edgeTypes = {
  special: CustomEdge,
};
const initialEdges: Edge[] = [];

const defaultEdgeOptions = { zIndex: 0 };

const BasicFlow = () => {
  const instance = useReactFlow();
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const updatePos = () => {
    instance.setNodes((nodes) =>
      nodes.map((node) => {
        node.position = {
          x: Math.random() * 400,
          y: Math.random() * 400,
        };

        return node;
      })
    );
  };

  const logToObject = () => console.log(instance.toObject());
  const resetTransform = () => instance.setViewport({ x: 0, y: 0, zoom: 1 });

  const toggleClassnames = () => {
    instance.setNodes((nodes) =>
      nodes.map((node) => {
        node.className = node.className === "light" ? "dark" : "light";

        return node;
      })
    );
  };
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => {
      setEdges((eds) => addEdge({ ...connection }, eds));
    },
    [setEdges]
  );
  useEffect(() => {
    console.log(edges);
  }, [edges]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodeClick={onNodeClick}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      className="react-flow-basic-example"
      minZoom={0.2}
      maxZoom={4}
      defaultZoom={0}
      fitView
      edgeTypes={edgeTypes}
    >
      <Background variant={BackgroundVariant.Lines} />

      <div style={{ position: "absolute", right: 10, top: 10, zIndex: 4 }}>
        <button onClick={resetTransform} style={{ marginRight: 5 }}>
          reset transform
        </button>
        <button onClick={updatePos} style={{ marginRight: 5 }}>
          change pos
        </button>
        <button onClick={toggleClassnames} style={{ marginRight: 5 }}>
          toggle classnames
        </button>
        <button onClick={logToObject}>toObject</button>
      </div>
    </ReactFlow>
  );
};
export default BasicFlow;
