import { Icon, Position, Tooltip } from "@blueprintjs/core";
import { IconButton } from "@mui/material";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import React from "react";

// import "tailwindcss/tailwind.css"
import StaffScheduleIcon from "assets/staff-schedule-icon.svg";

import "./style.css";

interface Props {
  title: string;
  subtitle: string;
  assigned?: boolean;
  onClick?: () => void;
  mainColor: string;
  backgroundColor: string;
  availabilityWarningVisible?: boolean;
  onWeeklyScheduleClick: () => void;
  upperSubtitle?: string;
  shiftTypeName: string;
}

const renderAvailabilityWarning = () => {
  const content = (
    <div
      className="bg-white  flex-col justify-items-center items-center p-5"
      style={{ display: "flex" }}
    >
      <img
        src={require("assets/warning.svg").default}
        alt="warning"
        style={{ marginBottom: "10px" }}
      />
      <p className="font-sf-semibold text-letter-black max-w-xs text-center text-sm font-bold">
        This icon indicates that you have scheduled this staff member outside of
        their stated availability.
      </p>
    </div>
  );
  return (
    <Tooltip
      content={content}
      position={Position.RIGHT}
      openOnTargetFocus={false}
      className=" flex-col justify-items-center items-center"
      popoverClassName="warning-popover"
      modifiers={{
        arrow: {
          enabled: false,
        },
      }}
    >
      <img src={require("assets/warning.svg").default} alt="warning" />
    </Tooltip>
  );
};

const SlotCard = ({
  title,
  subtitle,
  assigned,
  onClick,
  backgroundColor,
  mainColor,
  availabilityWarningVisible = false,
  onWeeklyScheduleClick,
  shiftTypeName,
}: Props) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      style={{ cursor: "pointer" }}
      className="slot-card"
    >
      <div
        className="m-2 p-3 rounded-lg h-20"
        style={{
          backgroundColor,
          border: assigned ? `2px solid ${mainColor}` : "0px",
        }}
        onClick={onClick}
      >
        <div className="min-h-full">
          <div
            className=" flex-row justify-start items-center"
            style={{ display: "flex" }}
          >
            <div
              className="h-2 w-2 rounded-full mr-2"
              style={{ backgroundColor: mainColor }}
            />
            <div className=" font-sf-semibold text-letter-black text-xs">
              {shiftTypeName}
            </div>
          </div>
          <div
            className=" flex-row justify-between items-center"
            style={{ display: "flex" }}
          >
            <div
              className=" flex-row justify-evenly items-center"
              style={{
                marginBottom: "0.4rem",
                marginTop: "0.7rem",
                display: "flex",
              }}
            >
              <div
                className="h-2 w-2 rounded-full mr-2"
                style={{ backgroundColor: "transparent" }}
              />
              <div className="font-sf-semibold text-letter-black text-xs">
                {title}
              </div>
            </div>
            <div
              className={` flex-row justify-items-center items-center ${
                availabilityWarningVisible ? "mr-1.5" : ""
              }`}
              style={{ display: "flex" }}
            >
              {assigned ? (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onWeeklyScheduleClick();
                  }}
                  style={{ marginRight: availabilityWarningVisible ? 2 : 0 }}
                >
                  <img
                    className="h-4"
                    style={{ height: 18 }}
                    src={StaffScheduleIcon}
                    alt="staff-schedule"
                  />
                </IconButton>
              ) : null}
              {assigned && availabilityWarningVisible
                ? renderAvailabilityWarning()
                : null}
            </div>
          </div>
          <div
            className=" flex-row justify-between items-center"
            style={{ display: "flex" }}
          >
            <div
              className=" flex-row justify-evenly items-center"
              style={{ display: "flex" }}
            >
              <div className="h-2 w-2 rounded-full mr-2" />
              <div className="font-sf-bold text-tango-gray text-tiny">
                {subtitle}
              </div>
            </div>
            {assigned ? (
              <Icon
                className="mr-15"
                icon="tick"
                iconSize={17}
                color={mainColor}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const SmallSlotCard = ({
  title,
  subtitle,
  assigned,
  onClick,
  backgroundColor,
  mainColor,
  availabilityWarningVisible = false,
  upperSubtitle,
}: Props) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.98 }}
      className="slot-card"
      style={{
        margin: 0,
        maxHeight: "70%",
        maxWidth: "90%",
        cursor: "pointer",
      }}
    >
      <div
        className="p-3 rounded-lg"
        style={{
          backgroundColor,
          border: `2px solid ${mainColor}`,
          height: "100%",
          width: "100%",
        }}
        onClick={onClick}
      >
        <div className="min-h-full">
          {upperSubtitle ? (
            <div
              className=" font-sf-semibold text-tango-gray text-xxs"
              style={{ textAlign: "left" }}
            >
              {upperSubtitle}
            </div>
          ) : null}

          <div
            className=" flex-row justify-between items-center"
            style={{ display: "flex" }}
          >
            <div className=" font-sf-semibold text-letter-black text-xs">
              {title}
            </div>
            <div
              style={{ display: "flex" }}
              className={` flex-row justify-items-center items-center ${
                availabilityWarningVisible ? "mr-1.5" : ""
              }`}
            >
              {assigned && availabilityWarningVisible
                ? renderAvailabilityWarning()
                : null}
            </div>
          </div>
          <div
            style={{ display: "flex" }}
            className=" flex-row justify-between items-center"
          >
            <div className="font-sf-bold text-tango-gray text-xs">
              {subtitle}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SlotCard;
