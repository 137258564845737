import { FirebaseTableDoc } from "types/tables";

import { tablesAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseTableDoc[] = [];

export default function tables(state = initialState, action: tablesAction) {
  switch (action.type) {
    case ActionType.RECEIVE_PHYSICAL_TABLES:
      return action.tables;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
