import { IToasterProps } from "@blueprintjs/core";
import { KeyboardEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import firebase from "../../../config/firebase";
import { RootState } from "../../../model/store";
import { addResponse, fetchForm } from "../../../models/surveyBuilder";
import { saveFile } from "../../../utils/storage";
import { Form } from "../../ViewForms/useForms";
import { themesData } from "../data";
import {
  ComponentType,
  EndingSectionProps,
  Operators,
  Rule,
  SurveyData,
  ThemeProp,
} from "../types";

const composeSurveyData = (data: any[]): SurveyData[] => {
  return data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      subtitle: item.subtitle,
      required: item.required,
      type: item.type,
      value: null,
      images: item.images,
      choices:
        item.type === ComponentType.multipleChoice ||
          item.type === ComponentType.dropdown ||
          item.type === ComponentType.pictureChoice ||
          item.type === ComponentType.correctOrder
          ? item.values
          : [],
      selectedChoices: [],
      matchingObj: item.type === ComponentType.matching ? item.values : null,
      fillInBlankChoices:
        item.type === ComponentType.fillInBlank ? item.values : null,
      rules: item.rules,
      score: 0,
      isScorePending: item.hasAnswer ? true : false,
    };
  });
};
type ScrollDirectionType = "down" | "up";

const useSurvey = (
  id: string,
  businessId: string,
  toastRef: React.RefObject<IToasterProps> | null,
  isPreview: boolean,
  formData: Form | undefined
) => {
  const [surveyData, setSurveyData] = useState<SurveyData[]>([]);
  const [showUsernameInput, setShowUsernameInput] = useState<boolean>(false);
  const [theme, setTheme] = useState<ThemeProp>(themesData[0]);
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [showEndingSection, setShowEndingSection] = useState<boolean>(false);
  const [typeform, setTypeform] = useState<Form | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [endingSection, setEndingSection] = useState<EndingSectionProps | null>(
    null
  );
  const [endingSections, setEndingSections] = useState<EndingSectionProps[]>(
    []
  );
  const [welcomeScreen, setWelcomeScreen] = useState<EndingSectionProps | null>(
    null
  );
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirectionType>("up");
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        let response;
        if (!isPreview) {
          response = await fetchForm(id);
          response = response.data;
        } else {
          response = formData;
        }
        if (response) {
          if (!isPreview && (!response.enabled || response.deleted)) {
            navigate("/", { replace: true });
          }
          if (!response.isAnonymous) {
            setShowUsernameInput(true);
          }
          // @ts-ignore
          setTypeform(response);
          setSurveyData(composeSurveyData(response.questions));
          setEndingSections(response.endingSections);
          if (response.selectedEndingSection) {
            setEndingSection(response.selectedEndingSection);
          }
          setWelcomeScreen(response.welcomeScreen ?? null);
          setIsAnonymous(response.isAnonymous);
          setTheme(response.selectedTheme);
        }
      }
    };
    fetchData();
  }, []);

  const updateInputValues = (value: string) => {
    const clonedData = [...surveyData];
    const componentType = clonedData[currentStep]["type"];
    clonedData[currentStep]["value"] = value;
    if (
      componentType === ComponentType.pictureChoice ||
      componentType === ComponentType.yesNo ||
      componentType === ComponentType.dropdown ||
      componentType === ComponentType.ratings ||
      componentType === ComponentType.legal ||
      componentType === ComponentType.labelPicture
    ) {
      const currentQuestionFromTypeform = typeform?.questions[currentStep];
      const hasAnswer = currentQuestionFromTypeform["hasAnswer"];
      const correctAnswer = currentQuestionFromTypeform["answer"];
      const score =
        hasAnswer &&
          (componentType === ComponentType.ratings ? parseInt(value) : value) ===
          correctAnswer
          ? 1
          : 0;
      clonedData[currentStep].score = score;
      clonedData[currentStep].isScorePending = false;
    }

    setSurveyData(clonedData);
  };

  const updateMultipleChoice = (choice: string) => {
    const clonedData = [...surveyData];
    const currentQuestionFromTypeform = typeform?.questions[currentStep];
    const hasAnswer = currentQuestionFromTypeform["hasAnswer"];
    const correctAnswer = currentQuestionFromTypeform["answer"];
    let score = 0;
    const choiceIndex = surveyData[currentStep].selectedChoices.findIndex(
      (item) => item === choice
    );
    if (choiceIndex === -1) {
      clonedData[currentStep].selectedChoices.push(choice);
    } else {
      clonedData[currentStep].selectedChoices.splice(choiceIndex, 1);
    }
    if (
      hasAnswer &&
      JSON.stringify(clonedData[currentStep].selectedChoices) ===
      JSON.stringify(correctAnswer)
    ) {
      score = 1;
    } else {
      score = 0;
    }
    clonedData[currentStep].score = score;
    clonedData[currentStep].isScorePending = false;
    setSurveyData(clonedData);
  };

  const handleOnKeyDown = (e: KeyboardEvent) => {
    const { required, type, selectedChoices } = surveyData[currentStep];
    if (e.key === "Enter") {
      if (surveyData[currentStep].required) {
        if (
          type === ComponentType.correctOrder ||
          type === ComponentType.matching
        ) {
          setCurrentStep((currentStep) => currentStep + 1);
        } else if (
          type === ComponentType.multipleChoice &&
          selectedChoices.length > 0
        ) {
          setCurrentStep((currentStep) => currentStep + 1);
        } else if (surveyData[currentStep].value) {
          setCurrentStep((currentStep) => currentStep + 1);
        }
      } else {
        setCurrentStep((currentStep) => currentStep + 1);
      }
    }
  };
  const updateCorrectOrderChoices = (updatedChoices: any[]) => {
    const clonedData = [...surveyData];
    const currentQuestionFromTypeform = typeform?.questions[currentStep];
    const hasAnswer = currentQuestionFromTypeform["hasAnswer"];
    const correctAnswer = currentQuestionFromTypeform["answer"];
    let score = 0;
    if (
      hasAnswer &&
      JSON.stringify(updatedChoices) === JSON.stringify(correctAnswer)
    ) {
      score = 1;
    } else {
      score = 0;
    }
    clonedData[currentStep].score = score;
    clonedData[currentStep].isScorePending = false;
    clonedData[currentStep]["choices"] = updatedChoices;

    setSurveyData(clonedData);
  };
  const handleFileChange = async (image: string, fileName: string) => {
    try {
      let fileUrl = "";
      if (image && !image.startsWith("https://")) {
        fileUrl = await saveFile(
          `businesses/${business.id}/typeform/` + fileName,
          image
        );
      }
      const clonedData = [...surveyData];
      clonedData[currentStep]["value"] = fileUrl;
      setSurveyData(clonedData);
    } catch (error) {
      // @ts-ignore
      const err: firebase.firestore.FirestoreError = error;
      return { error: err.message };
    }
  };
  const updateMatchingChoices = (type: string, choices: string[]) => {
    const clonedData = [...surveyData];
    const currentQuestionFromTypeform = typeform?.questions[currentStep];
    const hasAnswer = currentQuestionFromTypeform["hasAnswer"];
    const correctAnswer = currentQuestionFromTypeform["answer"];

    const clonedMatchingObj = { ...clonedData[currentStep].matchingObj };
    if (clonedMatchingObj) {
      if (type === "leftArray") {
        clonedMatchingObj["leftArray"] = choices;
      }
      if (type === "rightArray") {
        clonedMatchingObj["rightArray"] = choices;
      }
      let score = 0;
      if (
        hasAnswer &&
        JSON.stringify(clonedMatchingObj) === JSON.stringify(correctAnswer)
      ) {
        score = 1;
      } else {
        score = 0;
      }
      clonedData[currentStep].score = score;
      clonedData[currentStep].isScorePending = false;
      //@ts-ignore
      clonedData[currentStep].matchingObj = { ...clonedMatchingObj };
      setSurveyData(clonedData);
    }
  };
  const updateFillInTheBlankAnswers = (index: number, value: string) => {
    const currentQuestionFromTypeform = typeform?.questions[currentStep];
    const hasAnswer = currentQuestionFromTypeform["hasAnswer"];
    const correctAnswer = currentQuestionFromTypeform["answer"];
    const clonedData = [...surveyData];

    clonedData[currentStep].selectedChoices[index] = value;
    let score = 0;
    if (
      hasAnswer &&
      JSON.stringify(clonedData[currentStep].selectedChoices) ===
      JSON.stringify(correctAnswer)
    ) {
      score = 1;
    } else {
      score = 0;
    }
    clonedData[currentStep].score = score;
    clonedData[currentStep].isScorePending = false;
    setSurveyData(clonedData);
  };
  const getFunctionString = () => {
    // var theInstructions = "alert('Hello World'); var x = 100";
    //
    // var F=new Function (theInstructions);
    //
    // return(F());
  };
  const getConditionFromOperator = (
    operator: Operators,
    currentStepValue: string,
    operationValue: string
  ) => {
    switch (operator) {
      case "contains":
        return currentStepValue.includes(operationValue);
      case "is-equal-to":
        return currentStepValue === operationValue;
      case "is-not-equal-to":
        return currentStepValue !== operationValue;
      case "does-not-contain":
        return !currentStepValue.includes(operationValue);
      case "begins-with":
        return currentStepValue.startsWith(operationValue);
      case "ends-with":
        return currentStepValue.endsWith(operationValue);
    }
  };
  const gotoNextStep = () => {
    setScrollDirection("up");
    const clonedData = [...surveyData];
    const currentStepValue = clonedData[currentStep]["value"];
    const mainCondition = "if";
    const rules = clonedData[currentStep]["rules"];
    // return false
    const filteredRules = rules.filter((item) => !item.default);
    let stop = false;
    if (filteredRules.length === 0) {
      gotoDefaultQuestion(rules[0]);
    }
    filteredRules.forEach((item, index) => {
      if (!stop) {
        const finalConditions = item.conditions.map((condition) =>
          getConditionFromOperator(
            condition.operator,
            currentStepValue || "",
            condition.operationValue
          )
        );
        let finalResult;
        let i = 0;
        do {
          const chainBy = item.conditions[i].chainBy;
          if (chainBy) {
            finalResult =
              chainBy === "and"
                ? finalResult && finalConditions[i]
                : finalResult || finalConditions[i];
          } else {
            finalResult = finalConditions[i];
          }
          i++;
        } while (i < finalConditions.length);
        if (finalResult && item.destination) {
          if (item.destination > clonedData.length) {
            gotoEndingSection(item.destination);
          } else {
            const destinationIndex = clonedData.findIndex(
              (singleQuestion) => singleQuestion.id === item.destination
            );
            setCurrentStep(destinationIndex);
          }
          stop = true;
        } else {
          gotoDefaultQuestion(rules[0]);
          stop = true;
        }
      }
    });
  };
  const gotoDefaultQuestion = (rule: Rule) => {
    if (rule.destination) {
      if (rule.destination > surveyData.length) {
        gotoEndingSection(rule.destination);
      } else {
        const destinationIndex = surveyData.findIndex(
          (singleQuestion) => singleQuestion.id === rule.destination
        );
        setCurrentStep(destinationIndex);
      }
      return false;
    } else {
      setCurrentStep(currentStep + 1);
      return false;
    }
  };
  const gotoEndingSection = (destinationIndex: number) => {
    const selectedEndingSection = endingSections.find(
      (endingSec) => endingSec.id === destinationIndex
    );
    if (selectedEndingSection) {
      setEndingSection(selectedEndingSection);
      setShowEndingSection(true);
    }
  };
  const checkForRemainingRequiredQuestionIndex = () => {
    const requiredQeustion = surveyData.findIndex((item) => {
      if (
        item.type === ComponentType.multipleChoice &&
        item.required &&
        item.selectedChoices.length > 0
      ) {
        return false;
      } else if (
        item.type === ComponentType.correctOrder ||
        item.type === ComponentType.matching
      ) {
        return false;
      } else if (item.required && !item.value) {
        return true;
      }
    });
    return requiredQeustion;
  };
  const submitForm = async () => {
    setShowLoading(true);
    const index = checkForRemainingRequiredQuestionIndex();
    if (index !== -1) {
      //@ts-ignore
      toastRef.current.show({
        message: "Please enter the required details",
        intent: "danger",
      });
      setCurrentStep(index);
    } else {
      if (id) {
        const formResponse = await addResponse(
          surveyData,
          id,
          businessId,
          username
        );
        console.log(formResponse);

        if (formResponse?.data) {
          setShowEndingSection(true);
        } else {
          console.log(formResponse);
        }
      }
    }
    setShowLoading(false);
  };
  const resetForm = () => {
    if (typeform) {
      setShowEndingSection(false);
      setSurveyData(composeSurveyData(typeform.questions));
      setCurrentStep(0);
    }
  };
  return {
    surveyData,
    showUsernameInput,
    theme,
    isAnonymous,
    currentStep,
    username,
    showEndingSection,
    endingSection,
    scrollDirection,
    updateInputValues,
    updateMultipleChoice,
    handleOnKeyDown,
    updateCorrectOrderChoices,
    handleFileChange,
    updateMatchingChoices,
    updateFillInTheBlankAnswers,
    gotoNextStep,
    submitForm,
    setCurrentStep,
    setShowUsernameInput,
    setUsername,
    setScrollDirection,
    showLoading,
    welcomeScreen,
    setShowEndingSection,
    setSurveyData,
    resetForm,
  };
};
export default useSurvey;
