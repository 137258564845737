import ActionType from "./actionTypes";

export function receiveAllBoards(boards: any) {
  return {
    type: ActionType.RECEIVE_BOARDS,
    boards,
  };
}

export function receiveAllTickets(tickets: any) {
  return {
    type: ActionType.RECEIVE_BOARD_TICKETS,
    tickets,
  };
}
