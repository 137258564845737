import {
  Button as BlueprintButton,
  Dialog,
  H5,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import UIComponents from "@tangopay/tango-ui-library";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { firstBy } from "thenby";
import EnterpriseInitializeLoading from "views/Enterprise/EnterpriseInit";
import { saveStitchedProductsToFirebase } from "views/admin/menus&Products/SaveStitchedProducts";
import MenuHoursTable from "views/admin/menus&Products/menus/MenuHoursTable";
import MenusTable from "views/admin/menus&Products/menus/MenusTable";

import DraggableList from "components/DraggableList/DraggableList";
import Spinner from "components/Spinner";

import {
  addNewMenu,
  changeMenuName,
  createDuplicateMenu,
  deleteMenuForBusinesses,
  reOrderCategoriesAndProducts,
  setMenuAsDeleted,
} from "models/menus";

import { DraggableItem, DraggableType } from "types/document";
import {
  FirebaseMenuDoc,
  MenuAndCategoriesData,
  ProductIndexingByOrders,
} from "types/menus";

import { RootState } from "model/store";

import DragFlick from "assets/drag-flick.png";

import Layout from "./Layout";
import MenuCard from "./MenuCard";
import "./style.css";

const { Button, Icon, Modal, Dropdown, TextField } = UIComponents;

const Menus = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );
  const products: FirebaseProductDoc[] = useSelector(
    (state: RootState) => state.products || []
  );
  const menuCategories: FirebaseMenuCategoryDoc[] = useSelector(
    (state: RootState) => state.menuCategories
  );
  const menus: FirebaseMenuDoc[] = useSelector(
    (state: RootState) => state.menus
  );

  const modifiers: FirebaseModifierDoc[] = useSelector((state: RootState) =>
    state.modifiers.sort(firstBy("nameExternal", { ignoreCase: true }))
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );

  const initComplete: boolean = useSelector(
    (state: RootState) => state.enterpriseInitComplete
  );

  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const menusToShow = isEnterpriseLevel
    ? menus.filter((menu) => menu.enterpriseUpdatedDocument)
    : menus.filter((menu) => !menu.enterpriseUpdatedDocument);

  const [selectedMenusForPublishing, setSelectedMenusForPublishing] = useState<
    FirebaseMenuDoc[]
  >([]);
  const [currentMenu, setCurrentMenu] = useState<FirebaseMenuDoc | null>(null);
  const [allMenuAndCategoriesData, setAllMenuAndCategoriesData] = useState<
    MenuAndCategoriesData[]
  >([]);

  // Duplicate menu stuff
  const [duplicatePlaceholderMenuName, setDuplicatePlaceholderMenuName] =
    useState<string>("");
  const [duplicateMenuName, setDuplicateMenuName] = useState("");
  const updateDuplicateMenuName = useCallback(
    (e) => setDuplicateMenuName(e.target.value),
    []
  );
  const [showDuplicateMenuModal, setShowDuplicateMenuModal] = useState(false);
  const [duplicateMenuLoading, setDuplicateMenuLoading] =
    useState<boolean>(false);

  // New menu modal
  const [showCreateNewMenu, setShowCreateNewMenu] = useState(false);
  const [newMenuLoading, setNewMenuLoading] = useState<boolean>(false);
  const [newMenuName, setNewMenuName] = useState("");
  const updateNewMenuName = useCallback(
    (e) => setNewMenuName(e.target.value),
    []
  );
  const [newMenuType, setNewMenuType] = useState("");

  const [menuSelected, setMenuSelected] = useState<any | null>(null);
  const [allViableMenuCategories, setAllViableMenuCategories] = useState<any>(
    []
  );
  const [menuCategoriesSelected, setMenuCategoriesSelected] = useState<
    any | null
  >([]);
  const [allMenuAndMenuCategoriesToCopy, setAllMenuAndMenuCategoriesToCopy] =
    useState<{ category: FirebaseMenuCategoryDoc; menu: FirebaseMenuDoc }[]>(
      []
    );

  const [menuCategoryOrder, setMenuCategoryOrder] = useState<DraggableType>([
    {
      id: "categories",
      label: "Categories",
      items: menuCategories.map((menuCategory: any) => ({
        id: menuCategory.id,
        label: menuCategory.name,
        selected: false,
      })) as DraggableItem[],
    },
  ]);
  const [productsByMenuCategoriesOrder, setProductsByMenuCategoriesOrder] =
    useState<ProductIndexingByOrders>({});
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  // Modal booleans
  const [showDeleteMenuModal, setShowDeleteMenuModal] = useState(false);
  const [deleteMenuLoading, setDeleteMenuLoading] = useState<boolean>(false);

  const [showChangeMenuNameModal, setShowChangeMenuNameModal] = useState(false);
  const [changeMenuNameLoading, setChangeMenuNameLoading] =
    useState<boolean>(false);

  const [showHoursModal, setShowHoursModal] = useState(false);
  const [showOrderIndexingModal, setShowOrderIndexingModal] = useState(false);
  const [orderIndexingLoading, setOrderIndexingLoading] = useState(false);

  const discounts: DocumentData[] = [];

  const hours = [
    {
      closeTime: "4:00",
      day: "monday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "tuesday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "wednesday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "thursday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "friday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "saturday",
      open: true,
      openTime: "8:00",
    },
    {
      closeTime: "4:00",
      day: "sunday",
      open: true,
      openTime: "8:00",
    },
  ];

  const convertMenuForOrdering = (
    menuToOrder: FirebaseMenuDoc,
    allMenuCategories: FirebaseMenuCategoryDoc[]
  ): DraggableType => {
    const categoryForOrdering = [
      {
        id: "categories",
        label: "Categories",
        // @ts-ignore
        items: menuToOrder.categories
          .map((categoryId: string) => {
            const categoryIndex = allMenuCategories.findIndex(
              (category) => category.id === categoryId
            );
            if (categoryIndex !== -1) {
              return {
                id: categoryId,
                label: allMenuCategories[categoryIndex].name,
                selected: false,
              };
            }
            return null;
          })
          .filter((i) => !!i),
      },
    ];
    // @ts-ignore
    return categoryForOrdering;
  };

  // On mount get menus - on update set menu and menu categories
  useEffect(() => {
    const menusForData = isEnterpriseLevel
      ? menus.filter((menu) => menu.enterpriseUpdatedDocument)
      : menus.filter((menu) => !menu.enterpriseUpdatedDocument);
    // if (menusForData.length > 0) {
    //   let menuToSet: FirebaseMenuDoc | null = null;
    //   if (!currentMenuName) {
    //     menuToSet = menusForData[0];
    //   } else {
    //     const index = menusForData.findIndex(
    //       (existingMenu: FirebaseMenuDoc) =>
    //         existingMenu.nameExternal === currentMenuName
    //     );
    //     if (index !== -1) {
    //       menuToSet = menusForData[index];
    //     } else {
    //       menuToSet = menusForData[0];
    //     }
    //   }

    //   if (menuToSet) {
    //     setCurrentMenu(menuToSet);
    //     setMenuSelected(menuToSet);
    //     const selectedCategory = allMenuAndCategoriesData.filter(
    //       (menu) => menu.menuName === menuToSet?.nameExternal
    //     );

    //     if (selectedCategory.length > 0) {
    //       setAllViableMenuCategories(selectedCategory[0].data);
    //       setMenuCategoriesSelected([]);
    //     }

    //     // setMenuCategoryOrder(convertMenuForOrdering(menuToSet, menuCategories));
    //   }

    //   // There are no menus / all menus have been deleted
    // } else {
    //   setCurrentMenu(null);
    //   setMenuSelected(null);
    //   setAllViableMenuCategories([]);
    //   setMenuCategoriesSelected([]);
    // }
    const uniqueMenus = menusForData.map((menu: any) => ({
      menuId: menu.id,
      menuName: menu.nameExternal,
      categories: menu.categories,
    }));
    setAllMenuAndCategoriesData([
      ...uniqueMenus.map((menu: any) => ({
        menuName: menu.menuName,
        menuId: menu.menuId,
        data: menuCategories.filter((menuCategory: any) =>
          menu.categories.includes(menuCategory.id)
        ),
      })),
    ]);
  }, [menus, menuCategories]);

  // Add viable menu categories based on the menu name
  useEffect(() => {
    if (allMenuAndCategoriesData) {
      const selectedCategory = allMenuAndCategoriesData.filter((menu) =>
        menuSelected ? menu.menuName === menuSelected.nameExternal : false
      );
      if (selectedCategory.length > 0) {
        setAllViableMenuCategories(selectedCategory[0].data);
        setMenuCategoriesSelected([]);
      }
    }
  }, [menuSelected]);

  // Change the ordering of categories / products
  useEffect(() => {
    // Change for select
    if (menuCategoryOrder.length > 0) {
      const itemSelectedIndex = menuCategoryOrder[0].items.findIndex(
        (item: any) => item.selected
      );
      if (itemSelectedIndex !== -1) {
        const menuCategoryId = menuCategoryOrder[0].items[itemSelectedIndex].id;
        setSelectedCategoryId(menuCategoryId);
        if (
          !Object.keys(productsByMenuCategoriesOrder).includes(menuCategoryId)
        ) {
          const menuCategoryIndex = menuCategories.findIndex(
            (category: any) => category.id === menuCategoryId
          );
          if (menuCategoryIndex !== -1) {
            const productIds = menuCategories[menuCategoryIndex].products;
            const menuCategoryName = menuCategories[menuCategoryIndex].name;
            // Find the selected products and sort them by the order of productIds
            const selectedProducts = products
              .filter((product: any) => productIds.includes(product.id))
              .sort(
                (a, b) => productIds.indexOf(a.id) - productIds.indexOf(b.id)
              );
            setProductsByMenuCategoriesOrder({
              ...productsByMenuCategoriesOrder,
              [menuCategoryId]: {
                id: "products",
                label: `Products - ${menuCategoryName}`,
                items: selectedProducts.map((product: any) => ({
                  id: product.id,
                  label: product.nameExternal,
                  sublabel: `$ ${(product.price / 100).toFixed(2)}`,
                  selected: false,
                })),
              },
            });
            // setProductOrder([
            //     {
            //         ...productOrder[0],
            //         label: 'Products - ' + menuCategoryName,
            //         items: selectedProducts.map((product: any) => ({ id: product.id, label: product.nameExternal, sublabel: '$ ' + (product.price / 100).toFixed(2), selected: false }))
            //     }
            // ])
          }
        }
      }
    }
  }, [menuCategoryOrder]);

  if (!initComplete) {
    return <EnterpriseInitializeLoading />;
  }

  if (business) {
    return (
      <Layout>
        {!currentMenu && (
          <>
            <div className="flex justify-between items-center">
              <div className="text-big text-black font-lato-black">Menus</div>
              <div className="flex items-center">
                {isEnterpriseLevel && (
                  <Link to="/publish-menu">
                    <Button
                      onClick={() => { }}
                      type="btn-style-1"
                      size="btn-medium"
                      label="Publish"
                    />
                  </Link>
                )}
                <Button
                  type="btn-style-1"
                  className="bg-black text-white hover:bg-black hover:text-white ml-5"
                  size="btn-medium"
                  label="New Menu"
                  onClick={() => setShowCreateNewMenu(true)}
                />
                <div className="flex w-80 bg-blue-grey-2 ml-4 py-1.5 rounded-full relative">
                  <input
                    className="border-0 bg-transparent pl-10 w-full"
                    placeholder="search"
                  />
                  <Icon
                    name="search"
                    size="16"
                    className="absolute top-2.5 left-5"
                  />
                </div>
              </div>
            </div>
            <ul className="list-none p-0 m-0 flex flex-wrap mt-8">
              {menusToShow.map((menu) => (
                <li style={{ width: "25%" }} className="mb-8">
                  <MenuCard
                    heading={menu.nameExternal}
                    subHeading={`Last updated on ${moment(menu.updatedAt)
                      .format("MM/DD/YYYY")
                      .toString()}`}
                    hasShadow
                    onChange={(e) => {
                      setSelectedMenusForPublishing([
                        ...selectedMenusForPublishing,
                        menu,
                      ]);
                    }}
                    onClick={() => {
                      setCurrentMenu(menu);
                      setMenuCategoryOrder(
                        convertMenuForOrdering(menu, menuCategories)
                      );
                    }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {!showOrderIndexingModal && currentMenu && (
          <MenusTable
            businessId={business.id}
            menu={currentMenu}
            products={products}
            modifiers={modifiers}
            allMenuAndCategoriesData={allMenuAndCategoriesData}
            discounts={discounts}
            goBack={() => setCurrentMenu(null)}
            customComponent={
              <div className="menu-stuff">
                <BlueprintButton
                  rightIcon={<img src={DragFlick} />}
                  minimal
                  onClick={() => setShowOrderIndexingModal(true)}
                />
                <BlueprintButton
                  rightIcon="time"
                  minimal
                  onClick={() => setShowHoursModal(!showHoursModal)}
                />
                <Popover
                  content={
                    <Menu style={{ minWidth: 240 }}>
                      <H5
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        Actions
                      </H5>
                      <MenuItem
                        text="Duplicate Menu"
                        onClick={() => {
                          setShowDuplicateMenuModal(true);
                          setDuplicatePlaceholderMenuName(
                            `${currentMenu ? currentMenu.nameExternal : ""
                            } (Copy)`
                          );
                        }}
                      />
                      <Menu.Divider />
                      <MenuItem
                        text="Change Menu Name"
                        onClick={() => {
                          setShowChangeMenuNameModal(true);
                        }}
                      />
                      <Menu.Divider />
                      <MenuItem
                        text="Delete Menu"
                        onClick={() => setShowDeleteMenuModal(true)}
                      />
                    </Menu>
                  }
                  position={Position.BOTTOM}
                >
                  <BlueprintButton rightIcon="more" minimal />
                </Popover>
              </div>
            }
          />
        )}

        {/* Show indexing component */}
        {showOrderIndexingModal && (
          <div className="indexing-menu">
            <div
              className="indexing-menu-top"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="">
                <div className="text-big text-black font-lato-black">
                  Order categories & products
                </div>
                <div className="text-xl text-black font-lato-black">
                  Manage how you want categories and products to be displayed
                </div>
              </div>
              <div className="f-end">
                <BlueprintButton
                  loading={orderIndexingLoading}
                  text="Done"
                  intent="success"
                  style={{
                    backgroundColor: "#61C554",
                    backgroundImage: "none",
                    boxShadow: "none",
                  }}
                  onClick={async () => {
                    if (currentMenu) {
                      setOrderIndexingLoading(true);
                      await reOrderCategoriesAndProducts(
                        currentMenu,
                        menuCategoryOrder,
                        productsByMenuCategoriesOrder
                      );

                      if (!isEnterpriseLevel) {
                        await saveStitchedProductsToFirebase(business.id);
                      }
                    }

                    setOrderIndexingLoading(false);
                    setShowOrderIndexingModal(false);
                  }}
                />
              </div>
              <br />
            </div>
            <div
              className="indexing-items"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <div
                className="categories-draggable-list"
                style={{ display: "block", flex: 1 }}
              >
                <DraggableList
                  data={menuCategoryOrder}
                  setData={setMenuCategoryOrder}
                  isSelectable
                />
              </div>
              <div
                className="products-draggable-list"
                style={{ display: "block", flex: 3 }}
              >
                <DraggableList
                  data={
                    selectedCategoryId !== ""
                      ? [productsByMenuCategoriesOrder[selectedCategoryId]]
                      : [
                        {
                          id: "products",
                          label: "Products",
                          items: [],
                        },
                      ]
                  }
                  setData={(data: DraggableType) => {
                    setProductsByMenuCategoriesOrder({
                      ...productsByMenuCategoriesOrder,
                      [selectedCategoryId]: data[0],
                    });
                  }}
                  isSelectable={false}
                />
              </div>
            </div>
          </div>
        )}
        {/* End indexing component */}

        {/* Create Menu Modal */}
        <Modal
          isOpen={showCreateNewMenu}
          closeModal={() => setShowCreateNewMenu(false)}
          width={"736"}
        >
          <div className={"text-big text-black font-lato-black"}>New Menu</div>
          <div className={"justify-between mt-10"}>
            <div className={"w-full"}>
              <div className={"text-base text-black font-lato-black mb-3"}>
                Menu Name
              </div>
              <TextField
                sizeType={"medium"}
                className="w-full"
                placeholder={"Enter menu name"}
                onChange={updateNewMenuName}
              />
            </div>
            <div className={"w-full mt-10"}>
              <div className={"text-base text-black font-lato-black mb-3"}>
                Menu Type
              </div>
              <div className="menu-type-dropdown-container">
                <Dropdown
                  menuPosition={"absolute"}
                  className="w-full"
                  size={"medium"}
                  options={[
                    {
                      label: "Dine-in",
                      value: "dineIn",
                    },
                    {
                      label: "Carryout & Delivery",
                      value: "carryoutAndDelivery",
                    },
                    {
                      label: "Dine-in PWA",
                      value: "dineInPwa"
                    }
                  ]}
                  onChange={(newValue) => {
                    if (newValue) {
                      // @ts-ignore
                      setNewMenuType(newValue.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {newMenuLoading && (
            <div className="w-full">
              <Spinner />
            </div>
          )}
          <div className={"flex mt-16 justify-between"}>
            <Button
              label={"Cancel"}
              type={"btn-style-1"}
              className={"justify-center cursor-pointer"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={() => setShowCreateNewMenu(false)}
            />
            <Button
              label={"Create"}
              className={
                "justify-center bg-black text-white hover:bg-black hover:text-white cursor-pointer"
              }
              type={"btn-style-1"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={async () => {
                if (newMenuName) {
                  const newMenu: FirebaseMenuDoc = {
                    businessId: business.id,
                    categories: [],
                    createdAt: new Date(),
                    deleted: false,
                    enabled: true,
                    endDate: null,
                    // Default menu hours for all menus
                    hours,
                    id: "",
                    index: menus.length,
                    isDineInMenu: newMenuType === "dineIn" ? true : false,
                    isDeliveryAndCarryOutMenu:
                      newMenuType === "carryoutAndDelivery" ? true : false,
                    isDineInPwaMenu: newMenuType === 'dineInPwa' ? true : false,
                    isInternalMenu: false,
                    nameExternal: newMenuName,
                    nameInternal: newMenuName,
                    privateMenu: false,
                    spendingLimit: null,
                    startDate: null,
                    tags: [],
                    updatedAt: new Date(),
                    enterpriseUpdatedDocument: isEnterpriseLevel,
                  };

                  setNewMenuLoading(true);
                  setNewMenuName("");
                  setMenuSelected(null);
                  setAllViableMenuCategories([]);
                  setMenuCategoriesSelected([]);
                  setAllMenuAndMenuCategoriesToCopy([]);
                  await addNewMenu(newMenu, allMenuAndMenuCategoriesToCopy);
                  document.location.reload();
                }
                setShowCreateNewMenu(false);
              }}
            />
          </div>
        </Modal>
        {/* End Create Menu Modal */}

        {/* Delete Menu Modal */}
        <Modal
          isOpen={showDeleteMenuModal}
          closeModal={() => setShowDeleteMenuModal(false)}
          width={"472"}
        >
          <div className={"text-big text-black font-lato-black"}>
            Delete menu?
          </div>
          <div className={"text-sm text-grey-2 font-lato-regular mt-1"}>
            Deleting this menu will also delete all instances of this menu at locations where it has been published.
          </div>
          {deleteMenuLoading && (
            <div className="w-full">
              <Spinner />
            </div>
          )}
          <div className={"flex mt-8 center"}>
            <Button
              label={"Delete"}
              type={"btn-style-1"}
              className={"justify-center cursor-pointer"}
              size={"btn-large"}
              style={{
                width: "392px",
                height: "72px",
                backgroundColor: "#E3564D",
                color: "#ffffff",
                borderRadius: "15px",
              }}
              onClick={async () => {
                if (currentMenu) {
                  setDeleteMenuLoading(true);
                  if (menus.length <= 1) {
                    alert(
                      "Your business must have at least one menu to complete this update."
                    );
                    setDeleteMenuLoading(false);
                  } else {
                    await setMenuAsDeleted(currentMenu.id);
                    await deleteMenuForBusinesses(currentMenu.nameExternal);
                    for await (const location of businesses) {
                      await saveStitchedProductsToFirebase(location.id)
                    }
                    document.location.reload();
                  }
                }
              }}
            />
          </div>
          <div className={"flex mt-8 center"}>
            <Button
              label={"Cancel"}
              className={"justify-center cursor-pointer"}
              type={"btn-style-1"}
              size={"btn-large"}
              style={{
                width: "392px",
                height: "72px",
                borderRadius: "15px",
                backgroundColor: "#F5F7FB",
              }}
              onClick={() => setShowDeleteMenuModal(false)}
            />
          </div>
        </Modal>
        {/* End Delete Menu Modal */}

        {/* Duplicate Menu Modal */}
        <Modal
          isOpen={showDuplicateMenuModal}
          closeModal={() => setShowDuplicateMenuModal(false)}
          width={"472"}
        >
          <div className={"text-big text-black font-lato-black"}>
            Duplicate menu
          </div>
          <div className={"flex mt-8 center"}>
            <div style={{ width: "100%" }}>
              <div className={"text-base text-black font-lato-black mb-3"}>
                New Menu Name
              </div>
              <TextField
                sizeType={"medium"}
                className="w-full"
                placeholder={duplicatePlaceholderMenuName}
                onChange={updateDuplicateMenuName}
              />
            </div>
          </div>
          {duplicateMenuLoading && (
            <div className="w-full">
              <Spinner />
            </div>
          )}
          <div className={"flex mt-10 justify-between"}>
            <Button
              label={"Cancel"}
              type={"btn-style-1"}
              className={"justify-center cursor-pointer"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={() => setShowDuplicateMenuModal(false)}
            />
            <Button
              label={"Create"}
              className={
                "justify-center bg-black text-white hover:bg-black hover:text-white cursor-pointer"
              }
              type={"btn-style-1"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={async () => {
                if (currentMenu) {
                  setDuplicateMenuLoading(true);
                  await createDuplicateMenu(
                    currentMenu,
                    duplicateMenuName === ""
                      ? duplicatePlaceholderMenuName
                      : duplicateMenuName,
                    menus.length,
                    allMenuAndCategoriesData,
                    isEnterpriseLevel
                  );
                }

                setDuplicateMenuName("");
                window.location.reload();
              }}
            />
          </div>
        </Modal>
        {/* End Duplicate Menu Modal */}

        {/* Change Menu Name Modal */}
        <Modal
          isOpen={showChangeMenuNameModal}
          closeModal={() => setShowChangeMenuNameModal(false)}
          width={"472"}
        >
          <div className={"text-big text-black font-lato-black"}>
            Change Menu Name
          </div>
          <div className={"flex mt-8 center"}>
            <div style={{ width: "100%" }}>
              <div className={"text-base text-black font-lato-black mb-3"}>
                New Name
              </div>
              <TextField
                sizeType={"medium"}
                className="w-full"
                placeholder={"Enter new name"}
                onChange={updateNewMenuName}
              />
            </div>
          </div>
          {changeMenuNameLoading && (
            <div className="w-full">
              <Spinner />
            </div>
          )}
          <div className={"flex mt-10 justify-between"}>
            <Button
              label={"Cancel"}
              type={"btn-style-1"}
              className={"justify-center cursor-pointer"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={() => setShowDuplicateMenuModal(false)}
            />
            <Button
              label={"Create"}
              className={
                "justify-center bg-black text-white hover:bg-black hover:text-white cursor-pointer"
              }
              type={"btn-style-1"}
              size={"btn-large"}
              style={{ width: "48%" }}
              onClick={async () => {
                if (currentMenu) {
                  setChangeMenuNameLoading(true);
                  await changeMenuName(currentMenu.id, newMenuName);
                }

                window.location.reload();
              }}
            />
          </div>
        </Modal>
        {/* End Change Menu Name Modal */}

        {/* Hours Modal */}
        <Dialog
          isOpen={showHoursModal}
          onClose={() => setShowHoursModal(false)}
          style={{ padding: 20, width: 800, backgroundColor: "#fff" }}
        >
          {/* <div
            className="hours-header"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div className="left-hours-header">
              <H2>Menu Hours</H2>
            </div>
          </div> */}
          <div className="hours-table">
            {currentMenu && (
              <MenuHoursTable
                businessId={business.id}
                currentMenu={currentMenu}
              />
            )}
          </div>
        </Dialog>
        {/* End Hours Modal */}
      </Layout>
    );
  }

  return null;
};
export default Menus;
