import { Dialog } from "@blueprintjs/core";
import React from "react";

import firebase from "../../../../../config/firebase";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedTicketData: any;
  selectedBoard: any;
  onYesArchive: () => {};
}

export function ModalBoardArchive({
  isOpen,
  closeModal,
  selectedBoard,
  onYesArchive,
}: Props) {
  if (!selectedBoard) {
    return null;
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        className="modalBoardArchive"
        onClose={closeModal}
      >
        {/* <div className="modal-header">
          <div className="modal-title">
            <div className="action-div-row">
              <img src={BoardIcon} height="40px" width="40px" />
              <h2>To Do</h2>
            </div>
          </div>
        </div> */}

        <div className="modal-body">
          <label>Archive {selectedBoard.name} Board ?</label>
          <div className="action-div-row">
            <button className="btn default-btn" onClick={closeModal}>
              No
            </button>
            <button className="btn trigger-btn" onClick={onYesArchive}>
              Yes, Archive
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ModalBoardArchive;
