import { Button, Icon, InputGroup } from "@blueprintjs/core";
import { Dialog } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { DialogTitle } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";

import Box from "components/Box";

import {
  AddBoardStatus,
  archiveBoard,
  checkIsFormLinkedToBoard,
  createTicket,
  deleteStatus,
  updateBothStatusesCardsinDB,
  updateSingleStatusesCardsinDB,
} from "models/boards";

import { RootState } from "model/store";

import BoardIcon from "assets/BoardIcon.svg";
import editpen from "assets/ToDo/editpen.svg";
import trashcan from "assets/ToDo/trashcan.svg";

import { fetchForm, fetchFormResponses } from "../../../models/surveyBuilder";
import AutomateConfirmationModal from "./Modal/automateConfirmation";
import CreateBoardModal from "./Modal/createBoardModal";
import EditStatusModal from "./Modal/editStatusModal";
import ExpandTicket from "./Modal/expandTicket";
import ModalBoardArchive from "./Modal/modalBoardArchive";
import ModalBoardInfo from "./Modal/modalBoardInfo";
import TicketPropertyModal from "./Modal/ticketPropertiesModal";
import "./index.css";
import StatusTicket from "./statusTicket";
import Layout from "views/Productivity/Layout";

interface TangoToDoStatus {
  [x: string]: {
    name: string;
    items: {
      id: string;
      content: string;
    }[];
    bgcolor?: string;
  };
}

const itemsFromBackend = [
  {
    id: uuid(),
    content: "First task",
  },
];
const columnsFromBackend = {
  [uuid()]: {
    name: "To do",
    items: [],
  },
  [uuid()]: {
    name: "IN PROGRESS",
    items: itemsFromBackend,
  },
};

const onDragEnd = (result: any, taskColumns: any, setTaskColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = taskColumns[source.droppableId];
    const destColumn = taskColumns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setTaskColumns({
      ...taskColumns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      //we need to update tickets in two times first for source status tickets and second time will be destColumn tickets
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });

    //update db here

    let sourceColName = sourceColumn.name;
    let destiColName = destColumn.name;
    updateBothStatusesCardsinDB(
      sourceColName,
      sourceItems,
      destiColName,
      destItems
    );
  } else {
    const column = taskColumns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setTaskColumns({
      ...taskColumns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
    updateSingleStatusesCardsinDB(column.name, copiedItems);
  }
};

const Boards = () => {
  const boards: any =
    useSelector((state: RootState) => state.boards.boards) || [];
  const tickets: any =
    useSelector((state: RootState) => state.boards.tickets) || [];
  const [selectedBoard, setSelectedBoard]: any = useState({});

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const [toDoColumns, setToDoColumns] = useState<TangoToDoStatus>({});
  const [showAutoConfirmModal, setShowAutoConfirmModal] =
    useState<boolean>(false);
  const [showTicketPropertyModal, setShowTicketPropertyModal] =
    useState<boolean>(false);

  const [showBoardArchiveModal, setShowBoardArchiveModal] =
    useState<boolean>(false);

  const [showCreateBoardModal, setShowCreateBoardModal] =
    useState<boolean>(false);
  const [showTriggerToast, setShowTriggerToast] = useState<boolean>(false);

  const [showExpandTicketModal, setShowExpandTicketModal] =
    useState<boolean>(false);

  const [showEditStatusModal, setShowEditStatusModal] =
    useState<boolean>(false);

  const [showModalBoardInfoModal, setShowModalBoardInfoModal] =
    useState<boolean>(false);

  const [showModalBoardArchiveModal, setShowModalBoardArchiveModal] =
    useState<boolean>(false);

  const [selectedTicketData, setSelectedTicketData] = useState<any>({});

  const [selectedStatus, setSelectedStatus] = useState<any>({});

  const [dateSelect, setDateSelect] = useState(new Date());
  const saveDate = moment(dateSelect).format("MMM D");

  const [hoverItems, setHoverItems]: any = useState({});

  const [showArchieveBoards, setShowArchieveBoards] = useState<Boolean>(false);
  const [formData, setFormData] = useState<any | null>(null);
  const [formResponsesData, setFormResponsesData] = useState<any[]>([]);
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [boardProperties, setBoardProperties] = useState({
    Id: "",
    name: "",
    description: "",
    color: "",
    Icon: "",
    statuses: [],
  });

  //for board properties modal
  const [fixedProperties, setFixedProperties] = useState({
    Assignpeopletoticket: {
      value: false,
    },
    Ticketduedate: {
      value: false,
    },
    Ticketpriority: {
      value: false,
    },
    Attachfilestoticket: {
      value: false,
    },
  });

  //while creating board setting custom property in board collection
  const [customProperties, setCustomProperties] = useState({
    [uuid()]: {
      name: "Property 1",
      value: "Property Content",
      automationId: null,
      type: "textInput",
      enable: true,
    },
  });

  const colors = [
    "#333333",
    "#828282",
    "#E0E0E0",
    "#EB5757",
    "#F2994A",
    "#F2C94C",
    "#219653",
    "#27AE60",
  ];

  const colorstwo = [
    "#2D9CDB",
    "#56CCF2",
    "#5143EF",
    "#9B51E0",
    "#BB6BD9",
    "#6FCF97",
    "#43EF4A",
    "#2F80ED",
  ];

  const handleMouseOver = (objName: any) => {
    setHoverItems({
      ...hoverItems,
      [objName]: true,
    });
  };

  const handleMouseOut = (objName: any) => {
    setHoverItems({
      ...hoverItems,
      [objName]: false,
    });
  };

  const updateSelectedTicket = (tickets: any[]) => {
    if (Object.keys(selectedTicketData).length > 0) {
      let index = tickets.findIndex(
        (ticket: any) => ticket.id === selectedTicketData?.id
      );
      if (index != -1) {
        setSelectedTicketData(tickets[index]);
      }
    }
  };

  const findTicketsForStatus = (
    tickets: any,
    boardId: string,
    status: string
  ) => {
    return tickets?.filter((item: any) => {
      if (item.Status === status && item.boardId === selectedBoard.id) {
        return true;
      }
      return false;
    });
  };
  useEffect(() => {
    const formId = query.get("formId");
    if (formId) {
      const fetchData = async () => {
        const [r1, r2] = await Promise.all([
          checkIsFormLinkedToBoard(formId),
          fetchForm(formId),
        ]);
        if (r1.data) {
          if (r1.data.length === 0) {
            setShowCreateBoardModal(true);
          } else {
            clearStatus();
            setSelectedBoard(r1.data[0]);
          }
        }
        if (r2.data) {
          setFormData(r2.data);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (selectedBoard && selectedBoard.formId) {
      fetchFormResponses(selectedBoard.formId).then((res) => {
        if (res.data) {
          setFormResponsesData(res.data);
        }
      });
    }
  }, [selectedBoard]);

  useEffect(() => {
    if (boards) {
      if (
        (selectedBoard != null || selectedBoard !== undefined) &&
        Object.keys(selectedBoard).length > 0
      ) {
        let obj = {};
        obj = _.find(boards, function (x) {
          if (selectedBoard.id === x.id) return true;
        });
        setSelectedBoard(obj);
      }

      if (boards.length > 0) {
        let obj: any = {};
        boards?.map((item: any) => {
          obj[item.name] = false;
        });
        setHoverItems(obj);
      }
    }
  }, [boards]);

  useEffect(() => {
    if (selectedBoard) {
      let renderStatuses = () => {
        let obj: any = {};
        const filterDeletedStatuses = selectedBoard?.statuses?.filter(
          (item: any) => item.Deleted !== true
        );
        filterDeletedStatuses?.forEach((item: any) => {
          let boardTickets = findTicketsForStatus(
            tickets,
            selectedBoard.id,
            item.name
          );
          obj[uuid()] = {
            ...item,
            items:
              boardTickets?.sort(function (a: any, b: any) {
                return a.index - b.index;
              }) || [],
          };
        });
        return obj;
      };

      let statuses = renderStatuses;
      setToDoColumns(statuses);
    }

    if (tickets) updateSelectedTicket(tickets);
  }, [selectedBoard, tickets]);

  useEffect(() => {
    if (showTriggerToast) {
      setTimeout(() => {
        setShowTriggerToast(false);
      }, 2000);
    }
  }, [showTriggerToast]);

  const ArchievedBoards = useMemo(() => {
    if (boards && boards.length > 0) {
      return boards?.filter((board: any) => board.Enabled === false);
    }
    return [];
  }, [boards]);

  const [selectedColor, setSelectedColor] = useState("#333333");
  const [addStatus, setAddStatus] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");

  const addNewStatus = () => {
    if (newColumnName.length > 0) {
      setToDoColumns({
        ...toDoColumns,
        [uuid()]: {
          name: newColumnName,
          items: [],
          bgcolor: selectedColor,
        },
      });
    }
  };

  const validateStatusButton = () => {
    let name = newColumnName;
    let color = selectedColor;

    if (name.length > 2 && color.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const renderSidebar = () => {
    return (
      <Box className="sidebar">
        <div className="new-board-button-div">
          <Button
            minimal={true}
            className="board-button"
            onClick={() => setShowCreateBoardModal(true)}
          >
            New Board
          </Button>
        </div>
        <div className="todo-div">
          {boards &&
            boards.length > 0 &&
            boards
              .filter((board: any) => board.Enabled == true)
              .map((item: any) => {
                return (
                  <>
                    {/* <div className="no-board-tag">No Boards</div> */}
                    <div
                      className={`${item.id === selectedBoard?.id
                        ? "todo-div-elem-active"
                        : "todo-div-elem"
                        }`}
                      onClick={() => {
                        clearStatus();
                        setSelectedBoard(item);
                      }}
                      onMouseOver={() => handleMouseOver(item?.name)}
                      onMouseOut={() => handleMouseOut(item?.name)}
                    >
                      <div>
                        <Icon
                          icon="issue"
                          onClick={() => {
                            setSelectedBoard(item);
                            setShowModalBoardInfoModal(true);
                          }}
                          className="color-grey board-icon-info"
                          style={{
                            display:
                              hoverItems[item.name] == true ? "" : "none",
                          }}
                        />
                      </div>
                      {item?.Icon ? (
                        <img src={item?.Icon} height="20px" width="20px" />
                      ) : (
                        <img src={BoardIcon} height="20px" width="20px" />
                      )}
                      <span className="font-extrabold">{item?.name}</span>
                      <Icon
                        icon="import"
                        onClick={() => {
                          setSelectedBoard(item);
                          setShowModalBoardArchiveModal(true);
                        }}
                        className="color-grey"
                        style={{
                          display: hoverItems[item.name] == true ? "" : "none",
                        }}
                      />
                      {/* <div className="board-icons">
                   <Icon
                   icon="import"
                   onClick={() => setShowModalBoardArchiveModal(true)}
                   className="color-grey"
                   />
                   <Icon icon="more" className="color-grey" />
                   </div> */}
                    </div>
                  </>
                );
              })}

          {boards &&
            boards.length > 0 &&
            showArchieveBoards &&
            boards
              .filter((board: any) => board.Enabled == false)
              .map((item: any) => {
                return (
                  <>
                    {/* <div className="no-board-tag">No Boards</div> */}
                    <div
                      className={`${item.id === selectedBoard?.id
                        ? "todo-div-elem-active"
                        : "todo-div-elem"
                        }`}
                      onClick={() => {
                        clearStatus();
                        setSelectedBoard(item);
                      }}
                      onMouseOver={() => handleMouseOver(item?.name)}
                      onMouseOut={() => handleMouseOut(item?.name)}
                    >
                      <div>
                        <Icon
                          icon="issue"
                          onClick={() => {
                            setSelectedBoard(item);
                            setShowModalBoardInfoModal(true);
                          }}
                          className="color-grey board-icon-info"
                          style={{
                            display:
                              hoverItems[item.name] == true ? "" : "none",
                          }}
                        />
                      </div>
                      <img src={BoardIcon} height="20px" width="20px" />
                      <span className="font-extrabold">{item?.name}</span>

                      {/* <div className="board-icons">
                   <Icon
                   icon="import"
                   onClick={() => setShowModalBoardArchiveModal(true)}
                   className="color-grey"
                   />
                   <Icon icon="more" className="color-grey" />
                   </div> */}
                    </div>
                  </>
                );
              })}

          {/*Archieved Boards*/}

          {/* <div className="todo-div-elem">
           <img src={HirirngIcon} height="20px" width="20px" />
           <span className="font-extrabold">Hiring</span>
           </div>
           */}
        </div>

        {/* archive boards code */}
        {ArchievedBoards && ArchievedBoards.length > 0 && (
          <div className="archived-button">
            <label
              className="pointer"
              onClick={() => {
                setShowArchieveBoards(!showArchieveBoards);
              }}
            >
              <Icon icon="import" className="icon-archieve" />
              {showArchieveBoards == true
                ? "Hide Archived Boards"
                : "Show Archived Boards"}
            </label>
          </div>
        )}

        {/* <label>
           <Icon icon="import" className="icon-archieve" />
           Hide Archived Boards
           </label> */}
      </Box>
    );
  };

  const renderBoards = (data: any) => {
    return (
      <Box className="boards">
        <div className="context-wrapper">
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, toDoColumns, setToDoColumns)
            }
          >
            {Object.entries(toDoColumns).length > 0 &&
              Object.entries(toDoColumns).map(([columnId, column], index) => {
                return (
                  <div
                    className="column-wrap"
                    key={columnId}
                    draggable
                    onDragOver={(e) => e.preventDefault()}
                    onDragStart={(e) => {
                      e.dataTransfer.setData("string", index.toString());
                    }}
                    onDrop={async (e) => {
                      const clonedSelectedBoard = { ...selectedBoard };
                      if (clonedSelectedBoard) {
                        const existingStatuses = clonedSelectedBoard.statuses;
                        const sourceIndex = parseInt(
                          e.dataTransfer.getData("string")
                        );
                        let temp = existingStatuses[index];

                        existingStatuses[index] = existingStatuses[sourceIndex];
                        existingStatuses[sourceIndex] = temp;
                        setSelectedBoard({
                          ...clonedSelectedBoard,
                          statuses: existingStatuses,
                        });

                        AddBoardStatus(selectedBoard.id, existingStatuses, []);
                        e.dataTransfer.clearData("string");
                      }
                    }}
                  >
                    <Box
                      className="column-header"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div
                        className="column-header-border"
                        style={{
                          backgroundColor: column?.bgcolor
                            ? column?.bgcolor
                            : "#43EF4A",
                        }}
                      />
                      <div className="column-header-title">{column.name}</div>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        style={{
                          gap: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <Popover2
                          interactionKind="click"
                          // popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                          placement="bottom"
                          content={
                            <div>
                              <div className="card-option">
                                <label
                                  onClick={() => {
                                    setSelectedStatus(column);
                                    setShowEditStatusModal(true);
                                  }}
                                >
                                  <img src={editpen} className="icon-options" />
                                  Edit Status
                                </label>
                                {/*<label>
                               <img src={archive} className="icon-options" />
                               Archive Tickets
                               </label> */}
                                <label
                                  className="delete-option"
                                  onClick={() => {
                                    if (column.items.length <= 0) {
                                      deleteStatus(
                                        column?.name,
                                        selectedBoard?.statuses,
                                        selectedBoard?.id
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    src={trashcan}
                                    className="icon-options"
                                  />
                                  <div>Delete Status</div>
                                </label>
                              </div>
                            </div>
                          }
                          renderTarget={({ isOpen, ref, ...targetProps }) => (
                            <div {...targetProps} ref={ref}>
                              <Icon
                                icon="more"
                                size={19}
                                color="#7A7A90"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        />

                        <Icon
                          icon="plus"
                          size={19}
                          color="#7A7A90"
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            if (business && selectedBoard) {
                              await createTicket(
                                selectedBoard.id,
                                business.id,
                                business.accountId,
                                business?.parentId ? business?.parentId : null,
                                "",
                                "",
                                column.name,
                                selectedBoard.customProperties
                              );
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <div>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              className="dropbox"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: "transparent",
                              }}
                            >
                              {column?.items?.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className="todo-card"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <StatusTicket
                                            key={index}
                                            closeExpandTicketModal={() =>
                                              setShowExpandTicketModal(false)
                                            }
                                            selectedTicketData={
                                              selectedTicketData
                                            }
                                            selectedBoard={selectedBoard}
                                            data={item}
                                            setSelectedTicketData={
                                              setSelectedTicketData
                                            }
                                            formResponsesData={
                                              formResponsesData
                                            }
                                            formData={formData}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </DragDropContext>
          <div className="card-column">
            {!addStatus ? (
              <div
                className="new-status-btn"
                onClick={() => setAddStatus(true)}
              >
                <Icon icon="plus" size={20} color={"#C4C4C4"} />
                <span className="font-extrabold color-grey">NEW STATUS</span>
              </div>
            ) : (
              <div>
                {" "}
                <Box
                  className="add-status-column-header card-header-border"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className="column-header-border"
                    style={{
                      backgroundColor: selectedColor,
                    }}
                  ></div>
                  <div>
                    {" "}
                    <InputGroup
                      type="text"
                      placeholder="COLUMN NAME"
                      value={newColumnName}
                      onChange={(e) => setNewColumnName(e.target.value)}
                      dir="auto"
                    />
                  </div>
                </Box>
                <div className="add-status-popover">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{ gap: "0" }}
                  >
                    <div>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        style={{
                          gap: "8px",
                          width: "140px",
                          marginBottom: 10,
                        }}
                      >
                        {colors.map((item) => (
                          <div
                            className={`color-circle ${selectedColor === item ? `active-circle` : ""
                              }`}
                            style={{ backgroundColor: `${item}` }}
                            onClick={(event) => {
                              setSelectedColor(item);
                            }}
                          ></div>
                        ))}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        style={{
                          gap: "8px",
                          width: "140px",
                        }}
                      >
                        {colorstwo.map((item) => (
                          <div
                            className="color-circle"
                            style={{ backgroundColor: `${item}` }}
                            onClick={(event) => {
                              setSelectedColor(item);
                            }}
                          ></div>
                        ))}
                      </Box>
                    </div>
                    <Button
                      className={`${!validateStatusButton()
                        ? "status-save-btn"
                        : "disable-save-btn"
                        }`}
                      disabled={validateStatusButton()}
                      onClick={() => {
                        const newStatus = {
                          name: newColumnName,
                          bgcolor: selectedColor,
                          automationId: null,
                        };
                        AddBoardStatus(
                          selectedBoard.id,
                          selectedBoard.statuses,
                          newStatus
                        );
                        setAddStatus(false);
                        clearStatus();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </div>
              </div>
            )}
          </div>

          {/* archive ticket code */}
          <div className="archived-ticket-button">
            {/* <label>
             <Icon icon="import" className="icon-archieve" />
             Show Archived Tickets
             </label> */}
            {/* <label>
             <Icon icon="import" className="icon-archieve" />
             Hide Archived Tickets
             </label> */}
          </div>
        </div>
      </Box>
    );
  };

  const actiontriggereddiv = () => {
    return <div className="action-trigger-div">Board Archieved</div>;
  };

  const clearStatus = () => {
    setNewColumnName("");
    setSelectedColor("#333333");
    setAddStatus(false);
  };

  const onYesArchive = async () => {
    await archiveBoard(selectedBoard.id);
    setShowModalBoardArchiveModal(false);
    setShowTriggerToast(true);
  };
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="row"
        className="boards-container"
        flex={1}
        style={{ marginTop: "0px" }}
      >
        {showTriggerToast && actiontriggereddiv()}
        {renderSidebar()}
        {renderBoards(selectedBoard)}
        <AutomateConfirmationModal
          isOpen={showAutoConfirmModal}
          closeModal={() => setShowAutoConfirmModal(false)}
        />
        <TicketPropertyModal
          isOpen={showTicketPropertyModal}
          closeModal={() => setShowTicketPropertyModal(false)}
          fixedProperties={fixedProperties}
          setFixedProperties={setFixedProperties}
          customProperties={customProperties}
          setCustomProperties={setCustomProperties}
          boardProperties={boardProperties}
          formId={query.get("formId")}
          formData={formData}
        />
        <CreateBoardModal
          boardProperties={boardProperties}
          setBoardProperties={setBoardProperties}
          isOpen={showCreateBoardModal}
          closeModal={() => setShowCreateBoardModal(false)}
          setShowTicketPropertyModal={setShowTicketPropertyModal}
        />
        {/* <ExpandTicket
          isOpen={showExpandTicketModal}
          closeModal={() => setShowExpandTicketModal(false)}
          selectedTicketData={selectedTicketData}
          selectedBoard={selectedBoard}
        /> */}
        <ModalBoardInfo
          isOpen={showModalBoardInfoModal}
          closeModal={() => setShowModalBoardInfoModal(false)}
          selectedTicketData={selectedTicketData}
          selectedBoard={selectedBoard}
        />
        <ModalBoardArchive
          isOpen={showModalBoardArchiveModal}
          closeModal={() => setShowModalBoardArchiveModal(false)}
          selectedTicketData={selectedTicketData}
          selectedBoard={selectedBoard}
          onYesArchive={onYesArchive}
        />
        <EditStatusModal
          isOpen={showEditStatusModal}
          closeModal={() => setShowEditStatusModal(false)}
          selectedStatus={selectedStatus}
          selectedBoard={selectedBoard}
        />
      </Box>
    </Layout>
  );
};

export default Boards;
