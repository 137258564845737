import React, { useCallback } from "react";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage, setupRoutes } from "../routes";
import usePrimaryGroups from "./usePrimaryGroups";
import { useNavigate } from "react-router-dom";

const PrimaryGroups = () => {
  const {
    data,
    addItem,
    deleteIds,
    columns,
    instructions,
    isEditing,
    saveChanges,
    setEditing,
  } = usePrimaryGroups();
  const nav = useNavigate();
  const onRowClick = useCallback((id: string) => nav(
    `/inventory/primaryGroups/${id}/secondaryGroups`
  ), [nav]);

  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar routes={setupRoutes} />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title="Primary Groups"
            columns={columns}
            data={data}
            instructions={instructions}
            saveResults={saveChanges}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="New Primary Group"
            primaryActionHandler={addItem}
            deleteIds={deleteIds}
            onRowClick={onRowClick}
          />
        </div>
      </div>
    </div>
  );
};

export default PrimaryGroups;
