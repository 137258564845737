// @ts-ignore
// @ts-ignore
//TODO show question index while rending question
import {
  Button,
  IToasterProps,
  Icon,
  Toast,
  ToastProps,
  Toaster,
} from "@blueprintjs/core";
import FontPicker from "font-picker-react";
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Box from "../../components/Box";
import { Form } from "../ViewForms/useForms";
import Email from "./components/admin/Email";
import Legal from "./components/admin/Legal";
import PhoneInput from "./components/admin/Phone";
import UrlInput from "./components/admin/Url";
import YesNo from "./components/admin/YesNo";
import CorrectOrder from "./components/client/CorrectOrder";
import DigitalSignature from "./components/client/DigitalSignature";
import Dropdown from "./components/client/Dropdown";
import FillIntheBlank from "./components/client/FillInTheBlank";
import LabelPicture from "./components/client/LabelPicture";
import Matching from "./components/client/Matching";
import MultipleChoice from "./components/client/MultipleChoice";
import PictureChoice from "./components/client/PictureChoice";
import Rate from "./components/client/Ratings";
import ShortText from "./components/client/ShortText";
import UploadFile from "./components/client/UploadFile";
import "./form.css";
import useSurvey from "./hooks/useSurvey";
import { ComponentType, EndingSectionProps } from "./types";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

interface Props {
  isPreview: boolean;
  formData?: Form | undefined;
}
const RenderForm = ({ isPreview, formData }: Props) => {
  const { id = "", businessId = "" } =
    useParams<{ id: string; businessId: string }>();
  const toastRef = useRef<IToasterProps>(null);
  const [hideWelcomeScreen, setHideWelcomeScreen] = useState(false);
  const {
    surveyData,
    showUsernameInput,
    endingSection,
    theme,
    isAnonymous,
    currentStep,
    username,
    scrollDirection,
    setScrollDirection,
    setUsername,
    updateInputValues,
    updateMultipleChoice,
    handleOnKeyDown,
    updateCorrectOrderChoices,
    handleFileChange,
    updateMatchingChoices,
    updateFillInTheBlankAnswers,
    gotoNextStep,
    submitForm,
    setCurrentStep,
    setShowUsernameInput,
    showEndingSection,
    showLoading,
    welcomeScreen,
    resetForm,
  } = useSurvey(id, businessId, toastRef, isPreview, formData);

  const handleUserKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleOnKeyDown(event);
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [surveyData, currentStep]);

  const renderComponent = (type: ComponentType) => {
    switch (type) {
      case ComponentType.shortText:
      case ComponentType.longText:
        return (
          <ShortText
            color={theme.answerColor}
            onChange={(e) => updateInputValues(e.target.value)}
            value={surveyData[currentStep]?.value || ""}
          />
        );
      case ComponentType.phone:
        return (
          <PhoneInput
            color={theme.answerColor}
            phoneNumber={surveyData[currentStep]?.value || ""}
            onChange={(phoneNumber) => updateInputValues(phoneNumber)}
          />
        );
      case ComponentType.email:
        return (
          <Email
            onChange={(e) => updateInputValues(e.target.value)}
            color={theme.answerColor}
          />
        );
      case ComponentType.url:
        return (
          <UrlInput
            onChange={(e) => updateInputValues(e.target.value)}
            color={theme.answerColor}
          />
        );
      case ComponentType.multipleChoice:
        return (
          <MultipleChoice
            selectedChoices={surveyData[currentStep].selectedChoices}
            options={surveyData[currentStep]?.choices || []}
            onSelect={updateMultipleChoice}
            color={theme?.answerColor || "#000"}
          />
        );
      case ComponentType.pictureChoice:
        return (
          <PictureChoice
            color={theme?.answerColor || "#000"}
            selectedChoices={surveyData[currentStep].value}
            options={surveyData[currentStep]?.choices || []}
            onSelect={updateInputValues}
            images={surveyData[currentStep]?.images || []}
          />
        );
      case ComponentType.correctOrder:
        return (
          <CorrectOrder
            options={surveyData[currentStep]?.choices || []}
            updateChoice={updateCorrectOrderChoices}
            color={theme.answerColor}
          />
        );
      case ComponentType.yesNo:
        return (
          <YesNo
            color={theme?.answerColor || "#000"}
            updateChoice={(value) => updateInputValues(value)}
            value={surveyData[currentStep].value}
          />
        );
      case ComponentType.dropdown:
        return (
          <Dropdown
            color={theme.answerColor}
            options={surveyData[currentStep].choices}
            selectedVale={surveyData[currentStep].value}
            onChange={(option: string) => updateInputValues(option)}
          />
        );
      case ComponentType.ratings:
        return (
          <Rate
            color={theme.answerColor}
            rating={parseInt(surveyData[currentStep]?.value || "")}
            onRating={(rating) => updateInputValues(rating.toString())}
          />
        );
      case ComponentType.uploadFile:
        return (
          <UploadFile
            color={theme.answerColor}
            handleFileChange={handleFileChange}
            selectedValue={surveyData[currentStep]?.value || ""}
          />
        );
      case ComponentType.legal:
        return (
          <Legal
            updateChoice={(value) => updateInputValues(value)}
            value={surveyData[currentStep].value}
            color={theme.answerColor}
          />
        );
      case ComponentType.digitalSignature:
        return (
          <DigitalSignature
            color={theme.answerColor}
            onChange={(image: string) => {
              const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
              const random = ("" + Math.random()).substring(2, 8);
              const random_number = timestamp + random;
              handleFileChange(image, random_number);
            }}
          />
        );
      case ComponentType.labelPicture:
        return (
          <LabelPicture
            updateChoice={(value) => updateInputValues(value)}
            value={surveyData[currentStep].value || ""}
            color={theme.answerColor}
            images={surveyData[currentStep].images}
          />
        );
      case ComponentType.fillInBlank:
        return (
          <FillIntheBlank
            color={theme.answerColor}
            value={surveyData[currentStep].fillInBlankChoices}
            selectedAnswers={surveyData[currentStep].selectedChoices}
            updateAnswer={updateFillInTheBlankAnswers}
          />
        );
      case ComponentType.matching:
        return (
          <Matching
            color={theme.answerColor}
            value={surveyData[currentStep].matchingObj}
            updateChoice={updateMatchingChoices}
          />
        );
    }
  };

  const getButtonDisableState = () => {
    switch (surveyData[currentStep].type) {
      case ComponentType.email:
        return !emailRegex.test(surveyData[currentStep]?.value || "");
      case ComponentType.url:
        return !urlRegex.test(surveyData[currentStep]?.value || "");
      case ComponentType.multipleChoice:
        return (
          surveyData[currentStep].required &&
          surveyData[currentStep]?.selectedChoices.length === 0
        );
      case ComponentType.yesNo:
        return (
          surveyData[currentStep].required && !surveyData[currentStep]?.value
        );
      case ComponentType.dropdown:
        return (
          surveyData[currentStep].required && !surveyData[currentStep].value
        );
      case ComponentType.fillInBlank:
        return (
          surveyData[currentStep].required &&
          !surveyData[currentStep].selectedChoices.length
        );
      case ComponentType.correctOrder:
        return false;
      default:
        return (
          surveyData[currentStep].required && !surveyData[currentStep].value
        );
    }
  };
  const renderEndingSection = (section: EndingSectionProps | null) => {
    return (
      <div
        className="survey-form-body question-container"
        style={{ backgroundColor: theme?.backgroundColor }}
      >
        {section && section.image && section.imageLayout === "background" ? (
          <>
            <div
              className="form-bg-image"
              style={{
                backgroundImage: `url(${section.image})`,
              }}
            />
            <div
              className="form-bg-image bg-color"
              style={{
                backgroundColor: section.imageBrightness > 0 ? "#fff" : "#000",
                opacity:
                  section.imageBrightness > 0
                    ? section.imageBrightness / 100
                    : -section.imageBrightness / 100,
              }}
            />
          </>
        ) : null}
        <div className="center-component ending-section-component">
          <div className={`question-container ${section?.imageLayout}`}>
            {section &&
            section.image &&
            section.imageLayout !== "background" ? (
              <div className="thank-you-image">
                <img src={section.image} />
              </div>
            ) : null}
            <Box display="flex" flexDirection="column" flex={1}>
              <div
                className="question-title apply-font"
                style={{ color: theme?.questionColor }}
              >
                {section?.title}
              </div>
              {section?.subtitle ? (
                <input
                  style={{ color: theme?.questionColor }}
                  placeholder="Description on this is optional"
                  className="question-subtitle apply-font"
                  value={section?.subtitle}
                />
              ) : null}
            </Box>
          </div>
          {section && section.showButton ? (
            <Box display="flex" justifyContent="center">
              <Button
                onClick={() => {
                  if (section?.type === "welcomeScreen") {
                    setHideWelcomeScreen(true);
                  } else {
                    if (section?.buttonLink.length > 0) {
                      window.location.href = section?.buttonLink;
                    } else {
                      resetForm();
                    }
                  }
                }}
                text={section.buttonLabel}
                className="okay-button apply-font"
                style={{
                  backgroundColor: theme?.buttonColor,
                  color: theme?.buttonTextColor,
                }}
              />
            </Box>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div
      className="survey-form-container"
      style={{ backgroundColor: theme?.backgroundColor }}
    >
      {theme.backgroundImage ? (
        <>
          {" "}
          <div
            className="form-bg-image"
            style={{ backgroundImage: `url(${theme.backgroundImage})` }}
          />
          <div
            className="form-bg-image bg-color"
            style={{
              backgroundColor:
                theme.backgroundImageBrightness > 0 ? "#fff" : "#000",
              opacity:
                theme.backgroundImageBrightness > 0
                  ? theme.backgroundImageBrightness / 100
                  : -theme.backgroundImageBrightness / 100,
            }}
          />
        </>
      ) : null}
      {welcomeScreen && !hideWelcomeScreen ? (
        renderEndingSection(welcomeScreen)
      ) : showEndingSection ? (
        renderEndingSection(endingSection)
      ) : surveyData.length > 0 ? (
        <div
          className="survey-form-body question-container"
          style={{ backgroundColor: theme?.backgroundColor }}
        >
          <div style={{ position: "absolute", opacity: 0 }}>
            <FontPicker
              apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
              activeFontFamily={theme ? theme.systemFont : "Lato"}
            />
            {/*@ts-ignore*/}
            <Toaster ref={toastRef} />
          </div>

          <TransitionGroup style={{ zIndex: 100 }}>
            <CSSTransition
              classNames={`slide-${scrollDirection}`}
              timeout={500}
              key={`${currentStep}`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  zIndex: 100,
                  minHeight: "350px",
                  justifyContent: "center",
                }}
              >
                {!isAnonymous && showUsernameInput ? (
                  <>
                    <div
                      className="question-title apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      What is your name?
                    </div>
                    <ShortText
                      color={theme.answerColor}
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                    <Button
                      style={{
                        backgroundColor: theme?.buttonColor,
                        color: theme?.buttonTextColor,
                      }}
                      text="SUBMIT"
                      onClick={() => {
                        if (username.length > 0) {
                          setShowUsernameInput(false);
                        }
                      }}
                      className="okay-button apply-font"
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="question-title apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      <div className="question-number">{currentStep + 1}</div>
                      {surveyData[currentStep].title.replace(
                        "{{{name}}}",
                        isAnonymous ? "" : username
                      )}
                    </div>
                    <div
                      className="question-subtitle apply-font"
                      style={{ color: theme?.questionColor }}
                    >
                      {surveyData[currentStep].subtitle}
                    </div>
                    {renderComponent(surveyData[currentStep].type)}
                    {surveyData.length - 1 === currentStep ? (
                      <Button
                        style={{
                          backgroundColor: theme?.buttonColor,
                          color: theme?.buttonTextColor,
                        }}
                        loading={showLoading}
                        text="SUBMIT"
                        onClick={() => {
                          if (!isPreview) {
                            submitForm();
                          }
                        }}
                        className="okay-button apply-font"
                      />
                    ) : (
                      <Button
                        style={{
                          backgroundColor: theme?.buttonColor,
                          color: theme?.buttonTextColor,
                          opacity: getButtonDisableState() ? 0.8 : 1,
                        }}
                        text="OK"
                        disabled={getButtonDisableState()}
                        rightIcon={
                          <Icon icon="tick" color={theme.buttonTextColor} />
                        }
                        onClick={gotoNextStep}
                        className="okay-button apply-font"
                      />
                    )}
                  </>
                )}
              </div>
            </CSSTransition>
          </TransitionGroup>
          <div className="next-prev-buttons">
            <Button
              style={{
                backgroundColor: theme?.buttonColor,
                color: theme?.buttonTextColor,
              }}
              disabled={currentStep === 0}
              onClick={() => {
                setScrollDirection("down");
                setTimeout(() => {
                  setCurrentStep(currentStep - 1);
                }, 0);
              }}
              className="next-button"
              icon={<Icon icon="chevron-up" iconSize={30} color="#fff" />}
              minimal
            />
            <Button
              style={{
                backgroundColor: theme?.buttonColor,
                color: theme?.buttonTextColor,
              }}
              disabled={currentStep === surveyData.length - 1}
              onClick={() => {
                setScrollDirection("up");
                setTimeout(() => {
                  setCurrentStep(currentStep + 1);
                }, 0);
              }}
              className="prev-button"
              icon={<Icon icon="chevron-down" iconSize={30} color="#fff" />}
              minimal
            />
          </div>
          <div style={{ position: "absolute", opacity: 0 }}>
            {theme ? (
              <FontPicker
                apiKey="AIzaSyCAzTWQNgRzwkudY7lCPw8TjzfjPvBpSlk"
                activeFontFamily={theme.systemFont}
              />
            ) : null}

            {/*@ts-ignore*/}
            <Toaster ref={toastRef} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default RenderForm;
