import { MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

import Box from "components/Box";

import { RootState } from "model/store";

// import {TangoSettings} from '../types/TangoSettings';
import firebase from "../../../config/firebase";
// import "tailwindcss/tailwind.css"
import "./index.css";

const dummyUrl =
  "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/howToVideos%2Fapproving%20a%20trade%20.mov?alt=media&token=d34c991e-f7fd-4ef7-b610-52417d36d6ce";
const dummyThumbnailUrl =
  "https://firebasestorage.googleapis.com/v0/b/tango-2.appspot.com/o/howToVideos%2FCleanShot%202021-12-07%20at%2013.46.34%402x.png?alt=media&token=165ebefd-6a66-4fcc-9a92-0b01b2f15f82";

interface TangoTutorialVideoProps {
  url: string;
  title: string;
  thumbnail: string;
  onClick: () => void;
}

const db = firebase.firestore();

const HowToVideos = () => {
  const TangoSettings: any = useSelector(
    (state: RootState) => state.businessSettings
  );
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [videoGroups, setVideosData] = useState([]);
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const [allVideos, setAllVideos] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedModule, setSelectedModule] = useState<string>("all");

  const TangoTutorialVideo = (props: TangoTutorialVideoProps) => {
    const url = props.url;
    const title = props.title;
    const thumbnail = props.thumbnail;
    return (
      <div className="video-card" onClick={props.onClick}>
        {/* <img src={VideoImage} alt="" /> */}
        <img src={thumbnail} alt="" />
        <div className={"video-title"}>{title}</div>
      </div>
    );
  };

  useEffect(() => {
    db.collection("TangoSettings")
      .doc("OnboardingMaterials")
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          console.log("Videos data:", doc.data());
          //set featured video
          setAllVideos(doc.data().todoVideos);
          doc.data().todoVideos.forEach((video: any) => {
            if (video.featured) {
              setFeaturedVideo(video);
            }
          });

          //create array of videos based on
          const groups = doc
            .data()
            .todoVideos.reduce((acc: { [x: string]: any[] }, video: any) => {
              if (acc[video.moduleName]) {
                acc[video.moduleName].push(video);
              } else {
                acc[video.moduleName] = [video];
              }
              return acc;
            }, {});

          const res = {
            videoGroups: Object.entries(groups).map(([key, value]) => ({
              moduleName: key,
              videos: value,
            })),
          };

          //@ts-ignore
          setVideosData(res.videoGroups);
        }
      })
      .catch((error: any) => {
        console.log("Error getting document:", error);
      });
  }, []);
  return (
    <Box className="how-to-video-page">
      {videoGroups.length ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "29px",
            }}
          >
            <div className="search-container">
              {/*@ts-ignore*/}
              <Suggest
                query={query}
                popoverProps={{
                  fill: true,
                  portalClassName: "search-list-suggestion",
                }}
                inputProps={{
                  placeholder: "Search",
                }}
                resetOnClose
                onQueryChange={(term) => console.log(term)}
                fill={true}
                items={allVideos}
                //@ts-ignore
                onItemSelect={(video) => setVideoUrl(video.uri)}
                //@ts-ignore
                inputValueRenderer={(video) => video.title}
                itemPredicate={(query, video, _index, exactMatch) => {
                  //@ts-ignore
                  const normalizedTitle = video.title.toLowerCase();
                  const normalizedQuery = query.toLowerCase();

                  if (exactMatch) {
                    return normalizedTitle === normalizedQuery;
                  }
                  //@ts-ignore
                  return (
                    // @ts-ignore
                    `${video.title}. ${normalizedTitle}`.indexOf(
                      normalizedQuery
                    ) >= 0
                  );
                }}
                itemRenderer={(video, { handleClick }) => (
                  <MenuItem
                    //@ts-ignore
                    key={video.id}
                    //@ts-ignore
                    text={video.title}
                    onClick={handleClick}
                  />
                )}
              />
            </div>
            <div className="select-container">
              <div
                style={{
                  fontSize: "10px",
                  fontFamily: "SFProText-Bold",
                  color: "#8B8B8B",
                  position: "absolute",
                  left: "13px",
                  top: "6px",
                }}
              >
                Filter By Module
              </div>
              <select
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.target.value)}
              >
                <option value={"all"}>All</option>
                {videoGroups.map((group, index) => {
                  //@ts-ignore
                  return (
                    // @ts-ignore
                    <option value={group.moduleName} key={group.moduleName}>
                      {/* @ts-ignore */}
                      {group.moduleName.charAt(0).toUpperCase() +
                        // @ts-ignore
                        group.moduleName.slice(1)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {featuredVideo && selectedModule === "all" && (
            // @ts-ignore
            <div
              className="featured-video"
              // @ts-ignore
              onClick={() => setVideoUrl(featuredVideo.uri)}
            >
              {/* @ts-ignore */}
              <img src={featuredVideo.thumbnail} style={{ width: "50%" }} />
              <div style={{ marginLeft: "25px" }}>
                <div className="featured-video-title">
                  {/* @ts-ignore */}
                  {featuredVideo.title}
                </div>
                {/* @ts-ignore */}
                <div className="featured-video-description">
                  {/* @ts-ignore */}
                  {featuredVideo.description}
                </div>
              </div>
            </div>
          )}
          <div className="videos-section">
            {videoGroups.map((group, index) => {
              if (selectedModule !== "all") {
                //@ts-ignore
                if (selectedModule === group.moduleName) {
                  return (
                    //@ts-ignore
                    <div key={group.moduleName}>
                      {/*@ts-ignore*/}
                      <div className="section-title">
                        {/*@ts-ignore*/}
                        {group.moduleName.charAt(0).toUpperCase() +
                          // @ts-ignore
                          group.moduleName.slice(1)}
                      </div>
                      <div className="videos-lists">
                        {/* @ts-ignore */}
                        {group.videos.map((video, i) => {
                          return (
                            <TangoTutorialVideo
                              url={video.uri}
                              title={video.title}
                              thumbnail={video.thumbnail}
                              onClick={() => setVideoUrl(video.uri)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  //@ts-ignore
                  <div key={group.moduleName}>
                    {/*@ts-ignore*/}
                    <div className="section-title">
                      {/*@ts-ignore*/}
                      {group.moduleName.charAt(0).toUpperCase() +
                        // @ts-ignore
                        group.moduleName.slice(1)}
                    </div>
                    <div className="videos-lists">
                      {/* @ts-ignore */}
                      {group.videos.map((video, i) => {
                        return (
                          <TangoTutorialVideo
                            url={video.uri}
                            title={video.title}
                            thumbnail={video.thumbnail}
                            onClick={() => setVideoUrl(video.uri)}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>
          {/* click video shows modal*/}
          <Modal
            style={{
              content: {
                position: "fixed",
                width: "76.5%",
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                padding: 0,
                zIndex: 1000,
              },
              overlay: {
                backgroundColor: "transparent",
                zIndex: 1000,
              },
            }}
            shouldCloseOnOverlayClick
            isOpen={Boolean(videoUrl)}
            onRequestClose={() => setVideoUrl(undefined)}
          >
            <ReactPlayer
              style={{ border: "1px red" }}
              controls
              width="100%"
              height="100%"
              url={videoUrl}
            />
          </Modal>
        </div>
      ) : (
        <div>Loading Videos</div>
      )}
    </Box>
  );
};

export default HowToVideos;
