import { generateBearerToken } from '../controllers/init';
import axios from 'axios';
import { ReviewResponseTemplate, ReviewResponseTemplateUpdate, ReviewTrackersCreateResponse } from 'types/reputationManagement';

const nestJS_Base_URL = `https://us-central1-tango-2.cloudfunctions.net/nestApi/reviews`;
//`http://localhost:9001/tango-2/us-central1/nestApi/reviews`

export const getAllReviewResponseTemplates = async (accountId: string) => {
  try {
    const authorizationToken = await generateBearerToken();
    const allTemplates = await axios.get(`${nestJS_Base_URL}/reviewResponseTemplates/${accountId}`, {
      headers: {
        Authorization: authorizationToken ? authorizationToken : '',
      },
    });
    if (allTemplates.data) {
      return allTemplates.data;
    } else {
      return [];
    }
  } catch (error: any) {
    console.log('error get reviewResponseTemplates', error.response);
    return [];
  }
};

export const createReviewResponseTemplate = async (newTemplate: ReviewResponseTemplate) => {
  try {
    const authorizationToken = await generateBearerToken();
    const createSuccess = await axios.post(`${nestJS_Base_URL}/reviewResponseTemplate`, newTemplate, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    });
    if (createSuccess.data) {
      return createSuccess.data;
    } else {
      return null;
    }
  } catch (error: any) {
    console.log('error creating new template', error.response);
    return null;
  }
};

export const updateReviewResponseTemplate = async (newTemplate: ReviewResponseTemplateUpdate) => {
  try {
    const authorizationToken = await generateBearerToken();
    const updateSuccess = await axios.patch(`${nestJS_Base_URL}/reviewResponseTemplate`, newTemplate, {
      headers: {
        Authorization: `Bearer ${authorizationToken}`,
      },
    });
    if (updateSuccess.data) {
      return updateSuccess.data;
    } else {
      return null;
    }
  } catch (error: any) {
    console.log('error updating template', error.response);
    return null;
  }
};

export const createReviewTrackersResponse = async (replyData: ReviewTrackersCreateResponse) => {
  try {
    const authorizationToken = await generateBearerToken();
    const createSuccess = await axios.post(`${nestJS_Base_URL}/createReviewTrackersResponse`, replyData, {
      headers: {
        //TO DO - set up auth gaurd for this endpoint and pass in actual token
        Authorization: `Bearer ${authorizationToken}`,
      },
    });
    if (createSuccess.data.error) {
      console.log('create reply error', createSuccess);
      alert('There was a problem creating your reply. ' + createSuccess.data.error + '.');
      return false;
    } else {
      console.log('created reply', createSuccess.data);
      return createSuccess.data;
    }
  } catch (error: any) {
    console.log('create reply error', error.response);
    alert('There was a problem creating your reply.');
    return false;
  }
};
