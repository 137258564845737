import React from "react";

interface Props {
  color: string;
}
const DigitalSignature = ({ color }: Props) => {
  return (
    <div
      className="digital-signature-component"
      style={{ borderColor: color, color, backgroundColor: color + "0C" }}
    >
      <div className="apply-font">Sign Your name here</div>
    </div>
  );
};

export default DigitalSignature;
