import Header from "components/Header";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
// import './components/table.css';
import "./style.css";

interface Props {
  children: any;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const launchpad = useLaunchpad();
  return (
    <Box className="menu-builder-page">
      <Header
        routes={[
          {
            label: "Menus",
            link: "/menus",
          },
          {
            label: "Products",
            link: "/products",
          },
          {
            label: "Modifiers",
            link: "/modifiers"
          },
          {
            label: "Modifier Options",
            link: "/modifier-options"
          },
          {
            label: "Discounts",
            link: "/discounts"
          },
          {
            label: "Inventory",
            link: "/inventory/items/basic"
          },
        ]}
        selectedRoute={location.pathname}
        selectedPage={{
          label: "Food & Bev",
          value: "/products"
        }}
      />
      <div
        className="children pt-16"
        style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }}
      >
        {children}
      </div>
    </Box>
  );
};
export default Layout;
