import _ from "lodash";
import { createSelector } from "reselect";

import { RootState } from "model/store";

export const businessSettingsSelector = (state: RootState) =>
  state.businessSettings;

export const getShiftTypeById = (
  businessSettings: TangoBusinessSettings,
  shiftTypeId: string
) => {
  if (!businessSettings.shiftTypes?.length) return null;
  return businessSettings.shiftTypes.find(
    (shiftType) => shiftType.id === shiftTypeId
  );
};

export const shiftTypeSelector = (shiftTypeId: string) =>
  createSelector(
    businessSettingsSelector,
    (businessSettings: TangoBusinessSettings) => {
      if (!businessSettings.shiftTypes?.length) return null;
      return businessSettings.shiftTypes.find(
        (shiftType) => shiftType.id === shiftTypeId
      );
    }
  );

export const getPositionNameById = (
  businessSettings: TangoBusinessSettings,
  positionId: string
) => businessSettings?.jobFunctions?.[positionId]?.title ?? "-";
export const getPositionById = (
  businessSettings: TangoBusinessSettings,
  positionId: string
) => businessSettings?.jobFunctions?.[positionId];

export const getPositionIdByName = (
  businessSettings: TangoBusinessSettings,
  title: string
) =>
  _.keys(businessSettings?.jobFunctions).find(
    (key) => businessSettings?.jobFunctions?.[key]?.title === title
  );
