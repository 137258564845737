import axios from "axios";

import { MenuSummary } from "types/menus";

const saveStitchedProductsToFirebase = async (businessId: string) => {
  return axios
    .post(
      "https://us-central1-tango-2.cloudfunctions.net/createStitchedProducts",
      {
        businessId: businessId,
      }
    )
    .then((response) => {
      console.log("✅ saved stitched products to firestore", response.data);
      return true;
    })
    .catch((error) => {
      console.log("⛔", error);
      return false;
    });
};

const publishMenus = async (menuSummary: MenuSummary[], accountId: string) => {
  return axios
    .post("https://us-central1-tango-2.cloudfunctions.net/publishMenus", {
      menusToPublish: menuSummary,
      accountId: accountId,
    })
    .then((response) => {
      console.log("Success: ", response.data);
      return true;
    })
    .catch((error) => {
      console.log("ERR: ", error.message);
      return false;
    });
};

export { saveStitchedProductsToFirebase, publishMenus };
