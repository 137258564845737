import React from "react";

import Box from "components/Box";

import "../index.css";

type Employee = {
  id: string;
  firstName: string;
  lastName: string;
  url?: string;
  role: string;
};

type Props = {
  employee: Employee;
  onClickRoleCard?: (employee: Employee) => void;
};
const RoleCard = (props: Props) => {
  const { employee, onClickRoleCard } = props;
  const { firstName, lastName, url, role } = employee;
  return (
    <Box
      className="role-card"
      onClick={() => onClickRoleCard && onClickRoleCard(employee)}
    >
      <div className="profile-img-container">
        {url ? (
          <img className="profile-image" src={url} />
        ) : (
          <div className="user-avatar-name">
            {firstName.charAt(0)?.toUpperCase()}
            {lastName.charAt(0)?.toUpperCase()}
          </div>
        )}
      </div>
      <div className="profile-name">
        {firstName} {lastName}
      </div>
      <div className="profile-role">{role}</div>
    </Box>
  );
};

export default RoleCard;
