import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { performSoftDelete, saveData } from "models/discounts";

import { FirebaseMenuDoc } from "types/menus";

import { RootState } from "model/store";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import { DiscountDoc } from "../../../../types/discounts";
import { DocumentData } from "../../../../types/document";
import DiscountModal from "./DiscountModal";
import { FirebaseDiscountDoc } from "./discountTypes";
import { composeUsableDiscount, composeUsableMenus, composeUsableProducts, compouseUsableMenuCategories } from "controllers/composableTypes";
import { fetchCollectionByBusinessIds } from "controllers/accounts";

interface Props {
  business: TangoBusiness;
  discounts: FirebaseDiscountDoc[];
  products: FirebaseProductDoc[];
  menus: FirebaseMenuDoc[];
  menuCategories: FirebaseMenuCategoryDoc[];
}

const DiscountsTable = ({
  business,
  discounts,
  products,
  menus,
  menuCategories,
}: Props) => {
  const businesses: TangoBusiness[] = useSelector(
    (state: RootState) => state.businesses
  );
  const emulatorMode: boolean = useSelector(
    (state: RootState) => state.emulatorMode
  );
  const user: StaffMember = useSelector((state: RootState) => state.user);
  const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;

  const [discountsData, setDiscountsData] = useState<DiscountDoc[]>([]);
  const [softDeleteData, setSoftDeleteData] = useState<DiscountDoc[]>([]);
  const allOrderTypes: string[] = _.uniq(
    _.flattenDeep(
      business.tangoProducts.map((orderChannel: any) =>
        orderChannel.orderTypes.map((type: any) => type.name)
      )
    )
  );
  const productNames = products.map((product) => product.nameExternal);
  const productRef: { [T: string]: string } = products.reduce(
    (acc, product) => ({ ...acc, [product.id]: product.nameExternal }),
    {}
  );
  const productNameRef: { [T: string]: string } = products.reduce(
    (acc, product) => ({ ...acc, [product.nameExternal]: product.id }),
    {}
  );
  const menuNameRef: { [T: string]: string } = menus.reduce(
    (acc, menu) => ({ ...acc, [menu.nameExternal]: menu.id }),
    {}
  );
  const menuCategoriesNameRef: { [T: string]: string } = menuCategories.reduce(
    (acc, menuCategory) => ({ ...acc, [menuCategory.name]: menuCategory.id }),
    {}
  );

  const columnHeadingConversions = {
    nameExternal: "Discount Name",
    enabled: "Enabled",
    discountType: "Discount Type",
    schedule: "Schedule",
    discountRule: "Discount Rule",
  };
  const tableSchema = [
    { key: "nameExternal", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "discountType", type: "value", data: "text" },
    { key: "schedule", type: "value", data: "text" },
    { key: "discountRule", type: "value", data: "text" },
  ];
  const dataSchema = [
    { key: "id", type: "value", data: "text", default: null },
    { key: "nameExternal", type: "value", data: "text" },
    { key: "enabled", type: "value", data: "text" },
    { key: "discountType", type: "value", data: "text" },
    { key: "schedule", type: "value", data: "text" },
    { key: "discountRule", type: "value", data: "text" },

    // Coupon/Promo
    { key: "isOneTimeUse", type: "value", data: "text" },
    { key: "isSpecificPromoCode", type: "value", data: "text" },
    { key: "isPrivateDiscount", type: "value", data: "text" },
    { key: "promoCode", type: "value", data: "text" },
    { key: "discountTriggerType", type: "value", data: "text" },
    {
      key: "discountTriggerAllProductsNeedToBePurchased",
      type: "value",
      data: "text",
    },
    { key: "discountTriggerAmount", type: "value", data: "text" },
    { key: "discountTriggerItems", type: "arrayOfValue", data: "text" },
    { key: "rewardType", type: "value", data: "text" },
    { key: "rewardDiscountAmount", type: "value", data: "text" },
    { key: "rewardDiscountItems", type: "arrayOfValue", data: "text" },

    // Scheduled
    { key: "productsToDiscount", type: "arrayOfValue", data: "text" },
    { key: "scheduledDiscountType", type: "value", data: "text" },
    { key: "discountAmount", type: "value", data: "text" },

    // Custom
    { key: "customDiscountAmount", type: "string", data: "text" },
    { key: "isStaffDiscount", type: "string", data: "text" },
    { key: "isManagerDiscount", type: "string", data: "text" },

    // Exclusions
    { key: "orderTypesToExclude", type: "arrayOfValue", data: "text" },
    { key: "datesToExclude", type: "arrayOfValue", data: "text" },
    { key: "orderItemsToExclude", type: "arrayOfValue", data: "text" },
    { key: "productsToExclude", type: "arrayOfValue", data: "text" },
    { key: "menusToExclude", type: "arrayOfValue", data: "text" },
    { key: "menuCategoriesToExclude", type: "arrayOfValue", data: "text" },
  ];

  const formatSchedule = (hour: string | Date) => {
    if (typeof hour === "string") return hour;
    return moment(hour).format("MMM Do");
  };

  const [options, setOptions] = useState<OptionType[]>([
    // Table options
    {
      key: "enabled",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "schedule",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isDependantColumn: false,
      customText: {
        prefix: null,
        suffix: null,
        customFunction: formatSchedule.toString(),
      },
      dropdown: null,
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "discountRule",
      isEditable: false,
      isImage: false,
      isCustomText: false,
      isDropdown: false,
      isDependantColumn: false,
      customText: null,
      dropdown: null,
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "discountType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Scheduled", "Coupon/Promo", "Custom"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    // Modal options
    {
      key: "orderItemsToExclude",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "productsToExclude",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "productsToExclude",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },

    {
      key: "productsToDiscount",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "productsToExclude",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "discountTriggerItems",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "orderTypesToExclude",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: allOrderTypes.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "rewardDiscountItems",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: true,
        isIndependent: true,
        dependentColumn: null,
        options: productNames.map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "isSpecificPromoCode",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "isPrivateDiscount",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "isStaffDiscount",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "isManagerDiscount",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "isOneTimeUse",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Yes", "No"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "scheduledDiscountType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: ["Price Off", "Percent Off", "New Price"].map((type) => ({
          optionName: type,
          color: null,
        })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "discountTriggerAllProductsNeedToBePurchased",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: [
          "All chosen items must be purchased",
          "Any item must be purchased",
        ].map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "discountTriggerType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: [
          "Specific items are purchased",
          "Dollar spend is over a certain amount",
        ].map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
    {
      key: "rewardType",
      isEditable: true,
      isImage: false,
      isCustomText: false,
      isDropdown: true,
      isDependantColumn: false,
      customText: null,
      dropdown: {
        isMultiSelect: false,
        isIndependent: true,
        dependentColumn: null,
        options: [
          "Dollars off the total check",
          "Percent off the total check",
          "Dollar off viable items",
          "Percent off viable items",
          "New price for viable items",
          "Free Items",
        ].map((type) => ({ optionName: type, color: null })),
      },
      customRender: null,
      dependantColumn: null,
      isPrimaryId: false,
    },
  ]);

  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "nameExternal",
    errors: [],
  });

  const checkDuplicates = (data: DocumentData[]) => {
    // Use only the columns applicable
    const columnsToCheck = data.map((row, index) => ({
      id: row.id,
      nameExternal: row.nameExternal,
      index: index,
    }));

    // Filter for duplicates
    const discountDuplicateErrors = columnsToCheck.filter(
      (item, index) =>
        _.findIndex(
          columnsToCheck.map((row) => ({ nameExternal: row.nameExternal })),
          { nameExternal: item.nameExternal }
        ) !== index
    );

    // Find the duplicate without the productId
    const errors: DocumentData[] = [];
    for (let i = 0; i < discountDuplicateErrors.length; i++) {
      const allDuplicates = columnsToCheck.filter(
        (row) => row.nameExternal === discountDuplicateErrors[i].nameExternal
      );
      const existingRecords = allDuplicates.filter((row) => row.id);
      const newRecords = allDuplicates.filter(
        (row) => !row.id || row.id.length === 0
      );
      const recordToPick =
        existingRecords.length > 0 ? existingRecords[0] : newRecords[0];

      allDuplicates.forEach((record) => {
        if (record.index !== recordToPick.index) {
          errors.push({
            errorType: "duplicateRows",
            rowIndex: record.index,
            columnNames: [],
            isEdited: false,
          });
        }
      });
    }

    return errors;
  };

  const checkWrongDataType = (data: DocumentData[]) => {
    const errors = [
      // Wrong enabled
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter((row: any) => !(row.enabled === "Yes" || row.enabled === "No"))
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "enabled", isEdited: false }],
          isEdited: false,
        })),
      // Wrong discount type
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter(
          (row: any) =>
            !(
              row.discountType === "Scheduled" ||
              row.discountType === "Coupon/Promo" ||
              row.discountType === "Custom"
            )
        )
        .map((row) => ({
          errorType: "wrongDataType",
          rowIndex: row.index,
          columnNames: [{ columnName: "discountType", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const checkMissingCells = (data: DocumentData[]) => {
    const errors = [
      // No name external
      ...data
        .map((row, index) => ({ ...row, index: index }))
        .filter(
          (row: any) => !row.nameExternal || row.nameExternal.length === 0
        )
        .map((row) => ({
          errorType: "missingCells",
          rowIndex: row.index,
          columnNames: [{ columnName: "nameExternal", isEdited: false }],
          isEdited: false,
        })),
    ];
    return errors;
  };

  const verifyData = (showErrorModal = true) => {
    const duplicateErrors = checkDuplicates(discountsData);
    const dataErrors = checkWrongDataType(discountsData);
    const missingCells = checkMissingCells(discountsData);
    const errors = [...duplicateErrors, ...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal: showErrorModal,
        // @ts-ignore
        errors: errors,
      });
      return true;
      // All errors have been resolved
    } else {
      setErrorManager({
        ...errorManager,
        showErrorModal: false,
        errors: [],
      });
    }
    return false;
  };

  const saveToFirebase = async () => {
    if (isEnterpriseLevel) {
      await saveToFirebaseFromEnterprise();
      return true;
    } else {
      await performSoftDelete(softDeleteData);
      await saveData(
        discountsData,
        transformDataForGrid(discounts),
        business.id,
        productNameRef,
        menuNameRef,
        menuCategoriesNameRef
      );

      return true;
    }
  };

  const saveToFirebaseFromEnterprise = async () => {
    if (businesses.length > 0) {
      for await (const [index, business] of businesses.entries()) {
        const businessDiscounts = await fetchCollectionByBusinessIds(
          [business.id],
          "Discounts",
          composeUsableDiscount
        );

        const businessProducts = await fetchCollectionByBusinessIds(
          [business.id],
          "Products",
          composeUsableProducts
        );

        const businessMenus = await fetchCollectionByBusinessIds(
          [business.id],
          "Menus",
          composeUsableMenus
        );

        const businessMenuCartegories = await fetchCollectionByBusinessIds(
          [business.id],
          "MenuCategories",
          compouseUsableMenuCategories
        );

        const businessProductNameRef: { [T: string]: string } = businessProducts.reduce(
          (acc, product) => ({ ...acc, [product.nameExternal]: product.id }),
          {}
        );
        const businessMenuNameRef: { [T: string]: string } = businessMenus.reduce(
          (acc, menu) => ({ ...acc, [menu.nameExternal]: menu.id }),
          {}
        );
        const businessMenuCategoriesNameRef: { [T: string]: string } = businessMenuCartegories.reduce(
          (acc, menuCategory) => ({ ...acc, [menuCategory.name]: menuCategory.id }),
          {}
        );


        await performSoftDelete(softDeleteData);
        await saveData(
          discountsData,
          transformDataForGrid(businessDiscounts),
          business.id,
          businessProductNameRef,
          businessMenuNameRef,
          businessMenuCategoriesNameRef
        );

        if (index === businesses.length - 1) {
          return true;
        }
      }
    }
  };

  const discountRule = (row: any) => {
    if (row.isScheduled) {
      const items = products.filter((product: FirebaseProductDoc) =>
        row.scheduledDiscount?.productsToDiscount.includes(product.id)
      );
      const discountText =
        row.scheduledDiscount.discountType === "priceOff"
          ? `$${row.scheduledDiscount.discountAmount / 100} off`
          : row.scheduledDiscount.discountType === "percentOff"
            ? `${row.scheduledDiscount.discountAmount}% off on`
            : `$${row.scheduledDiscount.discountAmount} for`;
      const itemsNames = items.map((item: any) => item.nameExternal);
      return discountText + " " + itemsNames;
    }
    if (row.isRewardOrCoupon) {
      const discountData: any = row.rewardOrCouponDiscount;
      let preText = "";
      let postText = "";
      if (discountData.discountTriggerType === "minimumDollarSpent") {
        preText = `Spend $${discountData.discountTriggerAmount
          ? discountData.discountTriggerAmount / 100
          : 0
          } and get`;
      } else {
        const items = products.filter((product: any) =>
          row.rewardOrCouponDiscount?.discountTriggerItems?.includes(product.id)
        );
        const itemsNames = items.map((item: any) => item.nameExternal);
        preText = `Buy ${itemsNames} and get`;
      }
      if (row.rewardOrCouponDiscount.rewardType === "discountedAmount") {
        const discountText =
          discountData.rewardDiscountType === "priceOffEachViableItem"
            ? `$${discountData.rewardDiscountAmount / 100} off`
            : discountData.rewardDiscountType === "percentOffEachViableItem"
              ? `${discountData.rewardDiscountAmount}% off`
              : `$${discountData.rewardDiscountAmount / 100}`;
        const items = products.filter((product: any) =>
          row.rewardOrCouponDiscount?.rewardDiscountItems?.includes(product.id)
        );
        const itemsNames = items.map((item: any) => item.nameExternal);
        postText = discountText + " " + itemsNames;
      } else {
        const items = products.filter((product: any) =>
          row.rewardOrCouponDiscount?.rewardDiscountItems?.includes(product.id)
        );
        const itemsNames = items.map((item: any) => item.nameExternal);
        postText = itemsNames + " free";
      }

      return preText + " " + postText;
    }
  };

  const transformDataForGrid = (data: FirebaseDiscountDoc[]): DiscountDoc[] => {
    return data.map((row: FirebaseDiscountDoc) => {
      const schedule: [string, string] | [Date, Date] | null =
        row.hours && row.hours.length > 0
          ? row.hours[0].dateOrDay === "day"
            ? [row.hours[0].day as string, row.hours[0].day as string]
            : [row.hours[0].startDate as Date, row.hours[0].endDate as Date]
          : null;
      return {
        id: row.id,
        nameExternal: row.nameExternal,
        enabled: row.enabled ? "Yes" : "No",
        discountType:
          row.discountType === "Scheduled"
            ? "Scheduled"
            : row.discountType === "RewardOrCoupon"
              ? "Coupon/Promo"
              : "Custom",
        schedule: schedule, // allHours,
        discountRule: !row.isCustomDiscount ? discountRule(row) || "" : "",

        // Coupon/Promo
        isOneTimeUse: row.oneTimeUse ? "Yes" : "No",
        isSpecificPromoCode: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.promoCode
            ? "Yes"
            : "No"
          : "No",
        isPrivateDiscount: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.isPrivateDiscount
            ? "Yes"
            : "No"
          : "No",
        promoCode: row.rewardOrCouponDiscount
          ? String(row.rewardOrCouponDiscount.promoCode)
          : "",
        discountTriggerType: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.discountTriggerType ===
            "minimumDollarSpent"
            ? "Dollar spend is over a certain amount"
            : "Specific items are purchased"
          : null,
        discountTriggerAmount: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.discountTriggerAmount
            ? String(row.rewardOrCouponDiscount.discountTriggerAmount / 100)
            : ""
          : "",
        discountTriggerItems: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.discountTriggerItems
            ? row.rewardOrCouponDiscount.discountTriggerItems.map(
              (id: string) => productRef[id]
            )
            : []
          : [],
        rewardType: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.rewardType === "discountedAmount"
            ? "Dollars off the total check"
            : "Free Items"
          : null,
        rewardDiscountAmount: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.rewardDiscountAmount
            ? String(row.rewardOrCouponDiscount.rewardDiscountAmount / 100)
            : ""
          : "",
        rewardDiscountItems: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.rewardDiscountItems || []
          : [],
        discountTriggerAllProductsNeedToBePurchased: row.rewardOrCouponDiscount
          ? row.rewardOrCouponDiscount.discountTriggerType ===
            "specificItemsDiscounted"
            ? "All chosen items must be purchased"
            : row.rewardOrCouponDiscount.discountTriggerType ===
              "anyItemsDiscounted"
              ? "Any item must be purchased"
              : "All chosen items must be purchased"
          : "All chosen items must be purchased",

        // Scheduled
        productsToDiscount: row.scheduledDiscount
          ? (row?.scheduledDiscount?.productsToDiscount || []).map(
            (id: string) => productRef[id]
          )
          : [],
        scheduledDiscountType: row.scheduledDiscount
          ? row.scheduledDiscount.discountType === "priceOff"
            ? "Price Off"
            : row.scheduledDiscount.discountType === "percentOff"
              ? "Percent Off"
              : row.scheduledDiscount.discountType === "newPrice"
                ? "New Price"
                : "Percent Off"
          : "Percent Off",
        discountAmount:
          row.scheduledDiscount && row.scheduledDiscount.discountAmount
            ? String(row.scheduledDiscount.discountAmount / 100)
            : "0",

        // Custom discount
        customDiscountAmount:
          row.customDiscount && row.customDiscount.discountAmount
            ? String(row.customDiscount.discountAmount) || "0"
            : "0",
        isStaffDiscount:
          row.customDiscount && row.customDiscount.isStaffDiscount
            ? "Yes"
            : "No",
        isManagerDiscount:
          row.customDiscount && row.customDiscount.isManagerDiscount
            ? "Yes"
            : "No",

        orderTypesToExclude: row.orderTypesToExclude,
        datesToExclude: row.datesToExclude,
        orderItemsToExclude: row.rewardOrCouponDiscount
          ? (row.rewardOrCouponDiscount.orderItemsToExclude || []).map(
            (id: string) => productRef[id]
          )
          : [],
        productsToExclude: row.productsToExclude || [],
        menusToExclude: row.menusToExclude || [],
        menuCategoriesToExclude: row.menuCategoriesToExclude || [],
      };
    });
  };

  const filterData = (data: any) => {
    return data.map((row: any) => {
      return {
        nameExternal: row.nameExternal,
        enabled: row.enabled,
        discountType: row.discountType,
        schedule: row.schedule,
        discountRule: row.discountRule,
      };
    });
  };

  useEffect(() => {
    if (discountsData.length === 0) {
      setDiscountsData(transformDataForGrid(discounts));
    }
  }, []);

  return (
    <Table
      businessId={business.id}
      name={"Discounts"}
      parentName={"AdminPortal"}
      allData={discountsData}
      setAllData={setDiscountsData}
      softDelete={softDeleteData}
      setSoftDelete={setSoftDeleteData}
      allowSoftDelete
      filterData={filterData}
      tableSchema={tableSchema}
      dataSchema={dataSchema}
      options={options}
      setOptions={setOptions}
      hasModal={true}
      ModalComponent={DiscountModal}
      hasSearch={true}
      modalColumTitle="Edit Details"
      searchColumnName={"nameExternal"}
      hasFilter={true}
      setErrorManager={setErrorManager}
      errorManager={errorManager}
      saveToFirebase={saveToFirebase}
      verifySave={verifyData}
      closeErrorManager={() => {
        setErrorManager({
          ...errorManager,
          showErrorModal: false,
        });
      }}
      columnHeadingConversions={columnHeadingConversions}
      searchPlaceHolderText={"Find a discount"}
    />
  );
};

export default DiscountsTable;
