import { useState } from "react";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { TabWithStaff, TangoTab, useCompletedTabs } from "controllers/payments";
import { RootState } from "model/store";
import { useSelector } from "react-redux";
import { calculateCompletedCheckTotal, getCompletedCheckType } from "../utils";
import moment from "moment";


export type TabDocument = {
  id: string;
  businessId: string;
  name: string | null;
  isExplicitTab: boolean;
  deleted: boolean;
  staffId: string;
  createdAt: Date;
  updatedAt: Date;
  tableId: string;
  tableNumber: number | null;
  sessionId: string;
  numberOfGuests: number;
  customer: CustomerModel[];
  status: OrderStatus;
  paymentComplete: boolean;
  refundPayload: any | null;
  allSplitTypes: string[] | null;
  serviceAreaId: string;
  extraInformation?: {
    name: string;
    phoneNumber: string;
    email: string;
  } | null;
  tabNumber: string;
  voidedProducts: ProductDocument[];
  tableAlias: string | null;
}

type CustomerAmount = {
  netTotal: number;
  refundPayload: any;
  refundedAmount: number;
  tip: number;
  tax: number;
  deliveryFee: number;
}

export type CustomerModel = {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  userId: string;
  payment: OrderPayment;
  splitType: string | null;
  orderChannel: string;
  orderType: string;
  productsPaid: ProductsPaid[];
  isDineInOrder: boolean;
  amount: CustomerAmount;
}
type OrderStatus = {
  open: boolean;
  data: string;
  error: number;
  refunded: boolean;
  refundedAmount: number;
  deleted: boolean;
}

type OrderPayment = {
  type: string;
  successfulCharge: SuccessfulCharge;
  paymentType: CashPaymentType | StripePaymentType | any;
}

type ProductDocument = {
  alcohol: boolean;
  allergens: string[];
  businessId: string;
  createdAt: Date;
  deleted: boolean;
  description: string;
  enabled: boolean;
  id: string;
  imageUrl: string | null;
  ingredients: string[];
  ingredientsQuantity: string[];
  modifiers: string[];
  nameExternal: string;
  nameInternal: string;
  popularity: number;
  preparationInstructions: string | null;
  price: number;
  suggestedPairings: string[];
  autoSuggestedPairings: string[];
  tags: string[];
  taxRate: number | null;
  type: string;
  subType: string[];
  updatedAt: Date;
  plu: string | null;
}

type CashPaymentType = {
  staffId: string;
  amount: number;
  drawerId: string;
  type: string;
}

type SuccessfulCharge = {
  id: string | null;
  amount: number;
  timestampCharged: Date | null;
  paymentVendor: "stripe" | "fattmerchant" | "cash" | "external" | "gift-card" | null;
  applicationFeeAmount: number;
}

type StripePaymentType = {
  customerTokenId: string;
  acct_stripeConnectId: string;
  chargeId: string;
  merchantTokenId: string;
  type: string;
}

type ProductsPaid = {
  productId: string;
  productName: string;
  productCategory: string;
  menuCategory: string;
  menuName: string;
  quantity: number;
  price: number;
  taxRate: number | null;
  productOrderId: string;
}

export type CompletedCheckStaff = {
  id: string;
  contact: {
    firstName: string;
    lastName: string;
  }
}
export type ClosedChecksProps = {
  uniqueId: string;
  checkNumber: string | number;
  date: any;
  timeClosed: any;
  totalAmount: number | string;
  payments: number;
  completedCheck: TangoTab;
  serverName: string;
  type: string;
  staffMember: CompletedCheckStaff | null;
  // description: string;
};

const columns: ColumnInstruction<ClosedChecksProps>[] = [
  { type: "projection", header: "Date", attribute: "date" },
  { type: "projection", header: "Time Closed", attribute: "timeClosed" },
  { type: "data", header: "Total Amount", attribute: "totalAmount" },
  { type: "data", header: "Server Name", attribute: "serverName" },
  { type: "data", header: "Type", attribute: "type" },
  { type: "data", header: "Payments", attribute: "payments" },
  { type: "data", header: "Check Number", attribute: "checkNumber" },
  // { type: "data", header: "Description", attribute: "description" },
];

const instructions: { [x: string]: RenderInstruction<ClosedChecksProps> } = {};

instructions.date = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    return <div>
      {moment(new Date(fullObject.date._seconds * 1000)).format('MM/DD/YYYY')}
    </div>
  },
  editComponent: null
}

instructions.timeClosed = {
  type: "complex-custom",
  viewComponent: ({ fullObject }) => {
    return <div>
      {moment(new Date(fullObject.date._seconds * 1000)).format('h:mm A')}
    </div>
  },
  editComponent: null
}


const useClosedChecks = () => {
  const noOp = () => Promise.resolve(true);
  const logIt = (args: string[]) => console.log(args);
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const currency: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.currency;
  const [isEditing, setEditing] = useState(false);
  const [lastTabId, setLastTabId] = useState('start');
  const query = useCompletedTabs(businessId, lastTabId);
  const completedChecks = query.data ?? []; //query will return null if no business id has propogated 

  const data: ClosedChecksProps[] = completedChecks.map((completedCheck: TabWithStaff) => {
    const item: ClosedChecksProps = {
      uniqueId: completedCheck.tab.id,
      checkNumber: completedCheck.tab.tabNumber,
      date: completedCheck.tab.createdAt,
      timeClosed: completedCheck.tab.updatedAt,
      totalAmount: '$' + (calculateCompletedCheckTotal(completedCheck.tab.customer) * 0.01).toFixed(2) + ' ' + currency.toUpperCase(),
      payments: completedCheck.tab.customer.length,
      completedCheck: completedCheck.tab,
      serverName: completedCheck.staffMember && completedCheck.staffMember.contact && completedCheck.staffMember.contact.firstName ? completedCheck.staffMember.contact.firstName : '',
      type: getCompletedCheckType(completedCheck.tab),
      staffMember: completedCheck.staffMember
    }
    return item
  })

  return {
    data,
    noOp,
    logIt,
    columns,
    instructions,
    isEditing,
    setEditing,
    lastTabId,
    setLastTabId
  };
};

export default useClosedChecks;
