import { useEffect, useState } from "react";

import Table from "../../../../components/Table/Table";
import {
  OptionType,
  TableError,
} from "../../../../components/Table/tableTypes";
import { saveData } from "../../../../models/menuHours";
import { DocumentData } from "../../../../types/document";
import { FirebaseMenuHoursDoc, MenuHoursDoc } from "../../../../types/menus";

interface MenuHoursTableProps {
  businessId: string;
  currentMenu: DocumentData;
}

const MenuHoursTable = ({ businessId, currentMenu }: MenuHoursTableProps) => {
  const [data, setData] = useState<MenuHoursDoc[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(false);
  const [errorManager, setErrorManager] = useState<TableError>({
    showErrorModal: false,
    errorColumn: "hourType",
    errors: [],
  });

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const tableSchema = [
    { key: "hourType", type: "value", data: "text" },
    { key: "monday", type: "value", data: "text" },
    { key: "tuesday", type: "value", data: "text" },
    { key: "wednesday", type: "value", data: "text" },
    { key: "thursday", type: "value", data: "text" },
    { key: "friday", type: "value", data: "text" },
    { key: "saturday", type: "value", data: "text" },
    { key: "sunday", type: "value", data: "text" },
  ];

  const dataSchema = [
    { key: "hourType", type: "value", data: "text" },
    { key: "monday", type: "value", data: "text" },
    { key: "tuesday", type: "value", data: "text" },
    { key: "wednesday", type: "value", data: "text" },
    { key: "thursday", type: "value", data: "text" },
    { key: "friday", type: "value", data: "text" },
    { key: "saturday", type: "value", data: "text" },
    { key: "sunday", type: "value", data: "text" },
  ];

  const columnHeadingConversions = {
    hourType: "Hour Type",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  };

  const convertCurrentMenuHoursToData = (
    hours: FirebaseMenuHoursDoc[]
  ): MenuHoursDoc[] => {
    const data = [];
    const newData = {
      hourType: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
    };
    for (let i = 0; i < hours.length; i++) {
      let newEntry = true;
      for (let j = 0; j < data.length; j++) {
        if (data[j].hourType === "Open Time") {
          // @ts-ignore
          if (data[j][hours[i].day] === "") {
            newEntry = false;
            // @ts-ignore
            data[j][hours[i].day] = hours[i].openTime;
          }
        } else if (data[j].hourType === "Close Time") {
          // @ts-ignore
          if (data[j][hours[i].day] === "") {
            newEntry = false;
            // @ts-ignore
            data[j][hours[i].day] = hours[i].closeTime;
          }
        }
      }
      if (newEntry) {
        data.push({
          ...newData,
          hourType: "Open Time",
          [hours[i].day]: hours[i].openTime,
        });
        data.push({
          ...newData,
          hourType: "Close Time",
          [hours[i].day]: hours[i].closeTime,
        });
      }
    }
    return data;
  };

  useEffect(() => {
    if (currentMenu) {
      const currentMenuHours = convertCurrentMenuHoursToData(currentMenu.hours);
      setData([...currentMenuHours]);
      setLoading(false);
      setSaved(false);
    }
  }, [currentMenu, loading, saved]);

  const filterData = (data: MenuHoursDoc[]) => {
    return data;
  };

  const isTime = (time: string) => {
    const hoursString = time.split(":")[0];
    const minutesString = time.split(":")[1];

    if (!/^\d+$/.test(hoursString)) return false;
    if (!/^\d+$/.test(minutesString)) return false;

    const hours = parseInt(hoursString);
    const minutes = parseInt(minutesString);

    if (isNaN(minutes)) return false;
    if (minutes < 0 || minutes > 59) return false;

    if (isNaN(hours)) return false;
    if (hours < 0 || hours > 24) return false;

    return true;
  };

  const checkWrongDataType = (data: MenuHoursDoc[]) => {
    const errors = [];
    for (let i = 0; i < data.length; i++) {
      // Don't show 2 errors for missing cells and wrong data type
      if (data[i].hourType && data[i].hourType === "") {
        if (
          !(
            data[i].hourType === "Open Time" ||
            data[i].hourType === "Close Time"
          )
        ) {
          errors.push({
            errorType: "wrongDataType",
            rowIndex: i,
            columnNames: [{ columnName: "hourType", isEdited: false }],
            isEdited: false,
          });
        }
      }

      for (let j = 0; j < days.length; j++) {
        // @ts-ignore
        if (data[i][days[j]] && data[i][days[j]] !== "") {
          // @ts-ignore
          if (!isTime(data[i][days[j]])) {
            errors.push({
              errorType: "wrongDataType",
              rowIndex: i,
              columnNames: [{ columnName: days[j], isEdited: false }],
              isEdited: false,
            });
          }
        }
      }
    }
    return errors;
  };

  const checkMissingCells = (data: MenuHoursDoc[]) => {
    return [];
  };

  const verifyData = (showErrorModal = true) => {
    const dataErrors = checkWrongDataType(data);
    const missingCells = checkMissingCells(data);
    const errors = [...dataErrors, ...missingCells];

    // There are errors
    if (errors.length > 0) {
      setErrorManager({
        ...errorManager,
        showErrorModal: showErrorModal,
        // @ts-ignore
        errors: errors,
      });
      return true;
      // All errors have been resolved
    } else {
      setErrorManager({
        ...errorManager,
        showErrorModal: false,
        errors: [],
      });
    }
    return false;
  };

  const saveToFirebase = async () => {
    const dataOpeningHourType = data.filter(
      (hours) => hours.hourType === "Open Time"
    );
    const dataClosingHourType = data.filter(
      (hours) => hours.hourType === "Close Time"
    );

    if (dataOpeningHourType.length === dataClosingHourType.length) {
      const id = currentMenu.id;
      await saveData(data, id);
      setSaved(true);

      console.log("TEST: Saved successfully to firebase!");
      return true;
    }
  };

  return (
    <div className="menu-hours-table">
      <Table
        businessId={businessId}
        name={"Menu Hours"}
        parentName={"MenuBuilder"}
        allData={data}
        setAllData={setData}
        filterData={filterData}
        tableSchema={tableSchema}
        dataSchema={dataSchema}
        options={options}
        setOptions={setOptions}
        hasModal={false}
        ModalComponent={null}
        errorManager={errorManager}
        setErrorManager={setErrorManager}
        closeErrorManager={() => {
          setErrorManager({
            ...errorManager,
            showErrorModal: false,
          });
        }}
        verifySave={verifyData}
        saveToFirebase={saveToFirebase}
        hasSearch={false}
        searchColumnName={""}
        hasFilter={false}
        columnHeadingConversions={columnHeadingConversions}
      />
    </div>
  );
};

export default MenuHoursTable;
