import { ProductsPaid, TangoTab } from 'controllers/payments';
import React, { useCallback, useState } from "react";
import { calculateCompletedCheckTax, calculateCompletedCheckTip, calculateCompletedCheckTotal, calculateItemTotalWithModifiers, calculateSubtotalInOrder, calculateRefundsInTab, calculateEachValue, getCompletedCheckType } from './utils';
import UIComponents from '@tangopay/tango-ui-library';
const { Button, Icon } = UIComponents;

import { useNavigate } from "react-router-dom";
import { ProductDoc } from 'types/products';
import DiscountedItemsModal from './DiscountedPayments/DiscountedItemsModal';
import { CustomerModel, CompletedCheckStaff } from './ClosedChecks/useClosedChecks';

interface OrderRecieptProps {
    closedCheck: TangoTab;
    staffMember: CompletedCheckStaff | null;
}

const OrderReciept = ({ closedCheck, staffMember }: OrderRecieptProps) => {

    const [showDisountedItems, setShowDiscountedItems] = useState(false)
    const orderType = getCompletedCheckType(closedCheck)
    const tableNumber = closedCheck.tableNumber ? '(Table ' + JSON.stringify(closedCheck.tableNumber) + ')' : ''

    const itemsInCheck: any[] = []
    let discountedItemsInCheck: any[] = []

    closedCheck.customer.forEach((customer: CustomerModel) => {
        return customer.productsPaid.forEach((product: any) => {
            if (product.discountedPrice !== null) {
                product.discountsAppliedToProduct.forEach((discount: any) => {
                    discountedItemsInCheck.push({
                        product,
                        discountApplied: discount,
                        customer: customer
                    })
                })
            }
            itemsInCheck.push(product)
        })
    })

    closedCheck.voidedProducts && closedCheck.voidedProducts.length > 0 && closedCheck.voidedProducts.forEach((product: any) => {
        discountedItemsInCheck.push({
            product,
            discountApplied: {
                discountId: product.productOrderId,
                discountName: "VOID",
                discountTotal: product.price
            },
        })
    })

    const navigate = useNavigate();

    return (
        <div>
            <div className="shadow-card mr-16 rounded-2xl">
                <div className="py-8 px-6">
                    <div className="text-xs font-lato-bold flex items-center mb-2">
                        <div className="text-grey-2">
                            Check Number:
                        </div>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate(`/payments/closedchecks/${closedCheck.id}`, { state: closedCheck })
                            }}
                            className="text-black ml-2">
                            {closedCheck.tabNumber}
                        </div>
                    </div>
                    <div className="text-xs font-lato-bold flex items-center mb-2">
                        <div className="text-grey-2">Order Type:</div>
                        <div className="text-black ml-2">{orderType} {tableNumber}</div>
                    </div>
                    <div className="text-xs font-lato-bold flex items-center mb-2">
                        <div className="text-grey-2">Server:</div>
                        <div className="text-black ml-2">{staffMember ? staffMember.contact.firstName + ' ' + staffMember.contact.lastName : ''}</div>
                    </div>
                    <div className="text-xs font-lato-bold flex items-center">
                        <div className="text-grey-2">Payment:</div>
                        {closedCheck.allSplitTypes &&
                            <div className="text-black ml-2">{closedCheck.allSplitTypes[0]}</div>
                        }
                    </div>

                </div>
                <ul className="p-0 m-0 list-none flex px-6 py-2 border-t border-b border-solid border-l-0 border-r-0">
                    <li className="w-3/6 font-lato-black text-black text-xs">Item</li>
                    <li className="w-1/6 font-lato-black text-black text-xs text-center">QTY</li>
                    <li className="w-1/6 font-lato-black text-black text-xs text-center">Each</li>
                    <li className="w-1/6 font-lato-black text-black text-xs text-center">Total</li>
                </ul>


                <ul className="px-6 py-4 m-0 list-none">

                    {itemsInCheck.map((item: ProductsPaid, itemIndex: number) => {
                        return (
                            <li key={itemIndex} className="text-tiny font-lato-regular mb-4">
                                <ul className="p-0 m-0 list-none flex">
                                    <li className="w-3/6">
                                        <div className="text-black">{item.name}</div>
                                        {item.selectedModifiers.map((selectedModifiers: any) => {
                                            return (
                                                selectedModifiers.optionNames.map((name: string, index: number) => {
                                                    return (
                                                        <div key={index} className="text-grey-3 pl-4">{name}</div>
                                                    )
                                                })
                                            )
                                        })}

                                    </li>
                                    <li className="w-1/6 text-black text-center">
                                        {item.quantity}
                                    </li>

                                    {item.selectedModifiers && item.selectedModifiers.length > 0 ?
                                        <li className="w-1/6 text-black text-center">
                                            ${(calculateEachValue(item) * 0.01).toFixed(2)}
                                        </li>
                                        :
                                        <li className="w-1/6 text-black text-center">
                                            ${(item.price * 0.01).toFixed(2)}
                                        </li>
                                    }


                                    <li className="w-1/6 text-black text-center">
                                        ${(calculateItemTotalWithModifiers(item) * 0.01).toFixed(2)}
                                    </li>
                                </ul>
                            </li>

                        )
                    })}
                </ul>


                <div className="px-6 py-2 border-t border-b border-solid border-l-0 border-r-0">
                    <div className="text-black text-tiny font-lato-black flex justify-between mb-2">
                        <div>Subtotal</div>
                        <div>${(calculateSubtotalInOrder(itemsInCheck) * 0.01).toFixed(2)}</div>
                    </div>
                    <div className="text-black text-tiny font-lato-regular flex justify-between mb-2">
                        <div>Tax</div>
                        <div>${(calculateCompletedCheckTax(closedCheck.customer) * 0.01).toFixed(2)}</div>
                    </div>
                    <div className="text-black text-tiny font-lato-regular flex justify-between">
                        <div>Tip</div>
                        <div>${(calculateCompletedCheckTip(closedCheck.customer) * 0.01).toFixed(2)}</div>
                    </div>
                </div>
                <div className="py-8 px-6 flex justify-between">
                    <div className="text-xl font-lato-black text-black">
                        Total
                    </div>
                    <div className="text-xl font-lato-black text-black">
                        ${(calculateCompletedCheckTotal(closedCheck.customer) * 0.01).toFixed(2)}
                    </div>
                </div>
            </div>

            <div className="py-4">
                {discountedItemsInCheck.length > 0 &&
                    <Button
                        disabled={false}
                        label='View Discounts'
                        size="btn-medium"
                        type="btn-style-1"
                        className="ml-5"
                        onClick={() => {
                            setShowDiscountedItems(true)
                            console.log('open modal')
                        }}
                    />
                }
            </div>

            {showDisountedItems &&
                <DiscountedItemsModal
                    isOpen={showDisountedItems}
                    closeModal={() => setShowDiscountedItems(false)}
                    discountedItemsInCheck={discountedItemsInCheck}
                    closedCheck={closedCheck}
                    staffMember={staffMember}
                />
            }

        </div>
    )
}
export default OrderReciept;