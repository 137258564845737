import * as React from "react";

export const Drafts = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      d="M13.6667 0.125H2.20833C1.0625 0.125 0.125 1.0625 0.125 2.20833V16.7917C0.125 17.9375 1.0625 18.875 2.20833 18.875H16.7917C17.9375 18.875 18.875 17.9375 18.875 16.7917V5.33333L13.6667 0.125ZM16.7917 16.7917H2.20833V2.20833H12.625V6.375H16.7917V16.7917ZM4.29167 14.7083H14.7083V12.625H4.29167V14.7083ZM9.5 4.29167H4.29167V6.375H9.5V4.29167ZM4.29167 10.5417H14.7083V8.45833H4.29167V10.5417Z"
      fill={color}
    />
  </svg>
);
