/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Dialog,
  Icon,
  Intent,
  Position,
  TextArea,
  Toaster,
} from "@blueprintjs/core";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  createDraftScheduleFromDuplicateWithEditShiftPayload,
  deleteShift,
  deleteShiftInDuplicate,
  editScheduleShift,
} from "controllers/schedule";

import Box from "components/Box";

import { getPositionNameById } from "model/selectors/businessSettings";
import { RootState } from "model/store";

import DeleteShiftImage from "assets/trash_icon.svg";

import AvailableStaffModal from "../AvailableStaffModal";
import "./style.css";

interface Props {
  shift: TangoShift | null;
  schedule: TangoSchedule | null;
  closeModal: () => void;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
}

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

const EditSingleShiftModal = ({
  closeModal,
  shift,
  schedule,
  mergedScheduleForAWeekRange,
}: Props) => {
  const [startTime, setStartTime] = useState(shift?.startTime);
  const [endTime, setEndTime] = useState(shift?.endTime);
  const [notes, setNotes] = useState(shift?.notes);
  const [showAssignStaffModalContent, setShowAssignStaffModalContent] =
    useState<{ scheduleId: string; shiftId: string } | null>(null);
  const [selectedStaff, setSelectedStaff] = useState(shift?.staffId);

  console.log("shift", shift);

  useEffect(() => {
    setStartTime(shift?.startTime);
    setEndTime(shift?.endTime);
    setNotes(shift?.notes);
    setSelectedStaff(shift?.staffId);
  }, [shift]);

  const fellowStaffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );
  const [saveLoading, setSaveLoading] = useState(false);

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const positionName = shift
    ? getPositionNameById(businessSettings, shift.position)
    : null;

  const saveShiftHandler = useCallback(async () => {
    try {
      if (shift && business && schedule && startTime && endTime) {
        if (schedule?.isDuplicate) {
          setSaveLoading(true);
          await createDraftScheduleFromDuplicateWithEditShiftPayload(
            schedule,
            business.id,
            shift.id,
            startTime ?? shift.startTime,
            endTime ?? shift.endTime,
            selectedStaff ?? null,
            notes
          );
          setSaveLoading(false);
        } else {
          const scheduleId = schedule?.mergedSchedules?.length
            ? shift.draftScheduleId
            : schedule.id;
          if (scheduleId) {
            setSaveLoading(true);
            await editScheduleShift(
              shift.id,
              scheduleId,
              business.id,
              startTime ?? shift.startTime,
              endTime ?? shift.endTime,
              selectedStaff ?? null,
              notes
            );
            setSaveLoading(false);
          } else {
            throw new Error("Opps, we weren't able to find this schedule.");
          }
        }
      }
    } catch (e: any) {
      toaster.show({
        message:
          e?.response?.data?.message ??
          "Something went wrong, please try deleting this shift and creating a new one",
        intent: Intent.DANGER,
      });
      setSaveLoading(false);
    }
  }, [schedule, shift, business, startTime, endTime, notes, selectedStaff]);

  const deleteShiftHandler = useCallback(async () => {
    if (schedule && shift && business) {
      if (schedule?.isDuplicate) {
        await deleteShiftInDuplicate(business.id, schedule, shift.id);
      } else {
        const scheduleId = schedule?.mergedSchedules?.length
          ? shift.draftScheduleId
          : schedule.id;
        if (scheduleId) {
          await deleteShift(business.id, scheduleId, shift.id);
        }
      }
      closeModal();
    }
  }, [schedule, shift, business]);

  if (!fellowStaffMembers) {
    return null;
  }

  const staffMember = fellowStaffMembers.find((sm) => sm?.id === selectedStaff);
  const saveButtonDisabled =
    startTime === shift?.startTime &&
    endTime === shift?.endTime &&
    shift?.staffId === selectedStaff &&
    shift?.notes === notes;

  return (
    <Dialog
      isOpen={Boolean(shift)}
      canOutsideClickClose
      onClose={closeModal}
      className="request-modal"
    >
      {shift && schedule ? (
        <>
          <Box className="modal-header">
            <div className="modal-back-button" onClick={closeModal}>
              <Icon icon="chevron-left" iconSize={8} />
            </div>
            {moment(shift.startDate.toMillis()).format("dddd, MMM D")}
            <img
              onClick={deleteShiftHandler}
              className="modal-delete-button"
              src={DeleteShiftImage}
            />
          </Box>
          <Box className="modal-body">
            <Box display="flex" flexDirection="row" className="border-bottom">
              <Box
                className="staff-name body-section"
                onClick={() => {
                  const scheduleId = schedule?.mergedSchedules
                    ? shift.draftScheduleId
                    : schedule.id;
                  if (scheduleId) {
                    setShowAssignStaffModalContent({
                      shiftId: shift.id,
                      scheduleId: scheduleId,
                    });
                  }
                }}
                display="flex"
                flex={0.4}
                flexDirection="column"
              >
                <div className="section-title">{positionName || "-"}</div>
                <div className="section-body">
                  {staffMember?.contact?.firstName}{" "}
                  {staffMember?.contact?.lastName}
                </div>
              </Box>
              <Box className="shift-time" display="flex" flex={0.6}>
                <Box
                  className="shift-start-time  body-section border-left"
                  display="flex"
                  flex={1}
                  flexDirection="column"
                >
                  <div className="section-title">Start</div>
                  <input
                    className="time-picker"
                    value={startTime}
                    type="time"
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                  />
                </Box>
                <Box
                  className="shift-end-time  body-section  border-left"
                  display="flex"
                  flex={1}
                  flexDirection="column"
                >
                  <div className="section-title">End</div>
                  <input
                    className="time-picker"
                    value={endTime}
                    type="time"
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box className="border-bottom" display="flex" flexDirection="row">
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: "72.2%" }}
                className="body-section"
              >
                <div className="section-title">Notes</div>
                <TextArea
                  className="shift-notes-input"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  growVertically
                />
              </Box>

              <Box
                display="flex"
                style={{ width: "25%" }}
                className="body-section border-left"
                flexDirection="column"
              >
                {/* <div style={{fontSize: 10, fontFamily:"SFProText-Semibold", color:"#454545", marginBottom: 10}}>On call</div>
              <div>
                <Switch large/>
              </div> */}
              </Box>
            </Box>
          </Box>
          <Box
            className="modal-footer"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Button
              disabled={saveButtonDisabled}
              onClick={saveShiftHandler}
              loading={saveLoading}
              icon="small-tick"
              text="Save Shift"
              className="footer-button"
              id="approve-button"
            />
          </Box>
        </>
      ) : null}
      <AvailableStaffModal
        mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
        scheduleId={showAssignStaffModalContent?.scheduleId}
        shiftId={showAssignStaffModalContent?.shiftId}
        duplicateScheduleToUse={schedule}
        isOpen={Boolean(showAssignStaffModalContent)}
        closeModal={() => setShowAssignStaffModalContent(null)}
        customSave={(staffId) => {
          setSelectedStaff(staffId);
          setShowAssignStaffModalContent(null);
        }}
      />
    </Dialog>
  );
};
export default EditSingleShiftModal;
