import tangoComponents from "@tangopay/tango-ui-library";
import moment from "moment-timezone";
import React, { useCallback } from "react";

import { DataPoint } from "components/Table/GenericCell/TableCell";

const { DatePickerInput, TimePickerInput, Icon } = tangoComponents;

type ViewProps = {
  value: DataPoint;
};
type EditProps = {
  value: DataPoint;
  onChange?: (newValue: DataPoint) => unknown;
};

export const DateViewComponent = ({ value }: ViewProps) => {
  return <div>{value ?? "Not Clocked Out"}</div>;
};
export const DateEditComponent = ({ value, onChange }: EditProps) => {
  const dateISOString = value as string;
  const date = value ? moment(dateISOString).toDate() : undefined;
  const handleChange = useCallback(
    (d: Date) => {
      if (onChange) onChange(moment(d).toISOString());
    },
    [dateISOString, onChange]
  );

  return <DatePickerInput size="small" value={date} onChange={handleChange} />;
};

export const TimeViewComponent = ({ value }: ViewProps) => {
  return <div>{value ?? "Not Clocked Out"}</div>;
};
export const TimeEditComponent = ({ value, onChange }: EditProps) => {
  const timeString = value as string;
  const date = value ? moment(timeString, "hh:mm a").toDate() : undefined;
  const handleChange = useCallback(
    (d: Date) => {
      if (onChange && d) onChange(moment(d).format("hh:mm a"));
    },
    [date, onChange]
  );

  return <TimePickerInput size="small" value={date} onChange={handleChange} />;
};
