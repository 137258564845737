import _ from "lodash";
import moment from "moment-timezone";
import React from "react";

import {
  ScheduleEventType,
  getRequestsWithOpenRequestsForADay,
} from "controllers/schedule";
import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import RequestCard from "../RequestCard";
import TimeOffRequests from "../TimeOffRequests";
import { ScheduleStatus } from "./ShiftViewTypeSidebarContent";
import { WeeklySidebar } from "./WeeklySidebar";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";

interface RequestsProps {
  dayOfWeek: Date;
  requestType: ScheduleEventType;
  scheduleEvents: ScheduleEvent[];
  weekRange: Date[];
  fellowStaffMembers: StaffMember[];
  setSelectedTradeOrCoverRequestId: (v: string | null) => void;
  setSelectedTradeOrCoverRequest: (v: ScheduleEvent | null) => void;
  setSelectedRequest: (v: ScheduleEvent | null) => void;
  setSelectedRequestId: (v: string | null) => void;
}

const Requests = ({
  dayOfWeek,
  requestType,
  scheduleEvents,
  weekRange,
  fellowStaffMembers,
  setSelectedTradeOrCoverRequestId,
  setSelectedTradeOrCoverRequest,
  setSelectedRequest,
  setSelectedRequestId,
}: RequestsProps) => {
  let requestsForADay = getRequestsWithOpenRequestsForADay(
    dayOfWeek,
    scheduleEvents,
    weekRange,
    fellowStaffMembers
  ).filter((s) => s.eventType === requestType);

  if (requestType === "trade") {
    requestsForADay = getRequestsWithOpenRequestsForADay(
      dayOfWeek,
      scheduleEvents,
      weekRange,
      fellowStaffMembers
    ).filter((s) => s.eventType === "trade" || s.eventType === "cover");
  }
  return (
    <Box className="day-body">
      {requestsForADay.map((request) => (
        <RequestCard
          request={request}
          key={request?.localOptionalRequestId || request.id}
          title={request?.senderStaff?.name}
          subtitle=""
          type={requestType}
          onClick={() => {
            if (
              request?.eventType === "trade" ||
              request?.eventType === "cover"
            ) {
              setSelectedTradeOrCoverRequestId(request.id);
              setSelectedTradeOrCoverRequest(request);
            } else {
              setSelectedRequest(request);
              setSelectedRequestId(request.id);
            }
          }}
        />
      ))}
    </Box>
  );
};

interface RequestsViewProps {
  weekRange: Date[];
  selectedRequestType: ScheduleEventType;
  isSelectRequestType: boolean | undefined;
  isAvailabilityView: boolean;
  onCalendarDateClick: (d: Date) => void;
  business: TangoBusiness;
  schedulesWithLocalSchedule: TangoSchedule[];
  fixedSchedules: TangoFixedSchedule[];
  schedules: TangoSchedule[];
  scheduleEvents: ScheduleEvent[];
  setAvailabilityViewFilterAnchorEl: (t: any) => void;
  availabilityViewFilterAnchorEl: any;
  availabilityRoleFilterId: string | null;
  businessSettings: TangoBusinessSettings;
  jobFunctions: TangoJobFunctions;
  onAvailabilityFullNameInputChangeDebounced: (v: any) => void;
  setAvailabilityRoleFilterId: (v: any) => void;
  weeklyScheduleViewType: WeeklyScheduleViewType;
  setWeeklyScheduleViewType: (v: WeeklyScheduleViewType) => void;
  departmentScheduleViewType: DepartmentId | null;
  setDepartmentScheduleViewType: (d: DepartmentId | null) => void;
  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  publishScheduleLoading: boolean;
  scheduleStatus: ScheduleStatus;
  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;

  setSelectedRequestId: (v: string | null) => void;

  fellowStaffMembers: StaffMember[];
  setSelectedTradeOrCoverRequestId: (v: string | null) => void;
  setSelectedTradeOrCoverRequest: (v: ScheduleEvent | null) => void;
  setSelectedRequest: (v: ScheduleEvent | null) => void;
  bohDraftStatus: ScheduleStatus;
  fohDraftStatus: ScheduleStatus;
  mergedScheduleForAWeekRange: TangoSchedule | undefined;
  fohScheduleForAWeekRange: TangoSchedule | undefined;
  bohScheduleForAWeekRange: TangoSchedule | undefined;
}

export const RequestsView = ({
  weekRange,
  selectedRequestType,
  isSelectRequestType = false,
  isAvailabilityView = false,
  onCalendarDateClick,
  business,
  schedulesWithLocalSchedule,
  fixedSchedules,

  schedules,
  scheduleEvents,
  setAvailabilityViewFilterAnchorEl,
  availabilityViewFilterAnchorEl,
  availabilityRoleFilterId,
  businessSettings,

  jobFunctions,
  onAvailabilityFullNameInputChangeDebounced,
  setAvailabilityRoleFilterId,
  weeklyScheduleViewType,
  setWeeklyScheduleViewType,
  departmentScheduleViewType,
  setDepartmentScheduleViewType,
  scheduleStatus,
  publishScheduleLoading,
  scheduleForWeekRange,

  duplicatedScheduleForAWeekRange,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,

  setSelectedRequestId,

  fellowStaffMembers,
  setSelectedTradeOrCoverRequestId,
  setSelectedTradeOrCoverRequest,
  setSelectedRequest,
  bohDraftStatus,
  fohDraftStatus,
  mergedScheduleForAWeekRange,
  fohScheduleForAWeekRange,
  bohScheduleForAWeekRange,
}: RequestsViewProps) => {
  if (selectedRequestType === ScheduleEventType.time_off) {
    return (
      <>
        <TimeOffRequests onRequestPress={setSelectedRequestId} />
      </>
    );
  }
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          overflowY: "scroll",
          overflowX: "visible",
          width: "20vw",
          minWidth: 280,
        }}
      >
        <WeeklySidebar
          mergedScheduleForAWeekRange={mergedScheduleForAWeekRange}
          bohDraftStatus={bohDraftStatus}
          fohDraftStatus={fohDraftStatus}
          weekRange={weekRange}
          selectedRequestType={selectedRequestType}
          isSelectRequestType={isSelectRequestType}
          isAvailabilityView={isAvailabilityView}
          onCalendarDateClick={onCalendarDateClick}
          business={business}
          schedulesWithLocalSchedule={schedulesWithLocalSchedule}
          fixedSchedules={fixedSchedules}
          schedules={schedules}
          scheduleEvents={scheduleEvents}
          setAvailabilityViewFilterAnchorEl={setAvailabilityViewFilterAnchorEl}
          availabilityViewFilterAnchorEl={availabilityViewFilterAnchorEl}
          availabilityRoleFilterId={availabilityRoleFilterId}
          businessSettings={businessSettings}
          jobFunctions={jobFunctions}
          onAvailabilityFullNameInputChangeDebounced={
            onAvailabilityFullNameInputChangeDebounced
          }
          setAvailabilityRoleFilterId={setAvailabilityRoleFilterId}
          weeklyScheduleViewType={weeklyScheduleViewType}
          setWeeklyScheduleViewType={setWeeklyScheduleViewType}
          departmentScheduleViewType={departmentScheduleViewType}
          setDepartmentScheduleViewType={setDepartmentScheduleViewType}
          scheduleStatus={scheduleStatus}
          publishScheduleLoading={publishScheduleLoading}
          scheduleForWeekRange={scheduleForWeekRange}
          duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
          setWeeklyStaffMemberViewFilterAnchorEl={
            setWeeklyStaffMemberViewFilterAnchorEl
          }
          weeklyStaffMemberViewFilterAnchorEl={
            weeklyStaffMemberViewFilterAnchorEl
          }
          onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
          roleFilterId={roleFilterId}
          setRoleFilterId={setRoleFilterId}
          draftsWithDepartmentForAWeek={[]}
          fohScheduleForAWeekRange={fohScheduleForAWeekRange}
          bohScheduleForAWeekRange={bohScheduleForAWeekRange}
        />
      </Box>
      <Box display="flex" flex={0.8} style={{ overflow: "scroll" }}>
        {weekRange.map((item: any, index: number) => (
          <Box
            className="day-column"
            key={`requests_view_${moment(item).format("DD/MM/YYYY")}`}
          >
            <Box className="day-header">
              <div className="day-name">{moment(item).format("ddd")}</div>
              <div className="day-date">{moment(item).format("D")}</div>
            </Box>
            <Requests
              dayOfWeek={item}
              requestType={selectedRequestType}
              scheduleEvents={scheduleEvents}
              weekRange={weekRange}
              fellowStaffMembers={fellowStaffMembers}
              setSelectedTradeOrCoverRequestId={
                setSelectedTradeOrCoverRequestId
              }
              setSelectedTradeOrCoverRequest={setSelectedTradeOrCoverRequest}
              setSelectedRequest={setSelectedRequest}
              setSelectedRequestId={setSelectedRequestId}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
