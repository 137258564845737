import { Button, Icon, TextArea } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import firebase from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import TangoIcon from "assets/enterpise/tango-icon.svg";

import Box from "../../../components/Box";
import { RootState } from "../../../model/store";
import "./index.css";

interface Route {
  name: string;
  displayName: string;
  uri: any;
}

const routes: Route[] = [
  {
    name: "products",
    displayName: "Products",
    uri: require("assets/new_launchpad/Products.svg").default,
  },
  {
    name: "menus",
    displayName: "Menus",
    uri: require("assets/new_launchpad/Menus.svg").default,
  },

  {
    name: "modifiers",
    displayName: "Modifiers",
    uri: require("assets/new_launchpad/Modifiers.svg").default,
  },
  {
    name: "modifier-options",
    displayName: "Modifier Options",
    uri: require("assets/new_launchpad/Modifier Options.png").default,
  },
  {
    name: "discounts",
    displayName: "Discounts",
    uri: require("assets/new_launchpad/Discounts.svg").default,
  },
  // {
  //   name: "core-reports",
  //   displayName: "Reporting",
  //   uri: require("assets/launchpad/reporting.svg").default,
  // },
  // {
  //   name: "business-info",
  //   displayName: "Business Info",
  //   uri: require("assets/launchpad/business-info.svg").default,
  // },
  // {
  //   name: "settings",
  //   displayName: "Settings",
  //   uri: require("assets/launchpad/settings.svg").default,
  // },
  // {
  //   name: "manager/scheduling",
  //   displayName: "Scheduling",
  //   uri: require("assets/launchpad/scheduling.svg").default,
  // },
  // {
  //   name: "manager/daily-logs",
  //   displayName: "Daily Logs",
  //   uri: require("assets/launchpad/daily-logs-icon.svg").default,
  // },
  // {
  //   name: "manager/team",
  //   displayName: "Team",
  //   uri: require("assets/launchpad/team.svg").default,
  // },
  {
    name: "documents/*",
    displayName: "Storage",
    uri: require("assets/launchpad/storage.svg").default,
  },
  {
    name: "manager/boards",
    displayName: "Boards",
    uri: require("assets/launchpad/memos.svg").default,
  },
  // {
  //   name: "manager/training",
  //   displayName: "Training",
  //   uri: require("assets/launchpad/training.svg").default,
  // },
  // {
  //   name: "gift-cards",
  //   displayName: "Gift Cards",
  //   uri: require("assets/launchpad/giftCards.svg").default,
  // },
  // {
  //   name: "table-management",
  //   displayName: "Tables",
  //   uri: require("assets/launchpad/table-management.svg").default,
  // },
  // {
  //   name: "table-management",
  //   displayName: "Services Areas",
  //   uri: require("assets/launchpad/serviceAreas.svg").default,
  // },
  // {
  //   name: "device-setup",
  //   displayName: "Device Setup",
  //   uri: require("assets/launchpad/deviceSetup.svg").default,
  // },

  // {
  //   name: "tipouts",
  //   displayName: "Tipouts",
  //   uri: require("assets/launchpad/tipout.svg").default,
  // },
  // {
  //   name: "help/tutorials",
  //   displayName: "How To",
  //   uri: require("assets/launchpad/help-support.svg").default,
  // },
  // {
  //   name: "organizational-hierarchy",
  //   displayName: "Hierarchy",
  //   uri: require("assets/launchpad/team.svg").default,
  // },
  // {
  //   name:'employees',
  //   displayName:'Employees',
  //   uri:require('../../assets/launchpad/team.svg').default,
  // },
  // {
  //   name: "campaigns",
  //   displayName: "Email Marketing",
  //   uri: require("assets/launchpad/emailMarketing.svg").default,
  // },
  // {
  //   name:'help',
  //   displayName:'Help & Support',
  //   uri:require('../../assets/launchpad/helpAndSupport.svg').default,
  // }
];

const EnterPriseDashboard = () => {
  const navigate = useNavigate();
  const account = useSelector((state: RootState) => state.account) || [];
  const renderEvents = () => {
    return (
      <ul>
        {[].map(() => (
          <li>
            <div className="event-time">9:30 a.m.</div>
            <div>
              <div className="event-title">Catch-up with ops</div>
              <div className="event-subtitle">
                Heather Smith, Jonas Kline, Jacob Nolan
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };
  const renderLaunchpadWidgets = () => {
    const [editText, setEditText] = useState(true);
    const [text, setText] = useState("Start typing a note");

    useEffect(() => {
      const textFromStorage = localStorage.getItem("notes-widget-text");
      if (textFromStorage) {
        setText(textFromStorage);
      }
    }, []);

    return (
      <div className="left-part">
        <div className="upcoming-events">
          <div className={"flex justify-between items-center"}>
            <div className="card-title">{moment().format("dddd, MMMM DD")}</div>
            <div className={"card-title"}>{moment().format("h:mm a")}</div>
          </div>

          {renderEvents()}
        </div>
        <div className="notepad">
          <div className="card-title">Notepad</div>
          <br />
          {editText ? (
            <TextArea
              className="file-description"
              onChange={(e) => setText(e.target.value)}
              value={text}
              growVertically
              autoFocus
              onBlur={() => {
                localStorage.setItem("notes-widget-text", text);
                // setEditText(false);
              }}
              style={{ width: "90%" }}
            />
          ) : (
            <div
              className="file-description"
              onMouseUp={() => {
                if (window) {
                  const selection = window.getSelection()?.toString() || "";
                  if (!selection) {
                    setEditText(true);
                  }
                }
              }}
              style={{ width: "90%" }}
            >
              {text}
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderDocker = () => {
    return (
      <Box
        className="bottom-dock-container"
        display="flex"
        justifyContent="center"
      >
        <Box className="bottom-dock" display="flex" flexDirection="row">
          {/* <Tooltip2
            className="doc-tooltip"
            content="Business Administration"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/core-reports">
                <img
                  src={require("assets/launchpad/business-admin.svg").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2>
          <Tooltip2
            className="doc-tooltip"
            content="Products & More"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/products">
                <img
                  src={require("assets/launchpad/product-more.svg").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2>
          <Tooltip2
            className="doc-tooltip"
            content="Staff & Service"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/manager">
                <img
                  src={require("assets/launchpad/staffing.svg").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2>
          <Tooltip2
            className="doc-tooltip"
            content="Store Setup"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/table-management">
                <img
                  src={require("assets/launchpad/StoreSetup.svg").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2>
          <Tooltip2
            className="doc-tooltip"
            content="Customers"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/gift-cards">
                <img
                  src={require("assets/launchpad/customers.svg").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2> */}

          <Tooltip2
            className="doc-tooltip"
            content="Store emulator"
            intent="success"
            placement="top"
            usePortal={false}
          >
            <div className="navigation-item">
              <Link to="/store-emulator">
                <img
                  src={require("assets/emulator-icon.png").default}
                  alt="doc icon"
                />
              </Link>
            </div>
          </Tooltip2>
        </Box>
      </Box>
    );
  };
  const renderLinks = () => {
    return (
      <div className="right-part">
        <ul>
          {routes.map((item, index) => (
            <li key={index}>
              <Link to={`/${item.name}`}>
                <img src={item.uri} />
                <div className={"text-white text-xs mt-2"}>
                  {item.displayName}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <div id="enterprise-dashboard">
      <img src={TangoIcon} className="brand-icon" />
      {
        //@ts-ignore
        account && account.mobileOrders && account.mobileOrders.displayName ? (
          <div className={"enteprise-name"}>
            {/*@ts-ignore*/}
            {account.mobileOrders.displayName}
          </div>
        ) : null
      }
      <Button
        onClick={async () => {
          await firebase.auth().signOut();
          navigate("/signIn");
        }}
        rightIcon={<Icon icon="arrow-right" color="#CFD2E2" />}
        text="Logout"
        className="logout-button"
      />
      <div className="dashboard-body">
        {renderLaunchpadWidgets()}
        {renderLinks()}
      </div>
      {renderDocker()}
    </div>
  );
};
export default EnterPriseDashboard;
