import { Spinner } from "@blueprintjs/core";
import React, { ChangeEvent, useRef } from "react";

import Box from "components/Box";

import UploadImageIcon from "assets/upload-image.svg";

interface Props {
  color: string;
  handleFileChange: (image: string, index: number, fileName: string) => void;
  images: string[];
  uploadingIndex: number | null;
}
const LabelPicture = ({
  color,
  handleFileChange,
  images,
  uploadingIndex,
}: Props) => {
  const inputRef = useRef(null);
  const handleBgImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      //@ts-ignore
      reader.fileName = e.target.files[0].name;
      reader.onload = async (event) => {
        if (event.target) {
          //@ts-ignore
          const fileName = new Date().getTime().toString();
          // @ts-ignore
          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = "";
          }
          const text = event.target.result;

          //@ts-ignore
          handleFileChange(text, index, fileName);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <div className="multiple-choice-container picture-choice-container">
      <Box display="flex">
        <div
          className={`single-choice-container`}
          style={{ color, borderColor: color, backgroundColor: color + "0C" }}
        >
          {/*@ts-ignore*/}
          <div
            style={{ color, borderColor: color }}
            className="upload-image"
            // @ts-ignore
            onClick={() => inputRef.current.click()}
          >
            {uploadingIndex === 0 ? (
              <Spinner size={30} />
            ) : images[0] ? (
              <img src={images[0]} className="option-image" />
            ) : (
              <>
                <img src={UploadImageIcon} />
                <div style={{ color }} className="apply-font">
                  Upload
                </div>
              </>
            )}
            {/*@ts-ignore*/}
            <input
              type="file"
              hidden
              onChange={(e) => handleBgImageUpload(e, 0)}
              // @ts-ignore
              ref={inputRef}
            />
          </div>
          <Box display="flex">
            <div
              className="key-bind apply-font"
              style={{ color, borderColor: color }}
            >
              {String.fromCharCode(+65)}
            </div>
            <input className="choice-name" disabled />
          </Box>
        </div>
      </Box>
    </div>
  );
};
export default LabelPicture;
