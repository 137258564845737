import * as React from "react";

export const Unsubscribed = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 23 23"
    {...props}
  >
    <path
      d="M22.9587 11.5L20.417 8.60419L20.7712 4.77085L17.0107 3.91669L15.042 0.604187L11.5003 2.12502L7.95866 0.604187L5.98991 3.91669L2.22949 4.76044L2.58366 8.59377L0.0419922 11.5L2.58366 14.3959L2.22949 18.2396L5.98991 19.0938L7.95866 22.4063L11.5003 20.875L15.042 22.3959L17.0107 19.0834L20.7712 18.2292L20.417 14.3959L22.9587 11.5ZM18.2607 13.6979L18.5316 16.6042L15.6774 17.25L14.1878 19.7604L11.5003 18.6042L8.81283 19.7604L7.32324 17.25L4.46908 16.6042L4.73991 13.6875L2.81283 11.5L4.73991 9.29169L4.46908 6.39585L7.32324 5.76044L8.81283 3.25002L11.5003 4.39585L14.1878 3.2396L15.6774 5.75002L18.5316 6.39585L18.2607 9.3021L20.1878 11.5L18.2607 13.6979ZM10.4587 14.625H12.542V16.7084H10.4587V14.625ZM10.4587 6.29169H12.542V12.5417H10.4587V6.29169Z"
      fill={color}
    />
  </svg>
);
