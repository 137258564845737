import ReactPDF, { Font, Text, View } from "@react-pdf/renderer";
import * as React from "react";

import LatoBold from "../../assets/fonts/Lato-ExtraBold.ttf";
import Lato from "../../assets/fonts/Lato-Regular.ttf";
import LatoSemiBold from "../../assets/fonts/Lato-Semibold.ttf";
import SFProTextBold from "../../assets/fonts/SFProText-Bold.ttf";
import SFProTextSemibold from "../../assets/fonts/SFProText-Semibold.ttf";
import { getDefaultBorderIncludes, transformToArray } from "./Utils";

Font.register({
  family: "SFProText-Semibold",
  src: SFProTextSemibold,
});

Font.register({
  family: "SFProText-SFProTextBold",
  src: SFProTextBold,
});

Font.register({
  family: "LatoSemiBold",
  src: LatoSemiBold,
});

Font.register({
  family: "LatoBold",
  src: LatoBold,
});
Font.register({
  family: "Lato",
  src: Lato,
});

export const NoWrap = (props: any) => (
  //@ts-ignore
  <View wrap={false} style={{ width: "100%" }}>
    {props.children}
  </View>
);

/**
 * Whether to include borders or not.
 * Depending on the context some toggles will not have any effect.
 */
export interface TableBorder {
  /**
   * Include the top border. Default true.
   */
  includeTopBorder?: boolean;

  /**
   * Include the right border. Default true.
   */
  includeRightBorder?: boolean;

  /**
   * Include the bottom border. Default true.
   */
  includeBottomBorder?: boolean;

  /**
   * Include the left border. Default true.
   */
  includeLeftBorder?: boolean;
}

export interface TableCellProps extends TableBorder {
  /**
   * The weighting of a cell based on the flex layout style.
   * This value is between 0..1, if not specified 1 is assumed, this will take up the remaining available space.
   */
  weighting?: number;

  /**
   * Extra styling to apply. These will override existing style with the same key.
   */
  //@ts-ignore
  style?: ReactPDF.Style | ReactPDF.Style[];

  /**
   * How to align the text
   */
  textAlign?: "left" | "center" | "right";

  /**
   * Whether this is a header cell or not. If not defined it will be false.
   */
  isHeader?: boolean;

  /**
   * The font-size to apply to the cell.
   */
  fontSize?: number | string;
}

/**
 * This component displays the associated content of it's children.
 */
export class TableCell extends React.PureComponent<TableCellProps> {
  render() {
    let content: any;

    if (typeof this.props.children === "string") {
      content = <Text>{this.props.children}</Text>;
    } else if (typeof this.props.children === "number") {
      content = <Text>{this.props.children.toString()}</Text>;
    } else {
      content = this.props.children;
    }

    const { includeRightBorder } = getDefaultBorderIncludes(this.props);
    //@ts-ignore
    const defaultStyle: ReactPDF.Style = {
      flex: this.props.weighting ?? 1,
      // @ts-ignore
      justifyContent: "stretch",
      textAlign: this.props.textAlign ?? "left",
      fontSize: this.props.fontSize ?? (this.props.isHeader === true ? 12 : 10),
      borderRight: includeRightBorder && "1pt solid #f4f3f3",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
    };
    //@ts-ignore
    const mergedStyles: ReactPDF.Style[] = [
      defaultStyle,
      ...transformToArray(this.props.style),
    ];

    return (
      <View style={mergedStyles} wrap={false}>
        {content}
      </View>
    );
  }
}
