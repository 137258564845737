import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  DailyShiftsAMPMSplitReport,
  ShiftAMPMSplitTableRow,
} from "controllers/reporting";
import { DEPARTMENTS } from "controllers/staff";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const horizontalPadding = 15;

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 6,
    border: 0,
    fontFamily: "LatoBold",
    color: "#000000",
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: "#ededed",
  },
  shiftTitle: {
    fontSize: 13,
    fontFamily: "LatoBold",
    color: "#000000",
    marginBottom: 10,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  },
  shiftSubtitle: {
    fontSize: 8,
    fontFamily: "LatoSemiBold",
    color: "#000000",
    marginBottom: 10,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
  },
  footerCellStyle: {
    fontSize: 7,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 7,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 3,
  },
  dataCellStyle: {
    fontSize: 4,
    border: 0,
    fontFamily: "LatoSemiBold",
    color: "#000000",
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 2,
    paddingBottom: 2,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  completelyRoundedFooter: {
    borderRadius: 5,
    backgroundColor: "#f4f3f3",
  },

  roleHeaderContainer: {
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    paddingTop: 7,
    paddingBottom: 7,
    backgroundColor: "#dbdbdb",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  roleHeaderCell: {
    fontFamily: "LatoBold",
    color: "black",
    fontWeight: "bold",
    fontSize: 8,
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface DailyLaborPdfDocumentProps {
  report: DailyShiftsAMPMSplitReport;
  businessName: string;
}

const DailyScheduleReportAMPMSplitPdfDocument = (
  props: DailyLaborPdfDocumentProps
) => {
  DEPARTMENTS;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ marginTop: 5, marginBottom: 5 }}>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={styles.shiftTitle}>
              {moment(props.report.date).format("MMMM Do, YYYY")}
            </Text>
            <View>
              <Text style={styles.shiftSubtitle}>
                <Text style={{ fontFamily: "LatoBold" }}>
                  {props.businessName}
                </Text>{" "}
                | {props.report.totalHours} hrs, total staff hours
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <View style={{ flex: 1 }}>
              <View wrap={false}>
                <View style={styles.roleHeaderContainer}>
                  <Text style={styles.roleHeaderCell}>AM Shifts</Text>
                </View>

                <Table data={props.report.am}>
                  <TableHeader>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Time In
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Time Out
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={5}
                    >
                      Notes
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.roleName}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) =>
                        r.staffFullName
                      }
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.timeIn}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.timeOut}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={5}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.notes}
                    />
                  </TableBody>
                </Table>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <View wrap={false}>
                <View style={styles.roleHeaderContainer}>
                  <Text style={styles.roleHeaderCell}>PM Shifts</Text>
                </View>

                <Table data={props.report.pm}>
                  <TableHeader>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Time In
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={3}
                    >
                      Time Out
                    </TableCell>
                    <TableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      textAlign="center"
                      style={styles.headerCellStyle}
                      weighting={5}
                    >
                      Notes
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.roleName}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) =>
                        r.staffFullName
                      }
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.timeIn}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={3}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.timeOut}
                    />
                    <DataTableCell
                      includeRightBorder={false}
                      includeLeftBorder={false}
                      includeBottomBorder
                      includeTopBorder
                      style={styles.dataCellStyle}
                      weighting={5}
                      getContent={(r: ShiftAMPMSplitTableRow) => r.notes}
                    />
                  </TableBody>
                </Table>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DailyScheduleReportAMPMSplitPdfDocument;
