import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLaunchpad } from "views/LaunchPad/LaunchpadProvider";

import { RootState } from "model/store";

import TangoImage from "../../assets/manager/tango.png";
import Box from "../../components/Box";
import Header from "components/Header";

interface Props {
    children: any;
}

const Layout = ({ children }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const launchpad = useLaunchpad();

    const user: StaffMember = useSelector((state: RootState) => state.user);
    const emulatorMode: boolean = useSelector(
        (state: RootState) => state.emulatorMode
    );
    const isEnterpriseLevel = !emulatorMode && user?.corporateAccess;
    const routes = [{
        label: "Docs",
        link: "/documents"
    },
    {
        label: "Forms",
        link: "/forms"
    }, {
        label: "Boards",
        link: "/boards",
    }
    ]
    return (
        <Box className="business-admin-module">
            <Header
                routes={routes}
                selectedRoute={location.pathname.includes("documents") ? "/documents" : location.pathname}
                selectedPage={{
                    label: "Productivity",
                    value: "/documents"
                }}
            />
            {/* <Box>{user?.contact?.firstName} {user?.contact?.lastName}</Box> */}
            <div className="pt-16">
                {children}

            </div>
        </Box>
    );
};
export default Layout;
