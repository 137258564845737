import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
  formId: string;
  businessId: string;
  showPublishedText: boolean;
}
const SharableLinkModal = ({
  showModal,
  onClose,
  formId,
  businessId,
  showPublishedText,
}: Props) => {
  const { hostname, protocol, origin } = window.location;
  return (
    <Dialog
      onClose={onClose}
      hasBackdrop
      isOpen={showModal}
      className="sharable-link-modal"
    >
      <div className="modal-header">
        <Icon icon="cross" size={15} color="#424452" onClick={onClose} />
      </div>
      <div className="modal-body">
        {showPublishedText ? (
          <div className="published-text">Your form has been published!</div>
        ) : null}
        <div className="sharable-link-text">Shareable Link</div>
        <div style={{ display: "flex" }}>
          <input value={`${origin}/form/${formId}/${businessId}`} />
          <Button
            text="Copy link"
            onClick={() =>
              navigator.clipboard.writeText(
                `${origin}/form/${formId}/${businessId}`
              )
            }
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SharableLinkModal;
