import {
  Spinner as BluepringSpinner,
  Icon,
  InputGroup,
  Intent,
  Position,
  Toaster,
} from "@blueprintjs/core";
import { Checkbox } from "@mui/material";
import firebase from "config/firebase";
import parsePhoneNumber, {
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { find, findIndex } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Select from "react-select";
import { OptionTypeBase } from "react-select/src/types";

import {
  addTeammate,
  checkEmailFieldForStaffSignUp,
  checkPhoneNumberFieldForStaffSignUp,
} from "controllers/auth";

import Box from "components/Box";
import Spinner from "components/Spinner";

import { RootState } from "model/store";

import logo from "assets/normallogo.svg";

import staffMembers from "../../../../model/reducers/staffMembers";
import {
  findOrCreateUser,
  getAllBusinessesByAccountId,
} from "../teamFirebaseCalls";
import { BasicInfo } from "./Icons/BasicInfo";
import { EmployeeRole } from "./Icons/EmployeeRole";
import { EmployeeType } from "./Icons/EmployeeType";
import { OfferLetter } from "./Icons/OfferLetter";
import { PaymentInfo } from "./Icons/PaymentInfo";
import { Review } from "./Icons/Review";
import tickArrow from "./Icons/tickarrow.svg";
import "./index.css";
import FindLocationModal from "./modals/FindLocationModal";

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

const EMPLOYEE_PERMISSIONS = [
  // "Company Admin",
  "Location Admin",
  "Basic Employee",
];

// const initialEmployeeDetails = ;

const AddEmployee = () => {
  const navigate = useNavigate();
  const ayt = new AsYouType("US");
  const db = firebase.firestore();
  const [loading, setLoading] = useState(false);
  const [sidebarList, setSideBarList] = useState([
    {
      name: "Basic Information",
      icon: BasicInfo,
      index: 1,
    },
    {
      name: "Employee Roles",
      icon: EmployeeRole,
      index: 2,
    },
    {
      name: "Payment Information",
      icon: PaymentInfo,
      index: 3,
    },
    {
      name: "Employee Type",
      icon: EmployeeType,
      index: 4,
    },
  ]);

  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>({
    uid: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    employeeType: "",
    jobRoles: [],
    employeePermission: "",
    locations: [],
    compensationType: null,
    payRate: 0,
    employeeHiringType: null,
    annualSalary: 0,
  });

  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [allBusinesses, setAllBusinesses] = useState<TangoBusiness[]>([]);
  const [step, setStep] = useState<number>(1);
  const [emailCheckError, setEmailCheckError] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] =
    useState<OptionTypeBase | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<OptionTypeBase | null>(
    null
  );
  const [selectedManagers, setSelectedManagers] = useState<
    OptionTypeBase[] | null
  >(null);

  const [selectedCustomGroups, setSelectedCustomGorups] = useState<
    OptionTypeBase[] | null
  >(null);

  const [departments, setDepartments] = useState([
    {
      label: "FOH",
      value: "foh",
    },
    {
      label: "BOH",
      value: "boh",
    },
    {
      label: "Other",
      value: "other",
    },
  ]);
  const [roles, setRoles] = useState([
    {
      label: "Chief Legal Officer",
      value: "Chief Legal Officer",
    },
  ]);
  // const [levels, setLevels] = useState([
  //   {
  //     label: "C-Suite",
  //     value: "C-Suite",
  //   },
  // ]);

  const [isManagerER, setIsManagerER] = useState(false);
  const [isManagesER, setIsManagesER] = useState(false);
  const closeAddNamesPopup = () => {
    setIsManagerER(false);
    setIsManagesER(false);
  };
  const [phoneNumberCheckError, setPhoneNumberCheckError] = useState<
    string | null
  >(null);
  const [emailAndPhoneValidationLoading, setEmailAndPhoneValidationLoading] =
    useState(false);
  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const levels: Level[] = useMemo(() => {
    if (businessSettings && businessSettings.levels) {
      const levelsTemp: Level[] = [];
      Object.keys(businessSettings.levels).map((key) => {
        const businessSettingLevel = businessSettings.levels![key];
        levelsTemp.push({
          title: businessSettingLevel.title,
          levelId: businessSettingLevel.id,
          description: businessSettingLevel.description,
          label: businessSettingLevel.title,
          value: businessSettingLevel.id,
          index: businessSettingLevel.index,
        });
      });
      return levelsTemp;
    }
    return [];
  }, [businessSettings]);

  const customGroups: CustomGroupOrg[] = useMemo(() => {
    if (businessSettings && businessSettings.customGroups) {
      const customGroupsTemp: CustomGroupOrg[] = [];
      Object.keys(businessSettings.customGroups).map((key) => {
        const businessSettingLevel = businessSettings.customGroups![key];
        customGroupsTemp.push({
          title: businessSettingLevel.title,
          customGroupId: businessSettingLevel.id,
          description: businessSettingLevel.description,
          label: businessSettingLevel.title,
          value: businessSettingLevel.id,
        });
      });
      return customGroupsTemp;
    }
    return [];
  }, [businessSettings]);

  const customGroupsAsOptionType: OptionTypeBase[] = useMemo(() => {
    if (customGroups) {
      return customGroups.map((customGroup) => ({
        value: customGroup.customGroupId,
        label: customGroup.title,
      }));
    }
    return [];
  }, [customGroups]);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const jobRoles = useMemo(() => {
    if (businessSettings) {
      const jobRol = Object.keys(businessSettings.jobFunctions).map(
        (key) => businessSettings.jobFunctions[key]
      );
      console.log("jobRol", jobRol);
      return Object.keys(businessSettings.jobFunctions).map(
        (key) => businessSettings.jobFunctions[key]
      );
    }
    return [];
  }, [businessSettings]);

  const staffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const staffMembersAsOptionType: OptionTypeBase[] = useMemo(() => {
    if (staffMembers) {
      return staffMembers.map((staffMember) => ({
        value: staffMember.staffMemberCopyId,
        label: `${staffMember.contact.firstName} ${staffMember.contact.lastName}`,
      }));
    }
    return [];
  }, [staffMembers]);

  const submitForm = async (staffMember: any) => {
    try {
      console.log("staffMember", staffMember);
      // return db
      //   .collection("Staff")
      //   .add(staffMember)
      //   .then((docRef) => {
      //     db.collection("Staff").doc(docRef.id).update({ id: docRef.id });
      //   })
      //   .catch((error) => console.log(error));

      //setSuccessPage(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("business", business);
    const getAllLocationsOfAccount = async () => {
      const businesses = await getAllBusinessesByAccountId(business.accountId);
      setAllBusinesses(businesses);
    };
    if (business) getAllLocationsOfAccount();
  }, [business]);

  const isJobRoleSelected = (jobRoleId: string) => {
    const jobRoleById = find(employeeDetails.jobRoles, ["id", jobRoleId]);
    return !!jobRoleById;
  };

  const isJobRoleFirstIndexed = (item: any) => {
    return employeeDetails.jobRoles[0] == item;
  };

  const updateJobRole = (jobRole: any, isSelected: boolean) => {
    if (isSelected) {
      const indexToRemove = findIndex(employeeDetails.jobRoles, [
        "id",
        jobRole.id,
      ]);
      if (indexToRemove !== -1) {
        const oldJobRolesArray = [...employeeDetails.jobRoles];
        oldJobRolesArray.splice(indexToRemove, 1);
        setEmployeeDetails({
          ...employeeDetails,
          jobRoles: oldJobRolesArray,
        });
      }
    } else {
      setEmployeeDetails({
        ...employeeDetails,
        jobRoles: [...employeeDetails.jobRoles, jobRole],
      });
    }
  };

  const updateLocation = (
    location: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      if (index !== -1) {
        let oldLocationsArray = [...employeeDetails.locations];
        if (index === oldLocationsArray.length) {
          oldLocationsArray = [];
        } else {
          oldLocationsArray.splice(index, 1);
        }
        setEmployeeDetails({
          ...employeeDetails,
          locations: oldLocationsArray,
        });
      }
    } else {
      setEmployeeDetails({
        ...employeeDetails,
        locations: [...employeeDetails.locations, location],
      });
    }
  };
  const [successPage, setSuccessPage] = useState(false);

  useEffect(() => {
    if (successPage) {
      setTimeout(() => {
        setSuccessPage(false);
        setStep(1);
        setEmployeeDetails({
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          employeeType: "",
          jobRoles: [],
          employeePermission: "",
          locations: [],
          compensationType: null,
          payRate: 0,
          employeeHiringType: null,
          annualSalary: 0,
        });
        navigate("/manager/team");
      }, 2000);
    }
  }, [successPage]);

  const updateEmployeeDetail = (
    key: string,
    value: string | number,
    jobRoleIndex?: number
  ) => {
    if (jobRoleIndex === undefined) {
      setEmployeeDetails({
        ...employeeDetails,
        [key]: value,
      });
    } else {
      const existingRoles = [...employeeDetails.jobRoles];
      existingRoles[jobRoleIndex][key] = Number(value) * 100;

      setEmployeeDetails({ ...employeeDetails, jobRoles: existingRoles });
    }
  };

  const addTeammateHandler = useCallback(async () => {
    try {
      setLoading(true);
      const email = employeeDetails.emailAddress?.length
        ? employeeDetails.emailAddress
        : null;
      const parsedPhoneNumber = parsePhoneNumber(
        employeeDetails.phoneNumber,
        "US"
      )?.formatInternational();
      if (parsedPhoneNumber) {
        const roles = [...employeeDetails.jobRoles];
        const primaryRole = employeeDetails?.jobRoles[0]?.id;
        roles.shift();
        const payRates = employeeDetails?.jobRoles.map((item) => ({
          role: item.title,
          amount:
            employeeDetails.compensationType === "hourly"
              ? item.payRate * 100
              : item.annualSalary * 100,
          roleId: item.id,
          type: employeeDetails.compensationType,
        }));

        const secondaryRoles: string[] = roles
          .map((r) => r.id)
          .filter((x) => !!x);
        const annualSalary = Number(employeeDetails.annualSalary);
        const typeOfEmployee = employeeDetails.employeeType;
        //accountId:
        // employeeDetails.employeePermission == "Company Admin"
        // ? business.accountId
        // : null,
        //isAdmin: employeeDetails.employeePermission != "Basic Employee",
        const accountId =
          employeeDetails.employeePermission == "Company Admin"
            ? business.accountId
            : null;

        await addTeammate(
          business.id,
          parsedPhoneNumber.trim(),
          email,
          employeeDetails.employeePermission != "Basic Employee",
          employeeDetails.firstName,
          employeeDetails.lastName,
          primaryRole,
          secondaryRoles,
          payRates,
          annualSalary,
          typeOfEmployee,
          accountId,
          selectedLevel ? selectedLevel.value : null,
          selectedDepartment ? selectedDepartment.value : null,
          selectedManagers
            ? selectedManagers.map((manager) => manager.value)
            : null,
          selectedCustomGroups
            ? selectedCustomGroups.map((customGroup) => customGroup.value)
            : null
        );
        setLoading(false);
        setSuccessPage(true);
      }
    } catch (e: any) {
      setLoading(false);
      toaster.show({
        message:
          e?.response?.data?.message ??
          "Error adding a teammate, please try again later...",
        intent: Intent.DANGER,
      });
    }
  }, [
    employeeDetails.emailAddress,
    employeeDetails.phoneNumber,
    employeeDetails.firstName,
    employeeDetails.lastName,
    employeeDetails?.jobRoles,
    employeeDetails.payRate,
    employeeDetails.annualSalary,
    employeeDetails.employeeType,
    employeeDetails.employeePermission,
    employeeDetails.compensationType,
    selectedLevel,
    selectedDepartment,
    selectedCustomGroups,
    selectedManagers,
  ]);

  const setPayrateZero = () => {
    setEmployeeDetails({
      ...employeeDetails,
      compensationType: "annually",
      payRate: 0,
    });
  };

  const setAnnualSalaryZero = () => {
    setEmployeeDetails({
      ...employeeDetails,
      compensationType: "hourly",
      annualSalary: 0,
    });
  };

  const sidebarListNewHire = [
    {
      name: "Review",
      icon: Review,
      index: 5,
    },
    {
      name: "Offer Letter",
      icon: OfferLetter,
      index: 6,
    },
  ];

  const isFirstStepContinueButtonEnable = () => {
    return (
      employeeDetails.firstName.length > 2 &&
      employeeDetails.lastName.length > 2 &&
      (employeeDetails.emailAddress
        ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            employeeDetails.emailAddress
          )
        : true) &&
      isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`) &&
      employeeDetails.employeeType !== ""
    );
  };

  const isSecondStepContinueButtonEnable = () => {
    return (
      employeeDetails.jobRoles.length > 0 &&
      employeeDetails.employeePermission.length > 0 &&
      employeeDetails.locations.length > 0
    );
  };

  const isPayRatefilled = () => {
    if (
      employeeDetails.compensationType == "hourly" &&
      employeeDetails.jobRoles.reduce(
        (acc, currentRole) => acc + currentRole.payRate,
        0
      ) > 0
    ) {
      return true;
    } else if (
      employeeDetails.compensationType == "annually" &&
      employeeDetails.jobRoles.reduce(
        (acc, currentRole) => acc + currentRole.annualSalary,
        0
      ) > 0
    ) {
      return true;
    }
    return false;
  };

  const validatePhoneNumberAndEmail = useCallback(async () => {
    try {
      setEmailCheckError(null);
      setPhoneNumberCheckError(null);
      console.log("employeeDetails", employeeDetails);

      const parsedPhoneNumber = parsePhoneNumber(
        employeeDetails.phoneNumber,
        "US"
      )?.formatInternational();

      if (parsedPhoneNumber) {
        setEmailAndPhoneValidationLoading(true);

        const emailCheckResult = employeeDetails.emailAddress
          ? await checkEmailFieldForStaffSignUp(
              employeeDetails.emailAddress,
              business.id
            )
          : { available: true };
        const phoneNumberCheckResult =
          await checkPhoneNumberFieldForStaffSignUp(
            parsedPhoneNumber,
            business.id
          );

        if (!emailCheckResult.available) {
          setEmailCheckError(
            emailCheckResult.message ??
              "Something went wront, please try different email"
          );
        }

        if (!phoneNumberCheckResult.available) {
          setPhoneNumberCheckError(
            phoneNumberCheckResult.message ??
              "Something went wront, please try different phone number"
          );
        }

        setEmailAndPhoneValidationLoading(false);

        console.log({
          emailCheckResult,
          phoneNumberCheckResult,
        });
        if (emailCheckResult.available && phoneNumberCheckResult.available) {
          window.scrollTo(0, 0);
          setStep(2);
        }
      } else {
        setPhoneNumberCheckError(
          "Invalid phone number, please provide a valid US/CA phone number"
        );
        setEmailAndPhoneValidationLoading(false);
      }
    } catch (e) {
      console.log("error validating phone and email", e);
      setPhoneNumberCheckError(
        "Oops something went wrong with validation, please try again"
      );
      setEmailCheckError(
        "Oops something went wrong with validation, please try again"
      );
      setEmailAndPhoneValidationLoading(false);
    }
  }, [employeeDetails.emailAddress, employeeDetails.phoneNumber]);

  const renderEmployeeInformation = () => (
    <Box display="flex" flex-direction="column">
      <div className="section">
        <div className="section-title">Employee Information</div>
        <div className="section-subtitle">
          Let’s create your employees profile
        </div>
        <Box
          display="flex"
          flexDirection="column"
          style={{ marginTop: "15px" }}
        >
          <div className="input-container">
            <div className="input-label">First Name</div>
            <InputGroup
              onChange={(e) =>
                updateEmployeeDetail("firstName", e.target.value)
              }
              value={employeeDetails.firstName}
              className="employeeinputs"
            />
            {employeeDetails.firstName.length > 2 && (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            )}
          </div>
          <div className="input-container">
            <div className="input-label">Last Name</div>
            <InputGroup
              onChange={(e) => updateEmployeeDetail("lastName", e.target.value)}
              value={employeeDetails.lastName}
              className="employeeinputs"
            />
            {employeeDetails.lastName.length > 2 && (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            )}
          </div>
          <div
            className="input-container"
            style={
              emailCheckError
                ? { border: "1px solid red", color: "red" }
                : undefined
            }
          >
            <div className="input-label">Email</div>
            <InputGroup
              type="email"
              onChange={(e) =>
                updateEmployeeDetail("emailAddress", e.target.value)
              }
              value={employeeDetails.emailAddress}
              className="employeeinputs"
            />
            {emailCheckError ? <div>{emailCheckError}</div> : null}
            {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              employeeDetails.emailAddress
            ) &&
            !emailCheckError &&
            !emailAndPhoneValidationLoading ? (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            ) : null}
            {emailAndPhoneValidationLoading && employeeDetails.emailAddress ? (
              <BluepringSpinner className="input-check" size={40} />
            ) : null}
          </div>
          <div
            className="input-container"
            style={
              phoneNumberCheckError
                ? { border: "1px solid red", color: "red" }
                : undefined
            }
          >
            <div className="input-label">Phone Number</div>
            <InputGroup
              onChange={(e) => {
                const number = e.target.value
                  .replace("(", "")
                  .replace(")", "")
                  .replace("-", "")
                  .replace(" ", "");

                // console.log(number);
                if (number.length <= 10) {
                  // onChange(number);
                  updateEmployeeDetail("phoneNumber", e.target.value);
                }
              }}
              // maxLength={10}
              value={ayt.input(employeeDetails.phoneNumber)}
              className="employeeinputs"
            />
            {phoneNumberCheckError ? <div>{phoneNumberCheckError}</div> : null}
            {isValidPhoneNumber(`+1 ${employeeDetails.phoneNumber}`) &&
            !phoneNumberCheckError &&
            !emailAndPhoneValidationLoading ? (
              <img
                src={require("assets/input-check.svg").default}
                className="input-check"
              />
            ) : null}
            {emailAndPhoneValidationLoading ? (
              <BluepringSpinner className="input-check" size={40} />
            ) : null}
          </div>
        </Box>
      </div>
      <Box className="section option-section">
        <div className="section-title">Type of Employee</div>
        <div className="section-subtitle">
          Select what type of hire this employee will be
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <button
            className={`select-button ${
              employeeDetails.employeeType === "full-time" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "full-time")}
          >
            <Icon
              icon={
                employeeDetails.employeeType === "full-time" ? "tick" : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Full Time Employee
          </button>
          <button
            className={`select-button ${
              employeeDetails.employeeType === "part-time" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "part-time")}
          >
            <Icon
              icon={
                employeeDetails.employeeType === "part-time" ? "tick" : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Part Time Employee
          </button>
          <button
            className={`select-button ${
              employeeDetails.employeeType === "intern" ? "active" : ""
            }`}
            onClick={() => updateEmployeeDetail("employeeType", "intern")}
          >
            <Icon
              icon={employeeDetails.employeeType === "intern" ? "tick" : "plus"}
              size={12}
              color={"#4385EF"}
            />{" "}
            Intern
          </button>
        </Box>
      </Box>
      <button
        className={
          isFirstStepContinueButtonEnable() && !emailAndPhoneValidationLoading
            ? "continue-button"
            : "continue-button-disabled"
        }
        onClick={validatePhoneNumberAndEmail}
        disabled={
          !isFirstStepContinueButtonEnable() || emailAndPhoneValidationLoading
        }
      >
        Continue
      </button>
    </Box>
  );

  const renderEmployeeRolesForm = () => (
    <Box
      display="flex"
      flex-direction="column"
      onClick={() => closeAddNamesPopup()}
    >
      <Box className="section option-section">
        <div className="section-title">Location</div>
        <div className="section-subtitle">
          Select where this employee will be working
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          {employeeDetails.locations.map((item, index) => (
            <button className="select-button active" key={`${item}-${index}`}>
              <Icon icon="tick" size={12} color={"#4385EF"} />{" "}
              {item?.businessName}
            </button>
          ))}
          <button
            className="select-button"
            onClick={() => setShowLocationModal(true)}
          >
            <Icon icon="plus" size={12} color={"#4385EF"} /> Please add a
            location for this employee
          </button>
        </Box>
      </Box>
      <Box className="section option-section">
        <div className="section-title">Job Role</div>
        <div className="section-subtitle">
          Select what kind of role this employee will have
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          {jobRoles.map((item, key) => (
            <button
              className={`${
                isJobRoleSelected(item.id)
                  ? "select-button active"
                  : "select-button"
              }`}
              onClick={() => updateJobRole(item, isJobRoleSelected(item.id))}
              key={item.id}
            >
              {isJobRoleSelected(item.id) && isJobRoleFirstIndexed(item) && (
                <div className="PrimaryTag">Primary</div>
              )}
              <Icon
                icon={isJobRoleSelected(item.id) ? "tick" : "plus"}
                size={12}
                color={"#4385EF"}
              />{" "}
              {item.title}
            </button>
          ))}
        </Box>
      </Box>
      <Box className="section option-section">
        <div className="section-title">Employee Permissions</div>
        <div className="section-subtitle">
          Select what type of hire this employee will be
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          {EMPLOYEE_PERMISSIONS.map((item) => (
            <button
              className={
                employeeDetails.employeePermission === item
                  ? "select-button active"
                  : "select-button"
              }
              onClick={() => updateEmployeeDetail("employeePermission", item)}
              id={item}
            >
              <Icon
                icon={
                  employeeDetails.employeePermission === item ? "tick" : "plus"
                }
                size={12}
                color={"#4385EF"}
              />{" "}
              {item}
            </button>
          ))}
        </Box>
      </Box>
      <Box className="section option-section">
        <div className="section-title">Department</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <Select
            className="flex-react-select employee-section"
            placeholder=""
            value={selectedDepartment}
            options={departments}
            onChange={setSelectedDepartment}
          />
          {/*<div className="selected_employee_option">*/}
          {/*  <div className="option-text">Legal</div>*/}
          {/*  <Icon*/}
          {/*    icon="small-cross"*/}
          {/*    iconSize={20}*/}
          {/*    className="option-icon"*/}
          {/*  ></Icon>*/}
          {/*</div>*/}
        </Box>
      </Box>
      <Box className="section option-section">
        <div className="section-title">Level</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <Select
            className="flex-react-select employee-section"
            placeholder=""
            value={selectedLevel}
            onChange={setSelectedLevel}
            options={levels}
          />
        </Box>
      </Box>
      <Box className="section option-section">
        <div className="section-title">Manager(s)</div>
        <div className="section-subtitle">Who this employee reports to</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <Select
            className="flex-react-select employee-section"
            placeholder=""
            isMulti
            value={selectedManagers}
            onChange={(value: any) => {
              // console.log({ value })
              setSelectedManagers(value);
            }}
            options={staffMembersAsOptionType}
          />
        </Box>
      </Box>

      <Box className="section option-section">
        <div className="section-title">Custom Group(s)</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <Select
            className="flex-react-select employee-section"
            placeholder=""
            isMulti
            value={selectedCustomGroups}
            onChange={(value: any) => {
              console.log({ value });
              setSelectedCustomGorups(value);
            }}
            options={customGroupsAsOptionType}
          />
        </Box>
      </Box>

      <button
        className={
          isSecondStepContinueButtonEnable()
            ? "continue-button"
            : "continue-button-disabled"
        }
        disabled={!isSecondStepContinueButtonEnable()}
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(3);
        }}
      >
        Continue
      </button>
    </Box>
  );
  const renderPaymentInformationForm = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">Compensation Type</div>
        <div className="section-subtitle">How will this employee be paid</div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <button
            className={`select-button ${
              employeeDetails.compensationType === "annually" && "active"
            }`}
            onClick={() => {
              setPayrateZero();
            }}
          >
            <Icon
              icon={
                employeeDetails.compensationType === "annually"
                  ? "tick"
                  : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Annual Salary
          </button>
          <button
            className={`select-button ${
              employeeDetails.compensationType === "hourly" && "active"
            }`}
            onClick={() => setAnnualSalaryZero()}
          >
            <Icon
              icon={
                employeeDetails.compensationType === "hourly" ? "tick" : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            Hourly Rate
          </button>
        </Box>
      </Box>
      {employeeDetails.compensationType && (
        <Box className="section option-section">
          <div className="section-title">
            {employeeDetails.compensationType === "hourly"
              ? "Hourly"
              : "Annual"}{" "}
            Rate
          </div>
          <div className="section-subtitle">
            Enter the desired pay rate for this employee
          </div>
          {employeeDetails.compensationType === "annually" &&
            employeeDetails.jobRoles.map((jobRole, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                style={{ marginTop: "15px" }}
              >
                <div className="role-title">
                  {jobRole.title}
                  {index === 0 ? (
                    <span className="primary-role-badge">Primary</span>
                  ) : null}
                </div>
                <div className="input-container">
                  <div className="input-label">Pay Rate</div>
                  <span className="input-currencyprefix">$</span>
                  <input
                    value={jobRole.annualSalary / 100}
                    className="employeeinputspayrate"
                    type="number"
                    onChange={(e) =>
                      updateEmployeeDetail(
                        "annualSalary",
                        e.target.value,
                        index
                      )
                    }
                  />
                  {Number(jobRole.annualSalary) > 0 && (
                    <img
                      src={require("assets/input-check.svg").default}
                      className="input-check"
                    />
                  )}
                </div>
              </Box>
            ))}

          {employeeDetails.compensationType === "hourly" &&
            employeeDetails.jobRoles.map((jobRole, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                style={{ marginTop: "15px" }}
              >
                <div className="role-title">
                  {jobRole.title}
                  {index === 0 ? (
                    <span className="primary-role-badge">Primary</span>
                  ) : null}
                </div>
                <div className="input-container">
                  <div className="input-label">Pay Rate</div>
                  <span className="input-currencyprefix">$</span>
                  <input
                    value={jobRole.payRate / 100}
                    type="number"
                    className="employeeinputspayrate"
                    onChange={(e) =>
                      updateEmployeeDetail("payRate", e.target.value, index)
                    }
                  />
                  {jobRole.payRate && Number(jobRole.payRate) > 0 && (
                    <img
                      src={require("assets/input-check.svg").default}
                      className="input-check"
                    />
                  )}
                </div>
              </Box>
            ))}
        </Box>
      )}

      <button
        className="continue-button"
        disabled={!isPayRatefilled()}
        style={{ backgroundColor: isPayRatefilled() ? "#4385EF" : "#D0E0FB" }}
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(4);
        }}
      >
        Continue
      </button>
    </Box>
  );
  const renderEmployeeType = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">New hire or existing employee?</div>
        <div className="section-subtitle">
          Please let us know if this is a new hire or an existing employee
        </div>
        <Box
          className="Employee-type-options"
          display="flex"
          flexDirection="row"
        >
          <button
            className={`${
              employeeDetails.employeeHiringType === "new-hire" && "active"
            } select-button`}
            onClick={() => {
              /*updateEmployeeDetail("employeeHiringType", "new-hire")*/
            }}
          >
            <div className="PrimaryTag">Coming Soon</div>
            <Icon
              icon={
                employeeDetails.employeeHiringType === "new-hire"
                  ? "tick"
                  : "plus"
              }
              size={12}
              color={"#4385EF"}
            />
            This is a new hire
          </button>
          <button
            className={`${
              employeeDetails.employeeHiringType === "existing" && "active"
            } select-button`}
            onClick={() =>
              updateEmployeeDetail("employeeHiringType", "existing")
            }
          >
            <Icon
              icon={
                employeeDetails.employeeHiringType === "existing"
                  ? "tick"
                  : "plus"
              }
              size={12}
              color={"#4385EF"}
            />{" "}
            I’m onboarding an existing employee onto Tango
          </button>
        </Box>
      </Box>
      <button
        className="continue-button"
        disabled={employeeDetails.employeeHiringType !== "existing"}
        onClick={async () => {
          await addTeammateHandler();
          // if (employeeDetails.employeeHiringType === "existing") {
          //   await addTeammateHandler();
          // } else if (employeeDetails.employeeHiringType === "new-hire") {
          //   setSideBarList(sidebarList.concat(sidebarListNewHire));
          //   window.scrollTo(0, 0);
          //   setStep(5);
          // } else {
          //   window.scrollTo(0, 0);
          //   setStep(5);
          // }
        }}
      >
        Continue
      </button>
    </Box>
  );
  const renderEmployeeCheckList = () => (
    <Box display="flex" flex-direction="column">
      {employeeDetails.employeeHiringType === "existing" && (
        <Box className="section option-section">
          <div className="section-title">Things We'll Do</div>
          <div className="section-subtitle">
            We will take care of the following for you
          </div>
          <Box
            className="Employee-type-options"
            display="flex"
            flexDirection="row"
          >
            <button className={`select-button active`}>
              <Icon icon={"tick"} size={12} color={"#4385EF"} />
              Get Brandon Set up on the Tango Staffing App
            </button>
          </Box>
        </Box>
      )}
      {employeeDetails.employeeHiringType === "new-hire" && (
        <>
          <Box className="section option-section">
            <div className="section-title">Documents They Sign</div>
            <div className="section-subtitle">
              We will take care of the following for you
            </div>
            <Box
              className="Employee-type-options column-type"
              display="flex"
              flexDirection="column"
            >
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Offer Letter
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Team Member Sign Off
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                SOP Sign Off
              </button>
            </Box>
          </Box>
          <Box className="section option-section">
            <div className="section-title">Things We’ll Do</div>
            <div className="section-subtitle">
              We will take care of the following for you
            </div>
            <Box
              className="Employee-type-options column-type"
              display="flex"
              flexDirection="column"
            >
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Get Brandon Set up on the Tango Staffing App
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Ensure Brandon takes the mandatory awarness training
              </button>
              <button
                className={`select-button simple`}
                onClick={() => console.log("new-hire")}
              >
                <Icon icon={"plus"} size={12} color={"#4385EF"} />
                Ensure Brandon takes his role based training tests
              </button>
            </Box>
          </Box>
        </>
      )}
      <button
        className="continue-button"
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(6);
        }}
      >
        Done
      </button>
    </Box>
  );
  const renderSelectOfferLetter = () => (
    <Box display="flex" flex-direction="column">
      <Box className="section option-section">
        <div className="section-title">Select an Offer Letter</div>
        <div className="section-subtitle">
          Let us know which type of letter to send
        </div>
        <ul className="offer-letter-lists">
          {[1, 2].map((item, index) => (
            <li className="doc-container">
              <img src={require("assets/pdf-icon.svg").default} />
              <div className="doc-title">General Staff Offer Letter</div>
              <div className="doc-size">2.4 MB Doc File</div>
            </li>
          ))}
          <li className="doc-container create-template">
            <img src={require("assets/create-file-icon.svg").default} />
            <div className="doc-size">Create a Template</div>
          </li>
        </ul>
      </Box>
      <button
        className="continue-button"
        onClick={() => {
          window.scrollTo(0, 0);
          setStep(6);
        }}
      >
        Preview & Send
      </button>
    </Box>
  );
  const renderSuccessAdded = () => {
    return (
      <Box style={{ textAlign: "center" }}>
        <img src={logo} className="successlogo" />
        <p className="successtextheader">success</p>
        <p className="succestext">
          {employeeDetails.firstName + " " + employeeDetails.lastName} has been
          successfully added to the team
        </p>
        <div className="arrowicon">
          <img src={tickArrow} height="23.47px" width="17.87" />
        </div>
      </Box>
    );
  };
  const renderContent = () => {
    if (loading) {
      return <Spinner />;
    } else {
      return (
        <div id="add-employee-page" className="add-employee-page">
          <Box className="container">
            <Box className="header">
              <button className="back-button" onClick={() => navigate(-1)}>
                <Icon icon="arrow-left" size={11} color={"#7C7F93"} />
                <span className="btn-text">Back to Team</span>
              </button>
            </Box>
            <Box className="bodycontent">
              <Box className="sidetab">
                <span className="checklist-header">Hiring Checklist</span>
                <span className="checklist-action">Let’s get started</span>
                <div className="checklistdiv">
                  <ul className="checklist">
                    {sidebarList.map((item) => {
                      const IconComponent = item.icon;
                      return (
                        <li
                          onClick={() => {
                            if (item.index < step) {
                              window.scrollTo(0, 0);
                              setStep(item.index);
                            }
                          }}
                          className={`${
                            item.index < step ? "checkedlistitem" : ""
                          }${item.index === step ? "activelistitem" : ""}`}
                        >
                          {item.index < step && (
                            <Icon
                              icon={"tick"}
                              size={15}
                              color={"#4385EF"}
                              style={{ marginRight: "22px" }}
                            />
                          )}
                          <IconComponent
                            height={15}
                            width={15}
                            color={item.index < step ? "#4385EF" : ""}
                          />
                          <span className="navtext">{item.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Box>
              <Box className="tabcontent">
                <img src={logo} className="logo" />
                <Box>
                  <div className="form-title">Add Employee</div>
                  <div className="form-subtitle">Employee Creation Form</div>
                </Box>
                {step === 1 && renderEmployeeInformation()}
                {step === 2 && renderEmployeeRolesForm()}
                {step === 3 && renderPaymentInformationForm()}
                {step === 4 && renderEmployeeType()}
                {step === 5 && renderEmployeeCheckList()}
                {step === 6 && renderSelectOfferLetter()}
              </Box>
            </Box>
            <FindLocationModal
              isOpen={showLocationModal}
              businesses={[business]}
              onClose={() => setShowLocationModal(false)}
              updateLocation={updateLocation}
              selectedLocations={employeeDetails.locations}
            />
          </Box>
        </div>
      );
    }
  };
  if (!businessSettings) {
    return <></>;
  }
  return <>{successPage ? renderSuccessAdded() : renderContent()}</>;
};

export default AddEmployee;
