// @ts-nocheck
import { EditableText } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";

import { DocumentData } from "../../../../types/document";
import { ModifierOptions } from "../../../../types/modifiers";
import ModifierOptionsTable from "./ModifierOptionsTable";

interface ModalProps {
  showModal: boolean;
  closeModal: (index: number | null) => void;
  data: DocumentData;
  setData: (newData: DocumentData) => void;
  options: any;
  additionalInfo: { [T: string]: any };
}

const ModifierModal = ({
  showModal,
  closeModal,
  data,
  setData,
  options,
  additionalInfo,
}: ModalProps) => {
  const [record, setRecord] = useState<DocumentData | null>(data);
  const [editText, setEditText] = useState(null);
  const [tempText, setTempText] = useState("");
  const [errors, setErrors] = useState<string[]>([]);
  const modalRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, []);
  const handleClick = (e: any) => {
    if (
      e.target.className ===
        "bp3-overlay-backdrop bp3-overlay-exit bp3-overlay-exit-active" ||
      e.target.className === "modal-body" ||
      e.target.className === "bp3-button get-fixed-button" ||
      e.target.className === "bp3-button-text"
    ) {
      return;
    } else {
      if (modalRef) {
        if (modalRef.current.contains(e.target)) {
          return;
        }
      }
      // this click is outside
      closeModal(null);
    }
  };

  const findErrors = () => {
    const newErrors = [];
    const reg = new RegExp("^[0-9]+$");
    if (!reg.test(record.min)) {
      newErrors.push("min");
    }
    if (!reg.test(record.max)) {
      newErrors.push("max");
    }
    setErrors(newErrors);
    return newErrors;
  };

  useEffect(() => {
    setRecord(data);
    console.log("RECORD: ", data);
  }, [data]);
  return (
    <div
      className="modal table-product-modal"
      style={{ display: showModal ? "grid" : "none" }}
    >
      <div className="modal-content" ref={modalRef}>
        <span
          className="close"
          onClick={() => {
            const errors = findErrors();
            if (errors.length === 0) {
              closeModal(null);
              if (record) {
                setData({
                  ...data,
                  nameExternal: record.nameExternal,
                  min: parseInt(record.min),
                  max: parseInt(record.max),
                  description: record.description,
                });
              }
            }
          }}
        >
          &times;
        </span>

        {record && data && (
          <div>
            <div className="title">{record.nameExternal}</div>
            <div className="details">
              <div className="detail-row">
                <div>Message to Customers</div>
                {editText === "nameExternal" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        [editText]: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className="active"
                    onClick={() => {
                      setEditText("nameExternal");
                      setTempText(record.nameExternal);
                    }}
                  >
                    {record.nameExternal}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Min Required</div>
                {editText === "min" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        [editText]: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className="active"
                    onClick={() => {
                      setEditText("min");
                      setTempText(record.min);
                    }}
                    style={{
                      backgroundColor: errors.includes("min")
                        ? "rgba(255, 119, 119, 0.2)"
                        : "#ffffff",
                    }}
                  >
                    {record.min}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Max Allowed</div>
                {editText === "max" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        [editText]: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className="active"
                    onClick={() => {
                      setEditText("max");
                      setTempText(record.max);
                    }}
                    style={{
                      backgroundColor: errors.includes("max")
                        ? "rgba(255, 119, 119, 0.2)"
                        : "#ffffff",
                    }}
                  >
                    {record.max}
                  </div>
                )}
              </div>
              <div className="detail-row">
                <div>Modifier Description</div>
                {editText === "description" ? (
                  <EditableText
                    value={tempText}
                    confirmOnEnterKey={true}
                    onChange={(newText) => setTempText(newText)}
                    onConfirm={(text) => {
                      setRecord({
                        ...record,
                        [editText]: text,
                      });
                      setTempText("");
                      setEditText(null);
                    }}
                  />
                ) : (
                  <div
                    className="active"
                    onClick={() => {
                      setEditText("description");
                      setTempText(record.description);
                    }}
                  >
                    {record.description}
                  </div>
                )}
              </div>
            </div>
            {/*
            <div className="details">
              <div className="detail-row">
                <div>Modifier Options</div>
                <div></div>
              </div>
              <ModifierOptionsTable
                modifier={data}
                // modifierOptions={data.options}
                businessId={additionalInfo.businessId}
                // allModifierOptions={additionalInfo.allModifierOptions}
                // setAllModifierOptions={additionalInfo.setAllModifierOptions}
                // modifiers={additionalInfo.modifiers}
                setModifierOptions={(newOptions: ModifierOptions[]) => {
                  setData({
                    ...data,
                    options: newOptions,
                    nameExternal: record.nameExternal,
                    min: parseInt(record.min),
                    max: parseInt(record.max),
                    description: record.description,
                  });
                }}
              />
            </div>
              */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifierModal;
