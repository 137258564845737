import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VisaImage from 'assets/payments/visa.png';
import AmericanExpress from 'assets/payments/american-express.png';
import MasterCard from 'assets/payments/master-card.png';
import CashIcon from 'assets/payments/cash.svg';
import UIComponents from '@tangopay/tango-ui-library';
import { CustomerModel } from './ClosedChecks/useClosedChecks';
import { calculateCompletedCheckTotal, calculateRefundsInTab } from './utils';
import { useSelector } from "react-redux";
import { RootState } from 'model/store';
const { Button, Icon } = UIComponents;

interface PaymentsProps {
    closedCheck: any,
    setShowRefundPaymentModal: (success: boolean) => void;
    setSelectedPaymentToRefund: any;
    setRefundSuccess: (success: boolean) => void;
    refundSuccess: boolean;
    allowRefund: boolean;
}

export interface FormattedPayment {
    businessId: string;
    type: string;
    cardNumber: string;
    amount: number;
    status: string;
    isExpanded: boolean;
    customerDetail: {
        name: string;
        phoneNumer: string;
        email: string;
        address: string;
        tabId: string;
        customerIndexInTab: number;
    },
    paymentDetail: {
        id: string;
        number: string;
        fingerprint: string;
        expires: string;
        type: string;
        issuer: string;
        owner: string;
        ownerEmail: string;
        address: string;
        currency: string;
        paymentIntentId: string;
        refund: any;
    }
}



const Payments = ({ closedCheck, setShowRefundPaymentModal, setSelectedPaymentToRefund, setRefundSuccess, refundSuccess, allowRefund }: PaymentsProps) => {
    const business: TangoBusiness = useSelector(
        (state: RootState) => state.business as TangoBusiness
    );
    const fullyRefunded = calculateRefundsInTab(closedCheck) >= calculateCompletedCheckTotal(closedCheck.customer)
    const partiallyRefunded = calculateRefundsInTab(closedCheck) > 0 && !fullyRefunded
    const getImage = useCallback((type: string) => {
        if (type === 'visa') {
            return VisaImage;
        }
        else if (type === 'master-card') {
            return MasterCard;
        }
        else if (type === 'mastercard') {
            return MasterCard;
        }
        else if (type === 'cash') {
            return CashIcon;
        }
        else {
            return AmericanExpress;
        }
    }, [])

    //some completed payments are returning empty totals - probably just test orders with bad data
    //filter for customer with amount > 0
    const formattedPayments: FormattedPayment[] = closedCheck.customer.filter((customer: CustomerModel) => customer.amount.netTotal > 0).map((customer: CustomerModel, index: number) => {
        if (customer.payment.type === 'cash' || customer.payment.type === 'external') {
            return {
                businessId: closedCheck.businessId,
                type: 'cash',
                cardNumber: '',
                amount: customer.payment.successfulCharge.amount,
                status: "captured", //should always be captured if tab is completed (ie check is closed)
                isExpanded: closedCheck.customer.length === 1 ? true : false,
                customerDetail: {
                    name: customer.firstName + ' ' + customer.lastName,
                    phoneNumer: customer.phone,
                    email: customer.email,
                    address: '',//don't collect real address - could use delivery address?
                    tabId: closedCheck.id,
                    customerIndexInTab: index,
                },
                paymentDetail: {
                    id: 'cash-' + index + '-' + closedCheck.id,
                    number: '',
                    fingerprint: '',
                    expires: '',//we don't collect card expiration but could probably fetch from stripe
                    type: 'cash',
                    issuer: '',
                    owner: '',
                    ownerEmail: customer.email,
                    address: '',
                    currency: business && business.currency,
                    paymentIntentId: '',
                    refund: customer.amount.refundPayload
                }
            }

        } else {

            return {
                businessId: closedCheck.businessId,
                type: customer.payment.paymentType.brand,
                cardNumber: customer.payment.paymentType.lastFour,
                amount: customer.payment.successfulCharge.amount,
                status: "captured", //should always be captured if tab is completed (ie check is closed)
                isExpanded: closedCheck.customer.length === 1 ? true : false,
                customerDetail: {
                    name: customer.firstName + ' ' + customer.lastName,
                    phoneNumer: customer.phone,
                    email: customer.email,
                    address: '',//don't collect real address - could use delivery address?
                    tabId: closedCheck.id,
                    customerIndexInTab: index,
                },
                paymentDetail: {
                    id: customer.payment.successfulCharge.id,
                    number: customer.payment.paymentType.lastFour,
                    fingerprint: customer.payment.paymentType.fingerprint,
                    expires: '',//we don't collect card expiration but could probably fetch from stripe
                    type: customer.payment.paymentType.funding,
                    issuer: customer.payment.paymentType.brand,
                    owner: customer.payment.paymentType.cardholderName,
                    ownerEmail: customer.email,
                    address: '',
                    currency: business && business.currency,
                    paymentIntentId: customer.payment.paymentType.stripeId,
                    refund: customer.amount.refundPayload
                }
            }

        }
    })

    const [payments, setPayments] = useState(formattedPayments);

    const updateData = (indexClicked: number) => {
        const newData = [...payments].map((item, paymentIndex: number) => {
            if (paymentIndex === indexClicked) {
                return { ...item, isExpanded: !item.isExpanded }
            }
            return { ...item, isExpanded: false }
        })
        setPayments(newData)
    }

    useEffect(() => {
        if (refundSuccess) {
            setPayments(formattedPayments)//reset payment with any new refunds
            setRefundSuccess(false)
        }
    }, [refundSuccess])

    return (
        <div>
            <div className='font-lato-black text-2xl text-black mb-5'>Payments</div>

            <ul className='p-0 m-0 list-none'>
                {payments.map((item: FormattedPayment, index: number) => (
                    <li onClick={() => updateData(index)} key={index} className='border cursor-pointer border-grey-1 border-solid rounded-2xl mb-3 overflow-hidden'>
                        <div className='flex items-center justify-between py-8 px-6'>
                            <div className='flex items-center'>
                                <img src={getImage(item.type)} className="mr-3 w-7" />
                                <div className='font-lato-black text-black text-base'>{item.cardNumber}</div>
                                <div className='w-px h-8 bg-grey-1 mx-6' />
                                <div className='font-lato-black text-black text-base'>${(item.amount * 0.01).toFixed(2)}</div>
                                <div className='ml-10 py-1 px-4 rounded-2xl bg-green-2 border-green-3 text-xs font-lato-bold text-black border-solid border'>
                                    Succeeded
                                </div>
                            </div>

                            {partiallyRefunded &&
                                <div className='flex items-center'>
                                    <div className='ml-10 py-1 px-4 rounded-2xl bg-purple-2 border-purple-3 text-xs font-lato-bold text-black border-solid border'>
                                        Partially Refunded
                                    </div>
                                </div>
                            }

                            {fullyRefunded &&
                                <div className='flex items-center'>
                                    <div className='ml-10 py-1 px-4 rounded-2xl bg-purple-2 border-purple-3 text-xs font-lato-bold text-black border-solid border'>
                                        Fully Refunded
                                    </div>
                                </div>
                            }

                            {allowRefund && !fullyRefunded &&
                                <div className='flex items-center'>
                                    <Button
                                        disabled={fullyRefunded}
                                        label='Refund'
                                        size="btn-small"
                                        type="btn-style-2"
                                        onClick={() => {
                                            setSelectedPaymentToRefund(item)
                                            setShowRefundPaymentModal(true)
                                        }}
                                    />
                                    <Icon name="chevron-right" size='24' className='ml-6' />
                                </div>
                            }

                        </div>
                        {
                            item.isExpanded ?
                                <div>
                                    <div className='flex justify-between items-center border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                        <span className="text-xs font-lato-bold text-grey-2 px-4 py-2 mt-7 flex items-center justify-start">
                                            Customer Contact
                                        </span>
                                        <span
                                            onClick={() => { console.log('view customer') }}
                                            style={{ paddingRight: '2%' }} className="text-blue text-xs mt-7 flex items-center justify-end">
                                            View Customer Profile
                                        </span>
                                    </div>

                                    <ul className='p-0 m-0 list-none'>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Name</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.customerDetail.name}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Phone Number</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.customerDetail.phoneNumer}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Email</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.customerDetail.email}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Address</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.customerDetail.address}</div>
                                        </li>
                                    </ul>
                                    <div className='text-xs font-lato-bold text-grey-2 px-4 py-2 border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>Payment Method</div>
                                    <ul className='p-0 m-0 list-none'>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>ID</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.id}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Number</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.number}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Fingerprint</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.fingerprint}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Expires</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.expires}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Type</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.type}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Issuer</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.issuer}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Owner</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.owner}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Owner Email</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.ownerEmail}</div>
                                        </li>
                                        <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                            <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Address</div>
                                            <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>{item.paymentDetail.address}</div>
                                        </li>
                                    </ul>

                                    {(fullyRefunded || partiallyRefunded) &&
                                        <>
                                            <div className='text-xs font-lato-bold text-grey-2 px-4 py-2 border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>
                                                Refund Details
                                            </div>
                                            <ul className='p-0 m-0 list-none'>
                                                <li className='flex border-t border-solid border-grey-1 border-l-0 border-r-0 border-b-0'>

                                                    {fullyRefunded &&
                                                        <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Full Refund</div>
                                                    }
                                                    {partiallyRefunded &&
                                                        <div className='text-xs w-3/12 text-black font-lato-black bg-blue-grey-2 py-1 px-6'>Partial Refund</div>
                                                    }
                                                    {item.paymentDetail.refund &&
                                                        <div className='text-xs w-9/12 text-black font-lato-regular py-1 px-6'>${(item.paymentDetail.refund.refundAmount * 0.01).toFixed(2)}</div>
                                                    }
                                                </li>
                                            </ul>
                                        </>
                                    }
                                </div>
                                :
                                null
                        }

                    </li>
                ))
                }
            </ul>
        </div>
    )
};
export default Payments;