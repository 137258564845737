import { useState } from "react";
import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import { ColumnInstruction } from "components/Table/HorizontalTable";
import { TangoTab, useCompletedTabsAndGiftCardPurchases, TabWithStaff } from "controllers/payments";
import { useSelector } from "react-redux";
import { RootState } from "model/store";
import { CustomerModel } from "../ClosedChecks/useClosedChecks";
import { getTimestampCharged, getPaymentStatus } from './utils'


type CompletedPaymentProps = {
    uniqueId: string;
    date: any;
    amount: number;
    status: string,
    description: string,
    customerName: string,
    last4: string,
    checkNumber: string | number;
    completedCheck: TangoTab;
    staffMember: any;
};


const columns: ColumnInstruction<CompletedPaymentProps>[] = [
    { type: "data", header: "Date", attribute: "date" },
    { type: "data", header: "Total Amount", attribute: "amount" },
    { type: "projection", header: "Status", attribute: "status" },
    { type: "data", header: "Customer", attribute: "customerName" },
    { type: "data", header: "Last 4", attribute: "last4" },
    { type: "data", header: "Check Number", attribute: "checkNumber" },
    { type: "data", header: "Description", attribute: "description" },
];

const instructions: { [x: string]: RenderInstruction<CompletedPaymentProps> } = {};

// instructions.date = {
//     type: "complex-custom",
//     viewComponent: ({ fullObject }) => {
//         console.log('timestamp ', fullObject)
//         return <div>
//             {moment(fullObject.date).format('MM/DD/YYYY h:mm A')}
//         </div>
//     },
//     editComponent: null
// }

instructions.amount = {
    type: "currency",
}

instructions.status = {
    type: "complex-custom",
    viewComponent: ({ fullObject }) => {
        if (fullObject.status === 'Succeeded') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl  bg-green-2 border-green-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        if (fullObject.status === 'Fully Refunded' || fullObject.status === 'Partially Refunded') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-purple-2 border-purple-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        if (fullObject.status === 'Need Response') {
            return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-yellow-2 border-yellow-3 text-xs font-lato-bold text-black border-solid border'>
                {fullObject.status}
            </div>
            )
        }
        return (<div style={{ width: "fit-content" }} className='py-1 px-4 rounded-2xl bg-error-red bg-error-red-hover text-xs font-lato-bold text-white border-solid border'>
            {fullObject.status}
        </div>
        )
    },
    editComponent: null,
}

const usePaymentScreen = () => {
    const businessId: string = useSelector(
        (state: RootState) => state.business as TangoBusiness
    )?.id;
    const noOp = () => Promise.resolve(true);
    const logIt = (args: string[]) => console.log(args);
    const [isEditing, setEditing] = useState(false);
    const [lastTabId, setLastTabId] = useState('start');
    const query = useCompletedTabsAndGiftCardPurchases(businessId, lastTabId);
    const completedChecksWithGiftCards = query.data ?? []; //query will return null if no business id has propogated 
    const data: CompletedPaymentProps[] = []
    completedChecksWithGiftCards.length > 0 && completedChecksWithGiftCards.forEach((completedCheck: TabWithStaff) => {
        completedCheck.tab.customer.forEach((customer: CustomerModel, index: number) => {
            if (customer.payment.paymentType) {
                const completedPayment: CompletedPaymentProps = {
                    uniqueId: customer.payment.paymentType.type == 'cash' ? completedCheck.tab.id + '-cash-' + index : customer.payment.paymentType.chargeId,
                    date: getTimestampCharged(customer.payment.successfulCharge.timestampCharged, completedCheck.tab.updatedAt, customer.payment.paymentType.created),
                    amount: customer.payment.paymentType.amount,
                    status: getPaymentStatus(customer.amount, customer.payment.paymentType.amount),
                    description: customer.payment.paymentType.type == 'cash' ? 'Cash Payment' :
                        completedCheck.tab.customer[0] && completedCheck.tab.customer[0].orderChannel === 'mobile' ? 'Online Order' : 'Terminal Payment',
                    customerName: customer.firstName ? customer.firstName + ' ' + customer.lastName : 'Guest',
                    last4: customer.payment.paymentType.type == 'cash' ? 'Cash' : customer.payment.paymentType.lastFour,
                    completedCheck: completedCheck.tab,
                    checkNumber: completedCheck.tab.tabNumber,
                    staffMember: completedCheck.staffMember
                }
                data.push(completedPayment)
            }
        })
    })

    return {
        data,
        noOp,
        logIt,
        columns,
        instructions,
        isEditing,
        setEditing,
        lastTabId,
        setLastTabId
    };
};
export default usePaymentScreen;
