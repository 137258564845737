import moment from "moment-timezone";
import React from "react";

import Box from "components/Box";

import { SmallSlotCard } from "../SlotCard";

export type AvailabilityForADay = {
  staffId: string;
  id: string;
  available: boolean;
  startTime: string;
  endTime: string;
  staffFullName: string;
  staffMainRoleTitle: string;
  day: string;
};

interface AvailabilityForStaffMemberProps {
  staffId: string;
  availabilitiesForADay: AvailabilityForADay[];
  day: Date;
}

const AvailabilityForStaffMember = ({
  staffId,
  availabilitiesForADay,
  day,
}: AvailabilityForStaffMemberProps) => {
  const staffAvailability = availabilitiesForADay.find(
    (a) => a.staffId === staffId
  );

  if (staffAvailability) {
    return (
      <Box className="grid-cell" key={staffAvailability.id}>
        <SmallSlotCard
          upperSubtitle={staffAvailability.staffMainRoleTitle}
          subtitle={`${moment(staffAvailability?.startTime, "HH:mm").format(
            "hh:mm a"
          )} - ${moment(staffAvailability?.endTime, "HH:mm").format(
            "hh:mm a"
          )}`}
          title={staffAvailability.staffFullName}
          mainColor="#61C554"
          backgroundColor="#EFF9EE"
          onWeeklyScheduleClick={() => null}
          shiftTypeName=""
        />
      </Box>
    );
  } else {
    return (
      <Box
        className="grid-cell"
        key={`unavailable_staff_${moment(day).format()}`}
      />
    );
  }
};

interface AvailabilitiesForStaffMemberForADayProps {
  staffId: string;
  day: Date;
  generateAvailabilitiesForADay: (d: Date) => AvailabilityForADay[];
}

const AvailabilitiesForStaffMemberForADay = ({
  staffId,
  day,
  generateAvailabilitiesForADay,
}: AvailabilitiesForStaffMemberForADayProps) => {
  const availabilitiesForADay: AvailabilityForADay[] =
    generateAvailabilitiesForADay(day);

  return (
    <AvailabilityForStaffMember
      staffId={staffId}
      availabilitiesForADay={availabilitiesForADay}
      day={day}
    />
  );
};

interface AvailabilitiesContentProps {
  weekRange: Date[];
  filteredStaffMembersAvailability: StaffMember[];
  generateAvailabilitiesForADay: (d: Date) => AvailabilityForADay[];
}

export const AvailabilitiesContent = ({
  weekRange,
  filteredStaffMembersAvailability,
  generateAvailabilitiesForADay,
}: AvailabilitiesContentProps) => {
  return (
    <Box
      style={{
        width: "80vw",
        overflowY: "scroll",
        overflowX: "scroll",
        height: "93vh",
      }}
    >
      <Box
        display="flex"
        className="staff-member-view"
        style={{
          minWidth: 1500,
          width: "100%",
          overflowX: "scroll",
        }}
      >
        <Box className="day-column">
          <Box className="day-header"></Box>
        </Box>
        {weekRange.map((item: any, index: number) => (
          <Box className="day-column" key={new Date(item).toString()}>
            <Box className="day-header">
              <div className="day-name">{moment(item).format("ddd")}</div>
              <div className="day-date">{moment(item).format("D")}</div>
            </Box>
            {/* {renderAvailabilitiesForADay(item)} */}
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        className="staff-member-view"
        style={{
          minWidth: 1500,
          width: "100%",
          overflowX: "scroll",
        }}
      ></Box>
      <Box
        display="flex"
        className="staff-member-view"
        style={{
          minWidth: 1500,
          width: "100%",
          overflowX: "scroll",
        }}
      >
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {filteredStaffMembersAvailability.map((staffMember) => {
            return (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Box className="day-column">
                  <Box
                    className="grid-cell"
                    key={`availability_staff_name_${staffMember}`}
                  >
                    <div
                      style={{
                        color: "#454545",
                        fontSize: "0.775rem",
                        fontFamily: "SFProText-Semibold",
                      }}
                    >
                      {staffMember?.contact.firstName}{" "}
                      {staffMember?.contact.lastName}
                    </div>
                  </Box>
                </Box>
                {weekRange.map((item: Date) => (
                  <Box className="day-column" key={new Date(item).toString()}>
                    <AvailabilitiesForStaffMemberForADay
                      staffId={staffMember.id}
                      day={item}
                      generateAvailabilitiesForADay={
                        generateAvailabilitiesForADay
                      }
                    />
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
