import { CustomerModel } from './ClosedChecks/useClosedChecks';
import { ProductsPaid, TangoTab } from 'controllers/payments';

export const calculateCompletedCheckTotal = (customer: CustomerModel[]) => {
  const paymentAmounts: number[] = [];
  customer.map((customerObject: CustomerModel) => {
    paymentAmounts.push(customerObject.payment.successfulCharge.amount);
  });
  return paymentAmounts.reduce((a: number, b: number) => a + b, 0);
};

export const calculateCompletedCheckTip = (customer: CustomerModel[]) => {
  const tipAmounts: number[] = [];
  customer.map((customerObject: CustomerModel) => {
    tipAmounts.push(customerObject.amount.tip);
  });
  return tipAmounts.reduce((a: number, b: number) => a + b, 0);
};

export const calculateCompletedCheckTax = (customer: CustomerModel[]) => {
  const taxAmounts: number[] = [];
  customer.map((customerObject: CustomerModel) => {
    taxAmounts.push(customerObject.amount.tax);
  });
  return taxAmounts.reduce((a: number, b: number) => a + b, 0);
};

export const calculateSubtotalInOrder = (itemsInCheck: ProductsPaid[]) => {
  const itemTotals = itemsInCheck.map((item: ProductsPaid) => {
    const modifierAdditionalCosts = item.selectedModifiers.map((selectedMod: any) => {
      return selectedMod.additionalCost;
    });
    return (item.price + modifierAdditionalCosts.reduce((a: number, b: number) => a + b, 0)) * item.quantity;
  });
  return Math.round(itemTotals.reduce((a: number, b: number) => a + b, 0));
};

export const calculateItemTotalWithModifiers = (itemInCheck: ProductsPaid) => {
  const paymentAmounts: number[] = [];

  paymentAmounts.push(itemInCheck.quantity * itemInCheck.price);
  itemInCheck.selectedModifiers.map((selectedModifier: any) => {
    paymentAmounts.push(itemInCheck.quantity * selectedModifier.additionalCost);
  });
  return paymentAmounts.reduce((a: number, b: number) => a + b, 0);
};

export const calculateEachValue = (itemInCheckThatHasMods: ProductsPaid) => {
  const paymentAmounts: number[] = [];

  paymentAmounts.push(itemInCheckThatHasMods.price);

  itemInCheckThatHasMods.selectedModifiers.map((selectedModifier: any) => {
    paymentAmounts.push(selectedModifier.additionalCost);
  });
  return paymentAmounts.reduce((a: number, b: number) => a + b, 0);
};

export const calculateRefundsInTab = (completedCheck: TangoTab) => {
  const refundamounts: number[] = [];

  completedCheck.refundPayload &&
    completedCheck.refundPayload.map((refund: any) => {
      refundamounts.push(refund.refundAmount);
    });
  return refundamounts.reduce((a: number, b: number) => a + b, 0);
};

export const getCompletedCheckType = (completedCheck: TangoTab) => {
  if (completedCheck.tableNumber) {
    return 'Dine In';
  }
  //backend will fetch gift card purchases and return a tab with this name
  if (completedCheck.name === 'gift card') {
    return 'Gift Card';
  }
  for (let i = 0; i < completedCheck.customer.length; i++) {
    if (completedCheck.customer[i].orderType === 'delivery') {
      return 'Delivery'; //need to get DSP from order
    }
    if (completedCheck.customer[i].orderChannel === 'mobile') {
      return 'Online Order';
    }
  }

  return 'Carry Out';
};
