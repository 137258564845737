import { createSelector } from "reselect";

import { RootState } from "model/store";

const enterpriseFilesAndFolders = (state: RootState) => state.enterpriseDocs;
const filesAndFolders = (state: RootState) => state.docs;
const user = (state: RootState) => state.user;

export const getAllFilesBasedOnCurrentUser = createSelector(
  enterpriseFilesAndFolders,
  filesAndFolders,
  user,
  (
    enterpriseFiles: FilesAndFolders[],
    files: FilesAndFolders[],
    currentUser: StaffMember
  ) => {
    const anyFiles =
      (files && files.length > 0) ||
      (enterpriseFiles && enterpriseFiles.length > 0);
    if (currentUser && anyFiles) {
      const staffId = currentUser.staffMemberCopyId || "";
      const businessId = currentUser.businessId || "";

      // File access from the Docs collection
      const accessibleFilesAndFolders = files.filter((file) => {
        const accesibleMembers = file.access.map((staff) => staff.staffId);
        const isRootFile = file.isFolder && file.id === file.parentId;

        // Allow files with access and the root file
        return accesibleMembers.includes(staffId) || isRootFile;
      });

      // File access from the EnterpriseDocs collection
      const accessibleEnterpriseFilesAndFolders = enterpriseFiles.filter(
        (file) => {
          const accesibleBusinessMembers = file.businessAccess;
          const isRootFile = file.isFolder && file.id === file.parentId;

          // Allow files with access and the root file
          return (
            !!currentUser.corporateAccess ||
            accesibleBusinessMembers.includes(businessId) ||
            isRootFile
          );
        }
      );

      const rootIndex = accessibleFilesAndFolders.findIndex(
        (file) => file.isFolder && file.id === file.parentId
      );
      const enterpriseRootIndex = accessibleEnterpriseFilesAndFolders.findIndex(
        (file) => file.isFolder && file.id === file.parentId
      );

      const finalEnterpriseFilesAndFolders = [];
      const childrenIdsToAddToRoot = [];
      // const rootId = enterpriseRootIndex !== -1 ? accessibleEnterpriseFilesAndFolders[enterpriseRootIndex].id : null;
      const parentDocReference: { [T: string]: string } = {};

      for (const fileOrFolder of accessibleEnterpriseFilesAndFolders) {
        if (rootIndex !== -1 && enterpriseRootIndex !== -1) {
          // Replace the root with the regular file and folder root
          if (
            fileOrFolder.parentId ===
            accessibleEnterpriseFilesAndFolders[enterpriseRootIndex].parentId
          ) {
            if (fileOrFolder.parentId !== fileOrFolder.id) {
              // Create a reference to be able to get back the original
              // parent ID
              parentDocReference[fileOrFolder.id] = fileOrFolder.parentId;

              childrenIdsToAddToRoot.push(fileOrFolder.id);
              finalEnterpriseFilesAndFolders.push({
                ...fileOrFolder,
                parentId: accessibleFilesAndFolders[rootIndex].parentId,
              });
            }
          } else {
            finalEnterpriseFilesAndFolders.push(fileOrFolder);
          }
        } else {
          finalEnterpriseFilesAndFolders.push(fileOrFolder);
        }
      }

      // Add the children ids to the root
      if (rootIndex !== -1) {
        accessibleFilesAndFolders[rootIndex].childrenIds = [
          ...accessibleFilesAndFolders[rootIndex].childrenIds,
          ...childrenIdsToAddToRoot,
        ];
      }

      return {
        files: [
          ...accessibleFilesAndFolders,
          ...finalEnterpriseFilesAndFolders,
        ],
        parentDocReference,
      };
    }
    return { files: [], parentDocReference: {} };
  }
);

export const getFilesBasedOnCurrentUser = createSelector(
  getAllFilesBasedOnCurrentUser,
  ({ files, parentDocReference }) => {
    return {
      files: files.filter(
        (file) => !file.trashed && !file.deletedConfig && !file.deleted
      ),
    };
  }
);

export const getTrashedFilesBasedOnCurrentUser = createSelector(
  getAllFilesBasedOnCurrentUser,
  ({ files, parentDocReference }) => {
    return {
      files: files
        .filter((file) => file.trashed && file.deletedConfig)
        .map((f) =>
          f.deletedConfig
            ? {
                ...f,
                parentId: f.deletedConfig.parentId,
                absolutePath: f.deletedConfig.absolutePath,
                childrenIds: f.deletedConfig.childrenIds,
              }
            : f
        ),
      parentDocReference,
    };
  }
);
