import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import firebase from "../../../../../../config/firebase";
import { RootState } from "../../../../../../model/store";
import { saveSpreadSheetData } from "../../../../../../models/docs";

interface UseUpdateSpreadsheet {
  sheetId?: string;
}

export const useUpdateSpreadsheet = ({ sheetId }: UseUpdateSpreadsheet) => {
  const db = firebase.firestore();
  const [sheetData, setSpreadSheetData]: any = useState([]);

  const hiddenFileInput: any = React.useRef(null);

  const handleFileClick = useCallback(() => {
    hiddenFileInput.current.click();
  }, [hiddenFileInput]);

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    console.log("file taken");
    importExcelSheet(file);
  }, []);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const importExcelSheet = useCallback(
    (file: any) => {
      const luckyexcel = window.LuckyExcel;
      try {
        luckyexcel.transformExcelToLucky(file, function (exportJson: any) {
          createSheet(exportJson.sheets, exportJson.info.name);
        });
      } catch (error) {
        console.error(error);
      }
    },
    [window]
  );

  const createSheet = useCallback(
    (data: any, title: string) => {
      const luckysheet: any = window.luckysheet;

      if (data.length <= 0) {
        luckysheet.create({
          container: "luckysheet",
          plugins: ["chart"],
          myFolderUrl: "/manager/docs",
        });
      } else {
        luckysheet.create({
          container: "luckysheet",
          plugins: ["chart"],
          data: data,
          myFolderUrl: "/manager/docs",
        });
      }
      const options = {
        success: () => console.log("success name updated"),
      };
      if (title.length > 0) {
        window.luckysheet.setWorkbookName(title, options);
      }
    },
    [window]
  );

  const getAllSheets = useCallback(() => {
    const sheetData: any = window.luckysheet.getAllSheets();
    console.log("sheetData", sheetData);
    const title = window.luckysheet.getWorkbookName();
    if (sheetId) saveSpreadSheetData(sheetData, sheetId, title);
  }, [sheetId, window]);

  const getSpreadSheetData = useCallback(
    async (id: string) => {
      const unsubscribe = db
        .collection("TangoSpreadsheets")
        .where("businessId", "==", business.id)
        .where("id", "==", id)
        .onSnapshot(async (snapshot) => {
          const data: any = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log(data);
          const sheetData =
            data[0]?.data.length <= 0 ? "" : JSON.parse(data[0]?.data);
          await setSpreadSheetData(
            data[0]?.data.length <= 0 ? "" : JSON.parse(data[0]?.data)
          );
          createSheet(sheetData, data[0].title);
        });

      return () => unsubscribe();
    },
    [business]
  );

  useEffect(() => {
    if (business && sheetId) {
      getSpreadSheetData(sheetId);
    }
  }, [sheetId, business]);

  return {
    getAllSheets,
    hiddenFileInput,
    handleFileClick,
    onFileChange,
  };
};
