import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Assign } from "utility-types";

import { PayrollPunchIn } from "controllers/payroll";

import HorizontalTable from "components/Table/HorizontalTable";

import { OptionType } from "./hooks/usePayrollDateConfiguration";
import { usePayrollDetails } from "./hooks/usePayrollDetails";

import BackArrowIcon from "assets/back-arrow.svg";

const { DateRangePickerInput } = tangoComponents;

interface PayrollDetailsTableLeftHeaderContentProps {
  loading: boolean;
  onRefreshClick: () => void;
  title: string;
  viablePayrollReportEdgesData: string[];
  formattedTotalGrossPay: string;
}
interface PayrollDetailsTableRightHeaderContentProps {
  onDateRangePickerChange: (value: string | Date) => void;
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  customDateRangeOptions: OptionType[];
  onCustomDateRangeChange: (value: string) => void;
}
const PayrollDetailsTableRightContent = (props: PayrollDetailsTableRightHeaderContentProps) => {
  return (
    <>

      <DateRangePickerInput
        className={"ml-5"}
        // @ts-ignore
        onSelect={(o: OptionType) => props.onCustomDateRangeChange(o.value)}
        size="small"
        onChange={props.onDateRangePickerChange}
        //@ts-ignore
        value={[props.dateRange.startDate, props.dateRange.endDate]}
        customRangeOptions={props.customDateRangeOptions}
      />

      {/* <TextField
          className="hori-table-search-input"
          value={props.searchValue}
          // @ts-ignore
          onChange={(e) => props.onSearchChange(e.target.value)}
          sizeType="medium"
          placeholder="Search"
        /> */}
    </>
  );
}
const PayrollDetailsTableLeftHeaderContent = (
  props: PayrollDetailsTableLeftHeaderContentProps
) => {
  const navigate = useNavigate();

  const nameArray = props.title.split(/\s+/)

  return (
    <>
      <div className="subheader-left">
        <img
          src={BackArrowIcon}
          className={"back-button"}
          onClick={() => { navigate(-1) }}
        />
        <div className="ml-8 w-9 h-9 bg-yellow-2 rounded-full flex justify-center items-center">
          {nameArray.length >= 2 &&
            <div className="font-lato-regular text-tiny tracking-tighter text-black">{nameArray.length > 0 ? nameArray[0].charAt(0).toUpperCase() : ""} {nameArray.length > 0 ? nameArray[1].charAt(0).toUpperCase() : ""}
            </div>
          }
        </div>
        <div className="font-lato-black text-xl text-black ml-3">{props.title}</div>
        <div className={"h-9 w-px bg-grey-1 mx-5"} />
        <div className="font-lato-medium text-sm text-black">Total Gross pay: {props.formattedTotalGrossPay}</div>
        <div className={"h-9 w-px bg-grey-1 mr-7 ml-5"} />
        <div
          onClick={props.onRefreshClick}
          className={"cursor-pointer text-xs font-lato-bold text-black mr-7"}
        >
          Refresh
        </div>

        {props.loading ? (
          <Spinner size={SpinnerSize.SMALL} className="mr-7" />
        ) : null}
      </div>
    </>
  );
};

const PayrollDetails = () => {
  const {
    columns,
    instructions,
    edit,
    viablePayrollReportEdges,
    dateRange,
    dateRangeChangeHandler,
    saveResultsHandler,
    staffPayrollDetails,
    addRowHandler,
    tableDataWithAddedRows,
    generalLoading,
    deleteRowsHandler,
    customDateRangeOptions,
    onCustomDateRangeChange,
    rowErrors,
    formattedTotalGrossPay,
    onEditToggle
  } = usePayrollDetails();


  return (
    <div style={{ height: "93vh", overflowY: "scroll", marginLeft: "32px", marginRight: "32px" }}>
      <HorizontalTable
        disableEditStateChangeOnSave
        errors={rowErrors}
        disableRightArrow
        title={
          staffPayrollDetails.data?.staffMemberOrientedData?.fullName ?? ""
        }
        columns={columns}
        data={tableDataWithAddedRows}
        instructions={instructions}
        isEditing={edit}
        setEditing={onEditToggle}
        saveResults={saveResultsHandler}
        primaryActionLabel="Add Clock Punch"
        primaryActionHandler={addRowHandler}
        deleteIds={deleteRowsHandler}
        onRowClick={() => null}
        customHeaderLeftContent={
          <PayrollDetailsTableLeftHeaderContent
            formattedTotalGrossPay={formattedTotalGrossPay}
            loading={generalLoading}
            viablePayrollReportEdgesData={viablePayrollReportEdges.data}
            onRefreshClick={staffPayrollDetails.refetch}
            title={
              staffPayrollDetails.data?.staffMemberOrientedData?.fullName ?? ""
            }

          />
        }
        customHeaderRightContent={
          <PayrollDetailsTableRightContent
            customDateRangeOptions={customDateRangeOptions}
            onCustomDateRangeChange={onCustomDateRangeChange}
            //@ts-ignore
            onDateRangePickerChange={dateRangeChangeHandler}
            dateRange={dateRange}
          />
        }
      />
    </div>
  );
};

export default PayrollDetails;
