import { Dialog, InputGroup, TextArea } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import HireImg from "assets/HiringIcon.svg";

import firebase from "../../../../../config/firebase";
import { RootState } from "../../../../../model/store";
import "./index.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  boardProperties: any;
  setBoardProperties: any;
  setShowTicketPropertyModal: any;
}

export function CreateBoardModal({
  isOpen,
  closeModal,
  boardProperties,
  setBoardProperties,
  setShowTicketPropertyModal,
}: Props) {
  const db = firebase.firestore();

  let colorData = [
    "#333333",
    "#828282",
    "#E0E0E0",
    "#EB5757",
    "#F2994A",
    "#219653",
    "#27AE60",
    "#6FCF97",
    "#43EFB1",
    "#2F80ED",
  ];
  // let colorData = ["1","2","3"];
  const [boardColor, setBoardColor] = useState(colorData);
  const [selectedIcons, setSelectedIcons] = useState<string | null | "">("");
  const [selectedBoardColor, setSelectedBoardColor] = useState("#333333");

  const user: StaffMember = useSelector((state: RootState) => state.user);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const BoardIcons: any =
    useSelector((state: RootState) => state.boards.boardIcons) || [];

  // const [switches, setSwitches] = useState({
  //   emailSwitch: false,
  //   Safetytraing: false,
  //   createEmployee: false,
  // });

  // const handleSwithChange = (key: any, e: any) => {
  //   setSwitches({ ...switches, [key]: e.target.checked });
  // };

  const handleChange = (key: string, value: any) => {
    setBoardProperties({
      ...boardProperties,
      [key]: value,
    });
  };

  const handleIcons = (icon: string) => {
    setSelectedIcons(icon);
  };

  const validateBoard = () => {
    let name = boardProperties.name;
    let color = boardProperties.color;
    let description = boardProperties.description;

    if (name.length > 2 && color.length > 0 && description.length > 2) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Dialog isOpen={isOpen} className="boardModal" onClose={closeModal}>
        <div className="modal-header">
          <div className="modal-title">
            <div className="row">
              <div>Create new board</div>
              <div className="align-right col-6">
                <img src={HireImg} />
                <label className="hireText">Hiring</label>
              </div>
            </div>
          </div>
          {/* <span
           className="closebtn"
           // onClick={() => {
           //   clearState();
           //   closeModal();
           // }}
           >
           <img src={Closeicon} style={{ height: 12.83 }} />
           </span> */}
        </div>

        <div className="modal-body">
          <div>
            <label className="inputTitle">Board Name</label>
            <InputGroup
              type="text"
              placeholder="Hiring"
              className="TextBox"
              value={boardProperties.name}
              onChange={(e) => handleChange("name", e.target.value)}
              dir="auto"
            />
          </div>
          <div>
            <label className="inputTitle">Board Description</label>
            <TextArea
              dir="auto"
              placeholder="Enter short description of the use case of the board"
              className="textArea"
              value={boardProperties.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </div>
          <div>
            <label className="inputTitle">Board Colour</label>
            <ul>
              {boardColor.map((color, key) => {
                return (
                  <li key={key}>
                    <div
                      style={{ backgroundColor: `${color}` }}
                      className={`color-pellete ${
                        boardProperties.color === color ? "active-color" : ""
                      } `}
                      onClick={(event) => {
                        handleChange("color", color);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div>
            <label className="inputTitle">Board Icon</label>
            <ul>
              {BoardIcons.map((loadBoardIcon: any, key: any) => {
                return (
                  <li key={key}>
                    <div
                      className={`icons ${
                        boardProperties?.Icon === loadBoardIcon
                          ? "active-icon"
                          : ""
                      } `}
                      onClick={() => {
                        handleChange("Icon", loadBoardIcon);
                      }}
                    >
                      <img src={loadBoardIcon} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="modal-footer">
          {/* <span className="color-blue pointer" onClick={() => closeModal()}>
           Cancel
           </span> */}
          <button
            className="trigger-btn"
            disabled={validateBoard()}
            onClick={() => {
              closeModal();
              setShowTicketPropertyModal(true);
            }}
          >
            Next
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default CreateBoardModal;

{
  /* <div className="action-div-row">
   <Switch
   checked={switches.emailSwitch}
   onChange={(e) => handleSwithChange("emailSwitch", e)}
   innerLabel={"No"}
   innerLabelChecked={"Yes"}
   />

   <div className="action-div-container">
   <Icon icon="arrow-right" size={24} />
   Send
   <Select
   classNamePrefix="autoWorkflow-modal"
   placeholder={"Application Received"}
   //   options={listData}
   //   onChange={(e: any) => setSelectedItems(e)}
   />
   email template select
   <Select
   classNamePrefix="autoWorkflow-modal-emailselect"
   placeholder={"to applicant"}
   //   options={listData}
   //   onChange={(e: any) => setSelectedItems(e)}
   />
   </div> */
}
{
  /* <textarea
   className="note-input"
   placeholder="Type note here.."
   dir="auto"
   //   onChange={(e) => setNotesTxt(e.target.value)}
   />
   </div>
   <div className="action-div-row">
   <Switch
   checked={switches.Safetytraing}
   onChange={(e) => handleSwithChange("Safetytraing", e)}
   innerLabel={"No"}
   innerLabelChecked={"Yes"}
   />

   <div className="action-div-container">
   <Icon icon="arrow-right" size={24} />
   Assign
   <Select
   classNamePrefix="autoWorkflow-modal"
   placeholder={"Application Received"}
   //   options={listData}
   //   onChange={(e: any) => setSelectedItems(e)}
   />
   training
   </div>
   {/* <textarea
   className="note-input"
   placeholder="Type note here.."
   dir="auto"
   //   onChange={(e) => setNotesTxt(e.target.value)}
   />
   </div>
   {/* <div className="action-div-row">
   <Switch
   checked={switches.createEmployee}
   onChange={(e) => handleSwithChange("createEmployee", e)}
   innerLabel={"No"}
   innerLabelChecked={"Yes"}
   />

   <div className="action-div-container">
   <Icon icon="arrow-right" size={24} />
   <span>
   Create new employee (
   <span className="color-blue-3">Autofill from contract</span>)
   </span>
   </div>
   {/* <textarea
   className="note-input"
   placeholder="Type note here.."
   dir="auto"
   //   onChange={(e) => setNotesTxt(e.target.value)}
   />
   </div> */
}
