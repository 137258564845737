import { useCallback, useState } from "react";

/**
 * Convenience hook for creating a boolean state variable and callbacks
 * for editing it.
 * @param init the initial value of the flag
 * @returns [
 *      the flag's value,
 *      callback to set the flag to true,
 *      callback to set the flag to false,
 *      callback to toggle the flag,
 *      callback to set the flag's value directly
 * ]
 */
const useFlag = (
  init: boolean
): [boolean, () => void, () => void, () => void, (newVal: boolean) => void] => {
  const [flag, setFlag] = useState(init);
  const setOn = useCallback(() => setFlag(true), []);
  const setOff = useCallback(() => setFlag(false), []);
  const toggle = useCallback(() => setFlag((curr) => !curr), []);
  return [flag, setOn, setOff, toggle, setFlag];
};

export default useFlag;
