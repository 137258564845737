//@ts-ignore
import React from "react";
import {
  EdgeProps,
  getMarkerEnd,
  getSimpleBezierPath,
} from "react-flow-renderer";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}: EdgeProps) {
  const edgePath = getSimpleBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  console.log(edgePath);
  return (
    <g>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        className="VLD_EDGE VLD_EDGE_SELECTED"
        id="01FNY7Q2ERSEZ8MZSDCFJK3Z46-de7d4719-6138-407d-b050-fff145e04456"
        d="M336,112L341.3333333333333,112C346.6666666666667,112,357.3333333333333,112,362.6666666666667,112C368,112,368,112,376.3333333333333,112C384.6666666666667,112,401.3333333333333,112,418,128C434.6666666666667,144,451.3333333333333,176,510.3333333333333,192C569.3333333333334,208,670.6666666666666,208,737.3333333333334,192C804,176,836,144,857.3333333333334,128C878.6666666666666,112,889.3333333333334,112,894.6666666666666,112L900,112"
        marker-end="url(#typeform_vld_marker_arrow)"
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: "12px" }}
          startOffset="50%"
          textAnchor="middle"
        >
          {data.text}
        </textPath>
      </text>
    </g>
  );
}
