import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import "./index.css";

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogHeaderTitle: string;
  handleYes: () => void;
}

const DeleteConfirmationDialog = ({
  isOpen,
  setIsOpen,
  dialogHeaderTitle,
  handleYes,
}: DialogProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="dialog_header_title">{dialogHeaderTitle}</div>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="dialog_body"
          >
            <button
              type="button"
              className="dialog_no_btn"
              onClick={handleClose}
            >
              No
            </button>
            <button
              type="button"
              className="dialog_yes_btn"
              onClick={handleYes}
            >
              Yes, delete
            </button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmationDialog;
