import firebase from "../config/firebase";

const giftCardCollection = firebase.firestore().collection("GiftCards");

export interface GiftCard {
  id: string;
  businessId: string;
  imageUrl: string;
  themeName: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  deleted: boolean;
  enabled: boolean;
  isPhysicalCard: boolean;
}

export const createOrEditGiftCard = async (
  card: Partial<GiftCard>,
  edit?: boolean
) => {
  try {
    if (edit) {
      const dataToUpdate = {
        imageUrl: card.imageUrl,
        themeName: card.themeName,
        enabled: card.enabled,
        isPhysicalCard: card.isPhysicalCard,
        updatedAt: firebase.firestore.Timestamp.now(),
      };
      await giftCardCollection.doc(card.id).update(dataToUpdate);
      return { data: { ...card, dataToUpdate } };
    }
    const docRef = giftCardCollection.doc();
    const cardData = { ...card, id: docRef.id };
    await giftCardCollection.doc(docRef.id).set(cardData);
    return { data: cardData };
  } catch (error) {
    //@ts-ignore
    return { error: error.message };
  }
};
export const fetchGiftCardsByBusinessId = async (businessId: string) => {
  try {
    const querySnapShot = await giftCardCollection
      .where("businessId", "==", businessId)
      .get();
    const cards = querySnapShot.docs.map((doc) => doc.data() as GiftCard);
    return { data: cards };
  } catch (error) {
    //@ts-ignore
    return { error: error.message };
  }
};

export const deleteGiftCard = async (cardId: string) => {
  try {
    await giftCardCollection.doc(cardId).delete();
    return { data: cardId };
  } catch (error) {
    //@ts-ignore
    return { error: error.message };
  }
};
