import axios from "axios";
import firebase from "config/firebase";

import { apiCall, baseUrl } from "./core";
import { generateBearerToken } from "./init";

// const signUpData = {
//     businessName,
//     businessType,
//     businessLocation,
//     businessTimezone,
//     businessPhone,
//     deliverect,
//     deliverectChannelLinkId,
//     deliverectAccountId,
//     firstName,
//     lastName,
//     email,
//     password,
//     canada
// };

type DefaultResult = {
  success: boolean;
  error?: string;
};

const tangoAuthApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/auth`,
});

const tangoStaffApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/staff`,
});

tangoStaffApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

tangoAuthApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

export const sendTestCampaignEmail = async (
  toEmail: string,
  senderName: string,
  senderEmail: string,
  subjectLine: string,
  templateId: string
) => {
  await tangoStaffApiClient.post("send-test-campaign-emails", {
    toEmail,
    senderName,
    senderEmail,
    subjectLine,
    templateId,
  });
};

export const sendCampaignEmails = async (id: string) => {
  await tangoStaffApiClient.get(`/send-campaign-emails/${id}`);
};

export const addTeammate = async (
  businessId: string,
  phoneNumber: string,
  email: string | null,
  isAdmin: boolean,
  firstName: string,
  lastName: string,
  primaryRole: string,
  secondaryRoles: string[] = [],
  payRates: PayRate[] = [],
  annualSalary: number,
  typeOfEmployee: "" | "full-time" | "part-time" | "intern",
  accountId: string | null,
  levelId: string | null,
  departmentId: string | null,
  managers: string[] | null,
  idOfTheGroups: string[] | null
) => {
  console.log({ idOfTheGroups });
  await tangoStaffApiClient.post("/add-teammate", {
    businessId,
    phoneNumber,
    email,
    isAdmin,
    firstName,
    lastName,
    primaryRole,
    secondaryRoles,
    payRates,
    annualSalary,
    typeOfEmployee,
    accountId,
    levelId,
    departmentId,
    parentStaffIds: managers,
    idOfTheGroups,
  });
};

export const updateBasicInfo = async (
  businessId: string,
  firstName: string,
  lastName: string,
  typeOfEmployee: "" | "full-time" | "part-time" | "intern",
  staffUID: string
) => {
  await tangoStaffApiClient.put("/update-basic-information", {
    businessId,
    firstName,
    lastName,
    typeOfEmployee,
    staffUID,
  });
};

export const updateEmployeeRoles = async (
  businessId: string,
  primaryRole: string,
  secondaryRoles: string[],
  isAdmin: boolean,
  accountId: string | null,
  staffUID: string,
  levelId: string | null,
  departmentId: string | null,
  managers: string[] | null,
  idOfTheGroups: string[] | null
) => {
  console.log({
    businessId,
    primaryRole,
    secondaryRoles,
    isAdmin,
    accountId,
    staffUID,
    levelId,
    departmentId,
    managers,
    idOfTheGroups,
  });
  await tangoStaffApiClient.put("/update-staff-roles", {
    businessId,
    primaryRole,
    secondaryRoles,
    isAdmin,
    accountId,
    staffUID,
    levelId,
    departmentId,
    parentStaffIds: managers,
    idOfTheGroups,
  });
};

export const updateEmployeePaymentInfo = async (
  businessId: string,
  payRates: PayRate[],
  annualSalary: number,
  staffUID: string
) => {
  await tangoStaffApiClient.put("/update-staff-payment-information", {
    businessId,
    payRates,
    annualSalary,
    staffUID,
  });
};

export const updateStaffMemberEmail = async (
  businessId: string,
  email: string,
  uid: string
) => {
  await tangoAuthApiClient.put("/edit-email", {
    businessId,
    email,
    uid,
  });
};

export const sendVerificationToEmail = async (email: string) => {
  try {
    const res = await tangoAuthApiClient.post(
      "/send-email-verification",
      { email },
      { validateStatus: () => true }
    );

    console.log("res", res);

    if (res.status === 201) {
      return { success: true };
    }
    if (res.status === 404) {
      return { success: false, notFound: true };
    }
    return {
      success: false,
      message: res.data?.message ?? "Something went wrong",
    };
  } catch (e) {
    console.log("Error sending email verificatoin", e);
    return { success: false };
  }
};

export const checkEmailVerificationCode = async (
  email: string,
  code: string
) => {
  try {
    const res = await tangoAuthApiClient.post("/check-email-verification", {
      email,
      code,
    });
    console.log("res", res);

    if (res.status === 201 && res.data?.customToken) {
      return { success: true, customToken: res.data.customToken };
    }
    return {
      success: false,
      message: res.data?.message ?? "Something went wrong",
    };
  } catch (e) {
    console.log("Error checking verificatoin code", e);
    return { success: false };
  }
};

export const checkPhoneNumberVerificationCode = async (
  phoneNumber: string,
  code: string
) => {
  try {
    const res = await tangoAuthApiClient.post(
      "/check-phone-verification",
      { phoneNumber, code },
      { validateStatus: () => true }
    );
    console.log("res", res);

    if (res.status === 201 && res.data?.customToken) {
      return { success: true, customToken: res.data.customToken };
    }
    return {
      success: false,
      message: res.data?.message ?? "Something went wrong",
    };
  } catch (e) {
    console.log("Error checking verificatoin code", e);
    return { success: false, message: "Something went wrong" };
  }
};

export const sendVerificationToPhoneNumber = async (phoneNumber: string) => {
  try {
    const res = await tangoAuthApiClient.post(
      "/send-phone-verification",
      { phoneNumber },
      { validateStatus: () => true }
    );
    console.log("res", res);
    console.log("res.status", res.status);

    if (res.status === 201) {
      return { success: true };
    }
    if (res.status === 404) {
      return { success: false, notFound: true };
    }
    return {
      success: false,
      message: res.data?.message ?? "Something went wrong",
    };
  } catch (e) {
    console.log("Error sending verification to phone number", e);
    return { success: false };
  }
};

export const updateStaffMemberPhoneNumber = async (
  businessId: string,
  phoneNumber: string,
  uid: string
) => {
  await tangoAuthApiClient.put("/edit-phone-number", {
    businessId,
    phoneNumber,
    uid,
  });
};

export const checkEmailFieldForStaffSignUp = async (
  email: string,
  businessId: string
) => {
  try {
    const res = await tangoAuthApiClient.post<{
      available: boolean;
      message?: string;
    }>("/check-email", {
      email,
      businessId,
    });
    return res.data;
  } catch (e) {
    console.log("Error: checkEmailFieldForStaffSignUp ", e);
    return {
      available: false,
      message: "Something went wrong, please try a different email",
    };
  }
};

export const checkPhoneNumberFieldForStaffSignUp = async (
  phoneNumber: string,
  businessId: string
) => {
  try {
    const res = await tangoAuthApiClient.post<{
      available: boolean;
      message?: string;
    }>("/check-phone", {
      phoneNumber,
      businessId,
    });
    return res.data;
  } catch (e) {
    console.log("Error: checkPhoneNumberFieldForStaffSignUp ", e);
    return {
      available: false,
      message: "Something went wrong, please try a different phone number",
    };
  }
};

export const createUserWithEmptyBusinessAndAccount = async (
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  password: string
): Promise<DefaultResult> => {
  try {
    const res = await axios.post(
      apiCall("createUserWithEmptyBusinessAndAccount"),
      {
        email,
        phoneNumber,
        firstName,
        lastName,
        password,
      }
    );
    const data = res.data as DefaultResult;
    if (data.success) {
      return { success: true };
    }
    alert(data.error);
    return {
      success: false,
      error: data.error,
    };
  } catch (e) {
    console.log({ criticalError: e });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //@ts-ignore
    return {
      success: false,
      //@ts-ignore
      error: e.message,
    };
  }
};

// TODO also create semi-empty business and account documents
export const createUserForBusinessAccountAndSignInWithCustomToken = async (
  email: string,
  phoneNumber: string
) => {
  try {
    console.log({
      email,
      phoneNumber,
    });
    const res = await axios.post(apiCall("createUserForBusiness"), {
      email,
      phoneNumber,
    });
    console.log("res.data", res.data);
    if (res.data?.token) {
      await firebase.auth().signInWithCustomToken(res.data.token);
    }
  } catch (e) {
    console.log(e);
  }
};

export const finishBusinessSetup = async (
  businessName: string,
  businessType: string,
  businessLocation: {
    address: string;
    lat: number;
    lon: number;
    googlePlacesId: string;
  },
  businessPhone: string,
  canada: boolean,
  positions: string[],
  staffMembers: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    position: string;
    isAdmin: boolean;
  }[],
  numberOfEmployees: string
): Promise<DefaultResult> => {
  try {
    const authToken = await firebase.auth().currentUser?.getIdToken();
    const res = await axios.post(apiCall("finishBusinessAndAccountSetup"), {
      signUpData: {
        businessName,
        businessType,
        businessLocation,
        businessPhone,
        deliverect: null,
        deliverectChannelLinkId: null,
        deliverectAccountId: null,
        canada,
        staffMembers,
        positions,
        numberOfEmployees,
      },
      authToken,
    });
    const data = res.data as DefaultResult;
    if (data.success) {
      return { success: true };
    }
    alert(data.error);
    return {
      success: false,
      error: data.error,
    };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
