import { Icon } from "@blueprintjs/core";
import { camelCase } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { OptionTypeBase } from "react-select/src/types";

import Box from "components/Box";

import { RootState } from "../../../../model/store";
import { updateBusinessSetting } from "../../../../models/businesses";
import "./index.css";

interface Assignee extends StaffMember {
  isSelected: boolean;
}

interface Props {
  selectedRole?: JobWithDepartment;
  setShouldShowCreateRole: (value: boolean) => void;
}

const RoleForm = ({ selectedRole, setShouldShowCreateRole }: Props) => {
  const staffMembers: StaffMember[] = useSelector(
    (state: RootState) => state.fellowStaffMembers
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const [roleName, setRoleName] = useState("");

  const [selectedDepartment, setSelectedDepartment] =
    useState<OptionTypeBase | null>(null);

  const [departments, setDepartments] = useState([
    {
      label: "FOH",
      value: "foh",
    },
    {
      label: "BOH",
      value: "boh",
    },
    {
      label: "Other",
      value: "other",
    },
  ]);

  useEffect(() => {
    if (selectedRole) {
      setRoleName(selectedRole.title);
      setSelectedDepartment({
        value: selectedRole.departmentId,
        label: selectedRole.departmentId.toUpperCase(),
      });
    }
  }, [selectedRole]);

  const getStaffMemberNames = useCallback(() => {
    if (staffMembers && selectedRole) {
      const staffMembersByRole = staffMembers.filter(
        (member) =>
          member.primaryRole === selectedRole.jobId ||
          member.secondaryRoles.includes(selectedRole.jobId)
      );
      return staffMembersByRole
        ? staffMembersByRole.map(
            (member) => `${member.contact.firstName} ${member.contact.lastName}`
          )
        : [];
    } else {
      return [];
    }
  }, [staffMembers, selectedRole]);

  const isEdit = !!selectedRole;
  // useEffect(() => {
  //   if (staffMembers) {
  //     const assineesTemp = staffMembers.map((staffMember) => ({
  //       ...staffMember,
  //       isSelected: false
  //     })) as Assignee[];
  //     setAssignees(assineesTemp);
  //   } else {
  //     setAssignees([]);
  //   }
  // }, [staffMembers]);
  const shouldSubmit = roleName && selectedDepartment;

  const handleSubmit = async () => {
    const jobFunctionsOfBS = { ...businessSettings.jobFunctions };
    if (!selectedRole) {
      const roleId = camelCase(roleName);
      jobFunctionsOfBS[roleId] = {
        id: roleId,
        title: roleName,
        deleted: false,
        departmentId: selectedDepartment!.value,
      };
    } else {
      jobFunctionsOfBS[selectedRole.jobId] = {
        id: selectedRole.jobId,
        title: roleName,
        deleted: false,
        departmentId: selectedDepartment!.value,
      };
      // console.log({jobFunctionsOfBS})
    }
    await updateBusinessSetting(
      businessSettings.businessId,
      "jobFunctions",
      jobFunctionsOfBS
    );
    setShouldShowCreateRole(false);
  };

  return (
    <div className="role-wrapper">
      {/*<div*/}
      {/*  onClick={() => redirectOrganizationalHierarchy()}*/}
      {/*  className="role-header"*/}
      {/*>*/}
      {/*  <img src={require("assets/back_icon.svg").default}></img>*/}
      {/*  <div className="back-text">Back</div>*/}
      {/*</div>*/}
      <div className="role-content">
        <div>
          <Icon
            icon="arrow-left"
            size={22}
            onClick={() => setShouldShowCreateRole(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="role-header-content">
          <div className="role-header-text">
            {isEdit ? "Edit" : "Create"} Role
          </div>
          <div>
            <button
              type="button"
              className={`${
                shouldSubmit ? "role-header-btn" : "role-header-btn-disabled"
              }`}
              disabled={!shouldSubmit}
              onClick={handleSubmit}
            >
              {isEdit ? "Save" : "Create"}
            </button>
          </div>
        </div>
        <Box display="flex" flex-direction="column">
          <Box className="section option-section">
            <div className="section-title">Role</div>
            <Box className="Employee-type-options">
              <input
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                type="text"
                placeholder="Enter name of role"
                className="role-text-box"
              />
            </Box>
          </Box>
          {selectedRole && (
            <Box className="section option-section">
              <div className="section-title">Assignees(s)</div>
              {/*<div className="section-subtitle">Who to assign this role</div>*/}
              <Box className="Employee-type-options">
                {/*<div*/}
                {/*  className="select_add_names"*/}
                {/*  onClick={(event) => {*/}
                {/*    event.stopPropagation();*/}
                {/*    setIsManagerER(!isManagerER);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <div className="add_names_text">Add names</div>*/}
                {/*  <div>*/}
                {/*    <Icon*/}
                {/*      icon="plus"*/}
                {/*      iconSize={20}*/}
                {/*      className="add_names_icon"*/}
                {/*    ></Icon>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*{isManagerER && (
               <div className="add_names_popup">
               <div className="add_names_popup_header">Names</div>
               <div className="add_names_popup_content">
               {assignees.map((assignee) => {
               return (
               <div className="add_names_wrapper" key={assignee.id}>
               <div>{`${assignee.contact.firstName} ${assignee.contact.lastName}`}</div>
               <Checkbox value={assignee.isSelected} />
               </div>
               );
               })}
               </div>
               </div>
               )}*/}
                <div className="selected_employee_option_wrapper">
                  {getStaffMemberNames().map((item) => (
                    <div className="selected_employee_option">
                      <div className="option-text">{item}</div>
                    </div>
                  ))}
                </div>
              </Box>
            </Box>
          )}
          <Box className="section option-section">
            <div className="section-title">Department</div>
            {/*<div className='section-subtitle'>*/}
            {/*  Edit app access for assignee(s) in this role{' '}*/}
            {/*</div>*/}
            <Box className="Employee-type-options">
              <Select
                className="flex-react-select employee-section"
                placeholder=""
                value={selectedDepartment}
                options={departments}
                onChange={setSelectedDepartment}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default RoleForm;
