import React from "react";
import { useSelector } from "react-redux";
import { firstBy } from "thenby";

import { RootState } from "model/store";

import ModifiersTable from "../admin/menus&Products/modifiers/ModifiersTable";
import Layout from "./Layout";

const Modifiers = () => {
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );
  const modifiers: FirebaseModifierDoc[] = useSelector((state: RootState) =>
    state.modifiers.sort(firstBy("nameExternal", { ignoreCase: true }))
  );
  const modifierOptions: FirebaseModifierOptionsDoc[] = useSelector(
    (state: RootState) => state.modifierOptions
  );
  const products: FirebaseProductDoc[] = useSelector(
    (state: RootState) => state.products
  );

  if (business) {
    return (
      <Layout>
        <ModifiersTable
          businessId={business?.id}
          modifierOptions={modifierOptions}
          modifiers={modifiers}
          products={products}
        />
      </Layout>
    );
  }
  return null;
};

export default Modifiers;
