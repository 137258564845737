import { Button, Dialog, Icon, MenuItem, Position } from "@blueprintjs/core";
import {
  DateInput,
  DateRange,
  DateRangeInput,
  TimePicker,
  TimePrecision,
} from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Popover2 } from "@blueprintjs/popover2";
import firebase, { storage, storageRef } from "config/firebase";
import { first } from "cypress/types/lodash";
import moment from "moment";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useSelector } from "react-redux";
import Select from "react-select";

import Box from "components/Box";
import Spinner from "components/Spinner";

import { saveFile } from "utils/storage";

import { adminsSelector } from "model/selectors/staffing";
import { RootState } from "model/store";

import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedEvent: any;
  modalMode: string;
  setModalMode: any;
}

interface listOption {
  value: string;
  firstName: string;
  lastName: string;
  label: string;
  id: string;
  roles: string | null | undefined;
  imgUrl: string | null | undefined;
}

type SelectedItemsType = {
  value: string;
  id: string;
  firstName: string;
  lastName: string;
  label: string;
};

const AddEvent = ({
  isOpen,
  onClose,
  selectedEvent,
  modalMode,
  setModalMode,
}: Props) => {
  const db = firebase.firestore();
  const fellowStaffMembers: StaffMember[] = useSelector(adminsSelector);
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const user: StaffMember = useSelector((state: RootState) => state.user);

  const [openColorBox, setOpenColorBox] = useState<boolean | undefined>(
    undefined
  );
  const [openActionBox, setActionBox] = useState<boolean | undefined>(
    undefined
  );

  const [selectedFromDate, setSelectedFromDate]: any = useState(new Date());
  const [selectedToDate, setSelectedToDate]: any = useState(new Date());
  const [selectedPersons, setSelectedPersons] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles]: any = useState(null);
  const [selectedColor, setColor]: any = useState("#000000");
  const [listData, setListData]: any = useState<listOption>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedItems, setSelectedItems] = useState<SelectedItemsType[]>([]);
  const [description, setDescription]: any = useState("");
  const [eventName, setEventName]: any = useState("");
  const [range, setRange]: any = useState([null, null]);
  const [timeFrom, setTimeFrom]: any = useState(new Date());
  const [timeTo, setTimeTo]: any = useState(new Date());
  const [imgbase64Url, setImgbase64Url]: any = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [attachment, setAttachment] = useState({
    file: null,
    fileName: null,
  });
  const [allDayCheck, setAllDayCheck] = useState(false);

  const toogleColorBox = () => setOpenColorBox(!openColorBox);

  const showFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      // setSelectedFiles(e.target.files[0].name);
      setSelectedFiles({
        file: e.target.files[0],
        fileName: e.target.files[0].name,
      });
    }
  };

  const handleColorChange = (color: string) => {
    setColor(color);
  };

  const getdropdownvalue = (fellowStaffMembers: StaffMember[]) => {
    const array: listOption[] = [];
    fellowStaffMembers &&
      fellowStaffMembers.map(
        (item: {
          id: string;
          contact: { firstName: string; lastName: string; email: string };
          primaryRole: string | undefined | null;
          imageUrl: string | undefined | null;
        }) => {
          array.push({
            value: item.contact.firstName + " " + item.contact.lastName,
            id: item.id,
            firstName: item.contact.firstName,
            lastName: item.contact.lastName,
            label:
              item.contact.firstName +
              " " +
              item.contact.lastName +
              " ( " +
              item.contact.email +
              " )",
            roles: item.primaryRole,
            imgUrl: item.imageUrl,
          });
        }
      );
    setListData(array);
    console.log("array", array);
    // console.log("listdata from data",data && data.map((item:any)=>{return [item.contact.firstName,item.contact.]}))
  };

  const mergeTimeAndDate = (selectedDate: any, selectedTime: any) => {
    const value = selectedDate.toString();
    const copyofValue = new Date(value);
    copyofValue.setHours(selectedTime?.getHours());
    copyofValue.setMinutes(selectedTime?.getMinutes());
    copyofValue.setSeconds(selectedTime?.getSeconds());

    return copyofValue;
  };

  const setTimeAllDay = () => {
    const startTime = timeFrom.toString();
    const endTime = timeTo.toString();
    const copyofstartTime = new Date(startTime);
    const copyofendTime = new Date(endTime);

    copyofstartTime.setHours(0);
    copyofstartTime.setMinutes(0);
    copyofstartTime.setSeconds(0);

    copyofendTime.setHours(23);
    copyofendTime.setMinutes(59);
    copyofendTime.setSeconds(0);

    setTimeFrom(copyofstartTime);
    setTimeTo(copyofendTime);
  };

  const handleRangeChange = (range: DateRange) => setRange(range);

  const clearStates = () => {
    setEventName("");
    setDescription("");
    setSelectedItems([]);
    handleColorChange("#000000");
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setSelectedFiles(null);
    setAttachment({
      file: null,
      fileName: null,
    });
    setSelectedFiles(null);

    const initializeTime = new Date();

    setTimeFrom(initializeTime);
    setTimeTo(initializeTime);
  };
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL: any = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const handleInputChange = (e: any) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File Is", file.base64);
        setImgbase64Url(file.base64);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedFiles(e.target.files[0]);
  };

  const addEvent = async () => {
    // db.collection("CalenderEvents")
    // .doc(docRef.id)
    // .update({ id: docRef.id })
    // .then(() => {
    //   console.log("event added");
    //   clearStates();
    //   onClose();
    // });

    const payload = {
      id: null,
      businessId: business.id,
      eventName: eventName,
      color: selectedColor,
      user: {
        id: user.id,
        name: user?.contact?.firstName + " " + user?.contact?.lastName,
        email: user?.contact?.email,
      },
      eventDuration: {
        fromDateTime: mergeTimeAndDate(selectedFromDate, timeFrom),
        toDateTime: mergeTimeAndDate(selectedToDate, timeTo),
        allDay: allDayCheck,
      },
      deleted: false,
      invitedPeople: selectedItems.length > 0 ? selectedItems : [],
      attachments: {
        file: null,
        fileName: null,
      },
      description: description,
      updatedAt: new Date(),
      deletedAt: null,
      createdAt: new Date(),
    };

    try {
      setLoading(true);
      const eventRef = await db.collection("CalenderEvents").add(payload);
      // update menu with id
      await db
        .collection("CalenderEvents")
        .doc(eventRef.id)
        .update({ id: eventRef.id });

      //check the file and save to firestore
      if (selectedFiles) {
        //    const imageRef = storage.ref(`images/${selectedFiles.name}`);
        if (imgbase64Url) {
          const fileUrl = await saveFile(
            `EventCalendar/${business.id}/${eventRef.id}/${selectedFiles.name}`,
            imgbase64Url
          );
          if (fileUrl) {
            console.log("file url generated!", fileUrl);
            await db
              .collection("CalenderEvents")
              .doc(eventRef.id)
              .update({
                attachments: { file: fileUrl, fileName: selectedFiles.name },
              });
          }
          setLoading(false);
          console.log("event added");
          clearStates();
          onClose();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
      clearStates();
      onClose();
    }

    console.log("payload", payload);
  };

  const updateEvent = async () => {
    const payload = {
      eventName: eventName,
      color: selectedColor,
      eventDuration: {
        fromDateTime: mergeTimeAndDate(selectedFromDate, timeFrom),
        toDateTime: mergeTimeAndDate(selectedToDate, timeTo),
        allDay: allDayCheck,
      },
      invitedPeople: selectedItems.length > 0 ? selectedItems : [],
      description: description,
      updatedAt: new Date(),
      deleted: false,
    };

    // by default we dont have attachment => then we have upload image first,
    // if attachment is removed and file is not selected than send attchment null,

    try {
      setLoading(true);
      const eventRef = await db
        .collection("CalenderEvents")
        .doc(selectedEvent?.eventAllData?.id)
        .update(payload);

      if (attachment.file == null && selectedFiles) {
        //check the file and save to firestore
        if (selectedFiles) {
          //    const imageRef = storage.ref(`images/${selectedFiles.name}`);
          if (imgbase64Url) {
            const fileUrl = await saveFile(
              `EventCalendar/${business.id}/${selectedEvent?.eventAllData?.id}/${selectedFiles.name}`,
              imgbase64Url
            );
            if (fileUrl) {
              console.log("file url generated!", fileUrl);
              await db
                .collection("CalenderEvents")
                .doc(selectedEvent?.eventAllData?.id)
                .update({
                  attachments: { file: fileUrl, fileName: selectedFiles.name },
                });
            }

            console.log("event added");
          }
        }
      }

      if (attachment.file == null && selectedFiles == null) {
        return db
          .collection("CalenderEvents")
          .doc(selectedEvent?.eventAllData?.id)
          .update({
            attachments: {
              file: null,
              fileName: null,
            },
          });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      clearStates();
      onClose();
    }
  };

  const deleteEvent = () => {
    try {
      db.collection("CalenderEvents")
        .doc(selectedEvent?.eventAllData?.id)
        .update({ deleted: true, deletedAt: new Date() })
        .then(() => {
          console.log("event deleted");
          onClose();
        });
    } catch (error) {
      console.error(error);
    }
  };

  const prefillData = (event: any) => {
    if (event) {
      setEventName(event.title);
      setDescription(event?.eventAllData?.description);
      setSelectedItems(event?.eventAllData?.invitedPeople);
      handleColorChange(event?.bgColor);

      const fromDate =
        event?.eventAllData?.eventDuration?.fromDateTime?.toDate();
      const toDate = event?.eventAllData?.eventDuration?.toDateTime?.toDate();
      setSelectedFromDate(fromDate);
      setSelectedToDate(toDate);
      setTimeTo(toDate);
      setTimeFrom(fromDate);
      setAttachment({
        file: event?.eventAllData?.attachments?.file,
        fileName: event?.eventAllData?.attachments?.fileName,
      });
      setAllDayCheck(event?.eventAllData?.eventDuration?.allDay);
    }
  };

  const validateFields = () => {
    const event = eventName.length > 0;
    const selectedUser = selectedItems.length > 0;
    const desc = description.length > 3;

    if (event && selectedUser && desc) {
      return true;
    }

    return false;
  };

  const handleCheckBoxChange = (e: any) => {
    setAllDayCheck(e.target.checked);
    if (e.target.checked) {
      setSelectedToDate(selectedFromDate);
      setTimeAllDay();
    }
  };

  useEffect(() => {
    getdropdownvalue(fellowStaffMembers);
    console.log("fellowStaffMembers", fellowStaffMembers);
  }, [fellowStaffMembers]);

  useEffect(() => {
    console.log("range", range);
  }, [range]);

  useEffect(() => {
    if (selectedEvent && modalMode == ("read" || "edit")) {
      prefillData(selectedEvent);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (modalMode == "create") {
      clearStates();
    }
  }, [modalMode]);

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  return (
    <Dialog
      isOpen={isOpen}
      className="add-event-modal"
      hasBackdrop
      onClose={onClose}
    >
      <div className="modal-header">
        <Icon icon="cross" color="#000000" size={22} onClick={onClose} />
        <div className="modal-title">
          {modalMode == "create" ? "New Event" : selectedEvent?.title}
        </div>
        {modalMode != "create" && (
          <Box className="more-icon-div">
            {" "}
            <Popover2
              isOpen={openActionBox}
              content={
                <div className="action-popover">
                  <div
                    className="popover-content"
                    onClick={() => {
                      setModalMode("edit");
                      setActionBox(false);
                    }}
                  >
                    <img src={require("assets/renameIcon.svg").default} />
                    Edit Event
                  </div>
                  <div
                    className="popover-content"
                    onClick={() => deleteEvent()}
                  >
                    <img src={require("assets/deleteIconBlack.svg").default} />
                    Delete Event
                  </div>
                </div>
              }
              position={Position.BOTTOM_RIGHT}
            >
              <Box onClick={() => setActionBox(undefined)}>
                {" "}
                <Icon icon="more" color="#000000" size={20} />
              </Box>
            </Popover2>
          </Box>
        )}
      </div>
      <div className="modal-body">
        <div className="modal-section">
          <div className="section-title">Event Name</div>
          <div className="event-name-container">
            <input
              type="text"
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              readOnly={modalMode == "read" ? true : false}
            />

            <Popover2
              isOpen={openColorBox}
              content={
                <div className="color-popover">
                  <HexColorPicker
                    color={selectedColor}
                    onChange={handleColorChange}
                  />
                  <div className="color-tag">color:{selectedColor}</div>
                  <button
                    className="save-btn"
                    onClick={(e) => setOpenColorBox(false)}
                  >
                    save
                  </button>
                </div>
              }
              position={Position.BOTTOM}
            >
              <div
                className="color-dropdown"
                onClick={() => setOpenColorBox(undefined)}
              >
                <Icon icon="dot" size={20} color={selectedColor} />
                <Icon icon="chevron-down" size={10} color="#424452" />
              </div>
            </Popover2>
          </div>
        </div>
        <div className="modal-section inner-section">
          <div className="section-title">When</div>
          <div className="event-when-container">
            {/* <DateRangeInput
              allowSingleDayRange={true}
              formatDate={(date) => date.toLocaleString()}
              onChange={handleRangeChange}
              parseDate={(str) => new Date(str)}
              value={range}
              timePickerProps={{
                precision: TimePrecision.MINUTE,
                showArrowButtons: true,
              }}
            /> */}
            <div className="event-when-innersection">
              {/*from date*/}
              <span className="date-label">From :</span>
              <Box style={{ marginLeft: "10px" }}>
                <DateInput
                  inputProps={{
                    rightElement: (
                      <Icon icon="chevron-down" size={10} color="#7C7F93" />
                    ),
                  }}
                  className="event-date"
                  formatDate={(date) =>
                    moment(date).format("dddd, MMMM Do YYYY")
                  }
                  parseDate={(str) => moment(str).toDate()}
                  value={selectedFromDate}
                  popoverProps={{
                    position: "bottom",
                  }}
                  placeholder={"M/D/YYYY"}
                  showActionsBar={true}
                  onChange={(selectedDate) =>
                    selectedDate && setSelectedFromDate(selectedDate)
                  }
                  disabled={modalMode == "read" ? true : false}
                />
              </Box>
              <TimePicker
                className="event-time"
                precision={TimePrecision.MINUTE}
                value={timeFrom}
                onChange={(date) => setTimeFrom(date)}
                disabled={modalMode == "read" || allDayCheck ? true : false}
              />
              <Box className="allDay-checkbox" style={{ marginLeft: "auto" }}>
                <input
                  type="checkbox"
                  checked={allDayCheck}
                  onChange={(e) => handleCheckBoxChange(e)}
                  disabled={modalMode == "read" ? true : false}
                />

                <label className="checkbox-label">All Day Event</label>
              </Box>
            </div>
            <div
              className="event-when-innersection"
              style={{ marginTop: "5px" }}
            >
              <span className="date-label">To :</span>
              <Box style={{ marginLeft: "10px" }}>
                {/*to date*/}
                <DateInput
                  inputProps={{
                    rightElement: (
                      <Icon icon="chevron-down" size={10} color="#7C7F93" />
                    ),
                  }}
                  className="event-date"
                  formatDate={(date) =>
                    moment(date).format("dddd, MMMM Do YYYY")
                  }
                  parseDate={(str) => moment(str).toDate()}
                  value={selectedToDate}
                  popoverProps={{
                    position: "bottom",
                  }}
                  minDate={selectedFromDate}
                  placeholder={"M/D/YYYY"}
                  showActionsBar={true}
                  onChange={(selectedDate) =>
                    selectedDate && setSelectedToDate(selectedDate)
                  }
                  disabled={modalMode == "read" ? true : false}
                />
              </Box>
              <TimePicker
                minTime={timeFrom}
                className="event-time"
                precision={TimePrecision.MINUTE}
                value={timeTo}
                onChange={setTimeTo}
                disabled={modalMode == "read" || allDayCheck ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="modal-section inner-section">
          <div className="section-title">Who</div>
          <div className="event-who-container">
            <span className="group-dropdown-div">
              <Select
                classNamePrefix="group-dropdown"
                isMulti
                placeholder={"Groups or individuals"}
                options={listData}
                value={selectedItems}
                onChange={(e: any) => setSelectedItems(e)}
                isDisabled={modalMode == "read" ? true : false}
              />
            </span>
          </div>
        </div>
        <div className="modal-section inner-section">
          <div className="section-title">Attachments</div>
          <div className="attachment-container">
            {attachment.file ? (
              modalMode !== "create" &&
              selectedEvent?.eventAllData?.attachments?.file && (
                <div className="file-tag">
                  <a href={selectedEvent?.eventAllData?.attachments?.file}>
                    {selectedEvent?.eventAllData?.attachments?.fileName}
                  </a>
                  {modalMode !== "read" && (
                    <Icon
                      icon="cross"
                      color="#424452"
                      onClick={() =>
                        setAttachment({ file: null, fileName: null })
                      }
                    />
                  )}
                </div>
              )
            ) : selectedFiles ? (
              <div className="file-tag">
                {JSON.stringify(selectedFiles.name)}{" "}
                <Icon
                  icon="cross"
                  color="#424452"
                  onClick={() => setSelectedFiles("")}
                />
              </div>
            ) : (
              <div
                className="placeholder-text"
                onClick={() => inputRef.current?.click()}
              >
                Add an attachment here
              </div>
            )}
            <input
              type="file"
              hidden
              ref={inputRef}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="modal-section inner-section">
          <div className="section-title">Description</div>
          <div className="description-container">
            <textarea
              placeholder="Add description here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              readOnly={modalMode == "read" ? true : false}
            />
          </div>
          {modalMode !== "read" && (
            <Button
              text={
                modalMode == "create"
                  ? "Create New Event"
                  : "Save Event Updates"
              }
              className={` ${
                validateFields()
                  ? "create-event-button"
                  : "create-event-button-disable"
              }`}
              onClick={() => {
                if (validateFields()) {
                  if (modalMode == "create") {
                    addEvent();
                  }
                  if (modalMode == "edit") {
                    updateEvent();
                  }
                }
              }}
            />
          )}
        </div>
      </div>
      {loading && <Spinner />}
    </Dialog>
  );
};
export default AddEvent;
