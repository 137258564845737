import * as React from "react";

export const JobDetail = ({
  height = "24px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M8.6275 1.75L12.25 5.3725V12.25H1.75V1.75H8.6275ZM8.6275 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V5.3725C13.75 4.975 13.5925 4.5925 13.3075 4.315L9.685 0.6925C9.4075 0.4075 9.025 0.25 8.6275 0.25ZM3.25 9.25H10.75V10.75H3.25V9.25ZM3.25 6.25H10.75V7.75H3.25V6.25ZM3.25 3.25H8.5V4.75H3.25V3.25Z"
      fill={color}
    />
  </svg>
);
