import _ from "lodash";

import { isEqual } from "utils/data";

import { FirebaseOrderDisplayDoc, OrderDisplayDoc } from "types/orderDisplay";

import firebase from "../config/firebase";

const db = firebase.firestore();
const orderDisplayCollection = db.collection("OrderDisplays");

export const performSoftDelete = async (orderDiplays: OrderDisplayDoc[]) => {
  for await (const orderDisplay of orderDiplays) {
    try {
      if (orderDisplay.id && orderDisplay.id.length > 0) {
        await orderDisplayCollection
          .doc(orderDisplay.id)
          .update({ deleted: true });
      }
    } catch (err) {
      console.log("Failed to update: ", orderDisplay.id);
    }
  }
};

export const saveData = async (
  newData: OrderDisplayDoc[],
  existingData: OrderDisplayDoc[],
  businessId: string
) => {
  const firebaseOrderDisplays: FirebaseOrderDisplayDoc[] = newData
    .map((orderDisplay, index) => ({ ...orderDisplay, index: index }))
    .filter((orderDisplay) => {
      const orderDisplayToCheck = _.omit(orderDisplay, "index");
      const foundItem = existingData.some((existingModifier) =>
        isEqual(existingModifier, orderDisplayToCheck)
      );

      // Remove orderDisplay documents that have not been edited since existingData
      if (foundItem) {
        return false;
      }
      return true;
    })
    .map((orderDisplay) => {
      return {
        id: orderDisplay.id,
        businessId: businessId,
        enabled: orderDisplay.enabled === "Yes",
        deleted: false,
        displayName: orderDisplay.displayName,
        allowAllRoutedSubTypes: orderDisplay.routedSubTypes.includes("all"),
        allowAllRoutedOrderTypes: orderDisplay.routedOrderTypes.includes("all"),
        allowAllRoutedTables: orderDisplay.routedTables.includes("all"),
        routedSubTypes: orderDisplay.routedSubTypes.includes("all")
          ? []
          : orderDisplay.routedSubTypes,
        routedOrderTypes: orderDisplay.routedOrderTypes.includes("all")
          ? []
          : orderDisplay.routedOrderTypes,
        routedTables: orderDisplay.routedTables.includes("all")
          ? []
          : orderDisplay.routedTables.map((table) => parseInt(table)),
        createdAt: new Date(),
        updatedAt: new Date(),
      };
    });

  for await (const orderDisplay of firebaseOrderDisplays) {
    // For existing orderDisplays - just find the differences to update
    if (orderDisplay.id) {
      const index = existingData.findIndex(
        (existingProduct) => existingProduct.id === orderDisplay.id
      );
      if (index !== -1) {
        const dataToUpdate: { [T: string]: any } = Object.keys(
          existingData[index]
        )
          .filter(
            (key) =>
              // @ts-ignore
              !isEqual(existingData[index][key], newData[index][key]) &&
              Object.keys(orderDisplay).includes(key)
          )
          // @ts-ignore
          .reduce((acc, key) => ({ ...acc, [key]: orderDisplay[key] }), {});

        const allOptionsToUpdate = {
          allowAllRoutedSubTypes: orderDisplay.routedSubTypes.length === 0,
          allowAllRoutedOrderTypes: orderDisplay.routedOrderTypes.length === 0,
          allowAllRoutedTables: existingData[index].routedTables.length === 0,
        };

        try {
          await orderDisplayCollection.doc(orderDisplay.id).update({
            ...dataToUpdate,
            ...allOptionsToUpdate,
          });
        } catch (err) {
          console.log("Failed to update: ", orderDisplay.id);
        }
      }
    } else {
      const docRef = orderDisplayCollection.doc();
      orderDisplay["id"] = docRef.id;
      const finalModifier = _.omit(orderDisplay, "index");

      try {
        await docRef.set(finalModifier, { merge: true });
      } catch (err) {
        console.log("Failed to update: ", orderDisplay.id);
      }
    }
  }
};
