import * as React from "react";

export const Subscribed = ({
  height = "20px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 21 25"
    {...props}
  >
    <path
      d="M18.833 4.16667H2.16634C1.02051 4.16667 0.0830078 5.10417 0.0830078 6.25V18.75C0.0830078 19.8958 1.02051 20.8333 2.16634 20.8333H18.833C19.9788 20.8333 20.9163 19.8958 20.9163 18.75V6.25C20.9163 5.10417 19.9788 4.16667 18.833 4.16667ZM18.833 18.75H2.16634V6.25H18.833V18.75ZM2.16634 0H18.833V2.08333H2.16634V0ZM2.16634 22.9167H18.833V25H2.16634V22.9167ZM10.4997 12.5C11.9372 12.5 13.1038 11.3333 13.1038 9.89583C13.1038 8.45833 11.9372 7.29167 10.4997 7.29167C9.06217 7.29167 7.89551 8.45833 7.89551 9.89583C7.89551 11.3333 9.06217 12.5 10.4997 12.5ZM10.4997 8.85417C11.0726 8.85417 11.5413 9.32292 11.5413 9.89583C11.5413 10.4688 11.0726 10.9375 10.4997 10.9375C9.92676 10.9375 9.45801 10.4688 9.45801 9.89583C9.45801 9.32292 9.92676 8.85417 10.4997 8.85417ZM15.708 16.6562C15.708 14.4792 12.2601 13.5417 10.4997 13.5417C8.73926 13.5417 5.29134 14.4792 5.29134 16.6562V17.7083H15.708V16.6562ZM7.17676 16.1458C7.81218 15.6042 9.29134 15.1042 10.4997 15.1042C11.7184 15.1042 13.1976 15.6042 13.833 16.1458H7.17676Z"
      fill={color}
    />
  </svg>
);
