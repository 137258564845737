import { Dialog, Icon } from "@blueprintjs/core";
import firebase from "config/firebase";
import { includes } from "lodash";
import React, { useEffect, useState } from "react";

import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  businesses: TangoBusiness[];
  updateLocation: (
    location: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => void;
  selectedLocations?: TangoBusiness[];
  businessesId: string;
}

const FindLocationModal = ({
  isOpen,
  onClose,
  businesses,
  updateLocation,
  selectedLocations,
  businessesId,
}: Props) => {
  const db = firebase.firestore();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const isLocationSelected = (location: any) => {
    return includes(selectedLocations, location);
  };

  useEffect(() => {
    console.log("selectedLocations", selectedLocations);
  }, [selectedLocations]);

  return (
    <Dialog
      portalClassName="location-modal-portal"
      isOpen={isOpen}
      onClose={onClose}
      className="find-location"
    >
      <div className="header">
        <Icon
          icon="cross"
          className="close-button"
          color="#424452"
          iconSize={20}
          onClick={onClose}
        />
        <span>Find Location</span>
        <span className="selectallbtn">Select All</span>
      </div>
      {/* <div className="search-location">
        <div className="to">To:</div>
        <input placeholder="Cincinnati, OH" value={searchQuery} onChange={(e)=> setSearchQuery(e.target.value)}/>
      </div> */}
      <div className="body">
        <ul className="location-list">
          {businesses &&
            businesses?.map((item, index) => {
              return (
                <li
                  onClick={() =>
                    updateLocation(item, isLocationSelected(item), index)
                  }
                  className={isLocationSelected(item) ? "active" : ""}
                >
                  <Icon
                    icon={isLocationSelected(item) ? "tick" : "plus"}
                    color="#4385EF"
                  />
                  {item.location.address}
                </li>
              );
            })}
        </ul>
        {/*
          selectedLocations.length > 0 && <Button text="Continue" className="continue-button" onClick={onClose}/>
        
        <Button text="Edit" className="continue-button" onClick={onClose}/>
      */}
      </div>
    </Dialog>
  );
};
//className={item.name === selectedLocation ? 'active':''}
export default FindLocationModal;
