import { FirebaseOrderDisplayDoc } from "types/orderDisplay";

import { orderDisplaysAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: FirebaseOrderDisplayDoc[] = [];

export default function orderDisplays(
  state = initialState,
  action: orderDisplaysAction
) {
  switch (action.type) {
    case ActionType.RECEIVE_ORDER_DISPLAYS:
      return action.orderDisplays;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
