import { Button, Dialog, Icon } from "@blueprintjs/core";
import React, { ChangeEvent, useState } from "react";

interface Props {
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  options: string[];
  color: string;
}
const Dropdown = ({ onChange, options, color }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className="dropdown-component-container">
      <Icon
        icon="chevron-down"
        color={color}
        onClick={() => setShowModal(true)}
      />
      <input
        type="email"
        value="Type options by clicking the drop down icon"
        className="short-text-component apply-font"
        style={{ borderColor: color, color }}
      />
      <div className="number-of-options apply-font" style={{ color }}>
        {options.length} options added
      </div>

      <Dialog isOpen={showModal} className="add-option-dropdown">
        <div className="modal-header">
          <div className="modal-header-title">Add Options</div>
          <div className="modal-header-subtitle">
            Type in your options below
          </div>
          <Icon
            icon="cross"
            className="close-button"
            color="#231F20"
            iconSize={20}
            onClick={() => setShowModal(false)}
          />
        </div>
        <div className="modal-body">
          <textarea
            placeholder="Type here"
            onChange={onChange}
            value={options.join("\n")}
          />
        </div>
        <Button
          text="Save"
          className="save-button"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Dialog>
    </div>
  );
};

export default Dropdown;
