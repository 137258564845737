import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment-timezone";
import React from "react";

import {
  DailyLaborReportDocumentRow,
  IProductMixReport,
} from "controllers/reporting";

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "components/pdfTable";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  headerCellStyle: {
    fontSize: 10,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
    backgroundColor: "#f4f3f3",
  },
  footerCellStyle: {
    fontSize: 9,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    padding: 4,
  },
  totalFooterCellStyle: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: "SFProText-SFProTextBold",
    color: "#454545",
    fontWeight: "bold",
    padding: 4,
  },
  dataCellStyle: {
    fontSize: 8,
    fontFamily: "SFProText-Semibold",
    color: "#454545",
    padding: 4,
  },
  totalFooter: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: "#f4f3f3",
  },
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type GroupedLaborReportByDepartment = {
  [departmentName: string]: {
    data: DailyLaborReportDocumentRow[];
    totalLabor: number;
    totalHours: number;
    totalSales: number;
  };
};

export type TotalsForReport = {
  totalLabor: number;
  totalHours: number;
  totalSales: number;
};

interface ProductMixReportProps {
  report: IProductMixReport;
  businessName: string;
  snapshot?: boolean;
}

const SalesMixReportDocument = (props: ProductMixReportProps) => {
  const data = props.report.data;
  console.log("Props: ", props.report);
  const reportDateRange = props.snapshot
    ? `${moment(props.report.date).format(
        "DD/MM/YYYY hh:mm a"
      )} - ${moment().format("DD/MM/YYYY hh:mm a")}`
    : `${moment(props.report.date).format("DD/MM/YYYY hh:mm a")} - ${
        !props.report.endDate
          ? moment(props.report.date).add(1, "day").format("DD/MM/YYYY hh:mm a")
          : moment(props.report.endDate)
              .add(1, "day")
              .format("DD/MM/YYYY hh:mm a")
      }`;
  return (
    <Document>
      <Page style={styles.page}>
        <View fixed style={{ marginTop: 20, marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: "SFProText-SFProTextBold",
              color: "#454545",
              fontSize: 14,
            }}
          >
            Sales Mix {props.snapshot ? "Snapshot" : "Report"} for{" "}
            {props.businessName} on{" "}
            {moment(props.report.date).format("DD/MM/YYYY")}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            {props.snapshot ? "Snapshot" : "Report"} is for: {reportDateRange}
          </Text>
          <Text
            style={{
              fontFamily: "SFProText-Semibold",
              color: "#454545",
              fontSize: 8,
              marginTop: 5,
            }}
          >
            Generated on {moment().format("DD/MM/YYYY")} at{" "}
            {moment().format("hh:mm a")}
          </Text>
        </View>
        <Table>
          <TableHeader
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Description
            </TableCell>
            {/*
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Type
            </TableCell>
          */}
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Quantity
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Sales
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Cost
            </TableCell>
            <TableCell textAlign="center" style={styles.headerCellStyle}>
              Profit
            </TableCell>
          </TableHeader>
        </Table>

        {data.map((row, index) => {
          return (
            <Table
              data={row.products}
              key={index}
              style={{
                marginTop: index === 0 ? 0 : 10,
                marginBottom: index === 0 ? 0 : 10,
              }}
            >
              <TableHeader>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  {row.category}
                </TableCell>
                {/* <TableCell textAlign="center" style={styles.headerCellStyle} /> */}
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  {`${row.products
                    .reduce((acc, val) => acc + Math.ceil(val.quantity), 0)
                    .toLocaleString()}`}
                </TableCell>
                <TableCell textAlign="center" style={styles.headerCellStyle}>
                  {`${formatter.format(
                    row.products.reduce((acc, val) => acc + val.sales, 0) / 100
                  )}`}
                </TableCell>
                <TableCell
                  textAlign="center"
                  style={styles.headerCellStyle}
                ></TableCell>
                <TableCell
                  textAlign="center"
                  style={styles.headerCellStyle}
                ></TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => p.productName}
                />
                {/*
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => `${p.type.toLocaleString()}`}
                />
                */}
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) =>
                    `${Math.ceil(p.quantity).toLocaleString()}`
                  }
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => `${formatter.format(p.sales / 100)}`}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => `${formatter.format(p.cost / 100)}`}
                />
                <DataTableCell
                  style={styles.dataCellStyle}
                  getContent={(p) => `${formatter.format(p.profit / 100)}`}
                />
              </TableBody>
            </Table>
          );
        })}

        {/*
        <Table data={data}>
              
                <TableBody>
                    <DataTableCell style={styles.dataCellStyle} getContent={(r) => r.serviceAreaName}/>
                    <DataTableCell style={styles.dataCellStyle} getContent={(r) => `${formatter.format(r.sales / 100)}`}/>
                    <DataTableCell style={styles.dataCellStyle} getContent={(r) => `${(r.meals).toLocaleString()}`}/>
                    <DataTableCell style={styles.dataCellStyle} getContent={(r) => `${formatter.format(r.averageTable / 100)}`}/>
                    <DataTableCell style={styles.dataCellStyle} getContent={(r) => `${(r.turn).toLocaleString()}`}/>
                </TableBody>
                <TableFooter style={{borderTopWidth: 0, backgroundColor: '#f4f3f3'}}>
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    Total
                  </TableCell>
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {`${formatter.format(data.reduce((acc, val) => acc + val.sales, 0) / 100)}`}
                  </TableCell>
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {`${data.reduce((acc, val) => acc + val.meals, 0)}`}
                  </TableCell>
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {`${formatter.format(data.reduce((acc, val) => acc + val.averageTable, 0) / 100)}`}
                  </TableCell>
                  <TableCell textAlign="center" style={styles.footerCellStyle}>
                    {`${data.reduce((acc, val) => acc + val.turn, 0)}`}
                  </TableCell>

                </TableFooter>
              </Table>
          */}
      </Page>
    </Document>
  );
};

export default SalesMixReportDocument;
