import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import useForms from "./useForms";
import HorizontalTable from "components/Table/HorizontalTable";
import { columns, instructions } from "./instructions";
import Layout from "views/Productivity/Layout";

const ViewForms = () => {
  const { forms } = useForms();
  const navigate = useNavigate();
  return (
    <Layout>

      <div id="view-forms-page" className="px-8">
        <HorizontalTable
          title="Forms"
          columns={columns}
          data={forms}
          instructions={instructions}
          setEditing={() => console.log('yey')}
          isEditing={false}
          primaryActionLabel="Create New Form"
          primaryActionHandler={() => navigate("/survey-builder")}
          hideEdit
          deleteIds={(ids) => console.log(ids)}
          onRowClick={(rowId) => {

            navigate(`/form/${rowId}/responses`)
          }}
          filterItems={(item, text) => {
            if (item.name.search(text) >= 0) {
              return true
            } else {
              return false

            }
          }}
        />
      </div>
    </Layout>

  );
};
export default ViewForms;
