import { Dialog, Icon } from "@blueprintjs/core";
import React, { useState } from "react";

import "./index.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  businesses: TangoBusiness[];
  updateLocation: (
    location: TangoBusiness,
    isSelected: boolean,
    index: number
  ) => void;
  selectedLocations: any;
}

const FindLocationModal = ({
  isOpen,
  onClose,
  businesses,
  updateLocation,
  selectedLocations,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const isLocationSelected = (location: any) => {
    let index = selectedLocations.findIndex(
      (item: any) => item.id === location.id
    );
    return index !== -1;
  };

  return (
    <div id="addEmployeeModal">
      <Dialog
        portalClassName="location-modal-portal"
        isOpen={isOpen}
        onClose={onClose}
        className="find-location"
      >
        <div className="header">
          <Icon
            icon="cross"
            className="close-button"
            color="#424452"
            iconSize={20}
            onClick={onClose}
          />
          <span>Find Location</span>
          <span className="selectallbtn">Select All</span>
        </div>
        <div className="search-location">
          <div className="to">To:</div>
          <input
            placeholder="Search for a location by store number or location "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="body">
          <ul className="location-list">
            {businesses.map((item, index) => (
              <li
                onClick={() =>
                  updateLocation(item, isLocationSelected(item), index)
                }
                className={isLocationSelected(item) ? "active" : ""}
              >
                <Icon
                  icon={isLocationSelected(item) ? "tick" : "plus"}
                  color="#4385EF"
                />
                {item.businessName}
              </li>
            ))}
          </ul>
          {/*]] {
          selectedLocations.length > 0 && <Button text="Continue" className="continue-button" onClick={onClose}/>
        } */}
        </div>
      </Dialog>
    </div>
  );
};
//className={item.name === selectedLocation ? 'active':''}
export default FindLocationModal;
