import UIComponents from "@tangopay/tango-ui-library";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const MergeCalendarModal = ({ isOpen, onClose }: Props) => {
  return (
    <UIComponents.Modal isOpen={isOpen} closeModal={onClose}>
      <div className={"text-2xl font-lato-black text-black"}>Merge?</div>
      <div className={"text-sm font-lato-bold text-grey-2"}>
        Merge events with another calendar in the same account and delete this
        calendar.
      </div>
      <UIComponents.ModalButton
        label={"Merge"}
        type={"secondary"}
        className={"mt-9 !bg-black !text-white mb-4"}
      />
      <UIComponents.ModalButton
        label={"Cancel"}
        type={"secondary"}
        onClick={onClose}
      />
    </UIComponents.Modal>
  );
};
export default MergeCalendarModal;
