import { Intent, Position, Toaster } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import firebase from "config/firebase";
import moment from "moment";

import { DocumentData } from "types/document";

import store from "model/store";

import { addListener } from "./listeners";

const { firestore } = firebase;

export const baseUrl = `https://us-central1-tango-2.cloudfunctions.net`;
// export const baseUrl = `http://localhost:9001/tango-2/us-central1`;
export const apiCall = (endpoint: string) => `${baseUrl}/${endpoint}`;

export const fetchCollectionDocument = async <T>(
  collectionPath: string,
  documentId: string,
  action: (result: T) => void
): Promise<void> => {
  const sn = await firestore().collection(collectionPath).doc(documentId).get();

  if (sn.exists) {
    if (!sn.data()?.deleted) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store.dispatch(action(sn.data() as T));
    }
  }
};

const toaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

export const apiErrorHandler = (error: any) => {
  console.log("apiErrorHandler", error?.response?.data);
  Sentry.captureException(error);
  toaster.show({
    message:
      error?.response?.data?.message ??
      error?.message ??
      "Something went wrong here...",
    intent: Intent.DANGER,
  });
};

export const generalErrorMessageDisplay = (message: string) => {
  toaster.show({
    message,
    intent: Intent.DANGER,
  });
};

export const subscribeToCollectionDocument = <T>(
  collectionPath: string,
  documentId: string,
  action: (result: T) => void,
  listenerName: string | null,
  composer: (result: DocumentData) => T,
  customUnsubscribe?: (u: () => void) => void,
  additionalReceiver?: (result: T) => void
): void => {
  const unsubscribe = firestore()
    .collection(collectionPath)
    .doc(documentId)
    .onSnapshot(
      (listenerSn) => {
        if (!listenerSn.data()?.deleted) {
          const data = listenerSn.data() as T;
          if (data) {
            const result = composer(data);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            store.dispatch(action(result));
            if (additionalReceiver) {
              additionalReceiver(result);
            }
          }
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.warn(`fetch ${listenerName || ""} error`, err);
      }
    );
  if (listenerName) addListener(listenerName, unsubscribe);
  if (customUnsubscribe) customUnsubscribe(unsubscribe);
};

export const subscribeToCollectionDocumentsForDataInThePastNMonths = <T>(
  collectionPath: string,
  condition: [string, firebase.firestore.WhereFilterOp, string], // TODO Paul figure out a way to pass arbitrary number of conditions
  action: (result: T[]) => void,
  listenerName: string,
  composer: (result: DocumentData) => T,
  months: number
): void => {
  const date = new Date();

  if (months === 1) {
    const unsubscribeSingleMonth = firestore()
      .collection(collectionPath)
      .where(condition[0], condition[1], condition[2])
      .where("createdAt", ">=", moment().subtract(1, "month").toDate())
      .onSnapshot(
        (listenerSn) => {
          const result: T[] = [];
          listenerSn.forEach((doc) => {
            const data = doc.data();
            if (data && !data.deleted) {
              // console.log("DATA: ", data)
              result.push(composer(data));
            }
          });
          // @ts-ignore
          store.dispatch(action(result));
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.warn(`fetch ${listenerName} error`, err);
        }
      );
    addListener(listenerName, unsubscribeSingleMonth);
  } else {
    const unsubscribeMultipleMonths = firestore()
      .collection(collectionPath)
      .where(condition[0], condition[1], condition[2])
      .where("createdAt", ">=", moment().subtract(months, "months").toDate())
      .onSnapshot(
        (listenerSn) => {
          const result: T[] = [];
          listenerSn.forEach((doc) => {
            const data = doc.data();
            if (data && !data.deleted) {
              // console.log("DATA: ", data)
              result.push(composer(data));
            }
          });
          // @ts-ignore
          store.dispatch(action(result));
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.warn(`fetch ${listenerName} error`, err);
        }
      );
    addListener(listenerName, unsubscribeMultipleMonths);
  }
};

export const subscribeToCollectionDocumentsWithTwoConditions = <T>(
  collectionPath: string,
  condition: [string, firebase.firestore.WhereFilterOp, string],
  secondCondition: [string, firebase.firestore.WhereFilterOp, any],
  action: (result: T[]) => void,
  listenerName: string,
  composer: (result: DocumentData) => T
): void => {
  const unsubscribe = firestore()
    .collection(collectionPath)
    .where(condition[0], condition[1], condition[2])
    .where(secondCondition[0], secondCondition[1], secondCondition[2])
    .onSnapshot(
      (listenerSn) => {
        console.log("Second condition filter working");
        const result: T[] = [];
        listenerSn.forEach((doc) => {
          const data = doc.data();
          if (data && !data.deleted) {
            // console.log("DATA: ", data)
            result.push(composer(data));
          }
        });
        // @ts-ignore
        store.dispatch(action(result));
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.warn(`fetch ${listenerName} error`, err);
      }
    );
  addListener(listenerName, unsubscribe);
};

export const subscribeToCollectionDocuments = <T>(
  collectionPath: string,
  condition: [string, firebase.firestore.WhereFilterOp, any], // TODO Paul figure out a way to pass arbitrary number of conditions
  action: (result: T[]) => void,
  listenerName: string,
  composer: (result: DocumentData) => T | null
): void => {
  const unsubscribe = firestore()
    .collection(collectionPath)
    .where(condition[0], condition[1], condition[2])
    .onSnapshot(
      (listenerSn) => {
        const result: T[] = [];
        listenerSn.forEach((doc) => {
          const data = doc.data();
          if (data && data.deleted === false) {
            // console.log("DATA: ", data)
            const composedData = composer(data);
            if (composedData) {
              result.push(composedData);
            }
          }
        });
        if (result.length > 0) {
          // @ts-ignore
          store.dispatch(action(result));
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.warn(`fetch ${listenerName} error`, err);
      }
    );
  addListener(listenerName, unsubscribe);
};

export const subscribeToSubCollectionDocuments = <T>(
  collectionPath: string,
  subCollectionPath: string,
  businessId: string,
  condition: [string, firebase.firestore.WhereFilterOp, string],
  action: (result: T[]) => void,
  listenerName: string,
  composer: (result: DocumentData) => T | null
): void => {
  const unsubscribe = firestore()
    .collection(collectionPath)
    .doc(businessId)
    .collection(subCollectionPath)
    .where(condition[0], condition[1], condition[2])
    .where("deleted", "==", false)
    .onSnapshot(
      (listenerSn) => {
        const result: T[] = [];
        listenerSn.forEach((doc) => {
          const data = doc.data();
          if (data) {
            const composedData = composer(data);
            if (composedData) {
              result.push(composedData);
            }
          }
        });

        store.dispatch(action(result));
      },
      (err) => {
        console.warn(`fetch ${listenerName} error`, err);
      }
    );
  addListener(listenerName, unsubscribe);
};
