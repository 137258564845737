import { Alert, Button, Icon, Switch } from "@blueprintjs/core";
import firebase from "config/firebase";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  GiftCard,
  createOrEditGiftCard,
  deleteGiftCard,
  fetchGiftCardsByBusinessId,
} from "models/giftCards";

import { saveFile } from "utils/storage";

import { RootState } from "model/store";

import Layout from "../Layout";
import "./index.css";

const GiftCards = () => {
  const [showGiftCardForm, setShowGiftCardForm] = useState(false);
  const [cards, setCards] = useState<GiftCard[]>([]);
  const [cardCategory, setCardCategory] = useState<string>("");
  const [cardType, setCardType] = useState<string>("0");
  const [isCardActive, setIsCardActive] = useState<boolean>(false);
  const [cardImageUrl, setCardImageUrl] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [selectedCard, setSelectedCard] = useState<GiftCard | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [file, setFile] = useState();

  const fileInputRef = useRef();
  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    const fetchData = async () => {
      if (business && business.id) {
        const response = await fetchGiftCardsByBusinessId(business.id);
        if (response.data) {
          setCards(response.data);
        }
      }
    };
    fetchData();
  }, [business]);

  useEffect(() => {
    if (cardImageUrl && cardImageUrl.length > 0 && cardCategory.length > 3) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [cardImageUrl, cardCategory]);
  const resetState = () => {
    setCardCategory("");
    setCardType("0");
    setIsCardActive(false);
    setCardImageUrl("");
    setSelectedCard(null);
    setFile(undefined);
  };
  const createOrEditCard = async () => {
    if (business && business.id) {
      let imageUrl = cardImageUrl;
      setIsLoading(true);
      if (cardImageUrl && !cardImageUrl.includes("https")) {
        // @ts-ignore
        const fileName = file ? file.name : "";

        // @ts-ignore
        const fileUrl = await saveFile(
          `GiftCards/${business.id}/${fileName.split(".")[0]}`,
          cardImageUrl
        );
        imageUrl = fileUrl;
        setCardImageUrl(fileUrl);
      }
      const cardData: Partial<GiftCard> = {
        businessId: business.id,
        imageUrl: imageUrl || "",
        themeName: cardCategory,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
        deleted: false,
        enabled: isCardActive,
        isPhysicalCard: cardType === "0" ? false : true,
        id: selectedCard ? selectedCard.id : "",
      };
      const response = await createOrEditGiftCard(
        cardData,
        selectedCard ? true : false
      );
      if (response.data) {
        const newCards = [...cards];
        if (selectedCard) {
          const updatedCards = newCards.map((card) => {
            if (card.id === selectedCard.id) {
              return { ...response.data };
            } else {
              return card;
            }
          });
          //@ts-ignore
          setCards(updatedCards);
        } else {
          //@ts-ignore
          newCards.push(response.data);
          setCards(newCards);
        }

        setShowGiftCardForm(false);
        resetState();
      } else {
        console.log(response.error);
      }
      setIsLoading(false);
    }
  };

  const onImageLoad = (e: any) => setCardImageUrl(e.target.result);

  const onFileInputChange = (e: any) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", onImageLoad);
    if (e.target.files.length > 0) {
      // console.log(e.target.files[0]);
      setFile(e.target.files[0]);
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <Layout>
      <div id="gift-cards-page">
        <div className="body">
          <div className="left-part">
            <div className="side-nav">
              <div className="nav-title">Gift Cards</div>
              <div
                className={`side-nav-menu-item  ${
                  !showGiftCardForm && "active"
                }`}
                onClick={() => {
                  setShowGiftCardForm(false);
                  resetState();
                }}
              >
                Gift Cards
              </div>
              <div
                className={`side-nav-menu-item ${showGiftCardForm && "active"}`}
                onClick={() => setShowGiftCardForm(true)}
              >
                New Card
              </div>
            </div>
          </div>
          <div className="right-part">
            <div className="select-card-header">
              <div className="text-center extra-bold f19">
                {showGiftCardForm
                  ? selectedCard
                    ? "Edit gift card"
                    : "Create a new gift card"
                  : "My Gift Cards"}
              </div>
              <div className="text-center bold f12">
                {showGiftCardForm
                  ? selectedCard
                    ? "Change the details on this gift card"
                    : "Add details to the new card"
                  : "Add new cards and edit existing ones"}
              </div>
              {showGiftCardForm && (
                <div
                  className="back-button"
                  onClick={() => {
                    resetState();
                    setShowGiftCardForm(false);
                  }}
                >
                  <Icon icon="chevron-left" color="#231F20" /> My Cards
                </div>
              )}
            </div>
            {showGiftCardForm && (
              <div className="buy-card-form">
                <div className="form-container">
                  {business && business.profileImageUrl && (
                    <img
                      className="business-icon"
                      src={business.profileImageUrl}
                    />
                  )}
                  {/* @ts-ignore */}
                  <div
                    className="file-drop"
                    // @ts-ignore
                    onClick={() => fileInputRef.current.click()}
                  >
                    {cardImageUrl ? (
                      <>
                        <img
                          src={require("assets/remove.png").default}
                          className="remove-image-icon"
                        />{" "}
                        <img
                          src={cardImageUrl}
                          style={{ width: "100%", height: "125px" }}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            require("../../../assets/file_upload_black.svg")
                              .default
                          }
                        />
                        <div className="file-drop-text">upload</div>
                      </>
                    )}

                    {/* @ts-ignore */}
                    <input
                      type="file"
                      // @ts-ignore
                      ref={fileInputRef}
                      hidden
                      onChange={onFileInputChange}
                    />
                  </div>
                  <div className="input-container">
                    <div>Card Category</div>
                    <input
                      value={cardCategory}
                      onChange={(e) => setCardCategory(e.target.value)}
                    />
                  </div>
                  <div className="input-container">
                    <div>Card Type</div>
                    <select
                      value={cardType}
                      onChange={(e) => setCardType(e.target.value)}
                    >
                      <option disabled>Please select the type</option>
                      <option value={"0"}>Digital Card</option>
                      <option value={"1"}>Physical & Digital Card</option>
                    </select>
                  </div>
                  <div
                    className="input-container"
                    style={{ paddingBottom: "1px" }}
                  >
                    <div>Card Status</div>
                    <Switch
                      checked={isCardActive}
                      label={isCardActive ? "Active" : "In Active"}
                      alignIndicator="right"
                      large
                      onChange={(e) => setIsCardActive(!isCardActive)}
                    />
                  </div>
                  {selectedCard && (
                    <div
                      className="delete-gift-card"
                      onClick={() => setShowConfirmation(true)}
                    >
                      Delete Gift Card
                    </div>
                  )}
                </div>
              </div>
            )}
            {!showGiftCardForm && (
              <div className="cards-section">
                <div className="new-card-container">
                  <div className="card-section-title">New Card</div>
                  <div
                    className="new-card-box"
                    onClick={() => setShowGiftCardForm(true)}
                  >
                    <Icon icon="add" color="#C4C4C4" />
                  </div>
                </div>
                <div className="new-card-container">
                  {cards.length > 0 && (
                    <div className="card-section-title">My Cards</div>
                  )}
                  <div className="cards-list">
                    {cards.map((card) => (
                      <div
                        className="single-card"
                        key={card.id}
                        onClick={() => {
                          setSelectedCard(card);
                          setCardCategory(card.themeName);
                          setCardType(card.isPhysicalCard ? "1" : "0");
                          setIsCardActive(card.enabled);
                          setCardImageUrl(card.imageUrl);
                          setShowGiftCardForm(true);
                        }}
                      >
                        <img src={card.imageUrl} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {showGiftCardForm && (
              <Button
                text={"Done"}
                className="add-money"
                disabled={isButtonDisabled}
                onClick={createOrEditCard}
                loading={isLoading}
              />
            )}
            <Alert
              confirmButtonText="Okay"
              isOpen={showConfirmation}
              cancelButtonText="Cancel"
              onConfirm={async () => {
                if (selectedCard) {
                  const response = await deleteGiftCard(selectedCard.id);
                  if (response.data) {
                    const existingCards = [...cards];
                    const indexOfCard = existingCards.findIndex(
                      (item) => item.id === response.data
                    );
                    existingCards.splice(indexOfCard, 1);
                    setCards(existingCards);
                    resetState();
                    setShowConfirmation(false);
                    setShowGiftCardForm(false);
                  }
                }
              }}
              onCancel={() => setShowConfirmation(false)}
            >
              <p>Are You sure that you want to delete this card?</p>
            </Alert>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GiftCards;
