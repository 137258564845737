import * as React from "react";

export const EmployeeType = ({
  height = "24px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M11.5 6.75L10.4425 5.685L5.5 10.6275L3.5575 8.6925L2.5 9.75L5.5 12.75L11.5 6.75ZM12.25 2.25H9.115C8.8 1.38 7.975 0.75 7 0.75C6.025 0.75 5.2 1.38 4.885 2.25H1.75C1.645 2.25 1.5475 2.2575 1.45 2.28C1.1575 2.34 0.895 2.49 0.6925 2.6925C0.5575 2.8275 0.445 2.9925 0.37 3.1725C0.295 3.345 0.25 3.54 0.25 3.75V14.25C0.25 14.4525 0.295 14.655 0.37 14.835C0.445 15.015 0.5575 15.1725 0.6925 15.315C0.895 15.5175 1.1575 15.6675 1.45 15.7275C1.5475 15.7425 1.645 15.75 1.75 15.75H12.25C13.075 15.75 13.75 15.075 13.75 14.25V3.75C13.75 2.925 13.075 2.25 12.25 2.25ZM7 2.0625C7.3075 2.0625 7.5625 2.3175 7.5625 2.625C7.5625 2.9325 7.3075 3.1875 7 3.1875C6.6925 3.1875 6.4375 2.9325 6.4375 2.625C6.4375 2.3175 6.6925 2.0625 7 2.0625ZM12.25 14.25H1.75V3.75H12.25V14.25Z"
      fill={color}
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
