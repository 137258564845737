import moment from "moment-timezone";
import React from "react";

import { DepartmentId } from "controllers/staff";

import Box from "components/Box";

import {
  AvailabilitiesContent,
  AvailabilityForADay,
} from "./AvailabilitiesContent";
import { StaffMemberOrientedViewControls } from "./StaffMemberOrientedViewControls";
import { StaffMemberOrientedWeeklyView } from "./StaffMemberOrientedWeeklyView";
import { WeeklyShifts } from "./WeeklyShifts";
import { WeeklyScheduleViewType } from "./WeeklyViewSelect";

interface StaffWeeklyContentProps {
  isAvailabilityView: boolean;
  weekRange: Date[];
  filteredStaffMembersAvailability: StaffMember[];
  generateAvailabilitiesForADay: (d: Date) => AvailabilityForADay[];
  weeklyScheduleViewType: WeeklyScheduleViewType;

  schedulesWithLocalSchedule: TangoSchedule[];
  business: TangoBusiness;
  fixedSchedules: TangoFixedSchedule[];
  selectedDepartment: "boh" | "foh" | undefined;
  departmentScheduleViewType: DepartmentId | null;
  businessSettings: TangoBusinessSettings;
  setShowSingleDay: (v: boolean) => void;
  jobFunctions: TangoJobFunctions;

  setWeeklyStaffMemberViewFilterAnchorEl: (t: any) => void;
  weeklyStaffMemberViewFilterAnchorEl: any;
  onFullNameInputChangeDebounced: (e: any) => void;
  roleFilterId: string | null;
  setRoleFilterId: (v: any) => void;

  scheduleForWeekRange: TangoSchedule | null;
  duplicatedScheduleForAWeekRange: TangoSchedule | null;
  staffMemberNameFilter: string;
  fellowStaffMembers: StaffMember[];
  setSelectedShiftForEdit: (v: TangoShift | null) => void;
}

export const StaffWeeklyContent = ({
  isAvailabilityView = false,
  weekRange,
  filteredStaffMembersAvailability,
  generateAvailabilitiesForADay,
  weeklyScheduleViewType,
  schedulesWithLocalSchedule,
  business,
  fixedSchedules,
  selectedDepartment,
  departmentScheduleViewType,
  businessSettings,
  setShowSingleDay,
  jobFunctions,
  setWeeklyStaffMemberViewFilterAnchorEl,
  weeklyStaffMemberViewFilterAnchorEl,
  onFullNameInputChangeDebounced,
  roleFilterId,
  setRoleFilterId,

  scheduleForWeekRange,
  duplicatedScheduleForAWeekRange,
  staffMemberNameFilter,
  fellowStaffMembers,
  setSelectedShiftForEdit,
}: StaffWeeklyContentProps) => {
  if (isAvailabilityView) {
    return (
      <AvailabilitiesContent
        weekRange={weekRange}
        filteredStaffMembersAvailability={filteredStaffMembersAvailability}
        generateAvailabilitiesForADay={generateAvailabilitiesForADay}
      />
    );
  }
  if (weeklyScheduleViewType === "shift_view") {
    return (
      <Box style={{ width: "80vw", overflowX: "scroll", height: "90vh" }}>
        <Box
          flexDirection="column"
          style={{
            paddingBottom: "15%",
            minWidth: 1500,
            width: "100%",
            overflowX: "scroll",
          }}
        >
          <Box display="flex" flexDirection="row">
            {weekRange.map((item: any) => (
              <Box className="day-column" key={new Date(item).toString()}>
                <Box className="day-header">
                  <div className="day-name">{moment(item).format("ddd")}</div>
                  <div className="day-date">{moment(item).format("D")}</div>
                </Box>
              </Box>
            ))}
          </Box>
          <Box display="flex" flexDirection="row">
            {weekRange.map((item: Date) => (
              <Box className="day-column" key={new Date(item).toString()}>
                <WeeklyShifts
                  dayOfWeek={item}
                  weekRange={weekRange}
                  schedulesWithLocalSchedule={schedulesWithLocalSchedule}
                  business={business}
                  fixedSchedules={fixedSchedules}
                  selectedDepartment={selectedDepartment}
                  departmentScheduleViewType={departmentScheduleViewType}
                  businessSettings={businessSettings}
                  setShowSingleDay={setShowSingleDay}
                  jobFunctions={jobFunctions}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <StaffMemberOrientedWeeklyView
      weekRange={weekRange}
      business={business}
      businessSettings={businessSettings}
      departmentScheduleViewType={departmentScheduleViewType}
      scheduleForWeekRange={scheduleForWeekRange}
      duplicatedScheduleForAWeekRange={duplicatedScheduleForAWeekRange}
      roleFilterId={roleFilterId}
      staffMemberNameFilter={staffMemberNameFilter}
      fellowStaffMembers={fellowStaffMembers}
      setSelectedShiftForEdit={setSelectedShiftForEdit}
    />
  );
  return (
    <StaffMemberOrientedViewControls
      setWeeklyStaffMemberViewFilterAnchorEl={
        setWeeklyStaffMemberViewFilterAnchorEl
      }
      weeklyStaffMemberViewFilterAnchorEl={weeklyStaffMemberViewFilterAnchorEl}
      onFullNameInputChangeDebounced={onFullNameInputChangeDebounced}
      roleFilterId={roleFilterId}
      businessSettings={businessSettings}
      jobFunctions={jobFunctions}
      setRoleFilterId={setRoleFilterId}
    />
  );
};
