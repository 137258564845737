import { Dialog } from "@blueprintjs/core";
import React, { useEffect } from "react";

import Email from "../SurveyBuilder/components/admin/Email";
import Legal from "../SurveyBuilder/components/admin/Legal";
import PhoneInput from "../SurveyBuilder/components/admin/Phone";
import UrlInput from "../SurveyBuilder/components/admin/Url";
import YesNo from "../SurveyBuilder/components/admin/YesNo";
import CorrectOrder from "../SurveyBuilder/components/client/CorrectOrder";
import DigitalSignature from "../SurveyBuilder/components/client/DigitalSignature";
import Dropdown from "../SurveyBuilder/components/client/Dropdown";
import FillIntheBlank from "../SurveyBuilder/components/client/FillInTheBlank";
import LabelPicture from "../SurveyBuilder/components/client/LabelPicture";
import Matching from "../SurveyBuilder/components/client/Matching";
import MultipleChoice from "../SurveyBuilder/components/client/MultipleChoice";
import PictureChoice from "../SurveyBuilder/components/client/PictureChoice";
import Rate from "../SurveyBuilder/components/client/Ratings";
import ShortText from "../SurveyBuilder/components/client/ShortText";
import UploadFile from "../SurveyBuilder/components/client/UploadFile";
import { themesData } from "../SurveyBuilder/data";
import "../SurveyBuilder/form.css";
import { ComponentType } from "../SurveyBuilder/types";
import { Form } from "../ViewForms/useForms";
import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
  selectedResponse: any | null;
  formData: Form;
  updateScore: (responseIndex: number, score: number) => void;
}
const FormModal = ({
  showModal,
  onClose,
  selectedResponse,
  formData,
  updateScore,
}: Props) => {
  const renderComponent = (question: any) => {
    switch (question.type) {
      case ComponentType.shortText:
      case ComponentType.longText:
      case ComponentType.email:
      case ComponentType.url:
        return (
          <ShortText
            color={themesData[0].answerColor}
            onChange={(e) => console.log("yey")}
            value={question.value || ""}
          />
        );
      case ComponentType.phone:
        return (
          <PhoneInput
            color={themesData[0].answerColor}
            phoneNumber={question.value || ""}
            onChange={(phoneNumber) => console.log("yey")}
          />
        );

      case ComponentType.multipleChoice:
        return (
          <MultipleChoice
            selectedChoices={question.selectedChoices}
            options={question.choices || []}
            onSelect={() => console.log("")}
            color={themesData[0]?.answerColor || "#000"}
          />
        );
      case ComponentType.pictureChoice:
        return (
          <PictureChoice
            color={themesData[0]?.answerColor || "#000"}
            selectedChoices={question.value}
            options={question.choices || []}
            onSelect={() => console.log("")}
            images={question.images || []}
          />
        );
      case ComponentType.correctOrder:
        return (
          <CorrectOrder
            color={themesData[0].answerColor}
            options={question.choices || []}
            updateChoice={() => console.log("")}
          />
        );
      case ComponentType.yesNo:
        return (
          <YesNo
            color={themesData[0]?.answerColor || "#000"}
            updateChoice={(value) => console.log("")}
            value={question.value}
          />
        );
      case ComponentType.dropdown:
        return (
          <Dropdown
            color={themesData[0].answerColor}
            options={question.choices}
            selectedVale={question.value}
            onChange={(option: string) => console.log("")}
          />
        );
      case ComponentType.ratings:
        return (
          <Rate
            color={themesData[0].answerColor}
            rating={parseInt(question.value || "")}
            onRating={(rating) => console.log("")}
          />
        );
      case ComponentType.uploadFile:
      case ComponentType.digitalSignature:
        return (
          <UploadFile
            color={themesData[0].answerColor}
            handleFileChange={() => console.log("")}
            selectedValue={question.value || ""}
          />
        );
      case ComponentType.legal:
        return (
          <Legal
            updateChoice={(value) => console.log("")}
            value={question.value}
            color={themesData[0].answerColor}
          />
        );

      case ComponentType.labelPicture:
        return (
          <LabelPicture
            updateChoice={() => console.log("")}
            value={question.value || ""}
            color={themesData[0].answerColor}
            images={question.images}
          />
        );
      case ComponentType.fillInBlank:
        return (
          <FillIntheBlank
            color={themesData[0].answerColor}
            value={question.fillInBlankChoices}
            selectedAnswers={question.selectedChoices}
            updateAnswer={() => console.log("")}
          />
        );
      case ComponentType.matching:
        return (
          <Matching
            color={themesData[0].answerColor}
            value={question.matchingObj}
            updateChoice={() => console.log("")}
          />
        );
    }
  };
  return (
    <Dialog
      onClose={onClose}
      className="form-modal"
      hasBackdrop
      isOpen={showModal}
    >
      <div className="form-modal-header">
        <div className="form-name">{formData.name}</div>
        <div className="response-time">
          Response submitted on 2022-06-05 at 12:04 PM
        </div>
      </div>
      <div className="survey-form-container">
        <div className="questions-container">
          {selectedResponse
            ? selectedResponse.response.map((item: any, index: number) => {
                const showScoreInput =
                  formData.questions[index].points &&
                  formData.questions[index].hasAnswer;
                return (
                  <div
                    className={`question-container ${
                      showScoreInput ? "pending-score" : ""
                    }`}
                    key={item.id}
                  >
                    {showScoreInput ? (
                      <div className="score-input-container">
                        <input
                          value={item.score}
                          onChange={(e) => {
                            const score = parseInt(e.target.value);
                            if (score <= formData.questions[index].points) {
                              updateScore(index, parseInt(e.target.value));
                            }
                          }}
                        />
                        /<span>{formData.questions[index].points}</span>
                      </div>
                    ) : null}
                    <div
                      className="question-title apply-font"
                      style={{ color: themesData[0].questionColor }}
                    >
                      <div className="question-number">{item.id}</div>
                      {item.title.replace(
                        "{{{name}}}",
                        item.username ? item.username : ""
                      )}
                    </div>

                    <div
                      className="question-subtitle apply-font"
                      style={{ color: themesData[0]?.questionColor }}
                    >
                      {item.subtitle}
                    </div>
                    {renderComponent(item)}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Dialog>
  );
};

export default FormModal;
