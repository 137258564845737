import firebase from "config/firebase";
import React from "react";
import { Navigate } from "react-router-dom";

type PublicRouteWrapper = {
  children: any;
  restricted: boolean;
};

const PublicRouteWrapper = ({ children, restricted }: PublicRouteWrapper) => {
  if (firebase.auth().currentUser && restricted) {
    return <Navigate to="/" />;
  }
  return <React.Suspense fallback={<>Loading...</>}>{children}</React.Suspense>;
};

export default PublicRouteWrapper;
