import { useState } from "react";

import Layout from "../Layout";
import CashDrawers from "./CashDrawers";
import OrderDisplay from "./OrderDisplay";
import PrinterSetup from "./PrinterSetup";
import "./style.css";

const DeviceSetup = () => {
  const ORDER_DISPLAY = "Order Display";
  const CASH_DRAWERS = "Cash Drawers";
  const PRINTERS = "Printers";
  const [selectedNavigation, setSelectedNavigation] = useState(ORDER_DISPLAY);

  return (
    <Layout>
      <div id="device-setup">
        <div className="navigation">
          <div
            className={
              selectedNavigation === CASH_DRAWERS ? `button active` : `button`
            }
            onClick={() => setSelectedNavigation(CASH_DRAWERS)}
          >
            Cash Drawers
          </div>
          <div
            className={
              selectedNavigation === ORDER_DISPLAY ? `button active` : `button`
            }
            onClick={() => setSelectedNavigation(ORDER_DISPLAY)}
          >
            Order Display
          </div>
          <div
            className={
              selectedNavigation === PRINTERS ? `button active` : `button`
            }
            onClick={() => setSelectedNavigation(PRINTERS)}
          >
            Printers
          </div>
        </div>
        <div className="content">
          <div className="section-name">{selectedNavigation}</div>
          <div className="section-body">
            {selectedNavigation === ORDER_DISPLAY && <OrderDisplay />}
          </div>
          <div className="section-body">
            {selectedNavigation === CASH_DRAWERS && <CashDrawers />}
          </div>
          <div className="section-body">
            {selectedNavigation === PRINTERS && <PrinterSetup />}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeviceSetup;
