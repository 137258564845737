import React, { useRef } from "react";

import Box from "components/Box";

interface Props {
  updateChoice: (value: string) => void;
  value: string | undefined;
  color: string;
  images: string[];
}
const LabelPicture = ({ updateChoice, value = "", color, images }: Props) => {
  const refs = useRef([]);
  return (
    <div className="label-picture-component">
      <div
        className={`single-choice-container active`}
        style={{ borderColor: color }}
      >
        {/*@ts-ignore*/}
        <img src={images[0]} alt="" />
        <Box display="flex">
          <input
            style={{ color }}
            placeholder="add label here"
            className="choice-name apply-font"
            value={value || ""}
            onChange={(e) => updateChoice(e.target.value)}
          />
        </Box>
      </div>
    </div>
  );
};
export default LabelPicture;
