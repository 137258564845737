import { AccountAction } from "model/types";

import ActionType from "../actions/actionTypes";

const initialState: TangoAccount | null = null;

export default function account(state = initialState, action: AccountAction) {
  switch (action.type) {
    case ActionType.RECEIVE_ACCOUNT:
      return action.account;
    case ActionType.LOG_OUT:
      return initialState;
    default:
      return state;
  }
}
