import { Button, Position } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import React from "react";

import Box from "components/Box";

interface Props {
  onNext: () => void;
}
const HouseKeeping = (props: Props) => (
  <Box className="form-container">
    <div className="slide-in">
      <div className="welcome-title no-width">
        Awesome!
        <br />
        Ok, a few housekeeping questions..
      </div>
      <DateInput
        className="open-date-selector"
        formatDate={(date) => date.toLocaleDateString("en-US")}
        parseDate={(str) => new Date(Date.parse(str))}
        placeholder="Please select a date"
        defaultValue={new Date()}
        popoverProps={{ position: Position.BOTTOM }}
      />
      <Button text="Next" fill className="next-button" onClick={props.onNext} />
    </div>
  </Box>
);
export default HouseKeeping;
