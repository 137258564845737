import { Spinner, SpinnerSize } from "@blueprintjs/core";
import tangoComponents from "@tangopay/tango-ui-library";
import { useMutation } from "@tanstack/react-query";
import parsePhoneNumber, {
  AsYouType,
  isValidPhoneNumber,
} from "libphonenumber-js";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  addTeammate,
  checkEmailFieldForStaffSignUp,
  checkPhoneNumberFieldForStaffSignUp,
} from "controllers/auth";
import { apiErrorHandler } from "controllers/core";

import HorizontalTable from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import { useTeamTable } from "../../hooks/useTeamTable";

const { Button, TextField, Modal, Dropdown } = tangoComponents;

interface CreateEmployeeModalFlowProps {
  isOpen: boolean;
  onClose: () => void;
}

type CreateEmployeeStep =
  | "basic_info"
  | "job_role"
  | "compensation"
  | "phone_number_exists"
  | "email_exists"
  | "success";

const CreateEmployeeModalFlow = (props: CreateEmployeeModalFlowProps) => {
  const [step, setStep] = React.useState<CreateEmployeeStep>("basic_info");
  const ayt = new AsYouType("US");

  const [firstName, setFirstName] = React.useState("");

  const [emailCheckError, setEmailCheckError] = useState<string | null>(null);
  const [phoneNumberCheckError, setPhoneNumberCheckError] = useState<
    string | null
  >(null);

  const [employmentType, setEmploymentType] = React.useState<{
    value: "full-time" | "part-time" | "intern";
    label: string;
  } | null>(null);

  const [permissions, setPermissions] = React.useState<{
    value: "admin" | "basic_employee";
    label: string;
  } | null>(null);

  const [primaryRole, setPrimaryRole] = React.useState<{
    value: string;
    label: string;
  } | null>(null);

  const [payRates, setPayRates] = React.useState<
    { amount: number; role: string; roleId: string }[]
  >([]);
  const [secondaryRoles, setSecondaryRoles] = React.useState<
    { value: string; label: string }[]
  >([]);

  const [emailAndPhoneValidationLoading, setEmailAndPhoneValidationLoading] =
    useState(false);

  const handleFirstNameChange = useCallback(
    (e: any) => {
      setFirstName(e.target.value);
    },
    [setFirstName]
  );

  const [lastName, setLastName] = React.useState("");

  const handleLastNameChange = useCallback(
    (e: any) => {
      setLastName(e.target.value);
    },
    [setLastName]
  );

  const [email, setEmail] = React.useState("");

  const handleEmailChange = useCallback(
    (e: any) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const [phone, setPhone] = React.useState("");

  const [createEmployeeLoading, setCreateEmployeeLoading] = useState(false);

  const business: TangoBusiness = useSelector(
    (state: RootState) => state.business
  );

  const businessSettings: TangoBusinessSettings = useSelector(
    (state: RootState) => state.businessSettings
  );

  const availableRoles = useMemo(() => {
    if (!businessSettings) return [];
    const jobFunctions = businessSettings.jobFunctions;
    if (!jobFunctions) {
      return [];
    }
    return _.values(jobFunctions).map((v) => ({ value: v.id, label: v.title }));
  }, [businessSettings]);

  const availablePrimaryRoles = useMemo(() => {
    return availableRoles.filter(
      (r) => !secondaryRoles.find((s) => s.value === r.value)
    );
  }, [availableRoles, secondaryRoles]);

  const availableSecondaryRoles = useMemo(() => {
    return availableRoles.filter(
      (r) => !primaryRole || r.value !== primaryRole.value
    );
  }, [availableRoles, primaryRole]);

  const permissionsOptions = useMemo(() => {
    return [
      {
        value: "admin",
        label: "Location Admin",
      },
      {
        value: "basic_employee",
        label: "Basic Employee",
      },
    ];
  }, []);

  const businessId = useMemo(() => {
    return business?.id ?? null;
  }, [business?.id]);

  const handlePhoneChange = useCallback(
    (e: any) => {
      const number = e.target.value
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(" ", "");

      // console.log(number);
      if (number.length <= 10) {
        // onChange(number);
        setPhone(e.target.value);
      }
    },
    [setPhone]
  );

  const validateBasicInfo = useCallback(async () => {
    try {
      setPhoneNumberCheckError(null);
      setEmailCheckError(null);
      // if (!firstName || !lastName) {
      //   setFirstNameCheckError("First name is required");
      //   setLastNameCheckError("Last name is required");
      // }
      const parsedPhoneNumber = parsePhoneNumber(
        phone,
        "US"
      )?.formatInternational();

      if (parsedPhoneNumber) {
        setEmailAndPhoneValidationLoading(true);

        const emailCheckResult = email
          ? await checkEmailFieldForStaffSignUp(email, businessId)
          : { available: true };
        const phoneNumberCheckResult =
          await checkPhoneNumberFieldForStaffSignUp(
            parsedPhoneNumber,
            businessId
          );

        if (!emailCheckResult.available) {
          setEmailCheckError(
            emailCheckResult.message ??
              "Something went wront, please try different email"
          );
        }

        if (!phoneNumberCheckResult.available) {
          setPhoneNumberCheckError(
            phoneNumberCheckResult.message ??
              "Something went wront, please try different phone number"
          );
        }

        setEmailAndPhoneValidationLoading(false);

        if (emailCheckResult.available && phoneNumberCheckResult.available) {
          setStep("job_role");
        }
      } else {
        setPhoneNumberCheckError(
          "Invalid phone number, please provide a valid US/CA phone number"
        );
        setEmailAndPhoneValidationLoading(false);
      }
    } catch (e) {
      console.log("error validating phone and email", e);
      setPhoneNumberCheckError(
        "Oops something went wrong with validation, please try again"
      );
      setEmailCheckError(
        "Oops something went wrong with validation, please try again"
      );
      setEmailAndPhoneValidationLoading(false);
    }
  }, [
    phone,
    email,
    firstName,
    lastName,
    businessId,
    setPhoneNumberCheckError,
    setEmailCheckError,
    setEmailAndPhoneValidationLoading,
    setStep,
  ]);

  const basicInfoNextEnabled = useMemo(() => {
    return (
      firstName.length > 2 &&
      lastName.length > 2 &&
      (email
        ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        : true) &&
      isValidPhoneNumber(`+1 ${phone}`) &&
      employmentType
    );
  }, [email, firstName, lastName, phone, employmentType]);

  const addTeammateMutation = useMutation(
    (mutationData: {
      businessId: string;
      phoneNumber: string;
      email: string | null;
      isAdmin: boolean;
      firstName: string;
      lastName: string;
      primaryRole: string;
      secondaryRoles: string[];
      payRates: PayRate[];
      annualSalary: number;
      typeOfEmployee: "" | "full-time" | "part-time" | "intern";
      accountId: string | null;
      levelId: string | null;
      departmentId: string | null;
      managers: string[] | null;
      idOfTheGroups: string[] | null;
    }) => {
      return addTeammate(
        mutationData.businessId,
        mutationData.phoneNumber,
        mutationData.email,
        mutationData.isAdmin,
        mutationData.firstName,
        mutationData.lastName,
        mutationData.primaryRole,
        mutationData.secondaryRoles,

        mutationData.payRates,
        mutationData.annualSalary,
        mutationData.typeOfEmployee,
        mutationData.accountId,
        mutationData.levelId,
        mutationData.departmentId,
        mutationData.managers,
        mutationData.idOfTheGroups
      );
    },
    {
      onError: apiErrorHandler,
    }
  );

  const clearState = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setPrimaryRole(null);
    setSecondaryRoles([]);
    setEmploymentType(null);
    setPayRates([]);
  }, [
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setPrimaryRole,
    setSecondaryRoles,
    setEmploymentType,
    setPayRates,
  ]);

  const addTeammateHandler = useCallback(async () => {
    setCreateEmployeeLoading(true);

    const parsedPhoneNumber = parsePhoneNumber(
      phone,
      "US"
    )?.formatInternational();
    if (
      business &&
      primaryRole &&
      parsedPhoneNumber &&
      employmentType &&
      permissions
    ) {
      await addTeammateMutation.mutateAsync({
        businessId: business.id,
        phoneNumber: parsedPhoneNumber.trim(),
        email: email,
        isAdmin: permissions.value === "admin",
        firstName: firstName,
        lastName: lastName,
        primaryRole: primaryRole.value,
        secondaryRoles: secondaryRoles.map((r) => r.value),
        payRates: payRates.map((pr) => ({ ...pr, amount: pr.amount * 100 })),
        annualSalary: 0,
        typeOfEmployee: employmentType.value,
        accountId: permissions.value === "admin" ? business.accountId : null,
        levelId: null,
        departmentId: null,
        managers: null,
        idOfTheGroups: null,
      });
      setCreateEmployeeLoading(false);
      clearState();
      props.onClose();
    }
  }, [
    business,
    phone,
    email,
    firstName,
    lastName,
    primaryRole,
    secondaryRoles,
    payRates,
    businessId,
    addTeammateMutation,
    setCreateEmployeeLoading,
    employmentType,
    permissions,
    clearState,
  ]);

  const renderJobRoleStep = () => {
    return (
      <div>
        <div className="flex flex-row justify-between font-lato-bold text-xl text-black mb-5">
          <div>Employee Roles</div>
          {/* {true ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : <div />} */}
        </div>

        <div className="flex flex-col mb-5">
          <div className="font-lato-bold text-sm text-black mb-2 pl-1">
            Primary Role
          </div>
          <Dropdown
            menuPosition={"absolute"}
            size="medium"
            options={availablePrimaryRoles}
            value={primaryRole}
            //@ts-ignore
            onChange={(o) => setPrimaryRole(o)}
          />
        </div>
        <div className="flex flex-col mb-5">
          <div className="font-lato-bold text-sm text-black mb-2 pl-1">
            Secondary Role(s)
          </div>
          <Dropdown
            menuPosition={"absolute"}
            size="medium"
            options={availableSecondaryRoles}
            value={secondaryRoles}
            isMulti
            //@ts-ignore
            onChange={(ops) => setSecondaryRoles(ops)}
          />
        </div>
        <div className="flex flex-col mb-5">
          <div className="font-lato-bold text-sm text-black mb-2 pl-1">
            Permissions
          </div>
          <Dropdown
            menuPosition={"absolute"}
            size="medium"
            options={permissionsOptions}
            value={permissions}
            isMulti
            //@ts-ignore
            onChange={setPermissions}
          />
        </div>
        <div className="flex flex-row justify-between mt-10">
          <Button
            onClick={() => setStep("basic_info")}
            type="btn-style-1"
            size="btn-large"
            label="Back"
            className="w-full mr-5 flex items-center justify-center"
          />
          <Button
            disabled={!primaryRole || !permissions}
            onClick={() => setStep("compensation")}
            type="btn-style-2"
            size="btn-large"
            label="Next"
            className="w-full flex items-center justify-center"
          />
        </div>
      </div>
    );
  };

  const renderBasicInfoStep = () => {
    return (
      <div>
        <div className="flex flex-row justify-between font-lato-bold text-xl text-black mb-5">
          <div>Add New Employee</div>
          {emailAndPhoneValidationLoading ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            <div />
          )}
        </div>
        <div className="mb-10 mt-10">
          <div className="flex flex-row justify-between mb-5">
            <div className="flex flex-col mr-5">
              <div className="font-lato-bold text-sm text-black mb-2 pl-1">
                First Name
              </div>
              <TextField
                sizeType="medium"
                placeholder="First Name"
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-lato-bold text-sm text-black mb-2 pl-1">
                Last Name
              </div>
              <TextField
                sizeType="medium"
                placeholder="Last Name"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col mr-4">
              <div className="font-lato-bold text-sm text-black mb-2 pl-1">
                Email
              </div>
              <TextField
                sizeType="medium"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                type="email"
              />
              {emailCheckError ? (
                <div className="font-lato-regular text-xs text-error-red mt-2 pl-1  w-48	">
                  {emailCheckError}
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <div className="font-lato-bold text-sm text-black mb-2 pl-1">
                Phone
              </div>
              <TextField
                sizeType="medium"
                placeholder="Phone"
                value={ayt.input(phone)}
                onChange={handlePhoneChange}
              />
              {phoneNumberCheckError ? (
                <div className="font-lato-regular text-xs text-error-red mt-2 pl-1 w-48">
                  {phoneNumberCheckError}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Dropdown
          size="small"
          menuPosition={"absolute"}
          className="w-full mb-10 mt-10"
          value={employmentType}
          //@ts-ignore
          onChange={setEmploymentType}
          options={[
            {
              label: "Full Time",
              value: "full_time",
            },
            {
              label: "Part Time",
              value: "part_time",
            },
          ]}
        />
        <div className="flex flex-row justify-between mt-10">
          <Button
            type="btn-style-1"
            size="btn-large"
            label="Cancel"
            className="w-full mr-5 flex items-center justify-center"
          />
          <Button
            disabled={!basicInfoNextEnabled}
            onClick={validateBasicInfo}
            type="btn-style-2"
            size="btn-large"
            label="Next"
            className="w-full flex items-center justify-center"
          />
        </div>
      </div>
    );
  };

  const handlePayrateChange = useCallback(
    (
      roleInfo: {
        value: string;
        label: string;
      },
      amonut: number
    ) => {
      const payRateForRole = payRates.find(
        (pr) => pr.roleId === roleInfo.value
      );

      if (!payRateForRole) {
        setPayRates([
          ...payRates,
          {
            roleId: roleInfo.value,
            role: roleInfo.label,
            amount: amonut,
          },
        ]);
      } else {
        setPayRates(
          payRates.map((pr) => {
            if (pr.roleId === roleInfo.value) {
              return {
                ...pr,
                amount: amonut,
              };
            }
            return pr;
          })
        );
      }
    },
    [payRates, setPayRates]
  );

  const payRatesValid = useMemo(() => {
    if (!primaryRole) return false;
    const allRoles = [primaryRole, ...secondaryRoles];
    return (
      payRates.filter((pr) => allRoles.find((r) => r.value === pr.roleId))
        .length === allRoles.length
    );
  }, [payRates, primaryRole, secondaryRoles]);

  const renderCompensationStep = () => {
    if (!primaryRole) return null;
    return (
      <div>
        <div className="flex flex-row justify-between font-lato-bold text-xl text-black mb-5">
          <div>Hourly Compensation</div>
          {createEmployeeLoading ? (
            <Spinner size={SpinnerSize.SMALL} />
          ) : (
            <div />
          )}
        </div>
        <div className="flex flex-col">
          {[primaryRole, ...secondaryRoles].map((roleInfo) => (
            <div className="flex flex-row justify-start items-center mb-5">
              <div className="font-lato-semibold text-sm text-black mb-2  w-3/12 ">
                {roleInfo.label}
              </div>
              <TextField
                className="w-2/3"
                type="number"
                sizeType="medium"
                placeholder="Enter Hourly Rate"
                value={
                  payRates?.find((payRate) => payRate.roleId === roleInfo.value)
                    ?.amount
                }
                onChange={(e) =>
                  //@ts-ignore
                  handlePayrateChange(roleInfo, Number(e.target.value))
                }
              />
            </div>
          ))}
          <div className="flex flex-row justify-between mt-10">
            <Button
              type="btn-style-1"
              size="btn-large"
              label="Cancel"
              onClick={() => setStep("job_role")}
              className="w-full mr-5 flex items-center justify-center"
            />
            <Button
              disabled={!payRatesValid}
              onClick={addTeammateHandler}
              type="btn-style-2"
              size="btn-large"
              label="Next"
              className="w-full flex items-center justify-center"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (step) {
      case "basic_info":
        return renderBasicInfoStep();
      case "job_role":
        return renderJobRoleStep();
      case "compensation":
        return renderCompensationStep();
      default:
        return null;
    }
  };
  return (
    <Modal
      width={"600"}
      height={"600"}
      isOpen={props.isOpen}
      closeModal={props.onClose}
      style={{
        content: {
          overflow: "hidden",
        },
      }}
    >
      {renderContent()}
    </Modal>
  );
};

export default CreateEmployeeModalFlow;
