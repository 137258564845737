import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";

import {
  useCreateConversions,
  useDeleteConversion,
  useInventoryInfo,
  useUpdateConversion,
} from "controllers/inventoryInfo";

import { RenderInstruction } from "components/Table/GenericCell/TableCell";
import {
  ColumnInstruction,
  UpdateState,
} from "components/Table/HorizontalTable";

import { RootState } from "model/store";

import { RatioEditComponent, RatioViewComponent } from "./ratioComponents";

type ConversionProps = {
  uniqueId: string;
  fromAmount: number;
  fromId: string | null;
  toAmount: number;
  toId: string | null;
};

const columns: ColumnInstruction<ConversionProps>[] = [
  { type: "data", header: "From", attribute: "fromAmount" },
  { type: "data", header: "", attribute: "fromId" },
  { type: "data", header: "To", attribute: "toAmount" },
  { type: "data", header: "", attribute: "toId" },
  { type: "projection", header: "ratio", attribute: "ratio" },
];

const prefix = "new__conversion";

const useConversions = () => {
  const businessId: string = useSelector(
    (state: RootState) => state.business as TangoBusiness
  )?.id;
  const [isEditing, setEditing] = useState(false);
  // call update with false to push, true to clear
  const [addedIds, updateItems] = useReducer(
    (curr: string[], clear: boolean) => {
      if (clear) return [];
      return [...curr, prefix + curr.length];
    },
    []
  );
  useEffect(() => {
    if (!isEditing) updateItems(true);
  }, [isEditing]);
  const add = useCallback(() => {
    setEditing(true);
    updateItems(false);
  }, []);
  const query = useInventoryInfo(businessId);
  const create = useCreateConversions(businessId);
  const update = useUpdateConversion(businessId);
  const del = useDeleteConversion(businessId);
  const dataFromServer = query.data?.conversions ?? [];
  const units = query.data?.units ?? [];
  const instructions = useMemo(() => {
    const options = units.map((u) => ({
      value: u.id,
      label: u.name, // shortName?
    }));
    const i: { [x: string]: RenderInstruction<ConversionProps> } = {};
    i.ratio = {
      type: "complex-custom",
      viewComponent: RatioViewComponent,
      editComponent: RatioEditComponent,
    };
    i.fromAmount = {
      type: "number",
    };
    i.fromId = {
      type: "select",
      options,
      placeholder: "select a unit",
    };
    i.toAmount = {
      type: "number",
    };
    i.toId = {
      type: "select",
      options,
      placeholder: "select a unit",
    };
    return i;
  }, [units]);
  const dataWithAdditions = useMemo(() => {
    const adding = addedIds.map(
      (uniqueId: string) =>
        ({
          uniqueId,
          toId: null,
          fromId: null,
          toAmount: 1,
          fromAmount: 1,
        } as ConversionProps)
    );
    const curr = dataFromServer.map(
      (data) =>
        ({
          uniqueId: data.id,
          ...data,
        } as ConversionProps)
    );
    return [...adding, ...curr];
  }, [addedIds, dataFromServer]);

  const saveChanges = useCallback(
    async (instructions: UpdateState) => {
      const toCreate = addedIds.map((id) => {
        const updates = instructions[id];
        if (!updates.toId.newValue || !updates.fromId.newValue) {
          throw "Must specify targets";
        }
        return {
          toId: updates.toId.newValue as string,
          fromId: updates.fromId.newValue as string,
          toAmount: (updates?.toAmount?.newValue ?? 1) as number,
          fromAmount: (updates?.fromAmount?.newValue ?? 1) as number,
        };
      });
      return Promise.all([
        ...Object.entries(instructions)
          .filter(([id]) => !id.startsWith(prefix))
          .map(([conversionId, newValue]) => {
            const updateDTO = Object.keys(newValue).reduce(
              (acc, key) => ({
                ...acc,
                [key]: newValue[key].newValue,
              }),
              {}
            );
            update.mutate({ conversionId, newValue: updateDTO });
          }),
        create.mutate(toCreate),
      ]);
    },
    [addedIds, create]
  );

  const deleteIds = useCallback(
    (ids: string[]) => {
      return Promise.all(
        ids.map((conversionId) => {
          if (conversionId.startsWith(prefix)) return;
          return del.mutate({ conversionId });
        })
      );
    },
    [del]
  );

  return {
    dataWithAdditions,
    addItem: add,
    deleteIds,
    saveChanges,
    columns,
    instructions,
    isEditing,
    setEditing,
  };
};
export default useConversions;
