import { FirebasePrintersDoc } from "types/printers";

import ActionType from "./actionTypes";

export function receivePrinters(printers: FirebasePrintersDoc[]) {
  return {
    type: ActionType.RECEIVE_PRINTERS,
    printers,
  };
}
