import axios from "axios";
import firebase, { firestore } from "config/firebase";
import { access } from "fs";
import _ from "lodash";
import moment from "moment-timezone";
import { isElectron } from "utils";
import { generateWeekRangeForSelectedDate } from "views/manager/dailyLogs/dailyLogsFunctions";

import {
  convertDateBackToBusinessTimezone,
  convertGMTToBusinessTimezone,
  convertToBusinessTimezoneMoment,
} from "utils/dateUtils";
import { getStaffMemberFullName } from "utils/manager";

import { FirebaseCashEvents } from "types/cashDrawers";
import { FirebaseOrderProduct } from "types/order";
import { CashEvents } from "types/reporting";

import {
  getPositionById,
  getPositionNameById,
} from "model/selectors/businessSettings";

import { composeUsableCashEvent } from "./composableTypes";
import { apiCall, baseUrl } from "./core";
import { generateBearerToken } from "./init";
import { DEPARTMENTS, DepartmentId } from "./staff";

const db = firebase.firestore();

const tangoReportingApiClient = axios.create({
  baseURL: `${baseUrl}/nestApi/reporting`,
});

tangoReportingApiClient.interceptors.request.use(async (config) => {
  const authorizationToken = await generateBearerToken();
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: authorizationToken,
    },
  };
});

type DailyJobFunctionReport = {
  jobFunctionId: string;
  laborTotal: number;
  hoursWorked: number;
  departmentId: string;
};
type DailyLaborReport = {
  jobFunctionsReports: DailyJobFunctionReport[];
  totalSales: number;
};

interface CustomerProductsPaid {
  productId: string;
  productName: string;
  productCategory: string;
  menuCategory: string;
  menuName: string;
  name: string;
  type: string;
  price: number;
  quantity: number;
  taxRate: number | null;
}

export interface SelectedModifiers {
  modifierId: string;
  additionalCost: number;
  modifierName: string;
  optionNames: string[];
  options: {
    name: string;
    plu: string | null;
    id: string;
    additionalCost: number;
  }[];
}

export interface Product {
  id: string;
  productOrderId: string;
  productId: string;
  businessId: string;
  imageUrl: string;
  menuName: string;
  menuCategory: string;
  name: string;
  quantity: number;
  price: number;
  discountedPrice: number | null;
  taxRate: number | null;
  type: string;
  alcohol: boolean;
  discountsAppliedToProduct: string[];
  selectedModifiers: SelectedModifiers[];
  note: string | null;
  sentQuantity: number;
  orderQuantity: number;
  plu: string | null;
}

export interface ProductWithMetaData {
  productOrderId: string;
  productId: string;
  businessId: string;
  imageUrl: string;
  menuName: string;
  menuCategory: string;
  name: string;
  quantity: number;
  price: number;
  discountedPrice: number | null;
  taxRate: number | null;
  type: string;
  alcohol: boolean;
  discountsAppliedToProduct: string[];
  selectedModifiers: SelectedModifiers[];
  note: string | null;
  sentQuantity: number;
  orderQuantity: number;
  plu: string | null;
  isCash: boolean;
  currency: string;
  orderType: string;
  orderChannel: string;
}

type OrderApp = "flex" | "speed" | "pwa" | "none";
export interface DiscountApplied {
  discountName: string;
  discountId: string;
  discountTotal: number;
}
export interface FlattenTab {
  tabId: string;
  orderId: string;
  customerId: string;
  tableId: string;
  tableNumber: number | null;
  netAmount: number;
  discountAmount: number;
  discountsApplied: DiscountApplied[];
  deliveryFee: number;
  tax: number;
  tip: number;
  applicationFeeAmount: number;
  refundedAmount: number;
  paymentIntentId: string;
  chargeId: string;
  paymentType: string;
  currency: string;
  cardBrand: string;
  cardFunding: string;
  cardLastFour: string;
  cardholderName: string;
  isInteracPayment: boolean;
  hasMealPurchased: boolean;
  totalMeals: number;
  serviceAreaName: string;
  staffId: string;
  managerIdDiscountIssuedTo: string;
  createdAt: Date;
  productsPaid: Product[];
  orderType: string;
  orderChannel: string;
  isTab: boolean;
  numberOfGuests: number;
  isDineInOrder: boolean;
  appUsedToMakeOrder: OrderApp;
  isDeliverect: boolean;
  isStaffMemberPurchase: boolean;
  courier: string | null;
  isCash: boolean;
  isGiftCard: boolean;
  voidedProducts: Product[];
  tableNo: string;
  check: string;
}

export type LaborReportForADay = {
  id: string;
  date: Date;
  data: DailyLaborReportDocumentRow[];
  totalSales: number;
};

export type DailyLaborReportDocumentRow = {
  roleName: string;
  roleId: string;
  totalLabor: number;
  totalHours: number;
  percentageOfSales: number;
  departmentName: string;
};

export const roundNumberToTwoDecimals = (val: number) =>
  Math.round(val * 100) / 100;

export const percentage = (partialValue: number, totalValue: number) => {
  return roundNumberToTwoDecimals((100 * partialValue) / totalValue);
};

export const downloadProductsCSV = async (
  businessId: string,
  businessName = "Tango Sambrosa"
) => {
  try {
    const authorizationToken = await generateBearerToken();
    if (authorizationToken) {
      const url = apiCall(`api/products/protected_csv/${businessId}`);

      const res = await axios({
        method: "GET",
        url,
        headers: { Authorization: authorizationToken },
        responseType: "blob",
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const fileName = `${businessName.replace(
          "/",
          " "
        )} Products Export (${moment().format("DD-MM-YYYY")}).csv`;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        if (isElectron()) {
          //@ts-ignore
          window.electron.downloadFileByUrl({
            url,
            fileName,
          });
        } else {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      } else {
        alert("Opps, something went wrong here...");
      }
    }
  } catch (e) {
    console.log("Error: downloadProductsCSV ", e);
    alert("Opps, something went wrong here...");
  }
};

export const fetchReadReceiptData = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<{ response: MainReadReport } | null> => {
  try {
    const url =
      "https://us-central1-tango-2.cloudfunctions.net/readReceiptsForReporting";
    const response = await axios({
      method: "POST",
      url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: {
        businessId,
        startDate,
        endDate,
        staffId: null,
      },
      responseType: "json",
      validateStatus: () => true,
    });

    // const response = await fetch(, {
    //   method: 'POST',
    //   mode: 'cors',
    //   cache: 'no-cache',
    //   credentials: 'same-origin',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   redirect: 'follow',
    //   referrerPolicy: 'no-referrer',
    //   body: JSON.stringify({
    //     businessId,
    //     startDate,
    //     endDate,
    //     staffId,
    //   }),
    // });

    if (response.status === 200) {
      const result = await response.data;
      return result;
    } else {
      return null;
    }
  } catch (err) {
    console.log("ERR: ", err);
    return null;
  }
};

const getDailyLaborReportDocumentRows = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[]
): DailyLaborReportDocumentRow[] => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const groupedWorkEvents = _.groupBy(workEventsForTheDayOfReport, "position");
  return _.keys(groupedWorkEvents).map((positionId) => {
    const positionData = getPositionById(businessSettings, positionId);
    const roleName = positionData?.title || "Unknown";
    const departmentId: DepartmentId =
      (positionData?.departmentId as DepartmentId) || ("other" as DepartmentId);
    const departmentName = DEPARTMENTS[departmentId].title;
    const sumOfAllPunchInsForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      },
      0
    );
    const totalLaborAmountForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      },
      0
    );
    const laborAsPercentageOfSalesForTheDay = percentage(
      totalLaborAmountForPosition,
      totalSalesForTheDay
    );
    return {
      roleName: roleName || "Unknown",
      roleId: positionId,
      totalLabor: roundNumberToTwoDecimals(
        sanitizeNumericField(totalLaborAmountForPosition)
      ),
      totalHours: roundNumberToTwoDecimals(
        sanitizeNumericField(sumOfAllPunchInsForPosition)
      ),
      percentageOfSales: roundNumberToTwoDecimals(
        sanitizeNumericField(laborAsPercentageOfSalesForTheDay)
      ),
      departmentName,
    };
  });
};
export type FlattenedTabsGroupedByWeek = {
  [startOfWeekDateString: string]: {
    startDate: Date;
    endDate: Date;
    flattenTabs: FlattenTab[];
  };
};

export type FlattenedTabsGroupedByDates = {
  [date: string]: {
    flattenTabs: FlattenTab[];
  };
};

export type WorkEventsGroupedByWeek = {
  [startOfWeekDateString: string]: {
    startDate: Date;
    endDate: Date;
    workEvents: WorkEvent[];
  };
};

const getThirdPartySalesByBusinessIdAndDateRange = async (
  businessId: string,
  locationId: string,
  startDate: Date,
  endDate: Date
) => {
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  const salesDataRes = await axios.post(
    apiCall("fetchSalesForReportingThirdPartyDeliverect"),
    {
      businessId,
      locationId: locationId,
      startDate: startDate.toDateString(),
      endDate: endDate.toDateString(),
    }
  );
  const salesData = (salesDataRes?.data?.response || ([] as FlattenTab[])).map(
    (item: any) => {
      let date = new Date(0);
      if (typeof item.createdAt === "string") {
        date = new Date(item.createdAt);
      } else {
        date.setUTCSeconds(item.createdAt._seconds);
      }
      return {
        ...item,
        createdAt: date,
      };
    }
  );

  return salesData;
};

const fetchSharedData = async (
  businessId: string,
  startDate: Date,
  endDate: Date
) => {
  const businessSettingsSn = await firebase
    .firestore()
    .collection("BusinessSettings")
    .doc(businessId)
    .get();
  const businessSettings: TangoBusinessSettings =
    businessSettingsSn.data() as TangoBusinessSettings;

  const businessSn = await firebase
    .firestore()
    .collection("Businesses")
    .doc(businessId)
    .get();
  const business: TangoBusiness = businessSn.data() as TangoBusiness;

  const finalDate = moment(endDate).add(1, "day").toDate();

  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  const salesDataRes = await tangoReportingApiClient.post(
    "/fetch-sales-for-reporting",
    {
      businessId,
      startDate: startDate.toDateString(),
      endDate: finalDate.toDateString(),
    }
  );

  const thirdPartyTabs =
    business && business.deliverect && business.deliverect.locationId
      ? await getThirdPartySalesByBusinessIdAndDateRange(
          businessId,
          business.deliverect.locationId,
          startDate,
          endDate
        )
      : [];

  const salesData = [
    ...(salesDataRes?.data?.response || ([] as FlattenTab[])),
    ...thirdPartyTabs.map((i: FlattenTab) => ({
      ...i,
      createdAt: convertDateBackToBusinessTimezone(i.createdAt, business),
      oldCreatedAt: new Date(i.createdAt),
    })),
  ];

  const clockPunchesSn = await firebase
    .firestore()
    .collection("WorkEvents")
    .where("businessId", "==", businessId)
    .where("clockedIn", ">=", startDate)
    .where("clockedIn", "<=", endDate)
    .get();

  const staffMembersSn = await firebase
    .firestore()
    .collection("Staff")
    .where("businessId", "==", businessId)
    .get();

  const staffMembers: StaffMember[] = [];
  staffMembersSn.docs.forEach((doc) => {
    if (!doc?.data()?.deleted) {
      staffMembers.push(doc.data() as StaffMember);
    }
  });

  const workEvents: WorkEvent[] = [];
  clockPunchesSn.docs.forEach((doc) => {
    if (!doc?.data()?.deleted && doc?.data()?.clockedOut) {
      const we = doc.data() as WorkEvent;
      const wePosition = we.position;
      const weStaffId = we.staffId;
      if (weStaffId) {
        const weStaffMember = staffMembers.find((sm) => sm.uid === weStaffId);
        if (weStaffMember) {
          const weStaffMemberPayRates = weStaffMember?.payRates;
          if (weStaffMember && weStaffMember?.length && we.payRate) {
            workEvents.push(we);
          }
        }
      }
    }
  });

  const workEventsWithSnapshotClockOuts: WorkEvent[] = [];

  clockPunchesSn.docs.forEach((doc) => {
    if (!doc?.data()?.deleted) {
      const we = doc.data() as WorkEvent;
      const wePosition = we.position;
      const weStaffId = we.staffId;
      if (weStaffId) {
        const weStaffMember = staffMembers.find((sm) => sm.uid === weStaffId);
        if (weStaffMember) {
          const weStaffMemberPayRates = weStaffMember?.payRates;
          if (weStaffMemberPayRates?.length) {
            const payRateForWorkEvent = weStaffMemberPayRates.find(
              (pr) => pr.roleId === wePosition
            );
            if (we?.payRate) {
              if (we.clockedOut) {
                workEventsWithSnapshotClockOuts.push(we);
              } else {
                workEventsWithSnapshotClockOuts.push({
                  ...we,
                  clockedOut: firebase.firestore.Timestamp.fromDate(new Date()),
                });
              }
            }
          }
        }
      }
    }
  });

  const totalSales = salesData.reduce(
    (accum: number, flattenTab: FlattenTab) =>
      accum + (flattenTab.netAmount - flattenTab.refundedAmount),
    0
  );
  const salesGroupedByDay = _.groupBy(salesData, (flattenTab: FlattenTab) => {
    const hours = moment(flattenTab.createdAt).toDate().getHours();
    if (hours <= 3) {
      return `${moment(flattenTab.createdAt)
        .subtract(1, "day")
        .startOf("day")
        .add(4, "hours")
        .format()}_${moment(flattenTab.createdAt)
        .startOf("day")
        .add(4, "hours")
        .format()}`;
    }
    return `${moment(flattenTab.createdAt)
      .startOf("day")
      .add(4, "hours")
      .format()}_${moment(flattenTab.createdAt)
      .startOf("day")
      .add("1", "day")
      .add(4, "hours")
      .format()}`;
  });

  const groupedSalesWithAdjustedKeys: { [dateString: string]: FlattenTab[] } =
    {};
  _.keys(salesGroupedByDay).forEach((startDateEndDateString) => {
    groupedSalesWithAdjustedKeys[startDateEndDateString.split("_")[0]] =
      salesGroupedByDay[startDateEndDateString];
  });
  const groupedSales: { [dateString: string]: FlattenTab[] } = _.groupBy(
    salesData,
    (flattenTab: FlattenTab) => {
      //-${moment(flattenTab.createdAt).startOf('day').add('1', 'day').add(4, 'hours').format()}
      return `${moment(flattenTab.createdAt)
        .startOf("day")
        .add(4, "hours")
        .format()}`;
    }
  );

  const payrollStartOfTheWeek = business.payrollStartOfTheWeek || "Monday";

  let payrollStartOfTheWeekIndex = moment
    .weekdays()
    .indexOf(payrollStartOfTheWeek);
  if (payrollStartOfTheWeekIndex === -1) {
    payrollStartOfTheWeekIndex = 1;
  }

  const getMonday = (d: Date) => {
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(d.setDate(diff));
    return new Date(
      monday.getFullYear(),
      monday.getMonth(),
      monday.getDate(),
      4,
      0
    );
  };

  const momentSalesGroupedByWeek = salesData.reduce((acc, val) => {
    const date = new Date(val.createdAt);
    const finalDate: Date =
      date.getHours() <= 4 ? new Date(date.setDate(date.getDate() - 1)) : date;
    const startDate = getMonday(finalDate);

    const startDateFormatted = startDate.toISOString();
    if (Object.keys(acc).includes(startDateFormatted)) {
      acc[startDateFormatted].push(val);
    } else {
      acc[startDateFormatted] = [val];
    }

    return acc;
  }, {});

  // const OLD_momentSalesGroupedByWeek = _.groupBy(salesData, (flattenTab: FlattenTab) =>
  //   convertToBusinessTimezoneMoment(moment(flattenTab.createdAt).startOf('week').toDate(), business)
  //     .add(4, 'hours')
  //     .add(payrollStartOfTheWeekIndex, 'days')
  //     .format(),
  // );

  const salesGroupedByWeek: FlattenedTabsGroupedByWeek = {};
  _.keys(momentSalesGroupedByWeek).forEach((startOfWeekDateString) => {
    salesGroupedByWeek[startOfWeekDateString] = {
      flattenTabs: momentSalesGroupedByWeek[startOfWeekDateString],
      startDate: moment(startOfWeekDateString).toDate(),
      endDate: moment(startOfWeekDateString)
        .endOf("week")
        .add("hours", 4)
        .add("days", payrollStartOfTheWeekIndex)
        .toDate(),
    };
  });

  const momentWorkEventsGroupedByWeek = _.groupBy(
    workEvents,
    (workEvent: WorkEvent) => {
      if (
        moment(workEvent.clockedIn.toDate()).day() === 0 &&
        payrollStartOfTheWeekIndex !== 0
      ) {
        return moment(workEvent.clockedIn.toDate())
          .startOf("week")
          .subtract(1, "week")
          .add(4, "hours")
          .add(payrollStartOfTheWeekIndex, "days")
          .format();
      }

      return moment(workEvent.clockedIn.toDate())
        .startOf("week")
        .add(4, "hours")
        .add(payrollStartOfTheWeekIndex, "days")
        .format();
    }
  );
  const workEventsGroupedByWeek: WorkEventsGroupedByWeek = {};
  _.keys(momentWorkEventsGroupedByWeek).forEach((startOfWeekDateString) => {
    workEventsGroupedByWeek[startOfWeekDateString] = {
      workEvents: momentWorkEventsGroupedByWeek[startOfWeekDateString],
      startDate: moment(startOfWeekDateString).toDate(),
      endDate: moment(startOfWeekDateString)
        .endOf("week")
        .add(4, "hours")
        .add(payrollStartOfTheWeekIndex, "days")
        .toDate(),
    };
  });

  const momentWorkEventsWithSnapshotClockOutsGroupedByWeek = _.groupBy(
    workEventsWithSnapshotClockOuts,
    (workEvent: WorkEvent) => {
      if (
        moment(workEvent.clockedIn.toDate()).day() === 0 &&
        payrollStartOfTheWeekIndex !== 0
      ) {
        return moment(workEvent.clockedIn.toDate())
          .startOf("week")
          .subtract(1, "week")
          .add(4, "hours")
          .add(payrollStartOfTheWeekIndex, "days")
          .format();
      }

      return moment(workEvent.clockedIn.toDate())
        .startOf("week")
        .add(4, "hours")
        .add(payrollStartOfTheWeekIndex, "days")
        .format();
    }
  );
  const workEventsWithSnapshotClockOutsGroupedByWeek: WorkEventsGroupedByWeek =
    {};
  _.keys(momentWorkEventsWithSnapshotClockOutsGroupedByWeek).forEach(
    (startOfWeekDateString) => {
      workEventsWithSnapshotClockOutsGroupedByWeek[startOfWeekDateString] = {
        workEvents:
          momentWorkEventsWithSnapshotClockOutsGroupedByWeek[
            startOfWeekDateString
          ],
        startDate: moment(startOfWeekDateString).toDate(),
        endDate: moment(startOfWeekDateString)
          .endOf("week")
          .add(4, "hours")
          .add(payrollStartOfTheWeekIndex, "days")
          .toDate(),
      };
    }
  );
  const salesDataForVoidedProducts = salesData.filter(
    (sd) => (sd.voidedProducts ?? []).length
  );
  console.log("salesDataForVoidedProducts", salesDataForVoidedProducts);
  return {
    businessSettings,
    salesData,
    business,
    workEvents,
    staffMembers,
    totalSales: sanitizeNumericField(roundNumberToTwoDecimals(totalSales)),
    groupedSales: groupedSalesWithAdjustedKeys,
    workEventsGroupedByWeek,
    salesGroupedByWeek,
    workEventsWithSnapshotClockOuts,
    workEventsWithSnapshotClockOutsGroupedByWeek,
  };
};

export const getLaborReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<LaborReportForADay[]> => {
  const {
    businessSettings,
    salesData,
    workEvents,
    staffMembers,
    totalSales,
    salesGroupedByWeek,
    workEventsWithSnapshotClockOuts,
    groupedSales,
  } = await fetchSharedData(businessId, startDate, endDate);

  const laborReports: LaborReportForADay[] = _.keys(groupedSales).map(
    (dateString) => {
      const flattenTabsForDate: FlattenTab[] = groupedSales[dateString];
      const totalSales = flattenTabsForDate.reduce(
        (accum: number, currentValue: FlattenTab) =>
          accum +
          (currentValue.netAmount -
            currentValue.tax -
            currentValue.refundedAmount),
        0
      );
      const documentRows = getDailyLaborReportDocumentRows(
        workEventsWithSnapshotClockOuts,
        totalSales,
        new Date(dateString),
        businessSettings,
        staffMembers
      );
      return {
        id: dateString,
        date: new Date(dateString),
        data: documentRows,
        totalSales,
      };
    }
  );

  // The business only uses staffing
  if (laborReports.length === 0) {
    console.log("Grouped sales: ", groupedSales);
    const results: LaborReportForADay[] = [];
    const start = moment(startDate).startOf("day").add(4, "hours");
    const end = moment(endDate).isSameOrBefore(moment())
      ? moment(endDate).startOf("day").add(4, "hours")
      : moment().add(1, "day").startOf("day").add(4, "hours");
    const diff = end.diff(start, "days");
    console.log(start, end);
    for (let i = 0; i < diff; i++) {
      const currentDate = start.clone().add(i, "days");
      const documentRows = getDailyLaborReportDocumentRows(
        workEventsWithSnapshotClockOuts,
        totalSales,
        currentDate.toDate(),
        businessSettings,
        staffMembers
      );
      results.push({
        id: currentDate.format(),
        date: currentDate.toDate(),
        data: documentRows,
        totalSales: 0,
      });
    }
    // @ts-ignore
    return results.sort((a, b) => b.date - a.date);
  }

  return laborReports;
};
export type RoleReportTotal = {
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};
export type StaffMemberPunchInReport = {
  date: Date;
  timeIn: string;
  rate: number;
  timeOut: string;
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};

type StaffMemberReportTotal = {
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};
export type StaffMemberReport = {
  staffId: string;
  fullName: string;
  data: StaffMemberPunchInReport[];
  total: StaffMemberReportTotal;
};

export type RoleReport = {
  roleId: string;
  roleName: string;
  data: StaffMemberReport[];
  total: RoleReportTotal;
};

export type DetailReportTotal = {
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};
export type DetailReportData = {
  data: RoleReport[];
  total: DetailReportTotal;
};

export type DistributionAndLaborReportForADay = {
  id: string;
  date: Date;
  taskSummaryData: DistributionAndLaborReportTaskSummaryRow[];
  taskSummaryTotal: DistributionAndLaborReportTaskSummaryTotal;
  taskStatisticData: DistributionAndLaborReportTaskStatisticRow[];
  taskStatisticTotal: DistributionAndLaborReportTaskStatisticTotal;
  departmentStatisticData: DistributionAndLaborReportDepartmentStatisticRow[];
  departmentStatisticTotal: DistributionAndLaborReportDepartmentStatisticTotal;
  distributionSummaryData: DistributionAndLaborReportDistributionSummaryRow[];
  distributionSummaryTotal: DistributionAndLaborReportDistributionSummaryTotal;
  distributionDetailData: DistributionDetailData;
  detailReportData: DetailReportData;
  totalSales: number;
  totalCustomersNumberForTheDay: number;
  totalMealsNumberForTheDay: number;
};

export type DistributionDetailData = {
  tables: DistributionAndLaborReportDistributionDetailDepartmentTable[];
  total: DistributionAndLaborReportDistributionDetailReportTotal;
};

export type DistributionAndLaborReportTaskSummaryRow = {
  roleName: string;
  roleId: string;
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};

export type DistributionAndLaborReportTaskSummaryTotal = {
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
  fringeBenefits: number;
  total: number;
};

export type DistributionAndLaborReportTaskStatisticRow = {
  roleName: string;
  roleId: string;
  totalLabor: number;
  percentageOfTotalLabor: number;
  totalHours: number;
  percentageOfTotalHours: number;
  percentageOfSales: number;
  departmentName: string;
  customersPerHour: number;
  mealsPerHour: number;
  salesPerHour: number;
};

export type DistributionAndLaborReportTaskStatisticTotal = {
  totalLabor: number;
  percentageOfTotalLabor: number;
  totalHours: number;
  percentageOfTotalHours: number;
  percentageOfSales: number;
  customersPerHour: number;
  mealsPerHour: number;
  salesPerHour: number;
};

export type DistributionAndLaborReportDepartmentStatisticRow = {
  totalLabor: number;
  percentageOfTotalLabor: number;
  totalHours: number;
  percentageOfTotalHours: number;
  percentageOfSales: number;
  departmentName: string;
  customersPerHour: number;
  mealsPerHour: number;
  salesPerHour: number;
};

export type DistributionAndLaborReportDepartmentStatisticTotal = {
  totalLabor: number;
  percentageOfTotalLabor: number;
  totalHours: number;
  percentageOfTotalHours: number;
  percentageOfSales: number;
  customersPerHour: number;
  mealsPerHour: number;
  salesPerHour: number;
};

export type DistributionAndLaborReportDistributionSummaryRow = {
  departmentName: string;
  regularHours: number;
  regularHoursTotal: number;
  overtimeHours: number;
  overtimeHoursTotal: number;
  doubleHours: number;
  doubleHoursTotal: number;
  otherPaid: number;
  cphp: number;
  pphp: number;
  percentageOfSales: number;
};

export type DistributionAndLaborReportDistributionSummaryTotal = {
  regularHours: number;
  regularHoursTotal: number;
  overtimeHours: number;
  overtimeHoursTotal: number;
  doubleHours: number;
  doubleHoursTotal: number;
  otherPaid: number;
  cphp: number;
  pphp: number;
  percentageOfSales: number;
};

export type DistributionAndLaborReportDistributionDetailRow = {
  departmentName: string;
  roleName: string;
  roleId: string;
  regularHours: number;
  regularHoursTotal: number;
  overtimeHours: number;
  overtimeHoursTotal: number;
  doubleHours: number;
  doubleHoursTotal: number;
  otherPaid: number;
  cphp: number;
  pphp: number;
  percentageOfSales: number;
};

export type DistributionAndLaborReportDistributionDetailDepartmentTotal = {
  departmentName: string;
  regularHours: number;
  regularHoursTotal: number;
  overtimeHours: number;
  overtimeHoursTotal: number;
  doubleHours: number;
  doubleHoursTotal: number;
  otherPaid: number;
  cphp: number;
  pphp: number;
  percentageOfSales: number;
};

export type DistributionAndLaborReportDistributionDetailReportTotal = {
  regularHours: number;
  regularHoursTotal: number;
  overtimeHours: number;
  overtimeHoursTotal: number;
  doubleHours: number;
  doubleHoursTotal: number;
  otherPaid: number;
  cphp: number;
  pphp: number;
  percentageOfSales: number;
};

export type DistributionAndLaborReportDistributionDetailDepartmentTable = {
  data: DistributionAndLaborReportDistributionDetailRow[];
  totals: DistributionAndLaborReportDistributionDetailDepartmentTotal;
};

export const formatCentValue = (value: number) =>
  roundNumberToTwoDecimals(sanitizeNumericField(value / 100));

export const sanitizeCurrenctInputValue = (value: string) => {
  const number = Number(value);
  console.log("number", number);
  console.log(
    "roundNumberToTwoDecimals(sanitizeNumericField(number))",
    roundNumberToTwoDecimals(sanitizeNumericField(number))
  );
  return roundNumberToTwoDecimals(sanitizeNumericField(number));
};

export const convertStringToCentValue = (value: string) => {
  const number = Number(value);
  return roundNumberToTwoDecimals(sanitizeNumericField(number * 100));
};

export const sanitizeNumericField = (value: number) =>
  !_.isFinite(value) || _.isNil(value) || _.isNaN(value) ? 0 : value;

const getDistributionAndLaborReportDistributionDetailsData = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  totalMealsNumberForTheDay: number
): DistributionDetailData => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const groupedWorkEvents = _.groupBy(
    workEventsForTheDayOfReport,
    (workEvent) => workEvent.position
  );
  const data: DistributionAndLaborReportDistributionDetailRow[] = _.keys(
    groupedWorkEvents
  ).map((positionId) => {
    const positionData = getPositionById(businessSettings, positionId);
    const roleName = positionData?.title || "Unknown";
    const departmentId: DepartmentId =
      (positionData?.departmentId as DepartmentId) || ("other" as DepartmentId);
    const departmentName = DEPARTMENTS[departmentId].title;
    const sumOfAllPunchInsForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      },
      0
    );
    const totalLaborAmountForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      },
      0
    );

    const pphp = roundNumberToTwoDecimals(
      totalSalesForTheDay / sumOfAllPunchInsForPosition
    );
    const cphp = roundNumberToTwoDecimals(
      totalMealsNumberForTheDay / totalLaborAmountForPosition
    );

    const laborAsPercentageOfSalesForTheDay = percentage(
      totalLaborAmountForPosition,
      totalSalesForTheDay
    );
    const res: DistributionAndLaborReportDistributionDetailRow = {
      regularHours: roundNumberToTwoDecimals(
        sanitizeNumericField(sumOfAllPunchInsForPosition)
      ),
      regularHoursTotal: roundNumberToTwoDecimals(
        sanitizeNumericField(totalLaborAmountForPosition)
      ),
      roleId: positionId,
      overtimeHours: sanitizeNumericField(0),
      roleName,
      doubleHoursTotal: sanitizeNumericField(0),
      overtimeHoursTotal: sanitizeNumericField(0),
      departmentName,
      doubleHours: sanitizeNumericField(0),
      otherPaid: sanitizeNumericField(0),
      percentageOfSales: roundNumberToTwoDecimals(
        sanitizeNumericField(laborAsPercentageOfSalesForTheDay)
      ),
      cphp: roundNumberToTwoDecimals(sanitizeNumericField(cphp)),
      pphp: roundNumberToTwoDecimals(sanitizeNumericField(pphp)),
    };
    return res;
  });

  const rowsGroupedByDepartments = _.groupBy(data, "departmentName");
  const tables: DistributionAndLaborReportDistributionDetailDepartmentTable[] =
    _.keys(rowsGroupedByDepartments).map((departmentName: string) => {
      const data = rowsGroupedByDepartments[departmentName];
      const totalRegularHours = data.reduce((accumulator, currentRow) => {
        return accumulator + currentRow.regularHours;
      }, 0);
      const totalRegularHoursEarnedAmount = data.reduce(
        (accumulator, currentRow) => {
          return accumulator + currentRow.regularHoursTotal;
        },
        0
      );
      const totalPercentageOfSales = data.reduce((accumulator, currentRow) => {
        return accumulator + currentRow.percentageOfSales;
      }, 0);
      const totalCphp = data.reduce((accumulator, currentRow) => {
        return accumulator + currentRow.cphp;
      }, 0);
      const totalPphp = data.reduce((accumulator, currentRow) => {
        return accumulator + currentRow.pphp;
      }, 0);
      const totals: DistributionAndLaborReportDistributionDetailDepartmentTotal =
        {
          departmentName,
          regularHours: roundNumberToTwoDecimals(
            sanitizeNumericField(totalRegularHours)
          ),
          regularHoursTotal: roundNumberToTwoDecimals(
            sanitizeNumericField(totalRegularHoursEarnedAmount)
          ),
          overtimeHours: sanitizeNumericField(0),
          overtimeHoursTotal: sanitizeNumericField(0),
          doubleHours: sanitizeNumericField(0),
          doubleHoursTotal: sanitizeNumericField(0),
          otherPaid: sanitizeNumericField(0),
          cphp: roundNumberToTwoDecimals(sanitizeNumericField(totalCphp)),
          pphp: roundNumberToTwoDecimals(sanitizeNumericField(totalPphp)),
          percentageOfSales: roundNumberToTwoDecimals(
            sanitizeNumericField(totalPercentageOfSales)
          ),
        };
      return {
        data,
        totals,
      };
    });

  const totalRegularHours = tables.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totals.regularHours;
  }, 0);
  const totalRegularHoursEarnedAmount = tables.reduce(
    (accumulator, currentRow) => {
      return accumulator + currentRow.totals.regularHoursTotal;
    },
    0
  );
  const totalPercentageOfSales = tables.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totals.percentageOfSales;
  }, 0);
  const totalCphp = tables.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totals.cphp;
  }, 0);
  const totalPphp = tables.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totals.pphp;
  }, 0);

  const total: DistributionAndLaborReportDistributionDetailReportTotal = {
    regularHours: roundNumberToTwoDecimals(
      sanitizeNumericField(totalRegularHours)
    ),
    regularHoursTotal: roundNumberToTwoDecimals(
      sanitizeNumericField(totalRegularHoursEarnedAmount)
    ),
    overtimeHours: sanitizeNumericField(0),
    doubleHoursTotal: sanitizeNumericField(0),
    overtimeHoursTotal: sanitizeNumericField(0),
    doubleHours: sanitizeNumericField(0),
    otherPaid: sanitizeNumericField(0),
    percentageOfSales: roundNumberToTwoDecimals(
      sanitizeNumericField(totalPercentageOfSales)
    ),
    cphp: roundNumberToTwoDecimals(sanitizeNumericField(totalCphp)),
    pphp: roundNumberToTwoDecimals(sanitizeNumericField(totalPphp)),
  };
  const distributionDetailData: DistributionDetailData = {
    tables,
    total,
  };
  return distributionDetailData;
};

const getDetailReportData = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  totalMealsNumberForTheDay: number
): DetailReportData => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const groupedWorkEvents = _.groupBy(
    workEventsForTheDayOfReport,
    (workEvent) => workEvent.position
  );
  const data: RoleReport[] = _.keys(groupedWorkEvents).map((positionId) => {
    const dataForPosition = groupedWorkEvents[positionId];
    const dataGroupedByStaffId = _.groupBy(dataForPosition, "staffId");
    const staffMembersReports: StaffMemberReport[] = _.keys(
      dataGroupedByStaffId
    )
      .map((staffId: string) => {
        const workEventsForStaffMember = dataGroupedByStaffId[staffId];
        const staffMember = staffMembers.find((sm) => sm.uid === staffId);
        if (!staffMember) return null;
        const staffFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
        const staffPunchInReportsData: StaffMemberPunchInReport[] =
          workEventsForStaffMember.map((workEvent) => {
            const date = workEvent.clockedIn.toDate();
            const timeIn = moment(workEvent.clockedIn.toDate()).format("HH:mm");
            const timeOut = workEvent.clockedOut
              ? moment(workEvent.clockedOut.toDate()).format("HH:mm")
              : "-";
            const regularHours = extractWorkEventDurationInHours(workEvent);
            const overtimeHours = 0;
            const doubleHours = 0;
            const hourlyRate = getPayrateByPostionAndStaffId(
              positionId,
              staffId
            );

            const salary = hourlyRate * regularHours;
            const fringeBenefits = 0;
            const total = salary + fringeBenefits;
            return {
              date,
              timeIn,
              rate: hourlyRate,
              timeOut,
              regularHours: roundNumberToTwoDecimals(
                sanitizeNumericField(regularHours)
              ),
              overtimeHours,
              doubleHours,
              salary: sanitizeNumericField(roundNumberToTwoDecimals(salary)),
              fringeBenefits: sanitizeNumericField(
                roundNumberToTwoDecimals(fringeBenefits)
              ),
              total: sanitizeNumericField(roundNumberToTwoDecimals(total)),
            };
          });
        const totalRegularHours = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.regularHours,
          0
        );
        const totalOvertimeHours = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.overtimeHours,
          0
        );
        const totalDoubleHours = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.doubleHours,
          0
        );
        const totalSalary = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.salary,
          0
        );
        const totalFringeBenefits = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.fringeBenefits,
          0
        );
        const totalPaid = staffPunchInReportsData.reduce(
          (acc, curr) => acc + curr.total,
          0
        );
        const total: StaffMemberReportTotal = {
          regularHours: roundNumberToTwoDecimals(
            sanitizeNumericField(totalRegularHours)
          ),
          overtimeHours: totalOvertimeHours,
          doubleHours: totalDoubleHours,
          salary: roundNumberToTwoDecimals(sanitizeNumericField(totalSalary)),
          fringeBenefits: roundNumberToTwoDecimals(
            sanitizeNumericField(totalFringeBenefits)
          ),
          total: roundNumberToTwoDecimals(sanitizeNumericField(totalPaid)),
        };
        const staffMemberReport: StaffMemberReport = {
          staffId,
          fullName: staffFullName,
          data: staffPunchInReportsData,
          total,
        };
        return staffMemberReport;
      })
      .filter((x) => !!x) as StaffMemberReport[];
    const totalRegularHours = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.regularHours,
      0
    );
    const totalOvertimeHours = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.overtimeHours,
      0
    );
    const totalDoubleHours = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.doubleHours,
      0
    );
    const totalSalary = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.salary,
      0
    );
    const totalFringeBenefits = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.fringeBenefits,
      0
    );
    const totalPaid = staffMembersReports.reduce(
      (acc, curr) => acc + curr.total.total,
      0
    );
    const total: RoleReportTotal = {
      regularHours: roundNumberToTwoDecimals(
        sanitizeNumericField(totalRegularHours)
      ),
      overtimeHours: totalOvertimeHours,
      doubleHours: totalDoubleHours,
      salary: roundNumberToTwoDecimals(sanitizeNumericField(totalSalary)),
      fringeBenefits: roundNumberToTwoDecimals(
        sanitizeNumericField(totalFringeBenefits)
      ),
      total: roundNumberToTwoDecimals(sanitizeNumericField(totalPaid)),
    };
    return {
      roleId: positionId,
      roleName: getPositionNameById(businessSettings, positionId) || "Unknown",
      data: staffMembersReports,
      total,
    };
  });
  const totalRegularHours = data.reduce(
    (acc, curr) => acc + curr.total.regularHours,
    0
  );
  const totalOvertimeHours = data.reduce(
    (acc, curr) => acc + curr.total.overtimeHours,
    0
  );
  const totalDoubleHours = data.reduce(
    (acc, curr) => acc + curr.total.doubleHours,
    0
  );
  const totalSalary = data.reduce((acc, curr) => acc + curr.total.salary, 0);
  const totalFringeBenefits = data.reduce(
    (acc, curr) => acc + curr.total.fringeBenefits,
    0
  );
  const totalPaid = data.reduce((acc, curr) => acc + curr.total.total, 0);

  const total: DetailReportTotal = {
    regularHours: roundNumberToTwoDecimals(
      sanitizeNumericField(totalRegularHours)
    ),
    overtimeHours: totalOvertimeHours,
    doubleHours: totalDoubleHours,
    salary: roundNumberToTwoDecimals(sanitizeNumericField(totalSalary)),
    fringeBenefits: roundNumberToTwoDecimals(
      sanitizeNumericField(totalFringeBenefits)
    ),
    total: roundNumberToTwoDecimals(sanitizeNumericField(totalPaid)),
  };
  return {
    data,
    total,
  };
};

const getDistributionAndLaborReportDistributionSummaryDocumentRows = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  totalMealsNumberForTheDay: number
): {
  data: DistributionAndLaborReportDistributionSummaryRow[];
  total: DistributionAndLaborReportDistributionSummaryTotal;
} => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const groupedWorkEvents = _.groupBy(
    workEventsForTheDayOfReport,
    (workEvent) =>
      businessSettings?.jobFunctions?.[workEvent.position]?.departmentId
  );
  const data: DistributionAndLaborReportDistributionSummaryRow[] = _.keys(
    groupedWorkEvents
  )
    .map((departmentId) => {
      const departmentName = DEPARTMENTS[departmentId as DepartmentId]?.title;
      if (!departmentName) return null;
      const sumOfAllPunchInsForDepartment = groupedWorkEvents[
        departmentId
      ].reduce((accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      }, 0);
      const totalLaborAmountForDepartment = groupedWorkEvents[
        departmentId
      ].reduce((accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      }, 0);

      const pphp = roundNumberToTwoDecimals(
        totalSalesForTheDay / sumOfAllPunchInsForDepartment
      );
      const cphp = roundNumberToTwoDecimals(
        totalMealsNumberForTheDay / sumOfAllPunchInsForDepartment
      );
      const laborAsPercentageOfSalesForTheDay = percentage(
        totalLaborAmountForDepartment,
        totalSalesForTheDay
      );
      return {
        regularHours: sanitizeNumericField(sumOfAllPunchInsForDepartment),
        regularHoursTotal: sanitizeNumericField(totalLaborAmountForDepartment),
        overtimeHours: sanitizeNumericField(0),
        doubleHoursTotal: sanitizeNumericField(0),
        overtimeHoursTotal: sanitizeNumericField(0),
        departmentName,
        doubleHours: sanitizeNumericField(0),
        otherPaid: sanitizeNumericField(0),
        percentageOfSales: sanitizeNumericField(
          laborAsPercentageOfSalesForTheDay
        ),
        cphp: sanitizeNumericField(cphp),
        pphp: sanitizeNumericField(pphp),
      };
    })
    .filter((x) => !!x) as DistributionAndLaborReportDistributionSummaryRow[];
  const totalRegularHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.regularHours;
  }, 0);
  const totalRegularHoursEarnedAmount = data.reduce(
    (accumulator, currentRow) => {
      return accumulator + currentRow.regularHoursTotal;
    },
    0
  );
  const totalPercentageOfSales = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfSales;
  }, 0);
  const totalCphp = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.cphp;
  }, 0);
  const totalPphp = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.pphp;
  }, 0);

  const total: DistributionAndLaborReportDistributionSummaryTotal = {
    regularHours: sanitizeNumericField(totalRegularHours),
    regularHoursTotal: sanitizeNumericField(totalRegularHoursEarnedAmount),
    overtimeHours: sanitizeNumericField(0),
    doubleHoursTotal: sanitizeNumericField(0),
    overtimeHoursTotal: sanitizeNumericField(0),
    doubleHours: sanitizeNumericField(0),
    otherPaid: sanitizeNumericField(0),
    percentageOfSales: sanitizeNumericField(totalPercentageOfSales),
    cphp: sanitizeNumericField(totalCphp),
    pphp: sanitizeNumericField(totalPphp),
  };
  return {
    data,
    total,
  };
};

// TODO getPayrateByPostionAndStaffId gets calculated wrong bro wtf PAUL

const getDistributionAndLaborReportTaskSummaryDocumentRows = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[]
): {
  data: DistributionAndLaborReportTaskSummaryRow[];
  total: DistributionAndLaborReportTaskSummaryTotal;
} => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const groupedWorkEvents = _.groupBy(workEventsForTheDayOfReport, "position");
  const data: DistributionAndLaborReportTaskSummaryRow[] = _.keys(
    groupedWorkEvents
  ).map((positionId) => {
    const positionData = getPositionById(businessSettings, positionId);
    const roleName = positionData?.title || "Unknown";
    const departmentId: DepartmentId =
      (positionData?.departmentId as DepartmentId) || ("other" as DepartmentId);
    const departmentName = DEPARTMENTS[departmentId].title;
    const sumOfAllPunchInsForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      },
      0
    );
    const totalLaborAmountForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      },
      0
    );

    return {
      roleName,
      roleId: positionId,
      regularHours: roundNumberToTwoDecimals(
        sanitizeNumericField(sumOfAllPunchInsForPosition)
      ),
      overtimeHours: sanitizeNumericField(0),
      departmentName,
      doubleHours: sanitizeNumericField(0),
      salary: roundNumberToTwoDecimals(
        sanitizeNumericField(totalLaborAmountForPosition)
      ),
      fringeBenefits: sanitizeNumericField(0),
      total: roundNumberToTwoDecimals(
        sanitizeNumericField(totalLaborAmountForPosition)
      ),
    };
  });
  const totalLabor = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.salary;
  }, 0);
  const totalHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.regularHours;
  }, 0);

  const total: DistributionAndLaborReportTaskSummaryTotal = {
    regularHours: roundNumberToTwoDecimals(sanitizeNumericField(totalHours)),
    overtimeHours: sanitizeNumericField(0),
    doubleHours: sanitizeNumericField(0),
    salary: roundNumberToTwoDecimals(sanitizeNumericField(totalLabor)),
    fringeBenefits: roundNumberToTwoDecimals(sanitizeNumericField(0)),
    total: roundNumberToTwoDecimals(sanitizeNumericField(totalLabor)),
  };
  return {
    data,
    total,
  };
};

export const extractShiftDurationInHours = (shift: TangoShift) => {
  return roundNumberToTwoDecimals(
    sanitizeNumericField(
      Math.abs(
        moment
          .duration(
            moment(shift.startDate.toMillis()).diff(
              moment(shift.endDate.toMillis())
            )
          )
          .asHours()
      )
    )
  );
};

export const extractWorkEventDurationInHours = (we: WorkEvent) => {
  return roundNumberToTwoDecimals(
    sanitizeNumericField(
      Math.abs(
        moment
          .duration(
            moment(we?.clockedIn?.toMillis()).diff(
              moment(we?.clockedOut?.toMillis())
            )
          )
          .asHours()
      )
    )
  );
};

const getDistributionAndLaborReportDepartmentStatisticDocumentRows = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  totalCustomersNumberForTheDay: number,
  totalMealsNumberForTheDay: number
): {
  data: DistributionAndLaborReportDepartmentStatisticRow[];
  total: DistributionAndLaborReportDepartmentStatisticTotal;
} => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };
  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const totalLaborForTheDay = workEventsForTheDayOfReport.reduce(
    (accumulator: number, workEvent: WorkEvent) => {
      if (workEvent.staffId) {
        const workEventDuration = extractWorkEventDurationInHours(workEvent);
        return (
          accumulator +
          getPayrateByPostionAndStaffId(workEvent.position, workEvent.staffId) *
            workEventDuration
        );
      }
      return accumulator;
    },
    0
  );

  const totalHoursForTheDay = workEventsForTheDayOfReport.reduce(
    (accumulator: number, workEvent: WorkEvent) => {
      return accumulator + extractWorkEventDurationInHours(workEvent);
    },
    0
  );

  const groupedWorkEvents = _.groupBy(
    workEventsForTheDayOfReport,
    (workEvent) =>
      businessSettings?.jobFunctions?.[workEvent.position]?.departmentId
  );
  const data: DistributionAndLaborReportDepartmentStatisticRow[] = _.keys(
    groupedWorkEvents
  )
    .map((departmentId) => {
      const departmentName = DEPARTMENTS[departmentId as DepartmentId]?.title;
      if (!departmentName) return null;
      const sumOfAllPunchInsForDepartment = groupedWorkEvents[
        departmentId
      ].reduce((accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      }, 0);
      const totalLaborAmountForDepartment = groupedWorkEvents[
        departmentId
      ].reduce((accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      }, 0);
      const laborAsPercentageOfSalesForTheDay = percentage(
        totalLaborAmountForDepartment,
        totalSalesForTheDay
      );
      const percentageOfTotalLabor = percentage(
        totalLaborAmountForDepartment,
        totalLaborForTheDay
      );
      const percentageOfTotalHours = percentage(
        sumOfAllPunchInsForDepartment,
        totalHoursForTheDay
      );
      const salesPerHour = roundNumberToTwoDecimals(
        totalSalesForTheDay / sumOfAllPunchInsForDepartment
      );
      const customersPerHour = roundNumberToTwoDecimals(
        totalCustomersNumberForTheDay / sumOfAllPunchInsForDepartment
      );
      const mealsPerHour = roundNumberToTwoDecimals(
        totalMealsNumberForTheDay / sumOfAllPunchInsForDepartment
      );

      return {
        totalLabor: sanitizeNumericField(totalLaborAmountForDepartment),
        totalHours: sanitizeNumericField(sumOfAllPunchInsForDepartment),
        percentageOfSales: sanitizeNumericField(
          laborAsPercentageOfSalesForTheDay
        ),
        departmentName,
        percentageOfTotalLabor: sanitizeNumericField(percentageOfTotalLabor),
        percentageOfTotalHours: sanitizeNumericField(percentageOfTotalHours),
        salesPerHour: sanitizeNumericField(salesPerHour),
        customersPerHour: sanitizeNumericField(customersPerHour),
        mealsPerHour: sanitizeNumericField(mealsPerHour),
      };
    })
    .filter((x) => !!x) as DistributionAndLaborReportDepartmentStatisticRow[];
  const totalLabor = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totalLabor;
  }, 0);
  const totalHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totalHours;
  }, 0);
  const totalPercentageOfSales = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfSales;
  }, 0);
  const totalPercentageOfTotalLabor = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfTotalLabor;
  }, 0);
  const totalPercentageOfTotalHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfTotalHours;
  }, 0);

  const total: DistributionAndLaborReportDepartmentStatisticTotal = {
    totalLabor: sanitizeNumericField(totalLabor),
    percentageOfTotalLabor: sanitizeNumericField(totalPercentageOfTotalLabor),
    totalHours: sanitizeNumericField(totalHours),
    percentageOfTotalHours: sanitizeNumericField(totalPercentageOfTotalHours),
    percentageOfSales: sanitizeNumericField(totalPercentageOfSales),
    customersPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalCustomersNumberForTheDay / totalHours)
    ),
    mealsPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalMealsNumberForTheDay / totalHours)
    ),
    salesPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalSalesForTheDay / totalHours)
    ),
  };
  return {
    data,
    total,
  };
};

const getDistributionAndLaborReportTaskStatisticDocumentRows = (
  workEvents: WorkEvent[],
  totalSalesForTheDay: number,
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  totalCustomersNumberForTheDay: number,
  totalMealsNumberForTheDay: number
): {
  data: DistributionAndLaborReportTaskStatisticRow[];
  total: DistributionAndLaborReportTaskStatisticTotal;
} => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };
  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );

  const totalLaborForTheDay = workEventsForTheDayOfReport.reduce(
    (accumulator: number, workEvent: WorkEvent) => {
      if (workEvent.staffId) {
        const workEventDuration = extractWorkEventDurationInHours(workEvent);
        return (
          accumulator +
          getPayrateByPostionAndStaffId(workEvent.position, workEvent.staffId) *
            roundNumberToTwoDecimals(sanitizeNumericField(workEventDuration))
        );
      }
      return accumulator;
    },
    0
  );

  const totalHoursForTheDay = workEventsForTheDayOfReport.reduce(
    (accumulator: number, workEvent: WorkEvent) => {
      return accumulator + extractWorkEventDurationInHours(workEvent);
    },
    0
  );

  const groupedWorkEvents = _.groupBy(workEventsForTheDayOfReport, "position");
  const data: DistributionAndLaborReportTaskStatisticRow[] = _.keys(
    groupedWorkEvents
  ).map((positionId) => {
    const positionData = getPositionById(businessSettings, positionId);
    const roleName = positionData?.title || "Unknown";
    const departmentId: DepartmentId =
      (positionData?.departmentId as DepartmentId) || ("other" as DepartmentId);
    const departmentName = DEPARTMENTS[departmentId].title;
    const sumOfAllPunchInsForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        return accumulator + extractWorkEventDurationInHours(workEvent);
      },
      0
    );
    const totalLaborAmountForPosition = groupedWorkEvents[positionId].reduce(
      (accumulator: number, workEvent: WorkEvent) => {
        if (workEvent.staffId) {
          const workEventDuration = extractWorkEventDurationInHours(workEvent);
          return (
            accumulator +
            getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            ) *
              workEventDuration
          );
        }
        return accumulator;
      },
      0
    );
    const laborAsPercentageOfSalesForTheDay = percentage(
      totalLaborAmountForPosition,
      totalSalesForTheDay
    );
    const percentageOfTotalLabor = percentage(
      totalLaborAmountForPosition,
      totalLaborForTheDay
    );
    const percentageOfTotalHours = percentage(
      sumOfAllPunchInsForPosition,
      totalHoursForTheDay
    );
    const salesPerHour = roundNumberToTwoDecimals(
      totalSalesForTheDay / sumOfAllPunchInsForPosition
    );
    const customersPerHour = roundNumberToTwoDecimals(
      totalCustomersNumberForTheDay / sumOfAllPunchInsForPosition
    );
    const mealsPerHour = roundNumberToTwoDecimals(
      totalMealsNumberForTheDay / sumOfAllPunchInsForPosition
    );

    return {
      roleName,
      roleId: positionId,
      totalLabor: roundNumberToTwoDecimals(
        sanitizeNumericField(totalLaborAmountForPosition)
      ),
      totalHours: roundNumberToTwoDecimals(
        sanitizeNumericField(sumOfAllPunchInsForPosition)
      ),
      percentageOfSales: roundNumberToTwoDecimals(
        sanitizeNumericField(laborAsPercentageOfSalesForTheDay)
      ),
      departmentName,
      percentageOfTotalLabor: roundNumberToTwoDecimals(
        sanitizeNumericField(percentageOfTotalLabor)
      ),
      percentageOfTotalHours: roundNumberToTwoDecimals(
        sanitizeNumericField(percentageOfTotalHours)
      ),
      salesPerHour: roundNumberToTwoDecimals(
        sanitizeNumericField(salesPerHour)
      ),
      customersPerHour: roundNumberToTwoDecimals(
        sanitizeNumericField(customersPerHour)
      ),
      mealsPerHour: roundNumberToTwoDecimals(
        sanitizeNumericField(mealsPerHour)
      ),
    };
  });
  const totalLabor = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totalLabor;
  }, 0);
  const totalHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.totalHours;
  }, 0);
  const totalPercentageOfSales = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfSales;
  }, 0);
  const totalPercentageOfTotalLabor = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfTotalLabor;
  }, 0);
  const totalPercentageOfTotalHours = data.reduce((accumulator, currentRow) => {
    return accumulator + currentRow.percentageOfTotalHours;
  }, 0);

  const total: DistributionAndLaborReportTaskStatisticTotal = {
    totalLabor: roundNumberToTwoDecimals(sanitizeNumericField(totalLabor)),
    percentageOfTotalLabor: roundNumberToTwoDecimals(
      sanitizeNumericField(totalPercentageOfTotalLabor)
    ),
    totalHours: roundNumberToTwoDecimals(sanitizeNumericField(totalHours)),
    percentageOfTotalHours: roundNumberToTwoDecimals(
      sanitizeNumericField(totalPercentageOfTotalHours)
    ),
    percentageOfSales: roundNumberToTwoDecimals(
      sanitizeNumericField(totalPercentageOfSales)
    ),
    customersPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalCustomersNumberForTheDay / totalHours)
    ),
    mealsPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalMealsNumberForTheDay / totalHours)
    ),
    salesPerHour: sanitizeNumericField(
      roundNumberToTwoDecimals(totalSalesForTheDay / totalHours)
    ),
  };
  return {
    data,
    total,
  };
};

export const getDistributionAndLaborReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<DistributionAndLaborReportForADay[]> => {
  const {
    businessSettings,
    salesData,
    workEvents,
    staffMembers,
    totalSales,
    groupedSales,
  } = await fetchSharedData(businessId, startDate, endDate);
  const distributionAndLaborReports: DistributionAndLaborReportForADay[] =
    _.keys(groupedSales).map((dateString) => {
      const flattenTabsForDate: FlattenTab[] = groupedSales[dateString];
      const totalSales = flattenTabsForDate.reduce(
        (accum: number, currentValue: FlattenTab) =>
          accum + (currentValue.netAmount - currentValue.refundedAmount),
        0
      );
      const totalCustomersNumberForTheDay = flattenTabsForDate.length;
      const totalMealsNumberForTheDay = flattenTabsForDate.filter(
        (ft) => ft.hasMealPurchased
      ).length;
      const taskStatistic =
        getDistributionAndLaborReportTaskStatisticDocumentRows(
          workEvents,
          totalSales,
          new Date(dateString),
          businessSettings,
          staffMembers,
          totalCustomersNumberForTheDay,
          totalMealsNumberForTheDay
        );

      const taskSummary = getDistributionAndLaborReportTaskSummaryDocumentRows(
        workEvents,
        totalSales,
        new Date(dateString),
        businessSettings,
        staffMembers
      );
      const departmentStatistic =
        getDistributionAndLaborReportDepartmentStatisticDocumentRows(
          workEvents,
          totalSales,
          new Date(dateString),
          businessSettings,
          staffMembers,
          totalCustomersNumberForTheDay,
          totalMealsNumberForTheDay
        );

      const distributionSummary =
        getDistributionAndLaborReportDistributionSummaryDocumentRows(
          workEvents,
          totalSales,
          new Date(dateString),
          businessSettings,
          staffMembers,
          totalMealsNumberForTheDay
        );

      const distributionDetailData =
        getDistributionAndLaborReportDistributionDetailsData(
          workEvents,
          totalSales,
          new Date(dateString),
          businessSettings,
          staffMembers,
          totalMealsNumberForTheDay
        );

      const detailReportData = getDetailReportData(
        workEvents,
        totalSales,
        new Date(dateString),
        businessSettings,
        staffMembers,
        totalMealsNumberForTheDay
      );

      return {
        id: dateString,
        date: new Date(dateString),
        taskSummaryData: taskSummary.data,
        taskSummaryTotal: taskSummary.total,
        taskStatisticData: taskStatistic.data,
        taskStatisticTotal: taskStatistic.total,
        departmentStatisticData: departmentStatistic.data,
        departmentStatisticTotal: departmentStatistic.total,
        distributionSummaryData: distributionSummary.data,
        distributionSummaryTotal: distributionSummary.total,
        distributionDetailData: distributionDetailData,
        detailReportData,
        totalSales,
        totalMealsNumberForTheDay,
        totalCustomersNumberForTheDay,
      };
    });

  return distributionAndLaborReports;
};

export type TimeSlotName = "Lunch" | "Late Lunch" | "Dinner" | "Late";
export type TimeSlot = {
  startTime: string;
  endTime: string;
  isNextDay: boolean;
  name: TimeSlotName;
};

export type WeeklyTimeSlotSalesTableRow = {
  title: string;
  salesRange: number[];
  total: number;
};

export type WeeklyTimeSlotSalesTableTotal = {
  title: string;
  salesRange: number[];
  total: number;
};

export type WeeklyTimeSlotSalesTable = {
  timeSlot: TimeSlot;
  header: string[];
  data: WeeklyTimeSlotSalesTableRow[];
  total: WeeklyTimeSlotSalesTableTotal;
};

export type WeeklySummaryServiceAreaGroupedSalesReport = {
  serviceAreaName: string;
  timeSlotSalesTables: WeeklyTimeSlotSalesTable[];
  total: WeeklyTimeSlotSalesTableTotal;
};

export type WeeklySummarySalesReport = {
  id: string;
  startDate: Date;
  endDate: Date;
  serviceAreas: WeeklySummaryServiceAreaGroupedSalesReport[];
};

export type EmployeeSalaryTableRow = {
  employeeFullName: string;
  roleName: string;
  roleId: string;
  date: Date;
  timeIn: string;
  timeOut: string;
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  rate: number;
  salary: number;
};

export type EmployeeSalaryTableTotal = {
  regularHours: number;
  overtimeHours: number;
  doubleHours: number;
  salary: number;
};

export type EmployeeSalaryTable = {
  data: EmployeeSalaryTableRow[];
  staffId: string;
  staffFullName: string;
  total: EmployeeSalaryTableTotal;
};

export type WeeklyEmployeeSalaryReport = {
  id: string;
  startDate: Date;
  endDate: Date;
  data: EmployeeSalaryTable[];
};

export type TimeCardSummaryReportRow = {
  id: string;
  posId: string;
  employeeCode: string;
  employeeFullName: string;
  roleName: string;
  roleId: string;
  date: Date;
  timeIn: string;
  timeOut: string;
  totalHours: number;
  otherPaid: number;
  adjusted: number;
};

export type TimeCardSummaryReport = {
  id: string;
  date: Date;
  data: TimeCardSummaryReportRow[];
};

export const TimeSlots: TimeSlot[] = [
  {
    startTime: "11:00",
    endTime: "14:00",
    isNextDay: false,
    name: "Lunch",
  },
  {
    startTime: "14:00",
    endTime: "16:00",
    isNextDay: false,
    name: "Late Lunch",
  },
  {
    startTime: "16:00",
    endTime: "21:00",
    isNextDay: false,
    name: "Dinner",
  },
  {
    startTime: "21:00",
    endTime: "11:00",
    isNextDay: true,
    name: "Late",
  },
];

const convertDateToMilitaryTimeInteger = (date: Date): number =>
  Number(moment(date).format("HHmm"));
const convertMilitaryTimeStringToMilitaryTimeInteger = (
  milTimeString: string
): number => Number(moment(milTimeString, "HH:mm").format("HHmm"));

const convertIntegerTimeToStringTime = (time: number) => {
  switch (String(time).length) {
    case 1:
      return "00:0" + String(time);
    case 2:
      return "00:" + String(time);
    case 3:
      return (
        "0" + String(time).substring(0, 1) + ":" + String(time).substring(1, 3)
      );
    case 4:
      return String(time).substring(0, 2) + ":" + String(time).substring(2, 4);
    default:
      return null;
  }
};

const generateStringTimeInvervals = (startTime: number, endTime: number) => {
  const times = [];
  // const minTime = Math.min(startTime, endTime)
  // const maxTime = Math.max(startTime, endTime)

  for (let i = startTime; i <= endTime; i += 1) {
    switch (String(i).length) {
      case 1:
        if (Number(String(i).substring(0, 1)) === 0) {
          times.push(convertIntegerTimeToStringTime(i));
        }
        break;
      case 2:
        if (
          Number(String(i).substring(0, 2)) === 30 ||
          Number(String(i).substring(0, 2)) === 0
        ) {
          times.push(convertIntegerTimeToStringTime(i));
        }
        break;
      case 3:
        if (
          Number(String(i).substring(1, 3)) === 30 ||
          Number(String(i).substring(1, 3)) === 0
        ) {
          times.push(convertIntegerTimeToStringTime(i));
        }
        break;
      case 4:
        if (
          Number(String(i).substring(2, 4)) === 30 ||
          Number(String(i).substring(2, 4)) === 0
        ) {
          times.push(convertIntegerTimeToStringTime(i));
        }
        break;
      default:
        break;
    }
  }
  return times;
};

const generateWeeklyServiceAreaReports = (
  salesGroupedByWeek: FlattenedTabsGroupedByWeek,
  business: TangoBusiness
): WeeklySummarySalesReport[] => {
  return _.keys(salesGroupedByWeek).map((startOfTheWeekDateString: string) => {
    const dataForTheWeek = salesGroupedByWeek[startOfTheWeekDateString];
    console.log("dataForTheWeek", dataForTheWeek);
    const startDate = dataForTheWeek.startDate;
    const endDate = dataForTheWeek.endDate;
    const flattenTabsGroupedByServiceAreaName = _.groupBy(
      dataForTheWeek.flattenTabs.sort(
        (a, b) =>
          moment(a.createdAt).toDate().getTime() -
          moment(b.createdAt).toDate().getTime()
      ),
      "serviceAreaName"
    );
    const serviceAreas: WeeklySummaryServiceAreaGroupedSalesReport[] = _.keys(
      flattenTabsGroupedByServiceAreaName
    ).map((serviceAreaName: string) => {
      const dataForServiceArea =
        flattenTabsGroupedByServiceAreaName[serviceAreaName];

      const dataGroupedByTimeSlots = _.groupBy(
        dataForServiceArea,
        (flattenTab: FlattenTab) => {
          const timeSlotForTheMilitaryTime = TimeSlots.find((timeSlot) => {
            const flattenTabMoment = moment(flattenTab.createdAt);
            // console.log("flattenTabMoment with timezone", flattenTabMoment.format(), moment(flattenTab.createdAt).format())
            // console.log("flattenTabMoment without timezone", new Date(flattenTab.createdAt))
            // console.log("flattenTab", flattenTab)
            let timeSlotStartTimeMoment = flattenTabMoment.clone().set({
              hours: Number(timeSlot.startTime.split(":")[0]),
              minutes: Number(timeSlot.startTime.split(":")[1]),
            });
            let timeSlotEndTimeMoment = flattenTabMoment.clone().set({
              hours: Number(timeSlot.endTime.split(":")[0]),
              minutes: Number(timeSlot.endTime.split(":")[1]),
            });

            if (timeSlot.isNextDay) {
              // order comes in at 10 pm 15th Dec
              // 10 pm 15th Dec is after 11 am 15th Dec
              // success

              // order comes in at 4 am 16th Dec
              // 04 am 16th Dec is not after 11 am 16th Dec
              // fuck up

              // createdAt = 11 pm 22nd Dec
              // timeSlotEndTime = 11 am 22nd Dec
              if (flattenTabMoment.isAfter(timeSlotEndTimeMoment)) {
                timeSlotEndTimeMoment = timeSlotEndTimeMoment
                  .clone()
                  .add(1, "day");
                // is going to be after the end time
                // this is how we know that the createdAt is before midnight
                // add one day to time slot end date
                // leave the start date as is
              }
              // createdAt = 1 am 22nd Dec
              // timeSlotEndTime = 11 am 22nd Dec
              if (!flattenTabMoment.isAfter(timeSlotEndTimeMoment)) {
                timeSlotStartTimeMoment = timeSlotStartTimeMoment
                  .clone()
                  .subtract(1, "day");
                // is going to be after the end time
                // this is how we know that the createdAt is after midnight
                // subtract one day from the time slot start date
                // leave time slot end date as is
              }
            }
            return (
              flattenTabMoment.isSameOrAfter(timeSlotStartTimeMoment) &&
              flattenTabMoment.isBefore(timeSlotEndTimeMoment)
            );
          });

          return timeSlotForTheMilitaryTime?.name;
        }
      );
      console.log("dataGroupedByTimeSlots", dataGroupedByTimeSlots);
      const timeSlotSalesTables: WeeklyTimeSlotSalesTable[] = _.keys(
        dataGroupedByTimeSlots
      ).map((timeSlotName: string) => {
        const weekDateRange = _.range(0, 7).map((i) =>
          moment(startDate).add(i, "days").toDate()
        );
        const weekNameHeaders = weekDateRange.map((date) =>
          moment(date).format("ddd - DD")
        );
        const timeSlotForTheMilitaryTime = TimeSlots.find(
          (timeSlot) => timeSlot.name === timeSlotName
        ) as TimeSlot;
        const dataForTimeSlot = dataGroupedByTimeSlots[timeSlotName];

        let timeIntervalsForTheSlot = generateStringTimeInvervals(
          convertMilitaryTimeStringToMilitaryTimeInteger(
            timeSlotForTheMilitaryTime.startTime
          ),
          convertMilitaryTimeStringToMilitaryTimeInteger(
            timeSlotForTheMilitaryTime.endTime
          )
        ).filter((x) => x !== null) as string[];

        if (timeSlotForTheMilitaryTime.isNextDay) {
          const firstPartOFTimeIntervalsForTheSlot =
            generateStringTimeInvervals(
              convertMilitaryTimeStringToMilitaryTimeInteger(
                timeSlotForTheMilitaryTime.startTime
              ),
              2359
            ).filter((x) => x !== null) as string[];
          const secondPartOFTimeIntervalsForTheSlot =
            generateStringTimeInvervals(
              0,
              convertMilitaryTimeStringToMilitaryTimeInteger(
                timeSlotForTheMilitaryTime.endTime
              )
            ).filter((x) => x !== null) as string[];
          timeIntervalsForTheSlot = [
            ...firstPartOFTimeIntervalsForTheSlot,
            ...secondPartOFTimeIntervalsForTheSlot,
          ];
        }

        const rowsForTimeSlot: WeeklyTimeSlotSalesTableRow[] =
          timeIntervalsForTheSlot.map((timeIntervalStart: string) => {
            const timeIntervalStartDate = moment(
              timeIntervalStart,
              "HH:mm"
            ).toDate();
            const timeIntervalEndDate = moment(timeIntervalStartDate)
              .add(30, "minutes")
              .toDate();
            const title = `${moment(timeIntervalStartDate).format(
              "HH:mm"
            )} - ${moment(timeIntervalEndDate).format("HH:mm")}`;
            const salesRange = weekDateRange.map((date) => {
              const dataForDayOfTheWeek = dataForTimeSlot.filter((flattenTab) =>
                moment(flattenTab.createdAt)
                  .startOf("day")
                  .add(4, "hours")
                  .isSame(moment(date), "date")
              );

              //11 am to 11 am

              const dataForTimeInterval = dataForDayOfTheWeek.filter(
                (flattenTab) => {
                  const timeIntervalStartMoment = moment(date).set({
                    hours: Number(timeIntervalStart.split(":")[0]),
                    minutes: Number(timeIntervalStart.split(":")[1]),
                  });
                  const timeIntervalEndMoment = timeIntervalStartMoment
                    .clone()
                    .add(30, "minutes");
                  // if (moment(flattenTab.createdAt).isAfter(timeIntervalEndMoment)) {
                  //   timeIntervalEndMoment = timeIntervalEndMoment.clone().add(1, 'day')
                  //   // is going to be after the end time
                  //   // this is how we know that the createdAt is before midnight
                  //   // add one day to time slot end date
                  //   // leave the start date as is
                  // }
                  // // createdAt = 1 am 22nd Dec
                  // // timeSlotEndTime = 11 am 22nd Dec
                  // if (!moment(flattenTab.createdAt).isAfter(timeIntervalEndMoment)) {
                  //   timeIntervalStartMoment = timeIntervalStartMoment.clone().subtract(1, 'day')
                  //   // is going to be after the end time
                  //   // this is how we know that the createdAt is after midnight
                  //   // subtract one day from the time slot start date
                  //   // leave time slot end date as is
                  // }
                  const flattenTabMoment = moment(flattenTab.createdAt);

                  return (
                    flattenTabMoment.isSameOrAfter(timeIntervalStartMoment) &&
                    flattenTabMoment.isSameOrBefore(timeIntervalEndMoment)
                  );
                  //TODO: Handle midnight edge case
                }
              );

              // if (dataForTimeInterval.length) {
              //   console.log("has dataForTimeInterval ",dataForTimeInterval)
              // }

              const totalForTimeInterval = dataForTimeInterval.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue.netAmount - currentValue.refundedAmount),
                0
              );
              // if (totalForTimeInterval < 0) {
              //   console.log("data", dataForTimeInterval)
              // }
              return sanitizeNumericField(
                roundNumberToTwoDecimals(totalForTimeInterval)
              );
            });
            const total = salesRange.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
            if (total !== 0) {
              console.log("salesRange", salesRange);
            }
            return {
              title,
              salesRange: salesRange,
              total: sanitizeNumericField(roundNumberToTwoDecimals(total)),
            };
          });

        const data = rowsForTimeSlot;
        const totalTableSalesRange = weekDateRange.map((_, i) => {
          return sanitizeNumericField(
            roundNumberToTwoDecimals(
              data.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.salesRange[i],
                0
              )
            )
          );
        });

        const totalTableTotal = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total,
          0
        );

        const totalForTable: WeeklyTimeSlotSalesTableTotal = {
          title: timeSlotName,
          salesRange: totalTableSalesRange,
          total: sanitizeNumericField(
            roundNumberToTwoDecimals(totalTableTotal)
          ),
        };
        console.log(
          "totalForTable: " + startOfTheWeekDateString,
          totalForTable
        );
        return {
          header: ["From - To", ...weekNameHeaders, "Total"],
          timeSlot: timeSlotForTheMilitaryTime,
          data,
          total: totalForTable,
        };
      });

      const totalSalesRange = _.range(0, 7).map((i) => {
        return timeSlotSalesTables.reduce((acc, curr) => {
          return acc + curr.total.salesRange[i];
        }, 0);
      });

      const totalTotal = timeSlotSalesTables.reduce((acc, curr) => {
        return acc + curr.total.total;
      }, 0);

      return {
        serviceAreaName,
        timeSlotSalesTables,
        total: {
          title: "Total",
          total: totalTotal,
          salesRange: totalSalesRange,
        },
      };
    });

    const consolidatedTables: WeeklyTimeSlotSalesTable[] = [];
    for (const sa of serviceAreas) {
      for (const t of sa.timeSlotSalesTables) {
        const table = _.cloneDeep(t);
        const accumTableIndexForTheTimeSlot = consolidatedTables.findIndex(
          (accTable) => accTable.timeSlot.name === table.timeSlot.name
        );

        if (accumTableIndexForTheTimeSlot !== -1) {
          consolidatedTables[accumTableIndexForTheTimeSlot].data =
            consolidatedTables[accumTableIndexForTheTimeSlot].data.map(
              (row, rowIndex): WeeklyTimeSlotSalesTableRow => {
                const currTableRow = table.data[rowIndex];
                const newSalesRange = row.salesRange.map(
                  (value, salesRangeIndex) =>
                    value + currTableRow.salesRange[salesRangeIndex]
                );
                const newTotal = row.total + currTableRow.total;
                return {
                  ...row,
                  salesRange: newSalesRange,
                  total: newTotal,
                };
              }
            );
          consolidatedTables[accumTableIndexForTheTimeSlot].total.salesRange =
            consolidatedTables[
              accumTableIndexForTheTimeSlot
            ].total.salesRange.map(
              (value, totSalesRangeIndex) =>
                value + table.total.salesRange[totSalesRangeIndex]
            );
          consolidatedTables[accumTableIndexForTheTimeSlot].total.total +=
            table.total.total;
        } else {
          consolidatedTables.push(table);
        }
      }
    }
    const consolidatedTableTotalSalesRange = _.range(0, 7).map((i) => {
      return consolidatedTables.reduce((acc, curr) => {
        return acc + curr.total.salesRange[i];
      }, 0);
    });

    const consolidatedTableTotalTotal = consolidatedTables.reduce(
      (acc, curr) => {
        return acc + curr.total.total;
      },
      0
    );
    const consolidatedTableTotal: WeeklyTimeSlotSalesTableTotal = {
      total: consolidatedTableTotalTotal,
      title: "Total",
      salesRange: consolidatedTableTotalSalesRange,
    };
    return {
      id: _.uniqueId(),
      startDate,
      endDate,
      serviceAreas: [
        ...serviceAreas,
        {
          serviceAreaName: "Consolidated",
          timeSlotSalesTables: consolidatedTables,
          total: consolidatedTableTotal,
        },
      ],
    };
  });
};

// Weekly Summary Sales Report stuff
export const generateWeeklySummarySalesReports = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<WeeklySummarySalesReport[]> => {
  const { salesGroupedByWeek, business } = await fetchSharedData(
    businessId,
    startDate,
    endDate
  );
  const weeklyServiceAreaReports: WeeklySummarySalesReport[] =
    generateWeeklyServiceAreaReports(salesGroupedByWeek, business);
  console.log("weeklyServiceAreaReports", weeklyServiceAreaReports);
  return weeklyServiceAreaReports;
};

const getTimeSummaryReportData = (
  workEvents: WorkEvent[],
  dateForReporting: Date,
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[]
): TimeCardSummaryReport => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };
  const dateOfReportingStartMoment = moment(dateForReporting)
    .startOf("day")
    .add(4, "hours");
  const dateOfReportingEndMoment = dateOfReportingStartMoment
    .clone()
    .add(1, "day");
  const workEventsForTheDayOfReport = workEvents.filter(
    (workEvent) =>
      moment(workEvent?.clockedIn?.toDate()).isSameOrAfter(
        dateOfReportingStartMoment
      ) &&
      moment(workEvent?.clockedIn?.toDate()).isBefore(dateOfReportingEndMoment)
  );
  const data: TimeCardSummaryReportRow[] = workEventsForTheDayOfReport
    .map((workEvent) => {
      const hourlyRate = getPayrateByPostionAndStaffId(
        workEvent.position,
        workEvent.staffId
      );
      const date = workEvent.clockedIn.toDate();
      const timeIn = moment(workEvent.clockedIn.toDate()).format("HH:mm");
      const timeOut = workEvent.clockedOut
        ? moment(workEvent.clockedOut.toDate()).format("HH:mm")
        : "-";
      console.log("workEvent", workEvent);

      const regularHours = extractWorkEventDurationInHours(workEvent);
      const staffMember = staffMembers.find(
        (sm) => sm.uid === workEvent.staffId
      );

      if (!roundNumberToTwoDecimals(sanitizeNumericField(regularHours)))
        return null;
      if (!staffMember) return null;
      const staffMemberFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
      const row: TimeCardSummaryReportRow = {
        id: workEvent.id,
        posId: _.uniqueId(),
        employeeCode: staffMember.externalEmployeeId ?? "****",
        roleId: workEvent.position,
        roleName:
          getPositionNameById(businessSettings, workEvent.position) ||
          "Unknown",
        employeeFullName: staffMemberFullName,
        date: dateForReporting,
        timeIn,
        timeOut,
        totalHours: roundNumberToTwoDecimals(
          sanitizeNumericField(regularHours)
        ),
        otherPaid: 0,
        adjusted: 0,
      };
      return row;
    })
    .filter((x) => !!x) as TimeCardSummaryReportRow[];
  const sortedData = _.orderBy(data, ["employeeFullName"], ["asc"]);

  return {
    id: _.uniqueId(),
    data: sortedData,
    date: dateForReporting,
  };
};

const getWeeklyEmployeeSalaryReports = (
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  workEventsGroupedByWeek: WorkEventsGroupedByWeek
): WeeklyEmployeeSalaryReport[] => {
  const getPayrateByPostionAndStaffId = (
    positionId: string,
    staffId: string
  ) => {
    const staffMemberPayrates =
      staffMembers.find((s) => s?.uid === staffId)?.payRates || [];
    const rolePayrate = staffMemberPayrates.find(
      (payRate) => payRate?.roleId === positionId
    );
    if (rolePayrate?.amount) {
      return rolePayrate?.amount;
    }
    return 0;
  };

  console.log("workEventsGroupedByWeek", workEventsGroupedByWeek);

  const weeklySalaryReports: WeeklyEmployeeSalaryReport[] = _.keys(
    workEventsGroupedByWeek
  ).map((dateString: string) => {
    const workEventsGroup = workEventsGroupedByWeek[dateString];
    const workEventsForTheWeekGroupedByStaffId = _.groupBy(
      workEventsGroup.workEvents,
      "staffId"
    );
    const startDate = workEventsGroup.startDate;
    const endDate = workEventsGroup.endDate;
    const employeeSalaryTables: EmployeeSalaryTable[] = _.keys(
      workEventsForTheWeekGroupedByStaffId
    )
      .map((staffId: string) => {
        const staffWorkEvents = workEventsForTheWeekGroupedByStaffId[staffId];
        const staffMember = staffMembers.find((sm) => sm.uid === staffId);
        const staffMemberFullName = `${staffMember?.contact.firstName} ${staffMember?.contact.lastName}`;
        if (!staffMember) return null;
        const staffRows: EmployeeSalaryTableRow[] = staffWorkEvents
          .map((workEvent) => {
            const hourlyRate = getPayrateByPostionAndStaffId(
              workEvent.position,
              workEvent.staffId
            );
            const weDate = workEvent.clockedIn.toDate();
            const timeIn = moment(workEvent.clockedIn.toDate()).format("HH:mm");
            const timeOut = workEvent.clockedOut
              ? moment(workEvent.clockedOut.toDate()).format("HH:mm")
              : "-";
            const regularHours = extractWorkEventDurationInHours(workEvent);
            const overtimeHours = 0;
            const doubleHours = 0;
            const staffMember = staffMembers.find(
              (sm) => sm.uid === workEvent.staffId
            );
            const salary = regularHours * hourlyRate;

            if (!regularHours) return null;

            const row: EmployeeSalaryTableRow = {
              roleId: workEvent.position,
              roleName:
                getPositionNameById(businessSettings, workEvent.position) ||
                "Unknown",
              employeeFullName: staffMemberFullName,
              timeIn,
              timeOut,
              regularHours,
              date: weDate,
              rate: hourlyRate,
              salary,
              overtimeHours,
              doubleHours,
            };
            return row;
          })
          .filter((x) => !!x) as EmployeeSalaryTableRow[];

        const totalRegularHours = staffRows.reduce((acc, curr) => {
          return acc + curr.regularHours;
        }, 0);
        const totalDoubleHours = staffRows.reduce((acc, curr) => {
          return acc + curr.doubleHours;
        }, 0);
        const totalOvertimeHours = staffRows.reduce((acc, curr) => {
          return acc + curr.overtimeHours;
        }, 0);
        const totalSalary = staffRows.reduce((acc, curr) => {
          return acc + curr.salary;
        }, 0);
        const total: EmployeeSalaryTableTotal = {
          regularHours: roundNumberToTwoDecimals(
            sanitizeNumericField(totalRegularHours)
          ),
          doubleHours: roundNumberToTwoDecimals(
            sanitizeNumericField(totalDoubleHours)
          ),
          salary: roundNumberToTwoDecimals(sanitizeNumericField(totalSalary)),
          overtimeHours: roundNumberToTwoDecimals(
            sanitizeNumericField(totalOvertimeHours)
          ),
        };
        const employeeSalaryTable: EmployeeSalaryTable = {
          data: staffRows,
          total,
          staffFullName: staffMemberFullName,
          staffId,
        };
        return employeeSalaryTable;
      })
      .filter((x) => !!x) as EmployeeSalaryTable[];
    const weeklySalaryReport: WeeklyEmployeeSalaryReport = {
      id: _.uniqueId(),
      startDate,
      endDate,
      data: employeeSalaryTables,
    };
    return weeklySalaryReport;
  });

  const sortedData = _.orderBy(weeklySalaryReports, ["startDate"], ["asc"]);

  return sortedData;
};

const numerateDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = [];

  const currDate = moment(startDate).startOf("day");
  const lastDate = moment(endDate).startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};

export const generateTimeCardSummaryReports = async (
  businessId: string,
  startDate: Date,
  endDate: Date
) => {
  const {
    businessSettings,
    workEvents,
    workEventsWithSnapshotClockOuts,
    staffMembers,
  } = await fetchSharedData(businessId, startDate, endDate);
  console.log("staffMembers", staffMembers);
  const datesForReports = numerateDaysBetweenDates(startDate, endDate);
  return datesForReports.map((date) =>
    getTimeSummaryReportData(
      workEventsWithSnapshotClockOuts,
      date,
      businessSettings,
      staffMembers
    )
  );
};

export const generateWeeklyEmployeesSalaryReports = async (
  businessId: string,
  startDate: Date,
  endDate: Date
) => {
  const {
    businessSettings,
    staffMembers,
    workEventsWithSnapshotClockOutsGroupedByWeek,
  } = await fetchSharedData(businessId, startDate, endDate);
  return getWeeklyEmployeeSalaryReports(
    businessSettings,
    staffMembers,
    workEventsWithSnapshotClockOutsGroupedByWeek
  );
};

export interface IActivityReport {
  id: string;
  date: Date;
  data: ActivityReportSummary[];
}

export interface ActivityReportSummary {
  sales: number;
  meals: number;
  averageTable: number;
  tableCount: number;
  turn: number;
  serviceAreaName: string;
  date: Date;
}

export const getActivityReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<IActivityReport[]> => {
  try {
    const sharedData = await fetchSharedData(businessId, startDate, endDate);

    const data: IActivityReport[] = _.keys(sharedData.groupedSales).reduce(
      (report: IActivityReport[], date: string) => {
        const salesData: FlattenTab[] = sharedData.groupedSales[date].reduce(
          (tabs: FlattenTab[], transaction: FlattenTab) => {
            // Group by tabId
            const index = tabs.findIndex(
              (tab) => tab.tabId === transaction.tabId
            );
            if (index !== -1) {
              tabs[index].netAmount += transaction.netAmount;
              tabs[index].refundedAmount += transaction.refundedAmount;
              tabs[index].applicationFeeAmount +=
                transaction.applicationFeeAmount;
              tabs[index].tip += tabs[index].tip;
              // @ts-ignore
              tabs[index].hasMealPurchased += tabs[index].hasMealPurchased;

              return tabs;
            }

            return [...tabs, transaction];
          },
          [] as FlattenTab[]
        );

        const result: ActivityReportSummary[] = salesData.reduce(
          (acc: ActivityReportSummary[], item: FlattenTab) => {
            const index = acc.findIndex(
              (report) =>
                report.serviceAreaName.toLowerCase() ===
                item.serviceAreaName.toLowerCase()
            );
            // Existing service area
            if (index !== -1) {
              acc[index].sales += item.netAmount - item.refundedAmount;
              acc[index].tableCount += 1;
              if (item.hasMealPurchased) {
                acc[index].meals += 1;
              }
              return acc;
            }
            // New service area
            return [
              ...acc,
              {
                sales: item.netAmount - item.refundedAmount,
                meals:
                  typeof item.hasMealPurchased === "boolean"
                    ? item.hasMealPurchased
                      ? 1
                      : 0
                    : item.hasMealPurchased,
                averageTable: 0,
                tableCount: 1,
                turn: 0,
                serviceAreaName: item.serviceAreaName,
                date: item.createdAt,
              },
            ];
          },
          []
        );

        return [
          ...report,
          {
            id: String(date),
            date: date,
            data: result.reduce(
              (acc, val) => [
                ...acc,
                { ...val, averageTable: val.sales / val.tableCount },
              ],
              [] as ActivityReportSummary[]
            ),
          },
        ] as IActivityReport[];
      },
      [] as IActivityReport[]
    );

    return data;
  } catch (err) {
    return [];
  }
};

export interface IProductMixReport {
  id: string;
  date: Date;
  data: IProductMix[];
  endDate?: Date;
}

export interface IDailySalesReport {
  id: string;
  date: Date;
  data: IDailySales[];
  // data: {
  //   [T: string]: MainReadReport;
  // };
}

interface DailySalesReportSchemaTotal {
  [T: string]: number;
}

export interface DailySalesReportSchema {
  name: string;
  total: {
    [T: string]: number;
  };
  totalPercent?: {
    [T: string]: number;
  };
}

export interface IDailySales {
  businessId: string;
  salesByProductType: DailySalesReportSchema[];
  salesByProductTypeTotal: DailySalesReportSchema[];
  totalMeals: DailySalesReportSchema[];
  taxes: DailySalesReportSchema[];
  taxesTotal: DailySalesReportSchema[];
  feesAndCharges: DailySalesReportSchema[];
  salesTotals: DailySalesReportSchema[];
  payments: DailySalesReportSchema[];
  cashPayments: DailySalesReportSchema[];
  paymentsSubTotal: DailySalesReportSchema[];
  giftCards: DailySalesReportSchema[];
  giftCardsByCash: DailySalesReportSchema[];
  giftCardsByCard: DailySalesReportSchema[];
  giftCardsTotal: DailySalesReportSchema[];
  paymentsWithGiftCardsSubTotal: DailySalesReportSchema[];
  payoutsTotal: DailySalesReportSchema[];
  paymentsWithGiftCardsAndPayoutsSubTotal: DailySalesReportSchema[];
  // paymentsTotal: DailySalesReportSchema[];
  discounts: DailySalesReportSchema[];
  discountsTotal: DailySalesReportSchema[];
  finalTotal: DailySalesReportSchema[];
  netDeposit: DailySalesReportSchema[];
}

interface IProductMixProduct {
  productName: string;
  menuCategory: string;
  quantity: number;
  sales: number;
  cost: number;
  profit: number;
  type: "product" | "modifier";
  plu: string;
  serverName?: string;
  tabIds: string;
}

export interface IProductMix {
  category: string;
  products: IProductMixProduct[];
}

export interface MainReadReport {
  salesByProductType: {
    type: string;
    quantity: number;
    price: number;
    total: number;
  }[];
  breakdownByOrderType: {
    server: number;
    takeOutAndDelivery: number;
    bartender: number;
  };
  sales: number;
  discounts: {
    name: string;
    quantity: number;
    total: number;
  }[];
  highLevelDiscounts: {
    name: string;
    quantity: number;
    total: number;
  }[];
  netSales: number;
  hstLiquor: number;
  hstFoodProvincial: number;
  hstFoodRegional: number;
  totalTax: number;
  salesRefundedAmount: number;
  salesTotal: number;
  foodTotal: number;
  drinksTotal: number;

  // Breakdown
  salesByCardType: {
    name: string;
    total: number;
  }[];
  giftCardSales: number;
  onlineOrderSales: number;
  salesByThirdParty: {
    name: string;
    total: number;
  }[];
  payouts: number;
  giftCardPurchase: number;
  giftCardPurchaseByCash: number;
  giftCardPurchaseByCard: number;
  cash: number;
  subTotal: number;
  tipsDue: number;
  total: number;
  netDeposit: number;
  tipOut: number;
  totalOwed: number;
  totalMeals: number;
  staffId: string;
  // date: Date;

  // Staff related
  checkSummary: {
    tabId: string;
    check: string;
    tableNumber: string;
    method: string;
    tip: number;
    total: number;
  }[];
  voidSummary: {
    name: string;
    quantity: number;
    price: number;
  }[];
  salesByMenuCategory: {
    type: string;
    quantity: number;
    price: number;
    total: number;
  }[];
}

export const getDailySalesReportForADateRange = async (
  businessIds: string[],
  start: Date,
  endDate: Date,
  productTypes: { [T: string]: string },
  includeCashToSubTotal = false
): Promise<IDailySalesReport[]> => {
  // Start from the beginning of the week
  const startDate = moment(start).day(1);

  const allDates = Array.from(
    { length: moment(endDate).diff(moment(startDate), "days") + 1 },
    (_, index) => index
  );
  console.log("All dates: ", allDates);

  const result: IDailySalesReport[] = allDates.reduce((summary, date) => {
    const dateKey = moment(startDate).add(date, "days").startOf("day");
    // If the current day is Sunday .day(1) will return the Monday of that week as opposed to the previous week
    const startDateRange =
      dateKey.day() === 0
        ? dateKey.clone().subtract(2, "days").startOf("day").day(1)
        : dateKey.clone().day(1).startOf("day");
    const index = summary.findIndex((item) =>
      moment(item.date).isSame(startDateRange)
    );

    const emptySummary = [...businessIds, "Consolidated"].map((businessId) => {
      const emptySummary = {
        businessId: businessId,
        salesByProductType: [],
        totalMeals: [
          {
            name: "TOTAL MEALS",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        salesByProductTypeTotal: [
          {
            name: "TOTAL SALES",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
          // {
          //   name: "TOTAL MEALS",
          //   total: {
          //     1: 0,
          //     2: 0,
          //     3: 0,
          //     4: 0,
          //     5: 0,
          //     6: 0,
          //     7: 0,
          //     8: 0,
          //   },
          // },
        ],
        taxes: [
          {
            name: "HST - 8%",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
          {
            name: "HST - 5%",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
          {
            name: "HST - 13% Liquor",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        taxesTotal: [
          {
            name: "TOTAL TAXES",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        feesAndCharges: [],
        salesTotals: [
          {
            name: "POS TOTAL SALES",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        payments: [],
        cashPayments: [
          {
            name: "Cash",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        paymentsSubTotal: [
          {
            name: "SUBTOTAL",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        giftCards: [
          {
            name: "Gift certificates",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        giftCardsByCash: [
          {
            name: "Gift certificates - Cash",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        giftCardsByCard: [
          {
            name: "Gift certificates - Card",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        giftCardsTotal: [
          {
            name: "SUBTOTAL",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        paymentsWithGiftCardsSubTotal: [
          {
            name: "SUBTOTAL",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        payoutsTotal: [
          {
            name: "SUBTOTAL",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        paymentsWithGiftCardsAndPayoutsSubTotal: [
          {
            name: "Total payments",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        discounts: [],
        discountsTotal: [
          {
            name: "TOTAL DISCOUNT",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        finalTotal: [
          {
            name: "TOTAL",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
        netDeposit: [
          {
            name: "NET DEPOSIT",
            total: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
            },
          },
        ],
      };
      return emptySummary;
    });

    // Existing date range
    if (index !== -1) {
      // Check existing business id
      summary[index].data = emptySummary;
    } else {
      summary.push({
        id: String(summary.length + 1),
        date: startDateRange.toDate(),
        data: emptySummary,
      });
    }

    return summary;
  }, [] as IDailySalesReport[]);

  // // TODO: Remove this - it's just for testing
  // allDates = [allDates[0], allDates[1], allDates[2], allDates[3], allDates[4], allDates[5]];
  console.log("allDates", allDates);
  for await (const [i, date] of allDates.entries()) {
    for await (const businessId of businessIds) {
      const start = moment(startDate)
        .add(date, "days")
        .startOf("day")
        .add(4, "hours");
      const end = moment(start).add(1, "day");
      const readReceipt = await fetchReadReceiptData(
        businessId,
        start.toDate(),
        end.toDate()
      );
      console.log("Looking for: ", {
        businessId,
        startDate: start.toDate(),
        endDaTe: end.toDate(),
      });
      if (readReceipt && readReceipt.response) {
        const dateKey = moment(startDate).add(date, "days").startOf("day");
        const startDateRange =
          dateKey.day() === 0
            ? dateKey.clone().subtract(2, "days").startOf("day").day(1)
            : dateKey.clone().day(1).startOf("day");
        const index = result.findIndex((item) =>
          moment(item.date).isSame(startDateRange)
        );
        if (index !== -1) {
          const response = readReceipt.response;

          console.log("MAIN READ: ", response);

          // Get the day we need to update
          const day = Math.abs(dateKey.diff(startDateRange, "days")) + 1;

          const businessIndex = result[index].data.findIndex(
            (i) => i.businessId === businessId
          );

          if (businessIndex !== -1) {
            // Sales by product type
            let salesTotalBeforeTax = 0;
            console.log("response", response);
            for (const item of response.salesByProductType) {
              const typeIndex = result[index].data[
                businessIndex
              ].salesByProductType.findIndex(
                (value) => value.name === item.type
              );
              salesTotalBeforeTax += item.total;

              if (typeIndex !== -1) {
                result[index].data[businessIndex].salesByProductType[
                  typeIndex
                ].total[day] += item.total;
                result[index].data[businessIndex].salesByProductType[
                  typeIndex
                ].total[8] += item.total;
              } else {
                const newProductType: DailySalesReportSchema = {
                  name: item.type,
                  total: {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                  },
                };
                newProductType.total[day] += item.total;
                newProductType.total[8] += item.total;
                result[index].data[businessIndex].salesByProductType.push(
                  newProductType
                );
              }
            }

            // Percent total by item type
            for (const [i, productType] of result[index].data[
              businessIndex
            ].salesByProductType.entries()) {
              for (const [key, total] of Object.entries(productType.total)) {
                const totalPercent =
                  result[index].data[businessIndex].salesByProductType[i]
                    .totalPercent;
                const salesTotal = _.sum(
                  result[index].data[businessIndex].salesByProductType.map(
                    (productType) => productType.total[key]
                  )
                );
                if (totalPercent) {
                  totalPercent[key] = salesTotal
                    ? (total / salesTotal) * 100
                    : 0;
                } else {
                  const newPercent: DailySalesReportSchemaTotal = {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                  };
                  newPercent[key] = salesTotal ? (total / salesTotal) * 100 : 0;
                  result[index].data[businessIndex].salesByProductType[
                    i
                  ].totalPercent = newPercent;
                }
              }
            }

            // Total sales
            result[index].data[businessIndex].salesByProductTypeTotal[0].total[
              day
            ] += salesTotalBeforeTax;
            result[index].data[businessIndex].totalMeals[0].total[day] =
              response?.totalMeals;

            result[index].data[businessIndex].totalMeals[0].total[8] +=
              response?.totalMeals;
            result[index].data[
              businessIndex
            ].salesByProductTypeTotal[0].total[8] += salesTotalBeforeTax;
            // @ts-ignore
            console.log("response.totalMeals", response?.totalMeals);

            //HERE
            console.log(
              "result[index].data[businessIndex]",
              result[index].data[businessIndex]
            );
            let taxTotal = 0;
            const liquorTaxIndex = result[index].data[
              businessIndex
            ].taxes.findIndex((item) => item.name === "HST - 13% Liquor");
            result[index].data[businessIndex].taxes[liquorTaxIndex].total[
              day
            ] += response.hstLiquor;
            result[index].data[businessIndex].taxes[liquorTaxIndex].total[8] +=
              response.hstLiquor;
            taxTotal += response.hstLiquor;

            const hstProvTaxIndex = result[index].data[
              businessIndex
            ].taxes.findIndex((item) => item.name === "HST - 8%");
            result[index].data[businessIndex].taxes[hstProvTaxIndex].total[
              day
            ] += response.hstFoodProvincial;
            result[index].data[businessIndex].taxes[hstProvTaxIndex].total[8] +=
              response.hstFoodProvincial;
            taxTotal += response.hstFoodProvincial;

            const hstRegTaxIndex = result[index].data[
              businessIndex
            ].taxes.findIndex((item) => item.name === "HST - 5%");
            result[index].data[businessIndex].taxes[hstRegTaxIndex].total[
              day
            ] += response.hstFoodRegional;
            result[index].data[businessIndex].taxes[hstRegTaxIndex].total[8] +=
              response.hstFoodRegional;
            taxTotal += response.hstFoodRegional;
            // if (day === 1) {
            //   console.log('Response: ', response);
            // }

            // console.log("Response: ", response);

            // Total taxes
            result[index].data[businessIndex].taxesTotal[0].total[day] =
              taxTotal;
            result[index].data[businessIndex].taxesTotal[0].total[8] +=
              taxTotal;

            // Fees and Charges
            const newFeesAndCharges: DailySalesReportSchema[] = [];

            // TODO: Add delivery fee to the mainRead receipts response
            const feesTotal = 0;
            // const deliveryIndex = newFeesAndCharges.findIndex((item) => item.name === 'Delivery Fee');
            // newFeesAndCharges[deliveryIndex].total[day] += 0;
            // newFeesAndCharges[deliveryIndex].total[8] += 0;
            // feesTotal += 0;

            result[index].data[businessIndex].feesAndCharges =
              newFeesAndCharges;

            // POS Total fees and charges
            result[index].data[businessIndex].salesTotals[0].total[day] =
              salesTotalBeforeTax + taxTotal;
            result[index].data[businessIndex].salesTotals[0].total[8] +=
              salesTotalBeforeTax + taxTotal;

            // Card payments
            let paymentsSubTotal = 0;
            for (const card of response.salesByCardType) {
              const cardIndex = result[index].data[
                businessIndex
              ].payments.findIndex((item) => item.name === card.name);
              paymentsSubTotal += card.total;
              if (cardIndex !== -1) {
                result[index].data[businessIndex].payments[cardIndex].total[
                  day
                ] += card.total;
                result[index].data[businessIndex].payments[
                  cardIndex
                ].total[8] += card.total;
              } else {
                const cardTotal: DailySalesReportSchema = {
                  name: card.name,
                  total: {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                  },
                };
                cardTotal.total[day] += card.total;
                cardTotal.total[8] += card.total;
                result[index].data[businessIndex].payments.push(cardTotal);
              }
            }

            // Cash payments
            result[index].data[businessIndex].cashPayments[0].total[day] +=
              response.cash;
            if (includeCashToSubTotal) {
              paymentsSubTotal += response.cash;
            }

            // Online orders payment
            const onlineIndex = result[index].data[
              businessIndex
            ].payments.findIndex((item) => item.name === "Online orders");
            paymentsSubTotal += response.onlineOrderSales;
            if (onlineIndex !== -1) {
              result[index].data[businessIndex].payments[onlineIndex].total[
                day
              ] += response.onlineOrderSales;
              result[index].data[businessIndex].payments[
                onlineIndex
              ].total[8] += response.onlineOrderSales;
            } else {
              const onlineTotal: DailySalesReportSchema = {
                name: "Online orders",
                total: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                },
              };
              onlineTotal.total[day] += response.onlineOrderSales;
              onlineTotal.total[8] += response.onlineOrderSales;
              result[index].data[businessIndex].payments.push(onlineTotal);
            }

            // // Cash payment
            // const cashIndex = result[index].data.payments.findIndex((item) => item.name === 'Cash');
            // paymentsSubTotal += response.cash;
            // if (cashIndex !== -1) {
            //   result[index].data.payments[cashIndex].total[day] += response.cash;
            //   result[index].data.payments[cashIndex].total[8] += response.cash;
            // } else {
            //   const cashTotal: DailySalesReportSchema = {
            //     name: 'Cash',
            //     total: {
            //       1: 0,
            //       2: 0,
            //       3: 0,
            //       4: 0,
            //       5: 0,
            //       6: 0,
            //       7: 0,
            //       8: 0,
            //     },
            //   };
            //   cashTotal.total[day] += response.cash;
            //   cashTotal.total[8] += response.cash;
            //   result[index].data.payments.push(cashTotal);
            // }

            // // Tips
            // const tipIndex = result[index].data.payments.findIndex((item) => item.name === 'Tips');
            // // We deduct the tip amount from the payments that users have paid
            // paymentsSubTotal -= response.tipsDue;
            // if (cashIndex !== -1) {
            //   result[index].data.payments[tipIndex].total[day] += response.tipsDue;
            //   result[index].data.payments[tipIndex].total[8] += response.tipsDue;
            // } else {
            //   const tipsTotal: DailySalesReportSchema = {
            //     name: 'Tips',
            //     total: {
            //       1: 0,
            //       2: 0,
            //       3: 0,
            //       4: 0,
            //       5: 0,
            //       6: 0,
            //       7: 0,
            //       8: 0,
            //     },
            //   };
            //   tipsTotal.total[day] += response.tipsDue;
            //   tipsTotal.total[8] += response.tipsDue;
            //   result[index].data.payments.push(tipsTotal);
            // }

            // Gift card purchase payment
            const gcIndex = result[index].data[
              businessIndex
            ].payments.findIndex((item) => item.name === "Gift Card");
            // We deduct the tip amount from the payments that users have paid
            paymentsSubTotal += response.giftCardSales; // response.giftCardPurchase;
            if (gcIndex !== -1) {
              result[index].data[businessIndex].payments[gcIndex].total[day] +=
                response.giftCardSales; // response.giftCardPurchase;
              result[index].data[businessIndex].payments[gcIndex].total[8] +=
                response.giftCardSales; // response.giftCardPurchase;
            } else {
              const gcTotal: DailySalesReportSchema = {
                name: "Gift Card",
                total: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                },
              };
              gcTotal.total[day] += response.giftCardSales; // response.giftCardPurchase;
              gcTotal.total[8] += response.giftCardSales; // response.giftCardPurchase;
              result[index].data[businessIndex].payments.push(gcTotal);
            }

            // Deliverect
            for (const thirdParty of response.salesByThirdParty) {
              const tpIndex = result[index].data[
                businessIndex
              ].payments.findIndex((item) => item.name === thirdParty.name);
              paymentsSubTotal += thirdParty.total;
              if (tpIndex !== -1) {
                result[index].data[businessIndex].payments[tpIndex].total[
                  day
                ] += thirdParty.total;
                result[index].data[businessIndex].payments[tpIndex].total[8] +=
                  thirdParty.total;
              } else {
                const tpTotal: DailySalesReportSchema = {
                  name: thirdParty.name,
                  total: {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                  },
                };
                tpTotal.total[day] += thirdParty.total;
                tpTotal.total[8] += thirdParty.total;
                result[index].data[businessIndex].payments.push(tpTotal);
              }
            }

            // Payments subtotal
            result[index].data[businessIndex].paymentsSubTotal[0].total[day] +=
              paymentsSubTotal;
            result[index].data[businessIndex].paymentsSubTotal[0].total[8] +=
              paymentsSubTotal;

            // Paid In & Gift Certificate
            // const gcPurchaseIndex = result[index].data.giftCards.findIndex((item) => item.name === 'Gift card purchases');
            const gcCashSaleIndex = result[index].data[
              businessIndex
            ].giftCardsByCash.findIndex(
              (item) => item.name === "Gift certificates - Cash"
            );

            // // Gift card purchases combined
            // result[index].data[businessIndex].giftCards[gcSaleIndex].total[
            //   day
            // ] += response.giftCardPurchase;
            // result[index].data[businessIndex].giftCards[gcSaleIndex].total[8] +=
            //   response.giftCardPurchase;

            // Gift card purchases by cash
            result[index].data[businessIndex].giftCardsByCash[
              gcCashSaleIndex
            ].total[day] += response.giftCardPurchaseByCash;
            result[index].data[businessIndex].giftCardsByCash[
              gcCashSaleIndex
            ].total[8] += response.giftCardPurchaseByCash;

            const gcCardSaleIndex = result[index].data[
              businessIndex
            ].giftCardsByCard.findIndex(
              (item) => item.name === "Gift certificates - Card"
            );

            // Gift card purchases by card
            result[index].data[businessIndex].giftCardsByCard[
              gcCardSaleIndex
            ].total[day] += response.giftCardPurchaseByCard;
            result[index].data[businessIndex].giftCardsByCard[
              gcCardSaleIndex
            ].total[8] += response.giftCardPurchaseByCard;

            // Gift card total
            // TODO: Verify this true for gift card total
            result[index].data[businessIndex].giftCardsTotal[0].total[day] +=
              response.giftCardPurchase; // response.giftCardSales;
            result[index].data[businessIndex].giftCardsTotal[0].total[8] +=
              response.giftCardPurchase; // response.giftCardSales;

            // Paid out total
            result[index].data[businessIndex].payoutsTotal[0].total[day] +=
              response.payouts;
            result[index].data[businessIndex].payoutsTotal[0].total[8] +=
              response.payouts;

            // Total payments
            const payoutTotal =
              result[index].data[businessIndex].payoutsTotal[0].total[day];
            const giftCardsByCardTotal =
              result[index].data[businessIndex].giftCardsByCard[0].total[day];
            const giftCardsByCashTotal =
              result[index].data[businessIndex].giftCardsByCash[0].total[day];
            const paymentsTotal =
              result[index].data[businessIndex].paymentsSubTotal[0].total[day];

            result[index].data[
              businessIndex
            ].paymentsWithGiftCardsAndPayoutsSubTotal[0].total[day] =
              paymentsTotal + payoutTotal;

            // Discounts
            let discountTotal = 0;
            for (const discount of response.discounts) {
              const discountIndex = result[index].data[
                businessIndex
              ].discounts.findIndex((item) => item.name === discount.name);
              discountTotal += discount.total;
              if (discountIndex !== -1) {
                result[index].data[businessIndex].discounts[
                  discountIndex
                ].total[day] += discount.total;
                result[index].data[businessIndex].discounts[
                  discountIndex
                ].total[8] += discount.total;
              } else {
                const discTotal: DailySalesReportSchema = {
                  name: discount.name,
                  total: {
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                  },
                };
                discTotal.total[day] += discount.total;
                discTotal.total[8] += discount.total;
                result[index].data[businessIndex].discounts.push(discTotal);
              }
            }

            // Discount total
            result[index].data[businessIndex].discountsTotal[0].total[day] +=
              discountTotal;
            result[index].data[businessIndex].discountsTotal[0].total[8] +=
              discountTotal;

            // Total receipts
            const totalPayments =
              result[index].data[businessIndex]
                .paymentsWithGiftCardsAndPayoutsSubTotal[0].total[day];
            const totalDiscount =
              result[index].data[businessIndex].discountsTotal[0].total[day];
            result[index].data[businessIndex].finalTotal[0].total[day] +=
              totalPayments + totalDiscount;
            result[index].data[businessIndex].finalTotal[0].total[8] +=
              totalPayments + totalDiscount;

            // Net deposit
            const finalTotal =
              result[index].data[businessIndex].finalTotal[0].total[day];
            const salesTotal =
              result[index].data[businessIndex].salesTotals[0].total[day];
            const netDeposit = -response.netDeposit;
            result[index].data[businessIndex].netDeposit[0].total[day] +=
              netDeposit;
            result[index].data[businessIndex].netDeposit[0].total[8] +=
              netDeposit;
          }
        }
      }
    }
  }

  // if (result.length > 1) {
  //   // Create the consolidated report
  //   result.map((report) => {
  //     report.data.reduce((summary, data) => {
  //       if (summary.salesByProductType.length === 0) {
  //         summary.salesByProductType = data.salesByProductType
  //       } else {
  //       }
  //     return summary
  //     }, zeroSummary)

  //     return report
  //   })
  // }

  console.log("Result: ", JSON.stringify(result));
  return result;
};

export const getWeeklyProductMixForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date,
  productTypes: { [T: string]: string }
) => {
  const { salesGroupedByWeek } = await fetchSharedData(
    businessId,
    startDate,
    endDate
  );
  console.log("Sales grouped by week: ", salesGroupedByWeek);
  const result: IProductMixReport[] = _.keys(salesGroupedByWeek).reduce(
    (acc: IProductMixReport[], date: string) => {
      const productMixResults: IProductMix[] = [];
      salesGroupedByWeek[date].flattenTabs.forEach((tab) => {
        tab.productsPaid.forEach((product) => {
          // Temporary hack
          if (!product.name) {
            // @ts-ignore
            product.name = product.productName || "Unknown";
          }

          const deliverectProductType = product.plu
            ? productTypes[product.plu]
            : null;
          product.type = deliverectProductType
            ? _.startCase(_.toLower(deliverectProductType))
            : _.startCase(_.toLower(product.type)) || "Other";

          const productsInfo: IProductMixProduct = {
            productName: product.name,
            quantity: product.quantity,
            sales: product.price * product.quantity,
            cost: 0,
            profit: product.price * product.quantity,
            type: "product",
            menuCategory: product.menuCategory,
            plu: product.plu || "",
            tabIds: tab.tabId,
          };
          const modifiersInfo = ([] as IProductMixProduct[]).concat
            .apply(
              [],
              (product?.selectedModifiers || []).map((modifier) => {
                return modifier.options.map((option) => {
                  const modifierInfo: IProductMixProduct = {
                    productName: option.name,
                    quantity: 1,
                    sales: option.additionalCost,
                    cost: 0,
                    profit: option.additionalCost,
                    type: "modifier",
                    menuCategory: "Modifiers",
                    plu: option.plu || "",
                    tabIds: tab.tabId,
                  };
                  return modifierInfo;
                });
              })
            )
            .filter(
              (modifier) => !!modifier
            ) as unknown as IProductMixProduct[];

          const categoryIndex = productMixResults.findIndex(
            (productMix) => productMix.category === product.type
          );
          if (categoryIndex !== -1) {
            // Add the new product to existing category
            let productIndex = productMixResults[
              categoryIndex
            ].products.findIndex(
              (product) => product.productName === productsInfo.productName
            );

            // When items in the menu are created such that they are modifiers but are ordered
            // as products - because of a special menu category we need to look out for whether
            // or not they are products or modifiers - they will share the PLU but they'll effectively
            // be two different products
            if (
              productIndex !== -1 &&
              productMixResults[categoryIndex].products[productIndex].type ===
                "modifier"
            ) {
              // Check if there's another index
              productIndex = productMixResults[
                categoryIndex
              ].products.findIndex(
                (product) =>
                  product.productName === productsInfo.productName &&
                  product.plu === productsInfo.plu &&
                  product.type !== "modifier"
              );
            }

            // Increment quantity
            if (productIndex !== -1) {
              productMixResults[categoryIndex].products[
                productIndex
              ].quantity += productsInfo.quantity;
              productMixResults[categoryIndex].products[productIndex].profit +=
                productsInfo.profit;
              productMixResults[categoryIndex].products[productIndex].sales +=
                productsInfo.sales;
              productMixResults[categoryIndex].products[productIndex].cost +=
                productsInfo.cost;
              productMixResults[categoryIndex].products[productIndex].tabIds +=
                "," + productsInfo.tabIds;
            } else {
              productMixResults[categoryIndex].products.push(productsInfo);
            }

            // Add the new modifiers to existing category
            modifiersInfo.forEach((modifier) => {
              const modifierIndex = productMixResults[
                categoryIndex
              ].products.findIndex(
                (product) => product.productName === modifier.productName
              );
              if (modifierIndex !== -1) {
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].quantity += modifier.quantity;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].profit += modifier.profit;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].sales += modifier.sales;
                productMixResults[categoryIndex].products[modifierIndex].cost +=
                  modifier.cost;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].tabIds += "," + modifier.tabIds;
              } else {
                productMixResults[categoryIndex].products.push(modifier);
              }
            });
          } else {
            // Add the product in a new category
            productMixResults.push({
              category: product.type,
              products: [productsInfo],
            });

            // Add the modifier in a new category

            modifiersInfo.forEach((modifier) => {
              // This is me being paranoid - I know the last element of the index
              // should correspond to the latest category, but better be safe than
              // sorry
              const categoryIndex = productMixResults.findIndex(
                (productMix) => productMix.category === product.type
              );
              if (categoryIndex !== -1) {
                const modifierIndex = productMixResults[
                  categoryIndex
                ].products.findIndex(
                  (product) => product.productName === modifier.productName
                );
                if (modifierIndex !== -1) {
                  console.log(
                    "Modifier: ",
                    productMixResults[categoryIndex].products[modifierIndex]
                  );
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].quantity += modifier.quantity;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].profit += modifier.profit;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].sales += modifier.sales;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].cost += modifier.cost;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].tabIds += "," + modifier.tabIds;
                } else {
                  console.log("New Modifier: ", modifier);
                  productMixResults[categoryIndex].products.push(modifier);
                }
              } else {
                productMixResults.push({
                  category: product.type,
                  products: [modifier],
                });
              }
            });
          }
        });
      });

      return [
        ...acc,
        {
          id: String(date),
          date: new Date(date),
          endDate: moment(new Date(date)).add(6, "days").toDate(),
          data: productMixResults,
        },
      ];
    },
    [] as IProductMixReport[]
  );

  return result;
};

export const getProductMixForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date,
  productTypes: { [T: string]: string },
  includeServer = false
) => {
  const sharedData = await fetchSharedData(businessId, startDate, endDate);
  const staff = await getStaffMembers(businessId);
  console.log("sharedData", sharedData);
  const result: IProductMixReport[] = _.keys(sharedData.groupedSales).reduce(
    (acc: IProductMixReport[], date: string) => {
      const productMixResults: IProductMix[] = [];
      sharedData.groupedSales[date].forEach((tab) => {
        const staffMember = staff.find((member) => member.id === tab.staffId);
        const serverName = staffMember
          ? staffMember.firstName + " " + staffMember.lastName
          : "";

        tab.productsPaid.forEach((product) => {
          // Temporary hack
          if (!product.name) {
            // @ts-ignore
            product.name = product.productName || "Unknown";
          }

          const deliverectProductType = product.plu
            ? productTypes[product.plu]
            : null;
          product.type = deliverectProductType
            ? _.startCase(_.toLower(deliverectProductType))
            : _.startCase(_.toLower(product.type)) || "Other";

          const productsInfo: IProductMixProduct = {
            productName: product.name,
            quantity: product.quantity,
            sales: product.price * product.quantity,
            cost: 0,
            profit: product.price * product.quantity,
            type: "product",
            menuCategory: product.menuCategory,
            plu: product.plu || "",
            serverName: staffMember
              ? staffMember.firstName + " " + staffMember.lastName
              : "",
            tabIds: tab.tabId,
          };
          const modifiersInfo = ([] as IProductMixProduct[]).concat
            .apply(
              [],
              (product?.selectedModifiers || []).map((modifier) => {
                return modifier.options.map((option) => {
                  const modifierInfo: IProductMixProduct = {
                    productName: option.name,
                    quantity: product.quantity,
                    sales: option.additionalCost * product.quantity,
                    cost: 0,
                    profit: option.additionalCost * product.quantity,
                    type: "modifier",
                    menuCategory: "Modifiers",
                    plu: option.plu || "",
                    serverName: staffMember
                      ? staffMember.firstName + " " + staffMember.lastName
                      : "",
                    tabIds: tab.tabId,
                  };
                  return modifierInfo;
                });
              })
            )
            .filter(
              (modifier) => !!modifier
            ) as unknown as IProductMixProduct[];

          const categoryIndex = productMixResults.findIndex(
            (productMix) => productMix.category === product.type
          );
          if (categoryIndex !== -1) {
            // Add the new product to existing category
            let productIndex = includeServer
              ? productMixResults[categoryIndex].products.findIndex(
                  (product) =>
                    product.productName === productsInfo.productName &&
                    product.serverName === serverName &&
                    product.plu === productsInfo.plu
                )
              : productMixResults[categoryIndex].products.findIndex(
                  (product) =>
                    product.productName === productsInfo.productName &&
                    product.plu === productsInfo.plu
                );

            // When items in the menu are created such that they are modifiers but are ordered
            // as products - because of a special menu category we need to look out for whether
            // or not they are products or modifiers - they will share the PLU but they'll effectively
            // be two different products
            if (
              productIndex !== -1 &&
              productMixResults[categoryIndex].products[productIndex].type ===
                "modifier"
            ) {
              // Check if there's another index
              productIndex = includeServer
                ? productMixResults[categoryIndex].products.findIndex(
                    (product) =>
                      product.productName === productsInfo.productName &&
                      product.serverName === serverName &&
                      product.plu === productsInfo.plu &&
                      product.type !== "modifier"
                  )
                : productMixResults[categoryIndex].products.findIndex(
                    (product) =>
                      product.productName === productsInfo.productName &&
                      product.plu === productsInfo.plu &&
                      product.type !== "modifier"
                  );
            }

            // Increment quantity
            if (productIndex !== -1) {
              productMixResults[categoryIndex].products[
                productIndex
              ].quantity += productsInfo.quantity;
              productMixResults[categoryIndex].products[productIndex].profit +=
                productsInfo.profit;
              productMixResults[categoryIndex].products[productIndex].sales +=
                productsInfo.sales;
              productMixResults[categoryIndex].products[productIndex].cost +=
                productsInfo.cost;
              productMixResults[categoryIndex].products[productIndex].tabIds +=
                "," + productsInfo.tabIds;
            } else {
              productMixResults[categoryIndex].products.push(productsInfo);
            }

            // Add the new modifiers to existing category
            modifiersInfo.forEach((modifier) => {
              const modifierIndex = includeServer
                ? productMixResults[categoryIndex].products.findIndex(
                    (product) =>
                      product.productName === modifier.productName &&
                      modifier.serverName === serverName &&
                      product.plu === modifier.plu
                  )
                : productMixResults[categoryIndex].products.findIndex(
                    (product) =>
                      product.productName === modifier.productName &&
                      product.plu === modifier.plu
                  );
              if (modifierIndex !== -1) {
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].quantity += modifier.quantity;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].profit += modifier.profit;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].sales += modifier.sales;
                productMixResults[categoryIndex].products[modifierIndex].cost +=
                  modifier.cost;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].tabIds += "," + modifier.tabIds;
              } else {
                productMixResults[categoryIndex].products.push(modifier);
              }
            });
          } else {
            // Add the product in a new category
            productMixResults.push({
              category: product.type,
              products: [productsInfo],
            });

            // Add the modifier in a new category

            modifiersInfo.forEach((modifier) => {
              // This is me being paranoid - I know the last element of the index
              // should correspond to the latest category, but better be safe than sorry
              const categoryIndex = productMixResults.findIndex(
                (productMix) => productMix.category === product.type
              );
              if (categoryIndex !== -1) {
                const modifierIndex = includeServer
                  ? productMixResults[categoryIndex].products.findIndex(
                      (product) =>
                        product.productName === modifier.productName &&
                        product.serverName === serverName
                    )
                  : productMixResults[categoryIndex].products.findIndex(
                      (product) => product.productName === modifier.productName
                    );
                if (modifierIndex !== -1) {
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].quantity += modifier.quantity;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].profit += modifier.profit;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].sales += modifier.sales;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].cost += modifier.cost;
                  productMixResults[categoryIndex].products[
                    modifierIndex
                  ].tabIds += "," + modifier.tabIds;
                } else {
                  productMixResults[categoryIndex].products.push(modifier);
                }
              } else {
                productMixResults.push({
                  category: product.type,
                  products: [modifier],
                });
              }
            });
          }
        });
      });

      return [
        ...acc,
        {
          id: String(date),
          date: new Date(date),
          data: productMixResults,
        },
      ];
    },
    [] as IProductMixReport[]
  );

  console.log("result", result);

  return result;
};

export type ShiftTypeRolesTableRow = {
  staffId: string;
  staffFullName: string;
  timeIn: string;
  timeOut: string;
  startTimeDate: Date;
  endTimeDate: Date;
  notes: string;
};

export type ShiftAMPMSplitTableRow = {
  staffId: string;
  staffFullName: string;
  departmentName: string;
  departmentId: string;
  roleId: string;
  roleName: string;
  timeIn: string;
  timeOut: string;
  startTimeDate: Date;
  endTimeDate: Date;
  notes: string;
};

export type ShiftTypeRolesTable = {
  departmentId: string;
  departmentName: string;
  totalNumberOfShifts: number;
  totalNumberOfHours: number;
  roleId: string;
  roleName: string;
  shifts: ShiftTypeRolesTableRow[];
};

export type ShiftTable = {
  shiftTypeId: string;
  shiftTitle: string;
  totalHours: number;
  shiftRolesTables: ShiftTypeRolesTable[];
};

export type DailyShiftsReport = {
  startDate: Date;
  endDate: Date;
  shiftTables: ShiftTable[];
};

export type DailyShiftsAMPMSplitReport = {
  am: ShiftAMPMSplitTableRow[];
  pm: ShiftAMPMSplitTableRow[];
  totalHours: number;
  date: Date;
};

export type WeeklyReportShift = {
  id: string;
  staffId: string;
  startDate: any;
  endDate: any;
  startTime: string;
  endTime: string;
  shiftColor: string;
};

export type StaffMemberWeeklySchedule = {
  weekDayString: string;
  shifts: WeeklyReportShift[];
};

export type WeeklyAlphabeticScheduleReport = {
  staffReports: WeeklyAlphabeticStaffScheduleReportPart[];
  dateStringsForSchedule: string[];
  weekDaysWithoutDayName: string[];
  totalShiftsDuration: number;
};

export type WeeklyAlphabeticStaffScheduleReportPart = {
  staffId: string;
  staffFullName: string;
  staffMemberWeeklySchedule: StaffMemberWeeklySchedule[];
};

export type WeeklyRoleGroupedScheduleReport = {
  dateStringsForSchedule: string[];
  weekDaysWithoutDayName: string[];
  totalShiftsDuration: number;
  roleReports: WeeklyRoleScheduleReport[];
};

export type WeeklyRoleScheduleReport = {
  departmentId: string;
  departmentTitle: string;
  roleId: string;
  roleTitle: string;
  staffReports: WeeklyAlphabeticStaffScheduleReportPart[];
  totalHours: number;
  totalShifts: number;
};

export const generateWeelyRoleGroupedReport = (
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  scheduleForReport: TangoSchedule,
  business: TangoBusiness
): WeeklyRoleGroupedScheduleReport => {
  const weekRange = generateWeekRangeForSelectedDate(
    business,
    scheduleForReport?.startDate?.toDate()
  );

  const dateStringsForSchedule = weekRange.map((d) =>
    moment(d).format("ddd, MMM Do")
  );
  const weekDaysWithoutDayName = weekRange.map((d) =>
    moment(d).format("MMM Do")
  );

  const shiftsWithinTheReport = scheduleForReport?.shifts?.filter(
    (sh) => sh.staffId
  );
  const shiftsGroupedByPosition = _.groupBy(shiftsWithinTheReport, "position");
  const weeklyRoleReports: WeeklyRoleScheduleReport[] = _.keys(
    shiftsGroupedByPosition
  )
    .map((positionId) => {
      const positionData = getPositionById(businessSettings, positionId);
      if (!positionData?.title) return null;
      const departmentId = positionData.departmentId;
      const department = DEPARTMENTS[departmentId as DepartmentId];
      if (!department) return null;
      const shiftsForPosition = shiftsGroupedByPosition[positionId];
      const shiftsGroupedByStaff = _.groupBy(shiftsForPosition, "staffId");

      const staffReports = _.keys(shiftsGroupedByStaff)
        .map((staffId) => {
          const shiftsForStaff: WeeklyReportShift[] = shiftsGroupedByStaff[
            staffId
          ]
            ?.map((shift) => {
              if (shift.staffId) {
                return {
                  id: shift.id,
                  staffId: shift.staffId,
                  startDate: shift.startDate,
                  endDate: shift.endDate,
                  startTime: moment(shift.startTime, "HH:mm").format("hh:mm a"),
                  endTime: moment(shift.endTime, "HH:mm").format("hh:mm a"),
                  shiftColor:
                    businessSettings?.shiftTypes?.find(
                      (st) => st.id === shift?.shiftTypeId
                    )?.mainColor || "#dbdbdb",
                };
              }
              return null;
            })
            .filter((x) => !!x) as WeeklyReportShift[];
          const shiftsGroupedByWeekDayString = _.groupBy(
            shiftsForStaff,
            (shift) =>
              convertToBusinessTimezoneMoment(
                shift?.startDate?.toDate(),
                business
              ).format("ddd, MMM Do")
          );
          const staffMemberWeeklySchedule: StaffMemberWeeklySchedule[] =
            dateStringsForSchedule.map((weekDayString) => {
              const shifts = shiftsGroupedByWeekDayString[weekDayString] || [];
              return {
                weekDayString,
                shifts,
              };
            });
          const staffMember = staffMembers.find((sm) => sm?.uid === staffId);
          if (!staffMember) return null;
          return {
            staffId,
            staffFullName: `${staffMember.contact.firstName} ${staffMember.contact.lastName}`,
            staffMemberWeeklySchedule,
          };
        })
        .filter((x) => !!x) as WeeklyAlphabeticStaffScheduleReportPart[];
      const totalShiftsDuration = shiftsForPosition.reduce(
        (accum, curr) => accum + extractShiftDurationInHours(curr),
        0
      );

      return {
        staffReports,
        departmentId,
        roleId: positionId,
        roleTitle: positionData.title,
        departmentTitle: department.title,
        totalHours: totalShiftsDuration,
        totalShifts: shiftsForPosition.length,
      };
    })
    .filter((x) => !!x) as WeeklyRoleScheduleReport[];
  console.log("weeklyRoleReports", weeklyRoleReports);
  const totalShiftsDuration = weeklyRoleReports.reduce(
    (acc, curr) => acc + curr.totalHours,
    0
  );
  return {
    dateStringsForSchedule,
    weekDaysWithoutDayName,
    totalShiftsDuration,
    roleReports: weeklyRoleReports,
  };
};

export type StaffAvailabilityStaffMemberReport = {
  staffId: string;
  staffFullName: string;
  staffAvailabilities: (FixedAvailabilitySchedule | null)[];
};

export type AvailabilityReport = {
  staffReports: StaffAvailabilityStaffMemberReport[];
  weekDayStrings: string[];
};

export const generateStaffAvailabilityReportReport = (
  staffMembers: StaffMember[],
  fixedAvailabilities: FixedAvailability[]
): AvailabilityReport => {
  const weekDayStrings = moment.weekdays();
  const staffReports = staffMembers.map((sm) => {
    const staffId = sm.id;
    const staffFullName = `${sm.contact.firstName} ${sm.contact.lastName}`;
    const staffFixedAvailability = fixedAvailabilities.find(
      (a) => a.staffId === staffId
    );
    const availabilitySchedules = weekDayStrings.map((weekDayName) => {
      const availabilityForADay = (
        staffFixedAvailability?.schedule ?? []
      )?.find((a) => a.day === weekDayName);
      return availabilityForADay ?? null;
    });
    return {
      staffFullName,
      staffId,
      staffAvailabilities: availabilitySchedules,
    };
  });
  return {
    staffReports,
    weekDayStrings,
  };
};

export const generateWeeklyAlphabeticScheduleReport = (
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  scheduleForReport: TangoSchedule,
  business: TangoBusiness
): WeeklyAlphabeticScheduleReport => {
  const weekRange = generateWeekRangeForSelectedDate(
    business,
    scheduleForReport?.startDate?.toDate()
  );

  const dateStringsForSchedule = weekRange.map((d) =>
    moment(d).format("ddd, MMM Do")
  );
  const weekDaysWithoutDayName = weekRange.map((d) =>
    moment(d).format("MMM Do")
  );

  const shiftsWithinTheReport = scheduleForReport?.shifts?.filter(
    (sh) => sh.staffId
  );
  const shiftsGroupedByStaff = _.groupBy(shiftsWithinTheReport, "staffId");
  const staffReports = _.keys(shiftsGroupedByStaff)
    .map((staffId) => {
      const shiftsForStaff: WeeklyReportShift[] = shiftsGroupedByStaff[staffId]
        ?.map((shift) => {
          if (shift.staffId) {
            return {
              id: shift.id,
              staffId: shift.staffId,
              startDate: shift.startDate,
              endDate: shift.endDate,
              startTime: moment(shift.startTime, "HH:mm").format("hh:mm a"),
              endTime: moment(shift.endTime, "HH:mm").format("hh:mm a"),
              shiftColor:
                businessSettings?.shiftTypes?.find(
                  (st) => st.id === shift?.shiftTypeId
                )?.mainColor || "#dbdbdb",
            };
          }
          return null;
        })
        .filter((x) => !!x) as WeeklyReportShift[];
      const shiftsGroupedByWeekDayString = _.groupBy(shiftsForStaff, (shift) =>
        convertToBusinessTimezoneMoment(
          shift?.startDate?.toDate(),
          business
        ).format("ddd, MMM Do")
      );
      const staffMemberWeeklySchedule: StaffMemberWeeklySchedule[] =
        dateStringsForSchedule.map((weekDayString) => {
          const shifts = shiftsGroupedByWeekDayString[weekDayString] || [];
          return {
            weekDayString,
            shifts,
          };
        });
      const staffMember = staffMembers.find((sm) => sm?.uid === staffId);
      if (!staffMember) return null;
      return {
        staffId,
        staffFullName: `${staffMember.contact.firstName} ${staffMember.contact.lastName}`,
        staffMemberWeeklySchedule,
      };
    })
    .filter((x) => !!x) as WeeklyAlphabeticStaffScheduleReportPart[];
  const totalShiftsDuration = shiftsWithinTheReport.reduce(
    (accum, curr) => accum + extractShiftDurationInHours(curr),
    0
  );
  return {
    staffReports: _.orderBy(
      staffReports,
      [(sr) => sr.staffFullName.toLowerCase()],
      ["asc"]
    ),
    dateStringsForSchedule,
    weekDaysWithoutDayName,
    totalShiftsDuration,
  };
};

export const generateDailyScheduleReportDataWithAMPMSplit = (
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  dateForReport: Date,
  schedules: TangoSchedule[],
  business: TangoBusiness
): DailyShiftsAMPMSplitReport => {
  const startDateForReport = convertToBusinessTimezoneMoment(
    dateForReport,
    business
  )
    .startOf("day")
    .add(4, "hours")
    .toDate();
  const endDateForRerport = moment(startDateForReport).add(1, "day").toDate();
  let scheduleForReport = undefined;
  const schedulesWithDepartmentId = schedules?.filter((schedule) => {
    if (!dateForReport) return null;
    return (
      schedule.departmentId &&
      convertToBusinessTimezoneMoment(dateForReport, business).isBetween(
        moment(schedule.startDate.toMillis()).startOf("day"),
        moment(schedule.endDate.toMillis()).endOf("day"),
        null,
        "[]"
      )
    );
  });

  if (schedulesWithDepartmentId.length) {
    let mergedShifts: TangoShift[] = [];
    schedulesWithDepartmentId.forEach((sch) => {
      if (sch.shifts?.length) {
        mergedShifts = [
          ...mergedShifts,
          ...sch.shifts.map((shift) => ({ ...shift, draftScheduleId: sch.id })),
        ];
      }
    });
    const mergedSchedule: TangoSchedule = {
      ...schedulesWithDepartmentId[0],
      departmentId: schedulesWithDepartmentId[0].departmentId || "foh",
      shifts: mergedShifts,
      mergedSchedules: schedulesWithDepartmentId.map((s) => s.id),
    };

    scheduleForReport = mergedSchedule;
  } else {
    scheduleForReport = schedules?.find((schedule) => {
      if (!dateForReport) return null;
      return convertToBusinessTimezoneMoment(dateForReport, business).isBetween(
        moment(schedule.startDate.toMillis()).startOf("day"),
        moment(schedule.endDate.toMillis()).endOf("day"),
        null,
        "[]"
      );
    });
  }

  if (!scheduleForReport) {
    return {
      am: [],
      pm: [],
      date: dateForReport,
      totalHours: 0,
    };
  }

  const shiftsWithinTheReport = scheduleForReport?.shifts?.filter((shift) => {
    const shiftStartIsAfterReportStart = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSameOrAfter(moment(startDateForReport));
    const isSameDateAsStartDate = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSame(moment(startDateForReport), "date");
    const shiftStartIsBeforeReportEnd = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSameOrBefore(moment(endDateForRerport));
    return shiftStartIsAfterReportStart && shiftStartIsBeforeReportEnd;
  });

  if (!shiftsWithinTheReport.length) {
    return {
      date: startDateForReport,
      totalHours: 0,
      am: [],
      pm: [],
    };
  }

  const shiftsGroupedByAMPMSplit = _.groupBy(shiftsWithinTheReport, (shift) => {
    const shiftStartMoment = moment(shift.startDate.toDate());
    const shiftEndMoment = moment(shift.endDate.toDate());
    const amPmSplitTimeMoment = moment(businessSettings.amPmSplitTime, "HH:mm");
    const amPmSplitBasedOnShiftStartMoment = shiftStartMoment.clone().set({
      hours: amPmSplitTimeMoment.hours(),
      minutes: amPmSplitTimeMoment.minutes(),
    });
    const shiftDuration = extractShiftDurationInHours(shift);

    if (amPmSplitBasedOnShiftStartMoment.isAfter(shiftStartMoment)) {
      const splitVsShiftStartDifferenceInHours = roundNumberToTwoDecimals(
        sanitizeNumericField(
          Math.abs(
            moment
              .duration(shiftStartMoment.diff(amPmSplitBasedOnShiftStartMoment))
              .asHours()
          )
        )
      );
      if (splitVsShiftStartDifferenceInHours <= shiftDuration / 2) {
        return "pm";
      }
      return "am";
    }
    return "pm";
  });

  console.log("shiftsGroupedByAMPMSplit", shiftsGroupedByAMPMSplit);
  const amShiftsSortedByRole = _.sortBy(
    shiftsGroupedByAMPMSplit.am ?? [],
    "position"
  )
    .map((shift): ShiftAMPMSplitTableRow | null => {
      if (!shift.staffId) return null;
      const staffMember = staffMembers.find((sm) => sm.uid === shift.staffId);
      if (!staffMember) return null;
      const staffFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
      const positionData = businessSettings?.jobFunctions?.[shift.position];
      if (!positionData) return null;
      let departmentId = positionData.departmentId;
      if (!departmentId) {
        departmentId = "other";
      }
      const departmentName = DEPARTMENTS?.[departmentId as DepartmentId]?.title;
      if (!departmentName) return null;

      const roleId = shift.position;
      const roleName =
        getPositionNameById(businessSettings, roleId) || "Unknown";
      if (!roleName) return null;
      const timeIn = moment(shift.startTime, "HH:mm").format("hh:mm a");
      const timeOut = moment(shift.endTime, "HH:mm").format("hh:mm a");
      const startTimeDate = shift.startDate?.toDate();
      const endTimeDate = shift.endDate?.toDate();
      const notes = shift.notes || "";

      return {
        staffId: shift.staffId,
        staffFullName,
        departmentName,
        departmentId,
        roleName,
        roleId,
        timeIn,
        timeOut,

        startTimeDate,
        endTimeDate,
        notes,
      };
    })
    .filter((x) => !!x) as ShiftAMPMSplitTableRow[];
  const pmShiftsSortedByRole = _.sortBy(
    shiftsGroupedByAMPMSplit.pm ?? [],
    "position"
  )
    .map((shift): ShiftAMPMSplitTableRow | null => {
      if (!shift.staffId) return null;
      const staffMember = staffMembers.find((sm) => sm.uid === shift.staffId);
      if (!staffMember) return null;
      const staffFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
      const positionData = businessSettings?.jobFunctions?.[shift.position];
      if (!positionData) return null;
      let departmentId = positionData.departmentId;
      if (!departmentId) {
        departmentId = "other";
      }
      const departmentName = DEPARTMENTS?.[departmentId as DepartmentId]?.title;
      if (!departmentName) return null;

      const roleId = shift.position;
      const roleName =
        getPositionNameById(businessSettings, roleId) || "Unknown";
      if (!roleName) return null;
      const timeIn = moment(shift.startTime, "HH:mm").format("hh:mm a");
      const timeOut = moment(shift.endTime, "HH:mm").format("hh:mm a");
      const startTimeDate = shift.startDate?.toDate();
      const endTimeDate = shift.endDate?.toDate();
      const notes = shift.notes || "";

      return {
        staffId: shift.staffId,
        staffFullName,
        departmentName,
        departmentId,
        roleName,
        roleId,
        timeIn,
        timeOut,

        startTimeDate,
        endTimeDate,
        notes,
      };
    })
    .filter((x) => !!x) as ShiftAMPMSplitTableRow[];
  console.log({
    am: amShiftsSortedByRole,
    pm: pmShiftsSortedByRole,
  });
  return {
    date: startDateForReport,
    totalHours: [...amShiftsSortedByRole, ...pmShiftsSortedByRole].reduce(
      (acc, curr) =>
        acc +
        roundNumberToTwoDecimals(
          sanitizeNumericField(
            Math.abs(
              moment
                .duration(
                  moment(curr?.startTimeDate).diff(moment(curr?.endTimeDate))
                )
                .asHours()
            )
          )
        ),
      0
    ),
    am: amShiftsSortedByRole,
    pm: pmShiftsSortedByRole,
  };
};

export const generateDailyScheduleReportData = (
  businessSettings: TangoBusinessSettings,
  staffMembers: StaffMember[],
  dateForReport: Date,
  schedules: TangoSchedule[],
  business: TangoBusiness
) => {
  const startDateForReport = convertToBusinessTimezoneMoment(
    dateForReport,
    business
  )
    .startOf("day")
    .add(4, "hours")
    .toDate();
  const endDateForRerport = moment(startDateForReport).add(1, "day").toDate();
  let scheduleForReport = undefined;
  const schedulesWithDepartmentId = schedules?.filter((schedule) => {
    if (!dateForReport) return null;
    return (
      schedule.departmentId &&
      convertToBusinessTimezoneMoment(dateForReport, business).isBetween(
        moment(schedule.startDate.toMillis()).startOf("day"),
        moment(schedule.endDate.toMillis()).endOf("day"),
        null,
        "[]"
      )
    );
  });

  if (schedulesWithDepartmentId.length) {
    let mergedShifts: TangoShift[] = [];
    schedulesWithDepartmentId.forEach((sch) => {
      if (sch.shifts?.length) {
        mergedShifts = [
          ...mergedShifts,
          ...sch.shifts.map((shift) => ({ ...shift, draftScheduleId: sch.id })),
        ];
      }
    });
    const mergedSchedule: TangoSchedule = {
      ...schedulesWithDepartmentId[0],
      departmentId: schedulesWithDepartmentId[0].departmentId || "foh",
      shifts: mergedShifts,
      mergedSchedules: schedulesWithDepartmentId.map((s) => s.id),
    };

    scheduleForReport = mergedSchedule;
  } else {
    scheduleForReport = schedules?.find((schedule) => {
      if (!dateForReport) return null;
      return convertToBusinessTimezoneMoment(dateForReport, business).isBetween(
        moment(schedule.startDate.toMillis()).startOf("day"),
        moment(schedule.endDate.toMillis()).endOf("day"),
        null,
        "[]"
      );
    });
  }

  if (!scheduleForReport) return null;

  const shiftsWithinTheReport = scheduleForReport?.shifts?.filter((shift) => {
    const shiftStartIsAfterReportStart = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSameOrAfter(moment(startDateForReport));
    const isSameDateAsStartDate = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSame(moment(startDateForReport), "date");
    const shiftStartIsBeforeReportEnd = convertToBusinessTimezoneMoment(
      shift?.startDate?.toDate(),
      business
    ).isSameOrBefore(moment(endDateForRerport));
    return shiftStartIsAfterReportStart && shiftStartIsBeforeReportEnd;
  });

  if (!shiftsWithinTheReport.length) {
    return null;
  }

  const shiftsGroupedByShiftName = _.groupBy(
    shiftsWithinTheReport,
    (shift) =>
      businessSettings?.shiftTypes?.find((sh) => sh.id === shift.shiftTypeId)
        ?.name
  );
  console.log("shiftsGroupedByShiftName", shiftsGroupedByShiftName);
  const shiftsGroupedByShiftType = _.groupBy(
    shiftsWithinTheReport,
    "shiftTypeId"
  );
  console.log("shiftsGroupedByShiftType", shiftsGroupedByShiftType);
  const shiftTables: ShiftTable[] = (
    _.keys(shiftsGroupedByShiftName).map((shiftTypeName) => {
      const shifts = shiftsGroupedByShiftName[shiftTypeName];
      const shiftsGroupedByRole = _.groupBy(shifts, "position");
      const shiftRolesTables = _.keys(shiftsGroupedByRole)
        .map((positionId) => {
          const shiftsForPosition = shiftsGroupedByRole[positionId];
          const shiftTableRows: ShiftTypeRolesTableRow[] = shiftsForPosition
            .map((shiftForPosition) => {
              const staffId = shiftForPosition.staffId;
              if (!staffId) return null;
              const staffMember = staffMembers.find((sm) => sm.uid === staffId);
              if (!staffMember) return null;
              const staffFullName = `${staffMember.contact.firstName} ${staffMember.contact.lastName}`;
              const timeIn = moment(shiftForPosition.startTime, "HH:mm").format(
                "hh:mm a"
              );
              const timeOut = moment(shiftForPosition.endTime, "HH:mm").format(
                "hh:mm a"
              );
              const startTimeDate = shiftForPosition.startDate?.toDate();
              const endTimeDate = shiftForPosition.endDate?.toDate();
              const notes = shiftForPosition.notes || "";
              const shiftTableRow: ShiftTypeRolesTableRow = {
                staffId,
                staffFullName,
                timeIn,
                timeOut,
                startTimeDate,
                endTimeDate,
                notes,
              };
              return shiftTableRow;
            })
            .filter((x) => !!x) as ShiftTypeRolesTableRow[];
          const positionData = businessSettings?.jobFunctions?.[positionId];
          if (!positionData) return null;
          let departmentId = positionData.departmentId;
          if (!departmentId) {
            departmentId = "other";
          }
          const departmentName =
            DEPARTMENTS?.[departmentId as DepartmentId]?.title;
          if (!departmentName) return null;
          const totalNumberOfShifts = shiftTableRows.length;
          const totalNumberOfHours = shiftTableRows.reduce((acc, curr) => {
            return (
              acc +
              roundNumberToTwoDecimals(
                sanitizeNumericField(
                  Math.abs(
                    moment
                      .duration(
                        moment(curr?.startTimeDate).diff(
                          moment(curr?.endTimeDate)
                        )
                      )
                      .asHours()
                  )
                )
              )
            );
          }, 0);
          const roleId = positionId;
          const roleName =
            getPositionNameById(businessSettings, positionId) || "Unknown";
          if (!roleName) return null;
          const shiftRoleTable: ShiftTypeRolesTable = {
            departmentId,
            departmentName,
            totalNumberOfShifts,
            totalNumberOfHours,
            roleId,
            roleName,
            shifts: shiftTableRows,
          };
          return shiftRoleTable;
        })
        .filter((x) => !!x) as ShiftTypeRolesTable[];
      let shiftTitle = shiftTypeName;
      if (!shiftTitle) {
        shiftTitle = "Unnamed";
      }
      const totalHours = shiftRolesTables.reduce((acc, curr) => {
        return acc + curr.totalNumberOfHours;
      }, 0);
      const shiftTable: ShiftTable = {
        totalHours,
        shiftTypeId: shiftTypeName,
        shiftTitle,
        shiftRolesTables,
      };
      return shiftTable;
    }) || []
  ).filter((x) => !!x) as ShiftTable[];
  const dailyShiftReport: DailyShiftsReport = {
    startDate: startDateForReport,
    endDate: endDateForRerport,
    shiftTables,
  };
  return dailyShiftReport;
};

export interface TangoTax {
  orderChannel: string;
  orderType: string;
  taxRate: number;
  tangoFeeCents: number;
  tangoFeePercent: number;
}

export interface SalesByProductType {
  productType: string;
  quantity: number;
  subTotal: number;
  tax: number;
  tip: number;
  grossTotal: number;
  discountTotal: number;
  netTotal: number;
  fees: number;
}

export interface Amount {
  subTotal: number;
  tax: number;
  deliveryFee: number;
  serviceChargeTotal: number;
  discountTotal: number;
  tip: number;
  grossTotal: number;
  netTotal: number;
  currency: string;
}

export interface ICloseOutReport {
  id: string;
  date: Date;
  data: {
    ordersAndTabs: FlattenTab[];
    salesByProductType: SalesByProductType[];
    grossTotal: number;
    tax: number;
    discountTotal: number;
    netTotal: number;
    salesTax: number;
    refunds: number;
    tips: number;
    cashSales: number;
    cashQuantity: number;
    cardQuantity: number;
    cardSales: number;
    tangoFees: number;
  }[];
}

export const getTaxRateAndTangoFees = (
  allPricingModels: TangoTax[],
  orderChannel = "tangoPOS",
  orderType = "dineIn"
) => {
  const pricing = allPricingModels.filter(
    (price) =>
      orderChannel === price.orderChannel && orderType === price.orderType
  );

  if (pricing.length > 0) {
    return {
      taxRate: pricing[0].taxRate,
      tangoFeeCents: pricing[0].tangoFeeCents,
      tangoFeePercent: pricing[0].tangoFeePercent,
    };
  }

  return {
    taxRate: 0,
    tangoFeeCents: 0,
    tangoFeePercent: 0,
  };
};

export const createAmount = (
  itemsInCart: FirebaseOrderProduct[],
  allPricingModels: TangoTax[],
  currency: string,
  isCash: boolean,
  businessSettings: TangoBusinessSettings | null,
  isSpeed: boolean,
  tip = 0
): Amount => {
  const taxesAndFees = getTaxRateAndTangoFees(allPricingModels);

  let subTotal = 0;
  let discountTotal = 0;
  let tax = 0;

  const allUseDefaultTaxRate = !itemsInCart.some((item) => item.taxRate);

  itemsInCart.forEach((item) => {
    const taxRate = item.taxRate ? item.taxRate : taxesAndFees.taxRate;
    const totalRegularPrice =
      item.price +
      item.selectedModifiers.reduce((acc, val) => acc + val.additionalCost, 0);
    const totalProductPrice =
      (item.discountedPrice || item.discountedPrice === 0
        ? item.discountedPrice
        : totalRegularPrice) * item.quantity;
    const totalProductDiscount =
      item.discountedPrice || item.discountedPrice === 0
        ? (totalRegularPrice - item.discountedPrice) * item.quantity
        : 0;

    subTotal += totalProductPrice;
    discountTotal += totalProductDiscount;
    tax += (taxRate / 100) * totalProductPrice;
  });

  if (isCash) {
    if (allUseDefaultTaxRate) {
      const finalTaxRate = taxesAndFees.taxRate;
      const oldPrice = subTotal + tax;
      const roundingFactor = isSpeed
        ? businessSettings?.speedCashRoundAmount || 0
        : businessSettings?.flexCashRoundAmount || 0;
      const roundingType = isSpeed
        ? businessSettings?.speedCashRoundType
        : businessSettings?.flexCashRoundType;

      if (roundingFactor !== 0) {
        switch (roundingType) {
          case "to":
            const factor = parseFloat(String(roundingFactor)) * 100;
            const upper = (Math.trunc(oldPrice / factor) + 1) * factor;
            const newToPrice =
              (upper - oldPrice) / factor < 0.5
                ? upper
                : oldPrice - (oldPrice % factor);
            subTotal = newToPrice / (1 + finalTaxRate / 100);
            tax = subTotal * (finalTaxRate / 100);
            break;
          case "down":
            const newDownPrice =
              oldPrice -
              (oldPrice % (parseFloat(String(roundingFactor)) * 100));
            subTotal = newDownPrice / (1 + finalTaxRate / 100);
            tax = subTotal * (finalTaxRate / 100);
            break;
          default:
            break;
        }
      }
    }
  }

  return {
    subTotal: subTotal,
    tax: tax,
    deliveryFee: 0,
    serviceChargeTotal: 0,
    discountTotal: discountTotal,
    tip: tip,
    grossTotal: subTotal + tax + discountTotal,
    netTotal: subTotal + tax,
    currency: currency,
  };
};

export const organizeFlattenTabByProductType = (
  tabs: FlattenTab[],
  allPricingModels: TangoTax[],
  businessSettings: TangoBusinessSettings | null
) => {
  return ([] as ProductWithMetaData[]).concat
    .apply(
      [],
      tabs.map((tab) => {
        return tab.productsPaid.map((product) => {
          return {
            ...product,
            isCash: tab.paymentType === "cash",
            currency: tab.currency,
            orderType: tab.orderType,
            orderChannel: tab.orderChannel,
          };
        });
      })
    )
    .reduce(
      (
        acc: {
          productType: string;
          quantity: number;
          subTotal: number;
          tax: number;
          tip: number;
          grossTotal: number;
          discountTotal: number;
          netTotal: number;
          fees: number;
        }[],
        val
      ) => {
        const index = acc.findIndex((data) => data.productType === val.type);
        const amount = createAmount(
          [val],
          allPricingModels,
          val.currency,
          val.isCash,
          businessSettings,
          true
        );
        const pricingIndex = allPricingModels.findIndex(
          (pricingModel) =>
            pricingModel.orderChannel === val.orderChannel &&
            pricingModel.orderType === val.orderType
        );
        const fees =
          pricingIndex !== -1
            ? (allPricingModels[pricingIndex].tangoFeePercent / 100) *
                amount.netTotal +
              allPricingModels[pricingIndex].tangoFeeCents
            : 0;
        if (index !== -1) {
          acc[index] = {
            productType: val.type,
            quantity: acc[index].quantity + val.quantity,
            subTotal: acc[index].subTotal + amount.subTotal,
            tax: acc[index].tax + amount.tax,
            tip: acc[index].tip + amount.tip,
            grossTotal: acc[index].grossTotal + amount.grossTotal,
            discountTotal: acc[index].discountTotal + amount.discountTotal,
            netTotal: acc[index].netTotal + amount.netTotal,
            fees: acc[index].fees + fees,
          };
          return acc;
        }
        return [
          ...acc,
          {
            productType: val.type,
            quantity: val.quantity,
            subTotal: amount.subTotal,
            tax: amount.tax,
            tip: amount.tip,
            grossTotal: amount.grossTotal,
            discountTotal: amount.discountTotal,
            netTotal: amount.netTotal,
            fees: fees,
          },
        ];
      },
      []
    );
};

export const fetchTodaysCashEventsByBusinessId = async (businessId: string) => {
  try {
    const startDate =
      new Date().getHours() <= 4
        ? new Date(
            moment()
              .subtract(1, "day")
              .startOf("day")
              .add(4, "hours")
              .format("YYYY-MM-DD HH:mm:ss")
          )
        : new Date(
            moment()
              .subtract(1, "day")
              .endOf("day")
              .add(4, "hours")
              .format("YYYY-MM-DD HH:mm:ss")
          );
    const querySnapshot = await db
      .collection("CashEvents")
      .where("businessId", "==", businessId)
      .where("createdAt", ">=", startDate)
      .where("deleted", "==", false)
      .where("enabled", "==", true)
      .get();

    const data = _.sortBy(
      querySnapshot.docs.map((item) => composeUsableCashEvent(item.data())),
      "createdAt"
    );

    const results = [];
    for (let i = 0; i < data.length; i++) {
      results.push(data[i]);
    }

    return results;
  } catch (err) {
    throw new Error((err as Error).message);
  }
};

interface EventSummary {
  amount: number;
  timestamp: string;
  description?: string;
}

export const summarizeCashEvents = (unsortedEvents: FirebaseCashEvents[]) => {
  // @ts-ignore
  const cashEvents: CashEvents[] = unsortedEvents.sort(function (d1, d2) {
    // @ts-ignore
    return d1.updatedAt.toDate().getTime() - d2.updatedAt.toDate().getTime();
  });

  return cashEvents.reduce(
    (
      acc: {
        records: { [T: string]: number };
        cashDrawers: {
          cashDrawerId: string;
          openDrawer: EventSummary | null;
          closeDrawer: EventSummary | null;
          payout: EventSummary[] | null;
          order: EventSummary[] | null;
          refund: EventSummary[] | null;
        }[];
      },
      val: FirebaseCashEvents
    ) => {
      const index = acc.cashDrawers.findIndex(
        (cashDrawer) => cashDrawer.cashDrawerId === val.cashDrawerId
      );
      if (val.event === "openDrawer") {
        acc.records[val.cashDrawerId] = val.payload.startingFloat;
        if (index !== -1) {
          acc.cashDrawers[index].openDrawer = {
            amount: val.payload.startingFloat,
            timestamp: val.updatedAt.toDate(),
          };
          acc.cashDrawers[index].closeDrawer = null;
          acc.cashDrawers[index].payout = null;
          acc.cashDrawers[index].order = null;
          acc.cashDrawers[index].refund = null;
          return acc;
        }
        return {
          records: acc.records,
          cashDrawers: [
            ...acc.cashDrawers,
            {
              cashDrawerId: val.cashDrawerId,
              openDrawer: {
                amount: val.payload.startingFloat,
                timestamp: val.updatedAt.toDate(),
              },
              closeDrawer: null,
              payout: null,
              order: null,
              refund: null,
            },
          ],
        };
      }
      // Make sure every event besides opening the drawer has openDrawer as value and not null
      if (val.event === "closeDrawer") {
        if (index !== -1) {
          acc.records[val.cashDrawerId] = 0;
          acc.cashDrawers[index].closeDrawer = {
            amount: val.payload.currentCashExpected,
            timestamp: val.updatedAt.toDate(),
          };
          return acc;
        }
      }
      if (val.event === "order") {
        if (index !== -1) {
          const amountEarned =
            val.payload.currentCashExpected - acc.records[val.cashDrawerId];
          const orderEvent = {
            amount: amountEarned,
            timestamp: moment(val.updatedAt.toDate()).format("hh:mm:ss A"),
          };
          const orderEvents = acc.cashDrawers[index].order || [];
          orderEvents.push(orderEvent);
          acc.cashDrawers[index].order = orderEvents;
          acc.records[val.cashDrawerId] = val.payload.currentCashExpected;
          return acc;
        }
      }
      if (val.event === "refund") {
        if (index !== -1) {
          const amountRefunded =
            acc.records[val.cashDrawerId] - val.payload.currentCashExpected;
          const refundEvent = {
            amount: amountRefunded,
            timestamp: val.updatedAt.toDate(),
          };
          const refundEvents = acc.cashDrawers[index].refund || [];
          refundEvents.push(refundEvent);
          acc.cashDrawers[index].refund = refundEvents;
          acc.records[val.cashDrawerId] = val.payload.currentCashExpected;
          return acc;
        }
      }
      if (val.event === "payout") {
        if (index !== -1 && acc.cashDrawers[index].openDrawer) {
          const payoutAmount =
            acc.records[val.cashDrawerId] - val.payload.currentCashExpected;
          const payoutEvent = {
            amount: payoutAmount,
            timestamp: val.updatedAt.toDate(),
            description: val.description,
          };
          const payoutEvents = acc.cashDrawers[index].payout || [];
          payoutEvents.push(payoutEvent);
          acc.cashDrawers[index].payout = payoutEvents;
          acc.records[val.cashDrawerId] = val.payload.currentCashExpected;
          return acc;
        }
      }
      return acc;
    },
    { records: {}, cashDrawers: [] }
  ).cashDrawers;
};

export const overrideActualSalesDateStringFormat = "DD-MM-YYYY";

export const applyDailyLogActualSalesOverride = async (
  amount: number,
  date: Date,
  scheduleId: string
) => {
  try {
    const dateString = moment(date).format(overrideActualSalesDateStringFormat);
    const scheduleSN = await db.collection("Schedule").doc(scheduleId).get();
    const schedule: TangoSchedule = scheduleSN.data() as TangoSchedule;
    const currentActualSalesOverride =
      schedule?.actualDailySalesOverrides?.[dateString];
    if (currentActualSalesOverride) {
      if (amount) {
        await db
          .collection("Schedule")
          .doc(scheduleId)
          .update({
            [`actualDailySalesOverrides.${dateString}`]: {
              dayString: dateString,
              salesAmount: amount,
            },
          });
      } else {
        await db
          .collection("Schedule")
          .doc(scheduleId)
          .update({
            [`actualDailySalesOverrides.${dateString}`]: null,
          });
      }
    } else {
      await db
        .collection("Schedule")
        .doc(scheduleId)
        .update({
          [`actualDailySalesOverrides.${dateString}`]: {
            dayString: dateString,
            salesAmount: amount,
          },
        });
    }
    if (amount) {
    }
  } catch (e) {
    console.log("applyDailyLogActualSalesOverride error", e);
  }
};

export const fetchReportingData = async (
  businessId: string,
  startDate: string | Date,
  endDate: string | Date
) => {
  const response = await fetch(
    "https://us-central1-tango-2.cloudfunctions.net/fetchSalesForReporting",
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        businessId,
        startDate,
        endDate,
      }),
    }
  );

  if (response.status === 200) {
    const result = await response.json();
    return result;
  } else {
    return null;
  }
};

export const getCloseOutReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date,
  allPricingModels: TangoTax[],
  businessSettings: TangoBusinessSettings,
  business: TangoBusiness
): Promise<ICloseOutReport[]> => {
  let date = moment(startDate).startOf("day").add(4, "hours").toDate();
  const closeOutData: ICloseOutReport[] = [];
  const { salesData } = await fetchSharedData(businessId, startDate, endDate);
  while (date <= endDate) {
    const nextDay = moment(date).add(1, "day").toDate();
    try {
      const result = salesData.filter((tab: FlattenTab) => {
        const createdAt = convertDateBackToBusinessTimezone(
          tab.createdAt,
          business
        );
        return (
          moment(createdAt).isSameOrBefore(nextDay) &&
          moment(createdAt).isSameOrAfter(date)
        );
      });
      console.log("RESULT: ", result);
      console.log(
        result
          .map((i) => i.netAmount)
          .sort((a, b) => a - b)
          .join(",")
      );
      if (result && result.length) {
        const reportingData = result;
        const salesByType = organizeFlattenTabByProductType(
          reportingData,
          allPricingModels,
          businessSettings
        );

        const grossTotal = Math.round(
          salesByType.reduce((acc, val) => acc + val.grossTotal, 0)
        );
        const tax = Math.round(
          salesByType.reduce((acc, val) => acc + val.tax, 0)
        );
        const discountTotal = Math.round(
          salesByType.reduce((acc, val) => acc + val.discountTotal, 0)
        );
        const netTotal = Math.round(
          salesByType.reduce((acc, val) => acc + val.netTotal, 0)
        );
        const salesTax = Math.round(
          salesByType.reduce((acc, val) => acc + val.tax, 0)
        );
        const refunds = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) => acc + val.refundedAmount,
            0
          )
        );
        const tips = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) => (val.tip ? acc + val.tip : acc),
            0
          )
        );
        const cashSales = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) =>
              val.paymentType === "cash" ? acc + val.netAmount : acc,
            0
          )
        );
        const cashQuantity = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) =>
              val.paymentType === "cash" ? acc + 1 : acc,
            0
          )
        );
        const cardQuantity = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) =>
              val.paymentType === "stripe" ? acc + 1 : acc,
            0
          )
        );
        const cardSales =
          Math.round(
            reportingData.reduce(
              (acc: number, val: FlattenTab) =>
                val.paymentType === "stripe" ? acc + val.netAmount : acc,
              0
            )
          ) +
          tips -
          refunds;
        const tangoFees = Math.round(
          reportingData.reduce(
            (acc: number, val: FlattenTab) => acc + val.applicationFeeAmount,
            0
          )
        );

        console.log({
          id: moment(date).toDate().toString(),
          date: moment(date).toDate(),
          data: [
            {
              ordersAndTabs: reportingData,
              salesByProductType: salesByType,
              grossTotal,
              tax,
              discountTotal,
              netTotal,
              salesTax,
              refunds,
              tips,
              cashSales,
              cashQuantity,
              cardQuantity,
              cardSales,
              tangoFees,
            },
          ],
        });

        closeOutData.push({
          id: moment(date).toDate().toString(),
          date: moment(date).toDate(),
          data: [
            {
              ordersAndTabs: reportingData,
              salesByProductType: salesByType,
              grossTotal,
              tax,
              discountTotal,
              netTotal,
              salesTax,
              refunds,
              tips,
              cashSales,
              cashQuantity,
              cardQuantity,
              cardSales,
              tangoFees,
            },
          ],
        });
      } else {
        result.push({
          id: "",
          date: new Date(),
          data: [],
        });
      }
    } catch (err) {
      console.log("ERR: ", err);
    }

    date = moment(date).add(1, "day").toDate();
  }
  return closeOutData;
};

interface ITipsByMenu {
  menuName: string;
  tip: number;
}

export interface ITipsByMenuReport {
  id: string;
  date: Date;
  data: ITipsByMenu[];
}

export const getTipsByMenu = async (
  businessId: string,
  startDate: Date,
  endDate: Date
) => {
  const sharedData = await fetchSharedData(businessId, startDate, endDate);
  const result: ITipsByMenuReport[] = _.keys(sharedData.groupedSales).reduce(
    (acc: ITipsByMenuReport[], date: string) => {
      const tipsByMenu: ITipsByMenu[] = [];
      sharedData.groupedSales[date].forEach((tab) => {
        const customerId = tab.customerId;
        const tabId = tab.tabId;
        const tip = tab.tip;

        const totalPrice = (tab.productsPaid || []).reduce(
          (tot: number, product: Product) => {
            const totalModifier = (product.selectedModifiers || []).reduce(
              (acc: number, val) =>
                val.additionalCost ? acc + val.additionalCost : acc,
              0
            );
            const totalPrice =
              (product.discountedPrice || product.discountedPrice === 0
                ? product.discountedPrice
                : product.price + totalModifier) * product.quantity;
            return tot + totalPrice;
          },
          0
        );

        const products = (tab.productsPaid || []).map((product: any) => {
          const totalModifier = (product.selectedModifiers || []).reduce(
            (acc: number, val: any) =>
              val.additionalCost ? acc + val.additionalCost : acc,
            0
          );
          const cost =
            (product.discountedPrice || product.discountedPrice === 0
              ? product.discountedPrice
              : product.price + totalModifier) * product.quantity;
          const tipAmount = totalPrice ? (cost / totalPrice) * tip : 0;

          return {
            ...product,
            totalModifier,
            tipForProduct: tipAmount,
            totalPriceOfItem: cost,
            totalPriceToCustomer: totalPrice,
            totalTip: tip,
            customerId,
            tabId,
          };
        });

        products.forEach((product) => {
          const menuName = product.menuName;
          const index = tipsByMenu.findIndex(
            (tipByMenu) => tipByMenu.menuName === menuName
          );
          if (index !== -1) {
            tipsByMenu[index].tip += product.tipForProduct;
          } else {
            tipsByMenu.push({
              menuName: menuName,
              tip: product.tipForProduct,
            });
          }
        });
      });

      return [
        ...acc,
        {
          id: String(date),
          date: new Date(date),
          data: tipsByMenu,
        },
      ];
    },
    [] as ITipsByMenuReport[]
  );

  return result;
};

const readCollection = async <T>(
  collectionName: string,
  businessId: string,
  composeUsableType: (doc: DocumentData) => T,
  startDate?: Date,
  endDate?: Date
) => {
  try {
    const results: T[] = [];
    const collectionRef = db.collection(collectionName);

    if (startDate && endDate) {
      const querySnapshot = await collectionRef
        .where("businessId", "==", businessId)
        .where("createdAt", ">=", startDate)
        .where("createdAt", "<=", endDate)
        .get();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push(composeUsableType(data));
      });
      return results;
    } else {
      const querySnapshot = await collectionRef
        .where("businessId", "==", businessId)
        .get();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push(composeUsableType(data));
      });
      return results;
    }
  } catch (err) {
    console.log("Error getting documents: ", err);
    return [];
  }
};

interface StaffRow {
  firstName: string;
  lastName: string;
  role: string;
  payRate: number;
  fohBoh: string;
  id: string;
  pinCode: number;
}

export const getStaffMembers = async (businessId: string) => {
  const staffs = await readCollection("Staff", businessId, (doc) => doc);
  const settings = await readCollection(
    "BusinessSettings",
    businessId,
    (doc) => doc
  );
  const jobFunctions =
    settings && settings.length ? settings[0]?.jobFunctions || [] : [];
  // console.log('Job functions: ', jobFunctions);
  const jobs = Object.keys(jobFunctions).reduce((acc, key) => {
    const val = jobFunctions[key];
    acc[val.id] = val.departmentId.toUpperCase();
    return acc;
  }, {} as { [T: string]: string });
  console.log("Staff: ", jobs);

  // This is to account for staff like Takeout Takeout
  const staffWithoutPay = staffs
    .filter((staff) => !staff.payRates || staff.payRates.length === 0)
    .map((staff) => ({
      id: staff.id,
      firstName: staff.contact.firstName,
      lastName: staff.contact.lastName,
      role: staff.primaryRole,
      payRate: 0,
      fohBoh: "FOH",
      pinCode: staff.pinCode,
    }));

  const results: StaffRow[] = staffWithoutPay;

  for (const staff of staffs) {
    const payRates = staff.payRates || [];
    for (const payRate of payRates) {
      results.push({
        id: staff.id,
        firstName: staff.contact.firstName,
        lastName: staff.contact.lastName,
        role: payRate.role,
        payRate: payRate.amount,
        fohBoh: jobs[payRate.roleId],
        pinCode: staff.pinCode,
      });
    }
  }
  return results;
};

interface DiscountRow {
  discountName: string;
  managerName: string;
  serverName: string;
  itemName: string;
  price: number;
  quantity: number;
  originalPrice: number;
  discountAmount: number;
  finalPrice: number;
  date: string;
}

export interface IDiscountSummaryReport {
  id: string;
  startDate: Date;
  endDate: Date;
  data: DiscountRow[];
}

export const getDiscountReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<IDiscountSummaryReport[]> => {
  try {
    console.log(
      "Getting discount report for business: ",
      businessId,
      " from ",
      startDate,
      " to ",
      endDate
    );
    const { salesGroupedByWeek } = await fetchSharedData(
      businessId,
      startDate,
      endDate
    );
    const staff = await getStaffMembers(businessId);

    const result: IDiscountSummaryReport[] = _.keys(salesGroupedByWeek).reduce(
      (summary: IDiscountSummaryReport[], date: string) => {
        const allTabs: FlattenTab[] =
          salesGroupedByWeek[date].flattenTabs || [];
        const discountedData: DiscountRow[] = allTabs.reduce(
          (discounts: DiscountRow[], tab: FlattenTab) => {
            const products: Product[] = tab.productsPaid || [];
            const staffId = tab.staffId;
            const managerIdDiscountIssuedTo = tab.managerIdDiscountIssuedTo;

            const staffMember = staff.find((member) => member.id === staffId);
            const managerStaffMember = managerIdDiscountIssuedTo
              ? staff.find((member) => member.id === managerIdDiscountIssuedTo)
              : "";
            if (staffMember) {
              const newDiscounts = products.reduce(
                (acc: DiscountRow[], product) => {
                  const name = product.name;
                  const totalModifiers = product.selectedModifiers.reduce(
                    (tot, item) => tot + item.additionalCost,
                    0
                  );
                  const price = product.price + totalModifiers;
                  const discountedPrice = product.discountedPrice;
                  const quantity = product.quantity;

                  // @ts-ignore
                  if (
                    product.discountsAppliedToProduct &&
                    product.discountsAppliedToProduct.length > 0
                  ) {
                    // @ts-ignore
                    for (const discount of product.discountsAppliedToProduct) {
                      if (discountedPrice || discountedPrice === 0) {
                        const originalTotal = price * quantity;
                        const finalPrice = discountedPrice * quantity;
                        // Sorry for the hack guys sometimes
                        // the discount goes above it's so
                        // weird
                        const discountTotal = originalTotal - finalPrice;
                        const d = new Date(tab.createdAt);
                        const ds = d.toDateString();

                        acc.push({
                          // @ts-ignore
                          discountName: discount.discountName,
                          managerName: managerStaffMember
                            ? managerStaffMember.firstName +
                              " " +
                              managerStaffMember.lastName
                            : "",
                          serverName: staffMember
                            ? staffMember.firstName + " " + staffMember.lastName
                            : "",
                          itemName: name,
                          price: price,
                          quantity: quantity,
                          originalPrice: originalTotal,
                          discountAmount: discountTotal,
                          finalPrice: finalPrice,
                          date: ds,
                        });

                        discounts[0].price += price;
                        discounts[0].quantity += quantity;
                        discounts[0].originalPrice += originalTotal;
                        discounts[0].discountAmount += discountTotal;
                        discounts[0].finalPrice += finalPrice;
                      }
                    }
                  }
                  return acc;
                },
                [] as DiscountRow[]
              );

              return [...discounts, ...newDiscounts];
            }

            return discounts;
          },
          [
            {
              discountName: "TOTAL",
              managerName: "",
              serverName: "",
              itemName: "",
              price: 0,
              quantity: 0,
              originalPrice: 0,
              discountAmount: 0,
              finalPrice: 0,
              date: "",
            },
          ]
        );

        const [totalDiscount, ...discounts] = discountedData;

        summary.push({
          id: String(summary.length + 1),
          startDate: salesGroupedByWeek[date].startDate,
          endDate: salesGroupedByWeek[date].endDate,
          data: [...discounts, totalDiscount],
        });

        return summary;
      },
      []
    );

    return result;
  } catch (err) {
    console.log("ERR: ", err);
    return [];
  }
};

interface VoidRow {
  discountName: string;
  managerName: string;
  serverName: string;
  itemName: string;
  price: number;
  quantity: number;
  originalPrice: number;
  discountAmount: number;
  finalPrice: number;
  date: string;
}

export interface IVoidSummaryReport {
  id: string;
  startDate: Date;
  endDate: Date;
  data: VoidRow[];
}

export const getVoidReportForADateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date
): Promise<IVoidSummaryReport[]> => {
  try {
    console.log(
      "Getting discount report for business: ",
      businessId,
      " from ",
      startDate,
      " to ",
      endDate
    );
    const { salesGroupedByWeek } = await fetchSharedData(
      businessId,
      startDate,
      endDate
    );
    const staff = await getStaffMembers(businessId);

    const result: IVoidSummaryReport[] = _.keys(salesGroupedByWeek).reduce(
      (summary: IVoidSummaryReport[], date: string) => {
        const allTabs: FlattenTab[] =
          salesGroupedByWeek[date].flattenTabs || [];
        const voidedData: VoidRow[] = allTabs.reduce(
          (voids: VoidRow[], tab: FlattenTab) => {
            const products: Product[] = tab.productsPaid || [];
            const voidedProducts = tab?.voidedProducts ?? [];
            const staffId = tab.staffId;
            const managerIdDiscountIssuedTo = tab.managerIdDiscountIssuedTo;

            const staffMember = staff.find((member) => member.id === staffId);

            if (staffMember && voidedProducts.length) {
              const newVoids = voidedProducts.reduce(
                (acc: VoidRow[], product) => {
                  const name = product.name;
                  const totalModifiers = product.selectedModifiers.reduce(
                    (tot, item) => tot + item.additionalCost,
                    0
                  );
                  const price = product.price + totalModifiers;
                  const discountedPrice = product.discountedPrice;
                  const quantity = product.quantity;

                  const originalTotal = price * quantity;
                  const finalPrice = price * quantity;
                  // Sorry for the hack guys sometimes
                  // the discount goes above it's so
                  // weird
                  const discountTotal = finalPrice;
                  const d = new Date(tab.createdAt);
                  const ds = d.toDateString();

                  acc.push({
                    // @ts-ignore
                    discountName: "Void",
                    managerName: "",
                    serverName: staffMember
                      ? staffMember.firstName + " " + staffMember.lastName
                      : "",
                    itemName: name,
                    price: price,
                    quantity: quantity,
                    originalPrice: originalTotal,
                    discountAmount: discountTotal,
                    finalPrice: finalPrice,
                    date: ds,
                  });

                  voids[0].price += price;
                  voids[0].quantity += quantity;
                  voids[0].originalPrice += originalTotal;
                  voids[0].discountAmount += discountTotal;
                  voids[0].finalPrice += finalPrice;
                  return acc;
                },
                [] as VoidRow[]
              );

              return [...voids, ...newVoids];
            }

            return voids;
          },
          [
            {
              discountName: "TOTAL",
              managerName: "",
              serverName: "",
              itemName: "",
              price: 0,
              quantity: 0,
              originalPrice: 0,
              discountAmount: 0,
              finalPrice: 0,
              date: "",
            },
          ]
        );

        const [totalVoid, ...voids] = voidedData;

        summary.push({
          id: String(summary.length + 1),
          startDate: salesGroupedByWeek[date].startDate,
          endDate: salesGroupedByWeek[date].endDate,
          data: [...voids, totalVoid],
        });

        return summary;
      },
      []
    );

    return result;
  } catch (err) {
    console.log("ERR: ", err);
    return [];
  }
};

export const salesMixForDateRange = async (
  businessId: string,
  startDate: Date,
  endDate: Date,
  productTypes: { [T: string]: string }
) => {
  // Fetch business
  const businessSn = await firebase
    .firestore()
    .collection("Businesses")
    .doc(businessId)
    .get();
  const business: TangoBusiness = businessSn.data() as TangoBusiness;

  // Fetch sales
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  const salesDataRes = await fetchReportingData(
    businessId,
    startDate.toDateString(),
    endDate.toDateString()
  );

  // const salesDataRes = await tangoReportingApiClient.post(
  //   "/fetch-sales-for-reporting",
  //   {
  //     businessId,
  //     startDate: startDate.toDateString(),
  //     endDate: endDate.toDateString(),
  //   }
  // );

  // Fetch third party sales
  const thirdPartyTabs =
    business && business.deliverect && business.deliverect.locationId
      ? await getThirdPartySalesByBusinessIdAndDateRange(
          businessId,
          business.deliverect.locationId,
          startDate,
          endDate
        )
      : [];

  // Concat Tango Sales and Third party sales
  const salesData = [
    ...(salesDataRes?.response || ([] as FlattenTab[])),
    ...thirdPartyTabs.map((i: FlattenTab) => ({
      ...i,
      createdAt: convertDateBackToBusinessTimezone(i.createdAt, business),
      oldCreatedAt: new Date(i.createdAt),
    })),
  ];

  const productsPaid = salesData.flatMap((sale) => sale.productsPaid);
  const productIs = productsPaid.filter((product) => product.plu === "33801");
  const mods = productIs.flatMap((pr) => pr.selectedModifiers);
  const modif = mods.flatMap((mod) => mod.options);
  const jameson = modif.filter((mod) => mod.name === "Jameson");

  console.log("SALES DATA RES: ", productIs);

  // Groupe sales by dates
  const salesGroupedByDates = salesData.reduce((acc, val) => {
    const date = new Date(val.createdAt).toISOString();
    if (Object.keys(acc).includes(date)) {
      acc[date].push(val);
    } else {
      acc[date] = [val];
    }

    return acc;
  }, {});
  const finalSalesGroupedByDates: FlattenedTabsGroupedByDates = {};
  _.keys(salesGroupedByDates).forEach((date) => {
    finalSalesGroupedByDates[date] = {
      flattenTabs: salesGroupedByDates[date],
    };
  });

  const productMixResults: IProductMix[] = [];
  _.keys(finalSalesGroupedByDates).forEach((date) => {
    finalSalesGroupedByDates[date].flattenTabs.forEach((tab) => {
      tab.productsPaid.forEach((product) => {
        // Temporary hack
        if (!product.name) {
          // @ts-ignore
          product.name = product.productName || "Unknown";
        }

        const deliverectProductType = product.plu
          ? productTypes[product.plu]
          : null;
        product.type = deliverectProductType
          ? _.startCase(_.toLower(deliverectProductType))
          : _.startCase(_.toLower(product.type)) || "Other";

        const productsInfo: IProductMixProduct = {
          productName: product.name,
          quantity: product.quantity,
          sales: product.price * product.quantity,
          cost: 0,
          profit: product.price * product.quantity,
          type: "product",
          menuCategory: product.menuCategory,
          plu: product.plu || "",
          tabIds: tab.tabId,
        };
        const modifiersInfo = ([] as IProductMixProduct[]).concat
          .apply(
            [],
            (product.selectedModifiers || []).map((modifier) => {
              return modifier.options.map((option) => {
                const modifierInfo: IProductMixProduct = {
                  productName: option.name,
                  quantity: product.quantity,
                  sales: option.additionalCost * product.quantity,
                  cost: 0,
                  profit: option.additionalCost,
                  type: "modifier",
                  menuCategory: "Modifiers",
                  plu: option.plu || "",
                  tabIds: tab.tabId,
                };
                return modifierInfo;
              });
            })
          )
          .filter((modifier) => !!modifier) as unknown as IProductMixProduct[];

        const categoryIndex = productMixResults.findIndex(
          (productMix) => productMix.category === product.type
        );
        if (categoryIndex !== -1) {
          // Add the new product to existing category
          let productIndex = productMixResults[
            categoryIndex
          ].products.findIndex(
            (product) => product.productName === productsInfo.productName
          );

          if (
            productIndex !== -1 &&
            productMixResults[categoryIndex].products[productIndex].type ===
              "modifier"
          ) {
            productIndex = productMixResults[categoryIndex].products.findIndex(
              (product) =>
                product.productName === productsInfo.productName &&
                product.plu === productsInfo.plu &&
                product.type !== "modifier"
            );
          }

          // Increment quantity
          if (productIndex !== -1) {
            productMixResults[categoryIndex].products[productIndex].quantity +=
              productsInfo.quantity;
            productMixResults[categoryIndex].products[productIndex].profit +=
              productsInfo.profit;
            productMixResults[categoryIndex].products[productIndex].sales +=
              productsInfo.sales;
            productMixResults[categoryIndex].products[productIndex].cost +=
              productsInfo.cost;
            productMixResults[categoryIndex].products[productIndex].tabIds +=
              "," + productsInfo.tabIds;
          } else {
            productMixResults[categoryIndex].products.push(productsInfo);
          }

          // Add the new modifiers to existing category
          modifiersInfo.forEach((modifier) => {
            const modifierIndex = productMixResults[
              categoryIndex
            ].products.findIndex(
              (product) => product.productName === modifier.productName
            );
            if (modifierIndex !== -1) {
              productMixResults[categoryIndex].products[
                modifierIndex
              ].quantity += modifier.quantity;
              productMixResults[categoryIndex].products[modifierIndex].profit +=
                modifier.profit;
              productMixResults[categoryIndex].products[modifierIndex].sales +=
                modifier.sales;
              productMixResults[categoryIndex].products[modifierIndex].cost +=
                modifier.cost;
              productMixResults[categoryIndex].products[modifierIndex].tabIds +=
                "," + modifier.tabIds;
            } else {
              productMixResults[categoryIndex].products.push(modifier);
            }
          });
        } else {
          // Add the product in a new category
          productMixResults.push({
            category: product.type,
            products: [productsInfo],
          });

          // Add the modifier in a new category
          modifiersInfo.forEach((modifier) => {
            // This is me being paranoid - I know the last element of the index
            // should correspond to the latest category, but better be safe than
            // sorry
            const categoryIndex = productMixResults.findIndex(
              (productMix) => productMix.category === product.type
            );
            if (categoryIndex !== -1) {
              const modifierIndex = productMixResults[
                categoryIndex
              ].products.findIndex(
                (product) => product.productName === modifier.productName
              );
              if (modifierIndex !== -1) {
                console.log(
                  "Modifier: ",
                  productMixResults[categoryIndex].products[modifierIndex]
                );
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].quantity += modifier.quantity;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].profit += modifier.profit;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].sales += modifier.sales;
                productMixResults[categoryIndex].products[modifierIndex].cost +=
                  modifier.cost;
                productMixResults[categoryIndex].products[
                  modifierIndex
                ].tabIds += "," + modifier.tabIds;
              } else {
                console.log("New Modifier: ", modifier);
                productMixResults[categoryIndex].products.push(modifier);
              }
            } else {
              productMixResults.push({
                category: product.type,
                products: [modifier],
              });
            }
          });
        }
      });
    });
  });

  return productMixResults;
};
