import UIComponents from "@tangopay/tango-ui-library";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage, setupRoutes } from "../routes";
import useVendorDetail from "./useVendorDetail";

const { Icon, Button } = UIComponents;
const VendorDetail = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const vendorId = useMemo(() => {
    const split = loc.pathname.split("/");
    return split[split.length - 1];
  }, [loc]);
  const {
    sales,
    saleHeaders,
    addSale,
    saveSales,
    deleteSales,
    vendor,
    saveVendor,
    infoHeaders,
    contactHeaders,
    vendorInstructions,
    editingVendor,
    setEditingVendor,
    editingContact,
    setEditingContact,
    editingSales,
    setEditingSales,
    saleInstructions,
  } = useVendorDetail(vendorId);
  const vendorAsList = useMemo(() => [vendor], [vendor]);
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full">
        <div
          className={
            "border-r border-grey-1 border-solid border-t-0 border-b-0"
          }
          style={{ width: "10%" }}
        >
          <Sidebar routes={setupRoutes} />
        </div>

        <div style={{ width: "90%" }} className={"bg-off-white"}>
          <div
            className={
              "flex p-8 justify-between items-center bg-white border-b border-grey-1"
            }
            style={{ borderBottomStyle: "solid" }}
          >
            <div className={"flex items-center"}>
              <Icon
                name={"arrow-left"}
                size={"20"}
                className={"cursor-pointer"}
                onClick={() => navigate(-1)}
              />
              <div className={"text-big text-black font-lato-black ml-10"}>
                {vendor?.name}
              </div>
            </div>
            <div className={"flex items-center"}>
              <div
                className={
                  "font-lato-bold text-xs text-error-red mr-7 cursor-pointer"
                }
              >
                Delete
              </div>
              <div className={"h-9 w-px bg-grey-1 mr-7"} />
              <Button
                label={"Purchase Items"}
                type={"btn-style-2"}
                size={"btn-large"}
                onClick={() => navigate(`/inventory/invoices/create?vendorId=${vendorId}`)}
              />
            </div>
          </div>
          <div className={"flex p-8"}>
            <div className={"w-4/12"}>
              <HorizontalTable
                title={"Details"}
                isEditing={editingVendor}
                setEditing={setEditingVendor}
                data={vendorAsList}
                columns={infoHeaders}
                instructions={vendorInstructions}
                saveResults={saveVendor}
                isVertical
              />
              <div className={"h-8"} />
              <HorizontalTable
                title={"Contact Info"}
                isEditing={editingContact}
                setEditing={setEditingContact}
                data={vendorAsList}
                columns={contactHeaders}
                instructions={vendorInstructions}
                saveResults={saveVendor}
                isVertical
              />
            </div>
            <div className={"w-8/12 pl-8"}>
              <div
                className={
                  "bg-white border border-grey border-b-0 border-solid rounded-2xl"
                }
              >
                <HorizontalTable
                  title="Supplies"
                  columns={saleHeaders}
                  data={sales}
                  instructions={saleInstructions}
                  saveResults={saveSales}
                  setEditing={setEditingSales}
                  isEditing={editingSales}
                  primaryActionLabel="Add Item"
                  primaryActionHandler={addSale}
                  deleteIds={deleteSales}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDetail;
