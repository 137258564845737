import { firestore, storage } from "config/firebase";
import _ from "lodash";

export const BASE_TANGO_STORAGE_FOLDER = "TangoStorage";
export const GHOST_FILE_NAME = "folder.ghostfile";

declare type FolderWithPathAndChildrenFilesMetadata = {
  absolutePath: string;
  files: any[];
  numberOfFiles: number;
  folderName: string;
};

export declare type TangoStorageUnitType = "file" | "folder";

const TangoStorageUnitCollection = firestore.collection("TangoStorageUnit");

const extractFilesFromListResult = async (
  listResult: firebase.default.storage.ListResult
) => {
  const itemsReferences = listResult.items;
  const filesInsideAFolder = [];
  for await (const itemRef of itemsReferences) {
    try {
      const itemMetadata = await itemRef.getMetadata();
      filesInsideAFolder.push(itemMetadata);
    } catch (e) {
      console.log("Error fetching metadata for a file");
    }
  }
  return filesInsideAFolder;
};

const extractFoldersFromListResult = async (
  listResult: firebase.default.storage.ListResult
): Promise<FolderWithPathAndChildrenFilesMetadata[]> => {
  const foldersReferences = listResult.prefixes;
  const folders: FolderWithPathAndChildrenFilesMetadata[] = [];
  for await (const folderRef of foldersReferences) {
    try {
      const folderName = folderRef.name;
      const absolutePath = folderRef.fullPath;
      const folderListResult = await folderRef.listAll();
      const filesInFolder = await extractFilesFromListResult(folderListResult);
      const folderData: FolderWithPathAndChildrenFilesMetadata = {
        absolutePath,
        folderName,
        files: filesInFolder,
        numberOfFiles: filesInFolder.length,
      };
      folders.push(folderData);
    } catch (e) {
      console.log("Error fetching metadata for a folder");
    }
  }
  return folders;
};

export const listAllFilesInRootFolder = async (businessId: string) => {
  const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${businessId}`);
  const listResult = await rootRef.listAll();
  const filesInsideAFolder = await extractFilesFromListResult(listResult);
  const foldersInsideAFolder = await extractFoldersFromListResult(listResult);
  console.log("filesInsideAFolder", filesInsideAFolder);
  console.log("foldersInsideAFolder", foldersInsideAFolder);
};

export const addFileToFolder = async (
  folderRef: firebase.default.storage.Reference,
  fileName: string
) => {
  await folderRef.child(fileName).putString("Test");
  await folderRef.child(fileName).updateMetadata;
};

const generateRootRefForBusiness = (businessId: string) =>
  storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${businessId}`);

export declare type TangoStorageUnit = {
  businessId: string;
  absolutePath: string;
  pathRelativeToBusinessRoot: string;
  isRoot: boolean;
  url: string | null;
  id: string;
  deleted: boolean;
  storageUnitType: TangoStorageUnitType;
  name: string;
  fileExtension: string | null;
  sharedWith: string[];
  authorUid: string;
  sizeInMb: number | null;
  tags: string[];
  description: string | null;
  createdAt: any;
  updatedAt: any;
};

export const listFoldersInAbsolutePath = async (
  businessId: string,
  absolutePath: string
) => {
  const rootRef = storage.ref(`${BASE_TANGO_STORAGE_FOLDER}/${businessId}`);
  const listResult = await rootRef.child(absolutePath).listAll();
  const foldersInsideAFolder = await extractFoldersFromListResult(listResult);
  return foldersInsideAFolder;
};

export const fetchAllTangoStorageUnitsForBusiness = async (
  businessId: string
) => {
  const tangoStorageUnitsSn = await TangoStorageUnitCollection.where(
    "businessId",
    "==",
    businessId
  ).get();
  const tangoStorageUnits: TangoStorageUnit[] = [];
  tangoStorageUnitsSn.docs.forEach((doc) => {
    const docData = doc.data() as TangoStorageUnit;
    if (!docData.deleted) {
      tangoStorageUnits.push(docData);
    }
  });
  return tangoStorageUnits;
};

export const createFolderWithGhostFile = async (
  folderFullPath: string,
  businessId: string,
  folderName: string,
  authorId: string
) => {
  const rootRef = generateRootRefForBusiness(businessId);
  const absolutePathWithGhostFile = `${folderFullPath}/${GHOST_FILE_NAME}`;
  await rootRef.child(absolutePathWithGhostFile).putString("");
  const newTangoStorageUnitDocRef = TangoStorageUnitCollection.doc();
  const folderStorageUnit: TangoStorageUnit = {
    id: newTangoStorageUnitDocRef.id,
    absolutePath: `${rootRef.fullPath}/${folderFullPath}`,
    pathRelativeToBusinessRoot: folderFullPath,
    isRoot: false,
    businessId,
    url: null,
    storageUnitType: "folder",
    sizeInMb: null,
    name: folderName,
    fileExtension: null,
    sharedWith: [authorId],
    authorUid: authorId,
    tags: [],
    description: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
  };
  await newTangoStorageUnitDocRef.set(folderStorageUnit);
  await rootRef.child(absolutePathWithGhostFile).updateMetadata({
    customMetadata: { tangoStorageUnitId: folderStorageUnit.id },
  });
};

const composePreFolderPaths = (path: string) => {
  const splittedPreFolderPath = path.split("/");
  return splittedPreFolderPath.map((pathPart, i) => {
    const pathsBefore = _.clone(splittedPreFolderPath).splice(0, i);
    let resultingPath = null;
    if (!pathsBefore.length) {
      resultingPath = pathPart;
    } else if (pathsBefore.length === 1) {
      resultingPath = `${pathsBefore[0]}/${pathPart}`;
    } else {
      resultingPath = `${pathsBefore.join("/")}/${pathPart}`;
    }
    return resultingPath;
  });
};

const createSubfoldersBasedOnPreFolderPath = async (
  businessId: string,
  preFolderPath: string,
  authorId: string
) => {
  if (preFolderPath) {
    const rootRef = generateRootRefForBusiness(businessId);
    const splittedPreFolderPath = preFolderPath.split("/");
    const res = await Promise.all(
      splittedPreFolderPath.map(async (pathPart, i) => {
        const pathsBefore = _.clone(splittedPreFolderPath).splice(0, i);
        console.log("pathsBefore", pathsBefore);
        let resultingPath = null;
        if (!pathsBefore.length) {
          resultingPath = pathPart;
        } else if (pathsBefore.length === 1) {
          resultingPath = `${pathsBefore[0]}/${pathPart}`;
        } else {
          resultingPath = `${pathsBefore.join("/")}/${pathPart}`;
        }
        console.log("resultingPath", resultingPath);
        const listResult = await rootRef.child(resultingPath).listAll();
        const filesInFolder = await extractFilesFromListResult(listResult);
        if (
          !filesInFolder.find(
            (fileMetadata) => fileMetadata?.name === GHOST_FILE_NAME
          )
        ) {
          await createFolderWithGhostFile(
            resultingPath,
            businessId,
            pathPart,
            authorId
          );
        }
        return { resultingPath, filesInFolder };
      })
    );
    console.log("res", res);
  }
};

export const renameFolder = async (
  businessId: string,
  folderPath: string,
  newFolderName: string,
  tangoStorageUnits: TangoStorageUnit[],
  staffId: string
) => {
  const rootRef = generateRootRefForBusiness(businessId);
  const folderRef = rootRef.child(`${folderPath}`);
  const folderGhostFileMetadata = await rootRef
    .child(`${folderPath}/${GHOST_FILE_NAME}`)
    .getMetadata();
  const tangoStorageUnitId =
    folderGhostFileMetadata?.customMetadata?.tangoStorageUnitId;
  if (tangoStorageUnitId) {
    const tsuForFolder = tangoStorageUnits.find(
      (tsu) => tsu.id === tangoStorageUnitId
    );
    if (tsuForFolder) {
      console.log("Checking if has permissions to the folder");
      const hasAccessToFolder = await hasPermissionToTheFolder(
        businessId,
        staffId,
        folderPath,
        tangoStorageUnits
      );
      console.log("hasAccessToFolder", hasAccessToFolder);
      if (hasAccessToFolder) {
        const insidesOfFolderListResult = await folderRef.listAll();
        const prefolderPaths = composePreFolderPaths(
          tsuForFolder.pathRelativeToBusinessRoot
        );
        const reversedPrefoderPaths = _.reverse(_.clone(prefolderPaths));

        const foldersInsideOfFolder = await extractFoldersFromListResult(
          insidesOfFolderListResult
        );
        const filesInsideAFolder = await extractFilesFromListResult(
          insidesOfFolderListResult
        );
        console.log("prefolderPaths", prefolderPaths);
      } else {
        return { success: false };
      }
    } else {
      return { success: false };
    }
  } else {
    return { success: false };
  }
};

export const hasPermissionToTheFolder = async (
  businessId: string,
  staffId: string,
  folderPath: string,
  tangoStorageUnits: TangoStorageUnit[]
) => {
  const rootRef = generateRootRefForBusiness(businessId);
  const folderGhostFileMetadata = await rootRef
    .child(`${folderPath}/${GHOST_FILE_NAME}`)
    .getMetadata();
  const tangoStorageUnitId =
    folderGhostFileMetadata?.customMetadata?.tangoStorageUnitId;
  if (tangoStorageUnitId) {
    const tsuForFolder = tangoStorageUnits.find(
      (tsu) => tsu.id === tangoStorageUnitId
    );
    if (tsuForFolder) {
      if (tsuForFolder?.sharedWith?.includes(staffId)) {
        return true;
      } else {
        const prefolderPaths = composePreFolderPaths(
          tsuForFolder.pathRelativeToBusinessRoot
        );
        const reversedPrefoderPaths = _.reverse(_.clone(prefolderPaths));
        const precedingTangoStorageUnits = reversedPrefoderPaths.map((path) => {
          return tangoStorageUnits.find(
            (tsu) =>
              tsu.storageUnitType === "folder" &&
              tsu.pathRelativeToBusinessRoot === path
          );
        });
        const hasPermissionToAtLeastOneParentFolder =
          precedingTangoStorageUnits.find((tsu) =>
            tsu?.sharedWith?.includes(staffId)
          );
        return Boolean(hasPermissionToAtLeastOneParentFolder);
        //check parent folders
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const storageMagic = async (
  businessId: string,
  authorId: string,
  folderName: string,
  preFolderPath = ""
) => {
  const rootRef = generateRootRefForBusiness(businessId);
  const absolutePath = `${preFolderPath}/${folderName}`;
  const absolutePathWithGhostFile = `${preFolderPath}/${folderName}/${GHOST_FILE_NAME}`;
  createSubfoldersBasedOnPreFolderPath(businessId, preFolderPath, authorId);

  const foldersInPreFolderPath = await listFoldersInAbsolutePath(
    businessId,
    preFolderPath
  );
};

export const createFolder = async (
  businessId: string,
  authorId: string,
  folderName: string,
  preFolderPath = ""
) => {
  const absolutePath = `${preFolderPath}/${folderName}`;
  await createSubfoldersBasedOnPreFolderPath(
    businessId,
    preFolderPath,
    authorId
  );
  const foldersInPreFolderPath = await listFoldersInAbsolutePath(
    businessId,
    preFolderPath
  );
  console.log("foldersInPreFolderPath", foldersInPreFolderPath);
  console.log("absolutePath", absolutePath);
  if (
    foldersInPreFolderPath.find((folder) => folder.folderName === folderName)
  ) {
    alert("oops, there's already a folder with that name here");
    return;
  }
  await createFolderWithGhostFile(
    absolutePath,
    businessId,
    folderName,
    authorId
  );
};

export const createInitialStorageFolderForBusiness = async (
  businessId: string
) => {
  const newBusinessRef = storage.ref(
    `${BASE_TANGO_STORAGE_FOLDER}/${businessId}`
  );
  await newBusinessRef.child(GHOST_FILE_NAME).putString("");
  const newTangoStorageUnitDocRef = TangoStorageUnitCollection.doc();

  const folderStorageUnit: TangoStorageUnit = {
    id: newTangoStorageUnitDocRef.id,
    absolutePath: `${BASE_TANGO_STORAGE_FOLDER}/${businessId}`,
    pathRelativeToBusinessRoot: "",
    isRoot: true,
    businessId,
    url: null,
    storageUnitType: "folder",
    sizeInMb: null,
    name: businessId,
    fileExtension: null,
    sharedWith: [],
    authorUid: businessId,
    tags: [],
    description: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    deleted: false,
  };
  await newTangoStorageUnitDocRef.set(folderStorageUnit);
  await newBusinessRef.child(GHOST_FILE_NAME).updateMetadata({
    customMetadata: { tangoStorageUnitId: folderStorageUnit.id },
  });
};
