import UIComponents from "@tangopay/tango-ui-library";
import React, { useState } from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const groupedOptions = [
  {
    label: "People",
    options: [
      { value: "kishan", label: "Kishan" },
      { value: "spiderman", label: "spiderman" },
    ],
  },
  {
    label: "Business",
    options: [
      { value: "Tango", label: "Tango" },
      { value: "Coop", label: "Coop" },
    ],
  },
  {
    label: "Group",
    options: [
      { value: "group1", label: "Group1" },
      { value: "group2", label: "Group2" },
    ],
  },
];

const EventModal = ({ isOpen, onClose }: Props) => {
  const [showLocationCallPopover, setShowLocationCallPopover] = useState(false);

  return (
    <UIComponents.Modal
      isOpen={isOpen}
      closeModal={onClose}
      width={"763"}
      height={"600"}
    >
      <div className={"pb-10"}>
        <div className={"font-lato-black text-[32px] text-black mb-5"}>
          New Event
        </div>
        <div>
          <div className={"text-black font-lato-black text-xl mb-4"}>Title</div>
          <UIComponents.TextField
            sizeType={"medium"}
            placeholder={"Event title"}
            className={"w-full"}
          />
        </div>
        <div className={"mt-10"}>
          <div className={"text-black font-lato-black text-xl mb-4"}>
            Invite
          </div>
          <UIComponents.SearchBar
            //@ts-ignore
            options={groupedOptions}
            size={"medium"}
            filters={["People", "Groups"]}
            placeholder={"Search"}
            className={"w-full mr-4"}
          />
        </div>
        <div className={"mt-10"}>
          <div className={"text-black font-lato-black text-xl mb-4"}>
            Date and Time
          </div>
          <div className={"flex justify-between items-center mb-3"}>
            <div className={"font-lato-regular text-xs text-grey-3"}>
              Starts
            </div>
            <div className={"flex items-center"}>
              <UIComponents.DatePickerInput size={"large"} className={"mr-4"} />
              <UIComponents.TimePickerInput size={"large"} />
            </div>
          </div>
          <div className={"flex justify-between items-center mb-3"}>
            <div className={"font-lato-regular text-xs text-grey-3"}>End</div>
            <div className={"flex items-center"}>
              <UIComponents.DatePickerInput size={"large"} className={"mr-4"} />
              <UIComponents.TimePickerInput size={"large"} />
            </div>
          </div>
          <div className={"flex justify-between items-center "}>
            <div className={"font-lato-regular text-xs text-grey-3"}>
              Repeat
            </div>
            <div
              style={{ width: "489.16px" }}
              className={"repeat-dropdown-container"}
            >
              <UIComponents.Dropdown
                menuPosition={"absolute"}
                size={"medium"}
                className={"w-full"}
                options={[
                  {
                    label: "None",
                    value: "none",
                  },
                  {
                    label: "Every weekday",
                    value: "every weekday",
                  },
                  {
                    label: "Every week",
                    value: "Every week",
                  },
                  {
                    label: "Every two week",
                    value: "Every two week",
                  },
                  {
                    label: "Every Month",
                    value: "Every Month",
                  },
                  {
                    label: "Every Year",
                    value: "Every Year",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <UIComponents.Popover
          onClickOutside={() => setShowLocationCallPopover(false)}
          positions={["bottom"]}
          isOpen={showLocationCallPopover}
          containerClassName={"z-[100]"}
          content={
            <div
              className={
                "w-[680px] bg-white shadow-general-shadow pt-8 px-6 pb-6"
              }
            >
              <div className={"mb-8"}>
                <div className={"font-lato-regular text-xs text-grey-3"}>
                  Video Call
                </div>
                <div className={"flex items-center mt-3"}>
                  <UIComponents.Icon name={"video-on-filled"} size={"16"} />
                  <div className={"ml-4 font-lato-bold text-sm text-black"}>
                    Tango Video Call
                  </div>
                </div>
              </div>
              <div>
                <div className={"font-lato-regular text-xs text-grey-3"}>
                  Location
                </div>
                <div className={"flex items-center mt-3"}>
                  <UIComponents.TextField
                    sizeType={"medium"}
                    placeholder={"Enter Location"}
                    className={"w-full"}
                  />
                </div>
              </div>
            </div>
          }
        >
          <div
            className={"mt-10 cursor-pointer"}
            onClick={() => setShowLocationCallPopover(true)}
          >
            <div className={"text-black font-lato-black text-xl mb-4 "}>
              Location or Call
            </div>
            <div
              className={`flex bg-blue-grey-2 py-2 px-6 ${
                showLocationCallPopover ? `rounded-t-full` : "rounded-full"
              } items-center justify-between`}
            >
              <div className={"font-lato-bold text-sm text-grey-3"}>
                Location or video call
              </div>
              <UIComponents.Icon name={"chevron-down"} size={"16"} />
            </div>
          </div>
        </UIComponents.Popover>
        <div className={"mt-10 cursor-pointer"}>
          <div className={"text-black font-lato-black text-xl mb-4 "}>
            Notes
          </div>
          <UIComponents.Editor
            initialValue={""}
            placeholder={"Notes"}
            taggables={[]}
          />
        </div>
      </div>
    </UIComponents.Modal>
  );
};
export default EventModal;
