// TODO: use design system radio
import { Radio } from "@blueprintjs/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import CurrencyInput from "components/Inputs/CurrencyInput";

import CollapsibleCard from "../collapsibleCard";
import { ActionButtonProps } from "../collapsibleCard/actionButton";
import StatsGrid from "../statGrid";
import Table from "../table";

export type AugmentedProjection = {
  id: string | null; // null implies there is no such record
  targetFoh: number | null;
  targetBoh: number | null;
  targetAll: number | null;
  salesProj: number;
  wtdTargetFoh: number | null;
  wtdTargetBoh: number | null;
  wtdTargetAll: number | null;
  wtdSalesProj: number | null;
  date: number;
};
type Props = {
  isOpen: boolean;
  title: string;
  stats: AugmentedDailyStats;
  dailyProjection: AugmentedProjection;
  idx: number;
  toggleOpen: (idx: number) => void;
  setSplitTarget: (
    id: string | null,
    date: number,
    fohCents: number,
    bohCents: number
  ) => Promise<string>;
  setOverallTarget: (
    id: string | null,
    date: number,
    cents: number
  ) => Promise<string>;
  setProjection: (
    id: string | null,
    date: number,
    cents: number
  ) => Promise<string>;
};

const toPct = (fraction: number | null) => {
  if (fraction == null) {
    console.warn("toPct called with null argument");
    return "";
  }
  if (!isFinite(fraction)) {
    return "-";
  }
  const rawPct = fraction * 100;
  const rounded = Math.floor(rawPct * 100) / 100;
  return `${rounded} %`;
};

const toDollars = (cents: number | null) => {
  if (cents == null) {
    console.warn("toDollars called with null argument");
    return "";
  }
  const dollars = cents / 100;
  const rounded = Math.floor(dollars * 100) / 100;
  return `$${rounded.toLocaleString()}`;
};

const StatCard = ({
  title,
  toggleOpen: toggleOpenRaw,
  isOpen,
  stats,
  setSplitTarget,
  setOverallTarget,
  setProjection,
  dailyProjection,
  idx,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [lcPercent, setLcPercent] = useState(false);
  const [projectedSales, setProjectedSales] = useState(
    dailyProjection.salesProj
  );
  const [totalLcTarget, setTotalLcTarget] = useState(dailyProjection.targetAll);
  const [fohLcTarget, setFohLcTarget] = useState(dailyProjection.targetFoh);
  const [bohLcTarget, setBohLcTarget] = useState(dailyProjection.targetBoh);

  const toggleOpen = useCallback(
    () => toggleOpenRaw(idx),
    [toggleOpenRaw, idx]
  );

  const reset = useCallback(() => {
    setProjectedSales(dailyProjection.salesProj);
    setTotalLcTarget(dailyProjection.targetAll);
    setFohLcTarget(dailyProjection.targetFoh);
    setBohLcTarget(dailyProjection.targetBoh);
  }, [
    dailyProjection.salesProj,
    dailyProjection.targetAll,
    dailyProjection.targetFoh,
    dailyProjection.targetBoh,
  ]);

  useEffect(reset, [reset]);
  const clear = useCallback(() => {
    setProjectedSales(0);
    setLcPercent(false);
    setTotalLcTarget(0);
    setFohLcTarget(null);
    setBohLcTarget(null);
  }, []);

  const edit = useCallback(() => {
    setEditing(true);
    if (!isOpen) toggleOpen();
  }, [isOpen, toggleOpen]);

  const save = useCallback(async () => {
    const idPromise = setProjection(
      dailyProjection.id,
      dailyProjection.date,
      projectedSales
    );
    const projId = dailyProjection.id || (await idPromise);
    if (splitLc) {
      await setSplitTarget(
        projId,
        dailyProjection.date,
        fohLcTarget ?? 0,
        bohLcTarget ?? 0
      );
    } else {
      await setOverallTarget(projId, dailyProjection.date, totalLcTarget ?? 0);
    }
    // make sure the first promise has also resolved
    await idPromise;
    // don't clear, we anticipate changes
    setEditing(false);
  }, [
    dailyProjection,
    projectedSales,
    fohLcTarget,
    bohLcTarget,
    totalLcTarget,
  ]);

  const cancel = useCallback(() => {
    reset();
    setEditing(false);
  }, [reset]);

  // if we close, stop editing
  useEffect(() => {
    if (editing && !isOpen) {
      cancel();
    }
  }, [editing, isOpen, cancel]);

  const splitLc = useMemo(() => {
    if (totalLcTarget) return false;
    return bohLcTarget !== null && fohLcTarget !== null;
  }, [totalLcTarget, bohLcTarget, fohLcTarget]);
  const splitOn = useCallback(() => {
    if (splitLc) return;
    setTotalLcTarget(null);
    setBohLcTarget(0);
    setFohLcTarget(0);
  }, [splitLc]);
  const splitOff = useCallback(() => {
    if (!splitLc) return;
    setTotalLcTarget(stats.targetLaborCost.total);
    setBohLcTarget(null);
    setFohLcTarget(null);
  }, [splitLc, stats.targetLaborCost.total]);

  const buttons = useMemo(() => {
    const r: ActionButtonProps[] = editing
      ? [
          {
            text: "Clear",
            style: "red",
            onClick: clear,
          },
          {
            text: "Cancel",
            style: "normal",
            onClick: cancel,
          },
          {
            text: "Save",
            style: "focus",
            onClick: save,
          },
        ]
      : [
          {
            text: "Clear",
            style: "disabled",
            onClick: undefined,
          },
          {
            text: "Edit",
            style: "normal",
            onClick: edit,
          },
        ];
    return r;
  }, [editing, edit, clear, cancel, save]);

  // stats for grid
  const [labels, values] = useMemo(() => {
    const variance = Math.abs(
      stats.actualSales - (dailyProjection.salesProj ?? 0)
    );
    const varianceFrac = variance / stats.actualSales;
    const totalLC =
      stats.wtdLC.total ?? (stats.wtdLC.boh ?? 0) + (stats.wtdLC.foh ?? 0);
    const l = ["Actual Sales", "Variance", "Variance %", "WTD Sales"];
    const v = [
      toDollars(stats.actualSales),
      toDollars(stats.actualSales - (dailyProjection.salesProj ?? 0)),
      toPct(varianceFrac),
      toDollars(stats.wtdSales),
    ];
    if (stats.wtdLC.boh !== null && stats.wtdLC.foh !== null) {
      l.push("WTD LC (FOH)");
      l.push("WTD LC (BOH)");
      v.push(toDollars(stats.wtdLC.foh));
      v.push(toDollars(stats.wtdLC.boh));
    }
    l.push("WTD LC");
    v.push(toDollars(totalLC));
    if (stats.wtdSales) {
      l.push("WTD LC %");
      v.push(toPct(totalLC / stats.wtdSales));
    }
    if (
      stats.wtdLC.boh !== null &&
      stats.wtdLC.foh !== null &&
      stats.wtdSales
    ) {
      l.push("WTD LC (FOH) %");
      l.push("WTD LC (BOH) %");
      v.push(toPct(stats.wtdLC.foh / stats.wtdSales));
      v.push(toPct(stats.wtdLC.boh / stats.wtdSales));
    }
    if (stats.foodCost && stats.actualSales) {
      l.push("Food Cost %");
      v.push(toPct(stats.foodCost / stats.actualSales));
    }
    // l.push("Gross Margin %");
    // v.push("value of gross margin %");
    return [l, v];
  }, [
    stats.wtdLC.boh,
    stats.wtdLC.foh,
    stats.wtdLC.total,
    stats.wtdSales,
    stats.actualSales,
    stats.foodCost,
    dailyProjection.salesProj,
  ]);

  return (
    <CollapsibleCard
      containerClass=""
      title={title}
      toggleOpen={toggleOpen}
      buttons={buttons}
      isOpen={isOpen}
    >
      <Table>
        <div className="radio-container"></div>
        <div className="projection-label">Projected Sales</div>
        <div className="projection-input">
          <CurrencyInput
            value={projectedSales}
            onValueChange={setProjectedSales}
            disabled={!editing}
          />
        </div>

        <div className="radio-container">
          <Radio checked={!splitLc} onChange={splitOff} disabled={!editing} />
        </div>
        <div className="projection-label">Target LC</div>
        <div className="projection-input">
          <CurrencyInput
            value={
              splitLc
                ? (bohLcTarget ?? 0) + (fohLcTarget ?? 0)
                : totalLcTarget ?? 0
            }
            onValueChange={setTotalLcTarget}
            disabled={!editing || splitLc}
          />
        </div>

        <div className="radio-container">
          <Radio checked={splitLc} onChange={splitOn} disabled={!editing} />
        </div>
        <div className="projection-label">
          <div>Target LC (FOH)</div>
          <div>Target LC (BOH)</div>
        </div>
        <div className="projection-input">
          <CurrencyInput
            value={splitLc ? fohLcTarget ?? 0 : 0}
            onValueChange={setFohLcTarget}
            disabled={!editing || !splitLc}
          />
          <CurrencyInput
            value={splitLc ? bohLcTarget ?? 0 : 0}
            onValueChange={setBohLcTarget}
            disabled={!editing || !splitLc}
          />
        </div>
      </Table>
      <StatsGrid labels={labels} values={values} />
    </CollapsibleCard>
  );
};

export default StatCard;
