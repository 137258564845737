import {
  Alert,
  Button,
  H5,
  IToaster,
  Intent,
  Switch,
  Toaster,
} from "@blueprintjs/core";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  closeAlert: () => void;
}

const AddSubscriberAlert = ({ isOpen, closeAlert }: Props) => {
  const navigate = useNavigate();

  return (
    <Alert
      //className={this.props.data.themeName}
      cancelButtonText="Okay"
      icon="notifications"
      intent={Intent.PRIMARY}
      isOpen={isOpen}
      confirmButtonText="Add Subscriber"
      onCancel={() => navigate("/campaigns")}
      onConfirm={() => navigate("/add-subscriber")}
    >
      <p>
        Please add the <b>Subscriber</b> First.
      </p>
    </Alert>
  );
};

export default AddSubscriberAlert;
