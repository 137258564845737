import React from "react";

import Header from "components/Header";
import HorizontalTable from "components/Table/HorizontalTable";

import Sidebar from "../Sidebar";
import { headerRoutes, inventorySelectedPage, setupRoutes } from "../routes";
import useSecondaryGroups from "./useSecondaryGroups";
import { useParams } from "react-router-dom";

const SecondaryGroups = () => {
  const { id } = useParams()
  const primaryGroupId = id as string;
  const {
    data,
    primaryGroup,
    addItem,
    columns,
    instructions,
    isEditing,
    saveChanges,
    setEditing,
    deleteIds,
  } = useSecondaryGroups(primaryGroupId);
  return (
    <div className="absolute top-[63px] bottom-0 w-full">
      <Header routes={headerRoutes} selectedRoute={"/inventory/items/basic"} selectedPage={inventorySelectedPage} />
      <div className="flex h-full overflow-hidden">
        <div
          className={"border-r border-grey-1 border-t-0 border-b-0 overflow-y-auto"}
          style={{ width: "10%", borderRightStyle: "solid" }}
        >
          <Sidebar routes={setupRoutes} />
        </div>

        <div
          className={"flex px-10 py-6 overflow-y-auto"}
          style={{ width: "90%" }}
        >
          <HorizontalTable
            title={"Secondary Groups for " + primaryGroup?.name}
            columns={columns}
            data={data}
            instructions={instructions}
            saveResults={saveChanges}
            setEditing={setEditing}
            isEditing={isEditing}
            primaryActionLabel="New Secondary Group"
            primaryActionHandler={addItem}
            deleteIds={deleteIds}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondaryGroups;
