import { Icon } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";

interface Props {
  rating: number;
  onRating: (rating: number) => void;
  color: string;
}
const Rate = ({ rating, onRating, color }: Props) => {
  const [hoverRating, setHoverRating] = useState(0);
  const getColor = (index: number) => {
    if (hoverRating >= index) {
      return "#f5eb3b";
    } else if (!hoverRating && rating >= index) {
      return "#f5eb3b";
    }

    return color;
  };
  const getIconName = (index: number) => {
    if (hoverRating >= index) {
      return "star";
    } else if (!hoverRating && rating >= index) {
      return "star";
    }

    return "star-empty";
  };

  const starRating = useMemo(() => {
    return Array(5)
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => (
        <Icon
          key={idx}
          iconSize={30}
          className="cursor-pointer"
          icon={getIconName(idx)}
          onClick={() => onRating(idx)}
          style={{ color: getColor(idx) }}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        />
      ));
  }, [rating, hoverRating]);

  return <div className="stars-component">{starRating}</div>;
};

export default Rate;
