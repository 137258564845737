import React from "react";

/**
 * Some stuff
 */
interface Props {
  children?: any;
  flexDirection?: "row" | "column";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end";
  alignSelf?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  flex?: number;
  style?: any;
  display?: "flex";
  className?: string;
  draggable?: boolean;
  onClick?: () => void;
}

/**
 *
 * @returns
 * @param props
 */
const Box = (props: Props) => {
  const { children, style, className, onClick, ...rest } = props;
  const passedStyles = Array.isArray(style)
    ? Object.assign({}, ...style)
    : style;

  return (
    <div
      onClick={onClick}
      style={{ ...rest, ...passedStyles }}
      className={className}
    >
      {children}
    </div>
  );
};
export default Box;
