import React, { KeyboardEvent, useEffect, useRef } from "react";

import Box from "components/Box";

interface Props {
  options: string[];
  onSelect: (value: string) => void;
  selectedChoices: string | null;
  color: string;
  images: string[];
}
const PictureChoice = ({
  options,
  onSelect,
  selectedChoices,
  color,
  images,
}: Props) => {
  const refs = useRef([]);
  const handleUserKeyPress = (event: KeyboardEvent) => {
    const alphabets = "abcdefghijklmnopqrstuvwxyz";
    const characterIndex = alphabets.indexOf(event.key);
    if (characterIndex >= 0 && characterIndex < options.length) {
      onSelect(options[characterIndex]);
    }
  };
  useEffect(() => {
    // @ts-ignore
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      // @ts-ignore
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  return (
    <div className="multiple-choice-container picture-choice-container">
      <Box display="flex">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`single-choice-container ${
                selectedChoices === item ? "active" : null
              }`}
              style={{
                color: color,
                borderColor: color,
                borderWidth: selectedChoices === item ? "3px" : "0.5px",
              }}
              onClick={() => onSelect(item)}
            >
              {/*@ts-ignore*/}
              <img src={images[index]} alt="" />
              <Box display="flex">
                <div
                  className="key-bind apply-font"
                  style={{ color, borderColor: color }}
                >
                  {String.fromCharCode(index + 65)}
                </div>
                <input
                  className="choice-name apply-font"
                  value={item}
                  disabled
                  style={{ color }}
                />
              </Box>
            </div>
          );
        })}
      </Box>
    </div>
  );
};
export default PictureChoice;
