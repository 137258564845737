import React, { useMemo } from "react";

import "./style.css";

type Props = {
  actual: number;
  projected: number;
};

const pct = (num: number) => {
  return `${100 * num}%`;
};

const toDollarString = (cents: number) => {
  const dollars = Math.floor(cents / 100);
  return `$${dollars.toLocaleString()}`;
};

const Chart = ({ actual, projected }: Props) => {
  const divisor = Math.max(actual, projected);
  const style1 = useMemo(
    () => ({ height: pct(actual / divisor) }),
    [actual, divisor]
  );
  const style2 = useMemo(
    () => ({ height: pct(projected / divisor) }),
    [projected, divisor]
  );
  return (
    <div className="sales-element-container">
      <div className="sales-element-title">Daily Sales</div>
      <div className="sales-bar-container">
        <div className="sales-chart-bar">
          <div className="bar-container">
            <div style={style1} className="bar bar-actual" />
          </div>
          <div className="bar-title">{toDollarString(actual)}</div>
          <div className="bar-subtitle">Actual</div>
        </div>
        <div className="sales-chart-bar">
          <div className="bar-container">
            <div style={style2} className="bar bar-projected" />
          </div>
          <div className="bar-title">{toDollarString(projected)}</div>
          <div className="bar-subtitle">Projected</div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
