import { Dialog, Icon, InputGroup } from "@blueprintjs/core";
import { Grid } from "@mui/material";
import firebase from "config/firebase";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";

import Box from "components/Box";

import { adminsSelector } from "model/selectors/staffing";

import { RootState } from "../../../../../model/store";
import CrossIcon from "../../../icons/crossIcon.svg";
import "./styles.css";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  businessId: string;
}

export function TemplateNameModal({ isOpen, closeModal, businessId }: Props) {
  const db = firebase.firestore();
  const navigate = useNavigate();
  const user: StaffMember = useSelector((state: RootState) => state.user);

  const [templateName, setTemplateName] = useState("");

  const templateSaveNRedirect = () => {
    const payload = {
      id: null,
      businessId: businessId,
      templateName: templateName,
      templateBody: null,
      authorName:
        _.capitalize(user.contact.firstName) +
        " " +
        _.capitalize(user.contact.lastName),
      updatedAt: new Date(),
      deleted: false,
      deletedAt: null,
      createdAt: new Date(),
    };

    try {
      db.collection("Templates")
        .add(payload)
        .then((docRef) => {
          db.collection("Templates")
            .doc(docRef.id)
            .update({ id: docRef.id })
            .then(() => {
              navigate(`/creative-studio/${docRef.id}`);
            });
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        className="template-name-modal"
        onClose={closeModal}
      >
        <div className="modal-header">
          <img
            src={CrossIcon}
            style={{ height: 12.83, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
          <div className="modal-titlebody">
            <span className="modal-title">Name Template</span>
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-title">Template Name</div>
          <div className="modal-subtitle">
            Type the name of this template below..
          </div>
        </div>

        <div className="input-container">
          <Box className="search-input">
            <InputGroup
              intent="none"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </Box>
          <button
            onClick={() => {
              templateSaveNRedirect();
              // navigate("/creative-studio", {
              // state: { templateName: templateName },
              //});
            }}
            disabled={templateName.length <= 0}
            className={`${
              templateName.length > 0
                ? "continue-button"
                : "continue-button-disabled"
            }`}
          >
            Continue
          </button>
        </div>
      </Dialog>
    </>
  );
}

export default TemplateNameModal;
