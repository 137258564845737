import * as React from "react";

export const PaymentInfo = ({
  height = "24px",
  width = "24px",
  color = "black",
  secondaryColor,
  ...props
}: React.SVGProps<SVGSVGElement> & { secondaryColor?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M4.85023 6.175C3.14773 5.7325 2.60023 5.275 2.60023 4.5625C2.60023 3.745 3.35773 3.175 4.62523 3.175C5.96023 3.175 6.45523 3.8125 6.50023 4.75H8.15773C8.10523 3.46 7.31773 2.275 5.75023 1.8925V0.25H3.50023V1.87C2.04523 2.185 0.875234 3.13 0.875234 4.5775C0.875234 6.31 2.30773 7.1725 4.40023 7.675C6.27523 8.125 6.65023 8.785 6.65023 9.4825C6.65023 10 6.28273 10.825 4.62523 10.825C3.08023 10.825 2.47273 10.135 2.39023 9.25H0.740234C0.830234 10.8925 2.06023 11.815 3.50023 12.1225V13.75H5.75023V12.1375C7.21273 11.86 8.37523 11.0125 8.37523 9.475C8.37523 7.345 6.55273 6.6175 4.85023 6.175Z"
      fill={color}
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
